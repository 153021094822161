import { FetchPolicy } from '@apollo/client';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    CatchupEventDocument,
    CatchupEventMutationResult,
    CatchupEventMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/catchupEvent.generated';
import { ChangeMasterPincodeDocument } from '__SMART_APP_OLD__/api/graphql/mutation/changeMasterPincode.generated';
import {
    PauseLiveChannelDocument,
    PauseLiveChannelMutationResult,
    PauseLiveChannelMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/pauseLiveTv.generated';
import { PlayChannelDocument, PlayChannelMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/playChannel.generated';
import {
    PlayRecordingDocument,
    PlayRecordingMutationResult,
    PlayRecordingMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/playRecording.generated';
import {
    PlayTrailerDocument,
    PlayTrailerMutationResult,
    PlayTrailerMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/playTrailer.generated';
import {
    PlayVodAssetDocument,
    PlayVodAssetMutationResult,
    PlayVodAssetMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/playVODAsset.generated';
import {
    RestartEventDocument,
    RestartEventMutationResult,
    RestartEventMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/restartEvent.generated';
import { StopPlaybackDocument, StopPlaybackMutationResult } from '__SMART_APP_OLD__/api/graphql/mutation/stopPlayback.generated';
import { CheckPincodeDocument } from '__SMART_APP_OLD__/api/graphql/query/checkPincode.generated';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import {
    selectDataSource,
    selectIdpProviderClientId,
    selectIdpProviderClientScope,
    selectIdpProviderClientSecret,
    selectIdpProviderGrantType,
    selectIdpProviderUrl,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { ErrorCode } from '__SMART_APP_OLD__/app/modules/Error/types';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { IdpLoginTokenResult } from '__SMART_APP_OLD__/types/CommonTypes';
import * as sessionEventFactory from 'analytics/logging/factories/sessionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { DataSourceMethod, DataSourceService } from 'App/Types/DataSource';

export const validateAdminPin = async (pin: string) => {
    const profileId = Profile.selectors.selectId(store.getState());
    const pinOptions = {
        profileId,
        pincode: pin,
    };
    const options = { fetchPolicy: 'no-cache' as FetchPolicy };
    const response = await GraphqlClient.makeGraphqlRequest(CheckPincodeDocument, pinOptions, options);
    return response?.data?.checkPincode ?? false;
};

export const changePin = async (newPin: string) => {
    const mutationDocument = ChangeMasterPincodeDocument;
    const variables = {
        input: { masterPincode: newPin },
    };

    return GraphqlClient.makeGraphqlMutationRequest(mutationDocument, variables);
};

export const getDeviceLessToken = async (email: string, password: string) => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.Login];
    const res = await fetch(`${url}?devId=&user=${email}&pwd=${password}&rqT=true`, {
        method: 'POST',
        credentials: 'include',
    });
    return res.json();
};

export const logoutSdsevo = async () => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.Logout];
    const res = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });
    return res.json();
};

export const loginUser = async (email: string, password: string) => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.Login];
    const res = await fetch(`${url}?devId=${Platform.ID}&user=${email}&pwd=${password}&rqT=true&refr=true`, {
        method: 'POST',
        credentials: 'include',
    });
    return res.json();
};

export const getToken = async () => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.GetToken];
    const result = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });
    return result.json();
};

export const checkToken = async (token: string) => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.CheckToken];
    const result = await fetch(`${url}?devId=${Platform.ID}&token=${encodeURIComponent(token)}&apply=true`, {
        method: 'GET',
        credentials: 'include',
    });
    return result.json();
};

export const checkTokenValidity = async (token: string) => {
    try {
        const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.CheckToken];
        const result = await fetch(`${url}?devId=${Platform.ID}&token=${encodeURIComponent(token)}&apply=false`, {
            method: 'GET',
            credentials: 'include',
        });
        const response = await result.json();
        const tokenIsValid = response?.status === 'OK';
        return tokenIsValid;
    } catch (error) {
        console.log(JSON.stringify(error));
        return false;
    }
};

export const cloneToken = async () => {
    const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.CloneToken];
    const cloneResult = await fetch(`${url}?devId=${Platform.ID}`, {
        method: 'GET',
        credentials: 'include',
    });
    return cloneResult.json();
};

export const refreshToken = async () => {
    const token = Storage.get<string>(Storage.Key.REFRESH_TOKEN);
    if (!token) {
        throw new Error(`NO REFRESH TOKEN`);
    }
    const checkRefreshToken = await checkToken(token);

    if (checkRefreshToken.status !== 'OK') {
        throw new Error(checkRefreshToken.error_code);
    }
    const cloneTokenSessionFirst = await cloneToken();

    if (cloneTokenSessionFirst?.error_code) {
        throw new Error(cloneTokenSessionFirst.error_code);
    }
    const checkFirstSessionToken = await checkToken(cloneTokenSessionFirst.token);
    if (checkFirstSessionToken.status !== 'OK') {
        throw new Error(checkFirstSessionToken.error_code);
    }
    const cloneTokenSessionSecond = await cloneToken();
    if (cloneTokenSessionSecond?.error_code) {
        throw new Error(cloneTokenSessionSecond.error_code);
    }
    Storage.set(Storage.Key.USER_SESSION_TOKEN, cloneTokenSessionSecond.token);
};

export const sessionHeartBeat = async () => {
    try {
        const url = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.SessionHB];
        await fetch(`${url}`, {
            method: 'GET',
            credentials: 'include',
        });
    } catch (e) {
        console.log(`Session hb failed with ${(e as any)?.message}`);
    }
};

export const playChannel = (playChannelInput: PlayChannelMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(PlayChannelDocument, playChannelInput).then((response: any) => {
        if (!response) return null;
        const playbackInfo = response?.data?.playChannel?.playbackInfo;
        LoggingService.getInstance().logEvent(
            sessionEventFactory.getLiveViewStartEvent(playChannelInput.input.channelId, playbackInfo.url)
        );
        return playbackInfo;
    });

export const playCatchup = (playCatchupInput: CatchupEventMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(CatchupEventDocument, playCatchupInput).then((response: CatchupEventMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.catchupEvent?.playbackInfo!;
        LoggingService.getInstance().logEvent(
            sessionEventFactory.getCatchupViewStartEvent(playCatchupInput.input.eventId, playbackInfo.url)
        );
        return playbackInfo;
    });

export const pauseLiveEvent = (pauseLiveInput: PauseLiveChannelMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(PauseLiveChannelDocument, pauseLiveInput).then((response: PauseLiveChannelMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.pauseLiveChannel?.playbackInfo!;
        return playbackInfo;
    });

export const playRecording = (playRecordingInput: PlayRecordingMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(PlayRecordingDocument, playRecordingInput).then((response: PlayRecordingMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.playRecording?.playbackInfo!;
        LoggingService.getInstance().logEvent(
            sessionEventFactory.getCatchupViewStartEvent(playRecordingInput.input.recordingId, playbackInfo.url)
        );
        return playbackInfo;
    });

export const playVODAsset = (variables: PlayVodAssetMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(PlayVodAssetDocument, variables).then((response: PlayVodAssetMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.playVODAsset?.playbackInfo!;
        LoggingService.getInstance().logEvent(sessionEventFactory.getVODViewStartEvent(variables.input.vodAssetId, playbackInfo.url));
        return playbackInfo;
    });

export const playTrailer = (variables: PlayTrailerMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(PlayTrailerDocument, variables).then((response: PlayTrailerMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.playTrailer?.playbackInfo!;
        LoggingService.getInstance().logEvent(sessionEventFactory.getTrailerViewStartEvent(variables.input.trailerId, playbackInfo.url));
        return playbackInfo;
    });

export const playRestart = (playRestartInput: RestartEventMutationVariables) =>
    GraphqlClient.makeGraphqlMutationRequest(RestartEventDocument, playRestartInput).then((response: RestartEventMutationResult) => {
        if (!response) return null;
        const playbackInfo = response?.data?.restartEvent?.playbackInfo!;
        LoggingService.getInstance().logEvent(
            sessionEventFactory.getRestartViewStartEvent(playRestartInput.input.eventId, playbackInfo.url)
        );
        return playbackInfo;
    });

export const stopPlayback = (sessionId: string) => {
    const stopPlaybackInput = {
        input: {
            sessionId,
        },
    };
    return GraphqlClient.makeGraphqlMutationRequest(StopPlaybackDocument, stopPlaybackInput).then(
        (response: StopPlaybackMutationResult) => {
            LoggingService.getInstance().logEvent(sessionEventFactory.getViewStopEvent());
            return response;
        }
    );
};

export const idpLoginDeviceAuthorizationStart = async () => {
    try {
        const state = store.getState();
        const baseUrl = selectIdpProviderUrl(state);
        const clientSecret = selectIdpProviderClientSecret(state);
        const clientId = selectIdpProviderClientId(state);
        const scope = selectIdpProviderClientScope(state);
        const formData = new URLSearchParams();
        formData.append('client_id', clientId);
        formData.append('scope', scope);
        formData.append('client_secret', clientSecret);
        const devicePath = Env.IsBulgaria || Env.isBusiness ? 'auth/device' : 'device_authorization';
        const result = await fetch(`${baseUrl}/${devicePath}`, {
            method: 'POST',
            cache: 'no-cache',
            headers: createIdpHeaders(),
            body: formData.toString(),
        });
        return result.json();
    } catch (err: any) {
        console.log(`[IDP] error: ${err.message}`);
        return null;
    }
};

export const idpLoginDeviceAuthorizationAuthPooling = async (deviceCode: string): Promise<IdpLoginTokenResult | null> => {
    try {
        const state = store.getState();
        const baseUrl = selectIdpProviderUrl(state);
        const clientSecret = selectIdpProviderClientSecret(state);
        const clientId = selectIdpProviderClientId(state);
        const grantType = selectIdpProviderGrantType(state);
        const formData = new URLSearchParams();
        formData.append('client_id', clientId);
        formData.append('device_code', deviceCode);
        formData.append('grant_type', grantType);
        formData.append('client_secret', clientSecret);
        const response = await fetch(`${baseUrl}/token`, {
            method: 'POST',
            cache: 'no-cache',
            headers: createIdpHeaders(),
            body: formData.toString(),
        });
        return response.json();
    } catch (err: any) {
        console.log(`[IDP] error: ${err.message}`);
        return null;
    }
};

export const idpLoginRefreshToken = async (): Promise<void> => {
    try {
        const state = store.getState();
        const baseUrl = selectIdpProviderUrl(state);
        const clientSecret = selectIdpProviderClientSecret(state);
        const clientId = selectIdpProviderClientId(state);
        const refreshIdpToken = Storage.get<string>(Storage.Key.IDP_REFRESH_TOKEN) ?? '';
        const formData = new URLSearchParams();
        formData.append('client_id', clientId);
        formData.append('client_secret', clientSecret);
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refreshIdpToken);
        const result = await fetch(`${baseUrl}/token`, {
            method: 'POST',
            cache: 'no-cache',
            headers: createIdpHeaders(),
            body: formData.toString(),
        });
        const tokenInfo = await result.json();
        if (!tokenInfo.access_token) {
            throw new Error(ErrorCode.ERROR_EXPIRED_SEC_TOKEN);
        }
        Storage.set<string>(Storage.Key.IDP_ACCESS_TOKEN, tokenInfo.access_token);
    } catch (err: any) {
        console.log(`[IDP] error: ${err.message}`);
        throw err;
    }
};

export const idpLoginCheckToken = async (): Promise<boolean> => {
    try {
        const state = store.getState();
        const baseUrl = selectIdpProviderUrl(state);
        const clientSecret = selectIdpProviderClientSecret(state);
        const clientId = selectIdpProviderClientId(state);
        const token = Storage.get<string>(Storage.Key.IDP_ACCESS_TOKEN) ?? '';
        const formData = new URLSearchParams();
        formData.append('token', token);
        const result = await fetch(`${baseUrl}/token/introspect`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                ...createIdpHeaders(),
                Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
            },
            body: formData.toString(),
        });
        const tokenInfo = await result.json();
        return tokenInfo.active;
    } catch (err: any) {
        console.log(`[IDP] error: ${err.message}`);
        return false;
    }
};

export const idpLoginRevokeToken = (hint: string, token: string) => {
    try {
        const state = store.getState();
        const baseUrl = selectIdpProviderUrl(state);
        const clientSecret = selectIdpProviderClientSecret(state);
        const clientId = selectIdpProviderClientId(state);
        const formData = new URLSearchParams();
        const endPoint = Env.isBusiness || Env.IsBulgaria ? 'revoke' : 'token/revoke';
        formData.append('token', token);
        formData.append('token_type_hint', hint);
        return fetch(`${baseUrl}/${endPoint}`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                ...createIdpHeaders(),
                Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
            },
            body: formData.toString(),
        });
    } catch (err: any) {
        console.log(`[IDP] error: ${err.message}`);
        return null;
    }
};

const createIdpHeaders = () => {
    return Env.IsBulgaria
        ? {
              'Content-Type': 'application/x-www-form-urlencoded',
              ...(Platform.ID ? { X_DEVICE_ID: Platform.ID! } : {}),
          }
        : {
              'Content-Type': 'application/x-www-form-urlencoded',
          };
};
