import React from 'react';

import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';

import { Env } from 'App/Env';

interface Props {
    className?: string;
}

const getClassName = (isDarkTheme: boolean, className?: string) => {
    const classList: string[] = ['login-background'];
    if (Env.IsAustria) classList.push('login-background--austria');
    else if (isDarkTheme) classList.push('login-background--dark-theme');
    else classList.push('login-background--light-theme');
    if (className) classList.push(className);
    return classList.join(' ');
};

export const LoginBackground: React.FunctionComponent<Props> = (props) => {
    const isDarkTheme = useIsDarkTheme();
    const className = getClassName(isDarkTheme, props.className);
    return <div className={className} />;
};
