import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type VodAssetEntitlementFragment = { __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', id: string } | null };

export const VodAssetEntitlementFragmentDoc = gql`
    fragment vodAssetEntitlement on VODAssetEntitlement {
  id
  playback
  temporary
  playbackAvailableUntil
  product {
    id
  }
}
    `;