import { selectMenuItems } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';

/**
 *  Utility helper functions used in SmartTv application.
 * @namespace Utils
 */

/**
 * A void function introducing delay in the code equal to duration value it receives.
 * @function delay
 * @memberof Utils
 * @param duration - timeout in milliseconds to delay execution of a function.
 * @returns resolved promise after delay.
 */
export const delay = (duration = 300): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });

/**
 * A function wrapper around callback function,
 * which returns the callback after delay based on wait value
 * or immediately depending on the immediate flag
 * @function debounce
 * @memberof Utils
 * @param func - the callback function which will be debounced.
 * @param wait - timeout in milliseconds to delay execution of a function.
 * @param immediate  - a flag to indicate whether callback should be debounced.
 * @returns simulated delay in a code.
 */
export const debounce = (func: () => {}, wait: number, immediate: boolean) => {
    let timeout: ReturnType<typeof setTimeout> | null;
    const debounced = (...parameters: []) => {
        const context = this;
        const args = parameters;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout as ReturnType<typeof setTimeout>);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
        return () => {
            clearTimeout(timeout as ReturnType<typeof setTimeout>);
        };
    };
    debounced.cancel = () => {
        if (timeout !== undefined) {
            clearTimeout(timeout as ReturnType<typeof setTimeout>);
        }
    };

    return debounced;
};

/**
 * A function changing any string to camelCase.
 * @function toCamelCase
 * @param str string to transform to camelCase.
 * @memberof Utils
 * @returns the camelCase version of str
 */
export const toCamelCase = (str: string): string => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());

/**
 * A function checking the position of the given element in the navigation row, is it first or not.
 * @function isFirstMenuElement
 * @memberof Utils
 * @param menuElement the value of element in navigation to check against.
 * @returns is the element first or not.
 */
export const isFirstMenuElement = (menuElement: unknown): boolean => {
    const items = selectMenuItems(store.getState());
    return items[0].id === menuElement;
};

/**
 * A function which change the player styling based on visibility.
 * @summary Changes the player styles based on a flag if player is visible.
 * @function changePlayerVisibility
 * @memberof Utils
 * @param [isVisible] is the player visible or not.
 */
export const changePlayerVisibility = (isVisible = true): void => {
    try {
        const player = document.getElementById('tv-player');
        if (!player) return;
        player.style.visibility = isVisible ? 'visible' : 'hidden';
    } catch {
        console.log(`Can not change player visibility`);
    }
};

/**
 * An utility function changing a string of scss classes replacing substrings inside the string
 * used to change outlook based on user interactions.
 * @summary Changes a given classList substrings to change the styling.
 * @function classListReplace
 * @memberof Utils
 * @param classList current class list
 * @param classToRemove substring class string to remove from classList
 * @param classToAdd substring class string to add to classList
 */
export const classListReplace = (classList: DOMTokenList, classToRemove: string, classToAdd: string) => {
    try {
        if (classList.replace) {
            const replaced = classList.replace(classToRemove, classToAdd);
            if (replaced as unknown as boolean) {
                return;
            }
        }
        if (classList.contains(classToRemove)) {
            classList.remove(classToRemove);
        }
        classList.add(classToAdd);
    } catch (error) {
        console.log(error);
    }
};

/**
 * An utility function to check if string is json string
 * @function isJson
 * @memberof Utils
 * @param str the string to be tested if json
 * @returns boolean depending on th type of string provided
 */
export const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 * An utility function to parse a json string and return
 *  the value or a default passed value
 * @function JsonValueOrDefaultValue
 * @param str the json string to be parsed
 * @param defaultValue default value to be returned
 * if parsing fails
 * @memberof Utils
 * @returns result of the parsed string or default value
 */
export const JsonValueOrDefaultValue = (str: string | undefined, defaultValue: any): any => {
    try {
        if (!str) return defaultValue;
        const x = JSON.parse(str);
        return x;
    } catch (e) {
        return defaultValue;
    }
};

/**
 * An utility function to ellipsize any string
 *  up to the desired amount of words
 * @function ellipsizeStringByWordCount
 * @param str the string to be ellisized
 * @param wordLength the cutoff word count at which
 * the provided string will be ellipsized
 * (if wordLength is undefined it will default to 4)
 * @memberof Utils
 * @returns result of the ellipsized string if the word count
 * is over the provided value
 */
export const ellipsizeStringByWordCount = (str: string, wordLength: number = 4): string => {
    const words = str.split(' ');
    if (wordLength <= words.length) {
        return `${words.slice(0, wordLength).join(' ')}...`;
    }
    return str;
};
