import { selectConfig, selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { AssetType, PinAction, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { Overlay } from 'App/Modules/Overlay';
import { PromptType } from 'App/Modules/Prompt/Types';
import { Prompt } from 'App/Modules/Prompt';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { getPlanRecordingEvent } from 'analytics/logging/factories/recordingEventFactory';
import { RecordingPlanningTypes } from 'analytics/logging/events/RecordingEvent';
import { LoggingService } from 'analytics/loggingService';
import { selectRecordingByEventId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Screen } from 'App/Modules/Screen';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Detail } from 'App/Modules/Data/Detail';
import { Folder } from 'App/Modules/Folder';
import { ReminderEvents } from 'analytics/logging/events/ReminderEvent';
import { getReminderEvent } from 'analytics/logging/factories/reminderEventFactory';
import { reminderCancel, reminderCreate } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { toDetailPage } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/actions';
import { DefaultVariables } from 'App/Modules/Data/Detail/Event/Api/Api';
import { EventDetailLoadQueryVariables } from 'App/Modules/Data/Detail/Event/Api/eventDetailLoad.generated';
import { canResume, startPlayback } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/helpers';
import { DefaultDetailScreenOutOfBounds } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/defaultOutOfBounds';

const watchSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Event.selectors.selectAsset;
    const asset = itemSelector(state);
    const assetObject = Detail.Event.selectors.selectAssetObject(state);
    if (!asset) return;
    const progress = Detail.Root.selectors.selectAssetProgress(itemSelector)(state);
    const bookmark = Bookmarks.selectors.selectEntity(asset.id)(state);
    const resumeThreshold = selectResumeThreshold(state);
    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    const resume = canResume(asset, progress, bookmark, resumeThreshold);
    const isLive = asset.entitlements.programType === ProgramType.LIVE;
    const event = !isLive && resume ? UIActionEvents.CONTINUE_WATCHING : UIActionEvents.PLAY;
    const isStartOver = !isLive && !resume;

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, event, isStartOver));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, event, isStartOver)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const showMoreSelected =
    (title: string, actors: string[], directors: string[], description: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const asset = Detail.Event.selectors.selectAssetObject(state);

        if (!asset) return;

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.OPEN_FULL_SYNOPSIS, asset));
        dispatch(Overlay.actions.mount(Overlay.Type.ShowMore, { title, actors, directors, description }));
    };

const startOverSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Event.selectors.selectAsset;
    const asset = itemSelector(state);
    const assetObject = Detail.Event.selectors.selectAssetObject(state);
    if (!asset) return;
    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const folderListItemSelected =
    (folderId: string, itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const folderSelector = Detail.Event.selectors.selectFolder(folderId);
        const itemSelector = Folder.selectors.selectItem(folderSelector)(itemId);
        const item = itemSelector(state);

        if (!item || !item.type) return;

        const pinSessionType = item.metadata.sessionType;
        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, pinSessionType)(state);

        if (!isRestricted) {
            dispatch(toDetailPage(item.id, item.type));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toDetailPage(item.id, item.type)),
            () => dispatch(Overlay.actions.unmount())
        );
    };

const recordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Event.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetObject = Detail.Event.selectors.selectAssetObject(state);
    const assetType = AssetType.EVENT;

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCreate, { eventId: asset.id }));

    const uiActionEvent = getUIActionEvent(UIActionEvents.RECORD, {
        id: asset.id,
        assetType,
    });
    const recordingEvent = await getPlanRecordingEvent(asset.id, RecordingPlanningTypes.EPG, assetObject?.isSeriesEvent);

    LoggingService.getInstance().logEvent(uiActionEvent);
    LoggingService.getInstance().logEvent(recordingEvent);
};

const deleteRecordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const asset = Detail.Event.selectors.selectAsset(state);
    if (!asset) return;
    const recording = selectRecordingByEventId(asset.id)(state);

    if (!recording) return;

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmDelete, { recordingId: recording.id }));
};
const cancelRecordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Event.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetType = AssetType.EVENT;

    const event = getUIActionEvent(UIActionEvents.STOP_RECORDING, {
        id: asset.id,
        assetType,
    });
    const recording = selectRecordingByEventId(asset.id)(state);

    if (!recording) return;

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCancel, { recordingId: recording.id }));
    LoggingService.getInstance().logEvent(event);
};
const reminderSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Event.selectors.selectAsset;
    const assetId = Detail.Root.selectors.selectAssetId(itemSelector)(state);
    const reminder = Detail.Root.selectors.selectAssetReminder(itemSelector)(state);

    const eventType = reminder ? ReminderEvents.CANCEL : ReminderEvents.PLAN;
    LoggingService.getInstance().logEvent(getReminderEvent(eventType, assetId));

    if (eventType === ReminderEvents.CANCEL) {
        dispatch(reminderCancel(assetId));
    }

    dispatch(reminderCreate(assetId));
};

const mount: Screen.LifecycleMethod<Screen.Type.EventDetail> =
    (props): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        console.log('mounts event screen');
        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));

        const state = getState();
        const config = selectConfig(state);

        const eventId = props.id;

        const variables: EventDetailLoadQueryVariables = {
            ...DefaultVariables,
            eventId,
            thumbnailHeight: config.image.thumbnail.default.height,
            backgroundWidth: config.image.background.landscape,
            backgroundHeight: config.image.background.width,
        };

        await dispatch(Detail.Event.actions.load(variables));
        dispatch(Overlay.actions.unmount());
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    dispatch(Detail.Event.actions.reset());
};

export const DetailEventActions = {
    public: {
        watchSelected,
        showMoreSelected,
        recordSelected,
        cancelRecordSelected,
        startOverSelected,
        deleteRecordSelected,
        reminderSelected,
        folderListItemSelected,
    },
    lifecycle: {
        mount,
        unmount,
        ...DefaultDetailScreenOutOfBounds,
    },
};
