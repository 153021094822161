import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import React from 'react';

const getClassName = (props: DetailLogoProps): string => {
    const classList = ['detail__logo'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailLogoProps = {
    id: string;
    className?: string;
    theme?: Theme.Type | null;
};

export type DetailLogoComponent = React.FunctionComponent<DetailLogoProps>;

export const DetailLogo: DetailLogoComponent = (props) => {
    return <ChannelLogo className={getClassName(props)} id={props.id} theme={props.theme} />;
};
