import { isLanguageSet } from '__SMART_APP_OLD__/app/modules/Language/functions';
import { languageActions } from '__SMART_APP_OLD__/app/modules/Language/store/actions';
import { getInitialLanguageState } from '__SMART_APP_OLD__/app/modules/Language/store/getInitialState';
import { languageReducer } from '__SMART_APP_OLD__/app/modules/Language/store/reducer';
import { languageSelectors } from '__SMART_APP_OLD__/app/modules/Language/store/selectors';
import { LanguageActionType, LanguageEntityTable, ReduxLanguageAction } from '__SMART_APP_OLD__/app/modules/Language/store/types';

export { LanguageActionType as ActionType };

export type { LanguageEntityTable as EntityTable, ReduxLanguageAction as ReduxAction };

export const getInitialState = getInitialLanguageState;

export const isSet = isLanguageSet;

export const reducer = languageReducer;

export const selectors = languageSelectors.public;

export const actions = languageActions.public;
