import { useEffect } from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemActive = (item: string) => Subscribable.Value<boolean>;

export const useHeaderItemActive: UseHeaderItemActive = (item: string) => {
    const headerItems = HeaderItems.use();
    const active = Subscribable.useValue(false);

    useEffect(() => {
        const isActive = () => headerItems.active.get() === item;

        const update = async () => active.set(isActive());

        update();

        return headerItems.active.subscribe(update);
    }, [item, headerItems.active, active]);

    return active;
};
