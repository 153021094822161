/* eslint-disable max-statements */
/* eslint-disable react-func/max-lines-per-function */
import throttle from 'lodash.throttle';

import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { channelSwitchIdChange } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectSelectedChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { SHOW_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { LONG_PRESS_DURATION_THRESHOLD, SHORT_PRESS_THROTTLE } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';

type ChannelGetterFunction = () => string;

let keyDownPressed: boolean = false;
let keyUpPressed: boolean = false;
let currentChannelId: string = '';

/**
 * @description The animation trigger is throttled
 * to make sure we don't overload the devices with costly animations.
 * Here we also define a default animation end trigger
 * in case the `animationend` event is not triggered in time.
 * This way we don't remain with an overlay that can't be closed.
 * NOTE: The short press animation behaves as a long press in case the user is spamming the button.
 */
const showShortPressAnimation = throttle(
    (getChannel: ChannelGetterFunction, closeCallback?: () => void, forceSwitching = false) => {
        currentChannelId = getChannel();

        Events.triggerEvents(SHOW_CHANNEL_SWITCH_DIALOG, {
            longPress: false,
            channelId: currentChannelId,
            closeCallback,
            forceSwitching,
        });
    },
    SHORT_PRESS_THROTTLE,
    { trailing: false }
);

/**
 * @description The animation trigger is throttled
 * so we don't overload the devices with costly animations.
 */
const showLongPressAnimation = throttle((getChannel: ChannelGetterFunction) => {
    currentChannelId = getChannel();

    Events.triggerEvents(SHOW_CHANNEL_SWITCH_DIALOG, {
        channelId: currentChannelId,
        longPress: true,
    });
}, LONG_PRESS_DURATION_THRESHOLD);

/**
 * @description We need the keyup event to decide if we have a short press
 * or if the user is holding down the button. On keyup, we clear the pressed keys.
 * @param event event
 */
const onKeyUp = (event: any) => {
    const keyCode = event.which || event.keyCode;
    switch (keyCode) {
        case Key.VK_DOWN:
        case Key.VK_CHAN_DOWN:
            showLongPressAnimation.cancel();
            keyDownPressed = false;
            Player.blocked = false;
            break;
        case Key.VK_UP:
        case Key.VK_CHAN_UP:
            showLongPressAnimation.cancel();
            keyUpPressed = false;
            Player.blocked = false;
            break;
        default:
            break;
    }
};

export const initChannelSwitching = () => {
    document.body.addEventListener('keyup', onKeyUp);
};

export const cleanupAfterChannelSwitching = () => {
    document.body.removeEventListener('keyup', onKeyUp);

    keyDownPressed = false;
    keyUpPressed = false;
    currentChannelId = '';
};

export const handleChannelSwitching = (keyCode: number | null, channelId: string, closeCallback = () => {}, silent: boolean = false) => {
    if (!currentChannelId) {
        currentChannelId = channelId;
    }

    if (silent) {
        currentChannelId = channelId;
        store.dispatch(channelSwitchIdChange(currentChannelId));
        playChannel(currentChannelId);
        return;
    }

    if (!keyCode) {
        showShortPressAnimation(() => channelId, closeCallback, true);
        return;
    }

    const getPrevChannel = () => {
        const channelsIds = selectSelectedChannelListChannelIds(store.getState());
        const currentChannelIndex = channelsIds.indexOf(currentChannelId);

        if (currentChannelIndex === 0 && channelsIds?.length) {
            return channelsIds[channelsIds.length - 1];
        }

        if (channelsIds?.length) {
            return channelsIds[currentChannelIndex - 1];
        }

        return '';
    };

    const getNextChannel = () => {
        const channelsIds = selectSelectedChannelListChannelIds(store.getState());
        const currentChannelIndex = channelsIds.indexOf(currentChannelId);

        if (currentChannelIndex === channelsIds.length - 1 && channelsIds.length > 0) {
            return channelsIds[0];
        }

        if (channelsIds?.length) {
            return channelsIds[currentChannelIndex + 1];
        }

        return '';
    };

    switch (keyCode) {
        case Key.VK_DOWN:
        case Key.VK_CHAN_DOWN:
            if (!keyDownPressed) {
                /**
                 * @description
                 * NOTE: SHORT PRESS
                 * We make use of the fact, that if the user is holding down a key,
                 * a keydown event is constantly being triggered, hence the code will eventually
                 * reach this case. If  the corresponding key wasn't pressed before this event,
                 * we decide it is a short press. Otherwise it's a long press.
                 */
                keyDownPressed = true;
                Player.blocked = true;
                showShortPressAnimation(getPrevChannel);
            } else {
                showLongPressAnimation(getPrevChannel);
            }
            break;
        case Key.VK_UP:
        case Key.VK_CHAN_UP:
            if (!keyUpPressed) {
                /**
                 * @description
                 * NOTE: SHORT PRESS
                 * We make use of the fact, that if the user is holding down a key,
                 * a keydown event is constantly being triggered, hence the code will eventually
                 * reach this case. If  the corresponding key wasn't pressed before this event,
                 * we decide it is a short press. Otherwise it's a long press.
                 */
                keyUpPressed = true;
                Player.blocked = true;
                showShortPressAnimation(getNextChannel);
            } else {
                showLongPressAnimation(getNextChannel);
            }
            break;
        default:
            break;
    }
};
