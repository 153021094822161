import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { getTimeGap } from '__SMART_APP_OLD__/utils/timeUtils';

import { Folder } from 'App/Modules/Folder/index';
import { UI } from 'App/Packages/UI';

type VideoStorePortraitCardContentMarkerProps = {
    selector: Selector<Folder.Item | null>;
};

const VideoStorePortraitCardContentMarker: React.FunctionComponent<VideoStorePortraitCardContentMarkerProps> = (props) => {
    const marker = useSelector(Folder.selectors.item.selectContentMarker(props.selector));
    const availableUntil = useSelector(Folder.selectors.item.selectAvailableUntil(props.selector));

    if (!marker) return null;

    if (!availableUntil) return <UI.Folder.Card.Portrait.Main.Meta.Marker marker={marker} />;

    return <UI.Folder.Card.Portrait.Main.Meta.Marker marker={marker} label={getTimeGap(availableUntil)} />;
};

type VideoStorePortraitCardProgressProps = {
    selector: Selector<Folder.Item | null>;
};

const VideoStorePortraitCardProgress: React.FunctionComponent<VideoStorePortraitCardProgressProps> = (props) => {
    const progress = useSelector(Folder.selectors.item.selectProgress(props.selector));

    if (!progress) return null;

    return <UI.Folder.Card.Portrait.Main.Meta.Progress progress={progress} />;
};

export type VideoStorePortraitCardProps = {
    selector: Selector<Folder.Item | null>;
    onFocus?: (id: string) => void;
};

export const PortraitCard: React.FunctionComponent<VideoStorePortraitCardProps> = (props) => {
    const item = useSelector(props.selector);

    if (!item) return null;

    return (
        <UI.Folder.Card.Portrait
            id={item.id}
            parentalRating={item.metadata.parentalRatingId}
            sessionType={item.metadata.sessionType}
            onFocus={props.onFocus}
        >
            <UI.Folder.Card.Portrait.Main>
                <UI.Folder.Card.Portrait.Main.Thumbnail src={item.content.thumbnail} />
                <UI.Folder.Card.Portrait.Main.Meta>
                    <VideoStorePortraitCardContentMarker selector={props.selector} />
                    <VideoStorePortraitCardProgress selector={props.selector} />
                </UI.Folder.Card.Portrait.Main.Meta>
            </UI.Folder.Card.Portrait.Main>
            <UI.Folder.Card.Portrait.Content>
                <UI.Folder.Card.Portrait.Content.Title>{item.content.title}</UI.Folder.Card.Portrait.Content.Title>
                <UI.Folder.Card.Portrait.Content.Caption>{item.content.info}</UI.Folder.Card.Portrait.Content.Caption>
                <UI.Folder.Card.Portrait.Content.Description>{item.content.description}</UI.Folder.Card.Portrait.Content.Description>
            </UI.Folder.Card.Portrait.Content>
        </UI.Folder.Card.Portrait>
    );
};
