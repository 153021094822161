import React from 'react';

import { ChannelListKind } from '__SMART_APP_OLD__/api/graphql/types';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelListKind,
    selectChannelListName,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';

import { UseListReturnType } from 'App/Packages/Focus/Hooks/Types';

type ChannelListsScreenViewMainSectionItemProps = {
    channelListId: string;
    index: number;
    ctx: string;
    navigation: UseListReturnType<string>;
    isArranging: boolean;
    handleEnter: (channelListId: string) => void;
    onFocus: () => void;
};

export const ChannelListsScreenViewMainSectionItem: React.FunctionComponent<ChannelListsScreenViewMainSectionItemProps> = (props) => {
    const { channelListId, isArranging, index, navigation, ctx, handleEnter, onFocus } = props;

    const channelListName = useSelector(selectChannelListName(channelListId));
    const channelListKind = useSelector(selectChannelListKind(channelListId));
    const currentActiveChannelListId = useSelector(selectSelectedChannelListId);

    return (
        <>
            {index === 0 && <Settings.Section.Content.Separator />}
            <Settings.Section.Content.Item
                id={channelListId}
                ctx={ChannelListsScreenCtx.ChannelLists}
                isFocused={navigation.focused === channelListId && !navigation.isDisabled}
                onEnter={handleEnter}
                onHover={navigation.focus}
                onFocus={onFocus}
                isActive={navigation.focused === channelListId && ctx === ChannelListsScreenCtx.ChannelListsOptions}
            >
                <Settings.Section.Content.Item.Title>{channelListName}</Settings.Section.Content.Item.Title>
                {channelListKind === ChannelListKind.Subscriber && <Settings.Section.Content.Item.Icon.Editable />}
                <Settings.Section.Content.Item.Spacer />
                {currentActiveChannelListId === channelListId &&
                    (!isArranging || (isArranging && navigation.focused !== channelListId)) && (
                        <Settings.Section.Content.Item.Icon.Checked />
                    )}
                {navigation.focused === channelListId && isArranging && <Settings.Section.Content.Item.Icon.Arrange />}
            </Settings.Section.Content.Item>
            <Settings.Section.Content.Separator />
        </>
    );
};
