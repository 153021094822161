import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { useCardLandscape } from 'App/Packages/UI/Card/CardLandscape/Hooks/useCardLandscape';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (className: string | undefined, isHidden: boolean): string => {
    const classList = ['landscape-card-v3__description'];
    if (isHidden) classList.push('landscape-card-v3__description--hidden');
    if (className) classList.push(className);
    return classList.join(' ');
};

export type CardLandscapeDescriptionProps = React.PropsWithChildren<{
    className?: string;
}>;

export type CardLandscapeDescriptionComponent = React.FunctionComponent<CardLandscapeDescriptionProps>;

export const CardLandscapeDescription: CardLandscapeDescriptionComponent = (props) => {
    const card = useCardLandscape();

    return (
        <Text
            className={getClassName(props.className, !card.isFocused)}
            typeface={Typeface.SANS}
            size={FontSize.CAPTION_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            <Mask.Description>{props.children}</Mask.Description>
        </Text>
    );
};
