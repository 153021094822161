import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ReminderFragmentDoc } from '../fragments/reminder.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReminderLoadManyQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
}>;


export type ReminderLoadManyQuery = { __typename?: 'Nexx4Queries', reminders?: { __typename?: 'ReminderCatalog', items: Array<{ __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } } | null> } | null };

export type ReminderLoadManyFragment = { __typename?: 'Nexx4Queries', reminders?: { __typename?: 'ReminderCatalog', items: Array<{ __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } } | null> } | null };

export const ReminderLoadManyFragmentDoc = gql`
    fragment reminderLoadMany on Nexx4Queries {
  reminders(profileId: $profileId) {
    items {
      ...reminder
    }
  }
}
    ${ReminderFragmentDoc}`;
export const ReminderLoadManyDocument = gql`
    query reminderLoadMany($profileId: ID!) {
  ...reminderLoadMany
}
    ${ReminderLoadManyFragmentDoc}`;

/**
 * __useReminderLoadManyQuery__
 *
 * To run a query within a React component, call `useReminderLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderLoadManyQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useReminderLoadManyQuery(baseOptions: Apollo.QueryHookOptions<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>(ReminderLoadManyDocument, options);
      }
export function useReminderLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>(ReminderLoadManyDocument, options);
        }
export function useReminderLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>(ReminderLoadManyDocument, options);
        }
export type ReminderLoadManyQueryHookResult = ReturnType<typeof useReminderLoadManyQuery>;
export type ReminderLoadManyLazyQueryHookResult = ReturnType<typeof useReminderLoadManyLazyQuery>;
export type ReminderLoadManySuspenseQueryHookResult = ReturnType<typeof useReminderLoadManySuspenseQuery>;
export type ReminderLoadManyQueryResult = Apollo.QueryResult<ReminderLoadManyQuery, ReminderLoadManyQueryVariables>;