import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Translation as Module } from '__SMART_APP_OLD__/app/modules/Translation';

export type TranslationProps = {
    children?: string | null;
    transform?: (translation: string) => string;
};

export type TranslationModule = {} & React.FunctionComponent<TranslationProps>;

export const Translation: TranslationModule = (props) => {
    const translation = useSelector(Module.selectors.select(props.children ?? ''));
    const value = props.transform ? props.transform(translation) : translation;
    return <>{value}</>;
};
