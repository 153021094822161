import React from 'react';

export type KeyArrowRightIconProps = {};

export type KeyArrowRightIconComponent = React.FunctionComponent<KeyArrowRightIconProps>;

export const KeyArrowRightIcon: KeyArrowRightIconComponent = React.memo(() => (
    <svg className="key__icon key__icon--fill" width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 22V8l8 6.74z" />
    </svg>
));

export const KeyArrowRightIconInsance = <KeyArrowRightIcon />;
