import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { Folder } from 'App/Modules/Folder';
import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

export enum RecordingActionTypes {
    Load = '@Detail/Recording/Load',
    Reset = '@Detail/Recording/Reset',
}

export type DetailRecordingLoadedActionPayload = DetailRecordingState;

export type DetailRecordingLoadedAction = PayloadedAction<RecordingActionTypes.Load, DetailRecordingLoadedActionPayload>;
export type DetailRecordingResetAction = BaseAction<RecordingActionTypes.Reset>;

export type ReduxAction = DetailRecordingLoadedAction | DetailRecordingResetAction;

export type DetailRecordingState = {
    item: DetailItem | null;
    assetObject: any;
    folders: Folder.List;
};
