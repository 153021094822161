import { FetchPolicy } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useGetRecordingDetailLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/getRecordingDetail.generated';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { BaseDetailPage } from '__SMART_APP_OLD__/components/details/BaseDetailPage';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import NetworkRecording from '__SMART_APP_OLD__/data/NetworkRecording';

import { UI } from 'App/Packages/UI';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

type PropType = {
    id: string;
};

const detailVariables = {
    variables: {
        recordingId: '',
        profileId: '',
        fetchStreamInfo: false,
        firstFolders: 20,
        firstItems: 30,
        lastItems: 15,
        thumbnailHeight: 280,
        backgroundWidth: 1920,
        backgroundHeight: 780,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
        foldersAfterCursor: null,
        itemsAfterCursor: null,
        itemsBeforeCursor: null,
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

const setDetailVariables = (recordingId: string) => {
    detailVariables.variables.profileId = Profile.selectors.selectId(store.getState());
    detailVariables.variables.recordingId = recordingId;
    return detailVariables;
};

export const NetworkRecordingDetailPage: React.FC<PropType> = (props) => {
    const [asset, setAsset] = useState<NetworkRecording | null>(null);
    const [relatedStripeData, setRelatedStripeData] = useState([]);
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [loadedAssetId, setLoadedAssetId] = useState<string>('');

    const [loadRecordingDetail, { data, loading, error }] = useGetRecordingDetailLazyQuery(setDetailVariables(props.id));
    const getRelatedStripe = () =>
        relatedStripeData.map((stripe: any, index) => {
            const { assets } = stripe;

            return (
                <Stripe
                    stripeId={stripe.stripeId}
                    isRelated={true}
                    dataTestId="stripe_related"
                    key={`related-stripe-${index}`}
                    className={`stripe related`}
                    assets={assets}
                    title={stripe.title}
                    sessionType={PinSessionType.PIN}
                />
            );
        });

    useEffect(() => {
        if (loadedAssetId !== undefined && loadedAssetId !== props.id) {
            loadRecordingDetail(setDetailVariables(props.id));
        }
    }, [props.id, loadedAssetId, loadRecordingDetail]);

    useEffect(() => {
        if (data) {
            const parsedData = createAssetObject(data.recording, data.recording?.__typename);
            setAsset(parsedData);
            setRelatedStripeData(parsedData?.relatedContent);
            setLoadingState(false);
            setLoadedAssetId(props.id);
        }
    }, [data, loading, error, props.id]);

    return loadingState ? (
        <Layout backgroundColor={BackgroundColor.PRIMARY}>
            <UI.Spinner />
        </Layout>
    ) : (
        <BaseDetailPage asset={asset} programType={null} getRelatedStripe={getRelatedStripe} sessionType={PinSessionType.PIN} />
    );
};
