import React from 'react';

export type KeyBackspaceIconProps = {};

export type KeyBackspaceIconComponent = React.FunctionComponent<KeyBackspaceIconProps>;

export const KeyBackspaceIcon: KeyBackspaceIconComponent = React.memo(() => (
    <svg className="key__icon key__icon--fill" width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path d="m13.136 9.276 4.56 4.343 4.561-4.343 1.38 1.448L19.146 15l4.49 4.276-1.38 1.448-4.56-4.343-4.56 4.343-1.38-1.448L16.247 15l-4.49-4.276z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.044 5a1.2 1.2 0 0 0-.947.463l-6.844 8.8a1.2 1.2 0 0 0 0 1.474l6.844 8.8a1.2 1.2 0 0 0 .947.463h18.703a1.2 1.2 0 0 0 1.2-1.2V6.2a1.2 1.2 0 0 0-1.2-1.2zm-5.83 10 6.222-8h17.51v16H9.437z"
        />
    </svg>
));

export const KeyBackspaceIconInsance = <KeyBackspaceIcon />;
