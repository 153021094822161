import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { EventFolderItemFragmentDoc } from '../event/eventFolderItem.generated';
export type RecordingFolderItemFragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, event: { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null } };

export const RecordingFolderItemFragmentDoc = gql`
    fragment recordingFolderItem on NetworkRecording {
  id
  status
  event {
    ...eventFolderItem
  }
  __typename
}
    ${EventFolderItemFragmentDoc}`;