import { KeyboardCapsLockKey } from 'App/Modules/Keyboard/Components/KeyboardCapsLockKey';
import { KeyboardCharKey } from 'App/Modules/Keyboard/Components/KeyboardCharKey';
import { KeyboardLayoutKey } from 'App/Modules/Keyboard/Components/KeyboardLayoutKey';
import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { KeyboardKeyElement } from 'App/Modules/Keyboard/Element/KeyboardKeyElement';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React from 'react';

export type KeyboardKeyProps = {
    keyboard: KeyboardElement;
    element: KeyboardKeyElement;
};

export type KeyboardKeyComponent = React.FunctionComponent<KeyboardKeyProps>;

export const KeyboardKey: React.FunctionComponent<KeyboardKeyProps> = (props) => {
    const { element, keyboard } = props;
    const focused = Subscribable.use(element.focused);
    const { size } = element.schema;
    const variant: UI.KeyVariant | undefined = focused ? 'focused' : element.schema.variant;
    const keyProps: UI.KeyProps = { variant, size, onClick: () => keyboard.click(element), onHover: () => keyboard.hover(element) };
    if (element.schema.type === 'key.arrow.left') return <UI.Keyboard.Key.ArrowLeft {...keyProps} />;
    if (element.schema.type === 'key.arrow.right') return <UI.Keyboard.Key.ArrowRight {...keyProps} />;
    if (element.schema.type === 'key.space') return <UI.Keyboard.Key.Space {...keyProps} />;
    if (element.schema.type === 'key.enter') return <UI.Keyboard.Key.Enter {...keyProps} />;
    if (element.schema.type === 'key.backspace') return <UI.Keyboard.Key.Backspace {...keyProps} />;
    if (element.schema.type === 'key.language') return <UI.Keyboard.Key.Language {...keyProps} />;
    if (element.schema.type === 'key.capslock') return <KeyboardCapsLockKey keyboard={keyboard} {...keyProps} />;
    if (element.schema.type === 'key.layout') return <KeyboardLayoutKey keyboard={keyboard} {...keyProps} />;
    if (element.schema.type === 'key.char') return <KeyboardCharKey keyboard={keyboard} element={element} {...keyProps} />;
    return (
        <UI.Keyboard.Key {...keyProps}>
            <UI.Keyboard.Key.Label variant={element.schema.label?.variant} size={element.schema.label?.size}>
                {element.schema.value}
            </UI.Keyboard.Key.Label>
        </UI.Keyboard.Key>
    );
};
