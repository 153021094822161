import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ParentalRatingInfoFragment = { __typename?: 'ParentalRating', id: string, title: string, rank: number, adult: boolean };

export const ParentalRatingInfoFragmentDoc = gql`
    fragment parentalRatingInfo on ParentalRating {
  id
  title
  rank
  adult
}
    `;