import React, { useEffect, useRef } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { DEFAULT_PIN_INPUT_CHAR, SELECTED_PIN_INPUT_CHAR } from '__SMART_APP_OLD__/utils/Constants';

import { Input, InputElement } from 'App/Modules/Input';
import { Key } from 'App/Modules/Key';

let nodeRef: any = null;

const PinInput: React.FC<{
    onKey: (keycode: number) => void;
    focusedIndex: number;
    pin: any;
    isKeyboardOpen: boolean;
    resetInputStates: () => void;
    updateInputState: (value: number) => void;
    updateFocusedIndex: (value: number) => void;
}> = ({ onKey, focusedIndex = 0, pin = '', isKeyboardOpen = false, resetInputStates, updateInputState, updateFocusedIndex }) => {
    const input = useRef<InputElement>(null);
    const pastValue: any = useRef<string>('');

    useEffect(() => {
        if (nodeRef) {
            Focus.focus(nodeRef.nodeRef);
        }
    });

    const updatePin = (target: InputElement, onKeyHandler: (keycode: number) => void) => {
        const value = target.value.get();
        // eslint-disable-next-line no-unsafe-optional-chaining
        const keyCode = Number.parseInt(value[value?.length - 1], 10);
        if (Number.isNaN(keyCode)) return;
        onKeyHandler(keyCode + 48); // ascii code
    };

    const shadowInputChangeHandler = (target: InputElement) => {
        const value = target.value.get();
        if (value?.length >= pastValue.current?.length || !pastValue.current) {
            updatePin(target, onKey);
        }

        if (pastValue.current?.length > value?.length && value?.length !== 0) {
            updateInputState(-1);
        }

        if (pastValue.current?.length > 0 && value?.length === 0) {
            resetInputStates();
            pastValue.current = '';
            return;
        }

        pastValue.current = value;
    };

    /**
     * Focusable keeps reference to the first function that is passed to onKey
     * and onInputKey creates closure with isKeyboardOpen
     * so when isKeyboardOpen changes onInputKey is recreated
     * but the onKey handler of Focusable is not changed -> keeping referece to the
     * first onInputKey with the initial value of isKeyboardOpen
     * so to handle this we use Ref because the reference to isKeyboardOpenRef does not change
     * and when the value inside isKeyboardOpenRef changes the reference to onInputKey stored
     * in Focusable can get the updated value
     */
    const isKeyboardOpenRef = useRef(isKeyboardOpen);
    const onInputKey = (keycode: number) => {
        if (keycode === Key.VK_BACK) {
            return;
        }

        if (keycode === Key.VK_ENTER) {
            input.current?.focus();
        }

        // For samsung 2018 if keyboard is opened and user click key on remote control
        // we have 2 events: from focusable and from shadow input. Need to skip one
        if (!isKeyboardOpenRef.current) {
            onKey(keycode);
            return;
        }

        if (keycode === Key.VK_LEFT) {
            updateFocusedIndex(-1);
            return;
        }
        if (keycode === Key.VK_RIGHT) {
            updateFocusedIndex(1);
        }
    };
    useEffect(() => {
        isKeyboardOpenRef.current = isKeyboardOpen;
        if (input?.current && isKeyboardOpen) {
            input.current.focus();
        } else if (!isKeyboardOpen) {
            input.current?.blur();
        }
    }, [isKeyboardOpen]);

    return (
        <Container className={`pin-entry-overlay-input ${isKeyboardOpen ? 'move-up' : ''}`} stashId="pin-input">
            {[1, 2, 3, 4].map((item, index) => (
                <Focusable
                    className="pin-input"
                    onKey={onInputKey}
                    key={`pin-input-item-${index}`}
                    focusDelay={false}
                    ref={(ref) => {
                        nodeRef = index === focusedIndex ? ref : nodeRef;
                    }}
                    stashId="pin-input"
                >
                    <Text
                        className={pin[index] !== undefined ? 'pin-cell selected' : 'pin-cell'}
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        theme={Theme.Type.Dark}
                    >
                        {pin[index] !== undefined ? SELECTED_PIN_INPUT_CHAR : DEFAULT_PIN_INPUT_CHAR}
                    </Text>
                </Focusable>
            ))}

            <Input
                id="shadow-input"
                className="shadow-input"
                ref={input}
                onChange={shadowInputChangeHandler}
                onFocus={() => {
                    isKeyboardOpenRef.current = true;
                }}
                onBlur={() => {
                    isKeyboardOpenRef.current = false;
                }}
            />
        </Container>
    );
};

export default PinInput;
