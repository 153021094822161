import { API } from '__SMART_APP_OLD__/app/api';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    ChannelAudio,
    ChannelAudioChangeAction,
    ChannelBlockedChangeAction,
    ChannelIdLiveChangeAction,
    ChannelLoadManyAction,
    ChannelReplayPermissionChangeAction,
    ChannelReplayPermissionRelationEntityTable,
    ChannelSubtitle,
    ChannelSubtitleChangeAction,
    ChannelSwitchIdChangeAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';
import { eventLoadMany } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/actions';
import { selectEPGLimited } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { Utils } from '__SMART_APP_OLD__/app/utils';

import { Cursor } from 'App/Packages/Cursor';

export const channelLiveIdChange = (liveId: GQL.ChannelID): ChannelIdLiveChangeAction => ({
    type: ActionType.CHANNEL_LIVE_ID_CHANGE,
    payload: { liveId },
});

export const channelSwitchIdChange = (channelSwitchId: GQL.ChannelID): ChannelSwitchIdChangeAction => ({
    type: ActionType.CHANNEL_SWITCH_ID_CHANGE,
    payload: { channelSwitchId },
});

export const channelLoadMany = (channels: GQL.Channel[]): ChannelLoadManyAction => ({
    type: ActionType.CHANNEL_LOAD_MANY,
    payload: { channels },
});

export const channelAudioChange = (channelId: GQL.ChannelID, audio: ChannelAudio): ChannelAudioChangeAction => ({
    type: ActionType.CHANNEL_AUDIO_CHANGE,
    payload: { channelId, audio },
});

export const channelSubtitleChange = (channelId: GQL.ChannelID, subtitle: ChannelSubtitle): ChannelSubtitleChangeAction => ({
    type: ActionType.CHANNEL_SUBTITLE_CHANGE,
    payload: { channelId, subtitle },
});

export const channelBlockedChange = (channelId: GQL.ChannelID, blocked: boolean): ChannelBlockedChangeAction => ({
    type: ActionType.CHANNEL_BLOCKED_CHANGE,
    payload: { channelId, blocked },
});

export const channelReplayPermissionChange = (
    channels: ChannelReplayPermissionRelationEntityTable
): ChannelReplayPermissionChangeAction => ({
    type: ActionType.CHANNEL_REPLAY_PERMISSION_CHANGE,
    payload: { channels },
});

export const channelLiveChange =
    (channelId: GQL.ChannelID): Thunk<void> =>
    (dispatch, getState) => {
        dispatch(channelLiveIdChange(channelId));
        const liveId = selectChannelLiveId(getState());
        Storage.set(Storage.Key.LIVE_CHANNEL_ID, liveId);
    };

export const channelLoad =
    (loadTotalCountResponse?: API.Channel.LoadTotalCountResponse): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const profileId = Profile.selectors.selectId(state);
            const config = selectConfig(state);
            const totalCountResponse = loadTotalCountResponse ?? (await API.Channel.loadTotalCount());
            const batches = Cursor.batch(0, totalCountResponse.totalCount);
            const responses = batches.map((batch) =>
                API.Channel.loadMany({
                    profileId,
                    channelPagination: batch,
                    channelBackgroundHeight: config.image.background.landscape,
                    channelBackgroundWidth: config.image.background.width,
                    channelLogoHeight: config.image.logo.large.height,
                    channelLogoWidth: config.image.logo.large.width,
                })
            );
            const channels = (await Utils.Promise.allFulfilled(responses)).reduce<GQL.Channel[]>(
                (acc, res) => [...acc, ...res.channels],
                []
            );
            dispatch(channelLoadMany(channels));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.CHANNEL_LOAD, error));
            return false;
        }
    };

export const channelEventLoad =
    (channelId: GQL.ChannelID, startTime: number, endTime: number): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const [start, end] = selectEPGLimited(startTime, endTime)(state);
            const duration = Math.max(Math.floor((end - start) / Time.SECOND_MS), Time.MINUTE_S * 10);
            const response = await API.Channel.loadManyEvent({
                channelId,
                start: new Date(start),
                duration,
                backgroundHeight: config.image.background.landscape,
                backgroundWidth: config.image.background.width,
                thumbnailHeight: config.image.thumbnail.small.height,
            });
            dispatch(eventLoadMany({ events: { [channelId]: response.events }, start, end }));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.CHANNEL_EVENT_LOAD, error));
            return false;
        }
    };

export const loadEventsForChannel =
    (channelId: GQL.ChannelID, start: number, end?: number): Thunk<Promise<boolean>> =>
    async (dispatch) =>
        dispatch(channelEventLoad(channelId, start, end ?? start));

export const loadLiveEventForChannel =
    (channelId: GQL.ChannelID): Thunk<Promise<boolean>> =>
    (dispatch) =>
        dispatch(loadEventsForChannel(channelId, Date.now()));

export const updateReplayPermission =
    (channels: ChannelReplayPermissionRelationEntityTable): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        const mappedChannels = Object.keys(channels).map((key) => ({ channelId: key, enabled: !!channels[key] }));

        const response = await API.Channel.updateChannelsReplayPermission(mappedChannels);

        dispatch(channelReplayPermissionChange(channels));

        return response;
    };
