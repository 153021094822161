import React from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { Scroll } from 'App/Packages/Scroll';

const Default: FolderListValue = {
    ref: { current: null },
    ctx: Constants.Ctx,
    offsetTop: 0,
    ids: new Subscribable.MockedArray<string>([]),
    focused: new Subscribable.MockedValue<string>(''),
    disabled: new Subscribable.MockedValue<boolean>(false),
    scroll: {} as Scroll,
    focus: () => {},
};

export type FolderListValue = {
    ref: React.MutableRefObject<HTMLDivElement | null>;
    ctx: string;
    offsetTop: number;
    ids: Subscribable.Array<string>;
    focused: Subscribable.Value<string>;
    disabled: Subscribable.Value<boolean>;
    scroll: Scroll;
    focus: (id: string) => void;
    enter?: (id: string) => void;
};

export type FolderListContextModule = React.Context<FolderListValue> & { Default: FolderListValue };

export const FolderListContext: FolderListContextModule = Object.assign(React.createContext(Default), { Default });
