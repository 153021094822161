import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Translation } from 'App/Modules/Translation';

export const SettingsMyRecordingsNoData = () => (
    <Settings.Item id="no-data" ctx="" isDisabled={true}>
        <Text typeface={Typeface.SANS} size={FontSize.BODY_1} weight={FontWeight.REGULAR} color={Color.PRIMARY}>
            {Translation.Key.Screen.Settings.Options.MyRecordings.NoData}
        </Text>
    </Settings.Item>
);
