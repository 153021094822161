import { EducationScreenCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/EducationScreen/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === EducationScreenCTX.BUTTON && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.EDUCATION, EducationScreenCTX.LIST));
    }

    if (ctx === EducationScreenCTX.LIST && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.EDUCATION, EducationScreenCTX.BUTTON));
    }

    return undefined;
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.EDUCATION, EducationScreenCTX.BUTTON));
};

export const actions = {
    public: {},
    private: {},
    lifecycle: {
        outOfBounds,
        mount,
    },
} as const;
