import { Data } from 'App/Modules/Data';
import { KeyboardKey } from 'App/Modules/Keyboard/Components/KeyboardKey';
import { KeyboardSuggestionList } from 'App/Modules/Keyboard/Components/KeyboardSuggestionList';
import { Constants } from 'App/Modules/Keyboard/Constants';
import { useKeyboard } from 'App/Modules/Keyboard/Hooks/useKeyboard';
import { actions } from 'App/Modules/Keyboard/Store/actions';
import { Focus } from 'App/Packages/Focus';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import React, { useEffect } from 'react';

const options = {};

export const Widget: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const suggestions = useSelector(Data.Search.Suggestion.selectors.select);
    const keyboard = useKeyboard();
    const open = Subscribable.use(keyboard.isOpen);
    const state = open ? 'opened' : 'closed';
    const layout = Subscribable.use(keyboard.layout);

    useEffect(
        () => keyboard.isOpen.subscribe(() => dispatch(keyboard.isOpen.get() ? actions.private.opened : actions.private.closed)),
        [dispatch, keyboard]
    );

    useEffect(() => keyboard.suggest(suggestions), [suggestions, keyboard]);

    Focus.useKeyDown(keyboard.keydown, options);

    return (
        <UI.Keyboard state={state} zIndex={Constants.zIndex}>
            <KeyboardSuggestionList keyboard={keyboard} />
            <UI.Keyboard.Body>
                {layout.keys.map((row, i) => (
                    <UI.Keyboard.Row key={layout.schema.name + layout.schema.language + i}>
                        {row.map((key, j) => (
                            <KeyboardKey key={j} keyboard={keyboard} element={key} />
                        ))}
                    </UI.Keyboard.Row>
                ))}
            </UI.Keyboard.Body>
        </UI.Keyboard>
    );
};
