import { Subscribable } from 'App/Packages/Subscribable';
import { DetailAction as DetailActionModule } from 'App/Packages/UI/Detail/Modules/DetailAction';
import { Button, ButtonAnimation, ButtonCornersStyle, ButtonIconFlavour, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Typeface } from '__SMART_APP_OLD__/app/components/Text';
import React from 'react';

const getClassName = (props: DetailActionInternalProps): string => {
    const classList = ['detail__action'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

type DetailActionInternalProps = {
    className?: string;
    icon?: IconName | null;
    iconFlavour?: ButtonIconFlavour | null;
    width?: ButtonWidth | null;
    typeface?: Typeface | null;
    animation?: ButtonAnimation | null;
    isPrimary?: boolean;
    isTransparent?: boolean;
    children?: React.ReactNode | string;
    corners?: ButtonCornersStyle | null;
    disableTransition?: boolean;
};

type DetailActionInternalComponent = React.FunctionComponent<DetailActionInternalProps>;

const DetailActionInternal: DetailActionInternalComponent = (props) => {
    const action = DetailActionModule.use();
    const { width, typeface, corners, ...rest } = props;

    const isFocused = Subscribable.use(action.focused);

    return (
        <Button
            width={width ?? ButtonWidth.FIT}
            typeface={typeface ?? Typeface.SANS}
            corners={corners ?? ButtonCornersStyle.ROUNDED}
            {...rest}
            id={action.id}
            ctx={action.ctx}
            className={getClassName(props)}
            isFocused={isFocused}
            onEnter={action.enter}
            onHover={action.focus}
        >
            {props.children}
        </Button>
    );
};

export type DetailActionProps = DetailActionInternalProps & {
    id: string;
    onFocus?: (id: string) => void;
    onEnter?: (id: string) => void;
};

export type DetailActionComponent = React.FunctionComponent<DetailActionProps>;
export const DetailAction: DetailActionComponent = (props) => {
    const { id, onEnter, ...rest } = props;
    return (
        <DetailActionModule id={id} onEnter={onEnter}>
            <DetailActionInternal {...rest} />
        </DetailActionModule>
    );
};
