import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type FavouritableItem_Event_Fragment = { __typename: 'Event', id: string, expiry: any };

export type FavouritableItem_NetworkRecording_Fragment = { __typename: 'NetworkRecording', id: string, expiry: any };

export type FavouritableItem_VodAsset_Fragment = { __typename: 'VODAsset', id: string, expiry: any };

export type FavouritableItem_VodProduct_Fragment = { __typename: 'VODProduct', id: string, expiry: any };

export type FavouritableItemFragment = FavouritableItem_Event_Fragment | FavouritableItem_NetworkRecording_Fragment | FavouritableItem_VodAsset_Fragment | FavouritableItem_VodProduct_Fragment;

export const FavouritableItemFragmentDoc = gql`
    fragment favouritableItem on FavouritableItem {
  ... on VODAsset {
    id
    expiry
    __typename
  }
  ... on Recording {
    ... on NetworkRecording {
      id
      expiry
      __typename
    }
    __typename
  }
  ... on Event {
    id
    expiry
    __typename
  }
  ... on VODProduct {
    id
    expiry
    __typename
  }
}
    `;