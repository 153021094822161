import { initialTVGuideState } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/constants';
import { TVGuideState } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const tvGuideReducer: Reducer<TVGuideState> = (state, action) => {
    if (!state) return initialTVGuideState;

    switch (action.type) {
        case ActionType.TV_GUIDE_FIRST_INDEX_CHANGED:
            if (state.firstIndex === action.payload.index) return state;
            return { ...state, firstIndex: action.payload.index };
        case ActionType.TV_GUIDE_FOCUSED_INDEX_CHANGED:
            if (state.focusedIndex === action.payload.index) return state;
            return { ...state, focusedIndex: action.payload.index };
        case ActionType.TV_GUIDE_START_CHANGED:
            if (state.start === action.payload.time) return state;
            return { ...state, start: action.payload.time };
        case ActionType.TV_GUIDE_TIME_CHANGED:
            if (state.time === action.payload.time) return state;
            return { ...state, time: action.payload.time };
        default:
            return state;
    }
};
