import {
    Key,
    KeyArrowLeftComponent,
    KeyArrowLeftIconComponent,
    KeyArrowLeftIconProps,
    KeyArrowLeftProps,
    KeyArrowRightComponent,
    KeyArrowRightIconComponent,
    KeyArrowRightIconProps,
    KeyArrowRightProps,
    KeyBackspaceComponent,
    KeyBackspaceIconComponent,
    KeyBackspaceIconProps,
    KeyBackspaceProps,
    KeyCapsLockComponent,
    KeyCapsLockIconComponent,
    KeyCapsLockIconProps,
    KeyCapsLockProps,
    KeyComponent,
    KeyEnterComponent,
    KeyEnterIconComponent,
    KeyEnterIconProps,
    KeyEnterProps,
    KeyLabelComponent,
    KeyLabelProps,
    KeyLabelSize,
    KeyLabelVariant,
    KeyLanguageComponent,
    KeyLanguageIconComponent,
    KeyLanguageIconProps,
    KeyLanguageProps,
    KeyProps,
    KeySize,
    KeySpaceComponent,
    KeySpaceIconComponent,
    KeySpaceIconProps,
    KeySpaceProps,
    KeyVariant,
} from 'App/Packages/UI/Keyboard/Components/Key';
import { KeyboardComponent, KeyboardProps, KeyboardState, Keyboard as Root } from 'App/Packages/UI/Keyboard/Components/Keyboard';
import { KeyboardBody, KeyboardBodyComponent, KeyboardBodyProps } from 'App/Packages/UI/Keyboard/Components/KeyboardBody';
import { KeyboardHeader, KeyboardHeaderProps } from 'App/Packages/UI/Keyboard/Components/KeyboardHeader';
import { KeyboardRow, KeyboardRowComponent, KeyboardRowProps } from 'App/Packages/UI/Keyboard/Components/KeyboardRow';
import { KeyboardSuggestion, KeyboardSuggestionProps } from 'App/Packages/UI/Keyboard/Components/KeyboardSuggestion';

export type {
    KeyArrowLeftComponent,
    KeyArrowLeftIconComponent,
    KeyArrowLeftIconProps,
    KeyArrowLeftProps,
    KeyArrowRightComponent,
    KeyArrowRightIconComponent,
    KeyArrowRightIconProps,
    KeyArrowRightProps,
    KeyBackspaceComponent,
    KeyBackspaceIconComponent,
    KeyBackspaceIconProps,
    KeyBackspaceProps,
    KeyCapsLockComponent,
    KeyCapsLockIconComponent,
    KeyCapsLockIconProps,
    KeyCapsLockProps,
    KeyComponent,
    KeyEnterComponent,
    KeyEnterIconComponent,
    KeyEnterIconProps,
    KeyEnterProps,
    KeyLabelComponent,
    KeyLabelProps,
    KeyLabelSize,
    KeyLabelVariant,
    KeyLanguageComponent,
    KeyLanguageIconComponent,
    KeyLanguageIconProps,
    KeyLanguageProps,
    KeyProps,
    KeySize,
    KeySpaceComponent,
    KeySpaceIconComponent,
    KeySpaceIconProps,
    KeySpaceProps,
    KeyVariant,
    KeyboardBodyComponent,
    KeyboardBodyProps,
    KeyboardComponent,
    KeyboardHeaderProps,
    KeyboardProps,
    KeyboardRowComponent,
    KeyboardRowProps,
    KeyboardState,
    KeyboardSuggestionProps,
};

const Keyboard = Object.assign(Root, {
    Body: KeyboardBody,
    Header: KeyboardHeader,
    Row: KeyboardRow,
    Suggestion: KeyboardSuggestion,
    Key,
});

export { Keyboard };
