import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/actions';
import { DeviceManagementItems } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/DeviceManagementItems';
import { selectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const classNameForThemeType: Record<Theme.Type, string> = {
    DARK: 'device-management--dark-theme',
    LIGHT: 'device-management--light-theme',
};

const getClassName = (theme: Theme.Type) => {
    const classList = ['device-management'];
    classList.push(classNameForThemeType[theme]);
    return classList.join(' ');
};

export const DeviceManagementScreenView: React.FunctionComponent<Screen.Props<Screen.Type.DEVICE_MANAGEMENT>> = () => {
    const theme = useTheme();
    const className = getClassName(theme);
    const dispatch = useDispatch();
    const subtitle = useSelector(selectors.private.selectSubtitle);

    Focus.useBack(() => dispatch(actions.private.back()));

    return (
        <Layout className={className} backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <Text
                className="device-management__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                SETTINGS_DEVICE_MANAGEMENT
            </Text>
            <Text
                className="device-management__subtitle"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
            >
                {subtitle}
            </Text>
            <DeviceManagementItems />
        </Layout>
    );
};
