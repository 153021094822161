import { DetailEventState } from 'App/Modules/Data/Detail/Event/Types';

export const initialEventDetailReducerState: DetailEventState = {
    item: null,
    assetObject: null,
    folders: {
        id: '',
        count: 0,
        ids: [],
        entities: {},
    },
};
