import { useContext } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';

import { MaskContext } from 'App/Packages/UI/Mask/MaskContext';

export const useShouldMask = (): boolean => {
    const { parentalRatingId, pinSessionType } = useContext(MaskContext);
    const shouldMask = useSelector(selectParentalRatingIsRestricted(parentalRatingId, pinSessionType));
    return shouldMask;
};
