import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectParentalRatingTitle } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';

import { Focus } from 'App/Packages/Focus';

const getClassName = (isFocused: boolean) => {
    const classlist = ['age-rating-selection__item'];
    if (isFocused) classlist.push('age-rating-selection__item--focused');
    return classlist.join(' ');
};

export const AgeRatingSelectionScreenItem: React.FunctionComponent<Focus.Focusable<string>> = (props) => {
    const title = useSelector(selectParentalRatingTitle(props.id));
    const { onClick, onHover } = Focus.useFocusable(props);

    return (
        <Div
            className={getClassName(props.isFocused)}
            backgroundColor={props.isFocused ? BackgroundColor.BUTTON_HOT_RED : BackgroundColor.SECONDARY}
            shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
            onClick={onClick}
            onMouseOver={onHover}
        >
            <Text
                className="age-rating-selection__item-name"
                typeface={Typeface.SERIF}
                size={FontSize.BIG_2}
                weight={FontWeight.BOLD}
                color={props.isFocused ? Color.PRIMARY_INVERTED : Color.TERTIARY}
            >
                {title}
            </Text>
        </Div>
    );
};
