import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectSelected = (state: State) => Community.selectors.selectSelectedEntity(state)?.title ?? '';

export const selectors = {
    public: {},
    private: {
        selectSelected,
    },
} as const;
