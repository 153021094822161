import { StartupPosition } from '__SMART_APP_OLD__/app/modules/Startup/types';

export const initialStartupState: StartupPosition = StartupPosition.Home;

export const startupOptionIds = [StartupPosition.Live, StartupPosition.Home];

export const startupOptionLabelForPosition: Record<StartupPosition, string> = {
    [StartupPosition.Live]: 'STARTUP_BEHAVIOR_LIVE_PLAYBACK',
    [StartupPosition.Home]: 'STARTUP_BEHAVIOR_HOME',
};
