import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type HouseholdOnboardingInfoFragment = { __typename?: 'HouseholdOnboardingInfo', masterPincodeStepCompleted?: any | null, communityStepCompleted?: any | null, replayStepCompleted?: any | null };

export const HouseholdOnboardingInfoFragmentDoc = gql`
    fragment householdOnboardingInfo on HouseholdOnboardingInfo {
  masterPincodeStepCompleted
  communityStepCompleted
  replayStepCompleted
}
    `;