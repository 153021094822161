import { SeriesDetailLoadQueryVariables } from 'App/Modules/Data/Detail/Series/Api/seriesDetailLoad.generated';

export const DefaultVariables: SeriesDetailLoadQueryVariables = {
    seriesId: '',
    profileId: '',
    folderFirst: 100,
    folderAfter: null,
    backgroundWidth: 1920,
    backgroundHeight: 780,
    channelLogoHeight: 216,
    channelLogoWidth: 384,
    thumbnailHeight: 280,
};
