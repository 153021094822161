import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { selectCurrentParentalRatingRank } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { useIsLive } from '__SMART_APP_OLD__/common/useIsLive';
import AssetDetails from '__SMART_APP_OLD__/components/common/AssetDetails';
import Description from '__SMART_APP_OLD__/components/common/Description';
import LiveProgressBar from '__SMART_APP_OLD__/components/common/LiveProgressBar';
import StaticProgressBar from '__SMART_APP_OLD__/components/common/StaticProgressBar';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import Asset from '__SMART_APP_OLD__/data/Asset';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { AnyObj } from '__SMART_APP_OLD__/types/CommonTypes';
import { AssetType, StripeType } from '__SMART_APP_OLD__/utils/Constants';
import { maskAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import State from '__SMART_APP_OLD__/utils/State';
import { isFuture } from '__SMART_APP_OLD__/utils/timeUtils';
import translate from 'language/translate';

type AssetData = Asset & AnyObj;
type PropType = {
    asset: AssetData | null;
    isFavouriteNeeded?: boolean;
} & AnyObj;

export const BaseDetailPage: React.FC<PropType> = ({
    selectedAsset,
    asset,
    getRelatedStripe,
    isFavouriteNeeded,
    videoQuality,
    sessionType,
}) => {
    const focusedAsset = selectedAsset || asset;

    const parentalRatingRank = useSelector(selectCurrentParentalRatingRank);
    const bookmark = useSelector(Bookmarks.selectors.selectEntity(focusedAsset?.id));
    const resumeThreshold = useSelector(selectResumeThreshold);

    const [isOutOfRange, setIsOutOfRange] = useState(false);
    const isLive: boolean = useIsLive(focusedAsset?.startTime, focusedAsset?.endTime);

    const ref = useRef<HTMLDivElement | null>(null);

    // In some cases when re-fetching data stripes in response could be different. As the
    // result - focus is lost. To handle this - we simply focus the first tile in the first stripe.
    useEffect(() => {
        if (!asset) {
            return;
        }

        const { relatedContent } = asset;
        const stripeState = State.get('Stripe');

        if (relatedContent.length || !stripeState) {
            return;
        }

        const stripeIds = relatedContent.map(({ stripeId }) => stripeId);
        const hasStripe =
            stripeState.stripeId === StripeType.ASSET_DETAILS || stripeIds.some((stripeId) => stripeState.stripeId === stripeId);

        if (hasStripe) {
            return;
        }

        const firstStripe = document.getElementById(stripeIds[0]);
        const firstTile = firstStripe?.querySelector('.tiles')?.firstChild;

        if (firstTile) {
            Focus.focus(firstTile as any);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (!ref.current) return;
        const rects = ref.current.getBoundingClientRect();
        setIsOutOfRange(rects.bottom > window.innerHeight);
    }, [asset]);

    if (!asset) {
        return null;
    }

    const { castAndCrew, startTime, endTime, assetType, subtitle, progress, id } = focusedAsset;
    const stripeRelated = getRelatedStripe?.();
    const needShortDescription = stripeRelated?.length > 1 || asset?.seasons?.length >= 1;

    maskAsset(asset, parentalRatingRank, sessionType);
    maskAsset(focusedAsset, parentalRatingRank, sessionType);

    return (
        <>
            <AssetDetails
                program={asset}
                focusedAsset={focusedAsset}
                channelId={asset?.channelId}
                metaInfo={focusedAsset?.detailPageMetaInfo || asset?.detailPageMetaInfo}
                subtitle={subtitle}
                hideLiveMarker={!isLive}
                twoRowMeta
                isFavouriteNeeded={isFavouriteNeeded}
                videoQuality={videoQuality}
                sessionType={sessionType}
            >
                <div>
                    {startTime && endTime && !isFuture(startTime) && (
                        <LiveProgressBar startTime={startTime.getTime()} endTime={endTime.getTime()} />
                    )}
                    {!!bookmark && progress < resumeThreshold && assetType !== AssetType.EPISODE && (
                        <StaticProgressBar percentage={progress} />
                    )}
                    <Description
                        key={id}
                        asset={focusedAsset}
                        detailed
                        needMoreInfo={focusedAsset?.isAdult}
                        needShortDescription={needShortDescription}
                    />
                </div>
            </AssetDetails>
            <div className={`relative-info${isOutOfRange ? ' cropped' : ''}`} ref={ref}>
                {stripeRelated}
                {!!castAndCrew?.length && (
                    <Stripe
                        isRelated={true}
                        id="actor-stripe"
                        dataTestId="stripe_cast_crew"
                        key="actor-stripe"
                        className={`stripe related cast-and-crew`}
                        assets={castAndCrew}
                        title={translate('SCREEN_TAB_NAVIGATION_CAST_CREW')}
                    />
                )}
            </div>
        </>
    );
};
