import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { CommunityFragmentDoc } from '../fragments/community.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommunityLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommunityLoadQuery = { __typename?: 'Nexx4Queries', availableCommunities?: { __typename?: 'CommunityCatalog', items: Array<{ __typename?: 'Community', id: string, title: string } | null> } | null, me?: { __typename?: 'User', household: { __typename?: 'Household', community?: { __typename?: 'Community', id: string } | null } } | null };

export type CommunityLoadFragment = { __typename?: 'Nexx4Queries', availableCommunities?: { __typename?: 'CommunityCatalog', items: Array<{ __typename?: 'Community', id: string, title: string } | null> } | null, me?: { __typename?: 'User', household: { __typename?: 'Household', community?: { __typename?: 'Community', id: string } | null } } | null };

export const CommunityLoadFragmentDoc = gql`
    fragment communityLoad on Nexx4Queries {
  availableCommunities {
    items {
      ...community
    }
  }
  me {
    household {
      community {
        id
      }
    }
  }
}
    ${CommunityFragmentDoc}`;
export const CommunityLoadDocument = gql`
    query communityLoad {
  ...communityLoad
}
    ${CommunityLoadFragmentDoc}`;

/**
 * __useCommunityLoadQuery__
 *
 * To run a query within a React component, call `useCommunityLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommunityLoadQuery(baseOptions?: Apollo.QueryHookOptions<CommunityLoadQuery, CommunityLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityLoadQuery, CommunityLoadQueryVariables>(CommunityLoadDocument, options);
      }
export function useCommunityLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityLoadQuery, CommunityLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityLoadQuery, CommunityLoadQueryVariables>(CommunityLoadDocument, options);
        }
export function useCommunityLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommunityLoadQuery, CommunityLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommunityLoadQuery, CommunityLoadQueryVariables>(CommunityLoadDocument, options);
        }
export type CommunityLoadQueryHookResult = ReturnType<typeof useCommunityLoadQuery>;
export type CommunityLoadLazyQueryHookResult = ReturnType<typeof useCommunityLoadLazyQuery>;
export type CommunityLoadSuspenseQueryHookResult = ReturnType<typeof useCommunityLoadSuspenseQuery>;
export type CommunityLoadQueryResult = Apollo.QueryResult<CommunityLoadQuery, CommunityLoadQueryVariables>;