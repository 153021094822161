import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

const getClassName = (props: CardLandscapeLogoProps) => {
    const classlist = ['landscape-card-v3__logo'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeLogoProps = {
    id?: string;
    className?: string;
};

export type CardLandscapeLogoComponent = React.FunctionComponent<CardLandscapeLogoProps>;

export const CardLandscapeLogo: CardLandscapeLogoComponent = (props) => {
    if (!props.id) return null;
    return <ChannelLogo className={getClassName(props)} id={props.id} theme={Theme.Type.Dark} />;
};
