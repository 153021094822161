import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

export enum SeriesActionTypes {
    Load = '@Detail/Series/Load',
    Reset = '@Detail/Series/Reset',
    FolderHeaderSelected = '@Detail/Series/FolderHeaderSelected',
    ItemFocusChanged = '@Detail/Series/ItemFocusChanged',
}

export type ItemIdsForGroupTitle = {
    title: string;
    itemIds: string[];
} | null;

export type GroupingInfos = ItemIdsForGroupTitle[];

export type SeriesDetailLoadedActionPayload = SeriesState;
export type SeriesDetailFolderHeaderSelectedActionPayload = string;
export type SeriesDetailItemFocusChangedActionPayload = string;

export type SeriesDetailLoadedAction = PayloadedAction<SeriesActionTypes.Load, SeriesDetailLoadedActionPayload>;
export type SeriesDetailResetAction = BaseAction<SeriesActionTypes.Reset>;
export type SeriesDetailFolderHeaderSelectedAction = PayloadedAction<
    SeriesActionTypes.FolderHeaderSelected,
    SeriesDetailFolderHeaderSelectedActionPayload
>;
export type SeriesDetailItemFocusChangedAction = PayloadedAction<
    SeriesActionTypes.ItemFocusChanged,
    SeriesDetailItemFocusChangedActionPayload
>;

export type ReduxAction =
    | SeriesDetailLoadedAction
    | SeriesDetailFolderHeaderSelectedAction
    | SeriesDetailItemFocusChangedAction
    | SeriesDetailResetAction;

export type SeriesDetailStateItemsEntities = Record<string, DetailItem>;

export type SeriesDetailStateAssetObjects = Record<string, any>;

export type SeriesDetailStateItems = {
    ids: string[];
    entities: SeriesDetailStateItemsEntities;
    assetObjects: SeriesDetailStateAssetObjects;
};

export type SeriesState = {
    item: DetailItem | null;
    assetObject: any;
    screenBackgroundImageUrl: string;
    groupTitles: string[];
    items: SeriesDetailStateItems | null;
    itemIdsForGroupTitle?: GroupingInfos;
    selectedGroupTitle: string;
};
