import React from 'react';

import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (isLarge?: boolean) => {
    const classlist = ['portrait-card-v3__icons'];
    if (isLarge) classlist.push('portrait-card-v3__icons--large');
    return classlist.join(' ');
};

export type CardPortraitIconsProps = DivPropsExtendable & {};

export type CardPortraitIconsComponent = React.FunctionComponent<CardPortraitIconsProps>;

export const CardPortraitIcons: React.FunctionComponent<CardPortraitIconsProps> = (props) => {
    const card = useCardPortrait();
    return (
        <Mask masked={null}>
            <Div {...props} defaultClassName={getClassName(card.isLarge)}>
                {props.children}
            </Div>
        </Mask>
    );
};
