import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ConsentType } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectIsAccepted = (type: ConsentType) => (state: State): boolean => state.data.consent[type].accepted;

const selectGql = (state: State): GQL.Consent[] =>
    (Object.keys(state.data.consent) as ConsentType[]).map((type) => ({ type, accepted: selectIsAccepted(type)(state) }));

export const consentSelectors = {
    public: {
        selectIsAccepted,
    },
    private: {
        selectGql,
    },
};
