import React from 'react';

import { SettingsSectionContentDivider } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentDivider';
import {
    SettingsSectionContentItem,
    SettingsSectionContentItemModule,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItem';
import {
    SettingsSectionContentList,
    SettingsSectionContentListType,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentList';
import { SettingsSectionContentSeparator } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentSeparator';
import { SettingsSectionContentText } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentText';

type Props = {
    className?: string;
}

export type SettingsSectionContentModule = {
    Text: React.FunctionComponent<React.PropsWithChildren>;
    Item: SettingsSectionContentItemModule;
    Separator: React.FunctionComponent;
    List: SettingsSectionContentListType;
    Divider: React.FunctionComponent;
} & React.FunctionComponent<React.PropsWithChildren<Props>>;

const getClassName = (className?: string) => {
    const classList = ['settings-section__content'];

    if (className) {
        classList.push(className);
    }

    return classList.join(' ');
}

export const SettingsSectionContent: SettingsSectionContentModule = (props) => {
    const className = getClassName(props.className);

    return (
        <div className={className}>
            <div className="settings-section-content">{props.children}</div>
        </div>
    );
};

SettingsSectionContent.Text = SettingsSectionContentText;
SettingsSectionContent.Item = SettingsSectionContentItem;
SettingsSectionContent.Separator = SettingsSectionContentSeparator;
SettingsSectionContent.List = SettingsSectionContentList;
SettingsSectionContent.Divider = SettingsSectionContentDivider;
