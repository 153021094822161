import { ProfileInfo } from '__SMART_APP_OLD__/app/gql/gql';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Translation } from 'App/Modules/Translation';

const selectId = (state: State) => state.data.profile.profileId;
const selectKind = (state: State): string => state.data.profile.profiles[0].kind;
const selectUserId = (state: State) => state.data.profile.userId;
const selectMyLibraryAdultContentVisibility = (state: State): boolean => state.data.profile.myLibraryAdultContentVisibility;
const selectMyLibraryAdultContentVisibilityLabel = (state: State): string =>
    state.data.profile.myLibraryAdultContentVisibility ? Translation.Key.Shown : Translation.Key.Hidden;
const selectShowLiveClock = (state: State): boolean => state.data.profile.showLiveClock;
const selectProfiles = (state: State): ProfileInfo[] => state.data.profile.profiles;
const selectPreferences = (state: State) => state.data.profile.preferences;
const selectDataUsageAllowed = (state: State): boolean => state.data.profile.dataUsageAllowed;
const selectUsername = (state: State): string | null => state.data.profile.userName;

const selectFirstSubtitleLanguage = (state: State): string | null => state.data.profile.preferences?.firstSubtitleLanguage ?? null;
const selectSecondSubtitleLanguage = (state: State): string | null => state.data.profile.preferences?.secondSubtitleLanguage ?? null;

export const profileSelectors = {
    private: {},
    public: {
        selectId,
        selectKind,
        selectUserId,
        selectMyLibraryAdultContentVisibility,
        selectMyLibraryAdultContentVisibilityLabel,
        selectShowLiveClock,
        selectProfiles,
        selectPreferences,
        selectDataUsageAllowed,
        selectUsername,
        selectFirstSubtitleLanguage,
        selectSecondSubtitleLanguage,
    },
};
