import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

const getClassName = (focused?: boolean) => {
    const classList = ['keyboard__suggestion'];
    if (focused) classList.push('keyboard__suggestion--focused');
    return classList.join(' ');
};

export type KeyboardSuggestionProps = DivPropsExtendable & {
    focused?: boolean;
};

export const KeyboardSuggestion: React.FunctionComponent<KeyboardSuggestionProps> = (props) => {
    const { children, focused, ...rest } = props;
    return (
        <Div defaultClassName={getClassName(focused)} {...rest}>
            {children}
        </Div>
    );
};
