import { useCallback, useRef, useState } from 'react';

import { Calc } from 'App/Packages/Calc';
import { NavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';
import { useCtx } from 'App/Packages/Focus/Hooks/useCtx';
import { useHandler, useNavigationHandler } from 'App/Packages/Focus/Hooks/useNavigationHandler';
import { useOnBlur } from 'App/Packages/Focus/Hooks/useOnBlur';
import { Axis } from 'App/Types/Axis';
import { UseListOptions, UseListReturnType } from './Types';

const isOutOfBounds = (event: NavigationEvent, axis: Axis, focused: number, last: number): boolean => {
    if (axis === Axis.Y) return (focused === 0 && event.y === -1) || (focused === last && event.y === 1) || event.x !== 0;
    if (axis === Axis.X) return (focused === 0 && event.x === -1) || (focused === last && event.x === 1) || event.y !== 0;
    return false;
};

export const useList = <T>(options: UseListOptions<T>): UseListReturnType<T> => {
    const ctx = useCtx();
    const [focusedIndex, setFocusedIndex] = useState(options.initialFocusedIndex ?? 0);

    const isFocused = ctx === options.ctx;
    const focused = options.items[focusedIndex];
    const lastIndex = options.items.length - 1;

    const items = useRef(options.items);
    const lastIndexRef = useRef(lastIndex);

    items.current = options.items;
    lastIndexRef.current = lastIndex;

    const focusAt = useCallback((index: number) => setFocusedIndex(Calc.clamp(0, index, lastIndexRef.current)), []);
    const focus = useCallback(
        (item: T) => {
            const index = items.current.indexOf(item);
            if (index === -1) return;
            focusAt(index);
        },
        [focusAt]
    );

    const navigationHandler = useNavigationHandler({
        ctx,
        focused,
        columns: 1,
        lastIndex,
        disableOutOfBounds: options.disableOutOfBounds,
        guard: options.guard,
        interceptor: options.interceptor,
        setFocusedIndex,
        isOutOfBounds: (event) => isOutOfBounds(event, options.axis, focusedIndex, lastIndex),
    });

    const isDisabled = useHandler(navigationHandler, options.disabled || !isFocused);

    useOnBlur(() => setFocusedIndex(options.resetFocusIndex ?? 0), isFocused, options.resetFocusOnBlur ?? false);

    return {
        focused,
        focusedIndex,
        isDisabled,
        focus,
        focusAt,
    };
};
