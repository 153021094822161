import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Screen } from 'App/Modules/Screen';

const selectInitialFocusedIndex = (state: State): number => {
    const ids = Collection.selectors.selectEntityIds(state);
    const screen = Screen.selectors.selectTyped(Screen.Type.COLLECTION)(state);
    if (!screen || !screen.props.focused) return 0;
    const index = ids.indexOf(screen.props.focused);
    if (index === -1) return 0;
    return index;
};

const selectIsLandScapeViewCard = (state: State): boolean | undefined => {
    const screen = Screen.selectors.selectTyped(Screen.Type.COLLECTION)(state);
    return screen?.props.isLandScapeCardView;
};

export const collectionSelectors = {
    private: {
        selectInitialFocusedIndex,
        selectIsLandScapeViewCard,
    },
    public: {
        selectIsLandScapeViewCard,
    },
};
