import { Overlay } from 'App/Modules/Overlay';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectStandByActivated, selectStandByInactivityTimer } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import React, { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

export type StandByServiceProps = {};

export type StandByServiceComponent = React.FunctionComponent<StandByServiceProps>;

const selectCtx = () => Focus.Default;
const outOfBounds: Focus.OutOfBounds = () => () => {};
const setCtx = () => () => {};
const isActive = () => true;

export const StandByService: StandByServiceComponent = () => {
    const standByActivated = useSelector(selectStandByActivated);

    if (!standByActivated) return null;

    return (
        <Focus.Provider ctx={selectCtx} isActive={isActive} outOfBounds={outOfBounds} setCtx={setCtx}>
            <StandByServiceInternal />
        </Focus.Provider>
    );
};

const StandByServiceInternal: StandByServiceComponent = () => {
    const dispatch = useDispatch();
    const inactivityTimer = useSelector(selectStandByInactivityTimer);

    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        const start = () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            const regex = /(home|play)/i;
            if (!regex.test(document.location.pathname)) return;
            timer.current = setTimeout(() => {
                dispatch(Overlay.actions.mount(Overlay.Type.StandBy, {}));
            }, inactivityTimer);
        };
        start();
        const debouncedStart = debounce(start, 2000);
        document.addEventListener('keydown', () => debouncedStart());
        return () => {
            document.removeEventListener('keydown', () => debouncedStart());
            if (timer.current) clearTimeout(timer.current);
        };
    }, [dispatch, inactivityTimer]);

    return null;
};
