import { KeyBackspaceIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/Backspace/KeyBackspaceIcon';
import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import React from 'react';

export type KeyBackspaceProps = KeyProps & {};

export type KeyBackspaceComponent = React.FunctionComponent<KeyBackspaceProps>;

export const KeyBackspace: KeyBackspaceComponent = (props) => {
    const { size = 'x1', variant = 'secondary', children = KeyBackspaceIconInsance, ...other } = props;

    return (
        <Key size={size} variant={variant} {...other}>
            {children}
        </Key>
    );
};
