import { ContentRef } from 'analytics/logging/classes/ContentRef';
import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { generateUuid } from 'analytics/logging/loggingUtils';
import { EventConcepts } from 'analytics/types/concepts';

export enum ReminderEvents {
    // Event generated after a successful planning of a reminder
    PLAN = 'plan',

    // Event generated after a successful change to the planning of a reminder
    PLAN_CHANGE = 'plan change', // can't be implemented

    // Event generated after the successful cancellation of the planning of a reminder.
    // Note that is not the cancel operation after the reminder is fired.
    CANCEL = 'cancel', // in toast

    // Event generated after a successful fire of a reminder.
    FIRE = 'fire', // in scheduler reducer before toast showed on the screen

    // Event generated after a tune action resulting from a fired reminder.
    TUNE = 'tune', // in scheduler when play button on the reminder toast is clicked

    // Event generated after a record action resulting from a fired reminder
    RECORD = 'record', // in toast

    // Event generated after a snooze action resulting from a fired reminder
    SNOOZE = 'snooze', // in toast
}

export class ReminderEvent extends AbstractEvent {
    private readonly concept: EventConcepts.REMINDER = EventConcepts.REMINDER;

    constructor(
        private readonly event: ReminderEvents,
        private readonly contentRef: ContentRef
    ) {
        super(generateUuid());
    }
}
