import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodSeriesCollectionItemSeasonInfosFragmentDoc } from './vodSeriesCollectionItemSeasonInfos.generated';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodSeriesCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type VodSeriesCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type VodSeriesCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type VodSeriesCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type VodSeriesCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type VodSeriesCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type VodSeriesCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type VodSeriesCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type VodSeriesCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type VodSeriesCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type VodSeriesCollectionItem_VodSeries_Fragment = { __typename: 'VODSeries', id: string, title: string, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodSeriesParentalRating: { __typename?: 'ParentalRating', id: string } };

export type VodSeriesCollectionItemFragment = VodSeriesCollectionItem_Banner_Fragment | VodSeriesCollectionItem_Channel_Fragment | VodSeriesCollectionItem_ChannelProduct_Fragment | VodSeriesCollectionItem_Event_Fragment | VodSeriesCollectionItem_NetworkRecording_Fragment | VodSeriesCollectionItem_ProductBundle_Fragment | VodSeriesCollectionItem_Series_Fragment | VodSeriesCollectionItem_VodAsset_Fragment | VodSeriesCollectionItem_VodFolder_Fragment | VodSeriesCollectionItem_VodProduct_Fragment | VodSeriesCollectionItem_VodSeries_Fragment;

export const VodSeriesCollectionItemFragmentDoc = gql`
    fragment vodSeriesCollectionItem on ContentItem {
  ... on VODSeries {
    id
    title
    seasonInfos {
      ...vodSeriesCollectionItemSeasonInfos
    }
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    vodSeriesParentalRating: parentalRating {
      ...parentalRatingRelation
    }
    __typename
  }
}
    ${VodSeriesCollectionItemSeasonInfosFragmentDoc}
${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;