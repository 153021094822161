import { ProductOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { DetailEventState } from 'App/Modules/Data/Detail/Event/Types';

export enum VodAssetActionTypes {
    Load = '@Detail/VodAsset/Load',
    Reset = '@Detail/VodAsset/Reset',
}

export type DetailVodAssetLoadedActionPayload = VodAssetState;

export type DetailVodAssetLoadedAction = PayloadedAction<VodAssetActionTypes.Load, DetailVodAssetLoadedActionPayload>;
export type DetailVodAssetResetAction = BaseAction<VodAssetActionTypes.Reset>;

export type ReduxAction = DetailVodAssetLoadedAction | DetailVodAssetResetAction;

export type VodAssetState = DetailEventState & {
    products: ProductOption[];
};
