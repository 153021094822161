import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialParentalRatingEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/constants';
import { ParentalRatingEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const parentalRatingEntityTableReducer: Reducer<ParentalRatingEntityTable> = (state, action) => {
    if (!state) return initialParentalRatingEntityTableState;

    switch (action.type) {
        case ActionType.PARENTAL_RATING_ID_CHANGE: {
            if (state.selectedId === action.payload.parentalRatingId) return state;
            if (!state.ids.includes(action.payload.parentalRatingId)) return state;
            return { ...state, selectedId: action.payload.parentalRatingId };
        }
        case ActionType.PARENTAL_RATING_LOAD_MANY: {
            if (!action.payload.parentalRatings.length) return state;
            const ids = Mapper.Common.toIds(action.payload.parentalRatings);
            const entities = Mapper.ParentalRating.toEntities(action.payload.parentalRatings);
            return { ids, entities, selectedId: state.selectedId };
        }
        default:
            return state;
    }
};
