/* eslint-disable max-statements */
import { Quota } from '__SMART_APP_OLD__/api/graphql/types';
import { API } from '__SMART_APP_OLD__/app/api';
import { Branding } from '__SMART_APP_OLD__/app/common/branding';
import { loadConfig } from '__SMART_APP_OLD__/app/modules/Config/actions';
import { selectUarConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { channelLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import {
    channelListChange,
    channelListLoadMany,
    loadInitialEventsForEPG,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { eventInitialize } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/actions';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { messageLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { parentalRatingLoadMany } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/actions';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { reminderLoadMany } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { recordingsLoadMany } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import * as LoggingSession from 'analytics/logging/LoggingSession';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { Overlay } from 'App/Modules/Overlay';
import { initializeSessionAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';

export const loadInit: Thunk<Promise<void>> = async (dispatch, getState) => {
    // read user info
    const profileId = Profile.selectors.selectId(getState());
    const dataUsageAllowed = Profile.selectors.selectDataUsageAllowed(getState());
    // start uar if allowed
    if (dataUsageAllowed) {
        LoggingService.getInstance().startLoggingSession();
        console.log('STARTING LOGGING SESSION');
    } else {
        console.log('CLOSING LOGGING SESSION');
        LoggingService.getInstance().closeLoggingSession();
    }
    Player.initialize();

    await dispatch(messageLoad());
    await dispatch(recordingsLoadMany());
    await dispatch(Language.actions.init());
    await dispatch(Bookmarks.actions.load());
    await dispatch(Favourites.actions.load());
    await dispatch(Community.actions.load());

    // load initial data info
    const loadDataResponse = await API.Init.load(profileId);

    await dispatch(parentalRatingLoadMany(loadDataResponse.parentalRatingLoadManyResponse));
    await dispatch(reminderLoadMany(loadDataResponse.reminderLoadManyResponse.reminders));
    await dispatch(
        channelListLoadMany(
            loadDataResponse.channelListLoadManyResponse.channelLists,
            loadDataResponse.channelListLoadManyResponse.channelListChannelCount
        )
    );
    await dispatch(Household.actions.recordingQuotaLoad(loadDataResponse.recordingQuota as Quota));

    await dispatch(channelLoad(loadDataResponse.channelLoadTotalCountResponse));
    await dispatch(eventInitialize());
    await dispatch(channelListChange(loadDataResponse.channelListLoadInitialIdResponse.id, true));
    await dispatch(loadInitialEventsForEPG());
};

export const initializeApp = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    try {
        CustomHistory.replace('/');
        document.body.classList.add(Env.Opco);
        await Platform.READY;
        dispatch(Overlay.actions.mount(Overlay.Type.Splash, {}));
        const config = await dispatch(loadConfig());
        Branding.injectStyleVariables(config?.layout_config ?? {});
        const code = dispatch(Language.actions.initCode());
        await dispatch(Translation.actions.load(code));
        Focus.initialize();
        LoggingService.getInstance().setConfig(selectUarConfig(getState()));
        LoggingSession.setAppStartEventTime(Date.now());
    } catch (error) {
        console.log('[INIT_APP]: ', error);
    }
};

// no need to explicitly try catch here we have it in initializeSessionAction if it happens
// we will silently redirect to login screen
export const startApp = (): Thunk<Promise<void>> => async (dispatch) => dispatch(initializeSessionAction());
