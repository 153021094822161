import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Prompt } from 'App/Modules/Prompt';
import { actions } from 'App/Modules/Prompt/Modules/RecordingConfirmCreateSeriesPrompt/Store/actions';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';
import translate from 'language/translate';

const actionsIds = [
    Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.Record,
    Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.RecordSeries,
];

export const View: React.FunctionComponent<Prompt.Props<Prompt.Type.RecordingConfirmCreate>> = (props) => {
    const dispatch = useDispatch();
    const { eventId } = props;

    return (
        <UI.Alert
            zIndex={Prompt.zIndex}
            onClose={() => {
                dispatch(Prompt.actions.unmount());
            }}
        >
            <UI.Alert.Title>{Translation.Key.Prompt.RecordingConfirmCreate.Title}</UI.Alert.Title>
            <UI.Alert.Text>{Translation.Key.Prompt.RecordingConfirmCreate.Text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action
                    id={Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.Record}
                    onEnter={() => dispatch(actions.private.create(eventId))}
                >
                    {translate(Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.Record)}
                </UI.Alert.Action>
                <UI.Alert.Action
                    id={Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.RecordSeries}
                    onEnter={() => dispatch(actions.private.createSeries(eventId))}
                >
                    {translate(Translation.Key.Prompt.RecordingConfirmCreateSeries.Buttons.RecordSeries)}
                </UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
