import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { DeviceEnablementPolicyFragmentDoc } from './deviceEnablementPolicy.generated';
export type DeviceFragment = { __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> };

export const DeviceFragmentDoc = gql`
    fragment device on Device {
  id
  name
  deviceType
  removable
  removableFrom
  deviceEnablementPolicies {
    ...deviceEnablementPolicy
  }
}
    ${DeviceEnablementPolicyFragmentDoc}`;