import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelLoadTotalCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChannelLoadTotalCountQuery = { __typename?: 'Nexx4Queries', channels?: { __typename?: 'ChannelsConnection', totalCount?: number | null } | null };

export type ChannelLoadTotalCountFragment = { __typename?: 'Nexx4Queries', channels?: { __typename?: 'ChannelsConnection', totalCount?: number | null } | null };

export const ChannelLoadTotalCountFragmentDoc = gql`
    fragment channelLoadTotalCount on Nexx4Queries {
  channels(pagination: {}) {
    totalCount
  }
}
    `;
export const ChannelLoadTotalCountDocument = gql`
    query channelLoadTotalCount {
  ...channelLoadTotalCount
}
    ${ChannelLoadTotalCountFragmentDoc}`;

/**
 * __useChannelLoadTotalCountQuery__
 *
 * To run a query within a React component, call `useChannelLoadTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelLoadTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelLoadTotalCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useChannelLoadTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>(ChannelLoadTotalCountDocument, options);
      }
export function useChannelLoadTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>(ChannelLoadTotalCountDocument, options);
        }
export function useChannelLoadTotalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>(ChannelLoadTotalCountDocument, options);
        }
export type ChannelLoadTotalCountQueryHookResult = ReturnType<typeof useChannelLoadTotalCountQuery>;
export type ChannelLoadTotalCountLazyQueryHookResult = ReturnType<typeof useChannelLoadTotalCountLazyQuery>;
export type ChannelLoadTotalCountSuspenseQueryHookResult = ReturnType<typeof useChannelLoadTotalCountSuspenseQuery>;
export type ChannelLoadTotalCountQueryResult = Apollo.QueryResult<ChannelLoadTotalCountQuery, ChannelLoadTotalCountQueryVariables>;