import { selectPrivacyPolicyShowLegacyGdpr } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { TrackViewingBehaviour } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour';
import {
    ConsentTypeForPrivacyPolicyItemType,
    legacyPrivacyPolicyButtons,
    legacyPrivacyPolicyItems,
    privacyPolicyButtons,
    privacyPolicyItems,
    sloveniaPrivacyPolicyItems,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/constants';
import { PrivacyPolicyButtonType, PrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Env } from 'App/Env';
import { Screen } from 'App/Modules/Screen';

const selectItems = (state: State): PrivacyPolicyItemType[] => {
    if (selectPrivacyPolicyShowLegacyGdpr(state)) return legacyPrivacyPolicyItems;
    if (Env.IsSlovenia) return sloveniaPrivacyPolicyItems;
    return privacyPolicyItems;
};

const selectButtons = (type: Screen.PrivacyPolicyType) => (state: State): PrivacyPolicyButtonType[] => {
    if (selectPrivacyPolicyShowLegacyGdpr(state)) return legacyPrivacyPolicyButtons[type];
    return privacyPolicyButtons[type];
};

const selectIsAccepted = (item: PrivacyPolicyItemType) => (state: State): boolean => {
    if (item === PrivacyPolicyItemType.REQUIRED) return true;
    if (item === PrivacyPolicyItemType.TRACK_VIEWING_BEHAVIOUR) return TrackViewingBehaviour.selectors.select(state);
    return Consent.selectors.selectIsAccepted(ConsentTypeForPrivacyPolicyItemType[item])(state);
};

export const privacyPolicySelectors = {
    private: {
        selectItems,
        selectButtons,
        selectIsAccepted,
    },
};
