import React from 'react';

import { Button, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';

import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { AlertActions } from 'App/Packages/UI/Alert/AlertActions';
import { useAlertOnClose } from 'App/Packages/UI/Alert/AlertContext';

export type AlertActionProps = React.PropsWithChildren<{
    id: string;
    disableDismiss?: boolean;
    onEnter?: (id: string) => void;
}>;

export type AlertActionModule = {} & React.FunctionComponent<React.PropsWithChildren<AlertActionProps>>;

export const AlertAction: AlertActionModule = (props) => {
    const onClose = useAlertOnClose();
    const ctx = AlertActions.useContext();

    if (!onClose) throw new Error('Alert.Action must be used inside Alert');
    if (!ctx) throw new Error('Alert.Action must be used inside Alert.Actions');

    // Conditional rendering with defiend ids
    if (!ctx.ids.includes(props.id)) return null;

    const onEnter = (id: string) => {
        if (props.onEnter) props.onEnter(id);
        else if (ctx.onEnter) ctx.onEnter(id);
        if (props.disableDismiss) return;
        onClose.current();
    };

    return (
        <Button
            id={props.id}
            ctx={FocusDefault}
            className="alert__action"
            isFocused={ctx.focused === props.id}
            corners={ButtonCornersStyle.ROUNDED}
            onEnter={onEnter}
            onHover={ctx.onHover}
        >
            {props.children}
        </Button>
    );
};
