import {
    DetailActions as DetailActionsModule,
    DetailActionsProps as DetailActionsModuleProps,
} from 'App/Packages/UI/Detail/Modules/DetailActions';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailActionsProps = DetailActionsModuleProps & DivPropsExtendable;

export type DetailActionsComponent = React.FunctionComponent<DetailActionsProps>;

export const DetailActions: DetailActionsComponent = (props) => {
    const { children, ctx, onEnter, isFocused, ...rest } = props;
    return (
        <Div defaultClassName="detail__actions" {...rest}>
            <DetailActionsModule ctx={ctx} isFocused={isFocused} onEnter={onEnter}>
                {children}
            </DetailActionsModule>
        </Div>
    );
};
