import React from 'react';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { useHeaderMainShadows } from 'App/Packages/UI/Header/Hooks/useHeaderMainShadows';
import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

const getClassName = (start: boolean, end: boolean): string => {
    const classItems = ['header-v2__main'];
    if (start && end) classItems.push('header-v2__main--inline-shadow');
    else if (start) classItems.push('header-v2__main--inline-start-shadow');
    else if (end) classItems.push('header-v2__main--inline-end-shadow');
    return classItems.join(' ');
};

export type HeaderMainProps = DivPropsExtendable;

export type HeaderMainComponent = React.FunctionComponent<HeaderMainProps>;

export const HeaderMain: HeaderMainComponent = (props) => {
    const headerItems = HeaderItems.use();
    const [start, end] = useHeaderMainShadows();
    return <Div {...props} defaultClassName={getClassName(start, end)} ref={headerItems.ref} />;
};
