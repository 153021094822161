import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import translate from 'language/translate';

import { selectIsSubtitlesEditable, selectSubFontSizes } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Hidable } from 'App/Packages/UI/Hidable';
import { actions } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Actions';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useCtx, useList } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Axis } from 'App/Types/Axis';
import { SubFontSizeOption } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/SubFontSizeOption';
import { Overlay } from 'App/Modules/Overlay';

type Props = {
    fontSize: string;
    fontColor: string;
    backgroundColor: string;
    previewTextFontSize: string;
    playerSetFontSize: (value: string) => void;
};

type SubtitleFontSizesComponent = React.FunctionComponent<Props>;

export const SubtitleFontSizes: SubtitleFontSizesComponent = (props) => {
    const dispatch = useDispatch();
    const { fontSize, previewTextFontSize, backgroundColor, fontColor, playerSetFontSize } = props;
    const isSubtitlesEditable = useSelector(selectIsSubtitlesEditable);
    const subFontSizeOptions = useSelector(selectSubFontSizes);
    const ctx = useCtx();

    const { focus, focused } = useList({
        ctx: Constants.Ctx.fontSizes,
        axis: Axis.X,
        items: subFontSizeOptions.map((option) => option.size),
    });

    const updatePreviewTextOnFocus = (size: string) => {
        const subElement = subFontSizeOptions.find((option) => option.size === size);
        if (!subElement) return undefined;
        dispatch(
            Overlay.actions.update(Overlay.Type.SubtitleMenu, {
                previewTextFontSize: subElement.value,
                previewTextFontColor: fontColor,
                previewTextBackgroundColor: backgroundColor,
                edgeType: 'none',
            })
        );
        return focus(subElement.size);
    };

    const fontSizes = subFontSizeOptions.map((option, index: number) => {
        return (
            <SubFontSizeOption
                key={index}
                size={option.size}
                isFocused={focused === option.size && ctx === Constants.Ctx.fontSizes}
                isActive={option.value === fontSize}
                focus={updatePreviewTextOnFocus}
                onEnter={() => dispatch(actions.private.updateFontSize(option.value, option.size, playerSetFontSize))}
            />
        );
    });

    const currentlySelectedSizeLabel = subFontSizeOptions.find((element) => element.value === fontSize);
    const currentlyHoveredSizeLabel = subFontSizeOptions.find((element) => element.value === previewTextFontSize);
    const selectedLabel = currentlySelectedSizeLabel ? currentlySelectedSizeLabel.name : '';
    const hoveredLabel = currentlyHoveredSizeLabel ? currentlyHoveredSizeLabel.name : '';

    return (
        <Hidable hidden={!subFontSizeOptions.length}>
            <div className="font-sizes-container">
                <Text
                    className={`font-sizes-title ${!isSubtitlesEditable ? 'padLock' : ''}`}
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_2}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {`${translate('SUB_SIZE_LABEL')}: ${translate(hoveredLabel || selectedLabel)}`}
                </Text>
                <div className="font-size-options">{fontSizes}</div>
            </div>
        </Hidable>
    );
};
