import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

export type Props = {
    selector: Selector<string>;
};

export const SettingsAboutAppItemWithSelector: React.FC<Props> = ({ selector }) => <>{useSelector(selector)}</>;
