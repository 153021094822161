import { ContentFolderContentItemsEdge, ContentFolderKind, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import LandscapeCard from '__SMART_APP_OLD__/data/LandscapeCard';
import ProgramEvent from '__SMART_APP_OLD__/data/ProgramEvent';
import TVSeason from '__SMART_APP_OLD__/data/TVSeason';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

export default class Series extends LandscapeCard {
    assetType: string = AssetType.SERIES;

    seasons: Array<Series> = [];

    episodes: Array<ProgramEvent> = [];

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);

        this.rawData = data;

        if (this.rawData?.content?.items?.edges?.length && this.rawData?.content?.items?.edges?.[0].id) {
            this.seasons = this.rawData?.groupingInfos?.map(
                (season: Series) => new TVSeason(season, this?.rawData?.content?.items?.edges, factoryFunc)
            );
            this.episodes = this.rawData?.content?.items?.edges.map((episode: ContentFolderContentItemsEdge) =>
                factoryFunc(episode?.node, episode?.node?.__typename)
            );
        }
    }

    get groupingInfosLength() {
        return this.rawData?.groupingInfos?.length;
    }

    get shortMetaInfo() {
        const metaInfo = [];
        // check for metadata
        if (this.groupingInfosLength) {
            metaInfo.push(
                `${this.groupingInfosLength} ${
                    this.groupingInfosLength === 1 ? translate('SCREEN_CARDS_SEASON') : translate('SCREEN_CARDS_SEASONS')
                }`
            );
        } else if (this.seasonNumbers) {
            metaInfo.push(
                `${this.seasonNumbers} ${this.seasonNumbers === 1 ? translate('SCREEN_CARDS_SEASON') : translate('SCREEN_CARDS_SEASONS')}`
            );
        } else if (this.numberOfEpisodes) {
            metaInfo.push(
                `${this.numberOfEpisodes} ${
                    this.numberOfEpisodes === 1 ? translate('SCREEN_CARDS_EPISODE') : translate('SCREEN_CARDS_EPISODES')
                }`
            );
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get relatedMetaInfo() {
        return this.shortMetaInfo;
    }

    get isRecordable() {
        return this.rawData?.channel?.eventEntitlements?.networkRecording;
    }

    get detailPageMetaInfo() {
        return this.genres;
    }

    get stripeId() {
        return this?.rawData?.content?.id;
    }

    get numberOfEpisodes() {
        return this?.rawData?.episodeCount;
    }

    get status(): RecordingStatus {
        return this.rawData?.content?.items?.edges?.[0]?.node?.status;
    }

    get isRecording(): boolean {
        return this.rawData?.content?.kind === ContentFolderKind.Recordings;
    }
}
