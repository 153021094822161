import { translationActions } from '__SMART_APP_OLD__/app/modules/Translation/actions';
import { getInitialTranslationState } from '__SMART_APP_OLD__/app/modules/Translation/getInitialState';
import { translationReducer } from '__SMART_APP_OLD__/app/modules/Translation/reducer';
import { translationSelectors } from '__SMART_APP_OLD__/app/modules/Translation/selectors';
import { TranslationActionType, TranslationEntityTable, TranslationReduxAction } from '__SMART_APP_OLD__/app/modules/Translation/types';

export { TranslationActionType as ActionType };

export type { TranslationEntityTable as EntityTable, TranslationReduxAction as ReduxAction };

export const getInitialState = getInitialTranslationState;

export const actions = translationActions.public;

export const selectors = translationSelectors.public;

export const reducer = translationReducer;
