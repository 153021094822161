import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageInboxActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxActions';
import { MessageInboxBackButton } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxBackButton';
import { selectMessageInboxHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';

export const MessageInboxHeader: React.FunctionComponent = () => {
    const header = useSelector(selectMessageInboxHeader);
    return (
        <div className="message-inbox__header">
            <MessageInboxBackButton />
            <Text
                className="message-inbox__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_3}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {header}
            </Text>
            <div className="message-inbox__actions">
                <MessageInboxActions />
            </div>
        </div>
    );
};
