import { consentActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/actions';
import { getInitialConsentState } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/getInitialState';
import { consentReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/reducer';
import { consentSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/selectors';
import {
    ConsentActionType,
    ConsentEntity,
    ConsentEntityTable,
    ConsentReduxAction,
    ConsentType,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/types';

export type { ConsentEntity as Entity, ConsentEntityTable as EntityTable, ConsentReduxAction as ReduxAction };

export { ConsentActionType as ActionType, ConsentType as Type };

export const getInitialState = getInitialConsentState;

export const actions = consentActions.public;

export const selectors = consentSelectors.public;

export const reducer = consentReducer;
