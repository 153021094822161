import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ChangeProfilePreferencesDocument,
    ChangeProfilePreferencesMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/changeProfilePreferences.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    ConsentsLoadDocument,
    ConsentsLoadFragment,
    ConsentsLoadQueryResult,
    ConsentsLoadQueryVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/api/query/consentsLoad.generated';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadResponse = {
    consents: GQL.Consent[];
};

export const __parseLoad = (response?: ConsentsLoadFragment): LoadResponse => {
    const consents = (response?.me?.device.activeProfile.preferences.consents ?? []).filter((con): con is GQL.Consent => !!con);
    return { consents };
};

export const load = async (): Promise<LoadResponse> => {
    const variables: ConsentsLoadQueryVariables = {};
    const options = { fetchPolicy: 'no-cache' };
    const resonse: ConsentsLoadQueryResult = await GraphqlClient.makeGraphqlRequest(ConsentsLoadDocument, variables, options);
    Error.collect(resonse);
    return __parseLoad(resonse.data);
};

export const save = async (profileId: string, consents: GQL.Consent[]) => {
    const variables: ChangeProfilePreferencesMutationVariables = { input: { profileId, consents } };
    const response = await GraphqlClient.makeGraphqlMutationRequest(ChangeProfilePreferencesDocument, variables);
    Error.collect(response);
};
