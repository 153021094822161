import { HistoryEntity } from '__SMART_APP_OLD__/app/modules/History/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const select = (state: State) => state.history;

const selectPopEntity = (state: State): HistoryEntity | null => state.history[0] ?? null;

export const historySelectors = {
    public: {
        select,
    },
    private: {
        selectPopEntity,
    },
};
