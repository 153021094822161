import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ReminderCreateDocument,
    ReminderCreateFragment,
    ReminderCreateMutationResult,
    ReminderCreateMutationVariables,
} from '__SMART_APP_OLD__/app/api/reminder/mutation/reminderCreate.generated';
import {
    ReminderRemoveDocument,
    ReminderRemoveMutationResult,
    ReminderRemoveMutationVariables,
} from '__SMART_APP_OLD__/app/api/reminder/mutation/reminderRemove.generated';
import {
    ReminderLoadManyDocument,
    ReminderLoadManyFragment,
    ReminderLoadManyQueryResult,
    ReminderLoadManyQueryVariables,
} from '__SMART_APP_OLD__/app/api/reminder/query/reminderLoadMany.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

/**
 * @namespace API.Reminder
 */

export type CreateResponse = { reminder: GQL.Reminder };

/**
 * @memberof API.Reminder
 * @description Function that parses GQL Response to CreateResponse
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param [response] response from graphql
 * @returns parsed info after create reminder request
 */
export const __parseCreate = (response?: ReminderCreateFragment | null): CreateResponse => {
    const reminder = (response?.setReminder?.reminder ?? null) as GQL.Reminder;
    return { reminder };
};

/**
 * @memberof API.Reminder
 * @description Creates reminder for event with an Id that matches the provided eventId
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @param eventId Id of the tv event (should be in the future)
 * @returns raw info after create reminder request
 */
export const create = async (eventId: GQL.EventID): Promise<CreateResponse> => {
    const variables: ReminderCreateMutationVariables = { reminderCreateInput: { eventId } };
    const response: ReminderCreateMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ReminderCreateDocument, variables);
    Error.collect(response);

    return __parseCreate(response.data);
};

export type LoadManyResponse = { reminders: GQL.Reminder[] };

/**
 * @memberof API.Reminder
 * @description Function that parses GQL Response to LoadManyResponse
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param [response] response from graphql
 * @returns parsed list of reminders from Be
 */
export const __parseLoadMany = (response?: ReminderLoadManyFragment): LoadManyResponse => {
    if (!response) return { reminders: [] };
    const reminders = (response?.reminders?.items ?? []).filter(Boolean) as GQL.Reminder[];
    return { reminders };
};

/**
 * @memberof API.Reminder
 * @description Loads reminders for the requested time window
 * @author SmartTVBG@a1.bg
 * @param profileId payload for the request
 * @date 10/01/2023
 * @returns raw info after create reminder request
 */
export const loadMany = async (profileId: GQL.ProfileID): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: ReminderLoadManyQueryVariables = { profileId };
    const response: ReminderLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(ReminderLoadManyDocument, variables, options);
    Error.collect(response);

    return __parseLoadMany(response.data);
};

/**
 * @memberof API.Reminder
 * @description Deletes a reminder associated with an event
 * with an ID that matches the provided eventId
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @param eventId id of the event we want to remove the reminder for
 * @returns result of delete reminder request
 */
export const remove = async (eventId: GQL.EventID): Promise<void> => {
    const variables: ReminderRemoveMutationVariables = { reminderRemoveInput: { eventId } };
    const response: ReminderRemoveMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ReminderRemoveDocument, variables);
    Error.collect(response);
};
