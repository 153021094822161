import { cn } from 'App/Packages/Cn';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type SuggestionSize = 'small' | 'large';

export type SuggestionFont = 'sans' | 'serif';

export type SuggestionWeight = 'normal' | 'bold';

export type SuggestionBaseProps = DivPropsExtendable & {
    focused?: boolean;
    size?: SuggestionSize;
    font?: SuggestionFont;
    weight?: SuggestionWeight;
    rounded?: boolean;
    sharp?: boolean;
};

export const SuggestionBase = React.forwardRef<HTMLDivElement, SuggestionBaseProps>((props, ref) => {
    const { focused, size = 'large', font = 'sans', weight = 'normal', rounded = false, sharp = false, ...rest } = props;
    return (
        <Div
            ref={ref}
            defaultClassName={cn(
                'suggestion',
                focused && 'suggestion--focused',
                `suggestion--${size}`,
                `suggestion--font-${font}`,
                `suggestion--font-${weight}`,
                rounded && 'suggestion--rounded',
                sharp && 'suggestion--sharp'
            )}
            {...rest}
        />
    );
});
