import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectTVGuideFocusedChannelId } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';

// Extracted to a component because
// when scrolling throung Loading events up/down the only thing
// that changes is the channelId so in this use case instead of
// rerendering the whole TVGuideDetail we only rerender the logo
export const TVGuideDetailChannelLogo: React.FunctionComponent = () => {
    const channelId = useSelector(selectTVGuideFocusedChannelId);
    return <ChannelLogo className="tv-guide__detail-logo" id={channelId} />;
};
