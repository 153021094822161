import { GQL } from '__SMART_APP_OLD__/app/gql';
import { REMINDER_STATUS_PRIORITY } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/constants';
import { ReminderEntity, ReminderEntityStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

export const selectReminder = (id: GQL.ReminderID) => (state: State): ReminderEntity | null =>
    state.data.reminderEntityTable.entities[id] ?? null;

export const selectReminderByEventId = (id: GQL.EventID) => (state: State) =>
    selectReminder(state.data.reminderEntityTable.eventReminderRelationTable[id])(state);

export const selectReminderStatus = (id: GQL.ReminderID) => (state: State): ReminderEntityStatus | null =>
    state.data.reminderEntityTable.statusTable[id] ?? null;

export const selectReminderTriggerTS = (id: GQL.ReminderID) => (state: State): number | null =>
    state.data.reminderEntityTable.triggerTable[id] ?? null;

export const selectIsReminderSetForEvent = (eventId: string) => (state: State): boolean =>
    !!state.data.reminderEntityTable.eventReminderRelationTable[eventId];

export const selectReminderEntityTableTriggerTSStack = (state: State): number[] =>
    state.data.reminderEntityTable.ids
        .reduce<number[]>((timestamps, id) => {
            const triggerTS = selectReminderTriggerTS(id)(state);
            if (!triggerTS) return timestamps;
            if (!timestamps.includes(triggerTS)) timestamps.push(triggerTS);
            return timestamps;
        }, [])
        .sort((a, b) => a - b);

export const selectRemindersTriggerTSStackEqualityFn = (a: number[], b: number[]): boolean =>
    a.length === b.length && a.every((ts) => b.includes(ts));

export const selectReminderEntityTableTriggerTS = (state: State): number => selectReminderEntityTableTriggerTSStack(state)[0];

export const selectRemindersByTriggerTS = (triggerTS: number) => (state: State) =>
    state.data.reminderEntityTable.ids
        .filter((id) => selectReminderTriggerTS(id)(state) === triggerTS)
        .sort(
            (a, b) =>
                REMINDER_STATUS_PRIORITY[selectReminderStatus(a)(state) ?? ReminderEntityStatus.ACTIVE] -
                REMINDER_STATUS_PRIORITY[selectReminderStatus(b)(state) ?? ReminderEntityStatus.ACTIVE]
        );

export const selectRemindersByEventStart = (start: number) => (state: State) =>
    state.data.reminderEntityTable.ids
        .filter((id) => selectReminder(id)(state)?.start === start)
        .sort(
            (a, b) =>
                REMINDER_STATUS_PRIORITY[selectReminderStatus(a)(state) ?? ReminderEntityStatus.ACTIVE] -
                REMINDER_STATUS_PRIORITY[selectReminderStatus(b)(state) ?? ReminderEntityStatus.ACTIVE]
        );

export const selectRemindersEqualityFn = (a: ReminderEntity[], b: ReminderEntity[]): boolean =>
    a.length === b.length && a.every((entity) => b.includes(entity));
