import { KeyCapsLockIcon } from 'App/Packages/UI/Keyboard/Components/Key/CapsLock/KeyCapsLockIcon';
import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import React from 'react';

export type KeyCapsLockProps = KeyProps & {
    active?: boolean;
};

export type KeyCapsLockComponent = React.FunctionComponent<KeyCapsLockProps>;

export const KeyCapsLock: KeyCapsLockComponent = (props) => {
    const { size = 'x1', variant = 'secondary', active = false, children = <KeyCapsLockIcon active={active} />, ...other } = props;

    return (
        <Key size={size} variant={variant} {...other}>
            {children}
        </Key>
    );
};
