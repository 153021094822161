import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

import { CoreBackEngine } from 'App/Modules/EnhancedBack/Implementation/Core';

export class AustriaBackEngine extends CoreBackEngine {
    public onPlayerBack(assetType?: string): Thunk<void> {
        return (dispatch) => {
            if (this.isEligibleForGoBack(assetType)) {
                CustomHistory.back();
                return;
            }
            dispatch(this.goToHome());
        };
    }
}
