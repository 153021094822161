import { ContentFolderKind } from '__SMART_APP_OLD__/api/graphql/types';

import { Folder, FolderList } from 'App/Modules/Folder/Types';

export const initialFolderState: Folder = {
    id: '',
    type: ContentFolderKind.ArchivedRecordings,
    title: '',
    count: 0,
    ids: [],
    entities: {},
};

export const initialFolderListState: FolderList = {
    id: '',
    count: 0,
    ids: [],
    entities: {},
};
