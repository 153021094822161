import React from 'react';

export type KeySpaceIconProps = {};

export type KeySpaceIconComponent = React.FunctionComponent<KeySpaceIconProps>;

export const KeySpaceIcon: KeySpaceIconComponent = React.memo(() => (
    <svg
        className="key__icon key__icon--stroke key__icon--no-fill"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6 12v6.5h18V12" strokeWidth="2" />
    </svg>
));

export const KeySpaceIconInsance = <KeySpaceIcon />;
