import { selectResetPasswordQRCode } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';
import translate from 'language/translate';

/**
 * @namespace Utils.ErrorHandling
 */

/**
 * @description A function that returns invalid login error object
 * @returns login error
 * @memberof Utils.ErrorHandling
 */
export const invalidLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: `${translate('SCREEN_EMAIL_FIELD_EMPTY')} & ${translate('SCREEN_PASSWORD_FIELD_EMPTY')}`,
    buttons: [
        {
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns invalid email login error
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const emailInvalidLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('SCREEN_EMAIL_FIELD_EMPTY'),
    buttons: [
        {
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns invalid password error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const passwordInvalidLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('SCREEN_PASSWORD_FIELD_EMPTY'),
    buttons: [
        {
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns failed login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const failedLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('SCREEN_PASSWORD_FIELD_EMAIL_FIELD_INCORRECT'),
    image: selectResetPasswordQRCode(store.getState()),
    buttons: [
        {
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns not same household error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const notSameHouseholdLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: `${translate('ERR_LOGIN_DIFFERENT_HOUSEHOLD_ACCOUNT')}
            ${translate('SETTINGS_ABOUT_UNIQUE_DEVICE_ID')}: ${Platform.ID} `,
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns disabled account error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const disabledAccountLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_ACCOUNT_DISABLED'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns locked account login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const lockedAccountLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_ACCOUNT_LOCKED'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns disabled device login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const disabledDeviceLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_DEVICE_NOT_ENABLED'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns device require authentication login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const deviceRequireAuthenticationLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_DEVICE_TYPE_NOT_AUTHENTICATED'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns disabled household login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const disabledHouseholdLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_HOUSEHOLD_NOT_ENABLED'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns device household login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const deviceHouseholdLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_DIFFERENT_HOUSEHOLD_ACCOUNT'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns user not member of household login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const userNotMemberOfHouseholdError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_USER_NOT_IN_HOUSEHOLD'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns device not found  login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const deviceNotFoundLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_DEVICE_NOT_FOUND'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns change password login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const changePasswordLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('SCREEN_DIFFERENT_HOUSEHOLD_ACCOUNT'),
    buttons: [
        {
            // this should be Change now
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
        {
            btnLabel: translate('CANCEL_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns token login error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const tokenLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_TOKEN'),
    buttons: [
        {
            btnLabel: translate('OK_BUTTON'),
        },
    ],
});

/**
 * @description A function that returns error ten error object
 * @memberof Utils.ErrorHandling
 * @returns login error
 */
export const errorTenLoginError = () => ({
    title: translate('SCREEN_LOGIN_ALERT_LOGIN_FAILED'),
    text: translate('ERR_LOGIN_DELETE_FOREIGN_DEVICE_FAILED'),
    buttons: [
        {
            btnLabel: translate('TRY_AGAIN_BUTTON'),
        },
    ],
});
