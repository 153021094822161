import { SuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';
import { useCallback } from 'react';

export type UseSuggestionFocus = (value: string) => () => void;

export const useSuggestionFocus: UseSuggestionFocus = (value) => {
    const items = SuggestionList.use();

    return useCallback(() => items.focus(value), [value, items]);
};
