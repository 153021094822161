import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import { KeySpaceIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/Space/KeySpaceIcon';
import React from 'react';

export type KeySpaceProps = KeyProps;

export type KeySpaceComponent = React.FunctionComponent<KeySpaceProps>;

export const KeySpace: KeySpaceComponent = (props) => {
    const { size = 'x3', children = KeySpaceIconInsance, ...other } = props;
    return (
        <Key size={size} {...other}>
            {children}
        </Key>
    );
};
