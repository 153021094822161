import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../parentalRating/fragments/parentalRatingRelation.generated';
import { TrailerFragmentDoc } from '../../fragments/trailer.generated';
export type MessageAttachment_ImageItem_Fragment = { __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null };

export type MessageAttachment_VodAsset_Fragment = { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } };

export type MessageAttachmentFragment = MessageAttachment_ImageItem_Fragment | MessageAttachment_VodAsset_Fragment;

export const MessageAttachmentFragmentDoc = gql`
    fragment messageAttachment on MessageAttachment {
  ... on ImageItem {
    id
    image(
      width: $messageAttachmentImageWidth
      height: $messageAttachmentImageHeight
    ) {
      ...image
    }
    __typename
  }
  ... on VODAsset {
    id
    title
    backgroundImage(
      width: $messageAttachmentImageWidth
      height: $messageAttachmentImageHeight
    ) {
      ...image
    }
    parentalRating {
      ...parentalRatingRelation
    }
    trailers {
      items {
        ...trailer
      }
    }
    __typename
  }
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}
${TrailerFragmentDoc}`;