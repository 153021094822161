import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { ProgressBar } from '__SMART_APP_OLD__/app/components/ProgressBar';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

const getClassName = (props: CardLandscapeProgressProps) => {
    const classlist = ['landscape-card-v3__progress'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeProgressProps = {
    className?: string;
    color?: BackgroundColor;
    theme?: Theme.Type;
    progress: number;
};

export type CardLandscapeProgressComponent = React.FunctionComponent<CardLandscapeProgressProps>;

export const CardLandscapeProgress: CardLandscapeProgressComponent = (props) => (
    <ProgressBar className={getClassName(props)} progress={props.progress} color={props.color} theme={props.theme} />
);
