/* eslint-disable react-hooks/exhaustive-deps */
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { useGetFolderByIdLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/getFolderById.generated';
import { useHomeLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/home.generated';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { CONTINUE_WATCHING_CHANGED, HOME_CHANGED } from '__SMART_APP_OLD__/config/Events';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

const homeVariables = {
    variables: {
        channelLogoHeight: 216,
        channelLogoWidth: 384,
        firstFolders: 5,
        firstItems: 30,
        foldersAfterCursor: null,
        itemsAfterCursor: null,
        itemsBeforeCursor: null,
        lastItems: 15,
        profileId: '',
        thumbnailHeight: 280,
    },
    fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
};
const folderVariables = {
    variables: {
        id: '',
        profileId: '',
        firstItems: 30,
        itemsAfterCursor: null,
        itemsBeforeCursor: null,
        thumbnailHeight: 280,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
    },
    fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
};

export const useHomePageDataService = () => {
    const config = useSelector(selectConfig);
    const setLoadStripesVariables = () => {
        homeVariables.variables.profileId = Profile.selectors.selectId(store.getState());
        homeVariables.variables.channelLogoHeight = config.image.logo.large.height;
        homeVariables.variables.channelLogoWidth = config.image.logo.large.width;
        homeVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;

        return homeVariables;
    };

    const [loadStripes, { data: homeData, loading: homeLoading }] = useHomeLazyQuery(setLoadStripesVariables());
    const [loadFolderData, { data: folderData }] = useGetFolderByIdLazyQuery(folderVariables);

    const [updateNumber, setUpdateNumber] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const homePageData = useRef<any>({ stripes: [], galleryAssets: [] });
    const pageInfo = useRef<any>({});
    const stripesForReloadIds = useRef<any>([]);

    const setPageData = ({ stripes, galleryAssets }: any) => {
        if (stripes || galleryAssets) {
            homePageData.current = {
                stripes: stripes || homePageData.current.stripes,
                galleryAssets: galleryAssets || homePageData.current.galleryAssets,
            };
            setUpdateNumber(updateNumber + 1);
        }
    };

    const setFolderVariables = (stripeId: string) => {
        homeVariables.variables.profileId = Profile.selectors.selectId(store.getState());
        folderVariables.variables.id = stripeId;
        folderVariables.variables.channelLogoHeight = config.image.logo.large.height;
        folderVariables.variables.channelLogoWidth = config.image.logo.large.width;
        folderVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;

        return folderVariables;
    };

    const reloadAllStripes = () => {
        homeVariables.variables.profileId = Profile.selectors.selectId(store.getState());
        homeVariables.variables.foldersAfterCursor = null;
        homeVariables.variables.firstFolders = (homePageData.current?.stripes?.length || 4) + 1;
        homeVariables.variables.channelLogoHeight = config.image.logo.large.height;
        homeVariables.variables.channelLogoWidth = config.image.logo.large.width;
        homeVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;
        pageInfo.current = {};

        setPageData({ stripes: [] });
        loadStripes(homeVariables);
    };

    useEffect(() => {
        Events.addEventListener(CONTINUE_WATCHING_CHANGED, reloadAllStripes);
        Events.addEventListener(HOME_CHANGED, reloadAllStripes);

        return () => {
            Events.removeEventListener(CONTINUE_WATCHING_CHANGED, reloadAllStripes);
            Events.removeEventListener(HOME_CHANGED, reloadAllStripes);
        };
    }, []);

    useEffect(() => {
        if (!folderData) return;
        const assets = folderData?.contentFolder?.firstItems?.edges?.map((item: any) =>
            createAssetObject(item?.node, item?.node?.__typename)
        );
        const folderId = folderData?.contentFolder?.id;
        const currentStripes = homePageData.current.stripes;
        const reloadedStripe = currentStripes.find((s: any) => s.stripeId === folderId);

        if (reloadedStripe) {
            reloadedStripe.assets = assets;
        }

        if (stripesForReloadIds.current.length) {
            loadFolderData(setFolderVariables(stripesForReloadIds.current.shift()));
        }
    }, [folderData]);

    useEffect(() => {
        if (!homeLoading && homeData) {
            const edges =
                homeData?.homeRows?.folders?.edges?.map((edge: any) => ({
                    assets: edge?.node?.firstItems?.edges?.map((item: any) => createAssetObject(item?.node, item?.node?.__typename)),
                    type: edge?.node?.kind,
                    title: edge?.node?.title,
                    stripeId: edge?.node?.id,
                })) || [];
            pageInfo.current = homeData?.homeRows?.folders?.pageInfo || {};

            setLoading(homeLoading);
            setPageData({ stripes: [...homePageData.current.stripes, ...edges] });
        }
    }, [homeData, homeLoading]);

    const setHomeQueryVariables = () => {
        const { endCursor } = pageInfo.current;

        homeVariables.variables.profileId = Profile.selectors.selectId(store.getState());
        homeVariables.variables.foldersAfterCursor = endCursor;
        homeVariables.variables.firstFolders = 5;
        homeVariables.variables.channelLogoHeight = config.image.logo.large.height;
        homeVariables.variables.channelLogoWidth = config.image.logo.large.width;
        homeVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;

        return homeVariables;
    };

    const loadNextHomeStripes = (index: number) => {
        const { hasNextPage } = pageInfo.current;

        if (!homeLoading && hasNextPage && index > 2) {
            loadStripes(setHomeQueryVariables());
        }
    };

    const loadHomePageData = (refresh = false) => {
        if (homePageData.current?.stripes?.length && !refresh) {
            return { loading: false, ...homePageData.current };
        }
        if (refresh) {
            homeVariables.variables.firstFolders = homePageData.current?.stripes?.length || 5;
            homeVariables.variables.foldersAfterCursor = null;
            homeVariables.variables.channelLogoHeight = config.image.logo.large.height;
            homeVariables.variables.channelLogoWidth = config.image.logo.large.width;
            homeVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;
            homePageData.current = { ...homePageData.current, stripes: [] };
            pageInfo.current = {};
        }
        loadStripes(setLoadStripesVariables());
        setLoading(true);
        return { loading: true, stripes: [], galleryAssets: [] };
    };

    return {
        homePageData: homePageData.current,
        loading,
        loadHomePageData,
        loadNextHomeStripes,
    };
};
