import React, { useEffect, useState } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectHouseholdTrackList } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import Player from '__SMART_APP_OLD__/platforms/Player';

export const PlayerInfo: React.FunctionComponent = () => {
    const [info, setInfo] = useState<string[]>([]);
    const trackedIds = useSelector(selectHouseholdTrackList);
    const householdId = useSelector(Household.selectors.selectHouseholdId);
    const showPlayerInfo = trackedIds.includes(householdId);

    useEffect(() => {
        if (!showPlayerInfo) return undefined;
        const intervalID = setInterval(() => {
            const statPresentation = ['Playback info'];
            const stats = Player.playbackStats();
            const videoTrack = Player.getCurrentVideoTrack();
            const videoRendition = Player.getCurrentVideoRendition();
            const audioRendition = Player.getCurrentAudioRendition();
            statPresentation.push(`player reported stream start latency: ${stats?.loadLatency}`);
            statPresentation.push(`player estimated bandwidth: ${stats?.estimatedBandwidth}`);
            statPresentation.push(`player reported bandwidth: ${stats?.streamBandwidth}`);
            statPresentation.push(`player reported decoded frames: ${stats?.decodedFrames}`);
            statPresentation.push(`player reported dropped frames: ${stats?.droppedFrames}`);
            statPresentation.push(`player reported corrupted frames: ${Number.isNaN(stats?.corruptedFrames) ? 0 : stats?.corruptedFrames}`);
            statPresentation.push(`player reported play time: ${Math.floor(Math.abs((stats?.playTime ?? 0) / 60))}`);
            statPresentation.push(`player reported pause time: ${Math.floor(Math.abs((stats?.pauseTime ?? 0) / 60))}`);
            statPresentation.push(`player reported buffering time: ${stats?.playTime}`);
            statPresentation.push(`Video track info`);
            statPresentation.push(`Current video track id: ${videoTrack?.id}`);
            statPresentation.push(`Current video track framerate: ${videoTrack?.frameRate}`);
            statPresentation.push(`Current video track mimeType: ${videoTrack?.mimeType}`);
            statPresentation.push(`Current video track source: ${videoTrack?.src}`);
            statPresentation.push(`Video track video rendition info`);
            statPresentation.push(`Current video track bandwidth: ${videoRendition?.bandwidth}`);
            statPresentation.push(`Current video track codec: ${videoRendition?.codec}`);
            statPresentation.push(`Current video track dimension: ${videoRendition?.height}:${videoRendition?.width}`);
            statPresentation.push(`Current video track rendition id in manifest: ${videoRendition?.originalId}`);
            statPresentation.push(`Video track audio rendition info`);
            statPresentation.push(`Current video track bandwidth: ${audioRendition?.bandwidth}`);
            statPresentation.push(`Current video track codec: ${audioRendition?.codec}`);
            statPresentation.push(`Current video track rendition id in manifest: ${audioRendition?.originalId}`);
            setInfo(statPresentation);
        }, 5000);

        return () => clearTimeout(intervalID);
    }, [showPlayerInfo]);

    if (!showPlayerInfo || info.length === 0) return null;

    return (
        <ul className="player-info">
            {info.map((stat, index) => (
                <li key={index}>{stat}</li>
            ))}
        </ul>
    );
};
