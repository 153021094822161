import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { History } from '__SMART_APP_OLD__/app/modules/History';

import { Folder } from 'App/Modules/Folder';
import { Header } from 'App/Modules/Header';
import { RootFolder } from 'App/Modules/RootFolder';
import { Screen } from 'App/Modules/Screen';
import { actions } from 'App/Modules/Screen/Modules/VideoStore/Store/actions';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { MoreCard } from 'App/Modules/Folder/Components/MoreCard';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { MenuItemType } from 'App/Types/Menu';

export const View: React.FunctionComponent<Screen.Props<Screen.Type.VideoStore>> = (props) => {
    const dispatch = useDispatch();

    const folders = useSelector(RootFolder.selectors.selectFoldersIds);

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <Header.Widget
                active={MenuItemType.VideoStore}
                initial={props.focusedHeaderId ?? MenuItemType.VideoStore}
                strong
                onEnter={(id) => dispatch(actions.private.header.selected(id))}
            />
            <UI.Folder.List offsetTop={UI.Folder.Constants.List.Scroll.Offset.Header}>
                {folders.map((id) => {
                    const selector = RootFolder.selectors.selectFolder(id);
                    return (
                        <UI.Folder key={id} id={id}>
                            <UI.Folder.Header>
                                <UI.Selector selector={Folder.selectors.selectTitle(selector)} />
                            </UI.Folder.Header>
                            <UI.Folder.Items
                                initial={props.focusedFolderId === id ? props.focusedItemId : undefined}
                                onEnter={(itemId) => dispatch(actions.private.selected(id, itemId))}
                            >
                                <UI.Selector selector={Folder.selectors.selectItemsIds(selector)}>
                                    {(ids) =>
                                        ids.map((itemId) => (
                                            <FolderCardItem key={itemId} selector={Folder.selectors.selectItem(selector)(itemId)} />
                                        ))
                                    }
                                </UI.Selector>
                                <MoreCard id={UI.Folder.Constants.Item.Id.More} selector={selector} />
                            </UI.Folder.Items>
                        </UI.Folder>
                    );
                })}
            </UI.Folder.List>
        </Layout>
    );
};
