import React from 'react';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { Folder as FolderModule } from 'App/Packages/UI/Folder/Modules/Folder';
import { UseFolderValuePayload } from 'App/Packages/UI/Folder/Modules/Folder/Hooks/useFolderValue';

export type FolderProps = DivPropsExtendable & UseFolderValuePayload;

export type FolderComponent = React.FunctionComponent<FolderProps>;

const FolderInternal: React.FunctionComponent<DivPropsExtendable> = (props) => {
    const folder = FolderModule.use();

    return <Div {...props} defaultClassName="folder" ref={folder.ref} />;
};

export const Folder: FolderComponent = (props) => {
    const { id, ctx, getTop, ...rest } = props;
    return (
        <FolderModule id={id} ctx={ctx} getTop={getTop}>
            <FolderInternal {...rest} />
        </FolderModule>
    );
};
