/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from 'react';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { GetEventRelatedContentDocument } from '__SMART_APP_OLD__/api/graphql/query/getEventRelatedContent.generated';
import { GetNetworkRecordingRelatedContentDocument } from '__SMART_APP_OLD__/api/graphql/query/getNetworkRecordingRelatedContent.generated';
import { GetVodAssetRelatedContentDocument } from '__SMART_APP_OLD__/api/graphql/query/getVodAssetRelatedContent.generated';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { CreateNetworkRecordingDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/createRecording.generated';
import { RecordingRemoveDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/recordingRemove.generated';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import {
    selectBackgroundLandscapeHeight,
    selectBackgroundWidth,
    selectConfig,
    selectShowSeriesSeason,
    selectIsContentDiscoveryEnabled,
    selectVodEndedInactivityTimeout,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { channelSwitchIdChange } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import {
    selectChannelLiveTVEntitlement,
    selectChannelSwitchId,
    selectLiveChannelLiveTVEntitilement,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { ellipsizeStringByWordCount } from '__SMART_APP_OLD__/utils/Utils';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectEvent,
    selectEventBy,
    selectEventIsRestricted,
    selectEventTitle,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { isCatchupTV } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { addRecording, removeRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { selectRecordingByEventId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { useIsMagicMode } from '__SMART_APP_OLD__/common/useIsMagicMode';
import { handleChannelSwitching } from '__SMART_APP_OLD__/components/channelSwitchDialog';
import SnackBar from '__SMART_APP_OLD__/components/common/SnackBar';
import LinearProgressBar from '__SMART_APP_OLD__/components/playerUI/LinearProgressBar';
import PlayerBanner from '__SMART_APP_OLD__/components/playerUI/PlayerBanner';
import { PlayerButton, PlayerButtonType } from '__SMART_APP_OLD__/components/playerUI/PlayerButton';
import PlayerHints from '__SMART_APP_OLD__/components/playerUI/PlayerHints';
import { PlayerInfo } from '__SMART_APP_OLD__/components/playerUI/PlayerInfo';
import { PlayerRelatedContent } from '__SMART_APP_OLD__/components/playerUI/PlayerRelatedContent';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import VodProgressBar from '__SMART_APP_OLD__/components/playerUI/VodProgressBar';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG, PLAYER_SEEK } from '__SMART_APP_OLD__/config/Events';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import { AssetType, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { PlaybackEvents } from 'analytics/logging/events/PlaybackEvent';
import { RecordingPlanningTypes } from 'analytics/logging/events/RecordingEvent';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getPlaybackEvent } from 'analytics/logging/factories/playbackEventFactory';
import { getDeleteRecordingEvent, getPlanRecordingEvent } from 'analytics/logging/factories/recordingEventFactory';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Env } from 'App/Env';
import { Key } from 'App/Modules/Key';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { isCatchupAsset, isVodEpisodeAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import { Screen } from 'App/Modules/Screen';
import { Prompt } from 'App/Modules/Prompt';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { contentItemTypeForAssetType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

class PlayerUI extends React.Component {
    constructor(props) {
        super(props);
        const { isLinearPlay } = props;
        this.state = {
            relatedContent: null,
            isLinearPlay,
            isPlaying: Player.isPlaying(),
            isLoading: !Player.isPlaying(),
            isRelatedContentFocused: false,
        };
        this.options = null;
        this.showUI = props.showUI;
        this.hideUI = props.hideUI;
        this.showSubtitlesMenu = props.showSubtitlesMenu;
        this.toggleNotificationButton = props.toggleNotificationButton;
    }

    componentDidMount() {
        this.placeFocus();
        Player.addEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.addEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.addEventListener(VIDEO_EVENTS.ENDED, this.onEnded);
        Player.addEventListener(VIDEO_EVENTS.SEEKING, this.showLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.PLAYING, this.hideLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.ERROR, this.hideChannelSwitchDialog);
        Player.addEventListener(VIDEO_EVENTS.ERROR, this.hideLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.WAITING, this.showLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.BUFFERING_STARTED, this.showLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.BUFFERING_ENDED, this.hideLoadingSpinner);
        Player.addEventListener(VIDEO_EVENTS.SEEKED, this.hideLoadingSpinner);
        window.addEventListener('keyup', this.handlePlayerSeekKeyup);

        if (this.props.data) {
            this.getRelatedContent()
                .then((relatedContent) => {
                    this.setState({ relatedContent });

                    return null;
                })
                .catch((e) => {
                    throw e;
                });
        }
    }

    componentDidUpdate(prevProps) {
        // we should restore focus between continous catchup switching for VOD player
        if (prevProps.className !== this.props.className || (!this.props.isLinearPlay && this.props.data.id !== prevProps.data.id)) {
            setTimeout(this.placeFocus, 50, true);
        }

        if (!this.props.isMagicMode && prevProps.isMagicMode && this.state.isPlaying) {
            this.props.resetTimer();
            this.hideUI(true);
        }

        if (prevProps.data !== this.props.data && this.props.data) {
            this.getRelatedContent()
                .then((relatedContent) => {
                    this.setState({ relatedContent });
                    return null;
                })
                .catch((e) => {
                    throw e;
                });
        }
    }

    componentWillUnmount() {
        Player.removeEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.removeEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.removeEventListener(VIDEO_EVENTS.ENDED, this.onEnded);
        Player.removeEventListener(VIDEO_EVENTS.PLAY, this.hideLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.ERROR, this.hideLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.ERROR, this.hideChannelSwitchDialog);
        Player.removeEventListener(VIDEO_EVENTS.SEEKING, this.showLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.SEEKED, this.hideLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.BUFFERING_STARTED, this.showLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.BUFFERING_ENDED, this.hideLoadingSpinner);
        Player.removeEventListener(VIDEO_EVENTS.WAITING, this.showLoadingSpinner);
        window.removeEventListener('keyup', this.handlePlayerSeekKeyup);

        if (this.inactivityOnEndedTimer) {
            clearTimeout(this.inactivityOnEndedTimer);
        }

        if (this.onEndedAnimationTimer) {
            clearTimeout(this.onEndedAnimationTimer);
        }
    }

    placeFocus = (checkExistingFocus = false) => {
        const { className } = this.props;
        if (className !== 'hidden') {
            // if notification or other focusable page is displayed
            // while player is loading, prevent focus stealing
            if (checkExistingFocus && Focus.focused?.nodeRef?.classList.contains('focusable')) {
                return;
            }

            if (this.buttonRef) {
                Focus.focus(this.buttonRef.nodeRef);
            } else {
                Focus.blur();
                Focus.focus(document.getElementById('player-ui-related-content'));
            }
        }
    };

    onPlay = () => {
        if (Focus.isMagicMode === false) {
            this.hideUI(true);
        }

        if (this.state.isRelatedContentFocused) {
            this.placeFocus();
        }

        this.setState({
            isPlaying: true,
            isLoading: false,
            isRelatedContentFocused: false,
        });
    };

    handlePlayerSeekKeyup = (event) => {
        const focusedEl = Focus.focused?.nodeRef;
        const dataTestIdValue = focusedEl.getAttribute('data-test-id');
        if (dataTestIdValue !== 'button-player-forward' && dataTestIdValue !== 'button-player-rewind') return false;
        const { keyCode, type } = event;
        switch (keyCode) {
            case Key.VK_ENTER: {
                dataTestIdValue === 'button-player-forward'
                    ? this.seekButtonPressHandler(Key.VK_FAST_FWD, type)
                    : this.seekButtonPressHandler(Key.VK_REWIND, type);

                return true;
            }

            default:
                return false;
        }
    };

    onEnded = () => {
        const { relatedContent } = this.state;
        const { onEnded, event, data, isNextAssetAvailable } = this.props;

        if (!relatedContent || isCatchupTV(event) || (isVodEpisodeAsset(data) && isNextAssetAvailable)) {
            onEnded();
            return;
        }

        this.onEndedAnimationTimer = setTimeout(() => {
            this.setState({ isRelatedContentFocused: true });
            Focus.focus(this.relatedContentRef, true);
            this.setInactivityOnEndedTimer();
        }, 100);
    };

    onPause = () => {
        // TODO: uncomment for timeshift
        // const {isLinearPlay} = this.state;*
        // if (isLinearPlay) {
        //     this.setState({isLinearPlay: false});
        // }

        if (Focus.isMagicMode === false) {
            this.showUI(false);
        }

        if (this.relatedContentRef) {
            this.relatedContentRef.parentNode.scrollLeft = 0;
        }

        this.setState({ isPlaying: false });
    };

    onKey = (keyCode) => {
        const { isPlaying } = this.state;
        this.props.resetTimer();
        this.resetInactivityOnEndedTimer();

        switch (keyCode) {
            case Key.VK_CURSOR_ON:
                this.showUI(false);
                return true;
            case Key.VK_CURSOR_OFF:
                if (this.state.isPlaying) {
                    this.hideUI(true);
                }
                return true;
            case Key.VK_BACK:
                this.hideUI();
                return true;
            case Key.VK_PLAY_PAUSE:
                this.togglePlay();
                return true;
            case Key.VK_PAUSE:
                if (isPlaying) {
                    this.pause();
                }
                return true;
            case Key.VK_PLAY:
                if (!isPlaying) {
                    this.play();
                }
                return true;
            default:
                break;
        }
        return false;
    };

    onMouseMove = () => {
        if (Focus.isDragging) {
            this.props.resetTimer();
        }
    };

    setInactivityOnEndedTimer = () => {
        const { vodEndedInactivityTimeout, onEnded } = this.props;

        if (this.inactivityOnEndedTimer) {
            clearTimeout(this.inactivityOnEndedTimer);
        }

        this.inactivityOnEndedTimer = setTimeout(() => {
            onEnded();
        }, vodEndedInactivityTimeout);
    };

    resetInactivityOnEndedTimer = () => {
        if (!this.inactivityOnEndedTimer || isCatchupTV(this.props.event)) return;

        this.setInactivityOnEndedTimer();
    };

    playFromStart = () => {
        const { onPlayFromStart } = this.props;
        const { assetType, id } = this.props.data;
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.VIEW_FROM_START, { assetType, id }));
        const { isLinearPlay } = this.state;
        if (isLinearPlay) {
            this.switchToRestartStream()
                .then(() => {
                    if (this.progressBarRef?.setSeekToStartFlag) {
                        this.progressBarRef.setSeekToStartFlag(true);
                    }

                    return null;
                })
                .catch((e) => {
                    throw e;
                });
        } else if (typeof onPlayFromStart === 'function') {
            onPlayFromStart();
        }
        this.showUI();
        this.placeFocus();
    };

    switchToRestartStream = async () => {
        const timeshift = Player.getTimeshift();
        const { data, onSetStream } = this.props;
        await PlayerAPI.setRestartStream(data.id);
        onSetStream(true, data.id);
        Player.program = data;
        Player.setTimeshift(timeshift);
    };

    pause = () => {
        Player.pause();
        LoggingService.getInstance().logEvent(getPlaybackEvent(PlaybackEvents.PAUSE));
    };

    play = () => {
        const { isLinearPlay, data, hasManifestFromStart } = this.props;
        const { assetType, id } = data;
        // just play if VOD
        if (!isLinearPlay || hasManifestFromStart) {
            return Player.play();
        }

        if (isLinearPlay && !hasManifestFromStart) {
            const mutationVariables = {
                input: {
                    tunedAt: Player.getTunedAt(),
                    eventId: data.id,
                    channelId: this.props.liveChannelId,
                    pausedAt: Player.getPausedAt(),
                },
            };
            const timeshift = Player.getTimeshift();
            return PlayerAPI.setPauseLiveStream(mutationVariables)
                .then(() => {
                    Player.program = data;
                    Player.setTimeshift(timeshift);
                    this.props.onSetStream(true, data.id);

                    return null;
                })
                .catch((e) => {
                    throw e;
                });
        }

        const currentTime = Date.now();
        const timeshift = Player.getTimeshift();
        const liveDuration = Player.getLiveDuration();

        // if timeshift is longer then live duration - let's seek to live position
        // to avoid time freeze
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id }));
        if (timeshift && (currentTime - timeshift) / 1000 > liveDuration) {
            return Player.seekToLive(true);
        }
        return Player.play();
    };

    togglePlay = () => {
        const { isPlaying } = this.state;

        this.toggleNotificationButton(true);
        if (isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    };

    showInfo = () => {
        const { assetType, id, seriesId } = this.props.data;
        this.props.saveBookmark();
        const bookmark = Player.getPlayedTime();
        const leadIn = Player?.program?.leadIn ?? 0;
        const playerAssetBaseUrl = `/play/${assetType}/${id}`;

        if (document.location.pathname.indexOf(playerAssetBaseUrl) === 0) {
            CustomHistory.replace(`${playerAssetBaseUrl}/${bookmark || leadIn}`);
        }
        if (assetType === AssetType.EPISODE) {
            LoggingService.getInstance().logEvent(
                getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType: AssetType.VOD_SERIES, id: seriesId })
            );
            this.props.pushHistory();
            this.props.toVodSeriesDetailPage(seriesId, id);

            return;
        }

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id }));

        this.props.pushHistory();
        this.props.toDetailPage(id, contentItemTypeForAssetType[assetType], () => CustomHistory.go(`/details/${assetType}/${id}`));
    };

    recordButtonPressHandler = () => {
        if (!this.props.recording) {
            this.createRecording();
            return;
        }

        this.props.showConfirmDeleteRecordingPrompt(this.props.recording.id);
    };

    /**
     * @description Handles seek buttons press
     * @param {string} key the simulated key code (not enter)
     * @param eventType the type of event triggering the function
     * @returns {void}
     */
    seekButtonPressHandler = (key, eventType) => {
        switch (key) {
            case Key.VK_FAST_FWD:
                return Events.triggerEvents(PLAYER_SEEK, { keyCode: Key.VK_FAST_FWD, eventType, playerUiVisible: true });

            case Key.VK_REWIND:
                return Events.triggerEvents(PLAYER_SEEK, { keyCode: Key.VK_REWIND, eventType, playerUiVisible: true });
            default:
                return null;
        }
    };

    getPlayerButtons = () => {
        const { isTrailer, showReplayButton: showReplayButtonProp, data, isLinearPlay, event } = this.props;
        const asset = event || data; // edge case we don;t have event as it is 7 days old we cant find it in epg data

        const { audio, subtitles } = Player.getPlayerOptions() || {};
        const buttons = [];
        const isNetworkRecordingObject = data.assetType === AssetType.NETWORK_RECORDING;
        const { isPlaying } = this.state;
        const { programType, isRecordable, isNoInfo, isLoading, assetType } = data;
        const showReplayButton = showReplayButtonProp || this.shouldShowStartOverButton();
        const showSubtitleButton = audio?.length !== 0 || !(subtitles?.length === 1 && subtitles[0]?.iso_code === 'off');
        const isLive = Player.getTimeshift() === 0;

        if (asset?.entitlements?.restartTV || asset?.entitlements?.pauseLiveTV || assetType !== AssetType.EVENT) {
            buttons.push(
                <PlayerButton
                    key="rewind-button"
                    type={PlayerButtonType.REWIND}
                    dataTestId="button-player-rewind"
                    caption="SCREEN_PLAYER_REWIND_BUTTON"
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    onEnter={() => {
                        this.seekButtonPressHandler(Key.VK_REWIND, 'keydown');
                    }}
                />
            );
        }

        if ((isLinearPlay && asset?.entitlements?.pauseLiveTV) || !isLinearPlay) {
            buttons.push(
                <PlayerButton
                    key="play-pause-button"
                    type={isPlaying ? PlayerButtonType.PAUSE : PlayerButtonType.PLAY}
                    dataTestId="button_player_play"
                    caption={isPlaying ? translate('SCREEN_PLAYER_PAUSE_BUTTON') : translate('SCREEN_PLAYER_PLAY_BUTTON')}
                    onEnter={this.togglePlay}
                    ref={(node) => {
                        if (node) {
                            this.buttonRef = node;
                        }
                    }}
                />
            );
        } else {
            this.buttonRef = null;
        }

        if (
            (!isLive || !isLinearPlay) &&
            (asset?.entitlements?.restartTV || asset?.entitlements?.pauseLiveTV || assetType !== AssetType.EVENT)
        ) {
            buttons.push(
                <PlayerButton
                    key="forward-button"
                    type={PlayerButtonType.FORWARD}
                    dataTestId="button-player-forward"
                    caption="SCREEN_PLAYER_FORWARD_BUTTON"
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    onEnter={() => {
                        this.seekButtonPressHandler(Key.VK_FAST_FWD, 'keydown');
                    }}
                />
            );
        }

        if (showReplayButton) {
            buttons.push(
                <PlayerButton
                    key="restart-button"
                    type={PlayerButtonType.RESTART}
                    dataTestId="button_player_restart"
                    caption={translate('SCREEN_PLAYER_START_OVER_BUTTON')}
                    onEnter={this.playFromStart}
                />
            );
        }

        if (!isTrailer && !isNoInfo && !isLoading) {
            buttons.push(
                <PlayerButton
                    key="more-info-button"
                    type={PlayerButtonType.MORE_INFO}
                    dataTestId="button_player_info"
                    caption={translate('SCREEN_PLAYER_INFO_BUTTON')}
                    onEnter={this.showInfo}
                />
            );
        }

        if (isRecordable && !isNetworkRecordingObject && !Env.isBusiness) {
            const isRecordingInProgress = this.props.recording !== undefined;
            buttons.push(
                <PlayerButton
                    key="record-button"
                    type={isRecordingInProgress ? PlayerButtonType.STOP_RECORD : PlayerButtonType.RECORD}
                    dataTestId="button_player_record"
                    caption={
                        isRecordingInProgress ? translate('SCREEN_PLAYER_STOP_RECORD_PLAYER') : translate('SCREEN_PLAYER_RECORD_PLAYER')
                    }
                    onEnter={this.recordButtonPressHandler}
                />
            );
        }

        if (showSubtitleButton && !isTrailer && audio.length !== 0 && subtitles.length !== 0) {
            buttons.push(
                <PlayerButton
                    key="subtitles-button"
                    type={PlayerButtonType.AUDIO_SUBTITLE}
                    dataTestId="button_player_subtitle"
                    caption={translate('SCREEN_PLAYER_SUBTITLES_BUTTON')}
                    onEnter={this.showSubtitlesMenu}
                />
            );
        }

        if (programType === ProgramType.LIVE && !isLive) {
            buttons.splice(
                4,
                0,
                <PlayerButton
                    key="jump-to-live-button"
                    type={PlayerButtonType.JUMP_TO_LIVE}
                    caption={translate('SCREEN_PLAYER_JUMP_TO_LIVE_BUTTON')}
                    onEnter={this.onJumpToLive}
                />
            );
        }
        return buttons;
    };

    onJumpToLive = () => {
        const { isLoading } = this.state;
        const { data, onJumpToLive: onJumpToLiveCallback } = this.props;
        const { assetType, id } = data;
        if (isLoading) {
            return;
        }
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY_LIVE, { assetType, id }));

        onJumpToLiveCallback();
        this.placeFocus();
    };

    createRecording = async () => {
        const { data } = this.props;
        const createNPVRInput = {
            input: {
                eventId: data.id,
            },
            backgroundWidth: this.props.backgroundWidth,
            backgroundHeight: this.props.backgroundHeight,
        };

        return GraphqlClient.makeGraphqlMutationRequest(CreateNetworkRecordingDocument, createNPVRInput)
            .then((result) => {
                if (!result || result.errors) throw new Error('mutation failed');
                const { quota, recording } = result.data.createNetworkRecording;
                const { updateRecordingQuota } = this.props;
                this.props.addRecording(recording);
                updateRecordingQuota(quota);
                this.props.notificationShow('NOTIFICATION_RECORDING_STARTED');
                LoggingService.getInstance().logEvent(
                    getUIActionEvent(UIActionEvents.RECORD, {
                        id: data.id,
                        assetType: data.assetType,
                        isSeriesEvent: data.isSeriesEvent,
                    })
                );

                LoggingService.getInstance().logEvent(getPlanRecordingEvent(data.id, RecordingPlanningTypes.INSTANT, data.isSeriesEvent));

                return null;
            })
            .catch(() => {
                this.props.notificationShow('NOTIFICATION_RECORDING_START_FAILED');
            });
    };

    deleteRecording = async () => {
        try {
            const { data, recording } = this.props;

            const result = await GraphqlClient.makeGraphqlMutationRequest(RecordingRemoveDocument, {
                input: {
                    recordingId: recording.id,
                },
            });
            if (!result || result.errors) throw new Error('mutation failed');
            const { updateRecordingQuota } = this.props;
            const { quota, event } = result.data.deleteRecording;
            this.props.removeRecording([event.id]);
            updateRecordingQuota(quota);
            this.props.notificationShow('NOTIFICATION_RECORDING_STOPPED');
            const uiEvent = data.isSeriesEvent ? UIActionEvents.STOP_RECORDING : UIActionEvents.STOP_SERIES_RECORDING;
            LoggingService.getInstance().logEvent(
                getUIActionEvent(uiEvent, {
                    id: data.id,
                    assetType: data.assetType,
                    event: uiEvent,
                })
            );

            LoggingService.getInstance().logEvent(getDeleteRecordingEvent(recording.id, data.isSeriesEvent, false));
        } catch {
            this.props.notificationShow('NOTIFICATION_RECORDING_CANCEL_FAILED');
        }
    };

    getRelatedContentVariables = (id) => {
        const config = selectConfig(store.getState());
        return {
            variables: {
                id,
                profileId: Profile.selectors.selectId(store.getState()),
                firstFolders: 20,
                firstItems: 30,
                lastItems: 15,
                thumbnailHeight: config.image.thumbnail.default.height,
                backgroundWidth: config.image.background.width,
                backgroundHeight: config.image.background.landscape,
                channelLogoHeight: config.image.logo.large.height,
                channelLogoWidth: config.image.logo.large.width,
                foldersAfterCursor: null,
                itemsAfterCursor: null,
                itemsBeforeCursor: null,
            },
            fetchPolicy: 'network-only',
        };
    };

    getRelatedContent = async () => {
        try {
            if (!this.props.data) return null;
            const { isContentDiscoveryEnabled, data, showSeriesSeason } = this.props;
            const { assetType, attachedAssets } = data;

            if (attachedAssets && assetType === AssetType.EPISODE && showSeriesSeason) {
                return attachedAssets;
            }

            if (!isContentDiscoveryEnabled) return null;

            const relatedContentVariables = this.getRelatedContentVariables(data.id);
            switch (data.assetType) {
                case AssetType.NETWORK_RECORDING: {
                    const response = await GraphqlClient.makeGraphqlRequest(
                        GetNetworkRecordingRelatedContentDocument,
                        relatedContentVariables.variables,
                        relatedContentVariables.fetchPolicy
                    );
                    return response.data?.recording?.relatedContent ?? null;
                }
                case AssetType.EVENT: {
                    if (!isCatchupAsset(data)) return null;
                    const response = await GraphqlClient.makeGraphqlRequest(
                        GetEventRelatedContentDocument,
                        relatedContentVariables.variables,
                        relatedContentVariables.fetchPolicy
                    );
                    return response.data?.event?.relatedContent ?? null;
                }
                case AssetType.VOD_ASSET: {
                    const response = await GraphqlClient.makeGraphqlRequest(
                        GetVodAssetRelatedContentDocument,
                        relatedContentVariables.variables,
                        relatedContentVariables.fetchPolicy
                    );
                    return response.data?.vodAsset?.relatedContent ?? null;
                }
                default: {
                    return null;
                }
            }
        } catch {
            return null;
        }
    };

    setProgressbarRef = (node) => {
        if (node) {
            this.progressBarRef = node;
            Focus.focus(this.progressBarRef.nodeRef);
        }
    };

    setRelatedContentRef = (node) => {
        if (node) {
            this.relatedContentRef = node;
        }
    };

    showLoadingSpinner = () => {
        this.setState({ isLoading: true });
    };

    hideLoadingSpinner = () => {
        this.setState({ isLoading: false });
    };

    hideChannelSwitchDialog = () => {
        Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
    };

    onCustomRect = (domNode) => {
        const { top, bottom, height } = domNode.getBoundingClientRect();
        return {
            top,
            bottom,
            left: 500,
            right: 0,
            height,
            width: 10,
        };
    };

    handleNavigateUpFromProgressBar = () => {
        const { data, event, prevEventId } = this.props;
        if (data.assetType === AssetType.EVENT && (data?.entitlements?.catchupTV || event?.entitlements?.catchupTV) && !!prevEventId) {
            Focus.focus(this.prevEventButtonRef.nodeRef.nodeRef);
            return true;
        }
        return false;
    };

    getProgressBar = () => {
        const { isLinearPlay } = this.state;
        const { data, saveBookmark, hasManifestFromStart, onSeek, onEnded, onTimeUpdate } = this.props;
        // TODO: this never works it is always linear even on restart for live tv why wtf
        if (isLinearPlay) {
            return (
                <LinearProgressBar
                    allowSeek={this.allowSeek()}
                    data={data}
                    onEnded={onEnded}
                    ref={(ref) => this.setProgressbarRef(ref)}
                    onCustomRect={this.onCustomRect}
                    switchToRestartStream={this.switchToRestartStream}
                    onJumpToLive={this.onJumpToLive}
                    hasManifestFromStart={hasManifestFromStart}
                    resetTimer={this.props.resetTimer}
                    handleNavigateUp={this.handleNavigateUpFromProgressBar}
                />
            );
        }
        return (
            <VodProgressBar
                data={data}
                saveBookmark={saveBookmark}
                ref={(ref) => this.setProgressbarRef(ref)}
                onCustomRect={this.onCustomRect}
                onSeek={onSeek}
                onTimeUpdate={onTimeUpdate}
                resetTimer={this.props.resetTimer}
                handleNavigateUp={this.handleNavigateUpFromProgressBar}
            />
        );
    };

    // SNACK BAR - callback function for snack bar
    snackBarCallback = (channelId) => {
        const { data, channelSwitchId } = this.props;
        const { isLinearPlay } = this.state;

        // we need to switch channels if the channel id changed OR there was an attempt
        // to watch blocked content channel switching dialog was visible
        if (data.channelId !== channelId || (channelSwitchId && channelSwitchId !== channelId)) {
            if (!isLinearPlay) {
                // we need to set these values to really change the channel
                // this can be removed once the players are handled under the same route!
                Preferences.channelToBeChanged = true;
                Preferences.lastWatchedChannel = channelId;
            }
            this.hideUI();

            if (!isLinearPlay) {
                handleChannelSwitching(null, channelId, () => {}, true);
                return;
            }

            handleChannelSwitching(null, channelId);
        }
    };

    allowSeek = () => {
        if (!this.props.event || this.props.event.isLoading) return false;
        return (
            this.state.isLinearPlay &&
            this.props.event?.entitlements?.pauseLiveTV &&
            (this.props.event?.entitlements?.restartTV || isCatchupTV(this.props.event))
        );
    };

    shouldShowStartOverButton = () => {
        if (!this.props.event || this.props.event.isLoading) return false;
        return this.state.isLinearPlay && (this.props.event?.entitlements?.restartTV || isCatchupTV(this.props.event));
    };

    shouldRenderLoadingSpinner = () => {
        const event = selectEventBy(this.props.channelSwitchId, Date.now(), { equal: EqualityCompare.START })(store.getState());
        const isEventRestricted = selectEventIsRestricted(event?.id ?? '', this.props.sessionType)(store.getState());
        const isSubscribed = this.props.channelSwitchId ? this.props.isSubscribed : this.props.isLiveChannelSubscribed;
        return !isEventRestricted && isSubscribed && this.state.isLoading;
    };

    formatSurroundingEventTitle = (eventId) => {
        const state = store.getState();
        const eventTitle = selectEventTitle(eventId, PinSessionType.PIN_LIVE_TV)(state);
        return ellipsizeStringByWordCount(translate(eventTitle));
    };

    render() {
        const { showHints, className, snackBarEnabled, showNotificationButton, data, event, updatePlayerData } = this.props;
        const { isLoading, isPlaying, relatedContent, isRelatedContentFocused } = this.state;

        if (!data) return null;
        const playerButtons = this.getPlayerButtons();

        return (
            <>
                {this.shouldRenderLoadingSpinner() && <UI.Spinner key="loading-spinner" className="player-loading-spinner" />}
                <PlayerInfo />
                {snackBarEnabled && <SnackBar callback={this.snackBarCallback} key="player-snack-bar" />}
                <div className={`player-ui-dimmer ${className || ''}`} key="player-ui-dimmer" data-test-id="player-watch" id="player-ui">
                    {showHints && <PlayerHints />}
                    {showNotificationButton && (
                        <div className={`player-notification-icons-container ${isRelatedContentFocused ? 'hidden' : ''}`}>
                            <div className="icon-circle icon-circle-button" />
                            <div className={`${isPlaying ? 'icon-play icon-play-button' : 'icon-pause icon-pause-button'}`}></div>
                        </div>
                    )}
                    {data.assetType === AssetType.EVENT && (data?.entitlements?.catchupTV || event?.entitlements?.catchupTV) && (
                        <Container
                            className={`player-event-switch-controls${isRelatedContentFocused ? ' hidden' : ''}${!this.props.prevEventId && this.props.nextEventId ? ' justify-flex-end' : ''}`}
                            onKey={(keyCode) => {
                                this.props.resetTimer();
                                this.resetInactivityOnEndedTimer();
                                switch (keyCode) {
                                    case Key.VK_DOWN:
                                        if (this.progressBarRef) {
                                            Focus.focus(this.progressBarRef.nodeRef.current.nodeRef.nodeRef);
                                            return true;
                                        }
                                        return false;
                                    default:
                                        return false;
                                }
                            }}
                            onMouseMove={this.onMouseMove}
                            onFocusChanged={() => {
                                this.setState({ isRelatedContentFocused: false });
                            }}
                        >
                            {this.props.prevEventId && (
                                <PlayerButton
                                    className="prev-event-button"
                                    key="prev-event-button"
                                    type={PlayerButtonType.PREV_EVENT}
                                    dataTestId="button-player-prev-event"
                                    caption={`${translate('SCREEN_PLAYER_PREV_EVENT_BUTTON')}\n${this.formatSurroundingEventTitle(this.props.prevEventId)}`}
                                    onEnter={this.props.playPrevEvent}
                                    ref={(node) => {
                                        if (node) {
                                            this.prevEventButtonRef = node;
                                        }
                                    }}
                                />
                            )}
                            {isCatchupAsset(data) && this.props.nextEventId && (
                                <PlayerButton
                                    className="next-event-button"
                                    key="next-event-button"
                                    type={PlayerButtonType.NEXT_EVENT}
                                    dataTestId="button-player-next-event"
                                    caption={`${translate('SCREEN_PLAYER_NEXT_EVENT_BUTTON')}\n${this.formatSurroundingEventTitle(this.props.nextEventId)}`}
                                    onEnter={this.props.playNextEvent}
                                />
                            )}
                        </Container>
                    )}
                    <div
                        className={`player-ui-controls-container ${
                            isRelatedContentFocused ? 'player-ui-controls-container--expanded' : ''
                        }`}
                    >
                        <div className="player-ui-controls">
                            <PlayerBanner key="player-banner" data={data} />
                            <Container
                                key="container"
                                onKey={this.onKey}
                                onMouseMove={this.onMouseMove}
                                onFocusChanged={() => {
                                    this.setState({ isRelatedContentFocused: false });
                                }}
                            >
                                {this.getProgressBar()}
                                <div className="player-buttons-container">{playerButtons}</div>
                            </Container>
                        </div>
                        {!isLoading && relatedContent && (
                            <PlayerRelatedContent
                                className={`player-ui-related-content ${isPlaying ? 'hidden' : ''}`}
                                relatedContent={relatedContent}
                                isRelatedContentFocused={isRelatedContentFocused}
                                updatePlayerData={updatePlayerData}
                                currentId={data.id}
                                onFocus={() => {
                                    if (isPlaying) {
                                        this.placeFocus();
                                    } else {
                                        this.setState({ isRelatedContentFocused: true });
                                    }
                                    return true;
                                }}
                                onKey={(keyCode) => {
                                    this.resetInactivityOnEndedTimer();
                                    switch (keyCode) {
                                        case Key.VK_BACK:
                                            this.placeFocus();
                                            return true;
                                        default:
                                            return false;
                                    }
                                }}
                                ref={(ref) => this.setRelatedContentRef(ref)}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const withData = (Component) => (props) => {
    const isMagicMode = useIsMagicMode();
    return <Component isMagicMode={isMagicMode} {...props} />;
};

PlayerUI.defaultProps = {
    saveBookmark: () => Function.noop,
    onJumpToLive: Function.noop,
};

export default withData(
    connect(
        (state, props) => ({
            isSubscribed: selectChannelLiveTVEntitlement(selectChannelSwitchId(state))(state),
            channelSwitchId: selectChannelSwitchId(state),
            isLiveChannelSubscribed: selectLiveChannelLiveTVEntitilement(state),
            event: selectEvent(props?.data?.id ?? '')(state),
            backgroundWidth: selectBackgroundWidth(state),
            backgroundHeight: selectBackgroundLandscapeHeight(state),
            recording: selectRecordingByEventId(props?.data?.id ?? '')(state),
            bookmark: Bookmarks.selectors.selectEntity(props?.data?.id ?? '')(state),
            liveChannelId: selectChannelLiveId(state),
            vodEndedInactivityTimeout: selectVodEndedInactivityTimeout(state),
            isContentDiscoveryEnabled: selectIsContentDiscoveryEnabled(state),
            showSeriesSeason: selectShowSeriesSeason(state),
        }),
        (dispatch) => ({
            notificationShow: (text) => dispatch(textNotificationShow(text)),
            channelSwitchIdChange: (id) => dispatch(channelSwitchIdChange(id)),
            showConfirmDeleteRecordingPrompt: (recordingId) =>
                dispatch(Prompt.actions.mount(Prompt.Type.RecordingConfirmDelete, { recordingId })),
            addRecording: (recording) => dispatch(addRecording(recording)),
            removeRecording: (eventIds) => dispatch(removeRecording(eventIds)),
            updateRecordingQuota: (recordingQuota) => dispatch(Household.actions.recordingQuotaLoad(recordingQuota)),
            toDetailPage: (id, type, oldOperation) => dispatch(Screen.actions.mount(Screen.Type.Detail, { id, type, oldOperation })),
            toVodSeriesDetailPage: (seriesId, initialEpisodeId) =>
                dispatch(
                    Screen.actions.mount(
                        Screen.Type.VodSeriesDetail,
                        { id: seriesId, initialFocusedItemId: initialEpisodeId },
                        UI.Folder.Constants.Ctx
                    )
                ),
            pushHistory: () => dispatch(History.actions.push()),
        })
    )(PlayerUI)
);
