/**
 * No operation function.
 *
 * @see https://dev.to/praneshpsg239/noop-in-javascript-478h
 */
export const noop = () => {};

export const call = Object.assign(<T extends () => unknown>(...fns: T[]) => fns.forEach((fn) => fn()), {
    withArgs: <G extends any[], T extends (...args: G) => unknown>(args: G, ...fns: T[]) => fns.forEach((fn) => fn(...args)),
});

/**
 * Sleep Function to artificially delay the execution context.
 *
 * @param ms time to delay.
 *
 * @returns Promise
 */
export const sleep = (ms: number) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, ms);
    });

/**
 * Swaps the elements at positions `fromIndex` and `toIndex` in `arr`.
 *
 * @param arr
 * @param fromIndex
 * @param toIndex
 *
 * @returns A copy of the array with the elements swapped.
 */
export const swap = <T>(arr: T[], fromIndex: number, toIndex: number): T[] => {
    const copy = [...arr];
    [copy[fromIndex], copy[toIndex]] = [copy[toIndex], copy[fromIndex]];
    return copy;
};

/**
 * This function calculates the next first visible index on a ListV1 option focus.
 *
 * @param focusedIndex The clicked item's index
 * @param firstVisibleIndex The current first visible index
 * @param visibleItems The list visible items
 * @param listLength The length of the items in the list
 * @param offset When should the animation happen
 *  - (n elements before first visible index/n elements before end visible index)
 *
 * @returns {number} The next firstVisibleIndex to be set.
 */
export const calculateNextFirstVisibleIndex = (
    focusedIndex: number,
    firstVisibleIndex: number,
    visibleItems: number,
    listLength: number,
    offset: number = 0
): number => {
    const lastPossibleFirstVisibleIndex = listLength - visibleItems;
    const firstPossibleFocusedIndex = firstVisibleIndex + offset;
    const lastPossibleLastFocusedIndex = firstVisibleIndex + visibleItems - offset;

    return focusedIndex < firstPossibleFocusedIndex
        ? Math.max(0, firstVisibleIndex - 1)
        : focusedIndex >= lastPossibleLastFocusedIndex
          ? Math.min(firstVisibleIndex + 1, lastPossibleFirstVisibleIndex)
          : firstVisibleIndex;
};
