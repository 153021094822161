import { Exception } from '__SMART_APP_OLD__/app/modules/Error/Exception';
import { ErrorDispatcher } from '__SMART_APP_OLD__/app/modules/Error/types/ErrorDispatcher';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const occured = (dispatcher: ErrorDispatcher, err: unknown): Thunk<void> => () => {
    console.error(`[${dispatcher}]: `, err);
    if (!(err instanceof Array)) return;
    const errors = err.filter<Exception>((error): error is Exception => error instanceof Exception);
    if (!errors.length) return;
    console.error(`[${dispatcher}]: `, errors);

    // Iterate Errors
    // If Error hidden - return
    // If Error notification dispatch notification show
    // If Error code specific dispatch(errorShow(ErrorGenerator[ErrorType.TV_ERROR]({tit,})))
    //
    // If Error overlay dispatch error show
    // dispatch(errorShow(ErrorGenerator[ErrorType.TV_ERROR](error)))
};

export const errorActions = {
    public: {
        occured,
    },
};
