import React from 'react';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { useElement as useElementHook } from 'App/Packages/Focus/Hooks/useElement';

import { Div } from 'App/Packages/UI/Div';
import { Icon } from 'App/Packages/UI/Icon';
import { Constants } from 'App/Packages/UI/Detail/Constants';

export type DetailShowMoreProps = {
    onEnter: () => void;
};

export type DetailShowMoreComponent = React.FunctionComponent<DetailShowMoreProps>;

export const DetailShowMore: DetailShowMoreComponent = (props) => {
    const { isFocused } = useElementHook({ ctx: Constants.Action.ShowMore.Ctx });

    const { onClick, onHover } = useFocusableHook({
        id: Constants.Action.ShowMore.Ctx,
        ctx: Constants.Action.ShowMore.Ctx,
        onEnter: props.onEnter,
        isFocused,
    });

    return (
        <Div className="detail__icon-show-more" onClick={onClick} onHover={onHover}>
            <Icon.ShowMore isFocused={isFocused} />
        </Div>
    );
};
