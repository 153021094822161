/* eslint-disable max-lines */
import { by639_1 } from 'iso-language-codes';
import { DeepPartial } from 'redux';

import {
    Language,
    ParsedConfigServiceConfig,
    ParsedConfigServiceConfigContentMarkerConfig,
    ParsedConfigServiceConfigEPGConfig,
    ParsedConfigServiceConfigFTIConfig,
    ParsedConfigServiceConfigFTIEducationScreenContentItem,
    ParsedConfigServiceConfigHomeConfig,
    ParsedConfigServiceConfigImageConfig,
    ParsedConfigServiceConfigLanguageConfig,
    ParsedConfigServiceConfigLayoutConfig,
    ParsedConfigServiceConfigLoginConfig,
    ParsedConfigServiceConfigMenuConfig,
    ParsedConfigServiceConfigMetadata,
    ParsedConfigServiceConfigPinConfig,
    ParsedConfigServiceConfigPlayerConfig,
    ParsedConfigServiceConfigPrivacyPolicyConfig,
    ParsedConfigServiceConfigSearchConfig,
    ParsedConfigServiceConfigSettingsConfig,
    ParsedConfigServiceConfigSettingsElement,
    ParsedConfigServiceConfigStandByConfig,
    ParsedConfigServiceVodUpsellConfig,
    ParsedIdpLoginSettingConfig,
} from '__SMART_APP_OLD__/app/modules/Config/types';
import {
    ConfigServiceConfig,
    ConfigServiceConfigDataSourceConfig,
    ConfigServiceConfigFTIItem,
    ConfigServiceConfigLanguageSettingsItem,
    ConfigServiceConfigMenuItem,
    ConfigServiceConfigSettingsItem,
    ConfigServiceConfigUARConfig,
    ContentMarkerConfig,
} from '__SMART_APP_OLD__/app/modules/ConfigService/ConfigServiceConfig';
import { DeviceName } from '__SMART_APP_OLD__/app/platform/types';
import { SettingsElementType, SettingsItemType, SettingsLanguageElementType } from '__SMART_APP_OLD__/app/types';
import { JsonValueOrDefaultValue } from '__SMART_APP_OLD__/utils/Utils';

import { Env } from 'App/Env';
import { Screen } from 'App/Modules/Screen';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { DataSourceMethod, DataSourceService } from 'App/Types/DataSource';
import { Environment } from 'App/Types/Environment';
import { MenuItemType } from 'App/Types/Menu';
import { Opco } from 'App/Types/Opco';

/**
 * Namespace for ConfigService related functionality
 * @namespace Config
 */

export const SERVICE_ENDPOINT: Record<Environment, Record<Opco, string>> = {
    [Environment.Development]: {
        [Opco.Austria]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Bulgaria]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Croatia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Macedonia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Slovenia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Serbia]: 'https://3ss-config-service.a1.bg/api/config/v2',
    },
    [Environment.Integration]: {
        [Opco.Austria]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Bulgaria]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Croatia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Macedonia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Slovenia]: 'https://3ss-config-service.a1.bg/api/config/v2',
        [Opco.Serbia]: 'https://3ss-config-service.a1.bg/api/config/v2',
    },
    [Environment.Production]: {
        [Opco.Austria]: 'https://www.xploretv.at/controlcenter/config.json',
        [Opco.Bulgaria]: 'https://smarttv-config.a1xploretv.bg/config/config.json',
        [Opco.Croatia]: 'https://go.a1xploretv.hr/smartvconfig/config.json',
        [Opco.Macedonia]: 'https://www.xploretv.mk/smartvconfig/config.json',
        [Opco.Slovenia]: 'https://xploretv.si/smarttv/config.json',
        [Opco.Serbia]: 'https://xploretv.rs/rsconfigs/config.json',
    },
    [Environment.Business]: {
        [Opco.Austria]: 'https://www.xploretv.at/controlcenter/config.json',
        [Opco.Bulgaria]: 'https://smarttv-config.a1xploretv.bg/configsmartvbusiness/config.json',
        [Opco.Croatia]: 'https://go.a1xploretv.hr/smartvconfig/config.json',
        [Opco.Macedonia]: 'https://www.xploretv.mk/smartvconfig/config.json',
        [Opco.Slovenia]: 'https://xploretv.si/smarttv/config.json',
        [Opco.Serbia]: 'https://xploretv.rs/rsconfigs/config.json',
    },
};

/**
 * Default Configuration
 */
export const DEFAULT: ParsedConfigServiceConfig = {
    dataSource: {
        [DataSourceService.Auth]: {
            [DataSourceMethod.CheckToken]: '',
            [DataSourceMethod.CloneToken]: '',
            [DataSourceMethod.GetToken]: '',
            [DataSourceMethod.GraphQL]: '',
            [DataSourceMethod.Login]: '',
            [DataSourceMethod.Logout]: '',
            [DataSourceMethod.SessionHB]: '',
        },
        [DataSourceService.Drm]: {
            [DataSourceMethod.LicenseServerPlayReady]: '',
            [DataSourceMethod.LicenseServerWidevine]: '',
        },
    },
    metadata: { configId: '', createdAt: 0, createdBy: '', title: '', version: 0 },
    layout: { collectionMaxItems: 5 },
    uar: {
        endpoint: '',
        batchBuffer: { maxRetries: 5, maxSize: 100, timer: 60 },
        retryBuffer: { maxSize: 100000, purgeSize: 0, requestSize: 500, timer: 300 },
        metrics: { timer: 900 },
    },
    pin: { allowedWrongAttempts: 3, sessionDuration: 30000, lockDuration: 60000 },
    standBy: {
        countdown: 60000,
        inactivityTimer: 14400000,
        activated: true,
        screenSaverImg: 'https://smarttv-config.a1xploretv.bg/images/screensaver.png',
    },
    search: { keyThreshold: 3, debounce: 500, enabled: true },
    player: {
        showCatchupIcon: false,
        bookmarkStartTimeout: 180000,
        bookmarkThreshhold: 95,
        resumeThreshold: 100,
        radioFallbackImage: '',
        bingeWatchingThreshold: 30000,
        channelSwitchDialogTimeout: 1000,
        vodEndedInactivityTimeout: 10000,
        isContentDiscoveryEnabled: true,
        isBingeWatchingEnabled: true,
        hideControlsAfter: 5000,
        drmProtection: true,
        liveCatchupPlaybackThreshold: 0,
        skipBackInterval: 30,
        skipForwardInterval: 60,
        startoverMinPositionMs: 10000,
        playerSettings: {},
        subtitleConfig: {
            subFontSizes: [],
            subFontStyles: [],
            isSubtitlesEditable: false,
        },
        playerSettingsLowLatency: {},
        playerSettingsCatchup: {},
        lowLatencyChannelsList: [],
        householdTrackList: [],
        restrictivePlayerChannelSwitch: false,
        showSeriesSeason: true,
    },
    home: { inactivityRedirect: { enabled: false, timeout: 0 }, player: { enabled: false, muted: true, wideView: false } },
    image: {
        background: { landscape: 1080, portrait: 780, width: 1920 },
        logo: { large: { height: 216, width: 384 }, small: { height: 29, width: 75 }, default: { width: 41, height: 112 } },
        thumbnail: { large: { height: 808 }, small: { height: 200 }, default: { width: 0, height: 280 } },
    },
    epg: { visibility: { future: 7560000, past: 7560000 }, primeTime: { time: 68400000, timezone: 'GMT+3' } },
    login: {},
    privacyPolicy: { lastChangedTS: 0, showLegacyGdpr: false },
    fti: { regionOptionsIdsToFilter: [], educationScreenContent: [], screens: [] },
    menu: { items: [], ids: [], titleForMenuItemType: {} },
    settings: { elements: [], languageElements: [] },
    language: {
        translationsUrlForLanguageCode: {},
        availableAudioLanguages: {},
        availableSubtitleLanguages: {},
        defaultUILanguageCode: '',
        defaultAudioLanguageCode: '',
        defaultSubtitleLanguageCode: '',
        defaultUiLanguage: 'en',
    },
    contentMarkers: {},
    reminder: { timer: 30 },
    eventNotAvailable: { timer: 30 },
    vodUpsellConfig: {
        [DeviceName.SAMSUNG_TV]: {
            rentIsAllowed: false,
            subscribeIsAllowed: false,
            purchaseIsAllowed: false,
        },
        [DeviceName.LG_TV]: {
            rentIsAllowed: false,
            subscribeIsAllowed: false,
            purchaseIsAllowed: false,
        },
        [DeviceName.HISENSE_TV]: {
            rentIsAllowed: false,
            subscribeIsAllowed: false,
            purchaseIsAllowed: false,
        },
        [DeviceName.PHILIPS_TV]: {
            rentIsAllowed: false,
            subscribeIsAllowed: false,
            purchaseIsAllowed: false,
        },
        [DeviceName.PC]: {
            rentIsAllowed: false,
            subscribeIsAllowed: false,
            purchaseIsAllowed: false,
        },
    },
    idpLogin: {
        activated: false,
        url: '',
        clientId: '',
        clientSecret: '',
        clientScope: '',
        grantType: '',
    },
    recording: {
        isSeriesRecordingAllowed: false,
    },
};

/**
 * @memberof Config
 * @description Function that merges together a DeepPartial and a Default \
 * to create new object containing all present fields in DeepPartial and all the other
 * to be from Default
 * @author SmartTVBG@a1.bg
 * @date 9/12/2023 - 5:15:11 PM
 * @template T
 * @template G
 * @param partial object that can contain all properties
 * from T but not all are present
 * @param obj default value to replace null/undefined properties in partial
 * @param transformer function that will be called on every
 * entry that is parsed to mutate the final result
 * @returns partical config after replacing default values with be values if available
 */
const applyPartialConfig = <T extends { [key: string]: any } = any>(
    partial: DeepPartial<T> | undefined,
    obj: T,
    transformer?: <G>(payload: G) => G
): T => {
    const transform = transformer ?? (<G>(payload: G): G => payload);
    if (!partial) return obj;
    const keys = Object.keys(obj) as (keyof T)[];
    return keys.reduce<T>(
        (result, key) => {
            const current = result[key];
            const partialCurrent = partial[key] as DeepPartial<T[keyof T]>;
            if (partialCurrent === null || partialCurrent === undefined) return result;
            if (typeof partialCurrent !== 'object') result[key] = transform(partialCurrent ?? current);
            else result[key] = applyPartialConfig<T[keyof T]>(partialCurrent, current, transform);
            return result;
        },
        { ...obj }
    );
};

/**
 * @memberof Config
 * @description Function that gets metainfo from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns control center config metadata
 */
const getParsedConfigMetadataFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigMetadata =>
    applyPartialConfig(
        {
            configId: config?.api_config?.config_id,
            createdAt: config?.version_metadata?.created_at,
            createdBy: config?.version_metadata?.created_by,
            title: config?.version_metadata?.version_title,
            version: config?.version_metadata?.version_number,
        },
        DEFAULT.metadata
    );

const dataSourceTransformer = <G>(payload: G): G => {
    try {
        if (typeof payload !== 'string') return payload;
        if (!Env.IsLocalDevelopment) return payload;
        const url = new URL(payload);
        if (url.pathname === '/') return payload;
        return `${window.location.origin}${url.pathname}${url.search}` as unknown as G;
    } catch (error) {
        return '' as unknown as G;
    }
};

/**
 * @memberof Config
 * @description Function that groups layout specific data from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns layout config
 */
const getParsedLayoutConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigLayoutConfig => ({
    collectionMaxItems: config.app_config?.miscellanious?.max_items_collection ?? DEFAULT.layout.collectionMaxItems,
});

/**
 * @memberof Config
 * @description Function that groups home screen specific data from config service response
 * with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns home page config
 */
const getParsedHomeConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigHomeConfig => ({
    inactivityRedirect: {
        timeout: config?.app_config?.player_settings?.home_screen_player?.inactivity_redirect_timeout ?? 10000,
        enabled: config?.app_config?.player_settings?.home_screen_player?.redirect_after_inactivity ?? false,
    },
    player: {
        enabled: config?.app_config?.player_settings?.home_screen_player?.enabled ?? false,
        muted: config?.app_config?.player_settings?.home_screen_player?.muted ?? true,
        wideView: config?.app_config?.player_settings?.home_screen_player?.home_player_wide_view ?? false,
    },
});

/**
 * @memberof Config
 * @description Function that gets pin settings from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed pin config
 */
const getParsedPinConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigPinConfig =>
    applyPartialConfig(
        {
            allowedWrongAttempts: config?.app_config?.pin_settings?.allowed_wrong_attempts,
            lockDuration: config?.app_config?.pin_settings?.wrong_attempts_lock_duration,
            sessionDuration: config?.app_config?.pin_settings?.pin_session_expiry,
        },
        DEFAULT.pin
    );

/**
 * @memberof Config
 * @description Function that gets standby settings from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed standby config
 */
const getParsedStandByConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigStandByConfig =>
    applyPartialConfig(
        {
            activated: config?.app_config?.standby_settings?.standby_activated,
            countdown: config?.app_config?.standby_settings?.countdown,
            inactivityTimer: config?.app_config?.standby_settings?.inactivity_timer,
            screenSaverImg: config?.app_config?.standby_settings?.screen_saver_img,
        },
        DEFAULT.standBy
    );

/**
 * @memberof Config
 * @description Function that gets search settings from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 *  parsed pin config
 * @returns void
 */
const getParsedSearchConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigSearchConfig =>
    applyPartialConfig(
        {
            keyThreshold: config?.app_config?.search_settings?.key_threshold,
            debounce: config?.app_config?.search_settings?.trigger_timeout,
            enabled: config?.feature_control?.search,
        },
        DEFAULT.search
    );

/**
 * @memberof Config
 * @description  Function that gets config for privacy policy
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed privacy policy config
 */
const getParsedPrivacyPolicyConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigPrivacyPolicyConfig => ({
    lastChangedTS: config.app_config?.miscellanious?.gdpr_accepted ?? 0,
    showLegacyGdpr: config.app_config?.miscellanious?.show_legacy_gdpr ?? false,
});

/**
 * @memberof Config
 * @description Function that gets Login config from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed login config
 */
const getParsedLoginConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigLoginConfig => ({
    resetPasswordQRCode: Env.IsBulgaria || Env.IsCroatia ? config?.app_config?.miscellanious?.reset_pass_qr_code : '',
});

/**
 * @memberof Config
 * @description Function that gets EPG config from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed epg config
 */
const getParsedEPGConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigEPGConfig =>
    applyPartialConfig(
        {
            visibility: {
                future: config?.app_config?.epg_settings?.future_visibility,
                past: config?.app_config?.epg_settings?.past_visibility,
            },
            primeTime: {
                time: config?.app_config?.epg_settings?.prime_time,
                timezone: config?.app_config?.epg_settings?.prime_time_dst,
            },
        },
        DEFAULT.epg
    );

/**
 * @memberof Config
 * @description  Function that gets config for image size configuration
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed image config
 */
const getParsedImageConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigImageConfig =>
    applyPartialConfig(
        {
            background: config?.app_config?.miscellanious?.imagesettings?.background,
            thumbnail: {
                large: { height: config?.app_config?.miscellanious?.imagesettings?.thumbnail?.xlHeight },
                small: { height: config?.app_config?.miscellanious?.imagesettings?.thumbnail?.xsHeight },
                default: {
                    height: config?.app_config?.miscellanious?.imagesettings?.thumbnail?.height,
                    width: config?.app_config?.miscellanious?.imagesettings?.thumbnail?.width,
                },
            },
            logo: {
                large: {
                    height: config?.app_config?.miscellanious?.imagesettings?.logo?.largeHeight,
                    width: config?.app_config?.miscellanious?.imagesettings?.logo?.largeWidth,
                },
                small: {
                    height: config?.app_config?.miscellanious?.imagesettings?.logo?.smallHeight,
                    width: config?.app_config?.miscellanious?.imagesettings?.logo?.smallWidth,
                },
                default: {
                    height: config?.app_config?.miscellanious?.imagesettings?.logo?.mediumHeight,
                    width: config?.app_config?.miscellanious?.imagesettings?.logo?.mediumWidth,
                },
            },
        },
        DEFAULT.image
    );

/**
 * @memberof Config
 * @description Function that gets player settings from config service response with fallback
 * values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed player config
 */
const getParsedPlayerConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigPlayerConfig => {
    const playerConfig = applyPartialConfig(
        {
            showCatchupIcon: config.app_config?.miscellanious?.show_catchup_icon,
            bingeWatchingThreshold: config?.app_config?.player_settings?.binge_watching_threshold,
            bookmarkStartTimeout: config?.app_config?.player_settings?.bookmark_start_timeout,
            bookmarkThreshhold: config?.app_config?.player_settings?.bookmark_threshold,
            channelSwitchDialogTimeout: config?.app_config?.player_settings?.channel_switch_player_timeout,
            vodEndedInactivityTimeout: config?.app_config?.player_settings?.vod_ended_inactivity_timeout,
            isContentDiscoveryEnabled: config?.app_config?.player_settings?.is_content_discovery_enabled,
            isBingeWatchingEnabled: config?.app_config?.player_settings?.is_binge_watching_enabled,
            drmProtection: config?.app_config?.player_settings?.drm_protection,
            hideControlsAfter: config?.app_config?.player_settings?.hide_controls_after,
            liveCatchupPlaybackThreshold: config?.app_config?.player_settings?.live_catchup_playback_threshold,
            resumeThreshold: config?.app_config?.miscellanious?.resume_threshold,
            skipBackInterval: config?.app_config?.player_settings?.skip_back_interval,
            skipForwardInterval: config?.app_config?.player_settings?.skip_forward_interval,
            startoverMinPositionMs: config?.app_config?.player_settings?.startover_min_position_ms,
            restrictivePlayerChannelSwitch: config?.app_config?.player_settings?.restrictive_player_channel_switch,
            showSeriesSeason: config?.app_config?.player_settings?.show_series_season,
        },
        DEFAULT.player
    );
    playerConfig.lowLatencyChannelsList = JsonValueOrDefaultValue(config?.app_config?.player_settings?.low_latency_channels, []);
    playerConfig.householdTrackList = JsonValueOrDefaultValue(config?.app_config?.player_settings?.household_track_list, []);
    playerConfig.playerSettings = config?.app_config?.player_settings?.castlabs_settings ?? {};
    playerConfig.playerSettingsLowLatency = config?.app_config?.player_settings?.castlabs_setting_low_latency ?? {};
    playerConfig.playerSettingsCatchup = config?.app_config?.player_settings?.castlabs_settings_catchup ?? {};
    playerConfig.subtitleConfig.subFontSizes = config.app_config?.player_settings?.sub_font_sizes ?? [];
    playerConfig.subtitleConfig.subFontStyles = config?.app_config?.player_settings?.sub_font_styles ?? [];
    playerConfig.subtitleConfig.isSubtitlesEditable = config?.app_config?.player_settings?.is_subtitle_editable ?? false;
    return playerConfig;
};

/**
 * @memberof Config
 * @description Util functions that creates a map of langCode: url - to poEditor for translations
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed language confing
 */
const getTranslationsForLanguageCode = (config: ConfigServiceConfig): Record<string, string> => {
    const languages = config?.app_config?.languages ?? {};
    return Object.keys(languages).reduce<Record<string, string>>((translations, key) => {
        if (languages[key]?.url && !languages[key]?.disabled) {
            const lang = by639_1[key as keyof typeof by639_1].iso639_2T;
            translations[lang] = languages[key]?.url!;
        }
        return translations;
    }, {});
};

/**
 * @memberof Config
 * @description Util function that finds the default ui language
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns  default ui language configured in control center
 */
const getDefaultUILanguage = (config: ConfigServiceConfig): string => {
    const languages = config?.app_config?.languages ?? {};
    const defaultCode = Object.keys(languages).find((key) => languages[key]?.url && !languages[key]?.disabled && languages[key]?.default);
    const firstCode = Object.keys(languages).find((key) => languages[key]?.url && !languages[key]?.disabled);
    const code = defaultCode ?? firstCode ?? 'en';
    return by639_1[code as keyof typeof by639_1].iso639_2T;
};

/**
 * @memberof Config
 * @description Util functions that maps 2 character lang codes to
 * (3 lang codes / translations keys)
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @param key
 *
 *  key language mapping key
 * from config.app_config.audio_subtitles
 * @returns parsed language mapping
 */
const getLanguageMapping = (config: ConfigServiceConfig, key: string): Record<string, Language> => {
    const mappedLanguages: Record<string, Language> = {};
    (config?.app_config?.audio_subtitles as any)?.[key].forEach((langCode: string) => {
        switch (langCode) {
            case 'off':
                mappedLanguages[langCode] = {
                    translationKey: 'SETTINGS_OFF',
                    languageCode_639_1: 'none',
                    languageCode_639_2T: 'none',
                };
                break;
            case 'ola':
                mappedLanguages[langCode] = {
                    translationKey: 'SETTINGS_ORIGINAL',
                    languageCode_639_1: 'ola',
                    languageCode_639_2T: 'ola',
                };
                break;
            case 'mlt':
            case 'mt':
                mappedLanguages[langCode] = {
                    translationKey: 'SETTINGS_MULTILINGUAL',
                    languageCode_639_1: 'mt',
                    languageCode_639_2T: 'mlt',
                };
                break;
            case 'zho':
            case 'zh':
                mappedLanguages[langCode] = {
                    translationKey: 'SETTINGS_ZH_TW',
                    languageCode_639_1: 'zh',
                    languageCode_639_2T: 'zho',
                };
                break;
            default:
                mappedLanguages[langCode] = {
                    translationKey: `SETTINGS_${langCode.toUpperCase()}`,
                    languageCode_639_1: langCode,
                    languageCode_639_2T: by639_1[langCode as keyof typeof by639_1].iso639_2T,
                };
        }
    });

    return mappedLanguages;
};

/**
 * @memberof Config
 * @description  Function that gets config for the settings menu items
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed language object config
 */
const getParsedLanguageConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigLanguageConfig => ({
    translationsUrlForLanguageCode: getTranslationsForLanguageCode(config),
    availableAudioLanguages: getLanguageMapping(config, 'available_audio_languages_version_3'),
    availableSubtitleLanguages: getLanguageMapping(config, 'available_subtitles_languages_version_3'),
    defaultUILanguageCode: getDefaultUILanguage(config),
    defaultAudioLanguageCode: config.app_config?.audio_subtitles?.default_audio_track ?? '',
    defaultSubtitleLanguageCode: config.app_config?.audio_subtitles?.default_subtitle_track ?? '',
    defaultUiLanguage: getDefaultUILanguage(config),
});

/**
 * @memberof Config
 * @description Function that gets config for the navigation menu items
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed menu configuration
 */
const getParsedMenuConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigMenuConfig => ({
    items: (config?.content_config?.main_menu ?? [])
        .concat(Env.IsLocalDevelopment ? [{ id: MenuItemType.Playground, title: 'Playground' }] : [])
        .filter(
            (item) => !!item && item.id && Object.values(MenuItemType).includes(item.id) && !!item.title
        ) as ConfigServiceConfigMenuItem[],

    ids: (config?.content_config?.main_menu ?? [])
        .map<MenuItemType | undefined>((item) => item?.id)
        .concat(Env.IsLocalDevelopment ? [MenuItemType.Playground] : [])
        .filter<MenuItemType>((item): item is MenuItemType => !!item),

    titleForMenuItemType: (config?.content_config?.main_menu ?? []).reduce<Record<MenuItemType, string>>(
        (acc, item) => ({
            ...acc,
            ...(item?.id ? { [item.id]: item.title ?? '' } : {}),
        }),
        {
            [MenuItemType.Playground]: 'Playground',
        } as Record<MenuItemType, string>
    ),
});

/**
 * @memberof Config
 * @description Util function that maps config service settings item to application settings item
 * @author SmartTVBG@a1.bg
 * @template P
 * @param item
 * settings item from config service response
 * @returns settings items configuration
 * {ParsedConfigServiceConfigSettingsElement<P>}
 */
const mapConfigSettingsItemToSettingsItem = <P extends SettingsElementType | SettingsLanguageElementType>(
    item: P extends SettingsElementType ? ConfigServiceConfigSettingsItem : ConfigServiceConfigLanguageSettingsItem
): ParsedConfigServiceConfigSettingsElement<P> => ({
    id: (item?.id ?? '') as P,
    type: (item?.type ?? '') as SettingsItemType,
    title: item?.title ?? '',
    info: item?.info ?? '',
});

/**
 * @memberof Config
 * @description Util functions that checks if the provided item is valid and enabled
 * @author SmartTVBG@a1.bg
 * @template T
 * @param item mapped item
 * from config service response to app settings item
 * @param elementType
 * enum object
 * @returns filtered settings items based on visibility prop
 */
const filterSettingsElement = <T extends SettingsElementType | SettingsLanguageElementType>(
    item: Partial<ParsedConfigServiceConfigSettingsElement<T>>,
    elementType: T extends SettingsElementType ? typeof SettingsElementType : typeof SettingsLanguageElementType
): boolean => !!item && !!item.type && Object.values(elementType).includes(item.id) && Object.values(SettingsItemType).includes(item.type);

/**
 * @memberof Config
 * @description Function that gets config for the settings menu items
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns settings object config
 */
const getParsedSettingsConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigSettingsConfig => ({
    elements: ((config?.content_config?.settings_menu ?? []) as ConfigServiceConfigSettingsItem[])
        .map((item) => mapConfigSettingsItemToSettingsItem<SettingsElementType>(item))
        .filter((item) => filterSettingsElement(item, SettingsElementType)),
    languageElements: ((config?.app_config?.settings_items?.language_elements ?? []) as ConfigServiceConfigLanguageSettingsItem[])
        .filter((item) => item?.enabled)
        .map((item) => mapConfigSettingsItemToSettingsItem<SettingsLanguageElementType>(item))
        .filter((item) => filterSettingsElement(item, SettingsLanguageElementType)),
});

const screenTypeForName: Record<string, Screen.Type> = {
    welcomeScreen: Screen.Type.WELCOME,
    languageSelection: Screen.Type.LANGUAGE_SELECTION,
    pinCode: Screen.Type.MASTER_PIN_CODE,
    ageRating: Screen.Type.AGE_RATING_SELECTION,
    appearance: Screen.Type.APPEARANCE,
    privacyPolicy: Screen.Type.PRIVACY_POLICY,
    region: Screen.Type.COMMUNITY_SELECTION,
    viewControl: Screen.Type.REPLAY_SELECTION,
    closingScreen: Screen.Type.CLOSING,
    startupBehavior: Screen.Type.STARTUP_BEHAVIOR,
    custom: Screen.Type.EDUCATION,
};

/**
 * @memberof Config
 * @description Util Function to filter and map fti screens data from config service response
 * @author SmartTVBG@a1.bg
 * @param [screens] config data from Config Service response
 * containing information about fti screens
 * @returns  fti config screens based on visibility prop
 */
const mapConfigServiceFTIScreensToConfigFTIScreens = (screens: DeepPartial<ConfigServiceConfigFTIItem[]> = []): Screen.Type[] =>
    screens
        .filter((screen) => !!screen && screen.enabled && screenTypeForName[screen?.name ?? ''])
        .map((screen) => screenTypeForName[screen?.name ?? '']);

const getEducationScreenContent = (config: ConfigServiceConfig): ParsedConfigServiceConfigFTIEducationScreenContentItem[] => {
    const screen = (config?.app_config?.fti?.screens ?? []).find((s) => s?.name === 'custom');
    if (!screen || !screen.enabled || !screen.content || typeof screen.content !== 'object' || !screen.content.length) return [];
    return (screen.content ?? []).map((content) => ({
        id: content.uniq_id,
        image: content.image,
        text: content.text,
        title: content.title,
        timestamp: new Date(content.timestamp).getTime(),
    }));
};

const getParsedContentMarkers = (config: ConfigServiceConfig): ParsedConfigServiceConfigContentMarkerConfig => {
    const configMap = config.app_config?.content_markers ?? {};
    return Object.keys(configMap).reduce((result, key) => {
        const marker: ContentMarkerConfig | undefined = configMap[key as unknown as ContentMarkerType];
        if (!marker) return result;
        result[key as unknown as ContentMarkerType] = {
            container: { backgroundColor: marker.background, opacity: marker.transparency },
            color: marker.color,
        };
        return result;
    }, {} as ParsedConfigServiceConfigContentMarkerConfig);
};

/**
 * @memberof Config
 * @description Function that gets config for the FTI flow
 * from config service response with fallback values form DEFAULT config
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed fti config
 */
const getParsedFTIConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceConfigFTIConfig => ({
    regionOptionsIdsToFilter: (config?.app_config?.fti?.region_options_ids_to_filter as string[]) ?? DEFAULT.fti.regionOptionsIdsToFilter,
    educationScreenContent: getEducationScreenContent(config),
    screens: mapConfigServiceFTIScreensToConfigFTIScreens(config?.app_config?.fti?.screens),
});

/**
 * @memberof Config
 * @description function returning the configured vod upsell configuration
 * in control center or default value
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed vod upsell config
 */
const getParsedVodUpsellConfigFromConfig = (config: ConfigServiceConfig): ParsedConfigServiceVodUpsellConfig =>
    applyPartialConfig(config.app_config?.miscellanious?.vod_upsell_allowed, DEFAULT.vodUpsellConfig);

/**
 * @memberof Config
 * @description function returning the configured idp login configuration
 * in control center or default value
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns parsed idp login configuration
 */
const getParsedIdpLoginConfigFromConfig = (config: ConfigServiceConfig): ParsedIdpLoginSettingConfig =>
    applyPartialConfig(config.app_config?.idp_auth?.settings, DEFAULT.idpLogin);

/**
 * @memberof Config
 * @description Main function that maps config service config to app config that is then stored
 * in redux
 * @author SmartTVBG@a1.bg
 * @param config configuration response from Config Service
 * @returns config object combining all separate configs in one parsed mapped object
 */
export const parse = (config: ConfigServiceConfig): ParsedConfigServiceConfig => ({
    metadata: getParsedConfigMetadataFromConfig(config),
    dataSource: applyPartialConfig<ConfigServiceConfigDataSourceConfig>(
        config?.api_config?.routes,
        DEFAULT.dataSource,
        dataSourceTransformer
    ),
    uar: applyPartialConfig<ConfigServiceConfigUARConfig>(config?.app_config?.uar?.logging_config, DEFAULT.uar),
    layout: getParsedLayoutConfigFromConfig(config),
    image: getParsedImageConfigFromConfig(config),
    home: getParsedHomeConfigFromConfig(config),
    pin: getParsedPinConfigFromConfig(config),
    standBy: getParsedStandByConfigFromConfig(config),
    search: getParsedSearchConfigFromConfig(config),
    epg: getParsedEPGConfigFromConfig(config),
    login: getParsedLoginConfigFromConfig(config),
    menu: getParsedMenuConfigFromConfig(config),
    player: getParsedPlayerConfigFromConfig(config),
    language: getParsedLanguageConfigFromConfig(config),
    settings: getParsedSettingsConfigFromConfig(config),
    privacyPolicy: getParsedPrivacyPolicyConfigFromConfig(config),
    vodUpsellConfig: getParsedVodUpsellConfigFromConfig(config),
    fti: getParsedFTIConfigFromConfig(config),
    contentMarkers: getParsedContentMarkers(config),
    idpLogin: getParsedIdpLoginConfigFromConfig(config),
    reminder: { timer: config?.app_config?.miscellanious?.reminder_timer ?? DEFAULT.reminder.timer },
    eventNotAvailable: { timer: config?.app_config?.miscellanious?.event_not_available_timer ?? DEFAULT.eventNotAvailable.timer },
    recording: {
        isSeriesRecordingAllowed:
            config?.app_config?.miscellanious?.is_series_recording_allowed ?? DEFAULT.recording.isSeriesRecordingAllowed,
    },
});
