import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeyboardRowProps = DivPropsExtendable & {};

export type KeyboardRowComponent = React.FunctionComponent<KeyboardRowProps>;

export const KeyboardRow: KeyboardRowComponent = (props) => {
    const { children, ...other } = props;
    return (
        <Div defaultClassName="keyboard__row" {...other}>
            {children}
        </Div>
    );
};
