import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { Folder } from 'App/Modules/Folder';

const select: Selector<Folder.List> = (state) => state.dataV2.search.results;

export const selectors = {
    public: {
        select,
        selectId: Folder.selectors.list.selectId(select),
        selectCount: Folder.selectors.list.selectCount(select),
        selectFolder: Folder.selectors.list.selectFolder(select),
        selectFoldersIds: Folder.selectors.list.selectFoldersIds(select),
        selectFoldersEntities: Folder.selectors.list.selectFoldersEntities(select),
    },
    private: {},
} as const;
