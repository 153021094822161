import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export type AlertTextProps = {
    children: React.ReactNode;
};

export type AlertTextModule = {} & React.FunctionComponent<AlertTextProps>;

export const AlertText: AlertTextModule = (props) => (
    <Text className="alert__text" typeface={Typeface.SANS} size={FontSize.BODY_1} weight={FontWeight.REGULAR} color={Color.PRIMARY}>
        {props.children}
    </Text>
);
