import { DeviceEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { Utils } from '__SMART_APP_OLD__/app/utils';

export const selectDevice = (id: string) => (state: State): DeviceEntity | null => state.data.deviceEntityTable.entities?.[id] ?? null;
export const selectDeviceIds = (state: State): string[] => state.data.deviceEntityTable.ids;
export const selectMaxNumberOfUnmanagedDevices = (state: State): number => state.data.deviceEntityTable.maxNumberOfUnmanagedDevices;
export const selectDevicesCount = (state: State): number => selectDeviceIds(state).length;

export const selectIsDeviceEstDto = (id: string) => (state: State): boolean => selectDevice(id)(state)?.isESTDTOEnabled ?? false;

export const selectIsDeviceSTB = (deviceId: string) => (state: State): boolean =>
    Utils.isSTBDevice(selectDevice(deviceId)(state)?.deviceType);

export const selectIsDeviceTimeLocked = (deviceId: string) => (state: State): boolean =>
    Utils.isTimeLockedDevice(selectDevice(deviceId)(state), Time.selectors.select(state));

export const selectIsDeviceLocked = (id: string) => (state: State): boolean =>
    Utils.isCurrentDevice(id) || selectIsDeviceSTB(id)(state) || selectIsDeviceTimeLocked(id)(state);

export const selectRemaingLockedDaysForDevice = (deviceId: string) => (state: State): number => {
    const device = selectDevice(deviceId)(state);
    if (!device) return 0;
    const timestamp = Time.selectors.select(state);
    if (!Utils.isTimeLockedDevice(device, timestamp)) return 0;
    const remainingDays = Math.max(device.removableFrom - timestamp, 0) / 1000 / 60 / 60 / 24;
    const floored = Math.floor(remainingDays);
    return floored === remainingDays ? floored : floored + 1;
};
