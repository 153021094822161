import { Quota } from '__SMART_APP_OLD__/api/graphql/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectRecordingQuota = (state: State): Quota | null => state.data.household.recordingQuota;
const selectMaxNumberOfReplayChannels = (state: State): number => state.data.household.maxNumberOfConfirmedReplayChannels;
const selectHouseholdId = (state: State): number => (state.data.household.householdId ? parseInt(state.data.household.householdId, 10) : 0);
const selectMqttTopics = (state: State): string[] => state.data.household.mqttTopics;
const selectMqttBrokerUrl = (state: State): string | null => state.data.household.mqttBrokerUrl;

export const householdSelectors = {
    public: {
        selectRecordingQuota,
        selectMaxNumberOfReplayChannels,
        selectHouseholdId,
        selectMqttTopics,
        selectMqttBrokerUrl,
    },
};
