import { bookmarksActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/actions';
import { getInitialBookmarksState } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/getInitialState';
import { bookmarksReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/reducer';
import { bookmarksSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/selectors';
import { subscribeToBookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/subscriber';
import {
    BookmarksActionType,
    BookmarksEntity,
    BookmarksEntityTable,
    BookmarksReduxAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';

export { BookmarksActionType as ActionType };

export type { BookmarksEntity as Entity, BookmarksEntityTable as EntityTable, BookmarksReduxAction as ReduxAction };

export const getInitialState = getInitialBookmarksState;

export const actions = bookmarksActions.public;

export const selectors = bookmarksSelectors.public;

export const reducer = bookmarksReducer;

export const subscriber = subscribeToBookmarks;
