import { initialHouseholdState } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/constants';
import { HouseholdActionType, HouseholdEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const householdReducer: Reducer<HouseholdEntityTable> = (state, action) => {
    if (!state) return initialHouseholdState;

    switch (action.type) {
        case HouseholdActionType.MQTT_BROKER_URL_CHANGED:
            if (state.mqttBrokerUrl === action.payload.mqttBrokerUrl) return state;

            return {
                ...state,
                ...action.payload,
            };
        case HouseholdActionType.MQTT_TOPICS_CHANGED:
            if (state.mqttTopics === action.payload.mqttTopics) return state;

            return {
                ...state,
                ...action.payload,
            };
        case HouseholdActionType.RECORDING_QUOTA_LOAD:
            if (state.recordingQuota === action.payload.recordingQuota) return state;

            return {
                ...state,
                ...action.payload,
            };
        case HouseholdActionType.INITIAL_LOAD:
            if (state === action.payload) return state;

            return {
                ...state,
                householdId: action.payload.householdId,
                maxNumberOfConfirmedReplayChannels: action.payload.maxNumberOfConfirmedReplayChannels,
                onboardingInfo: action.payload.onboardingInfo,
            };
        default:
            return state;
    }
};
