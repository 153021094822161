import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ParentalRatingInfoFragmentDoc } from './parentalRating.generated';
export type ProfilePermissionsFragment = { __typename?: 'ProfilePermissions', maskContent: boolean, parentalRating: { __typename?: 'ParentalRating', id: string, title: string, rank: number, adult: boolean } };

export const ProfilePermissionsFragmentDoc = gql`
    fragment profilePermissions on ProfilePermissions {
  maskContent
  parentalRating {
    ...parentalRatingInfo
  }
}
    ${ParentalRatingInfoFragmentDoc}`;