/* eslint-disable complexity */

import { DeepLink } from 'App/Packages/DeepLink';
/* eslint-disable max-statements */
import { batch } from 'react-redux';

import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { MessageActionEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    selectMessageDetailActiveAttachmenId,
    selectMessageDetailAttachment,
    selectMessageDetailFirstPlayableAttachment,
    selectMessageDetailHasActions,
    selectMessageDetailHasAttachments,
    selectMessageDetailHasScrollTriggers,
    selectMessageDetailIsAttachmentView,
    selectMessageDetailIsAttachmentViewAttachmentsVissible,
    selectMessageDetailIsPlayerPreview,
    selectMessageDetailShouldRenderAttachmentContainer,
    selectMessageDetailVisibleContainerIndex,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import {
    MessageDetailActiveAttachmentIdChangeAction,
    MessageDetailAttachmentStatusChangedAction,
    MessageDetailAttachmentViewStatus,
    MessageDetailCTX,
    MessageDetailContainer,
    MessageDetailHeaderChangeAction,
    MessageDetailKnownActionId,
    MessageDetailLayoutChangeAction,
    MessageDetailLayoutType,
    MessageDetailPlayerStatus,
    MessageDetailPlayerStatusChangedAction,
    MessageDetailScrollContainer,
    MessageDetailTextLayoutChangeAction,
    MessageDetailVisibleTextContainerIndexChangeAction,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';
import {
    getPartionedTextContainerLines,
    getScrollContainer,
    getTextContainer,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/utils';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { getOpenMessageEvent, getPlayMessageAttachmentEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

// Action Creators
export const messageDetailActiveAttachmentIdChange = (
    activeAttachmentId: GQL.VODAssetID | null
): MessageDetailActiveAttachmentIdChangeAction => ({
    type: ActionType.MESSAGE_DETAIL_ACTIVE_ATTACHMENT_CHANGE,
    payload: { activeAttachmentId },
});

export const messageDetailLayoutChange = (layout: MessageDetailLayoutType): MessageDetailLayoutChangeAction => ({
    type: ActionType.MESSAGE_DETAIL_LAYOUT_CHANGE,
    payload: { layout },
});

export const messageDetailTextLayoutChange = (textLayout: MessageDetailContainer[]): MessageDetailTextLayoutChangeAction => ({
    type: ActionType.MESSAGE_DETAIL_TEXT_LAYOUT_CHANGE,
    payload: { textLayout },
});

export const messageDetailHeaderChange = (header: 1 | 2): MessageDetailHeaderChangeAction => ({
    type: ActionType.MESSAGE_DETAIL_HEADER_CHANGE,
    payload: { header },
});

export const messageDetailVisibleTextContainerIndexChange = (
    visibleTextContainerIndex: number
): MessageDetailVisibleTextContainerIndexChangeAction => ({
    type: ActionType.MESSAGE_DETAIL_VISIBLE_TEXT_CONTAINER_INDEX_CHANGE,
    payload: { visibleTextContainerIndex },
});

export const messageDetailPlayerStatusChanged = (status: MessageDetailPlayerStatus): MessageDetailPlayerStatusChangedAction => ({
    type: ActionType.MESSAGE_DETAIL_PLAYER_STATUS_CHANGE,
    payload: { status },
});

export const messageDetailAttachmentStatusChanged = (
    status: MessageDetailAttachmentViewStatus
): MessageDetailAttachmentStatusChangedAction => ({
    type: ActionType.MESSAGE_DETAIL_ATTACHMENT_STATUS_CHANGE,
    payload: { status },
});

export const messageDetailReset = (): Thunk<void> => (dispatch) => {
    batch(() => {
        dispatch(messageDetailHeaderChange(1));
        dispatch(messageDetailTextLayoutChange([]));
        dispatch(messageDetailLayoutChange(MessageDetailLayoutType.OBSERVER));
        dispatch(messageDetailVisibleTextContainerIndexChange(-1));
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, Focus.Default));
        dispatch(messageDetailAttachmentStatusChanged(MessageDetailAttachmentViewStatus.HIDDEN));
        dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
        dispatch(messageDetailActiveAttachmentIdChange(null));
    });
};

export const messageDetailPlayerPreviewClosed = (): Thunk<void> => (dispatch, getState) => {
    const isPlayerPreview = selectMessageDetailIsPlayerPreview(getState());
    if (!isPlayerPreview) return;
    dispatch(messageDetailLayoutChange(MessageDetailLayoutType.DETAIL));
    dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));
    Player.stop();
    dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
};

export const messageDetailPlayerStart =
    (trailerId: GQL.VODAssetID): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.LOADING));
        changePlayerVisibility(true);
        await PlayerAPI.setTrailerStream(trailerId);
        Player.play();
        LoggingService.getInstance().logEvent(getPlayMessageAttachmentEvent());
    };

export const messageDetailActionSelected =
    (action: MessageActionEntity): Thunk<void> =>
    (dispatch) => {
        if (action.id === MessageDetailKnownActionId.FULLSCREEN) {
            dispatch(messageDetailLayoutChange(MessageDetailLayoutType.ATTACHMENT));
            dispatch(messageDetailAttachmentStatusChanged(MessageDetailAttachmentViewStatus.VISIBLE));
            dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));
            return;
        }

        if (action.id === MessageDetailKnownActionId.CLOSE) {
            dispatch(messageDetailPlayerPreviewClosed());
            return;
        }

        dispatch(DeepLink.executeActionFromLink(action.uri));
    };

export const messageDetailActionsBlured = (): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const ctx = Screen.selectors.selectCtx(state);
    const isPlayerView = selectMessageDetailIsPlayerPreview(state);
    const isAttachmentView = selectMessageDetailIsAttachmentView(state);
    if (ctx === MessageDetailCTX.ACTION || !isPlayerView || isAttachmentView) return;
    dispatch(messageDetailLayoutChange(MessageDetailLayoutType.DETAIL));
    Player.stop();
    dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
};

export const messageDetailAttachmentSelected =
    (attachmentId: GQL.MessageAttachmentID): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const activeId = selectMessageDetailActiveAttachmenId(state);
        const attachment = selectMessageDetailAttachment(attachmentId)(state);
        const isAttachmentView = selectMessageDetailIsAttachmentView(state);
        if (!attachment) {
            Player.stop();
            dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
            return;
        }
        if (activeId === attachment.id && isAttachmentView) return;
        Player.stop();
        dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
        dispatch(messageDetailActiveAttachmentIdChange(attachment.id));
        dispatch(messageDetailAttachmentStatusChanged(MessageDetailAttachmentViewStatus.VISIBLE));
        dispatch(messageDetailLayoutChange(MessageDetailLayoutType.ATTACHMENT));
        if (attachment.type === GQL.MessageAttachmentType.Vodasset && attachment.trailers.length) {
            dispatch(messageDetailPlayerStart(attachment.trailers[0].id));
        }
    };

export const messageDetailScrollButtonTriggered =
    (container: MessageDetailScrollContainer): Thunk<void> =>
    (dispatch, getState) => {
        const activeIndex = selectMessageDetailVisibleContainerIndex(getState());
        if (container.from !== activeIndex && container.to !== activeIndex) return;
        const index = activeIndex === container.from ? container.to : container.from;
        dispatch(messageDetailVisibleTextContainerIndexChange(index));
    };

export const messageDetailSetStartingPosition = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const playable = selectMessageDetailFirstPlayableAttachment(state);
    if (!playable || playable.type !== GQL.MessageAttachmentType.Vodasset || !playable.trailers.length) {
        dispatch(messageDetailLayoutChange(MessageDetailLayoutType.DETAIL));
        return;
    }
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    dispatch(messageDetailActiveAttachmentIdChange(playable.id));
    dispatch(messageDetailLayoutChange(MessageDetailLayoutType.PLAYER_PREVIW));
    await dispatch(messageDetailPlayerStart(playable.trailers[0].id));
    dispatch(Overlay.actions.unmount());
};

export const messageDetailLayoutObserved =
    (header: 1 | 2, text: number): Thunk<void> =>
    (dispatch, getState) => {
        const shouldRenderAttachmentsConteiner = selectMessageDetailShouldRenderAttachmentContainer(getState());
        const containerLines = getPartionedTextContainerLines(shouldRenderAttachmentsConteiner, header, text);
        const layoutContainers = containerLines.reduce<MessageDetailContainer[]>((containers, lines, index) => {
            const from = containerLines.reduce((acc, crt, idx) => acc + (index > idx ? crt : 0), 0);
            containers.push(getTextContainer(from, from + lines, index));
            if (index !== containerLines.length - 1) containers.push(getScrollContainer(index, index + 1));
            return containers;
        }, []);
        dispatch(messageDetailHeaderChange(header));
        dispatch(messageDetailTextLayoutChange(layoutContainers));
        dispatch(messageDetailVisibleTextContainerIndexChange(0));
        dispatch(messageDetailSetStartingPosition());
    };

export const messageDetailBackTriggered = (): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const isPlayerPreview = selectMessageDetailIsPlayerPreview(state);
    const isAttachmentView = selectMessageDetailIsAttachmentView(state);
    const isAttachmentViewAttachmentsVisible = selectMessageDetailIsAttachmentViewAttachmentsVissible(state);

    if (isAttachmentView && isAttachmentViewAttachmentsVisible) {
        dispatch(messageDetailAttachmentStatusChanged(MessageDetailAttachmentViewStatus.HIDDEN));
        return;
    }

    if (isPlayerPreview || isAttachmentView) {
        dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.OFF));
        Player.stop();
    }

    if (isAttachmentView) {
        dispatch(messageDetailLayoutChange(MessageDetailLayoutType.DETAIL));
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));
        return;
    }
    dispatch(History.actions.pop());
};

export const toMessageDetail =
    (messageId: GQL.MessageID): Thunk<void> =>
    (dispatch) => {
        dispatch(messageDetailReset());
        dispatch(Screen.actions.mount(Screen.Type.MESSAGE_DETAIL, { messageId }));
        LoggingService.getInstance().logEvent(getOpenMessageEvent());
    };

export const messageDetailInitializeCTX = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const hasAttachments = selectMessageDetailHasAttachments(state);
    const hasActions = selectMessageDetailHasActions(state);
    const isPlayerView = selectMessageDetailIsPlayerPreview(state);
    if (isPlayerView) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ACTION));
        return;
    }
    if (hasAttachments) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));

        return;
    }
    if (hasActions) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ACTION));
        return;
    }
    dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.SCROLL));
};

export const messageDetailOutOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch, getState) => {
    const state = getState();
    const hasAttachments = selectMessageDetailHasAttachments(state);
    const hasActions = selectMessageDetailHasActions(state);
    const hasScrollTriggers = selectMessageDetailHasScrollTriggers(state);
    const isPlayerView = selectMessageDetailIsPlayerPreview(state);
    const isAttachmentsView = selectMessageDetailIsAttachmentView(state);
    if (ctx === MessageDetailCTX.ATTACHMENT && isAttachmentsView) return;

    if (ctx === MessageDetailCTX.ATTACHMENT && event.y === -1 && !isPlayerView && hasActions) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ACTION));
        return;
    }

    if (ctx === MessageDetailCTX.ATTACHMENT && event.y === -1 && !isPlayerView && hasScrollTriggers) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.SCROLL));
        return;
    }

    if (ctx === MessageDetailCTX.ACTION && event.y === 1 && !isPlayerView && hasAttachments) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));
        return;
    }

    if (ctx === MessageDetailCTX.ACTION && event.y === -1 && isPlayerView && hasAttachments) {
        dispatch(messageDetailPlayerPreviewClosed());
        return;
    }

    if (ctx === MessageDetailCTX.ACTION && event.y === 1 && !isPlayerView && hasScrollTriggers) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.SCROLL));
        return;
    }

    if (ctx === MessageDetailCTX.SCROLL && event.y === -1 && !isPlayerView && hasActions) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ACTION));
        return;
    }
    if (ctx === MessageDetailCTX.SCROLL && event.y === -1 && !isPlayerView && hasAttachments) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_DETAIL, MessageDetailCTX.ATTACHMENT));
    }
};

export const messageDetailLifeCycle = {
    mount: messageDetailInitializeCTX,
    outOfBounds: messageDetailOutOfBounds,
};
