import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommunityChangeMutationVariables = Types.Exact<{
  communityChangeInput: Types.ChangeHouseholdCommunityInput;
}>;


export type CommunityChangeMutation = { __typename?: 'Nexx4Mutations', changeHouseholdCommunity: { __typename: 'ChangeHouseholdCommunityPayload' } };

export type CommunityChangeFragment = { __typename?: 'Nexx4Mutations', changeHouseholdCommunity: { __typename: 'ChangeHouseholdCommunityPayload' } };

export const CommunityChangeFragmentDoc = gql`
    fragment communityChange on Nexx4Mutations {
  changeHouseholdCommunity(input: $communityChangeInput) {
    __typename
  }
}
    `;
export const CommunityChangeDocument = gql`
    mutation communityChange($communityChangeInput: ChangeHouseholdCommunityInput!) {
  ...communityChange
}
    ${CommunityChangeFragmentDoc}`;
export type CommunityChangeMutationFn = Apollo.MutationFunction<CommunityChangeMutation, CommunityChangeMutationVariables>;

/**
 * __useCommunityChangeMutation__
 *
 * To run a mutation, you first call `useCommunityChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityChangeMutation, { data, loading, error }] = useCommunityChangeMutation({
 *   variables: {
 *      communityChangeInput: // value for 'communityChangeInput'
 *   },
 * });
 */
export function useCommunityChangeMutation(baseOptions?: Apollo.MutationHookOptions<CommunityChangeMutation, CommunityChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityChangeMutation, CommunityChangeMutationVariables>(CommunityChangeDocument, options);
      }
export type CommunityChangeMutationHookResult = ReturnType<typeof useCommunityChangeMutation>;
export type CommunityChangeMutationResult = Apollo.MutationResult<CommunityChangeMutation>;
export type CommunityChangeMutationOptions = Apollo.BaseMutationOptions<CommunityChangeMutation, CommunityChangeMutationVariables>;