export enum ActionType {
    // Notification
    NOTIFICATION_SHOW = '@notification/show',
    NOTIFICATION_HIDE = '@notification/hide',
    NOTIFICATION_STATUS_CHANGE = '@notification/status/change',
    NOTIFICATION_ACTIVE_STATUS_CHANGE = '@notification/activeStatus/change',

    // Config
    CONFIG_CHANGE = '@config/change',

    // Profile
    PROFILE_ID_CHANGE = '@data/profile/profileId/change',
    PRIVACY_POLICY_CHANGE = '@data/profile/policy/change',
    PRIVACY_POLICY_ACCEPT_ALL = '@data/privacyPolicy/acceptAll',
    PRIVACY_POLICY_CHANGE_ALL = '@data/privacyPolicy/changeAll',
    MY_LIBRARY_ADULT_CONTENT_VISIBILITY_CHANGE = '@data/profile/adultContent/change',
    PROFILE_CHANGE_SHOW_LIVE_CLOCK_SETTING = '@data/profile/showLiveClock/change',
    PROFILE_INITIAL_DATA_LOAD = '@data/profile/profile/load',
    PROFILE_INITIAL_HOUSEHOLD_DATA_LOAD = '@data/profile/household/load',
    PROFILE_QUOTA_LOAD = '@data/profile/quota/load',
    // Auth Session
    AUTH_SESSION_STATUS_CHANGE = '@data/authSession/status/change',
    AUTH_SESSION_LOG_LOGIN_UAR = '@data/authSession/login/uar',
    AUTH_SESSION_START_LEGACY_CALLBACKS = '@data/authSession/startLegacyCallbacks',

    // Pin
    PIN_ACTIVE_STATUS_CHANGED = '@data/pin/isActive/changed',
    PIN_ACTIVE_STATUS_LIVE_TV_CHANGED = '@data/pin/isLiveTvSessionActive/changed',
    PIN_BLOCKED_CHANGED = '@data/pin/blockedUntil/changed',
    PIN_FORCE_PROTECTION_CHANGED = '@data/pin/forceprotection/changed',

    // Device
    MAX_NUMBER_OF_UNMANAGED_DEVICES_CHANGE = '@data/device/maxNumberOfUnmanagedDevices/change',
    DEVICE_LOAD_MANY = '@data/device/loadMany',
    DEVICE_REMOVE_ONE = '@data/device/removeOne',

    // Reminder
    REMINDER_CREATE = '@data/reminder/create',
    REMINDER_LOAD_MANY = '@data/reminder/loadMany',
    REMINDER_DELETE = '@data/reminder/delete',
    REMINDER_STATUS_CHANGE = '@data/reminder/status/chnage',

    // Parental Rating
    PARENTAL_RATING_ID_CHANGE = '@data/parentalRatingId/change',
    PARENTAL_RATING_LOAD_MANY = '@data/parentalRating/loadMany',

    // Channel List
    CHANNEL_LIST_ID_CHANGE = '@data/channelListId/change',
    CHANNEL_LIST_ORDER_CHANGE = '@data/channelList/order/change',
    CHANNEL_LIST_LOAD_MANY = '@data/channelList/loadMany',
    CHANNEL_LIST_CHANNELS_LOAD_ONE = '@data/channelListChannels/loadOne',
    CHANNEL_LIST_RENAME_ONE = '@data/channelList/renameOne',
    CHANNEL_LIST_UPDATE_ONE = '@data/channelList/updateOne',
    CHANNEL_LIST_CREATE_ONE = '@data/channelList/createOne',
    CHANNEL_LIST_REMOVE_ONE = '@data/channelList/removeOne',

    // Channel
    CHANNEL_LOAD_MANY = '@data/channel/loadMany',
    CHANNEL_SWITCH_ID_CHANGE = '@data/channelSwitchId/change',
    CHANNEL_LIVE_ID_CHANGE = '@data/liveId/change',
    CHANNEL_AUDIO_CHANGE = '@data/channelAudio/change',
    CHANNEL_SUBTITLE_CHANGE = '@data/channelSubtitle/change',
    CHANNEL_BLOCKED_CHANGE = '@data/channelBlocked/change',
    CHANNEL_REPLAY_PERMISSION_CHANGE = '@data/channelReplayPermission/change',

    // Event
    EVENT_LOAD_MANY = '@data/event/loadMany',
    EVENT_LOAD_MANY_PRECISE = '@data/event/loadManyPrecise',
    EVENT_INIT = '@data/event/init',

    // Messages
    MESSAGE_LOAD_ONE = '@data/message/loadOne',
    MESSAGE_LOAD_MANY = '@data/message/loadMany',
    MESSAGE_REMOVE_MANY = '@data/message/removeMany',
    MESSAGE_READ_MANY = '@data/message/readMany',
    MESSAGE_TRIGGER_ONE = '@data/message/triggerOne',
    MESSAGE_NEXT_TRIGGER_TS_CHANGE = '@data/messageTriggerTS/change',

    // Recording
    RECORDING_LOAD_MANY_SUCCESS = '@data/recording/loadMany/success',
    RECORDING_DELETE_SUCCESS = '@data/recording/delete/success',
    RECORDING_ADD_RECORDING = '@data/recording/add/recording',
    RECORDING_ADD_SERIES_RECORDING = '@data/recording/add/series/recording',
    RECORDING_REMOVE_RECORDING = '@data/recording/remove/recording',
    RECORDING_REMOVE_SERIES_RECORDING = '@data/recording/remove/series/recordings',

    // Recording Management
    RECORDING_MANAGE_SET_TRICK_MODE = '@recordingmanage/trickmode',
    RECORDING_MANAGE_SET_FILTER_OPTION = '@recordingmanage/filteroption',
    RECORDING_MANAGE_SET_SORT_OPTION = '@recordingmanage/sortoption',
    RECORDING_MANAGE_RESET_STATE = '@recordingmanage/reset',
    RECORDING_MANAGE_HANDLE_SELECT = '@recordingmanage/select',
    RECORDING_MANAGE_HANDLE_DELETE_RESULT = '@recordingmanage/delete/handle',

    // Message Inbox
    MESSAGE_INBOX_MESSAGE_SELECTED = '@messageInbox/message/selected',
    MESSAGE_INBOX_SELECTED_CHANGED = '@messageInbox/selected/changed',
    MESSAGE_INBOX_MODE_CHANGED = '@messageInbox/mode/changed',
    MESSAGE_INBOX_FILTER_CHANGED = '@messageInbox/filter/changed',
    MESSAGE_INBOX_CTX_CHANGED = '@messageInbox/ctx/changed',

    // Message Detail
    MESSAGE_DETAIL_LAYOUT_CHANGE = '@messageDetail/layout/change',
    MESSAGE_DETAIL_HEADER_CHANGE = '@messageDetail/header/change',
    MESSAGE_DETAIL_ACTIVE_ATTACHMENT_CHANGE = '@messageDetail/activeAttachment/change',
    MESSAGE_DETAIL_TEXT_LAYOUT_CHANGE = '@messageDetail/textLayout/change',
    MESSAGE_DETAIL_VISIBLE_TEXT_CONTAINER_INDEX_CHANGE = '@messageDetail/visibleTextContainerIndex/change',
    MESSAGE_DETAIL_PLAYER_STATUS_CHANGE = '@messageDetail/playerStatus/change',
    MESSAGE_DETAIL_ATTACHMENT_STATUS_CHANGE = '@messageDetail/attachmentStatus/change',

    // TVGuide
    TV_GUIDE_FIRST_INDEX_CHANGED = '@tvGuide/firstChannel/changed',
    TV_GUIDE_FOCUSED_INDEX_CHANGED = '@tvGuide/channel/changed',
    TV_GUIDE_TIME_CHANGED = '@tvGuide/event/changed',
    TV_GUIDE_START_CHANGED = '@tvGuide/start/changed',
    TV_GUIDE_LOADING_STATUS_CHANGED = '@tvGuide/isLoading/changed',

    // Vod Upsell
    VOD_UPSELL_SET_PRODUCTS = '@vod/upsell/products',
    VOD_UPSELL_SET_PACKAGE_PRODUCTS = '@vod/upsell/package/products',
    VOD_UPSELL_SELECT_PRODUCT = '@vod/upsell/select/product',
    VOD_UPSELL_SET_ASSET_ID_AND_TYPE = '@vod/upsell/asset/id/type',
    VOD_UPSELL_SET_OPERATION_TYPE = '@vod/upsell/operation/type',
    VOD_UPSELL_SET_CURRENT_ASSET = '@vod/upsell/current/asset',
    VOD_UPSELL_UPSELL_OPTION_SELECTED = '@vod/upsell/operation/option/selected',
    VOD_UPSELL_START_UPSELL_TRANSACTION = '@vod/upsell/operation/start/transaction',
    VOD_UPSELL_FINISH_UPSELL_TRANSACTION = '@vod/upsell/operation/stop/transaction',
    VOD_UPSELL_SET_TEMPORARY_ENTITLEMENT = '@vod/upsell/operation/temporary/entitlement',
    VOD_UPSELL_CANCEL_TRANSACTION_ON_REQUEST_ERROR = '@vod/upsell/transaction/cancel',
    VOD_UPSELL_CLEAN_UP_ON_USER_LEAVE = '@vod/upsell/state/clear',
}
