import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { SessionTypes, getLoggingSessions } from 'analytics/logging/LoggingSession';
import { EventConcepts } from 'analytics/types/concepts';

import { ContentRef } from '../classes/ContentRef';
import { generateUuid } from '../loggingUtils';

export enum RecordingEvents {
    PLAN = 'plan',
    CANCEL = 'cancel',
    DELETE = 'delete',
}

export enum RecordingPlanningTypes {
    INSTANT = 'instant',
    EPG = 'EPG',
}

export enum RecordingContentTypes {
    PROGRAM = 'Program',
    SERIES = 'TV Series',
    SEASON = 'TV Season',
}
export interface RecordingEventParams {
    event: RecordingEvents;
    contentRef: ContentRef;
    afterTime: number;
    beforeTime: number;
    planningType: RecordingPlanningTypes;
    contentType: RecordingContentTypes;
}

/**
 * @class RecordingEvent
 */
export class RecordingEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.RECORDING;

    private readonly system: string = 'Network';

    private readonly linkedSessionId: string;

    /**
     * @param event
     * @param contentRef
     * @param afterTime
     * @param beforeTime
     * @param planningType
     * @param contentType
     */
    constructor(
        private readonly event: RecordingEvents,
        private readonly contentRef: ContentRef,
        private afterTime?: number,
        private beforeTime?: number,
        private planningType?: RecordingPlanningTypes,
        private contentType?: RecordingContentTypes
    ) {
        super(generateUuid());

        this.event = event;
        this.contentRef = contentRef;
        this.contentType = contentType;
        const loggingSessions = getLoggingSessions();
        switch (planningType) {
            case RecordingPlanningTypes.INSTANT:
                this.linkedSessionId = loggingSessions[SessionTypes.VIEW] || '';
                break;
            case RecordingPlanningTypes.EPG:
                this.linkedSessionId = loggingSessions[SessionTypes.USER] || loggingSessions[SessionTypes.APPLICATION] || '';
                break;
            default:
                this.linkedSessionId = '';
                break;
        }
        if (afterTime) this.afterTime = afterTime;
        else delete this.afterTime;

        if (beforeTime) this.beforeTime = beforeTime;
        else delete this.beforeTime;

        if (planningType) this.planningType = planningType;
        else delete this.planningType;
    }
}
