import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CatchupEventMutationVariables = Types.Exact<{
  input: Types.CatchupEventInput;
}>;


export type CatchupEventMutation = { __typename?: 'Nexx4Mutations', catchupEvent: { __typename?: 'CatchupEventPayload', playbackInfo: { __typename?: 'TimeshiftPlaybackInfo', url: string, streamStart?: any | null, streamEnd?: any | null, sessionId?: string | null, deliveryKind: Types.ContentDeliveryKind, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, adPlaybackPreRoll?: number | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const CatchupEventDocument = gql`
    mutation catchupEvent($input: CatchupEventInput!) {
  catchupEvent(input: $input) {
    playbackInfo {
      url
      streamStart
      streamEnd
      sessionId
      deliveryKind
      adPlaybackRestrictions
      adPlaybackPreRoll
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type CatchupEventMutationFn = Apollo.MutationFunction<CatchupEventMutation, CatchupEventMutationVariables>;

/**
 * __useCatchupEventMutation__
 *
 * To run a mutation, you first call `useCatchupEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCatchupEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [catchupEventMutation, { data, loading, error }] = useCatchupEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCatchupEventMutation(baseOptions?: Apollo.MutationHookOptions<CatchupEventMutation, CatchupEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CatchupEventMutation, CatchupEventMutationVariables>(CatchupEventDocument, options);
      }
export type CatchupEventMutationHookResult = ReturnType<typeof useCatchupEventMutation>;
export type CatchupEventMutationResult = Apollo.MutationResult<CatchupEventMutation>;
export type CatchupEventMutationOptions = Apollo.BaseMutationOptions<CatchupEventMutation, CatchupEventMutationVariables>;