import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodFolderCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type VodFolderCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type VodFolderCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type VodFolderCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type VodFolderCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type VodFolderCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type VodFolderCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type VodFolderCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type VodFolderCollectionItem_VodFolder_Fragment = { __typename: 'VODFolder', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodFolderParentalRating: { __typename?: 'ParentalRating', id: string } };

export type VodFolderCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type VodFolderCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type VodFolderCollectionItemFragment = VodFolderCollectionItem_Banner_Fragment | VodFolderCollectionItem_Channel_Fragment | VodFolderCollectionItem_ChannelProduct_Fragment | VodFolderCollectionItem_Event_Fragment | VodFolderCollectionItem_NetworkRecording_Fragment | VodFolderCollectionItem_ProductBundle_Fragment | VodFolderCollectionItem_Series_Fragment | VodFolderCollectionItem_VodAsset_Fragment | VodFolderCollectionItem_VodFolder_Fragment | VodFolderCollectionItem_VodProduct_Fragment | VodFolderCollectionItem_VodSeries_Fragment;

export const VodFolderCollectionItemFragmentDoc = gql`
    fragment vodFolderCollectionItem on ContentItem {
  ... on VODFolder {
    id
    title
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    vodFolderParentalRating: parentalRating {
      ...parentalRatingRelation
    }
    __typename
  }
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;