import { App } from 'App/Modules/App';
import { TitanAppEngine } from 'App/Modules/App/Implementation/Titan';

export const IsReady = Promise.resolve(true);

export class Titan {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.Titan.Initialize]');
        App.initialize(new TitanAppEngine());
        return true;
    }
}
