import React, { useState } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { selectChannelLogo, selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    id: GQL.ChannelID;
    className?: string;
    theme?: Theme.Type | null;
}

export const ChannelLogo: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const logo = useSelector(selectChannelLogo(props.id, props.theme ?? theme));
    const name = useSelector(selectChannelName(props.id));
    const [hasError, setHasError] = useState(!logo);
    if (hasError) {
        return (
            <Text
                className={props.className}
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
                theme={props.theme}
            >
                {name}
            </Text>
        );
    }
    return (
        <img
            className={`channel-logo-v2${props.className ? ` ${props.className}` : ''}`}
            src={logo ?? ''}
            alt="logo"
            decoding="async"
            loading="lazy"
            onLoad={() => setHasError(false)}
            onError={() => setHasError(true)}
        />
    );
};
