import React, { useEffect, useRef } from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Focusable } from 'App/Packages/Focus/Types/Focusable';
import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';

type Props<T> = {
    className?: string;
    icon?: IconName | null;
    iconFlavour?: ButtonIconFlavour | null;
    width?: ButtonWidth | null;
    typeface?: Typeface | null;
    animation?: ButtonAnimation | null;
    isPrimary?: boolean;
    isTransparent?: boolean;
    children?: React.ReactNode | string;
    corners?: ButtonCornersStyle | null;
    disableTransition?: boolean;
} & Focusable<T>;

export enum ButtonCornersStyle {
    DEFAULT = 'DEFAULT',
    ROUNDED = 'ROUNDED',
}

export enum ButtonAnimation {
    LABEL = 'LABEL',
    SCALE = 'SCALE',
    NONE = 'NONE',
}

export enum ButtonWidth {
    FIT = 'FIT',
    FULL = 'FULL',
    DEFAULT = 'DEFAULT',
}

export enum ButtonIconFlavour {
    DEFAULT = 'DEFAULT',
    FANCY = 'FANCY',
}

const classNameForButtonAnimation: Record<ButtonAnimation, string> = {
    [ButtonAnimation.SCALE]: 'button-v2--animation-scale',
    [ButtonAnimation.LABEL]: 'button-v2--animation-label',
    [ButtonAnimation.NONE]: 'button-v2--animation-none',
};

const classNameForButtonWidth: Record<ButtonWidth, string> = {
    [ButtonWidth.FIT]: 'button-v2--fit-width',
    [ButtonWidth.FULL]: 'button-v2--full-width',
    [ButtonWidth.DEFAULT]: 'button-v2--default-width',
};

const classNameForButtonCornersStyle: Record<ButtonCornersStyle, string> = {
    [ButtonCornersStyle.DEFAULT]: 'button-v2--default-corners',
    [ButtonCornersStyle.ROUNDED]: 'button-v2--rounded-corners',
};

const classNameForThemeType: Record<string, string> = {
    DARK: 'button-v2--dark-theme',
    LIGHT: 'button-v2--light-theme',
};

const getButtonClassName = <T,>(isFocused: boolean, theme: Theme.Type, props: Props<T>): string => {
    const classList = ['button-v2'];
    classList.push(classNameForButtonAnimation[props.animation ?? ButtonAnimation.SCALE]);
    classList.push(classNameForButtonWidth[props.width ?? ButtonWidth.DEFAULT]);
    classList.push(classNameForButtonCornersStyle[props.corners ?? ButtonCornersStyle.DEFAULT]);
    classList.push(classNameForThemeType[theme]);

    if (isFocused) classList.push('button-v2--focused');
    if (props.icon && !props.children) classList.push('button-v2--icon-only');
    if (props.isTransparent) classList.push('button-v2--transparent');
    if (props.isPrimary) classList.push('button-v2--primary');
    if (props.disableTransition) classList.push('button-v2--disable-transition');
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

const getIconFlavour = (isFocused: boolean, theme: Theme.Type, flavour?: ButtonIconFlavour | null): IconFlavour => {
    if (isFocused && flavour === ButtonIconFlavour.FANCY) return IconFlavour.NEGATIVE;
    if (!isFocused && flavour === ButtonIconFlavour.FANCY) return IconFlavour.POSITIVE;
    if (isFocused || theme === Theme.Type.Dark) return IconFlavour.SOLID_DARK;
    return IconFlavour.SOLID_LIGHT;
};

export const Button = <T,>(props: Props<T>) => {
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const textRef = useRef<HTMLDivElement | null>(null);
    const { isEnterDisabled, onClick, onHover } = useFocusableHook({
        id: props.id,
        ctx: props.ctx,
        isFocused: props.isFocused,
        disabled: props.disabled,
        onClick: props.onClick,
        onEnter: props.onEnter,
        onHover: props.onHover,
        onFocus: props.onFocus,
        onBlur: props.onBlur,
    });
    const theme = useTheme();
    const isFocused = props.isFocused && !isEnterDisabled;
    const className = getButtonClassName(isFocused, theme, props);
    const iconFlavour = getIconFlavour(isFocused, theme, props.iconFlavour);

    useEffect(() => {
        if (!buttonRef.current || !textRef.current) return undefined;
        const timerId = setTimeout(() => {
            if (!buttonRef.current || !textRef.current) return;
            const width = (textRef.current.clientWidth * 100) / window.innerWidth;
            buttonRef.current.style.setProperty('--text-width', `${width}vw`);
        }, 250);

        return () => clearInterval(timerId);
    }, []);

    return (
        <div className={className} onClick={onClick} onMouseOver={onHover} ref={buttonRef}>
            {!!props.icon && <Icon className="button-v2__icon" name={props.icon} flavour={iconFlavour} />}
            {!!props.children && (
                <Text
                    className="button-v2__text"
                    typeface={props.typeface ?? Typeface.SERIF}
                    size={FontSize.BODY_1}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                    ref={textRef}
                >
                    {props.children}
                </Text>
            )}

            {!!props.icon && props.width === ButtonWidth.FULL && <div className="button-v2__icon" />}
        </div>
    );
};
