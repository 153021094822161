import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { ImageFragment } from '__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragment } from '__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
import { ChannelCollectionItem_Channel_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/channel/channelCollectionItem.generated';
import { CollectionItemFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/collectionItem.generated';
import { EventCollectionItem_Event_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/event/eventCollectionItem.generated';
import { EventCollectionItemEntitlementsFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/event/eventCollectionItemEntitlements.generated';
import { RecordingCollectionItem_NetworkRecording_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/recording/recordingCollectionItem.generated';
import { SeriesCollectionItem_Series_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/series/seriesCollectionItem.generated';
import { VodAssetCollectionItem_VodAsset_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodAsset/vodAssetCollectionItem.generated';
import { VodAssetCollectionItemEntitlementsFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodAsset/vodAssetCollectionItemEntitlements.generated';
import { VodAssetCollectionItemMetadataFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodAsset/vodAssetCollectionItemMetadata.generated';
import { VodFolderCollectionItem_VodFolder_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodFolder/vodFolderCollectionItem.generated';
import { VodProductCollectionItem_VodProduct_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodProduct/vodProductCollectionItem.generated';
import { VodSeriesCollectionItem_VodSeries_Fragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodSeries/vodSeriesCollectionItem.generated';
import {
    ChannelCollectionEntity,
    CollectionEntity,
    EventCollectionEntity,
    EventCollectionEntityEntitlements,
    RecordingCollectionEntity,
    SeriesCollectionEntity,
    VodAssetCollectionEntity,
    VodAssetCollectionEntityEntitlements,
    VodAssetCollectionEntityMetadata,
    VodFolderCollectionEntity,
    VodProductCollectionEntity,
    VodSeriesCollectionEntity,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';
import { BannerFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/banner/bannerFolderItem.generated';

const toTime = (date: any): number => new Date(date).getTime();
const toImage = (image: ImageFragment | null | undefined): string => image?.url ?? '';
const toParentalRating = (parentalRating: ParentalRatingRelationFragment | null | undefined): string => parentalRating?.id ?? 'FSK0';
const toChannelId = (channel: { id: string } | null | undefined): string => channel?.id ?? '';

const toEventEntityEntitlements = (
    entitlements: EventCollectionItemEntitlementsFragment | null | undefined
): EventCollectionEntityEntitlements => ({
    pauseLiveTV: !!entitlements?.pauseLiveTV,
    restartTV: !!entitlements?.restartTV,
    catchupTV: !!entitlements?.catchupTV,
    catchupTVAvailableUntil: toTime(entitlements?.catchupTVAvailableUntil ?? 0),
});

const toEventEntity = (item: EventCollectionItem_Event_Fragment): EventCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Event,
    channelId: toChannelId(item.channel),
    title: item.title,
    start: toTime(item.start),
    end: toTime(item.end),
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.parentalRating),
    entitlements: toEventEntityEntitlements(item.entitlements),
});

const toChannelEntity = (item: ChannelCollectionItem_Channel_Fragment): ChannelCollectionEntity => ({
    id: item.id,
    title: item.title,
    description: item.description ?? '',
    userInfo: {
        id: item.userInfo?.id ?? '',
        subscribed: item.userInfo?.subscribed ?? false,
    },
    type: ContentItemType.Channel,
    thumbnail: item.logoImageAlias?.url ?? '',
    parentalRatingId: '',
});

const toRecordingEntity = (item: RecordingCollectionItem_NetworkRecording_Fragment): RecordingCollectionEntity => {
    const event = toEventEntity(item.event);
    return {
        ...event,
        id: item.id,
        type: ContentItemType.Networkrecording,
        status: item.status,
        eventId: event.id,
    };
};

const toSeriesEntity = (item: SeriesCollectionItem_Series_Fragment): SeriesCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Series,
    channelId: toChannelId(item.channel),
    title: item.title,
    episodeCount: item.episodeCount,
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.seriesParentalRating),
});

const toVodFolderEntity = (item: VodFolderCollectionItem_VodFolder_Fragment): VodFolderCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Vodfolder,
    title: item.title,
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.vodFolderParentalRating),
});

const toVodAssetMetaData = (metadata: VodAssetCollectionItemMetadataFragment | null | undefined): VodAssetCollectionEntityMetadata => ({
    year: metadata?.year ?? 0,
    genre: {
        title: metadata?.genre?.title ?? '',
    },
});

const toVodAssetEntitlements = (entitlements: VodAssetCollectionItemEntitlementsFragment): VodAssetCollectionEntityEntitlements => {
    const playback = entitlements.items.find((item) => item?.playback)?.playback;
    const playbackAvailableUntil = entitlements.items.find((item) => item?.playbackAvailableUntil)?.playbackAvailableUntil;
    return {
        playback: !!playback,
        playbackAvailableUntil: toTime(playbackAvailableUntil ?? 0),
    };
};

const toVodAssetEntity = (item: VodAssetCollectionItem_VodAsset_Fragment): VodAssetCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Vodasset,
    title: item.title,
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.vodAssetParentalRating),
    duration: item.duration,
    metadata: toVodAssetMetaData(item.metadata),
    entitlements: toVodAssetEntitlements(item.vodAssetEntitlements),
});

const toVodProductEntity = (item: VodProductCollectionItem_VodProduct_Fragment): VodProductCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Vodproduct,
    title: item.title,
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.vodProductParentalRating),
});

const toVodSeriesEntity = (item: VodSeriesCollectionItem_VodSeries_Fragment): VodSeriesCollectionEntity => ({
    id: item.id,
    type: ContentItemType.Vodseries,
    title: item.title,
    thumbnail: toImage(item.thumbnail),
    parentalRatingId: toParentalRating(item.vodSeriesParentalRating),
    seasonCount: item.seasonInfos?.length ?? 0,
});

const toBannerEntity = (item: BannerFolderItemFragment): CollectionEntity => ({
    id: item.id,
    title: '',
    type: ContentItemType.Banner,
    thumbnail: item.thumbnailAlias.url ?? '',
    parentalRatingId: item.parentalRating.id,
    link: item.link,
});

const toEntity = (item: CollectionItemFragment): CollectionEntity | null => {
    switch (item.__typename) {
        case 'Event':
            return toEventEntity(item);
        case 'Channel':
            return toChannelEntity(item);
        case 'NetworkRecording':
            return toRecordingEntity(item);
        case 'Series':
            return toSeriesEntity(item);
        case 'VODFolder':
            return toVodFolderEntity(item);
        case 'VODAsset':
            return toVodAssetEntity(item);
        case 'VODProduct':
            return toVodProductEntity(item);
        case 'VODSeries':
            return toVodSeriesEntity(item);
        case 'Banner':
            return toBannerEntity(item);
        default:
            return null;
    }
};

const toEntities = (items: CollectionItemFragment[]): Record<string, CollectionEntity> =>
    items.reduce<Record<string, CollectionEntity>>((acc, item) => {
        const entity = toEntity(item);
        if (!entity) return acc;
        acc[entity.id] = entity;
        return acc;
    }, {});

const toIds = (items: CollectionItemFragment[]): string[] =>
    items.map((item) => ('id' in item ? item.id : null)).filter(Boolean) as string[];

export const collectionMapper = { toEntities, toIds };
