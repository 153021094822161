import { MessageActionEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import {
    MessageDetailAttachmentViewStatus,
    MessageDetailKnownActionId,
    MessageDetailLayoutType,
    MessageDetailPlayerStatus,
    MessageDetailState,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

export const MESSAGE_DETAIL_ATTACHMENT_CONTAINER_HEIGHT = 440;
export const MESSAGE_DETAIL_ACTION_CONTAINER_HEIGHT = 160;
export const MESSAGE_DETAIL_SCROLL_CONTAINER_HEIGHT = 200;
export const MESSAGE_DETAIL_HEADER_LINE_HEIGHT = 72;
export const MESSAGE_DETAIL_HEADER_LINE_HEIGHT_VW = MESSAGE_DETAIL_HEADER_LINE_HEIGHT / 19.2;
export const MESSAGE_DETAIL_ONE_LINE_HEADER_HEIGHT = 80;
export const MESSAGE_DETAIL_TWO_LINE_HEADER_HEIGHT = 160;
export const MESSAGE_DETAIL_TEXT_LINE_HEIGHT = 40;
export const MESSAGE_DETAIL_TEXT_LINE_HEIGHT_VW = MESSAGE_DETAIL_TEXT_LINE_HEIGHT / 19.2;
export const MESSAGE_DETAIL_SCREEN_HEIGHT = 1080;
export const MESSAGE_DETAIL_TEXT_MAX_HEIGHT = MESSAGE_DETAIL_SCREEN_HEIGHT - 2 * MESSAGE_DETAIL_SCROLL_CONTAINER_HEIGHT;
export const MESSAGE_DETAIL_TEXT_MAX_LINES = 17;
export const MESSAGE_DETAIL_SCREEN_PADDING_BOTTOM = 40;
export const MESSAGE_DETAIL_SCREEN_SCROLL_VW = 880 / 19.2;

export const MESSAGE_DETAIL_FULLSCREEN_ACTION: MessageActionEntity = {
    id: MessageDetailKnownActionId.FULLSCREEN,
    title: 'FULLSCREEN',
    uri: '',
};

export const MESSAGE_DETAIL_CLOSE_ACTION: MessageActionEntity = {
    id: MessageDetailKnownActionId.CLOSE,
    title: 'CLOSE',
    uri: '',
};

export const initialMessageDetailState: MessageDetailState = {
    activeAttachmentId: null,
    layout: MessageDetailLayoutType.OBSERVER,
    textLayout: [],
    visibleTextContainerIndex: -1,
    header: 1,
    attachmentViewStatus: MessageDetailAttachmentViewStatus.HIDDEN,
    playerStatus: MessageDetailPlayerStatus.OFF,
};
