import { KeyEnter as Root, KeyEnterComponent, KeyEnterProps } from 'App/Packages/UI/Keyboard/Components/Key/Enter/KeyEnter';
import {
    KeyEnterIcon,
    KeyEnterIconInsance,
    KeyEnterIconComponent,
    KeyEnterIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Enter/KeyEnterIcon';

export type { KeyEnterComponent, KeyEnterProps, KeyEnterIconComponent, KeyEnterIconProps };

const KeyEnter = Object.assign(Root, {
    Icon: Object.assign(KeyEnterIcon, {
        Instance: KeyEnterIconInsance,
    }),
});

export { KeyEnter };
