export enum ProfileType {
    FAMILY = 'Family',
    KIDS = 'Kids',
    OTHER = 'Other',
}

export interface ProfileParams {
    profileId: string;
    profileType: ProfileType;
    profileAgeRating: number;
    uiMode?: string;
}

/**
 * @class Profile
 */
export class Profile {
    private readonly profileId: string;

    private readonly profileType: ProfileType;

    private readonly profileAgeRating: number;

    private readonly uiMode?: string;

    /**
     *
     * @param profileId
     * @param profileType
     * @param profileAgeRating
     * @param uiMode
     */
    constructor(profileId: string, profileType: ProfileType, profileAgeRating: number, uiMode?: string) {
        this.profileId = profileId;
        this.profileType = profileType;
        this.profileAgeRating = profileAgeRating;
        this.uiMode = uiMode;
    }
}
