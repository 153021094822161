import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';

import { useCardLandscape } from 'App/Packages/UI/Card/CardLandscape/Hooks/useCardLandscape';

const getClassName = (props: CardLandscapeContentProps) => {
    const classlist = ['landscape-card-v3__content-container'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeContentProps = React.PropsWithChildren<{
    className?: string;
}>;

export type CardLandscapeContentComponent = React.FunctionComponent<CardLandscapeContentProps>;

export const CardLandscapeContent: CardLandscapeContentComponent = (props) => {
    const card = useCardLandscape();
    return (
        <div className={getClassName(props)}>
            <Div
                className="landscape-card-v3__content"
                backgroundColor={card.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
                shadow={card.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
            >
                {props.children}
            </Div>
        </div>
    );
};
