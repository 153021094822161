import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen/index';
import { UI } from 'App/Packages/UI';

import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { LoggingService } from 'analytics/loggingService';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { getFavouritableItemKind } from './helpers';

export const toDetailPage =
    (id: string, type: ContentItemType): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const assetType = assetTypeForContentItemType[type];
        const path = `/details/${assetType}/${id}`;

        const prevScreen = Screen.selectors.select(state);

        if (!prevScreen) return undefined;

        const newScreen = Screen.generator(
            prevScreen.type,
            {
                initialFocusedItemId: id,
                id: 'id' in prevScreen.props ? prevScreen.props.id : null,
                initialFocusedSeasonId: 'initialFocusedSeasonId' in prevScreen.props ? prevScreen.props.initialFocusedSeasonId : null,
            },
            UI.Folder.Constants.Ctx
        );

        if (!newScreen) return undefined;

        dispatch(History.actions.push({ screen: newScreen, overlay: null }));

        return dispatch(
            Screen.actions.mount(Screen.Type.Detail, {
                id,
                type,
                oldOperation: () => CustomHistory.go(path),
            })
        );
    };

const addToFavouritesSelected =
    (id: string, isFavourited: boolean, assetType: AssetType): Thunk<Promise<void>> =>
    async (dispatch) => {
        const itemKind = getFavouritableItemKind(assetType);

        dispatch(Favourites.actions.toggle({ itemId: id, itemKind }));

        const event = isFavourited ? UIActionEvents.REMOVE_FAVORITE : UIActionEvents.ADD_FAVORITE;
        LoggingService.getInstance().logEvent(getUIActionEvent(event, { assetType, id }));
    };

const screenForTypeLoaders: Partial<Record<ContentItemType, null | ((id: string) => Thunk<Promise<void>>)>> = {
    [ContentItemType.Vodasset]: (id) => Screen.actions.mount(Screen.Type.VodAssetDetail, { id }, UI.Folder.Constants.Ctx),
    [ContentItemType.Event]: (id) => Screen.actions.mount(Screen.Type.EventDetail, { id }, UI.Folder.Constants.Ctx),
    [ContentItemType.Series]: (id) => Screen.actions.mount(Screen.Type.SeriesDetail, { id }, UI.Folder.Constants.Ctx),
    [ContentItemType.Networkrecording]: (id) => Screen.actions.mount(Screen.Type.RecordingDetail, { id }, UI.Folder.Constants.Ctx),
    [ContentItemType.Vodseries]: (id) => Screen.actions.mount(Screen.Type.VodSeriesDetail, { id }, UI.Folder.Constants.Ctx),
};

const mount: Screen.LifecycleMethod<Screen.Type.Detail> = (props) => async (dispatch) => {
    const screenForTypeLoader = screenForTypeLoaders[props.type];

    if (!screenForTypeLoader) {
        props.oldOperation?.();
        await dispatch(Screen.actions.unmount());
        await dispatch(Overlay.actions.unmount());
        console.log(`No loader for screen type: ${props.type}`);
        return;
    }

    await dispatch(screenForTypeLoader(props.id));
    await dispatch(Overlay.actions.unmount());
};

const lifecycle: Partial<Screen.Lifecycle<Screen.Type.Detail>> = {
    mount,
};

export const actions = {
    public: {
        addToFavouritesSelected,
    },
    lifecycle,
};
