import { EpisodeInfo, Maybe, ProductKind, VodAsset, VodAssetEntitlement } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ProductOption, VodUpsellOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectAsset = (state: State): VodAsset | null => state.vodUpsell.asset;

/**
 * @memberof VodUpsell
 * @description selector checking renting upsell capability for vod asset
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns flag indicating rent capability
 */
const selectAssetCanBeRented = (state: State): boolean => {
    if (!state?.vodUpsell?.asset) return false;
    const canBePurchased = state?.vodUpsell?.asset?.entitlements?.items?.some(
        (item: Maybe<VodAssetEntitlement>) => item?.product?.kind === ProductKind.Purchase
    );
    return state.vodUpsell.productOptions.some((option: ProductOption) => option.kind === ProductKind.Rental) && !canBePurchased;
};

/**
 * @memberof VodUpsell
 * @description selector checking purchase upsell capability for vod asset
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns flag indicating purchase capability
 */
const selectAssetCanBePurchased = (state: State): boolean => {
    if (!state?.vodUpsell?.asset) return false;
    return state.vodUpsell.productOptions.some((option: ProductOption) => option.kind === ProductKind.Purchase);
};

/**
 * @memberof VodUpsell
 * @description selector checking subscribe upsell capability for vod asset
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns flag indicating subscribe capability
 */
const selectAssetCanBeSubscribed = (state: State): boolean => {
    if (!state?.vodUpsell?.asset) return false;
    return state.vodUpsell.productOptions.some((option: ProductOption) => option.kind === ProductKind.SubscriptionUpsellable);
};

/**
 * @memberof VodUpsell
 * @description selector getting upsell select options for overlay
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns array of selection options
 */
const selectOptions = (state: State): VodUpsellOption[] =>
    state.vodUpsell.productOptions
        .filter((option: ProductOption) => option.kind === state.vodUpsell.productKind && !option.packageProduct)
        .map((option: ProductOption) => ({
            id: option?.id!,
            videoQuality: option.videoQuality,
            label: `${option.price?.amount} ${option.price?.currency}`,
        }));

const selectPackageOptions = (state: State) =>
    state.vodUpsell.productOptions
        .filter((option: ProductOption) => option.kind === state.vodUpsell.productKind && option.packageProduct)
        .map((option: ProductOption) => ({
            id: option?.id!,
            videoQuality: option.videoQuality,
            label: `${option.price?.amount} ${option.price?.currency}`,
        }));

/**
 * @memberof VodUpsell
 * @description selector to get current package option selected if any selected
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns current entitlement option or null
 */
const selectOptionSelected = (state: State): GQL.VodProductEntitlement | undefined =>
    state.vodUpsell.productOptions.find((option: ProductOption) => option?.id! === state.vodUpsell.entitlementId);

/**
 * @memberof VodUpsell
 * @description selector to get the current upsell type of interaction selected
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns current productKind value can be Rental Purchase Subscribe_upsellable
 */
const selectOptionTypeSelected = (state: State): ProductKind | null => state.vodUpsell.productKind;

/**
 * @memberof VodUpsell
 * @description selector to get current targeted vod asset id
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns current vod asset id
 */
const selectCurrentAssetIdSelected = (state: State): string => state.vodUpsell.assetId;

/**
 * @memberof VodUpsell
 * @description selector to return a flag if is single or multiple asset operation
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns flag indicating the type of upsell operation single or multiple
 */
const selectIsSingleVodTransaction = (state: State): boolean =>
    !state.vodUpsell.productOptions.find((option: ProductOption) => option.entitlement?.id! === state.vodUpsell.entitlementId)
        ?.packageProduct && state.vodUpsell.productKind !== ProductKind.SubscriptionUpsellable;

/**
 * @memberof VodUpsell
 * @description selector to return current vod asset object metadata if any
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns current metadata for vod asset or null
 */
const selectCurrentVodAsset = (state: State): object | null => state.vodUpsell.asset;

/**
 * @memberof VodUpsell
 * @description selector to return temporary entitlement
 *  returned from StartVODTransaction if such exist
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns temporary entitlement if any or null
 */
const selectCurrentTemporaryEntitlement = (state: State): GQL.VODAssetEntitlement | null => state.vodUpsell.temporaryEntitlement;

/**
 * @memberof VodUpsell
 * @description selector to return a flag if any upsell operation targeting vod is in progress
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param state current redux state
 * @returns boolean flag showing if any upsell action is in progress
 */
const selectIsPurchaseInProgress = (state: State): boolean => state.vodUpsell.isPurchaseOn;

/**
 * @memberof VodUpsell
 * @description selector to return a to find the title of
 * the object which is being target of upsell action
 * @author SmartTVBG@a1.bg
 * @date 15/07/2023
 * @param state current redux state
 * @returns the title of target vod asset
 */
const selectCurrentAssetTitle = (state: State): string => state.vodUpsell?.asset?.title ?? '';
const selectCurrentAssetEpisodeInfo = (state: State): EpisodeInfo | null => state.vodUpsell?.asset?.metadata.episodeInfo ?? null;

/**
 * @memberof VodUpsell
 * @description selector to return a to find the vod overlay title based on upsell type
 * @author SmartTVBG@a1.bg
 * @date 15/07/2023
 * @param state current redux state
 * @returns the title of vod forbidden upsell overlay
 */
const selectForbiddenOverlayTitle = (state: State): string => {
    const operationType = selectOptionTypeSelected(state);
    return operationType === ProductKind.Purchase
        ? 'SCREEN_ALERT_BUY_EXTERNALLY_TITLE'
        : operationType === ProductKind.Rental
          ? 'SCREEN_ALERT_RENT_EXTERNALLY_TITLE'
          : 'SCREEN_ALERT_SUBSCRIBE_EXTERNALLY_TITLE';
};

/**
 * @memberof VodUpsell
 * @description selector to return a to find the vod forbidden overlay
 * text based on vod upsell action
 * @author SmartTVBG@a1.bg
 * @date 15/07/2023
 * @param state current redux state
 * @returns the text description of vod forbidden upsell overlay
 */
const selectForbiddenOverlayText = (state: State): string => {
    const operationType = selectOptionTypeSelected(state);
    return operationType === ProductKind.Purchase
        ? 'SCREEN_ALERT_BUY_EXTERNALLY_DESCRIPTION'
        : operationType === ProductKind.Rental
          ? 'SCREEN_ALERT_RENT_EXTERNALLY_DESCRIPTION'
          : 'SCREEN_ALERT_SUBSCRIBE_EXTERNALLY_DESCRIPTION';
};

export const vodUpsellSelectors = {
    public: {
        selectAsset,
        selectAssetCanBeRented,
        selectAssetCanBePurchased,
        selectAssetCanBeSubscribed,
        selectOptions,
        selectPackageOptions,
        selectOptionSelected,
        selectOptionTypeSelected,
        selectCurrentAssetIdSelected,
        selectIsSingleVodTransaction,
        selectCurrentVodAsset,
        selectCurrentTemporaryEntitlement,
        selectIsPurchaseInProgress,
        selectCurrentAssetTitle,
        selectCurrentAssetEpisodeInfo,
        selectForbiddenOverlayTitle,
        selectForbiddenOverlayText,
    },
};
