import { initialFavouritesState } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/constants';
import { favouritesMapper } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/mapper';
import { FavouritesActionType, FavouritesEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const favouritesReducer: Reducer<FavouritesEntityTable> = (state, action) => {
    if (!state) return initialFavouritesState;

    switch (action.type) {
        case FavouritesActionType.LOADED: {
            const ids = favouritesMapper.toIds(action.payload.favourites);
            return { ...state, ids };
        }
        case FavouritesActionType.ADDED: {
            const ids = [...state.ids, action.payload.id];
            return { ...state, ids };
        }
        case FavouritesActionType.REMOVED: {
            const ids = state.ids.filter((id) => id !== action.payload.id);
            return { ...state, ids };
        }
        default:
            return state;
    }
};
