import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import KeyboardSuggestionElement from 'App/Modules/Keyboard/Element/KeyboardSuggestionElement';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React, { useEffect, useRef } from 'react';

export type KeyboardSuggestionProps = {
    keyboard: KeyboardElement;
    element: KeyboardSuggestionElement;
    scroll: Scroll;
};

export const KeyboardSuggestion: React.FunctionComponent<KeyboardSuggestionProps> = (props) => {
    const { keyboard, element, scroll } = props;
    const ref = useRef<HTMLDivElement>(null);
    const focused = Subscribable.use(element.focused);

    useEffect(() => {
        const update = () => {
            const container = scroll.ref.current;
            const target = ref.current;
            if (!target || !container) return;
            const isFocused = element.focused.get();
            if (!isFocused) return;
            const left = target.offsetLeft - container.offsetLeft + (target.offsetWidth - container.offsetWidth) / 2;
            scroll.to({ left });
        };

        update();

        return element.focused.subscribe(update);
    }, [element, scroll]);

    return (
        <UI.Suggestion.Base
            ref={ref}
            focused={focused}
            className="keyboard__suggestion"
            size="small"
            font="sans"
            weight="normal"
            onClick={() => keyboard.click(element)}
            onHover={() => keyboard.hover(element)}
            rounded
        >
            {element.value}
        </UI.Suggestion.Base>
    );
};
