import { Unsubscribe } from 'redux';

import { Store } from '__SMART_APP_OLD__/app/store/types/Store';

export const subscribeToBookmarks = (store: Store, callback: () => void): Unsubscribe => {
    let prevBookmarkIds = store.getState().data.bookmarks.ids;

    const unsubscribeHandler = store.subscribe(() => {
        const currentBookmarkIds = store.getState().data.bookmarks.ids;

        if (prevBookmarkIds !== currentBookmarkIds) {
            callback();
            prevBookmarkIds = currentBookmarkIds;
        }
    });

    return unsubscribeHandler;
};
