import { ContentItemType, SearchContentType } from '__SMART_APP_OLD__/api/graphql/types';
import { ChannelListOption } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/constants';
import { ChannelListsScreenViewMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';
import { ChannelListsScreenManageMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/types';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { MenuIconItemType, MenuItemType } from 'App/Types/Menu';

// ActionType
export enum ScreenActionType {
    CHANGED = '@screen/changed',
}

// Actions
export type ScreenReduxAction = ScreenChangedAction;
export type ScreenChangedActionPayload = { screen: ScreenEntity | null };
export type ScreenChangedAction = PayloadedAction<ScreenActionType.CHANGED, ScreenChangedActionPayload>;

// State
export enum ScreenType {
    LOGIN = 'LOGIN',
    LOGIN_WITH_CODE = 'LOGIN_WITH_CODE',
    LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
    LOGIN_EXPIRED_CODE = 'LOGIN_EXPIRED_CODE',
    DEVICE_MANAGEMENT = 'DEVICE_MANAGEMENT',
    RECORDING_MANAGEMENT = 'RECORDING_MANAGEMENT',
    MESSAGE_INBOX = 'MESSAGE_INBOX',
    MESSAGE_DETAIL = 'MESSAGE_DETAIL',
    TV_GUIDE = 'TV_GUIDE',
    NOW_ON_TV = 'NOW_ON_TV',
    VIEW_CONTROL = 'VIEW_CONTROL',
    LANGUAGE_PREFERENCES = 'LANGUAGE_PREFERENCES',
    COLLECTION = 'COLLECTION',
    PLAYGROUND = 'PLAYGROUND',
    // Fti
    WELCOME = 'WELCOME',
    CLOSING = 'CLOSING',
    STARTUP_BEHAVIOR = 'STARTUP_BEHAVIOR',
    APPEARANCE = 'APPEARANCE',
    LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
    COMMUNITY_SELECTION = 'COMMUNITY_SELECTION',
    MASTER_PIN_CODE = 'MASTER_PIN_CODE',
    PIN_CODE = 'PIN_CODE',
    AGE_RATING_SELECTION = 'AGE_RATING_SELECTION',
    REPLAY_SELECTION = 'REPLAY_SELECTION',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    EDUCATION = 'EDUCATION',
    PARENTAL_CONTROL = 'PARENTAL_CONTROL',
    CHANNEL_LISTS = 'CHANNEL_LISTS',
    CHANNEL_LISTS_CREATE = 'CHANNEL_LISTS_CREATE',
    CHANNEL_LISTS_MANAGE = 'CHANNEL_LISTS_MANAGE',
    SETTINGS = 'SETTINGS',

    // PascalCase - Better readability
    VideoStore = 'VideoStore',
    MyLibrary = 'MyLibrary',
    Search = 'Search',
    Detail = 'Detail',
    VodAssetDetail = 'VodAssetDetail',
    EventDetail = 'EventDetail',
    VodFolders = 'VodFolders',
    SeriesDetail = 'SeriesDetail',
    VodSeriesDetail = 'VodSeriesDetail',
    RecordingDetail = 'RecordingDetail',
}

export type ScreenState = ScreenEntity | null;

export type ScreenEntity<T extends ScreenType = ScreenType> = {
    type: T;
    ctx: string;
    props: ScreenProps<T>;
};

export type ScreenProps<T extends ScreenType = ScreenType> = T extends keyof ScreenPropsMap
    ? ScreenPropsMap[T]
    : ScreenPropsMap[keyof ScreenPropsMap];

type ScreenPropsMap = {
    [ScreenType.LOGIN]: LoginFlowSelectorScreenProps;
    [ScreenType.LOGIN_WITH_CODE]: LoginWithCodeScreenProps;
    [ScreenType.LOGIN_WITH_EMAIL]: LoginWithEmailScreenProps;
    [ScreenType.LOGIN_EXPIRED_CODE]: LoginCodeExpiredScreenProps;
    [ScreenType.DEVICE_MANAGEMENT]: DeviceManagementScreenProps;
    [ScreenType.RECORDING_MANAGEMENT]: RecordingManagementScreenProps;
    [ScreenType.MESSAGE_INBOX]: MessageInboxScreenProps;
    [ScreenType.MESSAGE_DETAIL]: MessageDetailScreenProps;
    [ScreenType.TV_GUIDE]: TVGuideScreenProps;
    [ScreenType.NOW_ON_TV]: NowOnTVScreenProps;
    [ScreenType.VIEW_CONTROL]: ViewControlScreenProps;
    [ScreenType.WELCOME]: WelcomeScreenProps;
    [ScreenType.CLOSING]: ClosingScreenProps;
    [ScreenType.STARTUP_BEHAVIOR]: StartupBehaviorScreenProps;
    [ScreenType.APPEARANCE]: AppearanceScreenProps;
    [ScreenType.LANGUAGE_SELECTION]: LanguageSelectionScreenProps;
    [ScreenType.COMMUNITY_SELECTION]: CommunitySelectionScreenProps;
    [ScreenType.PRIVACY_POLICY]: PrivacyPolicyScreenProps;
    [ScreenType.MASTER_PIN_CODE]: MasterPinCodeScreenProps;
    [ScreenType.PIN_CODE]: PinCodeScreenProps;
    [ScreenType.AGE_RATING_SELECTION]: AgeRatingSelectionScreenProps;
    [ScreenType.REPLAY_SELECTION]: ReplaySelectionScreenProps;
    [ScreenType.EDUCATION]: EducationScreenProps;
    [ScreenType.LANGUAGE_PREFERENCES]: LanguagePreferencesScreenProps;
    [ScreenType.COLLECTION]: CollectionScreenProps;
    [ScreenType.PARENTAL_CONTROL]: ParentalControlScreenProps;
    [ScreenType.CHANNEL_LISTS]: ChannelListsScreenProps;
    [ScreenType.CHANNEL_LISTS_CREATE]: ChannelListsScreenCreateProps;
    [ScreenType.CHANNEL_LISTS_MANAGE]: ChannelListsScreenManageProps;
    [ScreenType.SETTINGS]: SettingsScreenProps;
    // PascalCase - Better readability
    [ScreenType.VideoStore]: VideoStoreScreenProps;
    [ScreenType.MyLibrary]: MyLibraryScreenProps;
    [ScreenType.Search]: SearchScreenProps;
    [ScreenType.Detail]: DetailProps;
    [ScreenType.VodAssetDetail]: VodAssetDetailScreenProps;
    [ScreenType.EventDetail]: EventDetailScreenProps;
    [ScreenType.VodFolders]: VodFolderScreenProps;
    // @note Playground
    [ScreenType.RecordingDetail]: RecordingDetailScreenProps;
    [ScreenType.SeriesDetail]: SeriesDetailScreenProps;
    [ScreenType.VodSeriesDetail]: VodSeriesDetailScreenProps;
    // Playground
    [ScreenType.PLAYGROUND]: MyLibraryScreenProps;
};

type LoginFlowSelectorScreenProps = { username?: string; password?: string };
type LoginWithCodeScreenProps = {};
type LoginWithEmailScreenProps = {};
type LoginCodeExpiredScreenProps = {};
type DeviceManagementScreenProps = {};
type RecordingManagementScreenProps = {};
type ChannelListsScreenProps = { isArranging?: boolean; initialFocusedId?: string | null; optionSelected?: ChannelListOption | undefined };
type ChannelListsScreenCreateProps = { mode: ChannelListsScreenViewMode; initialInputValue?: string; channelListId?: string };
type ChannelListsScreenManageProps = { mode: ChannelListsScreenManageMode; channelListName: string; channelListId?: string };
type MessageInboxScreenProps = { focused: string | null };
type MessageDetailScreenProps = { messageId: string };
type TVGuideScreenProps = {
    firstIndex: number;
    focusedIndex: number;
    time: number;
    start: number;
};
type NowOnTVScreenProps = { channelID: string | null };
type ViewControlScreenProps = { from: string; menuId?: string };
type LanguagePreferencesScreenProps = {};
type CollectionScreenProps = { id: string; focused?: string; isLandScapeCardView?: boolean };
type WelcomeScreenProps = {};
type ClosingScreenProps = {};
type StartupBehaviorScreenProps = {};
type AppearanceScreenProps = {};
type LanguageSelectionScreenProps = {};
type CommunitySelectionScreenProps = {};
type MasterPinCodeScreenProps = {};
type PinCodeScreenProps = {};
type AgeRatingSelectionScreenProps = {};
type ReplaySelectionScreenProps = {};
type PrivacyPolicyScreenProps = { type: PrivacyPolicyScreenType };
type EducationScreenProps = {
    content: EducationScreenContentItem[];
};
type ParentalControlScreenProps = {};
type SettingsScreenProps = {
    lastHoveredIndex?: number;
    lastFirstVisibleIndex?: number;
};
type VideoStoreScreenProps = {
    focusedHeaderId?: MenuItemType | MenuIconItemType;
    focusedFolderId?: string;
    focusedItemId?: string;
};

type MyLibraryScreenProps = {
    focusedHeaderId?: MenuItemType | MenuIconItemType;
    focusedFolderId?: string;
    focusedItemId?: string;
};

type VodFolderScreenProps = {
    folderId: string;
    focusedFolderId?: string;
    focusedItemId?: string;
};

type SearchScreenProps = {
    query?: string;
    context?: SearchContentType;
    focusedFolderId?: string;
    focusedItemId?: string;
};
// TODO: Remove oldOperation when all the detail screens are ready
type DetailProps = { id: string; type: ContentItemType; oldOperation?: () => void };
type VodAssetDetailScreenProps = { id: string; initialFocusedItemId?: string };
type EventDetailScreenProps = { id: string; initialFocusedItemId?: string };
type RecordingDetailScreenProps = { id: string; initialFocusedItemId?: string };
type SeriesDetailScreenProps = { id: string; initialFocusedItemId?: string };
type VodSeriesDetailScreenProps = { id: string; initialFocusedItemId?: string };

export enum PrivacyPolicyScreenType {
    FTI = 'FTI',
    SETTINGS = 'SETTINGS',
}

export type EducationScreenContentItem = {
    id: string;
    title: string;
    text: string;
    image: string;
};
