import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import {
    ChangeHouseholdOnboardingInfoDocument,
    ChangeHouseholdOnboardingInfoMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Fti/api/mutation/changeHouseholdOnboardingInfo.generated';
import {
    ChangeProfileOnboardingInfoDocument,
    ChangeProfileOnboardingInfoMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Fti/api/mutation/changeProfileOnboardingInfo.generated';

const completeProfileStep = async (variables: ChangeProfileOnboardingInfoMutationVariables) => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(ChangeProfileOnboardingInfoDocument, variables);
    Error.collect(response);
};

const completeHouseholdStep = async (variables: ChangeHouseholdOnboardingInfoMutationVariables) => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(ChangeHouseholdOnboardingInfoDocument, variables);
    Error.collect(response);
};

export const completeAgeRatingStep = () => completeProfileStep({ input: { ageRatingStepCompleted: true } });
export const completePrivacyStep = () => completeProfileStep({ input: { privacyStepCompleted: true } });
export const completeCommunityStep = () => completeHouseholdStep({ input: { communityStepCompleted: true } });
export const completeMasterPincodeStep = () => completeHouseholdStep({ input: { masterPincodeStepCompleted: true } });
export const completeReplayStep = () => completeHouseholdStep({ input: { replayStepCompleted: true } });
