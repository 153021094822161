import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { selectReminderByEventId } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import { getTimeGapInDays } from '__SMART_APP_OLD__/utils/timeUtils';
import { MyLibraryData } from 'App/Modules/MyLibraryData';
import { UI } from 'App/Packages/UI';
import React from 'react';
import { useSelector } from 'react-redux';
import { playChannel, playVod } from '__SMART_APP_OLD__/api/Tuner';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { AssetType, PinAction, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { isCatchupAsset, isRecordingAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';
import { Env } from 'App/Env';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { Detail } from 'App/Modules/Data/Detail';

const MyLibraryHeaderContentMarker: React.FunctionComponent = () => {
    const contentMarker = useSelector(MyLibraryData.selectors.selectHeaderContentMarker);
    const availableUntil = useSelector(MyLibraryData.selectors.selectHeaderAvailableUntil(MyLibraryData.selectors.selectHeader));
    if (!contentMarker) return null;

    if (!availableUntil || contentMarker === ContentMarkerType.Live) return <UI.Detail.Meta.Marker marker={contentMarker} />;

    return <UI.Detail.Meta.Marker marker={contentMarker} label={getTimeGapInDays(availableUntil)} />;
};

export const MyLibraryHeader = () => {
    const header = useSelector(MyLibraryData.selectors.selectHeader);
    const isFavourited = useSelector(Favourites.selectors.selectIsFavourited(header?.id || ''));
    const reminder = useSelector(selectReminderByEventId(header?.id || ''));
    const dispatch = useDispatch();
    const asset = useSelector(MyLibraryData.selectors.selectAsset);
    const resumeThreshold = useSelector(selectResumeThreshold);
    const isRestricted = useSelector(selectParentalRatingIsRestricted(header?.metadata.parentalRatingId || '', PinSessionType.PIN_LIVE_TV));
    const assetCanBeStartedOver = useSelector(Detail.Root.selectors.selectAssetCanBeStartedOver(() => header));

    const addToFavourites = () => {
        const event = isFavourited ? UIActionEvents.REMOVE_FAVORITE : UIActionEvents.ADD_FAVORITE;
        LoggingService.getInstance().logEvent(getUIActionEvent(event, { assetType: asset?.rawData.type, id: asset?.rawData.id }));

        if (!header?.metadata.favouriteItemType) return;

        dispatch(
            Favourites.actions.toggle({
                itemId: header?.id || '',
                itemKind: header?.metadata.favouriteItemType,
            })
        );
    };

    const getCatchupBookmarkPosition = (isStartOver: boolean) => {
        if (isStartOver) {
            return asset?.leadIn;
        }
        return asset?.bookmark?.position || asset?.leadIn;
    };

    const canResume = (): boolean => {
        const isLive = asset?.programType === ProgramType.LIVE;
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        const liveProgress = Math.round((Date.now() - asset?.startTime) / 1000);
        const isLiveRestartAvailable = isLive && liveProgress > 0 && asset?.isReplayAvailable;
        const isCatchupRestartAvailable = asset?.bookmark && asset?.progress > 0 && asset?.progress < resumeThreshold;
        return asset?.isPlaybackAvailable && (isLiveRestartAvailable || isCatchupRestartAvailable);
    };

    const getPlayButtonLabel = () => {
        const isLive = asset?.programType === ProgramType.LIVE;
        const label = canResume() && !isLive ? translate('SCREEN_DETAIL_RESUME_BUTTON') : translate('SCREEN_DETAIL_PLAY_BUTTON');

        // @ts-ignore
        if (asset?.assetType === AssetType.EPISODE) {
            return `${label} ${translate('HOVER_SEASON')}${asset?.seasonOrdinal} ${translate('HOVER_EPISODE')}${asset?.episodeOrdinal}`;
        }
        return label;
    };

    const playClicked = () => {
        const resume = canResume();
        const isLive = asset?.programType === ProgramType.LIVE;
        const event = !isLive && resume ? UIActionEvents.CONTINUE_WATCHING : UIActionEvents.PLAY;
        const isStartOver = !isLive && !resume;

        if (isRestricted) {
            openPinOverlay(
                () => {
                    startPlayback(event, isStartOver);
                },
                () => dispatch(Overlay.actions.unmount()),
                PinAction.ENTER,
                PinSessionType.PIN_LIVE_TV
            );
        } else {
            startPlayback(event, isStartOver);
        }
    };

    const startOverClicked = () => {
        const shouldRestrict = asset?.shouldRestrict;
        if (shouldRestrict) {
            openPinOverlay(
                () => {
                    startPlayback(UIActionEvents.VIEW_FROM_START, true);
                },
                () => dispatch(Overlay.actions.unmount()),
                PinAction.ENTER,
                PinSessionType.PIN_LIVE_TV
            );
        } else {
            startPlayback(UIActionEvents.VIEW_FROM_START, true);
        }
    };

    const startPlayback = (uiActionEventType: UIActionEvents, isStartOver = false, isTrailer = false) => {
        changePlayerVisibility(true);
        const catchup = isCatchupAsset(asset);
        const recording = isRecordingAsset(asset);

        if (asset?.rawData.programType === ProgramType.LIVE) {
            dispatch(History.actions.push());
            dispatch(Screen.actions.unmount());
            playChannel(asset.channelId, false, isStartOver);
        } else {
            const event = getUIActionEvent(uiActionEventType, {
                id: asset?.id,
                // @ts-ignore
                assetType: asset?.assetType,
            });

            LoggingService.getInstance().logEvent(event);
            dispatch(History.actions.push());
            dispatch(Screen.actions.unmount());
            const bookmarkPosition = catchup || recording ? getCatchupBookmarkPosition(isStartOver) : undefined;
            playVod(asset, {
                isStartOver: catchup ? false : isStartOver,
                bookmarkPosition,
                isTrailer,
            });
        }
    };

    if (!asset || !header) return null;

    return (
        <UI.Detail parentalRating={header?.metadata.parentalRatingId} offsetTop={UI.Folder.Constants.List.Scroll.Offset.Header}>
            <UI.Detail.Gradient.Left />
            <UI.Detail.Gradient.Bottom />
            <UI.Detail.Background src={header?.content.thumbnail || ''} />
            <UI.Detail.Body className="my-library__body">
                <UI.Detail.Logo id={header?.content.channelId || ''} />
                <UI.Detail.Title>{header?.content.title}</UI.Detail.Title>
                <UI.Detail.Meta>
                    <UI.Detail.Meta.Row>
                        <MyLibraryHeaderContentMarker />
                        <Icons
                            className="detail__icons"
                            isDolby={header?.entitlements.dolby}
                            isCatchup={header?.entitlements.catchupTV}
                            isRecording={header?.entitlements.recording}
                            assetType={header?.type}
                            isReminderSet={!!reminder}
                            status={header?.entitlements.status || null}
                            parentalControl={header?.metadata.parentalRatingId}
                            audioLanguages={header?.metadata.audioLanguages?.filter((language): language is string => language !== null)}
                            subtitleLanguages={header?.metadata.subtitleLanguages?.filter(
                                (language): language is string => language !== null
                            )}
                            isVisuallyImpaired={header?.entitlements.isVisuallyImpaired}
                            isFavourited={isFavourited}
                        />
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Description className="my-library__description">
                            {header?.content.shortDescription}
                        </UI.Detail.Description>
                    </UI.Detail.Meta.Row>
                </UI.Detail.Meta>
                <UI.Folder.Detail.Actions id="detail-actions">
                    {header.entitlements.playback && (
                        <UI.Folder.Detail.Action id="watch" icon={IconName.PLAY} onEnter={playClicked}>
                            {getPlayButtonLabel()}
                        </UI.Folder.Detail.Action>
                    )}
                    <UI.Detail.Button.StartOver hidden={!assetCanBeStartedOver} onEnter={startOverClicked} />
                    {!Env.isBusiness && (
                        <UI.Folder.Detail.Action
                            id="favorites"
                            icon={isFavourited ? IconName.FAVORITE_OLD : IconName.FAVORITE_EMPTY_OLD}
                            onEnter={addToFavourites}
                        >
                            {`SCREEN_DETAIL_${isFavourited ? 'REMOVE_FROM' : 'ADD_TO'}_FAVORITES_BUTTON`}
                        </UI.Folder.Detail.Action>
                    )}
                </UI.Folder.Detail.Actions>
            </UI.Detail.Body>
        </UI.Detail>
    );
};
