import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type MessageNotificationInfoFragment = { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null };

export const MessageNotificationInfoFragmentDoc = gql`
    fragment messageNotificationInfo on MessageNotificationDisplayInfo {
  timeout
  repeatTimes
  repeatDelay
  screens
}
    `;