// External Modules
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { Event } from 'analytics/types';

export const sendBatchToServer = async (endpoint: string, batch: Event[]): Promise<Response> => {
    console.log('####### Sending events to server: ', batch);
    const user_id: string = profileSelectors.public.selectUserId(store.getState());
    const token: string = Storage.get<string>(Storage.Key.USER_SESSION_TOKEN)!;
    const isIdpLogin = Storage.get<boolean>(Storage.Key.IS_IDP_LOGIN);
    const idpToken = Storage.get<string>(Storage.Key.IDP_ACCESS_TOKEN);
    const headers: Record<string, any> = isIdpLogin
        ? {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idpToken}`,
              SDSEVO_DEVICE_ID: Platform.ID,
              SDSEVO_USER_ID: user_id,
          }
        : {
              'Content-Type': 'application/json',
              SDSEVO_SESSION_ID: token,
              SDSEVO_DEVICE_ID: Platform.ID,
              SDSEVO_USER_ID: user_id,
          };
    try {
        return await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers,
            body: JSON.stringify(batch),
        });
    } catch (error) {
        console.log(`UAR failed to send events with ${error}`);
        return new Response(null, { status: 404 });
    }
};
