export class Calc {
    public static readonly clamp = (min: number, value: number, max: number): number => Math.min(Math.max(min, value), Math.max(min, max));

    public static readonly round = (value: number, roundBy: number) => value - (value % roundBy);

    // NaN is the only JavaScript value that is treated as unequal to itself
    // eslint-disable-next-line no-self-compare
    public static readonly isNaN = (value: number) => typeof value !== 'number' || value !== value;

    public static readonly pixel = (value: number) => Math.round((value / 1920) * window.innerWidth);

    public static readonly isInRange = (min: number, value: number, max: number) => min <= value && value <= max;

    public static readonly infinite = (min: number, value: number, max: number): number => {
        if (Calc.isNaN(min) || Calc.isNaN(value) || Calc.isNaN(max)) return 0;
        if (min === max) return min;
        if (min > max) return Calc.infinite(max, value, min);
        const result = Calc.infiniteBase(min, value, max);
        if (Calc.isInRange(min, value, max)) return result;
        return Calc.infinite(min, result, max);
    };

    private static readonly infiniteBase = (min: number, value: number, max: number): number => {
        if (value > max) return min - 1 + value - max;
        if (value < min) return max + 1 + value - min;
        return value;
    };
}
