import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { BookmarkFragmentDoc } from '../fragments/bookmark.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEventBookmarkMutationVariables = Types.Exact<{
  input: Types.SetEventBookmarkInput;
  profileId: Types.Scalars['ID']['input'];
}>;


export type SetEventBookmarkMutation = { __typename?: 'Nexx4Mutations', setEventBookmark: { __typename?: 'SetEventBookmarkPayload', event: { __typename?: 'Event', personalInfo?: { __typename?: 'PersonalEventInfo', bookmark?: { __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null } | null } | null } } };


export const SetEventBookmarkDocument = gql`
    mutation setEventBookmark($input: SetEventBookmarkInput!, $profileId: ID!) {
  setEventBookmark(input: $input) {
    event {
      personalInfo(profileId: $profileId) {
        bookmark {
          ...bookmark
        }
      }
    }
  }
}
    ${BookmarkFragmentDoc}`;
export type SetEventBookmarkMutationFn = Apollo.MutationFunction<SetEventBookmarkMutation, SetEventBookmarkMutationVariables>;

/**
 * __useSetEventBookmarkMutation__
 *
 * To run a mutation, you first call `useSetEventBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventBookmarkMutation, { data, loading, error }] = useSetEventBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useSetEventBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<SetEventBookmarkMutation, SetEventBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEventBookmarkMutation, SetEventBookmarkMutationVariables>(SetEventBookmarkDocument, options);
      }
export type SetEventBookmarkMutationHookResult = ReturnType<typeof useSetEventBookmarkMutation>;
export type SetEventBookmarkMutationResult = Apollo.MutationResult<SetEventBookmarkMutation>;
export type SetEventBookmarkMutationOptions = Apollo.BaseMutationOptions<SetEventBookmarkMutation, SetEventBookmarkMutationVariables>;