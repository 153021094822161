import { trackViewingBehaviourActions } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/actions';
import { getInitialTrackViewingBehaviourState } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/getInitialState';
import { trackViewingBehaviourReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/reducer';
import { trackViewingBehaviourSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/selectors';
import {
    TrackViewingBehaviourActionType,
    TrackViewingBehaviourReduxAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/types';

export { TrackViewingBehaviourActionType as ActionType };

export type { TrackViewingBehaviourReduxAction as ReduxAction };

export const getInitialState = getInitialTrackViewingBehaviourState;

export const reducer = trackViewingBehaviourReducer;

export const actions = trackViewingBehaviourActions.public;

export const selectors = trackViewingBehaviourSelectors.public;
