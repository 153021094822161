import { FetchPolicy } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useGetTimeframeEventsLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/getTimeframeEvents.generated';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { ParsedConfigServiceConfigImageConfig } from '__SMART_APP_OLD__/app/modules/Config/types';
import { loadLiveEventForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectLiveChannelLiveTVEntitilement } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectCurrentParentalRatingRank } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { selectIsLiveTvPinActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { useScreensaverLogic } from '__SMART_APP_OLD__/common/hooks';
import { useIsMagicMode } from '__SMART_APP_OLD__/common/useIsMagicMode';
import StaticMagicRemoteContainer from '__SMART_APP_OLD__/components/common/StaticMagicRemoteContainer';
import Stripes from '__SMART_APP_OLD__/components/stripe/Stripes';
import Events, { DARK_MODE_CHANGED, USER_ACTIVITY } from '__SMART_APP_OLD__/config/Events';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import { PageDataContext } from '__SMART_APP_OLD__/providers/usePageContext';
import { Route, WIDE_PLAYER_VIEW_ANIMATION_DURATION } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { maskAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import {
    isApplicationVisible,
    subscribeVisibilityChangeHandler,
    unsubscribeVisibilityChangeHandler,
} from '__SMART_APP_OLD__/utils/visibilityChange';

import { Alert } from 'App/Modules/Alert';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { UI } from 'App/Packages/UI';
import { pinActiveStatusChanged, pinActiveStatusLiveTvChanged } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/actions';
import { getEventForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { selectEventIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { StandByService } from 'App/Modules/Common/Components/StandByService';
import { clpp } from '@castlabs/prestoplay';

let intervalID: any = null;

const homeHeaderVariables = {
    variables: {
        channelId: '',
        following: 0,
        previous: 0,
        currentTime: new Date(),
        thumbnailHeight: 200,
        backgroundWidth: 1920,
        backgroundHeight: 1080,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

const setHomeHeaderVariables = (config: ParsedConfigServiceConfigImageConfig) => {
    homeHeaderVariables.variables.channelLogoHeight = config.logo.large.height;
    homeHeaderVariables.variables.channelLogoWidth = config.logo.large.width;
    homeHeaderVariables.variables.thumbnailHeight = config.thumbnail.small.height;
    homeHeaderVariables.variables.backgroundHeight = config.background.landscape;
    homeHeaderVariables.variables.backgroundWidth = config.background.width;
    return homeHeaderVariables;
};

export const HomePage: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const liveChannelId = useSelector(selectChannelLiveId);
    const liveChannelLiveTVEntitlement = useSelector(selectLiveChannelLiveTVEntitilement);
    const isDisplayed = useSelector(Alert.selectors.selectIsActive);
    const isMagicMode = useIsMagicMode();
    const pinSessionActive = useSelector(selectIsLiveTvPinActive);
    const [loadGallery, { data: homeHeaderData, loading: homeHeaderLoading, error: homeHeaderEror }] = useGetTimeframeEventsLazyQuery(
        setHomeHeaderVariables(config.image)
    );

    const [galleryAssets, setGalleryAssets] = useState<any>(undefined);
    const firstGalleryAssetId: number | null = galleryAssets ? galleryAssets?.[0]?.rawData?.id : null;

    const [isPlayerAvailable, setIsPlayerAvailable] = useState<boolean>(config.home.player.enabled);
    const [canPlay, setCanPlay] = useState(Player.isPlaying());
    const { data, loadNextData, initPageData } = useContext(PageDataContext);
    const parentalRatingRank = useSelector(selectCurrentParentalRatingRank);

    const { stripes } = data;
    const [loading, setLoading] = useState(true);

    const { isScreensaverActive } = useScreensaverLogic(liveChannelId, isPlayerAvailable, canPlay);

    const setGalleryVariables = (channelId: string) => {
        homeHeaderVariables.variables.currentTime = new Date();
        homeHeaderVariables.variables.channelId = channelId;
        return homeHeaderVariables;
    };

    const playerNotAvailable = () => {
        window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: false } }));
        setIsPlayerAvailable(false);
    };

    const getGalleryRefreshInterval = (endDate: Date) => {
        const currentDate = new Date();
        const currentTimeInMilliseconds = currentDate.getTime();
        const programEndTimeInMilliseconds = endDate.getTime();

        // added a 500ms safety net as the gallery request returns
        // asset currently playing at the request time
        return programEndTimeInMilliseconds - currentTimeInMilliseconds + 500;
    };

    const setAndMaskGallery = useCallback(
        (assets: any) => {
            if (!assets.length) playerNotAvailable();
            assets.forEach((asset: any) => {
                maskAsset(asset, parentalRatingRank, PinSessionType.PIN_LIVE_TV);
            });
            if (assets.some((asset: any) => asset?.shouldMask || asset?.shouldRestrict)) {
                playerNotAvailable();
            }

            setGalleryAssets(assets);
        },
        [parentalRatingRank]
    );

    const onVisibilityChanged = async () => {
        if (isApplicationVisible()) {
            const event = await getEventForChannel(liveChannelId, Date.now());
            if (!event) {
                return;
            }
            const isRestricted = selectEventIsRestricted(event.id, PinSessionType.PIN_LIVE_TV)(store.getState());
            if (!isRestricted) {
                setIsPlayerAvailable(config.home.player.enabled);
            }
            return;
        }
        const isPlaying = Player.isPlaying();
        const isHidden = !isApplicationVisible();

        if (isHidden && isPlaying) {
            setIsPlayerAvailable(false);
            setCanPlay(false);
            PlayerAPI.stopPlayback();
            dispatch(pinActiveStatusChanged(false));
            dispatch(pinActiveStatusLiveTvChanged(false));
        }
    };

    const isLoading = loading || homeHeaderLoading || galleryAssets === undefined || (isPlayerAvailable && !canPlay);
    const inactivityTimerId = useRef<ReturnType<typeof setTimeout> | null>(null);

    const activityChangeHandler = useCallback(
        (transitionHandler?: {} | (() => {})) => {
            if (
                !config.home.inactivityRedirect.enabled ||
                !config.home.inactivityRedirect.timeout ||
                isLoading ||
                isMagicMode ||
                isDisplayed
            ) {
                return;
            }
            if (inactivityTimerId.current) {
                clearTimeout(inactivityTimerId.current);
            }
            inactivityTimerId.current = setTimeout(() => {
                if (!Player.isPlaying()) {
                    clearTimeout(inactivityTimerId.current!);
                    return;
                }

                if (!Overlay.selectors.select(store.getState())) {
                    if (typeof transitionHandler === 'function') {
                        transitionHandler();
                        setTimeout(() => {
                            CustomHistory.go(`/playChannel/${liveChannelId}`);
                        }, WIDE_PLAYER_VIEW_ANIMATION_DURATION);
                    } else {
                        CustomHistory.go(`/playChannel/${liveChannelId}`);
                    }
                }
            }, config.home.inactivityRedirect.timeout);
        },
        [liveChannelId, isMagicMode, isLoading, config.home.inactivityRedirect, isDisplayed]
    );

    useEffect(() => {
        if (isLoading) return undefined;
        const timer = setTimeout(() => {
            Player.unMute();
            dispatch(Overlay.actions.unmount());
        }, 500);
        return () => clearTimeout(timer);
    }, [isLoading, dispatch]);

    useEffect(() => {
        if (loading) return undefined;
        activityChangeHandler();
        const onCanPlay = () => {
            activityChangeHandler();
        };
        Player.addEventListener(VIDEO_EVENTS.PLAYING, onCanPlay);
        return () => {
            Player.removeEventListener(VIDEO_EVENTS.PLAYING, onCanPlay);
        };
    }, [loading, activityChangeHandler]);

    useEffect(() => {
        if (!isDisplayed) activityChangeHandler();
        Events.addEventListener(USER_ACTIVITY, activityChangeHandler);

        return () => {
            Events.removeEventListener(USER_ACTIVITY, activityChangeHandler);
            if (inactivityTimerId.current) {
                clearTimeout(inactivityTimerId.current);
                inactivityTimerId.current = null;
            }
        };
    }, [activityChangeHandler, isDisplayed]);

    // initialize only stripe data
    useEffect(() => {
        initPageData(Route.HOME);
        dispatch(Screen.actions.unmount());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!liveChannelLiveTVEntitlement) playerNotAvailable();
    }, [liveChannelLiveTVEntitlement]);

    useEffect(() => {
        subscribeVisibilityChangeHandler(onVisibilityChanged);
        return () => {
            unsubscribeVisibilityChangeHandler(onVisibilityChanged);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { loading: dataLoading, pageId } = data;
        if (pageId === Route.HOME) {
            setLoading(dataLoading);
        }
    }, [data]);

    useEffect(() => {
        const onCanPlay = () => {
            if (config.home.player.muted) {
                Player.mute();
            }
            Player.disableSubtitles();
            setCanPlay(true);
        };
        const onStateChanged = (stateChanged: any) => {
            const {
                detail: { currentState },
            } = stateChanged;
            const isPlaying = currentState === clpp.Player.State.PLAYING;
            const isError = currentState === clpp.Player.State.ERROR;
            if (isPlaying || isError) {
                setTimeout(() => setCanPlay(true), 500);
            }
        };
        Player.addEventListener(VIDEO_EVENTS.AUTOPLAY_NOT_ALLOWED, playerNotAvailable);
        Player.addEventListener(VIDEO_EVENTS.CANPLAY, onCanPlay);
        Player.addEventListener(VIDEO_EVENTS.ERROR, playerNotAvailable);
        Player.addEventListener(VIDEO_EVENTS.STATE_CHANGED, onStateChanged);

        return () => {
            Player.removeEventListener(VIDEO_EVENTS.CANPLAY, onCanPlay);
            Player.removeEventListener(VIDEO_EVENTS.ERROR, playerNotAvailable);
            Player.removeEventListener(VIDEO_EVENTS.AUTOPLAY_NOT_ALLOWED, playerNotAvailable);
            Player.removeEventListener(VIDEO_EVENTS.STATE_CHANGED, onStateChanged);
        };
    }, [config.home.player.muted]);

    // request gallery data only when liveChannel is valid
    useEffect(() => {
        if (liveChannelId) {
            loadGallery(setGalleryVariables(liveChannelId));
            dispatch(loadLiveEventForChannel(liveChannelId));
        }
    }, [liveChannelId, dispatch, loadGallery]);

    useEffect(() => {
        if (homeHeaderData && !homeHeaderLoading && !homeHeaderEror) {
            const gallery = homeHeaderData?.channel?.eventsAt?.items.map((item) => createAssetObject(item, item?.__typename));
            setAndMaskGallery(gallery);
        }
    }, [homeHeaderData, homeHeaderLoading, homeHeaderEror, setAndMaskGallery]);

    useEffect(() => {
        if (!pinSessionActive) {
            if (galleryAssets?.length) {
                if (galleryAssets[0]?.shouldRestrict || galleryAssets[0]?.shouldMask) {
                    setIsPlayerAvailable(false);
                }
            }
        }
    }, [galleryAssets, pinSessionActive]);

    // mechanism for triggering auto refresh on gallery asset
    useEffect(() => {
        clearTimeout(intervalID);
        if (galleryAssets?.length) {
            const endDate = galleryAssets[0]?.endTime;
            intervalID = setTimeout(() => {
                loadGallery(setGalleryVariables(liveChannelId));
                dispatch(loadLiveEventForChannel(liveChannelId));
            }, getGalleryRefreshInterval(endDate));
        }
        return () => clearTimeout(intervalID);
    }, [galleryAssets, liveChannelId, dispatch, loadGallery]);

    useEffect(() => {
        if (!config.home.player.enabled) {
            Player.stop();
        }
        if (config.home.player.muted) {
            Player.mute();
        }
        if (Player.isPlaying() && config.home.player.enabled && config.home.player.wideView) {
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: true } }));
        }
        return () => {
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: false } }));
        };
    }, [config]);

    useEffect(() => {
        if (!galleryAssets) return;
        const [firstGalleryAsset] = galleryAssets;

        const shouldPlay =
            galleryAssets?.length &&
            !loading &&
            liveChannelId &&
            isPlayerAvailable &&
            !firstGalleryAsset?.shouldMask &&
            !isScreensaverActive;

        const program = Player.program as any; // Player.program inffered type is null
        const channelId = program?.channelId;

        if (shouldPlay && channelId !== liveChannelId) {
            Player.setProgram(firstGalleryAsset);
            PlayerAPI.setChannelStream(liveChannelId);
            setTimeout(() => {
                setCanPlay(true);
            }, 3000);
        }
    }, [loading, galleryAssets, liveChannelId, isPlayerAvailable, isScreensaverActive]);

    // must wait until all data - including gallery assets - is loaded,
    // otherwise the focus will malfunction on stripes

    return (
        <>
            {isLoading ? (
                <UI.Spinner />
            ) : (
                <Stripes
                    playerTargetDetails={{ isPlayerAvailable: isPlayerAvailable && canPlay && !isScreensaverActive, isLiveEvent: true }}
                    galleryAssets={galleryAssets}
                    stripes={stripes}
                    loadNextData={loadNextData}
                    parentalRatingRank={parentalRatingRank}
                    key={firstGalleryAssetId}
                    onActivity={activityChangeHandler}
                    sessionType={PinSessionType.PIN_LIVE_TV}
                    {...props}
                />
            )}
            <StaticMagicRemoteContainer />
            <StandByService />
        </>
    );
};
