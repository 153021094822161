import React, { useContext } from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ActionsContext } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsContext';

import { Function } from 'App/Packages/Function';

type Props = {
    id: string;
    onEnter?: (item: string) => void;
};

export type CollectionV2ActionButtonModule = React.FunctionComponent<React.PropsWithChildren<Props>>;

export const CollectionV2HeaderButton: CollectionV2ActionButtonModule = (props) => {
    const { focused, ctx, items, onHover, onEnter } = useContext(ActionsContext);

    const shouldRenderButton = items.includes(props.id);

    if (!shouldRenderButton) return null;

    return (
        <Button
            id={props.id}
            ctx={ctx}
            isFocused={focused === props.id}
            onEnter={props.onEnter || onEnter || Function.noop}
            onHover={onHover}
            className="collection-v2__header-action"
        >
            {props.children}
        </Button>
    );
};
