import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
export type SeriesFolderItemFragment = { __typename: 'Series', id: string, title: string, episodeCount: number, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, channel?: { __typename?: 'Channel', id: string } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export const SeriesFolderItemFragmentDoc = gql`
    fragment seriesFolderItem on Series {
  id
  title
  episodeCount
  seasonInfos {
    number
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  channel {
    id
  }
  parentalRating {
    ...parentalRatingRelation
  }
  __typename
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;