import {
    MESSAGE_DETAIL_ACTION_CONTAINER_HEIGHT,
    MESSAGE_DETAIL_ATTACHMENT_CONTAINER_HEIGHT,
    MESSAGE_DETAIL_ONE_LINE_HEADER_HEIGHT,
    MESSAGE_DETAIL_SCREEN_HEIGHT,
    MESSAGE_DETAIL_SCROLL_CONTAINER_HEIGHT,
    MESSAGE_DETAIL_TEXT_LINE_HEIGHT,
    MESSAGE_DETAIL_TEXT_MAX_LINES,
    MESSAGE_DETAIL_TWO_LINE_HEADER_HEIGHT,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import {
    MessageDetailContainerType,
    MessageDetailScrollContainer,
    MessageDetailTextContainer,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

export const getContentHeight = (attachments: boolean, header: number): number => {
    const attachmentsHeight = attachments ? MESSAGE_DETAIL_ATTACHMENT_CONTAINER_HEIGHT : 0;
    const headerHeight = header === 1 ? MESSAGE_DETAIL_ONE_LINE_HEADER_HEIGHT : MESSAGE_DETAIL_TWO_LINE_HEADER_HEIGHT;
    return attachmentsHeight + MESSAGE_DETAIL_ACTION_CONTAINER_HEIGHT + headerHeight;
};

export const getPartionedTextContainerLines = (attachments: boolean, header: number, text: number): number[] => {
    const contentHeight = getContentHeight(attachments, header);
    const first = MESSAGE_DETAIL_SCREEN_HEIGHT - (contentHeight + MESSAGE_DETAIL_SCROLL_CONTAINER_HEIGHT);
    const firstLines = Math.floor(first / MESSAGE_DETAIL_TEXT_LINE_HEIGHT);
    if (firstLines > text) return [text];
    const rest = Math.max(text - firstLines, 0);
    const fullLines: number[] = new Array(Math.floor(rest / MESSAGE_DETAIL_TEXT_MAX_LINES)).fill(MESSAGE_DETAIL_TEXT_MAX_LINES);
    const lastLines = rest % MESSAGE_DETAIL_TEXT_MAX_LINES;
    return [firstLines, ...fullLines, lastLines].filter(Boolean);
};

export const getTextContainer = (from: number, to: number, index: number): MessageDetailTextContainer => ({
    type: MessageDetailContainerType.TEXT,
    from,
    to,
    index,
});

export const getScrollContainer = (from: number, to: number): MessageDetailScrollContainer => ({
    type: MessageDetailContainerType.SCROLL,
    from,
    to,
});
