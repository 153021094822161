import {
    KeyBackspace as Root,
    KeyBackspaceComponent,
    KeyBackspaceProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Backspace/KeyBackspace';
import {
    KeyBackspaceIcon,
    KeyBackspaceIconInsance,
    KeyBackspaceIconComponent,
    KeyBackspaceIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Backspace/KeyBackspaceIcon';

export type { KeyBackspaceComponent, KeyBackspaceProps, KeyBackspaceIconComponent, KeyBackspaceIconProps };

const KeyBackspace = Object.assign(Root, {
    Icon: Object.assign(KeyBackspaceIcon, {
        Instance: KeyBackspaceIconInsance,
    }),
});

export { KeyBackspace };
