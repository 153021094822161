import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export type ReminderReduxAction = ReminderCreateAction | ReminderLoadManyAction | ReminderDeleteAction | ReminderStatusChangeAction;
export type ReminderCreateActionPayload = { reminder: GQL.Reminder; loadTS: number };
export type ReminderLoadManyActionPayload = { reminders: GQL.Reminder[]; loadTS: number; timeBeforeTrigger: number };
export type ReminderDeleteActionPayload = { reminderId: GQL.ReminderID; eventId: GQL.EventID };
export type ReminderStatusChangeActionPayload = { reminderId: string; status: ReminderEntityStatus };
export type ReminderCreateAction = PayloadedAction<ActionType.REMINDER_CREATE, ReminderCreateActionPayload>;
export type ReminderLoadManyAction = PayloadedAction<ActionType.REMINDER_LOAD_MANY, ReminderLoadManyActionPayload>;
export type ReminderDeleteAction = PayloadedAction<ActionType.REMINDER_DELETE, ReminderDeleteActionPayload>;
export type ReminderStatusChangeAction = PayloadedAction<ActionType.REMINDER_STATUS_CHANGE, ReminderStatusChangeActionPayload>;

export type ReminderEntityTable = {
    ids: GQL.ReminderID[];
    entities: Record<GQL.ReminderID, ReminderEntity>;
    triggerTable: Record<GQL.ReminderID, number>;
    statusTable: Record<GQL.ReminderID, ReminderEntityStatus>;
    eventReminderRelationTable: Record<GQL.EventID, GQL.ReminderID>;
};

export type ReminderEntity = {
    id: GQL.ReminderID;
    channelId: GQL.ChannelID;
    eventId: GQL.EventID;
    start: number;
    title: string;
};

export enum ReminderEntityStatus {
    ACTIVE = 'ACTIVE',
    SNOOZED = 'SNOOZED',
    DISMISSED = 'DISMISSED',
}

export enum ReminderActionID {
    WATCH = 'WATCH',
    SNOOZE = 'SNOOZE',
    RECORD = 'RECORD',
    CANCEL = 'CANCEL',
}
