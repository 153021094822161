import React, { useEffect, useState } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Overlay } from 'App/Modules/Overlay';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import Player from '__SMART_APP_OLD__/platforms/Player';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { Route } from '__SMART_APP_OLD__/utils/Constants';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Image } from 'App/Packages/UI/Image';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';

export const StandByOverlayView: React.FunctionComponent<Overlay.Props<Overlay.Type.StandBy>> = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const countdown = config.standBy.countdown / 1000;
    const theme = useTheme();

    const [counter, setCounter] = useState(countdown);
    const minutes = Math.floor(counter / 60);
    const seconds = Math.ceil(counter % 60);

    const handleHomeBack = () => {
        if (document.location.pathname.includes('HOME')) {
            dispatch(Overlay.actions.unmount());
            Player.play();
        } else {
            CustomHistory.go(`/page/${Route.HOME}`);
            CustomHistory.clear();
            Player.play();
        }
    };

    Focus.useKeyDown(() => (counter !== 0 ? dispatch(Overlay.actions.unmount()) : handleHomeBack()), {});

    useEffect(() => {
        const timer = setTimeout(() => {
            setCounter((c) => c - 1);
        }, 1000);

        if (counter === 0) {
            Player.pause();
            clearTimeout(timer);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [counter]);

    return (
        <Layout backgroundColor={BackgroundColor.STANDBY_OVERLAY} zIndex={Overlay.zIndex}>
            {counter !== 0 ? (
                <div className="standBy-overlay">
                    <Text
                        className="standBy-overlay__title"
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_3}
                        weight={FontWeight.BOLD}
                        color={theme === Theme.Type.Dark ? Color.PRIMARY : Color.PRIMARY_INVERTED}
                    >
                        STANDBY_OVERLAY_TITLE
                    </Text>
                    <Text
                        className="standBy-overlay__text"
                        size={FontSize.BODY_2}
                        typeface={Typeface.SANS}
                        weight={FontWeight.REGULAR}
                        color={theme === Theme.Type.Dark ? Color.PRIMARY : Color.PRIMARY_INVERTED}
                    >
                        STANDBY_OVERLAY_BODY
                    </Text>
                    <Text
                        className="standBy-overlay__counter"
                        typeface={Typeface.SANS}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.REGULAR}
                        color={theme === Theme.Type.Dark ? Color.PRIMARY : Color.PRIMARY_INVERTED}
                    >
                        {`${minutes}:${seconds.toString().padStart(2, '0')}`}
                    </Text>
                </div>
            ) : (
                <Image>
                    <Image.Img src={config.standBy.screenSaverImg} />
                </Image>
            )}
        </Layout>
    );
};
