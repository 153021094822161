import { vodUpsellActions } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/actions';
import { vodUpsellReducer } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/reducer';
import { vodUpsellSelectors } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/selectors';
import { VodUpsellEntityTable, VodUpsellReduxAction } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { VodUpsellView } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/VodUpsellView';

export const reducer = vodUpsellReducer;
export type { VodUpsellReduxAction as ReduxAction, VodUpsellEntityTable as EntityTable };

export const selectors = vodUpsellSelectors.public;
export const actions = vodUpsellActions.public;
export const { lifecycle } = vodUpsellActions;

export const View = VodUpsellView;
