import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum PlayerVisibleComponent {
    PLAYER_UI = 'playerUI',
    EPG = 'EPG',
    MINI_EPG = 'miniEPG',
    SUBTITLES = 'subtitles',
    CHANNEL_SWITCH = 'channelSwitch',
    BINGE_WATCH_DIALOG = 'bingeWatchDialog',
    NONE = 'none',
}

export enum PlayerActionType {
    VISIBLE_COMPONENT_CHANGED = '@player/visible_component_changed',
}

export type PlayerData = {
    visibleComponent: PlayerVisibleComponent;
};

export type PlayerReduxAction = PlayerVisibleComponentChangedAction;
export type PlayerVisibleComponentChangedAction = PayloadedAction<PlayerActionType.VISIBLE_COMPONENT_CHANGED, PlayerVisibleComponent>;
