import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import React from 'react';

export const LoginWithEmailGuide: React.FunctionComponent = () => {
    return (
        <div className="login-with-email-guide">
            <Text
                className="login-with-email-guide__title"
                typeface={Typeface.SANS}
                size={FontSize.BODY_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                SCREEN_SUBSCRIPTION_HINT
            </Text>
            <ol className="login-with-email-guide__list">
                <li className="login-with-email-guide__step">
                    <Text
                        className="login-with-email-guide__text"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_2}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                    >
                        SCREEN_SUBSCRIPTION_HINT_STEP_1
                    </Text>
                </li>
                <li className="login-with-email-guide__step">
                    <Text
                        className="login-with-email-guide__text"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_2}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                    >
                        SCREEN_SUBSCRIPTION_HINT_STEP_2
                    </Text>
                </li>
                <li className="login-with-email-guide__step">
                    <Text
                        className="login-with-email-guide__text"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_2}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                    >
                        SCREEN_SUBSCRIPTION_HINT_STEP_3
                    </Text>
                </li>
            </ol>
        </div>
    );
};
