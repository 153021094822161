import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeyboardState = 'opened' | 'closed';

export type KeyboardProps = DivPropsExtendable & { state?: KeyboardState };

export type KeyboardComponent = React.FunctionComponent<KeyboardProps>;

export const Keyboard: KeyboardComponent = (props) => {
    const { state = 'closed', children, ...other } = props;
    return (
        <Div defaultClassName={`keyboard keyboard--${state}`} {...other}>
            {children}
        </Div>
    );
};
