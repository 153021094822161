import { ContentRef } from './ContentRef';

export enum DeliveryProtocols {
    HLS = 'Unicast ABR HLS',
    DASH = 'Unicast ABR DASH',
    MSS = 'Unicast ABR MSS',
}

export enum ViewServices {
    LIVE = 'Live TV',
    NPLTV = 'Timeshift TV',
    CATCHUP = 'Catchup TV',
    RESTART = 'Restart TV',
    NPVR = 'PVR',
    VOD = 'VOD',
    MEDIA_CENTER = 'Media Center',
}

export interface ViewDataParams {
    contentRef: ContentRef;
    service: ViewServices;
    deliveryProtocol: DeliveryProtocols;
    videoSource?: VideoSource;
    summary?: any;
}

export interface VideoSource {
    playbackURL: string;
}

/**
 * @class ViewData
 */
export class ViewData {
    private readonly contentRef: ContentRef;

    private readonly service: ViewServices;

    private readonly deliveryProtocol: DeliveryProtocols;

    private videoSource?: VideoSource;

    // Need to check the documentation to create type for summary
    private summary?: any;

    /**
     * @param constructor constructor parameters
     * @param constructor.contentRef
     * @param constructor.service
     * @param constructor.deliveryProtocol
     * @param constructor.videoSource
     * @param constructor.summary
     */
    constructor({ contentRef, service, deliveryProtocol, videoSource, summary }: ViewDataParams) {
        this.contentRef = contentRef;
        this.service = service;
        this.deliveryProtocol = deliveryProtocol;
        if (videoSource) this.videoSource = videoSource;
        else delete this.videoSource;

        if (summary) this.summary = summary;
        else delete this.summary;
    }
}
