import React from 'react';

import { SettingsSectionHeaderTitle } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionHeaderTitle';

export type SettingsSectionHeaderModule = {
    Title: React.FunctionComponent<React.PropsWithChildren>;
} & React.FunctionComponent<React.PropsWithChildren>;

export const SettingsSectionHeader: SettingsSectionHeaderModule = (props) => (
    <div className="settings-section__header">
        <div className="settings-section-header">{props.children}</div>
    </div>
);

SettingsSectionHeader.Title = SettingsSectionHeaderTitle;
