import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { StartupBehaviorScreenOptions } from '__SMART_APP_OLD__/app/modules/Screen/modules/StartupBehaviorScreen/StartupBehaviorScreenOptions';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

export const StartupBehaviorScreenView: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <Layout className="startup-behavior" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <Text
                className="startup-behavior__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                STARTUP_BEHAVIOR
            </Text>
            <Text
                className="startup-behavior__subtitle"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                STARTUP_BEHAVIOR_HINT
            </Text>
            <div className="startup-behavior__options-container">
                <StartupBehaviorScreenOptions />
            </div>
        </Layout>
    );
};
