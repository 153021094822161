import { batch } from 'react-redux';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { API } from '__SMART_APP_OLD__/app/api';
import { CreateNetworkRecordingDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/createRecording.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig, selectReminderTimer } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import {
    selectReminder,
    selectReminderByEventId,
    selectReminderEntityTableTriggerTSStack,
    selectReminderStatus,
    selectRemindersByEventStart,
    selectRemindersByTriggerTS,
} from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import {
    ReminderCreateAction,
    ReminderDeleteAction,
    ReminderDeleteActionPayload,
    ReminderEntity,
    ReminderEntityStatus,
    ReminderLoadManyAction,
    ReminderStatusChangeAction,
    ReminderStatusChangeActionPayload,
} from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { generateUIReminderEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/utils';
import { Error as ErrorModule } from '__SMART_APP_OLD__/app/modules/Error';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { notificationShow, textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { NotificationActionID, ReminderNotificationEntity } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { addRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import Events, { NOTIFICATION_HIDE } from '__SMART_APP_OLD__/config/Events';
import { ReminderEvents } from 'analytics/logging/events/ReminderEvent';
import { getReminderEvent } from 'analytics/logging/factories/reminderEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { REMINDER_MINIMUM_TIME_BEFORE_TRIGGER } from './constants';

export const reminderCreateOne = (reminder: GQL.Reminder): ReminderCreateAction => ({
    type: ActionType.REMINDER_CREATE,
    payload: { reminder, loadTS: Date.now() },
});

export const reminderLoadMany = (reminders: GQL.Reminder[], timeBeforeTrigger: number = 0): ReminderLoadManyAction => ({
    type: ActionType.REMINDER_LOAD_MANY,
    payload: { reminders, loadTS: Date.now(), timeBeforeTrigger },
});

export const reminderDeleteOne = (payload: ReminderDeleteActionPayload): ReminderDeleteAction => ({
    type: ActionType.REMINDER_DELETE,
    payload,
});

export const reminderStatusChange = (payload: ReminderStatusChangeActionPayload): ReminderStatusChangeAction => ({
    type: ActionType.REMINDER_STATUS_CHANGE,
    payload,
});

export const reminderCreate =
    (eventId: string): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const reminder = selectReminderByEventId(eventId)(state);
        if (reminder) return true;
        try {
            const response = await API.Reminder.create(eventId);
            dispatch(reminderCreateOne(response.reminder));
            return true;
        } catch (error) {
            dispatch(ErrorModule.actions.occured(ErrorModule.Dispatcher.REMINDER_CREATE, error));
            return false;
        }
    };

export const reminderCancel =
    (eventId: string): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const reminder = selectReminderByEventId(eventId)(state);
        if (!reminder) return true;
        try {
            await API.Reminder.remove(eventId);
            return true;
        } catch (error) {
            dispatch(ErrorModule.actions.occured(ErrorModule.Dispatcher.REMINDER_DELETE, error));
            return false;
        } finally {
            dispatch(reminderDeleteOne({ eventId, reminderId: reminder.id }));
        }
    };

export const reminderLoad = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const profileId = Profile.selectors.selectId(state);
        const response = await API.Reminder.loadMany(profileId);
        dispatch(reminderLoadMany(response.reminders));
        return true;
    } catch (error) {
        dispatch(ErrorModule.actions.occured(ErrorModule.Dispatcher.REMINDER_LOAD, error));
        return false;
    }
};

export const reminderLoadFuture = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const profileId = Profile.selectors.selectId(state);
        const response = await API.Reminder.loadMany(profileId);
        dispatch(reminderLoadMany(response.reminders, REMINDER_MINIMUM_TIME_BEFORE_TRIGGER));
        return true;
    } catch (error) {
        dispatch(ErrorModule.actions.occured(ErrorModule.Dispatcher.REMINDER_LOAD, error));
        return false;
    }
};

export const reminderBackTriggered =
    (reminderId: GQL.ReminderID): Thunk<void> =>
    (dispatch, getState) => {
        const status = selectReminderStatus(reminderId)(getState());
        if (!status) return;
        const newStatus = status === ReminderEntityStatus.ACTIVE ? ReminderEntityStatus.SNOOZED : ReminderEntityStatus.DISMISSED;

        dispatch(reminderStatusChange({ reminderId, status: newStatus }));
    };

export const reminderNotificationWatchActionTriggered =
    (reminderId: GQL.ReminderID): Thunk<void> =>
    (dispatch, getState) => {
        const reminder = selectReminder(reminderId)(getState());
        if (!reminder) return;
        if (!window.location.pathname.includes(`playChannel/${reminder.channelId}`)) {
            dispatch(History.actions.clearReduxBeforeNavigation());
            dispatch(Screen.actions.unmount());
            dispatch(Overlay.actions.unmount());
            playChannel(reminder.channelId);
            Events.triggerEvents(NOTIFICATION_HIDE);
        }
        const reminders = selectRemindersByEventStart(reminder.start)(getState())
            .map((id) => selectReminder(id)(getState()))
            .filter((r): r is ReminderEntity => !!r);
        batch(() => reminders.forEach((entity) => dispatch(reminderDeleteOne({ reminderId: entity.id, eventId: entity.eventId }))));
    };

export const reminderRecordTriggered =
    (id: string): Thunk<void> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const reminder = selectReminder(id)(state);
            if (!reminder) throw new Error();
            const variables = {
                input: { eventId: reminder.eventId },
                backgroundWidth: config.image.background.width,
                backgroundHeight: config.image.background.landscape,
            };
            const result = await GraphqlClient.makeGraphqlMutationRequest(CreateNetworkRecordingDocument, variables);
            if (!result || result.errors) throw new Error('mutation failed');
            const { quota, recording } = result.data.createNetworkRecording;
            dispatch(addRecording(recording));
            dispatch(Household.actions.recordingQuotaLoad(quota));
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_SCHEDULED'));
        } catch (error) {
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_START_FAILED'));
        }
    };

export const reminderNotificationActionTriggered =
    (reminderId: string, action: NotificationActionID): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const reminder = selectReminder(reminderId)(getState());
        if (!reminder) return;
        switch (action) {
            case NotificationActionID.WATCH:
                dispatch(reminderNotificationWatchActionTriggered(reminderId));
                LoggingService.getInstance().logEvent(getReminderEvent(ReminderEvents.TUNE, reminder.eventId));

                break;
            case NotificationActionID.SNOOZE:
                dispatch(reminderStatusChange({ reminderId, status: ReminderEntityStatus.SNOOZED }));
                LoggingService.getInstance().logEvent(getReminderEvent(ReminderEvents.SNOOZE, reminder.eventId));

                break;
            case NotificationActionID.RECORD:
                dispatch(reminderRecordTriggered(reminderId));
                LoggingService.getInstance().logEvent(getReminderEvent(ReminderEvents.RECORD, reminder.eventId));

                break;
            case NotificationActionID.CANCEL:
                dispatch(reminderCancel(reminder.eventId));
                LoggingService.getInstance().logEvent(getReminderEvent(ReminderEvents.CANCEL, reminder.eventId));

                break;
            default:
                break;
        }
    };

export const reminderSchedule =
    (triggerTS: number): Thunk<number | null> =>
    (dispatch, getState) => {
        const triggerTSStack = selectReminderEntityTableTriggerTSStack(getState());
        if (!triggerTSStack.length) return null;
        const index = triggerTSStack.indexOf(triggerTS);
        const nextTriggerTS = triggerTSStack[index + 1] ?? null;
        if (index === -1) return nextTriggerTS;
        const reminderIds = selectRemindersByTriggerTS(triggerTS)(getState());
        if (!reminderIds.length) return nextTriggerTS;
        const profileId = Profile.selectors.selectId(getState());
        const timer = selectReminderTimer(getState());
        batch(async () => {
            const promisified = reminderIds.reduce<Promise<ReminderNotificationEntity | null>[]>((uiReminders, outDatedId) => {
                const reminder = selectReminder(outDatedId)(getState());
                const status = selectReminderStatus(outDatedId)(getState());
                if (!reminder) return uiReminders;
                const chName = selectChannelName(reminder.channelId)(getState());
                if (status !== ReminderEntityStatus.DISMISSED) {
                    return [...uiReminders, generateUIReminderEntity(reminder, status, chName, profileId, timer)];
                }
                if (!window.location.pathname.includes(`playChannel/${reminder.channelId}`)) {
                    dispatch(History.actions.clearReduxBeforeNavigation());
                    dispatch(Screen.actions.unmount());
                    dispatch(Overlay.actions.unmount());
                    playChannel(reminder.channelId);
                }
                Events.triggerEvents(NOTIFICATION_HIDE);
                const remindersToDelete = selectRemindersByEventStart(reminder.start)(getState());
                remindersToDelete.forEach((id) =>
                    dispatch(reminderDeleteOne({ reminderId: id, eventId: selectReminder(id)(getState())?.eventId ?? '' }))
                );
                return uiReminders;
            }, []);
            const promises = await Promise.all(promisified);
            const uiReminders = promises.reduce<ReminderNotificationEntity[]>(
                (entites, entity) => (!entity ? entites : [...entites, entity]),
                []
            );
            uiReminders.forEach((reminder) => dispatch(notificationShow(reminder)));
        });
        return nextTriggerTS;
    };
