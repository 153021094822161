/* eslint-disable max-lines */
import { DeepLink } from 'App/Packages/DeepLink';
import React from 'react';
import { connect } from 'react-redux';

import { ContentFolderKind } from '__SMART_APP_OLD__/api/graphql/types';
import { playChannel, playVod } from '__SMART_APP_OLD__/api/Tuner';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectMaxItemCollectionLayout } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import {
    selectChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    channelNotInActiveChannelListNotificationShow,
    channelNotSubscribedNotificationShow,
} from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { store } from '__SMART_APP_OLD__/app/store/store';
import PortraitCard from '__SMART_APP_OLD__/components/common/cards/PortraitCard';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { getAssetCardComponent, landscapeCardsConstants } from '__SMART_APP_OLD__/utils/CardUtils';
import { AssetType, PinAction, ProgramType, StripeAutoType, StripeType, stripeConfig } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { maskAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import { smoothScroll } from '__SMART_APP_OLD__/utils/smoothScroll';
import State from '__SMART_APP_OLD__/utils/State';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { contentItemTypeForAssetType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

const BOUNDARY_LOAD_SIZE = 4;
const SCROLL_OFFSET = 300;
const ANIMATION_DURATION = 300;

class Stripe extends React.Component {
    static activeIndexStore = new Map();

    transitionDelayId = undefined;

    transitionTimeoutId = undefined;

    transitionIntervalId = undefined;

    constructor(props) {
        super(props);

        const { type, assets } = props;
        let index = 0;
        let from = 0;
        let to = type === 'movie' || type === 'mixed' ? 10 : 7;

        if (State.hasState('Stripe')) {
            this.ref = React.createRef();
            const { stripeId, menuId, rangeFrom, rangeTo, activeAssetId } = State.get('Stripe');
            if (stripeId === props.stripeId && menuId === props.menuId) {
                let currentActiveIndex = assets?.findIndex((asset) => asset.id === activeAssetId);
                if (currentActiveIndex === -1) {
                    currentActiveIndex = 0;
                }
                index = currentActiveIndex;
                from = rangeFrom;
                to = rangeTo;
            }
        }

        index = Stripe.activeIndexStore.get(props.stripeId) ?? index;

        this.state = {
            activeIndex: index,
            rangeFrom: from,
            rangeTo: to,
            tiles: this.genTiles(this.ref ? index : null),
        };
    }

    get tiles() {
        const { type } = this.props;
        const { tiles } = this.state;
        if (type === StripeType.TV_CHANNEL) {
            return tiles;
        }
        // commented this line, because in mixed stripe there is an empty place due to this cut
        // return tiles.slice(rangeFrom, rangeTo);
        return tiles;
    }

    static clearActiveIndexStore() {
        Stripe.activeIndexStore.clear();
    }

    componentDidMount() {
        const stripeState = State.get('Stripe');
        const { stripeId, type, disableAutoFocus } = this.props;
        const isSmallCard = type === StripeType.GALLERY || type === StripeType.GENRE;

        this.focusLimit = isSmallCard ? 4 : 2;

        if (!stripeState) {
            return;
        }

        if (stripeId === stripeState.stripeId && this.ref?.current && !disableAutoFocus) {
            this.nodeRef.nodeRef.nodeRef.scrollLeft = stripeState.scrollLeft;
            Focus.focus(this.ref?.current?.nodeRef);
        }
    }

    componentWillUnmount() {
        Stripe.activeIndexStore.set(this.props.stripeId, this.state.activeIndex);
    }

    getNumberOfItemsForCollection() {
        return selectMaxItemCollectionLayout(store.getState()) || stripeConfig.numOfAssetsForCollection;
    }

    getSeeAllCardIfNeeded(activeIndex) {
        const { menuId, stripeId, assets, isRelated } = this.props;
        const count = this.getNumberOfItemsForCollection();
        if (assets.length > count && !isRelated) {
            return (
                <PortraitCard
                    key={`${stripeId}-see-all`}
                    isSeeAllCard
                    data={{ title: translate('VIEW_ALL') }}
                    onFocus={() => {
                        this.setState({ activeIndex: count - 1 });
                    }}
                    onEnter={() => {
                        changePlayerVisibility(false);
                        this.props.toCollectionPage(stripeId, menuId === 'search');
                    }}
                    {...(activeIndex === count - 1 ? { ref: this.ref } : {})}
                />
            );
        }
        return null;
    }

    cleanTransition = () => {
        clearInterval(this.transitionIntervalId);
        clearTimeout(this.transitionTimeoutId);
    };

    // eslint-disable-next-line complexity,max-statements,react-func/max-lines-per-function
    onFocusChanged = (focusedNode, prevDom) => {
        const focusedDom = focusedNode.nodeRef;
        this.cleanTransition();
        clearTimeout(this.transitionDelayId);

        let parentDom = focusedDom.parentNode; // parentDom = stripe (container class=tiles)
        if (!parentDom.className.includes('tiles')) {
            parentDom = parentDom.parentNode;
        }
        const stripeChildNodes = parentDom.childNodes;
        const { onFocus, stripeindex, menuId, onFocusChanged, stripeId, assets } = this.props;
        const { activeIndex, rangeFrom, rangeTo, tiles } = this.state;
        if (State.hasState('Stripe')) {
            const stripeState = State.get('Stripe');
            if (stripeId !== stripeState.stripeId && !assets?.[activeIndex]) {
                Focus.focus(stripeChildNodes[0]);
                return true;
            }
        }

        const asset = assets?.[activeIndex];
        if (onFocusChanged) {
            onFocusChanged({
                activeIndex,
                focusedNode,
            });
        }
        if (parentDom && prevDom && parentDom !== prevDom.parentNode && onFocus) {
            onFocus(activeIndex);
        }

        if (stripeChildNodes.length < 3) {
            State.set('Stripe', {
                scrollLeft: parentDom.scrollLeft,
                menuId,
                stripeindex,
                stripeId,
                activeIndex,
                activeAssetId: asset?.id,
                rangeFrom,
                rangeTo,
            });
            return true;
        }

        const windowWidth = window.innerWidth;
        const focusedRect = focusedDom.children[0].getBoundingClientRect();

        const leftBorder = SCROLL_OFFSET;
        const rightBorder = windowWidth - SCROLL_OFFSET;

        if (focusedRect.left < leftBorder) {
            parentDom.scrollLeft += focusedRect.left - leftBorder;
        }

        if (focusedRect.right > rightBorder) {
            parentDom.scrollLeft += focusedRect.right - rightBorder;
        }

        // handling for the last portrait card not being visible entirely
        // after of transition to the right
        const transitionElem = focusedDom.getElementsByClassName('transition-flag')[0];
        if (transitionElem) {
            this.transitionDelayId = setTimeout(() => {
                const transitionElemRect = transitionElem.getBoundingClientRect();

                if (transitionElemRect.left + transitionElem.scrollWidth > rightBorder) {
                    const scrollLeft = parentDom.scrollLeft + transitionElemRect.left + transitionElem.scrollWidth - rightBorder;
                    smoothScroll(parentDom, scrollLeft, ANIMATION_DURATION);
                }
            }, 2000);
        }

        const transitionElemLeft = document.querySelectorAll('[scrollLeft="start"]')[0];
        if (transitionElemLeft) {
            this.transitionDelayId = setTimeout(() => {
                const transitionElemRect = transitionElemLeft.getBoundingClientRect();
                if (transitionElemRect.left + transitionElemLeft.scrollWidth < leftBorder) {
                    parentDom.scrollLeft += transitionElemRect.left - transitionElemLeft.scrollWidth - leftBorder;
                    smoothScroll(parentDom, parentDom.scrollLeft, ANIMATION_DURATION);
                    transitionElemLeft.removeAttribute('scrollLeft');
                }
            }, 200);
        }

        let diff = 0;
        if (rangeTo - activeIndex < BOUNDARY_LOAD_SIZE && rangeTo < tiles.length) {
            diff = 1;
        }
        if (activeIndex - rangeFrom < BOUNDARY_LOAD_SIZE && rangeFrom > 0) {
            diff = -1;
        }

        State.set('Stripe', {
            scrollLeft: parentDom.scrollLeft,
            menuId,
            stripeindex,
            stripeId,
            activeIndex,
            activeAssetId: asset?.id,
            rangeFrom: rangeFrom + diff,
            rangeTo: rangeTo + diff,
        });

        this.setState({
            rangeFrom: rangeFrom + diff,
            rangeTo: rangeTo + diff,
        });
        return true;
    };

    handleNetworkRecordingEnter(asset) {
        const { status, assetType, id } = asset;

        if (status === GQL.RecordingStatus.Failed) {
            this.props.notificationShow('NOTIFICATION_FAILED_RECORDING_NO_DETAIL_PAGE');
            return;
        }

        if (window.location.pathname === `/details/${assetType}/${asset.id}`) return;
        this.props.toDetailPage(id, contentItemTypeForAssetType[assetType], () =>
            CustomHistory.replaceOnPlayPage(`/details/${AssetType.NETWORK_RECORDING}/${asset.id}`)
        );
    }

    handleEpisodeEnter = (asset) => {
        const { episode, updatePlayerData, assets } = this.props;
        const { assetType, seasonOrdinal, episodeOrdinal } = asset;
        if (episode) {
            if (episode.isEntitled) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id: asset.id }));
                Player.stop()
                    .then(() => {
                        if (updatePlayerData) {
                            episode.attachedAssets = assets;
                            updatePlayerData(episode);
                        }
                        return playVod(episode, { isStartOver: false, isTrailer: false, isReplaceNeeded: true });
                    })
                    .catch((error) => console.log('error', error));
            }
        } else {
            this.props.toDetailPage(asset.id, contentItemTypeForAssetType[assetType], () =>
                CustomHistory.replaceOnPlayPage(`/details/${AssetType.VOD_SERIES}/${asset.seriesId}`, {
                    episodeData: {
                        seasonOrdinal,
                        episodeOrdinal,
                    },
                })
            );
        }
    };

    handleChannelEnter = (asset) => {
        const { assetType, id, title: channelTitle } = asset;
        const channelListId = selectSelectedChannelListId(store.getState());
        const channelIds = selectChannelListChannelIds(channelListId)(store.getState());
        const isChannelInChannelList = channelIds.find((channelId) => channelId === id);
        if (asset.rawData.userInfo.subscribed) {
            if (isChannelInChannelList) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id }));
                CustomHistory.go(`/playChannel/${id}`);
            } else {
                this.props.channelNotInActiveChannelListNotificationShow(
                    'NOT_IN_ACTIVE_CHANNEL_LIST',
                    NotificationIconType.INFO,
                    channelTitle
                );
            }
        } else {
            this.props.channelNotSubscribedNotificationShow('UNSUBSCRIBED_PLAYER_INFO_TEXT', NotificationIconType.INFO, channelTitle);
        }
    };

    handleSeasonEnter(asset) {
        const { assetType } = asset;

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id: asset.id }));

        if (asset.programType === ProgramType.LIVE) {
            playChannel(asset.channelId);
        } else {
            playVod(asset, {
                isStartOver: false,
                bookmarkPosition: asset.bookmark?.position || asset.leadIn,
                isTrailer: false,
            });
        }
    }

    handleBannerEnter(asset) {
        this.props.executeDeepLink(asset.rawData.link);
    }

    handleDefaultEnter(asset) {
        const { assetType } = asset;
        if (window.location.pathname === `/details/${assetType}/${asset.id}`) return;
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: asset.id }));
        this.props.toDetailPage(asset.id, contentItemTypeForAssetType[assetType], () =>
            CustomHistory.replaceOnPlayPage(`/details/${assetType}/${asset.id}`)
        );
    }

    enter(asset) {
        const { isRelated, assetType, autoPlayType, isEntitled, isPlaybackAvailable } = asset;
        const { resumePlayback } = this.props;

        if (resumePlayback) {
            return Player.play();
        }

        if (isRelated) return undefined;

        switch (assetType) {
            case AssetType.NETWORK_RECORDING:
                this.handleNetworkRecordingEnter(asset);
                break;
            case AssetType.EPISODE:
                this.handleEpisodeEnter(asset);
                break;
            case AssetType.CHANNEL:
                this.handleChannelEnter(asset);
                break;
            case autoPlayType === StripeAutoType.SEASON && isEntitled && isPlaybackAvailable:
                this.handleSeasonEnter(asset);
                break;
            case AssetType.BANNER:
                this.handleBannerEnter(asset);
                break;
            default:
                this.handleDefaultEnter(asset);
                break;
        }
        return undefined;
    }

    enterAsset(asset) {
        if (asset.shouldRestrict) {
            const forceProtection = !asset.adult ?? true;

            openPinOverlay(
                () => this.enter(asset),
                () => {
                    this.props.unmountOverlay();
                },
                PinAction.ENTER,
                this.props.sessionType ?? PinSessionType.PIN,
                forceProtection
            );
        } else {
            this.enter(asset);
        }
    }

    genTiles(activeIndex) {
        const {
            assets,
            type,
            isRelated,
            parentalRatingRank,
            // menuId,
        } = this.props;
        const tiles = [];

        if (!assets) {
            return tiles;
        }

        let generatedCards = [];

        const limit =
            assets.length > this.getNumberOfItemsForCollection() && !isRelated ? this.getNumberOfItemsForCollection() - 1 : assets.length;

        assets.slice(0, limit).forEach((asset, index) => {
            const { assetType, id } = asset;
            const key = `${id}-${index}`;

            let assetUIType;

            switch (type) {
                case StripeType.GALLERY:
                    assetUIType = AssetType.SIMPLE_PROGRAM;
                    break;
                case StripeType.PERSON:
                    assetUIType = AssetType.PERSON;
                    break;
                case StripeType.TV_CHANNEL:
                    assetUIType = AssetType.TV_CHANNEL;
                    break;
                default:
                    assetUIType = assetType;
            }

            const AssetCard = getAssetCardComponent(assetUIType);
            if (!AssetCard) return;
            if (type === StripeType.TV_CHANNEL) {
                generatedCards.push(
                    <AssetCard
                        key={key}
                        data={asset}
                        onFocus={() => {
                            this.setState({ activeIndex: index });
                        }}
                        onEnter={() => {
                            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY_LIVE, { id: asset.id }));
                            playChannel(asset.id);
                        }}
                        isRelated={isRelated}
                        {...(activeIndex === index ? { ref: this.ref } : {})}
                    />
                );

                if (generatedCards.length === 2) {
                    tiles.push(
                        <div key={`channel-bundle-${index}`} className="channel-bundle-wrapper">
                            {generatedCards}
                        </div>
                    );
                    generatedCards = [];
                }
            } else {
                maskAsset(asset, parentalRatingRank, PinSessionType.PIN);
                tiles.push(
                    <AssetCard
                        key={key}
                        data={asset}
                        onFocus={() => {
                            this.setState({ activeIndex: index }, () => {
                                this.props.onFocusChanged?.({ activeIndex: index });
                            });
                        }}
                        isRelated={isRelated}
                        onEnter={() => {
                            this.enterAsset(asset);
                        }}
                        {...(activeIndex === index ? { ref: this.ref } : {})}
                        shouldMask={asset.shouldMask}
                    />
                );
            }
        });

        const seeAllCard = this.getSeeAllCardIfNeeded(activeIndex);
        if (seeAllCard !== null) {
            tiles.push(seeAllCard);
        }
        return tiles;
    }

    isMixedStripe = () => {
        const { assets, type, menuId, isRelated } = this.props;
        const landscapeCards = landscapeCardsConstants();
        return (
            assets?.some((asset) => landscapeCards.includes(asset.assetType)) ||
            type === ContentFolderKind.Favourites ||
            type === ContentFolderKind.ContinueWatching ||
            menuId === 'search' ||
            isRelated
        );
    };

    hasFoldersInside = () => {
        const { assets } = this.props;
        return assets?.some((asset) => asset.assetType === AssetType.VOD_FOLDER) || false;
    };

    onKey = (key) => {
        if (typeof this.props.onKey === 'function') {
            return this.props.onKey(key);
        }
        return false;
    };

    render() {
        const { className, title, type, stripeId, scrollTop } = this.props;
        const isMixedStripe = this.isMixedStripe();
        const foldersInside = this.hasFoldersInside();

        return (
            <div
                className={className}
                type={type}
                id={stripeId}
                data-active-index={this.state.activeIndex}
                data-has-quota={!!this.props.recordQuota}
            >
                {title && (
                    <Text
                        className="stripe-title"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                    >
                        {translate(title)}
                    </Text>
                )}
                {this.props.recordQuota}
                <Container
                    className={`tiles ${isMixedStripe ? 'mixed-a1' : ''} ${foldersInside ? 'folders' : ''}`}
                    onFocusChanged={this.onFocusChanged.bind(this)}
                    onKey={this.onKey}
                    scrollTop={scrollTop}
                    ref={(ref) => {
                        this.nodeRef = ref;
                    }}
                >
                    {this.tiles}
                </Container>
            </div>
        );
    }
}

export default connect(
    null,
    (dispatch) => ({
        notificationShow: (text) => dispatch(textNotificationShow(text)),
        channelNotSubscribedNotificationShow: (text, icon, channelTitle) =>
            dispatch(channelNotSubscribedNotificationShow(text, icon, channelTitle)),
        channelNotInActiveChannelListNotificationShow: (text, icon, channelTitle) =>
            dispatch(channelNotInActiveChannelListNotificationShow(text, icon, channelTitle)),
        toCollectionPage: (id, isLandScapeCardView) => {
            dispatch(History.actions.push());
            dispatch(Screen.actions.mount(Screen.Type.COLLECTION, { id, isLandScapeCardView }));
        },
        unmountOverlay: () => {
            dispatch(Overlay.actions.unmount());
        },
        executeDeepLink: (link) => dispatch(DeepLink.executeActionFromLink(link)),
        toDetailPage: (id, type, oldOperation) => dispatch(Screen.actions.mount(Screen.Type.Detail, { id, type, oldOperation })),
    }),
    null,
    { forwardRef: true }
)(Stripe);
