import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum StartupActionType {
    Changed = '@start/changed',
}

// Action
export type StartupChangedActionPayload = { position: StartupPosition };
export type StartupChangedAction = PayloadedAction<StartupActionType.Changed, StartupChangedActionPayload>;

export type StartupReduxAction = StartupChangedAction;

// State
export enum StartupPosition {
    Live = 'LIVE',
    Home = 'HOME',
}
