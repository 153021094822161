import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

export default class VODEpisode extends VODAsset {
    assetType = AssetType.EPISODE;

    get subtitle() {
        const subtitle = [];
        if (this.seasonAndEpisode) {
            subtitle.push(this.seasonAndEpisode);
        }
        if (this.episodeTitle) {
            subtitle.push(this.episodeTitle);
        }

        return subtitle.join(' ');
    }

    get episodeTitle() {
        return this.rawData?.metadata?.episodeInfo?.title;
    }

    get relatedMetaInfo() {
        const metaInfo = [];

        if (this.episodeOrdinal) {
            metaInfo.push(`${translate('HOVER_EPISODE')}${this.episodeOrdinal}`);
        }

        if (this.episodeTitle) {
            metaInfo.push(this.episodeTitle);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get title() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_TITLE');
        }
        return this.rawData?.metadata?.seriesInfo?.title;
    }

    get seasonOrdinal() {
        return this.rawData?.metadata?.episodeInfo?.season;
    }

    get episodeOrdinal() {
        return this.rawData?.metadata?.episodeInfo?.number;
    }

    get seriesId() {
        return this.rawData?.metadata?.seriesInfo?.id;
    }

    get metaInfoPlayer() {
        const metaInfo = [];

        if (this.seasonAndEpisode || this.episodeTitle) {
            metaInfo.push(`${this.seasonAndEpisode} ${this.episodeTitle}`);
        }

        if (this.shortMetaInfo) {
            metaInfo.push(this.shortMetaInfo);
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get isPlaybackAvailable() {
        return this.rawData?.entitlements?.items.some((entitlement: any) => entitlement.playback);
    }

    get entitlementsKinds() {
        if (this?.rawData?.entitlements?.items[0]?.product) {
            return this.rawData?.entitlements?.items?.map((entitlement: { product: { kind: string } }) => entitlement?.product?.kind) || [];
        }

        return [];
    }

    get description() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_DESCRIPTION');
        }
        return this.rawData?.metadata?.fullDescription;
    }
}
