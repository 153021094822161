import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListMoveMutationVariables = Types.Exact<{
  channelListId: Types.Scalars['ID']['input'];
  position: Types.Scalars['Int']['input'];
}>;


export type ChannelListMoveMutation = { __typename?: 'Nexx4Mutations', moveChannelList: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } | null> } };

export type ChannelListMoveFragment = { __typename?: 'Nexx4Mutations', moveChannelList: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } | null> } };

export const ChannelListMoveFragmentDoc = gql`
    fragment channelListMove on Nexx4Mutations {
  moveChannelList(channelListId: $channelListId, position: $position) {
    items {
      ...channelList
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListMoveDocument = gql`
    mutation channelListMove($channelListId: ID!, $position: Int!) {
  ...channelListMove
}
    ${ChannelListMoveFragmentDoc}`;
export type ChannelListMoveMutationFn = Apollo.MutationFunction<ChannelListMoveMutation, ChannelListMoveMutationVariables>;

/**
 * __useChannelListMoveMutation__
 *
 * To run a mutation, you first call `useChannelListMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListMoveMutation, { data, loading, error }] = useChannelListMoveMutation({
 *   variables: {
 *      channelListId: // value for 'channelListId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useChannelListMoveMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListMoveMutation, ChannelListMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListMoveMutation, ChannelListMoveMutationVariables>(ChannelListMoveDocument, options);
      }
export type ChannelListMoveMutationHookResult = ReturnType<typeof useChannelListMoveMutation>;
export type ChannelListMoveMutationResult = Apollo.MutationResult<ChannelListMoveMutation>;
export type ChannelListMoveMutationOptions = Apollo.BaseMutationOptions<ChannelListMoveMutation, ChannelListMoveMutationVariables>;