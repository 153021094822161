import React from 'react';

import { Constants } from 'App/Packages/UI/Card/CardPortrait/Constants';
import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Image, ObjectFit, Orientation } from 'App/Packages/UI/Image';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardPortraitThumbnailProps) => {
    const classList = ['portrait-card-v3__thumbnail'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type CardPortraitThumbnailProps = {
    className?: string;
    src?: string;
};

export type CardPortraitThumbnailComponent = React.FunctionComponent<CardPortraitThumbnailProps>;

export const CardPortraitThumbnail: CardPortraitThumbnailComponent = (props) => {
    const card = useCardPortrait();
    const width = card.isLarge ? Constants.Thumbnail.Large.Width : Constants.Thumbnail.Width;
    const height = card.isLarge ? Constants.Thumbnail.Large.Height : Constants.Thumbnail.Height;
    return (
        <Mask>
            {(shouldMask) => (
                <Image className={getClassName(props)}>
                    <Image.Loading hidden={shouldMask} />
                    <Image.Cover hidden={shouldMask} />
                    <Image.Adult orientation={Orientation.Portrait} hidden={!shouldMask} />
                    <Image.Img src={props.src} objectFit={ObjectFit.Cover} width={width} height={height} hidden={shouldMask} />
                </Image>
            )}
        </Mask>
    );
};
