import React from 'react';

import { ChannelCard } from '__SMART_APP_OLD__/app/components/ChannelCard';

type Props = {
    id: string;
    isFocused: boolean;
    isSelected: boolean;
};

export const ViewControlScreenViewGridItem = (props: Props) => (
    <ChannelCard id={props.id} isFocused={props.isFocused} className="view-control-grid__channel-card">
        <ChannelCard.Container top left>
            <ChannelCard.Number.DefaultNumber />
        </ChannelCard.Container>
        <ChannelCard.Container top right>
            <ChannelCard.Selected isSelected={props.isSelected} />
        </ChannelCard.Container>
        <ChannelCard.Container center bottom>
            <ChannelCard.Name className="view-control-grid__channel-card-name" />
        </ChannelCard.Container>
        <ChannelCard.Container center>
            <ChannelCard.Logo className={`view-control-grid__channel-card-logo${props.isSelected ? '--selected' : ''}`} />
        </ChannelCard.Container>
    </ChannelCard>
);
