import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useIsLive } from '__SMART_APP_OLD__/common/useIsLive';
import ContentMarkers from '__SMART_APP_OLD__/components/common/ContentMarkers';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';
import LiveProgressBar from '__SMART_APP_OLD__/components/common/LiveProgressBar';
import StaticProgressBar from '__SMART_APP_OLD__/components/common/StaticProgressBar';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';

import { Icons } from '../Icons';
import { InnerHTML } from '../InnerHTML';

const PortraitCard = React.forwardRef(
    ({ isSeeAllCard = false, needMetadataInfo = true, data, onFocus, onEnter, onKey, id, dataTestId }, ref) => {
        const isLive = useIsLive(new Date(data.startTime), new Date(data.endTime));
        const needExtendAnimation = needMetadataInfo && !isSeeAllCard;
        const { image, title, isHD, progress, descriptionShort, shortMetaInfo, channelImage, price, shouldMask } = data;

        const cardClassList = `portrait-card ${needExtendAnimation ? 'extend-animation' : ''} ${isSeeAllCard ? 'see-all-card' : ''}`;
        return (
            <Focusable
                className={cardClassList}
                onEnter={onEnter}
                onFocus={onFocus}
                onKey={onKey}
                ref={ref}
                focusDelay={false}
                id={id}
                dataTestId={dataTestId}
            >
                <div className="inner-container">
                    {!isSeeAllCard ? (
                        <div className="content-wrapper">
                            <div className="portrait-cover-area">
                                {channelImage && <div className="channel-logo" style={{ backgroundImage: `url(${channelImage})` }} />}
                                <div className="portrait-cover">
                                    <CoverImageWithFallback
                                        src={image}
                                        alt={title}
                                        mask={shouldMask}
                                        forceLoading={!data || data?.isLoadingCell}
                                    />
                                </div>
                                <div className="cover-overlay">
                                    <ContentMarkers className="portrait" asset={data} />
                                    {isLive ? (
                                        <LiveProgressBar startTime={data.startTime} endTime={data.endTime} />
                                    ) : (
                                        <StaticProgressBar percentage={progress} />
                                    )}
                                </div>
                            </div>
                            {needMetadataInfo && (
                                <div className="portrait-metadata-wrapper transition-flag">
                                    <div className="portrait-metadata">
                                        <Text
                                            className="portrait-title"
                                            typeface={Typeface.SERIF}
                                            size={FontSize.BODY_2}
                                            weight={FontWeight.BOLD}
                                            color={Color.PRIMARY}
                                        >
                                            {title}
                                        </Text>
                                        {shortMetaInfo && (
                                            <Text
                                                className="portrait-info"
                                                typeface={Typeface.SANS}
                                                size={FontSize.CAPTION_1}
                                                weight={FontWeight.REGULAR}
                                                color={Color.SECONDARY}
                                            >
                                                <InnerHTML html={shortMetaInfo} />
                                            </Text>
                                        )}
                                        {descriptionShort && (
                                            <Text
                                                className="portrait-summary"
                                                typeface={Typeface.SANS}
                                                size={FontSize.CAPTION_1}
                                                weight={FontWeight.REGULAR}
                                                color={Color.SECONDARY}
                                            >
                                                <div>{descriptionShort}</div>
                                            </Text>
                                        )}
                                        {isHD && <Icons videoQuality="HD" />}
                                        <Text
                                            className="portrait-price"
                                            typeface={Typeface.SANS}
                                            size={FontSize.CAPTION_1}
                                            weight={FontWeight.REGULAR}
                                            color={Color.TERTIARY}
                                        >
                                            {price}
                                        </Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="portrait-cover-area">
                            <Text
                                className="see-all"
                                typeface={Typeface.SANS}
                                size={FontSize.BODY_2}
                                weight={FontWeight.BOLD}
                                color={Color.PRIMARY}
                            >
                                {title}
                            </Text>
                        </div>
                    )}
                </div>
            </Focusable>
        );
    }
);

export default PortraitCard;
