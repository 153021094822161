import React from 'react';

import { Mask } from 'App/Packages/UI/Mask';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { hidable } from 'App/Packages/UI/Hidable';

const getClassName = (props: DetailSubTitleProps): string => {
    const classList = ['detail__subtitle'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailSubTitleProps = {
    typeface?: Typeface;
    size?: FontSize;
    weight?: FontWeight;
    color?: Color;
    theme?: Theme.Type | null;
    isFocused?: boolean;
    className?: string;
    children?: React.ReactNode;
    customColor?: string;
};

type DetailSubTitleRootComponent = React.FunctionComponent<DetailSubTitleProps>;

const Component: DetailSubTitleRootComponent = (props) => {
    return (
        <Text
            className={getClassName(props)}
            typeface={props.typeface ?? Typeface.SERIF}
            size={props.size ?? FontSize.TITLE_3}
            weight={props.weight ?? FontWeight.REGULAR}
            color={props.color ?? Color.SECONDARY}
            theme={props.theme}
            isFocused={props.isFocused}
            customColor={props.customColor}
        >
            <Mask.Title>{props.children}</Mask.Title>
        </Text>
    );
};

export const DetailSubTitle = hidable(Component);

export type DetailSubTitleComponent = typeof DetailSubTitle;
