import { useCallback, useRef } from 'react';

export type UseFolderListEnter = (enter?: (id: string) => void) => ((id: string) => void) | undefined;

export const useFolderListEnter: UseFolderListEnter = (enter) => {
    const ref = useRef(enter);
    ref.current = enter;
    const handler = useCallback((id: string) => ref.current?.(id), []);
    if (!ref.current) return undefined;
    return handler;
};
