export type AudioItem = {
    id: string;
    name: string;
    iso_code: string;
    codec: string;
};

export type SubtitleItem = {
    id: string;
    name: string;
    iso_code: string;
};

export enum MenuTypes {
    AUDIO = 'audio',
    SUBTITLES = 'subtitles',
    FONT_SIZES = 'fontSizes',
    TEXT_FONT_STYLES = 'textFontStyles',
}

export enum TextFontStyleVariants {
    VARIANT1 = 'variant1',
    VARIANT2 = 'variant2',
    VARIANT3 = 'variant3',
    VARIANT4 = 'variant4',
}

export enum TextFontSizeOptions {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
