import React, { useMemo } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageDetailBackTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import { MESSAGE_DETAIL_SCREEN_SCROLL_VW } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import { MessageDetailActionsContainer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailActionsContainer';
import { MessageDetailActiveAttachmentImage } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailActiveAttachmentImage';
import { MessageDetailAttachmentsContainer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailAttachmentsContainer';
import { MessageDetailContentContainer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailContentContainer';
import { MessageDetailHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailHeader';
import { MessageDetailImage } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailImage';
import {
    selectMessageDetailIsAttachmentView,
    selectMessageDetailIsAttachmentViewAttachmentsVissible,
    selectMessageDetailIsPlayerPreview,
    selectMessageDetailShouldRenderAttachmentContainer,
    selectMessageDetailVisibleContainerIndex,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const getClassName = (isPlayerView: boolean, isAttachmentView: boolean, isAttachmentsVisible: boolean): string => {
    const classList = ['message-detail__container'];
    if (isPlayerView) classList.push('message-detail__container--player-preview');
    if (isAttachmentView) classList.push('message-detail__container--attachment-view');
    if (isAttachmentView && isAttachmentsVisible) classList.push('message-detail__container--attachments-visible');
    return classList.join(' ');
};

const getStyle = (index: number): React.CSSProperties => ({
    transform: `translateZ(0) translateY(${index * -MESSAGE_DETAIL_SCREEN_SCROLL_VW}vw)`,
});

export const MessageDetailView: React.FunctionComponent<Screen.Props<Screen.Type.MESSAGE_DETAIL>> = () => {
    const dispatch = useDispatch();
    const isPlayerView = useSelector(selectMessageDetailIsPlayerPreview);
    const isAttachmentView = useSelector(selectMessageDetailIsAttachmentView);
    const isAttachmentsVisible = useSelector(selectMessageDetailIsAttachmentViewAttachmentsVissible);
    const shouldRenderAttachments = useSelector(selectMessageDetailShouldRenderAttachmentContainer);
    const activeIndex = useSelector(selectMessageDetailVisibleContainerIndex);
    const className = getClassName(isPlayerView, isAttachmentView, isAttachmentsVisible);
    const style = useMemo(() => getStyle(activeIndex), [activeIndex]);

    Focus.useBack(() => dispatch(messageDetailBackTriggered()));

    return (
        <Layout className="message-detail" containerClassName={className} backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <MessageDetailActiveAttachmentImage />
            <div className="message-detail__scroll-handler" style={style}>
                {shouldRenderAttachments && <MessageDetailImage />}
                {shouldRenderAttachments && <MessageDetailAttachmentsContainer />}
                <MessageDetailActionsContainer />
                <MessageDetailHeader />
                <MessageDetailContentContainer />
            </div>
        </Layout>
    );
};
