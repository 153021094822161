import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { PrivacyPolicyButtonType, PrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/types';

import { Screen } from 'App/Modules/Screen';

export const ConsentTypeForPrivacyPolicyItemType = {
    [PrivacyPolicyItemType.RECOMMENDATIONS]: Consent.Type.RECOMMENDATIONS,
    [PrivacyPolicyItemType.ANALYTICS]: Consent.Type.ANALYTICS,
    [PrivacyPolicyItemType.USER_BEHAVIOR]: Consent.Type.USER_BEHAVIOR,
    [PrivacyPolicyItemType.ADVERTISING]: Consent.Type.ADVERTISING,
};

export const labelForPrivacyPolicyItemType: Record<PrivacyPolicyItemType, string> = {
    [PrivacyPolicyItemType.REQUIRED]: 'REQUIRED_TITLE',
    [PrivacyPolicyItemType.RECOMMENDATIONS]: 'RECOMMENDATIONS_TITLE',
    [PrivacyPolicyItemType.ANALYTICS]: 'ANALYTICS_TITLE',
    [PrivacyPolicyItemType.USER_BEHAVIOR]: 'USER_BEHAVIOR_TITLE',
    [PrivacyPolicyItemType.ADVERTISING]: 'ADVERTISING_TITLE',
    [PrivacyPolicyItemType.TRACK_VIEWING_BEHAVIOUR]: 'TRACK_VIEWING_BEHAVIOUR_TITLE',
};

export const labelForPageTypeForPrivacyPolicyButtonType: Record<Screen.PrivacyPolicyType, Record<PrivacyPolicyButtonType, string>> = {
    [Screen.PrivacyPolicyType.SETTINGS]: {
        [PrivacyPolicyButtonType.ACCEPT_ALL]: 'SETTINGS_PRIVACY_POLICY_CONSENT_ALLOW_ALL',
        [PrivacyPolicyButtonType.READ_MORE]: 'SETTINGS_PRIVACY_POLICY_MORE_INFO',
        [PrivacyPolicyButtonType.CONTINUE]: 'SETTINGS_PRIVACY_POLICY_CONSENT_ALLOW',
    },
    [Screen.PrivacyPolicyType.FTI]: {
        [PrivacyPolicyButtonType.ACCEPT_ALL]: 'FTI_PRIVACY_POLICY_CONSENT_ALLOW_ALL',
        [PrivacyPolicyButtonType.READ_MORE]: 'FTI_PRIVACY_POLICY_MORE_INFO',
        [PrivacyPolicyButtonType.CONTINUE]: 'FTI_PRIVACY_POLICY_CONSENT_CONTINUE',
    },
};

export const legacyPrivacyPolicyItems = [PrivacyPolicyItemType.TRACK_VIEWING_BEHAVIOUR];

export const privacyPolicyButtons: Record<Screen.PrivacyPolicyType, PrivacyPolicyButtonType[]> = {
    [Screen.PrivacyPolicyType.SETTINGS]: [PrivacyPolicyButtonType.READ_MORE, PrivacyPolicyButtonType.ACCEPT_ALL],
    [Screen.PrivacyPolicyType.FTI]: [
        PrivacyPolicyButtonType.READ_MORE,
        PrivacyPolicyButtonType.ACCEPT_ALL,
        PrivacyPolicyButtonType.CONTINUE,
    ],
};

export const legacyPrivacyPolicyButtons: Record<Screen.PrivacyPolicyType, PrivacyPolicyButtonType[]> = {
    [Screen.PrivacyPolicyType.SETTINGS]: [PrivacyPolicyButtonType.READ_MORE],
    [Screen.PrivacyPolicyType.FTI]: [PrivacyPolicyButtonType.READ_MORE, PrivacyPolicyButtonType.CONTINUE],
};

export const privacyPolicyItems = [
    PrivacyPolicyItemType.REQUIRED,
    PrivacyPolicyItemType.RECOMMENDATIONS,
    PrivacyPolicyItemType.ANALYTICS,
    PrivacyPolicyItemType.USER_BEHAVIOR,
    PrivacyPolicyItemType.ADVERTISING,
];

export const sloveniaPrivacyPolicyItems = [
    PrivacyPolicyItemType.ANALYTICS,
    PrivacyPolicyItemType.USER_BEHAVIOR,
    PrivacyPolicyItemType.ADVERTISING,
];
