import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

import NetworkRecording from './NetworkRecording';
import ProgramEvent from './ProgramEvent';

export default class TVSeason {
    episodes: (ProgramEvent | NetworkRecording)[] = [];

    assetType = AssetType.SEASON;

    rawData: any;

    constructor(season: any, data: any[], factoryFunc: any) {
        this.rawData = season;

        if (data) {
            const { startCursor, endCursor } = season;
            const firstIndex = data.findIndex((episode: any) => episode.cursor === startCursor);
            const lastIndex = data.findIndex((episode: any) => episode.cursor === endCursor);
            this.episodes = data.slice(firstIndex, lastIndex + 1).map((episode) => factoryFunc(episode?.node, episode?.node.__typename));
        }
    }

    get id() {
        return `${this.rawData?.selectBehaviour}-${this.rawData?.startCursor}`;
    }

    get ordinal() {
        return this.rawData?.title;
    }

    get isAdult() {
        return null;
    }

    get title() {
        return null;
    }

    get numberOfEpisodes() {
        return this?.rawData?.episodeCount;
    }

    get shortDescription() {
        return null;
    }

    get lastEpisode() {
        return null;
    }
}
