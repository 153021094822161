import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../fragments/image.generated';
import { MessageActionFragmentDoc } from './messageAction.generated';
import { MessageNotificationInfoFragmentDoc } from './messageNotificationInfo.generated';
import { MessageAttachmentFragmentDoc } from './messageAttachment.generated';
export type MessageFragment = { __typename?: 'Message', id: string, title: string, message: string, label: string, displayKind: Types.MessageDisplayKind, status: Types.MessageStatus, timestamp: any, validFrom: any, validUntil: any, icon?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, actions?: { __typename?: 'ActionCatalog', items: Array<{ __typename: 'Action', id: string, uri: string, title: string } | null> } | null, notificationInfo?: { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null } | null, attachments?: { __typename?: 'MessageAttachmentCatalog', items: Array<{ __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null } | { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } } | null> } | null };

export const MessageFragmentDoc = gql`
    fragment message on Message {
  id
  title
  message
  icon(width: $messageIconWidth, height: $messageIconHeight, flavour: INVERTED) {
    ...image
  }
  label
  displayKind
  status
  backgroundImage(
    width: $messageBackgroundWidth
    height: $messageBackgroundHeight
  ) {
    ...image
  }
  actions {
    items {
      ...messageAction
    }
  }
  notificationInfo {
    ...messageNotificationInfo
  }
  timestamp
  validFrom
  validUntil
  attachments {
    items {
      ...messageAttachment
    }
  }
}
    ${ImageFragmentDoc}
${MessageActionFragmentDoc}
${MessageNotificationInfoFragmentDoc}
${MessageAttachmentFragmentDoc}`;