import {
    EPG_HIDDEN_CHANNELS_TO_CHECK,
    EPG_HIDDEN_CHANNELS_TO_REQUEST,
    EPG_VISSIBLE_CHANNELS,
    MINI_EPG_HIDDEN_FOLLOWING,
    MINI_EPG_HIDDEN_PREVIOUS,
    MINI_EPG_VISIBLE_FOLLOWING,
    MINI_EPG_VISIBLE_PREVIOUS,
} from '__SMART_APP_OLD__/app/constants/epg';

import { Calc } from 'App/Packages/Calc';

export const getIndexInRange = (index: number, size: number) => {
    if (index < 0) return size + index;
    if (index >= size) return index - size;
    return index;
};

export const getIds = (startIndex: number, array: string[], size: number) => {
    const start = startIndex >= 0 ? startIndex : array.length + startIndex;
    if (size >= array.length) return array;
    const diff = start + size - array.length;
    if (diff > 0) return [...array.slice(start), ...array.slice(0, diff)];
    return array.slice(start, start + size);
};

export const getChannelsIds = (startIndex: number, ids: string[]) => {
    const hiddenRowsToCheckStartIndex = startIndex - EPG_HIDDEN_CHANNELS_TO_CHECK;
    const hiddenRowsToRequestStartIndex = startIndex - EPG_HIDDEN_CHANNELS_TO_REQUEST;
    const hiddenRowsToCheckSize = EPG_VISSIBLE_CHANNELS + 2 * EPG_HIDDEN_CHANNELS_TO_CHECK;
    const hiddenRowsToRequestSize = EPG_VISSIBLE_CHANNELS + 2 * EPG_HIDDEN_CHANNELS_TO_REQUEST;
    const visibleRows = getIds(startIndex, ids, EPG_VISSIBLE_CHANNELS);
    const hiddenRowsToCheck = getIds(hiddenRowsToCheckStartIndex, ids, hiddenRowsToCheckSize);
    const hiddenRowsToRequest = getIds(hiddenRowsToRequestStartIndex, ids, hiddenRowsToRequestSize);
    return [visibleRows, hiddenRowsToCheck, hiddenRowsToRequest];
};

export const getEventIndexes = (index: number, size: number) => {
    const min = 0;
    const max = size - 1;
    const visible = [Calc.clamp(min, index - MINI_EPG_VISIBLE_PREVIOUS, max), Calc.clamp(min, index + MINI_EPG_VISIBLE_FOLLOWING, max)];
    const toCheck = [
        Calc.clamp(min, index - (MINI_EPG_VISIBLE_PREVIOUS + MINI_EPG_HIDDEN_PREVIOUS), max),
        Calc.clamp(min, index + (MINI_EPG_VISIBLE_FOLLOWING + MINI_EPG_HIDDEN_FOLLOWING), max),
    ];
    const toLoad = [
        Calc.clamp(min, index - (MINI_EPG_VISIBLE_PREVIOUS + 2 * MINI_EPG_HIDDEN_PREVIOUS), max),
        Calc.clamp(min, index + (MINI_EPG_VISIBLE_FOLLOWING + 2 * MINI_EPG_HIDDEN_FOLLOWING), max),
    ];
    return [visible, toCheck, toLoad];
};
