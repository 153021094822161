import { App } from 'App/Modules/App';
import { WebOSAppEngine } from 'App/Modules/App/Implementation/WebOS';

export class WebOS {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.WebOS.Initialize]');
        App.initialize(new WebOSAppEngine());
        return true;
    }
}
