import { useEffect, useMemo, useState } from 'react';

import { Calc } from 'App/Packages/Calc';
import { Axis } from 'App/Types/Axis';

export interface ListEngineConfig {
    axis: Axis;
    offset: number;
    max: number;
}

export class ListEngine<T> {
    private readonly hidden: number = 4;

    private readonly offset: number;

    private readonly max: number;

    private readonly axis: Axis;

    private ready: boolean = false;

    private ref: HTMLElement | null = null;

    private data: T[] = [];

    private animationFrameId: number = 0;

    private focused: number = 0;

    private firstIndex: number = 0;

    private lastIndex: number = 0;

    private statusChangeHandler: (ready: boolean) => void = () => {};

    protected constructor(config: ListEngineConfig) {
        this.offset = config.offset;
        this.max = config.max;
        this.axis = config.axis;
        this.setRef = this.setRef.bind(this);
    }

    public static useEngine = <G>(focused: number, data: G[], config: ListEngineConfig): ListEngine<G> => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const engine = useMemo(() => new ListEngine<G>(config), []);
        engine.setData(data);
        engine.setFocused(focused);
        engine.useReady();
        return engine;
    };

    public getContainer(): DOMRect {
        throw new Error('Method not implemented.');
    }

    public getList(): DOMRect {
        throw new Error('Method not implemented.');
    }

    public getItems(): DOMRect[] {
        throw new Error('Method not implemented.');
    }

    public getMax(): number {
        return this.max;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getAxis(): Axis {
        return this.axis;
    }

    public getRef(): HTMLElement | null {
        return this.ref;
    }

    public setRef(ref: HTMLElement | null): void {
        this.ref = ref;
        this.setReady(true);
    }

    public getReady(): boolean {
        return this.ready;
    }

    public setReady(ready: boolean): void {
        this.ready = ready;
        this.align();
        this.statusChangeHandler(true);
    }

    public getData(): T[] {
        return this.data;
    }

    public setData(data: T[]): void {
        this.data = data;
    }

    protected getFirst(): DOMRect | null {
        return this.getItems()[this.firstIndex] ?? null;
    }

    public getFirstIndex(): number {
        return this.firstIndex;
    }

    private setFirstIndex(index: number): void {
        this.firstIndex = Calc.clamp(0, index, this.getItems().length - 1);
    }

    protected getLast(): DOMRect | null {
        return this.getItems()[this.lastIndex] ?? null;
    }

    public getLastIndex(): number {
        return this.lastIndex;
    }

    protected setLastIndex(index: number): void {
        this.lastIndex = Calc.clamp(0, index, this.getItems().length - 1);
    }

    public getFocused(): number {
        return this.focused;
    }

    public setFocused(index: number): void {
        if (index === this.getFocused() || index < 0 || index > this.getItems().length - 1) return;
        this.focused = index;
        if (this.getList().height <= this.getContainer().height) return;
        const firstIndex = this.getFirstIndex();
        const lastIndex = this.getLastIndex();
        const focused = this.getFocused();
        const offset = this.getOffset();
        if (focused >= firstIndex + offset && focused <= lastIndex - offset) return;
        this.align(focused > lastIndex - offset);
    }

    public getIsFocused(index: number): boolean {
        if (!this.ready) return false;
        return this.focused === index;
    }

    public getIsHidden(index: number): boolean {
        return index < this.firstIndex || index > this.lastIndex;
    }

    public getIsRendered(index: number): boolean {
        return index >= this.firstIndex - this.hidden && index <= this.lastIndex + this.hidden;
    }

    public getKey(index: number): string {
        const item = this.getData()[index];

        if (!item) return '';

        if (typeof item === 'string') return item;

        if (typeof item === 'object' && 'id' in item) return (item as any).id;

        return index.toString();
    }

    public getListClassName(): string {
        const classlist = ['list__items'];
        if (this.getAxis() === Axis.X) {
            classlist.push('list__items--row');
        } else {
            classlist.push('list__items--column');
        }
        return classlist.join(' ');
    }

    public getItemClassName(index: number): string {
        const classlist = ['list__item'];
        if (this.getIsHidden(index) || !this.ready) classlist.push('list__item--hidden');
        return classlist.join(' ');
    }

    public align(fromBottom: boolean = false): void {
        if (this.getItems().length === 0) return;
        if (fromBottom) {
            this.setLastIndex(this.focused + this.offset);
            this.updateFirstIndex();
        } else {
            this.setFirstIndex(this.focused - this.offset);
            this.updateLastIndex();
        }
        this.updateListPosition();
    }

    private updateFirstIndex(): void {
        const items = this.getItems();
        const lastIndex = this.getLastIndex();
        const itemsToObserve = items.slice(Math.max(0, lastIndex - this.getMax()), lastIndex);
        const first = this.getFirstFromItems(itemsToObserve);
        this.setFirstIndex(items.indexOf(first!));
    }

    private getFirstFromItems(items: DOMRect[]): DOMRect | undefined {
        const container = this.getContainer();
        const last = this.getLast();
        if (!last) return undefined;
        if (this.getAxis() === Axis.X) return items.find((item) => item.left + container.width >= last.right);
        return items.find((item) => item.top + container.height >= last.bottom);
    }

    private updateLastIndex(): void {
        const items = this.getItems();
        const firstIndex = this.getFirstIndex();
        const itemsToObserve = items.slice(firstIndex, Math.min(firstIndex + this.getMax(), items.length)).reverse();
        const last = this.getLastFromObserved(itemsToObserve);
        this.setLastIndex(items.indexOf(last!));
    }

    private getLastFromObserved(items: DOMRect[]): DOMRect | undefined {
        const container = this.getContainer();
        const first = this.getFirst();
        if (!first) return undefined;
        if (this.getAxis() === Axis.X) return items.find((item) => item.right - first.left <= container.width);
        return items.find((item) => item.bottom - first.top <= container.height);
    }

    private updateListPosition(): void {
        window.cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = window.requestAnimationFrame(() => this.setTransformValue());
    }

    private setTransformValue(): void {
        const ref = this.getRef();
        if (!ref) return;
        ref.style.transform = this.getTransformValue();
    }

    private getTransformValue(): string {
        return `translateZ(0) translate${this.getAxis()}(${this.getTranslateValue()}px)`;
    }

    private getTranslateValue(): number {
        const first = this.getFirst();
        const list = this.getList();
        if (!first) return 0;
        if (this.getAxis() === Axis.X) return list.left - first.left;
        return list.top - first.top;
    }

    public useReady = (): boolean => {
        const [isReady, setIsReady] = useState(() => this.getReady());
        const [, setShouldUpdate] = useState(false);

        useEffect(() => {
            setIsReady(this.getReady());
            this.onStatusChange((ready) => setIsReady(ready));
        }, []);

        useEffect(() => {
            if (!isReady) return;
            setShouldUpdate(true);
            this.align();
        }, [isReady]);

        return isReady;
    };

    private onStatusChange(cb: (ready: boolean) => void): void {
        this.statusChangeHandler = cb;
    }
}
