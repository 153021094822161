import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectOverlayActions } from '__SMART_APP_OLD__/app/modules/Overlay/modules/SelectOverlay/actions';
import { selectors } from '__SMART_APP_OLD__/app/modules/Overlay/modules/SelectOverlay/selectors';

import { Overlay } from 'App/Modules/Overlay';
import { UI } from 'App/Packages/UI';

const headerForTarget: Record<Overlay.SelectTarget, string> = {
    [Overlay.SelectTarget.RECORDING_FILTER]: 'FILTER',
    [Overlay.SelectTarget.RECORDING_SORT]: 'SORT',
    [Overlay.SelectTarget.DATE_PICKER]: 'EPG_DATE_PICKER_DIALOG_TITLE',
    [Overlay.SelectTarget.LANGUAGE]: 'SETTINGS_LANGUAGE_UI_LANGUAGE',
    [Overlay.SelectTarget.AUDIO_PRIMARY]: 'SETTINGS_LANGUAGE_AUDIO_PRIMARY',
    [Overlay.SelectTarget.AUDIO_SECONDARY]: 'SETTINGS_LANGUAGE_AUDIO_SECONDARY',
    [Overlay.SelectTarget.SUBTITLE_PRIMARY]: 'SETTINGS_LANGUAGE_SUBTITLE_PRIMARY',
    [Overlay.SelectTarget.SUBTITLE_SECONDARY]: 'SETTINGS_LANGUAGE_SUBTITLE_SECONDARY',
};

export const SelectOverlayView: React.FunctionComponent<Overlay.Props<Overlay.Type.SELECT>> = (props) => {
    const dispatch = useDispatch();
    const options = useSelector(selectors.private.selectOptions(props.target));
    const initialFocusedIndex = useSelector(selectors.private.selectSelectedIndex(props.target));

    const ids = options.map((option) => option.id);
    const selected = ids[initialFocusedIndex];

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={() => dispatch(selectOverlayActions.private.dismissed(props.target))}>
            <UI.Select.Header>
                <UI.Select.Title>
                    <UI.Translation>{headerForTarget[props.target]}</UI.Translation>
                </UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options
                ids={ids}
                selected={selected}
                onEnter={(id) => dispatch(selectOverlayActions.private.selected(props.target, options[ids.indexOf(id)]))}
            >
                <UI.Select.Options.Children>
                    {(id, index) => (
                        <UI.Select.Option id={id} key={id} disableDismiss={true}>
                            <UI.Translation>{options[index].label}</UI.Translation>
                        </UI.Select.Option>
                    )}
                </UI.Select.Options.Children>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
