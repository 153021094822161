export enum Elements {
    CHANNEL = 'CHANNEL',
    EVENT = 'EVENT',
    DATE_PICKER_BUTTON = 'DATE_PICKER_BUTTON',
}

export interface ChannelElement {
    type: Elements.CHANNEL;
    channelId: string;
}

export interface EventElement {
    type: Elements.EVENT;
    channelId: string;
    eventId: string;
    restricted?: boolean;
    date: number;
    isLive?: boolean;
    isNowPlaying?: boolean;
    isNoInfo: boolean;
    isLoading: boolean;
}

export type Element = ChannelElement | EventElement;
