/* eslint-disable complexity */
/* eslint-disable max-depth */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-statements */
import throttle from 'lodash.throttle';

import Elements from '__SMART_APP_OLD__/navigation/Elements';
import FocusUtils from '__SMART_APP_OLD__/navigation/FocusUtils';
import Node from '__SMART_APP_OLD__/navigation/Node';
import { magicRemoteConfig } from '__SMART_APP_OLD__/utils/Constants';
import { debounce, delay } from '__SMART_APP_OLD__/utils/Utils';

import { Env } from 'App/Env';
import { App } from 'App/Modules/App';
import { Key } from 'App/Modules/Key';

export const DIR_LEFT = 'dir-left';
const DIR_RIGHT = 'dir-right';
const DIR_UP = 'dir-up';
export const DIR_DOWN = 'dir-down';

class Focus {
    events = {};

    stashes = {};

    focused;

    defaultFocusDelta = 250; // ms

    debounceActive = false;

    focusDelayDelta = this.defaultFocusDelta;

    pendingFocus = false;

    cursorIgnored = false;

    cursorIgnoredTimeout = null;

    isMagicMode = false;

    isDragging = false;

    isKeyboardOpen = false;

    resetFocusDelta = debounce(() => {
        this.focusDelayDelta = this.defaultFocusDelta;
        this.debounceActive = false;
    }, this.defaultFocusDelta + 10);

    constructor() {
        window.Focus = this;
    }

    initialize() {
        document.addEventListener('keydown', this.handleKeyEvents);
        document.addEventListener('keyboardStateChange', this.keyboardVissibilityChangeHandler);
        if (Env.IsBrowser || Env.IsWebOS) {
            const eventName = 'onwheel' in document ? 'wheel' : 'mousewheel';
            window.addEventListener('click', this.handleClickEvents);
            window.addEventListener(eventName, this.handleDebouncedWheelEvents);
            window.addEventListener('mousemove', this.handleReducedMouseOverEvents);
            window.addEventListener('mouseover', this.handleReducedMouseOverEvents);
            window.addEventListener('mousedown', this.handleMouseDownEvent);
            window.addEventListener('mouseup', this.handleMouseUpEvent);
        }
    }

    keyboardVissibilityChangeHandler = (event) => {
        this.isKeyboardOpen = event.detail.visibility;
    };

    handleMouseDownEvent = () => {
        this.isDragging = true;
    };

    handleMouseUpEvent = () => {
        this.isDragging = false;
    };

    handleKeyEvents = (event) => {
        if (event.keyCode === Key.VK_BACK && (Env.IsTitan || Env.IsBrowser) && App.getIsInputActive()) return;
        if (event.keyCode === Key.VK_BACK) App.onBack(event);
        if (event.keyCode === Key.VK_CURSOR_ON) this.isMagicMode = true;
        if (event.keyCode === Key.VK_CURSOR_OFF) this.isMagicMode = false;
        if (this.callEventListeners('onkey', event.keyCode)) return;
        if (this.focused && this.focused.events.onKey && this.focused.events.onKey(event.keyCode, event)) return;
        const container = FocusUtils.findParentContainer(this.focused);
        if (container && container.events.onKey && container.events.onKey(event.keyCode)) return;

        switch (event.keyCode) {
            case Key.VK_LEFT:
                event.preventDefault();
                this.changeFocus(DIR_LEFT);
                break;
            case Key.VK_RIGHT:
                event.preventDefault();
                this.changeFocus(DIR_RIGHT);
                break;
            case Key.VK_UP:
                event.preventDefault();
                this.changeFocus(DIR_UP);
                break;
            case Key.VK_DOWN:
                event.preventDefault();
                this.changeFocus(DIR_DOWN);
                break;
            case Key.VK_ENTER:
                event.preventDefault();
                if (this.focused) this.focused.onEnter();
                break;
            case Key.VK_BACK:
                if (!this.callEventListeners('back', event.keyCode)) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;
            default:
        }
    };

    handleReducedMouseOverEvents = (event) => {
        if (this.cursorIgnored) return;
        this.reducedMouseOverEvents(event);
    };

    reducedMouseOverEvents = throttle((event) => {
        this.handleMouseOverEvents(event);
    }, magicRemoteConfig.remoteDelay);

    handleMouseOverEvents = (event) => {
        let ref = event.target;
        let item = Elements.findNodeByRef(ref);
        const container = FocusUtils.findParentContainer(this.focused);
        if (container && container.events && container.events.onMouseMove && container.events.onMouseMove(event)) return;
        if (!ref) return;

        if (item) {
            this.focus(item);
            return;
        }

        ref = ref.parentNode;
        while (ref) {
            item = Elements.findNodeByRef(ref);
            if (item && !item.isContainer) {
                if (!item.usePointer) return;
                this.focus(item);
                break;
            }
            ref = ref.parentNode;
        }
    };

    handleDebouncedWheelEvents = (event) => {
        this.cursorIgnored = true;
        clearTimeout(this.cursorIgnoredTimeout);
        this.reducedMouseOverEvents.cancel();
        this.cursorIgnoredTimeout = setTimeout(() => {
            this.cursorIgnored = false;
        }, magicRemoteConfig.remoteDelay);
        this.debouncedHandleWheelEvents(event);
    };

    debouncedHandleWheelEvents = debounce((event) => {
        event.stopPropagation();
        event.preventDefault();
        this.handleWheelEvents(event);
    }, 5);

    handleWheelEvents = (event) => {
        const keyCode = event.deltaY > 0 ? Key.VK_DOWN : Key.VK_UP;
        if (this.isKeyboardOpen && keyCode === Key.VK_UP) return;

        this.handleKeyEvents({
            keyCode,
            preventDefault: () => {},
            stopPropagation: () => {},
        });
    };

    handleClickEvents = (event) => {
        const focused = this.focused?.nodeRef;
        const clicked = event.target;
        if (clicked === focused || focused?.contains(clicked)) {
            this.handleKeyEvents({
                keyCode: Key.VK_ENTER,
                preventDefault: () => {},
                stopPropagation: () => {},
                mouseEvent: event,
            });
        }
    };

    changeFocus(direction) {
        if (!this.focused && Elements.nodes.length > 0) {
            this.focus(Elements.nodes[0]);
        }

        let nodes;
        let index = -1;
        let containerIndex = -1;
        let lastFocusedInContainer;
        let container = FocusUtils.findParentContainer(this.focused);
        switch (direction) {
            case DIR_RIGHT:
            case DIR_LEFT:
                if (container) {
                    if (container.isCollection) {
                        this.focus(
                            direction === DIR_RIGHT ? this.focused.nodeRef.nextElementSibling : this.focused.nodeRef.previousElementSibling
                        );
                    } else {
                        nodes = FocusUtils.filterNodesInContainer(Elements.nodes, this.focused, container);
                        index = this.focusNextHorizontal(nodes, direction);
                    }
                } else {
                    nodes = FocusUtils.filterNodes(Elements.nodes, this.focused);
                    index = this.focusNextHorizontal(nodes, direction);
                }
                break;
            case DIR_UP:
            case DIR_DOWN:
                if (container) {
                    const pass = true;
                    while (pass) {
                        if (container) {
                            if (container.getLastFocusedTile) {
                                lastFocusedInContainer = container.getLastFocusedTile();
                                if (lastFocusedInContainer && lastFocusedInContainer.nodeRef !== this.focused.nodeRef) {
                                    this.focus(lastFocusedInContainer.nodeRef);
                                    return true;
                                }
                            }
                            nodes = FocusUtils.filterNodesInContainer(Elements.nodes, this.focused, container);
                            index = this.focusNextVertical(nodes, direction);
                            if (index === -1) {
                                nodes = FocusUtils.filterContainersInСurrentContainer(
                                    Elements.nodes,
                                    FocusUtils.findParentContainer(container)
                                );
                                containerIndex = nodes.indexOf(container);
                                if (containerIndex !== -1) {
                                    nodes.splice(containerIndex, 1);
                                }
                                index = this.focusNextVertical(nodes, direction);
                                container = nodes[index];
                            } else {
                                break;
                            }
                        } else {
                            nodes = FocusUtils.filterNodes(Elements.nodes, this.focused);
                            index = this.focusNextVertical(nodes, direction);
                            break;
                        }
                    }
                } else {
                    nodes = FocusUtils.filterNodes(Elements.nodes, this.focused);
                    index = this.focusNextVertical(nodes, direction);
                }
                break;
            default:
        }
        if (index !== -1) {
            this.focus(nodes[index]);
        }
        return true;
    }

    focus(node = null, skipDelay = false) {
        if (this.pendingFocus && node && node.focusDelay) {
            return;
        }
        const executeFocus = () => {
            let nextNode;

            if (!node && !this.focused && Elements.nodes.length > 0) {
                for (let i = 0; i < Elements.nodes.length; i += 1) {
                    if (!Elements.nodes[i].isContainer) {
                        nextNode = Elements.nodes[i];
                        break;
                    }
                }
            } else if (!node) {
                return false;
            } else if (!(node instanceof Node)) {
                nextNode = Elements.findNodeByRef(node);
            } else if (Elements.nodes.find((element) => node.nodeRef === element.nodeRef)) {
                nextNode = node;
            } else {
                Elements.updateFocusInStash(node);
            }

            if (!nextNode) {
                console.warn('Could not find Node for ', node);
                return false;
            }

            if (this.focused && (this.focused === node || this.focused?.nodeRef === node?.nodeRef)) {
                return false;
            }

            const prevNode = this.focused;

            if (this.focused) {
                this.focused.removeFocus();
            }

            this.focused = nextNode;
            nextNode.setFocus();
            this.updateContainer(nextNode, prevNode);
            return true;
        };

        if ((node && !node.focusDelay) || skipDelay) {
            executeFocus();
            return;
        }

        if (!this.debounceActive) {
            this.debounceActive = true;
            this.resetFocusDelta();
            executeFocus(node);
            return;
        }

        this.pendingFocus = true;
        delay(this.focusDelayDelta)
            .then(() => {
                this.pendingFocus = false;
                this.debounceActive = true;
                this.focusDelayDelta -= this.focusDelayDelta > 50 ? 50 : 0;
                this.resetFocusDelta();
                executeFocus(node);

                return null;
            })
            .catch((e) => {
                throw e;
            });
    }

    blur() {
        if (!this.focused) {
            return;
        }

        this.focused.removeFocus();
        delete this.focused;
    }

    addEventListener(type, callback) {
        if (!this.events[type]) {
            this.events[type] = [];
        }

        this.events[type].push(callback);
    }

    removeEventListener(type, callback) {
        const stack = this.events[type];

        if (!stack) {
            return false;
        }

        const index = stack.indexOf(callback);
        if (index < 0) {
            return false;
        }

        this.events[type].splice(index, 1);
        return true;
    }

    callEventListeners(type, args = undefined) {
        const stack = this.events[type];

        if (!stack) {
            return false;
        }

        for (let i = stack.length - 1; i >= 0; i -= 1) {
            const callback = stack[i];
            if (callback(args) === true) {
                return true;
            }
        }

        return false;
    }

    focusNextHorizontal(nodes, direction) {
        if (!this.focused) {
            return -1;
        }
        const { nodeRef } = this.focused;
        const sourceRect = this.focused.events.onCustomRect ? this.focused.events.onCustomRect(nodeRef) : nodeRef.getBoundingClientRect();
        const distances = new Array(nodes.length);

        nodes.forEach((node, index) => {
            const nodeRect = node.ref.getBoundingClientRect();
            distances[index] = FocusUtils.distance(nodeRect, sourceRect);
        });

        let nextIndex = -1;
        distances.forEach((distanceInfo, index) => {
            switch (direction) {
                case DIR_LEFT:
                    if (sourceRect.left < distanceInfo.right) {
                        break;
                    }

                    if (nextIndex === -1) {
                        nextIndex = index;
                        break;
                    }

                    if (distanceInfo.distance < distances[nextIndex].distance) {
                        nextIndex = index;
                    }
                    break;
                case DIR_RIGHT:
                    if (sourceRect.right > distanceInfo.left) {
                        break;
                    }

                    if (nextIndex === -1) {
                        nextIndex = index;
                        break;
                    }

                    if (distanceInfo.distance < distances[nextIndex].distance) {
                        nextIndex = index;
                    }
                    break;
                default:
            }
        });

        return nextIndex;
    }

    focusNextVertical(nodes, direction) {
        if (!this.focused) {
            return -1;
        }
        const { nodeRef } = this.focused;
        const sourceRect = this.focused.events.onCustomRect ? this.focused.events.onCustomRect(nodeRef) : nodeRef.getBoundingClientRect();
        const distances = new Array(nodes.length);

        nodes.forEach((node, index) => {
            const nodeRect = node.ref.getBoundingClientRect();
            distances[index] = FocusUtils.distance(nodeRect, sourceRect);
        });

        let nextIndex = -1;
        distances.forEach((distanceInfo, index) => {
            switch (direction) {
                case DIR_UP:
                    if (sourceRect.top < distanceInfo.bottom) {
                        break;
                    }

                    if (nextIndex === -1) {
                        nextIndex = index;
                        break;
                    }

                    if (distanceInfo.distance < distances[nextIndex].distance) {
                        nextIndex = index;
                    }
                    break;
                case DIR_DOWN:
                    if (sourceRect.bottom > distanceInfo.top) {
                        break;
                    }

                    if (nextIndex === -1) {
                        nextIndex = index;
                        break;
                    }

                    if (distanceInfo.distance < distances[nextIndex].distance) {
                        nextIndex = index;
                    }
                    break;
                default:
            }
        });

        return nextIndex;
    }

    updateContainer(focusedNode, prevNode) {
        let parent = FocusUtils.findParentContainer(focusedNode);
        while (parent) {
            if (parent.events.onFocusChanged) {
                if (!parent.events.onFocusChanged(focusedNode, prevNode)) {
                    this.setVisible(focusedNode, prevNode);
                    break;
                }
            } else {
                this.setVisible(focusedNode, prevNode);
            }
            parent = FocusUtils.findParentContainer(parent);
        }
    }

    isScrollNeeded(focusedNode) {
        let parent = FocusUtils.findParentContainer(focusedNode);

        if (focusedNode.scrollable === false) {
            return false;
        }

        while (parent) {
            if (parent.scrollable === false) {
                break;
            } else {
                parent = FocusUtils.findParentContainer(parent);
            }
        }
        return true;
    }

    shouldScrollTop(focusedNode) {
        let parent = FocusUtils.findParentContainer(focusedNode);

        if (focusedNode.scrollTop) {
            return true;
        }

        while (parent) {
            if (parent.scrollTop) {
                return true;
            }
            parent = FocusUtils.findParentContainer(parent);
        }
        return false;
    }

    setVisible(focusedNode) {
        const focusedContainer = FocusUtils.findParentContainer(focusedNode);
        if (!focusedContainer) {
            return;
        }
        const focusedRect = focusedNode.nodeRef.getBoundingClientRect();
        const containerRect = focusedContainer.nodeRef.getBoundingClientRect();
        const parentContainer = FocusUtils.findParentContainer(focusedContainer);

        /**
         * Step 1: Set visible within it's own container
         */

        // eslint-disable-next-line no-unsafe-optional-chaining
        focusedContainer.nodeRef.scrollLeft += focusedRect?.left + focusedRect?.width / 2 - focusedContainer.nodeRef?.offsetWidth / 2; // - focusedContainer.nodeRef?.parentElement?.offsetLeft;

        /**
         * Step 2: Set visible the container within the parent container
         */
        if (!parentContainer) {
            return;
        }

        const parentRect = parentContainer.nodeRef.getBoundingClientRect();

        if (containerRect.top + containerRect.height > parentRect.height + parentRect.top) {
            parentContainer.nodeRef.scrollTop += containerRect.top + containerRect.height - (parentRect.height + parentRect.top);
        }

        if (containerRect.top < parentRect.top) {
            parentContainer.nodeRef.scrollTop += containerRect.top - parentRect.top;
        }
    }

    stash() {
        console.log('Focus.stash');
        Elements.pushStash(this.focused);
        this.blur();
    }

    restore() {
        const focusedElement = Elements.popStash();
        if (focusedElement) {
            this.focus(focusedElement, true);
        } else {
            this.focus();
        }
    }
}

export default new Focus();
