import { ContentItemType, FavouritableItemKind } from '__SMART_APP_OLD__/api/graphql/types';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

export * from 'App/Modules/Screen/Modules/DetailRootScreen/Store/helpers/buttons';

export const getFavouritableItemKind = (typeOfAsset: AssetType): FavouritableItemKind => {
    switch (typeOfAsset) {
        case AssetType.EVENT:
        case AssetType.SERIES:
        case AssetType.SEASON:
        case AssetType.EPISODE:
            return FavouritableItemKind.Event;
        case AssetType.VOD_ASSET:
        case AssetType.VOD_FOLDER:
        case AssetType.VOD_SERIES:
            return FavouritableItemKind.VodAsset;
        case AssetType.NETWORK_RECORDING:
            return FavouritableItemKind.NetworkRecording;
        case AssetType.VOD_PRODUCT:
            return FavouritableItemKind.VodProduct;
        default:
            return typeOfAsset as unknown as FavouritableItemKind;
    }
};

export const pinSessionTypeForContentItemType: Partial<Record<ContentItemType, PinSessionType>> = {
    [ContentItemType.Vodasset]: PinSessionType.PIN,
    [ContentItemType.Event]: PinSessionType.PIN_LIVE_TV,
};
