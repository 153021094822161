import { InputElement, InputInit } from 'App/Modules/Input/Element/InputElement';
import { useKeyboard } from 'App/Modules/Keyboard/Hooks/useKeyboard';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React, { useEffect, useMemo } from 'react';

export type InputProps = InputInit & Omit<UI.InputProps, 'value' | 'carret' | 'focused' | 'onFocus' | 'onBlur'>;

export const Input = React.forwardRef<InputElement, InputProps>((props, ref) => {
    const { id, value, autoFocus, hidden, onBlur, onChange, onComplete, onFocus, ...rest } = props;
    const keyboard = useKeyboard();
    // props not provided to createInput because
    // props will be used only in the InputElement constructor
    const input = useMemo(
        () => new InputElement(keyboard, { id, value, autoFocus, hidden, onBlur, onChange, onComplete, onFocus }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keyboard]
    );

    const h = Subscribable.use(input.hidden);

    useEffect(() => {
        if (!ref) return;
        if (typeof ref === 'function') ref(input);
        else ref.current = input;
    }, [ref, input]);

    useEffect(() => () => input.blur(), [input]);

    return <UI.Input value={input.value} focused={input.focused} carret={input.cursor} hidden={h} {...rest} />;
});
