import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';

export type UINotificationEntityTable = {
    isActive: boolean;
    stack: string[];
    entities: Record<string, UINotificationEntity>;
};

export type UINotificationEntity =
    | BasicNotificationEntity
    | SeekNotificationEntity
    | ReminderNotificationEntity
    | MessageNotificationEntity
    | EventNotAvailableNotificationEntity
    | ChannelNotInActiveChannelListNotificationEntity;

export type SeekNotificationEntity = {
    id: string;
    type: NotificationType.SEEK;
    status: NotificationVisibilityStatus;
    timer: number;
    icon: NotificationIconType.SEEK_BACKWARD | NotificationIconType.SEEK_FORWARD;
    title: string;
    channelTitle?: string;
    subtitle?: never;
    actions?: NotificationActionID[];
};

export type BasicNotificationEntity = {
    id: string;
    type: NotificationType.BASIC;
    status: NotificationVisibilityStatus;
    timer: number;
    icon?: NotificationIconType;
    title: string;
    channelTitle?: string;
    subtitle?: string;
    actions?: NotificationActionID[];
};

export type ReminderNotificationEntity = {
    id: string;
    type: NotificationType.REMINDER;
    status: NotificationVisibilityStatus;
    timer: number;
    icon: NotificationIconType.REMINDER;
    title: string;
    channelTitle?: string;
    subtitle: string;
    actions: NotificationActionID[];
};

export type MessageNotificationEntity = {
    id: string;
    type: NotificationType.MESSAGE;
    status: NotificationVisibilityStatus;
    timer: number;
    icon: string;
    title: string;
    channelTitle?: string;
    subtitle: string;
    actions: NotificationActionID[];
};

export type EventNotAvailableNotificationEntity = {
    id: GQL.EventID;
    type: NotificationType.EVENT_NOT_AVAILABLE;
    status: NotificationVisibilityStatus;
    timer: number;
    icon: string;
    title: string;
    channelTitle?: string;
    subtitle: string;
    actions: NotificationActionID[];
};

export type ChannelNotInActiveChannelListNotificationEntity = {
    id: string;
    type: NotificationType.CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST;
    status: NotificationVisibilityStatus;
    timer: number;
    icon?: NotificationIconType;
    title: string;
    channelTitle?: string;
    subtitle?: string;
    actions?: NotificationActionID[];
};

export enum NotificationType {
    BASIC = 'BASIC',
    SEEK = 'SEEK',
    REMINDER = 'REMINDER',
    MESSAGE = 'MESSAGE',
    EVENT_NOT_AVAILABLE = 'EVENT_NOT_AVAILABLE',
    CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST = 'CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST',
}

export enum NotificationIconType {
    SEEK_FORWARD = 'SEEK_FORWARD',
    SEEK_BACKWARD = 'SEEK_BACKWARD',
    MESSAGE = 'MESSAGE',
    REMINDER = 'REMINDER',
    INFO = 'INFO',
}

export enum NotificationActionID {
    WATCH = 'WATCH',
    SNOOZE = 'SNOOZE',
    RECORD = 'RECORD',
    CANCEL = 'CANCEL',
    READ_MESSAGE = 'READ_MESSAGE',
    GO_TO_LIVE = 'GO_TO_LIVE',
    GO_TO_CHANNEL_LIST = 'GO_TO_CHANNEL_LIST',
}

export enum NotificationActionTrigger {
    BACK = 'BACK',
    BUTTON = 'BUTTON',
    ANIMATION_END = 'ANIMATION_END',
}

export enum NotificationButtonIconFlavour {
    DEFAULT = 'DEFAULT',
    FANCY = 'FANCY',
}

export enum NotificationVisibilityStatus {
    ANIMATE_IN = 'ANIMATE_IN',
    VISIBLE = 'VISIBLE',
    ANIMATE_OUT = 'ANIMATE_OUT',
    HIDDEN = 'HIDDEN',
}

export enum NotificationAnimation {
    ANIMATE_IN = 'notificationAnimateIn',
    ANIMATE_OUT = 'notificationAnimateOut',
    TIMER = 'notificationTimer',
}

export type NotifictionWatchAction = ButtonEntity<NotificationActionID.WATCH, 'SCREEN_DETAIL_PLAY_BUTTON'>;
export type NotifictionSnoozeAction = ButtonEntity<NotificationActionID.SNOOZE, 'SNOOZE_BUTTON'>;
export type NotifictionRecordAction = ButtonEntity<NotificationActionID.RECORD, 'SCREEN_PLAYER_RECORD_BUTTON'>;
export type NotifictionCancelAction = ButtonEntity<NotificationActionID.CANCEL, 'CANCEL_BUTTON'>;
export type NotifictionReadMessageAction = ButtonEntity<NotificationActionID.READ_MESSAGE, 'READ_MESSAGE'>;
export type NotifictionGoToLiveAction = ButtonEntity<NotificationActionID.GO_TO_LIVE, 'GO_TO_LIVE'>;
export type NotifictionGoToChannelList = ButtonEntity<NotificationActionID.GO_TO_CHANNEL_LIST, 'MAIN_MENU_CHANNEL_LISTS'>;

export type NotificationAction =
    | NotifictionWatchAction
    | NotifictionSnoozeAction
    | NotifictionRecordAction
    | NotifictionCancelAction
    | NotifictionReadMessageAction
    | NotifictionGoToLiveAction
    | NotifictionGoToChannelList;

// Actions
export type NotificationReduxAction =
    | NotificationShowAction
    | NotificationHideAction
    | NotificationStatusChangeAction
    | NotificationActiveStatusChangeAction;

export type NotificationShowActionPayload = { notification: UINotificationEntity };
export type NotificationHideActionPayload = { notificationId: string };
export type NotificationStatusChangeActionPayload = { notificationId: string; status: NotificationVisibilityStatus };
export type NotificationActiveStatusChangeActionPayload = { isActive: boolean };

export type NotificationShowAction = PayloadedAction<ActionType.NOTIFICATION_SHOW, NotificationShowActionPayload>;
export type NotificationHideAction = PayloadedAction<ActionType.NOTIFICATION_HIDE, NotificationHideActionPayload>;
export type NotificationStatusChangeAction = PayloadedAction<ActionType.NOTIFICATION_STATUS_CHANGE, NotificationStatusChangeActionPayload>;
export type NotificationActiveStatusChangeAction = PayloadedAction<
    ActionType.NOTIFICATION_ACTIVE_STATUS_CHANGE,
    NotificationActiveStatusChangeActionPayload
>;
