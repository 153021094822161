import React from 'react';

import { CardFolder, CardFolderModule } from 'App/Packages/UI/Card/CardFolder/CardFolder';
import { CardLandscape, CardLandscapeModule } from 'App/Packages/UI/Card/CardLandscape/CardLandscape';
import { CardMore, CardMoreModule } from 'App/Packages/UI/Card/CardMore/CardMore';
import { CardPortrait, CardPortraitModule } from 'App/Packages/UI/Card/CardPortrait/CardPortrait';
import { CardBanner, CardBannerModule } from 'App/Packages/UI/Card/CardBanner/CardBanner';

export * from 'App/Packages/UI/Card/CardFolder/Types';
export * from 'App/Packages/UI/Card/CardLandscape/Types';
export * from 'App/Packages/UI/Card/CardMore/Types';
export * from 'App/Packages/UI/Card/CardPortrait/Types';

export type CardProps = {};

export type CardComponents = {
    More: CardMoreModule;
    Folder: CardFolderModule;
    Portrait: CardPortraitModule;
    Landscape: CardLandscapeModule;
    Banner: CardBannerModule;
};

export type CardModule = React.FunctionComponent<CardProps> & CardComponents;

export const Card: CardModule = () => null;

Card.More = CardMore;
Card.Folder = CardFolder;
Card.Portrait = CardPortrait;
Card.Landscape = CardLandscape;
Card.Banner = CardBanner;
