import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

const getClassName = (className?: string) => {
    const classList = ['header-item__label'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type HeaderMenuItemLabelProps = React.PropsWithChildren<{ className?: string }>;

export type HeaderMenuItemLabelComponent = React.FunctionComponent<HeaderMenuItemLabelProps>;

export const HeaderMenuItemLabel: HeaderMenuItemLabelComponent = (props) => {
    const item = HeaderItem.use();
    const isFocused = Subscribable.use(item.focused);
    return (
        <Text
            className={getClassName(props.className)}
            isFocused={isFocused}
            typeface={Typeface.SANS}
            size={FontSize.TITLE_3}
            weight={FontWeight.REGULAR}
            color={Color.PRIMARY}
        >
            {props.children}
        </Text>
    );
};
