import { NotificationView } from '__SMART_APP_OLD__/app/modules/Notification/NotificationView';
import { NotificationOrchestrator } from '__SMART_APP_OLD__/app/modules/Notification/NotificationOrchestrator';

interface NotificationType {
    View: typeof NotificationView;
    Orchestrator: typeof NotificationOrchestrator;
}

export const Notification: NotificationType = {
    View: NotificationView,
    Orchestrator: NotificationOrchestrator,
};
