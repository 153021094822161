import React, { useMemo } from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Calc } from 'App/Packages/Calc';

export type ProgressBarProps = {
    className?: string;
    color?: BackgroundColor;
    theme?: Theme.Type;
    progress: number;
};

const getClassName = (props: ProgressBarProps): string => {
    const classList = ['progress-bar-v2'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

const getStyles = (progress: number) =>
    ({
        width: `${Calc.clamp(0, progress, 100)}%`,
    }) as React.CSSProperties;

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props) => {
    const className = getClassName(props);
    const styles = useMemo(() => getStyles(props.progress), [props.progress]);
    return (
        <Div className={className} backgroundColor={BackgroundColor.FILL_PRIMARY} shadow={Shadow.NONE} theme={props.theme}>
            <Div
                className="progress-bar-v2__progress"
                backgroundColor={props.color ?? BackgroundColor.HOT_RED_PRIMARY}
                shadow={Shadow.NONE}
                theme={props.theme}
                style={styles}
            />
        </Div>
    );
};
