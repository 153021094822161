import React, { SyntheticEvent } from 'react';

import { changePin, validateAdminPin } from '__SMART_APP_OLD__/api/Services';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import {
    pinActiveStatusChanged,
    pinActiveStatusLiveTvChanged,
    pinForceProtectionChanged,
} from '__SMART_APP_OLD__/app/modules/Data/modules/pin/actions';
import {
    selectIsLiveTvPinActive,
    selectIsPinForceProtected,
    selectPinBlockedUntil,
    selectPinIsActive,
} from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { PIN_BLOCKED, PIN_DATA_UPDATE, PIN_OVERLAY_HIDE, PIN_OVERLAY_SHOW, PIN_UNBLOCKED } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { PinAction, PinActionState, PinStyle, pinKeysByType } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';

import { Key } from 'App/Modules/Key';
import { Calc } from 'App/Packages/Calc';

// eslint-disable-next-line complexity
const handleKeyEvents = (
    keyCode: number,
    updateFocusedIndex: any,
    updateInputValue: any,
    isKeyboardOpen: any,
    setIsKeyboardOpen: any,
    evt?: SyntheticEvent
): boolean => {
    if (evt && typeof evt.stopPropagation === 'function') {
        evt.stopPropagation();
    }

    switch (keyCode) {
        case Key.VK_BACK_SPACE:
            updateInputValue(-1);
            return false;
        case Key.VK_BACK:
        case Key.VK_CANCEL:
            if (isKeyboardOpen) {
                setIsKeyboardOpen(false);
                return true;
            }

            Events.triggerEvents(PIN_OVERLAY_HIDE);
            return true; // don't propagate event further! Look in Focus.callEventListeners!;
        case Key.VK_ENTER:
            setIsKeyboardOpen(true);
            return false;
        case Key.VK_RIGHT:
            updateFocusedIndex(1);
            return false;
        case Key.VK_LEFT:
            updateFocusedIndex(-1);
            return false;
        case Key.VK_UP:
        case Key.VK_DOWN:
            return true;
        case Key.VK_0:
        case Key.VK_1:
        case Key.VK_2:
        case Key.VK_3:
        case Key.VK_4:
        case Key.VK_5:
        case Key.VK_6:
        case Key.VK_7:
        case Key.VK_8:
        case Key.VK_9:
            updateInputValue(keyCode - 48);
            return false;
        default:
            return false;
    }
};

export const showPinOverlay = (
    action: string,
    actionState: string,
    successCallback: () => void,
    dismissCallback: () => void,
    sessionType: string,
    forceProtection: boolean,
    style: {
        layout: string;
    },
    isOverlay: boolean
) => {
    Events.triggerEvents(PIN_DATA_UPDATE, {
        action,
        actionState,
        sessionType,
        forceProtection,
        onSuccess: successCallback,
        style,
        isOverlay,
    });
    Events.triggerEvents(PIN_OVERLAY_SHOW, {
        onClose: dismissCallback,
        isOverlay,
    });
};

const defaultPinStyle = {
    layout: PinStyle.Overlay,
};

/**
 * Opens an overlay for entering PIN
 * @param successCallback Callback trigger on entering/re-entering correct PIN
 * @param dismissCallback Callback triggered when PIN check failed
 * If not specified, GENERAL type will be used.
 * @param pinAction Indicates the type of action to be executed e.g.
 *  Enter/Change. If not specified, ENTER type will be used.
 * @param sessionType
 * @param forceProtection
 * @param style Comes as classNames from Constants.ts file - PinStyle
 * @param isOverlay If set to true, an onBack listener is set in PinWrapper.js file,
 *  that closes the overlay, and stops event propagation.
 */
export const openPinOverlay = (
    successCallback: () => void,
    dismissCallback: (args?: any) => void = () => {},
    pinAction: string = PinAction.ENTER,
    sessionType: string = PinSessionType.PIN,
    forceProtection = false,
    style = defaultPinStyle,
    isOverlay = true
) => {
    store.dispatch(pinForceProtectionChanged(forceProtection));
    if (
        (!forceProtection && selectPinIsActive(store.getState()) && sessionType === PinSessionType.PIN) ||
        (selectIsLiveTvPinActive(store.getState()) && sessionType === PinSessionType.PIN_LIVE_TV)
    ) {
        successCallback();
        return;
    }
    const pinScreenState = selectPinBlockedUntil(store.getState()) === -1 ? PinActionState.FIRST : PinActionState.BLOCKED;
    showPinOverlay(pinAction, pinScreenState, successCallback, dismissCallback, sessionType, forceProtection, style, isOverlay);
};

export const openOnVisibilityChangePinOverlay = (sessionType: string = PinSessionType.PIN) => {
    openPinOverlay(
        () => Player.restore(),
        () => {
            setTimeout(() => {
                if (!selectIsLiveTvPinActive(store.getState())) {
                    Player.stop();
                    History.back();
                }

                if (!selectPinIsActive(store.getState())) {
                    Player.stop();
                    History.back();
                }
                // The dismissCallback is called before the successCallback.
                // That is why we need this delay to make sure
                // we have the right pinSessionActive value.
            }, 10);
        },
        PinAction.ENTER,
        sessionType
    );
};

const useVisibilityState = (
    updateFocusedIndex: (value: number) => void,
    updateInputState: (value: number) => void,
    resetInputState: () => void
) => {
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);
    const [options, setOptions]: [any, any] = React.useState(null);
    const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(true);

    const onKey = (keyCode: number) => {
        if (keyCode === Key.VK_ENTER) return undefined;
        return isDialogVisible ? handleKeyEvents(keyCode, updateFocusedIndex, updateInputState, isKeyboardOpen, setIsKeyboardOpen) : false;
    };

    const showDialog = (opts: any) => {
        Focus.stash();
        setIsDialogVisible(true);
        setOptions(opts);
    };

    const hideDialog = (args?: any) => {
        setIsDialogVisible(false);
        resetInputState();
        setIsKeyboardOpen(true);
        if (typeof options?.onClose === 'function') {
            options.onClose(args?.status);
        }

        Focus.restore();
    };

    React.useEffect(() => {
        Events.addEventListener(PIN_OVERLAY_SHOW, showDialog);
        Events.addEventListener(PIN_OVERLAY_HIDE, hideDialog);

        return () => {
            Events.removeEventListener(PIN_OVERLAY_SHOW, showDialog);
            Events.removeEventListener(PIN_OVERLAY_HIDE, hideDialog);
        };
    });

    return {
        onKey,
        isDialogVisible,
        isKeyboardOpen,
        setIsKeyboardOpen,
    };
};

interface PinData {
    action: PinAction;
    actionState: PinActionState;
    onSuccess?: () => void;
    forceProtection?: boolean;
    isOverlay?: boolean;
    style?: any;
    sessionType: string;
}

const assignOverlayInfo = (pinScreenAction: PinAction, actionState: PinActionState) => pinKeysByType[pinScreenAction][actionState];

// eslint-disable-next-line max-statements
export const usePinBusinessLogic = (): {
    focusedIndex: number;
    resetInputStates: () => void;
    updateInputState: (value: number) => void;
    updateFocusedIndex: (value: number) => void;
    pin: any;
    isDialogVisible: boolean;
    onKey: (keyCode: number) => void;
    setIsKeyboardOpen: any;
    pinData: any;
    overlayInfo: any;
    style: {
        theme: string;
        layout: string;
    };
    isKeyboardOpen: boolean;
} => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const nrOfInvalidPinEntries = React.useRef<number>(0);
    const [enteredPin, setEnteredPin] = React.useState('');
    const focusedIndex = React.useRef(0);
    const [shadowPin, setShadowPin] = React.useState({});
    const pin = React.useRef({});
    const [isPinEntered, setIsPinEntered] = React.useState(false);
    const forceProtection = useSelector(selectIsPinForceProtected);
    const resetInputStates = () => {
        pin.current = {};
        setShadowPin({});
        focusedIndex.current = 0;
        setIsPinEntered(false);
    };

    const updateFocusedIndex = (value: number) => {
        focusedIndex.current = Calc.clamp(0, focusedIndex.current + value, 3);
    };

    const updateInputState = (value: number) => {
        if (value < 0) {
            const key = `${focusedIndex.current - 1}`;
            const tempObject: any = { ...pin.current };
            delete tempObject[key];
            pin.current = tempObject;
            setShadowPin(pin.current);
            updateFocusedIndex(-1);
        } else {
            const input: any = { ...pin.current };
            input[focusedIndex.current] = value;
            updateFocusedIndex(1);
            pin.current = input;
            setShadowPin(pin.current);
        }
    };

    // TODO: check why this is called 2 times, seems like on key down + on key up
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (Object.keys(pin.current).length === 4) {
            setIsPinEntered(true);
        } else {
            setIsPinEntered(false);
        }
    });

    const resetOnClose = () => {
        resetInputStates();
    };

    const { isDialogVisible, isKeyboardOpen, onKey, setIsKeyboardOpen } = useVisibilityState(
        updateFocusedIndex,
        updateInputState,
        resetOnClose
    );

    const [pinData, setPinData] = React.useState<PinData>();
    const [overlayInfo, setOverlayInfo] = React.useState<any>();
    const [style, setStyle] = React.useState<any>(false);

    const successCallbackRef = React.useRef<() => void>(() => {});

    const updateAction = (args: PinData) => {
        const { action, actionState, style: pinStyle, isOverlay } = args;
        const newOverlayInfo = assignOverlayInfo(action, actionState);
        setStyle(pinStyle);
        setPinData({ ...pinData, ...args });
        setOverlayInfo({ ...newOverlayInfo, isOverlay });
        successCallbackRef.current = args.onSuccess!;
    };

    const updateOverlayInfo = (newActionState: PinActionState) => {
        if (!pinData) return;

        const { action } = pinData;
        const newOverlayInfo = assignOverlayInfo(action, newActionState);
        setOverlayInfo(newOverlayInfo);
        setPinData({ ...pinData, actionState: newActionState });
    };

    const unblockedAction = () => {
        Events.triggerEvents(PIN_DATA_UPDATE, {
            action: PinAction.ENTER,
            actionState: PinActionState.FIRST,
            forceProtection,
            style: defaultPinStyle,
            onSuccess: successCallbackRef.current,
            isOverLay: true,
        });
    };

    const startSessionCounter = (pinSessionType: string) => {
        if (pinSessionType === PinSessionType.PIN) {
            dispatch(pinActiveStatusChanged(true));
        } else {
            dispatch(pinActiveStatusLiveTvChanged(true));
        }
    };

    React.useEffect(() => {
        Events.addEventListener(PIN_DATA_UPDATE, updateAction);
        Events.addEventListener(PIN_UNBLOCKED, unblockedAction);

        return () => {
            Events.removeEventListener(PIN_DATA_UPDATE, updateAction);
            Events.removeEventListener(PIN_UNBLOCKED, unblockedAction);
        };
    });

    // eslint-disable-next-line max-statements,react-func/max-lines-per-function
    React.useEffect(() => {
        if (!isDialogVisible || !isPinEntered || !pinData) {
            return;
        }

        const { action, actionState, sessionType } = pinData;
        switch (action) {
            case PinAction.CHANGE:
            case PinAction.FIRST_SETUP: {
                if (actionState === PinActionState.FIRST) {
                    setEnteredPin(Object.values(pin.current).join(''));
                    nrOfInvalidPinEntries.current = 0;
                    updateOverlayInfo(PinActionState.REENTER);
                    resetInputStates();
                } else if (actionState === PinActionState.REENTER) {
                    if (enteredPin === Object.values(pin.current).join('')) {
                        changePin(enteredPin)
                            .then(() => {
                                nrOfInvalidPinEntries.current = 0;
                                Events.triggerEvents(PIN_OVERLAY_HIDE, { status: true });

                                return null;
                            })
                            .catch((e) => {
                                throw e;
                            });
                    } else {
                        updateOverlayInfo(PinActionState.WRONG);
                        nrOfInvalidPinEntries.current = 0;
                        resetInputStates();
                    }
                } else if (actionState === PinActionState.WRONG) {
                    setEnteredPin(Object.values(pin.current).join(''));
                    nrOfInvalidPinEntries.current = 0;
                    updateOverlayInfo(PinActionState.REENTER);
                    resetInputStates();
                }
                break;
            }
            case PinAction.ENTER: {
                if (actionState === PinActionState.FIRST) {
                    validateAdminPin(Object.values(pin.current).join(''))
                        .then((success: Boolean) => {
                            if (success) {
                                nrOfInvalidPinEntries.current = 0;

                                if (successCallbackRef.current && typeof successCallbackRef.current === 'function') {
                                    setTimeout(() => {
                                        if (!forceProtection) startSessionCounter(sessionType);
                                        successCallbackRef.current();
                                        Events.triggerEvents(PIN_OVERLAY_HIDE, { status: true });
                                    }, 10);
                                } else {
                                    Events.triggerEvents(PIN_OVERLAY_HIDE, { status: true });
                                }
                            } else {
                                // invalid pin typed
                                updateOverlayInfo(PinActionState.WRONG);
                                nrOfInvalidPinEntries.current = 1;
                                resetInputStates();
                            }

                            return null;
                        })
                        .catch((e) => {
                            throw e;
                        });
                } else if (actionState === PinActionState.WRONG) {
                    validateAdminPin(Object.values(pin.current).join(''))
                        .then((success: Boolean) => {
                            if (success) {
                                nrOfInvalidPinEntries.current = 0;

                                if (successCallbackRef.current && typeof successCallbackRef.current === 'function') {
                                    setTimeout(() => {
                                        if (!forceProtection) startSessionCounter(sessionType);
                                        successCallbackRef.current();
                                        Events.triggerEvents(PIN_OVERLAY_HIDE, { status: true });
                                    }, 10);
                                } else {
                                    Events.triggerEvents(PIN_OVERLAY_HIDE, { status: true });
                                }
                            } else {
                                // invalid pin typed
                                nrOfInvalidPinEntries.current += 1;
                                if (nrOfInvalidPinEntries.current >= config.pin.allowedWrongAttempts) {
                                    nrOfInvalidPinEntries.current = 0;
                                    updateOverlayInfo(PinActionState.BLOCKED);
                                    Events.triggerEvents(PIN_BLOCKED);
                                }
                                resetInputStates();
                            }

                            return null;
                        })
                        .catch((e) => {
                            throw e;
                        });
                }
                break;
            }
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPinEntered, isDialogVisible, overlayInfo]);

    return {
        onKey,
        isKeyboardOpen,
        focusedIndex: focusedIndex.current,
        isDialogVisible,
        pin: shadowPin,
        pinData,
        overlayInfo,
        style,
        setIsKeyboardOpen,
        resetInputStates,
        updateInputState,
        updateFocusedIndex,
    };
};
