import { Calc } from 'App/Packages/Calc';
import { Axis } from 'App/Types/Axis';

export const Constants = {
    List: {
        Axis: Axis.X,
        Scroll: {
            Offset: 0,
            Left: Calc.pixel(96),
            calc: (value: number) => value - Constants.List.Scroll.Left,
        },
    },
    Item: {
        Focus: {
            Delay: 25,
        },
        Scroll: {
            Delay: 100,
        },
    },
} as const;
