import React, { useCallback } from 'react';

import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { messageInboxItemSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { MessageInboxItemActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItemActions';
import { MessageInboxItemContent } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItemContent';
import { MessageInboxItemImage } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItemImage';
import { MessageInboxItemSelect } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItemSelect';
import { selectMessageInboxIsMessageSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';
import { MessageInboxCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Focus } from 'App/Packages/Focus';

interface Props {
    id: GQL.MessageID;
    isFocused: boolean;
    onHover: (id: GQL.MessageID) => void;
}

const classNameForThemeType: Record<Theme.Type, string> = {
    DARK: 'message-inbox-item--dark-theme',
    LIGHT: 'message-inbox-item--light-theme',
};

const getClassName = (props: Props, isSelected: boolean, theme: Theme.Type): string => {
    const classList = ['message-inbox-item'];
    if (props.isFocused) classList.push('message-inbox-item--focused');
    if (isSelected) classList.push('message-inbox-item--selected');
    classList.push(classNameForThemeType[theme]);
    return classList.join(' ');
};

export const MessageInboxItemComponent: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSelected = useSelector(selectMessageInboxIsMessageSelected(props.id));
    const className = getClassName(props, isSelected, theme);

    const enterHandler = useCallback((id: GQL.MessageID) => dispatch(messageInboxItemSelected(id)), [dispatch]);

    const { onHover, onClick } = Focus.useFocusable({
        ctx: MessageInboxCTX.LIST,
        id: props.id,
        isFocused: props.isFocused,
        onEnter: enterHandler,
        onHover: props.onHover,
    });

    return (
        <div className={className} onClick={onClick} onMouseOver={onHover}>
            <MessageInboxItemImage id={props.id} />
            <div className="message-inbox-item__content-container">
                <MessageInboxItemContent id={props.id} />
                <div className="message-inbox-item__actions">
                    <MessageInboxItemActions id={props.id} isFocused={props.isFocused} />
                </div>
            </div>
            <MessageInboxItemSelect id={props.id} isFocused={props.isFocused} />
        </div>
    );
};

export const MessageInboxItem = React.memo(MessageInboxItemComponent);
