import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ParentalRatingRelationFragmentDoc } from '../fragments/parentalRatingRelation.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ParentalRatingChangeMutationVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
  parentalRatingId: Types.Scalars['ID']['input'];
}>;


export type ParentalRatingChangeMutation = { __typename?: 'Nexx4Mutations', changeProfilePermissions: { __typename?: 'ChangeProfilePermissionsPayload', profile: { __typename: 'Profile', id: string, permissions: { __typename: 'ProfilePermissions', id: string, parentalRating: { __typename?: 'ParentalRating', id: string } } } } };

export type ParentalRatingChangeFragment = { __typename?: 'Nexx4Mutations', changeProfilePermissions: { __typename?: 'ChangeProfilePermissionsPayload', profile: { __typename: 'Profile', id: string, permissions: { __typename: 'ProfilePermissions', id: string, parentalRating: { __typename?: 'ParentalRating', id: string } } } } };

export const ParentalRatingChangeFragmentDoc = gql`
    fragment parentalRatingChange on Nexx4Mutations {
  changeProfilePermissions(
    input: {profileId: $profileId, parentalRatingId: $parentalRatingId}
  ) {
    profile {
      id
      permissions {
        id
        parentalRating {
          ...parentalRatingRelation
        }
        __typename
      }
      __typename
    }
  }
}
    ${ParentalRatingRelationFragmentDoc}`;
export const ParentalRatingChangeDocument = gql`
    mutation parentalRatingChange($profileId: ID!, $parentalRatingId: ID!) {
  ...parentalRatingChange
}
    ${ParentalRatingChangeFragmentDoc}`;
export type ParentalRatingChangeMutationFn = Apollo.MutationFunction<ParentalRatingChangeMutation, ParentalRatingChangeMutationVariables>;

/**
 * __useParentalRatingChangeMutation__
 *
 * To run a mutation, you first call `useParentalRatingChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParentalRatingChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parentalRatingChangeMutation, { data, loading, error }] = useParentalRatingChangeMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      parentalRatingId: // value for 'parentalRatingId'
 *   },
 * });
 */
export function useParentalRatingChangeMutation(baseOptions?: Apollo.MutationHookOptions<ParentalRatingChangeMutation, ParentalRatingChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParentalRatingChangeMutation, ParentalRatingChangeMutationVariables>(ParentalRatingChangeDocument, options);
      }
export type ParentalRatingChangeMutationHookResult = ReturnType<typeof useParentalRatingChangeMutation>;
export type ParentalRatingChangeMutationResult = Apollo.MutationResult<ParentalRatingChangeMutation>;
export type ParentalRatingChangeMutationOptions = Apollo.BaseMutationOptions<ParentalRatingChangeMutation, ParentalRatingChangeMutationVariables>;