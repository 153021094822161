import { Reducer } from 'redux';

import { initialHeaderState } from '__SMART_APP_OLD__/app/modules/Header/constants';
import { HeaderActionType, HeaderData } from '__SMART_APP_OLD__/app/modules/Header/types';

export const headerReducer: Reducer<HeaderData> = (state, action) => {
    if (state === undefined) return initialHeaderState;

    switch (action.type) {
        case HeaderActionType.FOCUSED_INDEX_CHANGED:
            return { ...state, focusedIndex: action.payload };
        case HeaderActionType.SELECTED_INDEX_CHANGED:
            return { ...state, selectedIndex: action.payload };
        case HeaderActionType.ADD_INDEX_TO_HISTORY: {
            const currentHistory = state.selectedIndexesHistory;
            currentHistory.unshift(action.payload);
            return { ...state, selectedIndexesHistory: currentHistory };
        }
        case HeaderActionType.REMOVE_INDEX_FROM_HISTORY: {
            const currentHistory = state.selectedIndexesHistory;
            currentHistory.shift();
            return { ...state, selectedIndexesHistory: currentHistory };
        }
        case HeaderActionType.CLEAR_INDEXES_HISTORY:
            return { ...state, selectedIndexesHistory: [] };
        default:
            return state;
    }
};
