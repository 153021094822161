import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';

import { Screen } from 'App/Modules/Screen/index';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { Header, Folder } from 'App/Modules/Screen/Modules/DetailNetworkRecordingScreen/View/Components/';

export const DetailNetworkRecordingScreen: React.FunctionComponent<Screen.Props<Screen.Type.RecordingDetail>> = (props) => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <UI.Detail.ScreenContainer>
            <UI.Folder.List>
                <Header />
                <Folder initialFocusedItemId={props.initialFocusedItemId} />
            </UI.Folder.List>
        </UI.Detail.ScreenContainer>
    );
};
