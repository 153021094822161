import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { PersonalRecordingInfoFragmentFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/api/graphql/query/fragments.generated';
import { EventDetailItemFragmentDoc } from '../event/eventDetailItem.generated';
export type RecordingDetailItemFragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, allowPlayback: boolean, size: number, channelName?: string | null, personalRecordingInfo: { __typename: 'PersonalRecordingInfo', id: string, expiry: any, favourited: boolean, partOfSeriesRecording: boolean, seasonCancelled: boolean, seriesCancelled: boolean }, event: { __typename: 'Event', id: string, title: string, start: any, end: any, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null } };

export const RecordingDetailItemFragmentDoc = gql`
    fragment recordingDetailItem on NetworkRecording {
  id
  status
  start
  end
  availableUntil
  deleteProtected
  allowPlayback
  personalRecordingInfo: personalInfo(profileId: $profileId) {
    ...personalRecordingInfoFragment
  }
  size
  channelName
  event {
    ...eventDetailItem
  }
  __typename
}
    ${PersonalRecordingInfoFragmentFragmentDoc}
${EventDetailItemFragmentDoc}`;