import React from 'react';

import { List, ListProps } from '__SMART_APP_OLD__/app/components/ListV2';

export type SettingsSectionContentListProps<T extends string = string> = Omit<ListProps<T>, 'className'>;

export type SettingsSectionContentListType = <T extends string = string>(
    props: SettingsSectionContentListProps<T>
) => ReturnType<React.FunctionComponent<SettingsSectionContentListProps<T>>>;

export const SettingsSectionContentList = <T extends string = string>(props: SettingsSectionContentListProps<T>) => (
    <List className="settings-section-content__list" {...props} />
);
