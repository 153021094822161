export type InputCredentials = {
    email: {
        label?: string;
        onChange?: any;
        ref: HTMLInputElement | null;
    };
    password: {
        label?: string;
        onChange?: any;
        ref: HTMLInputElement | null;
    };
};

export type AnyObj = Record<string, any>;

export type IdpLoginDeviceAuthResponseType = {
    user_code: string;
    device_code: string;
    interval: number;
    verification_uri_complete: string;
    verification_uri: string;
    expires_in: number;
};

export type IdpLoginTokenResponseType = {
    access_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
    expires_in: number;
};

export type IdpLoginTokenResult = IdpLoginTokenResponseType & {
    error_description: string;
    error: string;
};

export const enum IdpLoginTokenErrors {
    AUTH_DECLINE = 'authorization_declined',
    BAD_VERIFICATION_CODE = 'bad_verification_code',
    EXPIRED_TOKEN = 'expired_token',
    AUTH_PENDING = 'authorization_pending',
}
