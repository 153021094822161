import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailBodyProps = DivPropsExtendable;

export type DetailBodyComponent = React.FunctionComponent<DetailBodyProps>;

export const DetailBody: DetailBodyComponent = (props) => {
    return <Div defaultClassName="detail__body" {...props} />;
};
