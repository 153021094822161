import * as Types from '../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type LanguageFragment = { __typename?: 'Language', id: string, code: string };

export const LanguageFragmentDoc = gql`
    fragment language on Language {
  id
  code
}
    `;