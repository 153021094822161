import React from 'react';

import { FolderItemsContext, FolderItemsContextModule } from 'App/Packages/UI/Folder/Modules/FolderItems/Context/FolderItemsContext';
import { UseFolderItems, useFolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/useFolderItems';
import { UseFolderItemsValue, useFolderItemsValue } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/useFolderItemsValue';

export type FolderItemsProps = React.PropsWithChildren<{
    id?: string;
    ctx?: string;
    initial?: string;
    disabled?: boolean;
    offset?: number;
    onEnter?: (id: string) => void;
}>;

export type FolderItemsModule = React.FunctionComponent<FolderItemsProps> & {
    // Context
    Context: FolderItemsContextModule;

    // Hooks
    use: UseFolderItems;
    useValue: UseFolderItemsValue;
};

export const FolderItems: FolderItemsModule = (props) => {
    const items = FolderItems.useValue({
        ctx: props.ctx,
        id: props.id,
        offset: props.offset,
        initial: props.initial,
        enter: props.onEnter,
    });
    return <FolderItems.Context.Provider value={items}>{props.children}</FolderItems.Context.Provider>;
};

// Context
FolderItems.Context = FolderItemsContext;

// Hooks
FolderItems.use = useFolderItems;
FolderItems.useValue = useFolderItemsValue;
