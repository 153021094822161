import { API } from '__SMART_APP_OLD__/app/api';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectParentalRatingId,
    selectParentalRatingIds,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import {
    ParentalRatingIdChangeAction,
    ParentalRatingLoadManyAction,
    ParentalRatingLoadManyActionPayload,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/types';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

export const parentalRatingLoadMany = (payload: ParentalRatingLoadManyActionPayload): ParentalRatingLoadManyAction => ({
    type: ActionType.PARENTAL_RATING_LOAD_MANY,
    payload,
});

export const parentalRatingIdChange = (parentalRatingId: string): ParentalRatingIdChangeAction => ({
    type: ActionType.PARENTAL_RATING_ID_CHANGE,
    payload: { parentalRatingId },
});

export const parentalRatingLoad =
    (force: boolean = false): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            if (selectParentalRatingIds(getState()).length && !force) return true;
            const response = await API.ParentalRating.loadMany();
            dispatch(parentalRatingLoadMany(response));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ParentalRatingLoad, error));
            return false;
        }
    };
export const parentalRatingChange =
    (id: GQL.ParentalRatingID): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const currentParentalRatingId = selectParentalRatingId(state);

            if (currentParentalRatingId === id) return true;

            const profileId = profileSelectors.public.selectId(state);
            await API.ParentalRating.change(id, profileId);
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ParentalRatingChange, error));
            return false;
        } finally {
            dispatch(parentalRatingIdChange(id));
        }
    };
