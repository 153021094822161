export enum PrivacyPolicyItemType {
    REQUIRED = 'REQUIRED',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    ANALYTICS = 'ANALYTICS',
    USER_BEHAVIOR = 'USER_BEHAVIOR',
    ADVERTISING = 'ADVERTISING',
    TRACK_VIEWING_BEHAVIOUR = 'TRACK_VIEWING_BEHAVIOUR',
}

export enum PrivacyPolicyButtonType {
    ACCEPT_ALL = 'ACCEPT_ALL',
    READ_MORE = 'READ_MORE',
    CONTINUE = 'CONTINUE',
}
