import React from 'react';

import AssetDetails from '__SMART_APP_OLD__/components/common/AssetDetails';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import { maskAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import State from '__SMART_APP_OLD__/utils/State';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

export default class Gallery extends React.Component {
    nodeRef = null;

    primaryButtonRef = null;

    constructor(props) {
        super(props);
        this.state = {
            focusedIndex: 0,
        };
    }

    componentDidMount() {
        if (!State.hasState('Stripes') && this.nodeRef) {
            Focus.focus(this.nodeRef);
        }
    }

    openDetailPage = () => {
        const { assets } = this.props;
        const { focusedIndex } = this.state;
        const asset = assets[focusedIndex];
        const { assetType, shouldRestrict } = asset;

        const openPage = () => {
            if (assetType === AssetType.EPISODE) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: asset.seriesId }));
                History.go(`/details/${assetType}/${asset.seriesId}`);
            } else {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: asset.id }));
                History.go(`/details/${assetType}/${asset.id}`);
            }
        };

        if (shouldRestrict) {
            openPinOverlay(openPage);
        } else {
            openPage();
        }
    };

    genButtons = () => [];

    render() {
        const {
            assets,
            type,
            onFocus,
            stripeindex,
            config,
            parentalRatingRank,
            playerTargetDetails,
            showScrollableGallery,
            playerView,
            sessionType,
        } = this.props;
        const { focusedIndex } = this.state;
        const buttons = this.genButtons();

        if (!assets || !assets.length) {
            return null;
        }

        const focusedAsset = assets[focusedIndex];
        const count = assets.length;
        const isLiveEvent = playerTargetDetails?.isLiveEvent || focusedAsset.isLive || false;

        maskAsset(focusedAsset, parentalRatingRank, sessionType);

        return (
            <div className="gallery">
                <AssetDetails
                    program={focusedAsset}
                    focusedAsset={focusedAsset}
                    channelId={focusedAsset?.channelId}
                    metaInfo={isLiveEvent ? focusedAsset?.liveEventMetaInfo : focusedAsset?.galleryMetaInfo}
                    additionalButtons={buttons}
                    playerTargetDetails={playerTargetDetails}
                    openDetailPage={this.openDetailPage}
                    playerView={playerView}
                    sessionType={sessionType}
                />
                {count > 1 && showScrollableGallery && (
                    <Stripe
                        className="gallery-stripe"
                        assets={assets}
                        config={config}
                        type={type}
                        totalCount={count}
                        onFocusChanged={({ activeIndex }) => this.setState({ focusedIndex: activeIndex })}
                        onFocus={onFocus}
                        stripeindex={stripeindex}
                        parentalRatingRank={parentalRatingRank}
                        ref={(ref) => {
                            if (ref) {
                                const { nodeRef } = ref.nodeRef;
                                if (nodeRef.nodeRef.children) {
                                    const [firstChild] = nodeRef.nodeRef.children;
                                    this.nodeRef = firstChild;
                                }
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}
