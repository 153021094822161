import { ErrorCode } from '__SMART_APP_OLD__/app/modules/Error/types';

export class Exception {
    readonly code: ErrorCode;

    readonly message: string;

    constructor(code: ErrorCode, message: string = '') {
        this.code = code;
        this.message = message;
    }
}
