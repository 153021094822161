import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodAssetEntitlementFragmentDoc } from '../fragments/vodAssetEntitlement.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteVodTransactionMutationVariables = Types.Exact<{
  input: Types.CompleteVodTransactionInput;
}>;


export type CompleteVodTransactionMutation = { __typename?: 'Nexx4Mutations', completeVODTransaction: { __typename?: 'CompleteVODTransactionPayload', asset: { __typename?: 'VODAsset', title: string, entitlements: { __typename?: 'VODAssetEntitlementCatalog', items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', id: string } | null } | null> } } } };

export type CompleteVodTransactionFragment = { __typename?: 'Nexx4Mutations', completeVODTransaction: { __typename?: 'CompleteVODTransactionPayload', asset: { __typename?: 'VODAsset', title: string, entitlements: { __typename?: 'VODAssetEntitlementCatalog', items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', id: string } | null } | null> } } } };

export const CompleteVodTransactionFragmentDoc = gql`
    fragment completeVODTransaction on Nexx4Mutations {
  completeVODTransaction(input: $input) {
    asset {
      title
      entitlements {
        items {
          ...vodAssetEntitlement
        }
      }
    }
  }
}
    ${VodAssetEntitlementFragmentDoc}`;
export const CompleteVodTransactionDocument = gql`
    mutation completeVODTransaction($input: CompleteVODTransactionInput!) {
  ...completeVODTransaction
}
    ${CompleteVodTransactionFragmentDoc}`;
export type CompleteVodTransactionMutationFn = Apollo.MutationFunction<CompleteVodTransactionMutation, CompleteVodTransactionMutationVariables>;

/**
 * __useCompleteVodTransactionMutation__
 *
 * To run a mutation, you first call `useCompleteVodTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVodTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVodTransactionMutation, { data, loading, error }] = useCompleteVodTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteVodTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteVodTransactionMutation, CompleteVodTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteVodTransactionMutation, CompleteVodTransactionMutationVariables>(CompleteVodTransactionDocument, options);
      }
export type CompleteVodTransactionMutationHookResult = ReturnType<typeof useCompleteVodTransactionMutation>;
export type CompleteVodTransactionMutationResult = Apollo.MutationResult<CompleteVodTransactionMutation>;
export type CompleteVodTransactionMutationOptions = Apollo.BaseMutationOptions<CompleteVodTransactionMutation, CompleteVodTransactionMutationVariables>;