import { ProfileInfo, ProfilePreferences } from '__SMART_APP_OLD__/app/gql/gql';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum ProfileActionType {
    MY_LIBRARY_ADULT_CONTENT_VISIBILITY_CHANGED = '@profile/adultContentVisibility/changed',
    SHOW_LIVE_CLOCK_CHANGED = '@profile/showLiveClock/changed',
    PROFILE_INITIAL_DATA_LOAD = '@profile/initialData/load',
}

export type ProfileReduxAction =
    | MyLibraryAdultContentVisibilityChangeAction
    | LiveClockVisibilityChangedAction
    | ProfileInitialDataLoadAction;

// Payloads
export type ShowLiveClockChangeActionPayload = { showLiveClock: boolean };
export type MyLibraryAdultContentVisibilityChangeActionPayload = { myLibraryAdultContentVisibility: boolean };
export type ProfileInitialDataPayload = {
    userId: string;
    userName: string;
    profileId: string;
    masterPinCode: string;
    profiles: ProfileInfo[];
    dataUsageAllowed: boolean;
    preferences: ProfilePreferences;
};

// Actions
export type MyLibraryAdultContentVisibilityChangeAction = PayloadedAction<
    ProfileActionType.MY_LIBRARY_ADULT_CONTENT_VISIBILITY_CHANGED,
    MyLibraryAdultContentVisibilityChangeActionPayload
>;
export type LiveClockVisibilityChangedAction = PayloadedAction<ProfileActionType.SHOW_LIVE_CLOCK_CHANGED, ShowLiveClockChangeActionPayload>;
export type ProfileInitialDataLoadAction = PayloadedAction<ProfileActionType.PROFILE_INITIAL_DATA_LOAD, ProfileInitialDataPayload>;

export type ProfileEntityTable = {
    profileId: string;
    userId: string;
    userName: string;
    masterPinCode: string;
    profiles: ProfileInfo[];
    dataUsageAllowed: boolean;
    showLiveClock: boolean;
    myLibraryAdultContentVisibility: boolean;
    preferences: ProfilePreferences | null;
};
