import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { channelListRemove } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Translation } from 'App/Modules/Translation';

const removed = (): Thunk<void> => async (dispatch, getState) => {
    const prompt = Prompt.selectors.selectTyped(Prompt.Type.ChannelListConfirmDelete)(getState());
    await dispatch(Prompt.actions.unmount());
    await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));

    if (!prompt) return;

    const success = await dispatch(channelListRemove(prompt.props.id));
    await dispatch(Overlay.actions.unmount());

    if (success) {
        dispatch(textNotificationShow(Translation.Key.Notification.ChannelListDeletedSuccessfully));

        LoggingService.getInstance().logEvent(
            getUIActionEvent(UIActionEvents.CHANNEL_LIST_DELETED, {
                id: prompt.props.id,
                assetType: AssetType.CHANNEL_LIST,
            })
        );
    }
};

const canceled = (): Thunk<void> => (dispatch) => dispatch(Prompt.actions.unmount());

export const actions = {
    private: {
        removed,
        canceled,
    },
};
