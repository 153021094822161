import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlayRecordingMutationVariables = Types.Exact<{
  input: Types.PlayRecordingInput;
}>;


export type PlayRecordingMutation = { __typename?: 'Nexx4Mutations', playRecording: { __typename?: 'PlayRecordingPayload', playbackInfo: { __typename?: 'RecordingPlaybackInfo', url: string, sessionId?: string | null, deliveryKind: Types.ContentDeliveryKind, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, adPlaybackPreRoll?: number | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const PlayRecordingDocument = gql`
    mutation playRecording($input: PlayRecordingInput!) {
  playRecording(input: $input) {
    playbackInfo {
      url
      sessionId
      deliveryKind
      adPlaybackRestrictions
      adPlaybackPreRoll
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type PlayRecordingMutationFn = Apollo.MutationFunction<PlayRecordingMutation, PlayRecordingMutationVariables>;

/**
 * __usePlayRecordingMutation__
 *
 * To run a mutation, you first call `usePlayRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playRecordingMutation, { data, loading, error }] = usePlayRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlayRecordingMutation(baseOptions?: Apollo.MutationHookOptions<PlayRecordingMutation, PlayRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayRecordingMutation, PlayRecordingMutationVariables>(PlayRecordingDocument, options);
      }
export type PlayRecordingMutationHookResult = ReturnType<typeof usePlayRecordingMutation>;
export type PlayRecordingMutationResult = Apollo.MutationResult<PlayRecordingMutation>;
export type PlayRecordingMutationOptions = Apollo.BaseMutationOptions<PlayRecordingMutation, PlayRecordingMutationVariables>;