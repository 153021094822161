import { useMemo, useRef } from 'react';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Header/Constants';
import { HeaderItemsValue } from 'App/Packages/UI/Header/Modules/HeaderItems/Context/HeaderItemsContext';
import { useHeaderItemsEnter } from 'App/Packages/UI/Header/Modules/HeaderItems/Hooks/Private/useHeaderItemsEnter';
import { useHeaderItemsFocused } from 'App/Packages/UI/Header/Modules/HeaderItems/Hooks/Private/useHeaderItemsFocused';
import { HeaderItemValue } from 'App/Packages/UI/Header/Modules/HeaderItem/Context/HeaderItemContext';

export type UseHeaderItemsValuePayload = {
    ctx?: string;
    active: string;
    initial?: string;
    disabled?: boolean;
    enter?: (id: string) => void;
};

export type UseHeaderItemsValue = (payload: UseHeaderItemsValuePayload) => HeaderItemsValue;

export const useHeaderItemsValue: UseHeaderItemsValue = (payload) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const ctx = payload.ctx ?? Constants.Ctx;
    const ids = Subscribable.useArray<string>([]);
    const store = Subscribable.useRecord<HeaderItemValue>({});
    const { initial } = payload;
    const _items = Subscribable.use(ids);
    const axis = Constants.Items.Axis;
    const isDisabled = payload.disabled;
    const navigation = useListHook({ axis, ctx, items: _items, disabled: isDisabled });
    const active = Subscribable.useValue(payload.active);
    const focused = Subscribable.useValue(navigation.focused);
    const disabled = Subscribable.useValue(navigation.isDisabled);
    const scroll = useMemo(() => new Scroll(ref), [ref]);
    const { focus } = navigation;
    const enter = useHeaderItemsEnter(payload.enter);

    Subscribable.useSetter(active, payload.active);
    Subscribable.useSetter(focused, navigation.focused);
    Subscribable.useSetter(disabled, navigation.isDisabled);

    useHeaderItemsFocused(store, initial, disabled);

    return useMemo<HeaderItemsValue>(
        () => ({ ref, ctx, ids, store, focused, active, disabled, initial, scroll, focus, enter }),
        [ref, ctx, ids, store, focused, active, disabled, initial, scroll, focus, enter]
    );
};
