import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { selectConfig, selectIsVodUpsellOptions, selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Detail } from 'App/Modules/Data/Detail';
import { Screen } from 'App/Modules/Screen';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { LoggingService } from 'analytics/loggingService';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { Overlay } from 'App/Modules/Overlay';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Data } from 'App/Modules/Data';
import { Folder } from 'App/Modules/Folder';
import { canResume, startPlayback } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/helpers';
import { DefaultDetailScreenOutOfBounds } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/defaultOutOfBounds';
import { VodSeriesDetailLoadQueryVariables } from 'App/Modules/Data/Detail/VodSeries/Api/vodSeriesDetailLoad.generated';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { ProductKind } from '__SMART_APP_OLD__/api/graphql/types';

export const toPlayerPage =
    (id: string): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();

        const prevScreen = Screen.selectors.selectTyped(Screen.Type.VodSeriesDetail)(state);

        if (!prevScreen) return undefined;

        dispatch(
            Screen.actions.update(Screen.Type.VodSeriesDetail, {
                id: prevScreen.props.id,
                initialFocusedItemId: id,
            })
        );

        return dispatch(watchSelected());
    };

const watchSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.VodSeries.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetObject = Detail.VodSeries.selectors.selectAssetObjectById(asset.id)(state);

    const { seasonOrdinal } = assetObject;
    assetObject.attachedAssets = Detail.VodSeries.selectors.selectEpisodesBySeasonNumber(seasonOrdinal)(state);

    const progress = Detail.Root.selectors.selectAssetProgress(itemSelector)(state);
    const bookmark = Bookmarks.selectors.selectEntity(asset.id)(state);
    const resumeThreshold = selectResumeThreshold(state);
    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    const resume = canResume(asset, progress, bookmark, resumeThreshold);
    const event = resume ? UIActionEvents.CONTINUE_WATCHING : UIActionEvents.PLAY;
    const isStartOver = !resume;

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, event, isStartOver));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, event, isStartOver)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const startOverSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const asset = Detail.VodSeries.selectors.selectAsset(state);
    if (!asset) return;
    const assetObject = Detail.VodSeries.selectors.selectAssetObjectById(asset.id)(state);

    const { seasonOrdinal } = assetObject;
    assetObject.attachedAssets = Detail.VodSeries.selectors.selectEpisodesBySeasonNumber(seasonOrdinal)(state);

    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const showMoreSelected =
    (title: string, actors: string[], directors: string[], description: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const asset = Detail.VodSeries.selectors.selectAsset(state);
        if (!asset) return;
        const assetObject = Detail.VodSeries.selectors.selectAssetObjectById(asset.id)(state);

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.OPEN_FULL_SYNOPSIS, assetObject));
        dispatch(Overlay.actions.mount(Overlay.Type.ShowMore, { title, actors, directors, description }));
    };

const folderItemSelected =
    (itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const itemSelector = Detail.VodSeries.selectors.selectAssetById(itemId);
        const item = itemSelector(state);
        const assetCanBeWatched = Detail.Root.selectors.selectAssetCanBeWatched(itemSelector)(state);

        if (!item || !assetCanBeWatched) return;

        const pinSessionType = item.metadata.sessionType;
        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, pinSessionType)(state);

        if (!isRestricted) {
            dispatch(toPlayerPage(itemId));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toPlayerPage(itemId)),
            () => dispatch(Overlay.actions.unmount())
        );
    };

const rentSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.VodSeries.selectors.selectAsset;
    const id = Detail.Root.selectors.selectAssetId(itemSelector)(state);
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);
    const vodUpsellOptions = selectIsVodUpsellOptions(state);

    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.RENT, { id, assetType }));
    dispatch(VodUpsell.actions.setUpsellOperation(ProductKind.Rental));
    vodUpsellOptions.rentIsAllowed ? dispatch(VodUpsell.actions.overlayRaised) : dispatch(VodUpsell.actions.notAllowedPromptRaised);
};

const buySelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.VodSeries.selectors.selectAsset;
    const id = Detail.Root.selectors.selectAssetId(itemSelector)(state);
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);
    const vodUpsellOptions = selectIsVodUpsellOptions(state);

    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BUY, { id, assetType }));
    dispatch(VodUpsell.actions.setUpsellOperation(ProductKind.Purchase));
    vodUpsellOptions.purchaseIsAllowed ? dispatch(VodUpsell.actions.overlayRaised) : dispatch(VodUpsell.actions.notAllowedPromptRaised);
};

const subscribeSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.VodSeries.selectors.selectAsset;
    const id = Detail.Root.selectors.selectAssetId(itemSelector)(state);
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);
    const vodUpsellOptions = selectIsVodUpsellOptions(state);

    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BUY, { id, assetType }));
    dispatch(VodUpsell.actions.setUpsellOperation(ProductKind.SubscriptionUpsellable));
    vodUpsellOptions.subscribeIsAllowed ? dispatch(VodUpsell.actions.overlayRaised) : dispatch(VodUpsell.actions.notAllowedPromptRaised);
};

const trailerSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.VodSeries.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetObject = Detail.VodSeries.selectors.selectAssetObjectById(asset.id)(state);

    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, UIActionEvents.PLAY_TRAILER, true, true));
        return;
    }

    openPinOverlay(() => {
        dispatch(startPlayback(asset, assetObject, UIActionEvents.PLAY_TRAILER, true, true));
    });
};

const mount: Screen.LifecycleMethod<Screen.Type.VodSeriesDetail> =
    (props): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));

        const state = getState();
        const config = selectConfig(state);

        const vodSeriesId = props.id;
        const profileId = Profile.selectors.selectId(state);

        const variables: VodSeriesDetailLoadQueryVariables = {
            ...Data.Detail.Api.vodSeries.DefaultVariables,
            profileId,
            vodSeriesId,
            backgroundWidth: config.image.background.landscape,
            backgroundHeight: config.image.background.width,
        };

        await dispatch(Detail.VodSeries.actions.load(variables));
        dispatch(Overlay.actions.unmount());
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    dispatch(Detail.VodSeries.actions.reset());
};

export const DetailVodSeriesActions = {
    public: {
        watchSelected,
        startOverSelected,
        showMoreSelected,
        folderItemSelected,
        rentSelected,
        buySelected,
        subscribeSelected,
        trailerSelected,
    },
    lifecycle: {
        mount,
        unmount,
        ...DefaultDetailScreenOutOfBounds,
    },
};
