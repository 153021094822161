import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AudioItem, SubtitleItem } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Types';

export enum OverlayActionType {
    Changed = '@overlay/changed',
}

export type OverlayReduxAction = OverlayChangedAction;
export type OverlayChangedActionPayload = { overlay: OverlayState };
export type OverlayChangedAction = PayloadedAction<OverlayActionType.Changed, OverlayChangedActionPayload>;

export enum OverlayType {
    SELECT = 'SELECT',
    MORE_INFO = 'MORE_INFO',
    PIN = 'PIN',
    StandBy = 'StandBy',
    VOD_UPSELL = 'VOD_UPSELL',
    Splash = 'Splash',
    Loading = 'Loading',
    CommunitySelect = 'CommunitySelect',
    ThemeSelect = 'ThemeSelect',
    AdultContentSelect = 'AdultContentSelect',
    StartUpSelect = 'StartUpSelect',
    MessageFilterSelect = 'MessageFilterSelect',
    LiveClockSelect = 'LiveClockSelect',
    ChannelListSelect = 'ChannelListSelect',
    SubtitlesSelect = 'SubtitlesSelect',
    AudioSelect = 'AudioSelect',
    ShowMore = 'ShowMore',
    SubtitleMenu = 'SubtitleMenu',
}

export type OverlayState = OverlayEntity | null;

export type OverlayProps<T extends OverlayType = OverlayType> = T extends keyof OverlayPropsMap
    ? OverlayPropsMap[T]
    : OverlayPropsMap[keyof OverlayPropsMap];

type OverlayPropsMap = {
    [OverlayType.SELECT]: SelectOverlayProps;
    [OverlayType.Loading]: LoadingOverlayProps;
    [OverlayType.Splash]: SplashOverlayProps;
    [OverlayType.MORE_INFO]: MoreInfoOverlayProps;
    [OverlayType.StandBy]: StandByOverlayProps;
    [OverlayType.PIN]: PinOverlayProps;
    [OverlayType.VOD_UPSELL]: VodUpsellOverlayProps;
    [OverlayType.CommunitySelect]: CommunitySelectOverlayProps;
    [OverlayType.ThemeSelect]: ThemeSelectOverlayProps;
    [OverlayType.AdultContentSelect]: AdultContentSelectOverlayProps;
    [OverlayType.StartUpSelect]: StartUpSelectOverlayProps;
    [OverlayType.MessageFilterSelect]: MessageFilterSelectOverlayProps;
    [OverlayType.LiveClockSelect]: LiveClockSelectOverlayProps;
    [OverlayType.ChannelListSelect]: ChannelListSelectOverlayProps;
    [OverlayType.SubtitlesSelect]: SubtitlesSelectOverlayProps;
    [OverlayType.AudioSelect]: AudioSelectOverlayProps;
    [OverlayType.ShowMore]: ShowMoreOverlayProps;
    [OverlayType.SubtitleMenu]: SubtitleMenuOverlayProps;
};

export type OverlayEntity<T extends OverlayType = OverlayType> = {
    type: T;
    ctx: string;
    props: OverlayProps<T>;
    //
    noFocus?: boolean;
};

// Select Overlay

/**
 *
 * !!!IMPORTANT!!!
 *
 * DO NOT ADD SELECT OVERLAY TARGETS
 *
 * CREATE DIFFERENT OVERLAY TYPES INSTEAD
 *
 * EXAMPLE:
 *  - Overlay.Type.CommunitySelect
 *  - Overlay.Type.ThemeSelect
 *  - Overlay.Type.AdultContentSelect
 *  - Overlay.Type.StartUpSelect
 *  - Overlay.Type.MessageFilterSelect
 *  - Overlay.Type.LiveClockSelect
 *  - Overlay.Type.ChannelListSelect
 *
 * Implementaions can be found in src/App/Modules/Overlay/Modules/**
 *
 */

export enum SelectOverlayTarget {
    RECORDING_FILTER = 'RECORDING_FILTER',
    RECORDING_SORT = 'RECORDING_SORT',
    DATE_PICKER = 'DATE_PICKER',
    LANGUAGE = 'LANGUAGE',
    AUDIO_PRIMARY = 'AUDIO_PRIMARY',
    AUDIO_SECONDARY = 'AUDIO_SECONDARY',
    SUBTITLE_PRIMARY = 'SUBTITLE_PRIMARY',
    SUBTITLE_SECONDARY = 'SUBTITLE_SECONDARY',
}

type SelectOverlayProps = {
    target: SelectOverlayTarget;
};

// Loading Overlay
type LoadingOverlayProps = {
    backgroundColor: BackgroundColor;
    text?: string;
};

// Splash Overlay
type SplashOverlayProps = {};

// Pin Overlay
type PinOverlayProps = {};

// MoreInfo Overlay
type MoreInfoOverlayProps = {
    title: string;
    text: string;
};

// StandBy Overlay
type StandByOverlayProps = {};

// VodUpsell Overlay
type VodUpsellOverlayProps = {};

// Community Select Overlay
type CommunitySelectOverlayProps = {};
type ThemeSelectOverlayProps = {};
type AdultContentSelectOverlayProps = {};
type StartUpSelectOverlayProps = {};
type MessageFilterSelectOverlayProps = {};
type LiveClockSelectOverlayProps = {};
type ChannelListSelectOverlayProps = {};
type SubtitleMenuOverlayProps = {
    channelId: string;
    playerOptions: any;
    currentSubtitle: SubtitleItem;
    currentAudioTrack: AudioItem;
    fontSize: string;
    previewTextFontSize: string;
    fontColor: string;
    previewTextFontColor: string;
    backgroundColor: string;
    previewTextBackgroundColor: string;
    edgeColor: string;
    edgeType: string;
    playerSetEdgeType: (value: string) => void;
    playerSetFontSize: (value: string) => void;
    playerSetFontColor: (value: string) => void;
    playerSetBackgroundColor: (value: string) => void;
    playerSetSubtitleStream: (option: SubtitleItem) => void;
    playerSetAudioStream: (option: AudioItem) => void;
    onClose: () => void;
};
type SubtitlesSelectOverlayProps = {
    subtitles: SubtitleItem[];
    currentSubtitle: SubtitleItem;
    onClose?: () => void;
    onSubtitleSelect: (option: SubtitleItem) => Thunk<void>;
    subtitleMenuProps: SubtitleMenuOverlayProps;
};
type AudioSelectOverlayProps = {
    audios: AudioItem[];
    currentAudioTrack: AudioItem;
    onClose?: () => void;
    onAudioSelect: (option: AudioItem) => Thunk<void>;
    subtitleMenuProps: SubtitleMenuOverlayProps;
};
type ShowMoreOverlayProps = { title: string; description: string; actors: string[]; directors: string[] };
