import { Delay } from 'App/Packages/Focus/Constants';
import { Function } from 'App/Packages/Function';

export type Subscribe<T> = (subscriber: Subscriber<T>) => Unsubscribe;

export type Unsubscribe = () => void;

export type Subscriber<T> = (event: T) => void;

export class Engine<T> {
    private readonly subscribers: Subscriber<T>[] = [];

    private isEmitting = false;

    public subscribe(subscriber: Subscriber<T>): Unsubscribe {
        this.subscribers.push(subscriber);
        return () => {
            if (!this.subscribers.includes(subscriber)) return;
            this.subscribers.splice(this.subscribers.indexOf(subscriber), 1);
        };
    }

    public async emit(event: T): Promise<void> {
        if (this.isEmitting) return;
        this.isEmitting = true;
        const subscribers = this.subscribers.slice();
        await Function.sleep(Delay.Keyboard);
        subscribers.forEach((subscriber) => subscriber(event));
        this.isEmitting = false;
    }
}
