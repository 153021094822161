import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PauseLiveChannelMutationVariables = Types.Exact<{
  input: Types.PauseLiveChannelInput;
}>;


export type PauseLiveChannelMutation = { __typename?: 'Nexx4Mutations', pauseLiveChannel: { __typename?: 'PauseLiveChannelPayload', playbackInfo: { __typename?: 'TimeshiftPlaybackInfo', sessionId?: string | null, url: string, deliveryKind: Types.ContentDeliveryKind, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, adPlaybackPreRoll?: number | null, playbackRestrictions?: Array<{ __typename?: 'EventConcurrencyPlaybackRestriction', message: string } | { __typename?: 'JailbrokenPlaybackRestriction' } | { __typename?: 'OutputPlaybackRestriction' } | { __typename?: 'VideoQualityPlaybackRestriction' } | null> | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const PauseLiveChannelDocument = gql`
    mutation pauseLiveChannel($input: PauseLiveChannelInput!) {
  pauseLiveChannel(input: $input) {
    playbackInfo {
      sessionId
      url
      playbackRestrictions {
        ... on EventConcurrencyPlaybackRestriction {
          message
        }
      }
      deliveryKind
      adPlaybackRestrictions
      adPlaybackPreRoll
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type PauseLiveChannelMutationFn = Apollo.MutationFunction<PauseLiveChannelMutation, PauseLiveChannelMutationVariables>;

/**
 * __usePauseLiveChannelMutation__
 *
 * To run a mutation, you first call `usePauseLiveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseLiveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseLiveChannelMutation, { data, loading, error }] = usePauseLiveChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseLiveChannelMutation(baseOptions?: Apollo.MutationHookOptions<PauseLiveChannelMutation, PauseLiveChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseLiveChannelMutation, PauseLiveChannelMutationVariables>(PauseLiveChannelDocument, options);
      }
export type PauseLiveChannelMutationHookResult = ReturnType<typeof usePauseLiveChannelMutation>;
export type PauseLiveChannelMutationResult = Apollo.MutationResult<PauseLiveChannelMutation>;
export type PauseLiveChannelMutationOptions = Apollo.BaseMutationOptions<PauseLiveChannelMutation, PauseLiveChannelMutationVariables>;