import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ImageFragment = { __typename: 'Image', id: string, url?: string | null };

export const ImageFragmentDoc = gql`
    fragment image on Image {
  id
  url
  __typename
}
    `;