import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { ReactComponent as ImageAdultLandscapeSvg } from 'App/Packages/UI/Image/Assets/LandscapeAdult.svg';
import { ReactComponent as PortraitAdultSvg } from 'App/Packages/UI/Image/Assets/PortraitAdult.svg';
import { ImageClassNameProps } from 'App/Packages/UI/Image/Utils';

export const enum Orientation {
    Landscape = 'Landscape',
    Portrait = 'Portrait',
}

const getImageAdultClassName = (theme: Theme.Type, props: ImageClassNameProps) => {
    const classList = [];
    const base = 'image-v3__adult';
    classList.push(base);
    classList.push(Theme.getClassName(base, theme));
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type ImageAdultLandscapeProps = ImageClassNameProps & { hidden?: boolean };

export type ImageAdultLandscapeModule = {
    Svg: typeof ImageAdultLandscapeSvg;
} & React.FunctionComponent<ImageAdultLandscapeProps>;

const ImageAdultLandscape: ImageAdultLandscapeModule = (props) => {
    const theme = useTheme();
    if (props.hidden) return null;
    return <ImageAdultLandscapeSvg className={getImageAdultClassName(theme, props)} />;
};

ImageAdultLandscape.Svg = ImageAdultLandscapeSvg;

export type ImageAdultPortraitProps = ImageClassNameProps & { hidden?: boolean };

export type ImageAdultPortraitModule = {
    Svg: typeof PortraitAdultSvg;
} & React.FunctionComponent<ImageAdultPortraitProps>;

const ImageAdultPortrait: ImageAdultPortraitModule = (props) => {
    const theme = useTheme();
    if (props.hidden) return null;
    return <PortraitAdultSvg className={getImageAdultClassName(theme, props)} />;
};

ImageAdultPortrait.Svg = PortraitAdultSvg;

export type ImageAdultProps = ImageClassNameProps & { orientation?: Orientation; hidden?: boolean };

export type ImageAdultModule = {
    Landscape: ImageAdultLandscapeModule;
    Portrait: ImageAdultPortraitModule;
} & React.FunctionComponent<ImageAdultProps>;

export const ImageAdult: ImageAdultModule = (props) => {
    if (props.hidden) return null;
    if (props.orientation === Orientation.Portrait) return <ImageAdultPortrait className={props.className} />;
    return <ImageAdultLandscape className={props.className} />;
};

ImageAdult.Landscape = ImageAdultLandscape;
ImageAdult.Portrait = ImageAdultPortrait;
