import React from 'react';

import { useShouldMask } from 'App/Packages/UI/Mask/Hooks';
import { MaskContext, MaskContextState, MaskProvider, MaskProviderModule, MaskProviderProps } from 'App/Packages/UI/Mask/MaskContext';
import { MaskDescription, MaskDescriptionModule, MaskDescriptionProps } from 'App/Packages/UI/Mask/MaskDescription';
import { MaskText, MaskTextModule, MaskTextProps } from 'App/Packages/UI/Mask/MaskText';
import { MaskTitle, MaskTitleModule, MaskTitleProps } from 'App/Packages/UI/Mask/MaskTitle';

export type { MaskContextState, MaskProviderProps, MaskProviderModule };
export type { MaskTextProps, MaskTextModule };
export type { MaskTitleModule, MaskTitleProps };
export type { MaskDescriptionModule, MaskDescriptionProps };

export type MaskProps =
    | {
          children: (shouldMask: boolean) => React.ReactNode;
      }
    | {
          children: React.ReactNode;
          masked: React.ReactNode;
      };

export type MaskModule = {
    Provider: MaskProviderModule;
    Text: MaskTextModule;
    Title: MaskTitleModule;
    Description: MaskDescriptionModule;
    useShouldMask: typeof useShouldMask;
    __Context: typeof MaskContext;
} & React.FunctionComponent<MaskProps>;

export const Mask: MaskModule = (props) => {
    const shouldMask = useShouldMask();
    const children = 'masked' in props ? (shouldMask ? props.masked : props.children) : props.children(shouldMask);
    return <>{children}</>;
};

Mask.Provider = MaskProvider;
Mask.Text = MaskText;
Mask.Title = MaskTitle;
Mask.Description = MaskDescription;
Mask.useShouldMask = useShouldMask;
Mask.__Context = MaskContext;
