import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Registry } from 'App/Modules/Overlay/Registry';
import { actions } from 'App/Modules/Overlay/Store/actions';
import { selectors } from 'App/Modules/Overlay/Store/selectors';
import { Focus } from 'App/Packages/Focus';

export const View: React.FunctionComponent = () => {
    const overlay = useSelector(selectors.public.select);
    if (!overlay) return null;
    const [Component, lifecycle] = Registry.get(overlay.type);
    return (
        <Focus.Provider
            ctx={selectors.public.selectCtx}
            setCtx={actions.public.ctx.set}
            isActive={selectors.public.selectIsActive}
            outOfBounds={lifecycle.outOfBounds}
        >
            <Component {...overlay.props} />
        </Focus.Provider>
    );
};
