import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

type Props = {};

export type ChannelCardNumberBaseModule = React.FunctionComponent<React.PropsWithChildren<Props>>;

export const ChannelCardNumberBase: ChannelCardNumberBaseModule = (props) => {
    const { isFocused } = useContext(ChannelCardContext);

    return (
        <Text
            className="channel-card-v2__number"
            typeface={Typeface.SANS}
            size={FontSize.CAPTION_1}
            weight={FontWeight.BOLD}
            color={isFocused ? Color.SECONDARY : Color.PRIMARY}
        >
            {props.children}
        </Text>
    );
};
