import {
    NotificationType,
    NotificationShowAction,
    NotificationVisibilityStatus,
    UINotificationEntity,
} from '__SMART_APP_OLD__/app/modules/Notification/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';

export const notificationShow = (notification: UINotificationEntity): NotificationShowAction => ({
    type: ActionType.NOTIFICATION_SHOW,
    payload: { notification },
});

export const textNotificationShow = (title: string, timer: number = 5): NotificationShowAction =>
    notificationShow({ id: title, type: NotificationType.BASIC, status: NotificationVisibilityStatus.ANIMATE_IN, timer, title });
