export const enum IconFlavour {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    SOLID_LIGHT = 'SOLID_LIGHT',
    SOLID_DARK = 'SOLID_DARK',
}

export const enum IconType {
    SOLID = 'SOLID',
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
}

export enum IconName {
    OK = 'OK',
    SETTINGS = 'SETTINGS',
    SEARCH = 'SEARCH',
    OLD_SEARCH = 'OLD_SEARCH',
    TIMER = 'TIMER',
    CHAT = 'CHAT',
    CHECKBOX = 'CHECKBOX',
    LG_TV = 'LG_TV',
    SAMSUNG_TV = 'SAMSUNG_TV',
    FIRE_TV = 'FIRE_TV',
    HISENSE_TV = 'HISENSE_TV',
    APPLE_PHONE = 'APPLE_PHONE',
    HUAWEI_PHONE = 'HUAWEI_PHONE',
    ANDROID_PHONE = 'ANDROID_PHONE',
    APPLE_TABLET = 'APPLE_TABLET',
    HUAWEI_TABLET = 'HUAWEI_TABLET',
    ANDROID_TABLET = 'ANDROID_TABLET',
    STB = 'STB',
    DEVICE_MANAGEMENT = 'DEVICE_MANAGEMENT',
    WEB = 'WEB',
    FOLDER = 'FOLDER',
    LOADING = 'LOADING',
    INBOX = 'INBOX',
    MESSAGING = 'MESSAGING',
    CLOSE = 'CLOSE',
    COGWHEEL = 'COGWHEEL',
    DOWNLOAD = 'DOWNLOAD',
    ADD = 'ADD',
    NEW_ADD = 'NEW_ADD',
    BOOKMARK_SELECTED = 'BOOKMARK_SELECTED',
    BOOKMARK = 'BOOKMARK',
    BOOKMARK_ACTIVE = 'BOOKMARK_ACTIVE',
    LOCK_OPEN = 'LOCK_OPEN',
    LOCK_CLOSED = 'LOCK_CLOSED',
    ARRANGE = 'ARRANGE',
    SORT = 'SORT',
    SORT_AZ = 'SORT_AZ',
    RESIZE = 'RESIZE',
    RIGHT = 'RIGHT',
    UP = 'UP',
    LEFT = 'LEFT',
    DOWN = 'DOWN',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    FAVORITE = 'FAVORITE',
    FAVORITE_ACTIVE = 'FAVORITE_ACTIVE',
    RECORD = 'RECORD',
    CANCEL_RECORD = 'CANCEL_RECORD',
    BUY = 'BUY',
    VOICE_INPUT = 'VOICE_INPUT',
    VISIBLE = 'VISIBLE',
    HIDDEN = 'HIDDEN',
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    ERROR = 'ERROR',
    INFO = 'INFO',
    START_OVER = 'START_OVER',
    IMAGE = 'IMAGE',
    MENU_HORIZONTAL = 'MENU_HORIZONTAL',
    MENU_VERTICAL = 'MENU_VERTICAL',
    CATCHUP = 'CATCHUP',
    REMINDER = 'REMINDER',
    DOLBY = 'DOLBY',
    SD = 'SD',
    HD = 'HD',
    UHD = 'UHD',
    FORWARD = 'FORWARD',
    REWIND = 'REWIND',
    LIVE = 'LIVE',
    AUDIO_SUBTITLES = 'AUDIO_SUBTITLES',
    DIRECTOR = 'DIRECTOR',
    PEOPLE = 'PEOPLE',
    CHANNEL_LIST = 'CHANNEL_LIST',
    FAVORITE_OLD = 'FAVORITE_OLD',
    FAVORITE_EMPTY_OLD = 'FAVORITE_EMPTY_OLD',
}
