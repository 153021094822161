import Elements from '__SMART_APP_OLD__/navigation/Elements';

class FocusUtils {
    elementFromPoint(event) {
        const { clientX, clientY } = event;

        if (!clientX || !clientY) {
            return null;
        }

        return document.elementFromPoint(clientX, clientY);
    }

    filterNodes(elements, focused) {
        const focusables = [];
        elements.forEach((element) => {
            if (!element.isContainer && element !== focused) {
                focusables.push(element);
            }
        });

        return focusables;
    }

    filterNodesInContainer(elements, focused, container) {
        const focusables = [];

        elements.forEach((element) => {
            const elementContainer = this.findParentContainer(element);
            if (!element.isContainer && element !== focused && elementContainer && elementContainer.nodeRef === container.nodeRef) {
                focusables.push(element);
            }
        });

        return focusables;
    }

    filterContainersInContainer(elements, currentContainer) {
        const containers = [];
        const parentOfCurrentContainer = this.findParentContainer(currentContainer);
        if (parentOfCurrentContainer) {
            elements.forEach((element) => {
                const parentContainer = this.findParentContainer(element);
                if (
                    element.isContainer &&
                    element !== currentContainer &&
                    parentContainer &&
                    parentContainer.nodeRef === parentOfCurrentContainer.nodeRef
                ) {
                    containers.push(element);
                }
            });
        }
        return containers;
    }

    filterContainersInСurrentContainer(elements, currentContainer) {
        let subContainers = [];
        let containers = [];
        if (currentContainer) {
            elements.forEach((element) => {
                const parentContainer = this.findParentContainer(element);
                if (
                    element.isContainer &&
                    element !== currentContainer &&
                    parentContainer &&
                    parentContainer.nodeRef === currentContainer.nodeRef
                ) {
                    containers.push(element);
                    subContainers = this.filterContainersInСurrentContainer(elements, element);
                    containers = [...containers, ...subContainers];
                }
            });
        }
        return containers;
    }

    distance(p1, p2) {
        const c1x = p1.left + p1.width / 2;
        const c1y = p1.top + p1.height / 2;
        const c2x = p2.left + p2.width / 2;
        const c2y = p2.top + p2.height / 2;
        return {
            left: p1.left,
            right: p1.right,
            top: p1.top,
            bottom: p1.bottom,

            distance: Math.sqrt((c2x - c1x) ** 2 + (c2y - c1y) ** 2),
        };
    }

    /**
     * Returns the container node for the given node if exists
     * @param node Reference to the DOM node
     * @returns Parent of the node if exists
     */
    findParentContainer(node) {
        if (!node || !node.nodeRef) {
            return null;
        }
        let parent = node.nodeRef.parentNode;
        while (parent && parent !== document.body) {
            if (parent.getAttribute('container') === 'true') {
                break;
            }
            parent = parent.parentNode;
        }

        return Elements.findContainerByRef(parent);
    }
}

export default new FocusUtils();
