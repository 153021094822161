import React from 'react';

import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { AlertActionsChildren, AlertActionsChildrenModule } from 'App/Packages/UI/Alert/AlertActionsChildren';
import { AlertActionsContainer, AlertActionsContainerModule } from 'App/Packages/UI/Alert/AlertActionsContainer';
import { AlertActionsContext, AlertActionsContextModule, useAlertActionsContext } from 'App/Packages/UI/Alert/AlertActionsContext';
import { Axis } from 'App/Types/Axis';

export type AlertActionsProps = {
    ids: string[];
    onEnter?: (id: string) => void;
    children: React.ReactNode;
};

export type AlertActionsModule = {
    Container: AlertActionsContainerModule;
    Context: AlertActionsContextModule;
    Children: AlertActionsChildrenModule;
    useContext: typeof useAlertActionsContext;
} & React.FunctionComponent<AlertActionsProps>;

export const AlertActions: AlertActionsModule = (props) => {
    const { focused, isDisabled, focus } = useListHook({ axis: Axis.X, ctx: FocusDefault, items: props.ids });

    return (
        <AlertActionsContainer>
            <AlertActionsContext.Provider ids={props.ids} focused={focused} disabled={isDisabled} onHover={focus} onEnter={props.onEnter}>
                {props.children}
            </AlertActionsContext.Provider>
        </AlertActionsContainer>
    );
};

AlertActions.Container = AlertActionsContainer;
AlertActions.Context = AlertActionsContext;
AlertActions.Children = AlertActionsChildren;
AlertActions.useContext = useAlertActionsContext;
