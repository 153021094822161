import React from 'react';
import { shallowEqual } from 'react-redux';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectTVGuideContentMarker,
    selectTVGuideIcons,
    selectTVGuideMetaInfo,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';

export const TVGuideMetaInfo: React.FunctionComponent = () => {
    const contentMarker = useSelector(selectTVGuideContentMarker);
    const icons = useSelector(selectTVGuideIcons, shallowEqual);
    const metainfo = useSelector(selectTVGuideMetaInfo);
    return (
        <div className="tv-guide__meta-info">
            <ContentMarker className="tv-guide__content-marker" marker={contentMarker} />
            {icons.map((icon) => (
                <Icon key={icon} className="tv-guide__icon" name={icon} type={IconType.SOLID} />
            ))}
            <Text
                className="tv-guide__meta-info-text"
                typeface={Typeface.SANS}
                size={FontSize.BODY_2}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                {metainfo}
            </Text>
        </div>
    );
};
