import { selectChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectEPGFutureLimit, selectEPGPastLimit } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import {
    EventInitAction,
    EventInitActionPayload,
    EventLoadManyAction,
    EventLoadManyActionPayload,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

export const eventLoadMany = (payload: EventLoadManyActionPayload): EventLoadManyAction => ({
    type: ActionType.EVENT_LOAD_MANY,
    payload,
});

export const eventInit = (payload: EventInitActionPayload): EventInitAction => ({
    type: ActionType.EVENT_INIT,
    payload,
});

export const eventInitialize = (): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const channels = selectChannelIds(state);
    const start = selectEPGPastLimit(state);
    const end = selectEPGFutureLimit(state);
    dispatch(eventInit({ channels, end, start }));
};
