import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

export const MasterPinCodeScreenView: React.FunctionComponent<Screen.Props<Screen.Type.MASTER_PIN_CODE>> = () => {
    const dispatch = useDispatch();
    Focus.useBack(() => dispatch(Fti.actions.back()));
    return null;
};
