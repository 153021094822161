import { BookmarksEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';

const toEntities = (bookmarks: BookmarksEntity[]): Record<string, BookmarksEntity> =>
    bookmarks.reduce<Record<string, BookmarksEntity>>((acc, bookmark) => {
        acc[bookmark.id] = bookmark;
        return acc;
    }, {});

const toIds = (entities: BookmarksEntity[]): string[] => entities.map((entity) => entity.id);

export const bookmarksMapper = { toEntities, toIds };
