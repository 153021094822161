export enum UIElementTypes {
    AD = 'ad',
    ARTICLE = 'article',
    ACTION = 'action',
    APP = 'app',
    BUTTON = 'button',
    CAST = 'cast',
    CHANNEL = 'channel',
    CHANNEL_PACKAGE = 'channelpackage',
    CONCURRENT_STREAM = 'concurrentstream',
    DEEPLINK = 'deeplink',
    EVENT = 'event',
    INPUT = 'input',
    INTERACTIVE_FEATURE = 'interactivefeature',
    MESSAGE = 'message',
    ONDEMAND_PACKAGE = 'ondemandpackage',
    ONDEMAND_VIDEO = 'ondemandvideo',
    OPTION = 'option',
    PROFILE = 'profile',
    PROMOTION = 'promotion',
    RECORDING = 'recording',
    SYNOPSIS = 'synopsis',
    VIDEO = 'video',
}

export interface UIElementParams {
    type: UIElementTypes;
    title?: string;
}

export class UIElement {
    private readonly type: UIElementTypes;

    private title?: string;

    constructor({ title, type }: UIElementParams) {
        this.type = type;
        if (title) this.title = title;
        else delete this.title;
    }
}
