import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { MetadataFragmentDoc } from '../../fragments/metadata.generated';
import { ImageFragmentFragmentDoc, ParentalRatingFragmentFragmentDoc, TrailersFragmentFragmentDoc, VodAssetEntitlementCatalogFragmentFragmentDoc, VodAssetEntitlementFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc, CacheInfoFragmentFragmentDoc, PageInfoFragmentFragmentDoc, ChannelLogosFragmentFragmentDoc, PersonalChannelInfoFragmentFragmentDoc, ChannelEntitlementsFragmentFragmentDoc, EventEntitlementsFragmentFragmentDoc, PersonalEventInfoFragmentFragmentDoc, MetadataFragmentFragmentDoc, PersonalRecordingInfoFragmentFragmentDoc, GenreFragmentFragmentDoc, VodProductFrogmentFragmentDoc, PersonalVodInfoFragmentFragmentDoc, PriceFragmentFragmentDoc } from '../../../../api/graphql/query/fragments.generated';
import { ParentalRatingFragmentDoc } from '../../parentalRating/fragments/parentalRating.generated';
export type RecordingDetailedFragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } };

export const RecordingDetailedFragmentDoc = gql`
    fragment recordingDetailed on NetworkRecording {
  id
  status
  size
  start
  end
  availableUntil
  deleteProtected
  personalInfo {
    favourited
    partOfSeriesRecording
  }
  channel {
    id
    beforeTime
    afterTime
  }
  event {
    id
    title
    metadata {
      ...metadata
    }
    backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
      ...imageFragment
    }
    parentalRating {
      ...parentalRating
    }
  }
  __typename
}
    ${MetadataFragmentDoc}
${ImageFragmentFragmentDoc}
${ParentalRatingFragmentDoc}`;