import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodAssetCollectionItemEntitlementsItemsFragmentDoc } from './vodAssetCollectionItemEntitlementsItems.generated';
export type VodAssetCollectionItemEntitlementsFragment = { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null } | null> };

export const VodAssetCollectionItemEntitlementsFragmentDoc = gql`
    fragment vodAssetCollectionItemEntitlements on VODAssetEntitlementCatalog {
  itemCount
  items {
    ...vodAssetCollectionItemEntitlementsItems
  }
}
    ${VodAssetCollectionItemEntitlementsItemsFragmentDoc}`;