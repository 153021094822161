import React from 'react';

import { Button, ButtonAnimation, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { useSelectOnClose } from 'App/Packages/UI/Select/SelectContext';
import { SelectOptionEmpty, SelectOptionEmptyModule } from 'App/Packages/UI/Select/SelectOptionEmpty';
import { SelectOptions } from 'App/Packages/UI/Select/SelectOptions';

export type SelectOptionProps = {
    id: string;
    onEnter?: (id: string) => void;
    children: React.ReactNode;
    disableDismiss?: boolean;
};

export type SelectOptionModule = {
    Empty: SelectOptionEmptyModule;
} & React.FunctionComponent<SelectOptionProps>;

export const SelectOption: SelectOptionModule = (props) => {
    const onClose = useSelectOnClose();
    const ctx = SelectOptions.useContext();

    if (!onClose) throw new Error('Select.Option must be used inside Select');
    if (!ctx) throw new Error('Select.Option must be used inside Select.Options');

    // Conditional rendering with defined ids
    if (!ctx.ids.includes(props.id)) return null;

    const onEnter = (id: string) => {
        if (props.onEnter) props.onEnter(id);
        else if (ctx.onEnter) ctx.onEnter(id);
        if (props.disableDismiss) return;
        onClose.current();
    };

    return (
        <Button
            key={props.id}
            id={props.id}
            ctx={FocusDefault}
            className="select__option"
            isFocused={props.id === ctx.focused}
            onEnter={onEnter}
            onHover={ctx.onHover}
            animation={ButtonAnimation.NONE}
            width={ButtonWidth.FULL}
            corners={ButtonCornersStyle.ROUNDED}
            icon={ctx.selected === props.id ? IconName.OK : undefined}
        >
            {props.children}
        </Button>
    );
};

SelectOption.displayName = 'Select.Option';
SelectOption.Empty = SelectOptionEmpty;
