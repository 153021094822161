import { ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';

export const THEME_STORAGE_KEY = 'theme';

export const initialThemeState: ThemeType = ThemeType.Light;

export const themeOptionIds = [ThemeType.Light, ThemeType.Dark];

export const themeOptionLabelForType: Record<ThemeType, string> = {
    [ThemeType.Dark]: 'OPTION_DARK_THEME',
    [ThemeType.Light]: 'OPTION_LIGHT_THEME',
};

export const themeOptionIndexForType: Record<ThemeType, number> = {
    [ThemeType.Light]: 0,
    [ThemeType.Dark]: 1,
};

export const classNameForThemeType: Record<ThemeType, string> = {
    [ThemeType.Dark]: 'dark-theme',
    [ThemeType.Light]: 'light-theme',
};

export const classNameModifierForThemeType: Record<ThemeType, string> = {
    [ThemeType.Dark]: '--dark-theme',
    [ThemeType.Light]: '--light-theme',
};

export const themeSettingsOptions = themeOptionIds.map((id) => ({ id, label: themeOptionLabelForType[id] }));
