import React from 'react';

import { ProductKind } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { vodUpsellSelectors } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/selectors';
import { VodUpsellCTX } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { VodUpsellOptions } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/VodUpsellOptions';
import { store } from '__SMART_APP_OLD__/app/store/store';
import translate from 'language/translate';

import { Overlay } from 'App/Modules/Overlay';
import { useBack as useBackHook } from 'App/Packages/Focus/Hooks/useBack';

export const VodUpsellView: React.FunctionComponent<Overlay.Props<Overlay.Type.VOD_UPSELL>> = () => {
    const dispatch = useDispatch();
    const operationType = vodUpsellSelectors.public.selectOptionTypeSelected(store.getState());
    const assetTitle = useSelector(vodUpsellSelectors.public.selectCurrentAssetTitle);
    const episodeInfo = useSelector(vodUpsellSelectors.public.selectCurrentAssetEpisodeInfo);
    const options = useSelector(vodUpsellSelectors.public.selectOptions);
    const packageOptions = useSelector(vodUpsellSelectors.public.selectPackageOptions);

    useBackHook(() => dispatch(Overlay.actions.unmount()));

    const getOverlayTitle = (isPackageProduct: boolean = false, isEpisode: boolean = false) => {
        switch (operationType) {
            case ProductKind.Purchase:
                if (isPackageProduct) {
                    return translate('VOD_UPSELL_BUY_SERIES_TITLE');
                }

                if (isEpisode) {
                    return translate('VOD_UPSELL_BUY_EPISODE_TITLE');
                }

                return 'VOD_UPSELL_BUY_TITLE';
            case ProductKind.Rental:
                return 'VOD_UPSELL_RENT_TITLE';
            case ProductKind.SubscriptionUpsellable:
                return 'VOD_UPSELL_SUBSCRIBE_TITLE';
            default:
                return '';
        }
    };

    const getOverlayDescription = (isPackageProduct: boolean = false) => {
        switch (operationType) {
            case ProductKind.Purchase:
                if (isPackageProduct) {
                    return `${translate('VOD_UPSELL_BUY_SERIES_DESCRIPTION')} S${episodeInfo?.season} ${assetTitle}`;
                }

                if (episodeInfo) {
                    return translate('VOD_UPSELL_BUY_EPISODE_DESCRIPTION')
                        .replace('%%SE%%', `S${episodeInfo.season} E${episodeInfo.number}`)
                        .replace('%%TITLE%%', assetTitle);
                }
                return `${translate('VOD_UPSELL_BUY_DESCRIPTION')} ${assetTitle}`;
            case ProductKind.Rental:
                return assetTitle;
            case ProductKind.SubscriptionUpsellable:
                return `${translate('VOD_UPSELL_SUBSCRIBE_DESCRIPTION')}`;
            default:
                return '';
        }
    };

    return (
        <Layout className="vod-upsell-overlay" backgroundColor={BackgroundColor.OVERLAY} zIndex={Overlay.zIndex}>
            {packageOptions.length && (
                <>
                    <div className="vod-upsell-overlay--options-container">
                        <Text
                            className="vod-upsell-overlay__title"
                            typeface={Typeface.SERIF}
                            size={FontSize.BODY_1}
                            weight={FontWeight.BOLD}
                            color={Color.PRIMARY}
                        >
                            {getOverlayTitle(true)}
                        </Text>
                        <Text size={FontSize.CAPTION_1} typeface={Typeface.SANS} weight={FontWeight.REGULAR} color={Color.SECONDARY}>
                            {getOverlayDescription(true)}
                        </Text>
                        <VodUpsellOptions options={packageOptions} ctx={VodUpsellCTX.LEFT_COLUMN} />
                    </div>
                    <div className="vod-upsell-overlay__line"></div>
                </>
            )}

            <div className="vod-upsell-overlay--options-container">
                <Text
                    className="vod-upsell-overlay__title"
                    typeface={Typeface.SERIF}
                    size={FontSize.BODY_1}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {getOverlayTitle(false, !!episodeInfo)}
                </Text>
                <Text size={FontSize.CAPTION_1} typeface={Typeface.SANS} weight={FontWeight.REGULAR} color={Color.SECONDARY}>
                    {getOverlayDescription()}
                </Text>
                <VodUpsellOptions options={options} ctx={packageOptions.length ? VodUpsellCTX.RIGHT_COLUMN : VodUpsellCTX.ONE_COLUMN} />
            </div>
        </Layout>
    );
};
