import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Api } from 'App/Modules/Data/Detail/Root/Api';
import {
    EventDetailLoadDocument,
    EventDetailLoadFragment,
    EventDetailLoadQueryResult,
    EventDetailLoadQueryVariables,
} from './eventDetailLoad.generated';

export { DefaultVariables } from 'App/Modules/Data/Detail/Event/Api/defaultVariables';

type LoadEvent = (variables: EventDetailLoadQueryVariables) => Promise<EventDetailLoadFragment | null>;

const __parseLoadEvent = (response: EventDetailLoadQueryResult) => response?.data || null;

export const loadEvent: LoadEvent = async (variables) => {
    const response: EventDetailLoadQueryResult = await GraphqlClient.makeGraphqlRequest(
        EventDetailLoadDocument,
        variables,
        Api.detailQueryOptions
    );
    Error.collect(response);
    return __parseLoadEvent(response);
};
