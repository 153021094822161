import { useCallback, useEffect, useRef } from 'react';
import { sessionHeartBeat } from '__SMART_APP_OLD__/api/Services';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { KeepSessionAliveDocument } from '__SMART_APP_OLD__/api/graphql/mutation/keepAlive.generated';
import * as workerTimers from 'worker-timers';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectIsLoggedIn,
    selectIsLoginWithCode,
    selectLegacyCallbacksInterval,
} from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';

export const useZappwareCallbacks = () => {
    const userIsLogged = useSelector(selectIsLoggedIn);
    const isIdpLogin = useSelector(selectIsLoginWithCode);
    const interval = useSelector(selectLegacyCallbacksInterval);
    const intervalId = useRef<number | null>(null);
    const sessionRequests = useCallback(async () => {
        try {
            await sessionHeartBeat();
            await GraphqlClient.makeGraphqlMutationRequest(KeepSessionAliveDocument, {});
            console.log('[Session] refreshed legacy session callbacks');
        } catch (error) {
            console.log('[Session] failed to refresh legacy session callbacks');
        }
    }, []);

    useEffect(() => {
        if (!userIsLogged || isIdpLogin) return undefined;
        intervalId.current = workerTimers.setInterval(sessionRequests, interval);
        return () => {
            if (!intervalId.current) return undefined;
            return workerTimers.clearInterval(intervalId.current);
        };
    }, [sessionRequests, interval, isIdpLogin, userIsLogged]);
};
