import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
import { SeriesInfoFragmentDoc } from './seriesInfo.generated';
import { EpisodeInfoFragmentDoc } from './episodeInfo.generated';
import { GenreFragmentDoc } from './genre.generated';
export type MetadataFragment = { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null };

export const MetadataFragmentDoc = gql`
    fragment metadata on Metadata {
  id
  title
  originalTitle
  shortDescription
  fullDescription
  actors
  directors
  country
  year
  audioLanguages
  subtitleLanguages
  seriesInfo {
    ...seriesInfo
  }
  episodeInfo {
    ...episodeInfo
  }
  genre {
    ...genre
  }
  __typename
}
    ${SeriesInfoFragmentDoc}
${EpisodeInfoFragmentDoc}
${GenreFragmentDoc}`;