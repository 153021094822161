import throttle from 'lodash.throttle';
import React from 'react';

import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';

export default class VerticalContainer extends React.Component {
    scroll = throttle((focusedNode) => {
        const focusedDom = focusedNode.nodeRef;
        const { topOffset = 0 } = this.props;
        const container = this.containerRef?.nodeRef?.nodeRef;

        if (container) {
            if (Focus.shouldScrollTop(focusedNode)) {
                container.scrollTop = 0;
            } else {
                const focusedDomRect = focusedDom.getBoundingClientRect();
                const { top, height } = focusedDomRect;
                const windowHeight = window.innerHeight;
                container.scrollTop += top + height / 2 - windowHeight / 2 - topOffset;
            }

            if (typeof this.props.additionalScrollAction === 'function') {
                this.props.additionalScrollAction(container.scrollTop);
            }

            if (typeof this.props.onFocusChanged === 'function') {
                return this.props.onFocusChanged(focusedNode);
            }
        }

        return true;
    }, 250);

    render() {
        const { /* topOffset, */ children, setRef, className, ...restProps } = this.props;
        return (
            <Container
                className={`vertical-container ${className || ''}`}
                onFocusChanged={this.scroll}
                ref={(ref) => {
                    this.containerRef = ref;
                    if (typeof setRef === 'function') {
                        setRef(ref);
                    }
                }}
                {...restProps}
            >
                {children}
            </Container>
        );
    }
}
