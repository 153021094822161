/* eslint-disable prefer-promise-reject-errors */
/**
 * @namespace Utils
 */

/**
 * @param start start
 * @param end end
 * @param point point
 * @returns calculated step
 * @memberof Utils
 * @function smoothStep
 */
const smoothStep = (start: number, end: number, point: number): number => {
    if (point <= start || point >= end) {
        return point < start ? 0 : 1;
    }

    // const x = (point - start) / (end - start); // interpolation
    // Can be used as easing
    // return x * x * (3 - 2 * x);
    return (point - start) / (end - start);
};

/**
 * Smoothly scroll element to the given target scroll position for the given duration
 * @param element - the HTML element that we are scrolling
 * @param target - target scroll position
 * @param duration - The duration of the smooth scroll
 * @returns -  Returns a promise that's fulfilled when done, or rejected if interrupted
 * @memberof Utils
 * @function smoothScroll
 */
const smoothScroll = (element: HTMLElement, target: number, duration: number): Promise<void> => {
    target = Math.round(target);
    duration = Math.round(duration);
    if (duration < 0) {
        return Promise.reject('bad duration');
    }
    if (duration === 0) {
        element.scrollLeft = target;
        return Promise.resolve();
    }

    const startTime: number = Date.now();
    const endTime: number = startTime + duration;
    const startLeft: number = element.scrollLeft;
    const distance: number = target - startLeft;

    return new Promise((resolve, reject) => {
        // This is to keep track of where the element's scrollTop is
        // supposed to be, based on what we're doing
        let previousLeft = element.scrollLeft;

        // This is like a think function from a game loop
        const scrollFrame = (): void => {
            if (element.scrollLeft !== previousLeft) {
                reject('interrupted');
                return;
            }

            // set the scrollTop for this frame
            const now = Date.now();
            const point = smoothStep(startTime, endTime, now);
            const frameLeft = Math.round(startLeft + distance * point);
            element.scrollLeft = frameLeft;

            // check if we're done!
            if (now >= endTime) {
                resolve();
                return;
            }

            // If we were supposed to scroll but didn't, then we
            // probably hit the limit, so consider it done; not
            // interrupted.
            if (element.scrollLeft === previousLeft && element.scrollLeft !== frameLeft) {
                resolve();
                return;
            }
            previousLeft = element.scrollLeft;

            // schedule next frame for execution
            window.requestAnimationFrame(scrollFrame);
        };

        // boostrap the animation process
        window.requestAnimationFrame(scrollFrame);
    });
};

export { smoothScroll };
