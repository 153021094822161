import { UI } from 'App/Packages/UI';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import React, { useEffect, useRef, useState } from 'react';
import * as workerTimers from 'worker-timers';

import { idpLoginDeviceAuthorizationAuthPooling, idpLoginDeviceAuthorizationStart } from '__SMART_APP_OLD__/api/Services';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { IdpLoginDeviceAuthResponseType, IdpLoginTokenErrors } from '__SMART_APP_OLD__/types/CommonTypes';

import { Env } from 'App/Env';
import { Screen } from 'App/Modules/Screen';
import { handleIdpLoginFlowAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';

export type LoginWithCodeContentProps = {
    onFail: () => void;
};

export const LoginWithCodeContent: React.FunctionComponent<LoginWithCodeContentProps> = (props) => {
    const { onFail } = props;
    const onFailRef = useRef(onFail);
    onFailRef.current = onFail;
    const dispatch = useDispatch();
    const request = useRef<number | null>(null);
    const start = useRef<number>(Date.now());
    const [idpInfo, setIdpInfo] = useState<IdpLoginDeviceAuthResponseType | null>(null);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        (async () => {
            const info = await idpLoginDeviceAuthorizationStart();
            const success = info !== undefined && info !== null && typeof info === 'object';
            if (success) {
                const result = { ...info };
                if (Env.IsBulgaria || Env.isBusiness || Env.IsAustria) {
                    result.verification_uri_complete = result.verification_uri_complete.replace('/auth/realms/Xplore-TV/device', '');
                    result.verification_uri = result.verification_uri.replace('/auth/realms/Xplore-TV/device', '').replace('https://', '');
                }
                setIdpInfo(result);
            } else {
                dispatch(textNotificationShow('LOGIN_WITH_CODE_FAILED_IDP_REQUEST'));
                timer = setTimeout(() => onFailRef.current(), 5000);
            }
        })();

        return () => {
            if (!timer) return;
            clearTimeout(timer);
        };
    }, [dispatch]);

    useEffect(() => {
        if (idpInfo && !request.current) {
            start.current = Date.now();
            const timer = idpInfo?.interval ? (idpInfo.interval + 1) * Time.SECOND_MS : 5 * Time.SECOND_MS;
            request.current = workerTimers.setInterval(async () => {
                const result = await idpLoginDeviceAuthorizationAuthPooling(idpInfo.device_code);
                if (result?.error && result?.error !== IdpLoginTokenErrors.AUTH_PENDING) {
                    dispatch(Screen.actions.mount(Screen.Type.LOGIN_EXPIRED_CODE, {}));
                    workerTimers.clearInterval(request.current!);
                    return;
                }
                const dateTime = new Date(start.current);
                const maxInterval = Math.floor((idpInfo.expires_in ?? 5 * Time.MINUTE_S) / Time.MINUTE_S);
                const endPoolingDate = dateTime.setMinutes(dateTime.getMinutes() + maxInterval);
                const expiredToken = new Date(endPoolingDate).getTime() <= Date.now();
                if (result?.access_token && result?.refresh_token) {
                    workerTimers.clearInterval(request.current as number);
                    await dispatch(handleIdpLoginFlowAction(result?.access_token, result?.refresh_token));
                }
                if (expiredToken) {
                    workerTimers.clearInterval(request.current as number);
                    dispatch(Screen.actions.mount(Screen.Type.LOGIN_EXPIRED_CODE, {}));
                }
            }, timer);
        }
        return () => {
            if (request.current) {
                workerTimers.clearInterval(request.current);
            }
        };
    }, [idpInfo, dispatch]);

    if (!idpInfo) return <UI.Spinner />;

    return (
        <div className="login-with-code__content-container">
            <div className="login-with-code__content">
                <Text
                    className="login-with-code__text"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SANS}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                >
                    MANUAL_LOGIN_WITH_CODE_HINT_1
                </Text>
                <Text
                    className="login-with-code__text"
                    size={FontSize.TITLE_2}
                    typeface={Typeface.SERIF}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {idpInfo?.verification_uri ?? ''}
                </Text>
                <Text
                    className="login-with-code__text"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SANS}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                >
                    MANUAL_LOGIN_WITH_CODE_HINT_2
                </Text>
                <Text
                    className="login-with-code__code"
                    size={FontSize.TITLE_1}
                    typeface={Typeface.SERIF}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {idpInfo?.user_code ?? ''}
                </Text>
            </div>
            <div className="login-with-code__content">
                <Text
                    className="login-with-code__text"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SERIF}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                >
                    QR_CODE_HINT_TEXT
                </Text>
                <UI.QRCode className="login-with-code__qr-code" url={idpInfo?.verification_uri_complete ?? ''}>
                    <UI.QRCode.Logo />
                </UI.QRCode>
            </div>
        </div>
    );
};
