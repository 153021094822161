import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListRemoveMutationVariables = Types.Exact<{
  channelListId: Types.Scalars['ID']['input'];
}>;


export type ChannelListRemoveMutation = { __typename?: 'Nexx4Mutations', deleteChannelList: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } | null> } };

export type ChannelListRemoveFragment = { __typename?: 'Nexx4Mutations', deleteChannelList: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } | null> } };

export const ChannelListRemoveFragmentDoc = gql`
    fragment channelListRemove on Nexx4Mutations {
  deleteChannelList(channelListId: $channelListId) {
    items {
      ...channelList
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListRemoveDocument = gql`
    mutation channelListRemove($channelListId: ID!) {
  ...channelListRemove
}
    ${ChannelListRemoveFragmentDoc}`;
export type ChannelListRemoveMutationFn = Apollo.MutationFunction<ChannelListRemoveMutation, ChannelListRemoveMutationVariables>;

/**
 * __useChannelListRemoveMutation__
 *
 * To run a mutation, you first call `useChannelListRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListRemoveMutation, { data, loading, error }] = useChannelListRemoveMutation({
 *   variables: {
 *      channelListId: // value for 'channelListId'
 *   },
 * });
 */
export function useChannelListRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListRemoveMutation, ChannelListRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListRemoveMutation, ChannelListRemoveMutationVariables>(ChannelListRemoveDocument, options);
      }
export type ChannelListRemoveMutationHookResult = ReturnType<typeof useChannelListRemoveMutation>;
export type ChannelListRemoveMutationResult = Apollo.MutationResult<ChannelListRemoveMutation>;
export type ChannelListRemoveMutationOptions = Apollo.BaseMutationOptions<ChannelListRemoveMutation, ChannelListRemoveMutationVariables>;