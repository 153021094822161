import { useEffect } from 'react';

import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageTriggerTable } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';

export const useStoreMessageTriggerTable = () => {
    const triggerTable = useSelector(selectMessageTriggerTable);

    useEffect(() => {
        Storage.set(Storage.Key.MESSAGE_TRIGGER_TABLE, triggerTable);
    }, [triggerTable]);
};
