import { KeyLanguage as Root, KeyLanguageComponent, KeyLanguageProps } from 'App/Packages/UI/Keyboard/Components/Key/Language/KeyLanguage';
import {
    KeyLanguageIcon,
    KeyLanguageIconInsance,
    KeyLanguageIconComponent,
    KeyLanguageIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Language/KeyLanguageIcon';

export type { KeyLanguageComponent, KeyLanguageProps, KeyLanguageIconComponent, KeyLanguageIconProps };

const KeyLanguage = Object.assign(Root, {
    Icon: Object.assign(KeyLanguageIcon, {
        Instance: KeyLanguageIconInsance,
    }),
});

export { KeyLanguage };
