import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export const SettingsHeaderTitle: React.FunctionComponent<React.PropsWithChildren> = (props) => (
    <Text
        className="settings-header__title"
        typeface={Typeface.SANS}
        size={FontSize.TITLE_3}
        weight={FontWeight.BOLD}
        color={Color.PRIMARY}
    >
        {props.children}
    </Text>
);
