import { useCallback, useRef } from 'react';

import { FolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems';

export type UseFolderItemEnter = (id: string, enter?: (id: string) => void) => () => void;

export const useFolderItemEnter: UseFolderItemEnter = (id, enter) => {
    const items = FolderItems.use();
    const folderItemEnter = useRef(enter);
    folderItemEnter.current = enter;

    return useCallback(() => {
        if (folderItemEnter.current) return folderItemEnter.current(id);
        if (items.enter) return items.enter(id);
        return undefined;
    }, [id, items]);
};
