import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KeepSessionAliveMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type KeepSessionAliveMutation = { __typename?: 'Nexx4Mutations', keepSessionAlive: { __typename?: 'KeepSessionAlivePayload', sessionTimeout?: number | null } };


export const KeepSessionAliveDocument = gql`
    mutation keepSessionAlive {
  keepSessionAlive {
    sessionTimeout
  }
}
    `;
export type KeepSessionAliveMutationFn = Apollo.MutationFunction<KeepSessionAliveMutation, KeepSessionAliveMutationVariables>;

/**
 * __useKeepSessionAliveMutation__
 *
 * To run a mutation, you first call `useKeepSessionAliveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepSessionAliveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepSessionAliveMutation, { data, loading, error }] = useKeepSessionAliveMutation({
 *   variables: {
 *   },
 * });
 */
export function useKeepSessionAliveMutation(baseOptions?: Apollo.MutationHookOptions<KeepSessionAliveMutation, KeepSessionAliveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KeepSessionAliveMutation, KeepSessionAliveMutationVariables>(KeepSessionAliveDocument, options);
      }
export type KeepSessionAliveMutationHookResult = ReturnType<typeof useKeepSessionAliveMutation>;
export type KeepSessionAliveMutationResult = Apollo.MutationResult<KeepSessionAliveMutation>;
export type KeepSessionAliveMutationOptions = Apollo.BaseMutationOptions<KeepSessionAliveMutation, KeepSessionAliveMutationVariables>;