import { AbstractEvent } from './AbstractEvent';
import { ContentRef } from '../classes/ContentRef';
import { Product } from '../classes/Product';
import { UIElement } from '../classes/UIElement';
import { UILayer } from '../classes/UILayer';
import { generateUuid } from '../loggingUtils';

export enum UIActionEvents {
    CLEAR_SEARCH_HISTORY = 'clear search history', // DONE
    RESTART = 'restart',
    SELECT_DATE = 'select date', // DONE
    EXIT = 'exit', // DONE *need to check how to listen for application close from rc
    ADD_FAVORITE = 'add favorite', // DONE
    AUDIO_CHANGED = 'audio changed', // DONE
    VIEW_FROM_START = 'view from start', // DONE
    BACK = 'back', // DONE
    CONTINUE_WATCHING = 'continue watching', // DONE
    LOGOUT = 'logout', // DONE
    PLAY = 'play', // DONE
    PLAY_LIVE = 'play live', // Done
    PLAY_TRAILER = 'play trailer', // DONE
    REMOVE_FAVORITE = 'remove favorite', // Done
    SUBTITLES_CHANGED = 'subtitles changed', // DONE
    TO_CHANNEL_LISTS = 'to channel lists', // DONE
    TO_DETAILED_INFO = 'to detailed info', // DONE
    TO_GRID_TV_GUIDE = 'to grid tv guide', // Done
    TO_HOME = 'to home', // Done
    TO_MY_LIBRARY = 'to my library', // Done
    TO_SEARCH = 'to search', // Done
    TO_SETTINGS = 'to settings', // Done
    TO_VERTICAL_TV_GUIDE = 'to vertical tv guide', // DONE
    ZAP = 'zap', // DONE
    SELECT_CHANNEL_LIST = 'select channel list', // DONE
    ACKNOWLEDGE_ERROR = 'acknowledge error', // On closing error popup // DONE
    AUTHENTICATION_NEEDED = 'authentication needed', // When token is not vaild and error is auth needed
    DISMISS_ERROR = 'dismiss error', // using ACKNOWLEDGE_ERROR
    OPEN_FULL_SYNOPSIS = 'open full synopsis', // DONE
    CLOSE_FULL_SYNOPSIS = 'close full synopsis', // Created method in event factory
    PURCHASE_CONFIRMED = 'purchase confirmed', // At the moment there is no purchase logic. (You buy/rent assets from the xploreTV system ( STB/Web ))
    RENT = 'rent', // DONE
    BUY = 'buy', // DONE
    SUBSCRIBE = 'subscribe', // DONE
    RETRY = 'retry',
    SHOW_BLOCKING_ERROR = 'show blocking error', // Not sure if every error that require user action to dismiss is blocking error or only errors like: No internet connection
    STOP_PLAYBACK = 'stop playback', // DONE
    TO_PURCHASE_PIN = 'to purchase pin', // NO Purchase PIN in use currently
    PIN_FAILED = 'pin failed', // This is about purchase pin so can't be logged
    PIN_SUCCESS = 'pin success', // This is about purchase pin so can't be logged
    USER_LOGIN = 'user login', // Created method in event factory need to log!
    BINGE_WATCH = 'binge watch', // In Progress (can't implement for now)
    DISPLAY_ADULT_CONTENT = 'display adult content', // DONE
    DISPLAY_REGULAR_CONTENT = 'display regular content', // DONE
    // ERROR_HELP = 'error help',
    // START_APP = 'start app',
    // STOP_APP = 'stop app',
    // DISABLE_DISPLAY_CONTENT_OF_CURRENT_PROFILE = 'disable display content of current profile',
    // HELP_CLOSE = 'help close',
    // HELP_NEXT = 'help next',
    // HELP_PREVIOUS = 'help previous',
    // OPEN_DEEPLINK = 'open deeplink',
    SHOW_EMERGENCY = 'show emergency',
    // START_APP_SORTING = 'start app sorting',
    // STOP_APP_SORTING = 'stop app sorting',
    // TO_ON_DEMAND_CATALOGUE = 'to on demand catalogue',
    // TO_NOW = 'to now', // Done for menu item need to log on back
    CUSTOM_ACTION = 'custom action', // Not sure exactly what is this custom action
    ERROR_RESOLVED = 'error resolved',
    // PIN_ERROR = 'pin error', // Not sure when this occurrs
    // QUALITY_CHANGED = 'quality changed', // Not implemented
    // RATE = 'rate', // I don't think we have implemented rating (only favourite)
    // SUBSCRIBE = 'subscribe',
    // SUBSCRIBE_TO_CHANNEL = 'subscribe to channel',
    // TO_CHANNEL_GRID = 'to channel grid',
    // TO_CHANNEL_GUIDE = 'to channel guide',
    // TO_FOR_YOU = 'to for you',
    // TO_SUBSCRIPTIONS = 'to subscriptions',
    // ADD_APP = 'add app', // Not implemented
    // CANCEL_DOWNLOAD = 'cancel download', // Not implemented
    CANCEL_EPISODE_RECORDING = 'cancel episode recording', // DONE
    CANCEL_RECORDING = 'cancel recording', // DONE
    CANCEL_REMINDER = 'cancel reminder', // Handled with Reminder Event Type
    CANCEL_SERIES_RECORDING = 'cancel series recording', // Not implemented
    // CHANGE_RECORDING_SETTINGS = 'change recording settings', // Not implemented
    MANAGE_CHANNEL_LISTS = 'manage channel lists', // DONE
    CHANNEL_LIST_CHANGED = 'channel list changed', // DONE
    CHANNEL_LIST_CREATED = 'channel list created', // DONE
    CHANNEL_LIST_DELETED = 'channel list deleted', // DONE
    // CLOSE_MESSAGE = 'close message', // Not implemented
    // CLOSE_PIP = 'close pip', // Not implemented | Don't know what is pip
    // CUSTOM_ACTION_URL = 'custom action url', // Not implemented
    DELETE_ALL_RECORDED_EPISODES = 'delete all recorded episodes', // DONE
    // DELETE_APP = 'delete app', // Not implemented
    // DELETE_CONTENT = 'delete content', // Not implemented
    // DELETE_DOWNLOAD = 'delete download', // Not implemented
    DELETE_EPISODE_RECORDING = 'delete episode recording', // DONE
    DELETE_MESSAGE = 'delete message', // Not implemented
    DELETE_RECORDING = 'delete recording', // DONE
    // DISABLE_AIRPLAY = 'disable airplay', // Not implemented
    // DISABLE_CHROMECAST = 'disable chromecast', // Not implemented
    // DISABLE_PLAYCAST = 'disable playcast', // Not implemented
    // DISMISS_REMINDER = 'dismiss reminder', // Not implemented
    // ENABLE_AIRPLAY = 'enable airplay', // Not implemented
    // ENABLE_CHROMECAST = 'enable chromecast', // Not implemented
    // ENABLE_DISPLAY_CONTENT_OF_ALL_PROFILES =
    // 'enable display content of all profiles', // Not implemented
    // ENABLE_PLAYCAST = 'enable playcast', // Not implemented
    // ERROR_OPEN_URL = 'error open url', // Not implemented
    // GOTO_URL = 'goto url', // Not implemented
    // GUEST_LOGIN = 'guest login', // Not implemented
    // INSTALL_COMPLETED = 'install completed', // Not implemented
    // MAXIMIZE_PLAYER = 'maximize player', // Not implemented
    OPEN_MESSAGE = 'open message',
    // PASSWORD_FORGOTTEN = 'password forgotten', // Not implemented
    PLAY_MESSAGE_ATTACHMENT = 'play message attachment', // DONE
    // PROFILE_LOGIN = 'profile login', // Not implemented
    // PURCHASE_FROM_MOBILE = 'purchase from mobile', // Not implemented
    // PURCHASE_ON_STB = 'purchase on stb', // Not implemented
    // PURCHASE_ON_WEB = 'purchase on web', // Not implemented
    // RECEIVE_CAST = 'receive cast', // Not implemented
    RECORD = 'record', // DONE
    RECORD_FROM_REMINDER = 'record from reminder',
    // REMOTE_SEARCH_INPUT = 'remote search input', // Not implemented
    // REMOTE_TEXT_INPUT = 'remote text input', // Not implemented
    // SELECT_PROFILE = 'select profile', // Not implemented
    // SELECT_TIMESLOT = 'select timeslot', // Not implemented
    // SET_REMINDER = 'set reminder', // Not implemented
    // SIGN_UP = 'sign up', // Not implemented
    SNOOZE_REMINDER = 'snooze reminder', // DONE
    // START_DOWNLOAD = 'start download', // Not implemented
    STOP_RECORDING = 'stop recording', // DONE
    // STOP_SEASON_RECORDING = 'stop season recording', // Not implemented
    STOP_SERIES_RECORDING = 'stop series recording', // DONE
    // TO_APPS = 'to apps', // Not implemented
    // TO_BACKGROUND =
    // 'to background', // Not possible if we dont start/stop logging session on login/logout
    // TO_FAQ = 'to faq', // Not implemented
    // TO_FOREGROUND =
    // 'to foreground', // Not possible if we dont start/stop logging session on login/logout
    // TO_FULLSCREEN = 'to fullscreen', // Not implemented
    // TO_HOME_SV = 'to home sv', // Not implemented
    // TO_INBOX = 'to inbox', // Not implemented
    // TO_MY_ACCOUNT = 'to my account', // Not implemented
    // TO_PROFILE_SETTINGS = 'to profile settings', // Not implemented
    // TO_RADIO = 'to radio', // Not implemented
    // TO_TELETEXT = 'to teletext', // Not implemented
    // TO_THIRD_PARTY_APP = 'to third party app', // Not implemented
    // TO_WEB_BROWSER = 'to web browser', // Not implemented
    // TUNE_FROM_REMINDER = 'tune from reminder', // Not implemented
    // UNMAXIMIZE_PLAYER = 'unmaximize player', // Not implemented
    // USERNAME_FORGOTTEN = 'username forgotten', // Can't log
    // WRONG_CREDENTIALS = 'wrong credentials', // Can't log
}

// type Digit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

// export type UIActionChNumber = `CH_${Digit}${Digit}${Digit}`;

export enum UIStates {
    BACKGROUND = 'Background',
    CHANNELGRID = 'ChannelGrid',
    CHANNELGUIDE = 'ChannelGuide',
    CHANNELLISTS = 'ChannelLists',
    DETAILEDINFO = 'DetailedInfo',
    FAQ = 'FAQ',
    FSV = 'FSV',
    FIRSTINSTALL = 'FirstInstall',
    FULLSCREENMESSAGE = 'FullScreenMessage',
    GRIDTVGUIDE = 'GridTvGuide',
    HOME = 'Home',
    HOME_APPS = 'Home_Apps',
    HOME_FORYOU = 'Home_ForYou',
    HOME_SV = 'Home_SV',
    LANGUAGESELECTION = 'LanguageSelection',
    LOGIN = 'Login',
    MESSAGES = 'Messages',
    MESSAGES_INBOX = 'Messages_Inbox',
    MESSAGES_READ = 'Messages_Read',
    MYACCOUNT = 'MyAccount',
    MYLIBRARY = 'MyLibrary',
    NOW = 'Now',
    ONDEMANDCATALOGUE = 'OnDemandCatalogue',
    PROFILESELECTION = 'ProfileSelection',
    PURCHASE_OPTIONS = 'Purchase_Options',
    PURCHASE_PIN = 'Purchase_PIN',
    RADIO = 'Radio',
    SEARCH = 'Search',
    SETTINGS = 'Settings',
    STOPPED = 'Stopped',
    SUBSCRIPTIONS = 'Subscriptions',
    TELETEXT = 'Teletext',
    VERTICALTVGUIDE = 'VerticalTvGuide',
    WEBBROWSER = 'WebBrowser',
}

export enum UIActionTriggers {
    BACK = 'BACK',
    CH_UP = 'CH+',
    CH_DOWN = 'CH-',
    DEL = 'DEL',
    DOWN = 'DOWN',
    EXIT = 'EXIT',
    GUIDE = 'GUIDE',
    HOME = 'HOME',
    INFO = 'INFO',
    LEFT = 'LEFT',
    OK = 'OK',
    POWER = 'POWER',
    RADIO = 'RADIO',
    RIGHT = 'RIGHT',
    TP_FWD = 'TP_FWD',
    TP_PAUSE = 'TP_PAUSE',
    TP_PLAY = 'TP_PLAY',
    TP_PLAY_PAUSE = 'TP_PLAY_PAUSE',
    TP_REC = 'TP_REC',
    TP_REW = 'TP_REW',
    TP_STOP = 'TP_STOP',
    TXT = 'TXT',
    UP = 'UP',
    VOD = 'VOD',
    HIDE = 'hide',
    PIN = 'pin',
    REMOTE = 'remote',
    SELECT = 'select',
    SHOW = 'show',
    SYSTEM = 'system',
}

export interface UIActionEventParams {
    event: UIActionEvents;
    fromState: UIStates;
    toState: UIStates;
    trigger?: UIActionTriggers | string; // string should be  UIActionChNumber
    uiElement?: UIElement;
    uiLayer?: UILayer;
    contentRef?: ContentRef;
    product?: Product;
}

export class UIActionEvent extends AbstractEvent {
    private readonly concept = 'UIAction';

    private readonly event: UIActionEvents;

    private readonly fromState: UIStates;

    private readonly toState: UIStates;

    private trigger?: UIActionTriggers | string; // string should be  UIActionChNumber

    private uiElement?: UIElement;

    private uiLayer?: UILayer;

    private contentRef?: ContentRef;

    private product?: Product;

    constructor({ event, fromState, toState, trigger, uiElement, uiLayer, contentRef, product }: UIActionEventParams) {
        super(generateUuid());
        this.event = event;
        this.fromState = fromState;
        this.toState = toState;
        if (trigger) this.trigger = trigger;
        else delete this.trigger;

        if (uiElement) this.uiElement = uiElement;
        else delete this.uiElement;

        if (uiLayer) this.uiLayer = uiLayer;
        else delete this.uiLayer;

        if (contentRef) this.contentRef = contentRef;
        else delete this.contentRef;

        if (product) this.product = product;
        else delete this.product;
    }
}
