import React from 'react';

import { DynamicListEngine, List, StaticListEngine } from '__SMART_APP_OLD__/app/components/ListV2';

import { UseGridReturnType } from 'App/Packages/Focus/Hooks/Types';

export type GridV2Props<T> = {
    items: T[];
    columns: number;
    navigation: UseGridReturnType<string>;
    listEngine: StaticListEngine<T[]> | DynamicListEngine<T[]>;
    className?: string;
    children: (item: string, index: number, isFocused: boolean) => React.ReactNode;
};

export const GridV2 = <T extends string = string>({ columns, navigation, listEngine, className = '', children }: GridV2Props<T>) => {
    const lastIndex = listEngine.getData().length - 1;

    return (
        <List engine={listEngine} className={`grid-v2 ${listEngine.getListClassName()} ${className} `}>
            {(row, rowIndex) => (
                <React.Fragment key={listEngine.getKey(rowIndex)}>
                    <div className="grid-v2__row">
                        {row.map((column, columnIndex) => {
                            const originalColumnIndex = rowIndex * columns + columnIndex;
                            return (
                                <React.Fragment key={originalColumnIndex}>
                                    {children(
                                        column,
                                        originalColumnIndex,
                                        !navigation.isDisabled && originalColumnIndex === navigation.focusedIndex
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {rowIndex === lastIndex && <div className="grid-v2__bottom-spacer" />}
                </React.Fragment>
            )}
        </List>
    );
};
