import { ContentItemType, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { CollectionApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api';
import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum CollectionActionType {
    LOADED = '@collection/loaded',
    RESET = '@collection/reset',
}

// Action
export type CollectionReduxAction = CollectionLoadedAction | CollectionResetAction;

export type CollectionLoadedActionPayload = { response: CollectionApi.Collection };
export type CollectionLoadedAction = PayloadedAction<CollectionActionType.LOADED, CollectionLoadedActionPayload>;

export type CollectionResetAction = BaseAction<CollectionActionType.RESET>;

// State
export type BaseCollectionEntity = {
    id: string;
    type: ContentItemType;
    title: string;
    thumbnail: string;
    parentalRatingId: string;
};

// Event
export type EventCollectionEntity = {
    type: ContentItemType.Event;
    start: number;
    end: number;
    channelId: string;
    entitlements: EventCollectionEntityEntitlements;
} & BaseCollectionEntity;

export type EventCollectionEntityEntitlements = {
    pauseLiveTV: boolean;
    restartTV: boolean;
    catchupTV: boolean;
    catchupTVAvailableUntil: number;
};

// Channel
export type ChannelCollectionEntity = {
    type: ContentItemType.Channel;
    description: string;
    userInfo: ChannelCollectionEntityUserInfo;
} & BaseCollectionEntity;

export type ChannelCollectionEntityUserInfo = {
    id: string;
    subscribed: boolean;
};

// Recording
export type RecordingCollectionEntity = {
    type: ContentItemType.Networkrecording;
    status: RecordingStatus;
    eventId: string;
} & BaseCollectionEntity &
    Omit<EventCollectionEntity, 'type'>;

export type SeriesCollectionEntity = { type: ContentItemType.Series; channelId: string; episodeCount: number } & BaseCollectionEntity;
export type VodFolderCollectionEntity = { type: ContentItemType.Vodfolder } & BaseCollectionEntity;

// VodAsset
export type VodAssetCollectionEntity = {
    type: ContentItemType.Vodasset;
    duration: number;
    metadata: VodAssetCollectionEntityMetadata;
    entitlements: VodAssetCollectionEntityEntitlements;
} & BaseCollectionEntity;

export type VodAssetCollectionEntityMetadata = {
    year: number;
    genre: VodAssetCollectionEntityMetadataGenre;
};

export type VodAssetCollectionEntityMetadataGenre = {
    title: string;
};

export type VodAssetCollectionEntityEntitlements = {
    playback: boolean;
    playbackAvailableUntil: number;
};

export type VodProductCollectionEntity = { type: ContentItemType.Vodproduct } & BaseCollectionEntity;

// VodSeries
export type VodSeriesCollectionEntity = {
    type: ContentItemType.Vodseries;
    seasonCount: number;
} & BaseCollectionEntity;

export type BannerCollectionEntity = { type: ContentItemType.Banner; link?: string } & BaseCollectionEntity;

export type CollectionEntity =
    | EventCollectionEntity
    | ChannelCollectionEntity
    | RecordingCollectionEntity
    | SeriesCollectionEntity
    | VodFolderCollectionEntity
    | VodAssetCollectionEntity
    | VodProductCollectionEntity
    | VodSeriesCollectionEntity
    | BannerCollectionEntity;

export type CollectionEntityTable = {
    id: string;
    title: string;
    kind: GQL.ContentFolderKind;
    ids: string[];
    entities: Record<string, CollectionEntity>;
};
