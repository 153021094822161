// Logging imports
import * as contentRefGenerator from 'analytics/logging/contentRefGenerators';
import { RecordingContentTypes, RecordingEvent, RecordingEvents } from 'analytics/logging/events/RecordingEvent';
import * as loggingUtils from 'analytics/logging/loggingUtils';

export const getPlanRecordingEvent = async (eventId, planningType, isSeriesEvent) => {
    const contentRef = contentRefGenerator.getEventContentRef(eventId);
    const {
        startOverTVBeforeTime: eventBeforeTime,
        startOverTVAfterTime: eventAfterTime,
        channel: { beforeTime: channelBeforeTime, afterTime: channelAfterTime },
    } = loggingUtils.safeDestructure(contentRef, { channel: {} });
    const event = RecordingEvents.PLAN;
    const beforeTime = eventBeforeTime || channelBeforeTime;
    const afterTime = eventAfterTime || channelAfterTime;
    const contentType = isSeriesEvent ? RecordingContentTypes.SERIES : RecordingContentTypes.PROGRAM;

    return new RecordingEvent(event, contentRef, afterTime, beforeTime, planningType, contentType);
};

export const getDeleteRecordingEvent = (recordingId, isSeriesEvent, cancelling) => {
    const contentRef = contentRefGenerator.getRecordingContentRef(recordingId);
    const event = cancelling ? RecordingEvents.CANCEL : RecordingEvents.DELETE;
    const contentType = isSeriesEvent ? RecordingContentTypes.SERIES : RecordingContentTypes.PROGRAM;

    return new RecordingEvent(event, contentRef, null, null, null, contentType);
};
