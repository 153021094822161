import { SuggestionState } from 'App/Modules/Data/Search/Suggestion/Types';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

const select: Selector<SuggestionState> = (state) => state.dataV2.search.suggestions;

export const selectors = {
    public: {
        select,
    },
};
