import * as Types from '../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { LanguageFragmentDoc } from '../fragments/language.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LanguageLoadManyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LanguageLoadManyQuery = { __typename?: 'Nexx4Queries', languages?: { __typename?: 'LanguageCatalog', items: Array<{ __typename?: 'Language', id: string, code: string } | null> } | null };

export type LanguageLoadManyFragment = { __typename?: 'Nexx4Queries', languages?: { __typename?: 'LanguageCatalog', items: Array<{ __typename?: 'Language', id: string, code: string } | null> } | null };

export const LanguageLoadManyFragmentDoc = gql`
    fragment languageLoadMany on Nexx4Queries {
  languages {
    items {
      ...language
    }
  }
}
    ${LanguageFragmentDoc}`;
export const LanguageLoadManyDocument = gql`
    query languageLoadMany {
  ...languageLoadMany
}
    ${LanguageLoadManyFragmentDoc}`;

/**
 * __useLanguageLoadManyQuery__
 *
 * To run a query within a React component, call `useLanguageLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageLoadManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguageLoadManyQuery(baseOptions?: Apollo.QueryHookOptions<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>(LanguageLoadManyDocument, options);
      }
export function useLanguageLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>(LanguageLoadManyDocument, options);
        }
export function useLanguageLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>(LanguageLoadManyDocument, options);
        }
export type LanguageLoadManyQueryHookResult = ReturnType<typeof useLanguageLoadManyQuery>;
export type LanguageLoadManyLazyQueryHookResult = ReturnType<typeof useLanguageLoadManyLazyQuery>;
export type LanguageLoadManySuspenseQueryHookResult = ReturnType<typeof useLanguageLoadManySuspenseQuery>;
export type LanguageLoadManyQueryResult = Apollo.QueryResult<LanguageLoadManyQuery, LanguageLoadManyQueryVariables>;