import { selectDevice } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Translation as Translations } from 'App/Modules/Translation';

const selectTitle = (id: string) => (state: State): string => {
    const device = selectDevice(id)(state);
    if (!device) return '';
    return Translation.selectors
        .select(Translations.Key.Prompt.DeviceConfirmDelete.Title)(state)
        .replace('%registered_device_name%', `“${device.name}”`);
};

export const selectors = {
    private: {
        selectTitle,
    },
} as const;
