import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    EpisodeInfoEntity,
    EventEntitlementsEntity,
    EventEntity,
    GenreEntity,
    MetadataEntity,
    PlaceHolderEventType,
    SeriesInfoEntity,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';

export const toIds = () => {};

export const toEntitlements = (entitlements?: GQL.EventEntitlements | null): EventEntitlementsEntity => ({
    ppvTV: entitlements?.ppvTV ?? false,
    // liveTV: entitlements?.liveTV ?? false,
    pauseLiveTV: entitlements?.pauseLiveTV ?? false,
    restartTV: entitlements?.restartTV ?? false,
    catchupTV: entitlements?.catchupTV ?? false,
    catchupTVAvailableUntil: new Date(entitlements?.catchupTVAvailableUntil).getTime() ?? -1,
    networkRecording: entitlements?.networkRecording ?? false,
    networkRecordingPlannableUntil: new Date(entitlements?.networkRecordingPlannableUntil).getTime() ?? -1,
});

export const toGenre = (genre?: GQL.Genre | null): GenreEntity | null => {
    if (!genre) return null;
    return {
        id: genre.id,
        title: genre.title,
    };
};
export const toEpisodeInfo = (episodeInfo?: GQL.EpisodeInfo | null): EpisodeInfoEntity | null => {
    if (!episodeInfo) return null;
    return {
        id: episodeInfo.id,
        number: episodeInfo.number ?? null,
        season: episodeInfo.season ?? null,
        title: episodeInfo.title ?? null,
    };
};
export const toSeriesInfo = (seriesInfo?: GQL.SeriesInfo | null): SeriesInfoEntity | null => {
    if (!seriesInfo) return null;
    return {
        id: seriesInfo.id,
        title: seriesInfo.title ?? null,
    };
};

export const toMetadata = (metadata: GQL.Metadata): MetadataEntity => ({
    id: metadata.id,
    title: metadata.title,
    originalTitle: metadata.originalTitle ?? null,
    fullDescription: metadata.fullDescription ?? null,
    shortDescription: metadata.shortDescription ?? null,
    actors: (metadata.actors ?? []).filter((a): a is string => !!a),
    directors: (metadata.directors ?? []).filter((a): a is string => !!a),
    audio: (metadata.audioLanguages ?? []).filter((a): a is string => !!a),
    subtitle: (metadata.subtitleLanguages ?? []).filter((a): a is string => !!a),
    country: metadata.country ?? null,
    year: metadata.year ?? null,
    genre: toGenre(metadata.genre),
    episodeInfo: toEpisodeInfo(metadata.episodeInfo),
    seriesInfo: toSeriesInfo(metadata.seriesInfo),
});

export const toEntity = (event: GQL.Event, channelId: GQL.ChannelID): EventEntity => ({
    id: event.id,
    title: event.title,
    channelId,
    parentalRatingId: event.parentalRating.id,
    start: new Date(event.start).getTime(),
    end: new Date(event.end).getTime(),
    isLoading: false,
    isNoInfo: false,
    thumbnail: event.thumbnail?.url ?? null,
    background: event.backgroundImage?.url ?? null,
    entitlements: toEntitlements(event.entitlements),
    metadata: toMetadata(event.metadata),
    streamInfo: event.streamInfo,
    startOverPlaybackContinuePosition: event.startOverPlaybackContinuePosition ?? null,
    startOverPlaybackStartPosition: event.startOverPlaybackStartPosition ?? null,
    startOverPlaybackStopPosition: event.startOverPlaybackStopPosition ?? null,
    startOverTVAfterTime: event.startOverTVAfterTime ?? null,
    startOverTVBeforeTime: event.startOverTVBeforeTime ?? null,
});

export const toPlaceholderEventEntity = (
    channelId: GQL.ChannelID,
    start: number,
    end: number,
    type: PlaceHolderEventType
): EventEntity | null =>
    end <= start
        ? null
        : {
              id: `${type}-${channelId}-${start}-${end}`,
              title: type === PlaceHolderEventType.LOADING ? 'EPG_LOADING' : 'EPG_NO_INFO',
              channelId,
              end,
              start,
              isLoading: type === PlaceHolderEventType.LOADING,
              isNoInfo: type === PlaceHolderEventType.NO_INFO,
              parentalRatingId: '',
              startOverPlaybackContinuePosition: null,
              startOverPlaybackStartPosition: null,
              startOverPlaybackStopPosition: null,
              startOverTVAfterTime: null,
              startOverTVBeforeTime: null,
              streamInfo: {
                  dolby: false,
                  hardOfHearing: false,
                  multipleAudioLanguages: false,
                  subtitles: false,
                  visuallyImpaired: false,
              },
              thumbnail: null,
              background: null,
              metadata: toMetadata({
                  id: '',
                  title: type === PlaceHolderEventType.LOADING ? 'EPG_LOADING' : 'EPG_NO_INFO',
                  __typename: 'Metadata',
              }),
              entitlements: toEntitlements(),
          };

export const toNoInfoEventEntity = (channelId: GQL.ChannelID, start: number, end: number) =>
    toPlaceholderEventEntity(channelId, start, end, PlaceHolderEventType.NO_INFO);

export const toLoadingEventEntity = (channelId: GQL.ChannelID, start: number, end: number) =>
    toPlaceholderEventEntity(channelId, start, end, PlaceHolderEventType.LOADING);

export const toPlaceholderInitState = (channel: GQL.ChannelID, start: number, end: number) =>
    new Array(Math.floor((end - start) / Time.DAY_MS) + 1)
        .fill(0)
        .map((_, idx) => toLoadingEventEntity(channel, start + Time.DAY_MS * idx, Math.min(start + Time.DAY_MS * (idx + 1), end)))
        .filter((e): e is EventEntity => !!e);
