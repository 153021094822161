import { Data } from 'App/Modules/Data';
import { Folder } from 'App/Modules/Folder';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { MoreCard } from 'App/Modules/Folder/Components/MoreCard';
import { Screen } from 'App/Modules/Screen';
import { actions } from 'App/Modules/Screen/Modules/Search/Store/Actions';
import { UI } from 'App/Packages/UI';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import React from 'react';

export const SearchResults: React.FunctionComponent<Screen.Props<Screen.Type.Search>> = (props) => {
    const dispatch = useDispatch();
    return (
        <UI.Selector selector={Data.Search.Result.selectors.selectFoldersIds}>
            {(results) =>
                results.map((id, index) => {
                    const selector = Data.Search.Result.selectors.selectFolder(id);
                    const getTop: UI.UseFolderScrollIntoViewGetTop = (payload) => (index === 0 ? 0 : payload.default);
                    return (
                        <UI.Folder key={id} id={id} getTop={getTop}>
                            <UI.Folder.Header>
                                <UI.Selector selector={Folder.selectors.selectTitle(selector)} />
                            </UI.Folder.Header>
                            <UI.Folder.Items
                                initial={props.focusedFolderId === id ? props.focusedItemId : undefined}
                                onEnter={(itemId) => dispatch(actions.private.selected(id, itemId))}
                            >
                                <UI.Selector selector={Folder.selectors.selectItemsIds(selector)}>
                                    {(ids) =>
                                        ids.map((itemId) => (
                                            <FolderCardItem key={itemId} selector={Folder.selectors.selectItem(selector)(itemId)} />
                                        ))
                                    }
                                </UI.Selector>
                                <MoreCard id={UI.Folder.Constants.Item.Id.More} selector={selector} />
                            </UI.Folder.Items>
                        </UI.Folder>
                    );
                })
            }
        </UI.Selector>
    );
};
