import React, { useRef } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import { moreInfoOverlayConfig } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

import { Key } from 'App/Modules/Key';
import { Overlay } from 'App/Modules/Overlay';
import { Focus } from 'App/Packages/Focus';

const keys = [Key.VK_UP, Key.VK_DOWN, Key.VK_ENTER, Key.VK_BACK];

export const MoreInfoOverlayView: React.FunctionComponent<Overlay.Props<Overlay.Type.MORE_INFO>> = (props) => {
    const dispatch = useDispatch();
    const divRef = useRef<HTMLDivElement | null>(null);

    const scrollPage = (direction: number) => {
        if (!divRef.current?.parentElement) return;
        divRef.current.parentElement.scrollTop += direction * moreInfoOverlayConfig.scrollStep;
    };

    Focus.useKeyDown(
        (event) => {
            switch (event.key) {
                case Key.VK_DOWN:
                    scrollPage(1);
                    break;
                case Key.VK_UP:
                    scrollPage(-1);
                    break;
                case Key.VK_BACK:
                case Key.VK_ENTER:
                    dispatch(Overlay.actions.unmount());
                    break;
                default:
                    break;
            }
        },
        { keys }
    );

    return (
        <Layout className="more-info-overlay" backgroundColor={BackgroundColor.OVERLAY} zIndex={Overlay.zIndex}>
            <div ref={divRef}>
                <Text
                    className="more-info-overlay__title"
                    typeface={Typeface.SERIF}
                    size={FontSize.TITLE_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {props.title}
                </Text>
                <Text
                    className="more-info-overlay__text"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SANS}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    <InnerHTML html={translate(props.text)} className="more-info-overlay__ul" />
                </Text>
            </div>
        </Layout>
    );
};
