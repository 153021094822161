import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { recordingManagementBackTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { RecordingManagementGrid } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/RecordingManagementGrid';
import { RecordingManagementHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/RecordingManagementHeader';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

export const RecordingManagement: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(recordingManagementBackTriggered));

    return (
        <Layout className="recording-management" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <RecordingManagementHeader />
            <div className="recording-management__grid-container">
                <RecordingManagementGrid />
            </div>
        </Layout>
    );
};
