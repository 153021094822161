import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { LabelPayload } from '__SMART_APP_OLD__/app/components/Label';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectIsMessageUnread,
    selectMessageIds,
    selectMessageLabel,
    selectMessageLabels,
    selectMessageStatus,
    selectMessageUnreadIds,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import {
    MESSAGE_INBOX_DEFAULT_ACTION_IDS,
    MESSAGE_INBOX_FILTER_ACTION_IDS,
    MESSAGE_INBOX_SELECT_ACTION_IDS,
    UNREAD_MESSAGE_LABEL,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/constants';
import { MessageInboxActionID, MessageInboxFilter } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Screen } from 'App/Modules/Screen';
import { Calc } from 'App/Packages/Calc';

export const selectMessageInboxFilter = (state: State): string => state.messageInbox.appliedFilter;

export const selectMessageInboxSelectedIds = (state: State): GQL.MessageID[] => state.messageInbox.selected;

export const selectMessageInboxIsFilterApplied = (state: State): boolean =>
    selectMessageInboxFilter(state) !== MessageInboxFilter.NO_FILTER;

export const selectMessageInboxIsSelectMode = (state: State): boolean => state.messageInbox.isSelectMode;

export const selectMessageInboxSelectedMessagesCount = (state: State): number => selectMessageInboxSelectedIds(state).length;

export const selectMessageInboxSelectedUnreadMessagesCount = (state: State): number =>
    selectMessageInboxSelectedIds(state).filter((id) => selectIsMessageUnread(id)(state)).length;

export const selectMessageInboxActions = (state: State): MessageInboxActionID[] => {
    const isFilterApplied = selectMessageInboxIsFilterApplied(state);
    const isSelectMode = selectMessageInboxIsSelectMode(state);
    if (isSelectMode) return MESSAGE_INBOX_SELECT_ACTION_IDS;
    if (isFilterApplied) return MESSAGE_INBOX_FILTER_ACTION_IDS;
    return MESSAGE_INBOX_DEFAULT_ACTION_IDS;
};

export const selectMessageInboxMessagesIds = (state: State): GQL.MessageID[] => {
    const isFilterApplied = selectMessageInboxIsFilterApplied(state);
    if (!isFilterApplied) return selectMessageIds(state);
    const filter = selectMessageInboxFilter(state);
    if (filter === MessageInboxFilter.UNREAD) return selectMessageUnreadIds(state);
    return selectMessageIds(state).filter((id) => selectMessageLabel(id)(state) === filter);
};

export const selectMessageInboxFilteredMessagesCount = (state: State): number => selectMessageInboxMessagesIds(state).length;

export const selectMessageInboxInitialFocusedIndex = (state: State): number => {
    const screen = Screen.selectors.selectTyped(Screen.Type.MESSAGE_INBOX)(state);
    if (!screen) return 0;
    const messageIds = selectMessageInboxMessagesIds(state);
    return Calc.clamp(0, messageIds.indexOf(screen.props.focused ?? ''), messageIds.length - 1);
};

export const selectMessageInboxMessageLabels =
    (id: GQL.MessageID) =>
    (state: State): LabelPayload[] => {
        const text = selectMessageLabel(id)(state);
        const status = selectMessageStatus(id)(state);
        const labels: LabelPayload[] = [];
        if (text) labels.push({ color: BackgroundColor.COOL_BLUE_PRIMARY, text });
        if (status === GQL.MessageStatus.New) labels.push(UNREAD_MESSAGE_LABEL);
        return labels;
    };

export const selectMessageInboxIsMessageSelected =
    (id: GQL.MessageID) =>
    (state: State): boolean =>
        selectMessageInboxSelectedIds(state).includes(id);

export const selectSelectOverlayMessageInboxFilterOptions = (state: State) => [
    MessageInboxFilter.NO_FILTER,
    MessageInboxFilter.UNREAD,
    ...selectMessageLabels(state),
];

export const selectMessageInboxFocusCTX = (state: State) => state.messageInbox.ctx;

export const selectMessageInboxHeader = (state: State): string => {
    if (!selectMessageInboxIsFilterApplied(state)) {
        return Translation.selectors
            .select('MESSAGE_INBOX_HEADER_DEFAULT')(state)
            .replace('%count%', `${selectMessageInboxFilteredMessagesCount(state)}`);
    }
    return Translation.selectors
        .select('MESSAGE_INBOX_HEADER_FILTER')(state)
        .replace('%type%', Translation.selectors.select(selectMessageInboxFilter(state))(state) ?? '')
        .replace('%count%', `${selectMessageInboxFilteredMessagesCount(state)}`);
};

export const selectMessageActionsTranslation =
    (key: string) =>
    (state: State): string | null => {
        switch (key) {
            case 'MESSAGE_INBOX_MARK_AS_READ': {
                const count = selectMessageInboxSelectedUnreadMessagesCount(state);
                const totalCount = selectMessageInboxFilteredMessagesCount(state);
                return Translation.selectors.select(key)(state).replace('%count%', `${count}`).replace('%total_count%', `${totalCount}`);
            }
            case 'MESSAGE_INBOX_DELETE': {
                const count = selectMessageInboxSelectedMessagesCount(state);
                const totalCount = selectMessageInboxFilteredMessagesCount(state);
                return Translation.selectors.select(key)(state).replace('%count%', `${count}`).replace('%total_count%', `${totalCount}`);
            }
            default:
                return Translation.selectors.select(key)(state);
        }
    };
