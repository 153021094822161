/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useRef, useState } from 'react';

import Events, { CLEAR_SEARCH_HISTORY, LANGUAGE_CHANGED } from '__SMART_APP_OLD__/config/Events';
import { useHomePageDataService } from '__SMART_APP_OLD__/providers/pageDataServices/useHomePageDataService';
import { useSearchDataService } from '__SMART_APP_OLD__/providers/pageDataServices/useSearchDataService';
import { Route } from '__SMART_APP_OLD__/utils/Constants';

const INITIAL_STATE = { stripes: [], galleryAssets: [], loading: true };

const usePageDataService = () => {
    const { homePageData, loading: homeLoading, loadHomePageData, loadNextHomeStripes } = useHomePageDataService();
    const { searchData, onTermChange, onSearchData, onSearchSuggestions, onResetSearchData } = useSearchDataService();

    const currentPageId = useRef<any>(Route.HOME);

    const [data, setData] = useState<any>(INITIAL_STATE);

    const setPageData = ({ stripes, galleryAssets, loading }: any) => {
        const pageId = currentPageId.current;
        if (pageId === Route.MY_LIBRARY) {
            setData({
                pageId: currentPageId.current,
                stripes: stripes || data.stripes,
                galleryAssets: galleryAssets || data.galleryAssets,
                loading,
                assets: [],
                title: '',
                totalAssetsNumber: 0,
                kind: '',
            });
        } else {
            setData({
                pageId: currentPageId.current,
                stripes: stripes?.length ? stripes : data.stripes,
                galleryAssets: galleryAssets || data.galleryAssets,
                loading,
                assets: [],
                title: '',
                totalAssetsNumber: 0,
                kind: '',
            });
        }
    };

    const setDefaultPageData = () => {
        setPageData(INITIAL_STATE);
    };

    const initPageData = (pageId: Route = Route.HOME) => {
        currentPageId.current = pageId;
        switch (pageId) {
            case Route.HOME:
                setPageData(loadHomePageData(true));
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const pageId = currentPageId.current;
        if (pageId === Route.HOME) {
            setPageData({ ...homePageData, loading: homeLoading });
        }
    }, [homePageData]);

    const loadNextData = (index: number) => {
        const pageId = currentPageId.current;
        switch (pageId) {
            case Route.HOME:
                loadNextHomeStripes(index);
                break;
            default:
                break;
        }
    };

    const refreshData = () => {
        setData(INITIAL_STATE);
        setPageData(loadHomePageData(true));
    };

    useEffect(() => {
        Events.addEventListener(CLEAR_SEARCH_HISTORY, onResetSearchData);
        Events.addEventListener(LANGUAGE_CHANGED, refreshData);
        return () => {
            Events.removeEventListener(CLEAR_SEARCH_HISTORY, onResetSearchData);
            Events.removeEventListener(LANGUAGE_CHANGED, refreshData);
        };
    }, []);

    return {
        data,
        searchData,
        onTermChange,
        onSearchData,
        onSearchSuggestions,
        initPageData,
        loadNextData,
        refreshData,
        setDefaultPageData,
    };
};

export const PageDataContext = createContext<ReturnType<typeof usePageDataService>>({
    data: {},
    searchData: { searchValue: '', searchResults: [], searchDataLoaded: false, searchSuggestions: [] },
    onTermChange: () => {},
    onSearchData: () => {},
    onSearchSuggestions: () => {},
    initPageData: () => {},
    loadNextData: () => {},
    refreshData: () => {},
    setDefaultPageData: () => {},
});

export const PageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const data = usePageDataService();
    return <PageDataContext.Provider value={data}>{children}</PageDataContext.Provider>;
};
