import React from 'react';

import { Env } from 'App/Env';

const getClassName = (className: string | undefined, defaultClassName: string | undefined): string => {
    const classList = [];
    if (defaultClassName) classList.push(defaultClassName);
    if (className) classList.push(className);
    return classList.join(' ');
};

const getStyle = (props: DivProps): React.CSSProperties | undefined => {
    if (!props.style && !props.zIndex) return undefined;
    if (!props.zIndex) return props.style;
    return { ...props.style, zIndex: props.zIndex };
};

const getProps = (
    props: DivProps,
    ref: React.Ref<HTMLDivElement>
): React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, defaultClassName, zIndex, onMouseOver, onHover, onClick, ...rest } = props;
    return { ...rest, ref, className: getClassName(className, defaultClassName), style: getStyle(props) };
};

const DivNoMouse: DivComponent = React.forwardRef((props, ref) => (
    <div {...getProps(props, ref)} onClick={undefined} onMouseOver={undefined} />
));

const DivMouse: DivComponent = React.forwardRef((props, ref) => (
    <div {...getProps(props, ref)} onClick={props.onClick} onMouseOver={props.onHover} />
));

export type DivPropsExtendable = {
    onHover?: React.MouseEventHandler<HTMLDivElement>;
    zIndex?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export type DivPropsNoRef = { defaultClassName?: string } & DivPropsExtendable;

export type DivProps = DivPropsNoRef & React.RefAttributes<HTMLDivElement>;

export type DivComponent = React.FunctionComponent<DivProps>;

export type DivModules = {};

export type DivModule = DivComponent & DivModules;

export const Div: DivModule = Env.IsMouseSupported ? DivMouse : DivNoMouse;
