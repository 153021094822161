import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListLoadInitialIdFragmentDoc } from '../../channelList/query/channelListLoadInitialId.generated';
import { ChannelListLoadManyFragmentDoc } from '../../channelList/query/channelListLoadMany.generated';
import { ChannelLoadTotalCountFragmentDoc } from '../../channel/query/channelLoadTotalCount.generated';
import { ParentalRatingLoadManyFragmentDoc } from '../../parentalRating/query/parentalRatingLoadMany.generated';
import { ReminderLoadManyFragmentDoc } from '../../reminder/query/reminderLoadMany.generated';
import { MessageLoadTotalCountFragmentDoc } from '../../message/query/messageLoadTotalCount.generated';
import { RecordingQuotaFragmentDoc } from '../../recording/query/recordingQuota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitLoadQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
}>;


export type InitLoadQuery = { __typename?: 'Nexx4Queries', initialChannelList?: { __typename?: 'ChannelList', id: string } | null, channelLists?: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } | null> } | null, channels?: { __typename?: 'ChannelsConnection', totalCount?: number | null } | null, availableParentalRatings?: { __typename?: 'ParentalRatingCatalog', items: Array<{ __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } | null> } | null, reminders?: { __typename?: 'ReminderCatalog', items: Array<{ __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } } | null> } | null, messages: { __typename?: 'MessagesConnection', totalCount?: number | null }, recordingQuota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null };


export const InitLoadDocument = gql`
    query initLoad($profileId: ID!) {
  ...channelListLoadInitialId
  ...channelListLoadMany
  ...channelLoadTotalCount
  ...parentalRatingLoadMany
  ...reminderLoadMany
  ...messageLoadTotalCount
  ...recordingQuota
}
    ${ChannelListLoadInitialIdFragmentDoc}
${ChannelListLoadManyFragmentDoc}
${ChannelLoadTotalCountFragmentDoc}
${ParentalRatingLoadManyFragmentDoc}
${ReminderLoadManyFragmentDoc}
${MessageLoadTotalCountFragmentDoc}
${RecordingQuotaFragmentDoc}`;

/**
 * __useInitLoadQuery__
 *
 * To run a query within a React component, call `useInitLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitLoadQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useInitLoadQuery(baseOptions: Apollo.QueryHookOptions<InitLoadQuery, InitLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitLoadQuery, InitLoadQueryVariables>(InitLoadDocument, options);
      }
export function useInitLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitLoadQuery, InitLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitLoadQuery, InitLoadQueryVariables>(InitLoadDocument, options);
        }
export function useInitLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InitLoadQuery, InitLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InitLoadQuery, InitLoadQueryVariables>(InitLoadDocument, options);
        }
export type InitLoadQueryHookResult = ReturnType<typeof useInitLoadQuery>;
export type InitLoadLazyQueryHookResult = ReturnType<typeof useInitLoadLazyQuery>;
export type InitLoadSuspenseQueryHookResult = ReturnType<typeof useInitLoadSuspenseQuery>;
export type InitLoadQueryResult = Apollo.QueryResult<InitLoadQuery, InitLoadQueryVariables>;