import { initialTimeState } from '__SMART_APP_OLD__/app/modules/Time/constants';
import { TimeActionType } from '__SMART_APP_OLD__/app/modules/Time/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const timeReducer: Reducer<number> = (state, action): number => {
    if (typeof state !== 'number') return initialTimeState;
    switch (action.type) {
        case TimeActionType.CHANGED: {
            return action.payload.time;
        }
        default:
            return state;
    }
};
