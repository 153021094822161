import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import { formatDayName } from '__SMART_APP_OLD__/utils/timeUtils';
import { getPrimeTimeDate } from '__SMART_APP_OLD__/utils/TvGuideUtils';
import translate from 'language/translate';

export const generateDatePickerOptionFromDate = (time: Date, isPrime: boolean): ButtonEntity => {
    const startLabel = formatDayName(time, 'DD dd MMM', '', false);
    if (startLabel !== translate('DAY_NAME_TODAY')) return { id: time.toString(), label: startLabel };
    if (isPrime) {
        const prime = getPrimeTimeDate(new Date());
        return { id: prime.toString(), label: translate('DAY_NAME_TODAY_TONIGHT') };
    }
    return { id: new Date().toString(), label: translate('DAY_NAME_TODAY_NOW') };
};
