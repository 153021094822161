import React from 'react';

import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Overlay } from 'App/Modules/Overlay';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.Loading>> = (props) => (
    <Layout className="loading" backgroundColor={props.backgroundColor} zIndex={Overlay.zIndex}>
        <UI.Spinner />
        {!!props.text && (
            <Text
                className="loading__text"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
            >
                <UI.Translation>{props.text}</UI.Translation>
            </Text>
        )}
    </Layout>
);
