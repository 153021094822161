import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeProfilePreferencesMutationVariables = Types.Exact<{
  input: Types.ChangeProfilePreferencesInput;
}>;


export type ChangeProfilePreferencesMutation = { __typename?: 'Nexx4Mutations', changeProfilePreferences: { __typename?: 'ChangeProfilePreferencesPayload', profile: { __typename?: 'Profile', preferences: { __typename?: 'ProfilePreferences', trackViewingBehaviour: boolean, firstAudioLanguage: string, secondAudioLanguage: string, firstSubtitleLanguage: string, secondSubtitleLanguage: string, consents: Array<{ __typename?: 'ProfileConsent', type: any, accepted: boolean } | null> } } } };


export const ChangeProfilePreferencesDocument = gql`
    mutation changeProfilePreferences($input: ChangeProfilePreferencesInput!) {
  changeProfilePreferences(input: $input) {
    profile {
      preferences {
        trackViewingBehaviour
        consents {
          type
          accepted
        }
        firstAudioLanguage
        secondAudioLanguage
        firstSubtitleLanguage
        secondSubtitleLanguage
      }
    }
  }
}
    `;
export type ChangeProfilePreferencesMutationFn = Apollo.MutationFunction<ChangeProfilePreferencesMutation, ChangeProfilePreferencesMutationVariables>;

/**
 * __useChangeProfilePreferencesMutation__
 *
 * To run a mutation, you first call `useChangeProfilePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProfilePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProfilePreferencesMutation, { data, loading, error }] = useChangeProfilePreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProfilePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProfilePreferencesMutation, ChangeProfilePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProfilePreferencesMutation, ChangeProfilePreferencesMutationVariables>(ChangeProfilePreferencesDocument, options);
      }
export type ChangeProfilePreferencesMutationHookResult = ReturnType<typeof useChangeProfilePreferencesMutation>;
export type ChangeProfilePreferencesMutationResult = Apollo.MutationResult<ChangeProfilePreferencesMutation>;
export type ChangeProfilePreferencesMutationOptions = Apollo.BaseMutationOptions<ChangeProfilePreferencesMutation, ChangeProfilePreferencesMutationVariables>;