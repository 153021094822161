import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { ChannelCardNumberBase } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumberBase';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelListChannelNumber,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';

type Props = {};

export type ChannelCardNumberChannelListModule = React.FunctionComponent<Props>;

export const ChannelCardNumberChannelList: ChannelCardNumberChannelListModule = () => {
    const { id } = useContext(ChannelCardContext);
    const channelListId = selectSelectedChannelListId(store.getState());
    const number = useSelector(selectChannelListChannelNumber(channelListId, id));

    return <ChannelCardNumberBase>{number}</ChannelCardNumberBase>;
};
