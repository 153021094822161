import KeyboardSuggestionElement from 'App/Modules/Keyboard/Element/KeyboardSuggestionElement';
import { NavigationEvent as FocusNavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';
import { Subscribable } from 'App/Packages/Subscribable';

export default class KeyboardSuggestionListElement {
    public focused: KeyboardSuggestionElement | null = null;

    public readonly suggestions: Subscribable.Value<KeyboardSuggestionElement[]>;

    constructor() {
        this.suggestions = new Subscribable.Value<KeyboardSuggestionElement[]>([]);
        this.focused = null;
    }

    public suggestionsChanged(suggestions: string[]) {
        this.suggestions.set(suggestions.map((value) => new KeyboardSuggestionElement(value)));
        this.focus(this.suggestions.get()[0]);
    }

    public focus(suggestion: KeyboardSuggestionElement) {
        if (!suggestion) return;
        this.focused?.blur();
        this.focused = suggestion;
        this.focused.focus();
    }

    public navigate(event: FocusNavigationEvent) {
        if (event.x === 0 && event.y === 0) return undefined;
        const items = this.suggestions.get();
        if (!items.length) throw new Error('No suggestions');
        if (!this.focused) return this.focus(items[0]);
        if (event.y !== 0) throw new Error('Out of bounds');
        const index = items.indexOf(this.focused);
        if (index === -1) return this.focus(items[0]);
        const next = items[index + event.x];
        if (!next) return undefined;
        return this.focus(next);
    }
}
