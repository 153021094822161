import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { MacedoniaBackEngine } from 'App/Modules/EnhancedBack/Implementation/Macedonia';

export class Macedonia {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Opco.Macedonia.Initialize]');
        EnhancedBack.initialize(new MacedoniaBackEngine());

        return true;
    }
}
