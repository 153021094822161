import React from 'react';

import { FolderContext, FolderContextModule } from 'App/Packages/UI/Folder/Modules/Folder/Context/FolderContext';
import { UseFolder, useFolder } from 'App/Packages/UI/Folder/Modules/Folder/Hooks/useFolder';
import { UseFolderValue, UseFolderValuePayload, useFolderValue } from 'App/Packages/UI/Folder/Modules/Folder/Hooks/useFolderValue';

export type FolderProps = React.PropsWithChildren<UseFolderValuePayload>;

export type FolderModule = {
    // Context
    Context: FolderContextModule;

    // Hooks
    use: UseFolder;
    useValue: UseFolderValue;
} & React.FunctionComponent<FolderProps>;

export const Folder: FolderModule = (props) => {
    const folder = Folder.useValue(props);
    return <Folder.Context.Provider value={folder}>{props.children}</Folder.Context.Provider>;
};

// Context
Folder.Context = FolderContext;

// Hooks
Folder.use = useFolder;
Folder.useValue = useFolderValue;
