import React from 'react';

import { OverlayProps, OverlayType } from 'App/Modules/Overlay/Types';
import { Null } from 'App/Packages/UI/Null';
import { OverlayLifecycle } from './types';

export class Registry {
    private static readonly default: OverlayLifecycle = {
        outOfBounds: () => () => {},
        mount: () => async () => {},
        unmount: () => async () => {},
        update: () => async () => {},
    };

    private static readonly store: Partial<Record<OverlayType, [React.FunctionComponent<OverlayProps>, Partial<OverlayLifecycle>]>> = {};

    public static readonly get = <T extends OverlayType>(type: T): [React.FunctionComponent<OverlayProps<T>>, OverlayLifecycle<T>] => [
        (Registry.store[type]?.[0] as unknown as React.FunctionComponent<OverlayProps<T>>) ?? Null,
        (Registry.store[type]?.[1] as unknown as OverlayLifecycle<T>) ?? Registry.default,
    ];

    public static readonly register = <T extends OverlayType>(
        type: T,
        Component: React.FunctionComponent<OverlayProps<T>>,
        lifecycle: Partial<OverlayLifecycle<T>> = {}
    ) => {
        Registry.store[type] = [Component as unknown as React.FunctionComponent<OverlayProps>, { ...Registry.default, ...lifecycle }];
    };
}
