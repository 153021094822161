import { themeActions } from '__SMART_APP_OLD__/app/modules/Theme/actions';
import {
    themeOptionIds,
    themeOptionIndexForType,
    themeOptionLabelForType,
    themeSettingsOptions,
} from '__SMART_APP_OLD__/app/modules/Theme/constants';
import { getInitialThemeState } from '__SMART_APP_OLD__/app/modules/Theme/getInitialState';
import {
    applyTheme,
    getTheme,
    getThemeClassName,
    initializeTheme,
    isThemeSet,
    setTheme,
} from '__SMART_APP_OLD__/app/modules/Theme/integration';
import { themeReducer } from '__SMART_APP_OLD__/app/modules/Theme/reducer';
import { themeSelectors } from '__SMART_APP_OLD__/app/modules/Theme/selectors';
import { RootThemeProvider, ThemeContext, ThemeProvider } from '__SMART_APP_OLD__/app/modules/Theme/ThemeContext';
import { ThemeActionType, ThemeReduxAction, ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';

export { ThemeActionType as ActionType, ThemeType as Type };

export type { ThemeReduxAction as ReduxAction };

export const get = getTheme;

export const getClassName = getThemeClassName;

export const isSet = isThemeSet;

export const apply = applyTheme;

export const set = setTheme;

export const optionIds = themeOptionIds;

export const optionIndexForType = themeOptionIndexForType;

export const optionLabelForType = themeOptionLabelForType;

export const settingsOptions = themeSettingsOptions;

export const getInitialState = getInitialThemeState;

export const initialize = initializeTheme;

export const selectors = themeSelectors.public;

export const actions = themeActions.public;

export const reducer = themeReducer;

export const Context = ThemeContext;

export const RootProvider = RootThemeProvider;

export const Provider = ThemeProvider;
