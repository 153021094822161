import { initialConsentState } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/constants';
import { ConsentActionType, ConsentEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const consentReducer: Reducer<ConsentEntityTable> = (state, action) => {
    if (!state) return initialConsentState;

    switch (action.type) {
        case ConsentActionType.INITLOADALL: {
            return { ...state, ...action.payload };
        }
        case ConsentActionType.CHANGED: {
            if (action.payload.accepted === state[action.payload.type].accepted) return state;
            return { ...state, [action.payload.type]: { accepted: action.payload.accepted } };
        }
        default:
            return state;
    }
};
