import React, { useState } from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { ReactComponent as MoreInfo } from 'styles/assets/ico-info.svg';
import { ReactComponent as JumpToLive } from 'styles/assets/ico-live.svg';
import { ReactComponent as StopRecord } from 'styles/assets/ico-notification-close.svg';
import { ReactComponent as Pause } from 'styles/assets/ico-pause-v2.svg';
import { ReactComponent as Play } from 'styles/assets/ico-play-v2.svg';
import { ReactComponent as Record } from 'styles/assets/ico-recording.svg';
import { ReactComponent as Restart } from 'styles/assets/ico-start-over.svg';
import { ReactComponent as AudioSubtitle } from 'styles/assets/ico-subtitles.svg';

enum PlayerButtonType {
    PLAY = 'play',
    PAUSE = 'pause',
    MORE_INFO = 'more-info',
    AUDIO_SUBTITLE = 'audio-subtitle',
    RESTART = 'restart',
    JUMP_TO_LIVE = 'jump-to-live',
    RECORD = 'record',
    STOP_RECORD = 'stop-record',
    REWIND = 'rewind',
    FORWARD = 'forward',
    NEXT_EVENT = 'next-event',
    PREV_EVENT = 'prev-event',
}

const iconsMap = {
    [PlayerButtonType.PLAY]: <Play />,
    [PlayerButtonType.PAUSE]: <Pause />,
    [PlayerButtonType.MORE_INFO]: <MoreInfo />,
    [PlayerButtonType.AUDIO_SUBTITLE]: <AudioSubtitle />,
    [PlayerButtonType.RESTART]: <Restart />,
    [PlayerButtonType.JUMP_TO_LIVE]: <JumpToLive />,
    [PlayerButtonType.RECORD]: <Record />,
    [PlayerButtonType.STOP_RECORD]: <StopRecord />,
    [PlayerButtonType.REWIND]: <Icon name={IconName.REWIND} flavour={IconFlavour.SOLID_DARK} />,
    [PlayerButtonType.FORWARD]: <Icon name={IconName.FORWARD} flavour={IconFlavour.SOLID_DARK} />,
    [PlayerButtonType.NEXT_EVENT]: <Icon name={IconName.RIGHT} flavour={IconFlavour.SOLID_DARK} />,
    [PlayerButtonType.PREV_EVENT]: <Icon name={IconName.LEFT} flavour={IconFlavour.SOLID_DARK} />,
};

interface Props {
    className?: string;
    onEnter: () => void;
    caption: string;
    dataTestId?: string;
    type: PlayerButtonType;
}

const PlayerButton = React.forwardRef<Focusable, Props>(({ className = '', onEnter, caption, dataTestId, type }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const btnContainerClassName = `player-button ${className}`;
    const btnIconClassName = `button-icon ${isFocused ? 'focused' : ''}`;

    return (
        <Focusable
            className={btnContainerClassName}
            onEnter={onEnter}
            onFocus={handleFocus}
            onBlur={handleBlur}
            dataTestId={dataTestId}
            ref={ref}
            focusDelay={false}
        >
            <div className={btnIconClassName}>{iconsMap[type]}</div>
            <Text
                className="button-caption"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
                theme={Theme.Type.Dark}
            >
                {caption}
            </Text>
        </Focusable>
    );
});

export { PlayerButton, PlayerButtonType };
