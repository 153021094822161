import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';

const getClassName = (isStrongGradient: boolean, theme: Theme.Type): string => {
    const classlist = ['header-v2'];
    classlist.push(Theme.getClassName('header-v2', theme));
    if (isStrongGradient) classlist.push('header-v2--strong');
    return classlist.join(' ');
};

const useHeaderTheme = (theme?: Theme.Type) => {
    const appTheme = useTheme();
    return theme ?? appTheme;
};

export type HeaderProps = DivPropsExtendable & {
    theme?: Theme.Type;
    strong?: boolean;
};

export type HeaderComponent = React.FunctionComponent<HeaderProps>;

export const Header: HeaderComponent = (props) => {
    const { theme, strong, ...rest } = props;
    const headerTheme = useHeaderTheme(theme);
    return (
        <Theme.Provider theme={headerTheme}>
            <Div {...rest} defaultClassName={getClassName(!!strong, headerTheme)} />
        </Theme.Provider>
    );
};
