import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';

import Elements from '__SMART_APP_OLD__/navigation/Elements';
import Focus, { DIR_DOWN } from '__SMART_APP_OLD__/navigation/Focus';
import { magicRemoteConfig } from '__SMART_APP_OLD__/utils/Constants';

interface Props {
    containerClassName?: string;
}

const { minRemoteDelay } = magicRemoteConfig;
const focusChange = debounce(
    () => {
        Focus.changeFocus(DIR_DOWN);
    },
    minRemoteDelay,
    { leading: false }
);

const StaticMagicRemoteContainer: React.FunctionComponent<Props> = ({
    containerClassName = 'stripes-container',
}): React.ReactElement | any => {
    const [showContainer, setShowContainer] = useState<boolean>(true);

    const onConainerOver = () => {
        focusChange();
    };

    const onConainerOut = () => {
        focusChange.cancel();
    };

    const onMouseMove = (event: any) => {
        event.preventDefault();
        const stripesContainer = Elements?.nodes.find((el) => el?.nodeRef?.className?.includes(containerClassName));
        setShowContainer(stripesContainer?.nodeRef?.scrollTop === 0);
    };

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return showContainer && <div className="magic-remote-container" onMouseOver={onConainerOver} onMouseOut={onConainerOut}></div>;
};

export default StaticMagicRemoteContainer;
