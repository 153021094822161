import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ParentalRatingChangeDocument,
    ParentalRatingChangeFragment,
    ParentalRatingChangeMutationResult,
    ParentalRatingChangeMutationVariables,
} from '__SMART_APP_OLD__/app/api/parentalRating/mutation/parentalRatingChange.generated';
import {
    ParentalRatingLoadManyDocument,
    ParentalRatingLoadManyFragment,
    ParentalRatingLoadManyQueryResult,
} from '__SMART_APP_OLD__/app/api/parentalRating/query/parentalRatingLoadMany.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

/**
 * @namespace API.ParentalRating
 */

export type LoadManyResponse = { parentalRatings: GQL.ParentalRating[] };

/**
 * @memberof API.ParentalRating
 * @description Function that parses response from graphql to LoadManyResponse
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param [response] response from graphql
 * @returns object handling available Age ratings from BE
 */
export const __parseLoadMany = (response?: ParentalRatingLoadManyFragment): LoadManyResponse => {
    if (!response) return { parentalRatings: [] };
    const parentalRatings = (response?.availableParentalRatings?.items ?? []).filter(Boolean) as GQL.ParentalRating[];
    return { parentalRatings };
};

/**
 * @memberof API.ParentalRating
 * @description Loads all available parental ratings
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @returns handling raw response for available Age ratings from BE
 */
export const loadMany = async (): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ParentalRatingLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(ParentalRatingLoadManyDocument, {}, options);
    Error.collect(response);

    return __parseLoadMany(response.data);
};

export type ChangeResponse = { parentalRatingId: GQL.ParentalRatingID };

/**
 * @memberof API.ParentalRating
 * @description Function that parses response from graphql to ChangeResponse
 * @author SmartTVBG@a1.bg
 * @date 20/03/2023
 * @param [response] response from graphql
 * @returns object with the current rating id mapped to the be response
 */
export const __parseChange = (response?: ParentalRatingChangeFragment | null): ChangeResponse => {
    const parentalRatingId = response?.changeProfilePermissions?.profile?.permissions?.parentalRating?.id ?? '';
    return { parentalRatingId };
};

/**
 * @memberof API.ParentalRating
 * @description Changes the selected parental rating for the specified profile
 * @author SmartTVBG@a1.bg
 * @date 20/03/2023
 * @param parentalRatingId id of the selected parental rating
 * @param profileId id of the current profile
 * @returns the new age rating after update from inside the app.
 */
export const change = async (parentalRatingId: string, profileId: string): Promise<ChangeResponse> => {
    const variables: ParentalRatingChangeMutationVariables = { parentalRatingId, profileId };
    const response: ParentalRatingChangeMutationResult = await GraphqlClient.makeGraphqlMutationRequest(
        ParentalRatingChangeDocument,
        variables
    );
    Error.collect(response);

    return __parseChange(response.data);
};
