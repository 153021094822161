import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { SubtitleItem } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Types';
import { Function } from 'App/Packages/Function';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';

const selected =
    (id: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitlesSelect)(getState());
        if (!overlay?.props) return;

        await dispatch(
            Overlay.actions.mount(Overlay.Type.Loading, {
                backgroundColor: BackgroundColor.PRIMARY,
            })
        );
        const { subtitles, onSubtitleSelect, subtitleMenuProps } = overlay.props;
        const subtitle = subtitles.find((opt: SubtitleItem) => opt.id === id);

        await Function.sleep(250);
        await dispatch(Overlay.actions.unmount());
        await dispatch(Overlay.actions.mount(Overlay.Type.SubtitleMenu, subtitleMenuProps, Constants.Ctx.subtitles));
        if (subtitle) {
            dispatch(onSubtitleSelect(subtitle));
        }
    };

const getSubNameById =
    (id: string): Thunk<string | undefined> =>
    (dispatch, getState) => {
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitlesSelect)(getState());
        if (!overlay?.props) return undefined;

        const subs = overlay.props.subtitles.find((sub) => sub.id === id);
        return Translation.selectors.select(subs ? subs.name : '')(getState());
    };

export const Actions = {
    private: {
        selected,
        getSubNameById,
    },
};
