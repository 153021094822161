import { App } from 'App/Modules/App';
import { BrowserAppEngine } from 'App/Modules/App/Implementation/Browser';

export const IsReady = Promise.resolve(true);

export class Browser {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.Browser.Initialize]');
        App.initialize(new BrowserAppEngine());
        return true;
    }
}
