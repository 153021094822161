import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { BulgariaBackEngine } from 'App/Modules/EnhancedBack/Implementation/Bulgaria';
import { Screen } from 'App/Modules/Screen';
import { Login } from 'App/Modules/Screen/Modules/Login';

export class Bulgaria {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Opco.Bulgaria.Initialize]');
        EnhancedBack.initialize(new BulgariaBackEngine());

        Screen.register(Screen.Type.LOGIN, Login.View, Login.lifecycle);
        return true;
    }
}
