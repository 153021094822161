import React from 'react';

import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { FolderItemValue } from 'App/Packages/UI/Folder/Modules/FolderItem/Context/FolderItemContext';

const Default: FolderItemsValue = {
    id: '',
    ref: { current: null },
    ctx: Constants.Ctx,
    ids: new Subscribable.MockedArray<string>([]),
    store: new Subscribable.MockedRecord<FolderItemValue>({}),
    focused: new Subscribable.MockedValue<string>(''),
    disabled: new Subscribable.MockedValue<boolean>(false),
    offset: 0,
    scroll: {} as Scroll,
    focus: () => {},
    __focus: () => {},
};

export type FolderItemsValue = {
    id: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
    ctx: string;
    initial?: string;
    ids: Subscribable.Array<string>;
    store: Subscribable.Record<FolderItemValue>;
    focused: Subscribable.Value<string>;
    disabled: Subscribable.Value<boolean>;
    offset: number;
    scroll: Scroll;
    focus: (id: string) => void;
    enter?: (id: string) => void;
    __focus: (id: string) => void;
};

export type FolderItemsContextModule = React.Context<FolderItemsValue> & { Default: FolderItemsValue };

export const FolderItemsContext: FolderItemsContextModule = Object.assign(React.createContext(Default), { Default });
