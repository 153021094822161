import { Config } from '__SMART_APP_OLD__/app/modules/Config';
import { ParsedConfigServiceConfig } from '__SMART_APP_OLD__/app/modules/Config/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const initialState: ParsedConfigServiceConfig = Config.DEFAULT;

export const configReducer: Reducer<ParsedConfigServiceConfig> = (state, action) => {
    if (!state) return initialState;
    switch (action.type) {
        case ActionType.CONFIG_CHANGE:
            if (!action.payload.config) return state;
            return { ...state, ...action.payload.config };
        default:
            return state;
    }
};
