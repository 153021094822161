import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';

type Props = {
    className?: string;
};

export type ChannelCardLogoModule = React.FunctionComponent<Props>;

export const ChannelCardLogo: ChannelCardLogoModule = (props) => {
    const { id } = useContext(ChannelCardContext);

    const className = `channel-card-v2__logo${props.className ? ` ${props.className}` : ''}`;

    return <ChannelLogo id={id} className={className} />;
};
