import React from 'react';

export type ChildrenProps<T> = {
    items: T[];
    children: ChildRenderer<T>;
};

export type ChildRenderer<T> = (item: T, index: number, items: T[]) => React.ReactNode;

export const Children = <T,>(props: ChildrenProps<T>): ReturnType<React.FunctionComponent> => <>{props.items.map(props.children)}</>;
