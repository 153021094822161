import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

const getClassName = (className?: string): string => {
    const classList = ['folder__header'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type FolderHeaderProps = React.PropsWithChildren<{ className?: string }>;

export type FolderHeaderComponent = React.FunctionComponent<FolderHeaderProps>;

export const FolderHeader: FolderHeaderComponent = (props) => (
    <Text
        className={getClassName(props.className)}
        color={Color.PRIMARY}
        size={FontSize.BODY_1}
        weight={FontWeight.BOLD}
        typeface={Typeface.SANS}
    >
        {props.children}
    </Text>
);
