import React, { useRef } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

import { useBack as useBackHook } from 'App/Packages/Focus/Hooks/useBack';
import { AlertAction, AlertActionModule, AlertActionProps } from 'App/Packages/UI/Alert/AlertAction';
import { AlertActions, AlertActionsModule, AlertActionsProps } from 'App/Packages/UI/Alert/AlertActions';
import { AlertActionsChildrenProps } from 'App/Packages/UI/Alert/AlertActionsChildren';
import { AlertActionsContainerProps } from 'App/Packages/UI/Alert/AlertActionsContainer';
import { AlertActionsContextState } from 'App/Packages/UI/Alert/AlertActionsContext';
import { AlertContext } from 'App/Packages/UI/Alert/AlertContext';
import { AlertText, AlertTextModule, AlertTextProps } from 'App/Packages/UI/Alert/AlertDescription';
import { AlertInfo, AlertInfoModule, AlertInfoProps } from 'App/Packages/UI/Alert/AlertInfo';
import { AlertTitle, AlertTitleModule, AlertTitleProps } from 'App/Packages/UI/Alert/AlertTitle';

export type {
    AlertActionProps,
    AlertActionsChildrenProps,
    AlertActionsContainerProps,
    AlertActionsContextState,
    AlertActionsProps,
    AlertInfoProps,
    AlertTextProps,
    AlertTitleProps,
};

export type AlertProps = {
    zIndex: number;
    children: React.ReactNode;
    onClose: () => void;
    className?: string;
};

export type AlertModule = {
    Title: AlertTitleModule;
    Text: AlertTextModule;
    Info: AlertInfoModule;
    Action: AlertActionModule;
    Actions: AlertActionsModule;
} & React.FunctionComponent<AlertProps>;

const getClassName = (className?: string) => {
    if (className) {
        return `alert ${className}`;
    }
    return 'alert';
};

export const Alert: AlertModule = (props) => {
    const onClose = useRef(props.onClose);
    onClose.current = props.onClose;

    useBackHook(onClose.current);

    return (
        <Layout className={getClassName(props?.className)} backgroundColor={BackgroundColor.OVERLAY} zIndex={props.zIndex}>
            <AlertContext.Provider onClose={onClose}>{props.children}</AlertContext.Provider>
        </Layout>
    );
};

Alert.Title = AlertTitle;
Alert.Text = AlertText;
Alert.Info = AlertInfo;
Alert.Action = AlertAction;
Alert.Actions = AlertActions;
