import {
    SuggestionActionType,
    SuggestionLoadedAction,
    SuggestionLoadedActionPayload,
    SuggestionReduxAction,
    SuggestionClearAction,
    SuggestionState,
} from 'App/Modules/Data/Search/Suggestion/Types';

import { Api } from 'App/Modules/Data/Search/Suggestion/Api';
import { actions as A } from 'App/Modules/Data/Search/Suggestion/Store/Actions';
import { selectors as S } from 'App/Modules/Data/Search/Suggestion/Store/Selectors';
import { reducer } from 'App/Modules/Data/Search/Suggestion/Store/Reducer';
import { getInitialState, initialState } from 'App/Modules/Data/Search/Suggestion/Store/State';

export type {
    SuggestionLoadedAction as LoadedAction,
    SuggestionLoadedActionPayload as LoadedActionPayload,
    SuggestionReduxAction as ReduxAction,
    SuggestionClearAction as ClearAction,
    SuggestionState as State,
};

export { SuggestionActionType as ActionType, Api, reducer, getInitialState, initialState };

export const actions = A.public;
export const selectors = S.public;
