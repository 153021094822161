import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelVodTransactionMutationVariables = Types.Exact<{
  input: Types.CancelVodTransactionInput;
}>;


export type CancelVodTransactionMutation = { __typename?: 'Nexx4Mutations', cancelVODTransaction: { __typename?: 'CancelVODTransactionPayload', success: boolean } };

export type CancelVodTransactionFragment = { __typename?: 'Nexx4Mutations', cancelVODTransaction: { __typename?: 'CancelVODTransactionPayload', success: boolean } };

export const CancelVodTransactionFragmentDoc = gql`
    fragment cancelVODTransaction on Nexx4Mutations {
  cancelVODTransaction(input: $input) {
    success
  }
}
    `;
export const CancelVodTransactionDocument = gql`
    mutation cancelVODTransaction($input: CancelVODTransactionInput!) {
  ...cancelVODTransaction
}
    ${CancelVodTransactionFragmentDoc}`;
export type CancelVodTransactionMutationFn = Apollo.MutationFunction<CancelVodTransactionMutation, CancelVodTransactionMutationVariables>;

/**
 * __useCancelVodTransactionMutation__
 *
 * To run a mutation, you first call `useCancelVodTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVodTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVodTransactionMutation, { data, loading, error }] = useCancelVodTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelVodTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CancelVodTransactionMutation, CancelVodTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelVodTransactionMutation, CancelVodTransactionMutationVariables>(CancelVodTransactionDocument, options);
      }
export type CancelVodTransactionMutationHookResult = ReturnType<typeof useCancelVodTransactionMutation>;
export type CancelVodTransactionMutationResult = Apollo.MutationResult<CancelVodTransactionMutation>;
export type CancelVodTransactionMutationOptions = Apollo.BaseMutationOptions<CancelVodTransactionMutation, CancelVodTransactionMutationVariables>;