import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Mask } from 'App/Packages/UI/Mask';

export type CardFolderTitleProps = React.PropsWithChildren<{}>;

export type CardFolderTitleComponent = React.FunctionComponent<CardFolderTitleProps>;

export const CardFolderTitle: CardFolderTitleComponent = (props) => (
    <Text
        className="folder-card-v3__title"
        typeface={Typeface.SANS}
        size={FontSize.BODY_1}
        weight={FontWeight.BOLD}
        color={Color.PRIMARY}
        theme={Theme.Type.Dark}
    >
        <Mask.Title>{props.children}</Mask.Title>
    </Text>
);
