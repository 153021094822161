import { Focus } from 'App/Packages/Focus';
import React from 'react';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Screen } from 'App/Modules/Screen';
import { Header } from 'App/Modules/Header';
import { MenuItemType } from 'App/Types/Menu';
import { MyLibraryData } from 'App/Modules/MyLibraryData';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { UI } from 'App/Packages/UI';
import { Folder } from 'App/Modules/Folder';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { MoreCard } from 'App/Modules/Folder/Components/MoreCard';
import { actions } from 'App/Modules/Screen/Modules/MyLibrary/Store/actions';
import { MyLibraryQuota } from 'App/Modules/Screen/Modules/MyLibrary/View/components/MyLibraryQuota';
import { MyLibraryHeader } from 'App/Modules/Screen/Modules/MyLibrary/View/components/MyLibraryHeader';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export const View: React.FunctionComponent<Screen.Props<Screen.Type.MyLibrary>> = (props) => {
    const dispatch = useDispatch();

    const folders = useSelector(MyLibraryData.selectors.selectFoldersIds);
    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex} className="my-library">
            <Header.Widget active={MenuItemType.MyLibrary} initial={MenuItemType.MyLibrary} strong />
            <UI.Folder.List offsetTop={UI.Folder.Constants.List.Scroll.Offset.Header}>
                <MyLibraryHeader />
                {folders.length === 0 && (
                    <Text
                        className="my-library__empty-content"
                        typeface={Typeface.SANS}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                    >
                        EMPTY_CONTENT_PAGES_GLOBAL
                    </Text>
                )}
                {folders.map((id) => {
                    const selector = MyLibraryData.selectors.selectFolder(id);
                    return (
                        <UI.Folder key={id} id={id}>
                            <UI.Folder.Header>
                                <UI.Selector selector={Folder.selectors.selectTitle(selector)} />
                            </UI.Folder.Header>
                            <MyLibraryQuota selector={selector} initial={props.focusedFolderId === id ? props.focusedItemId : undefined} />
                            <UI.Folder.Items
                                initial={props.focusedFolderId === id ? props.focusedItemId : undefined}
                                onEnter={(itemId) => dispatch(actions.private.selected(id, itemId))}
                            >
                                <UI.Selector selector={Folder.selectors.selectItemsIds(selector)}>
                                    {(ids) =>
                                        ids.map((itemId) => (
                                            <FolderCardItem key={itemId} selector={Folder.selectors.selectItem(selector)(itemId)} />
                                        ))
                                    }
                                </UI.Selector>
                                <MoreCard id={UI.Folder.Constants.Item.Id.More} selector={selector} />
                            </UI.Folder.Items>
                        </UI.Folder>
                    );
                })}
            </UI.Folder.List>
        </Layout>
    );
};
