import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const selected = (option: Startup.Position): Thunk<void> => (dispatch) => {
    dispatch(Startup.actions.changed(option));
    dispatch(Fti.actions.next(Screen.Type.STARTUP_BEHAVIOR));
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    private: {
        selected,
    },
    lifecycle: {
        mount,
    },
} as const;
