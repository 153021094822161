import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';

import { DeliveryProtocols } from 'analytics/logging/classes/ViewData';

// ------------------------------------
// Safe destructure
// ------------------------------------

// Overwrites non empty properties from sources into target
const isObject = (item) => item && typeof item === 'object' && !Array.isArray(item);

// Fills in empty/missing fields in JSON with value provided
// so we can avoid errors whilst server catches up
// IMPLEMENTATION : let {genre: {title}} = safeDestructure(metadata, {genre: {title: ''}})
// AFTER SERVER FULLY WORKS : const {genre: {title}} = metadata
// TODO: this I have to check
/**
 *
 * @param target
 * @param sources
 * @returns {target}
 */
function safeDestructureFunc(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if ((!target[key] && target[key] !== false) || (isEmpty(target[key]) && target[key] instanceof Array)) {
                Object.assign(target, { [key]: source[key] });
            }
            safeDestructureFunc(target[key], source[key]);
        });
    }
    return safeDestructureFunc(target, ...sources);
}

/**
 *
 * @param target
 * @param sources
 * @returns {target}
 */
export const safeDestructure = (target, ...sources) => {
    target = cloneDeep(target);
    sources = cloneDeep(sources);
    return safeDestructureFunc(target, ...sources);
};

// ------------------------------------
// Other
// ------------------------------------

export const getDeliveryProtocolFromUrl = (streamingUrl) => {
    if (streamingUrl.indexOf('.mpd') !== -1) return DeliveryProtocols.DASH;
    if (streamingUrl.indexOf('.m3u8') !== -1) return DeliveryProtocols.HLS;
    if (streamingUrl.indexOf('.ism') !== -1) return DeliveryProtocols.MSS;
    return undefined;
};

export const generateUuid = () => {
    const dec2hex = [];
    for (let i = 0; i <= 15; i += 1) {
        dec2hex[i] = i.toString(16);
    }

    let uuid = '';
    for (let i = 1; i <= 36; i += 1) {
        if (i === 9 || i === 14 || i === 19 || i === 24) {
            uuid += '-';
        } else if (i === 15) {
            uuid += 4;
        } else if (i === 20) {
            uuid += dec2hex[(Math.random() * 4) | (0 + 8)];
        } else {
            uuid += dec2hex[(Math.random() * 15) | 0];
        }
    }
    return uuid;
};
