import { useEffect, useState } from 'react';

import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderMainShadows = () => [boolean, boolean];

export const useHeaderMainShadows: UseHeaderMainShadows = () => {
    const items = HeaderItems.use();
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(false);

    useEffect(() => {
        const container = items.ref.current;
        if (!container) return undefined;

        const handler = () => {
            // -2 is a magic number because
            // scrollLeft + clientWidth can be 1 pixel off from scrollWidth
            // due to rounding errors
            // so we use 2 to make sure we don't show the shadow when it's not needed
            setEnd(container.scrollLeft + container.clientWidth <= container.scrollWidth - 2);
            setStart(container.scrollLeft !== 0);
        };

        container.addEventListener('scroll', handler);
        return () => container.removeEventListener('scroll', handler);
    }, [items.ref]);

    return [start, end];
};
