import { SearchContentType } from '__SMART_APP_OLD__/api/graphql/types';
import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectIsLiveChannelEventRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { toMessageInbox } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { toNowOnTV } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/actions';
import { toTvGuide } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { MenuIconItemType, MenuItemType } from 'App/Types/Menu';

const menuSelected =
    (id: string, active: MenuItemType): Thunk<Promise<void>> =>
    async (dispatch) => {
        switch (id) {
            case MenuItemType.Home:
                return dispatch(toHome());
            case MenuItemType.LiveTV:
                return dispatch(toLiveTV());
            case MenuItemType.MyLibrary:
                return dispatch(toMyLibrary());
            case MenuItemType.NowOnTV:
                return dispatch(toNowOnTv());
            case MenuItemType.TVGuide:
                return dispatch(toTVGuide());
            case MenuItemType.VideoStore:
                return dispatch(toVideoStore());
            case MenuItemType.Playground:
                return dispatch(toPlayground());
            case MenuIconItemType.ChannelLists:
                return dispatch(toChannelLists());
            case MenuIconItemType.MessageInbox:
                return dispatch(toInbox());
            case MenuIconItemType.Search:
                return dispatch(toSearch(active));
            case MenuIconItemType.Settings:
                return dispatch(toSettings());
            default:
                return undefined;
        }
    };

const toHome = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.clearReduxBeforeNavigation());
    const menuItem = { id: MenuItemType.Home, title: 'Home' };
    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_HOME, { menuItem }));
    CustomHistory.go(`/page/${MenuItemType.Home}`);
};

const toMyLibrary = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.clearReduxBeforeNavigation());
    const menuItem = { id: MenuItemType.MyLibrary, title: 'My Library' };
    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_MY_LIBRARY, { menuItem }));
    dispatch(History.actions.push());
    dispatch(Screen.actions.mount(Screen.Type.MyLibrary, {}));
};

const toVideoStore = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(Screen.actions.mount(Screen.Type.VideoStore, {}));
};

const toLivePlayer = (): Thunk<void> => (dispatch, getState) => {
    dispatch(History.actions.clearReduxBeforeNavigation());
    const id = selectChannelLiveId(getState());
    const menuItem = { id: MenuItemType.LiveTV, title: 'Live TV' };
    const event = getUIActionEvent(UIActionEvents.PLAY_LIVE, { menuItem, id });
    LoggingService.getInstance().logEvent(event);
    playChannel(id);
};

const toLiveTV = (): Thunk<void> => (dispatch, getState) => {
    dispatch(History.actions.clearReduxBeforeNavigation());
    const isRestricted = selectIsLiveChannelEventRestricted(getState());
    if (isRestricted) return openPinOverlay(() => dispatch(toLivePlayer()));
    dispatch(toLivePlayer());
    return undefined;
};

const toTVGuide = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(toTvGuide());
};

const toNowOnTv = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(toNowOnTV());
};

const toPlayground = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(Screen.actions.mount(Screen.Type.MyLibrary, {}));
};

const toChannelLists = (): Thunk<void> => (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.ChannelListSelect, {}));
};

const toInbox = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(toMessageInbox());
};

const toSearch =
    (active: MenuItemType): Thunk<void> =>
    (dispatch) => {
        dispatch(History.actions.clearReduxBeforeNavigation());
        const isOnDemand = active === MenuItemType.MyLibrary || active === MenuItemType.VideoStore;
        const context = isOnDemand ? SearchContentType.OnDemand : SearchContentType.Events;
        const menuItem = { id: MenuIconItemType.Search, title: 'Search' };
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_SEARCH, { menuItem }));
        dispatch(Screen.actions.mount(Screen.Type.Search, { context }));
    };

const toSettings = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(Screen.actions.mount(Screen.Type.SETTINGS, {}));
};

export const actions = {
    private: {
        menuSelected,
    },
};
