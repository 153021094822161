import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { selectChannelLiveTVEntitlement } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import {
    selectChannelLiveId,
    selectSelectedChannelListChannelIds,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectChannelEventIds,
    selectEPGFutureLimit,
    selectEPGPastLimit,
    selectEvent,
    selectEventBy,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { selectIsLiveTvPinActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { useIsMagicMode } from '__SMART_APP_OLD__/common/useIsMagicMode';
import { handleChannelSwitching } from '__SMART_APP_OLD__/components/channelSwitchDialog';
import SnackBar from '__SMART_APP_OLD__/components/common/SnackBar';
import { Channels } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/Channels/Channels';
import { ProgramInfo } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramInfo';
import { ProgramsData } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramsData';
import { selectChannelIdMiniEpgGenerator, selectModeMiniEpg, selectTimeMiniEpg } from '__SMART_APP_OLD__/components/EPG/MiniEpg/selectors';
import { MiniEpgMode } from '__SMART_APP_OLD__/components/EPG/MiniEpg/types';
import { isFullMode, isZapperMode } from '__SMART_APP_OLD__/components/EPG/MiniEpg/utils';
import { Elements, EventElement } from '__SMART_APP_OLD__/components/EPG/types';
import { getElementData, toDetailPage } from '__SMART_APP_OLD__/components/EPG/utils';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';
import { getEPGFutureLimit, getEPGPastLimit } from '__SMART_APP_OLD__/utils/TvGuideUtils';

import { Overlay } from 'App/Modules/Overlay';
import { Calc } from 'App/Packages/Calc';

interface Props {
    onClose: (isRestricted?: boolean) => void;
    isVodOrCatchup?: boolean;
}

export const DataGrid: React.FunctionComponent<Props> = (props) => {
    const store = useStore();
    const { onClose } = props;
    const isMagicMode = useIsMagicMode();
    const liveChannelId = useSelector(selectChannelLiveId);
    const channelsIdsFromChannelList = useSelector(selectSelectedChannelListChannelIds);
    const [channelId, setChannelId] = useState(selectChannelIdMiniEpgGenerator(channelsIdsFromChannelList, liveChannelId));
    const [mode, setMode] = useState(selectModeMiniEpg);
    const [time, setTime] = useState(selectTimeMiniEpg);
    const [snackBarEnabled, setSnackBarEnabled] = useState(true);
    const [isHorizontalFastScroll, setIsHorizontalFastScroll] = useState(false);
    const pastLimit = useRef(getEPGPastLimit().getTime() + 60 * 1000);
    const futureLimit = useRef(getEPGFutureLimit().getTime() - 60 * 1000);
    const isZapper = isZapperMode(mode);
    const isFull = isFullMode(mode);
    const isOverlayLayerActive = useSelector(Overlay.selectors.selectIsLayerActive);
    const pinSessionActive = useSelector(selectIsLiveTvPinActive);

    const showZapperHandler = useCallback(() => setMode(MiniEpgMode.ZAPPER), []);
    const showFullHandler = useCallback(() => setMode(MiniEpgMode.FULL), []);

    const changeChannelHandler = useCallback(
        (direction: 1 | -1) => {
            if (!isOverlayLayerActive) return;
            setChannelId((id) => {
                const currentIndex = channelsIdsFromChannelList.indexOf(id);
                const { length } = channelsIdsFromChannelList;
                const index = (((currentIndex + direction) % length) + length) % length;
                return channelsIdsFromChannelList[index];
            });
        },
        [channelsIdsFromChannelList, isOverlayLayerActive]
    );

    const changeEventHandler = useCallback(
        (direction: 1 | -1) => {
            if (!isOverlayLayerActive) return;
            setTime((date) => {
                const state = store.getState();
                const future = selectEPGFutureLimit(state);
                const past = selectEPGPastLimit(state);
                const current = selectEventBy(channelId, date, { equal: EqualityCompare.START })(state);
                if (!current) return date;
                const channelEventIds = selectChannelEventIds(channelId)(state);
                const currentIndex = channelEventIds.indexOf(current?.id ?? '');
                const newIndex = currentIndex + direction;
                const id = channelEventIds[newIndex];
                const event = selectEvent(id)(state);
                if (!event || event.isLoading) return date;
                // to ensure there will be event in that time when we change channel
                return Calc.clamp(past, event.start, future);
            });
        },
        [isOverlayLayerActive, store, channelId]
    );

    const changeFastScrollTimeHandler = useCallback(
        (direction = 0) => {
            if (!isOverlayLayerActive) return;
            setTime((date) => {
                const old = new Date(date);
                const newDate = new Date(date);
                newDate.setHours(newDate.getHours() + direction, 0, 0);
                const diff = newDate.getHours() - old.getHours();
                // When timezone changes increment with 1/-1 won't change anything so we do it 2/-2
                if (diff === 0) newDate.setHours(newDate.getHours() + direction * 2, 0, 0);
                if (newDate.getTime() > pastLimit.current && newDate.getTime() < futureLimit.current) return newDate.getTime();
                return date;
            });
        },
        [isOverlayLayerActive]
    );

    const selectEventHandler = useCallback(
        (event: EventElement) => {
            if (!event.eventId || event.isLoading || event.isNoInfo || !isOverlayLayerActive) return;
            if (event.isLive) {
                const isSubscribed = selectChannelLiveTVEntitlement(event.channelId)(store.getState());
                const isRestricted = (!pinSessionActive && event.restricted) || !isSubscribed;
                if (onClose) onClose(!isRestricted);
                if (event.isNowPlaying && !props.isVodOrCatchup) {
                    if (Player.getTimeshift()) {
                        Player.seekToLive(true);
                    }
                    return;
                }

                Preferences.channelToBeChanged = false;
                handleChannelSwitching(null, event.channelId, onClose, !isRestricted || !!props.isVodOrCatchup);
                handleChannelSwitching(null, event.channelId, onClose, !isRestricted);
                return;
            }
            if (event.restricted) {
                Focus.stash();
                setSnackBarEnabled(false);
                openPinOverlay(
                    () => toDetailPage(event),
                    () => {
                        Focus.restore();
                        setSnackBarEnabled(true);
                    },
                    PinAction.ENTER,
                    PinSessionType.PIN_LIVE_TV
                );
            } else {
                toDetailPage(event);
            }
        },
        [isOverlayLayerActive, onClose, pinSessionActive, store, props.isVodOrCatchup]
    );

    const setChannelIdCallback = useCallback((id: string) => setChannelId(id), []);

    useEffect(() => {
        if (!isMagicMode || !isOverlayLayerActive) return undefined;
        const clickHandler = (e: MouseEvent) => {
            const element = getElementData(e.target);
            switch (element?.type) {
                case Elements.EVENT:
                    selectEventHandler(element);
                    break;
                case Elements.CHANNEL:
                    setChannelId(element.channelId);
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('click', clickHandler);
        return () => {
            document.removeEventListener('click', clickHandler);
        };
    }, [selectEventHandler, isMagicMode, isOverlayLayerActive]);

    useEffect(() => {
        if (isZapper) setTime(Date.now());
    }, [isZapper]);

    return (
        <>
            {snackBarEnabled && <SnackBar callback={setChannelIdCallback} isLight />}
            {!isHorizontalFastScroll && (
                <ProgramsData
                    channelId={channelId}
                    isVodOrCatchup={props.isVodOrCatchup}
                    time={time}
                    isZapperMode={isZapper}
                    isMagicMode={isMagicMode}
                    onShowFull={showFullHandler}
                    onShowZapper={showZapperHandler}
                    onClose={onClose}
                    onFastScrollTimeChange={changeFastScrollTimeHandler}
                    onEventChange={changeEventHandler}
                    onSelectEvent={selectEventHandler}
                />
            )}
            <Channels
                channelId={channelId}
                isMagicMode={isMagicMode}
                isFullMode={isFull}
                onChannelChange={changeChannelHandler}
                onFastScrollChangeHandler={setIsHorizontalFastScroll}
            />
            <ProgramInfo time={time} />
        </>
    );
};
