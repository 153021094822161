import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { updateReplayPermission } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { ChannelReplayPermissionRelationEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { getSelectedChannelsLength } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/utils';
import { ViewControlCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/ViewControlCtx';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { ftiTranslateElements } from '__SMART_APP_OLD__/utils/Constants';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const accept = (channels: ChannelReplayPermissionRelationEntityTable): Thunk<void> => async (dispatch, getState) => {
    const screen = Screen.selectors.selectTyped(Screen.Type.VIEW_CONTROL)(getState());
    if (!screen) return;
    const screenProps = screen?.props;
    const isFromSettings = screenProps.from === 'settings';

    if (!isFromSettings) {
        dispatch(History.actions.pop());
        await dispatch(Fti.actions.next(Screen.Type.REPLAY_SELECTION));

        return;
    }

    if (getSelectedChannelsLength(channels) < 1) {
        dispatch(textNotificationShow(ftiTranslateElements.viewControl.notifications.noChannelsSelected));

        return;
    }

    await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(updateReplayPermission(channels));
    await dispatch(Overlay.actions.unmount());

    dispatch(History.actions.pop());
};

const back = (
    initialChannels: ChannelReplayPermissionRelationEntityTable,
    channels: ChannelReplayPermissionRelationEntityTable
): Thunk<void> => (dispatch, getState) => {
    const ctx = Screen.selectors.selectCtx(getState());
    const areChangesMade = JSON.stringify(initialChannels) !== JSON.stringify(channels);

    if (ctx === ViewControlCtx.Grid && areChangesMade) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.VIEW_CONTROL, ViewControlCtx.Confirm));
        return;
    }

    dispatch(History.actions.pop());
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === ViewControlCtx.Grid && event.y === -1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.VIEW_CONTROL, ViewControlCtx.Confirm));
    }
    if (ctx === ViewControlCtx.Confirm && event.y === 1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.VIEW_CONTROL, ViewControlCtx.Grid));
    }
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.VIEW_CONTROL, ViewControlCtx.Confirm));
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
};

export const viewControlActions = {
    private: {
        accept,
        back,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
