import { KeyComponent, KeyProps, KeySize, KeyVariant, Key as Root } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import {
    KeyLabel,
    KeyLabelComponent,
    KeyLabelProps,
    KeyLabelSize,
    KeyLabelVariant,
} from 'App/Packages/UI/Keyboard/Components/Key/KeyLabel';

import {
    KeyArrowLeft,
    KeyArrowLeftComponent,
    KeyArrowLeftIconComponent,
    KeyArrowLeftIconProps,
    KeyArrowLeftProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowLeft';
import {
    KeyArrowRight,
    KeyArrowRightComponent,
    KeyArrowRightIconComponent,
    KeyArrowRightIconProps,
    KeyArrowRightProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowRight';
import {
    KeyBackspace,
    KeyBackspaceComponent,
    KeyBackspaceIconComponent,
    KeyBackspaceIconProps,
    KeyBackspaceProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Backspace';
import {
    KeyCapsLock,
    KeyCapsLockComponent,
    KeyCapsLockIconComponent,
    KeyCapsLockIconProps,
    KeyCapsLockProps,
} from 'App/Packages/UI/Keyboard/Components/Key/CapsLock';
import {
    KeyEnter,
    KeyEnterComponent,
    KeyEnterIconComponent,
    KeyEnterIconProps,
    KeyEnterProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Enter';
import {
    KeyLanguage,
    KeyLanguageComponent,
    KeyLanguageIconComponent,
    KeyLanguageIconProps,
    KeyLanguageProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Language';
import {
    KeySpace,
    KeySpaceComponent,
    KeySpaceIconComponent,
    KeySpaceIconProps,
    KeySpaceProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Space';

export type {
    KeyArrowLeftComponent,
    KeyArrowLeftIconComponent,
    KeyArrowLeftIconProps,
    KeyArrowLeftProps,
    KeyArrowRightComponent,
    KeyArrowRightIconComponent,
    KeyArrowRightIconProps,
    KeyArrowRightProps,
    KeyBackspaceComponent,
    KeyBackspaceIconComponent,
    KeyBackspaceIconProps,
    KeyBackspaceProps,
    KeyCapsLockComponent,
    KeyCapsLockIconComponent,
    KeyCapsLockIconProps,
    KeyCapsLockProps,
    KeyComponent,
    KeyEnterComponent,
    KeyEnterIconComponent,
    KeyEnterIconProps,
    KeyEnterProps,
    KeyLabelComponent,
    KeyLabelProps,
    KeyLabelSize,
    KeyLabelVariant,
    KeyLanguageComponent,
    KeyLanguageIconComponent,
    KeyLanguageIconProps,
    KeyLanguageProps,
    KeyProps,
    KeySize,
    KeySpaceComponent,
    KeySpaceIconComponent,
    KeySpaceIconProps,
    KeySpaceProps,
    KeyVariant,
};

export const Key = Object.assign(Root, {
    Label: KeyLabel,
    ArrowLeft: KeyArrowLeft,
    ArrowRight: KeyArrowRight,
    Backspace: KeyBackspace,
    CapsLock: KeyCapsLock,
    Enter: KeyEnter,
    Language: KeyLanguage,
    Space: KeySpace,
});
