import { HouseholdEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/types';

export const initialHouseholdState: HouseholdEntityTable = {
    mqttTopics: [],
    mqttBrokerUrl: null,
    recordingQuota: null,
    householdId: '',
    maxNumberOfConfirmedReplayChannels: 0,
    onboardingInfo: null,
};
