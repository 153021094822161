import { Mask } from 'App/Packages/UI/Mask';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import React from 'react';

const getClassName = (props: DetailTitleProps): string => {
    const classList = ['detail__title'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailTitleProps = {
    typeface?: Typeface;
    size?: FontSize;
    weight?: FontWeight;
    color?: Color;
    theme?: Theme.Type | null;
    isFocused?: boolean;
    className?: string;
    children?: React.ReactNode;
    customColor?: string;
};

export type DetailTitleComponent = React.FunctionComponent<DetailTitleProps>;

export const DetailTitle: React.FunctionComponent<DetailTitleProps> = (props) => {
    return (
        <Text
            className={getClassName(props)}
            typeface={props.typeface ?? Typeface.SERIF}
            size={props.size ?? FontSize.TITLE_1}
            weight={props.weight ?? FontWeight.BOLD}
            color={props.color ?? Color.PRIMARY}
            theme={props.theme}
            isFocused={props.isFocused}
            customColor={props.customColor}
        >
            <Mask.Title>{props.children}</Mask.Title>
        </Text>
    );
};
