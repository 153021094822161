import React from 'react';

import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/StartupBehaviorScreen/actions';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const StartupBehaviorScreenOptions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { focused, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items: Startup.optionIds,
    });

    return (
        <>
            {Startup.optionIds.map((id) => (
                <Button
                    id={id}
                    key={id}
                    ctx={Focus.Default}
                    className="startup-behavior__option"
                    width={ButtonWidth.FULL}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === id && !isDisabled}
                    onEnter={(option) => dispatch(actions.private.selected(option))}
                    onHover={focus}
                >
                    {Startup.optionLabelForPosition[id]}
                </Button>
            ))}
        </>
    );
};
