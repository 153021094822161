import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { EventCollectionItemFragmentDoc } from '../event/eventCollectionItem.generated';
export type RecordingCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type RecordingCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type RecordingCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type RecordingCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type RecordingCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording', id: string, status: Types.RecordingStatus, event: { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename?: 'EventEntitlements', pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any } | null } };

export type RecordingCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type RecordingCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type RecordingCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type RecordingCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type RecordingCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type RecordingCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type RecordingCollectionItemFragment = RecordingCollectionItem_Banner_Fragment | RecordingCollectionItem_Channel_Fragment | RecordingCollectionItem_ChannelProduct_Fragment | RecordingCollectionItem_Event_Fragment | RecordingCollectionItem_NetworkRecording_Fragment | RecordingCollectionItem_ProductBundle_Fragment | RecordingCollectionItem_Series_Fragment | RecordingCollectionItem_VodAsset_Fragment | RecordingCollectionItem_VodFolder_Fragment | RecordingCollectionItem_VodProduct_Fragment | RecordingCollectionItem_VodSeries_Fragment;

export const RecordingCollectionItemFragmentDoc = gql`
    fragment recordingCollectionItem on ContentItem {
  ... on NetworkRecording {
    id
    status
    event {
      ...eventCollectionItem
    }
  }
}
    ${EventCollectionItemFragmentDoc}`;