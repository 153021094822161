import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailMetaProps = DivPropsExtendable;

export type DetailMetaComponent = React.FunctionComponent<DetailMetaProps>;

export const DetailMeta: DetailMetaComponent = (props) => {
    return <Div className="detail__meta" {...props} />;
};
