import { useEffect, useState } from 'react';

import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { VisibilityEvent } from '__SMART_APP_OLD__/types/events';

export const useIsMagicMode = (): boolean => {
    const [visible, setVisible] = useState(Focus.isMagicMode);

    useEffect(() => {
        const visibilityChangeHandler = (event: VisibilityEvent) => {
            setVisible(event.detail.visibility);
        };
        document.addEventListener('cursorStateChange', visibilityChangeHandler);
        return () => document.removeEventListener('cursorStateChange', visibilityChangeHandler);
    }, []);

    return visible;
};
