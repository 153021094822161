import {
    KeyArrowRight as Root,
    KeyArrowRightComponent,
    KeyArrowRightProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowRight/KeyArrowRight';
import {
    KeyArrowRightIcon,
    KeyArrowRightIconInsance,
    KeyArrowRightIconComponent,
    KeyArrowRightIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowRight/KeyArrowRightIcon';

export type { KeyArrowRightComponent, KeyArrowRightProps, KeyArrowRightIconComponent, KeyArrowRightIconProps };

const KeyArrowRight = Object.assign(Root, {
    Icon: Object.assign(KeyArrowRightIcon, {
        Instance: KeyArrowRightIconInsance,
    }),
});

export { KeyArrowRight };
