import React from 'react';

import { Env } from 'App/Env';
import { UI } from 'App/Packages/UI';
import { MenuIconItemType } from 'App/Types/Menu';

const HeaderIconItemsGeneral: HeaderIconItemsModule = () => (
    <>
        <UI.Header.IconItem id={MenuIconItemType.ChannelLists}>
            <UI.Header.IconItem.ChannelLists />
        </UI.Header.IconItem>
        <UI.Header.IconItem id={MenuIconItemType.MessageInbox}>
            <UI.Header.IconItem.MessageInbox />
        </UI.Header.IconItem>
        <UI.Header.IconItem id={MenuIconItemType.Search}>
            <UI.Header.IconItem.Search />
        </UI.Header.IconItem>
        <UI.Header.IconItem id={MenuIconItemType.Settings}>
            <UI.Header.IconItem.Settings />
        </UI.Header.IconItem>
    </>
);

const HeaderIconItemsBusiness: HeaderIconItemsModule = () => (
    <>
        <UI.Header.IconItem id={MenuIconItemType.Settings}>
            <UI.Header.IconItem.Settings />
        </UI.Header.IconItem>
    </>
);

export type HeaderIconItemsProps = {};

export type HeaderIconItemsModule = React.FunctionComponent<HeaderIconItemsProps>;

export const HeaderIconItems: HeaderIconItemsModule = Env.isBusiness ? HeaderIconItemsBusiness : HeaderIconItemsGeneral;
