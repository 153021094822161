import { ContentMarkerType } from 'App/Types/ContentMarker';
import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import React from 'react';

const getClassName = (props: DetailContentMarkerProps): string => {
    const classList = ['detail__content-marker'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailContentMarkerProps = {
    marker?: ContentMarkerType | null;
    label?: string;
    className?: string;
};

export type DetailContentMarkerComponent = React.FunctionComponent<DetailContentMarkerProps>;

export const DetailContentMarker: DetailContentMarkerComponent = (props) => {
    return <ContentMarker className={getClassName(props)} marker={props.marker} label={props.label} />;
};
