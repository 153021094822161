import { useEffect, useRef } from 'react';

export const useOnBlur = (onBlur: () => void, isFocused: boolean, isActive: boolean) => {
    const hasBeenFocused = useRef(false);
    const isActiveRef = useRef(isActive);
    const onBlurRef = useRef(onBlur);

    isActiveRef.current = isActive;
    onBlurRef.current = onBlur;

    useEffect(() => {
        if (isFocused) hasBeenFocused.current = true;
        if (isFocused || !hasBeenFocused.current || !isActiveRef.current) return;
        onBlurRef.current();
    }, [isFocused]);
};
