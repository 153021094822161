import { Folder } from 'App/Modules/Folder';
import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';
import { GetVodFolderDocument } from 'App/Modules/VodFolderData/Api/query/getVodFolder.generated';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';

import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadVariables = {
    id: string;
    profileId: string;
    folderListFirst: number;
    folderListAfter: string | null;
    folderFirst: number;
    folderAfter: string | null;
    thumbnailHeight: number;
    backgroundHeight: number;
    backgroundWidth: number;
    channelLogoHeight: number;
    channelLogoWidth: number;
};

const __load = async (variables: LoadVariables, folderList: FolderListFragment | null = null): Promise<FolderListFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response = await GraphqlClient.makeGraphqlRequest(GetVodFolderDocument, variables, options);
    Error.collect(response);
    const page = response.data?.vodFolder?.content?.folders.pageInfo;
    if (!page) return folderList;
    const data = response.data?.vodFolder?.content;
    if (!data) return folderList;
    const next = Folder.Util.merge(folderList, data);
    if (!page.hasNextPage) return next;
    const nextCursor = page.endCursor;
    if (!nextCursor) return next;
    const nextVariables = { ...variables, folderListAfter: nextCursor };
    return __load(nextVariables, next);
};

export const load = async (vars: LoadVariables) => {
    const response = await __load(vars);
    return response;
};
