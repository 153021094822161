import React from 'react';

type Props = {
    isFocused: boolean;
} & React.SVGProps<SVGSVGElement>;

type ShowMoreComponent = React.FunctionComponent<Props>;

const getClassName = (isFocused: boolean, className: string | undefined) => {
    const classNames = ['show-more'];
    if (isFocused) classNames.push('show-more--focused');
    if (className) classNames.push(className);
    return classNames.join(' ');
};

export const ShowMore: ShowMoreComponent = (props) => {
    const { isFocused, className, ...rest } = props;

    return (
        <svg className={getClassName(isFocused, className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9Z"
                className="show-more__background"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 11V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H13V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V13H9C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11H11Z"
                className="show-more__plus"
            />
        </svg>
    );
};
