import { Env } from 'App/Env';
import { KeyboardContext } from 'App/Modules/Keyboard/Context';
import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { Schema } from 'App/Modules/Keyboard/Schema';
import React, { useMemo } from 'react';

export type ProviderProps = {
    instance?: KeyboardElement;
    children: React.ReactNode;
};

const getSchema = () => {
    if (Env.IsAustria) return Schema.Austria;
    if (Env.IsCroatia) return Schema.Croatia;
    if (Env.IsMacedonia) return Schema.Macedonia;
    if (Env.IsSerbia) return Schema.Serbia;
    if (Env.IsSlovenia) return Schema.Slovenia;
    return Schema.Bulgaria;
};

export const Provider: React.FunctionComponent<ProviderProps> = (props) => {
    const value = useMemo(() => props.instance ?? new KeyboardElement(getSchema()), [props.instance]);
    return <KeyboardContext.Provider value={value}>{props.children}</KeyboardContext.Provider>;
};
