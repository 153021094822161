import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type IsRecordableFragment = { __typename?: 'Event', start: any, end: any, entitlements?: { __typename: 'EventEntitlements', networkRecording: boolean, networkRecordingPlannableUntil: any } | null };

export const IsRecordableFragmentDoc = gql`
    fragment isRecordable on Event {
  start
  end
  entitlements {
    networkRecording
    networkRecordingPlannableUntil
    __typename
  }
}
    `;