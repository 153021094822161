import { Engine } from 'App/Packages/Focus/Engine';
import { KeyUpEvent } from 'App/Packages/Focus/Events/KeyUpEvent';

export class KeyUpEngine extends Engine<KeyUpEvent> {
    private static readonly instance: KeyUpEngine = new KeyUpEngine();

    private constructor() {
        super();
        this.attach();
    }

    public static readonly getInstance = () => KeyUpEngine.instance;

    private readonly attach = () => document.addEventListener('keyup', this.emitter);

    private readonly emitter = (event: KeyboardEvent) => this.emit(new KeyUpEvent(event.keyCode));
}
