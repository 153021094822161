import * as Types from '../types';

import { gql } from '@apollo/client';
import { PersonalChannelInfoFragmentFragmentDoc } from '../query/fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeChannelPreferencesMutationVariables = Types.Exact<{
  input: Types.ChangeChannelPreferencesInput;
}>;


export type ChangeChannelPreferencesMutation = { __typename?: 'Nexx4Mutations', changeChannelPreferences: { __typename?: 'ChangeChannelPreferencesPayload', channel: { __typename?: 'Channel', id: string, personalInfo?: { __typename: 'PersonalChannelInfo', id: string, expiry: any, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null } | null } } };


export const ChangeChannelPreferencesDocument = gql`
    mutation changeChannelPreferences($input: ChangeChannelPreferencesInput!) {
  changeChannelPreferences(input: $input) {
    channel {
      id
      personalInfo {
        ...personalChannelInfoFragment
      }
    }
  }
}
    ${PersonalChannelInfoFragmentFragmentDoc}`;
export type ChangeChannelPreferencesMutationFn = Apollo.MutationFunction<ChangeChannelPreferencesMutation, ChangeChannelPreferencesMutationVariables>;

/**
 * __useChangeChannelPreferencesMutation__
 *
 * To run a mutation, you first call `useChangeChannelPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeChannelPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeChannelPreferencesMutation, { data, loading, error }] = useChangeChannelPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeChannelPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeChannelPreferencesMutation, ChangeChannelPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeChannelPreferencesMutation, ChangeChannelPreferencesMutationVariables>(ChangeChannelPreferencesDocument, options);
      }
export type ChangeChannelPreferencesMutationHookResult = ReturnType<typeof useChangeChannelPreferencesMutation>;
export type ChangeChannelPreferencesMutationResult = Apollo.MutationResult<ChangeChannelPreferencesMutation>;
export type ChangeChannelPreferencesMutationOptions = Apollo.BaseMutationOptions<ChangeChannelPreferencesMutation, ChangeChannelPreferencesMutationVariables>;