import { Folder } from 'App/Modules/Folder';
import { Constants } from 'App/Modules/MyLibraryData/Constants';
import { Reducer, combineReducers } from 'redux';
import { ActionTypes } from 'App/Modules/MyLibraryData/Store/types';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

const headerReducer: Reducer<DetailItem | null> = (state, action) => {
    if (state === undefined) return null;
    switch (action.type) {
        case ActionTypes.Load:
            return action.payload;
        default:
            return state;
    }
};

// @todo remove after mapping the asset
const assetReducer: Reducer = (state, action) => {
    if (state === undefined) return null;
    switch (action.type) {
        case ActionTypes.LoadAsset:
            return action.payload;
        default:
            return state;
    }
};

export const reducer = combineReducers({
    headerData: headerReducer,
    assetData: assetReducer,
    myLibraryData: Folder.reducer.list(Constants.Hash),
});
