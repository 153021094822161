import React, { useEffect, useRef } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageDetailLayoutObserved } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import {
    MESSAGE_DETAIL_HEADER_LINE_HEIGHT_VW,
    MESSAGE_DETAIL_TEXT_LINE_HEIGHT_VW,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import { selectMessageDetailHeader, selectMessageDetailText } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

import { Screen } from 'App/Modules/Screen';
import { UI } from 'App/Packages/UI';

export const MessageDetailObserver: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const headerRef = useRef<HTMLDivElement | null>(null);
    const textRef = useRef<HTMLDivElement | null>(null);
    const header = useSelector(selectMessageDetailHeader);
    const text = useSelector(selectMessageDetailText);

    useEffect(() => {
        if (!headerRef.current || !textRef.current) return;
        const headerHeight = headerRef.current.getBoundingClientRect().height / (window.innerWidth / 100);
        const textHeight = textRef.current.getBoundingClientRect().height / (window.innerWidth / 100);
        const headerLines = headerHeight > MESSAGE_DETAIL_HEADER_LINE_HEIGHT_VW ? 2 : 1;
        const textLines = Math.round(textHeight / MESSAGE_DETAIL_TEXT_LINE_HEIGHT_VW);
        dispatch(messageDetailLayoutObserved(headerLines, textLines));
    }, [dispatch]);

    return (
        <Layout className="message-detail" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <UI.Spinner />
            <Text
                className="message-detail__header-observeable"
                typeface={Typeface.SERIF}
                size={FontSize.TITLE_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
                ref={headerRef}
            >
                {header}
            </Text>
            <Text
                className="message-detail__text-observeable"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
                ref={textRef}
            >
                {text}
            </Text>
        </Layout>
    );
};
