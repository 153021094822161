import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { FavouritableItemFragmentDoc } from '../fragments/favouritableItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnfavouriteItemMutationVariables = Types.Exact<{
  input: Types.UnfavouriteItemInput;
}>;


export type UnfavouriteItemMutation = { __typename?: 'Nexx4Mutations', unfavouriteItem: { __typename?: 'UnfavouriteItemPayload', item: { __typename: 'Event', id: string, expiry: any } | { __typename: 'NetworkRecording', id: string, expiry: any } | { __typename: 'VODAsset', id: string, expiry: any } | { __typename: 'VODProduct', id: string, expiry: any } } };


export const UnfavouriteItemDocument = gql`
    mutation unfavouriteItem($input: UnfavouriteItemInput!) {
  unfavouriteItem(input: $input) {
    item {
      ...favouritableItem
    }
  }
}
    ${FavouritableItemFragmentDoc}`;
export type UnfavouriteItemMutationFn = Apollo.MutationFunction<UnfavouriteItemMutation, UnfavouriteItemMutationVariables>;

/**
 * __useUnfavouriteItemMutation__
 *
 * To run a mutation, you first call `useUnfavouriteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavouriteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavouriteItemMutation, { data, loading, error }] = useUnfavouriteItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfavouriteItemMutation(baseOptions?: Apollo.MutationHookOptions<UnfavouriteItemMutation, UnfavouriteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfavouriteItemMutation, UnfavouriteItemMutationVariables>(UnfavouriteItemDocument, options);
      }
export type UnfavouriteItemMutationHookResult = ReturnType<typeof useUnfavouriteItemMutation>;
export type UnfavouriteItemMutationResult = Apollo.MutationResult<UnfavouriteItemMutation>;
export type UnfavouriteItemMutationOptions = Apollo.BaseMutationOptions<UnfavouriteItemMutation, UnfavouriteItemMutationVariables>;