import { startupActions } from '__SMART_APP_OLD__/app/modules/Startup/actions';
import { startupOptionIds, startupOptionLabelForPosition } from '__SMART_APP_OLD__/app/modules/Startup/constants';
import { isStartupSet } from '__SMART_APP_OLD__/app/modules/Startup/functions';
import { getInitialStartupState } from '__SMART_APP_OLD__/app/modules/Startup/getInitialState';
import { startupReducer } from '__SMART_APP_OLD__/app/modules/Startup/reducer';
import { startSelectors } from '__SMART_APP_OLD__/app/modules/Startup/selectors';
import { StartupActionType, StartupPosition, StartupReduxAction } from '__SMART_APP_OLD__/app/modules/Startup/types';

export { StartupActionType as ActionType, StartupPosition as Position };

export type { StartupReduxAction as ReduxAction };

export const isSet = isStartupSet;

export const optionIds = startupOptionIds;

export const optionLabelForPosition = startupOptionLabelForPosition;

export const getInitialState = getInitialStartupState;

export const reducer = startupReducer;

export const actions = startupActions.public;

export const selectors = startSelectors.public;
