import React from 'react';

export type SelectOptionsContextState = {
    ids: string[];
    focused: string;
    focusedIndex: number;
    selected: string;
    disabled: boolean;
    onHover: (id: string) => void;
    onEnter?: (id: string) => void;
};
export type SelectOptionsContextModule = {
    Provider: React.FunctionComponent<React.PropsWithChildren<SelectOptionsContextState>>;
} & Omit<React.Context<SelectOptionsContextState>, 'Provider'>;

const SelectOptionsContextInternal = React.createContext<SelectOptionsContextState>({} as SelectOptionsContextState);

const SelectOptionsContextProvider = SelectOptionsContextInternal.Provider as React.FunctionComponent<
    React.PropsWithChildren<{ value: SelectOptionsContextState }>
>;

const SelectOptionsProvider: React.FunctionComponent<React.PropsWithChildren<SelectOptionsContextState>> = ({ children, ...props }) => (
    <SelectOptionsContextProvider value={props}>{children}</SelectOptionsContextProvider>
);
export const SelectOptionsContext: SelectOptionsContextModule = Object.assign(SelectOptionsContextInternal, {
    Provider: SelectOptionsProvider,
});

export const useSelectOptionsContext = () => React.useContext(SelectOptionsContextInternal);
