import { Exception } from '__SMART_APP_OLD__/app/modules/Error/Exception';

export const collectExceptions = (response: any): void => {
    try {
        const errors: any[] = response?.errors ?? [];
        const gqlErrors: Exception[] = errors.map((error) => new Exception(error?.errorCode ?? '', error?.message ?? ''));
        if (gqlErrors.length) throw gqlErrors;
    } catch (error) {
        console.error('[ERROR_READ]: ', error);
    }
};
