import { ContentItemType, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Variant } from '__SMART_APP_OLD__/app/components/Image';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import {
    ChannelCollectionEntityUserInfo,
    CollectionEntity,
    EventCollectionEntityEntitlements,
    VodAssetCollectionEntityEntitlements,
    VodAssetCollectionEntityMetadata,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { selectPinIsActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { selectRecording, selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { ContentMarkerType } from 'App/Types/ContentMarker';
import translate from 'language/translate';

const selectTitle = (state: State): string => state.data.collection.title;

const selectTitleWithEntityCount = (state: State) => {
    const name = selectTitle(state);
    const eventIds = selectEntityIds(state);
    return `${name} (${eventIds.length})`;
};

const selectKind = (state: State): string => state.data.collection.kind;

const selectId = (state: State): string => state.data.collection.id;

const selectEntityIds = (state: State): string[] => state.data.collection.ids;

const selectEntity =
    (id: string) =>
    (state: State): CollectionEntity | null =>
        state.data.collection.entities[id] ?? null;

const selectEntities = (state: State) => state.data.collection.entities ?? null;

const selectEntityType =
    (id: string) =>
    (state: State): ContentItemType | null =>
        selectEntity(id)(state)?.type ?? null;

const selectEntityTitle =
    (id: string) =>
    (state: State): string => {
        const isPinSessionActive = selectPinIsActive(state);
        const restricted = selectIsEntityRestricted(id)(state);

        if (restricted && !isPinSessionActive) return translate('PARENTAL_CONTROL_MASKED_TITLE');
        return selectEntity(id)(state)?.title ?? '';
    };

const selectEntityThumbnail =
    (id: string) =>
    (state: State): string =>
        selectEntity(id)(state)?.thumbnail ?? '';

const selectEntityParentalRatingId =
    (id: string) =>
    (state: State): string =>
        selectEntity(id)(state)?.parentalRatingId ?? '';

const selectEntitySessionType = (id: string) => (state: State) => {
    const entity = selectEntity(id)(state);
    if (!entity) return PinSessionType.PIN;
    switch (entity.type) {
        case ContentItemType.Event:
            return PinSessionType.PIN_LIVE_TV;
        case ContentItemType.Networkrecording:
            return PinSessionType.PIN_LIVE_TV;
        case ContentItemType.Vodasset:
            return PinSessionType.PIN;
        case ContentItemType.Vodseries:
            return PinSessionType.PIN;
        case ContentItemType.Series:
            return PinSessionType.PIN_LIVE_TV;
        case ContentItemType.Vodfolder:
            return PinSessionType.PIN;
        case ContentItemType.Vodproduct:
            return PinSessionType.PIN;
        case ContentItemType.Banner:
            return PinSessionType.PIN;
        // case ContentItemType.Channel:
        //     return PinSessionType.PIN_LIVE_TV;
        // case ContentItemType.Channelproduct:
        //     return PinSessionType.PIN;
        // case ContentItemType.Productbundle:
        //     return PinSessionType.PIN;
        default:
            return PinSessionType.PIN;
    }
};

const selectIsEntityRestricted =
    (id: string) =>
    (state: State): boolean =>
        selectParentalRatingIsRestricted(selectEntityParentalRatingId(id)(state), selectEntitySessionType(id)(state))(state);

const selectEventIsLive =
    (id: string) =>
    (state: State): boolean =>
        selectEntityStart(id)(state) <= Time.selectors.select(state) && selectEntityEnd(id)(state) > Time.selectors.select(state);

const selectEntitySeasonCount =
    (id: string) =>
    (state: State): number => {
        const entity = selectEntity(id)(state);
        if (!entity) return 0;
        switch (entity.type) {
            case ContentItemType.Vodseries:
                return entity.seasonCount;
            default:
                return 0;
        }
    };

const selectEntityEpisodeCount =
    (id: string) =>
    (state: State): number => {
        const entity = selectEntity(id)(state);
        if (!entity) return 0;
        switch (entity.type) {
            case ContentItemType.Series:
                return entity.episodeCount;
            default:
                return 0;
        }
    };

const selectEntityUserInfo =
    (id: string) =>
    (state: State): ChannelCollectionEntityUserInfo | null => {
        const entity = selectEntity(id)(state);
        if (!entity) return null;
        switch (entity.type) {
            case ContentItemType.Channel:
                return entity.userInfo;
            default:
                return null;
        }
    };

const selectEntityProviderId =
    (id: string) =>
    (state: State): string => {
        const entity = selectEntity(id)(state);
        if (!entity) return '';
        switch (entity.type) {
            case ContentItemType.Event:
                return entity.channelId;
            case ContentItemType.Networkrecording:
                return entity.channelId;
            case ContentItemType.Series:
                return entity.channelId;
            default:
                return '';
        }
    };

const selectEntityStart =
    (id: string) =>
    (state: State): number => {
        const entity = selectEntity(id)(state);
        if (!entity) return 0;
        switch (entity.type) {
            case ContentItemType.Event:
                return entity.start;
            case ContentItemType.Networkrecording:
                return entity.start;
            default:
                return 0;
        }
    };

const selectEntityEnd =
    (id: string) =>
    (state: State): number => {
        const entity = selectEntity(id)(state);
        if (!entity) return 0;
        switch (entity.type) {
            case ContentItemType.Event:
                return entity.end;
            case ContentItemType.Networkrecording:
                return entity.end;
            default:
                return 0;
        }
    };

const selectEntityMetadata =
    (id: string) =>
    (state: State): VodAssetCollectionEntityMetadata | null => {
        const entity = selectEntity(id)(state);
        if (!entity) return null;
        switch (entity.type) {
            case ContentItemType.Vodasset:
                return entity.metadata;
            default:
                return null;
        }
    };

const selectEntityDuration =
    (id: string) =>
    (state: State): number => {
        const entity = selectEntity(id)(state);
        if (!entity) return 0;
        switch (entity.type) {
            case ContentItemType.Vodasset:
                return entity.duration;
            default:
                return 0;
        }
    };

const selectVODAssetEntityEntitlements =
    (id: string) =>
    (state: State): VodAssetCollectionEntityEntitlements | null => {
        const entity = selectEntity(id)(state);
        if (!entity) return null;
        switch (entity.type) {
            case ContentItemType.Vodasset:
                return entity.entitlements;
            default:
                return null;
        }
    };

const selectEventEntityEntitlements =
    (id: string) =>
    (state: State): EventCollectionEntityEntitlements | null => {
        const entity = selectEntity(id)(state);
        if (!entity) return null;
        switch (entity.type) {
            case ContentItemType.Event:
                return entity.entitlements;
            default:
                return null;
        }
    };

// Icons
const selectEntityIcons =
    (id: string) =>
    (state: State): IconName[] => {
        const icons: IconName[] = [];
        if (selectEventEntityIconsCatchup(id)(state)) icons.push(IconName.CATCHUP);
        if (selectVODSeriesEntityIcons(id)(state)) icons.push(IconName.FOLDER);
        if (selectRecordingStatus(id)(state) === RecordingStatus.Completed) icons.push(IconName.RECORD);
        return icons;
    };

const selectEventEntityIconsCatchup = (id: string) => (state: State) => {
    const entitlements = selectEventEntityEntitlements(id)(state);
    const start = selectEntityStart(id)(state);
    const time = Time.selectors.select(state);
    return entitlements?.catchupTV && entitlements.catchupTVAvailableUntil > time && start < time;
};

const selectVODSeriesEntityIcons = (id: string) => (state: State) => {
    const entityType = selectEntityType(id)(state);
    if (entityType === ContentItemType.Vodseries || entityType === ContentItemType.Series) return true;
    return false;
};

// Progress Color
const selectEntityProgressColor =
    (id: string) =>
    (state: State): BackgroundColor | undefined => {
        const type = selectEntityType(id)(state);
        switch (type) {
            case ContentItemType.Event:
            case ContentItemType.Networkrecording:
                return selectEventEntityProgressColor(id)(state);
            case ContentItemType.Vodasset:
                return selectVODAssetEntityProgressColor();
            default:
                return undefined;
        }
    };

const selectEventEntityProgressColor =
    (id: string) =>
    (state: State): BackgroundColor | undefined =>
        selectEventIsLive(id)(state) ? BackgroundColor.COOL_BLUE_PRIMARY : BackgroundColor.HOT_RED_PRIMARY;

const selectVODAssetEntityProgressColor = (): BackgroundColor | undefined => BackgroundColor.HOT_RED_PRIMARY;

// Content Marker
const selectEntityContentMarker = (id: string) => (state: State) => {
    const type = selectEntityType(id)(state);
    switch (type) {
        case ContentItemType.Event:
            return selectEventEntityContentMarker(id)(state);
        case ContentItemType.Networkrecording:
            return selectRecordingEntityContentMarker(id)(state);
        case ContentItemType.Vodasset:
            return selectVODAssetEntityContentMarker(id)(state);
        default:
            return undefined;
    }
};

const selectEventEntityContentMarker = (id: string) => (state: State) =>
    selectEventIsLive(id)(state) ? ContentMarkerType.Live : undefined;

const selectRecordingEntityContentMarker = (id: string) => (state: State) =>
    selectEventEntityContentMarker(id)(state) ?? ContentMarkerType.Recorded;

const selectVODAssetEntityContentMarker = (id: string) => (state: State) => {
    const entity = selectVODAssetEntityEntitlements(id)(state);
    if (entity?.playback === true && entity.playbackAvailableUntil > Time.selectors.select(state)) return ContentMarkerType.Rented;
    return undefined;
};

// Content marker label
const selectEntityContentMarkerLabel = (id: string) => (state: State) => {
    const type = selectEntityType(id)(state);
    switch (type) {
        case ContentItemType.Event:
            return selectEventEntityContentMarkerLabel();
        case ContentItemType.Networkrecording:
            return selectRecordingEntityContentMarkerLabel(id)(state);
        case ContentItemType.Vodasset:
            return selectVODAssetEntityContentMarkerLabel(id)(state);
        default:
            return undefined;
    }
};

const selectEventEntityContentMarkerLabel = () => undefined;

const selectRecordingEntityContentMarkerLabel = (id: string) => (state: State) => {
    const recording = selectRecording(id)(state);
    return recording.availableUntil;
};

const selectVODAssetEntityContentMarkerLabel = (id: string) => (state: State) => {
    const entity = selectVODAssetEntityEntitlements(id)(state);
    return entity?.playbackAvailableUntil;
};

// Progress Value
const selectEntityProgressValue = (id: string) => (state: State) => {
    const type = selectEntityType(id)(state);
    switch (type) {
        case ContentItemType.Event:
        case ContentItemType.Networkrecording:
            return selectEventEntityProgressValue(id)(state);
        case ContentItemType.Vodasset:
            return selectVODAssetEntityProgressValue(id)(state);
        default:
            return undefined;
    }
};

const selectEventEntityProgressValue = (id: string) => (state: State) => {
    if (selectEventIsLive(id)(state)) {
        return selectEventLiveProgress(id)(state);
    }
    const bookmark = Bookmarks.selectors.selectEntity(id)(state);
    if (!bookmark || !bookmark.position) return 0;
    const bookmarkPosition = bookmark.position * 100;
    const duration = (selectEntityEnd(id)(state) - selectEntityStart(id)(state)) / 1000;
    return bookmarkPosition / duration;
};

const selectVODAssetEntityProgressValue = (id: string) => (state: State) => {
    const bookmark = Bookmarks.selectors.selectEntity(id)(state);
    if (!bookmark || !bookmark.position) return 0;
    const bookmarkPosition = bookmark.position * 100;
    const duration = selectEntityDuration(id)(state);
    return bookmarkPosition / duration;
};

const selectEventLiveProgress =
    (id: string) =>
    (state: State): number => {
        const isLive = selectEventIsLive(id)(state);
        if (!isLive) return -1;
        return (
            ((Time.selectors.select(state) - selectEntityStart(id)(state)) / (selectEntityEnd(id)(state) - selectEntityStart(id)(state))) *
            100
        );
    };

// HasProgressBar
const seclectEntityHasProgressBar =
    (id: string) =>
    (state: State): boolean => {
        const progressValue = selectEntityProgressValue(id)(state) ?? 0;
        return progressValue > 0;
    };

// Image Variant
const selectImageVariant = (id: string) => (state: State) => {
    const isRestricted = selectIsEntityRestricted(id)(state);
    if (isRestricted) return Variant.ADULT;
    return Variant.BASE;
};

// Banner Link
const selectBannerEntityLink = (id: string) => (state: State) => {
    const entity = selectEntity(id)(state);
    if (entity?.type === ContentItemType.Banner) return entity.link;
    return null;
};

// Entity Description
const selectEntityDescription = (id: string) => (state: State) => {
    const entityType = selectEntityType(id)(state);
    switch (entityType) {
        case ContentItemType.Event:
            return selectEventEntityDescription(id)(state);
        case ContentItemType.Vodasset:
            return selectVODAssetEntityDescription(id)(state);
        case ContentItemType.Vodseries:
            return selectVODSeriesEntityDescription(id)(state);
        case ContentItemType.Series:
            return selectSeriesEntityDescription(id)(state);
        default:
            return '';
    }
};

const selectEventEntityDescription = (id: string) => (state: State) => {
    const eventStart = selectEntityStart(id)(state);
    const eventEnd = selectEntityEnd(id)(state);
    return getTimeStampInfo(eventStart ?? 0, eventEnd ?? 0);
};

const selectVODAssetEntityDescription = (id: string) => (state: State) => {
    const vodAssetDuration = selectEntityDuration(id)(state);
    const vodAssetMetada = selectEntityMetadata(id)(state);
    return `${vodAssetMetada?.year} | ${vodAssetMetada?.genre.title} | ${secondsToTime(vodAssetDuration)}`;
};

const selectVODSeriesEntityDescription = (id: string) => (state: State) => {
    const seasonCount = selectEntitySeasonCount(id)(state);
    return `${seasonCount} ${seasonCount > 1 ? translate('SCREEN_CARDS_SEASONS') : translate('SCREEN_CARDS_SEASON')}`;
};

const selectSeriesEntityDescription = (id: string) => (state: State) => {
    const episodeCount = selectEntityEpisodeCount(id)(state);
    return `${episodeCount} ${episodeCount === 1 ? translate('NUMBER_EPISODE') : translate('NUMBER_EPISODES')}`;
};

const secondsToTime = (time: number) => {
    const h = Math.floor(time / 3600);
    const m = Math.floor((time / 60) % 60);

    let timeString = '';

    if (h > 0) {
        if (h < 10) {
            timeString = `0${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
        }
        timeString = `${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
    }

    if (m > 0) {
        if (m < 10) {
            timeString += `0${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        } else {
            timeString += `${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        }
    }
    return timeString;
};

export const collectionSelectors = {
    public: {
        selectTitleWithEntityCount,
        selectKind,
        selectId,
        selectEntities,
        selectEntityIds,
        selectEntityType,
        selectEntityTitle,
        selectEntityUserInfo,
        selectEntityThumbnail,
        selectEntityProviderId,
        selectIsEntityRestricted,
        selectEntityDescription,
        selectImageVariant,
        selectEntityProgressColor,
        selectEntityContentMarker,
        selectEntityContentMarkerLabel,
        selectEntityProgressValue,
        seclectEntityHasProgressBar,
        selectEntityIcons,
        selectBannerEntityLink,
        selectEntityParentalRatingId,
    },
};
