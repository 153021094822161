import React from 'react';

import { ChannelKind } from '__SMART_APP_OLD__/api/graphql/types';
import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectRadioFallbackImage } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannel, selectChannelSwitchId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectEventBy } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

/**
 *  Common jsx components expressing common shared logic in the application.
 * @namespace Common
 */

/**
 * Component that shows overlay with program and background image on radio channels
 * It shows the channel background image or fallback image from Control center
 * and current epg metadata
 * of the current event and the timeline of the event. Which refreshes accordingly.
 * @memberof Common
 * @alias RadioOverlay
 * @augments React.FC
 * @summary Component that shows overlay with program and background image on radio channels
 * @returns return the jsx for radio overlay.
 */
export const RadioOverlay: React.FC = () => {
    const channelSwitchId = useSelector(selectChannelSwitchId);
    const liveChannelId = useSelector(selectChannelLiveId);
    const liveChannel = useSelector(selectChannel(liveChannelId));
    const channelSwitchChannel = useSelector(selectChannel(channelSwitchId));
    const event = useSelector(selectEventBy(liveChannelId, Date.now(), { equal: EqualityCompare.START }));
    const radioFallbackImage = useSelector(selectRadioFallbackImage);

    if (!event || !liveChannel || !liveChannelId) return null;
    if (liveChannel.kind !== ChannelKind.Radio) {
        return null;
    }
    if (channelSwitchChannel && channelSwitchChannel.kind !== ChannelKind.Radio && liveChannel.kind !== ChannelKind.Radio) {
        return null;
    }

    return (
        <div className="radio-overlay dark-theme" style={{ backgroundImage: `url(${liveChannel.background || radioFallbackImage})` }}>
            <ChannelLogo className="radio-overlay__channel-logo" id={liveChannel.id} theme={Theme.Type.Dark} />
        </div>
    );
};
