/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react-func/max-lines-per-function */
import { clpp } from '@castlabs/prestoplay';
import React from 'react';

import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { playVod } from '__SMART_APP_OLD__/api/Tuner';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import {
    channelLiveChange,
    channelSwitchIdChange,
    loadEventsForChannel,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { channelNotInActiveChannelListNotificationShow, textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import {
    selectChannelLiveId,
    selectChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectEvent, selectEventIsRestricted, selectEventBy } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { getDataForLivePlayer, getEventForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { pinActiveStatusChanged, pinActiveStatusLiveTvChanged } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/actions';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { store } from '__SMART_APP_OLD__/app/store/store';
import {
    cleanupAfterChannelSwitching,
    handleChannelSwitching,
    initChannelSwitching,
} from '__SMART_APP_OLD__/components/channelSwitchDialog';
import PlayerError from '__SMART_APP_OLD__/components/common/PlayerError';
import { RadioOverlay } from '__SMART_APP_OLD__/components/common/RadioOverlay';
import { MiniEPG } from '__SMART_APP_OLD__/components/EPG';
import { openOnVisibilityChangePinOverlay, openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import PlayerUI from '__SMART_APP_OLD__/components/playerUI/PlayerUI';
import Events, {
    DARK_MODE_CHANGED,
    HIDE_CHANNEL_SWITCH_DIALOG,
    INTERNET_CONNECTION_OFF,
    INTERNET_CONNECTION_ON,
    NOTIFICATION_HIDE,
    PIN_OVERLAY_HIDE,
    PIN_OVERLAY_SHOW,
    PLAYER_SEEK,
    SHOW_CHANNEL_SWITCH_DIALOG,
} from '__SMART_APP_OLD__/config/Events';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import {
    AssetType,
    ComponentsWithPlayer,
    NavKey,
    PinAction,
    PlayerNotificationIcon,
    playerDownloadErrors,
} from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import State from '__SMART_APP_OLD__/utils/State';
import { changePlayerVisibility, debounce } from '__SMART_APP_OLD__/utils/Utils';
import {
    isApplicationVisible,
    subscribeVisibilityChangeHandler,
    unsubscribeVisibilityChangeHandler,
} from '__SMART_APP_OLD__/utils/visibilityChange';
import { PlaybackEvents } from 'analytics/logging/events/PlaybackEvent';
import { UIActionEvents, UIActionTriggers } from 'analytics/logging/events/UIActionEvent';
import { getPlaybackEvent } from 'analytics/logging/factories/playbackEventFactory';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { Key } from 'App/Modules/Key';
import { Overlay } from 'App/Modules/Overlay';
import { UI } from 'App/Packages/UI';
import { StandByService } from 'App/Modules/Common/Components/StandByService';
import { Keyboard } from 'App/Modules/Keyboard';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';

class PageWithPlayer extends React.Component {
    audioTrackWasInitialized = false;

    channelId = null;

    data = null;

    prevAsset = null;

    isRestricted = true;

    isSubscribed = false;

    isLoadingStream = false;

    initialPlayerStart = true;

    initialStreamSet = true;

    requestedChannelId;

    isRequestedChannelIdForRestart;

    restartedDataId = null;

    timerId = null;

    resetPlayerUITimer = () => {};

    constructor(props) {
        super();
        if (props?.location?.state?.playFromStart) {
            State.set(NavKey.PAGE_WITH_PLAYER, {
                visibleComponent: ComponentsWithPlayer.PLAYER_UI,
                playFromStart: true,
            });
        }
        if (Preferences.channelToBeChanged) {
            this.channelId = Preferences.lastWatchedChannel;
            Preferences.channelToBeChanged = false;
        } else {
            this.channelId = props.computedMatch.params.channelId;
        }

        this.state = {
            programDataLoaded: true,
            visibleComponent: null,
            showNotificationButton: false,
            isPinOverlayVisible: false,
            hasManifestFromStart: false,
            notificationIconToShow: null,
            hasInternetConnection: true,
        };

        this.resetPlayerUITimer = debounce(() => {
            const { visibleComponent } = this.state;
            if (!this.isShowingPlayerUI(visibleComponent) || this.isFreezedUI) {
                return;
            }
            this.hidePlayerUI();
            this.toggleNotificationButton(false);
        }, props.hideControllsAfter);
    }

    async componentDidMount() {
        changePlayerVisibility(true);
        this.props.clearOverlay();
        Player.unMute();

        await this.loadDataForPlayback();
        this.initialPlayerStartHandler();

        if (!Focus.focused && this.state.visibleComponent !== ComponentsWithPlayer.MINI_EPG) {
            this.focusContainer();
        }
        initChannelSwitching();
        Events.addEventListener(INTERNET_CONNECTION_OFF, this.suspendPlayer);
        Events.addEventListener(INTERNET_CONNECTION_ON, this.restorePlayer);
        Events.addEventListener(PIN_OVERLAY_SHOW, this.handlePinOverlayStateChange.bind(this, true));
        Events.addEventListener(PIN_OVERLAY_HIDE, this.handlePinOverlayStateChange.bind(this, false));
        Events.addEventListener(NOTIFICATION_HIDE, this.handleNotificationHide);
        Player.addEventListener(VIDEO_EVENTS.ERROR, this.handlePlayerRestart);
        Player.addEventListener(VIDEO_EVENTS.CANPLAY, this.onCanPlay);
        Player.addEventListener(VIDEO_EVENTS.ENDED, this.handleEndedVideoEvent);
        Player.addEventListener(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange);
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);

        subscribeVisibilityChangeHandler(this.onVisibilityChange);

        this.loadPrevAsset();
    }

    async componentDidUpdate(prevProps) {
        // Check channel id and if different update
        const { channelId } = this.props.computedMatch.params;
        const prevChannelId = prevProps.computedMatch.params.channelId;
        // after component was updated we need to trigger playback if channel id
        // OR location key changed because Tuner.playChannel was invoked
        await this.initialPlayerStartHandler();

        if (channelId !== prevChannelId || this.props.location.key !== prevProps.location.key) {
            this.channelId = channelId;
            await this.loadDataForPlayback();
            this.prevAsset = null;
            this.loadPrevAsset();
        }

        if (!Focus.focused && this.state.visibleComponent !== ComponentsWithPlayer.MINI_EPG) {
            this.focusContainer();
        }

        if (this.state.notificationIconToShow === PlaybackEvents.PLAY) {
            clearTimeout(this.timerId);
            this.timerId = setTimeout(() => {
                this.toggleShowNotificationIcon(null);
            }, 1000);
        }
    }

    componentWillUnmount() {
        this.clearNextProgramTimer();
        cleanupAfterChannelSwitching();
        Events.removeEventListener(INTERNET_CONNECTION_OFF, this.suspendPlayer);
        Events.removeEventListener(INTERNET_CONNECTION_ON, this.restorePlayer);
        Events.removeEventListener(PIN_OVERLAY_SHOW, this.handlePinOverlayStateChange);
        Events.removeEventListener(PIN_OVERLAY_HIDE, this.handlePinOverlayStateChange);
        Events.removeEventListener(NOTIFICATION_HIDE, this.handleNotificationHide);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        Player.removeEventListener(VIDEO_EVENTS.ERROR, this.handlePlayerRestart);
        Player.removeEventListener(VIDEO_EVENTS.CANPLAY, this.onCanPlay);
        Player.removeEventListener(VIDEO_EVENTS.ENDED, this.handleEndedVideoEvent);
        Player.removeEventListener(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange);
        unsubscribeVisibilityChangeHandler(this.onVisibilityChange);

        if (document.location.pathname.includes('HOME') && this.props.isHomeScreenPlayerEnabled && this.props.isHomePlayerWideViewEnabled) {
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: true } }));
        }

        // if live playback timeshifted we also need stop the player
        // because on the HOME page we should watch video at live position
        if (
            (!document.location.pathname.includes('HOME') && !document.location.pathname.includes('play')) ||
            !this.props.isHomeScreenPlayerEnabled ||
            Player.getTimeshift()
        ) {
            PlayerAPI.stopPlayback();
            Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
        } else if (this.props.isHomeScreenPlayerMuted) {
            Player.mute();
        }
        this.resetPlayerUITimer.cancel();
        this.props.channelSwitchIdChange('');
        clearTimeout(this.timerId);
    }

    async getCurrentProgram() {
        return getEventForChannel(this.channelId, Date.now());
    }

    async initialPlayerStartHandler() {
        if (!this.initialPlayerStart) return;
        const currentProgram = await this.getCurrentProgram();
        if (!currentProgram) return;
        this.initialPlayerStart = false;

        await this.setIsRestricted();
        if (this.isRestricted || !this.isSubscribed) {
            this.setState({ visibleComponent: null });
            State.set(NavKey.PAGE_WITH_PLAYER, { visibleComponent: null });
            handleChannelSwitching(null, this.channelId, () => this.checkAndPlayChannel());
            return;
        }

        this.playChannel();
        const currentVisibleComponent = this.state.visibleComponent;
        if (State.hasState(NavKey.PAGE_WITH_PLAYER)) {
            const { visibleComponent } = State.get(NavKey.PAGE_WITH_PLAYER);
            if (visibleComponent) {
                this.setState({ visibleComponent });
            } else if (this.isChannelInChannelList()) {
                this.showMiniEPG();
            } else {
                this.showPlayerUI();
            }
        } else if (!currentVisibleComponent) {
            if (this.isChannelInChannelList()) {
                this.showMiniEPG();
            } else {
                this.showPlayerUI();
            }
        }
    }

    loadPrevAsset = async () => {
        if (this.prevAsset) return;

        const { channelId, start } = this.data;
        const state = store.getState();
        const channel = selectChannel(channelId)(state);
        const prevAssetEndTime = new Date(start).getTime();

        let prevEvent = selectEventBy(channelId, prevAssetEndTime, { equal: EqualityCompare.END, noLoading: false })(state);

        if (prevEvent.isLoading) {
            await this.props.loadEventsForChannel(channelId, prevAssetEndTime - Time.MINUTE_MS, prevAssetEndTime);
            prevEvent = selectEventBy(channelId, prevAssetEndTime, { equal: EqualityCompare.END, noLoading: false })(store.getState());
        }

        this.prevAsset = createAssetObject({ ...prevEvent, channel }, AssetType.EVENT);
    };

    // eslint-disable-next-line consistent-return
    handleEndedVideoEvent = async () => {
        const { hasManifestFromStart } = this.state;
        if (!hasManifestFromStart) {
            return this.loadProgramData(false);
        }
        const state = store.getState();
        const event = selectEvent(this.restartedDataId)(state);

        if (!event) {
            const { assetType, id } = this.data;
            return History.replace(`/details/${assetType}/${id}`);
        }
        const channel = selectChannel(this.channelId)(state);
        const eventObject = createAssetObject({ ...event, channel }, AssetType.EVENT);
        // console.log(`[PLAYER] ${JSON.stringify(eventObject)}`);
        playVod(eventObject, { bookmarkPosition: Player.getPlayedTime(), isReplaceNeeded: true });
        // this.audioTrackWasInitialized = false;
        // await this.loadProgramData(false);
        // const playedTime = Player.getPlayedTime();
        // return this.playContent(hasManifestFromStart, channelEvent.start);
    };

    onEnded = async () => {
        // temporary commenting this out as for Croatia Macedonia
        // it seems to break event refresh as no
        // static manifest ended is emitted
        // const { hasManifestFromStart } = this.state;
        if (this.state.hasManifestFromStart && (!Env.IsCroatia || !Env.IsMacedonia)) return undefined;

        await this.setIsRestricted();
        if (this.isRestricted && this.props.restrictivePlayerChannelSwitch) {
            return this.loadProgramData(true);
        }
        return this.loadProgramData(false);
    };

    async setIsRestricted() {
        const currentProgram = await this.getCurrentProgram();
        if (!currentProgram) return;
        this.isRestricted = selectEventIsRestricted(currentProgram.id, PinSessionType.PIN_LIVE_TV)(store.getState());
    }

    async checkAndPlayChannel() {
        await this.setIsRestricted();
        if (!this.isRestricted && this.isSubscribed) this.playChannel();
    }

    handlePinOverlayStateChange(visibilityState) {
        this.setState({ isPinOverlayVisible: visibilityState });
    }

    restorePlayer = () => {
        this.setState({ hasInternetConnection: true });
        this.toggleNotificationButton(false);
        if (!Player.isError() && !Player.isPlaying() && !Player.isIdle()) {
            Player.play();
            return;
        }
        this.checkAndPlayChannel();
    };

    suspendPlayer = () => {
        this.setState({ hasInternetConnection: false });
        if (Player.isPlaying()) {
            Player.pause();
            this.toggleNotificationButton(true);
        }
    };

    focusContainer = () => {
        if (this.containerRef) {
            Focus.focus(this.containerRef);
        }
    };

    isShowingPlayerUI = (visibleComponent) => visibleComponent === ComponentsWithPlayer.PLAYER_UI;

    noVisibleComponent = (visibleComponent) => visibleComponent === null;

    onVisibilityChange = async () => {
        if (isApplicationVisible()) {
            await this.setIsRestricted();
            if (this.isRestricted) {
                openOnVisibilityChangePinOverlay(PinSessionType.PIN_LIVE_TV);
                return;
            }

            const state = State.get(NavKey.PAGE_WITH_PLAYER);
            if (state?.visibleComponent === ComponentsWithPlayer.MINI_EPG) {
                this.showMiniEPG();
            }
        } else {
            this.props.pinActiveStatusChanged(false);
            this.props.pinLiveStatusChanged(false);
            Player.suspend();
        }
    };

    playContent = async (playFromStart = false) => {
        if (this.isLoadingStream && this.requestedChannelId === this.channelId && playFromStart === this.isRequestedChannelIdForRestart) {
            return;
        }
        this.isLoadingStream = true;
        if (playFromStart && this.initialStreamSet) {
            this.requestedChannelId = this.channelId;
            this.isRequestedChannelIdForRestart = true;
            await PlayerAPI.setRestartStream(this.data.id);
            this.setState({ hasManifestFromStart: true });
            this.restartedDataId = this.data.id;
        } else {
            this.requestedChannelId = this.channelId;
            this.isRequestedChannelIdForRestart = false;
            await PlayerAPI.setChannelStream(this.channelId);
            this.setState({ hasManifestFromStart: false });
            this.restartedDataId = null;
        }

        this.isLoadingStream = false;
        this.initialStreamSet = false;
    };

    handlePlayerRestart = async (error) => {
        // is internet connection was lost or number of attempts excided
        // we stop the player and show debugging mesage
        if (!window.navigator.onLine) return;
        const isDownloadError = playerDownloadErrors.includes(error.detail.code);
        // player error is not connected to http request no need to restart player return early
        if (isDownloadError || document.location.pathname.includes('HOME')) {
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
            History.back();
        }
    };

    handleNotificationHide = () => {
        this.hidePlayerUI();
    };

    loadDataForPlayback = async () => {
        await this.loadProgramData(false);
        if (!this.isSubscribed) {
            this.hidePlayerUI();
            Events.triggerEvents(SHOW_CHANNEL_SWITCH_DIALOG, { channelId: this.channelId });
        }
        await this.checkAndPlayChannel();
    };

    loadProgramData = async (withChannelSwitch) => {
        this.setState({ forceRenderer: {} });
        this.data = await getDataForLivePlayer(this.channelId);
        this.isSubscribed = this.data.liveTV;
        Player.setProgram(this.data);
        this.setTimerToLoadNextProgramData();
        if (withChannelSwitch) {
            this.hideOverlay();
            Events.triggerEvents(SHOW_CHANNEL_SWITCH_DIALOG, {
                channelId: this.channelId,
            });
            Player.stop();
        }
    };

    onCanPlay = () => {
        if (this.audioTrackWasInitialized) return;
        this.audioTrackWasInitialized = true;
        Player.setInitialSubtitles();
    };

    setTimerToLoadNextProgramData() {
        if (this.nextProgramTimer) {
            this.clearNextProgramTimer();
        }
        const currentTime = Date.now();
        const startTime = this.data.start;
        const endTime = this.data.end;
        const duration = endTime - startTime;
        const halfDuration = duration / 2;
        if (startTime + halfDuration < currentTime) {
            this.loadNextEventData(this.getRandomValue(0, endTime - currentTime));
            return;
        }
        const min = startTime + halfDuration - currentTime;
        const max = halfDuration + min;
        this.loadNextEventData(this.getRandomValue(min, max));
    }

    getRandomValue(min, max) {
        return Math.random() * (max - min) + min;
    }

    async loadNextEventData(duration) {
        const event = await this.getCurrentProgram();
        this.nextProgramTimer = setTimeout(() => {
            this.props.loadEventsForChannel(this.channelId, event.end, event.end + Time.MINUTE_MS);
        }, duration);
    }

    clearNextProgramTimer() {
        clearTimeout(this.nextProgramTimer);
        this.nextProgramTimer = null;
    }

    stopPlayer = () => {
        // if we have only the player and the root in history,
        // we will go to Home page, otherwise History back
        if (History.stack.length < 2) Player.stop();
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        History.back();
    };

    handleKeyDown = (event) => {
        const { keyCode, type } = event;
        const { visibleComponent } = this.state;
        const focusedEl = Focus.focused?.nodeRef;
        const scrubberWrapper = document.querySelector('.scrubber-wrapper');
        // const { keyPressInterval } = playerDefaultConfig;

        switch (keyCode) {
            case Key.VK_REWIND:
            case Key.VK_FAST_FWD:
                if (focusedEl !== scrubberWrapper && visibleComponent !== ComponentsWithPlayer.MINI_EPG) {
                    // This code is commented out because of ticket #255993
                    // if (Env.IsTizen) {
                    //     this.keyDownInterval = setInterval(() => {
                    //         Events.triggerEvents(PLAYER_SEEK, { keyCode });
                    //     }, keyPressInterval);
                    // }
                    Events.triggerEvents(PLAYER_SEEK, { keyCode, eventType: type });
                }
                break;
            case Key.VK_STOP:
                if (!visibleComponent || visibleComponent === ComponentsWithPlayer.PLAYER_UI) this.stopPlayer();
                break;
            case Key.VK_BACK:
                event.preventDefault();
                this.hidePlayerUI();
                break;
            default:
                break;
        }
    };

    handleKeyUp = (event) => {
        if (Env.IsTizen) {
            clearInterval(this.keyDownInterval);
        }
        const { keyCode, type } = event;
        const { visibleComponent } = this.state;
        const focusedEl = Focus.focused?.nodeRef;
        const scrubberWrapper = document.querySelector('.scrubber-wrapper');
        // const { keyPressInterval } = playerDefaultConfig;

        switch (keyCode) {
            case Key.VK_REWIND:
            case Key.VK_FAST_FWD:
                if (focusedEl !== scrubberWrapper && visibleComponent !== ComponentsWithPlayer.MINI_EPG) {
                    Events.triggerEvents(PLAYER_SEEK, { keyCode, eventType: type });
                }
                break;
            default:
                break;
        }
    };

    getPlayerActionType = (trigger) => {
        switch (trigger) {
            case UIActionTriggers.TP_PLAY_PAUSE:
                return Player.isPlaying() ? PlaybackEvents.PAUSE : PlaybackEvents.PLAY;
            case UIActionTriggers.TP_PAUSE:
                return PlaybackEvents.PAUSE;
            case UIActionTriggers.TP_PLAY:
                return PlaybackEvents.PLAY;
            default:
                return null;
        }
    };

    createPlayerActionEvent = (trigger) => getPlaybackEvent(this.getPlayerActionType(trigger));

    isChannelInChannelList = () => {
        const channelListId = selectSelectedChannelListId(store.getState());
        const channelIds = selectChannelListChannelIds(channelListId)(store.getState());
        return channelIds.find((channelId) => channelId === this.channelId);
    };

    onKey = (keyCode) => {
        const { visibleComponent } = this.state;
        // when no componentVisible
        // on BACK show exit notification
        // on LEFT/RIGHT show mini EPG
        if (Key.VK_0 <= keyCode && keyCode <= Key.VK_9) {
            return true;
        }
        console.log(`PageWithPlayer onKey ${keyCode}`);
        if (this.noVisibleComponent(visibleComponent)) {
            if (Player.isPlaying() || Player.isError()) {
                switch (keyCode) {
                    case Key.VK_CURSOR_ON:
                        this.showPlayerUI(false);
                        break;
                    case Key.VK_CURSOR_OFF:
                        this.hidePlayerUI();
                        break;
                    case Key.VK_BACK:
                    case Key.VK_UP:
                    case Key.VK_DOWN:
                    case Key.VK_CHAN_UP:
                    case Key.VK_CHAN_DOWN:
                        break;
                    case Key.VK_LEFT:
                    case Key.VK_RIGHT:
                        if (!this.isChannelInChannelList()) {
                            this.props.channelNotInActiveChannelListNotificationShow(
                                'NOT_IN_ACTIVE_CHANNEL_LIST',
                                NotificationIconType.INFO,
                                this.data.channelName
                            );
                            return true;
                        }
                        LoggingService.getInstance().logEvent(
                            getUIActionEvent(UIActionEvents.TO_VERTICAL_TV_GUIDE, { trigger: this.mapKeyCodeToTrigger(keyCode) })
                        );
                        this.showMiniEPG();
                        break;
                    case Key.VK_PLAY_PAUSE:
                        LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PLAY_PAUSE));
                        Player.playPause();
                        this.toggleNotificationButton(true);
                        return true;
                    case Key.VK_PAUSE:
                        LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PAUSE));
                        Player.pause();
                        this.toggleNotificationButton(true);
                        return true;
                    case Key.VK_REWIND:
                    case Key.VK_FAST_FWD:
                    case Key.VK_STOP:
                        return true;
                    default:
                        this.showPlayerUI();
                        break;
                }
            } else {
                // When the player is paused and the playerUi is not visible
                switch (keyCode) {
                    case Key.VK_PLAY:
                        LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PLAY));
                        Player.play();
                        this.toggleNotificationButton(true);
                        return true;
                    case Key.VK_ENTER:
                        this.showPlayerUI();
                        return true;
                    case Key.VK_PLAY_PAUSE:
                        LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PLAY_PAUSE));
                        Player.playPause();
                        this.toggleNotificationButton(true);
                        return true;
                    default:
                        break;
                }
            }
            switch (keyCode) {
                case Key.VK_UP:
                case Key.VK_DOWN:
                case Key.VK_CHAN_UP:
                case Key.VK_CHAN_DOWN:
                    if (!this.isChannelInChannelList()) {
                        this.showPlayerUI();
                        return true;
                    }
                    LoggingService.getInstance().logEvent(
                        getUIActionEvent(UIActionEvents.ZAP, { trigger: this.mapKeyCodeToTrigger(keyCode) })
                    );
                    handleChannelSwitching(keyCode, this.channelId);
                    this.toggleShowNotificationIcon(null);
                    return true;
                case Key.VK_BACK:
                case Key.VK_STOP:
                    if (History.stack[History.stack.length - 2] !== '/page/HOME') Player.stop();
                    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
                    this.props.playerBack(this.props.type);
                    return true;
                case Key.VK_ENTER:
                    this.toggleNotificationButton(true);
                    return true;
                case Key.VK_PLAY_PAUSE:
                    LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PLAY_PAUSE));
                    Player.playPause();
                    this.toggleNotificationButton(true);
                    return true;
                case Key.VK_PLAY:
                    if (Player.isPaused()) {
                        LoggingService.getInstance().logEvent(this.createPlayerActionEvent(UIActionTriggers.TP_PLAY));
                        Player.play();
                        this.toggleNotificationButton(true);
                    }
                    return true;
                default:
                    break;
            }
            return true;
        }
        return false;
    };

    mapKeyCodeToTrigger(keyCode) {
        switch (keyCode) {
            case Key.VK_UP:
                return UIActionTriggers.UP;
            case Key.VK_DOWN:
                return UIActionTriggers.DOWN;
            case Key.VK_CHAN_UP:
                return UIActionTriggers.CH_UP;
            case Key.VK_CHAN_DOWN:
                return UIActionTriggers.CH_DOWN;
            case Key.VK_LEFT:
                return UIActionTriggers.LEFT;
            case Key.VK_RIGHT:
                return UIActionTriggers.RIGHT;
            default:
                return UIActionTriggers.OK; // some default trigger
        }
    }

    showPlayerUI = (hideWithDelay = true) => {
        this.setState({ visibleComponent: ComponentsWithPlayer.PLAYER_UI });
        if (hideWithDelay) {
            this.resetPlayerUITimer();
        } else {
            this.isFreezedUI = true;
        }
    };

    hidePlayerUI = (hideWithDelay = false) => {
        if (hideWithDelay) {
            this.resetPlayerUITimer();
        } else {
            this.focusContainer();
            this.setState({ visibleComponent: null });
        }
        this.isFreezedUI = false;
    };

    showMiniEPG = () => {
        this.setState({ visibleComponent: ComponentsWithPlayer.MINI_EPG });
        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: ComponentsWithPlayer.MINI_EPG,
        });
    };

    hideOverlay = (showPlayerUI = false) => {
        if (showPlayerUI) {
            this.showPlayerUI();
        } else {
            this.focusContainer();
            this.setState({ visibleComponent: null });
        }

        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: null,
        });
    };

    hideSubtitleMenuOverlay = () => {
        this.setState({ visibleComponent: ComponentsWithPlayer.PLAYER_UI });
        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: ComponentsWithPlayer.PLAYER_UI,
        });
    };

    showSubtitlesMenu = () => {
        this.props.showSubtitleMenu(this.hideSubtitleMenuOverlay);
    };

    toggleNotificationButton = (showNotification) => {
        this.setState({ showNotificationButton: showNotification });
    };

    handlePlayerStateChange = (stateChanged) => {
        const {
            detail: { currentState, previousState },
        } = stateChanged;

        switch (currentState) {
            case clpp.Player.State.PLAYING:
                if (previousState !== clpp.Player.State.PAUSED) {
                    this.toggleShowNotificationIcon(null);
                    return;
                }
                this.toggleShowNotificationIcon(PlayerNotificationIcon.PLAY);
                break;
            case clpp.Player.State.PAUSED:
                this.toggleShowNotificationIcon(PlayerNotificationIcon.PAUSE);
                break;
            default:
                this.toggleShowNotificationIcon(null);
                break;
        }
    };

    toggleShowNotificationIcon = (showIcon) => {
        this.setState({ notificationIconToShow: showIcon });
    };

    getNotificationIconsOnHiddenPlayer = () => {
        const { notificationIconToShow, visibleComponent } = this.state;
        if (!notificationIconToShow) return null;
        const iconClassNames =
            notificationIconToShow === PlaybackEvents.PLAY ? 'icon-play icon-play-button' : 'icon-pause icon-pause-button';

        return (
            this.noVisibleComponent(visibleComponent) && (
                <div className="player-notification-icons-container">
                    <div className="icon-circle icon-circle-button" />
                    <div className={iconClassNames}></div>
                </div>
            )
        );
    };

    playPrevEvent = async () => {
        if (this.isEventSwitchingInProgress || this.isEventSwitchingBlocked) return undefined;
        this.isEventSwitchingInProgress = true;
        this.isEventSwitchingBlocked = true;
        const isRestricted = selectEventIsRestricted(this.prevAsset.id, PinSessionType.PIN_LIVE_TV)(store.getState());
        if (isRestricted) {
            await Player.stop();
            this.props.notificationShow('PIN_REQUIRED_EVENT_RESTRICTED_NOTIFICATION', 5);
            openPinOverlay(
                () => {
                    this.handleSwitchingEvent(this.prevAsset);
                },
                (status) => {
                    if (!status) {
                        this.isEventSwitchingBlocked = false;
                        this.isEventSwitchingInProgress = false;
                        History.go(`/details/${AssetType.EVENT}/${this.prevAsset.id}`);
                    }
                },
                PinAction.ENTER,
                PinSessionType.PIN_LIVE_TV,
                false
            );
            // eslint-disable-next-line consistent-return
            return;
        }
        return this.handleSwitchingEvent(this.prevAsset);
    };

    handleSwitchingEvent = (asset) => {
        const startFrom = asset.leadIn;
        Player.setBookmark(startFrom);
        playVod(asset, {
            isStartOver: false,
            isTrailer: false,
            isReplaceNeeded: true,
            bookmarkPosition: startFrom,
        });
    };

    getPlayerUI = () => {
        const { visibleComponent, showNotificationButton, isPinOverlayVisible, hasManifestFromStart } = this.state;
        return (
            <PlayerUI
                data={this.data}
                channelInfo={this.channelInfo}
                showUI={this.showPlayerUI}
                hideUI={this.hidePlayerUI}
                onEnded={this.onEnded}
                snackBarEnabled={
                    (this.isShowingPlayerUI(visibleComponent) || this.noVisibleComponent(visibleComponent)) &&
                    !isPinOverlayVisible &&
                    !this.props.isKeyboardOpen
                }
                showSubtitlesMenu={this.showSubtitlesMenu}
                resetTimer={this.resetPlayerUITimer}
                className={this.isShowingPlayerUI(visibleComponent) ? '' : 'hidden'}
                showHints={false}
                isLinearPlay={true}
                toggleNotificationButton={this.toggleNotificationButton}
                showNotificationButton={showNotificationButton}
                hasManifestFromStart={hasManifestFromStart}
                onSetStream={this.handleSetStream}
                onJumpToLive={this.handleJumpToLive}
                sessionType={PinSessionType.PIN_LIVE_TV}
                playPrevEvent={this.playPrevEvent}
                prevEventId={this.prevAsset?.id}
            />
        );
    };

    getClock = () => {
        const { showLiveClock } = this.props;
        const { visibleComponent } = this.state;
        if (visibleComponent !== ComponentsWithPlayer.MINI_EPG && showLiveClock) return <UI.Clock className="live-clock" />;
        return null;
    };

    handleSetStream = async (hasManifestFromStart, restartedDataId) => {
        this.clearNextProgramTimer();
        if (this.data) {
            this.props.loadEventsForChannel(this.channelId, this.data.end, this.data.end + Time.MINUTE_MS);
        }
        if (hasManifestFromStart) {
            this.restartedDataId = restartedDataId;
        }
        this.setState({ hasManifestFromStart });
    };

    jumpToLiveFromRestartStream = async () => {
        await PlayerAPI.setChannelStream(this.channelId);
        this.restartedDataId = null;
        this.setState({ hasManifestFromStart: false });
    };

    handleJumpToLive = () => {
        const { hasManifestFromStart } = this.state;
        if (hasManifestFromStart) {
            this.jumpToLiveFromRestartStream();
        } else {
            Player.seekToLive(true);
        }
    };

    getComponent = (visibleComponent) => {
        switch (visibleComponent) {
            case ComponentsWithPlayer.MINI_EPG:
                return <MiniEPG onClose={this.hideOverlay} />;
            default:
                return null;
        }
    };

    playChannel = () => {
        const isPlaying = Player.isPlaying();
        const sameChannel = this.props.liveChannelId === this.channelId;
        const playFromStart = State.hasState(NavKey.PAGE_WITH_PLAYER) ? State.get(NavKey.PAGE_WITH_PLAYER).playFromStart : false;
        if (isPlaying && sameChannel && !playFromStart) return;
        this.audioTrackWasInitialized = false;
        this.props.channelLiveIdChange(this.channelId);
        this.playContent(playFromStart);
    };

    render() {
        const { visibleComponent } = this.state;
        const Component = this.getComponent(visibleComponent);
        return (
            <Container
                className={`page`}
                onKey={this.onKey}
                ref={(ref) => {
                    if (ref) {
                        this.containerRef = ref.nodeRef || null;
                    }
                }}
            >
                {this.state.hasInternetConnection && <PlayerError />}
                {!Env.IsBulgaria && <RadioOverlay />}
                {this.getClock()}
                {this.getNotificationIconsOnHiddenPlayer()}
                {this.getPlayerUI()}
                {Component}
                <StandByService />
            </Container>
        );
    }
}

export default connect(
    (state) => {
        const config = selectConfig(state);
        const liveChannelId = selectChannelLiveId(state);
        const showLiveClock = Profile.selectors.selectShowLiveClock(state);
        const isKeyboardOpen = Keyboard.selectors.selectIsActive(state);
        return {
            isHomeScreenPlayerEnabled: config.home.player.enabled,
            isHomeScreenPlayerMuted: config.home.player.muted,
            isHomePlayerWideViewEnabled: config.home.player.wideView,
            hideControllsAfter: config.player.hideControlsAfter,
            restrictivePlayerChannelSwitch: config.player.restrictivePlayerChannelSwitch,
            liveChannelId,
            showLiveClock,
            isKeyboardOpen,
        };
    },
    (dispatch) => ({
        clearOverlay: () => dispatch(Overlay.actions.unmount()),
        channelSwitchIdChange: (id) => dispatch(channelSwitchIdChange(id)),
        channelLiveIdChange: (id) => dispatch(channelLiveChange(id)),
        loadEventsForChannel: (id, start, end) => dispatch(loadEventsForChannel(id, start, end)),
        pinActiveStatusChanged: (isActive) => dispatch(pinActiveStatusChanged(isActive)),
        pinLiveStatusChanged: (isActive) => dispatch(pinActiveStatusLiveTvChanged(isActive)),
        playerBack: (type) => dispatch(EnhancedBack.onPlayerBack(type)),
        notificationShow: (title, timer) => dispatch(textNotificationShow(title, timer)),
        channelNotInActiveChannelListNotificationShow: (text, icon, channelTitle) =>
            dispatch(channelNotInActiveChannelListNotificationShow(text, icon, channelTitle)),
        showSubtitleMenu: (onClose) =>
            dispatch(
                Overlay.actions.mount(
                    Overlay.Type.SubtitleMenu,
                    {
                        onClose,
                        playerOptions: Player.getPlayerOptions(),
                        currentSubtitle: Player.getCurrentSubtitle(),
                        currentAudioTrack: Player.getCurrentAudioTrack(),
                        fontSize: Player.getFontSize(),
                        playerSetFontSize: Player.setFontSize,
                        fontColor: Player.getFontColor(),
                        playerSetFontColor: Player.setFontColor,
                        backgroundColor: Player.getBackgroundColor(),
                        playerSetBackgroundColor: Player.setBackgroundColor,
                        edgeColor: Player.getEdgeColor(),
                        edgeType: Player.getEdgeType(),
                        playerSetEdgeType: Player.setEdgeType,
                        playerSetSubtitleStream: Player.setSubtitleStream,
                        playerSetAudioStream: Player.setAudioStream,
                    },
                    Constants.Ctx.subtitles
                )
            ),
    })
)(PageWithPlayer);
