import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordingRemoveMutationVariables = Types.Exact<{
  input: Types.DeleteRecordingInput;
}>;


export type RecordingRemoveMutation = { __typename?: 'Nexx4Mutations', deleteRecording: { __typename: 'DeleteRecordingPayload', event?: { __typename?: 'Event', id: string } | null, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export type RecordingRemoveFragment = { __typename?: 'Nexx4Mutations', deleteRecording: { __typename: 'DeleteRecordingPayload', event?: { __typename?: 'Event', id: string } | null, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export const RecordingRemoveFragmentDoc = gql`
    fragment recordingRemove on Nexx4Mutations {
  deleteRecording(input: $input) {
    event {
      id
    }
    quota {
      ...quota
    }
    __typename
  }
}
    ${QuotaFragmentDoc}`;
export const RecordingRemoveDocument = gql`
    mutation recordingRemove($input: DeleteRecordingInput!) {
  ...recordingRemove
}
    ${RecordingRemoveFragmentDoc}`;
export type RecordingRemoveMutationFn = Apollo.MutationFunction<RecordingRemoveMutation, RecordingRemoveMutationVariables>;

/**
 * __useRecordingRemoveMutation__
 *
 * To run a mutation, you first call `useRecordingRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordingRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordingRemoveMutation, { data, loading, error }] = useRecordingRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordingRemoveMutation(baseOptions?: Apollo.MutationHookOptions<RecordingRemoveMutation, RecordingRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordingRemoveMutation, RecordingRemoveMutationVariables>(RecordingRemoveDocument, options);
      }
export type RecordingRemoveMutationHookResult = ReturnType<typeof useRecordingRemoveMutation>;
export type RecordingRemoveMutationResult = Apollo.MutationResult<RecordingRemoveMutation>;
export type RecordingRemoveMutationOptions = Apollo.BaseMutationOptions<RecordingRemoveMutation, RecordingRemoveMutationVariables>;