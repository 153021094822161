import React from 'react';

import { ChildRenderer, Children } from 'App/Packages/UI/Children';
import { useSelectOptionsContext } from 'App/Packages/UI/Select/SelectOptionsContext';

export type SelectOptionsChildrenProps = {
    children: ChildRenderer<string>;
};

export type SelectOptionsChildrenModule = {} & React.FunctionComponent<SelectOptionsChildrenProps>;

export const SelectOptionsChildren: SelectOptionsChildrenModule = (props) => {
    const { ids } = useSelectOptionsContext();
    return <Children items={ids}>{props.children}</Children>;
};
