import { GQL } from '__SMART_APP_OLD__/app/gql';

import { ContentMarkerType } from 'App/Types/ContentMarker';

export enum MiniEpgMode {
    ZAPPER = 'ZAPPER',
    FULL = 'FULL',
}

export enum CardType {
    PROGRAM_CARD = 'PROGRAM_CARD',
    DATE_CARD = 'DATE_CARD',
}

interface IProgramCard {
    type: CardType.PROGRAM_CARD;
    data: GQL.EventID;
    marker?: ContentMarkerType;
}

interface IDateCard {
    type: CardType.DATE_CARD;
    data: Date;
    marker?: ContentMarkerType;
}

export type Card = IProgramCard | IDateCard;
