import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageDetailAttachments } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailAttachments';
import { selectMessageDetailHasAttachments } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

export const MessageDetailAttachmentsContainer: React.FunctionComponent = () => {
    const hasAttachments = useSelector(selectMessageDetailHasAttachments);
    return <div className="message-detail__attachments-container">{hasAttachments && <MessageDetailAttachments />}</div>;
};
