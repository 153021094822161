/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-lines */
import React from 'react';

import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { MessageInboxIcon } from '__SMART_APP_OLD__/app/components/Icon/MessageInboxIcon';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import { selectMenuItems, selectSearchConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectIsLiveChannelEventRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { Header as HeaderModule } from '__SMART_APP_OLD__/app/modules/Header';
import { toMessageInbox } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { toNowOnTV } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/actions';
import { toTvGuide } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Events, { CONTAINER_SCROLLED, DARK_MODE_CHANGED, USER_ACTIVITY } from '__SMART_APP_OLD__/config/Events';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Elements from '__SMART_APP_OLD__/navigation/Elements';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { NavKey, Route, SearchContext, headerDimensionCorrections, headerShadow } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import State from '__SMART_APP_OLD__/utils/State';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { Key } from 'App/Modules/Key';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { UI } from 'App/Packages/UI';
import { MenuItemType } from 'App/Types/Menu';

const menuItemToUIEventMap = {
    [MenuItemType.MyLibrary]: UIActionEvents.TO_MY_LIBRARY,
    [MenuItemType.Home]: UIActionEvents.TO_HOME,
    [MenuItemType.LiveTV]: UIActionEvents.PLAY_LIVE,
    [MenuItemType.TVGuide]: UIActionEvents.TO_GRID_TV_GUIDE,
};

class Header extends React.Component {
    menuRefs = [];

    menuItems = [];

    headerNotFocused = true;

    /**
     * @description - Returns the length of the menu (main menu items + secondary menu)
     * @returns {number} - length of menu items
     */
    get menuLen() {
        // 2 because of channel lists and inbox
        // if env Business we show only the settings this is why we add only 1
        const subMenuItemsCount = Env.isBusiness ? 0 : 2 + this.props.searchConfig.enabled;
        return this.props.menuItems.length + subMenuItemsCount;
    }

    constructor(props) {
        super(props);
        this.state = {
            darkMode: false,
        };
        this.backgroundGradientUpdater = this.backgroundGradientUpdater.bind(this);
    }

    componentDidMount() {
        Events.addEventListener(CONTAINER_SCROLLED, this.backgroundGradientUpdater);
        window.addEventListener(DARK_MODE_CHANGED, this.darkModeChangeHandler);
        Focus.addEventListener('back', this.onBack);

        if (this.props.selectedIndexesHistory.length > 0) {
            this.props.removeIndexFromHistory();
            this.props.setSelectedIndex(this.props.selectedIndexesHistory[0]);
            this.props.setFocusedIndex(this.props.selectedIndexesHistory[0]);
        }

        const route = CustomHistory.stack[CustomHistory.stack.length - 1];
        if (!route || (route && route.includes(Route.HOME))) {
            this.props.clearIndexesHistory();
            this.props.setSelectedIndex(0);
            this.props.setFocusedIndex(0);
        }

        this.generateShadowForMenu();
    }

    componentDidUpdate(prevProps, prevState) {
        const parentElement = this.menuContainerRef.nodeRef.nodeRef;

        if (this.props.focusedIndex < this.menuItems.length) {
            parentElement.setAttribute('style', '');
            const element = parentElement.children[this.props.focusedIndex].getBoundingClientRect();
            const scroll = element?.left + element?.width / 2 - parentElement?.offsetWidth / 2 - parentElement?.offsetLeft;
            parentElement.scrollLeft += scroll;
        } else {
            parentElement.setAttribute('style', 'overflow:hidden;');
        }

        this.generateShadowForMenu();

        if (prevState.darkMode !== this.state.darkMode) {
            this.generateShadowForMenu();
            if (!Focus.focused) return;
            if (!Focus.focused.ref.classList.contains('focused')) {
                Focus.focused.ref.classList.add('focused');
            }
        }
    }

    componentWillUnmount() {
        Events.removeEventListener(CONTAINER_SCROLLED, this.backgroundGradientUpdater);
        window.removeEventListener(DARK_MODE_CHANGED, this.darkModeChangeHandler);
        Focus.removeEventListener('back', this.onBack);

        /**
         * We add this index only if we are one a page that opens a redux page
         * and the header is not focused (e.g video store -> stripes -> more)
         * Without this index on back we will remove the index of the page that we go back to
         * (video store)
         */
        if (this.headerNotFocused) {
            this.props.addIndexToHistory(this.props.selectedIndex);
        }
    }

    backgroundGradientUpdater(containerScrollTop) {
        if (containerScrollTop > 0) {
            this.setState({ isStrongerGradientNeeded: true });
        } else {
            this.setState({ isStrongerGradientNeeded: false });
        }
    }

    darkModeChangeHandler = ({ detail: { mode = false, timeout = 0 } }) => {
        if (!window.location.pathname.includes(Route.HOME)) {
            this.setState({ darkMode: false });
            return;
        }
        if (mode === this.state.darkMode || typeof mode !== 'boolean') return;
        if (timeout) {
            setTimeout(() => this.setState({ darkMode: mode }), timeout);
        } else {
            this.setState({ darkMode: mode });
        }
    };

    onCustomRect() {
        return {
            width: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        };
    }

    onBack = () => {
        if (window.location.pathname.includes(Route.HOME)) {
            return false;
        }

        this.props.removeIndexFromHistory();
        const index = this.props.selectedIndexesHistory[0] ?? 0;

        this.props.setSelectedIndex(index);
        this.props.setFocusedIndex(index);
        return false;
    };

    onKey = (keyCode) => {
        if (window.location.pathname.includes(Route.HOME)) {
            Events.triggerEvents(USER_ACTIVITY);
        }

        const { focusedIndex } = this.props;

        const { nodes } = Elements;
        let next;
        if (keyCode === Key.VK_RIGHT) {
            next = focusedIndex + 1;
            this.setFocus(next);
            return true;
        }
        if (keyCode === Key.VK_LEFT) {
            next = focusedIndex - 1;
            this.setFocus(next);
            return true;
        }
        if (keyCode === Key.VK_DOWN) {
            // IMPORTANT for each container or focusable add  +1
            Focus.focus(nodes[this.menuLen + 3]); // +2 because we have new node (menu-items container)
            this.headerNotFocused = true;
            this.forceUpdate();
            return true;
        }
        return false;
    };

    onFocus = (index) => {
        if (this.props.focusedIndex === index || this.headerNotFocused) return;
        this.props.setFocusedIndex(index);
    };

    setFocus = (_index) => {
        const { menuRefs } = this;
        const { focusedIndex } = this.props;
        let index = _index < 0 ? 0 : _index;
        index = index > this.menuLen ? this.menuLen : index;
        if (index !== focusedIndex) {
            Focus.focus(menuRefs[index].nodeRef);
        }
        this.props.setFocusedIndex(index);
    };

    onEnter = () => {
        const { focusedIndex } = this.props;
        this.props.addIndexToHistory(focusedIndex);
        this.props.setSelectedIndex(focusedIndex);
        this.select(focusedIndex);
        // override focus to match last selected item
        this.setFocus(focusedIndex);
        this.headerNotFocused = true;
    };

    onButtonEnterSettings = () => {
        const { selectedIndex, focusedIndex } = this.props;
        const menuItem = this.menuItems[selectedIndex];
        this.props.addIndexToHistory(focusedIndex);
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_SETTINGS, { menuItem }));
        this.props.toSettings();
    };

    onButtonEnterSearch = () => {
        let context;
        const { selectedIndex, focusedIndex } = this.props;
        const menuItem = this.menuItems[selectedIndex];
        this.props.addIndexToHistory(focusedIndex);

        switch (menuItem.id) {
            case MenuItemType.Home:
            case MenuItemType.LiveTV:
            case MenuItemType.TVGuide:
                context = SearchContext.events;
                break;
            case MenuItemType.MyLibrary:
                context = SearchContext.onDemand;
                break;
            default:
                context = SearchContext.events;
        }
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_SEARCH, { menuItem }));
        this.props.toSearch({ context });
    };

    menuRefExists = (nodeRef) => this.menuRefs.findIndex((node) => node.nodeRef.id === nodeRef.nodeRef.id) !== -1;

    generateMenuItems = () => {
        const mainMenuItems = [];
        const searchFeature = this.props.searchConfig.enabled;
        this.menuItems = this.props.menuItems;
        const secondaryMenuItems = [];
        const mainMenuLength = this.menuItems ? this.menuItems.length : 0;

        const { focusedIndex } = this.props;

        const channelListsIndex = mainMenuLength;
        const inboxIndex = mainMenuLength + 1;
        const searchIndex = mainMenuLength + 2;
        // if env.isBusiness inbox search channelist are hidden
        // we have only settings icon so we adjust
        const settingsIndex = Env.isBusiness ? mainMenuLength : searchFeature ? searchIndex + 1 : searchIndex;
        this.menuItems.forEach((menu, index) => {
            const menuId = menu.id.toLowerCase();
            mainMenuItems.push(
                <Focusable
                    className={`menu-item ${index === this.props.selectedIndex ? 'selected' : ''}`}
                    id={menuId}
                    key={menuId}
                    dataTestId={`menu_${menuId}`}
                    onCustomRect={this.onCustomRect}
                    onKey={this.onKey}
                    ref={(ref) => {
                        if (ref && !this.menuRefExists(ref.nodeRef)) {
                            this.menuRefs.push(ref.nodeRef);
                        }
                    }}
                    onFocus={() => this.onFocus(index)}
                    onEnter={() => this.onEnter(index)}
                    focusDelay={false}
                >
                    <Text
                        className="header-item-text"
                        typeface={Typeface.SANS}
                        size={FontSize.TITLE_3}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                    >
                        {menu.title}
                    </Text>
                </Focusable>
            );
        });
        secondaryMenuItems.push(
            <Focusable
                className="menu-icon"
                id="channelLists"
                key="channelLists"
                dataTestId="menu_channelLists"
                onCustomRect={this.onCustomRect}
                onKey={this.onKey}
                onEnter={this.props.openSelectChannelListOverlay}
                ref={(ref) => {
                    if (ref && !this.menuRefExists(ref.nodeRef)) {
                        this.menuRefs.push(ref.nodeRef);
                    }
                }}
                onFocus={() => {
                    this.onFocus(channelListsIndex);
                }}
                focusDelay={false}
            >
                <Icon
                    name={IconName.CHANNEL_LIST}
                    className="menu-icon__icon menu-icon__icon--channel-lists"
                    isFocused={focusedIndex === channelListsIndex && (Focus?.focused?.nodeRef?.id === 'channelLists' || !Focus.focused)}
                />
            </Focusable>,

            <Focusable
                className="menu-icon"
                id="inbox"
                key="inbox"
                dataTestId="menu_inbox"
                onCustomRect={this.onCustomRect}
                onKey={this.onKey}
                onEnter={() => {
                    this.props.addIndexToHistory(focusedIndex);
                    this.props.toMessageInbox();
                }}
                ref={(ref) => {
                    if (ref && !this.menuRefExists(ref.nodeRef)) {
                        this.menuRefs.push(ref.nodeRef);
                    }
                }}
                onFocus={() => this.onFocus(inboxIndex)}
                focusDelay={false}
            >
                <MessageInboxIcon
                    className="menu-icon__icon menu-icon__icon--inbox"
                    isFocused={focusedIndex === inboxIndex && (Focus?.focused?.nodeRef?.id === 'inbox' || !Focus.focused)}
                />
            </Focusable>,
            searchFeature && (
                <Focusable
                    className="menu-icon"
                    id="search"
                    key="search"
                    dataTestId="menu_search"
                    onCustomRect={this.onCustomRect}
                    onKey={this.onKey}
                    onEnter={this.onButtonEnterSearch}
                    ref={(ref) => {
                        if (ref && !this.menuRefExists(ref.nodeRef)) {
                            this.menuRefs.push(ref.nodeRef);
                        }
                    }}
                    onFocus={() => this.onFocus(searchIndex)}
                    focusDelay={false}
                >
                    <Icon
                        name={IconName.OLD_SEARCH}
                        className="menu-icon__icon menu-icon__icon--search"
                        isFocused={focusedIndex === searchIndex && (Focus?.focused?.nodeRef?.id === 'search' || !Focus.focused)}
                    />
                </Focusable>
            ),
            <Focusable
                className="menu-icon"
                id="settings"
                key="settings"
                dataTestId="menu_settings"
                onCustomRect={this.onCustomRect}
                onKey={this.onKey}
                onEnter={this.onButtonEnterSettings}
                ref={(ref) => {
                    if (ref && !this.menuRefExists(ref.nodeRef)) {
                        this.menuRefs.push(ref.nodeRef);
                    }
                }}
                onFocus={() => this.onFocus(settingsIndex)}
                focusDelay={false}
            >
                <Icon
                    name={IconName.COGWHEEL}
                    className="menu-icon__icon menu-icon__icon--settings"
                    isFocused={focusedIndex === settingsIndex && (Focus?.focused?.nodeRef?.id === 'settings' || !Focus.focused)}
                />
            </Focusable>
        );
        Env.isBusiness && this.menuRefs.splice(-1, 3);
        Env.isBusiness && secondaryMenuItems.splice(0, 3);
        return { mainMenuItems, secondaryMenuItems };
    };

    // eslint-disable-next-line max-statements,react-func/max-lines-per-function
    select(index) {
        if (this.menuItems.length === 0) {
            return;
        }

        if (index === -1) {
            return;
        }

        const { onMenuChange } = this.props;
        const menuItem = this.menuItems[index];
        const { selectedIndex } = this.props; // 0
        const current = this.menuItems[selectedIndex];

        switch (menuItem.id) {
            case MenuItemType.TVGuide: {
                this.props.toTVGuide();
                return;
            }
            case MenuItemType.LiveTV: {
                const { liveChannelId, isRestricted } = this.props;

                if (isRestricted) {
                    openPinOverlay(() => {
                        // from -> should be PIN but there is no State pin
                        // Not sure what the trigger should be
                        // LoggingService.getInstance().logEvent(
                        //  getUIActionEvent(UIActionEvents.PLAY_LIVE, {})\
                        // );
                        const event = getUIActionEvent(UIActionEvents.PLAY_LIVE, { menuItem: current, id: liveChannelId });
                        LoggingService.getInstance().logEvent(event);

                        playChannel(liveChannelId);
                    });
                    return;
                }
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY_LIVE, { menuItem: current, id: liveChannelId }));
                playChannel(liveChannelId);
                return;
            }
            case MenuItemType.MyLibrary:
                this.props.toMyLibrary();
                break;
            case MenuItemType.Home: {
                if (!document.location.pathname.includes(menuItem.id)) {
                    LoggingService.getInstance().logEvent(getUIActionEvent(menuItemToUIEventMap[menuItem.id], { menuItem: current }));
                    CustomHistory.go(`/page/${menuItem.id}`);
                }
                break;
            }
            case MenuItemType.VideoStore:
                this.props.toVideoStore();
                break;
            case MenuItemType.NowOnTV:
                this.props.toNowOnTV();
                break;
            case MenuItemType.Playground:
                this.props.toPlayground();
                break;
            default:
                break;
        }

        const stripeState = State.get(NavKey.STRIPE);

        if (stripeState && stripeState.menuId !== menuItem.id) {
            State.remove(NavKey.STRIPE);
        }

        if (onMenuChange) {
            onMenuChange(menuItem.id);
        }
    }

    onFocusChanged = () => {
        if (this.headerNotFocused) {
            this.headerNotFocused = false;
            const index = this.props.selectedIndex ?? this.props.selectedIndex;
            const ourNode = this.menuRefs[index];
            Focus.focus(ourNode.nodeRef);

            if (this.props.selectedIndex !== this.props.focusedIndex) {
                this.props.setFocusedIndex(this.props.selectedIndex);
            }
            return true;
        }
        return false;
    };

    generateShadowForMenu = () => {
        const { leftCorrection, rightCorrection } = headerDimensionCorrections;
        const { additionalInterval, maxVisiblePart } = headerShadow;

        const menuContainer = this.menuContainerRef?.nodeRef?.nodeRef;
        const menuContainerRect = menuContainer?.getBoundingClientRect();
        const leftContainerBorder = menuContainerRect?.left + leftCorrection;
        const rightContainerBorder = leftContainerBorder + menuContainerRect?.width - rightCorrection;
        const menuItemsArray = document.querySelectorAll('.menu-item');
        let hideItems = false;

        menuItemsArray.forEach((item, index, arr) => {
            const element = item?.getBoundingClientRect();
            const elementStyle = window.getComputedStyle(item);
            const elementRightMargin = parseInt(elementStyle.marginRight, 10);
            const nextElement = item?.nextElementSibling;
            const elementLeftSide = element?.left + leftCorrection;
            const elementRightSide = element?.right - rightCorrection;
            const elementSize = elementRightSide - elementLeftSide;
            const elementVisiblePart = (elementRightSide - leftContainerBorder) / (elementSize / 100);
            const calculatedInterval = index === 0 || index === arr.length - 1 ? 0 : additionalInterval;

            item?.classList.remove('left-header-shadow', 'right-header-shadow', 'not-visible');

            if (hideItems) {
                item?.classList.add('not-visible');
            } else if (elementVisiblePart < maxVisiblePart) {
                item?.classList.add('not-visible');
                nextElement?.classList.add('left-header-shadow');
            } else if (
                menuContainer.scrollLeft !== 0 &&
                element?.left - calculatedInterval < leftContainerBorder &&
                element?.right > leftContainerBorder
            ) {
                item?.classList.remove('not-visible');
                item?.classList.add('left-header-shadow');
            } else if (
                (element?.left < rightContainerBorder && element?.right - elementRightMargin > rightContainerBorder) ||
                (menuContainer.scrollLeft === 0 && element?.right > rightContainerBorder)
            ) {
                item?.classList.add('right-header-shadow');
                hideItems = true;
            }
        });
    };

    render() {
        const { mainMenuItems, secondaryMenuItems } = this.generateMenuItems();

        return (
            <Theme.Provider theme={this.state.darkMode ? Theme.Type.Dark : this.props.theme}>
                <div
                    className={`header${this.state.isStrongerGradientNeeded ? ' stronger-header-background-linear-gradient' : ''} ${
                        this.state.darkMode ? 'dark-theme stronger-header-background-linear-gradient' : ''
                    }`}
                    ref={(ref) => {
                        if (ref) {
                            this.nodeRef = ref;
                        }
                    }}
                >
                    <UI.Logo.XploreTV className="operator-logo" />
                    <div className="header-items-container">
                        <Container
                            className="menu-items main"
                            onFocusChanged={this.onFocusChanged.bind(this)}
                            ref={(ref) => {
                                if (ref) {
                                    this.menuContainerRef = ref;
                                }
                            }}
                        >
                            {mainMenuItems}
                        </Container>
                        <Container onFocusChanged={this.onFocusChanged.bind(this)} className="menu-items secondary">
                            {secondaryMenuItems}
                        </Container>
                    </div>
                    <UI.Clock className="clock" />
                </div>
            </Theme.Provider>
        );
    }
}

export default connect(
    (state) => ({
        focusedIndex: HeaderModule.selectors.selectFocusedIndex(state),
        selectedIndex: HeaderModule.selectors.selectSelectedIndex(state),
        selectedIndexesHistory: HeaderModule.selectors.selectSelectedIndexesHistory(state),
        liveChannelId: selectChannelLiveId(state),
        isRestricted: selectIsLiveChannelEventRestricted(state),
        searchConfig: selectSearchConfig(state),
        menuItems: selectMenuItems(state),
        isOverlayLayerActive: Overlay.selectors.selectIsActive(state),
        theme: Theme.selectors.select(state),
    }),
    (dispatch) => ({
        setFocusedIndex: (index) => dispatch(HeaderModule.actions.focusedIndexChanged(index)),
        setSelectedIndex: (index) => dispatch(HeaderModule.actions.selectedIndexChanged(index)),
        clearIndexesHistory: () => dispatch(HeaderModule.actions.clearIndexesHistory()),
        addIndexToHistory: (index) => dispatch(HeaderModule.actions.addIndexToHistory(index)),
        removeIndexFromHistory: (index) => dispatch(HeaderModule.actions.removeIndexFromHistory(index)),
        toMessageInbox: () => dispatch(toMessageInbox()),
        openSelectChannelListOverlay: () => dispatch(Overlay.actions.mount(Overlay.Type.ChannelListSelect, {})),
        toTVGuide: () => dispatch(toTvGuide()),
        toNowOnTV: () => dispatch(toNowOnTV()),
        toMyLibrary: () => dispatch(Screen.actions.mount(Screen.Type.MyLibrary, {})),
        toPlayground: () => dispatch(Screen.actions.mount(Screen.Type.Search, {})), // @note playground here
        toVideoStore: () => dispatch(Screen.actions.mount(Screen.Type.VideoStore, {})),
        toSearch: (props) => dispatch(Screen.actions.mount(Screen.Type.Search, props)),
        toSettings: () => dispatch(Screen.actions.mount(Screen.Type.SETTINGS, {})),
    })
)(Header);
