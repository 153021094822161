import { ParsedConfigServiceConfigSettingsElement } from '__SMART_APP_OLD__/app/modules/Config/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { SettingsElementType } from '__SMART_APP_OLD__/app/types';

const selectPin = (state: State): ParsedConfigServiceConfigSettingsElement<SettingsElementType> =>
    state.config.settings.elements.find((element) => element.id === SettingsElementType.PIN)!;

export const pinCodeSelectors = {
    private: {
        selectPin,
    },
    public: {},
};
