import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';

export const merge = (root: FolderListFragment | null, payload: FolderListFragment): FolderListFragment | null => {
    if (!root) return payload;
    const result: FolderListFragment = {
        id: root.id,
        folders: {
            pageInfo: {
                hasNextPage: payload.folders.pageInfo.hasNextPage,
                endCursor: payload.folders.pageInfo.endCursor,
                __typename: root.folders.pageInfo.__typename,
            },
            edges: [...root.folders.edges, ...payload.folders.edges],
            totalCount: root.folders.totalCount,
            __typename: root.folders.__typename,
        },
        __typename: root.__typename,
    };
    return result;
};
