import React from 'react';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { FolderItems as FolderItemsModule } from 'App/Packages/UI/Folder/Modules/FolderItems';
import { FolderItemsProps as FolderItemsModuleProps } from 'App/Packages/UI/Folder/Modules/FolderItems/Types';

const FolderItemsInternal: React.FunctionComponent<DivPropsExtendable> = (props) => {
    const items = FolderItemsModule.use();
    return <Div {...props} defaultClassName="folder-items" ref={items.ref} />;
};

export type FolderItemsContainerProps = DivPropsExtendable & FolderItemsModuleProps;

export type FolderItemsComponent = React.FunctionComponent<FolderItemsContainerProps>;

export const FolderItems: FolderItemsComponent = (props) => {
    const { id, ctx, disabled, offset, initial, onEnter, ...rest } = props;
    return (
        <FolderItemsModule id={id} ctx={ctx} disabled={disabled} offset={offset} initial={initial} onEnter={onEnter}>
            <FolderItemsInternal {...rest} />
        </FolderItemsModule>
    );
};
