import React, { useEffect, useRef } from 'react';

import { DataGrid } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/DataGrid';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { NavKey } from '__SMART_APP_OLD__/utils/Constants';
import State from '__SMART_APP_OLD__/utils/State';

import { Key, NumberKeys } from 'App/Modules/Key';
import { UI } from 'App/Packages/UI';

const containerKeyPressHandler = (key: number): boolean => {
    if (NumberKeys.includes(key)) return true;
    switch (key) {
        case Key.VK_BACK:
        case Key.VK_UP:
        case Key.VK_DOWN:
        case Key.VK_LEFT:
        case Key.VK_RIGHT:
        case Key.VK_ENTER:
            return true;
        default:
            return false;
    }
};

interface Props {
    onClose: (isRestricted?: boolean) => void;
    isVodOrCatchup?: boolean;
}

export const MiniEPG: React.FunctionComponent<Props> = (props) => {
    const container = useRef<Container | null>(null);

    useEffect(() => {
        State.remove(NavKey.MINI_EPG);
        if (container.current?.nodeRef) {
            Focus.focus(container.current.nodeRef as unknown as null);
        }
    }, []);

    return (
        <div className="mini-epg mini-epg-overlay" id="mini-epg">
            <UI.Clock className="mini-epg-clock" />
            <Container ref={container} onKey={containerKeyPressHandler} />
            <DataGrid {...props} onClose={props.onClose} />
        </div>
    );
};
