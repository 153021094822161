import React from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Header/Constants';

const Default: HeaderItemValue = {
    id: '',
    ctx: Constants.Ctx,
    ref: { current: null },
    active: new Subscribable.Value(false),
    focused: new Subscribable.Value(false),
    disabled: new Subscribable.Value(false),
    enter: () => {},
    focus: () => {},
    scrollIntoView: () => {},
};

export type HeaderItemValue = {
    id: string;
    ctx: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
    active: Subscribable.Value<boolean>;
    focused: Subscribable.Value<boolean>;
    disabled: Subscribable.Value<boolean>;
    enter: () => void;
    focus: () => void;
    scrollIntoView: () => void;
};

export type HeaderItemContextModule = React.Context<HeaderItemValue> & { Default: HeaderItemValue };

export const HeaderItemContext: HeaderItemContextModule = Object.assign(React.createContext(Default), { Default });
