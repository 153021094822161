/* eslint-disable max-classes-per-file */
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

class BackEngine {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onPlayerBack(_assetType?: string): Thunk<void> {
        return () => async () => {};
    }
}

export class EnhancedBack {
    public static readonly Engine = BackEngine;

    private static engine: BackEngine = new BackEngine();

    public static readonly initialize = (engine: BackEngine): void => {
        EnhancedBack.engine = engine;
    };

    public static readonly onPlayerBack = (assetType?: string): Thunk<void> => EnhancedBack.engine.onPlayerBack(assetType);
}
