import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type SeriesInfoFragment = { __typename: 'SeriesInfo', id: string, title?: string | null };

export const SeriesInfoFragmentDoc = gql`
    fragment seriesInfo on SeriesInfo {
  id
  title
  __typename
}
    `;