import React from 'react';

export type SettingsSectionContentItemContextState<T extends string = string> = {
    id: T;
    isSecondary: boolean;
    isFocused: boolean;
    isActive: boolean;
    isHidden: boolean;
    isDisabled: boolean;
};

export const SettingsSectionContentItemContext = React.createContext<SettingsSectionContentItemContextState>({
    id: '',
    isSecondary: false,
    isFocused: false,
    isActive: false,
    isHidden: false,
    isDisabled: false,
});

export type SettingsSectionContentItemContextProviderProps = {
    value: SettingsSectionContentItemContextState;
};

export const SettingsSectionContentItemContextProvider = SettingsSectionContentItemContext.Provider as React.FunctionComponent<
React.PropsWithChildren <SettingsSectionContentItemContextProviderProps>>;
