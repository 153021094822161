import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListLoadManyQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
}>;


export type ChannelListLoadManyQuery = { __typename?: 'Nexx4Queries', channelLists?: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } | null> } | null };

export type ChannelListLoadManyFragment = { __typename?: 'Nexx4Queries', channelLists?: { __typename?: 'ChannelListCatalog', items: Array<{ __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } | null> } | null };

export const ChannelListLoadManyFragmentDoc = gql`
    fragment channelListLoadMany on Nexx4Queries {
  channelLists(profileId: $profileId) {
    items {
      ...channelList
      channels {
        totalCount
      }
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListLoadManyDocument = gql`
    query channelListLoadMany($profileId: ID!) {
  ...channelListLoadMany
}
    ${ChannelListLoadManyFragmentDoc}`;

/**
 * __useChannelListLoadManyQuery__
 *
 * To run a query within a React component, call `useChannelListLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelListLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelListLoadManyQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useChannelListLoadManyQuery(baseOptions: Apollo.QueryHookOptions<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>(ChannelListLoadManyDocument, options);
      }
export function useChannelListLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>(ChannelListLoadManyDocument, options);
        }
export function useChannelListLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>(ChannelListLoadManyDocument, options);
        }
export type ChannelListLoadManyQueryHookResult = ReturnType<typeof useChannelListLoadManyQuery>;
export type ChannelListLoadManyLazyQueryHookResult = ReturnType<typeof useChannelListLoadManyLazyQuery>;
export type ChannelListLoadManySuspenseQueryHookResult = ReturnType<typeof useChannelListLoadManySuspenseQuery>;
export type ChannelListLoadManyQueryResult = Apollo.QueryResult<ChannelListLoadManyQuery, ChannelListLoadManyQueryVariables>;