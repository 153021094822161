import React, { useMemo } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

import { Overlay } from 'App/Modules/Overlay';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { Scroll } from 'App/Packages/Scroll';

const SCROLL_STEP = 400;

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.ShowMore>> = (props) => {
    const dispatch = useDispatch();
    const scrollableRef = React.useRef<HTMLDivElement>(null);

    const scroll = useMemo(() => new Scroll(scrollableRef), [scrollableRef]);

    Focus.useVerticalNavigation((event) => {
        if (!scrollableRef.current) return;

        if (event.y === -1) {
            scroll.by({ top: -SCROLL_STEP });

            return;
        }

        scroll.by({ top: SCROLL_STEP });
    });
    Focus.useBack(() => dispatch(Overlay.actions.unmount()));
    Focus.useEnter(() => dispatch(Overlay.actions.unmount()));

    return (
        <Layout className="show-more-overlay" backgroundColor={BackgroundColor.OVERLAY} zIndex={Overlay.zIndex}>
            <div className="show-more-overlay__content" ref={scrollableRef}>
                <Text
                    className="show-more-overlay__title"
                    typeface={Typeface.SERIF}
                    size={FontSize.TITLE_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {props.title}
                </Text>
                {props.actors.length && (
                    <div className="show-more-overlay__horizontal-container">
                        <Icon className="show-more-overlay__cast-icon" name={IconName.PEOPLE} type={IconType.SOLID} />
                        <Text
                            className="show-more-overlay__actors"
                            size={FontSize.BODY_1}
                            typeface={Typeface.SANS}
                            weight={FontWeight.REGULAR}
                            color={Color.SECONDARY}
                        >
                            {props.actors.join(' ')}
                        </Text>
                    </div>
                )}
                {props.directors.length && (
                    <div className="show-more-overlay__horizontal-container">
                        <Icon className="show-more-overlay__cast-icon" name={IconName.DIRECTOR} type={IconType.SOLID} />
                        <Text
                            className="show-more-overlay__directors"
                            size={FontSize.BODY_1}
                            typeface={Typeface.SANS}
                            weight={FontWeight.REGULAR}
                            color={Color.SECONDARY}
                        >
                            {props.directors.join(' ')}
                        </Text>
                    </div>
                )}
                <Text
                    className="show-more-overlay__description"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SANS}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    {props.description}
                </Text>
            </div>
        </Layout>
    );
};
