import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonRentProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonRentProps> = (props) => {
    return (
        <Folder.Detail.Action id="rent" icon={IconName.BUY} onEnter={props.onEnter}>
            SCREEN_DETAIL_RENT_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonRent = hidable(Button);

export type DetailButtonRentModule = typeof DetailButtonRent;
