import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Registry } from 'App/Modules/Alert/Registry';
import { generator } from 'App/Modules/Alert/Store/generator';
import { selectors } from 'App/Modules/Alert/Store/selectors';
import { AlertActionType, AlertChangedAction, AlertEntity, AlertProps, AlertType } from 'App/Modules/Alert/Types';
import { Focus } from 'App/Packages/Focus';

const changed = (alert: AlertEntity | null): AlertChangedAction => ({
    type: AlertActionType.Changed,
    payload: { alert },
});

const update =
    <T extends AlertType>(type: T, props: Partial<AlertProps<T>> = {}, ctx?: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const alert = selectors.public.selectTyped(type)(getState());
        if (!alert) return;
        const newProps: AlertProps<T> = { ...alert.props, ...props } as unknown as AlertProps<T>;
        dispatch(changed(generator(type, newProps, ctx ?? alert.ctx)));
        await dispatch(Registry.get(type)[1].update(newProps, ctx ?? alert.ctx));
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const alert = selectors.public.select(getState());
    if (!alert) return;
    await dispatch(Registry.get(alert.type)[1].unmount(alert.props, alert.ctx));
    dispatch(changed(null));
};

const mount =
    <T extends AlertType>(type: T, props: AlertProps<T>, ctx: string = Focus.Default): Thunk<Promise<void>> =>
    async (dispatch) => {
        await dispatch(unmount());
        dispatch(changed(generator(type, props, ctx)));
        await dispatch(Registry.get(type)[1].mount(props, ctx));
    };

const ctxChanged =
    (type: AlertType, ctx: string): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(update(type, {}, ctx));

const setCtx =
    (ctx: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const alert = selectors.public.select(getState());
        if (!alert) return;
        if (alert.ctx === ctx) return;
        dispatch(ctxChanged(alert.type, ctx));
    };

export const actions = {
    public: {
        mount,
        unmount,
        update,
        ctx: {
            set: setCtx,
            changed: ctxChanged,
        },
    },
    private: {
        changed,
    },
} as const;
