import React from 'react';

import { Focus } from 'App/Packages/Focus';

export type CollectionV2GridItemProps = {
    children: React.ReactNode;
} & Focus.Focusable<string>;

export type CollectionV2GridItemModule = React.FunctionComponent<CollectionV2GridItemProps>;

export const CollectionV2GridItem: CollectionV2GridItemModule = (props) => {
    const { onClick, onHover } = Focus.useFocusable({
        id: props.id,
        ctx: props.ctx,
        isFocused: props.isFocused,
        disabled: props.disabled,
        onEnter: props.onEnter,
        onHover: props.onHover,
        onBlur: props.onBlur,
        onFocus: props.onFocus,
    });

    return (
        <div onClick={onClick} onMouseOver={onHover}>
            {props.children}
        </div>
    );
};
