import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';

import { CardLandscapeContext } from 'App/Packages/UI/Card/CardLandscape/Context/CardLandscapeContext';
import { Mask } from 'App/Packages/UI/Mask';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

const getClassName = (props: CardLandscapeProps) => {
    const classlist = ['landscape-card-v3'];
    if (props.isFocused) classlist.push('landscape-card-v3--focused');
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

const getBodyClassName = (isFocused: boolean) => {
    const classlist = ['landscape-card-v3__body'];
    if (isFocused) classlist.push('landscape-card-v3__body--focused');
    return classlist.join(' ');
};

export type CardLandscapeProps = React.PropsWithChildren<{
    className?: string;
    parentalRating?: string;
    sessionType?: PinSessionType;
    isFocused?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHover?: React.MouseEventHandler<HTMLDivElement>;
}> &
    React.RefAttributes<HTMLDivElement>;

export type CardLandscapeComponent = React.FunctionComponent<CardLandscapeProps>;

export const CardLandscape: CardLandscapeComponent = React.forwardRef((props, ref) => (
    <Mask.Provider parentalRatingId={props.parentalRating} pinSessionType={props.sessionType}>
        <CardLandscapeContext.Provider value={{ isFocused: !!props.isFocused }}>
            <div ref={ref} className={getClassName(props)} onClick={props.onClick} onMouseOver={props.onHover}>
                <Div
                    className={getBodyClassName(!!props.isFocused)}
                    backgroundColor={props.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
                    shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
                >
                    {props.children}
                </Div>
            </div>
        </CardLandscapeContext.Provider>
    </Mask.Provider>
));
