import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListLoadInitialIdQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
}>;


export type ChannelListLoadInitialIdQuery = { __typename?: 'Nexx4Queries', initialChannelList?: { __typename?: 'ChannelList', id: string } | null };

export type ChannelListLoadInitialIdFragment = { __typename?: 'Nexx4Queries', initialChannelList?: { __typename?: 'ChannelList', id: string } | null };

export const ChannelListLoadInitialIdFragmentDoc = gql`
    fragment channelListLoadInitialId on Nexx4Queries {
  initialChannelList(profileId: $profileId) {
    id
  }
}
    `;
export const ChannelListLoadInitialIdDocument = gql`
    query channelListLoadInitialId($profileId: ID!) {
  ...channelListLoadInitialId
}
    ${ChannelListLoadInitialIdFragmentDoc}`;

/**
 * __useChannelListLoadInitialIdQuery__
 *
 * To run a query within a React component, call `useChannelListLoadInitialIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelListLoadInitialIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelListLoadInitialIdQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useChannelListLoadInitialIdQuery(baseOptions: Apollo.QueryHookOptions<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>(ChannelListLoadInitialIdDocument, options);
      }
export function useChannelListLoadInitialIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>(ChannelListLoadInitialIdDocument, options);
        }
export function useChannelListLoadInitialIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>(ChannelListLoadInitialIdDocument, options);
        }
export type ChannelListLoadInitialIdQueryHookResult = ReturnType<typeof useChannelListLoadInitialIdQuery>;
export type ChannelListLoadInitialIdLazyQueryHookResult = ReturnType<typeof useChannelListLoadInitialIdLazyQuery>;
export type ChannelListLoadInitialIdSuspenseQueryHookResult = ReturnType<typeof useChannelListLoadInitialIdSuspenseQuery>;
export type ChannelListLoadInitialIdQueryResult = Apollo.QueryResult<ChannelListLoadInitialIdQuery, ChannelListLoadInitialIdQueryVariables>;