import { RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import {
    RECORDING_MANAGEMENT_BASE_ACTION_IDS,
    RECORDING_MANAGEMENT_FILTERING_OPTIONS,
    RECORDING_MANAGEMENT_SORTING_OPTIONS,
    RECORDING_MANAGEMENT_TRICK_MODE_BASE_ACTION_IDS,
    RECORDING_MANAGEMENT_TRICK_MODE_FULL_ACTION_IDS,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/constants';
import {
    RecomendationManagementFilter,
    RecomendationManagementSort,
    RecordingManagementActionID,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { RecordingEntity } from '__SMART_APP_OLD__/app/store/types/state/data/recording';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import { formatDateTime, formatDayName } from '__SMART_APP_OLD__/utils/timeUtils';

import { ContentMarkerType } from 'App/Types/ContentMarker';

export const selectRecording =
    (id: GQL.RecordingID) =>
    (state: State): RecordingEntity =>
        state.data.recordingEntityTable.entities[id];

export const selectSelectOverlayRecordingFilterOptions = (): ButtonEntity[] => RECORDING_MANAGEMENT_FILTERING_OPTIONS;

export const selectSelectOverlaySelectedRecordingFilterOptionIndex = (state: State): number =>
    RECORDING_MANAGEMENT_FILTERING_OPTIONS.findIndex((option) => option.id === state.recordingManagement.filterByValue);

export const selectSelectOverlayRecordingSortOptions = (): ButtonEntity[] => RECORDING_MANAGEMENT_SORTING_OPTIONS;

export const selectSelectOverlaySelectedRecordingSortOptionIndex = (state: State): number =>
    RECORDING_MANAGEMENT_SORTING_OPTIONS.findIndex((option) => option.id === state.recordingManagement.sortByValue);

export const selectRecordingsCount = (state: State): number => selectCurrentRecordings(state).length;

export const selectRecordingManagementSelectedRecordingsCount = (state: State): number =>
    state.recordingManagement.selectedRecordings.length;

export const selectRecordingManagementIsTrickMode = (state: State): boolean => state.recordingManagement.isTrickMode;

export const selectRecordingManagementCleanUpSpaceSelected = (state: State): number =>
    state.recordingManagement.selectedRecordings.reduce((acc, id) => acc + selectRecording(id)(state).size * 60, 0);

export const selectRecordingManagementSelectedRecordings = (state: State): string[] => state.recordingManagement.selectedRecordings;

export const selectRecordingManagementActions = (state: State): RecordingManagementActionID[] => {
    if (!state.recordingManagement.isTrickMode) return RECORDING_MANAGEMENT_BASE_ACTION_IDS;
    if (!state.recordingManagement.selectedRecordings.length) return RECORDING_MANAGEMENT_TRICK_MODE_BASE_ACTION_IDS;
    return RECORDING_MANAGEMENT_TRICK_MODE_FULL_ACTION_IDS.slice(1);
};

// eslint-disable-next-line react-func/max-lines-per-function
export const selectCurrentRecordings = (state: State): string[] => {
    const filterbyValue = state.recordingManagement.filterByValue;
    const sortbyValue = state.recordingManagement.sortByValue;
    return state.data.recordingEntityTable.ids
        .filter((id) => {
            const recording = selectRecording(id)(state);
            const bookmark = Bookmarks.selectors.selectEntity(id)(state);
            switch (filterbyValue) {
                case RecomendationManagementFilter.CLEAR_FILTER:
                    return true;
                case RecomendationManagementFilter.CONTINUE_WATCHING:
                    return bookmark !== null;
                case RecomendationManagementFilter.COMPLETED:
                    return recording.status === RecordingStatus.Completed;
                case RecomendationManagementFilter.IN_PROGRESS:
                    return recording.status === RecordingStatus.InProgress;
                case RecomendationManagementFilter.FAILED:
                    return recording.status === RecordingStatus.Failed;
                case RecomendationManagementFilter.PLANNED:
                    return recording.status === RecordingStatus.Planned;
                case RecomendationManagementFilter.IS_SERIES_RECORDING:
                    return recording.isSeriesRecording;
                case RecomendationManagementFilter.IS_SINGLE_RECORDING:
                    return !recording.isSeriesRecording;
                default:
                    return true;
            }
        })
        .sort((a, b) => {
            const recordingA: RecordingEntity = selectRecording(a)(state);
            const recordingB: RecordingEntity = selectRecording(b)(state);
            switch (sortbyValue) {
                case RecomendationManagementSort.START:
                    return recordingB.start - recordingA.start;
                case RecomendationManagementSort.NAME:
                    return recordingA.title.localeCompare(recordingB.title);
                case RecomendationManagementSort.NAMEREV:
                    return recordingB.title.localeCompare(recordingA.title);
                case RecomendationManagementSort.SIZE:
                    return recordingA.size - recordingB.size;
                case RecomendationManagementSort.SIZEREV:
                    return recordingB.size - recordingA.size;
                case RecomendationManagementSort.AVAILABLE:
                    return recordingA?.availableUntil && recordingB?.availableUntil
                        ? recordingA.availableUntil - recordingB.availableUntil
                        : !recordingA?.availableUntil && recordingB?.availableUntil
                          ? -1
                          : recordingA?.availableUntil && !recordingB?.availableUntil
                            ? 1
                            : 0;
                default:
                    return 0;
            }
        });
};

export const selectRecordingManagementItemMetaInfo =
    (id: GQL.RecordingID) =>
    (state: State): string => {
        const recording = selectRecording(id)(state);
        const { episode, season } = recording;
        const info = [];
        const date = formatDayName(new Date(recording.start), 'DD dd MMM', '');
        const hours = `${formatDateTime(new Date(recording.start), 'HH:mm')} - ${formatDateTime(new Date(recording.end), 'HH:mm')}`;

        if (season) {
            info.push(`S${season} I `);
        }

        if (episode) {
            info.push(`E${episode} I`);
        }
        info.push(`${date}`);
        info.push(`${season ? '\n' : 'I '}${hours}`);

        return info.join(' ');
    };

export const selectRecordingManagementItemTitle =
    (id: GQL.RecordingID) =>
    (state: State): string =>
        selectRecording(id)(state).title;

export const selectRecordingManagementIsRecordingSelected =
    (id: GQL.RecordingID) =>
    (state: State): boolean =>
        state.recordingManagement.selectedRecordings.includes(id);

export const selectRecordingManagementImage =
    (id: GQL.RecordingID) =>
    (state: State): string | null =>
        selectRecording(id)(state)?.recordingImage;

export const selectRecordingManagementItemIsLive =
    (id: GQL.RecordingID) =>
    (state: State): boolean =>
        state.data.recordingEntityTable.entities[id].status === RecordingStatus.InProgress;

export const selectRecordingManagementChannelId =
    (id: GQL.RecordingID) =>
    (state: State): string | null =>
        selectRecording(id)(state)?.channelId ?? null;

export const selectRecordingManagementProgressBar =
    (id: GQL.RecordingID) =>
    (state: State): [BackgroundColor, number] => {
        const recording = selectRecording(id)(state);
        const bookmark = Bookmarks.selectors.selectEntity(id)(state);
        const time = Time.selectors.select(state);
        const isLive = selectRecordingManagementItemIsLive(id)(state);
        if (isLive) {
            const livePosition = time - (recording.start + recording.leadIn);
            const duration = recording.end - recording.start + recording.leadIn + recording.leadOut;
            const progress = (livePosition / duration) * 100;
            return [BackgroundColor.COOL_BLUE_PRIMARY, progress];
        }

        if (!bookmark) {
            return [BackgroundColor.HOT_RED_PRIMARY, 0];
        }
        const bookmarkPosition = (bookmark?.position ?? 0) * 100;
        const duration = (recording.end - recording.start) / 1000;
        const progress = bookmarkPosition / (duration + recording.leadIn + recording.leadOut);
        return [BackgroundColor.HOT_RED_PRIMARY, progress];
    };

export const selectRecordingManagementContentMarkerType =
    (id: GQL.RecordingID) =>
    (state: State): ContentMarkerType | null => {
        const currentTime = Time.selectors.select(state);
        const recording = state.data.recordingEntityTable.entities[id];
        if (recording.start <= currentTime && recording.end > currentTime) {
            return ContentMarkerType.Live;
        }
        return ContentMarkerType.Recorded;
    };

export const selectRecordingManagementRecordingStart =
    (id: GQL.RecordingID) =>
    (state: State): number =>
        state.data.recordingEntityTable.entities[id]?.start;

export const selectRecordingManagementRecordingEnd =
    (id: GQL.RecordingID) =>
    (state: State): number =>
        state.data.recordingEntityTable.entities[id]?.end;

export const selectRecordingAvailableUntil =
    (id: GQL.RecordingID) =>
    (state: State): number | null => {
        const recording = selectRecording(id)(state);
        return recording?.availableUntil ?? null;
    };

export const selectRecordingManagementTitle = (state: State) =>
    Translation.selectors
        .select('MANAGE_RECORDING_PAGE_COUNT')(state)
        .replace('%count%', `${selectRecordingsCount(state)}`);

export const selectRecordingManagementActionTranslation =
    (key: string) =>
    (state: State): string | null => {
        switch (key) {
            case 'RECORDING_MANAGE_DELETE': {
                const recordingsSelected = selectRecordingManagementSelectedRecordingsCount(state);
                const totalRecordings = selectRecordingsCount(state);
                return Translation.selectors
                    .select(key)(state)
                    .replace('%count%', `${recordingsSelected}`)
                    .replace('%totalRecordings%', `${totalRecordings}`);
            }
            default:
                return Translation.selectors.select(key)(state);
        }
    };

const selectStatusFromRecording =
    (recording: RecordingEntity | null) =>
    (state: State): GQL.RecordingStatus | null => {
        if (!recording) return null;
        const time = Time.selectors.select(state);
        const hasStarted = recording.start < time;
        const hasEnded = time > recording.end;
        const isInProgress = hasStarted && !hasEnded;
        const isCompleted = hasEnded;

        if (recording.status !== GQL.RecordingStatus.Planned && recording.status !== GQL.RecordingStatus.InProgress) {
            return recording.status;
        }

        if (isCompleted) return GQL.RecordingStatus.Completed;
        if (isInProgress) return GQL.RecordingStatus.InProgress;
        return GQL.RecordingStatus.Planned;
    };

export const selectRecordingByEventId = (id: GQL.EventID) => (state: State) =>
    selectRecording(state.data.recordingEntityTable.eventRecordMap[id])(state) ?? selectRecording(id)(state);

export const selectEventHasRecording =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        !!state.data.recordingEntityTable.eventRecordMap[id];

export const selectRecordingStatusForEvent =
    (id: GQL.EventID) =>
    (state: State): GQL.RecordingStatus | null => {
        const recording = selectRecordingByEventId(id)(state);
        return selectStatusFromRecording(recording)(state);
    };

export const selectRecordingStatus =
    (id: GQL.RecordingID) =>
    (state: State): GQL.RecordingStatus | null => {
        const recording = selectRecording(id)(state);
        return selectStatusFromRecording(recording)(state);
    };
