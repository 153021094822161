import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonSubscribeProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonSubscribeProps> = (props) => {
    return (
        <Folder.Detail.Action id="subscribe-button" icon={IconName.BUY} onEnter={props.onEnter}>
            SCREEN_DETAIL_SUBSCRIBE_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonSubscribe = hidable(Button);

export type DetailButtonSubscribeModule = typeof DetailButtonSubscribe;
