import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    className?: string;
    color: BackgroundColor;
}

export type LabelPayload = {
    color: BackgroundColor;
    text: string;
};

const getClassName = (props: Props) => {
    const classList = ['label-v2'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const Label: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    const className = getClassName(props);
    return (
        <Div className={className} backgroundColor={props.color} shadow={Shadow.NONE} contentTheme={Theme.Type.Dark}>
            <Text
                className="label-v2__text"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_3}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {props.children}
            </Text>
        </Div>
    );
};
