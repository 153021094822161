import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { CancelNetworkSeriesRecordingDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/cancelSeriesRecording.generated';
import { RecordingSeriesRemoveDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/recordingSeriesRemove.generated';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { removeRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const cancelSeries =
    (eventId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const profileId = Profile.selectors.selectId(getState());
            const variables = { input: { eventId, profileId } };
            const result = await GraphqlClient.makeGraphqlMutationRequest(CancelNetworkSeriesRecordingDocument, variables);
            if (!result || result.errors) throw new Error('mutation failed');
            const { quota, events } = result.data.cancelNetworkSeriesRecording;
            dispatch(textNotificationShow('NOTIFICATION_SERIES_RECORDING_CANCELED'));
            dispatch(removeRecording(events.map((event: { id: string }) => event.id)));
            dispatch(Household.actions.recordingQuotaLoad(quota));
        } catch {
            dispatch(textNotificationShow('NOTIFICATION_SERIES_RECORDING_FAILED'));
        }
    };

const deleteSeries =
    (eventId: string): Thunk<Promise<void>> =>
    async (dispatch) => {
        try {
            const result = await GraphqlClient.makeGraphqlMutationRequest(RecordingSeriesRemoveDocument, { input: { eventId } });
            if (!result || result.errors) throw new Error('mutation failed');
            dispatch(
                removeRecording(result?.data?.deleteEpisodesOfNetworkSeriesRecording?.events.map((event: { id: string }) => event.id))
            );
            dispatch(Household.actions.recordingQuotaLoad(result?.data?.deleteEpisodesOfNetworkSeriesRecording?.quota));
            dispatch(textNotificationShow('NOTIFICATION_SERIES_RECORDING_DELETED'));
        } catch {
            dispatch(textNotificationShow('NOTIFICATION_SERIES_RECORDING_DELETE_FAILED'));
        }
    };

export const actions = {
    private: {
        cancelSeries,
        deleteSeries,
    },
};
