import { API } from '__SMART_APP_OLD__/app/api';
import { TRIGGER_TS_OFFSET_FOR_REMINDER_ENTITY_STATUS } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/constants';
import { ReminderEntity, ReminderEntityStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import {
    NotificationActionID,
    NotificationIconType,
    NotificationType,
    NotificationVisibilityStatus,
    ReminderNotificationEntity,
} from '__SMART_APP_OLD__/app/modules/Notification/types';
import { ReminderEvents } from 'analytics/logging/events/ReminderEvent';
import { getReminderEvent } from 'analytics/logging/factories/reminderEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

export const getStatusFromTimeToStart = (time: number): ReminderEntityStatus => {
    if (time > TRIGGER_TS_OFFSET_FOR_REMINDER_ENTITY_STATUS[ReminderEntityStatus.SNOOZED]) return ReminderEntityStatus.ACTIVE;
    return ReminderEntityStatus.SNOOZED;
};

export const getReminderEntityTriggerTS = (start: number, status: ReminderEntityStatus): number =>
    start - TRIGGER_TS_OFFSET_FOR_REMINDER_ENTITY_STATUS[status];

const generateUIReminderEntityActionsIds = (
    isCurrentChannel: boolean,
    isSnoozable: boolean,
    isRecordable: boolean
): NotificationActionID[] => {
    const actions: NotificationActionID[] = [];
    if (!isCurrentChannel) actions.push(NotificationActionID.WATCH);
    if (isSnoozable) actions.push(NotificationActionID.SNOOZE);
    if (isRecordable) actions.push(NotificationActionID.RECORD);
    actions.push(NotificationActionID.CANCEL);
    return actions;
};

export const generateUIReminderEntity = async (
    reminder: ReminderEntity | null,
    status: ReminderEntityStatus | null,
    channelName: string,
    profileId: string,
    timer: number
): Promise<ReminderNotificationEntity | null> => {
    if (!reminder || status === ReminderEntityStatus.DISMISSED) return null;
    const isSnoozable = status === ReminderEntityStatus.ACTIVE;
    const { eventId } = reminder;
    const isRecordable = await API.Event.canRecord(eventId, profileId).catch(() => false);
    const isCurrentChannel = window.location.pathname.split('/').reverse()[0] === reminder.channelId;
    const actionsIds = generateUIReminderEntityActionsIds(isCurrentChannel, isSnoozable, isRecordable);

    if (status === ReminderEntityStatus.ACTIVE) {
        LoggingService.getInstance().logEvent(getReminderEvent(ReminderEvents.FIRE, eventId));
    }

    return {
        id: reminder.id,
        icon: NotificationIconType.REMINDER,
        timer,
        status: NotificationVisibilityStatus.ANIMATE_IN,
        title: reminder.title,
        subtitle: `${translate('ON')} ${channelName}`,
        actions: actionsIds,
        type: NotificationType.REMINDER,
    };
};
