import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum FtiActionType {
    WELCOME_STEP_COMPLETED_CHANGED = '@fti/welcomeStepCompleted/changed',
    CLOSING_STEP_COMPLETED_CHANGED = '@fti/closingStepCompleted/changed',
    LANGUAGE_STEP_COMPLETED_CHANGED = '@fti/languageStepCompleted/changed',
    MASTER_PIN_CODE_STEP_COMPLETED_CHANGED = '@fti/masterPincodeStepCompleted/changed',
    COMMUNITY_STEP_COMPLETED_CHANGED = '@fti/communityStepCompleted/changed',
    REPLAY_STEP_COMPLETED_CHANGED = '@fti/replayStepCompleted/changed',
    AGE_RATING_STEP_COMPLETED_CHANGED = '@fti/ageRatingStepCompleted/changed',
    PRIVACY_POLICY_STEP_COMPLETED_CHANGED = '@fti/privacyStepCompleted/changed',
    EDUCATION_STEP_COMPLETED_CHANGED = '@fti/educationStepCompleted/changed',
    STARTUP_STEP_COMPLETED_CHANGED = '@fti/startupStepCompleted/changed',
    THEME_STEP_COMPLETED_CHANGED = '@fti/themeStepCompleted/changed',
    SETUP_INITIAL_FTI_VALUES_AFTER_AUTHENTICATION = '@fti/setupInitialFTIValuesAfterAuthentication',
}

export type FtiActionStepPayload = { value: number };

export type FtiInitialActionPayload = {
    masterPincodeStepCompleted: number;
    communityStepCompleted: number;
    replayStepCompleted: number;
    ageRatingStepCompleted: number;
    privacyStepCompleted: number;
};

export type FtiReduxAction =
    | FtiReduxInitialAction
    | FtiCloseStepAction
    | FtiAgeRatingStepAction
    | FtiComunityStepAction
    | FtiEducationStepAction
    | FtiLanguageStepAction
    | FtiMasterPinStepAction
    | FtiPrivacyStepAction
    | FtiReplayStepAction
    | FtiStartupStepAction
    | FtiThemeStepAction
    | FtiWelcomeStepAction;

export type FtiCloseStepAction = PayloadedAction<FtiActionType.CLOSING_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiAgeRatingStepAction = PayloadedAction<FtiActionType.AGE_RATING_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiComunityStepAction = PayloadedAction<FtiActionType.COMMUNITY_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiEducationStepAction = PayloadedAction<FtiActionType.EDUCATION_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiLanguageStepAction = PayloadedAction<FtiActionType.LANGUAGE_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiMasterPinStepAction = PayloadedAction<FtiActionType.MASTER_PIN_CODE_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiPrivacyStepAction = PayloadedAction<FtiActionType.PRIVACY_POLICY_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiReplayStepAction = PayloadedAction<FtiActionType.REPLAY_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiStartupStepAction = PayloadedAction<FtiActionType.STARTUP_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiThemeStepAction = PayloadedAction<FtiActionType.THEME_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;
export type FtiWelcomeStepAction = PayloadedAction<FtiActionType.WELCOME_STEP_COMPLETED_CHANGED, FtiActionStepPayload>;

export type FtiReduxInitialAction = PayloadedAction<FtiActionType.SETUP_INITIAL_FTI_VALUES_AFTER_AUTHENTICATION, FtiInitialActionPayload>;

export type FtiCompletedTable = {
    welcomeStepCompleted: number;
    closingStepCompleted: number;
    languageStepCompleted: number;
    masterPincodeStepCompleted: number;
    communityStepCompleted: number;
    replayStepCompleted: number;
    ageRatingStepCompleted: number;
    privacyStepCompleted: number;
    startupStepCompleted: number;
    themeStepCompleted: number;
    educationStepCompleted: number;
};
