import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import {
    DetailsForSettingsScreenOptionId,
    SettingsScreenOptionId,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Translation } from 'App/Modules/Translation';

export const pinProtectedOptions = [SettingsScreenOptionId.PIN, SettingsScreenOptionId.PARENTAL_CONTROL];

export const changeScreenOptions = [
    SettingsScreenOptionId.LANGUAGE,
    SettingsScreenOptionId.CHANNEL_LINEUP,
    SettingsScreenOptionId.PARENTAL_CONTROL,
    SettingsScreenOptionId.PIN,
    SettingsScreenOptionId.DEVICE_MANAGEMENT,
    SettingsScreenOptionId.PRIVACY_POLICY,
    SettingsScreenOptionId.VIEW_CONTROL,
    SettingsScreenOptionId.IMPRINT,
];

const translations = Translation.Key.Screen.Settings.Options;

export const detailsForOptionId: Record<SettingsScreenOptionId, DetailsForSettingsScreenOptionId> = {
    [SettingsScreenOptionId.LANGUAGE]: {
        text: { title: translations.Language.Title, hint: translations.Language.Hint },
        action: () => Screen.actions.mount(Screen.Type.LANGUAGE_PREFERENCES, {}),
    },
    [SettingsScreenOptionId.CHANNEL_LINEUP]: {
        text: {
            title: translations.ChannelLineup.Title,
            hint: translations.ChannelLineup.Hint,
        },
        action: () => Screen.actions.mount(Screen.Type.CHANNEL_LISTS, {}, ChannelListsScreenCtx.ChannelLists),
    },
    [SettingsScreenOptionId.CLOCK]: {
        text: { title: translations.Clock.Title, hint: translations.Clock.Hint },
        action: () => Overlay.actions.mount(Overlay.Type.LiveClockSelect, {}),
    },
    [SettingsScreenOptionId.PARENTAL_CONTROL]: {
        text: { title: translations.ParentalControl.Title, hint: translations.ParentalControl.Hint },
        action: () => Screen.actions.mount(Screen.Type.PARENTAL_CONTROL, {}),
    },
    [SettingsScreenOptionId.PIN]: {
        text: { title: translations.PinProtection.Title, hint: translations.PinProtection.Hint },
        action: () => Screen.actions.mount(Screen.Type.PIN_CODE, {}),
    },
    [SettingsScreenOptionId.APPEARANCE]: {
        text: { title: translations.Appearance.Title, hint: translations.Appearance.Hint },
        action: () => Overlay.actions.mount(Overlay.Type.ThemeSelect, {}),
    },
    [SettingsScreenOptionId.DEVICE_MANAGEMENT]: {
        text: { title: translations.DeviceManagement.Title, hint: translations.DeviceManagement.Hint },
        action: () => Screen.actions.mount(Screen.Type.DEVICE_MANAGEMENT, {}),
    },
    [SettingsScreenOptionId.STARTUP_BEHAVIOR]: {
        text: { title: translations.StartUpBehavior.Title, hint: translations.StartUpBehavior.Hint },
        action: () => Overlay.actions.mount(Overlay.Type.StartUpSelect, {}),
    },
    [SettingsScreenOptionId.PRIVACY_POLICY]: {
        text: { title: translations.PrivacyPolicy.Title, hint: translations.PrivacyPolicy.Hint },
        action: () => Screen.actions.mount(Screen.Type.PRIVACY_POLICY, { type: Screen.PrivacyPolicyType.SETTINGS }),
    },
    [SettingsScreenOptionId.NVPR]: {
        text: { title: translations.MyRecordings.Title, hint: translations.MyRecordings.Hint },
        action: null,
    },
    [SettingsScreenOptionId.ABOUT]: {
        text: {
            /* an enum SETTINGS_ABOUT_APP_TITLE is used instead */
        },
        action: null,
    },
    [SettingsScreenOptionId.LOGOUT]: {
        text: { title: translations.LogOut.Title, hint: translations.LogOut.Hint },
        action: () => Prompt.actions.mount(Prompt.Type.Logout, {}),
    },
    [SettingsScreenOptionId.VIEW_CONTROL]: {
        text: { title: translations.ViewControl.Title, hint: translations.ViewControl.Hint },
        action: () => Screen.actions.mount(Screen.Type.VIEW_CONTROL, { from: 'settings' }),
    },
    [SettingsScreenOptionId.IMPRINT]: {
        text: { title: translations.Imprint.Title, hint: translations.Imprint.Hint },
        action: () =>
            Overlay.actions.mount(Overlay.Type.MORE_INFO, {
                title: translations.Imprint.OverlayTitle,
                text: translations.Imprint.OverlayBody,
            }),
    },
    [SettingsScreenOptionId.REGION]: {
        text: {
            title: translations.Region.Title,
            hint: translations.Region.Hint,
        },
        action: () => Overlay.actions.mount(Overlay.Type.CommunitySelect, {}),
    },
};
