import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { DevicesFragmentDoc } from '../fragments/devices.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeviceLoadManyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DeviceLoadManyQuery = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', household: { __typename?: 'Household', maxNumberOfUnmanagedDevices: number, devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> } | null> } } } | null };

export type DeviceLoadManyFragment = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', household: { __typename?: 'Household', maxNumberOfUnmanagedDevices: number, devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> } | null> } } } | null };

export const DeviceLoadManyFragmentDoc = gql`
    fragment deviceLoadMany on Nexx4Queries {
  me {
    household {
      ...devices
      maxNumberOfUnmanagedDevices
    }
  }
}
    ${DevicesFragmentDoc}`;
export const DeviceLoadManyDocument = gql`
    query deviceLoadMany {
  ...deviceLoadMany
}
    ${DeviceLoadManyFragmentDoc}`;

/**
 * __useDeviceLoadManyQuery__
 *
 * To run a query within a React component, call `useDeviceLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceLoadManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceLoadManyQuery(baseOptions?: Apollo.QueryHookOptions<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>(DeviceLoadManyDocument, options);
      }
export function useDeviceLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>(DeviceLoadManyDocument, options);
        }
export function useDeviceLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>(DeviceLoadManyDocument, options);
        }
export type DeviceLoadManyQueryHookResult = ReturnType<typeof useDeviceLoadManyQuery>;
export type DeviceLoadManyLazyQueryHookResult = ReturnType<typeof useDeviceLoadManyLazyQuery>;
export type DeviceLoadManySuspenseQueryHookResult = ReturnType<typeof useDeviceLoadManySuspenseQuery>;
export type DeviceLoadManyQueryResult = Apollo.QueryResult<DeviceLoadManyQuery, DeviceLoadManyQueryVariables>;