import React, { useEffect, useRef } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectEventExists, selectEventIsLoading } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { ProgramCard } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramCard';
import { miniEpgConfig } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';
import { ContentMarkerType } from 'App/Types/ContentMarker';

interface Props {
    eventId?: GQL.EventID;
    channelId: string;
    contentMarker?: ContentMarkerType;
    isMagicMode: boolean;
    onShowFull: () => void;
    onBack: () => void;
}

export const ZapperProgram: React.FunctionComponent<Props> = (props) => {
    const exists = useSelector(selectEventExists(props.eventId ?? ''));
    const isLoading = useSelector(selectEventIsLoading(props.eventId ?? ''));
    const { channelId, contentMarker, isMagicMode, onShowFull, onBack } = props;

    const onShowFullNewestRef = useRef(onShowFull);
    const onBackNewestRef = useRef(onBack);

    onShowFullNewestRef.current = onShowFull;
    onBackNewestRef.current = onBack;

    useEffect(() => {
        let backTimer: ReturnType<typeof setTimeout>;

        const resetBackTimeout = () => {
            if (backTimer) clearTimeout(backTimer);
            if (isMagicMode) return;
            backTimer = setTimeout(onBackNewestRef.current, miniEpgConfig.zapperTimeout);
        };

        const keyDownHandler = (e: KeyboardEvent) => {
            resetBackTimeout();
            const key = e.keyCode || e.which || e.detail;
            if (key === Key.VK_UP || key === Key.VK_DOWN) {
                onShowFullNewestRef.current();
                return;
            }
            if (key === Key.VK_BACK) {
                e.preventDefault();
                onBackNewestRef.current();
            }
        };

        resetBackTimeout();
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            if (backTimer) clearTimeout(backTimer);
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isMagicMode]);

    if (!exists || isLoading) return null;

    return (
        <>
            {isMagicMode && <div className="mini-epg-navigation-back" onClick={() => onBack()} />}
            <div onClick={onShowFull}>
                <div className="arrow arrow-up" />
                <div className="channel-zapper-text-container text-up">
                    <Text
                        typeface={Typeface.SANS}
                        size={FontSize.CAPTION_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        theme={Theme.Type.Dark}
                    >
                        SCREEN_MINI_EPG_PROGRAM_GUIDE_ARROW
                    </Text>
                </div>
            </div>
            <ProgramCard className="channel-zapper" eventId={props.eventId ?? ''} channelId={channelId} contentMarker={contentMarker} />
            <div onClick={onShowFull}>
                <div className="channel-zapper-text-container text-down">
                    <Text
                        typeface={Typeface.SANS}
                        size={FontSize.CAPTION_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        theme={Theme.Type.Dark}
                    >
                        SCREEN_MINI_EPG_PROGRAM_GUIDE_ARROW
                    </Text>
                </div>
                <div className="arrow arrow-down" />
            </div>
            {isMagicMode && (
                <>
                    <div className="mini-epg-navigation-up" onMouseOver={onShowFull} />
                    <div className="mini-epg-navigation-down" onMouseOver={onShowFull} />
                </>
            )}
        </>
    );
};
