import { ChannelListEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/types';

export const initialChannelListEntityTableState: ChannelListEntityTable = {
    selectedId: '',
    ids: [],
    entities: {},
    channelTotalCountRelationEntityTable: {},
    channelNumberRelationEntityTable: {},
    channelsRelationEntityTable: {},
};
