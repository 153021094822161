import React from 'react';

import { Div } from '__SMART_APP_OLD__/app/components/Div';
import { Switch } from '__SMART_APP_OLD__/app/components/Switch';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { Focus } from 'App/Packages/Focus';
import { useSelector } from 'react-redux';
import { Overlay } from 'App/Modules/Overlay';

const getClassName = (props: LoginFlowSwitchProps): string => {
    const classList = ['login-flow-switch'];
    if (props.isFocused) classList.push('login-flow-switch--focused');
    return classList.join(' ');
};

export type LoginFlowSwitchProps = {
    on: boolean;
} & Omit<Focus.Focusable<LoginElement.FlowSwitch>, 'id' | 'disabled'>;

export const LoginFlowSwitch: React.FunctionComponent<LoginFlowSwitchProps> = (props) => {
    const type = useSelector(Overlay.selectors.selectType);
    const isActive = useSelector(Overlay.selectors.selectIsActive);
    const disabled = type === Overlay.Type.Loading && isActive;
    const { onClick, onHover } = Focus.useFocusable({
        id: LoginElement.FlowSwitch,
        ctx: props.ctx,
        isFocused: props.isFocused,
        disabled,
        onEnter: props.onEnter,
        onHover: props.onHover,
        onBlur: props.onBlur,
        onClick: props.onClick,
        onFocus: props.onFocus,
    });

    Focus.useHorizontalNavigation(() => props.onEnter?.(LoginElement.FlowSwitch), { disabled: !props.isFocused });

    return (
        <Div className={getClassName(props)} onClick={onClick} onMouseDown={onHover}>
            <Text
                className="login-flow-switch__label login-flow-switch__label--align-end"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                color={Color.PRIMARY}
                weight={FontWeight.REGULAR}
            >
                LOGIN_WITH_REMOTE
            </Text>
            <Switch className="login-flow-switch__input" isFocused={props.isFocused} isToggled={props.on} disableBackgroundChange={false} />
            <Text
                className="login-flow-switch__label"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                color={Color.PRIMARY}
                weight={FontWeight.REGULAR}
            >
                LOGIN_WITH_PHONE
            </Text>
        </Div>
    );
};
