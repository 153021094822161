import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type IsRecordedFragment = { __typename?: 'Event', personalInfo?: { __typename?: 'PersonalEventInfo', recordings?: Array<{ __typename: 'NetworkRecording', id: string } | null> | null } | null };

export const IsRecordedFragmentDoc = gql`
    fragment isRecorded on Event {
  personalInfo(profileId: $profileId) {
    recordings(kindFilter: NETWORK) {
      ... on NetworkRecording {
        id
      }
      __typename
    }
  }
}
    `;