import React from 'react';

import { SuggestionContext, SuggestionContextModule } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Context/SuggestionContext';
import { UseSuggestion, useSuggestion } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/useSuggestion';
import { UseSuggestionValue, useSuggestionValue } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/useSuggestionValue';

export type SuggestionProps = React.PropsWithChildren<{
    value: string;
    offset?: number;
    onEnter?: (value: string) => void;
}>;

export type SuggestionModule = React.FunctionComponent<SuggestionProps> & {
    // Contexts
    Context: SuggestionContextModule;

    // Hooks
    use: UseSuggestion;
    useValue: UseSuggestionValue;
};

export const Suggestion: SuggestionModule = (props) => {
    const item = Suggestion.useValue({ value: props.value, offset: props.offset, enter: props.onEnter });
    return <Suggestion.Context.Provider value={item}>{props.children}</Suggestion.Context.Provider>;
};

// Contexts
Suggestion.Context = SuggestionContext;

// Hooks
Suggestion.use = useSuggestion;
Suggestion.useValue = useSuggestionValue;
