import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const select = (key: string) => (state: State): string => state.translation?.[Language.selectors.selectSelected(state)]?.[key] ?? key;

const selectUrl = (code: string) => (state: State) => state.config.language.translationsUrlForLanguageCode[code];

const selectData = (code: string) => (state: State) => state.translation[code];

export const translationSelectors = { public: { select }, private: { selectUrl, selectData } };
