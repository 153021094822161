import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { MessageInboxCTX, MessageInboxFilter, MessageInboxState } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const initialState: MessageInboxState = {
    ctx: MessageInboxCTX.ACTIONS,
    appliedFilter: MessageInboxFilter.NO_FILTER,
    isSelectMode: false,
    selected: [],
};

export const messageInboxReducer: Reducer<MessageInboxState> = (state, action) => {
    if (!state) return initialState;

    switch (action.type) {
        case ActionType.MESSAGE_INBOX_MODE_CHANGED: {
            const selected = state.selected.length ? [] : state.selected;
            const isSelectMode = !state.isSelectMode;
            return { ...state, selected, isSelectMode };
        }
        case ActionType.MESSAGE_INBOX_MESSAGE_SELECTED: {
            if (!state.isSelectMode) return state;
            const selected = state.selected.includes(action.payload.messageId)
                ? Mapper.Common.toFilteredIds(state.selected, [action.payload.messageId])
                : [...state.selected, action.payload.messageId];

            return { ...state, selected };
        }
        case ActionType.MESSAGE_INBOX_SELECTED_CHANGED: {
            if (!state.isSelectMode) return state;
            return { ...state, selected: action.payload.messageIds };
        }
        case ActionType.MESSAGE_INBOX_FILTER_CHANGED: {
            if (state.appliedFilter === action.payload.filter) return state;
            return { ...state, appliedFilter: action.payload.filter };
        }
        default:
            return state;
    }
};
