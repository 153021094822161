import { OverlayEntity, OverlayProps, OverlayType } from 'App/Modules/Overlay/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';

export const generator = <T extends OverlayType>(
    type: T,
    props: OverlayProps<T>,
    ctx: string = FocusDefault,
    noFocus: boolean = false
): OverlayEntity<T> => ({
    type,
    props,
    ctx,
    noFocus,
});
