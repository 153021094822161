import { timeActions } from '__SMART_APP_OLD__/app/modules/Time/actions';
import { DAY_MS, DAY_S, HOUR_MS, HOUR_S, MINUTE_MS, MINUTE_S, SECOND_MS, SECOND_S } from '__SMART_APP_OLD__/app/modules/Time/constants';
import { getInitialTimeState } from '__SMART_APP_OLD__/app/modules/Time/getInitialState';
import { timeReducer } from '__SMART_APP_OLD__/app/modules/Time/reducer';
import { timeSelectors } from '__SMART_APP_OLD__/app/modules/Time/selectors';
import { TimeActionType, TimeReduxAction } from '__SMART_APP_OLD__/app/modules/Time/types';

export { TimeActionType as ActionType };

export type { TimeReduxAction as ReduxAction };

export { SECOND_MS, DAY_MS, DAY_S, HOUR_MS, HOUR_S, MINUTE_MS, MINUTE_S, SECOND_S };

export const getInitialState = getInitialTimeState;

export const actions = timeActions.public;

export const selectors = timeSelectors.public;

export const reducer = timeReducer;
