import React from 'react';

import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { channelListsScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/actions';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListsScreenViewCreateButton } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenViewCreateButton';
import { ChannelListsScreenViewMainSectionItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenViewMainSectionItem';

import { UseListReturnType } from 'App/Packages/Focus/Hooks/Types';
import { Focus } from 'App/Packages/Focus';

type Props = {
    navigation: UseListReturnType<string>;
    arrangeableChannelListsIds: string[];
    isArranging: boolean;
    firstVisibleIndex: number;
    onFocus: () => void;
};

type ChannelListsScreenViewLeftSectionModule = React.FunctionComponent<Props>;

export const ChannelListsScreenViewMainSectionContent: ChannelListsScreenViewLeftSectionModule = (props) => {
    const { navigation, arrangeableChannelListsIds, isArranging, firstVisibleIndex, onFocus } = props;

    const dispatch = useDispatch();
    const ctx = Focus.useCtx();

    const handleEnter = () => {
        dispatch(channelListsScreenActions.private.itemSelected(arrangeableChannelListsIds, navigation.focused));
    };

    const renderItem = (channelListId: string, index: number) => (
        <ChannelListsScreenViewMainSectionItem
            key={channelListId}
            channelListId={channelListId}
            index={index}
            ctx={ctx}
            navigation={navigation}
            isArranging={isArranging}
            handleEnter={handleEnter}
            onFocus={onFocus}
        />
    );

    Focus.useBack(() => {
        if (ctx === ChannelListsScreenCtx.ChannelLists && isArranging) {
            handleEnter();
            return;
        }

        dispatch(channelListsScreenActions.private.back());
    });

    return (
        <>
            <Settings.Section.Header>
                <ChannelListsScreenViewCreateButton />
            </Settings.Section.Header>
            <Settings.Section.Content className="channel-lists-screen__list">
                <List
                    firstVisibleIndex={firstVisibleIndex}
                    className={'settings-section-content__list'}
                    items={arrangeableChannelListsIds}
                    itemType={ListItemType.CHANNEL_LIST_ITEM}
                    renderer={renderItem}
                />
            </Settings.Section.Content>
        </>
    );
};
