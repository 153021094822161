import { GQL } from '__SMART_APP_OLD__/app/gql';
import { DeviceEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/types';

export const toIsESTDTOEnabled = (policies: GQL.Maybe<GQL.DeviceEnablementPolicy>[]): boolean =>
    policies.some((policy) => policy?.enabled && policy?.title === 'EST/DTO');

export const toEntity = (device: GQL.Device): DeviceEntity => ({
    id: device.id,
    deviceType: device.deviceType,
    name: device.name ?? '_',
    removable: device.removable,
    removableFrom: new Date(device.removableFrom).getTime(),
    isESTDTOEnabled: toIsESTDTOEnabled(device.deviceEnablementPolicies),
});

export const toEntities = (devices: GQL.Device[]): Record<GQL.DeviceID, DeviceEntity> =>
    devices.reduce<Record<GQL.DeviceID, DeviceEntity>>((acc, device) => {
        acc[device.id] = toEntity(device);
        return acc;
    }, {});
