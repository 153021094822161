import { Key } from 'App/Modules/Key';

//
// X and Y - Different types for easier use
//  --------------------------------------------------
// when you hover on the function the return type will be:
// [ X, Y ] instead of [ Direction, Direction ]
//
export type X = 1 | -1 | 0;
export type Y = 1 | -1 | 0;
export type Tuple = [X, Y];

/**
 *
 * @param key tv remote control key
 * @returns tuple of X,Y direction
 */
export const get = (key: number): Tuple => {
    switch (key) {
        case Key.VK_RIGHT:
            return [1, 0];
        case Key.VK_LEFT:
            return [-1, 0];
        case Key.VK_UP:
            return [0, -1];
        case Key.VK_DOWN:
            return [0, 1];
        default:
            return [0, 0];
    }
};
