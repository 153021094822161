import React from 'react';

import { Image, ObjectFit, Orientation } from '__SMART_APP_OLD__/app/components/Image';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectTVGuideBackgroundImage,
    selectTVGuideBackgroundImageVariant,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';

export const TVGuideBackgroundImageComponent: React.FunctionComponent = () => {
    const image = useSelector(selectTVGuideBackgroundImage);
    const variant = useSelector(selectTVGuideBackgroundImageVariant);
    return (
        <Image
            key={image ?? variant}
            className="tv-guide__background-image"
            src={image}
            variant={variant}
            objectFit={ObjectFit.COVER}
            orientation={Orientation.LANDSCAPE}
        />
    );
};

export const TVGuideBackgroundImage = React.memo(TVGuideBackgroundImageComponent);
