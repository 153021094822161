import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlayTrailerMutationVariables = Types.Exact<{
  input: Types.PlayTrailerInput;
}>;


export type PlayTrailerMutation = { __typename?: 'Nexx4Mutations', playTrailer: { __typename?: 'PlayTrailerPayload', playbackInfo: { __typename?: 'TrailerPlaybackInfo', deliveryKind: Types.ContentDeliveryKind, url: string, sessionId?: string | null, adPlaybackPreRoll?: number | null, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const PlayTrailerDocument = gql`
    mutation playTrailer($input: PlayTrailerInput!) {
  playTrailer(input: $input) {
    playbackInfo {
      deliveryKind
      url
      sessionId
      adPlaybackPreRoll
      adPlaybackRestrictions
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type PlayTrailerMutationFn = Apollo.MutationFunction<PlayTrailerMutation, PlayTrailerMutationVariables>;

/**
 * __usePlayTrailerMutation__
 *
 * To run a mutation, you first call `usePlayTrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayTrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playTrailerMutation, { data, loading, error }] = usePlayTrailerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlayTrailerMutation(baseOptions?: Apollo.MutationHookOptions<PlayTrailerMutation, PlayTrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayTrailerMutation, PlayTrailerMutationVariables>(PlayTrailerDocument, options);
      }
export type PlayTrailerMutationHookResult = ReturnType<typeof usePlayTrailerMutation>;
export type PlayTrailerMutationResult = Apollo.MutationResult<PlayTrailerMutation>;
export type PlayTrailerMutationOptions = Apollo.BaseMutationOptions<PlayTrailerMutation, PlayTrailerMutationVariables>;