import { Subscribable } from 'App/Packages/Subscribable';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { InputPlaceholder } from 'App/Packages/UI/Input/Components/InputPlaceholder';
import { InputValue } from 'App/Packages/UI/Input/Components/InputValue';
import React from 'react';

const getClassName = (block: string | undefined) => {
    const classList = ['input-v8'];
    if (block) classList.push(block);
    return classList.join(' ');
};

export type InputProps = Omit<DivPropsExtendable, 'children' | 'onChange'> & {
    value: Subscribable.Value<string>;
    carret: Subscribable.Value<number>;
    focused: Subscribable.Value<boolean>;
    placeholder?: string;
    block?: string;
    hidden?: boolean;
};

export const Input: React.FunctionComponent<InputProps> = (props) => {
    const { value, carret, placeholder = '', block, hidden, focused, ...other } = props;
    const text = Subscribable.use(value);
    return (
        <Div defaultClassName={getClassName(block)} {...other}>
            <InputValue value={value} focused={focused} carret={carret} block={block} hidden={hidden} />
            {!text && <InputPlaceholder block={block}>{placeholder}</InputPlaceholder>}
        </Div>
    );
};
