import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { DeviceFragmentDoc } from './device.generated';
export type DevicesFragment = { __typename?: 'Household', devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> } | null> } };

export const DevicesFragmentDoc = gql`
    fragment devices on Household {
  devices {
    items {
      ...device
    }
  }
}
    ${DeviceFragmentDoc}`;