import { Screen } from 'App/Modules/Screen';
import { ContentItemType, SearchContentType } from '__SMART_APP_OLD__/api/graphql/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Dispatch } from '__SMART_APP_OLD__/app/store/types/Dispatch';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AssetType, Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

const ERROR_NOT_IMPLEMENTED_YET = 'DeepLink: The action for this URL is not implemented yet.';
const ERROR_INVALID_DEEP_LINK = 'DeepLink: Not a valid deep link structure.';
const UNHANDLED_ERROR_EXECUTE_DEEP_LINK_ACTION = (e: unknown) => `DeepLink: Error executing deep link action ${JSON.stringify(e)}`;

const getConfiguration =
    (dispatch: Dispatch) =>
    (id: string | null, link: string): Record<string, () => void> => ({
        'nexx4:home': () => {
            // nexx4:Home[/]
            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/page/${Route.HOME}`);
        },
        'nexx4:ondemand': () => {
            // nexx4:OnDemand[/][?id=theContentFolderId123]
            if (id) {
                dispatch(History.actions.clearReduxBeforeNavigation());
                CustomHistory.go(`/page/${Route.VOD_FOLDER}/${id}`);
                return;
            }

            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/page/${Route.VIDEOSTORE}`);
        },
        'nexx4:mylibrary': () => {
            // nexx4:MyLibrary[/]
            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/page/${Route.MY_LIBRARY}`);
        },
        'nexx4:detailedtvinfo': () => {
            // nexx4:DetailedTVInfo[/]?id=theEventId123
            if (!id) return;

            dispatch(History.actions.clearReduxBeforeNavigation());
            dispatch(
                Screen.actions.mount(Screen.Type.Detail, {
                    id,
                    type: ContentItemType.Event,
                })
            );
            // CustomHistory.go(`/details/${AssetType.EVENT}/${id}`); --migrated
        },
        'nexx4:detailedondemandvideoinfo': () => {
            // nexx4:DetailedOnDemandVideoInfo[/]?id=theVodAssetId123
            if (!id) return;

            dispatch(History.actions.clearReduxBeforeNavigation());
            dispatch(
                Screen.actions.mount(Screen.Type.Detail, {
                    id,
                    type: ContentItemType.Vodasset,
                })
            );
            // CustomHistory.go(`/details/${AssetType.VOD_ASSET}/${id}`); -- migrated
        },
        'nexx4:detailedondemandseriesinfo': () => {
            // nexx4:DetailedOnDemandSeriesInfo[/]?id=theSeriesID123
            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/details/${AssetType.VOD_SERIES}/${id}`);
        },
        'nexx4:detailedrecordinginfo': () => {
            // nexx4:DetailedRecordingInfo[/]?id=theRecordingId123
            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/details/${AssetType.NETWORK_RECORDING}/${id}`);
        },
        'nexx4:search': () => {
            // nexx4:Search[/]?[orderContentType=EVENTS][&query=theUrlEncodedSearchString]
            dispatch(History.actions.clearReduxBeforeNavigation());
            const queryParams = new URLSearchParams(link.replace('nexx4:search', '').replace('/', ''));
            const query = queryParams.get('query') ?? '';
            const context = link.includes(`ordercontenttype=events`)
                ? SearchContentType.Events
                : link.includes(`ordercontenttype=recordings`)
                  ? SearchContentType.Recordings
                  : SearchContentType.OnDemand;

            dispatch(Screen.actions.mount(Screen.Type.Search, { query, context }));
        },
        'nexx4:settings': () => {
            if (link.includes('languages')) {
                // nexx4:Settings/languages[/]
                dispatch(History.actions.clearReduxBeforeNavigation());
                CustomHistory.go(`/settings/languages`);
                return;
            }

            if (link.includes('privacy')) {
                // nexx4:Settings/privacy[/]
                throw new Error(ERROR_NOT_IMPLEMENTED_YET);
            }

            if (link.includes('about')) {
                // nexx4:Settings/about[/]
                throw new Error(ERROR_NOT_IMPLEMENTED_YET);
            }

            dispatch(History.actions.clearReduxBeforeNavigation());
            CustomHistory.go(`/settings/main`);
        },
    });

export const executeActionFromLink =
    (link: string): Thunk<void> =>
    (dispatch) => {
        try {
            const isValidDeepLink = link.startsWith('nexx4:');

            if (!isValidDeepLink) {
                throw new Error(ERROR_INVALID_DEEP_LINK);
            }

            const id = link.includes('id=') ? link.slice(link.indexOf('id=') + 3) : null;
            const actionLabel = link.split('?')[0].split('/')[0].toLowerCase();
            const action = getConfiguration(dispatch)(id, link)[actionLabel];

            if (!action) {
                throw new Error(ERROR_NOT_IMPLEMENTED_YET);
            }

            action();
        } catch (e) {
            if (typeof e === 'object' && !!e && 'message' in e) {
                console.error(e.message);
                return;
            }

            const unhandledError = UNHANDLED_ERROR_EXECUTE_DEEP_LINK_ACTION(e);
            console.error(unhandledError);
        }
    };
