import React from 'react';

import { Div } from '__SMART_APP_OLD__/app/components/Div';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { Scrollable } from 'App/Packages/UI/Scrollable';
import { SelectOptionEmpty } from 'App/Packages/UI/Select/SelectOptionEmpty';
import { SelectOptionsChildren, SelectOptionsChildrenModule } from 'App/Packages/UI/Select/SelectOptionsChildren';
import { SelectOptionsContainer, SelectOptionsContainerModule } from 'App/Packages/UI/Select/SelectOptionsContainer';
import { SelectOptionsContext, SelectOptionsContextModule, useSelectOptionsContext } from 'App/Packages/UI/Select/SelectOptionsContext';
import { Axis } from 'App/Types/Axis';

export type SelectOptionsProps = {
    ids: string[];
    selected: string;
    initialFocused?: string;
    onEnter?: (id: string) => void;
    children: React.ReactNode;
};

export type SelectOptionsStaticModule = React.FunctionComponent<SelectOptionsProps>;

export type SelectOptionsModule = {
    Container: SelectOptionsContainerModule;
    Context: SelectOptionsContextModule;
    useContext: typeof useSelectOptionsContext;
    Children: SelectOptionsChildrenModule;
} & React.FunctionComponent<SelectOptionsProps>;

const OptionSize = 104 / 19.2;
// TO DO: Fix Aniamtion
// const ScrollTransition = 250;
// const ScrollDelay = 50;
const OptionsAxis = Axis.Y;
const EmptyOptionsCount = 7;
const MaximumOptionsCountForStaticBehaviour = 4;

const SelectOptionsWrapper: React.FunctionComponent<SelectOptionsProps> = (props) => {
    const { focused, focusedIndex, isDisabled, focus } = useListHook({
        axis: OptionsAxis,
        ctx: FocusDefault,
        items: props.ids,
        initialFocusedIndex: props.ids.indexOf(props.initialFocused ?? props.selected),
    });

    return (
        <SelectOptions.Context.Provider
            ids={props.ids}
            focused={focused}
            focusedIndex={focusedIndex}
            selected={props.selected}
            disabled={isDisabled}
            onHover={focus}
            onEnter={props.onEnter}
        >
            {props.children}
        </SelectOptions.Context.Provider>
    );
};

const SelectOptionsInternal: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const { ids, focusedIndex } = useSelectOptionsContext();

    const optionsCount = ids.length;

    if (optionsCount <= MaximumOptionsCountForStaticBehaviour) {
        return <Div className="select__static-options">{props.children}</Div>;
    }

    return (
        <Scrollable.Static
            className="select__options"
            top={focusedIndex}
            axis={OptionsAxis}
            step={OptionSize}
            steps={ids.length + EmptyOptionsCount}
            // transition={ScrollTransition}
            // delay={ScrollDelay}
        >
            <SelectOptionEmpty />
            <SelectOptionEmpty />
            <SelectOptionEmpty />
            {props.children}
            <SelectOptionEmpty />
            <SelectOptionEmpty />
            <SelectOptionEmpty />
            <SelectOptionEmpty />
        </Scrollable.Static>
    );
};

export const SelectOptions: SelectOptionsModule = (props) => (
    <SelectOptions.Container loading={!props.ids.length}>
        <SelectOptionsWrapper ids={props.ids} selected={props.selected} initialFocused={props.initialFocused} onEnter={props.onEnter}>
            <SelectOptionsInternal>{props.children}</SelectOptionsInternal>
        </SelectOptionsWrapper>
    </SelectOptions.Container>
);

SelectOptions.Container = SelectOptionsContainer;
SelectOptions.Children = SelectOptionsChildren;
SelectOptions.Context = SelectOptionsContext;
SelectOptions.useContext = useSelectOptionsContext;
