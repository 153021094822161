import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';

import { Overlay } from 'App/Modules/Overlay';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

const ids = [Translation.Key.Shown, Translation.Key.Hidden];

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.AdultContentSelect>> = () => {
    const dispatch = useDispatch();

    const selected = useSelector(Profile.selectors.selectMyLibraryAdultContentVisibilityLabel);

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={() => dispatch(Overlay.actions.unmount())}>
            <UI.Select.Header>
                <UI.Select.Title>{Translation.Key.Overlay.Select.AdultContent.Title}</UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options ids={ids} selected={selected}>
                <UI.Select.Option
                    id={Translation.Key.Shown}
                    onEnter={() => dispatch(Profile.actions.myLibraryAdultContentVisibilityChanged(true))}
                >
                    <UI.Translation>{Translation.Key.Shown}</UI.Translation>
                </UI.Select.Option>
                <UI.Select.Option
                    id={Translation.Key.Hidden}
                    onEnter={() => dispatch(Profile.actions.myLibraryAdultContentVisibilityChanged(false))}
                >
                    <UI.Translation>{Translation.Key.Hidden}</UI.Translation>
                </UI.Select.Option>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
