import { useMemo, useRef } from 'react';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { FolderListValue } from 'App/Packages/UI/Folder/Modules/FolderList/Context/FolderListContext';
import { useFolderListEnter } from 'App/Packages/UI/Folder/Modules/FolderList/Hooks/Private/useFolderListEnter';
import { useFolderListInterceptor } from 'App/Packages/UI/Folder/Modules/FolderList/Hooks/Private/useFolderListInterceptor';

export type UseFolderListValuePayload = {
    ctx?: string;
    disabled?: boolean;
    offsetTop?: number;
    enter?: (id: string) => void;
};

export type UseFolderListValue = (payload: UseFolderListValuePayload) => FolderListValue;

export const useFolderListValue: UseFolderListValue = (payload) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const ctx = payload.ctx ?? Constants.Ctx;
    const offsetTop = payload.offsetTop ?? 0;
    const axis = Constants.List.Axis;
    const ids = Subscribable.useArray<string>([]);
    const scroll = useMemo(() => new Scroll(ref), [ref]);
    const { guard } = Constants.List;
    const interceptor = useFolderListInterceptor(ids, scroll);
    const items = Subscribable.use(ids);
    const navigation = useListHook({ axis, ctx, items, disabled: payload.disabled, guard, interceptor });
    const focused = Subscribable.useValue<string>(navigation.focused);
    const disabled = Subscribable.useValue<boolean>(navigation.isDisabled);
    const { focus } = navigation;
    const enter = useFolderListEnter(payload.enter);

    Subscribable.useSetter(focused, navigation.focused);
    Subscribable.useSetter(disabled, navigation.isDisabled);

    return useMemo(
        () => ({ ref, ctx, ids, focused, disabled, offsetTop, scroll, focus, enter }),
        [ref, ctx, ids, focused, disabled, offsetTop, scroll, focus, enter]
    );
};
