import * as Types from '../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { UpsellProductsFragmentDoc } from '../../Root/Api/fragments/upsellProducts/upsellProducts.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VodAssetProductsLoadQueryVariables = Types.Exact<{
  vodAssetId: Types.Scalars['ID']['input'];
  profileId: Types.Scalars['ID']['input'];
}>;


export type VodAssetProductsLoadQuery = { __typename?: 'Nexx4Queries', upsellProducts?: { __typename?: 'ProductCatalog', id: string, items: Array<{ __typename?: 'ChannelProduct' } | { __typename?: 'PPVProduct' } | { __typename?: 'ProductBundle' } | { __typename?: 'VODProduct', title: string, id: string, videoQuality?: Types.VideoQuality | null, packageProduct: boolean, kind: Types.ProductKind, price?: { __typename?: 'Price', id: string, expiry: any, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, expiry: any, availableUntil?: any | null } | null } | null> } | null };

export type VodAssetProductsLoadFragment = { __typename?: 'Nexx4Queries', upsellProducts?: { __typename?: 'ProductCatalog', id: string, items: Array<{ __typename?: 'ChannelProduct' } | { __typename?: 'PPVProduct' } | { __typename?: 'ProductBundle' } | { __typename?: 'VODProduct', title: string, id: string, videoQuality?: Types.VideoQuality | null, packageProduct: boolean, kind: Types.ProductKind, price?: { __typename?: 'Price', id: string, expiry: any, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, expiry: any, availableUntil?: any | null } | null } | null> } | null };

export const VodAssetProductsLoadFragmentDoc = gql`
    fragment vodAssetProductsLoad on Nexx4Queries {
  upsellProducts(kind: VOD_ASSET, objectId: $vodAssetId, profileId: $profileId) {
    ...upsellProducts
  }
}
    ${UpsellProductsFragmentDoc}`;
export const VodAssetProductsLoadDocument = gql`
    query vodAssetProductsLoad($vodAssetId: ID!, $profileId: ID!) {
  ...vodAssetProductsLoad
}
    ${VodAssetProductsLoadFragmentDoc}`;

/**
 * __useVodAssetProductsLoadQuery__
 *
 * To run a query within a React component, call `useVodAssetProductsLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useVodAssetProductsLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVodAssetProductsLoadQuery({
 *   variables: {
 *      vodAssetId: // value for 'vodAssetId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useVodAssetProductsLoadQuery(baseOptions: Apollo.QueryHookOptions<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>(VodAssetProductsLoadDocument, options);
      }
export function useVodAssetProductsLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>(VodAssetProductsLoadDocument, options);
        }
export function useVodAssetProductsLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>(VodAssetProductsLoadDocument, options);
        }
export type VodAssetProductsLoadQueryHookResult = ReturnType<typeof useVodAssetProductsLoadQuery>;
export type VodAssetProductsLoadLazyQueryHookResult = ReturnType<typeof useVodAssetProductsLoadLazyQuery>;
export type VodAssetProductsLoadSuspenseQueryHookResult = ReturnType<typeof useVodAssetProductsLoadSuspenseQuery>;
export type VodAssetProductsLoadQueryResult = Apollo.QueryResult<VodAssetProductsLoadQuery, VodAssetProductsLoadQueryVariables>;