import { Input, InputElement } from 'App/Modules/Input';
import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { Focus } from 'App/Packages/Focus';
import React from 'react';

export type LoginWithEmailFormFieldProps = {
    id: LoginElement;
    ctx: string;
    field: React.RefObject<InputElement>;
    value?: string;
    isFocused?: boolean;
    placeholder: string;
    hidden?: boolean;
    onComplete?: ((target: InputElement) => void) | undefined;
    onHover: (id: LoginElement) => void;
};

export const LoginWithEmailFormField: React.FunctionComponent<LoginWithEmailFormFieldProps> = (props) => {
    const { id, ctx, field, isFocused = false, value, placeholder, hidden, onHover, onComplete } = props;
    const onEnter = () => field.current?.focus();
    const onBlur = () => field.current?.blur();
    const focusable = Focus.useFocusable({ id, ctx, isFocused, onHover, onEnter, onBlur });

    return (
        <Input
            id={id}
            ref={field}
            block="login-with-email-field"
            className={isFocused ? 'login-with-email-field--focused' : ''}
            value={value}
            placeholder={placeholder}
            hidden={hidden}
            onClick={focusable.onClick}
            onHover={focusable.onHover}
            onComplete={onComplete}
        />
    );
};
