import { initialState } from 'App/Modules/Data/Search/Suggestion/Store/State';
import { SuggestionActionType, SuggestionState } from 'App/Modules/Data/Search/Suggestion/Types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const reducer: Reducer<SuggestionState> = (state, action) => {
    if (!state) return initialState;

    switch (action.type) {
        case SuggestionActionType.Loaded:
            if (action.payload.suggestions.length === 0) return initialState;
            return action.payload.suggestions;
        case SuggestionActionType.Clear:
            return initialState;
        default:
            return state;
    }
};
