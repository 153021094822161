import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Mask } from 'App/Packages/UI/Mask';
import React from 'react';

const getClassName = (props: DetailMetaInfoProps): string => {
    const classList = ['detail__meta-info'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailMetaInfoProps = {
    typeface?: Typeface;
    size?: FontSize;
    weight?: FontWeight;
    color?: Color;
    theme?: Theme.Type | null;
    isFocused?: boolean;
    className?: string;
    children?: React.ReactNode;
    customColor?: string;
};

export type DetailMetaInfoComponent = React.FunctionComponent<DetailMetaInfoProps>;

export const DetailMetaInfo: DetailMetaInfoComponent = (props) => {
    return (
        <Text
            className={getClassName(props)}
            typeface={props.typeface ?? Typeface.SANS}
            size={props.size ?? FontSize.BODY_2}
            weight={props.weight ?? FontWeight.REGULAR}
            color={props.color ?? Color.SECONDARY}
            theme={props.theme}
            isFocused={props.isFocused}
            customColor={props.customColor}
        >
            <Mask.Description>{props.children}</Mask.Description>
        </Text>
    );
};
