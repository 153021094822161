import { Translation } from 'App/Packages/UI/Translation';
import React from 'react';

const getClassName = (block: string | undefined) => {
    const classList = ['input-v8__placeholder'];
    if (block) classList.push(`${block}__placeholder`);
    return classList.join(' ');
};

export type InputPlaceholderProps = {
    block?: string;
    children: string;
};

export const InputPlaceholder: React.FunctionComponent<InputPlaceholderProps> = (props) => {
    const { block, children } = props;
    return (
        <span className={getClassName(block)}>
            <Translation>{children}</Translation>
        </span>
    );
};
