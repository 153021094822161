import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

import { Registry } from 'App/Modules/Screen/Registry';
import { generator } from 'App/Modules/Screen/Store/generator';
import { selectors } from 'App/Modules/Screen/Store/selectors';
import { ScreenActionType, ScreenChangedAction, ScreenEntity, ScreenProps, ScreenType } from 'App/Modules/Screen/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants/';

const changed = (screen: ScreenEntity | null): ScreenChangedAction => ({
    type: ScreenActionType.CHANGED,
    payload: { screen },
});

const update =
    <T extends ScreenType>(type: T, props: Partial<ScreenProps<T>> = {}, ctx?: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const screen = selectors.public.selectTyped(type)(getState());
        if (!screen) return;
        const newProps: ScreenProps<T> = { ...screen.props, ...props } as unknown as ScreenProps<T>;
        dispatch(changed(generator(type, newProps, ctx ?? screen.ctx)));
        await dispatch(Registry.get(type)[1].update(newProps, ctx ?? screen.ctx));
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const screen = selectors.public.select(getState());
    if (!screen) return;
    await dispatch(Registry.get(screen.type)[1].unmount(screen.props, screen.ctx));
    dispatch(changed(null));
};

const mount =
    <T extends ScreenType>(type: T, props: ScreenProps<T>, ctx: string = FocusDefault): Thunk<Promise<void>> =>
    async (dispatch) => {
        if (window.location.pathname !== '/redux') CustomHistory.go('/redux');
        await dispatch(unmount());
        dispatch(changed(generator(type, props, ctx)));
        await dispatch(Registry.get(type)[1].mount(props, ctx));
    };

const ctxChanged =
    (type: ScreenType, ctx: string): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(update(type, {}, ctx));

const setCtx =
    (ctx: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const screen = selectors.public.select(getState());
        if (!screen) return;
        if (screen.ctx === ctx) return;
        dispatch(ctxChanged(screen.type, ctx));
    };

export const actions = {
    public: {
        mount,
        unmount,
        update,
        ctx: {
            set: setCtx,
            changed: ctxChanged,
        },
    },
    private: {
        changed,
    },
} as const;
