import { CardBannerComponent, CardBanner as Root } from 'App/Packages/UI/Card/CardBanner/Components/CardBanner';
import { CardBannerThumbnail, CardBannerThumbnailComponent } from 'App/Packages/UI/Card/CardBanner/Components/CardBannerThumbnail';
import { Constants, ConstantsType } from 'App/Packages/UI/Card/CardBanner/Constants';

export type CardBannerModules = {
    Constants: ConstantsType;
    Thumbnail: CardBannerThumbnailComponent;
};

export type CardBannerModule = CardBannerComponent & CardBannerModules;

export const CardBanner: CardBannerModule = Object.assign(Root, {
    Constants,
    Thumbnail: CardBannerThumbnail,
});
