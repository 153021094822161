/* eslint-disable max-classes-per-file */
class AppEngine {
    public getIsInputActive: () => boolean = () => false;

    public close: () => void = () => {};

    public onBack: (event: KeyboardEvent) => void = () => {};
}

export class App {
    public static readonly Engine = AppEngine;

    private static engine: AppEngine = new AppEngine();

    public static readonly initialize = (engine: AppEngine): void => {
        App.engine = engine;
    };

    public static readonly getIsInputActive = (): boolean => App.engine.getIsInputActive();

    public static readonly close = (): void => App.engine.close();

    public static readonly onBack = (event: KeyboardEvent): void => App.engine.onBack(event);
}
