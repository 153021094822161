import React from 'react';

const Default: CardPortraitValue = {
    isFocused: false,
    isLarge: false,
};

export type CardPortraitValue = {
    isFocused: boolean;
    isLarge: boolean;
};

export type CardPortraitContextModule = React.Context<CardPortraitValue> & { Default: CardPortraitValue };

export const CardPortraitContext: CardPortraitContextModule = Object.assign(React.createContext(Default), { Default });
