import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListUpdateMutationVariables = Types.Exact<{
  channelListUpdateInput: Types.SetChannelListChannelsInput;
}>;


export type ChannelListUpdateMutation = { __typename?: 'Nexx4Mutations', setChannelListChannels: { __typename?: 'SetChannelListChannelsPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } } };

export type ChannelListUpdateFragment = { __typename?: 'Nexx4Mutations', setChannelListChannels: { __typename?: 'SetChannelListChannelsPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } } };

export const ChannelListUpdateFragmentDoc = gql`
    fragment channelListUpdate on Nexx4Mutations {
  setChannelListChannels(input: $channelListUpdateInput) {
    channelList {
      ...channelList
      channels {
        totalCount
      }
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListUpdateDocument = gql`
    mutation channelListUpdate($channelListUpdateInput: SetChannelListChannelsInput!) {
  ...channelListUpdate
}
    ${ChannelListUpdateFragmentDoc}`;
export type ChannelListUpdateMutationFn = Apollo.MutationFunction<ChannelListUpdateMutation, ChannelListUpdateMutationVariables>;

/**
 * __useChannelListUpdateMutation__
 *
 * To run a mutation, you first call `useChannelListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListUpdateMutation, { data, loading, error }] = useChannelListUpdateMutation({
 *   variables: {
 *      channelListUpdateInput: // value for 'channelListUpdateInput'
 *   },
 * });
 */
export function useChannelListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListUpdateMutation, ChannelListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListUpdateMutation, ChannelListUpdateMutationVariables>(ChannelListUpdateDocument, options);
      }
export type ChannelListUpdateMutationHookResult = ReturnType<typeof useChannelListUpdateMutation>;
export type ChannelListUpdateMutationResult = Apollo.MutationResult<ChannelListUpdateMutation>;
export type ChannelListUpdateMutationOptions = Apollo.BaseMutationOptions<ChannelListUpdateMutation, ChannelListUpdateMutationVariables>;