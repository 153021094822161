import { useEffect } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { selectIsLoggedIn } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { removeRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';

export const useRecordingScheduling = () => {
    const store = useStore();
    const time = useSelector(Time.selectors.select);
    const isLogged = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLogged) return;
        const expiredRecordings = Object.values(store.getState().data.recordingEntityTable.entities)
            .filter((recording) => recording.availableUntil! <= time)
            .map((recording) => recording.eventId!);
        if (expiredRecordings.length > 0) dispatch(removeRecording(expiredRecordings));
    }, [time, isLogged, store, dispatch]);
};
