import isEmpty from 'lodash.isempty';

import { NetworkRecording, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import Asset from '__SMART_APP_OLD__/data/Asset';
import { AssetType, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { calcRecordingStatus } from '__SMART_APP_OLD__/utils/events';
import { formatDateTime, getServerTime, isFuture } from '__SMART_APP_OLD__/utils/timeUtils';

import { ContentMarkerType } from 'App/Types/ContentMarker';

export default class ProgramEvent extends Asset {
    assetType = AssetType.EVENT;

    rawData: any;

    isNext: boolean = false;

    _recordings: NetworkRecording[] = [];

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);
        this.rawData = data;
        this.recordings = data?.personalEventInfo?.recordings ?? this.rawData?.personalInfo?.recordings ?? [];
    }

    get entitlements() {
        return this.rawData?.entitlements ?? this.rawData?.eventEntitlements ?? {};
    }

    get personalInfo() {
        return this.rawData?.personalInfo ?? this.rawData.personalEventInfo ?? {};
    }

    get isSeriesEvent(): boolean {
        return !isEmpty(this?.rawData?.metadata?.seriesInfo) && !isEmpty(this?.rawData?.metadata?.episodeInfo);
    }

    get isRecordable(): boolean {
        const { networkRecording, networkRecordingPlannableUntil } = this.entitlements;
        const start = this.startTime.getTime();
        const end = this.endTime.getTime();
        return Utils.isEventRecordable(networkRecording, start, end, networkRecordingPlannableUntil);
    }

    get recordings(): NetworkRecording[] {
        return this._recordings;
    }

    set recordings(value: NetworkRecording[]) {
        this._recordings = value;
    }

    get recordingStatus(): RecordingStatus | undefined {
        const isRecording = !!this.recordings?.length;
        if (!isRecording) return undefined;
        const status = this.recordings?.[0]?.status;
        return calcRecordingStatus(status, this.startTime.getTime(), this.endTime.getTime());
    }

    get isPartOfSeriesRecording(): boolean {
        const recording = this.recordings?.[0] as any;
        const personalInfo = recording?.personalEventInfo || recording?.personalInfo || recording?.personalRecordingInfo;
        return personalInfo?.partOfSeriesRecording ?? false;
    }

    get isPlaybackAvailable() {
        return (this.catchupTV || this.isLive) && !this.isFuture;
    }

    get detailPageMetaInfo() {
        const metaInfo = [];

        if (!this.channelLogo) {
            metaInfo.push(this.channelName);
        }

        metaInfo.push(this.shortMetaInfo);

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get liveEventMetaInfo() {
        const metaInfo: any = [];

        if (this.startTime) {
            metaInfo.push(this.getTimeStampInfo(formatDateTime(this.startTime, 'DD dd MMM')));
        }

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get duration() {
        return (this.endTime.getTime() - this.startTime.getTime()) / 1000;
    }

    get leadIn() {
        return this.rawData.startOverTVBeforeTime || 0;
    }

    get leadOut() {
        return this.rawData.startOverTVAfterTime || 0;
    }

    get progress() {
        let progress;

        // we need to include lead in and lead out values
        //  in case of catch up for calculation progress value
        // otherwise duration can be smaller than bookmark value
        if (this.bookmark) progress = ((this.bookmark?.position ?? 0) * 100) / (this.duration + this.leadIn + this.leadOut);
        else progress = 0;

        if (progress >= 100) {
            progress = 0;
        }

        return progress;
    }

    get endDateTime() {
        return this.endTime;
    }

    get endTime() {
        return new Date(this.rawData?.end);
    }

    get favourited() {
        return this.personalInfo.favourited;
    }

    get id(): string {
        return this.rawData?.id;
    }

    get isEntitled() {
        return this.rawData?.channel?.userInfo?.subscribed;
    }

    // used on mini epg: For Movies and Shows  → 'Year | Genre', For Series → 'S00E00 | Genre '
    get metaInfo() {
        const metaInfo = [];

        if (this.seasonAndEpisode) {
            metaInfo.push(this.seasonAndEpisode);
        } else if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp; I&nbsp; ');
    }

    get metaInfoPlayer() {
        const metaInfo = [];

        if (this.seasonAndEpisode || this.episodeTitle) {
            metaInfo.push(`${this.seasonAndEpisode} ${this.episodeTitle}`);
        }

        if (this.shortMetaInfo) {
            metaInfo.push(this.shortMetaInfo);
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get availableContentMarkers(): string[] {
        const currentDate = getServerTime();
        const currentTime = currentDate.getTime();

        const markers = [];
        const { programType } = this;

        if ((this.utcStartTime.getTime() <= currentTime && this.utcEndTime.getTime() > currentTime) || programType === ProgramType.LIVE) {
            markers.push(ContentMarkerType.Live);
        }

        if (this.isNext) {
            markers.push(ContentMarkerType.Next);
        }

        return markers;
    }

    get parentalRating() {
        return this.rawData?.parentalRating?.title;
    }

    get audioLanguages() {
        return this.rawData?.metadata?.audioLanguages;
    }

    get subtitleLanguages() {
        return this.rawData?.metadata?.subtitleLanguages;
    }

    get programType() {
        const currentDate = getServerTime();
        const currentTime = currentDate.getTime();

        if (this.utcStartTime.getTime() <= currentTime && this.utcEndTime.getTime() > currentTime) {
            return ProgramType.LIVE;
        }
        if (this.utcStartTime.getTime() > currentTime && this.utcEndTime.getTime() > currentTime) {
            return ProgramType.FUTURE;
        }
        return ProgramType.CATCHUP;
    }

    get shortMetaInfo() {
        return this.getTimeStampInfo();
    }

    get relatedMetaInfo() {
        return this.shortMetaInfo;
    }

    get startTime() {
        return new Date(this.rawData?.start);
    }

    get startDateTime() {
        return this.startTime;
    }

    get streamInfo() {
        return this.rawData?.streamInfo;
    }

    get isFuture() {
        return isFuture(this.rawData?.start);
    }

    get isLive() {
        return this.programType === ProgramType.LIVE;
    }

    get catchupTV() {
        try {
            return this.entitlements.catchupTV && isFuture(this.entitlements.catchupTVAvailableUntil) && !this.isFuture;
        } catch (e) {
            return false;
        }
    }

    get restartTV() {
        return this.entitlements.restartTV;
    }

    get pauseLiveTv() {
        return this.rawData?.eventEntitlements?.pauseLiveTV;
    }

    get isNetworkRecordingAvailable() {
        return this.entitlements.networkRecording && isFuture(this.entitlements.networkRecordingPlannableUntil);
    }

    get isReplayAvailable() {
        return this.catchupTV;
    }
}
