import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { selectConfig, selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Detail } from 'App/Modules/Data/Detail';
import { Screen } from 'App/Modules/Screen';
import { AssetType, PinAction } from '__SMART_APP_OLD__/utils/Constants';
import { PromptType } from 'App/Modules/Prompt/Types';
import { Prompt } from 'App/Modules/Prompt';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { LoggingService } from 'analytics/loggingService';
import { getDeleteRecordingEvent, getPlanRecordingEvent } from 'analytics/logging/factories/recordingEventFactory';
import { RecordingPlanningTypes } from 'analytics/logging/events/RecordingEvent';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import Events, { CONTINUE_WATCHING_CHANGED } from '__SMART_APP_OLD__/config/Events';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { selectParentalRatingIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { Overlay } from 'App/Modules/Overlay';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Data } from 'App/Modules/Data';
import { SeriesDetailLoadQueryVariables } from 'App/Modules/Data/Detail/Series/Api/seriesDetailLoad.generated';
import { Folder } from 'App/Modules/Folder';
import { canResume, startPlayback } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/helpers';
import { DefaultDetailScreenOutOfBounds } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/defaultOutOfBounds';
import { selectRecordingByEventId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { ReminderEvents } from 'analytics/logging/events/ReminderEvent';
import { getReminderEvent } from 'analytics/logging/factories/reminderEventFactory';
import { reminderCancel, reminderCreate } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';
import { UI } from 'App/Packages/UI';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { History } from '__SMART_APP_OLD__/app/modules/History';

export const toDetailPage =
    (id: string, type: ContentItemType): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const assetType = assetTypeForContentItemType[type];
        const path = `/details/${assetType}/${id}`;

        const prevScreen = Screen.selectors.selectTyped(Screen.Type.SeriesDetail)(state);

        if (!prevScreen) return undefined;

        dispatch(
            Screen.actions.update(
                Screen.Type.SeriesDetail,
                {
                    initialFocusedItemId: id,
                    id: prevScreen.props.id,
                },
                UI.Folder.Constants.Ctx
            )
        );
        dispatch(History.actions.push());

        return dispatch(
            Screen.actions.mount(Screen.Type.Detail, {
                id,
                type,
                oldOperation: () => CustomHistory.go(path),
            })
        );
    };

const watchSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Series.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetObject = Detail.Series.selectors.selectAssetObjectById(asset.id)(state);
    const progress = Detail.Root.selectors.selectAssetProgress(itemSelector)(state);
    const bookmark = Bookmarks.selectors.selectEntity(asset.id)(state);
    const resumeThreshold = selectResumeThreshold(state);
    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    const resume = canResume(asset, progress, bookmark, resumeThreshold);
    const event = resume ? UIActionEvents.CONTINUE_WATCHING : UIActionEvents.PLAY;
    const isStartOver = !resume;

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, event, isStartOver));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, event, isStartOver)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const startOverSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const asset = Detail.Series.selectors.selectAsset(state);
    if (!asset) return;
    const assetObject = Detail.Series.selectors.selectAssetObjectById(asset.id)(state);
    const pinSessionType = asset.metadata.sessionType;
    const isRestricted = selectParentalRatingIsRestricted(asset.metadata.parentalRatingId, pinSessionType)(state);

    if (!isRestricted) {
        dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true));
        return;
    }

    openPinOverlay(
        () => dispatch(startPlayback(asset, assetObject, UIActionEvents.VIEW_FROM_START, true)),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        pinSessionType
    );
};

const manageRecordingsSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const itemSelector = Detail.Series.selectors.selectAsset;
    const asset = itemSelector(state);

    if (!asset) return;

    const bookmark = Detail.Root.selectors.selectAssetBookmark(itemSelector)(state);
    const recording = Detail.Root.selectors.selectAssetRecording(itemSelector)(state);
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCancelSeries, { eventId: asset.id }));

    const isFuture = recording?.status === GQL.RecordingStatus.Planned;
    const action = isFuture ? UIActionEvents.CANCEL_SERIES_RECORDING : UIActionEvents.DELETE_ALL_RECORDED_EPISODES;
    const event = getUIActionEvent(action, { id: asset.id, assetType: AssetType.SERIES });
    LoggingService.getInstance().logEvent(event);
    LoggingService.getInstance().logEvent(getDeleteRecordingEvent(asset.id, true, isFuture));
    const uiActionEvent = getUIActionEvent(UIActionEvents.RECORD, {
        id: asset.id,
        assetType,
    });
    const recordingEvent = await getPlanRecordingEvent(asset.id, RecordingPlanningTypes.EPG, true);
    LoggingService.getInstance().logEvent(uiActionEvent);
    LoggingService.getInstance().logEvent(recordingEvent);

    if (!bookmark) return;

    Events.triggerEvents(CONTINUE_WATCHING_CHANGED);
};

const showMoreSelected =
    (title: string, actors: string[], directors: string[], description: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const asset = Detail.Series.selectors.selectAsset(state);
        if (!asset) return;
        const assetObject = Detail.Series.selectors.selectAssetObjectById(asset.id)(state);

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.OPEN_FULL_SYNOPSIS, assetObject));
        dispatch(Overlay.actions.mount(Overlay.Type.ShowMore, { title, actors, directors, description }));
    };

const folderItemSelected =
    (itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        const itemSelector = Detail.Series.selectors.selectAssetById(itemId);
        const item = itemSelector(state);

        if (!item || !item.type) return;

        const pinSessionType = item.metadata.sessionType;
        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, pinSessionType)(state);

        if (!isRestricted) {
            dispatch(toDetailPage(item.id, item.type));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toDetailPage(item.id, item.type)),
            () => dispatch(Overlay.actions.unmount())
        );
    };

const recordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Series.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetObject = Detail.Series.selectors.selectAssetObjectById(asset.id)(state);
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCreate, { eventId: asset.id }));

    const uiActionEvent = getUIActionEvent(UIActionEvents.RECORD, {
        id: asset.id,
        assetType,
    });
    const recordingEvent = await getPlanRecordingEvent(asset.id, RecordingPlanningTypes.EPG, assetObject?.isSeriesEvent);

    LoggingService.getInstance().logEvent(uiActionEvent);
    LoggingService.getInstance().logEvent(recordingEvent);
};

const deleteRecordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const asset = Detail.Series.selectors.selectAsset(state);
    if (!asset) return;
    const recording = selectRecordingByEventId(asset.id)(state);

    if (!recording) return;

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmDelete, { recordingId: recording.id }));
};
const cancelRecordSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Series.selectors.selectAsset;
    const asset = itemSelector(state);
    if (!asset) return;
    const assetType = Detail.Root.selectors.selectAssetType(itemSelector)(state);

    const event = getUIActionEvent(UIActionEvents.STOP_RECORDING, {
        id: asset.id,
        assetType,
    });
    const recording = selectRecordingByEventId(asset.id)(state);

    if (!recording) return;

    dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCancel, { recordingId: recording.id }));
    LoggingService.getInstance().logEvent(event);
};
const reminderSelected = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const itemSelector = Detail.Series.selectors.selectAsset;
    const assetId = Detail.Root.selectors.selectAssetId(itemSelector)(state);
    const reminder = Detail.Root.selectors.selectAssetReminder(itemSelector)(state);

    const eventType = reminder ? ReminderEvents.CANCEL : ReminderEvents.PLAN;
    LoggingService.getInstance().logEvent(getReminderEvent(eventType, assetId));

    if (eventType === ReminderEvents.CANCEL) {
        dispatch(reminderCancel(assetId));
    }

    dispatch(reminderCreate(assetId));
};

const mount: Screen.LifecycleMethod<Screen.Type.SeriesDetail> =
    (props): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));

        const state = getState();
        const config = selectConfig(state);

        const seriesId = props.id;
        const profileId = Profile.selectors.selectId(state);

        const variables: SeriesDetailLoadQueryVariables = {
            ...Data.Detail.Api.series.DefaultVariables,
            profileId,
            seriesId,
            backgroundWidth: config.image.background.landscape,
            backgroundHeight: config.image.background.width,
        };

        await dispatch(Detail.Series.actions.load(variables));
        dispatch(Overlay.actions.unmount());
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    dispatch(Detail.Series.actions.reset());
};

export const DetailSeriesActions = {
    public: {
        watchSelected,
        startOverSelected,
        manageRecordingsSelected,
        showMoreSelected,
        folderItemSelected,
        recordSelected,
        deleteRecordSelected,
        cancelRecordSelected,
        reminderSelected,
    },
    lifecycle: {
        mount,
        unmount,
        ...DefaultDetailScreenOutOfBounds,
    },
};
