import React, { useEffect, useState } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { pinBlockedChanged } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/actions';
import { selectPinBlockedUntil } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { usePinBusinessLogic } from '__SMART_APP_OLD__/components/pin/PinUtils';
import PinWrapper from '__SMART_APP_OLD__/components/pin/PinWrapper';
import Events, { PIN_BLOCKED, PIN_UNBLOCKED } from '__SMART_APP_OLD__/config/Events';
import { convertMilliseconds } from '__SMART_APP_OLD__/utils/timeUtils';
import * as issueEventFactory from 'analytics/logging/factories/issueEventFactory';
import { LoggingService } from 'analytics/loggingService';

const PinOverlay: React.FC = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const pinBlockedTime = Math.floor(useSelector(selectPinBlockedUntil) / 1000);
    const {
        focusedIndex,
        isDialogVisible,
        onKey,
        pin,
        overlayInfo,
        isKeyboardOpen,
        setIsKeyboardOpen,
        style,
        resetInputStates,
        updateInputState,
        updateFocusedIndex,
    } = usePinBusinessLogic();
    const [userBlocked, setUserBlocked] = useState(false);
    const [counter, setCounter] = useState(0);

    const startCounter = (lockDuration: number) => {
        if (lockDuration > 1) {
            setTimeout(() => {
                startCounter(lockDuration - 1);
            }, 1000);
            setCounter(lockDuration - 1);
        } else {
            setUserBlocked(false);
            setIsKeyboardOpen(true);
            dispatch(pinBlockedChanged(-1));
            Events.triggerEvents(PIN_UNBLOCKED);
        }
    };

    const pinBlocked = () => {
        setIsKeyboardOpen(false);
        startCounter(convertMilliseconds(config.pin.lockDuration, 's'));
        dispatch(pinBlockedChanged(Date.now() + config.pin.lockDuration));
        LoggingService.getInstance().logEvent(issueEventFactory.getPinLockoutIssueEvent());
        setUserBlocked(true);
    };

    useEffect(() => {
        if (pinBlockedTime !== -1) {
            const currentTimeStamp = Math.floor(Date.now() / 1000);
            if (pinBlockedTime - currentTimeStamp > -1) {
                startCounter(pinBlockedTime - currentTimeStamp);
                setUserBlocked(true);
            }
        }

        Events.addEventListener(PIN_BLOCKED, pinBlocked);
        return () => {
            Events.removeEventListener(PIN_BLOCKED, pinBlocked);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isDialogVisible) {
        return null;
    }

    return (
        <PinWrapper
            overlayInfo={overlayInfo}
            userBlocked={userBlocked}
            counter={counter}
            pin={pin}
            onKey={onKey}
            focusedIndex={focusedIndex}
            isKeyboardOpen={isKeyboardOpen}
            setIsKeyboardOpen={setIsKeyboardOpen}
            style={style}
            resetInputStates={resetInputStates}
            updateInputState={updateInputState}
            updateFocusedIndex={updateFocusedIndex}
        />
    );
};

export default PinOverlay;
