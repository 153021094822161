import React, { useEffect, useRef, useState } from 'react';

import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import Events, { SEARCH_HIDE_TOP_AREA, SEARCH_SHOW_TOP_AREA } from '__SMART_APP_OLD__/config/Events';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { NavKey } from '__SMART_APP_OLD__/utils/Constants';
import State from '__SMART_APP_OLD__/utils/State';

const SearchResults = ({ stripes, focusPlaced, focusOnRender, pageId, showSuggestions }) => {
    const containerRef = useRef(null);
    const stripeNodeRef = useRef(null);
    const firstStripeNodeKey = useRef(null);
    const [stripesData, setStripesData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const searchResultsState = State.hasState(NavKey.SEARCH_RESULTS) ? State.get(NavKey.SEARCH_RESULTS) : null;
    const { scrollTop } = searchResultsState || { scrollTop: 0 };

    const calculateAndSetTopValues = (focusedIndex, container, childNodes) => {
        let count = 0;
        if (focusedIndex === 0) {
            Events.triggerEvents(SEARCH_SHOW_TOP_AREA);
        } else {
            for (let i = 0; i < focusedIndex; i += 1) {
                const node = childNodes[i];
                const nodeStyle = window.getComputedStyle(node);
                const size = node.offsetHeight + parseInt(nodeStyle['margin-top'], 10);
                count += i !== focusedIndex - 1 ? size : size / 1.8;
            }
            Events.triggerEvents(SEARCH_HIDE_TOP_AREA);
        }

        container.scrollTop = count;
        State.set(NavKey.SEARCH_RESULTS, {
            focusedIndex,
            scrollTop: count,
        });
    };

    const calculateFocusedIndex = (focusedDom) => {
        let parent = focusedDom.nodeRef.parentNode;
        let parentDom = null;

        while (parent && parent !== document.body) {
            if (parent?.classList.contains('stripe')) {
                parentDom = parent;
                break;
            }
            parent = parent.parentNode;
        }

        if (!parentDom) {
            console.warn("Don't know where to focus!");
            return false;
        }

        const container = containerRef?.current?.nodeRef?.nodeRef;
        const childNodes = [];
        container?.childNodes.forEach((node) => childNodes.push(node));

        return { focusedIndex: childNodes.indexOf(parentDom), container, childNodes };
    };

    const onFocusChanged = (focusedDom) => {
        const { focusedIndex, container, childNodes } = calculateFocusedIndex(focusedDom);

        if (focusedIndex < 0) {
            console.warn("HTML Structure changed, can't resolve focus!");
            return false;
        }
        calculateAndSetTopValues(focusedIndex, container, childNodes);
        return true;
    };

    useEffect(() => {
        setStripesData(stripes);
        setActiveIndex(searchResultsState?.focusedIndex || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResultsState]);

    useEffect(() => {
        setStripesData(stripes);
    }, [stripes]);

    useEffect(() => {
        if (!focusPlaced && !showSuggestions) {
            Focus.focus(stripeNodeRef.current);
        }
    }, [focusPlaced, showSuggestions]);

    useEffect(() => {
        const container = containerRef?.current?.nodeRef?.nodeRef;

        if (activeIndex > 0 && container) {
            Events.triggerEvents(SEARCH_HIDE_TOP_AREA);
            container.scrollTop = scrollTop;
        } else {
            Events.triggerEvents(SEARCH_SHOW_TOP_AREA);
        }
    }, [activeIndex, scrollTop]);

    return (
        <Container
            className="search-stripes with-suggestions"
            onFocusChanged={onFocusChanged}
            ref={(ref) => {
                containerRef.current = ref;
            }}
            scrollTop={scrollTop}
        >
            {stripesData.map((stripe, index) => {
                const key = `search-stripe-${index}-${stripe.stripeId}`;
                return (
                    <Stripe
                        stripeId={stripe.stripeId}
                        className="stripe"
                        assets={stripe.assets}
                        stripeindex={index}
                        type={stripe.type}
                        title={stripe.title || ''}
                        key={key}
                        menuId={pageId}
                        ref={(ref) => {
                            if (ref && firstStripeNodeKey.current !== key && index === 0) {
                                const { nodeRef } = ref.nodeRef;
                                if (nodeRef.nodeRef.children) {
                                    const [firstChild] = nodeRef.nodeRef.children;
                                    stripeNodeRef.current = firstChild;
                                    firstStripeNodeKey.current = key;

                                    if (focusOnRender) {
                                        Focus.focus(stripeNodeRef.current);
                                    }
                                }
                            }
                        }}
                    />
                );
            })}
        </Container>
    );
};

export default SearchResults;
