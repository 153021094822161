import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { ReactComponent as SeekForwardIcon } from 'styles/assets/ico-fw.svg';
import { ReactComponent as NotSubscribedInfoIcon } from 'styles/assets/ico-info-notSubscribed.svg';
import { ReactComponent as ReminderIcon } from 'styles/assets/ico-reminder.svg';
import { ReactComponent as SeekBackwardIcon } from 'styles/assets/ico-rw.svg';

interface Props {
    icon: NotificationIconType | string;
}

export const NotificationIcon: React.FunctionComponent<Props> = (props) => {
    switch (props.icon) {
        case NotificationIconType.REMINDER:
            return <ReminderIcon className="notification-v2__icon" />;
        case NotificationIconType.SEEK_BACKWARD:
            return <SeekBackwardIcon className="notification-v2__icon" />;
        case NotificationIconType.SEEK_FORWARD:
            return <SeekForwardIcon className="notification-v2__icon" />;
        case NotificationIconType.MESSAGE:
            return <Icon className="notification-v2__icon" name={IconName.MESSAGING} flavour={IconFlavour.SOLID_DARK} />;
        case NotificationIconType.INFO:
            return <NotSubscribedInfoIcon className="notification-v2__icon" />;
        default:
            return <img className="notification-v2__icon" src={props.icon} />;
    }
};
