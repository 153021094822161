import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type QuotaFragment = { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number };

export const QuotaFragmentDoc = gql`
    fragment quota on Quota {
  kind
  allowed
  used
}
    `;