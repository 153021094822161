import { NetworkRecording as NetRecording } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { AssetType, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { calcRecordingStatus } from '__SMART_APP_OLD__/utils/events';
import { formatDateTime, getServerTime, isFuture } from '__SMART_APP_OLD__/utils/timeUtils';
import translate from 'language/translate';

import { ContentMarkerType } from 'App/Types/ContentMarker';

import LandscapeCard from './LandscapeCard';

export default class NetworkRecording extends LandscapeCard {
    assetType: string = AssetType.NETWORK_RECORDING;

    _isRecordable: boolean = true;

    _recordings: NetRecording[] = [];

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);
        this.rawData = data;
        this.recordings = data?.event?.personalEventInfo?.recordings ?? this.rawData?.personalInfo?.recordings ?? [];
    }

    get eventId() {
        return this.rawData.event.id;
    }

    get channelId() {
        return this.rawData?.channel?.id;
    }

    get recordingStatus(): GQL.RecordingStatus | undefined {
        const seriesStatus = this.rawData.status;
        if (seriesStatus) return seriesStatus;
        const isRecording = !!this.recordings?.length;
        if (!isRecording) return undefined;
        const status = this.recordings?.[0]?.status;
        return calcRecordingStatus(status, this.startTime.getTime(), this.endTime.getTime());
    }

    get isPlaybackAvailable() {
        return this.recordingStatus === GQL.RecordingStatus.Completed;
    }

    get detailPageMetaInfo() {
        const metaInfo = [];

        if (!this.channelLogo) {
            metaInfo.push(this.channelName);
        }
        metaInfo.push(this.shortMetaInfo);

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get isPartOfSeriesRecording(): boolean {
        return (
            ((this.recordings?.[0] as any)?.personalRecordingInfo?.partOfSeriesRecording ||
                this.personalRecordingInfo?.partOfSeriesRecording) ??
            false
        );
    }

    get liveEventMetaInfo() {
        const metaInfo: any = [];

        if (this.startTime) {
            metaInfo.push(this.getTimeStampInfo(formatDateTime(this.startTime, 'DD dd MMM')));
        }

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get duration() {
        return (this.endTime.getTime() - this.startTime.getTime()) / 1000;
    }

    get isRecordable(): boolean {
        return this._isRecordable;
    }

    set isRecordable(value: boolean) {
        this._isRecordable = value;
    }

    get recordings(): NetRecording[] {
        return this._recordings;
    }

    set recordings(value: NetRecording[]) {
        this._recordings = value;
    }

    get leadIn() {
        return this.rawData?.channel?.beforeTime || 0;
    }

    get leadOut() {
        return this.rawData?.channel?.afterTime || 0;
    }

    get progress() {
        let progress;

        // we need to include lead in and lead out values in case of catch up for calculation
        // progress value otherwise duration can be smaller than bookmark value
        if (this.bookmark) progress = ((this.bookmark?.position ?? 0) * 100) / (this.duration + this.leadIn + this.leadOut);
        else progress = 0;

        if (progress >= 100) {
            progress = 0;
        }

        return progress;
    }

    get endDateTime() {
        return this.endTime;
    }

    get endTime() {
        return new Date(this.rawData?.event?.end);
    }

    get favourited() {
        return this.personalRecordingInfo?.favourited;
    }

    get id() {
        return this.recordings?.[0]?.id || this.rawData?.id;
    }

    get isEntitled() {
        return this.rawData?.channel?.userInfo?.subscribed;
    }

    // used on mini epg: For Movies and Shows  → 'Year | Genre', For Series → 'S00E00 | Genre '
    // get metaInfo() {
    //     const metaInfo = [];

    //     if (this.seasonAndEpisode) {
    //         metaInfo.push(this.seasonAndEpisode);
    //     } else if (this.releaseYear) {
    //         metaInfo.push(this.releaseYear);
    //     }

    //     if (this.genres) {
    //         metaInfo.push(this.genres);
    //     }

    //     return metaInfo.join('&nbsp; I&nbsp; ');
    // }

    get metaInfoPlayer() {
        const metaInfo = [];

        if (this.seasonAndEpisode || this.episodeTitle) {
            metaInfo.push(`${this.seasonAndEpisode} ${this.episodeTitle}`);
        }

        if (this.shortMetaInfo) {
            metaInfo.push(this.shortMetaInfo);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get availableContentMarkers(): string[] {
        const currentDate = getServerTime();
        const currentTime = currentDate.getTime();

        const markers = [];
        const { programType } = this;

        if ((this.utcStartTime.getTime() <= currentTime && this.utcEndTime.getTime() > currentTime) || programType === ProgramType.LIVE) {
            markers.push(ContentMarkerType.Live);
        } else {
            markers.push(ContentMarkerType.Recorded);
        }

        return markers;
    }

    get ageRatingRank() {
        return this.rawData?.event?.parentalRating?.rank;
    }

    get parentalRating() {
        return this.rawData?.event?.parentalRating?.title;
    }

    get programType() {
        const currentDate = getServerTime();
        const currentTime = currentDate.getTime();

        if (this.utcStartTime.getTime() <= currentTime && this.utcEndTime.getTime() > currentTime) {
            return ProgramType.LIVE;
        }
        if (this.utcStartTime.getTime() > currentTime && this.utcEndTime.getTime() > currentTime) {
            return ProgramType.FUTURE;
        }
        return ProgramType.CATCHUP;
    }

    get shortMetaInfo() {
        return this.getTimeStampInfo();
    }

    get relatedMetaInfo() {
        return this.shortMetaInfo;
    }

    get startTime() {
        return new Date(this.rawData?.event?.start);
    }

    get startDateTime() {
        return this.startTime;
    }

    get isFuture() {
        return isFuture(this.rawData?.event?.start);
    }

    get catchupTV() {
        const { catchupTV, catchupTVAvailableUntil } = this.rawData?.event?.eventEntitlements || {};
        return catchupTV && isFuture(catchupTVAvailableUntil) && !this.isFuture;
    }

    get restartTV() {
        return this.rawData?.event?.eventEntitlements?.restartTV;
    }

    get isNetworkRecordingAvailable() {
        const { networkRecording, networkRecordingPlannableUntil } = this.rawData?.event?.eventEntitlements || {};
        return networkRecording && isFuture(networkRecordingPlannableUntil);
    }

    get isReplayAvailable() {
        return this.catchupTV || this.restartTV;
    }

    get image() {
        return this.rawData?.event?.thumbnail?.url;
    }

    get status() {
        return calcRecordingStatus(this.rawData?.status, this.startTime.getTime(), this.endTime.getTime());
    }

    get isRecording() {
        return this.assetType === AssetType.NETWORK_RECORDING;
    }

    get genres() {
        return this.rawData?.event?.eventMetadata?.genre?.title;
    }

    get title() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_TITLE');
        }
        return this.rawData?.event?.title;
    }

    get bookmark() {
        return Bookmarks.selectors.selectEntity(this.id)(store.getState());
    }

    get utcStartTime() {
        return getServerTime(this.rawData?.event?.start);
    }

    get utcEndTime() {
        return getServerTime(this.rawData?.event?.end);
    }

    get seasonAndEpisode() {
        const seasonAndEpisode = [];
        const season = this.seasonOrdinal;
        const episode = this.episodeOrdinal;

        if (season) {
            seasonAndEpisode.push(`${translate('HOVER_SEASON')}${season}`);
        }

        if (episode) {
            seasonAndEpisode.push(`${translate('HOVER_EPISODE')}${episode}`);
        }

        return seasonAndEpisode.join(' ');
    }

    get seasonOrdinal() {
        return this.rawData?.event?.eventMetadata?.episodeInfo?.season;
    }

    get episodeOrdinal() {
        return this.rawData?.event?.eventMetadata?.episodeInfo?.number;
    }

    get episodeTitle() {
        return this.rawData?.event?.eventMetadata?.episodeInfo?.title || '';
    }

    get landscapeImage() {
        return this.rawData?.event?.backgroundImage?.url;
    }

    get description() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_DESCRIPTION');
        }

        if (this.seasonAndEpisode) {
            const description = [];
            description.push(this.seasonAndEpisode);
            description.push(this.rawData?.event?.eventMetadata?.fullDescription);
            return description.join(' ');
        }
        return this.rawData?.event?.eventMetadata?.fullDescription;
    }

    get titlePlayer() {
        return this.rawData?.event?.title;
    }

    get availableUntil() {
        return this.rawData?.availableUntil;
    }

    get personalRecordingInfo() {
        return this.rawData?.personalNetworkRecordingInfo || this.rawData?.personalRecordingInfo;
    }
}
