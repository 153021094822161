import { BookmarksEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectIds = (state: State): string[] => state.data.bookmarks.ids;

const selectEntity =
    (id: string) =>
    (state: State): BookmarksEntity | null =>
        state.data.bookmarks.entities[id] ?? null;

export const bookmarksSelectors = {
    public: {
        selectIds,
        selectEntity,
    },
};
