import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type MessageActionFragment = { __typename: 'Action', id: string, uri: string, title: string };

export const MessageActionFragmentDoc = gql`
    fragment messageAction on Action {
  id
  uri
  title
  __typename
}
    `;