import React from 'react';

import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { vodUpsellActions } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/actions';
import { VodUpsellOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { Axis } from 'App/Types/Axis';

interface Props {
    options: Array<{
        id: string;
        label: string;
        videoQuality: string;
    }>;
    ctx?: string;
}

export const VodUpsellOptions: React.FunctionComponent<Props> = ({ options, ctx }) => {
    const context = ctx || FocusDefault;
    const dispatch = useDispatch();

    const { focusedIndex, focus } = useListHook({
        ctx: context,
        axis: Axis.Y,
        initialFocusedIndex: 0,
        items: options,
        resetFocusOnBlur: true,
        resetFocusIndex: () => 0,
    });

    const renderItem = (item: VodUpsellOption, index: number) => (
        <Button
            className="vod-upsell-overlay__button"
            width={ButtonWidth.FULL}
            id={item}
            key={item.id}
            ctx={context}
            isFocused={focusedIndex === index}
            onHover={focus}
            onEnter={() => dispatch(vodUpsellActions.public.vodUpsell(item.id))}
            icon={IconName[item.videoQuality as IconName]}
            corners={ButtonCornersStyle.ROUNDED}
        >
            {item.label}
        </Button>
    );

    return (
        <List
            className="vod-upsell-overlay__option-list"
            firstVisibleIndex={focusedIndex - 2}
            itemType={ListItemType.VOD_UPSELL_OPTION}
            items={options}
            renderer={renderItem}
        />
    );
};
