import { createBrowserHistory } from 'history';

import { History } from '__SMART_APP_OLD__/app/modules/History';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { classListReplace } from '__SMART_APP_OLD__/utils/Utils';

import { Route } from './Constants';

/**
 * @namespace Utils
 */

export const BrowserHistory = createBrowserHistory();

/**
 * @memberof Utils
 */
class CustomHistory {
    __timer = null;

    length = 0;

    stack = ['/'];

    isBackInProgress = false;

    constructor() {
        window.Dev = {};
        window.Dev.History = this;
        BrowserHistory.listen(this.handleHistoryChange);
    }

    /**
     * Moves the browser to the new path
     * @param path - the new path
     * @param data - history state
     * @memberof Utils
     * @function go
     */
    go(path, data = undefined) {
        BrowserHistory.push(path, data);
    }

    /**
     * Replaces the existing browser path
     * @param path - the new path
     * @param data - history state
     * @memberof Utils
     * @function replace
     */
    replace(path, data = undefined) {
        BrowserHistory.replace(path, data);
    }

    /**
     * Rewinds the history either by one (if count is not specified) or by count entries
     * @param count - the number of pages that we are going back to
     * @memberof Utils
     * @function back
     */
    back(count = 0) {
        if (this.isBackInProgress) {
            return;
        }
        if (this.stack.length === 0) return;
        this.isBackInProgress = true;

        const num = count || -1;
        BrowserHistory.go(num);
        clearTimeout(this.__timer);

        this.__timer = setTimeout(() => {
            this.handleHistoryChange(document.location, 'BACK');
            this.isBackInProgress = false;
        }, 50);
    }

    /**
     * Rewinds the history to the specified path
     * @param path
     * @memberof Utils
     * @function backTo
     */
    backTo(path = '') {
        const index = this.stack.indexOf(path);
        if (index === -1) {
            BrowserHistory.replace(`/page/${Route.HOME}`);
            return;
        }
        const step = this.stack.length - index - 1;
        this.stack.splice(index);
        BrowserHistory.go(step * -1);
    }

    /**
     * @description Method for handling the history change
     * @param location current location object
     * @param action action
     * @function handleHistoryChange
     * @memberof Utils
     */
    handleHistoryChange = (location, action) => {
        this.playerClassNameChangeHandler(location.pathname);

        const isBlurNeeded = !(
            action === 'REPLACE' &&
            location.pathname.includes('playChannel') &&
            this.stack[this.stack.length - 1].includes('playChannel')
        );

        switch (action) {
            case 'BACK':
                return;
            case 'PUSH':
                if (location.pathname !== '/login') {
                    this.stack.push(location.pathname);
                }
                break;
            case 'POP':
                if (this.stack[this.stack.length - 2] === '/redux') store.dispatch(History.actions.pop(true));
                this.stack.splice(this.stack.length - 1, 1);
                break;
            case 'REPLACE':
                this.stack[this.stack.length - 1] = location.pathname;
                break;
            default:
        }

        if (isBlurNeeded) {
            Focus.blur();
        }
    };

    /**
     * @description A function that changes the classNames (for background color)
     * @param pathName pathname
     */
    playerClassNameChangeHandler(pathName = '') {
        const player = document.getElementById('player');
        if (!player) {
            return;
        }
        if (pathName.includes('play')) {
            classListReplace(player.classList, 'default-background-color', 'black-background-color');
        } else {
            classListReplace(player.classList, 'black-background-color', 'default-background-color');
        }
        if (!pathName.includes('HOME')) {
            player.classList.remove('gallery-player');
        }
    }

    /**
     * Moves the browser to the new path. When called
     * from a Player, it replaces the old path with the new path.
     * @param path - the new path
     * @param data - history state
     * @memberof Utils
     * @function replaceOnPlayPage
     */
    replaceOnPlayPage(path, data = undefined) {
        const isOnPlayPage = document.location.pathname.includes('play');
        if (isOnPlayPage) {
            this.replace(path, data);
        } else {
            this.go(path, data);
        }
    }

    clear() {
        this.stack = [];
    }
}

export default new CustomHistory();
