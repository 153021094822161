import { selectSettingsItemsList } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectSelectedChannelListName } from "__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors";
import { communitySelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/selectors';
import { SettingsScreenOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';
import { startupOptionLabelForPosition } from '__SMART_APP_OLD__/app/modules/Startup/constants';
import { startSelectors } from '__SMART_APP_OLD__/app/modules/Startup/selectors';
import { themeOptionLabelForType } from '__SMART_APP_OLD__/app/modules/Theme/constants';
import { themeSelectors } from '__SMART_APP_OLD__/app/modules/Theme/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { SettingsItemType } from '__SMART_APP_OLD__/app/types';

export const selectSettingsItemsIds = (state: State): string[] => selectSettingsItemsList(state).map((item) => item.id);

export const selectSettingsItemTitle = (optionId: string) => (state: State): string | undefined =>
    selectSettingsItemsList(state).find((item) => item.id === optionId)?.title;

export const selectSettingsItemType = (optionId: string) => (state: State): SettingsItemType | undefined =>
    selectSettingsItemsList(state).find((item) => item.id === optionId)?.type;

export const selectSettingsItemOptionValue = (optionId: SettingsScreenOptionId) => (state: State) => {
    if (optionId === SettingsScreenOptionId.REGION) return communitySelectors.public.selectSelectedEntityTitle(state);
    if (optionId === SettingsScreenOptionId.APPEARANCE) return themeOptionLabelForType[themeSelectors.public.select(state)];
    if (optionId === SettingsScreenOptionId.STARTUP_BEHAVIOR) return startupOptionLabelForPosition[startSelectors.public.select(state)];
    if (optionId === SettingsScreenOptionId.CHANNEL_LINEUP) return selectSelectedChannelListName(state);

    return false;
}
