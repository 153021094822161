import { useEffect, useRef } from 'react';
import * as workerTimers from 'worker-timers';

const getRandInterval = (interval: number) => Math.floor(interval - (interval / 10) * Math.random() + (interval / 10) * Math.random());

export const usePooling = (cb: () => void, delay: number) => {
    const cbRef = useRef(cb);
    cbRef.current = cb;

    useEffect(() => {
        const jitteredIntervalDelay = getRandInterval(delay);
        const interval = workerTimers.setInterval(cbRef.current, jitteredIntervalDelay);
        return () => workerTimers.clearInterval(interval);
    }, [delay]);
};
