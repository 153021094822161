import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { initialEventEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/constants';
import { EventEntity, EventEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const prepare = (events: GQL.Event[], channelId: GQL.ChannelID, start: number, end: number): EventEntity[] => {
    const noInfoEvent = Mapper.Event.toNoInfoEventEntity(channelId, start, end);
    if (noInfoEvent && !events.length) return [noInfoEvent];
    return events.reduce<EventEntity[]>((acc, evt, idx) => {
        const event = Mapper.Event.toEntity(evt, channelId);
        const ts = idx === 0 ? start : acc[acc.length - 1].end;
        const noInfoAtStart = Mapper.Event.toNoInfoEventEntity(channelId, ts, event.start);
        if (ts < event.start && noInfoAtStart) acc.push(noInfoAtStart);
        acc.push(event);
        const noInfoAtEnd = Mapper.Event.toNoInfoEventEntity(channelId, event.end, end);
        if (idx === events.length - 1 && event.end < end && noInfoAtEnd) acc.push(noInfoAtEnd);
        return acc;
    }, []);
};

const getIndexes = (events: EventEntity[], start: number, end: number): [number, number] => {
    const startIndex = events[0].start > start ? 0 : events.findIndex((e) => e.start <= start && e.end > start);
    const endIndex = events[events.length - 1].end < end ? events.length - 1 : events.findIndex((e) => e.start < end && e.end >= end);
    return [startIndex, endIndex];
};

const generateMergeGap = (a: EventEntity, b: EventEntity, ch: GQL.ChannelID) => {
    switch (true) {
        case a.id === b.id:
            return [b];
        case a.isNoInfo && b.isNoInfo:
            return [Mapper.Event.toNoInfoEventEntity(ch, a.start, b.end)];
        case a.isNoInfo && b.isLoading:
            return [a, Mapper.Event.toLoadingEventEntity(ch, a.end, b.end)];
        case a.isNoInfo && !b.isNoInfo && !b.isLoading:
            return [Mapper.Event.toNoInfoEventEntity(ch, a.start, b.start), b];
        case a.isLoading && b.isNoInfo:
            return [Mapper.Event.toLoadingEventEntity(ch, a.start, b.start), b];
        case a.isLoading && b.isLoading:
            return [Mapper.Event.toLoadingEventEntity(ch, a.start, b.end)];
        case a.isLoading && !b.isNoInfo && !b.isLoading:
            return [Mapper.Event.toLoadingEventEntity(ch, a.start, b.start), b];
        case !a.isNoInfo && !a.isLoading && b.isNoInfo:
            return [a, Mapper.Event.toNoInfoEventEntity(ch, a.end, b.end)];
        case !a.isNoInfo && !a.isLoading && b.isLoading:
            return [a, Mapper.Event.toLoadingEventEntity(ch, a.end, b.end)];
        case !a.isNoInfo && !a.isLoading && !b.isNoInfo && !b.isLoading:
            return [a, b];
        default:
            return [a, b];
    }
};

const getValidMergeGap = (a: EventEntity, b: EventEntity, ch: GQL.ChannelID) =>
    generateMergeGap(a, b, ch).filter((e): e is EventEntity => !!e);

const getMergeGapFromArray = (entities: EventEntity[], ch: GQL.ChannelID) =>
    entities.slice(1).reduce((acc, ent) => acc.slice(0, -1).concat(getValidMergeGap(acc[acc.length - 1], ent, ch)), [entities[0]]);

const merge = (events: EventEntity[], toMerge: EventEntity[], channelId: GQL.ChannelID): EventEntity[] => {
    if (!toMerge?.length) return events;
    const [startIndex, endIndex] = getIndexes(events, toMerge[0].start, toMerge[toMerge.length - 1].end);
    return [
        ...events.slice(0, startIndex),
        ...getMergeGapFromArray([events[startIndex], ...toMerge, events[endIndex]], channelId),
        ...events.slice(endIndex + 1),
    ];
};

export const eventEntityTableReducer: Reducer<EventEntityTable> = (state, action) => {
    if (!state) return initialEventEntityTableState;
    switch (action.type) {
        case ActionType.EVENT_LOAD_MANY: {
            const result = Object.keys(action.payload.events).reduce<{
                ids: Record<GQL.ChannelID, GQL.EventID[]>;
                ent: Record<GQL.EventID, EventEntity>;
                rmv: GQL.EventID[];
            }>(
                (acc, ch) => {
                    if (!state.channelEventRelationOrderTable[ch]) return acc;
                    const payload = prepare(action.payload.events[ch], ch, action.payload.start, action.payload.end);
                    const current = state.channelEventRelationOrderTable[ch].map((id) => state.entities[id]);
                    const merged = merge(current, payload, ch);
                    const ids = Mapper.Common.toIds(merged);
                    acc.ids[ch] = ids;
                    acc.ent = { ...acc.ent, ...Mapper.Common.toEntities(merged) };
                    acc.rmv.push(...state.channelEventRelationOrderTable[ch].filter((id) => !ids.includes(id)));
                    return acc;
                },
                { ids: {}, ent: {}, rmv: [] }
            );

            const entities = Mapper.Common.toRemovedEntities({ ...state.entities, ...result.ent }, result.rmv);
            const channelEventRelationOrderTable = { ...state.channelEventRelationOrderTable, ...result.ids };
            return { entities, channelEventRelationOrderTable };
        }
        case ActionType.EVENT_INIT: {
            return action.payload.channels.reduce<EventEntityTable>(
                (acc, ch) => {
                    const events = Mapper.Event.toPlaceholderInitState(ch, action.payload.start, action.payload.end);
                    acc.entities = { ...acc.entities, ...Mapper.Common.toEntities(events) };
                    acc.channelEventRelationOrderTable = { ...acc.channelEventRelationOrderTable, [ch]: Mapper.Common.toIds(events) };
                    return acc;
                },
                { ...initialEventEntityTableState }
            );
        }
        default:
            return state;
    }
};
