import '__SMART_APP_OLD__/api/Tuner';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Route } from '__SMART_APP_OLD__/utils/Constants';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { Platform } from '__SMART_APP_OLD__/app/platform';
import { TransportType } from '__SMART_APP_OLD__/app/platform/types';
import { initializeApp, startApp } from '__SMART_APP_OLD__/app/store/actions/data/init/init.actions';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { CLEAR_SEARCH_HISTORY, INTERNET_CONNECTION_OFF, INTERNET_CONNECTION_ON } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { App as AppComponent } from '__SMART_APP_OLD__/routing/Routes';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import CustomHistory, { BrowserHistory } from '__SMART_APP_OLD__/utils/CustomHistory';
import { NetworkEvents } from 'analytics/logging/events/NetworkEvent';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getNetworkEvent } from 'analytics/logging/factories/networkEventFactory';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { Platform as DevicePlatform } from 'App/Platform';
// we know about it is expected as webpack generate the folder
// eslint-disable-next-line import/no-unresolved
import 'Style';
import { Prompt } from 'App/Modules/Prompt';
import { Alert } from 'App/Modules/Alert';
import { AlertType } from 'App/Modules/Alert/Types';
import { Screen } from 'App/Modules/Screen';

let blockBackListener: boolean = false;

const backExceptions = ['/splash', '/login', '/login/fti', '/', '/redux'];

Focus.addEventListener('back', () => {
    if (backExceptions.indexOf(document.location.pathname) > -1 || blockBackListener) {
        return true;
    }

    // triggerEvents use setTimeout. Block back trigger for the timeout time
    blockBackListener = true;
    setTimeout(() => {
        blockBackListener = false;
    }, 100);

    if (document.location.pathname.indexOf(`/page/${Route.HOME}`) === 0) {
        if (CustomHistory.stack.length < 3 && Env.isBusiness) {
            return true;
        }
        if (CustomHistory.stack.length < 3) {
            store.dispatch(Prompt.actions.mount(Prompt.Type.AppExitPrompt, {}));
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.EXIT));
            return true;
        }
    }

    if (document.location.pathname.includes('search') && !document.location.pathname.includes('collection')) {
        CustomHistory.back();
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        Events.triggerEvents(CLEAR_SEARCH_HISTORY);
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.CLEAR_SEARCH_HISTORY));

        return true;
    }

    // will be needed when video is started on app start
    if (document.location.pathname.indexOf(`/play`) === 0) {
        if (CustomHistory.stack.length >= 2) {
            const prevPath = CustomHistory.stack[CustomHistory.stack.length - 2];
            if (backExceptions.indexOf(prevPath) > -1 && prevPath !== '/redux') {
                CustomHistory.go('/');
                return true;
            }
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
            CustomHistory.back();
            return true;
        }
        return true;
    }

    if (document.location.pathname.includes('details')) {
        if (CustomHistory.stack.length >= 2) {
            const prevPath = CustomHistory.stack[CustomHistory.stack.length - 2];
            const hasTVGuide = History.selectors.select(store.getState()).some((entity) => entity.screen?.type === Screen.Type.TV_GUIDE);
            if (prevPath === '/redux' && !hasTVGuide) {
                CustomHistory.backTo(CustomHistory.stack[CustomHistory.stack.length - 3]);
                return true;
            }
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
            CustomHistory.back();
            return true;
        }
        return true;
    }

    if (document.location.pathname.indexOf(`/page/${Route.HOME}`) === -1 && CustomHistory.stack.length === 0) {
        CustomHistory.go(`/page/${Route.HOME}`);
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        return true;
    }

    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
    CustomHistory.back();
    return true;
});

enum ReportTypes {
    Crash = 'crash',
}

Sentry.init({
    dsn: 'https://2419b7ae02414fd0a7992bb6b11dcd9d@o582167.ingest.sentry.io/5810454',
    ignoreErrors: [
        'Proxy is not defined',
        'FATAL Clpp-Error [Category 7 - Code 7000]',
        'FATAL Clpp-Error [Category 1 - Code 1002]',
        'FATAL Clpp-Error [Category 1 - Code 1001]',
        'FATAL Clpp-Error [Category 1 - Code 1003]',
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: Env.IsSentryActivated ? 0.1 : 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: Env.IsSentryActivated ? 1.0 : 0,
    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: false,
        }),
        Sentry.browserApiErrorsIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history: BrowserHistory }),
        Sentry.reportingObserverIntegration({ types: [ReportTypes.Crash] }),
        Sentry.extraErrorDataIntegration({
            // Defaults to 3.
            depth: 3,
        }),
        Sentry.dedupeIntegration(),
        Sentry.breadcrumbsIntegration({
            console: false,
            fetch: false,
            sentry: false,
        }),
    ],
    beforeSend(event) {
        const rejectedErrorsPattern =
            /\[Conviva\]|NotAllowedError|\[graphQL\]|\[player\]|\[LOGIN ERROR\]|Warning:|FATAL Clpp-Error|Clpp-Error|Proxy is not defined|Symbol(Symbol.iterator)/gim;
        const shouldErrorBeRejected = rejectedErrorsPattern.test(event.message ?? '');
        const rejectEvent = event.exception?.values?.some((v) => rejectedErrorsPattern.test(v?.value ?? ''));

        if (!Env.IsSentryActivated) return null;
        if (shouldErrorBeRejected || rejectEvent) return null;

        return event;
    },
    // @ts-ignore
    release: `web-smarttv@${process.env.REACT_APP_VERSION}-${Env.Opco}-${Env.Environment}`,
    environment: `${Env.Opco}-${Env.Environment}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    attachStacktrace: true,
});

Platform.onNetworkStateChange = (state, prevState) => {
    if (state.transport === TransportType.OFFLINE && prevState.transport !== TransportType.OFFLINE) {
        store.dispatch(Alert.actions.mount(AlertType.InternetConnectionLost, {}));
        Events.triggerEvents(INTERNET_CONNECTION_OFF);
        LoggingService.getInstance().logEvent(getNetworkEvent(NetworkEvents.DISCONNECT, state));
    }
    if (state.transport !== TransportType.OFFLINE && prevState.transport === TransportType.OFFLINE) {
        store.dispatch(Alert.actions.unmount());
        Events.triggerEvents(INTERNET_CONNECTION_ON);
        LoggingService.getInstance().logEvent(getNetworkEvent(NetworkEvents.CONNECT, state));
    }
    if (state.ipAddress !== prevState.ipAddress) {
        LoggingService.getInstance().logEvent(getNetworkEvent(NetworkEvents.SWITCH_IP, state));
    }
    if (state.transport !== prevState.transport) {
        LoggingService.getInstance().logEvent(getNetworkEvent(NetworkEvents.SWITCH_TRANSPORT, state));
    }
};

export const main = async () => {
    try {
        await DevicePlatform.initialize();
        await store.dispatch(initializeApp());
        const container = document.getElementById('root');
        const root = createRoot(container!);
        root.render(<AppComponent />);
        await store.dispatch(startApp());
    } catch (error) {
        console.log('[APP_START]: ', error);
    }
};

main();
