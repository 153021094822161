import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelListChannelsEdgeFragment = { __typename: 'ChannelListChannelsEdge', id: string, channelListNumber: number, node: { __typename?: 'Channel', id: string } };

export const ChannelListChannelsEdgeFragmentDoc = gql`
    fragment channelListChannelsEdge on ChannelListChannelsEdge {
  id
  channelListNumber
  node {
    id
  }
  __typename
}
    `;