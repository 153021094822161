export enum ContentTypes {
    CHANNEL = 'Channel',
    CHANNEL_LIST = 'ChannelList',
    PROGRAM = 'Program',
    ASSET = 'Asset',
    RECORDING = 'Recording',
    TV_SERIES = 'TV Series',
    TV_SEASON = 'TV Season',
    VOD_SERIES = 'VOD Series',
    VOD_SEASON = 'VOD Season',
    USER_MEDIA = 'User Media',
    TRAILER = 'Trailer',
}

export interface ContentRefParams {
    contentId: string;
    contentName: string;
    contentType: ContentTypes;
    channelId?: string;
    channelName?: string;
    originalTitle?: string;
    episodeId?: string;
    episodeName?: string;
    episodeSequenceName?: string;
    seasonSequenceName?: string;
    seriesId?: string;
    genreName?: string;
}

/**
 * @class ContentRef
 */
export class ContentRef {
    private readonly contentId: string;

    private readonly contentName: string;

    private readonly contentType: ContentTypes;

    private channelId?: string;

    private channelName?: string;

    private originalTitle?: string;

    private episodeId?: string;

    private episodeName?: string;

    private episodeSequenceName?: string;

    private seasonSequenceName?: string;

    private seriesId?: string;

    private genreName?: string;

    /**
     * @param constructor constructor parameters
     * @param constructor.contentId
     * @param constructor.contentName
     * @param constructor.contentType
     * @param constructor.channelId
     * @param constructor.channelName
     * @param constructor.originalTitle
     * @param constructor.episodeId
     * @param constructor.episodeName
     * @param constructor.episodeSequenceName
     * @param constructor.seasonSequenceName
     * @param constructor.seriesId
     * @param constructor.genreName
     */
    constructor({
        contentId,
        contentName,
        contentType,
        channelId,
        channelName,
        originalTitle,
        episodeId,
        episodeName,
        episodeSequenceName,
        seasonSequenceName,
        seriesId,
        genreName,
    }: ContentRefParams) {
        this.contentId = contentId;
        this.contentName = contentName || contentId;
        this.contentType = contentType;

        if (channelId) this.channelId = channelId;
        else delete this.channelId;

        if (channelName) this.channelName = channelName;
        else delete this.channelName;

        if (originalTitle) this.originalTitle = originalTitle;
        else delete this.originalTitle;

        if (episodeId) this.episodeId = episodeId;
        else delete this.episodeId;

        if (episodeName) this.episodeName = episodeName;
        else delete this.episodeName;

        if (episodeSequenceName) this.episodeSequenceName = episodeSequenceName;
        else delete this.episodeSequenceName;

        if (seasonSequenceName) this.seasonSequenceName = seasonSequenceName;
        else delete this.seasonSequenceName;

        if (seriesId) this.seriesId = seriesId;
        else delete this.seriesId;

        if (genreName) this.genreName = genreName;
        else delete this.genreName;
    }
}
