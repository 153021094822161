import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectMessageDate,
    selectMessageHeader,
    selectMessageText,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import { MessageInboxItemLabels } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItemLabels';

interface Props {
    id: GQL.MessageID;
}

export const MessageInboxItemContentComponent: React.FunctionComponent<Props> = (props) => {
    const header = useSelector(selectMessageHeader(props.id));
    const date = useSelector(selectMessageDate(props.id));
    const content = useSelector(selectMessageText(props.id));

    return (
        <>
            <div className="message-inbox-item__header-container">
                <Text
                    className="message-inbox-item__header"
                    typeface={Typeface.SERIF}
                    size={FontSize.TITLE_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {header}
                </Text>
                <MessageInboxItemLabels id={props.id} />
                <Text
                    className="message-inbox-item__date"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {date}
                </Text>
            </div>
            <Text
                className="message-inbox-item__text-content"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                {content}
            </Text>
        </>
    );
};

export const MessageInboxItemContent = React.memo(MessageInboxItemContentComponent);
