import { initialRecordingDetailReducerState } from 'App/Modules/Data/Detail/NetworkRecording/Store/initialState';
import { DetailRecordingState, RecordingActionTypes } from 'App/Modules/Data/Detail/NetworkRecording/Types/Types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const detailRecordingReducer: Reducer<DetailRecordingState> = (state, action) => {
    if (!state) return initialRecordingDetailReducerState;

    switch (action.type) {
        case RecordingActionTypes.Load: {
            return {
                item: action.payload.item,
                assetObject: action.payload.assetObject,
                folders: action.payload.folders,
            };
        }
        case RecordingActionTypes.Reset:
            return initialRecordingDetailReducerState;
        default:
            return state;
    }
};
