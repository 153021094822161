import { useEffect } from 'react';

import { Function } from 'App/Packages/Function';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { Folder } from 'App/Packages/UI/Folder/Modules/Folder';
import { FolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';

export type UseFolderItemFocused = (item: string) => Subscribable.Value<boolean>;

export const useFolderItemFocused: UseFolderItemFocused = (item: string) => {
    const folder = Folder.use();
    const folderList = FolderList.use();
    const folderItems = FolderItems.use();
    const focused = Subscribable.useValue(false);

    useEffect(() => {
        const isFocused = () => !folderList.disabled.get() && folderList.focused.get() === folder.id && folderItems.focused.get() === item;
        const update = async () => {
            if (!isFocused()) return focused.set(false);
            await Function.sleep(Constants.Item.Focus.Delay);
            focused.set(isFocused());
            return undefined;
        };

        update();

        const unsubscribers = [
            folderList.focused.subscribe(update),
            folderList.disabled.subscribe(update),
            folderItems.focused.subscribe(update),
        ];
        return () => Function.call(...unsubscribers);
    }, [item, focused, folder, folderList, folderItems]);

    return focused;
};
