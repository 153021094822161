import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ChangeProfilePreferencesDocument,
    ChangeProfilePreferencesMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/changeProfilePreferences.generated';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export const change = async (profileId: string, value: boolean) => {
    const variables: ChangeProfilePreferencesMutationVariables = { input: { profileId, trackViewingBehaviour: value } };
    const response = await GraphqlClient.makeGraphqlMutationRequest(ChangeProfilePreferencesDocument, variables);
    Error.collect(response);
};
