import { Constants } from 'App/Packages/UI/Detail/Constants';
import { Image, ObjectFit, Orientation } from 'App/Packages/UI/Image';
import { Mask } from 'App/Packages/UI/Mask';
import React from 'react';

const getClassName = (className?: string) => {
    const classList = ['detail__background'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type DetailBackgroundProps = {
    src: string;
    className?: string;
};

export type DetailBackgroundComponent = React.FunctionComponent<DetailBackgroundProps>;

export const DetailBackground: DetailBackgroundComponent = (props) => {
    return (
        <Mask>
            {(shouldMask) => (
                <Image className={getClassName(props.className)}>
                    <Image.Loading hidden={shouldMask} />
                    <Image.Cover hidden={shouldMask} />
                    <Image.Adult orientation={Orientation.Landscape} hidden={!shouldMask} />
                    <Image.Img
                        src={props.src}
                        objectFit={ObjectFit.Cover}
                        width={Constants.Background.Width}
                        height={Constants.Background.Height}
                        hidden={shouldMask}
                    />
                </Image>
            )}
        </Mask>
    );
};
