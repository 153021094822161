import { App } from 'App/Modules/App';
import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Alert } from 'App/Modules/Alert';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { logoutAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import translate from 'language/translate';

const actionsIds = [Translation.Key.Retry, Translation.Key.QuitApp];

export const View: React.FunctionComponent<Alert.Props<Alert.Type.BackEndDoesntWork>> = () => {
    const dispatch = useDispatch();
    return (
        <UI.Alert zIndex={Alert.zIndex} onClose={() => dispatch(Alert.actions.unmount())}>
            <UI.Alert.Title>SCREEN_ALERT_NO_BE_DATA_TITLE</UI.Alert.Title>
            <UI.Alert.Text>SCREEN_ALERT_NO_BE_DATA_DESCRIPTION</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action id={Translation.Key.Retry} onEnter={() => store.dispatch(logoutAction())}>
                    {translate(Translation.Key.Ok)}
                </UI.Alert.Action>
                <UI.Alert.Action
                    id={Translation.Key.QuitApp}
                    onEnter={() => {
                        App.close();
                    }}
                >
                    {translate(Translation.Key.QuitApp)}
                </UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
