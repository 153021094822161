import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { MessageFragmentDoc } from '../fragments/message.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageLoadOneQueryVariables = Types.Exact<{
  messageId: Types.Scalars['ID']['input'];
  messageBackgroundWidth: Types.Scalars['Int']['input'];
  messageBackgroundHeight: Types.Scalars['Int']['input'];
  messageIconWidth: Types.Scalars['Int']['input'];
  messageIconHeight: Types.Scalars['Int']['input'];
  messageAttachmentImageWidth: Types.Scalars['Int']['input'];
  messageAttachmentImageHeight: Types.Scalars['Int']['input'];
}>;


export type MessageLoadOneQuery = { __typename?: 'Nexx4Queries', message: { __typename?: 'Message', id: string, title: string, message: string, label: string, displayKind: Types.MessageDisplayKind, status: Types.MessageStatus, timestamp: any, validFrom: any, validUntil: any, icon?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, actions?: { __typename?: 'ActionCatalog', items: Array<{ __typename: 'Action', id: string, uri: string, title: string } | null> } | null, notificationInfo?: { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null } | null, attachments?: { __typename?: 'MessageAttachmentCatalog', items: Array<{ __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null } | { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } } | null> } | null } };

export type MessageLoadOneFragment = { __typename?: 'Nexx4Queries', message: { __typename?: 'Message', id: string, title: string, message: string, label: string, displayKind: Types.MessageDisplayKind, status: Types.MessageStatus, timestamp: any, validFrom: any, validUntil: any, icon?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, actions?: { __typename?: 'ActionCatalog', items: Array<{ __typename: 'Action', id: string, uri: string, title: string } | null> } | null, notificationInfo?: { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null } | null, attachments?: { __typename?: 'MessageAttachmentCatalog', items: Array<{ __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null } | { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } } | null> } | null } };

export const MessageLoadOneFragmentDoc = gql`
    fragment messageLoadOne on Nexx4Queries {
  message(messageId: $messageId) {
    ...message
  }
}
    ${MessageFragmentDoc}`;
export const MessageLoadOneDocument = gql`
    query messageLoadOne($messageId: ID!, $messageBackgroundWidth: Int!, $messageBackgroundHeight: Int!, $messageIconWidth: Int!, $messageIconHeight: Int!, $messageAttachmentImageWidth: Int!, $messageAttachmentImageHeight: Int!) {
  ...messageLoadOne
}
    ${MessageLoadOneFragmentDoc}`;

/**
 * __useMessageLoadOneQuery__
 *
 * To run a query within a React component, call `useMessageLoadOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageLoadOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageLoadOneQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      messageBackgroundWidth: // value for 'messageBackgroundWidth'
 *      messageBackgroundHeight: // value for 'messageBackgroundHeight'
 *      messageIconWidth: // value for 'messageIconWidth'
 *      messageIconHeight: // value for 'messageIconHeight'
 *      messageAttachmentImageWidth: // value for 'messageAttachmentImageWidth'
 *      messageAttachmentImageHeight: // value for 'messageAttachmentImageHeight'
 *   },
 * });
 */
export function useMessageLoadOneQuery(baseOptions: Apollo.QueryHookOptions<MessageLoadOneQuery, MessageLoadOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageLoadOneQuery, MessageLoadOneQueryVariables>(MessageLoadOneDocument, options);
      }
export function useMessageLoadOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageLoadOneQuery, MessageLoadOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageLoadOneQuery, MessageLoadOneQueryVariables>(MessageLoadOneDocument, options);
        }
export function useMessageLoadOneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageLoadOneQuery, MessageLoadOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageLoadOneQuery, MessageLoadOneQueryVariables>(MessageLoadOneDocument, options);
        }
export type MessageLoadOneQueryHookResult = ReturnType<typeof useMessageLoadOneQuery>;
export type MessageLoadOneLazyQueryHookResult = ReturnType<typeof useMessageLoadOneLazyQuery>;
export type MessageLoadOneSuspenseQueryHookResult = ReturnType<typeof useMessageLoadOneSuspenseQuery>;
export type MessageLoadOneQueryResult = Apollo.QueryResult<MessageLoadOneQuery, MessageLoadOneQueryVariables>;