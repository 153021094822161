import { Data } from 'App/Modules/Data';
import { Input, InputElement } from 'App/Modules/Input';
import { Key } from 'App/Modules/Key';
import { Screen } from 'App/Modules/Screen';
import { Constants } from 'App/Modules/Screen/Modules/Search/Constants';
import { selectors } from 'App/Modules/Screen/Modules/Search/Store/Selectors';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import React, { useRef } from 'react';

export const SearchField: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const initValue = useSelector(selectors.private.selectQuery);
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const element = useRef<InputElement | null>(null);
    const { isFocused, onHover } = Focus.useElement({ ctx: Constants.Ctx.Field });

    const focus = Focus.useFocusable({
        id: Constants.Ctx.Field,
        ctx: Constants.Ctx.Field,
        isFocused,
        onHover,
        onFocus: () => element.current?.focus(),
        onBlur: () => element.current?.blur(),
    });

    const getClassName = (focused?: boolean) => {
        const classList = ['search__field'];
        if (focused) classList.push('search__field--focused');
        return classList.join(' ');
    };

    return (
        <Input
            ref={element}
            className={getClassName(isFocused)}
            block="search-field"
            value={initValue}
            placeholder="SCREEN_SEARCH"
            onClick={focus.onClick}
            onHover={focus.onHover}
            onChange={(target) => {
                const value = target.value.get();
                dispatch(Data.Search.Suggestion.actions.load(value));
                if (timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(async () => {
                    timeout.current = null;
                    dispatch(Data.Search.Result.actions.load({ query: value }));
                }, 1500);
            }}
            onBlur={async () => {
                await dispatch(Screen.actions.update(Screen.Type.Search, { query: element.current?.value.get() }));
                if (timeout.current) {
                    clearTimeout(timeout.current);
                    timeout.current = null;
                    await dispatch(Data.Search.Result.actions.load({ query: element.current?.value.get() }));
                }
                await Promise.resolve(); // Make sure that keyboard has closed before emitting key event
                await Focus.KeyDownEngine.getInstance().emit(new Focus.KeyDownEvent(Key.VK_DOWN));
            }}
        />
    );
};
