import React from 'react';

import { HeaderItemsContext, HeaderItemsContextModule } from 'App/Packages/UI/Header/Modules/HeaderItems/Context/HeaderItemsContext';
import { UseHeaderItems, useHeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems/Hooks/useHeaderItems';
import { UseHeaderItemsValue, useHeaderItemsValue } from 'App/Packages/UI/Header/Modules/HeaderItems/Hooks/useHeaderItemsValue';

export type HeaderItemsProps = React.PropsWithChildren<{
    ctx?: string;
    active: string;
    initial?: string;
    disabled?: boolean;
    onEnter?: (id: string) => void;
}>;

export type HeaderItemsModules = {
    // Context
    Context: HeaderItemsContextModule;

    // Hooks
    use: UseHeaderItems;
    useValue: UseHeaderItemsValue;
};

export type HeaderItemsModule = React.FunctionComponent<HeaderItemsProps> & HeaderItemsModules;

export const HeaderItems: HeaderItemsModule = (props) => {
    const items = HeaderItems.useValue({
        ctx: props.ctx,
        active: props.active,
        initial: props.initial,
        disabled: props.disabled,
        enter: props.onEnter,
    });

    return <HeaderItems.Context.Provider value={items}>{props.children}</HeaderItems.Context.Provider>;
};

// Context
HeaderItems.Context = HeaderItemsContext;

// Hooks
HeaderItems.use = useHeaderItems;
HeaderItems.useValue = useHeaderItemsValue;
