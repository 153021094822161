import { Subscribable } from 'App/Packages/Subscribable';
import React from 'react';

export type DetailActionValue = {
    id: string;
    ctx: string;
    focused: Subscribable.Value<boolean>;
    focus: () => void;
    enter: () => void;
};

export const DetailActionContext = React.createContext({} as DetailActionValue);
