import { Env } from 'App/Env';
import { Austria } from 'App/Platform/Opco/Austria';
import { Bulgaria } from 'App/Platform/Opco/Bulgaria';
import { Croatia } from 'App/Platform/Opco/Croatia';
import { Macedonia } from 'App/Platform/Opco/Macedonia';
import { Serbia } from 'App/Platform/Opco/Serbia';
import { Slovenia } from 'App/Platform/Opco/Slovenia';

export class Opco {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Opco.Initialize]');
        if (Env.IsAustria) return Austria.initialize();
        if (Env.IsBulgaria) return Bulgaria.initialize();
        if (Env.IsCroatia) return Croatia.initialize();
        if (Env.IsMacedonia) return Macedonia.initialize();
        if (Env.IsSerbia) return Serbia.initialize();
        if (Env.IsSlovenia) return Slovenia.initialize();
        return false;
    }
}
