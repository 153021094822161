import React from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';

const Default: FolderItemValue = {
    id: '',
    ctx: Constants.Ctx,
    ref: { current: null },
    focused: new Subscribable.MockedValue(false),
    enter: () => {},
    focus: () => {},
    scrollIntoView: Object.assign(async () => {}, { instant: new Subscribable.MockedValue(false) }),
};

export type FolderItemValue = {
    id: string;
    ctx: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
    focused: Subscribable.Value<boolean>;
    enter: () => void;
    focus: () => void;
    scrollIntoView: (() => Promise<void>) & { instant: Subscribable.Value<boolean> };
};

export type FolderItemContextModule = React.Context<FolderItemValue> & { Default: FolderItemValue };

export const FolderItemContext: FolderItemContextModule = Object.assign(React.createContext(Default), { Default });
