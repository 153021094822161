import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type PageInfoFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null };

export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  hasNextPage
  endCursor
}
    `;