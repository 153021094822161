import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { EventConcepts } from 'analytics/types/concepts';

import { Layer } from '../classes/Layer';
import { SessionTypes, getLoggingSessions } from '../LoggingSession';
import { generateUuid } from '../loggingUtils';

export enum PlaybackEvents {
    BUFFER = 'buffer',
    PLAY = 'play',
    PAUSE = 'pause',
}

/**
 * @class PlaybackEvent
 */
export class PlaybackEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.PLAYBACK;

    private readonly linkedSessionId: string;

    private rate?: number;

    /**
     *
     * @param event
     * @param delay
     * @param position
     * @param layer
     * @param videoServerSessionId
     */
    constructor(
        private readonly event: PlaybackEvents,
        private delay?: number,
        private position?: number,
        private layer?: Layer,
        private videoServerSessionId?: string
    ) {
        super(generateUuid());
        const loggingSessions = getLoggingSessions();

        this.event = event;
        this.linkedSessionId = loggingSessions[SessionTypes.VIEW] || '';

        if (delay || delay === 0) this.delay = delay;
        else delete this.delay;

        if (position || position === 0) this.position = position;
        else delete this.position;

        if (layer) this.layer = layer;
        else delete this.layer;

        if (videoServerSessionId) this.videoServerSessionId = videoServerSessionId;
        else delete this.videoServerSessionId;

        if (event === PlaybackEvents.PLAY) this.rate = 1.0;
        else delete this.rate;
    }
}
