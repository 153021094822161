export enum ProductTypes {
    BUNDLE = 'Bundle',
    CHANNEL = 'channel',
    VOD = 'VOD',
    FEATURE = 'Feature',
}

export enum PaymentTypes {
    RENTAL = 'one-off',
    PURCHASE = 'one-off',
    SUBSCRIPTION = 'recurring',
    SUBSCRIPTION_UPSELLABLE = 'recurring',
    FREE = 'free',
}

export interface ProductParams {
    productId: string;
    productName: string;
    productType: ProductTypes;
    paymentType: PaymentTypes;
    price: Price;
}

export interface Price {
    currency: string;
    amount: number;
}

/**
 * @class Product
 */
export class Product {
    private readonly productId: string;

    private readonly productName: string;

    private readonly productType: ProductTypes;

    private readonly paymentType: PaymentTypes;

    private readonly price: Price;

    /**
     * @param constructor constructor parameters
     * @param constructor.productId
     * @param constructor.productName
     * @param constructor.productType
     * @param constructor.paymentType
     * @param constructor.price
     */
    constructor({ productId, productName, productType, paymentType, price }: ProductParams) {
        this.productId = productId;
        this.productName = productName;
        this.productType = productType;
        this.paymentType = paymentType;
        this.price = price && {
            amount: price.amount * 10000,
            currency: price.currency,
        };
    }
}
