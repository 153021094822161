import { Calc } from 'App/Packages/Calc';
import { Axis } from 'App/Types/Axis';

export const Constants = {
    Actions: {
        Axis: Axis.X,
    },
    Action: {
        Focus: {
            Delay: 25,
        },
        ShowMore: {
            Ctx: 'ShowMoreCtx',
        },
    },
    Background: {
        Width: Calc.pixel(1440),
        Height: Calc.pixel(807),
    },
} as const;
