import { FetchPolicy } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { useGetSearchRowsQuery } from '__SMART_APP_OLD__/api/graphql/query/getSearchRows.generated';
import { useGetTrendingSearchesQuery } from '__SMART_APP_OLD__/api/graphql/query/getTrendingSearches.generated';
import { useSearchLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/search.generated';
import { useSearchKeywordsLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/searchKeywords.generated';
import { SearchContentType } from '__SMART_APP_OLD__/api/graphql/types';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Search from '__SMART_APP_OLD__/components/search/Search';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import { PageDataContext } from '__SMART_APP_OLD__/providers/usePageContext';

const searchVariables = {
    variables: {
        keyword: '',
        context: {
            profileId: '',
            orderContentType: SearchContentType.Events,
        },
        filter: {},
        profileId: '',
        firstFolders: 5,
        foldersAfterCursor: null,
        firstItems: 30,
        itemsAfterCursor: null,
        lastItems: 15,
        itemsBeforeCursor: null,
        thumbnailHeight: 280,
        channelLogoWidth: 75,
        channelLogoHeight: 29,
        channelsChannelLogoWidth: 75,
        channelsChannelLogoHeight: 29,
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

const keywordsVariables = {
    variables: {
        input: '',
        filter: {},
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

export const SearchPage: React.FC = (props: any) => {
    const {
        computedMatch: {
            params: { context },
        },
    } = props;
    const config = useSelector(selectConfig);
    const setSearchVariables = () => {
        searchVariables.variables.channelLogoHeight = config.image.logo.small.height;
        searchVariables.variables.channelLogoWidth = config.image.logo.small.width;
        searchVariables.variables.channelsChannelLogoWidth = config.image.logo.large.width;
        searchVariables.variables.channelsChannelLogoHeight = config.image.logo.large.height;
        searchVariables.variables.thumbnailHeight = config.image.thumbnail.default.height;
        return searchVariables;
    };
    const { searchData, onSearchData, onSearchSuggestions, onTermChange } = useContext(PageDataContext);

    const { searchValue, searchResults, searchDataLoaded, searchSuggestions } = searchData;

    const [searchForData, { data, loading, error }] = useSearchLazyQuery(setSearchVariables());

    const getSearchRows =
        useGetSearchRowsQuery({
            variables: {
                context: {
                    profileId: Profile.selectors.selectId(store.getState()),
                },
                firstFolders: searchVariables.variables.firstFolders,
                foldersAfterCursor: searchVariables.variables.foldersAfterCursor,
                profileId: Profile.selectors.selectId(store.getState()),
                firstItems: searchVariables.variables.firstItems,
                itemsAfterCursor: searchVariables.variables.itemsAfterCursor,
                lastItems: searchVariables.variables.lastItems,
                itemsBeforeCursor: searchVariables.variables.itemsBeforeCursor,
                thumbnailHeight: config.image.thumbnail.default.height,
                channelLogoWidth: config.image.logo.small.width,
                channelLogoHeight: config.image.logo.small.height,
            },
        }) ?? [];

    const searchRows = getSearchRows.data?.searchRows?.folders.edges.map((folder) => ({
        assets: folder?.node.firstItems?.edges.map((item) => createAssetObject(item?.node, item?.node?.__typename)),
        stripeId: folder?.node.id,
        title: folder?.node.title,
        type: folder?.node.kind,
    }));

    const [searchForKeywords, { data: keywordsData, loading: keywordsLoading, error: keywordsError }] =
        useSearchKeywordsLazyQuery(keywordsVariables);

    const trendingSearches = useGetTrendingSearchesQuery().data?.trendingSearches?.items ?? [];

    const fromSuggestionRef = useRef(false);

    const withKeyword = (keyword: string) => {
        keywordsVariables.variables.input = keyword;
        return keywordsVariables;
    };

    const withKeywordAndContext = (keyword: string) => {
        const variables = setSearchVariables();

        variables.variables.profileId = Profile.selectors.selectId(store.getState());
        variables.variables.keyword = keyword;
        variables.variables.context.orderContentType = context;

        return variables;
    };

    const onSearch = useCallback((keyword: string, fromSuggestion: boolean = false) => {
        if (fromSuggestion) {
            fromSuggestionRef.current = true;
        }

        searchForData(withKeywordAndContext(keyword));
        searchForKeywords(withKeyword(keyword));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data && !loading && !error) {
            const edges =
                data?.search?.folders?.edges?.map((edge: any) => ({
                    assets: edge?.node?.firstItems.edges?.map((item: any) => createAssetObject(item?.node, item?.node?.__typename)),
                    type: edge?.node?.kind,
                    title: edge?.node?.title,
                    stripeId: edge?.node?.id,
                })) || [];

            onSearchData(edges, searchValue, fromSuggestionRef.current);
            fromSuggestionRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    useEffect(() => {
        if (keywordsData && !keywordsLoading && !keywordsError) {
            onSearchSuggestions(keywordsData?.searchKeywords?.items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keywordsData, keywordsLoading, keywordsError]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const query = params.get('query');
        if (!query) return;
        onTermChange(query);
        onSearch(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Search
            searchValue={searchValue}
            stripes={searchValue ? searchResults : searchRows ?? []}
            searchSuggestions={searchValue ? searchSuggestions : trendingSearches}
            searchDataLoaded={searchDataLoaded}
            onSearch={onSearch}
        />
    );
};
