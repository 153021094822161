import { CHANGE_EVENT, VISIBILITY_PROP } from '__SMART_APP_OLD__/utils/visibility';

/**
 * @namespace Utils
 */

/**
 * @param handler  eventHandler function
 * @memberof Utils
 * @function subscribeVisibilityChangeHandler
 */
export const subscribeVisibilityChangeHandler = (handler: () => void): void => {
    document.addEventListener(CHANGE_EVENT, handler, false);
};

/**
 * @param handler  eventHandler function
 * @memberof Utils
 * @function unsubscribeVisibilityChangeHandler
 */
export const unsubscribeVisibilityChangeHandler = (handler: () => void): void => {
    document.removeEventListener(CHANGE_EVENT, handler);
};

//
/**
 * @description A function that returns boolean whether or not the application is visible
 * NOTE: we are using document.hidden to decide hence the negation.
 * @returns is application visible
 * @memberof Utils
 * @function isApplicationVisible
 */
// @ts-ignore
export const isApplicationVisible = (): boolean => !document[VISIBILITY_PROP];
