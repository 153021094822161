import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Alert } from 'App/Modules/Alert';
import { BasicAlert } from 'App/Modules/Alert/Modules/BasicAlert';
import { selectors } from 'App/Modules/Alert/Modules/DeviceCannotDeleteAlert/Store/selectors';

export const View: React.FunctionComponent<Alert.Props<Alert.Type.DeviceCannotDelete>> = (props) => {
    const title = useSelector(selectors.private.selectTitle(props.deviceId));
    const text = useSelector(selectors.private.selectText(props.deviceId));

    return <BasicAlert.View title={title} text={text} />;
};
