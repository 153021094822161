import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { settingsScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/actions';
import {
    selectSettingsItemOptionValue,
    selectSettingsItemTitle,
    selectSettingsItemType,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/selectors';
import { SettingsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsScreenCtx';
import { SettingsScreenOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';
import { SettingsItemType } from '__SMART_APP_OLD__/app/types';

type Props = {
    optionId: SettingsScreenOptionId;
    index: number;
    isFocused: boolean;
    firstVisibleIndex: number;
    onHover: (optionId: string) => void;
    onFocus: (optionId: string) => void;
};

type SettingsScreenViewItemModule = React.FunctionComponent<Props>;

export const SettingsScreenViewItem: SettingsScreenViewItemModule = (props) => {
    const { optionId, index, isFocused, firstVisibleIndex, onHover, onFocus } = props;

    const dispatch = useDispatch();

    const optionValue = useSelector(selectSettingsItemOptionValue(optionId));
    const settingsItemType = useSelector(selectSettingsItemType(optionId));
    const settingsItemTitle = useSelector(selectSettingsItemTitle(optionId));

    return (
        <React.Fragment key={optionId}>
            {index === 0 && <Settings.Section.Content.Separator />}
            <Settings.Section.Content.Item
                id={optionId}
                ctx={SettingsScreenCtx.Options}
                isFocused={isFocused}
                onEnter={(id) => dispatch(settingsScreenActions.private.entered(id, index, firstVisibleIndex))}
                onHover={onHover}
                onFocus={onFocus}
            >
                <Settings.Section.Content.Item.Title>{settingsItemTitle}</Settings.Section.Content.Item.Title>
                <Settings.Section.Content.Item.Spacer />
                {optionValue && <Settings.Section.Content.Item.Value>{optionValue}</Settings.Section.Content.Item.Value>}
                {settingsItemType === SettingsItemType.LINK && <Settings.Section.Content.Item.Icon.ArrowRight />}
            </Settings.Section.Content.Item>
            <Settings.Section.Content.Separator />
        </React.Fragment>
    );
};
