import { BrowserHistory } from '__SMART_APP_OLD__/utils/CustomHistory';
/**
 * @namespace Utils
 */

/**
 * @description A class containing the navigation state
 * @memberof Utils
 */
class State {
    navState;

    navKey = '';

    constructor() {
        window._nav_state = {};
        this.navState = window._nav_state;
        BrowserHistory.listen(this.handleHistoryChange);
    }

    /**
     * @description A method that handles change in history
     * @param location location
     * @param action action
     *  POP | PUSH | REPLACE
     */
    handleHistoryChange = (location, action) => {
        switch (action) {
            case 'POP':
                this.navKey = location.key;
                break;
            case 'PUSH':
                this.navKey = location.key;
                break;
            case 'REPLACE':
                this.replaceNavKey(location);
                break;
            default:
        }
    };

    /**
     *
     * @param key key
     * @param value value that will be set to the state key
     */
    set(key, value) {
        const { navKey } = this;
        if (!this.navState[navKey]) {
            this.navState[navKey] = {};
        }
        this.navState[navKey][key] = value;
    }

    get(key) {
        const { navKey } = this;
        if (this.navState[navKey] && this.navState[navKey][key]) {
            return this.navState[navKey][key];
        }
        return undefined;
    }

    replaceNavKey(location) {
        const oldNavKey = this.navKey;
        this.navKey = location.key;
        this.navState[this.navKey] = this.navState[oldNavKey];
        delete this.navState[oldNavKey];
    }

    remove(key) {
        const { navKey } = this;
        if (this.navState[navKey]) {
            delete this.navState[navKey][key];
        }
    }

    hasState(key, ignoreLocation = false) {
        if (ignoreLocation) {
            let hasState = false;
            Object.keys(this.navState).forEach((navKey) => {
                if (this.navState[navKey][key]) {
                    hasState = true;
                }
            });

            return hasState;
        }

        const { navKey } = this;
        return !!(this.navState[navKey] && this.navState[navKey][key]);
    }
}

export default new State();
