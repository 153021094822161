import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type FavouritesFragment = { __typename?: 'FavouritableItemCatalog', items: Array<{ __typename?: 'Event', id: string } | { __typename?: 'NetworkRecording', id: string } | { __typename?: 'VODAsset', id: string } | { __typename?: 'VODProduct', id: string } | null> };

export const FavouritesFragmentDoc = gql`
    fragment favourites on FavouritableItemCatalog {
  items {
    ... on VODAsset {
      id
    }
    ... on Recording {
      ... on NetworkRecording {
        id
      }
    }
    ... on Event {
      id
    }
    ... on VODProduct {
      id
    }
  }
}
    `;