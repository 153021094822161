import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
export type ChannelCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type ChannelCollectionItem_Channel_Fragment = { __typename: 'Channel', id: string, title: string, description?: string | null, logoImageAlias?: { __typename: 'Image', id: string, url?: string | null } | null, userInfo?: { __typename?: 'UserChannelInfo', id: string, subscribed: boolean } | null };

export type ChannelCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type ChannelCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type ChannelCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type ChannelCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type ChannelCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type ChannelCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type ChannelCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type ChannelCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type ChannelCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type ChannelCollectionItemFragment = ChannelCollectionItem_Banner_Fragment | ChannelCollectionItem_Channel_Fragment | ChannelCollectionItem_ChannelProduct_Fragment | ChannelCollectionItem_Event_Fragment | ChannelCollectionItem_NetworkRecording_Fragment | ChannelCollectionItem_ProductBundle_Fragment | ChannelCollectionItem_Series_Fragment | ChannelCollectionItem_VodAsset_Fragment | ChannelCollectionItem_VodFolder_Fragment | ChannelCollectionItem_VodProduct_Fragment | ChannelCollectionItem_VodSeries_Fragment;

export const ChannelCollectionItemFragmentDoc = gql`
    fragment channelCollectionItem on ContentItem {
  ... on Channel {
    id
    title
    description
    logoImageAlias: logo(width: 384, height: 216) {
      ...image
    }
    userInfo {
      id
      subscribed
    }
    __typename
  }
}
    ${ImageFragmentDoc}`;