import { audioLanguageListMapping, subtitleLanguageListMapping } from '__SMART_APP_OLD__/utils/dataUtils';

export const getAudioMappingLanguageDisplay = (language: string) => {
    const list = audioLanguageListMapping();
    const lang = list.find((l) => l.backendCode === language);
    return lang ? lang.title : list[0].title; // fixing language bug in control center
};

export const getSubtitleMappingLanguageDisplay = (language: string) => {
    const list = subtitleLanguageListMapping();
    const lang = list.find((l) => l.backendCode === language);
    return lang ? lang.title : list[0].title; // fixing language bug in control center
};
