import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchKeywordsQueryVariables = Types.Exact<{
  input: Types.Scalars['String']['input'];
  filter: Types.SearchFilter;
}>;


export type SearchKeywordsQuery = { __typename?: 'Nexx4Queries', searchKeywords?: { __typename?: 'KeywordCatalog', id: string, itemCount: number, items: Array<string | null> } | null };


export const SearchKeywordsDocument = gql`
    query searchKeywords($input: String!, $filter: SearchFilter!) {
  searchKeywords(input: $input, filter: $filter) {
    id
    itemCount
    items
  }
}
    `;

/**
 * __useSearchKeywordsQuery__
 *
 * To run a query within a React component, call `useSearchKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchKeywordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchKeywordsQuery(baseOptions: Apollo.QueryHookOptions<SearchKeywordsQuery, SearchKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchKeywordsQuery, SearchKeywordsQueryVariables>(SearchKeywordsDocument, options);
      }
export function useSearchKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchKeywordsQuery, SearchKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchKeywordsQuery, SearchKeywordsQueryVariables>(SearchKeywordsDocument, options);
        }
export function useSearchKeywordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchKeywordsQuery, SearchKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchKeywordsQuery, SearchKeywordsQueryVariables>(SearchKeywordsDocument, options);
        }
export type SearchKeywordsQueryHookResult = ReturnType<typeof useSearchKeywordsQuery>;
export type SearchKeywordsLazyQueryHookResult = ReturnType<typeof useSearchKeywordsLazyQuery>;
export type SearchKeywordsSuspenseQueryHookResult = ReturnType<typeof useSearchKeywordsSuspenseQuery>;
export type SearchKeywordsQueryResult = Apollo.QueryResult<SearchKeywordsQuery, SearchKeywordsQueryVariables>;