import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ProfileOnboardingInfoFragment = { __typename?: 'ProfileOnboardingInfo', ageRatingStepCompleted?: any | null, privacyStepCompleted?: any | null };

export const ProfileOnboardingInfoFragmentDoc = gql`
    fragment profileOnboardingInfo on ProfileOnboardingInfo {
  ageRatingStepCompleted
  privacyStepCompleted
}
    `;