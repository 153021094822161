import { CombinedState, PreloadedState } from 'redux';

import { getInitialAuthSessionState } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/getInitialState';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { getInitialChannelEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/getInitialState';
import { getInitialChannelListEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/getInitialState';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { getInitialDeviceEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/getInitialState';
import { getInitialEventEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/getInitialState';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { getInitialMessageEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/getInitialState';
import { getInitialParentalRatingEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/getInitialState';
import { getInitialPinState } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/getInitialState';
// import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { getInitialProfileState } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/getInitialState';
import { getInitialReminderEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/getInitialState';
import { TrackViewingBehaviour } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { getInitialNotificationState } from '__SMART_APP_OLD__/app/modules/Notification/getInitialState';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Alert } from 'App/Modules/Alert';
import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Keyboard } from 'App/Modules/Keyboard';
import { Data } from 'App/Modules/Data';

export const getInitialState = (): PreloadedState<CombinedState<State>> => ({
    notification: getInitialNotificationState(),
    data: {
        recordingEntityTable: { ids: [], entities: {}, eventRecordMap: {} },
        reminderEntityTable: getInitialReminderEntityTableState(),
        authSession: getInitialAuthSessionState(),
        channelEntityTable: getInitialChannelEntityTableState(),
        channelListEntityTable: getInitialChannelListEntityTableState(),
        eventEntityTable: getInitialEventEntityTableState(),
        deviceEntityTable: getInitialDeviceEntityTableState(),
        parentalRatingEntityTable: getInitialParentalRatingEntityTableState(),
        messageEntityTable: getInitialMessageEntityTableState(),
        pin: getInitialPinState(),
        // Refactored
        profile: getInitialProfileState(),
        household: Household.getInitialState(),
        bookmarks: Bookmarks.getInitialState(),
        consent: Consent.getInitialState(),
        community: Community.getInitialState(),
        collection: Collection.getInitialState(),
        favourites: Favourites.getInitialState(),
        trackViewingBehaviour: TrackViewingBehaviour.getInitialState(),
    },
    // Refactored
    time: Time.getInitialState(),
    theme: Theme.getInitialState(),
    startup: Startup.getInitialState(),
    language: Language.getInitialState(),
    translation: Translation.getInitialState(),
    fti: Fti.getInitialState(),
    history: History.getInitialState(),
    // New App Modules
    alert: Alert.getInitialState(),
    prompt: Prompt.getInitialState(),
    overlay: Overlay.getInitialState(),
    screen: Screen.getInitialState(),
    keyboard: Keyboard.getInitialState(),
    dataV2: Data.getInitialState(),
});
