import { GQL } from '__SMART_APP_OLD__/app/gql';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum BookmarksActionType {
    LOADED = '@bookmarks/loaded',
    SAVED = '@bookmarks/saved',
}

// Action
export type BookmarksReduxAction = BookmarksLoadedAction | BookmarksSavedAction;

export type BookmarksLoadedActionPayload = { bookmarks: BookmarksEntity[] };
export type BookmarksLoadedAction = PayloadedAction<BookmarksActionType.LOADED, BookmarksLoadedActionPayload>;

export type BookmarksSavedActionPayload = { bookmark: BookmarksEntity };
export type BookmarksSavedAction = PayloadedAction<BookmarksActionType.SAVED, BookmarksSavedActionPayload>;

// State
export type BookmarksEntity = {
    id: string;
    position: number;
    duration: number;
    progress: number;
    audio?: GQL.Maybe<string>;
    subtitle?: GQL.Maybe<string>;
};

export type BookmarksEntityTable = {
    ids: string[];
    entities: Record<string, BookmarksEntity>;
};
