/* eslint-disable max-lines */
import React from 'react';

import { InboxNegative } from '__SMART_APP_OLD__/app/components/Icon/components/Inbox/InboxNegative';
import { InboxPositive } from '__SMART_APP_OLD__/app/components/Icon/components/Inbox/InboxPositive';
import { InboxSolidDark } from '__SMART_APP_OLD__/app/components/Icon/components/Inbox/InboxSolidDark';
import { InboxSolidLight } from '__SMART_APP_OLD__/app/components/Icon/components/Inbox/InboxSolidLight';
import { ReactComponent as NegativeIconAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/add/negative.svg';
import { ReactComponent as PositiveIconAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/add/positive.svg';
import { ReactComponent as SolidDarkIconAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/add/solidDark.svg';
import { ReactComponent as SolidLightIconAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/add/solidLight.svg';
import { ReactComponent as NegativeIconNewAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/newAdd/negative.svg';
import { ReactComponent as PositiveIconNewAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/newAdd/positive.svg';
import { ReactComponent as SolidDarkIconNewAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/newAdd/solidDark.svg';
import { ReactComponent as SolidLightIconNewAdd } from '__SMART_APP_OLD__/app/components/Icon/icons/newAdd/solidLight.svg';
import { ReactComponent as NegativeIconAndroidPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/androidPhone/negative.svg';
import { ReactComponent as PositiveIconAndroidPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/androidPhone/positive.svg';
import { ReactComponent as SolidDarkIconAndroidPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/androidPhone/solidDark.svg';
import { ReactComponent as SolidLightIconAndroidPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/androidPhone/solidLight.svg';
import { ReactComponent as NegativeIconAndroidTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/androidTablet/negative.svg';
import { ReactComponent as PositiveIconAndroidTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/androidTablet/positive.svg';
import { ReactComponent as SolidDarkIconAndroidTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/androidTablet/solidDark.svg';
import { ReactComponent as SolidLightIconAndroidTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/androidTablet/solidLight.svg';
import { ReactComponent as NegativeIconApplePhone } from '__SMART_APP_OLD__/app/components/Icon/icons/applePhone/negative.svg';
import { ReactComponent as PositiveIconApplePhone } from '__SMART_APP_OLD__/app/components/Icon/icons/applePhone/positive.svg';
import { ReactComponent as SolidDarkIconApplePhone } from '__SMART_APP_OLD__/app/components/Icon/icons/applePhone/solidDark.svg';
import { ReactComponent as SolidLightIconApplePhone } from '__SMART_APP_OLD__/app/components/Icon/icons/applePhone/solidLight.svg';
import { ReactComponent as NegativeIconAppleTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/appleTablet/negative.svg';
import { ReactComponent as PositiveIconAppleTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/appleTablet/positive.svg';
import { ReactComponent as SolidDarkIconAppleTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/appleTablet/solidDark.svg';
import { ReactComponent as SolidLightIconAppleTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/appleTablet/solidLight.svg';
import { ReactComponent as NegativeIconArrange } from '__SMART_APP_OLD__/app/components/Icon/icons/arrange/negative.svg';
import { ReactComponent as PositiveIconArrange } from '__SMART_APP_OLD__/app/components/Icon/icons/arrange/positive.svg';
import { ReactComponent as SolidDarkIconArrange } from '__SMART_APP_OLD__/app/components/Icon/icons/arrange/solidDark.svg';
import { ReactComponent as SolidLightIconArrange } from '__SMART_APP_OLD__/app/components/Icon/icons/arrange/solidLight.svg';
import { ReactComponent as NegativeIconAudioSubtitles } from '__SMART_APP_OLD__/app/components/Icon/icons/audioSubtitles/negative.svg';
import { ReactComponent as PositiveIconAudioSubtitles } from '__SMART_APP_OLD__/app/components/Icon/icons/audioSubtitles/positive.svg';
import { ReactComponent as SolidDarkIconAudioSubtitles } from '__SMART_APP_OLD__/app/components/Icon/icons/audioSubtitles/solidDark.svg';
import { ReactComponent as SolidLightIconAudioSubtitles } from '__SMART_APP_OLD__/app/components/Icon/icons/audioSubtitles/solidLight.svg';
import { ReactComponent as NegativeIconBookmark } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmark/negative.svg';
import { ReactComponent as PositiveIconBookmark } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmark/positive.svg';
import { ReactComponent as SolidDarkIconBookmark } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmark/solidDark.svg';
import { ReactComponent as SolidLightIconBookmark } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmark/solidLight.svg';
import { ReactComponent as NegativeIconBookmarkActive } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkActive/negative.svg';
import { ReactComponent as PositiveIconBookmarkActive } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkActive/positive.svg';
import { ReactComponent as SolidDarkIconBookmarkActive } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkActive/solidDark.svg';
import { ReactComponent as SolidLightIconBookmarkActive } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkActive/solidLight.svg';
import { ReactComponent as NegativeIconBookmarkSelected } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkSelected/negative.svg';
import { ReactComponent as PositiveIconBookmarkSelected } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkSelected/positive.svg';
import { ReactComponent as SolidDarkIconBookmarkSelected } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkSelected/solidDark.svg';
import { ReactComponent as SolidLightIconBookmarkSelected } from '__SMART_APP_OLD__/app/components/Icon/icons/bookmarkSelected/solidLight.svg';
import { ReactComponent as NegativeIconBuy } from '__SMART_APP_OLD__/app/components/Icon/icons/buy/negative.svg';
import { ReactComponent as PositiveIconBuy } from '__SMART_APP_OLD__/app/components/Icon/icons/buy/positive.svg';
import { ReactComponent as SolidDarkIconBuy } from '__SMART_APP_OLD__/app/components/Icon/icons/buy/solidDark.svg';
import { ReactComponent as SolidLightIconBuy } from '__SMART_APP_OLD__/app/components/Icon/icons/buy/solidLight.svg';
import { ReactComponent as NegativeIconCancelRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/cancelRecord/negative.svg';
import { ReactComponent as PositiveIconCancelRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/cancelRecord/positive.svg';
import { ReactComponent as SolidDarkIconCancelRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/cancelRecord/solidDark.svg';
import { ReactComponent as SolidLightIconCancelRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/cancelRecord/solidLight.svg';
import { ReactComponent as NegativeIconCatchup } from '__SMART_APP_OLD__/app/components/Icon/icons/catchup/negative.svg';
import { ReactComponent as PositiveIconCatchup } from '__SMART_APP_OLD__/app/components/Icon/icons/catchup/positive.svg';
import { ReactComponent as SolidDarkIconCatchup } from '__SMART_APP_OLD__/app/components/Icon/icons/catchup/solidDark.svg';
import { ReactComponent as SolidLightIconCatchup } from '__SMART_APP_OLD__/app/components/Icon/icons/catchup/solidLight.svg';
import { ReactComponent as NegativeChannelListIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/channelList/negative.svg';
import { ReactComponent as PositiveChannelListIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/channelList/positive.svg';
import { ReactComponent as SolidDarkChannelListIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/channelList/solidDark.svg';
import { ReactComponent as SolidLightChannelListIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/channelList/solidLight.svg';
import { ReactComponent as NegativeIconChat } from '__SMART_APP_OLD__/app/components/Icon/icons/chat/negative.svg';
import { ReactComponent as PositiveIconChat } from '__SMART_APP_OLD__/app/components/Icon/icons/chat/positive.svg';
import { ReactComponent as SolidDarkIconChat } from '__SMART_APP_OLD__/app/components/Icon/icons/chat/solidDark.svg';
import { ReactComponent as SolidLightIconChat } from '__SMART_APP_OLD__/app/components/Icon/icons/chat/solidLight.svg';
import { ReactComponent as NegativeIconCheckbox } from '__SMART_APP_OLD__/app/components/Icon/icons/checkbox/negative.svg';
import { ReactComponent as PositiveIconCheckbox } from '__SMART_APP_OLD__/app/components/Icon/icons/checkbox/positive.svg';
import { ReactComponent as SolidDarkIconCheckbox } from '__SMART_APP_OLD__/app/components/Icon/icons/checkbox/solidDark.svg';
import { ReactComponent as SolidLightIconCheckbox } from '__SMART_APP_OLD__/app/components/Icon/icons/checkbox/solidLight.svg';
import { ReactComponent as NegativeIconClose } from '__SMART_APP_OLD__/app/components/Icon/icons/close/negative.svg';
import { ReactComponent as PositiveIconClose } from '__SMART_APP_OLD__/app/components/Icon/icons/close/positive.svg';
import { ReactComponent as SolidDarkIconClose } from '__SMART_APP_OLD__/app/components/Icon/icons/close/solidDark.svg';
import { ReactComponent as SolidLightIconClose } from '__SMART_APP_OLD__/app/components/Icon/icons/close/solidLight.svg';
import { ReactComponent as NegativeIconCogwheel } from '__SMART_APP_OLD__/app/components/Icon/icons/cogWheel/negative.svg';
import { ReactComponent as PositiveIconCogwheel } from '__SMART_APP_OLD__/app/components/Icon/icons/cogWheel/positive.svg';
import { ReactComponent as SolidDarkIconCogwheel } from '__SMART_APP_OLD__/app/components/Icon/icons/cogWheel/solidDark.svg';
import { ReactComponent as SolidLightIconCogwheel } from '__SMART_APP_OLD__/app/components/Icon/icons/cogWheel/solidLight.svg';
import { ReactComponent as NegativeIconDelete } from '__SMART_APP_OLD__/app/components/Icon/icons/delete/negative.svg';
import { ReactComponent as PositiveIconDelete } from '__SMART_APP_OLD__/app/components/Icon/icons/delete/positive.svg';
import { ReactComponent as SolidDarkIconDelete } from '__SMART_APP_OLD__/app/components/Icon/icons/delete/solidDark.svg';
import { ReactComponent as SolidLightIconDelete } from '__SMART_APP_OLD__/app/components/Icon/icons/delete/solidLight.svg';
import { ReactComponent as NegativeIconDeviceManagement } from '__SMART_APP_OLD__/app/components/Icon/icons/deviceManagement/negative.svg';
import { ReactComponent as PositiveIconDeviceManagement } from '__SMART_APP_OLD__/app/components/Icon/icons/deviceManagement/positive.svg';
import { ReactComponent as SolidDarkIconDeviceManagement } from '__SMART_APP_OLD__/app/components/Icon/icons/deviceManagement/solidDark.svg';
import { ReactComponent as SolidLightIconDeviceManagement } from '__SMART_APP_OLD__/app/components/Icon/icons/deviceManagement/solidLight.svg';
import { ReactComponent as NegativeDirectorIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/director/negative.svg';
import { ReactComponent as PositiveDirectorIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/director/positive.svg';
import { ReactComponent as SolidDarkDirectorIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/director/solidDark.svg';
import { ReactComponent as SolidLightDirectorIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/director/solidLight.svg';
import { ReactComponent as NegativeIconDolby } from '__SMART_APP_OLD__/app/components/Icon/icons/dolby/negative.svg';
import { ReactComponent as PositiveIconDolby } from '__SMART_APP_OLD__/app/components/Icon/icons/dolby/positive.svg';
import { ReactComponent as SolidDarkIconDolby } from '__SMART_APP_OLD__/app/components/Icon/icons/dolby/solidDark.svg';
import { ReactComponent as SolidLightIconDolby } from '__SMART_APP_OLD__/app/components/Icon/icons/dolby/solidLight.svg';
import { ReactComponent as NegativeIconDown } from '__SMART_APP_OLD__/app/components/Icon/icons/down/negative.svg';
import { ReactComponent as PositiveIconDown } from '__SMART_APP_OLD__/app/components/Icon/icons/down/positive.svg';
import { ReactComponent as SolidDarkIconDown } from '__SMART_APP_OLD__/app/components/Icon/icons/down/solidDark.svg';
import { ReactComponent as SolidLightIconDown } from '__SMART_APP_OLD__/app/components/Icon/icons/down/solidLight.svg';
import { ReactComponent as NegativeIconDownload } from '__SMART_APP_OLD__/app/components/Icon/icons/download/negative.svg';
import { ReactComponent as PositiveIconDownload } from '__SMART_APP_OLD__/app/components/Icon/icons/download/positive.svg';
import { ReactComponent as SolidDarkIconDownload } from '__SMART_APP_OLD__/app/components/Icon/icons/download/solidDark.svg';
import { ReactComponent as SolidLightIconDownload } from '__SMART_APP_OLD__/app/components/Icon/icons/download/solidLight.svg';
import { ReactComponent as NegativeIconEdit } from '__SMART_APP_OLD__/app/components/Icon/icons/edit/negative.svg';
import { ReactComponent as PositiveIconEdit } from '__SMART_APP_OLD__/app/components/Icon/icons/edit/positive.svg';
import { ReactComponent as SolidDarkIconEdit } from '__SMART_APP_OLD__/app/components/Icon/icons/edit/solidDark.svg';
import { ReactComponent as SolidLightIconEdit } from '__SMART_APP_OLD__/app/components/Icon/icons/edit/solidLight.svg';
import { ReactComponent as NegativeIconError } from '__SMART_APP_OLD__/app/components/Icon/icons/error/negative.svg';
import { ReactComponent as PositiveIconError } from '__SMART_APP_OLD__/app/components/Icon/icons/error/positive.svg';
import { ReactComponent as SolidDarkIconError } from '__SMART_APP_OLD__/app/components/Icon/icons/error/solidDark.svg';
import { ReactComponent as SolidLightIconError } from '__SMART_APP_OLD__/app/components/Icon/icons/error/solidLight.svg';
import { ReactComponent as NegativeIconFavorite } from '__SMART_APP_OLD__/app/components/Icon/icons/favorite/negative.svg';
import { ReactComponent as PositiveIconFavorite } from '__SMART_APP_OLD__/app/components/Icon/icons/favorite/positive.svg';
import { ReactComponent as SolidDarkIconFavorite } from '__SMART_APP_OLD__/app/components/Icon/icons/favorite/solidDark.svg';
import { ReactComponent as SolidLightIconFavorite } from '__SMART_APP_OLD__/app/components/Icon/icons/favorite/solidLight.svg';
import { ReactComponent as NegativeIconFavoriteActive } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteActive/negative.svg';
import { ReactComponent as PositiveIconFavoriteActive } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteActive/positive.svg';
import { ReactComponent as SolidDarkIconFavoriteActive } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteActive/solidDark.svg';
import { ReactComponent as SolidLightIconFavoriteActive } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteActive/solidLight.svg';
import { ReactComponent as NegativeIconFireTv } from '__SMART_APP_OLD__/app/components/Icon/icons/fireTv/negative.svg';
import { ReactComponent as PositiveIconFireTv } from '__SMART_APP_OLD__/app/components/Icon/icons/fireTv/positive.svg';
import { ReactComponent as SolidDarkIconFireTv } from '__SMART_APP_OLD__/app/components/Icon/icons/fireTv/solidDark.svg';
import { ReactComponent as SolidLightIconFireTv } from '__SMART_APP_OLD__/app/components/Icon/icons/fireTv/solidLight.svg';
import { ReactComponent as NegativeIconFolder } from '__SMART_APP_OLD__/app/components/Icon/icons/folder/negative.svg';
import { ReactComponent as PositiveIconFolder } from '__SMART_APP_OLD__/app/components/Icon/icons/folder/positive.svg';
import { ReactComponent as SolidDarkIconFolder } from '__SMART_APP_OLD__/app/components/Icon/icons/folder/solidDark.svg';
import { ReactComponent as SolidLightIconFolder } from '__SMART_APP_OLD__/app/components/Icon/icons/folder/solidLight.svg';
import { ReactComponent as NegativeIconForward } from '__SMART_APP_OLD__/app/components/Icon/icons/forward/negative.svg';
import { ReactComponent as PositiveIconForward } from '__SMART_APP_OLD__/app/components/Icon/icons/forward/positive.svg';
import { ReactComponent as SolidDarkIconForward } from '__SMART_APP_OLD__/app/components/Icon/icons/forward/solidDark.svg';
import { ReactComponent as SolidLightIconForward } from '__SMART_APP_OLD__/app/components/Icon/icons/forward/solidLight.svg';
import { ReactComponent as NegativeIconHD } from '__SMART_APP_OLD__/app/components/Icon/icons/hd/negative.svg';
import { ReactComponent as PositiveIconHD } from '__SMART_APP_OLD__/app/components/Icon/icons/hd/positive.svg';
import { ReactComponent as SolidDarkIconHD } from '__SMART_APP_OLD__/app/components/Icon/icons/hd/solidDark.svg';
import { ReactComponent as SolidLightIconHD } from '__SMART_APP_OLD__/app/components/Icon/icons/hd/solidLight.svg';
import { ReactComponent as NegativeIconHidden } from '__SMART_APP_OLD__/app/components/Icon/icons/hidden/negative.svg';
import { ReactComponent as PositiveIconHidden } from '__SMART_APP_OLD__/app/components/Icon/icons/hidden/positive.svg';
import { ReactComponent as SolidDarkIconHidden } from '__SMART_APP_OLD__/app/components/Icon/icons/hidden/solidDark.svg';
import { ReactComponent as SolidLightIconHidden } from '__SMART_APP_OLD__/app/components/Icon/icons/hidden/solidLight.svg';
import { ReactComponent as NegativeIconHisenseTv } from '__SMART_APP_OLD__/app/components/Icon/icons/hisenseTv/negative.svg';
import { ReactComponent as PositiveIconHisenseTv } from '__SMART_APP_OLD__/app/components/Icon/icons/hisenseTv/positive.svg';
import { ReactComponent as SolidDarkIconHisenseTv } from '__SMART_APP_OLD__/app/components/Icon/icons/hisenseTv/solidDark.svg';
import { ReactComponent as SolidLightIconHisenseTv } from '__SMART_APP_OLD__/app/components/Icon/icons/hisenseTv/solidLight.svg';
import { ReactComponent as NegativeIconHuaweiPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiPhone/negative.svg';
import { ReactComponent as PositiveIconHuaweiPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiPhone/positive.svg';
import { ReactComponent as SolidDarkIconHuaweiPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiPhone/solidDark.svg';
import { ReactComponent as SolidLightIconHuaweiPhone } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiPhone/solidLight.svg';
import { ReactComponent as NegativeIconHuaweiTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiTablet/negative.svg';
import { ReactComponent as PositiveIconHuaweiTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiTablet/positive.svg';
import { ReactComponent as SolidDarkIconHuaweiTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiTablet/solidDark.svg';
import { ReactComponent as SolidLightIconHuaweiTablet } from '__SMART_APP_OLD__/app/components/Icon/icons/huaweiTablet/solidLight.svg';
import { ReactComponent as NegativeIconImage } from '__SMART_APP_OLD__/app/components/Icon/icons/image/negative.svg';
import { ReactComponent as PositiveIconImage } from '__SMART_APP_OLD__/app/components/Icon/icons/image/positive.svg';
import { ReactComponent as SolidDarkIconImage } from '__SMART_APP_OLD__/app/components/Icon/icons/image/solidDark.svg';
import { ReactComponent as SolidLightIconImage } from '__SMART_APP_OLD__/app/components/Icon/icons/image/solidLight.svg';
import { ReactComponent as NegativeIconInfo } from '__SMART_APP_OLD__/app/components/Icon/icons/info/negative.svg';
import { ReactComponent as PositiveIconInfo } from '__SMART_APP_OLD__/app/components/Icon/icons/info/positive.svg';
import { ReactComponent as SolidDarkIconInfo } from '__SMART_APP_OLD__/app/components/Icon/icons/info/solidDark.svg';
import { ReactComponent as SolidLightIconInfo } from '__SMART_APP_OLD__/app/components/Icon/icons/info/solidLight.svg';
import { ReactComponent as NegativeIconLeft } from '__SMART_APP_OLD__/app/components/Icon/icons/left/negative.svg';
import { ReactComponent as PositiveIconLeft } from '__SMART_APP_OLD__/app/components/Icon/icons/left/positive.svg';
import { ReactComponent as SolidDarkIconLeft } from '__SMART_APP_OLD__/app/components/Icon/icons/left/solidDark.svg';
import { ReactComponent as SolidLightIconLeft } from '__SMART_APP_OLD__/app/components/Icon/icons/left/solidLight.svg';
import { ReactComponent as NegativeIconLgTv } from '__SMART_APP_OLD__/app/components/Icon/icons/lgTv/negative.svg';
import { ReactComponent as PositiveIconLgTv } from '__SMART_APP_OLD__/app/components/Icon/icons/lgTv/positive.svg';
import { ReactComponent as SolidDarkIconLgTv } from '__SMART_APP_OLD__/app/components/Icon/icons/lgTv/solidDark.svg';
import { ReactComponent as SolidLightIconLgTv } from '__SMART_APP_OLD__/app/components/Icon/icons/lgTv/solidLight.svg';
import { ReactComponent as NegativeIconLive } from '__SMART_APP_OLD__/app/components/Icon/icons/live/negative.svg';
import { ReactComponent as PositiveIconLive } from '__SMART_APP_OLD__/app/components/Icon/icons/live/positive.svg';
import { ReactComponent as SolidDarkIconLive } from '__SMART_APP_OLD__/app/components/Icon/icons/live/solidDark.svg';
import { ReactComponent as SolidLightIconLive } from '__SMART_APP_OLD__/app/components/Icon/icons/live/solidLight.svg';
import { ReactComponent as NegativeIconLoading } from '__SMART_APP_OLD__/app/components/Icon/icons/loading/negative.svg';
import { ReactComponent as PositiveIconLoading } from '__SMART_APP_OLD__/app/components/Icon/icons/loading/positive.svg';
import { ReactComponent as SolidDarkIconLoading } from '__SMART_APP_OLD__/app/components/Icon/icons/loading/solidDark.svg';
import { ReactComponent as SolidLightIconLoading } from '__SMART_APP_OLD__/app/components/Icon/icons/loading/solidLight.svg';
import { ReactComponent as NegativeIconLockClosed } from '__SMART_APP_OLD__/app/components/Icon/icons/lockClosed/negative.svg';
import { ReactComponent as PositiveIconLockClosed } from '__SMART_APP_OLD__/app/components/Icon/icons/lockClosed/positive.svg';
import { ReactComponent as SolidDarkIconLockClosed } from '__SMART_APP_OLD__/app/components/Icon/icons/lockClosed/solidDark.svg';
import { ReactComponent as SolidLightIconLockClosed } from '__SMART_APP_OLD__/app/components/Icon/icons/lockClosed/solidLight.svg';
import { ReactComponent as NegativeIconLockOpen } from '__SMART_APP_OLD__/app/components/Icon/icons/lockOpen/negative.svg';
import { ReactComponent as PositiveIconLockOpen } from '__SMART_APP_OLD__/app/components/Icon/icons/lockOpen/positive.svg';
import { ReactComponent as SolidDarkIconLockOpen } from '__SMART_APP_OLD__/app/components/Icon/icons/lockOpen/solidDark.svg';
import { ReactComponent as SolidLightIconLockOpen } from '__SMART_APP_OLD__/app/components/Icon/icons/lockOpen/solidLight.svg';
import { ReactComponent as NegativeIconMenuHorizontal } from '__SMART_APP_OLD__/app/components/Icon/icons/menuHorizontal/negative.svg';
import { ReactComponent as PositiveIconMenuHorizontal } from '__SMART_APP_OLD__/app/components/Icon/icons/menuHorizontal/positive.svg';
import { ReactComponent as SolidDarkIconMenuHorizontal } from '__SMART_APP_OLD__/app/components/Icon/icons/menuHorizontal/solidDark.svg';
import { ReactComponent as SolidLightIconMenuHorizontal } from '__SMART_APP_OLD__/app/components/Icon/icons/menuHorizontal/solidLight.svg';
import { ReactComponent as NegativeIconMenuVertical } from '__SMART_APP_OLD__/app/components/Icon/icons/menuVertical/negative.svg';
import { ReactComponent as PositiveIconMenuVertical } from '__SMART_APP_OLD__/app/components/Icon/icons/menuVertical/positive.svg';
import { ReactComponent as SolidDarkIconMenuVertical } from '__SMART_APP_OLD__/app/components/Icon/icons/menuVertical/solidDark.svg';
import { ReactComponent as SolidLightIconMenuVertical } from '__SMART_APP_OLD__/app/components/Icon/icons/menuVertical/solidLight.svg';
import { ReactComponent as NegativeIconMessaging } from '__SMART_APP_OLD__/app/components/Icon/icons/messaging/negative.svg';
import { ReactComponent as PositiveIconMessaging } from '__SMART_APP_OLD__/app/components/Icon/icons/messaging/positive.svg';
import { ReactComponent as SolidDarkIconMessaging } from '__SMART_APP_OLD__/app/components/Icon/icons/messaging/solidDark.svg';
import { ReactComponent as SolidLightIconMessaging } from '__SMART_APP_OLD__/app/components/Icon/icons/messaging/solidLight.svg';
import { ReactComponent as NegativeIconOk } from '__SMART_APP_OLD__/app/components/Icon/icons/ok/negative.svg';
import { ReactComponent as PositiveIconOk } from '__SMART_APP_OLD__/app/components/Icon/icons/ok/positive.svg';
import { ReactComponent as SolidDarkIconOk } from '__SMART_APP_OLD__/app/components/Icon/icons/ok/solidDark.svg';
import { ReactComponent as SolidLightIconOk } from '__SMART_APP_OLD__/app/components/Icon/icons/ok/solidLight.svg';
import { ReactComponent as NegativeIconPause } from '__SMART_APP_OLD__/app/components/Icon/icons/pause/negative.svg';
import { ReactComponent as PositiveIconPause } from '__SMART_APP_OLD__/app/components/Icon/icons/pause/positive.svg';
import { ReactComponent as SolidDarkIconPause } from '__SMART_APP_OLD__/app/components/Icon/icons/pause/solidDark.svg';
import { ReactComponent as SolidLightIconPause } from '__SMART_APP_OLD__/app/components/Icon/icons/pause/solidLight.svg';
import { ReactComponent as NegativePeopleIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/people/negative.svg';
import { ReactComponent as PositivePeopleIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/people/positive.svg';
import { ReactComponent as SolidDarkPeopleIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/people/solidDark.svg';
import { ReactComponent as SolidLightPeopleIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/people/solidLight.svg';
import { ReactComponent as NegativeIconPlay } from '__SMART_APP_OLD__/app/components/Icon/icons/play/negative.svg';
import { ReactComponent as PositiveIconPlay } from '__SMART_APP_OLD__/app/components/Icon/icons/play/positive.svg';
import { ReactComponent as SolidDarkIconPlay } from '__SMART_APP_OLD__/app/components/Icon/icons/play/solidDark.svg';
import { ReactComponent as SolidLightIconPlay } from '__SMART_APP_OLD__/app/components/Icon/icons/play/solidLight.svg';
import { ReactComponent as NegativeIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/negative.svg';
import { ReactComponent as PositiveIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/positive.svg';
import { ReactComponent as SolidDarkIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/solidDark.svg';
import { ReactComponent as SolidLightIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/solidLight.svg';
import { ReactComponent as NegativeIconReminder } from '__SMART_APP_OLD__/app/components/Icon/icons/reminder/negative.svg';
import { ReactComponent as PositiveIconReminder } from '__SMART_APP_OLD__/app/components/Icon/icons/reminder/positive.svg';
import { ReactComponent as SolidDarkIconReminder } from '__SMART_APP_OLD__/app/components/Icon/icons/reminder/solidDark.svg';
import { ReactComponent as SolidLightIconReminder } from '__SMART_APP_OLD__/app/components/Icon/icons/reminder/solidLight.svg';
import { ReactComponent as NegativeIconResize } from '__SMART_APP_OLD__/app/components/Icon/icons/resize/negative.svg';
import { ReactComponent as PositiveIconResize } from '__SMART_APP_OLD__/app/components/Icon/icons/resize/positive.svg';
import { ReactComponent as SolidDarkIconResize } from '__SMART_APP_OLD__/app/components/Icon/icons/resize/solidDark.svg';
import { ReactComponent as SolidLightIconResize } from '__SMART_APP_OLD__/app/components/Icon/icons/resize/solidLight.svg';
import { ReactComponent as NegativeIconRewind } from '__SMART_APP_OLD__/app/components/Icon/icons/rewind/negative.svg';
import { ReactComponent as PositiveIconRewind } from '__SMART_APP_OLD__/app/components/Icon/icons/rewind/positive.svg';
import { ReactComponent as SolidDarkIconRewind } from '__SMART_APP_OLD__/app/components/Icon/icons/rewind/solidDark.svg';
import { ReactComponent as SolidLightIconRewind } from '__SMART_APP_OLD__/app/components/Icon/icons/rewind/solidLight.svg';
import { ReactComponent as NegativeIconRight } from '__SMART_APP_OLD__/app/components/Icon/icons/right/negative.svg';
import { ReactComponent as PositiveIconRight } from '__SMART_APP_OLD__/app/components/Icon/icons/right/positive.svg';
import { ReactComponent as SolidDarkIconRight } from '__SMART_APP_OLD__/app/components/Icon/icons/right/solidDark.svg';
import { ReactComponent as SolidLightIconRight } from '__SMART_APP_OLD__/app/components/Icon/icons/right/solidLight.svg';
import { ReactComponent as NegativeIconSamsungTv } from '__SMART_APP_OLD__/app/components/Icon/icons/samsungTv/negative.svg';
import { ReactComponent as PositiveIconSamsungTv } from '__SMART_APP_OLD__/app/components/Icon/icons/samsungTv/positive.svg';
import { ReactComponent as SolidDarkIconSamsungTv } from '__SMART_APP_OLD__/app/components/Icon/icons/samsungTv/solidDark.svg';
import { ReactComponent as SolidLightIconSamsungTv } from '__SMART_APP_OLD__/app/components/Icon/icons/samsungTv/solidLight.svg';
import { ReactComponent as NegativeIconSD } from '__SMART_APP_OLD__/app/components/Icon/icons/sd/negative.svg';
import { ReactComponent as PositiveIconSD } from '__SMART_APP_OLD__/app/components/Icon/icons/sd/positive.svg';
import { ReactComponent as SolidDarkIconSD } from '__SMART_APP_OLD__/app/components/Icon/icons/sd/solidDark.svg';
import { ReactComponent as SolidLightIconSD } from '__SMART_APP_OLD__/app/components/Icon/icons/sd/solidLight.svg';
import { ReactComponent as NegativeIconSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/search/negative.svg';
import { ReactComponent as PositiveIconSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/search/positive.svg';
import { ReactComponent as SolidDarkIconSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/search/solidDark.svg';
import { ReactComponent as SolidLightIconSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/search/solidLight.svg';
import { ReactComponent as SolidDarkIconOldSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/oldSearch/solidDark.svg';
import { ReactComponent as SolidLightIconOldSearch } from '__SMART_APP_OLD__/app/components/Icon/icons/oldSearch/solidLight.svg';
import { ReactComponent as NegativeIconSettings } from '__SMART_APP_OLD__/app/components/Icon/icons/settings/negative.svg';
import { ReactComponent as PositiveIconSettings } from '__SMART_APP_OLD__/app/components/Icon/icons/settings/positive.svg';
import { ReactComponent as SolidDarkIconSettings } from '__SMART_APP_OLD__/app/components/Icon/icons/settings/solidDark.svg';
import { ReactComponent as SolidLightIconSettings } from '__SMART_APP_OLD__/app/components/Icon/icons/settings/solidLight.svg';
import { ReactComponent as NegativeIconSort } from '__SMART_APP_OLD__/app/components/Icon/icons/sort/negative.svg';
import { ReactComponent as PositiveIconSort } from '__SMART_APP_OLD__/app/components/Icon/icons/sort/positive.svg';
import { ReactComponent as SolidDarkIconSort } from '__SMART_APP_OLD__/app/components/Icon/icons/sort/solidDark.svg';
import { ReactComponent as SolidLightIconSort } from '__SMART_APP_OLD__/app/components/Icon/icons/sort/solidLight.svg';
import { ReactComponent as NegativeIconSortAZ } from '__SMART_APP_OLD__/app/components/Icon/icons/sortAZ/negative.svg';
import { ReactComponent as PositiveIconSortAZ } from '__SMART_APP_OLD__/app/components/Icon/icons/sortAZ/positive.svg';
import { ReactComponent as SolidDarkIconSortAZ } from '__SMART_APP_OLD__/app/components/Icon/icons/sortAZ/solidDark.svg';
import { ReactComponent as SolidLightIconSortAZ } from '__SMART_APP_OLD__/app/components/Icon/icons/sortAZ/solidLight.svg';
import { ReactComponent as NegativeIconStartOver } from '__SMART_APP_OLD__/app/components/Icon/icons/startOver/negative.svg';
import { ReactComponent as PositiveIconStartOver } from '__SMART_APP_OLD__/app/components/Icon/icons/startOver/positive.svg';
import { ReactComponent as SolidDarkIconStartOver } from '__SMART_APP_OLD__/app/components/Icon/icons/startOver/solidDark.svg';
import { ReactComponent as SolidLightIconStartOver } from '__SMART_APP_OLD__/app/components/Icon/icons/startOver/solidLight.svg';
import { ReactComponent as NegativeIconStb } from '__SMART_APP_OLD__/app/components/Icon/icons/stb/negative.svg';
import { ReactComponent as PositiveIconStb } from '__SMART_APP_OLD__/app/components/Icon/icons/stb/positive.svg';
import { ReactComponent as SolidDarkIconStb } from '__SMART_APP_OLD__/app/components/Icon/icons/stb/solidDark.svg';
import { ReactComponent as SolidLightIconStb } from '__SMART_APP_OLD__/app/components/Icon/icons/stb/solidLight.svg';
import { ReactComponent as NegativeIconTimer } from '__SMART_APP_OLD__/app/components/Icon/icons/timer/negative.svg';
import { ReactComponent as PositiveIconTimer } from '__SMART_APP_OLD__/app/components/Icon/icons/timer/positive.svg';
import { ReactComponent as SolidDarkIconTimer } from '__SMART_APP_OLD__/app/components/Icon/icons/timer/solidDark.svg';
import { ReactComponent as SolidLightIconTimer } from '__SMART_APP_OLD__/app/components/Icon/icons/timer/solidLight.svg';
import { ReactComponent as NegativeIconUHD } from '__SMART_APP_OLD__/app/components/Icon/icons/uhd/negative.svg';
import { ReactComponent as PositiveIconUHD } from '__SMART_APP_OLD__/app/components/Icon/icons/uhd/positive.svg';
import { ReactComponent as SolidDarkIconUHD } from '__SMART_APP_OLD__/app/components/Icon/icons/uhd/solidDark.svg';
import { ReactComponent as SolidLightIconUHD } from '__SMART_APP_OLD__/app/components/Icon/icons/uhd/solidLight.svg';
import { ReactComponent as NegativeIconUp } from '__SMART_APP_OLD__/app/components/Icon/icons/up/negative.svg';
import { ReactComponent as PositiveIconUp } from '__SMART_APP_OLD__/app/components/Icon/icons/up/positive.svg';
import { ReactComponent as SolidDarkIconUp } from '__SMART_APP_OLD__/app/components/Icon/icons/up/solidDark.svg';
import { ReactComponent as SolidLightIconUp } from '__SMART_APP_OLD__/app/components/Icon/icons/up/solidLight.svg';
import { ReactComponent as NegativeIconVisible } from '__SMART_APP_OLD__/app/components/Icon/icons/visible/negative.svg';
import { ReactComponent as PositiveIconVisible } from '__SMART_APP_OLD__/app/components/Icon/icons/visible/positive.svg';
import { ReactComponent as SolidDarkIconVisible } from '__SMART_APP_OLD__/app/components/Icon/icons/visible/solidDark.svg';
import { ReactComponent as SolidLightIconVisible } from '__SMART_APP_OLD__/app/components/Icon/icons/visible/solidLight.svg';
import { ReactComponent as NegativeIconVoiceInput } from '__SMART_APP_OLD__/app/components/Icon/icons/voiceInput/negative.svg';
import { ReactComponent as PositiveIconVoiceInput } from '__SMART_APP_OLD__/app/components/Icon/icons/voiceInput/positive.svg';
import { ReactComponent as SolidDarkIconVoiceInput } from '__SMART_APP_OLD__/app/components/Icon/icons/voiceInput/solidDark.svg';
import { ReactComponent as SolidLightIconVoiceInput } from '__SMART_APP_OLD__/app/components/Icon/icons/voiceInput/solidLight.svg';
import { ReactComponent as NegativeIconWeb } from '__SMART_APP_OLD__/app/components/Icon/icons/web/negative.svg';
import { ReactComponent as PositiveIconWeb } from '__SMART_APP_OLD__/app/components/Icon/icons/web/positive.svg';
import { ReactComponent as SolidDarkIconWeb } from '__SMART_APP_OLD__/app/components/Icon/icons/web/solidDark.svg';
import { ReactComponent as SolidLightIconWeb } from '__SMART_APP_OLD__/app/components/Icon/icons/web/solidLight.svg';
import { ReactComponent as NegativeFavoriteOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteOld/negative.svg';
import { ReactComponent as PositiveFavoriteOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteOld/positive.svg';
import { ReactComponent as SolidDarkFavoriteOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteOld/solidDark.svg';
import { ReactComponent as SolidLightFavoriteOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteOld/solidLight.svg';
import { ReactComponent as NegativeFavoriteEmptyOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteEmptyOld/negative.svg';
import { ReactComponent as PositiveFavoriteEmptyOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteEmptyOld/positive.svg';
import { ReactComponent as SolidDarkFavoriteEmptyOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteEmptyOld/solidDark.svg';
import { ReactComponent as SolidLightFavoriteEmptyOldIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/favoriteEmptyOld/solidLight.svg';
import { IconFlavour, IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';

const componentForIconName: Record<IconName, Record<IconFlavour, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>> = {
    [IconName.OK]: {
        [IconFlavour.POSITIVE]: PositiveIconOk,
        [IconFlavour.NEGATIVE]: NegativeIconOk,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconOk,
        [IconFlavour.SOLID_DARK]: SolidDarkIconOk,
    },

    [IconName.SETTINGS]: {
        [IconFlavour.POSITIVE]: PositiveIconSettings,
        [IconFlavour.NEGATIVE]: NegativeIconSettings,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSettings,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSettings,
    },

    [IconName.SEARCH]: {
        [IconFlavour.POSITIVE]: PositiveIconSearch,
        [IconFlavour.NEGATIVE]: NegativeIconSearch,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSearch,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSearch,
    },

    [IconName.OLD_SEARCH]: {
        [IconFlavour.POSITIVE]: SolidLightIconOldSearch,
        [IconFlavour.NEGATIVE]: SolidDarkIconOldSearch,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconOldSearch,
        [IconFlavour.SOLID_DARK]: SolidDarkIconOldSearch,
    },

    [IconName.TIMER]: {
        [IconFlavour.POSITIVE]: PositiveIconTimer,
        [IconFlavour.NEGATIVE]: NegativeIconTimer,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconTimer,
        [IconFlavour.SOLID_DARK]: SolidDarkIconTimer,
    },

    [IconName.CHAT]: {
        [IconFlavour.POSITIVE]: PositiveIconChat,
        [IconFlavour.NEGATIVE]: NegativeIconChat,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconChat,
        [IconFlavour.SOLID_DARK]: SolidDarkIconChat,
    },

    [IconName.CHECKBOX]: {
        [IconFlavour.POSITIVE]: PositiveIconCheckbox,
        [IconFlavour.NEGATIVE]: NegativeIconCheckbox,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconCheckbox,
        [IconFlavour.SOLID_DARK]: SolidDarkIconCheckbox,
    },

    [IconName.LG_TV]: {
        [IconFlavour.POSITIVE]: PositiveIconLgTv,
        [IconFlavour.NEGATIVE]: NegativeIconLgTv,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLgTv,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLgTv,
    },

    [IconName.SAMSUNG_TV]: {
        [IconFlavour.POSITIVE]: PositiveIconSamsungTv,
        [IconFlavour.NEGATIVE]: NegativeIconSamsungTv,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSamsungTv,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSamsungTv,
    },

    [IconName.FIRE_TV]: {
        [IconFlavour.POSITIVE]: PositiveIconFireTv,
        [IconFlavour.NEGATIVE]: NegativeIconFireTv,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconFireTv,
        [IconFlavour.SOLID_DARK]: SolidDarkIconFireTv,
    },

    [IconName.HISENSE_TV]: {
        [IconFlavour.POSITIVE]: PositiveIconHisenseTv,
        [IconFlavour.NEGATIVE]: NegativeIconHisenseTv,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconHisenseTv,
        [IconFlavour.SOLID_DARK]: SolidDarkIconHisenseTv,
    },

    [IconName.APPLE_PHONE]: {
        [IconFlavour.POSITIVE]: PositiveIconApplePhone,
        [IconFlavour.NEGATIVE]: NegativeIconApplePhone,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconApplePhone,
        [IconFlavour.SOLID_DARK]: SolidDarkIconApplePhone,
    },

    [IconName.HUAWEI_PHONE]: {
        [IconFlavour.POSITIVE]: PositiveIconHuaweiPhone,
        [IconFlavour.NEGATIVE]: NegativeIconHuaweiPhone,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconHuaweiPhone,
        [IconFlavour.SOLID_DARK]: SolidDarkIconHuaweiPhone,
    },

    [IconName.ANDROID_PHONE]: {
        [IconFlavour.POSITIVE]: PositiveIconAndroidPhone,
        [IconFlavour.NEGATIVE]: NegativeIconAndroidPhone,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconAndroidPhone,
        [IconFlavour.SOLID_DARK]: SolidDarkIconAndroidPhone,
    },

    [IconName.APPLE_TABLET]: {
        [IconFlavour.POSITIVE]: PositiveIconAppleTablet,
        [IconFlavour.NEGATIVE]: NegativeIconAppleTablet,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconAppleTablet,
        [IconFlavour.SOLID_DARK]: SolidDarkIconAppleTablet,
    },

    [IconName.HUAWEI_TABLET]: {
        [IconFlavour.POSITIVE]: PositiveIconHuaweiTablet,
        [IconFlavour.NEGATIVE]: NegativeIconHuaweiTablet,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconHuaweiTablet,
        [IconFlavour.SOLID_DARK]: SolidDarkIconHuaweiTablet,
    },

    [IconName.ANDROID_TABLET]: {
        [IconFlavour.POSITIVE]: PositiveIconAndroidTablet,
        [IconFlavour.NEGATIVE]: NegativeIconAndroidTablet,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconAndroidTablet,
        [IconFlavour.SOLID_DARK]: SolidDarkIconAndroidTablet,
    },

    [IconName.STB]: {
        [IconFlavour.POSITIVE]: PositiveIconStb,
        [IconFlavour.NEGATIVE]: NegativeIconStb,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconStb,
        [IconFlavour.SOLID_DARK]: SolidDarkIconStb,
    },

    [IconName.DEVICE_MANAGEMENT]: {
        [IconFlavour.POSITIVE]: PositiveIconDeviceManagement,
        [IconFlavour.NEGATIVE]: NegativeIconDeviceManagement,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconDeviceManagement,
        [IconFlavour.SOLID_DARK]: SolidDarkIconDeviceManagement,
    },

    [IconName.WEB]: {
        [IconFlavour.POSITIVE]: PositiveIconWeb,
        [IconFlavour.NEGATIVE]: NegativeIconWeb,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconWeb,
        [IconFlavour.SOLID_DARK]: SolidDarkIconWeb,
    },

    [IconName.FOLDER]: {
        [IconFlavour.POSITIVE]: PositiveIconFolder,
        [IconFlavour.NEGATIVE]: NegativeIconFolder,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconFolder,
        [IconFlavour.SOLID_DARK]: SolidDarkIconFolder,
    },

    [IconName.LOADING]: {
        [IconFlavour.POSITIVE]: PositiveIconLoading,
        [IconFlavour.NEGATIVE]: NegativeIconLoading,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLoading,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLoading,
    },

    [IconName.MESSAGING]: {
        [IconFlavour.POSITIVE]: PositiveIconMessaging,
        [IconFlavour.NEGATIVE]: NegativeIconMessaging,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconMessaging,
        [IconFlavour.SOLID_DARK]: SolidDarkIconMessaging,
    },

    [IconName.CLOSE]: {
        [IconFlavour.POSITIVE]: PositiveIconClose,
        [IconFlavour.NEGATIVE]: NegativeIconClose,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconClose,
        [IconFlavour.SOLID_DARK]: SolidDarkIconClose,
    },

    [IconName.COGWHEEL]: {
        [IconFlavour.POSITIVE]: PositiveIconCogwheel,
        [IconFlavour.NEGATIVE]: NegativeIconCogwheel,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconCogwheel,
        [IconFlavour.SOLID_DARK]: SolidDarkIconCogwheel,
    },

    [IconName.DOWNLOAD]: {
        [IconFlavour.POSITIVE]: PositiveIconDownload,
        [IconFlavour.NEGATIVE]: NegativeIconDownload,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconDownload,
        [IconFlavour.SOLID_DARK]: SolidDarkIconDownload,
    },

    [IconName.ADD]: {
        [IconFlavour.POSITIVE]: PositiveIconAdd,
        [IconFlavour.NEGATIVE]: NegativeIconAdd,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconAdd,
        [IconFlavour.SOLID_DARK]: SolidDarkIconAdd,
    },

    [IconName.BOOKMARK_SELECTED]: {
        [IconFlavour.POSITIVE]: PositiveIconBookmarkSelected,
        [IconFlavour.NEGATIVE]: NegativeIconBookmarkSelected,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconBookmarkSelected,
        [IconFlavour.SOLID_DARK]: SolidDarkIconBookmarkSelected,
    },

    [IconName.BOOKMARK]: {
        [IconFlavour.POSITIVE]: PositiveIconBookmark,
        [IconFlavour.NEGATIVE]: NegativeIconBookmark,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconBookmark,
        [IconFlavour.SOLID_DARK]: SolidDarkIconBookmark,
    },

    [IconName.BOOKMARK_ACTIVE]: {
        [IconFlavour.POSITIVE]: PositiveIconBookmarkActive,
        [IconFlavour.NEGATIVE]: NegativeIconBookmarkActive,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconBookmarkActive,
        [IconFlavour.SOLID_DARK]: SolidDarkIconBookmarkActive,
    },

    [IconName.LOCK_OPEN]: {
        [IconFlavour.POSITIVE]: PositiveIconLockOpen,
        [IconFlavour.NEGATIVE]: NegativeIconLockOpen,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLockOpen,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLockOpen,
    },

    [IconName.LOCK_CLOSED]: {
        [IconFlavour.POSITIVE]: PositiveIconLockClosed,
        [IconFlavour.NEGATIVE]: NegativeIconLockClosed,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLockClosed,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLockClosed,
    },

    [IconName.ARRANGE]: {
        [IconFlavour.POSITIVE]: PositiveIconArrange,
        [IconFlavour.NEGATIVE]: NegativeIconArrange,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconArrange,
        [IconFlavour.SOLID_DARK]: SolidDarkIconArrange,
    },

    [IconName.SORT]: {
        [IconFlavour.POSITIVE]: PositiveIconSort,
        [IconFlavour.NEGATIVE]: NegativeIconSort,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSort,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSort,
    },

    [IconName.SORT_AZ]: {
        [IconFlavour.POSITIVE]: PositiveIconSortAZ,
        [IconFlavour.NEGATIVE]: NegativeIconSortAZ,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSortAZ,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSortAZ,
    },

    [IconName.RESIZE]: {
        [IconFlavour.POSITIVE]: PositiveIconResize,
        [IconFlavour.NEGATIVE]: NegativeIconResize,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconResize,
        [IconFlavour.SOLID_DARK]: SolidDarkIconResize,
    },

    [IconName.RIGHT]: {
        [IconFlavour.POSITIVE]: PositiveIconRight,
        [IconFlavour.NEGATIVE]: NegativeIconRight,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconRight,
        [IconFlavour.SOLID_DARK]: SolidDarkIconRight,
    },

    [IconName.UP]: {
        [IconFlavour.POSITIVE]: PositiveIconUp,
        [IconFlavour.NEGATIVE]: NegativeIconUp,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconUp,
        [IconFlavour.SOLID_DARK]: SolidDarkIconUp,
    },

    [IconName.LEFT]: {
        [IconFlavour.POSITIVE]: PositiveIconLeft,
        [IconFlavour.NEGATIVE]: NegativeIconLeft,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLeft,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLeft,
    },

    [IconName.DOWN]: {
        [IconFlavour.POSITIVE]: PositiveIconDown,
        [IconFlavour.NEGATIVE]: NegativeIconDown,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconDown,
        [IconFlavour.SOLID_DARK]: SolidDarkIconDown,
    },

    [IconName.EDIT]: {
        [IconFlavour.POSITIVE]: PositiveIconEdit,
        [IconFlavour.NEGATIVE]: NegativeIconEdit,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconEdit,
        [IconFlavour.SOLID_DARK]: SolidDarkIconEdit,
    },

    [IconName.DELETE]: {
        [IconFlavour.POSITIVE]: PositiveIconDelete,
        [IconFlavour.NEGATIVE]: NegativeIconDelete,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconDelete,
        [IconFlavour.SOLID_DARK]: SolidDarkIconDelete,
    },

    [IconName.FAVORITE]: {
        [IconFlavour.POSITIVE]: PositiveIconFavorite,
        [IconFlavour.NEGATIVE]: NegativeIconFavorite,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconFavorite,
        [IconFlavour.SOLID_DARK]: SolidDarkIconFavorite,
    },

    [IconName.FAVORITE_ACTIVE]: {
        [IconFlavour.POSITIVE]: PositiveIconFavoriteActive,
        [IconFlavour.NEGATIVE]: NegativeIconFavoriteActive,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconFavoriteActive,
        [IconFlavour.SOLID_DARK]: SolidDarkIconFavoriteActive,
    },
    [IconName.RECORD]: {
        [IconFlavour.POSITIVE]: PositiveIconRecord,
        [IconFlavour.NEGATIVE]: NegativeIconRecord,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconRecord,
        [IconFlavour.SOLID_DARK]: SolidDarkIconRecord,
    },
    [IconName.CANCEL_RECORD]: {
        [IconFlavour.POSITIVE]: PositiveIconCancelRecord,
        [IconFlavour.NEGATIVE]: NegativeIconCancelRecord,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconCancelRecord,
        [IconFlavour.SOLID_DARK]: SolidDarkIconCancelRecord,
    },

    [IconName.BUY]: {
        [IconFlavour.POSITIVE]: PositiveIconBuy,
        [IconFlavour.NEGATIVE]: NegativeIconBuy,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconBuy,
        [IconFlavour.SOLID_DARK]: SolidDarkIconBuy,
    },

    [IconName.VOICE_INPUT]: {
        [IconFlavour.POSITIVE]: PositiveIconVoiceInput,
        [IconFlavour.NEGATIVE]: NegativeIconVoiceInput,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconVoiceInput,
        [IconFlavour.SOLID_DARK]: SolidDarkIconVoiceInput,
    },

    [IconName.VISIBLE]: {
        [IconFlavour.POSITIVE]: PositiveIconVisible,
        [IconFlavour.NEGATIVE]: NegativeIconVisible,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconVisible,
        [IconFlavour.SOLID_DARK]: SolidDarkIconVisible,
    },

    [IconName.HIDDEN]: {
        [IconFlavour.POSITIVE]: PositiveIconHidden,
        [IconFlavour.NEGATIVE]: NegativeIconHidden,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconHidden,
        [IconFlavour.SOLID_DARK]: SolidDarkIconHidden,
    },

    [IconName.PLAY]: {
        [IconFlavour.POSITIVE]: PositiveIconPlay,
        [IconFlavour.NEGATIVE]: NegativeIconPlay,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconPlay,
        [IconFlavour.SOLID_DARK]: SolidDarkIconPlay,
    },

    [IconName.PAUSE]: {
        [IconFlavour.POSITIVE]: PositiveIconPause,
        [IconFlavour.NEGATIVE]: NegativeIconPause,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconPause,
        [IconFlavour.SOLID_DARK]: SolidDarkIconPause,
    },

    [IconName.ERROR]: {
        [IconFlavour.POSITIVE]: PositiveIconError,
        [IconFlavour.NEGATIVE]: NegativeIconError,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconError,
        [IconFlavour.SOLID_DARK]: SolidDarkIconError,
    },

    [IconName.INFO]: {
        [IconFlavour.POSITIVE]: PositiveIconInfo,
        [IconFlavour.NEGATIVE]: NegativeIconInfo,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconInfo,
        [IconFlavour.SOLID_DARK]: SolidDarkIconInfo,
    },

    [IconName.START_OVER]: {
        [IconFlavour.POSITIVE]: PositiveIconStartOver,
        [IconFlavour.NEGATIVE]: NegativeIconStartOver,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconStartOver,
        [IconFlavour.SOLID_DARK]: SolidDarkIconStartOver,
    },

    [IconName.IMAGE]: {
        [IconFlavour.POSITIVE]: PositiveIconImage,
        [IconFlavour.NEGATIVE]: NegativeIconImage,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconImage,
        [IconFlavour.SOLID_DARK]: SolidDarkIconImage,
    },

    [IconName.MENU_HORIZONTAL]: {
        [IconFlavour.POSITIVE]: PositiveIconMenuHorizontal,
        [IconFlavour.NEGATIVE]: NegativeIconMenuHorizontal,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconMenuHorizontal,
        [IconFlavour.SOLID_DARK]: SolidDarkIconMenuHorizontal,
    },

    [IconName.MENU_VERTICAL]: {
        [IconFlavour.POSITIVE]: PositiveIconMenuVertical,
        [IconFlavour.NEGATIVE]: NegativeIconMenuVertical,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconMenuVertical,
        [IconFlavour.SOLID_DARK]: SolidDarkIconMenuVertical,
    },

    [IconName.CATCHUP]: {
        [IconFlavour.POSITIVE]: PositiveIconCatchup,
        [IconFlavour.NEGATIVE]: NegativeIconCatchup,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconCatchup,
        [IconFlavour.SOLID_DARK]: SolidDarkIconCatchup,
    },

    [IconName.REMINDER]: {
        [IconFlavour.POSITIVE]: PositiveIconReminder,
        [IconFlavour.NEGATIVE]: NegativeIconReminder,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconReminder,
        [IconFlavour.SOLID_DARK]: SolidDarkIconReminder,
    },

    [IconName.DOLBY]: {
        [IconFlavour.POSITIVE]: PositiveIconDolby,
        [IconFlavour.NEGATIVE]: NegativeIconDolby,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconDolby,
        [IconFlavour.SOLID_DARK]: SolidDarkIconDolby,
    },

    [IconName.INBOX]: {
        [IconFlavour.POSITIVE]: InboxPositive,
        [IconFlavour.NEGATIVE]: InboxNegative,
        [IconFlavour.SOLID_LIGHT]: InboxSolidLight,
        [IconFlavour.SOLID_DARK]: InboxSolidDark,
    },
    [IconName.SD]: {
        [IconFlavour.POSITIVE]: PositiveIconSD,
        [IconFlavour.NEGATIVE]: NegativeIconSD,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconSD,
        [IconFlavour.SOLID_DARK]: SolidDarkIconSD,
    },
    [IconName.HD]: {
        [IconFlavour.POSITIVE]: PositiveIconHD,
        [IconFlavour.NEGATIVE]: NegativeIconHD,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconHD,
        [IconFlavour.SOLID_DARK]: SolidDarkIconHD,
    },
    [IconName.UHD]: {
        [IconFlavour.POSITIVE]: PositiveIconUHD,
        [IconFlavour.NEGATIVE]: NegativeIconUHD,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconUHD,
        [IconFlavour.SOLID_DARK]: SolidDarkIconUHD,
    },
    [IconName.FORWARD]: {
        [IconFlavour.POSITIVE]: PositiveIconForward,
        [IconFlavour.NEGATIVE]: NegativeIconForward,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconForward,
        [IconFlavour.SOLID_DARK]: SolidDarkIconForward,
    },
    [IconName.REWIND]: {
        [IconFlavour.POSITIVE]: PositiveIconRewind,
        [IconFlavour.NEGATIVE]: NegativeIconRewind,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconRewind,
        [IconFlavour.SOLID_DARK]: SolidDarkIconRewind,
    },
    [IconName.AUDIO_SUBTITLES]: {
        [IconFlavour.POSITIVE]: PositiveIconAudioSubtitles,
        [IconFlavour.NEGATIVE]: NegativeIconAudioSubtitles,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconAudioSubtitles,
        [IconFlavour.SOLID_DARK]: SolidDarkIconAudioSubtitles,
    },
    [IconName.LIVE]: {
        [IconFlavour.POSITIVE]: PositiveIconLive,
        [IconFlavour.NEGATIVE]: NegativeIconLive,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconLive,
        [IconFlavour.SOLID_DARK]: SolidDarkIconLive,
    },
    [IconName.DIRECTOR]: {
        [IconFlavour.POSITIVE]: PositiveDirectorIcon,
        [IconFlavour.NEGATIVE]: NegativeDirectorIcon,
        [IconFlavour.SOLID_LIGHT]: SolidLightDirectorIcon,
        [IconFlavour.SOLID_DARK]: SolidDarkDirectorIcon,
    },
    [IconName.PEOPLE]: {
        [IconFlavour.POSITIVE]: PositivePeopleIcon,
        [IconFlavour.NEGATIVE]: NegativePeopleIcon,
        [IconFlavour.SOLID_LIGHT]: SolidLightPeopleIcon,
        [IconFlavour.SOLID_DARK]: SolidDarkPeopleIcon,
    },
    [IconName.CHANNEL_LIST]: {
        [IconFlavour.POSITIVE]: PositiveChannelListIcon,
        [IconFlavour.NEGATIVE]: NegativeChannelListIcon,
        [IconFlavour.SOLID_LIGHT]: SolidLightChannelListIcon,
        [IconFlavour.SOLID_DARK]: SolidDarkChannelListIcon,
    },
    [IconName.FAVORITE_OLD]: {
        [IconFlavour.POSITIVE]: PositiveFavoriteOldIcon,
        [IconFlavour.NEGATIVE]: NegativeFavoriteOldIcon,
        [IconFlavour.SOLID_LIGHT]: SolidLightFavoriteOldIcon,
        [IconFlavour.SOLID_DARK]: SolidDarkFavoriteOldIcon,
    },
    [IconName.FAVORITE_EMPTY_OLD]: {
        [IconFlavour.POSITIVE]: PositiveFavoriteEmptyOldIcon,
        [IconFlavour.NEGATIVE]: NegativeFavoriteEmptyOldIcon,
        [IconFlavour.SOLID_LIGHT]: SolidLightFavoriteEmptyOldIcon,
        [IconFlavour.SOLID_DARK]: SolidDarkFavoriteEmptyOldIcon,
    },
    [IconName.NEW_ADD]: {
        [IconFlavour.POSITIVE]: PositiveIconNewAdd,
        [IconFlavour.NEGATIVE]: NegativeIconNewAdd,
        [IconFlavour.SOLID_LIGHT]: SolidLightIconNewAdd,
        [IconFlavour.SOLID_DARK]: SolidDarkIconNewAdd,
    },
};

interface Props {
    className?: string;
    flavour?: IconFlavour;
    type?: IconType;
    isFocused?: boolean;
    name: IconName;
}

const getIconFlavour = (isDarkTheme: boolean, isFocused: boolean, flavour: IconFlavour | undefined, type: IconType | undefined) => {
    if (flavour) return flavour;
    if (!type || type === IconType.SOLID) return isDarkTheme || isFocused ? IconFlavour.SOLID_DARK : IconFlavour.SOLID_LIGHT;
    if (type === IconType.NEGATIVE || isFocused) return IconFlavour.NEGATIVE;
    return IconFlavour.POSITIVE;
};

export const Icon: React.FunctionComponent<Props> = (props) => {
    const isDarkTheme = useIsDarkTheme();
    const flavour = getIconFlavour(isDarkTheme, props.isFocused ?? false, props.flavour, props.type);
    const Component = componentForIconName[props.name][flavour];
    return <Component className={props.className} />;
};
