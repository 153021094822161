import {
    ConfigServiceConfigLayoutConfig,
    ConfigServiceConfigLayoutConfigColors,
} from '__SMART_APP_OLD__/app/modules/ConfigService/ConfigServiceConfig';

const variableNameForConfigKey: ConfigServiceConfigLayoutConfigColors = {
    tv_background_dark_base_primary: '--tv-background-dark-base-primary',
    tv_background_dark_base_secondary: '--tv-background-dark-base-secondary',
    tv_background_dark_base_tertiary: '--tv-background-dark-base-tertiary',
    tv_background_light_primary: '--tv-background-light-primary',
    tv_background_light_secondary: '--tv-background-light-secondary',
    tv_background_light_tertiary: '--tv-background-light-tertiary',
    tv_stripe_item_shadow_a1_gray_light_default: '--tv-stripe-item-shadow-a1-gray-light-default',
    tv_stripe_item_shadow_a1_gray_light_selected: '--tv-stripe-item-shadow-a1-gray-light-selected',
    tv_stripe_item_shadow_a1_gray_dark_default: '--tv-stripe-item-shadow-a1-gray-dark-default',
    tv_stripe_item_shadow_a1_gray_dark_selected: '--tv-stripe-item-shadow-a1-gray-dark-selected',
    tv_button_background_a1_cool_blue_dark_selected: '--tv-button-background-a1-cool-blue-dark-selected',
    tv_button_background_a1_cool_blue_light_selected: '--tv-button-background-a1-cool-blue-light-selected',
    tv_button_background_a1_hot_red_dark_selected: '--tv-button-background-a1-hot-red-dark-selected',
    tv_button_background_a1_hot_red_light_selected: '--tv-button-background-a1-hot-red-light-selected',
    tv_button_background_a1_gray_dark_primary: '--tv-button-background-a1-gray-dark-primary',
    tv_button_background_a1_gray_dark_secondary: '--tv-button-background-a1-gray-dark-secondary',
    tv_button_background_a1_gray_light_primary: '--tv-button-background-a1-gray-light-primary',
    tv_button_background_a1_gray_light_secondary: '--tv-button-background-a1-gray-light-secondary',
    tv_button_shadow_a1_gray_light_selected: '--tv-button-shadow-a1-gray-light-selected',
    tv_button_shadow_a1_gray_dark_selected: '--tv-button-shadow-a1-gray-dark-selected',
    tv_fill_colors_a1_gray_dark_primary: '--tv-fill-colors-a1-gray-dark-primary',
    tv_fill_colors_a1_gray_dark_secondary: '--tv-fill-colors-a1-gray-dark-secondary',
    tv_fill_colors_a1_gray_dark_tertiary: '--tv-fill-colors-a1-gray-dark-tertiary',
    tv_fill_colors_a1_gray_dark_quaternary: '--tv-fill-colors-a1-gray-dark-quaternary',
    tv_fill_colors_a1_gray_light_primary: '--tv-fill-colors-a1-gray-light-primary',
    tv_fill_colors_a1_gray_light_secondary: '--tv-fill-colors-a1-gray-light-secondary',
    tv_fill_colors_a1_gray_light_tertiary: '--tv-fill-colors-a1-gray-light-tertiary',
    tv_fill_colors_a1_gray_light_quaternary: '--tv-fill-colors-a1-gray-light-quaternary',
    tv_indicator_light: '--tv-indicator-light',
    tv_indicator_dark: '--tv-indicator-dark',
    tv_label_colors_a1_hot_red_dark_primary: '--tv-label-colors-a1-hot-red-dark-primary',
    tv_label_colors_a1_hot_red_dark_tertiary: '--tv-label-colors-a1-hot-red-dark-tertiary',
    tv_label_colors_a1_hot_red_light_primary: '--tv-label-colors-a1-hot-red-light-primary',
    tv_label_colors_a1_hot_red_light_tertiary: '--tv-label-colors-a1-hot-red-light-tertiary',
    tv_label_colors_a1_hot_red_light_quaternary: '--tv-label-colors-a1-hot-red-light-quaternary',
    tv_label_colors_a1_hot_red_dark_quaternary: '--tv-label-colors-a1-hot-red-dark-quaternary',
    tv_label_colors_a1_cool_blue_dark_primary: '--tv-label-colors-a1-cool-blue-dark-primary',
    tv_label_colors_a1_cool_blue_light_primary: '--tv-label-colors-a1-cool-blue-light-primary',
    tv_label_colors_a1_gray_dark_primary: '--tv-label-colors-a1-gray-dark-primary',
    tv_label_colors_a1_gray_dark_secondary: '--tv-label-colors-a1-gray-dark-secondary',
    tv_label_colors_a1_gray_dark_tertiary: '--tv-label-colors-a1-gray-dark-tertiary',
    tv_label_colors_a1_gray_dark_quaternary: '--tv-label-colors-a1-gray-dark-quaternary',
    tv_label_colors_a1_gray_light_primary: '--tv-label-colors-a1-gray-light-primary',
    tv_label_colors_a1_gray_light_secondary: '--tv-label-colors-a1-gray-light-secondary',
    tv_label_colors_a1_gray_light_tertiary: '--tv-label-colors-a1-gray-light-tertiary',
    tv_label_colors_a1_gray_light_quaternary: '--tv-label-colors-a1-gray-light-quaternary',
    tv_overlay_background_a1_gray_dark_no_blur_supported: '--tv-overlay-background-a1-gray-dark-no-blur-supported',
    tv_overlay_background_a1_gray_light_no_blur_supported: '--tv-overlay-background-a1-gray-light-no-blur-supported',
    tv_overlay_background_a1_gray_dark: '--tv-overlay-background-a1-gray-dark',
    tv_overlay_background_a1_gray_light: '--tv-overlay-background-a1-gray-light',
    tv_functional_dark_activeadblock: '--tv-functional-dark-activeadblock',
    tv_functional_light_activeadblock: '--tv-functional-light-activeadblock',
};

const alphasForConfigKey: Partial<Record<keyof ConfigServiceConfigLayoutConfigColors, number[]>> = {
    tv_background_light_primary: [0.95, 0.77, 0.7, 0.5, 0.4, 0.2],
    tv_background_dark_base_primary: [0.95, 0.77, 0.7, 0.5, 0.4, 0.2],
};

/**
 *
 * @param hex - hex string color
 * @returns true if the provided string is a valid hex color and false otherwise
 */
const isValidHex = (hex: string): boolean => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

/**
 *
 * @param st
 * @param chunkSize
 * @returns array of string with chunkSize length
 */
const getChunksFromString = (st: string, chunkSize: number) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

/**
 *
 * @param hexStr hex number
 * @returns hex number converted to int
 */
const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

/**
 *
 * @param a from 0 to 255
 * @param alpha default number from 0 - 1 (decimal)
 * @returns if a is valid calculates alpha (number from 0 to 1)
 */
const getAlphafloat = (a: number, alpha: number): number => {
    if (typeof a !== 'undefined') return +(a / 255).toFixed(2);
    if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) return 1;
    return alpha;
};

/**
 *
 * @param hex  hex color string
 * @param alpha from 0 to 1
 * @returns  if provided a valid hex color returns
 * rgba representation of the hex color, throws error otherwise
 */
const transformHexToRGBA = (hex: string, alpha: number): string => {
    if (!isValidHex(hex)) throw new Error('Invalid HEX');
    const hexValue = hex.slice(1);
    const chunkSize = Math.floor(hexValue.length / 3);
    const hexArr = getChunksFromString(hexValue, chunkSize);
    if (!hexArr) throw new Error('Invalid HEX');
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
    return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};

/**
 *
 * @param variableName value from keyVariableKeyMap
 * @param hexColor hex color string
 *
 * Applies the hexColor as a value to the variableName in the document element styles
 * @param alpha
 */
const setStyleVarable = (variableName: string, hexColor: string, alpha: number = 1): void => {
    try {
        const rgbaColor = transformHexToRGBA(hexColor, alpha);
        document.documentElement.style.setProperty(variableName, rgbaColor);
    } catch (error) {
        console.log('Setting Style Variable Failed');
    }
};

/**
 *
 * @param config Object with key - value pairs where key is a key from keyVariableKeyMap
 * and value is a hex color string
 *
 * If provided a valid config object it injects the colors as variables
 * to the document element styles
 */
export const injectStyleVariables = (config: Partial<ConfigServiceConfigLayoutConfig>): void => {
    if (!config || typeof config !== 'object') return;
    const keys = Object.keys(variableNameForConfigKey) as (keyof ConfigServiceConfigLayoutConfigColors)[];
    keys.forEach((key) => {
        const variableName = variableNameForConfigKey[key];
        const color = config[key];
        if (typeof variableName !== 'string' || typeof color !== 'string') return;
        setStyleVarable(variableName, color);
        const alphas = alphasForConfigKey[key];
        if (!alphas?.length) return;
        alphas.forEach((alpha) => {
            const variableNameWithAlpha = `${variableName}-${alpha * 100}`;
            setStyleVarable(variableNameWithAlpha, color, alpha);
        });
    });
};
