import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { RecordingDetailedFragmentDoc } from '../fragments/recording_detailed.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordingLoadManyQueryVariables = Types.Exact<{
  pagination: Types.FullPaginationParams;
  filter?: Types.InputMaybe<Types.RecordingsFilterParams>;
  backgroundWidth: Types.Scalars['Int']['input'];
  backgroundHeight: Types.Scalars['Int']['input'];
}>;


export type RecordingLoadManyQuery = { __typename?: 'Nexx4Queries', recordings?: { __typename?: 'RecordingsConnection', id: string, expiry: any, totalCount?: number | null, edges: Array<{ __typename?: 'RecordingsEdge', node: { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } } } | null> } | null };

export type RecordingLoadManyFragment = { __typename?: 'Nexx4Queries', recordings?: { __typename?: 'RecordingsConnection', id: string, expiry: any, totalCount?: number | null, edges: Array<{ __typename?: 'RecordingsEdge', node: { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } } } | null> } | null };

export const RecordingLoadManyFragmentDoc = gql`
    fragment recordingLoadMany on Nexx4Queries {
  recordings(pagination: $pagination, filter: $filter) {
    id
    expiry
    totalCount
    edges {
      node {
        ... on NetworkRecording {
          ...recordingDetailed
        }
      }
    }
  }
}
    ${RecordingDetailedFragmentDoc}`;
export const RecordingLoadManyDocument = gql`
    query recordingLoadMany($pagination: FullPaginationParams!, $filter: RecordingsFilterParams, $backgroundWidth: Int!, $backgroundHeight: Int!) {
  ...recordingLoadMany
}
    ${RecordingLoadManyFragmentDoc}`;

/**
 * __useRecordingLoadManyQuery__
 *
 * To run a query within a React component, call `useRecordingLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingLoadManyQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      backgroundWidth: // value for 'backgroundWidth'
 *      backgroundHeight: // value for 'backgroundHeight'
 *   },
 * });
 */
export function useRecordingLoadManyQuery(baseOptions: Apollo.QueryHookOptions<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>(RecordingLoadManyDocument, options);
      }
export function useRecordingLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>(RecordingLoadManyDocument, options);
        }
export function useRecordingLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>(RecordingLoadManyDocument, options);
        }
export type RecordingLoadManyQueryHookResult = ReturnType<typeof useRecordingLoadManyQuery>;
export type RecordingLoadManyLazyQueryHookResult = ReturnType<typeof useRecordingLoadManyLazyQuery>;
export type RecordingLoadManySuspenseQueryHookResult = ReturnType<typeof useRecordingLoadManySuspenseQuery>;
export type RecordingLoadManyQueryResult = Apollo.QueryResult<RecordingLoadManyQuery, RecordingLoadManyQueryVariables>;