import React from 'react';

import Elements from '__SMART_APP_OLD__/navigation/Elements';
import Node from '__SMART_APP_OLD__/navigation/Node';

export default class Focusable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { stashId } = this.props;
        Elements.add(this.nodeRef, stashId);
    }

    componentWillUnmount() {
        const { stashId } = this.props;
        Elements.remove(this.nodeRef, stashId);
    }

    render() {
        const {
            children,
            className,
            id,
            onChange,
            onFocus,
            onBlur,
            onEnter,
            onKey,
            onMouseMove,
            onCustomRect,
            usePointer = true,
            style,
            tag,
            defaultValue,
            focusDelay = true,
            placeholder,
            type,
            dataTestId,
            scrollable,
            scrollTop,
            overwriteBack,
            value,
        } = this.props;
        const classList = `focusable${className ? ` ${className}` : ''}`;
        const Component = tag || 'div';

        return (
            <Component
                style={style}
                ref={(node) => {
                    this.nodeRef = new Node(
                        node,
                        false,
                        false,
                        onFocus,
                        onBlur,
                        onEnter,
                        onKey,
                        onMouseMove,
                        undefined,
                        onCustomRect,
                        usePointer,
                        false,
                        focusDelay,
                        undefined,
                        scrollable,
                        scrollTop
                    );
                }}
                onChange={onChange}
                className={classList}
                id={id}
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={type}
                data-test-id={dataTestId}
                value={value}
                data-overwrite-back={overwriteBack ?? false}
            >
                {children}
            </Component>
        );
    }
}
