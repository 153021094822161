import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React from 'react';

export type KeyboardLayoutKeyProps = UI.KeyProps & {
    keyboard: KeyboardElement;
};

export const KeyboardLayoutKey: React.FunctionComponent<KeyboardLayoutKeyProps> = (props) => {
    const { keyboard, variant = 'secondary', ...other } = props;
    const layout = Subscribable.use(keyboard.layout);
    const children = layout.schema.name === 'default' ? '!#?' : 'ABC';
    return (
        <UI.Keyboard.Key variant={variant} {...other}>
            <UI.Keyboard.Key.Label variant="uppercase" size="small">
                {children}
            </UI.Keyboard.Key.Label>
        </UI.Keyboard.Key>
    );
};
