import { useEffect } from 'react';

import { Value } from 'App/Packages/Subscribable/Core/Value';

const setter = <T>(subscribable: Value<T> | undefined, value: T): void => {
    if (!subscribable) return;
    subscribable.set(value);
};

export type UseSetter = <T>(subscribable: Value<T> | undefined, value: T) => void;

export const useSetter: UseSetter = (subscribable, value) => useEffect(() => setter(subscribable, value), [subscribable, value]);
