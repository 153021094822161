import { KeyboardKeySchema } from 'App/Modules/Keyboard/types';
import { Subscribable } from 'App/Packages/Subscribable';

export class KeyboardKeyElement {
    public readonly schema: KeyboardKeySchema;

    public readonly size: number;

    public readonly focused: Subscribable.Value<boolean>;

    constructor(schema: KeyboardKeySchema) {
        this.schema = schema;
        this.focused = new Subscribable.Value<boolean>(false);
        this.size = this.getElementSize();
    }

    public focus() {
        this.focused.set(true);
    }

    public blur() {
        this.focused.set(false);
    }

    private getElementSize(): number {
        if (this.schema.size === 'x1') return 1;
        if (this.schema.size === 'x2') return 2;
        if (this.schema.size === 'x3') return 3;
        if (this.schema.type === 'key.space') return 3;
        if (this.schema.type === 'key.enter') return 2;
        return 1;
    }
}
