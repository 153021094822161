export interface SessionStorageData {
    appStartEventTime?: number;
    loggingSessions: LoggingSessions;
}

export enum SessionTypes {
    APPLICATION = 'Application',
    USER = 'User',
    VIEW = 'View',
}

export interface LoggingSessions {
    [SessionTypes.USER]: string | null;
    [SessionTypes.APPLICATION]: string | null;
    [SessionTypes.VIEW]: string | null;
}

const defultLoggingSessions = {
    [SessionTypes.APPLICATION]: null,
    [SessionTypes.USER]: null,
    [SessionTypes.VIEW]: null,
};

const getSessionData = (): SessionStorageData =>
    sessionStorage.nexx4_uar_session_data ? JSON.parse(sessionStorage.nexx4_uar_session_data) : {};

const setSessionData = (data: SessionStorageData): void => {
    sessionStorage.nexx4_uar_session_data = JSON.stringify(data);
};

export const getAppStartEventTime = (): number | undefined => getSessionData().appStartEventTime;

export const setAppStartEventTime = (eventTime: number) => {
    const data = getSessionData();
    data.appStartEventTime = eventTime;
    setSessionData(data);
};

export const getLoggingSessions = (): LoggingSessions => getSessionData().loggingSessions ?? defultLoggingSessions;

export const isAnySessionStarted = (): boolean => Object.values(getLoggingSessions()).some((session) => !!session);

export const setLoggingSessions = (sessions: LoggingSessions): void => {
    const data = getSessionData();
    data.loggingSessions = sessions;
    setSessionData(data);
};
