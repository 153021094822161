import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Prompt } from 'App/Modules/Prompt';
import { actions } from 'App/Modules/Prompt/Modules/LogoutPrompt/Store/actions';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

const actionsIds = [Translation.Key.Logout, Translation.Key.Cancel];

export const View: React.FunctionComponent<Prompt.Props<Prompt.Type.Logout>> = () => {
    const dispatch = useDispatch();

    return (
        <UI.Alert zIndex={Prompt.zIndex} onClose={() => dispatch(Prompt.actions.unmount())}>
            <UI.Alert.Title>{Translation.Key.Prompt.Logout.Title}</UI.Alert.Title>
            <UI.Alert.Text>{Translation.Key.Prompt.Logout.Text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action id={Translation.Key.Logout} onEnter={() => dispatch(actions.private.logout())}>
                    {Translation.Key.Logout}
                </UI.Alert.Action>
                <UI.Alert.Action id={Translation.Key.Cancel} onEnter={() => dispatch(actions.private.cancel())}>
                    {Translation.Key.Cancel}
                </UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
