import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    CanRecordEventDocument,
    CanRecordEventQueryResult,
    CanRecordEventQueryVariables,
} from '__SMART_APP_OLD__/app/api/event/query/canRecordEvent.generated';
import {
    IsEventRecordableDocument,
    IsEventRecordableQueryResult,
    IsEventRecordableQueryVariables,
} from '__SMART_APP_OLD__/app/api/event/query/isEventRecordable.generated';
import {
    IsEventRecordedDocument,
    IsEventRecordedQueryResult,
    IsEventRecordedQueryVariables,
} from '__SMART_APP_OLD__/app/api/event/query/isEventRecorded.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Utils } from '__SMART_APP_OLD__/app/utils';

/**
 * @namespace API.Event
 */

/**
 * @memberof API.Event
 * @description Determines if event is eligible for recording
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param eventId id of the event
 * @returns boolean if the event is recordable
 */
export const isRecordable = async (eventId: GQL.EventID): Promise<boolean> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: IsEventRecordableQueryVariables = { eventId };
    const response: IsEventRecordableQueryResult = await GraphqlClient.makeGraphqlRequest(IsEventRecordableDocument, variables, options);
    Error.collect(response);

    return Utils.isEventRecordable(
        response.data?.event?.entitlements?.networkRecording ?? false,
        response?.data?.event?.start,
        response?.data?.event?.end,
        response?.data?.event?.entitlements?.networkRecordingPlannableUntil
    );
};

/**
 * @memberof API.Event
 * @description Determines if recording is created for the requested event
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param eventId id of event
 * @param profileId id of the used profile
 * @returns  boolean if the asset is already recorded
 */
export const isRecorded = async (eventId: GQL.EventID, profileId: GQL.ProfileID): Promise<boolean> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: IsEventRecordedQueryVariables = { eventId, profileId };
    const response: IsEventRecordedQueryResult = await GraphqlClient.makeGraphqlRequest(IsEventRecordedDocument, variables, options);
    Error.collect(response);
    return !!response.data?.event?.personalInfo?.recordings?.length;
};

/**
 * @memberof API.Event
 * @description Determines if event is eligible for recording and no recording is created yet
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param eventId id of event
 * @param profileId id of the used profile
 * @returns boolean if assset can be recorded
 */
export const canRecord = async (eventId: GQL.EventID, profileId: GQL.ProfileID): Promise<boolean> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: CanRecordEventQueryVariables = { eventId, profileId };
    const response: CanRecordEventQueryResult = await GraphqlClient.makeGraphqlRequest(CanRecordEventDocument, variables, options);
    Error.collect(response);

    return (
        Utils.isEventRecordable(
            response.data?.event?.entitlements?.networkRecording ?? false,
            response?.data?.event?.start,
            response?.data?.event?.end,
            response?.data?.event?.entitlements?.networkRecordingPlannableUntil
        ) && !response.data?.event?.personalInfo?.recordings?.length
    );
};
