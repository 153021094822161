import { useMemo, useRef } from 'react';

import { FolderValue } from 'App/Packages/UI/Folder/Modules/Folder/Context/FolderContext';
import {
    UseFolderScrollIntoViewGetTop,
    useFolderScrollIntoView,
} from 'App/Packages/UI/Folder/Modules/Folder/Hooks/Private/useFolderScrollIntoView';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';

export type UseFolderValuePayload = {
    id: string;
    ctx?: string;
    getTop?: UseFolderScrollIntoViewGetTop;
};

export type UseFolderValue = (payload: UseFolderValuePayload) => FolderValue;

export const useFolderValue: UseFolderValue = (payload) => {
    const list = FolderList.use();
    const { id } = payload;
    const ref = useRef<HTMLDivElement | null>(null);
    const ctx = payload.ctx ?? list.ctx;
    const { disabled } = list;
    const scrollIntoView = useFolderScrollIntoView(ref, payload.getTop);

    return useMemo(() => ({ id, ref, ctx, disabled, scrollIntoView }), [id, ref, ctx, disabled, scrollIntoView]);
};
