import { Array } from 'App/Packages/Subscribable/Core/Array';
import { Setter, Subscribe, UnSubscribe, Register, UnRegister } from 'App/Packages/Subscribable/Types';

export class MockedArray<T> extends Array<T> {
    public readonly set: Setter<T[]> = async () => {};

    public readonly subscribe: Subscribe = () => () => {};

    public readonly unsubscribe: UnSubscribe = () => {};

    public readonly register: Register<T> = () => () => {};

    public readonly unregister: UnRegister<T> = () => {};
}
