import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ConsentFragmentDoc } from '../fragments/consent.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsentsLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ConsentsLoadQuery = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', device: { __typename?: 'Device', activeProfile: { __typename?: 'Profile', preferences: { __typename?: 'ProfilePreferences', consents: Array<{ __typename?: 'ProfileConsent', accepted: boolean, type: any } | null> } } } } | null };

export type ConsentsLoadFragment = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', device: { __typename?: 'Device', activeProfile: { __typename?: 'Profile', preferences: { __typename?: 'ProfilePreferences', consents: Array<{ __typename?: 'ProfileConsent', accepted: boolean, type: any } | null> } } } } | null };

export const ConsentsLoadFragmentDoc = gql`
    fragment consentsLoad on Nexx4Queries {
  me {
    device {
      activeProfile {
        preferences {
          consents {
            ...consent
          }
        }
      }
    }
  }
}
    ${ConsentFragmentDoc}`;
export const ConsentsLoadDocument = gql`
    query consentsLoad {
  ...consentsLoad
}
    ${ConsentsLoadFragmentDoc}`;

/**
 * __useConsentsLoadQuery__
 *
 * To run a query within a React component, call `useConsentsLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentsLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentsLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsentsLoadQuery(baseOptions?: Apollo.QueryHookOptions<ConsentsLoadQuery, ConsentsLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentsLoadQuery, ConsentsLoadQueryVariables>(ConsentsLoadDocument, options);
      }
export function useConsentsLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentsLoadQuery, ConsentsLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentsLoadQuery, ConsentsLoadQueryVariables>(ConsentsLoadDocument, options);
        }
export function useConsentsLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConsentsLoadQuery, ConsentsLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsentsLoadQuery, ConsentsLoadQueryVariables>(ConsentsLoadDocument, options);
        }
export type ConsentsLoadQueryHookResult = ReturnType<typeof useConsentsLoadQuery>;
export type ConsentsLoadLazyQueryHookResult = ReturnType<typeof useConsentsLoadLazyQuery>;
export type ConsentsLoadSuspenseQueryHookResult = ReturnType<typeof useConsentsLoadSuspenseQuery>;
export type ConsentsLoadQueryResult = Apollo.QueryResult<ConsentsLoadQuery, ConsentsLoadQueryVariables>;