import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectContentMarker } from '__SMART_APP_OLD__/app/modules/Config/selectors';

import { ContentMarkerType } from 'App/Types/ContentMarker';

interface Props {
    marker?: ContentMarkerType | null;
    label?: string;
    className?: string;
}

const getClassName = (props: Props): string => {
    const classList = ['content-marker__container'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const ContentMarker: React.FunctionComponent<Props> = (props) => {
    const contentMarker = useSelector(selectContentMarker(props.marker));

    if (!props.marker || !contentMarker) return null;
    const className = getClassName(props);

    return (
        <div className={className}>
            <div className="content-marker" style={contentMarker.container}>
                <Text
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_2}
                    weight={FontWeight.BOLD}
                    color={Color.CUSTOM}
                    customColor={contentMarker.color}
                >
                    {props.label ? props.label : props.marker}
                </Text>
            </div>
        </div>
    );
};
