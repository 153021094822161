import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum FavouritesActionType {
    LOADED = '@favourites/loaded',
    ADDED = '@favourites/added',
    REMOVED = '@favourites/removed',
}

// Action
export type FavouritesReduxAction = FavouritesLoadedAction | FavouritesAddedAction | FavouritesRemovedAction;

export type FavouritesLoadedActionPayload = { favourites: FavouritesEntity[] };
export type FavouritesLoadedAction = PayloadedAction<FavouritesActionType.LOADED, FavouritesLoadedActionPayload>;

export type FavouritesAddedActionPayload = { id: string };
export type FavouritesAddedAction = PayloadedAction<FavouritesActionType.ADDED, FavouritesAddedActionPayload>;

export type FavouritesRemovedActionPayload = { id: string };
export type FavouritesRemovedAction = PayloadedAction<FavouritesActionType.REMOVED, FavouritesRemovedActionPayload>;

// State
export type FavouritesEntity = {
    id: string;
};

export type FavouritesEntityTable = {
    ids: string[];
};
