import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PurchaseVodProductMutationVariables = Types.Exact<{
  input: Types.PurchaseVodProductInput;
}>;


export type PurchaseVodProductMutation = { __typename?: 'Nexx4Mutations', purchaseVODProduct: { __typename?: 'PurchaseVODProductPayload', product: { __typename?: 'VODProduct', id: string, title: string, price?: { __typename?: 'Price', id: string, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, availableUntil?: any | null } | null } } };

export type PurchaseVodProductFragment = { __typename?: 'Nexx4Mutations', purchaseVODProduct: { __typename?: 'PurchaseVODProductPayload', product: { __typename?: 'VODProduct', id: string, title: string, price?: { __typename?: 'Price', id: string, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, availableUntil?: any | null } | null } } };

export const PurchaseVodProductFragmentDoc = gql`
    fragment purchaseVODProduct on Nexx4Mutations {
  purchaseVODProduct(input: $input) {
    product {
      id
      title
      price {
        id
        amount
        currency
      }
      entitlement {
        id
        availableUntil
      }
    }
  }
}
    `;
export const PurchaseVodProductDocument = gql`
    mutation purchaseVODProduct($input: PurchaseVODProductInput!) {
  ...purchaseVODProduct
}
    ${PurchaseVodProductFragmentDoc}`;
export type PurchaseVodProductMutationFn = Apollo.MutationFunction<PurchaseVodProductMutation, PurchaseVodProductMutationVariables>;

/**
 * __usePurchaseVodProductMutation__
 *
 * To run a mutation, you first call `usePurchaseVodProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseVodProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseVodProductMutation, { data, loading, error }] = usePurchaseVodProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseVodProductMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseVodProductMutation, PurchaseVodProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseVodProductMutation, PurchaseVodProductMutationVariables>(PurchaseVodProductDocument, options);
      }
export type PurchaseVodProductMutationHookResult = ReturnType<typeof usePurchaseVodProductMutation>;
export type PurchaseVodProductMutationResult = Apollo.MutationResult<PurchaseVodProductMutation>;
export type PurchaseVodProductMutationOptions = Apollo.BaseMutationOptions<PurchaseVodProductMutation, PurchaseVodProductMutationVariables>;