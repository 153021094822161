import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Events, { PIN_OVERLAY_HIDE } from '__SMART_APP_OLD__/config/Events';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.PIN_CODE, SettingsCtx.PrimaryList));
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    Events.triggerEvents(PIN_OVERLAY_HIDE);
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === SettingsCtx.PrimaryList && event.y === -1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.PIN_CODE, SettingsCtx.Back));
    }
    if (ctx === SettingsCtx.Back && event.y === 1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.PIN_CODE, SettingsCtx.PrimaryList));
    }
};

const changePin =
    (id: string): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => {
                dispatch(Overlay.actions.unmount());
                Screen.actions.mount(Screen.Type.PIN_CODE, { id });
            },
            () => {
                dispatch(Overlay.actions.unmount());
            },
            PinAction.CHANGE,
            PinSessionType.PIN,
            true
        );
    };

export const pinCodeActions = {
    private: {
        changePin,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
