import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import {
    channelListChange,
    channelListChannelsLoad,
    channelListReorder,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { selectChannelListName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListOption } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/constants';
import { ChannelListsScreenViewMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';
import { ChannelListsScreenManageMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const createSelected = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.push());
    dispatch(Screen.actions.mount(Screen.Type.CHANNEL_LISTS_CREATE, { mode: ChannelListsScreenViewMode.CREATE }));
};

const itemSelected =
    (channelListIds: string[], channelListId: string): Thunk<void> =>
    async (dispatch, getState) => {
        const screen = Screen.selectors.selectTyped(Screen.Type.CHANNEL_LISTS)(getState());

        if (!screen) return undefined;

        if (screen.props.isArranging) {
            await dispatch(channelListReorder(channelListIds, channelListId));
            await dispatch(Screen.actions.update(screen.type, { isArranging: false }));

            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelLists));
        }

        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelListsOptions));
    };

const optionSelected =
    (hoveredChannelListId: string, option: ChannelListOption): Thunk<void> =>
    async (dispatch, getState) => {
        if (option === ChannelListOption.Activate) {
            return dispatch(channelListChange(hoveredChannelListId));
        }

        if (option === ChannelListOption.Rearrange) {
            await dispatch(Screen.actions.update(Screen.Type.CHANNEL_LISTS, { isArranging: true }, ChannelListsScreenCtx.ChannelLists));
        }

        const hoveredChannelListName = selectChannelListName(hoveredChannelListId)(getState());

        if (option === ChannelListOption.Rename) {
            dispatch(History.actions.push());

            return dispatch(
                Screen.actions.mount(Screen.Type.CHANNEL_LISTS_CREATE, {
                    initialInputValue: hoveredChannelListName,
                    mode: ChannelListsScreenViewMode.EDIT,
                    channelListId: hoveredChannelListId,
                })
            );
        }

        if (option === ChannelListOption.ManageChannelLists) {
            dispatch(History.actions.push());
            await dispatch(channelListChannelsLoad(hoveredChannelListId));
            return dispatch(
                Screen.actions.mount(Screen.Type.CHANNEL_LISTS_MANAGE, {
                    mode: ChannelListsScreenManageMode.UPDATE,
                    channelListId: hoveredChannelListId,
                    channelListName: hoveredChannelListName,
                })
            );
        }

        if (option === ChannelListOption.Delete) {
            return dispatch(
                Prompt.actions.mount(Prompt.Type.ChannelListConfirmDelete, {
                    id: hoveredChannelListId,
                })
            );
        }

        return undefined;
    };

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
};

const back = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.pop());
};

const outOfBoundsHandlerForCtx: Record<string, Focus.OutOfBounds> = {
    [SettingsCtx.Back]: (event) => (dispatch) => {
        if (event.y === 1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.CreateChannelList));
        }
        if (event.x === 1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelListsOptions));
        }

        return undefined;
    },
    [ChannelListsScreenCtx.CreateChannelList]: (event) => (dispatch) => {
        if (event.y === 1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelLists));
        }
        if (event.y === -1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, SettingsCtx.Back));
        }
        if (event.x === 1) {
            dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelListsOptions));
        }

        return undefined;
    },
    [ChannelListsScreenCtx.ChannelLists]: (event) => (dispatch) => {
        if (event.x === 1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelListsOptions));
        }
        if (event.y === -1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.CreateChannelList));
        }

        return undefined;
    },
    [ChannelListsScreenCtx.ChannelListsOptions]: (event) => (dispatch) => {
        if (event.x === -1) {
            return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS, ChannelListsScreenCtx.ChannelLists));
        }

        return undefined;
    },
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    const handler = outOfBoundsHandlerForCtx[ctx];
    if (!handler) return;
    dispatch(handler(event, ctx));
};

export const channelListsScreenActions = {
    private: {
        back,
        itemSelected,
        createSelected,
        optionSelected,
    },
    public: {},
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
