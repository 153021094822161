import React from 'react';

import { Label } from '__SMART_APP_OLD__/app/components/Label';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageInboxMessageLabels } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';

interface Props {
    id: GQL.MessageID;
}

export const MessageInboxItemLabels: React.FunctionComponent<Props> = (props) => {
    const labels = useSelector(selectMessageInboxMessageLabels(props.id));
    return (
        <>
            {labels.map((label) => (
                <Label key={label.text} className="message-inbox-item__label" color={label.color}>
                    {label.text}
                </Label>
            ))}
        </>
    );
};
