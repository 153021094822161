import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectSelectedChannelListChannelIds,
    selectSelectedChannelListName,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectEventBy,
    selectEventIsCatchUpTVEnabled,
    selectHasLoadingEvents,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Screen } from 'App/Modules/Screen';

export const selectNowOnTVTitle = (state: State) => {
    const name = selectSelectedChannelListName(state);
    const channelIds = selectSelectedChannelListChannelIds(state);
    return `${name} (${channelIds.length})`;
};

export const selectNowOnTVInitialFocusedItem = (state: State): GQL.ChannelID | null => {
    const screen = Screen.selectors.selectTyped(Screen.Type.NOW_ON_TV)(state);
    if (!screen) return null;
    return screen.props.channelID;
};
export const selectNowOnTVItemsIds = selectSelectedChannelListChannelIds;

export const selectNowOnTVEvent = (id: GQL.ChannelID) => (state: State) =>
    selectEventBy(id, Time.selectors.select(state), { equal: EqualityCompare.START })(state);

export const selectNowOnTVIcons = (id: GQL.EventID) => (state: State): IconName[] => {
    const icons: IconName[] = [];
    if (selectEventIsCatchUpTVEnabled(id)(state)) icons.push(IconName.CATCHUP);
    return icons;
};

export const selectNowOnTVHasLoadingEvents = (state: State) => {
    const channelIds = selectSelectedChannelListChannelIds(state);
    const start = Time.selectors.select(state);
    const end = start + 10 * Time.MINUTE_MS;
    return channelIds.some((id) => selectHasLoadingEvents(id, start, end));
};
