import { GQL } from '__SMART_APP_OLD__/app/gql';

import { getEventContentRef } from '../contentRefGenerators';
import { ReminderEvent, ReminderEvents } from '../events/ReminderEvent';

type GetReminderEventType = (event: ReminderEvents, eventId: GQL.EventID) => ReminderEvent;

export const getReminderEvent: GetReminderEventType = (event, eventId) => {
    const contentRef = getEventContentRef(eventId);

    return new ReminderEvent(event, contentRef);
};
