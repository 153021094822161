import { HouseholdOnboardingInfo, Quota } from '__SMART_APP_OLD__/api/graphql/types';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum HouseholdActionType {
    MQTT_TOPICS_CHANGED = '@household/mqttTopics/changed',
    MQTT_BROKER_URL_CHANGED = '@household/mqttBrokerUrl/changed',
    RECORDING_QUOTA_LOAD = '@household/recordingQuota/load',
    INITIAL_LOAD = '@household/initial/load',
}

export type HouseholdReduxAction = MqttTopicsChangedAction | MqttBrokerUrlChangedAction | RecordingQuotaLoadAction | InitialLoadAction;

// Payloads
export type MqttTopicsChangedActionPayload = { mqttTopics: string[] };
export type MqttBrokerUrlChangedActionPayload = { mqttBrokerUrl: string };
export type RecordingQuotaPayload = { recordingQuota: Quota };
export type HouseholdInitialLoadPayload = {
    householdId: string;
    maxNumberOfConfirmedReplayChannels: number;
    onboardingInfo: HouseholdOnboardingInfo;
};
// Actions
export type MqttTopicsChangedAction = PayloadedAction<HouseholdActionType.MQTT_TOPICS_CHANGED, MqttTopicsChangedActionPayload>;
export type MqttBrokerUrlChangedAction = PayloadedAction<HouseholdActionType.MQTT_BROKER_URL_CHANGED, MqttBrokerUrlChangedActionPayload>;
export type RecordingQuotaLoadAction = PayloadedAction<HouseholdActionType.RECORDING_QUOTA_LOAD, RecordingQuotaPayload>;
export type InitialLoadAction = PayloadedAction<HouseholdActionType.INITIAL_LOAD, HouseholdInitialLoadPayload>;

export type HouseholdEntityTable = {
    mqttTopics: string[];
    mqttBrokerUrl: string | null;
    recordingQuota: Quota | null;
    householdId: string;
    maxNumberOfConfirmedReplayChannels: number;
    onboardingInfo: HouseholdOnboardingInfo | null;
};
