import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { AlertEntity, AlertState, AlertType } from 'App/Modules/Alert/Types';
import { Keyboard } from 'App/Modules/Keyboard';
import { Focus } from 'App/Packages/Focus';

const select = (state: State): AlertState => state.alert;

const selectType = (state: State): AlertType | null => select(state)?.type ?? null;

const selectTyped =
    <T extends AlertType>(type: T) =>
    (state: State): AlertEntity<T> | null => {
        const alert = select(state);
        if (!alert) return null;
        if (alert.type !== type) return null;
        return alert as AlertEntity<T>;
    };

const selectIsLayerActive = (state: State): boolean => !Keyboard.selectors.selectIsActive(state);
const selectIsActive = (state: State): boolean => !!state.alert && selectIsLayerActive(state);

const selectCtx = (state: State): string => select(state)?.ctx ?? Focus.Default;

export const selectors = {
    public: {
        select,
        selectCtx,
        selectType,
        selectTyped,
        selectIsActive,
        selectIsLayerActive,
    },
    private: {},
} as const;
