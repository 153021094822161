import React from 'react';

import { Default as FocusDefault } from 'App/Packages/Focus/Constants/index';
import { Subscribable } from 'App/Packages/Subscribable';

const Default: SuggestionValue = {
    ctx: FocusDefault,
    ref: { current: null },
    value: '',
    focused: new Subscribable.MockedValue(false),
    enter: () => {},
    focus: () => {},
    scrollIntoView: Object.assign(async () => {}, { instant: new Subscribable.MockedValue(false) }),
};

export type SuggestionValue = {
    ctx: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
    value: string;
    focused: Subscribable.Value<boolean>;
    enter: () => void;
    focus: () => void;
    scrollIntoView: (() => Promise<void>) & { instant: Subscribable.Value<boolean> };
};

export type SuggestionContextModule = React.Context<SuggestionValue> & { Default: SuggestionValue };

export const SuggestionContext: SuggestionContextModule = Object.assign(React.createContext(Default), { Default });
