import { Austria } from 'App/Modules/Keyboard/Schema/Austria';
import { Bulgaria } from 'App/Modules/Keyboard/Schema/Bulgaria';
import { Croatia } from 'App/Modules/Keyboard/Schema/Croatia';
import { Macedonia } from 'App/Modules/Keyboard/Schema/Macedonia';
import { Serbia } from 'App/Modules/Keyboard/Schema/Serbia';
import { Slovenia } from 'App/Modules/Keyboard/Schema/Slovenia';

export const Schema = {
    Austria,
    Bulgaria,
    Croatia,
    Macedonia,
    Serbia,
    Slovenia,
};
