import React from 'react';

import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { ReplaySelectionAction } from '__SMART_APP_OLD__/app/modules/Screen/modules/ReplaySelectionScreen/types';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const ReplaySelectionScreenView: React.FunctionComponent<Screen.Props<Screen.Type.REPLAY_SELECTION>> = () => {
    const dispatch = useDispatch();
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items: [ReplaySelectionAction.MANAGE, ReplaySelectionAction.CONTINUE],
        initialFocusedIndex: 1,
    });

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <Layout className="replay-selection" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <Text
                className="replay-selection__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                SETTINGS_VIEW_CONTROL
            </Text>
            <Text
                className="replay-selection__subtitle"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                FTI_VIEW_CONTROL_DESCRIPTION
            </Text>
            <div className="replay-selection__actions-container">
                <Button
                    className="replay-selection__action"
                    id={ReplaySelectionAction.MANAGE}
                    ctx={Focus.Default}
                    width={ButtonWidth.FULL}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === ReplaySelectionAction.MANAGE && !isDisabled}
                    onEnter={() => {
                        dispatch(History.actions.clearReduxBeforeNavigation());
                        CustomHistory.go('/viewControl/fti');
                    }}
                    onHover={focus}
                >
                    FTI_VIEW_CONTROL_SELECT_CHANNELS_BUTTON
                </Button>
                <Button
                    className="replay-selection__action"
                    id={ReplaySelectionAction.CONTINUE}
                    ctx={Focus.Default}
                    width={ButtonWidth.FULL}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === ReplaySelectionAction.CONTINUE && !isDisabled}
                    onEnter={() => dispatch(Fti.actions.next(Screen.Type.REPLAY_SELECTION))}
                    onHover={focus}
                >
                    FTI_VIEW_CONTROL_ACCEPT_RECOMMENDED_BUTTON
                </Button>
            </div>
        </Layout>
    );
};
