import { Function } from 'App/Packages/Function';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Detail/Constants';
import { DetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions';
import { useEffect } from 'react';

export type UseDetailActionFocused = (id: string) => Subscribable.Value<boolean>;

export const useDetailActionFocused: UseDetailActionFocused = (id) => {
    const actions = DetailActions.use();
    const focused = Subscribable.useValue(false);
    useEffect(() => {
        const isFocused = () => actions.focused.get() === id && actions.isFocused.get();
        const update = async () => {
            if (!isFocused()) return focused.set(false);
            await Function.sleep(Constants.Action.Focus.Delay);
            return focused.set(isFocused());
        };
        update();
        const unsubscribers = [actions.focused.subscribe(update), actions.isFocused.subscribe(update)];
        return () => Function.call(...unsubscribers);
    }, [id, actions, focused]);

    return focused;
};
