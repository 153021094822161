import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Screen } from 'App/Modules/Screen';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

type DetailScreenContainerProps = React.PropsWithChildren<{}>;

export type DetailScreenContainerModule = React.FunctionComponent<DetailScreenContainerProps>;

export const DetailScreenContainer: DetailScreenContainerModule = (props) => {
    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            {props.children}
        </Layout>
    );
};
