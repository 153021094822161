import React from 'react';

export type SelectContextState = { onClose: React.MutableRefObject<() => void> };

export type SelectContextModule = {
    Provider: React.FunctionComponent<React.PropsWithChildren<SelectContextState>>;
} & Omit<React.Context<SelectContextState>, 'Provider'>;

const SelectContextInternal = React.createContext<SelectContextState>(null as unknown as SelectContextState);

const SelectContextProvider = SelectContextInternal.Provider as React.FunctionComponent<
    React.PropsWithChildren<{ value: SelectContextState }>
>;

const SelectProvider: React.FunctionComponent<React.PropsWithChildren<SelectContextState>> = (props) => (
    <SelectContextProvider value={props}>{props.children}</SelectContextProvider>
);

export const SelectContext: SelectContextModule = Object.assign(SelectContextInternal, {
    Provider: SelectProvider,
});

export const useSelectOnClose = () => React.useContext(SelectContextInternal).onClose;
