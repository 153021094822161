import { Interceptor as FocusInterceptor } from 'App/Packages/Focus/Types/Interceptor';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { useCallback } from 'react';

export type UseFolderListInterceptor = (ids: Subscribable.Array<string>, scroll: Scroll) => FocusInterceptor<string>;

export const useFolderListInterceptor: UseFolderListInterceptor = (ids, scroll) => {
    return useCallback(
        (event, focused) => {
            const items = ids.get();
            if (event.y === -1 && items.indexOf(focused) === 0) scroll.to({ top: scroll.top() });
            if (event.y === 1 && items.indexOf(focused) === items.length - 1) scroll.to({ top: scroll.bottom() });
        },
        [ids, scroll]
    );
};
