import { actions as A } from 'App/Modules/Data/Detail/Series/Store/actions';
import { detailSeriesReducer } from 'App/Modules/Data/Detail/Series/Store/reducer';
import { initialDetailSeriesState } from 'App/Modules/Data/Detail/Series/Store/initialState';

export { selectors } from 'App/Modules/Data/Detail/Series/Store/selectors';
export { Api } from 'App/Modules/Data/Detail/Series/Api';

export const reducer = detailSeriesReducer;
export const actions = A.public;
export const initialState = initialDetailSeriesState;
export type { SeriesState as State, ReduxAction } from 'App/Modules/Data/Detail/Series/Types';
