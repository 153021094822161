import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckPincodeQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
  pincode: Types.Scalars['String']['input'];
}>;


export type CheckPincodeQuery = { __typename?: 'Nexx4Queries', checkPincode: boolean };


export const CheckPincodeDocument = gql`
    query checkPincode($profileId: ID!, $pincode: String!) {
  checkPincode(profileId: $profileId, pincode: $pincode, pincodeKinds: [MASTER])
}
    `;

/**
 * __useCheckPincodeQuery__
 *
 * To run a query within a React component, call `useCheckPincodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPincodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPincodeQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      pincode: // value for 'pincode'
 *   },
 * });
 */
export function useCheckPincodeQuery(baseOptions: Apollo.QueryHookOptions<CheckPincodeQuery, CheckPincodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPincodeQuery, CheckPincodeQueryVariables>(CheckPincodeDocument, options);
      }
export function useCheckPincodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPincodeQuery, CheckPincodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPincodeQuery, CheckPincodeQueryVariables>(CheckPincodeDocument, options);
        }
export function useCheckPincodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckPincodeQuery, CheckPincodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckPincodeQuery, CheckPincodeQueryVariables>(CheckPincodeDocument, options);
        }
export type CheckPincodeQueryHookResult = ReturnType<typeof useCheckPincodeQuery>;
export type CheckPincodeLazyQueryHookResult = ReturnType<typeof useCheckPincodeLazyQuery>;
export type CheckPincodeSuspenseQueryHookResult = ReturnType<typeof useCheckPincodeSuspenseQuery>;
export type CheckPincodeQueryResult = Apollo.QueryResult<CheckPincodeQuery, CheckPincodeQueryVariables>;