import { initialMessageDetailState } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import { MessageDetailState } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const messageDetailReducer: Reducer<MessageDetailState> = (state, action) => {
    if (!state) return initialMessageDetailState;

    switch (action.type) {
        case ActionType.MESSAGE_DETAIL_ACTIVE_ATTACHMENT_CHANGE:
            if (state.activeAttachmentId === action.payload.activeAttachmentId) return state;
            return { ...state, activeAttachmentId: action.payload.activeAttachmentId };
        case ActionType.MESSAGE_DETAIL_LAYOUT_CHANGE:
            if (state.layout === action.payload.layout) return state;
            return { ...state, layout: action.payload.layout };
        case ActionType.MESSAGE_DETAIL_TEXT_LAYOUT_CHANGE:
            if (state.textLayout === action.payload.textLayout) return state;
            return { ...state, textLayout: action.payload.textLayout };
        case ActionType.MESSAGE_DETAIL_VISIBLE_TEXT_CONTAINER_INDEX_CHANGE:
            if (state.visibleTextContainerIndex === action.payload.visibleTextContainerIndex) return state;
            return { ...state, visibleTextContainerIndex: action.payload.visibleTextContainerIndex };
        case ActionType.MESSAGE_DETAIL_HEADER_CHANGE:
            if (state.header === action.payload.header) return state;
            return { ...state, header: action.payload.header };
        case ActionType.MESSAGE_DETAIL_PLAYER_STATUS_CHANGE:
            if (state.playerStatus === action.payload.status) return state;
            return { ...state, playerStatus: action.payload.status };
        case ActionType.MESSAGE_DETAIL_ATTACHMENT_STATUS_CHANGE:
            if (state.attachmentViewStatus === action.payload.status) return state;
            return { ...state, attachmentViewStatus: action.payload.status };
        default:
            return state;
    }
};
