import { Folder } from 'App/Modules/Folder';
import { Constants } from 'App/Modules/MyLibraryData/Constants';
import { Api } from 'App/Modules/MyLibraryData/Api';

import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error as ErrModule } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import { ActionTypes, MyLibraryHeaderAsset, MyLibraryHeaderLoadAction, MyLibraryHeaderLoadAssetAction } from './types';

export const myLibraryHeaderLoadAction = (payload: DetailItem | null): MyLibraryHeaderLoadAction => ({
    type: ActionTypes.Load,
    payload,
});

export const myLibraryHeaderLoadAssetAction = (payload: MyLibraryHeaderAsset): MyLibraryHeaderLoadAssetAction => ({
    type: ActionTypes.LoadAsset,
    payload,
});

const loaded = Folder.actions.list.loaded(Constants.Hash);
const reset = Folder.actions.list.reset(Constants.Hash);

const load = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const config = selectConfig(state);
        const profileId = Profile.selectors.selectId(state);

        const myLibraryAdultContentVisibility = Profile.selectors.selectMyLibraryAdultContentVisibility(state);
        const items = config.layout.collectionMaxItems || Folder.Constants.List.Folders.Items;
        const variables: Api.LoadVariables = {
            profileId,
            items,
            thumbnailHeight: config.image.thumbnail.default.height,
            showAdultContent: myLibraryAdultContentVisibility,
            backgroundHeight: config.image.background.landscape,
            backgroundWidth: config.image.background.width,
        };
        const { myLibraryData, myLibraryHeaderData } = await Api.load(variables);
        if (!myLibraryData) throw new Error('Failed to load my library');
        const data = Folder.Mapper.toFolderList(myLibraryData);
        const asset = createAssetObject(myLibraryHeaderData, myLibraryHeaderData?.__typename);
        const headerData = Mapper.toDetailItem(myLibraryHeaderData);
        if (!data) throw new Error('Failed to load my library');
        dispatch(loaded(data));
        dispatch(myLibraryHeaderLoadAction(headerData));
        dispatch(myLibraryHeaderLoadAssetAction(asset));
        return true;
    } catch (error) {
        dispatch(ErrModule.actions.occured(ErrModule.Dispatcher.FolderListLoad, error));
        return false;
    }
};

export const actions = {
    public: {
        load,
        reset,
    },
    private: {
        loaded,
    },
} as const;
