import { clone } from 'lodash';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Api } from 'App/Modules/Data/Detail/Root/Api';
import {
    VodSeriesDetailLoadContentFragment,
    VodSeriesDetailLoadDocument,
    VodSeriesDetailLoadFragment,
    VodSeriesDetailLoadQueryResult,
    VodSeriesDetailLoadQueryVariables,
} from 'App/Modules/Data/Detail/VodSeries/Api/vodSeriesDetailLoad.generated';
import {
    VodAssetProductsLoadDocument,
    VodAssetProductsLoadFragment,
    VodAssetProductsLoadQueryVariables,
} from './vodAssetProductsLoad.generated';

export { DefaultVariables } from 'App/Modules/Data/Detail/VodSeries/Api/defaultVariables';

const mergeVodSeries = (
    seriesContentItemsEdges: VodSeriesDetailLoadContentItems | null,
    response: VodSeriesDetailLoadQueryResult
): VodSeriesDetailLoadQueryResult => {
    const copiedResponse = clone(response);
    if (!copiedResponse.data?.vodSeries?.content.items || !seriesContentItemsEdges) return response;
    copiedResponse.data.vodSeries.content.items.edges = [
        ...seriesContentItemsEdges.edges,
        ...copiedResponse.data.vodSeries.content.items.edges,
    ];
    return copiedResponse;
};

type LoadVodSeries = (variables: VodSeriesDetailLoadQueryVariables) => Promise<VodSeriesDetailLoadFragment | null>;

type VodSeriesDetailLoadContentItems = VodSeriesDetailLoadContentFragment['content']['items'];

const __parseLoadVodSeries = (response: VodSeriesDetailLoadQueryResult) => response.data || null;

const __loadVodSeries = async (
    variables: VodSeriesDetailLoadQueryVariables,
    seriesContentItemsEdges: VodSeriesDetailLoadContentItems | null = null
): Promise<VodSeriesDetailLoadQueryResult> => {
    const response: VodSeriesDetailLoadQueryResult = await GraphqlClient.makeGraphqlRequest(
        VodSeriesDetailLoadDocument,
        variables,
        Api.detailQueryOptions
    );
    Error.collect(response.data?.vodSeries);
    const page = response.data?.vodSeries?.content?.items?.pageInfo;
    if (!page) return response;
    const nextResponse = mergeVodSeries(seriesContentItemsEdges, response);
    if (!page.hasNextPage) return nextResponse;
    const nextCursor = page.endCursor;
    if (!nextCursor) return nextResponse;
    const nextVariables: VodSeriesDetailLoadQueryVariables = { ...variables, folderAfter: nextCursor };
    if (!nextResponse?.data?.vodSeries?.content) return nextResponse;
    const prevSeriesContentItemsEdges = nextResponse.data.vodSeries.content.items;
    return __loadVodSeries(nextVariables, prevSeriesContentItemsEdges);
};

export const load: LoadVodSeries = async (variables) => {
    const loadedVodSeriesResponse = await __loadVodSeries(variables);
    return __parseLoadVodSeries(loadedVodSeriesResponse);
};

type LoadVodSeriesProducts = (
    variables: VodAssetProductsLoadQueryVariables
) => Promise<VodAssetProductsLoadFragment['upsellProducts'] | null>;

const __loadVodSeriesProducts = async (variables: VodAssetProductsLoadQueryVariables): Promise<VodAssetProductsLoadFragment | null> => {
    const response = await GraphqlClient.makeGraphqlRequest(VodAssetProductsLoadDocument, variables, Api.detailQueryOptions);
    Error.collect(response.data?.upsellProducts);
    return response.data || null;
};
export const loadVodSeriesProducts: LoadVodSeriesProducts = async (variables) => {
    const response = await __loadVodSeriesProducts(variables);
    Error.collect(response?.upsellProducts?.items);
    return response?.upsellProducts;
};
