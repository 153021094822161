import { Detail } from 'App/Modules/Data/Detail';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';
import { Screen } from 'App/Modules/Screen';
import { playChannel, playVod } from '__SMART_APP_OLD__/api/Tuner';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

export const getCatchupBookmarkPosition = (item: Detail.Item, isStartOver: boolean) => {
    if (isStartOver) {
        return item.metadata.leadIn;
    }
    return item?.metadata.bookmark?.position || item?.metadata?.leadIn;
};

export const canResume = (item: Detail.Item, progress: number, bookmark: Bookmarks.Entity | null, resumeThreshold: number): boolean => {
    const isLive = item.entitlements.programType === ProgramType.LIVE;
    const liveProgress = Math.round((Date.now() - (item.metadata?.start as number)) / 1000);
    const isLiveRestartAvailable = isLive && liveProgress > 0 && item.entitlements.catchupTV;
    const isCatchupRestartAvailable = !!bookmark && progress > 0 && progress < resumeThreshold;

    return item.entitlements.playback && (isLiveRestartAvailable || isCatchupRestartAvailable);
};

export const getPlayButtonLabel = (item: Detail.Item, progress: number, bookmark: Bookmarks.Entity | null, resumeThreshold: number) => {
    const isLive = item.entitlements.programType === ProgramType.LIVE;

    const label =
        canResume(item, progress, bookmark, resumeThreshold) && !isLive
            ? translate('SCREEN_DETAIL_RESUME_BUTTON')
            : translate('SCREEN_DETAIL_PLAY_BUTTON');

    if (item.type === ContentItemType.Vodasset && item.metadata.seasonOrdinal && item.metadata.episodeOrdinal) {
        return `${label} ${translate('HOVER_SEASON')}${item.metadata.seasonOrdinal} ${translate('HOVER_EPISODE')}${item.metadata.episodeOrdinal}`;
    }
    return label;
};

export const startPlayback =
    (
        item: Detail.Item,
        assetObject: any,
        uiActionEventType: UIActionEvents,
        isStartOver = false,
        isTrailer = false
    ): Thunk<Promise<void>> =>
    async (dispatch) => {
        changePlayerVisibility(true);
        const { catchupTV, recording } = item.entitlements;

        if (item.entitlements.programType === ProgramType.LIVE) {
            dispatch(History.actions.push());
            dispatch(Screen.actions.unmount());
            playChannel(item.content.channelId, false, isStartOver);
        } else {
            const event = getUIActionEvent(uiActionEventType, {
                id: item.id,
                assetType: assetTypeForContentItemType[item.type],
            });

            LoggingService.getInstance().logEvent(event);
            dispatch(History.actions.push());
            dispatch(Screen.actions.unmount());
            const bookmarkPosition = catchupTV || recording ? getCatchupBookmarkPosition(item, isStartOver) : undefined;
            playVod(assetObject, {
                isStartOver: catchupTV ? false : isStartOver,
                bookmarkPosition,
                isTrailer,
            });
        }
    };
