import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import translate from 'language/translate';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Hidable } from 'App/Packages/UI/Hidable';
import { actions } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Actions';
import { useElement, useFocusable } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Div } from 'App/Packages/UI/Div';

type Props = {
    channelId: string;
    playerOptions: any;
    currentAudioTrack: any;
};

type AudiosComponent = React.FunctionComponent<Props>;

export const Audios: AudiosComponent = (props) => {
    const { playerOptions, currentAudioTrack } = props;
    const dispatch = useDispatch();

    const { isFocused, onHover: onElementHover } = useElement({ ctx: Constants.Ctx.audio });

    const { onClick, onHover } = useFocusable({
        id: Constants.Ctx.audio,
        ctx: Constants.Ctx.audio,
        isFocused,
        onClick: () => dispatch(actions.private.audioOverlaySelected()),
        onEnter: () => dispatch(actions.private.audioOverlaySelected()),
        onHover: onElementHover,
        onFocus: onElementHover,
    });

    const { audio } = playerOptions;
    const currentAudioName = audio.find((audioEl: any) => audioEl.id === currentAudioTrack?.id);

    return (
        <Hidable hidden={!audio.length}>
            <Div onClick={onClick} onHover={onHover} className="audio-container">
                <Text
                    className="audio-subtitle-title"
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_2}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {`${translate('SCREEN_AUDIO_SUBTITLES_AUDIO_LANGUAGE')} (${audio.length})`}
                </Text>
                <div className={`audio-subtitle-item ${isFocused ? 'focused' : ''}`} key={audio.length}>
                    <div className="audio-item-title">{translate(currentAudioName.name)}</div>
                </div>
            </Div>
        </Hidable>
    );
};
