import { ResultState } from 'App/Modules/Data/Search/Result/Types';

import { Api } from 'App/Modules/Data/Search/Result/Api';
import { Constants } from 'App/Modules/Data/Search/Result/Constants';
import { actions as A } from 'App/Modules/Data/Search/Result/Store/Actions';
import { selectors as S } from 'App/Modules/Data/Search/Result/Store/Selectors';
import { reducer } from 'App/Modules/Data/Search/Result/Store/Reducer';
import { getInitialState, initialState } from 'App/Modules/Data/Search/Result/Store/State';

export type { ResultState as State };

export { Api, reducer, getInitialState, initialState, Constants };

export const actions = A.public;
export const selectors = S.public;
