import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ParentalRatingRelationFragmentDoc } from '../../parentalRating/fragments/parentalRatingRelation.generated';
import { EventEntitlementsFragmentDoc } from './eventEntitlements.generated';
import { ImageFragmentDoc } from '../../fragments/image.generated';
import { MetadataFragmentDoc } from '../../fragments/metadata.generated';
import { StreamInfoFragmentDoc } from './streamInfo.generated';
export type EventFragment = { __typename: 'Event', id: string, title: string, start: any, end: any, startOverTVBeforeTime?: number | null, startOverTVAfterTime?: number | null, startOverPlaybackStartPosition?: number | null, startOverPlaybackContinuePosition?: number | null, startOverPlaybackStopPosition?: number | null, parentalRating: { __typename?: 'ParentalRating', id: string }, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, channel?: { __typename?: 'Channel', id: string } | null };

export const EventFragmentDoc = gql`
    fragment event on Event {
  id
  title
  start
  end
  startOverTVBeforeTime
  startOverTVAfterTime
  startOverPlaybackStartPosition
  startOverPlaybackContinuePosition
  startOverPlaybackStopPosition
  parentalRating {
    ...parentalRatingRelation
  }
  entitlements {
    ...eventEntitlements
  }
  backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
    ...image
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  metadata {
    ...metadata
  }
  streamInfo {
    ...streamInfo
  }
  channel {
    id
  }
  __typename
}
    ${ParentalRatingRelationFragmentDoc}
${EventEntitlementsFragmentDoc}
${ImageFragmentDoc}
${MetadataFragmentDoc}
${StreamInfoFragmentDoc}`;