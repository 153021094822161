import {
    RecomendationManagementFilter,
    RecomendationManagementSort,
    RecordingManagementState,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const initialState: RecordingManagementState = {
    isTrickMode: false,
    filterByValue: RecomendationManagementFilter.COMPLETED,
    sortByValue: RecomendationManagementSort.START,
    selectedRecordings: [],
};

export const recordingManagementReducer: Reducer<RecordingManagementState> = (state, action) => {
    if (!state) return initialState;
    switch (action.type) {
        case ActionType.RECORDING_MANAGE_SET_TRICK_MODE:
            return { ...state, isTrickMode: action.payload.isTrickMode };
        case ActionType.RECORDING_MANAGE_SET_FILTER_OPTION:
            return { ...state, filterByValue: action.payload.filterByValue };
        case ActionType.RECORDING_MANAGE_SET_SORT_OPTION:
            return { ...state, sortByValue: action.payload.sortByValue };
        case ActionType.RECORDING_MANAGE_HANDLE_DELETE_RESULT:
        case ActionType.RECORDING_MANAGE_HANDLE_SELECT:
            return { ...state, selectedRecordings: action.payload.selected };
        case ActionType.RECORDING_MANAGE_RESET_STATE:
            return { ...initialState };
        default:
            return state;
    }
};
