import { DetailActionProps, DetailAction } from 'App/Packages/UI/Detail/Components/DetailAction';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';
import React from 'react';

export type FolderDetailActionProps = DetailActionProps;
export type FolderDetailActionComponent = React.FunctionComponent<FolderDetailActionProps>;

export const FolderDetailAction: FolderDetailActionComponent = (props) => {
    const { onFocus, ...rest } = props;
    const folderList = FolderList.use();
    const focusHandler = (id: string) => {
        folderList.scroll.to({
            top: folderList.scroll.top(),
        });

        if (onFocus) {
            onFocus(id);
        }
    };

    return <DetailAction onFocus={focusHandler} {...rest} />;
};
