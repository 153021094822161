import React, { useContext } from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

export const SettingsSectionContentItemChannelLogo: React.FunctionComponent = () => {
    const { id, isFocused } = useContext(SettingsSectionContentItemContext);
    const theme = isFocused ? Theme.Type.Dark : undefined;
    return <ChannelLogo className="settings-section-content-item__channel-logo" id={id} theme={theme} />;
};
