import React from 'react';

import { useFastScroll } from '__SMART_APP_OLD__/components/EPG/hooks/useFastScroll';

import { Function } from 'App/Packages/Function';

interface Props {
    onChannelChange: (direction: 1 | -1) => void;
}

export const ChannelsMagicModeScroll: React.FunctionComponent<Props> = (props) => {
    const { onChannelChange } = props;
    const [magicModeFastScrollStartHandler, magicModeFastScrollEndHandler] = useFastScroll(Function.noop, onChannelChange);

    return (
        <>
            <div
                className="mini-epg-navigation-right"
                onMouseOver={() => magicModeFastScrollStartHandler(1)}
                onMouseLeave={magicModeFastScrollEndHandler}
            />
            <div
                className="mini-epg-navigation-left"
                onMouseOver={() => magicModeFastScrollStartHandler(-1)}
                onMouseLeave={magicModeFastScrollEndHandler}
            />
        </>
    );
};
