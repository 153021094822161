import React from 'react';

import { History } from '__SMART_APP_OLD__/app/modules/History';

import { UI } from 'App/Packages/UI';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Screen } from 'App/Modules/Screen';
import { Header, Folder } from 'App/Modules/Screen/Modules/DetailVodAssetScreen/View/Components';

export const DetailVodAssetScreenView: React.FunctionComponent<Screen.Props<Screen.Type.VodAssetDetail>> = (props) => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <UI.Detail.ScreenContainer>
            <UI.Folder.List>
                <Header />
                <Folder initialFocusedItemId={props.initialFocusedItemId} />
            </UI.Folder.List>
        </UI.Detail.ScreenContainer>
    );
};
