import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import React, { useMemo } from 'react';

const Default: MaskContextState = { parentalRatingId: 'FSK0', pinSessionType: PinSessionType.PIN };

export type MaskContextState = {
    parentalRatingId: string;
    pinSessionType: PinSessionType;
};

export const MaskContext = React.createContext<MaskContextState>(Default);

export type MaskProviderProps = React.PropsWithChildren<Partial<MaskContextState>>;

export type MaskProviderModule = React.FunctionComponent<MaskProviderProps>;

export const MaskProvider: MaskProviderModule = (props) => {
    const { parentalRatingId, pinSessionType } = props;
    const value = useMemo(
        () => ({
            pinSessionType: pinSessionType ?? Default.pinSessionType,
            parentalRatingId: parentalRatingId ?? Default.parentalRatingId,
        }),
        [parentalRatingId, pinSessionType]
    );
    return <MaskContext.Provider value={value}>{props.children}</MaskContext.Provider>;
};
