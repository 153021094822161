import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { secondsToTime } from '__SMART_APP_OLD__/utils/timeUtils';
import translate from 'language/translate';

import { FolderFragment } from 'App/Modules/Folder/Api/fragments/folder.generated';
import { BannerFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/banner/bannerFolderItem.generated';
import { ChannelFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/channel/channelFolderItem.generated';
import { ChannelProductFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/channelProduct/channelProductFolderItem.generated';
import { EventFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/event/eventFolderItem.generated';
import { FolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/folderItem.generated';
import { ProductBundleFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/productBundle/productBundleFolderItem.generated';
import { RecordingFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/recording/recordingFolderItem.generated';
import { SeriesFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/series/seriesFolderItem.generated';
import { VodAssetFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/vodAsset/vodAssetFolderItem.generated';
import { VodFolderFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/vodFolder/vodFolderFolderItem.generated';
import { VodProductFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/vodProduct/vodProductFolderItem.generated';
import { VodSeriesFolderItemFragment } from 'App/Modules/Folder/Api/fragments/folderItem/vodSeries/vodSeriesFolderItem.generated';
import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';
import { Folder, FolderItem, FolderList } from 'App/Modules/Folder/Types';
import { Mapper } from 'App/Packages/Mapper';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

export const toFolderList = (data: FolderListFragment): FolderList | null => {
    const items = (data?.folders?.edges ?? []).map((e) => toFolder(e?.node)).filter(Boolean) as Folder[];
    return {
        id: data.id,
        count: data.folders.totalCount ?? 0,
        ids: Mapper.toIds(items, (e) => e.id),
        entities: Mapper.toEntities(items, (e) => e.id),
    };
};

export const toFolder = (data: FolderFragment | null | undefined): Folder | null => {
    if (!data) return null;
    const items = (data?.items?.edges ?? []).map((i) => toItem(i?.node)).filter((item): item is FolderItem => !!item);
    return {
        id: data.id,
        title: data.title,
        type: data.kind,
        count: data.items?.totalCount ?? 0,
        ids: Mapper.toIds(items, (e) => e.id),
        entities: Mapper.toEntities(items, (e) => e.id),
    };
};

export const toItem = (data: FolderItemFragment | null | undefined): FolderItem | null => {
    if (!data) return null;

    switch (data.__typename) {
        case 'Banner':
            return toBannerItem(data);
        case 'Channel':
            return toChannelItem(data);
        case 'ChannelProduct':
            return toChannelProductlItem(data);
        case 'Event':
            return toEventItem(data);
        case 'ProductBundle':
            return toProductBundleItem(data);
        case 'NetworkRecording':
            return toRecordingItem(data);
        case 'Series':
            return toSeriesItem(data);
        case 'VODAsset':
            return toVodAssetItem(data);
        case 'VODFolder':
            return toVodFolderItem(data);
        case 'VODProduct':
            return toVodProductItem(data);
        case 'VODSeries':
            return toVodSeriesItem(data);
        default:
            return null;
    }
};

// NOT VISUALIZED
const toBannerItem = (data: BannerFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Banner,
    content: {
        title: '',
        thumbnail: '',
        description: undefined,
        info: undefined,
    },
    metadata: {
        parentalRatingId: '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: false,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: true,
    },
});

const toChannelItem = (data: ChannelFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Channel,
    content: {
        title: data.title,
        thumbnail: data.logoImageAlias?.url ? data.logoImageAlias?.url : '',
        description: data.description ?? undefined,
        userInfo: {
            id: data.userInfo?.id,
            subscribed: data.userInfo?.subscribed,
        },
    },
    metadata: {
        parentalRatingId: '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN_LIVE_TV,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

// NOT VISUALIZED
const toChannelProductlItem = (data: ChannelProductFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Channelproduct,
    content: {
        title: '',
        thumbnail: '',
        description: undefined,
        info: undefined,
    },
    metadata: {
        parentalRatingId: '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: false,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

// NOT VISUALIZED
const toProductBundleItem = (data: ProductBundleFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Productbundle,
    content: {
        title: '',
        thumbnail: '',
        description: undefined,
        info: undefined,
    },
    metadata: {
        parentalRatingId: '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: false,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

// NOT VISUALIZED
const toVodProductItem = (data: VodProductFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Vodproduct,
    content: {
        title: '',
        thumbnail: '',
        description: undefined,
        info: undefined,
    },
    metadata: {
        parentalRatingId: '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: false,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

const toEventItem = (data: EventFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Event,
    content: {
        title: data.title,
        thumbnail: data.thumbnail?.url ?? '',
        info: getTimeStampInfo(new Date(data.start).getTime(), new Date(data.end).getTime()),
        description: undefined,
    },
    metadata: {
        parentalRatingId: data.parentalRating?.id ?? '',
        providerId: data.channel?.id ?? undefined,
        duration: (new Date(data.end).getTime() - new Date(data.start).getTime()) / 1000,
        start: new Date(data.start).getTime(),
        end: new Date(data.end).getTime(),
        sessionType: PinSessionType.PIN_LIVE_TV,
    },
    entitlements: {
        restartTV: data.entitlements?.restartTV ?? false,
        pauseLiveTV: data.entitlements?.pauseLiveTV ?? false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: data.entitlements?.catchupTV ?? false,
        catchupTVAvailableUntil: new Date(data.entitlements?.catchupTVAvailableUntil ?? 0).getTime(),
        networkRecording: data.entitlements?.networkRecording ?? false,
        networkRecordingPlannableUntil: new Date(data.entitlements?.networkRecordingPlannableUntil ?? 0).getTime(),
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

const toRecordingItem = (data: RecordingFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Networkrecording,
    content: {
        title: data.event.title,
        thumbnail: data.event.thumbnail?.url ?? '',
        info: getTimeStampInfo(new Date(data.event.start).getTime(), new Date(data.event.end).getTime()),
        description: undefined,
    },
    metadata: {
        parentalRatingId: data.event.parentalRating?.id ?? '',
        providerId: data.event.channel?.id ?? undefined,
        duration: (new Date(data.event.end).getTime() - new Date(data.event.start).getTime()) / 1000,
        start: new Date(data.event.start).getTime(),
        end: new Date(data.event.end).getTime(),
        sessionType: PinSessionType.PIN_LIVE_TV,
    },
    entitlements: {
        status: data.status ?? '',
        restartTV: data.event.entitlements?.restartTV ?? false,
        pauseLiveTV: data.event.entitlements?.pauseLiveTV ?? false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: data.event.entitlements?.catchupTV ?? false,
        catchupTVAvailableUntil: new Date(data.event.entitlements?.catchupTVAvailableUntil ?? 0).getTime(),
        networkRecording: data.event.entitlements?.networkRecording ?? false,
        networkRecordingPlannableUntil: new Date(data.event.entitlements?.networkRecordingPlannableUntil ?? 0).getTime(),
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

const toSeriesItem = (data: SeriesFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Series,
    content: {
        title: data.title,
        thumbnail: data.thumbnail?.url ?? '',
        info: getSeriesMeta(data),
        description: undefined,
    },
    metadata: {
        parentalRatingId: data.parentalRating?.id ?? '',
        providerId: data.channel?.id ?? undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN_LIVE_TV,
    },
    entitlements: {
        restartTV: false,
        pauseLiveTV: false,
        playback: false,
        playbackAvailableUntil: 0,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

const toVodAssetItem = (data: VodAssetFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Vodasset,
    content: {
        title: data.title,
        thumbnail: data.thumbnail?.url ?? '',
        info: getVodAssetMeta(data),
        description: data.metadata.shortDescription ?? undefined,
    },
    metadata: {
        parentalRatingId: data.parentalRating?.id ?? '',
        providerId: undefined,
        duration: data.duration ?? 0,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        playback: data.vodAssetEntitlements.items[0]?.playback ?? false,
        playbackAvailableUntil: new Date(data.vodAssetEntitlements.items[0]?.playbackAvailableUntil ?? 0).getTime(),
        restartTV: false,
        pauseLiveTV: false,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: !data.metadata.seriesInfo?.id,
        isEligibleForLandscapeCard: !!data.metadata.seriesInfo?.id,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

const toVodFolderItem = (data: VodFolderFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Vodfolder,
    content: {
        title: data.title,
        thumbnail: data.thumbnail?.url ?? '',
        info: undefined,
        description: undefined,
    },
    metadata: {
        parentalRatingId: data.parentalRating?.id ?? '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        playback: false,
        playbackAvailableUntil: 0,
        restartTV: false,
        pauseLiveTV: false,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: true,
        isEligibleForBannerCard: false,
    },
});

const toVodSeriesItem = (data: VodSeriesFolderItemFragment): FolderItem | null => ({
    id: data.id,
    type: ContentItemType.Vodseries,
    content: {
        title: data.title,
        thumbnail: data.thumbnail?.url ?? '',
        info: getSeriesMeta(data),
        description: undefined,
    },
    metadata: {
        parentalRatingId: data.parentalRating?.id ?? '',
        providerId: undefined,
        duration: undefined,
        start: undefined,
        end: undefined,
        sessionType: PinSessionType.PIN,
    },
    entitlements: {
        playback: false,
        playbackAvailableUntil: 0,
        restartTV: false,
        pauseLiveTV: false,
        catchupTV: false,
        catchupTVAvailableUntil: 0,
        networkRecording: false,
        networkRecordingPlannableUntil: 0,
    },
    visualization: {
        isEligibleForPortraitCard: false,
        isEligibleForLandscapeCard: true,
        isEligibleForChannelCard: false,
        isEligibleForFolderCard: false,
        isEligibleForBannerCard: false,
    },
});

// Helpers
const getVodAssetMeta = (item: VodAssetFolderItemFragment): string => {
    const entries = [];
    if (item.metadata?.year) entries.push(item.metadata.year);
    if (item.metadata?.genre?.title) entries.push(item.metadata.genre.title);
    if (item.duration) entries.push(secondsToTime(item.duration).split('&nbsp;').join('\xa0'));
    return entries.join('\xa0\xa0I\xa0\xa0');
};

const getSeriesMeta = (item: VodSeriesFolderItemFragment | SeriesFolderItemFragment) => {
    const count = item.seasonInfos?.length ?? 0;
    const label = count > 1 ? 'SCREEN_CARDS_SEASONS' : 'SCREEN_CARDS_SEASON';
    const translated = translate(label);
    return `${count}\xa0${translated}`;
};
