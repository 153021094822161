import React from 'react';

import { CollectionV2GridItem, CollectionV2GridItemModule } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2GridItem';
import { GridV2, GridV2Props } from '__SMART_APP_OLD__/app/components/GridV2/GridV2';
import { DynamicListEngine } from '__SMART_APP_OLD__/app/components/ListV2';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export type CollectionV2GridProps<T extends string = string> = {
    columns: number;
    maxRows: number;
    items: T[];
    ctx: string;
    onEnterItem: (item: T) => void;
    className?: string;
    children: GridV2Props<T>['children'];
};

export type CollectionV2GridModule = {
    Item: CollectionV2GridItemModule;
} & React.FunctionComponent<CollectionV2GridProps>;

export const CollectionV2Grid: CollectionV2GridModule = (props) => {
    const { items, columns, maxRows, ctx, onEnterItem, className, children } = props;

    const navigation = Focus.useGrid({
        ctx,
        columns,
        items,
    });

    const listEngine = DynamicListEngine.useEngine(navigation.focusedRowIndex, navigation.rows, {
        axis: Axis.Y,
        max: maxRows,
        offset: 2,
    });

    return (
        <div className="collection-v2__grid-container">
            <div className="collection-v2__grid">
                <GridV2 items={items} navigation={navigation} listEngine={listEngine} columns={columns} className={className}>
                    {(item, index, isFocused) => (
                        <CollectionV2GridItem id={item} ctx={ctx} isFocused={isFocused} onHover={navigation.focus} onEnter={onEnterItem}>
                            {children(item, index, isFocused)}
                        </CollectionV2GridItem>
                    )}
                </GridV2>
            </div>
        </div>
    );
};

CollectionV2Grid.Item = CollectionV2GridItem;
