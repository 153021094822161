import { THEME_STORAGE_KEY, classNameForThemeType, classNameModifierForThemeType } from '__SMART_APP_OLD__/app/modules/Theme/constants';
import { ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';

const getThemeFromStorage = (): ThemeType | null => {
    try {
        const theme = localStorage.getItem(THEME_STORAGE_KEY);
        switch (theme) {
            case ThemeType.Light:
            case ThemeType.Dark:
                return theme;
            default:
                return null;
        }
    } catch (error) {
        return null;
    }
};

export const applyTheme = (theme: ThemeType) => {
    const className = classNameForThemeType[theme];
    if (document.body.classList.contains(className)) return;
    document.body.classList.remove(...Object.values(classNameForThemeType));
    document.body.classList.add(className);
};

export const setTheme = (theme: ThemeType) => {
    try {
        applyTheme(theme);
        localStorage.setItem(THEME_STORAGE_KEY, theme);
    } catch (error) {
        console.error('Setting Theme Failed');
    }
};

export const getTheme = (): ThemeType => getThemeFromStorage() || ThemeType.Light;

export const getThemeClassName = (root: string, theme: ThemeType): string => root + classNameModifierForThemeType[theme];

export const initializeTheme = () => applyTheme(getTheme());

export const isThemeSet = (): boolean => !!getThemeFromStorage();
