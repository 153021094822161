import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import translate from 'language/translate';

/**
 *
 * @namespace Utils
 */

/**
 * @description An array which includes the keys for the weekdays
 * @constant
 * @type {Array<string>}
 * @memberof Utils
 */
export const weekdays = [
    'DAY_NAME_DIVIDER_SUNDAY',
    'DAY_NAME_DIVIDER_MONDAY',
    'DAY_NAME_DIVIDER_TUESDAY',
    'DAY_NAME_DIVIDER_WEDNESDAY',
    'DAY_NAME_DIVIDER_THURSDAY',
    'DAY_NAME_DIVIDER_FRIDAY',
    'DAY_NAME_DIVIDER_SATURDAY',
];

/**
 * @description An array which includes the keys for the weekdays (short)
 * @constant
 * @type {Array<string>}
 * @memberof Utils
 */
export const weekdaysShort = [
    'DAY_NAME_SUNDAY',
    'DAY_NAME_MONDAY',
    'DAY_NAME_TUESDAY',
    'DAY_NAME_WEDNESDAY',
    'DAY_NAME_THURSDAY',
    'DAY_NAME_FRIDAY',
    'DAY_NAME_SATURDAY',
];

/**
 * @description An array which includes the keys for the months
 * @constant
 * @type {Array<string>}
 * @memberof Utils
 */
export const months = [
    'MONTH_NAME_FULL_JANUARY',
    'MONTH_NAME_FULL_FEBRUARY',
    'MONTH_NAME_FULL_MARCH',
    'MONTH_NAME_FULL_APRIL',
    'MONTH_NAME_FULL_MAY',
    'MONTH_NAME_FULL_JUNE',
    'MONTH_NAME_FULL_JULY',
    'MONTH_NAME_FULL_AUGUST',
    'MONTH_NAME_FULL_SEPTEMBER',
    'MONTH_NAME_FULL_OCTOBER',
    'MONTH_NAME_FULL_NOVEMBER',
    'MONTH_NAME_FULL_DECEMBER',
];
/**
 * @description An array which includes the keys for the months (short)
 * @constant
 * @type {Array<string>}
 * @memberof Utils
 */
export const monthsShort = [
    'MONTH_NAME_JANUARY',
    'MONTH_NAME_FEBRUARY',
    'MONTH_NAME_MARCH',
    'MONTH_NAME_APRIL',
    'MONTH_NAME_MAY',
    'MONTH_NAME_JUNE',
    'MONTH_NAME_JULY',
    'MONTH_NAME_AUGUST',
    'MONTH_NAME_SEPTEMBER',
    'MONTH_NAME_OCTOBER',
    'MONTH_NAME_NOVEMBER',
    'MONTH_NAME_DECEMBER',
];

/**
 * @description A function that converts seconds/milliseconds to minutes
 * @param time time that will be converted to minutes
 * @returns the time converted to minutes
 * @memberof Utils
 * @function convertToMinutes
 */
export const convertToMinutes = (time) => {
    const { seconds, milliseconds } = time;
    return seconds ? Math.round(seconds / 60) : Math.round(milliseconds / 1000 / 60);
};

/**
 * @param value input value to transform
 * @param input input format of the value.
 *      s - seconds
 *      m - minutes
 *      h - hours
 * @returns the value in milliseconds
 * @memberof Utils
 * @function convertToMilliseconds
 */
export const convertToMilliseconds = (value, input = 's') => {
    switch (input) {
        case 's':
            return Math.round(value * 60 * 1000);
        case 'm':
            return Math.round(value * 60 * 1000);
        case 'h':
            return Math.round(value * 60 * 60 * 1000);

        default:
            return value;
    }
};

/**
 * @param value milliseconds value (duration, not timestamp) to convert
 * @param output desired output duration format.
 *      s - seconds
 *      m - minutes
 *      h - hours
 *      d - days
 * @returns - The result from the conversion from milliseconds to the desired format
 * @memberof Utils
 * @function convertMilliseconds
 */
export const convertMilliseconds = (value, output = 's') => {
    if (!value || !Number.isInteger(value)) {
        console.error(`Invalid parameter provided in \`convertMilliseconds\`. Cannot convert ${value}`);
        return 0;
    }
    switch (output) {
        case 's':
            return Math.floor(value / 1000);
        case 'm':
            return Math.floor(value / (1000 * 60));
        case 'h':
            return Math.floor(value / (1000 * 60 * 60));
        case 'd':
            return Math.floor(value / (1000 * 60 * 60 * 24));
        default:
            return value;
    }
};

/**
 * @description A function that returns the server time as a Date object
 * @param [date] - datetime string
 * @returns date object
 * @memberof Utils
 * @function getServerTime
 */
export const getServerTime = (date) => {
    const d = date ? new Date(Date.parse(date)) : new Date();
    const year = new Date().getFullYear();
    const isSummerTime = isSummerDaytime(d, year);
    const atOffset = isSummerTime ? 2 : 1;
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;

    return new Date(utc + 3600000 * atOffset);
};

/**
 *
 * @param date The date that will be converted to ISO String
 * @returns The date converted to ISO String
 * @memberof Utils
 * @function dateToLocalISO
 */
export const dateToLocalISO = (date) => {
    const offset = date.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    return `${
        new Date(date.getTime() - offset * 60 * 1000).toISOString().substr(0, 23) +
        (offset > 0 ? '-' : '+') +
        (absOffset / 60).toFixed(0).padStart(2, '0')
    }:${(absOffset % 60).toString().padStart(2, '0')}`;
};

/**
 * @description A function that formats the hours in a datetime string
 * @param hours time that will be formatted to the desired format
 * @param format desired output format.
 *  hh - 0-12 AM/PM
 *  HH - 0-24
 * @param resultString a string containing the datetime not formatted.
 * @returns a string containing the datetime with formatted hours
 * @memberof Utils
 * @function formatHour
 */
const formatHour = (hours, format, resultString) => {
    if (/hh/.test(format)) {
        let meridian = 'AM';
        if (hours >= 12) {
            meridian = 'PM';
            hours -= 12;
        }
        hours = hours < 10 ? `0${hours}` : hours;
        resultString = `${resultString.replace(/hh/, hours)} ${meridian}`;
    }
    if (/HH/.test(format)) {
        hours = hours < 10 ? `0${hours}` : hours;
        resultString = `${resultString.replace(/HH/, hours)}`;
    }
    return resultString;
};

/**
 * @description A function that formats the day in a datetime string
 * @param day day that will be formatted to the desired format
 * @param format desired output format
 * @param resultString a string containing the datetime with the day not formatted
 * @returns a string containing the datetime with the day formatted
 * @memberof Utils
 * @function formatDay
 */
const formatDay = (day, format, resultString) => {
    if (/DDD/.test(format)) {
        resultString = resultString.replace(/DDD/, translate(weekdays[day]));
    } else if (/DD/.test(format)) {
        resultString = resultString.replace(/DD/, translate(weekdaysShort[day]));
    }
    return resultString;
};

/**
 * @description A function that formats the month in a datetime string
 * @param month month that will be formatted to the desired format
 * @param format desired output format
 * @param resultString a string containing the datetime with the month not formatted
 * @returns a string containing the datetime with the string formatted
 * @memberof Utils
 * @function formatMonth
 */
const formatMonth = (month, format, resultString) => {
    const formattedMonth = month < 10 ? `0${month + 1}` : month + 1;

    if (/MMMM/.test(format)) {
        resultString = resultString.replace(/MMMM/, translate(months[month]));
    } else if (/MMM/.test(format)) {
        resultString = resultString.replace(/MMM/, translate(monthsShort[month]));
    } else if (/MM/.test(format)) {
        resultString = resultString.replace(/MM/, formattedMonth);
    }
    return resultString;
};

/**
 * @description A function that formats a datetime string
 * @param dateTime datetime string that will be formatted to the desired format
 * @param format desired output format
 * @returns string containing the formatted datetime
 * @memberof Utils
 * @function formatDateTime
 */
export const formatDateTime = (dateTime, format) => {
    if (!format) {
        throw new Error('Invalid parameter: format.');
    }

    const d = dateTime.getDate();
    const m = dateTime.getMinutes();
    const s = dateTime.getSeconds();
    const day = d < 10 ? `0${d}` : d;
    const minutes = m < 10 ? `0${m}` : m;
    const seconds = s < 10 ? `0${s}` : s;
    let result = format.replace(/dd/, day);

    result = formatHour(dateTime.getHours(), format, result);
    result = formatDay(dateTime.getDay(), format, result);
    result = formatMonth(dateTime.getMonth(), format, result);

    return result.replace(/yyyy/, dateTime.getFullYear()).replace(/mm/, minutes).replace(/ss/, seconds);
};

/**
 * @description A function that converts the time from seconds to hours and minutes
 * @param time time in seconds
 * @returns time in hours minutes
 * @memberof Utils
 * @function secondsToTime
 */
export const secondsToTime = (time) => {
    const h = Math.floor(time / 3600);
    const m = Math.floor((time / 60) % 60);

    let timeString = '';

    if (h > 0) {
        if (h < 10) {
            timeString = `0${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
        }
        timeString = `${h}${translate('SCREEN_DETAIL_TIME_HOURS')} `;
    }

    if (m > 0) {
        if (m < 10) {
            timeString += `0${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        } else {
            timeString += `${m}${translate('SCREEN_DETAIL_TIME_MINUTES')}`;
        }
    }
    return timeString;
};

/**
 * @description A function that gets the year, month and date from Date object
 * @param dateTime  date object
 * @returns [year, month, date]
 * @memberof Utils
 * @function getDateTimeNumbers
 */
export const getDateTimeNumbers = (dateTime) => [dateTime.getFullYear(), dateTime.getMonth() + 1, dateTime.getDate()];

/**
 * @description A function that formats the date name
 * @param days value of tomorrow, yesterday, today
 * @param format desired format
 * @param separator separator string
 * @param showDate is the date shown
 * @param dateTime date object
 * @returns formatted day name
 * @memberof Utils
 * @function formattedDayName
 */
const formattedDayName = (days, format, separator, showDate, dateTime) => {
    let result = '';

    if (days.today) {
        result = translate('DAY_NAME_TODAY');
    }
    if (days.yesterday) {
        result = translate('DAY_NAME_YESTERDAY');
    }
    if (days.tomorrow) {
        result = translate('DAY_NAME_TOMORROW');
    }
    if (!showDate && result !== '') return result;
    if (showDate) {
        if (result === '' && format === 'dd MMMM') {
            format = `DDD, ${format}`;
            separator = '';
        }
        // if we have day formatting in the format and we have the day name already, don't use it
        if (result !== '') {
            format = format.replace('DD ', '').replace('DDD ', '');
        }
    }
    return `${result}${separator}${formatDateTime(dateTime, format)}`;
};

/**
 * @description A function that formats the day name to the specified format
 * @param dateTime date object
 * @param _format desired format
 * @param _separator separator string
 * @param showDate is the date shown
 * @returns formatted day name
 * @memberof Utils
 * @function formatDayName
 */
export const formatDayName = (dateTime, _format, _separator, showDate = false) => {
    const format = _format;
    const separator = _separator;
    const currentDateTime = new Date(); // getServerTime();
    const [currentYear, currentMonth, currentDay] = getDateTimeNumbers(currentDateTime);
    const [year, month, day] = getDateTimeNumbers(dateTime);
    const yesterdayDateTime = new Date(currentDateTime.setDate(currentDay - 1));
    const [yesterdayYear, yesterdayMonth, yesterdayDay] = getDateTimeNumbers(yesterdayDateTime);
    const tomorrowDateTime = new Date(currentDateTime.setDate(currentDay + 1));
    const [tomorrowYear, tomorrowMonth, tomorrowDay] = getDateTimeNumbers(tomorrowDateTime);
    const dateTimeIsToday = currentYear === year && currentMonth === month && currentDay === day;
    const dateTimeIsYesterday = yesterdayYear === year && yesterdayMonth === month && yesterdayDay === day;
    const dateTimeIsTomorrow = tomorrowYear === year && tomorrowMonth === month && tomorrowDay === day;

    return formattedDayName(
        { today: dateTimeIsToday, yesterday: dateTimeIsYesterday, tomorrow: dateTimeIsTomorrow },
        format,
        separator,
        showDate,
        dateTime
    );
};

/**
 * @description A function formats the duration of the event
 * @param duration event duration
 * @returns formatted event duration
 * @memberof Utils
 * @function getFormattedDuration
 */
export const getFormattedDuration = (duration) => {
    const minutes = parseInt((duration / 60) % 60, 10);
    const hours = parseInt((duration / (60 * 60)) % 24, 10);
    return (
        (hours > 0 ? hours + translate('SCREEN_DETAIL_TIME_HOURS') : '') +
        (minutes > 0 ? minutes + translate('SCREEN_DETAIL_TIME_MINUTES') : '')
    );
};

/**
 * @description A function that checks if the start time is in the future
 * @param start starting time
 * @returns  true if the start is in the future
 * @memberof Utils
 * @function isFuture
 */
export const isFuture = (start) => new Date(start).getTime() > new Date().getTime();

/**
 * @description A function that returns the timestamp for a program that is used in ChannelSwitch
 * @param startTime  program starting time
 * @param endTime  program ending time
 * @returns the timestamp for the ChannelSwitch
 * @memberof Utils
 * @function getTimestampForChannelSwitch
 */
export const getTimestampForChannelSwitch = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const format = 'HH:mm';
    const formattedStartTime = formatDateTime(startTime, format);
    const formattedEndTime = formatDateTime(endTime, format);

    return `${formattedStartTime} - ${formattedEndTime}`;
};

/**
 * @description A function that returns the gap time between 2 dates
 * @param rentLeft timestamp string
 * @returns time gap between currentTime and expiryTime
 * @memberof Utils
 * @function getTime
 */
export const getTimeGap = (rentLeft) => {
    const expiryTime = new Date(rentLeft);
    const currentTime = new Date();
    const timeDifference = Math.abs(expiryTime - currentTime);
    const hours = Math.floor(timeDifference / Time.HOUR_MS);
    const minutes = Math.floor((timeDifference % Time.HOUR_MS) / Time.MINUTE_MS);
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return (
        (hours > 0 ? formattedHours + translate('SCREEN_DETAIL_TIME_HOURS') : '') +
        (minutes > 0 ? formattedMinutes + translate('SCREEN_DETAIL_TIME_MINUTES') : '')
    );
};

/**
 * @description A function that returns the gap time between 2 dates in days
 * @param timeLeft timestamp
 * @returns time gap between currentTime and expiryTime in days
 * @function getTimeGapInDays
 */
export const getTimeGapInDays = (timeLeft) => {
    const targetDate = new Date(timeLeft);
    const currentDate = new Date();
    const timeDifference = Math.abs(targetDate - currentDate);

    const daysLeft = Math.floor(timeDifference / Time.DAY_MS);
    const hoursLeft = Math.floor((timeDifference % Time.DAY_MS) / Time.HOUR_MS);
    const minutesLeft = Math.floor((timeDifference % Time.HOUR_MS) / Time.MINUTE_MS);

    const formattedHours = hoursLeft < 10 ? `0${hoursLeft}` : `${hoursLeft}`;
    const formattedMinutes = minutesLeft < 10 ? `0${minutesLeft}` : `${minutesLeft}`;
    return (
        (daysLeft > 0 ? `${daysLeft}${translate('SCREEN_DETAIL_TIME_DAYS')} ` : '') +
        (hoursLeft > 0 ? `${formattedHours}${translate('SCREEN_DETAIL_TIME_HOURS')} ` : '') +
        (minutesLeft > 0 ? `${formattedMinutes}${translate('SCREEN_DETAIL_TIME_MINUTES')} ` : '')
    );
};

/**
 * @description The indices of month and date start from 0 (0 - Sunday, 0 - January)
 * @param {number} year
 * @param {number} month
 * @returns {Date}
 */
export const getLastSundayOfMonth = (year, month) => {
    const date = new Date(year, month, 1, 12);
    const weekday = date.getDay();
    const difference = weekday === 0 ? 7 : weekday;
    date.setDate(date.getDate() - difference);
    return date;
};

export const isSummerDaytime = (date, year) => {
    const summerDaytimeStart = getLastSundayOfMonth(year, 3);
    const winterDaytimeStart = getLastSundayOfMonth(year, 10);

    const x = summerDaytimeStart <= date && date < winterDaytimeStart;
    return x;
};
