import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { CardBannerContext } from 'App/Packages/UI/Card/CardBanner/Context/CardBannerContext';
import { Mask } from 'App/Packages/UI/Mask';
import React from 'react';

const getClassName = (props: CardBannerProps) => {
    const classlist = ['banner-card-v3'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

type GetBodyClassName = (args: { isFocused: boolean; disableTransition: boolean }) => string;

const getBodyClassName: GetBodyClassName = ({ isFocused, disableTransition }) => {
    const classlist = ['banner-card-v3__body'];
    if (isFocused) classlist.push('banner-card-v3__body--focused');
    if (disableTransition) classlist.push('banner-card-v3__body--disabled-transition');
    return classlist.join(' ');
};

export type CardBannerProps = React.PropsWithChildren<{
    className?: string;
    parentalRating?: string;
    sessionType?: PinSessionType;
    isFocused?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHover?: React.MouseEventHandler<HTMLDivElement>;
    // This is because the old cards have no transition, remove when whole app is migrated
    disableTransition?: boolean;
}> &
    React.RefAttributes<HTMLDivElement>;

export type CardBannerComponent = React.FunctionComponent<CardBannerProps>;

export const CardBanner: CardBannerComponent = React.forwardRef((props, ref) => (
    <Mask.Provider parentalRatingId={props.parentalRating} pinSessionType={props.sessionType}>
        <CardBannerContext.Provider value={{ isFocused: !!props.isFocused }}>
            <div ref={ref} className={getClassName(props)} onClick={props.onClick} onMouseOver={props.onHover}>
                <Div
                    className={getBodyClassName({ isFocused: !!props.isFocused, disableTransition: !!props.disableTransition })}
                    backgroundColor={props.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
                    shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
                >
                    {props.children}
                </Div>
            </div>
        </CardBannerContext.Provider>
    </Mask.Provider>
));
