import { VodAssetState } from 'App/Modules/Data/Detail/VodAsset/Types';

export const initialVodAssetDetailReducerState: VodAssetState = {
    item: null,
    assetObject: null,
    products: [],
    folders: {
        id: '',
        count: 0,
        ids: [],
        entities: {},
    },
};
