import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';

type Props = {
    className?: string;
};

export type ChannelCardNameModule = React.FunctionComponent<Props>;

export const ChannelCardName: ChannelCardNameModule = (props) => {
    const { id, isFocused } = useContext(ChannelCardContext);

    const name = useSelector(selectChannelName(id));

    if (!isFocused) return null;

    const className = `channel-card-v2__name${props.className ? ` ${props.className}` : ''}`;

    return (
        <Text className={className} typeface={Typeface.SANS} size={FontSize.CAPTION_2} weight={FontWeight.BOLD} color={Color.SECONDARY}>
            {name}
        </Text>
    );
};
