import { useCallback, useRef } from 'react';

import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemEnter = (id: string, enter?: (id: string) => void) => () => void;

export const useHeaderItemEnter: UseHeaderItemEnter = (id, enter) => {
    const items = HeaderItems.use();
    const headerItemEnter = useRef(enter);
    headerItemEnter.current = enter;

    return useCallback(() => {
        if (items.active.get() === id) return undefined;
        if (headerItemEnter.current) return headerItemEnter.current(id);
        if (items.enter) return items.enter(id);
        return undefined;
    }, [id, items]);
};
