import { Platform } from '__SMART_APP_OLD__/app/platform';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import translate from 'language/translate';

import { Env } from 'App/Env';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { logoutAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { Alert } from 'App/Modules/Alert';
import { AlertType } from 'App/Modules/Alert/Types';

import {
    changePasswordLoginError,
    deviceHouseholdLoginError,
    deviceNotFoundLoginError,
    deviceRequireAuthenticationLoginError,
    disabledAccountLoginError,
    disabledDeviceLoginError,
    disabledHouseholdLoginError,
    emailInvalidLoginError,
    failedLoginError,
    invalidLoginError,
    lockedAccountLoginError,
    notSameHouseholdLoginError,
    passwordInvalidLoginError,
    tokenLoginError,
    userNotMemberOfHouseholdError,
    errorTenLoginError,
} from './loginErrors';

/**
 * @namespace Utils.ErrorHandling
 */

export const loginErrors = {
    invalid: invalidLoginError,
    emailInvalid: emailInvalidLoginError,
    passwordInvalid: passwordInvalidLoginError,
    failed: failedLoginError,
    notSameHouseholdError: notSameHouseholdLoginError,
    disabledAccountError: disabledAccountLoginError,
    lockedAccountError: lockedAccountLoginError,
    disabledDeviceError: disabledDeviceLoginError,
    deviceRequireAuthenticationError: deviceRequireAuthenticationLoginError,
    disabledHouseholdError: disabledHouseholdLoginError,
    deviceHouseholdError: deviceHouseholdLoginError,
    userNotMemberOFHouseholdError: userNotMemberOfHouseholdError,
    deviceNotFoundError: deviceNotFoundLoginError,
    changePasswordError: changePasswordLoginError,
    tokenError: tokenLoginError,
    errorTenError: errorTenLoginError,
};

/**
 * @description A function that returns the graphql errors
 * @param err err
 * @param message  message
 * @param id  id
 * @returns graphqlErrors object
 * @memberof Utils.ErrorHandling
 */
export const graphQlErrors = (err, message, id) => ({
    tvError: {
        title: translate(err),
        text: id ? `${translate(message)}: ${id} ` : translate(message),
        buttons: [
            {
                btnLabel: translate('OK_BUTTON'),
            },
        ],
    },
});

/**
 * @memberof Utils.ErrorHandling
 */
class ErrorHandling {
    /**
     *
     * @description Method that checks the GraphQL error and displays a notification
     * @param {string} message
     * @param {string} errorCode
     */
    /* eslint-disable */
    checkGraphQlErrors(message, errorCode, isIdpLogin) {
        console.error(`[graphQL] A graphQL error occured with error code: ${errorCode} and  message: ${message} `);
        switch (errorCode) {
            case '0x02000200':
                Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
                store.dispatch(logoutAction());
                break;
            case '0x02000216':
            case '0x02001601':
            case '0x01030000': // we hide this as this is internal sdsevo error
            case '0x02000501':
            case '0x02000708':
            case '0x02000209':
            case '0x02000000':
            case '0xFF000000':
            case '0xFF010000':
            case '0xFF000002':
            case '0xFF000003':
            case '0x01000005':
            case '0x00000000':
            case '0x02000206':
            case '0x02001701':
                console.log('WE HIDE ERROR BY REQUEST');
                break;
            case '0x02000A01': {
                if (isIdpLogin) return;
                const id = Env.IsCroatia && Platform.ID ? Platform.ID : null;
                const text = id ? `${message}: ${id} ` : message;
                store.dispatch(logoutAction());
                store.dispatch(Alert.actions.mount(AlertType.Basic, { title: errorCode, text }));
                break;
            }
            case '0xFF01F00A':
            case '0x01000001': {
                // 0xFF01F00A  add as logout error clients should never hit this as they have max of 5-10 devices. this error happens
                // when you hit the zp license limit which is about 60 devices
                const id = Env.IsCroatia && Platform.ID ? Platform.ID : null;
                const text = id ? `${message}: ${id} ` : message;
                store.dispatch(logoutAction());
                store.dispatch(Alert.actions.mount(AlertType.Basic, { title: errorCode, text }));
                break;
            }
            case '0x02000703':
            case '0x02001003':
                // Hidden playback related errors I noticed when making vod upsell functionality
                // hidden as it makes no sense to show them and I noticed on web we don't show this too.
                console.log('WE HIDE ERROR BY REQUEST');
                break;
            default:
                store.dispatch(Alert.actions.mount(AlertType.Basic, { title: errorCode, text: message }));
                break;
        }
    }
}

export default new ErrorHandling();
