import { Bg } from 'App/Modules/Keyboard/Layout/Bg';
import { De } from 'App/Modules/Keyboard/Layout/De';
import { En } from 'App/Modules/Keyboard/Layout/En';
import { Hr } from 'App/Modules/Keyboard/Layout/Hr';
import { Mk } from 'App/Modules/Keyboard/Layout/Mk';
import { Sl } from 'App/Modules/Keyboard/Layout/Sl';
import { Sr } from 'App/Modules/Keyboard/Layout/Sr';
import { Symbol } from 'App/Modules/Keyboard/Layout/Symbol';

export const Layout = {
    Bg,
    De,
    En,
    Hr,
    Mk,
    Sl,
    Sr,
    Symbol,
};
