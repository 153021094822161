import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

const selectConfigId: Selector<string> = (state) => {
    if (!state.config.metadata.configId && !state.config.metadata.version) return '';
    return `${state.config.metadata.configId}_${state.config.metadata.version}`;
};

const selectProfileUserName: Selector<string> = (state) => Profile.selectors.selectUsername(state) || '';

export const settingsAboutAppSelectors = {
    private: {
        selectConfigId,
        selectProfileUserName,
    },
};
