import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Detail } from 'App/Modules/Data/Detail';
import { UI } from 'App/Packages/UI';
import { Folder as FolderModule } from 'App/Modules/Folder';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { DetailNetworkRecordingScreen } from 'App/Modules/Screen/Modules/DetailNetworkRecordingScreen';

type FolderComponentProps = {
    initialFocusedItemId?: string;
};

type FolderComponent = React.FunctionComponent<FolderComponentProps>;

export const Folder: FolderComponent = (props) => {
    const dispatch = useDispatch();

    const folders = useSelector(Detail.Recording.selectors.selectFoldersIds);

    return folders.map((folderListId) => {
        const selector = Detail.Recording.selectors.selectFolder(folderListId);
        return (
            <UI.Folder key={folderListId} id={folderListId}>
                <UI.Folder.Header>
                    <UI.Selector selector={FolderModule.selectors.selectTitle(selector)} />
                </UI.Folder.Header>
                <UI.Folder.Items
                    onEnter={(itemId) => dispatch(DetailNetworkRecordingScreen.actions.folderListItemSelected(folderListId, itemId))}
                    initial={props.initialFocusedItemId}
                >
                    <UI.Selector selector={FolderModule.selectors.selectItemsIds(selector)}>
                        {(ids) =>
                            ids.map((itemId) => (
                                <FolderCardItem key={itemId} selector={FolderModule.selectors.selectItem(selector)(itemId)} />
                            ))
                        }
                    </UI.Selector>
                </UI.Folder.Items>
            </UI.Folder>
        );
    });
};
