import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { recordingManageHandleSelect } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { selectCurrentRecordings } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { recordingsDelete } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';

const remove =
    (recordingIds: string[]): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(Prompt.actions.unmount());
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        await dispatch(recordingsDelete(recordingIds));
        dispatch(Overlay.actions.unmount());
    };

const cancel =
    (recordingIds: string[]): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        dispatch(Prompt.actions.unmount());
        const recordingsCount = selectCurrentRecordings(getState()).length;
        if (recordingIds.length !== recordingsCount) return;
        dispatch(recordingManageHandleSelect([]));
    };

const back =
    (recordingIds: string[]): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(cancel(recordingIds));

export const actions = {
    private: {
        remove,
        cancel,
        back,
    },
};
