export type CursorBatch = { first: number; after?: string };

export class Cursor {
    private static readonly Body = 'index:';

    private static readonly Max = 250;

    private static encode(index: number): string {
        return btoa(Cursor.Body + index);
    }

    private static decode(cursor: string): number {
        const decoded = atob(cursor);
        if (!decoded.includes(Cursor.Body)) return -1;
        const index = parseInt(decoded.slice(Cursor.Body.length), 10);
        if (Number.isNaN(index)) return -1;
        return index;
    }

    public static batch(start: number, count: number): CursorBatch[] {
        const index = Math.max(start, 0);
        const batchesCount = Math.floor(count / Cursor.Max) + (count % Cursor.Max === 0 ? 0 : 1);
        const itemsPerBatch = Math.floor(count / batchesCount);
        const itemsForLastBatch = itemsPerBatch + (count % batchesCount);
        const partitionedItems = [...new Array(Math.max(0, batchesCount - 1)).fill(itemsPerBatch), itemsForLastBatch];
        return partitionedItems.map<CursorBatch>((first, idx) => {
            const entry: CursorBatch = { first };
            if (index === 0 && idx === 0) return entry;
            entry.after = Cursor.encode(index - 1 + idx * itemsPerBatch);
            return entry;
        }, []);
    }
}
