import { Element, Elements, EventElement } from '__SMART_APP_OLD__/components/EPG/types';
import { AssetType, NavKey } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import State from '__SMART_APP_OLD__/utils/State';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Key } from 'App/Modules/Key';

const setState = (channelId: string, time: Date) => {
    State.set(NavKey.MINI_EPG, { channelId, time });
};

export const toDetailPage = (event: EventElement) => {
    setState(event.channelId, new Date(event.date));
    LoggingService.getInstance().logEvent(
        getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType: AssetType.EVENT, id: event.eventId })
    );
    CustomHistory.replaceOnPlayPage(`/details/${AssetType.EVENT}/${event.eventId}`);
};

export const dispatchEnterEvent = () => {
    document.dispatchEvent(new KeyboardEvent('keydown', { detail: Key.VK_ENTER }));
    document.dispatchEvent(new KeyboardEvent('keyup'));
};

export const getElementData = (element: EventTarget | null): Element | null => {
    if (element instanceof HTMLDivElement) {
        const type = element?.dataset?.element;
        switch (type) {
            case Elements.EVENT:
                return {
                    type: Elements.EVENT,
                    eventId: element?.dataset?.eventId ?? '',
                    channelId: element?.dataset?.channelId ?? '',
                    date: +(element?.dataset?.eventDate || 0),
                    restricted: element?.dataset?.eventRestricted === 'true',
                    isLive: element?.dataset?.eventIsLive === 'true',
                    isNowPlaying: element?.dataset?.eventIsNowPlaying === 'true',
                    isLoading: element?.dataset?.eventId?.includes('loading') || false,
                    isNoInfo: element?.dataset?.eventId?.includes('no-info') || false,
                };
            case Elements.CHANNEL:
                return {
                    type: Elements.CHANNEL,
                    channelId: element?.dataset?.channelId ?? '',
                };
            default:
                return null;
        }
    }
    return null;
};
