import { CommunityEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectSelected = (state: State): string => state.data.community.selected;

const selectIds = (state: State): string[] => state.data.community.ids;

const selectEntity = (id: string) => (state: State): CommunityEntity | null => state.data.community.entities[id] ?? null;

const selectEntityTitle = (id: string) => (state: State): string => selectEntity(id)(state)?.title ?? '';

const selectSelectedEntityTitle = (state: State): string => selectEntityTitle(selectSelected(state))(state);

const selectSelectedEntity = (state: State): CommunityEntity | null => selectEntity(selectSelected(state))(state);

export const communitySelectors = {
    public: {
        selectEntity,
        selectIds,
        selectSelected,
        selectSelectedEntity,
        selectSelectedEntityTitle,
        selectEntityTitle,
    },
};
