import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import Events, { MORE_OVERLAY_HIDE, MORE_OVERLAY_SHOW } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { moreInfoOverlayConfig } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';
import { Overlay } from 'App/Modules/Overlay';

export default class MoreInfoOverlay extends React.Component {
    nodeRef = null;

    smallContentSize = moreInfoOverlayConfig.smallContentSize;

    bigContentSize = moreInfoOverlayConfig.bigContentSize;

    scrollStep = moreInfoOverlayConfig.scrollStep;

    constructor(props) {
        super(props);
        this.state = {
            biggerContent: false,
            scrollable: false,
            initialized: false,
            options: null,
        };

        Events.addEventListener(MORE_OVERLAY_SHOW, this.showDialog);
        Events.addEventListener(MORE_OVERLAY_HIDE, this.hideDialog);
    }

    componentWillUnmount() {
        Events.removeEventListener(MORE_OVERLAY_SHOW, this.showDialog);
        Events.removeEventListener(MORE_OVERLAY_HIDE, this.hideDialog);
    }

    showDialog = (options) => {
        this.setState({ options });
    };

    hideDialog = () => {
        this.setState({
            biggerContent: false,
            scrollable: false,
            initialized: false,
            centeredContent: true,
            options: null,
        });
        this.nodeRef = null;
    };

    closeDialog = () => {
        Events.triggerEvents(MORE_OVERLAY_HIDE);
        Focus.restore();
        return true;
    };

    scrollPage = (direction) => {
        const { scrollable } = this.state;

        if (scrollable) {
            const container = this.nodeRef.nodeRef.nodeRef.parentNode;
            // scroll page
            container.scrollTop += direction * this.scrollStep;
        }
    };

    onKey = (keyCode) => {
        switch (keyCode) {
            case Key.VK_DOWN:
                this.scrollPage(1);
                break;
            case Key.VK_UP:
                this.scrollPage(-1);
                break;
            case Key.VK_BACK:
            case Key.VK_ENTER:
                this.closeDialog();
                break;
            default:
                return true;
        }
        return true;
    };

    updateState = () => {
        if (this.nodeRef) {
            const descSize = this.nodeRef.scrollHeight;

            if (descSize > this.smallContentSize && descSize <= this.bigContentSize) {
                // bigger content, but not scrollable yet
                this.setState({
                    biggerContent: true,
                    scrollable: false,
                    initialized: true,
                    centeredContent: true,
                });
            } else if (descSize > this.bigContentSize) {
                // bigger content & scrollable
                this.setState({
                    biggerContent: true,
                    scrollable: true,
                    initialized: true,
                    centeredContent: false,
                });
            } else {
                this.setState({
                    initialized: true,
                    centeredContent: true,
                });
            }

            Focus.focus(this.nodeRef);
        }
    };

    stringArrayToText = (textArr) => textArr.join(', ');

    renderCast = (iconName, cast) => (
        <div className="more-info-content__cast-container">
            <Icon className="more-info-content__cast-icon" name={iconName} type={IconType.SOLID} />
            <Text
                className="more-info-content__cast-text"
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
                theme={Theme.Type.Dark}
            >
                {this.stringArrayToText(cast)}
            </Text>
        </div>
    );

    render() {
        const { options, initialized, biggerContent, scrollable, centeredContent } = this.state;

        if (!options) {
            return null;
        }

        const { title, description, directors, actors } = options.asset;

        return (
            <Layout className="more-info-overlay" backgroundColor={BackgroundColor.OVERLAY} zIndex={Overlay.zIndex}>
                <Focusable
                    className={`more-info-content ${initialized ? 'visible' : ''} ${centeredContent ? 'centered' : ''}`}
                    ref={(ref) => {
                        if (ref) {
                            if (!this.nodeRef) {
                                this.nodeRef = ref.nodeRef.nodeRef;
                                this.updateState();
                            }
                            this.nodeRef = ref;
                        }
                    }}
                    onKey={this.onKey}
                    stashId="more-info"
                >
                    <Text
                        className={`more-title ${biggerContent ? 'small-margin' : ''}`}
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        theme={Theme.Type.Dark}
                    >
                        {title}
                    </Text>

                    {actors.length && this.renderCast(IconName.PEOPLE, actors)}
                    {directors.length && this.renderCast(IconName.DIRECTOR, directors)}

                    <Text
                        className="more-text"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_1}
                        weight={FontWeight.REGULAR}
                        color={Color.SECONDARY}
                        theme={Theme.Type.Dark}
                    >
                        <InnerHTML html={description} className="ul-padding" />
                    </Text>
                    {scrollable && <div className="top-gradient" />}
                    {scrollable && <div className="bottom-gradient" />}
                </Focusable>
            </Layout>
        );
    }
}
