import { App } from 'App/Modules/App';
import { TizenAppEngine } from 'App/Modules/App/Implementation/Tizen';

export class Tizen {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.Tizen.Initialize]');
        App.initialize(new TizenAppEngine());
        return true;
    }
}
