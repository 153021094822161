import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type DeviceEnablementPolicyFragment = { __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean };

export const DeviceEnablementPolicyFragmentDoc = gql`
    fragment deviceEnablementPolicy on DeviceEnablementPolicy {
  title
  enabled
}
    `;