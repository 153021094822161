import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export type TVGuideReduxAction =
    | TVGuideFirstIndexChangedAction
    | TVGuideFocusedIndexChangedAction
    | TVGuideStartChangedAction
    | TVGuideTimeChangedAction
    | TVGuideIsLoadingChangedAction;

export type TVGuideFirstIndexChangedActionPayload = { index: number };
export type TVGuideFirstIndexChangedAction = PayloadedAction<
    ActionType.TV_GUIDE_FIRST_INDEX_CHANGED,
    TVGuideFirstIndexChangedActionPayload
>;

export type TVGuideFocusedIndexChangedActionPayload = { index: number };
export type TVGuideFocusedIndexChangedAction = PayloadedAction<
    ActionType.TV_GUIDE_FOCUSED_INDEX_CHANGED,
    TVGuideFocusedIndexChangedActionPayload
>;

export type TVGuideStartChangedActionPayload = { time: number };
export type TVGuideStartChangedAction = PayloadedAction<ActionType.TV_GUIDE_START_CHANGED, TVGuideStartChangedActionPayload>;

export type TVGuideTimeChangedActionPayload = { time: number };
export type TVGuideTimeChangedAction = PayloadedAction<ActionType.TV_GUIDE_TIME_CHANGED, TVGuideTimeChangedActionPayload>;

export type TVGuideIsLoadingChangedActionPayload = { isLoading: boolean };
export type TVGuideIsLoadingChangedAction = PayloadedAction<
    ActionType.TV_GUIDE_LOADING_STATUS_CHANGED,
    TVGuideIsLoadingChangedActionPayload
>;

export type TVGuideState = {
    isLoading: boolean;
    firstIndex: number;
    focusedIndex: number;
    time: number;
    start: number;
};

export const enum TVGuideCTX {
    TIMELINE = 'TIMELINE',
    GRID = 'GRID',
}
