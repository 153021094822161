import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type BookmarkFragment = { __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null };

export const BookmarkFragmentDoc = gql`
    fragment bookmark on Bookmark {
  position
  audio
  subtitle
  item {
    ... on Event {
      id
      start
      end
    }
    ... on VODAsset {
      id
      duration
    }
    ... on Recording {
      ... on NetworkRecording {
        id
        start
        end
      }
    }
  }
}
    `;