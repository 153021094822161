import { Core } from 'App/Platform/Core';
import { Device } from 'App/Platform/Device';
import { Opco } from 'App/Platform/Opco';

export class Platform {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Initialize]: 🚀 🚀 🚀');
        const core = await Core.initialize();
        const isReady = await Promise.all([Device.initialize(), Opco.initialize()]);
        return core && isReady.every(Boolean);
    }
}
