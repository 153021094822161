import React from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Header/Constants';
import { Scroll } from 'App/Packages/Scroll';
import { HeaderItemValue } from 'App/Packages/UI/Header/Modules/HeaderItem/Context/HeaderItemContext';

const Default: HeaderItemsValue = {
    ref: { current: null },
    ctx: Constants.Ctx,
    ids: new Subscribable.MockedArray<string>([]),
    store: new Subscribable.MockedRecord<HeaderItemValue>({}),
    focused: new Subscribable.MockedValue<string>(''),
    active: new Subscribable.MockedValue<string>(''),
    disabled: new Subscribable.MockedValue<boolean>(false),
    scroll: {} as Scroll,
    focus: () => {},
};

export type HeaderItemsValue = {
    ref: React.MutableRefObject<HTMLDivElement | null>;
    ctx: string;
    ids: Subscribable.Array<string>;
    store: Subscribable.Record<HeaderItemValue>;
    focused: Subscribable.Value<string>;
    active: Subscribable.Value<string>;
    disabled: Subscribable.Value<boolean>;
    initial?: string;
    scroll: Scroll;
    focus: (id: string) => void;
    enter?: (id: string) => void;
};

export type HeaderItemsContextModule = React.Context<HeaderItemsValue> & { Default: HeaderItemsValue };

export const HeaderItemsContext: HeaderItemsContextModule = Object.assign(React.createContext(Default), { Default });
