import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export type AlertTitleProps = {
    children: React.ReactNode;
};

export type AlertTitleModule = {} & React.FunctionComponent<AlertTitleProps>;

export const AlertTitle: AlertTitleModule = (props) => (
    <Text className="alert__title" typeface={Typeface.SANS} size={FontSize.TITLE_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
        {props.children}
    </Text>
);
