import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { Focus } from 'App/Packages/Focus';
import { Subscribable } from 'App/Packages/Subscribable';
import { Div } from 'App/Packages/UI/Div';
import { Switch } from '__SMART_APP_OLD__/app/components/Switch';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import React from 'react';

const getClassName = (props: PasswordSwitchProps): string => {
    const classList = ['login-with-email-password-switch'];
    if (props.isFocused) classList.push('login-with-email-password-switch--focused');
    return classList.join(' ');
};

export type PasswordSwitchProps = {
    hidden?: Subscribable.Value<boolean>;
} & Omit<Focus.Focusable<LoginElement.PasswordSwitch>, 'id'>;

export const PasswordSwitch: React.FunctionComponent<PasswordSwitchProps> = (props) => {
    const { ctx, isFocused, disabled, onEnter, onHover, onBlur, onFocus, onClick } = props;
    const hidden = Subscribable.use(props.hidden);
    const id = LoginElement.PasswordSwitch;
    const focusable = Focus.useFocusable({ id, ctx, isFocused, disabled, onEnter, onHover, onBlur, onClick, onFocus });

    Focus.useHorizontalNavigation(() => props.onEnter?.(id), { disabled: !props.isFocused });

    return (
        <Div className={getClassName(props)} onClick={focusable.onClick} onMouseDown={focusable.onHover}>
            <Switch
                className="login-with-email-password-switch__root"
                isFocused={props.isFocused}
                isToggled={!(hidden ?? true)}
                disableBackgroundChange
            />
            <Text
                className="login-with-email-password-switch__label"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                color={Color.PRIMARY}
                weight={FontWeight.REGULAR}
            >
                SCREEN_LOGIN_SHOW_PASSWORD
            </Text>
        </Div>
    );
};
