export const isValid = (ts?: number) => typeof ts === 'number' && !Number.isNaN(ts);

export const getTSFromDate = (date: string | number | null | undefined): number | null => (!date ? null : new Date(date).getTime());

export const isSameDay = (ts: number, now: number) => new Date(ts).setHours(0, 0, 0, 0) === new Date(now).setHours(0, 0, 0, 0);

export const getTheDayBefore = (ts: number) => {
    const date = new Date(ts);
    date.setHours(0, 0, 0, 0);
    date.setHours(date.getHours() - 24);
    return date.getTime();
};

export const isTheDayBefore = (ts: number, compare: number) => getTheDayBefore(compare) === new Date(ts).setHours(0, 0, 0, 0);
