export enum ErrorCode {
    // Custom Error Codes
    HIDDEN = 'HIDDEN', // Used for Errors handled by NOTIFICATION_SHOW event

    // 0x00 - Shared error codes (used by all components)
    UNEXPECTED_ERROR = '0x00000000', // An unexpected error occurred.
    ILLEGAL_METHOD_ARGUMENT = '0x00000001', // Illegal method argument
    PERFORMANCE_GUARD = '0x0000000D', // Due to performance guards, a certain GraphQL field cannot be requested using this query or mutation.

    // 0x0100 - Errors in the incoming request (required headers or parameters, URL path, ...)
    INVALID_URL = '0x01000000', // The request URL itself is invalid, meaning the Operator id and schema version can't be determined from it.
    BACKEND_SESSION_HEADER_MISSING = '0x01000001', // The BACKEND_SESSION request header or parameter is missing.
    GQL_MISSING_QUERY = '0x01000002', // The GraphQL query was missing in the incoming request.
    GQL_INVALID_VARIABLES = '0x01000003', // The supplied GraphQL variables were not valid in the incoming request.
    INVALID_DATE_FORMAT = '0x01000004', // An invalid date format was used.
    FAILED_TO_RETRIVE_DATA_FROM_REQUEST = '0x01000005', // Error code to indicate that the request data could not be retrieved from the incoming request.

    // 0x0101 - Errors triggered by GraphQL library
    GQL_INVALID_SYNTAX = '0x01010001', // A syntax error was detected in the incoming GraphQL request.
    GQL_FAILED_VALIDATION = '0x01010002', // A validation error was detected in the incoming GraphQL request.
    GQL_RUNTIME_ERROR = '0x01010003', // A runtime error occurred while executing the incoming GraphQL request.

    // 0x0102 - Errors triggered by data fetchers
    GQL_UNKNOWN_QUERY = '0x01020000', // Unknown GraphQL query.
    GQL_UNKNOWN_FIELD = '0x01020001', // Unknown field in GraphQL data fetcher environment.
    GQL_MISSING_FIELD = '0x01020002', // Field missing from GraphQL data fetcher environment.
    GQL_FIELD_NAME_MISMATCH = '0x01020003', // Mismatch in GraphQL data fetcher environment field names.
    GQL_MISSING_REQUIRED_FIELD_ARGUMENT = '0x01020004', // Required GraphQL field argument missing.
    GQL_INVALID_REQUEST_SOURCE = '0x01020005', // Invalid request source object on data fetcher environment.
    GQL_QUERY_NOT_IMPLEMENTED = '0x01020006', // This GraphQL query is not implemented on this back-office
    GQL_MUTATION_NOT_IMPLEMENTED = '0x01020007', // This GraphQL mutation is not implemented on this back-office
    GQL_PROPERTY_NOT_IMPLEMENTED = '0x01020008', // This GraphQL property is not implemented on this back-office
    GQL_GUEST_USER_METHOD_NOT_ALLOWED = '0x01020009', // This GraphQL query or mutation cannot be executed by a guest mode account.

    // 0x010300 - Errors triggered when invoking EJBs

    ERROR_WRAPPER = '0x01030000', // Used by data fetcher to wrap another exception that occured. The wrapped error should end up in the client response, not this error.
    CHANNEL_SERVICE_INVOCATION_ERROR = '0x01030001', // An error occurred during the invocation of the Channel service.
    EVENT_SERVICE_INVOCATION_ERROR = '0x01030002', // An error occurred during the invocation of the Event service.
    SESSION_SERVICE_INVOCATION_ERROR = '0x01030003', // An error occurred during the invocation of the Session service.
    HOUSEHOLD_SERVICE_INVOCATION_ERROR = '0x01030004', // An error occurred during the invocation of the Household service.
    OPERATOR_SERVICE_INVOCATION_ERROR = '0x01030005', // An error occurred during the invocation of the Operator service.
    DEVICE_SERVICE_INVOCATION_ERROR = '0x01030006', // An error occurred during the invocation of the Device service.
    APP_CONFIG_SERVICE_INVOCATION_ERROR = '0x01030007', // An error occurred during the invocation of the App config service.
    PLAYBACK_SERVICE_INVOCATION_ERROR = '0x01030008', // An error occurred during the invocation of the Playback service.
    RECORDING_SERVICE_INVOCATION_ERROR = '0x01030009', // An error occurred during the invocation of the Recording service.
    PROFILE_SERVICE_INVOCATION_ERROR = '0x0103000A', // An error occurred during the invocation of the Profile service.
    USER_SERVICE_INVOCATION_ERROR = '0x0103000B', // An error occurred during the invocation of the User service.
    VOD_SERVICE_INVOCATION_ERROR = '0x0103000C', // An error occurred during the invocation of the VOD service.
    CONTENT_SERVICE_INVOCATION_ERROR = '0x0103000D', // An error occurred during the invocation of the Content service.
    SEARCH_SERVICE_INVOCATION_ERROR = '0x0103000E', // An error occurred during the invocation of the Search service.
    PURCHASE_SERVICE_INVOCATION_ERROR = '0x0103000F', // An error occurred during the invocation of the Purchase service.
    HOME_SERVICE_INVOCATION_ERROR = '0x01030010', // An error occurred during the invocation of the Home service.
    FAVOURITES_SERVICE_INVOCATION_ERROR = '0x01030011', // An error occurred during the invocation of the Favourites service.
    ENVIRONMENT_SERVICE_INVOCATION_ERROR = '0x01030012', // An error occurred during the invocation of the Environment service.
    MESSAGE_SERVICE_INVOCATION_ERROR = '0x01030013', // An error occurred during the invocation of the Message service.
    REMINDER_SERVICE_INVOCATION_ERROR = '0x01030014', // An error occurred during the invocation of the Reminder service.
    BANNER_SERVICE_INVOCATION_ERROR = '0x01030015', // An error occurred during the invocation of the Banner service.
    PAUSE_CONTENT_SERVICE_INVOCATION_ERROR = '0x01030016', // An error occurred during the invocation of the PauseContent service.
    PRODUCT_SERVICE_INVOCATION_ERROR = '0x01030017', // An error occurred during the invocation of the Product service.

    // 0x010301 - Profiling Service Exceptions
    PROFILE_EVENT_INVALID_DATA = '0x01030100', // The data which is passed to the Profiling Service, in a Profiling Event, is not valid.

    // 0x010302 - Alerting Service Exceptions
    MAIL_SEND_FAILED = '0x01030200', // An error occurred while trying to send a mail with the alerts that were triggered

    // 0x020000 - Errors related to sessions
    SESSION_NOT_FOUND = '0x02000000', // The session with the passed id is invalid, it doesn't exist on the Backend. Reauthentication is probably needed.
    UNEXPECTED_SESSION_BUSINESS_LOGIC = '0x02000001', // Unexpected session business logic prevented successful execution of this request

    // 0x020001 - Errors related to households
    HOUSEHOLD_NOT_FOUND = '0x02000100', // The Household which is involved in the request, isn't found in the Backend.
    HOUSEHOLD_DISABLED = '0x02000101', // The Household which is involved in the request is disabled.
    HOUSEHOLD_INVALID_DATA = '0x02000102', // The Household which is involved in the request has invalid data.
    HOUSEHOLD_MASTER_PIN_VALIDATION_FAILED = '0x02000103', // The Household master pincode does not conform to the expectation of 4 digits.
    HOUSEHOLD_BARRED = '0x02000104', // The Household which is involved in the request is barred.
    HOUSEHOLD_INVALID_CHANNEL_SORT_LANGUAGE = '0x02000105', // The value supplied for the Household channelSortLanguage is not a valid language code
    HOUSEHOLD_MASTER_PIN_NOT_ALLOWED = '0x02000106', // That household master pincode is not allowed, please choose another one.

    // 0x020002 - Errors related to devices
    DEVICE_NOT_FOUND = '0x02000200', // The Device which is involved in the request, isn't found in the Backend. Re-registration might be needed.
    DEVICE_DISABLED = '0x02000201', // The Device which is involved in the request, is disabled.
    DEVICE_INVALID_REGISTRATION = '0x02000202', // The Device which is involved in the request, has an invalid registration.
    DEVICE_LANGUAGE_UNKNOWN_DATA_REQUESTED = '0x02000203', // Data was requested that was not known in the language of the device.
    DEVICE_LANGUAGE_CHANGE_VALIDATION_FAILED = '0x02000204', // The language of a device can not be updated to a non-existing language.
    DEVICE_ALREADY_EXISTS = '0x02000206', // The Device that should be created (registered) already exists.
    DEVICE_INVALID_PARAMETER = '0x02000207', // An invalid parameter was passed (like device type or language code) when creating (registering) a device.
    DEVICE_REGISTER_FAILED = '0x02000208', // A device can't register e.g. because devices of the device's type can't register themselves.
    DEVICE_NOT_REGISTERED = '0x02000209', // The request was executed by a Device that is not registered but that did pass valid Household credentials in the request. The error is raised because the request can only be executed by registered Devices.
    DEVICE_INVALID_USER_AGENT = '0x0200020A', // The device User Agent is missing or invalid/not handled
    DEVICE_ID_NOT_ACCEPTED = '0x0200020B', // Specified deviceId can not be accepted within current authentication scope
    HOUSEHOLD_DEVICE_LIMIT_REACHED = '0x0200020C', // A new device can not be created (registered) because the Household's maximum number of devices limit is reached.
    DEVICE_CREATE_FAILED = '0x0200020D', // A new device can not be created (registered)
    DEVICE_NOT_REMOVABLE = '0x0200020E', // A Device can not be deleted because it's removable property is false (see the Nexx 4.0 GraphQL Schema for more info on the removable property from the Device type).
    DEVICE_NOT_LINKED_TO_SPECIFIED_HOUSEHOLD = '0x0200020F', // The device which is involved in the request, isn't part of the specified household.
    DEVICE_IDENTIFIER_REQUIRED = '0x02000210', // The device identifier is required within the authentication header.
    DEVICE_INVALID_CURRENCY = '0x02000211', // The currency of a Device could not be matched to a known currency.
    DEVICE_PREVIEW_MODE_NOT_ALLOWED = '0x02000212', // Enabling preview mode on this device is not allowed because the household is not allowed to enable preview mode.
    DEVICE_REMOVE_FAILED_DEVICE_LOCKED = '0x02000213', // The device can not be removed (deleted) because it is still in the lock period.
    DEVICE_HOUSEHOLD_LINK_MISSING = '0x02000214', // The device is not linked to a household.
    DEVICE_ACTIVE_PROFILE_ID_NOT_FOUND = '0x02000215', // The device's active profile id does not exist or does not belong to the household.
    DEVICE_NOT_LINKED_TO_SAME_HOUSEHOLD = '0x02000216', // An operation was done for which the device is expected to be in the same household, but the target device was part of a different household

    // 0x020003 - Errors related to Profiles
    PROFILE_NOT_FOUND = '0x02000300', // The Profile which is involved in the request, isn't found in the Backend.
    PROFILE_PIN_ALREADY_FILLED = '0x02000301', // The profile pincode already has a value
    PROFILE_PIN_NOT_FILLED = '0x02000302', // The profile pincode does not have a value
    PROFILE_PIN_MISMATCH = '0x02000303', // Profile pincode value does not match
    PROFILE_INVALID_PROPERTY = '0x02000304', // Profile property contains invalid argument value.
    PROFILE_INVALID_ID = '0x02000305', // Specified profileId can not be accepted within current authentication scope
    PROFILE_NOT_LINKED_TO_SPECIFIED_USER = '0x02000306', // The profile which is involved in the request, isn't part of the specified user.
    PROFILE_PIN_NOT_ALLOWED = '0x02000307', // That profile pincode is not allowed, please choose another one.
    PROFILE_INVALID_PROFILE_TYPE = '0x02000308', // The Profile type which is involved in the request, isn't found in the Backend.
    PROFILE_NAME_UNIQUE_VIOLATION = '0x02000309', // A profile with this name already exists in the household
    DEFAULT_PROFILE_DELETE_FORBIDDEN = '0x02000310', // The default client profile can not be deleted.
    PROFILE_CREATE_TYPE_NOT_ALLOWED = '0x02000311', // Creating a profile with this type is not allowed.
    MULTIPLE_PROFILES_NOT_ENABLED = '0x02000312', // The request couldn't be executed because the Multiple Profiles feature is not enabled in the backend
    PROFILE_ACTION_NOT_ALLOWED = '0x02000320', // Action not allowed for profile with this kind.
    PROFILE_COUNT_LIMIT_EXCEEDED = '0x02000321', // User created profiles exceeded allowed number.
    PROFILE_INVALID_NAME = '0x02000322', // The profile's name does not have a value.
    PROFILE_NOT_LINKED_TO_SPECIFIED_ACCOUNT = '0x02000323', // The specified account and profile are not linked to each other.
    PROFILE_CREATE_NOT_ALLOWED = '0x02000324', // Creation of profile is not allowed
    PROFILE_DELETE_NOT_ALLOWED = '0x02000325', // Deletion of profile is not allowed

    // 0x020004 - Errors related to Channels
    CHANNEL_NOT_FOUND = '0x02000400', // The Channel which is involved in the request, isn't found in the Backend.

    // 0x020005 - Errors related to events
    EVENT_NOT_FOUND = '0x02000500', // The Event which is involved in the request, isn't found in the Backend.
    EVENT_RECORDED_ASSET_NOT_FOUND = '0x02000501', // The Event is e.g. a CUTV event but the recorded asset for that event can't be found.
    PAUSE_LIVE_CHANNEL_EVENT_ID_REQUIRED = '0x02000502', // An eventId is required for this back-office in order to fulfill the pauseLiveChannel request.
    EVENT_SERIES_NOT_FOUND = '0x02000503', // The event series which is involved in the request, isn't found in the Backend.
    EVENT_SEASON_NOT_FOUND = '0x02000504', // The event season which is involved in the request, isn't found in the Backend.
    EVENT_INVALID_PAUSED_AT = '0x02000505', // Received pausedAt does not fit into given event timeframe, or you paused a future event.
    EVENT_INVALID_TUNED_AT = '0x02000506', // Received tunedAt value was empty or in the future.

    // 0x020006	Errors related to communities
    COMMUNITY_NOT_FOUND = '0x02000600', // The Community which is involved in the request, isn't found in the Backend.
    COMMUNITY_DISABLED = '0x02000601', // The Community which is involved in the request, is disabled.
    COMMUNITY_INVALID = '0x02000602', // The community which is involved in the request is not valid for some reason, it can't be used to handle the request.
    UNEXPECTED_COMMUNITY_BUSINESS_LOGIC = '0x02000603', // COMMUNITY business logic prevented successful execution of this request

    // 0x020007	Errors related to playback sessions
    PLAYBACK_ITEM_NOT_FOUND = '0x02000700', // The item for which a playback session was requested was not found.
    PLAYBACK_RELATED_ITEM_NOT_FOUND = '0x02000701', // A required item related to the item for which a playback session was requested was not found
    PLAYBACK_ITEM_CANNOT_BE_PLAYED = '0x02000702', // The item for which a playback session was requested is not in a state from which it can be played (e.g. a service is not enabled or an asset has a bad status)
    PLAYBACK_DENIED = '0x02000703', // Access to the item for which a playback session was requested has been denied
    PLAYBACK_FAILED_SESSION_LIMIT_EXCEEDED = '0x02000704', // The maximum number of playback sessions for this client has been reached
    PLAYBACK_FAILED_COMMUNICATION_ERROR = '0x02000705', // Starting a new playback session has failed due to a communication error with the media service. Please retry.
    PLAYBACK_FAILED_BANDWIDTH_NOT_ENOUGH = '0x02000706', // There is not enough bandwidth available to start the requested playback session
    PLAY_URL_CONSTRUCT_FAILED_DEVICE_CAPABILITIES = '0x02000707', // No playUrl can be constructed based on device capabilities
    PLAYBACK_SESSION_ID_NOT_FOUND = '0x02000708', // The provided playback session id could not be found
    PLAYBACK_FAILED_DEVICE_POLICY_RESTRICTION = '0x02000709', // The requested channel can not be played on this device due to some policy restriction
    PLAYBACK_FAILED_INVALID_ENTITLEMENT_ID = '0x0200070A', // The playback could not be started because an invalid entitlement id was passed.
    PLAYBACK_HOUSEHOLD_NOT_ENTITLED = '0x0200070B', // The Household is not entitled for the item for which a playback session was requested
    PLAYBACK_FAILED_INVALID_DVB_TRIPPLET = '0x0200070C', // The playback could not be started because an invalid dvb_tripplet was configured in the Service Custom Data
    PLAYBACK_DENIED_PROXY_DETECTED = '0x0200070D', // Playback denied because a proxy was detected
    PLAYBACK_DENIED_VPN_DETECTED = '0x0200070E', // Playback denied because a vpn was detected
    PLAYBACK_DENIED_INVELID_REGION = '0x0200070F', // Playback denied due to the detected region

    // 0x020008	Errors related to app config (resource bundles)
    APP_CONFIG_NOT_FOUND = '0x02000800', // An app config corresponding with the given parameters could not be found

    // 0x020009	Errors related to recordings
    RECORDING_QUOTA_LIMIT_REACHED = '0x02000900', // A request concerning a recording couldn't be handled since the user's NPVR quota was reached. Probably only when creating recordings.
    RECORDING_CREATE_PAST_EVENT = '0x02000901', // A request was received to create a recording of an event that occurred in the past.
    RECORDING_CREATE_FORBIDDEN = '0x02000902', // A request was received to create a recording but the user is not authorized to create the recording.
    RECORDING_ALREADY_EXISTS = '0x02000903', // A request was received to create a recording, but a recording for the same event already exists.
    RECORDING_CREATE_EVENT_NOT_FOUND = '0x02000904', // A request was received to create a recording of an event that doesn't exist.
    RECORDING_CREATE_CHANNEL_RECORDING_NOT_ALLOWED = '0x02000905', // A request was received to create a recording of an event that belongs to a Channel for which NPVR isn't enabled.
    RECORDING_CREATE_INVALID_CHANNEL = '0x02000906', // "A request was received to create a recording but the channel is invalid (not found, disabled, ...)."
    RECORDING_INVALID = '0x02000909', // The recording which is involved in the request, isn't valid in the Backend.
    RECORDING_ASSET_NOT_FOUND = '0x0200090A', // A request was received for which a recording asset is needed, but the recording asset could not be found in the backend
    RECORDING_NOT_FOUND = '0x0200090B', // The recording which is involved in the request, isn't found in the Backend.
    UNEXPECTED_RECORDING_BUISNESS_LOGIC = '0x0200090C', // Unexpected RECORDING business logic prevented successful execution of this request
    RECORDING_DELETE_PROTECTED = '0x0200090D', // The recording which is involved in the request, cannot be deleted because it is delete protected.
    SERIES_RECORDING_NOT_FOUND = '0x0200090E', // The series recording which is involved in the request, isn't found in the Backend.
    SERIES_RECORDING_ALREADY_EXISTS = '0x0200090F', // A request was received to create a series recording, but a recording for the same series already exists.
    SERIES_RECORDING_ALREADY_CANCELED = '0x02000910', // A request was received to cancel a series recording, but the recording for the same series is already cancelled.
    SERIES_RECORDING_ALREADY_DELETED = '0x02000911', // A request was received to delete a series recording, but the recording for the same series is already deleted.
    SERIES_RECORDING_SEASON_NOT_FOUND = '0x02000912', // The season of the series recording which is involved in the request, isn't found in the Backend.
    RECORDING_ASSET_INVALID_STATE = '0x02000913', // A request was received to create a recording, but the recording asset in the backend is in an invalid state
    RECORDING_ARCHIVE_FAILED_QUOTA_LIMIT_REACHED = '0x02000914', // The recording cannot be archived because the user's NPVR archive quota was reached.
    RECORDING_ARCHIVE_NOT_ALLOWED = '0x02000915', // The recording cannot be archived because archiving is disabled or not allowed.
    RECORDING_ARCHIVE_FAILED_EVENT_NOT_STARTED = '0x02000916', // The recording cannot be archived because the event has not started yet.
    RECORDING_DELETION_FAILED = '0x02000917', // An error occurred during the deletion of a ghost series as part of creating a new series recording for that same series.
    RECORDING_CONFLICT = '0x02000918', // The recording cannot be created because it is in conflict and the event is currently playing.
    RECORDING_FAILED = '0x02000919', // This recording action cannot be executed because the recording has failed.

    // 0x02000A	Errors related to users
    USER_NOT_FOUND = '0x02000A00', //	The User which is involved in the request, isn't found in the Backend.
    USER_NOT_LINKED_TO_CURRENT_HOUSEHOLD = '0x02000A01', //	The User which is involved in the request, isn't part of the current Household.

    // 0x02000B	Errors related to parental ratings
    PARENTAL_RATING_NOT_FOUND = '0x02000B00', // A Parental rating that was requested wasn't found. Could indicate no Parental rating is defined for a certain rank.
    PARENTAL_RATING_INVALID_RANK = '0x02000B01', // An invalid rank was used when requesting a Parental rating, e.g. a negative rank.
    CHANNEL_DEFAULT_PARENTAL_RATING_NOT_FOUND = '0x02000B02', // Unable to retrieve the default channel parental rating definition
    EVENT_DEFAULT_PARENTAL_RATING_NOT_FOUND = '0x02000B03', // Unable to retrieve the default event parental rating definition
    PROFILE_PERMISSION_DEFAULT_PARENTAL_RATING_NOT_FOUND = '0x02000B04', // Unable to retrieve the default profile permission parental rating definition
    SERIES_DEFAULT_PARENTAL_RATING_NOT_FOUND = '0x02000B05', // Unable to retrieve the default series parental rating definition.
    PROFILE_INVALID_PARENTAL_RATING_ID = '0x02000B06', // The profile's parental rating id is invalid.

    // 0x02000C	Errors related to languages
    LANGUAGE_NOT_FOUND = '0x02000C00', // The language could not be found by its language code or id.

    // 0x02000D	Errors related to VOD items
    VOD_ASSET_NOT_FOUND = '0x02000D00', // The VOD Asset which was requested wasn't found in the Backend.
    VOD_CATALOG_NOT_FOUND = '0x02000D01', // The VOD Catalog which was requested wasn't found in the Backend.
    VOD_SERIES_NOT_FOUND = '0x02000D02', // The VOD Series which was requested wasn't found in the Backend.
    VOD_SEASON_NOT_FOUND = '0x02000D03', // The VOD Season which was requested wasn't found in the Backend.
    VOD_PRODUCT_NOT_FOUND = '0x02000D04', // The VOD Product which was requested wasn't found in the Backend.
    VOD_PLAYBACK_NOT_FOUND = '0x02000D05', // The VOD Playback Asset which was requested wasn't found in the Backend
    VOD_ITEM_NOT_FOUND = '0x02000D06', // The VOD item which was requested wasn't found in the Backend (a vod item can be any of the VOD types, i.e. asset, catalog, series, ...)
    VOD_ASSET_INVALID_LICENSE = '0x02000D07', // The VOD Asset which was requested does not have a valid license or is not visible for this client device.
    VOD_SERIES_INVALID_LICENSE = '0x02000D08', // The VOD Series which was requested does not have a valid license or is not visible for this client device.
    VOD_FOLDER_INVALID_LICENSE = '0x02000D09', // The VOD Folder which was requested does not have a valid license or is not visible for this client device.
    VOD_PRODUCT_INVALID_LICENSE = '0x02000D0A', // The VOD Product which was requested does not have a valid license or is not visible for this client device.
    VOD_BOXSET_INVALID_LICENSE = '0x02000D0B', // The VOD Boxset which was requested does not have a valid license or is not visible for this client device.

    // 0x02000E	Errors related to cursors
    CURSOR_FIRST_NULL_AFTER_NOT_NULL = '0x02000E00', // First is null, but after is not null.
    CURSOR_FIRST_NEGATIVE = '0x02000E01', // First has a negative value
    CURSOR_AFTER_NEGATIVE = '0x02000E02', // After has a negative value
    CURSOR_AFTER_EXCEEDS_MAX = '0x02000E03', // After exceeds the number of edges
    CURSOR_LAST_NULL_BEFORE_NOT_NULL = '0x02000E04', // Last is null, but before is not null
    CURSOR_LAST_NEGATIVE = '0x02000E05', // Last has a negative value
    CURSOR_BEFORE_NEGATIVE = '0x02000E06', // Before has a negative value
    CURSOR_BEFORE_EXCEEDS_EDGES_COUNT = '0x02000E07', // Before exceeds the number of edges
    CURSOR_EDGES_COUNT_NULL = '0x02000E08', // Number of edges is null
    CURSOR_EDGES_COUNT_0 = '0x02000E09', // Number of edges is 0
    CURSOR_FIRST_AND_LAST_NOT_SUPPORED = '0x02000E0A', // First and last are set. This is not supported
    CURSOR_INVALID_AFTER = '0x02000E0B', // Invalid after cursor received
    CURSOR_INVALID_BEFORE = '0x02000E0C', // Invalid before cursor received
    CURSOR_INVALID_BASE_64 = '0x02000E0D', // Invalid base64 encoded cursor received
    CURSOR_FIRST_EXCEEDS_MAX = '0x02000E0E', // First value exceeds specified maximum
    CURSOR_LAST_EXCEEDS_MAX = '0x02000E0F', // Last value exceeds specified maximum

    // 0x02000F	Errors related to channel lists
    CHANNEL_LIST_NOT_FOUND = '0x02000F00', // The ChannelList which is involved in the request, isn't found in the Backend.
    CHANNEL_LIST_OPERATOR_ID_NOT_ALLOWED = '0x02000F01', // The ChannelList identifier which is involved in the request, identifies an operator channel list.
    CHANNEL_LIST_CHANNEL_POSSITION_NEGATIVE = '0x02000F02', // The position for the channel in the channel list is a negative value.
    INITIAL_CHANNEL_LIST_NOT_FOUND = '0x02000F03', // No initial channel list could be found for current device type.
    OPERATOR_CHANNEL_LIST_MOVE_NOT_ALLOWED = '0x02000F04', // Operator channel lists are not allowed to be moved.
    CHANNEL_LIST_UNKNOWN_CHANNEL_ID = '0x02000F05', // Unknown channel identified in personal channel list creation
    CHANNEL_LIST_CHANNEL_DUPLICATE = '0x02000F06', // Duplicate channel included in channels for channel lists
    OPERATOR_CHANNEL_LIST_UPDATE_NOT_ALLOWED = '0x02000F07', // A request was made to update an operator channel list

    // 0x020010	Errors related to prices and purchases
    PRICE_NOT_DETERMINED = '0x02001000', // A price could not be determined for a product.
    PRODUCT_NOT_PROVISIONED = '0x02001001', // A product is purchased but not yet provisioned.
    PRODUCT_NOT_FOUND = '0x02001002', // A product which is involved in the request, couldn't be found in the Backend.
    TRANSACTION_EXPIRED = '0x02001003', // The transaction that was being used for a purchase has expired.
    UNEXPECTED_PRODUCT_BUSINESS_LOGIC = '0x02001004', // Unexpected product business logic prevented successful execution of this request
    PURCHASE_FAILED_PRICE_NOT_FOUND = '0x02001005', // Purchase failed. Price for given pricing reference could not be found.
    PURCHASE_FAILED_PRICE_OFFER_EXPIRED = '0x02001006', // Purchase cannot be fulfilled due to expiration of pricing offer.
    PURCHASE_INVALID_REQUEST_DATA = '0x02001007', // Purchase cannot be fulfilled due to wrong request data.
    UNEXPECTED_PURCHASE_BUSINESS_LOGIC = '0x02001008', // Unexpected PURCHASE business logic prevented successful execution of this request.
    PRICE_INVALID_REQUEST_DATA = '0x02001009', // Price could not be returned due to wrong request data.
    PRICE_OSS_BSS_ERROR = '0x0200100A', // Price could not be returned due to OSS/BSS issue.
    PRICE_INVALID_DATA_FORMAT = '0x0200100B', // Price could not be returned due to wrong data format.
    UNEXPECTED_PRICING_BUSINESS_LOGIC = '0x0200100C', // Unexpected PRICING business logic prevented successful execution of this request.
    PURCHASE_INFO_STATUS_INVALID_REQUEST_DATA = '0x0200100D', // Purchase Info Status could not be returned due to wrong request data.
    UNEXPECTED_PURCHASE_INFO_BUSINESS_LOGIC = '0x0200100E', // Unexpected PURCHASE INFO business logic prevented successful execution of this request.

    // 0x020011	Errors related to content data retrieval
    CONTENT_LIST_NOT_FOUND = '0x02001100', // A requested content folder (list) could not be found
    CONTENT_INVALID_QUERY = '0x02001101', // A content related query could not be parsed as a valid query
    CONTENT_INVALID_USE = '0x02001102', // A content query was used in an invalid way

    // 0x020012	Errors related to recommendations
    RECOMMENDATION_SETUP_ERROR = '0x02001200', // An error was detected in the recommendation setup
    RECOMMENDATION_ENTITLEMENT_ERROR = '0x02001201', // An entitlement error occurred while executing the recommendation
    RECOMMENDATION_RESULT_ERROR = '0x02001202', // An error occurred while parsing a recommendation result
    RECOMMENDATION_EPG_ID_NOT_FOUND = '0x02001203', // A supplied EPG id for the recommendation could not be found

    // 0x020013	Errors related to authorization
    UNEXPECTED_AUTHORIZATION_BUSINESS_LOGIC = '0x02001300', // Unexpected authorization business logic prevented successful execution of this request

    // 0x020014	Errors related to favourites
    FAVOURITES_LIMIT_REACHED = '0x02001400', // Maximum number of favourites reached.

    // 0x020015	Errors related to blockedChannels
    UNEXPECTED_BLOCKED_CHANNEL_BUSINESS_LOGIC = '0x02001500', // Unexpected blockedChannels business logic prevented successful execution of this request.

    // 0x020016	Errors related to bookmarks
    UNEXPECTED_BOOKMARK_BUSINESS_LOGIC = '0x02001600', // Unexpected bookmark business logic prevented successful execution of this request.
    BOOKMARK_MISSING_ENTITLEMENT = '0x02001601', // Bookmark could not be retrieved, set or deleted because there is no entitlement for this item.

    // 0x020017	Errors related to reminders
    UNEXPECTED_REMINDER_BUSINESS_LOGIC = '0x02001700', // Unexpected reminder business logic prevented successful execution of this request.
    REMINDER_NOT_FOUND = '0x02001701', // A reminder with the given id could not be found
    REMINDER_ALREADY_EXISTS = '0x02001702', // A request was received to set a reminder, but a reminder for that event already exists.
    REMINDER_INVALID_EVENT_TIME = '0x02001703', // A request was received to set a reminder, but the event is in the past.

    // 0x020018	Errors related to messages
    MESSAGE_NOT_FOUND = '0x02001800', // Message with the given id could not be found
    MESSAGE_OPERATION_NOT_ALLOWED = '0x02001801', // This operation cannot be performed on a Message with this status
    UNEXPECTED_MESSAGE_BUSINESS_LOGIC = '0x02001802', // MESSAGE business logic prevented successful execution of this request

    // 0x020019	Errors related to banners
    BANNER_NOT_FOUND = '0x02001900', // Banner with the given id could not be found.
    BANNER_TARGET_TYPE_NOT_SUPPORTED = '0x02001901', // Banner target type not supported

    // 0x020020	Errors related to applications
    APP_NOT_FOUND = '0x02002000', // Application with the given id could not be found.

    // 0x020021	Errors related to search
    UNEXPECTED_SEARCH_BUSINESS_LOGIC = '0x02002100', // SEARCH business logic prevented successful execution of this request

    // 0x0302	General data cache exceptions
    CACHE_PARSE_ERROR = '0x03020000', // An error occurred while parsing the published general data, for which no more specific error code exists.
    CACHE_INVALID_DATA = '0x03020001', // During the handling of a request, invalid data was encountered in the General Data Cache. In theory this shouldn't occur since this should be blocked during the ingest.

    // 0x0303	EPG data cache exceptions
    EPG_CACHE_CONFLICT_KEY = '0x03030000', // Conflicting cache key detected
    EPG_CACHE_VALIDATION_FAILED = '0x03030001', // The EPG data failed to validate
    EPG_CACHE_INGEST_ERROR = '0x03030002', // An error occured during ingest of EPG data

    // 0x0304	VOD data cache exceptions
    VOD_CACHE_CONFLICT_KEY = '0x03040000', // Conflicting cache key detected
    VOD_CACHE_VALIDATION_FAILED = '0x03040001', // The VOD data failed to validate
    VOD_CACHE_INGEST_ERROR = '0x03040002', // An error occured during ingest of VOD data

    // 0x0401	Nova Policy exceptions
    NOVA_DEVICE_LIMIT_REACHED = '0x04010000', // The maximum number of devices with NOVA enabled was reached
    NOVA_DEVICE_CHANGES_NOT_ALLOWED = '0x04010001', // Changes to the NOVA enabled device are not yet allowed

    // 0x0402	Nova Policy exceptions
    UNEXPECTED_NOVA_POLICY_BUSINESS_LOGIC = '0x04020000', // POLICY business logic prevented successful execution of this request
    NOVA_CONCURRENT_STREAMS_LIMIT_REACHED = '0x04020001', // The maximum number of concurrent streams have been reached for this request
    NOVA_VPN_PROHIBITED = '0x04020002', // The usage of a VPN connection is prohibited for this request
    NOVA_CELLULAR_PROHIBITED = '0x04020003', // The usage of a cellular connection is prohibited for this request
    NOVA_DEVICE_NOT_LOCATED_ON_NETWORK = '0x04020004', // The device is not located on the network which prohibited for this request
    NOVA_DEVICE_PROHIBITED = '0x04020005', // Streaming to the device is prohibited for this request
    NOVA_STERAM_OUTSIDE_EU_PROHIBITED = '0x04020006', // Streaming outside of the EU  is prohibited for this request
    NOVA_DEVICE_NOT_ENABLED = '0x04020007', // Streaming to a non NOVA enabled device is prohibited for this request
    NOVA_RECORDING_TIME_RESTRICTION = '0x04020008', // Streaming of the recording on this device is prohibited due to time restrictions.
    NOVA_DEVICE_NO_VIEWING_RIGHTS = '0x04020009', // This device currently doesn't have the viewing rights for this event. You can claim the viewing rights if no other device in your household is watching this event. Claiming this right might take up to 30 sec.
    NOVA_CHANNEL_PB_DEVICE_TYPE_NOT_COMPATIBLE = '0x0402000A', // NPVR playback is not allowed on this channel with your device type.

    // 0xFF00	General backend exceptions
    BE_COMMUNICATION_ERROR = '0xFF000000', // An error occurred while communicating with a backend system
    BE_UNSUPPORTED_OPERATION = '0xFF000001', // Unsupported operation: The requested operation is not supported on the currently active backend
    BE_CONCURENCY_VIOLATION = '0xFF000002', // Concurrency violation: An update was requested for an object that has already been updated by another request
    BE_TIMEOUT_ERROR = '0xFF000003', // A timeout occurred while communicating with a backend system

    // 0xFF01	SDS Evo backend exceptions
    SDS_EVO_UNEXPECTED_COMMUNICATION_ERROR = '0xFF010000', // An unexpected error occurred while communicating with SDS Evolution.
    SDS_EVO_COMMUNICATION_ERROR = '0xFF010001', // An error occurred while performing network communication with SDS Evolution.
    SDS_EVO_INVALID_RESPONSE = '0xFF010002', // The reply received from SDS Evolution was not valid.
    SDS_EVO_INVALID_DEVICE_ID = '0xFF010003', // The required SDS Evo device Id was either missing from the request or invalid.
    SDS_EVO_MQTT_NOT_SET_UP = '0xFF010004', // MQTT is not set up correctly in SDS Evolution
    SDS_EVO_UNEXPECTED_FILE_FORMAT = '0xFF010101', // File format is unexpected
    SDS_EVO_MULTILINGUAL_INCONSISTENT_FILE = '0xFF010102', // Multilingual file contents is inconsistent (e.g. there are differences between multilingual files besides the multilingual strings)
    SDS_EVO_UNEXPECTED_FIELD_VALUE = '0xFF010201', // Unexpected field value (An entry in an SDS Evo API response - or file - has a value that we didn't expect)
    SDS_EVO_ERROR_CONTENT_NOT_FOUND = '0xFF01F000', // SDS Evolution error code "errContentNotFound"
    SDS_EVO_ERROR_HOUSEHOLD_PGI_ALREADY_EXISTS = '0xFF01F001', // SDS Evolution error code "errHhPgiAlreadyExist"
    SDS_EVO_ERROR_HOUSEHOLD_RECORDING_TCS = '0xFF01F002', // SDS Evolution error code "errHhRecordingTcs"
    SDS_EVO_ERROR_ORBIT_UNKNOWN_ERROR = '0xFF01F003', // SDS Evolution error code "errOrbUnknownError"
    SDS_EVO_ERROR_PLAYBACK_SESSION_ALREADY_EXISTS = '0xFF01F004', // SDS Evolution error code "errPbSessAlreadyExist"
    SDS_EVO_ERROR_PRODUCT_NOT_FOUND = '0xFF01F005', // SDS Evolution error code "errProdNotFound"
    SDS_EVO_ERROR_SERVICE_NOT_FOUND = '0xFF01F006', // SDS Evolution error code "errServiceNotFound"
    SDS_EVO_ERROR_STRA_NOT_FOUND = '0xFF01F007', // SDS Evolution error code "errStraNotFound"
    SDS_EVO_ERROR_USER_AS_BAD_TYPE = '0xFF01F008', // SDS Evolution error code "errUsrAsBadType"
    SDS_EVO_ERROR_AST_FILE_NOT_FOUND = '0xFF01F009', // SDS Evolution error code "errAstFileNotFound"
    SDS_EVO_ERROR_LIC_LIMIT_EXCEEDED = '0xFF01F00A', // SDS Evolution error code "errLicLimitExceeded"

    // Rest Api Error
    ERROR_EXPIRED_SEC_TOKEN = 'errExpiredSecToken',
    ERROR_BAD_SEC_TOKEN = 'errBadSecToken',
    ERROR_SEC_TOKEN_CUSTOM = 'errSecTokenCustom',
    ERROR_SEC_TOKEN_SESSION = 'errSecTokenSession',
    ERROR_CLIENT_DEVICE_NOT_FOUND = 'errClDevNotFound',
    ERROR_NOT_HOUSEHOLD_DEVICE = 'errNotHoushDevice',
    ERROR_CLIENT_DEVICE_NOT_ENABLED = 'errClDevNotEnabled',
    ERROR_ACCOUNT_DISABLED = 'errAccountDisabled',
    ERROR_ACCOUNT_LOCKED = 'errAccountLocked',
    ERROR_BAD_USER_NAME_OR_PASSWORD = 'errBadUsrNmOrPwd',
    ERROR_MUST_CHANGE_PASSWORD = 'errMustChgPwd',
    ERROR_LIC_LIMIT_EXCEEDED = 'errLicLimitExceeded',
    ERROR_SINGLE_HOUSEHOLD_FOR_USER = 'errSingleHhForUsr',
    ERROR_HOUSHOLD_NOT_ENABLED = 'errHoushNotEnabled',
    ERROR_SINGLE_HOUSEHOLD_FOR_DEVICE = 'errSingleHhForDev',
    ERROR_NOT_SAME_HOUSHOLD = 'errNotSameHoush',
    ERROR_DEV_TYPE_REQ_AUTH = 'errDevTypeReqAuth',
    ERROR_SESS_TIMEOUT_TOO_BIG = 'errSessTimeoutTooBig',
    ERROR_MAM_REFR_SESSIONS = 'errMaxRefrSessions',
    ERROR_DTM_FW_VER_REQ_NULL_AUTH = 'errDtmFwVerReqNullAuth',
    ERROR_UNKNOWN_FW_VERSION = 'errUnknownFwVersion',
    ERROR_BAD_AUTH_HMAC = 'errBadAuthHmac',
    ERROR_DTM_BIG_DEVIATION = 'errDtmBigDeviation',
    ERROR_NO_SESSION = 'errNoSession',
    ERROR_DOMAIN_NOT_RESOLVED = 'errActDomNotResolved',
    ERROR_DEVICE_CREATE_FAILED = 'deviceCreateFailed',

    ERROR_MAX_DEVICES_REACHED = 'errMaxHoushDevs',
}
