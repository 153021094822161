import { clone } from 'lodash';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Api } from 'App/Modules/Data/Detail/Root/Api';
import {
    SeriesDetailLoadContentFragment,
    SeriesDetailLoadDocument,
    SeriesDetailLoadFragment,
    SeriesDetailLoadQueryResult,
    SeriesDetailLoadQueryVariables,
} from 'App/Modules/Data/Detail/Series/Api/seriesDetailLoad.generated';

export { DefaultVariables } from 'App/Modules/Data/Detail/Series/Api/defaultVariables';

const mergeSeries = (
    seriesContentItemsEdges: SeriesDetailLoadContentItems | null,
    response: SeriesDetailLoadQueryResult
): SeriesDetailLoadQueryResult => {
    const copiedResponse = clone(response);
    if (!copiedResponse.data?.series?.content.items || !seriesContentItemsEdges) return response;
    copiedResponse.data.series.content.items.edges = [...seriesContentItemsEdges.edges, ...copiedResponse.data.series.content.items.edges];
    return copiedResponse;
};

type LoadSeries = (variables: SeriesDetailLoadQueryVariables) => Promise<SeriesDetailLoadFragment | null>;

type SeriesDetailLoadContentItems = SeriesDetailLoadContentFragment['content']['items'];

const __parseLoadSeries = (response: SeriesDetailLoadQueryResult) => response.data || null;

const __loadSeries = async (
    variables: SeriesDetailLoadQueryVariables,
    seriesContentItemsEdges: SeriesDetailLoadContentItems | null = null
): Promise<SeriesDetailLoadQueryResult> => {
    const response: SeriesDetailLoadQueryResult = await GraphqlClient.makeGraphqlRequest(
        SeriesDetailLoadDocument,
        variables,
        Api.detailQueryOptions
    );
    Error.collect(response.data?.series);
    const page = response.data?.series?.content?.items?.pageInfo;
    if (!page) return response;
    const nextResponse = mergeSeries(seriesContentItemsEdges, response);
    if (!page.hasNextPage) return nextResponse;
    const nextCursor = page.endCursor;
    if (!nextCursor) return nextResponse;
    const nextVariables: SeriesDetailLoadQueryVariables = { ...variables, folderAfter: nextCursor };
    if (!nextResponse?.data?.series?.content) return nextResponse;
    const prevSeriesContentItemsEdges = nextResponse.data.series.content.items;
    return __loadSeries(nextVariables, prevSeriesContentItemsEdges);
};

export const load: LoadSeries = async (variables) => {
    const loadedSeriesResponse = await __loadSeries(variables);
    return __parseLoadSeries(loadedSeriesResponse);
};
