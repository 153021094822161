import { by639_2T } from 'iso-language-codes';

import {
    CastLabsPlayerConfig,
    FontSizes,
    FontStyles,
    Language,
    ParsedConfigServiceConfig,
    ParsedConfigServiceConfigSearchConfig,
    ParsedConfigServiceConfigSettingsElement,
    ParsedContentMarkerConfig,
} from '__SMART_APP_OLD__/app/modules/Config/types';
import {
    ConfigServiceConfigMenuItem,
    ConfigServiceConfigUARConfig,
    ParsedVodUpsellOptionsConfig,
} from '__SMART_APP_OLD__/app/modules/ConfigService/ConfigServiceConfig';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { SettingsElementType, SettingsLanguageElementType } from '__SMART_APP_OLD__/app/types';

import { ContentMarkerType } from 'App/Types/ContentMarker';
import { MenuItemType } from 'App/Types/Menu';

export const selectConfig = (state: State): ParsedConfigServiceConfig => state.config;

export const selectStandByInactivityTimer = (state: State): number => state.config.standBy.inactivityTimer;

export const selectStandByActivated = (state: State): boolean => state.config.standBy.activated;

export const selectSearchConfig = (state: State): ParsedConfigServiceConfigSearchConfig => state.config.search;

export const selectMenuItems = (state: State): ConfigServiceConfigMenuItem[] => state.config.menu.items;

export const selectMenuItemsIds = (state: State): MenuItemType[] => state.config.menu.ids;

export const selectMenuItemTitle =
    (id: MenuItemType) =>
    (state: State): string =>
        state.config.menu.titleForMenuItemType[id] ?? '';

export const selectUarConfig = (state: State): ConfigServiceConfigUARConfig => state.config.uar;

export const selectResetPasswordQRCode = (state: State): string | undefined => state.config.login.resetPasswordQRCode;

export const selectReminderTimer = (state: State): number => state.config.reminder.timer;

export const selectChannelSwitchTimeout = (state: State): number => state.config.player.channelSwitchDialogTimeout;

export const selectVodEndedInactivityTimeout = (state: State): number => state.config.player.vodEndedInactivityTimeout;

export const selectIsContentDiscoveryEnabled = (state: State): boolean => state.config.player.isContentDiscoveryEnabled;

export const selectBingeWatchingThreshold = (state: State): number => state.config.player.bingeWatchingThreshold;

export const selectIsBingeWatchingEnabled = (state: State): boolean => state.config.player.isBingeWatchingEnabled;

export const selectShowSeriesSeason = (state: State): boolean => state.config.player.showSeriesSeason;

export const selectPlayerSettings = (state: State): CastLabsPlayerConfig => state.config.player.playerSettings;

export const selectSubFontSizes = (state: State): FontSizes[] => state.config.player.subtitleConfig.subFontSizes;

export const selectSubFontStyles = (state: State): FontStyles[] => state.config.player.subtitleConfig.subFontStyles;

export const selectIsSubtitlesEditable = (state: State): boolean => state.config.player.subtitleConfig.isSubtitlesEditable;

export const selectPlayerSettingsLowLatency = (state: State): CastLabsPlayerConfig => state.config.player.playerSettingsLowLatency;

export const selectPlayerSettingsCatchup = (state: State): CastLabsPlayerConfig => state.config.player.playerSettingsCatchup;

export const selectContentMarker =
    (marker?: ContentMarkerType | null) =>
    (state: State): ParsedContentMarkerConfig | null =>
        marker ? state.config.contentMarkers[marker] ?? null : null;

export const selectPlayReadyDrmSettings = (state: State): string => state.config.dataSource.DRM.license_server_playready;

export const selectWidewineDrmSettings = (state: State): string => state.config.dataSource.DRM.license_server_widevine;

export const selectDrmProtection = (state: State): boolean => state.config.player.drmProtection;

export const selectBackgroundWidth = (state: State): number => state.config.image.background.width;

export const selectBackgroundLandscapeHeight = (state: State): number => state.config.image.background.landscape;

export const selectBackgroundPortraitHeight = (state: State): number => state.config.image.background.portrait;

export const selectIsVodUpsellOptions = (state: State): ParsedVodUpsellOptionsConfig => state.config.vodUpsellConfig[Platform.DEVICE_NAME];

export const selectThumbnailDefaultHeight = (state: State): number => state.config.image.thumbnail.default.height;

export const selectShowCatchupIcon = (state: State): boolean => state.config.player.showCatchupIcon;

export const selectHideControlsAfter = (state: State): number => state.config.player.hideControlsAfter;

export const selectBookmarkStartTimeout = (state: State): number => state.config.player.bookmarkStartTimeout;

export const selectLiveCatchupThreshold = (state: State): number => state.config.player.liveCatchupPlaybackThreshold;

export const selectStartOverMinPosition = (state: State): number => state.config.player.startoverMinPositionMs;

export const selectPrivacyPolicyLastChanged = (state: State): number => state.config.privacyPolicy.lastChangedTS;

export const selectPrivacyPolicyShowLegacyGdpr = (state: State): boolean => state.config.privacyPolicy.showLegacyGdpr;

export const selectIdpProviderActive = (state: State): boolean => state.config.idpLogin.activated;

export const selectIdpProviderUrl = (state: State): string => state.config.idpLogin.url;

export const selectIdpProviderGrantType = (state: State): string => state.config.idpLogin.grantType;

export const selectIdpProviderClientScope = (state: State): string => state.config.idpLogin.clientScope;

export const selectIdpProviderClientSecret = (state: State): string => state.config.idpLogin.clientSecret;

export const selectIdpProviderClientId = (state: State): string => state.config.idpLogin.clientId;

export const selectHouseholdTrackList = (state: State): number[] => state.config.player.householdTrackList;

export const selectLowLatencyChannelsList = (state: State): string[] => state.config.player.lowLatencyChannelsList;

export const selectDataSource = (state: State) => state.config.dataSource;

export const selectDefaultAudioLanguage = (state: State): string => state.config.language.defaultAudioLanguageCode;

export const selectDefaultSubtitleLanguage = (state: State): string => state.config.language.defaultSubtitleLanguageCode;

export const selectAvailableAudioLanguages = (state: State): Record<string, Language> => state.config.language.availableAudioLanguages;

export const selectAvailableSubtitleLanguages = (state: State): Record<string, Language> =>
    state.config.language.availableSubtitleLanguages;

export const selectUiLanguages = (state: State): any[] => {
    const availableLanguages = state.config.language.translationsUrlForLanguageCode;
    const result = Object.keys(availableLanguages).map((langCode) => {
        const langObject = by639_2T[langCode as keyof typeof by639_2T];
        return { title: `SETTINGS_${langObject.iso639_1.toUpperCase()}`, iso_code: langObject.iso639_1, backendCode: langObject.iso639_2B };
    });
    return result;
};

export const selectDefaultUiLanguage = (state: State): string => state.config.language.defaultUiLanguage;

export const selectPlayerCatchupPlaybackThreshold = (state: State): number => state.config.player.liveCatchupPlaybackThreshold;

export const selectResumeThreshold = (state: State): number => state.config.player.resumeThreshold;

export const selectPlayerSkipForwardStep = (state: State): number => state.config.player.skipForwardInterval;

export const selectPlayerSkipBackwardStep = (state: State): number => state.config.player.skipBackInterval;

export const selectMaxItemCollectionLayout = (state: State): number => state.config.layout.collectionMaxItems;

export const selectSettingsItemsList = (state: State): ParsedConfigServiceConfigSettingsElement<SettingsElementType>[] =>
    state.config.settings.elements;

export const selectLanguageSettingsScreenTitle = (state: State): string =>
    state.config.settings.elements.find((el) => el.id === SettingsElementType.LANGUAGE)?.title ?? '';

export const selectSettingsLanguageItemsList = (state: State): ParsedConfigServiceConfigSettingsElement<SettingsLanguageElementType>[] =>
    state.config.settings.languageElements;

export const selectIsSeriesRecordingAllowed = (state: State): boolean => state.config.recording.isSeriesRecordingAllowed;

export const selectRadioFallbackImage = (state: State): string => state.config.player.radioFallbackImage;

export const selectConfigName = (state: State): string => `${state.config.metadata.configId}_${state.config.metadata.version}`;
