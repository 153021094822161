import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { ProgressBar } from '__SMART_APP_OLD__/app/components/ProgressBar';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardPortraitProgressProps) => {
    const classlist = ['portrait-card-v3__progress'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardPortraitProgressProps = {
    className?: string;
    color?: BackgroundColor;
    theme?: Theme.Type;
    progress: number;
};

export type CardPortraitProgressComponent = React.FunctionComponent<CardPortraitProgressProps>;

export const CardPortraitProgress: CardPortraitProgressComponent = (props) => (
    <Mask masked={null}>
        <ProgressBar className={getClassName(props)} progress={props.progress} color={props.color} theme={props.theme} />
    </Mask>
);
