import { initialEventDetailReducerState } from 'App/Modules/Data/Detail/Event/Store/initialState';
import { initialDetailSeriesState } from 'App/Modules/Data/Detail/Series/Store/initialState';
import { initialVodAssetDetailReducerState } from 'App/Modules/Data/Detail/VodAsset/Store/initialState';
import { initialVodDetailSeriesState } from 'App/Modules/Data/Detail/VodSeries/Store/initialState';
import { DetailState } from 'App/Modules/Data/Detail/Root/Types/Types';
import { initialRecordingDetailReducerState } from 'App/Modules/Data/Detail/NetworkRecording/Store/initialState';

export const initialDetailReducerState: DetailState = {
    event: initialEventDetailReducerState,
    vodAsset: initialVodAssetDetailReducerState,
    series: initialDetailSeriesState,
    vodSeries: initialVodDetailSeriesState,
    recording: initialRecordingDetailReducerState,
};
