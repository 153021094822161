import React from 'react';

import RecordingIcon, { RecordingIconProps } from '__SMART_APP_OLD__/app/components/Icon/RecordingIcon';

const getClassName = (props: CardLandscapeIconRecordingProps) => {
    const classlist = ['landscape-card-v3__icon'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeIconRecordingProps = RecordingIconProps;

export type CardLandscapeIconRecordingComponent = React.FunctionComponent<CardLandscapeIconRecordingProps>;

export const CardLandscapeIconRecording: CardLandscapeIconRecordingComponent = (props) => (
    <RecordingIcon {...props} className={getClassName(props)} />
);
