import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { parentalRatingChange, parentalRatingLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/actions';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const select = (id: string): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(parentalRatingChange(id));
    await dispatch(Fti.actions.next(Screen.Type.AGE_RATING_SELECTION));
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(parentalRatingLoad());
    dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    public: {},
    private: {
        select,
    },
    lifecycle: {
        mount,
        unmount,
    },
} as const;
