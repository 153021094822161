import React from 'react';

const getClassName = (props: CardPortraitMetaProps) => {
    const classlist = ['portrait-card-v3__meta'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardPortraitMetaProps = React.PropsWithChildren<{ className?: string }>;

export type CardPortraitMetaComponent = React.FunctionComponent<CardPortraitMetaProps>;

export const CardPortraitMeta: CardPortraitMetaComponent = (props) => <div className={getClassName(props)}>{props.children}</div>;
