import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch, getState) => {
    const currentScreenType = Screen.selectors.select(getState())?.type;

    if (!currentScreenType) return undefined;

    if (ctx === UI.Detail.Constants.Action.ShowMore.Ctx && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(currentScreenType, UI.Folder.Constants.Ctx));
    }

    if (ctx === UI.Folder.Constants.Ctx && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(currentScreenType, UI.Detail.Constants.Action.ShowMore.Ctx));
    }

    return undefined;
};

export const DefaultDetailScreenOutOfBounds = {
    outOfBounds,
};
