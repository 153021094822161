import { initialAuthSessionState } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/constants';
import { AuthSessionState } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const authSessionReducer: Reducer<AuthSessionState> = (state, action) => {
    if (!state) return initialAuthSessionState;
    switch (action.type) {
        case ActionType.AUTH_SESSION_STATUS_CHANGE:
            if (action.payload.status === state.status) return state;
            return { ...state, status: action.payload.status };
        case ActionType.AUTH_SESSION_LOG_LOGIN_UAR:
            return { ...state, isLoginWithCode: action.payload.isLoginWithCode, logAction: action.payload.logAction };
        case ActionType.AUTH_SESSION_START_LEGACY_CALLBACKS:
            return { ...state, legacyCallbacksInterval: action.payload.legacyCallbacksInterval };
        default:
            return state;
    }
};
