import React, { useMemo } from 'react';

import { LandscapeCard } from '__SMART_APP_OLD__/app/components/LandscapeCard';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectMessageDetailAttachmentIcon,
    selectMessageDetailAttachmentImage,
    selectMessageDetailAttachmentTitle,
    selectMessageDetailIsAttachmentView,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

import { Focus } from 'App/Packages/Focus';

export const MessageDetailAttachment: React.FunctionComponent<Focus.Focusable<GQL.MessageAttachmentID>> = (props) => {
    const title = useSelector(selectMessageDetailAttachmentTitle(props.id));
    const icon = useSelector(selectMessageDetailAttachmentIcon(props.id));
    const image = useSelector(selectMessageDetailAttachmentImage(props.id));
    const isAttachmentView = useSelector(selectMessageDetailIsAttachmentView);
    const icons = useMemo(() => [icon], [icon]);
    return (
        <LandscapeCard
            ctx={props.ctx}
            id={props.id}
            className="message-detail__attachment"
            title={title}
            icons={icons}
            image={image}
            imageOnly={isAttachmentView}
            isFocused={props.isFocused}
            onEnter={props.onEnter}
            onHover={props.onHover}
        />
    );
};
