import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectTVGuideEventDescription, selectTVGuideEventHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { TVGuideDetailChannelLogo } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideDetailChannelLogo';
import { TVGuideMetaInfo } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideMetaInfo';

export const TVGuideDetail: React.FunctionComponent = () => {
    const header = useSelector(selectTVGuideEventHeader);
    const description = useSelector(selectTVGuideEventDescription);

    return (
        <div className="tv-guide__detail">
            <TVGuideDetailChannelLogo />
            <Text
                className="tv-guide__detail-header"
                typeface={Typeface.SERIF}
                size={FontSize.TITLE_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {header}
            </Text>
            <TVGuideMetaInfo />
            <Text
                className="tv-guide__detail-description"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
            >
                {description}
            </Text>
        </div>
    );
};
