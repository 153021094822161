import React, { useCallback, useMemo } from 'react';

import { Function } from 'App/Packages/Function';
import { DurationOptions } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';
import { Constants } from 'App/Packages/UI/Suggestion/Constants';

const getItem = (
    target: HTMLDivElement,
    container: HTMLDivElement,
    value: string,
    values: string[],
    offset: number
): HTMLDivElement | null => {
    if (offset === 0) return target;
    const index = values.indexOf(value);
    const item = container.children.item(index - offset);
    if (!item || !(item instanceof HTMLDivElement)) return null;
    return item;
};

export type UseSuggestionScrollIntoView = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    value: string,
    focused: Subscribable.Value<boolean>,
    offset?: number
) => (() => Promise<void>) & { instant: Subscribable.Value<boolean> };

export const useSuggestionScrollIntoView: UseSuggestionScrollIntoView = (ref, value, focused, offset = Constants.List.Scroll.Offset) => {
    const list = SuggestionList.use();
    const instant = Subscribable.useValue(false);

    const scrollIntoView = useCallback(async () => {
        const isInstant = instant.get();
        instant.set(false);

        const target = ref.current;
        const container = list.ref.current;
        if (!target || !container) return;
        if (!isInstant) await Function.sleep(Constants.Item.Scroll.Delay);
        if (!focused.get()) return;
        const item = getItem(target, container, value, list.values.get(), offset);
        const duration: DurationOptions | undefined = isInstant ? { max: 0, min: 0, ratio: 0 } : undefined;
        if (!item || !(item instanceof HTMLElement)) list.scroll.to({ left: 0, duration });
        else list.scroll.to({ left: Constants.List.Scroll.calc(item.offsetLeft - container.offsetLeft), duration });
    }, [ref, value, focused, offset, instant, list]);

    return useMemo(() => Object.assign(scrollIntoView, { instant }), [scrollIntoView, instant]);
};
