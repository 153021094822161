import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { messageInboxBackTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { MessageInboxHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxHeader';
import { MessageInboxList } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxList';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const classNameForThemeType: Record<Theme.Type, string> = {
    DARK: 'message-inbox--dark-theme',
    LIGHT: 'message-inbox--light-theme',
};

const getClassName = (theme: Theme.Type): string => {
    const classList = ['message-inbox'];
    classList.push(classNameForThemeType[theme]);
    return classList.join(' ');
};

export const MessageInbox: React.FunctionComponent = () => {
    const theme = useTheme();
    const className = getClassName(theme);
    const dispatch = useDispatch();
    Focus.useBack(() => dispatch(messageInboxBackTriggered));

    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex} className={className}>
            <MessageInboxHeader />
            <div className="message-inbox__list-container">
                <MessageInboxList />
            </div>
        </Layout>
    );
};
