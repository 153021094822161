import * as Types from '../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeHouseholdOnboardingInfoMutationVariables = Types.Exact<{
  input: Types.ChangeHouseholdOnboardingInfoInput;
}>;


export type ChangeHouseholdOnboardingInfoMutation = { __typename?: 'Nexx4Mutations', changeHouseholdOnboardingInfo: { __typename?: 'ChangeHouseholdOnboardingInfoPayload', onboardingInfo?: { __typename?: 'HouseholdOnboardingInfo', id: string } | null } };


export const ChangeHouseholdOnboardingInfoDocument = gql`
    mutation changeHouseholdOnboardingInfo($input: ChangeHouseholdOnboardingInfoInput!) {
  changeHouseholdOnboardingInfo(input: $input) {
    onboardingInfo {
      id
    }
  }
}
    `;
export type ChangeHouseholdOnboardingInfoMutationFn = Apollo.MutationFunction<ChangeHouseholdOnboardingInfoMutation, ChangeHouseholdOnboardingInfoMutationVariables>;

/**
 * __useChangeHouseholdOnboardingInfoMutation__
 *
 * To run a mutation, you first call `useChangeHouseholdOnboardingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeHouseholdOnboardingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeHouseholdOnboardingInfoMutation, { data, loading, error }] = useChangeHouseholdOnboardingInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeHouseholdOnboardingInfoMutation(baseOptions?: Apollo.MutationHookOptions<ChangeHouseholdOnboardingInfoMutation, ChangeHouseholdOnboardingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeHouseholdOnboardingInfoMutation, ChangeHouseholdOnboardingInfoMutationVariables>(ChangeHouseholdOnboardingInfoDocument, options);
      }
export type ChangeHouseholdOnboardingInfoMutationHookResult = ReturnType<typeof useChangeHouseholdOnboardingInfoMutation>;
export type ChangeHouseholdOnboardingInfoMutationResult = Apollo.MutationResult<ChangeHouseholdOnboardingInfoMutation>;
export type ChangeHouseholdOnboardingInfoMutationOptions = Apollo.BaseMutationOptions<ChangeHouseholdOnboardingInfoMutation, ChangeHouseholdOnboardingInfoMutationVariables>;