import {
    selectDevicesCount,
    selectMaxNumberOfUnmanagedDevices,
} from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectSubtitle = (state: State): string =>
    Translation.selectors
        .select('SETTINGS_DEVICE_MANAGEMENT_DESCRIPTION_COUNT')(state)
        .replace('%devices_count%', `${selectDevicesCount(state)}`)
        .replace('%max_devices%', `${selectMaxNumberOfUnmanagedDevices(state)}`);

export const selectors = {
    public: {},
    private: {
        selectSubtitle,
    },
} as const;
