import { Common } from '__SMART_APP_OLD__/app/common/mapper/common';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    MessageActionEntity,
    MessageAttachmentEntity,
    MessageEntity,
    MessageEntityNotificationInfo,
    MessageImageAttachmentEntity,
    MessageVODAssetAttachmentEntity,
    TrailerEntity,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';

export const isActionValid = (action: GQL.Maybe<GQL.MessageAction>): action is GQL.MessageAction => {
    if (!action) return false;
    const uri = action.uri.toLowerCase();
    if (uri.includes('nexx4:home')) return true; // nexx4:Home[/]
    if (uri.includes('nexx4:mylibrary')) return true; // nexx4:MyLibrary[/]
    if (uri.includes('nexx4:ondemand')) return true; // nexx4:OnDemand[/][?id=theContentFolderId123]
    if (uri.includes('nexx4:search')) return true; // nexx4:Search[/]?[orderContentType=EVENTS][&query=theUrlEncodedSearchString]
    if (uri.includes('nexx4:detailedtvinfo')) return true; // nexx4:DetailedTVInfo[/]?id=theEventId123
    if (uri.includes('nexx4:detailedondemandvideoinfo')) return true; // nexx4:DetailedOnDemandVideoInfo[/]?id=theVodAssetId123
    if (uri.includes('nexx4:detailedondemandseriesinfo')) return true; // nexx4:DetailedOnDemandSeriesInfo[/]?id=theSeriesID123
    if (uri.includes('nexx4:detailedrecordinginfo')) return true; // nexx4:DetailedRecordingInfo[/]?id=theRecordingId123
    if (uri === 'nexx4:settings' || uri === 'nexx4:settings/') return true; // nexx4:Settings[/]
    if (uri.includes('nexx4:settings/languages')) return true; // nexx4:Settings/languages[/]
    if (uri.includes('nexx4:settings/privacy')) return true; // nexx4:Settings/privacy[/]
    if (uri.includes('nexx4:settings/about')) return true; // nexx4:Settings/about[/]
    // nexx4:DetailedOnDemandBoxsetInfo[/]?id=theBoxsetID123 // Not sure what page is this
    return false;
};

export const isTrailerValid = (trailer: GQL.Maybe<GQL.Trailer>): trailer is GQL.Trailer => !!trailer;

export const isAttachmentValid = (attachment: GQL.Maybe<GQL.MessageAttachment>): attachment is GQL.MessageAttachment => !!attachment;

export const isScreenValid = (
    screen: GQL.Maybe<GQL.MessageNotificationDisplayInfoScreens>
): screen is GQL.MessageNotificationDisplayInfoScreens => !!screen;

export const toAction = (action: GQL.MessageAction): MessageActionEntity => ({
    id: action.id,
    title: action.title,
    uri: action.uri.toLowerCase(),
});

export const toImageAttachment = (attachment: GQL.MessageImageAttachment): MessageImageAttachmentEntity => ({
    id: attachment.id,
    image: attachment.image?.url ?? null,
    title: 'IMAGE',
    type: GQL.MessageAttachmentType.Image,
});

export const toTrailer = (trailer: GQL.Trailer): TrailerEntity => ({ id: trailer.id, title: trailer.title });

export const toVODAssetAttachment = (attachment: GQL.MessageVODAssetAttachment): MessageVODAssetAttachmentEntity => ({
    id: attachment.id,
    image: attachment.backgroundImage?.url ?? null,
    title: attachment.title,
    parentalRatingId: attachment.parentalRating.id,
    trailers: attachment.trailers.items.filter(isTrailerValid).map(toTrailer),
    type: GQL.MessageAttachmentType.Vodasset,
});

export const toAttachment = (attachment: GQL.MessageAttachment): MessageAttachmentEntity =>
    attachment.__typename === 'ImageItem' ? toImageAttachment(attachment) : toVODAssetAttachment(attachment);

export const toEntity = (message: GQL.Message): MessageEntity => ({
    id: message.id,
    title: message.title,
    label: message.label,
    message: message.message,
    image: message.backgroundImage?.url ?? null,
    icon: message.icon?.url ?? null,
    displayKind: message.displayKind,
    timestamp: new Date(message.timestamp).getTime(),
    validFrom: new Date(message.validFrom).getTime(),
    validUntil: new Date(message.validUntil).getTime(),
    actions: (message.actions?.items ?? []).filter(isActionValid).map(toAction),
    attachments: Common.toEntityTable((message.attachments?.items ?? []).filter(isAttachmentValid).map(toAttachment)),
});

export const toTriggerRecord = (message: GQL.Message, entity?: MessageEntityNotificationInfo): MessageEntityNotificationInfo | null => {
    if (message.status !== GQL.MessageStatus.New) return null;
    if (message.displayKind !== GQL.MessageDisplayKind.Notification) return null;
    const screens = (message.notificationInfo?.screens ?? []).filter(isScreenValid);
    if (!screens.length) return null;
    return {
        triggeredTimes: entity?.triggeredTimes ?? 0,
        nextTriggerTS: entity?.nextTriggerTS ?? new Date(message.validFrom).getTime(),
        repeatDelay: message.notificationInfo?.repeatDelay ?? null,
        repeatTimes: message?.notificationInfo?.repeatTimes ?? null,
        screens,
        timeout: message?.notificationInfo?.timeout ?? entity?.timeout ?? 30,
    };
};

export const toEntities = (messages: GQL.Message[]): Record<GQL.MessageID, MessageEntity> =>
    messages.reduce<Record<GQL.MessageID, MessageEntity>>((entities, message) => {
        entities[message.id] = toEntity(message);
        return entities;
    }, {});

export const toStatusEntityTable = (messages: GQL.Message[]): Record<GQL.MessageID, GQL.MessageStatus> =>
    messages.reduce<Record<GQL.MessageID, GQL.MessageStatus>>((entities, message) => {
        entities[message.id] = message.status;
        return entities;
    }, {});

export const toTriggerTable = (
    messages: GQL.Message[],
    triggerTable: Record<GQL.MessageID, MessageEntityNotificationInfo>
): Record<GQL.MessageID, MessageEntityNotificationInfo> =>
    messages.reduce<Record<GQL.MessageID, MessageEntityNotificationInfo>>((entities, message) => {
        const info = toTriggerRecord(message, triggerTable[message.id]);
        if (info) entities[message.id] = info;
        return entities;
    }, {});

export const toReadUpdatedStatusEntityTable = (
    statusTable: Record<GQL.MessageID, GQL.MessageStatus>,
    messageIds: GQL.MessageID[]
): Record<GQL.MessageID, GQL.MessageStatus> =>
    messageIds.reduce<Record<GQL.MessageID, GQL.MessageStatus>>(
        (table, id) => {
            if (table[id] === GQL.MessageStatus.New) table[id] = GQL.MessageStatus.Read;
            return table;
        },
        { ...statusTable }
    );
