import { initialProfileState } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/constants';
import { ProfileActionType, ProfileEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const profileReducer: Reducer<ProfileEntityTable> = (state, action) => {
    if (!state) return initialProfileState;

    switch (action.type) {
        case ProfileActionType.MY_LIBRARY_ADULT_CONTENT_VISIBILITY_CHANGED:
            if (state.myLibraryAdultContentVisibility === action.payload.myLibraryAdultContentVisibility) return state;
            return { ...state, myLibraryAdultContentVisibility: action.payload.myLibraryAdultContentVisibility };
        case ProfileActionType.SHOW_LIVE_CLOCK_CHANGED:
            if (state.showLiveClock === action.payload.showLiveClock) return state;
            return { ...state, showLiveClock: action.payload.showLiveClock };
        case ProfileActionType.PROFILE_INITIAL_DATA_LOAD:
            return {
                ...state,
                preferences: action.payload.preferences,
                userId: action.payload.userId,
                profileId: action.payload.profileId,
                userName: action.payload.userName,
                masterPinCode: action.payload.masterPinCode,
                profiles: action.payload.profiles,
                dataUsageAllowed: action.payload.dataUsageAllowed,
            };
        default:
            return state;
    }
};
