import React from 'react';

import { useDisableFocus } from '__SMART_APP_OLD__/app/hooks/useDisableFocus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useMqtt } from '__SMART_APP_OLD__/app/hooks/useMqtt';
import { usePooling } from '__SMART_APP_OLD__/app/hooks/usePooling';
import { useRecordingScheduling } from '__SMART_APP_OLD__/app/hooks/useRecordingScheduling';
import { useReminderScheduling } from '__SMART_APP_OLD__/app/hooks/useReminderScheduling';
import { useStoreMessageTriggerTable } from '__SMART_APP_OLD__/app/hooks/useStoreMessageTriggerTable';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { reloadChannelLists } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { messageLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { reminderLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { Notification } from '__SMART_APP_OLD__/app/modules/Notification';
import { MoreInfoOverlay } from '__SMART_APP_OLD__/app/modules/Overlay/modules/MoreInfoOverlay';
import { StandByOverlay } from 'App/Modules/Overlay/Modules/StandByOverlay';
import { SelectOverlay } from '__SMART_APP_OLD__/app/modules/Overlay/modules/SelectOverlay';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { AgeRatingSelectionScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/AgeRatingSelectionScreen';
import { AppearanceScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/AppearanceScreen';
import { ChannelListsScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen';
import { ChannelListsScreenCreate } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate';
import { ChannelListsScreenManageChannels } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels';
import { ClosingScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/ClosingScreen';
import { CollectionScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen';
import { CommunitySelectionScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen';
import { DeviceManagementScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen';
import { EducationScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/EducationScreen';
import { LanguagePreferencesScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguagePreferencesScreen';
import { LanguageSelectionScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguageSelectionScreen';
import { MasterPinCodeScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/MasterPinCodeScreen';
import { MessageDetail } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail';
import { messageDetailLifeCycle } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import { MessageInbox } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox';
import { messageInboxLifeCycle } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { NowOnTV } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV';
import { ParentalControlScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/ParentalControlScreen';
import { PinCodeScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/PinCodeScreen';
import { PrivacyPolicyScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen';
import { RecordingManagement } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement';
import { recordingManagementLifycycle } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { ReplaySelectionScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/ReplaySelectionScreen';
import { SettingsScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen';
import { StartupBehaviorScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/StartupBehaviorScreen';
import { TVGuide } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide';
import { ViewControlScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen';
import { WelcomeScreen } from '__SMART_APP_OLD__/app/modules/Screen/modules/WelcomeScreen';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { CONTINUE_WATCHING_CHANGED } from '__SMART_APP_OLD__/config/Events';

import { Alert } from 'App/Modules/Alert';
import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Keyboard } from 'App/Modules/Keyboard';
import { MyLibrary } from 'App/Modules/Screen/Modules/MyLibrary';
import { useInitialize } from '__SMART_APP_OLD__/app/hooks/useInitialize';
import { useZappwareCallbacks } from '__SMART_APP_OLD__/app/hooks/useZappwareCallbacks';
import { Search } from 'App/Modules/Screen/Modules/Search';
import { VodFolders } from 'App/Modules/Screen/Modules/VodFolders';

Theme.initialize();

Overlay.register(Overlay.Type.SELECT, SelectOverlay.View, SelectOverlay.lifecycle);
Overlay.register(Overlay.Type.MORE_INFO, MoreInfoOverlay.View);
Overlay.register(Overlay.Type.StandBy, StandByOverlay.View);
Overlay.register(Overlay.Type.VOD_UPSELL, VodUpsell.View, VodUpsell.lifecycle);

Screen.register(Screen.Type.APPEARANCE, AppearanceScreen.View, AppearanceScreen.lifecycle);
Screen.register(Screen.Type.CLOSING, ClosingScreen.View, ClosingScreen.lifecycle);
Screen.register(Screen.Type.COMMUNITY_SELECTION, CommunitySelectionScreen.View, CommunitySelectionScreen.lifecycle);
Screen.register(Screen.Type.DEVICE_MANAGEMENT, DeviceManagementScreen.View, DeviceManagementScreen.lifecycle);
Screen.register(Screen.Type.EDUCATION, EducationScreen.View, EducationScreen.lifecycle);
Screen.register(Screen.Type.LANGUAGE_SELECTION, LanguageSelectionScreen.View, LanguageSelectionScreen.lifecycle);
Screen.register(Screen.Type.MESSAGE_DETAIL, MessageDetail);
Screen.register(Screen.Type.MESSAGE_INBOX, MessageInbox);
Screen.register(Screen.Type.MESSAGE_DETAIL, MessageDetail, messageDetailLifeCycle);
Screen.register(Screen.Type.MESSAGE_INBOX, MessageInbox, messageInboxLifeCycle);
Screen.register(Screen.Type.NOW_ON_TV, NowOnTV);
Screen.register(Screen.Type.PRIVACY_POLICY, PrivacyPolicyScreen.View, PrivacyPolicyScreen.lifecycle);
Screen.register(Screen.Type.RECORDING_MANAGEMENT, RecordingManagement, recordingManagementLifycycle);
Screen.register(Screen.Type.STARTUP_BEHAVIOR, StartupBehaviorScreen.View, StartupBehaviorScreen.lifecycle);
Screen.register(Screen.Type.TV_GUIDE, TVGuide);
Screen.register(Screen.Type.WELCOME, WelcomeScreen.View, WelcomeScreen.lifecycle);
Screen.register(Screen.Type.AGE_RATING_SELECTION, AgeRatingSelectionScreen.View, AgeRatingSelectionScreen.lifecycle);
Screen.register(Screen.Type.MASTER_PIN_CODE, MasterPinCodeScreen.View, MasterPinCodeScreen.lifecycle);
Screen.register(Screen.Type.PIN_CODE, PinCodeScreen.View, PinCodeScreen.lifecycle);
Screen.register(Screen.Type.REPLAY_SELECTION, ReplaySelectionScreen.View, ReplaySelectionScreen.lifecycle);
Screen.register(Screen.Type.LANGUAGE_PREFERENCES, LanguagePreferencesScreen.View, LanguagePreferencesScreen.lifecycle);
Screen.register(Screen.Type.COLLECTION, CollectionScreen.View, CollectionScreen.lifecycle);
Screen.register(Screen.Type.PARENTAL_CONTROL, ParentalControlScreen.View, ParentalControlScreen.lifecycle);
Screen.register(Screen.Type.VIEW_CONTROL, ViewControlScreen.View, ViewControlScreen.lifecycle);
Screen.register(Screen.Type.CHANNEL_LISTS, ChannelListsScreen.View, ChannelListsScreen.lifecycle);
Screen.register(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreate.View, ChannelListsScreenCreate.lifecycle);
Screen.register(Screen.Type.CHANNEL_LISTS_MANAGE, ChannelListsScreenManageChannels.View, ChannelListsScreenManageChannels.lifecycle);
Screen.register(Screen.Type.SETTINGS, SettingsScreen.View, SettingsScreen.lifecycle);
Screen.register(Screen.Type.VIEW_CONTROL, ViewControlScreen.View, ViewControlScreen.lifecycle);
Screen.register(Screen.Type.MyLibrary, MyLibrary.View, MyLibrary.lifecycle);
Screen.register(Screen.Type.Search, Search.View, Search.lifecycle);
Screen.register(Screen.Type.VodFolders, VodFolders.View, VodFolders.lifecycle);

// @note Playground
Screen.register(Screen.Type.PLAYGROUND, MyLibrary.View);

Bookmarks.subscriber(store, () => Events.triggerEvents(CONTINUE_WATCHING_CHANGED));

export const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    useInitialize();
    useReminderScheduling();
    useRecordingScheduling();
    useStoreMessageTriggerTable();
    const isMqttEnabled = useMqtt();
    usePooling(() => dispatch(messageLoad()), Time.HOUR_MS * (isMqttEnabled ? 4 : 2));
    usePooling(() => dispatch(reminderLoad()), Time.HOUR_MS * (isMqttEnabled ? 4 : 2));

    Community.useOnChange(() => dispatch(reloadChannelLists()));
    useZappwareCallbacks();
    useDisableFocus();

    return (
        <>
            <Alert.View />
            <Notification.Orchestrator />
            <Prompt.View />
            <Overlay.View />
            <Screen.View />
            <Keyboard.View />
        </>
    );
};
