import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodAssetEntitlementFragmentDoc } from '../fragments/vodAssetEntitlement.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StartVodTransactionMutationVariables = Types.Exact<{
  input: Types.StartVodTransactionInput;
}>;


export type StartVodTransactionMutation = { __typename?: 'Nexx4Mutations', startVODTransaction: { __typename?: 'StartVODTransactionPayload', entitlement: { __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', id: string } | null } } };

export type StartVodTransactionFragment = { __typename?: 'Nexx4Mutations', startVODTransaction: { __typename?: 'StartVODTransactionPayload', entitlement: { __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', id: string } | null } } };

export const StartVodTransactionFragmentDoc = gql`
    fragment startVODTransaction on Nexx4Mutations {
  startVODTransaction(input: $input) {
    entitlement {
      ...vodAssetEntitlement
    }
  }
}
    ${VodAssetEntitlementFragmentDoc}`;
export const StartVodTransactionDocument = gql`
    mutation startVODTransaction($input: StartVODTransactionInput!) {
  ...startVODTransaction
}
    ${StartVodTransactionFragmentDoc}`;
export type StartVodTransactionMutationFn = Apollo.MutationFunction<StartVodTransactionMutation, StartVodTransactionMutationVariables>;

/**
 * __useStartVodTransactionMutation__
 *
 * To run a mutation, you first call `useStartVodTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVodTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVodTransactionMutation, { data, loading, error }] = useStartVodTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartVodTransactionMutation(baseOptions?: Apollo.MutationHookOptions<StartVodTransactionMutation, StartVodTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartVodTransactionMutation, StartVodTransactionMutationVariables>(StartVodTransactionDocument, options);
      }
export type StartVodTransactionMutationHookResult = ReturnType<typeof useStartVodTransactionMutation>;
export type StartVodTransactionMutationResult = Apollo.MutationResult<StartVodTransactionMutation>;
export type StartVodTransactionMutationOptions = Apollo.BaseMutationOptions<StartVodTransactionMutation, StartVodTransactionMutationVariables>;