import { UINotificationEntity } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Alert } from 'App/Modules/Alert';
import { Keyboard } from 'App/Modules/Keyboard';

export const selectNotification =
    (notificationId: string) =>
    (state: State): UINotificationEntity | null =>
        state.notification.entities[notificationId] ?? null;

export const selectNotificationStack = (state: State): string[] => state.notification.stack;

export const selectActiveNotificationId = (state: State): string | null =>
    state.notification.stack[state.notification.stack.length - 1] ?? null;

export const selectIsNotificationLayerActive = (state: State): boolean =>
    !Keyboard.selectors.selectIsActive(state) && !Alert.selectors.selectIsActive(state);

export const selectIsNotificationActive =
    (notificationId: string) =>
    (state: State): boolean =>
        selectIsNotificationLayerActive(state) && selectActiveNotificationId(state) === notificationId;

export const selectIsNotificationFocusActive =
    (notificationId: string) =>
    (state: State): boolean =>
        selectIsNotificationActive(notificationId)(state) && !!selectNotification(notificationId)(state)?.actions?.length;

export const selectIsNotificationsActive = (state: State) =>
    selectIsNotificationFocusActive(selectActiveNotificationId(state) ?? '')(state);

export const selectNotificationActiveStatus = (state: State): boolean => state.notification.isActive;
