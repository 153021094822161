import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelDefaultNumber, selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectSelectedChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Elements } from '__SMART_APP_OLD__/components/EPG/types';

interface ChannelCardBodyProps {
    channelId: GQL.ChannelID;
    showTitle?: boolean;
    defaultNumber?: boolean;
}

const ChannelCardBody: React.FunctionComponent<ChannelCardBodyProps> = (props) => {
    const name = useSelector(selectChannelName(props.channelId));
    const defaultNumber = useSelector(selectChannelDefaultNumber(props.channelId));
    const number = useSelector(selectSelectedChannelListChannelNumber(props.channelId));

    return (
        <div className="channel-card-container">
            <div className="channel-number-container">
                <Text
                    className="channel-number"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.BOLD}
                    color={props.showTitle ? Color.SECONDARY : Color.PRIMARY}
                >
                    {props.defaultNumber ? defaultNumber : number}
                </Text>
            </div>
            <div className="checked-card" />
            <div className="container">
                <ChannelLogo className="channel-logo" id={props.channelId} />
            </div>
            {props.showTitle && (
                <Text
                    className="channel-title"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.BOLD}
                    color={Color.SECONDARY}
                >
                    {name}
                </Text>
            )}
        </div>
    );
};

interface NotFocusableChannelCardProps {
    className?: string;
    channelId: string;
}

export const NotFocusableChannelCard: React.FunctionComponent<NotFocusableChannelCardProps> = React.memo((props) => (
    <div className={`channel-card ${props.className || ''}`} data-element={Elements.CHANNEL} data-channel-id={props.channelId}>
        <ChannelCardBody channelId={props.channelId} />
    </div>
));
