import React, { useState } from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    adjacentIndexInterceptor,
    swappableItemsInterceptor,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/navigationInterceptors';
import { ChannelListsScreenManageChannelsCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsCtx';
import { ChannelListsScreenManageChannelsViewAddedSection } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsViewAddedSection';
import { ChannelListsScreenManageChannelsViewAllSection } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsViewAllSection';
import { CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/constants';
import { channelListsScreenManageActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/actions';
import { selectAddedChannelIds } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/selectors';
import { ChannelListsScreenManageMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const ChannelListsScreenManageChannelsView: React.FunctionComponent<Screen.Props<Screen.Type.CHANNEL_LISTS_MANAGE>> = (props) => {
    const { mode, channelListId, channelListName } = props;

    const [isArranging, setIsArranging] = useState(false);

    const dispatch = useDispatch();
    const ctx = Focus.useCtx();

    const allChannels = useSelector(selectChannelIds);
    const initialAddedItems = useSelector(selectChannelListChannelIds(channelListId ?? ''));
    const addedChannelIds = useSelector(selectAddedChannelIds);

    const updateChannelIds = (channelIds: string[]) => {
        dispatch(channelListsScreenManageActions.private.updateChannelIds(channelIds));
    };

    const [addedChannelsFirstVisibleIndex, setAddedChannelsFirstVisibleIndex] = useState(0);
    const addedChannelsNavigation = Focus.useList({
        ctx: ChannelListsScreenManageChannelsCtx.Added,
        axis: Axis.Y,
        items: addedChannelIds,
        disableOutOfBounds: isArranging,
        guard: (event) => {
            if (isArranging) return event.x === 0;
            return true;
        },
        interceptor: (event, focused) => {
            if (event.x === -1) {
                const focusedIndex = addedChannelIds.indexOf(focused);
                adjacentIndexInterceptor(
                    addedChannelsFirstVisibleIndex,
                    allChannels.length,
                    allChannelsFirstVisibleIndex,
                    focusedIndex,
                    allChannelsNavigation
                )(event, focused);
            }

            if (!isArranging) return;

            swappableItemsInterceptor(addedChannelIds, updateChannelIds)(event, focused);
        },
    });

    const [allChannelsFirstVisibleIndex, setAllChannelsFirstVisibleIndex] = useState(0);
    const allChannelsNavigation = Focus.useList({
        ctx: ChannelListsScreenManageChannelsCtx.All,
        axis: Axis.Y,
        items: allChannels,
        interceptor: (event, focused) => {
            if (event.x === -1) return;

            const focusedIndex = allChannels.indexOf(focused);

            adjacentIndexInterceptor(
                allChannelsFirstVisibleIndex,
                addedChannelIds.length,
                addedChannelsFirstVisibleIndex,
                focusedIndex,
                addedChannelsNavigation
            )(event, focused);
        },
    });

    const updateChannel = async (channelId: string) => {
        const isAdding = !addedChannelIds.includes(channelId);
        const newAddedChannelIds = isAdding ? [...addedChannelIds, channelId] : addedChannelIds.filter((id) => id !== channelId);

        setIsArranging(false);
        updateChannelIds(newAddedChannelIds);

        if (isAdding) {
            setAddedChannelsFirstVisibleIndex(Math.max(0, newAddedChannelIds.length - CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS));
        }
    };

    const onAddedSectionEnter = () => {
        if (isArranging) {
            updateChannelIds(addedChannelIds);
        }

        setIsArranging(!isArranging);
    };

    const onBack = () => {
        if (ctx === ChannelListsScreenManageChannelsCtx.Added && isArranging) {
            onAddedSectionEnter();
            return;
        }

        if (mode === ChannelListsScreenManageMode.CREATE) {
            dispatch(channelListsScreenManageActions.private.createModeBack(addedChannelIds, channelListName));
            return;
        }

        if (channelListId) {
            dispatch(channelListsScreenManageActions.private.updateModeBack(channelListId, addedChannelIds, initialAddedItems));
        }
    };

    Focus.useBack(onBack);

    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action onEnter={onBack} />
                <Settings.Header.Title>{channelListName}</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <ChannelListsScreenManageChannelsViewAllSection
                    allItems={allChannels}
                    firstVisibleIndex={allChannelsFirstVisibleIndex}
                    setFirstVisibleIndex={setAllChannelsFirstVisibleIndex}
                    addedChannelIds={addedChannelIds}
                    updateChannel={updateChannel}
                    navigation={allChannelsNavigation}
                />
            </Settings.Section>
            <Settings.Section>
                <ChannelListsScreenManageChannelsViewAddedSection
                    channelListName={channelListName}
                    navigation={addedChannelsNavigation}
                    isArranging={isArranging}
                    addedChannelIds={addedChannelIds}
                    firstVisibleIndex={addedChannelsFirstVisibleIndex}
                    setFirstVisibleIndex={setAddedChannelsFirstVisibleIndex}
                    onEnter={onAddedSectionEnter}
                />
            </Settings.Section>
        </Settings>
    );
};
