import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    className?: string;
    isFocused?: boolean;
    isDisabled?: boolean;
    isToggled?: boolean;
    disableBackgroundChange?: boolean;
    disableRing?: boolean;
}

const classNameForThemeType: Record<Theme.Type, string> = {
    [Theme.Type.Dark]: 'switch--dark-theme',
    [Theme.Type.Light]: 'switch--light-theme',
};

const getClassName = (props: Props, theme: Theme.Type): string => {
    const classList = ['switch'];
    if (props.isToggled) classList.push('switch--toggled');
    if (props.isFocused) classList.push('switch--focused');
    if (props.isDisabled) classList.push('switch--disabled');
    if (!props.disableBackgroundChange) classList.push('switch--background-change');
    if (!props.disableRing) classList.push('switch--ring');
    classList.push(classNameForThemeType[theme]);
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const Switch: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const className = getClassName(props, theme);
    return <div className={className} />;
};
