import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { selectIdpProviderActive } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { loginLegacyAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const mount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(Overlay.actions.unmount());
    if (selectIdpProviderActive(getState())) return;
    dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}));
};

const loginAfterDeviceDeletion =
    (username: string, password: string): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
        dispatch(loginLegacyAction(username, password));
        dispatch(Overlay.actions.unmount());
    };

export const actions = {
    public: {},
    private: {
        loginAfterDeviceDeletion,
    },
    lifecycle: {
        mount,
    },
};
