import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { favouritesSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import ContentMarkers from '__SMART_APP_OLD__/components/common/ContentMarkers';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';

/**
 *
 * @param {PlayerBannerProps} props
 *
 * @returns {JSX.Element}
 */
function PlayerBanner(props) {
    const [videoQuality, setVideoQuality] = useState(Player.getCurrentVideoQuality());
    const { data } = props;
    const {
        titlePlayer,
        metaInfoPlayer,
        catchupTV,
        isRecording,
        streamInfo,
        assetType,
        status,
        videoQuality: vodEntitlementVideoQuality,
        isTrailer,
        parentalRating,
    } = data;

    useEffect(() => {
        Player.addEventListener(VIDEO_EVENTS.VIDEO_TRACK_CHANGED, () => {
            setVideoQuality(Player.getCurrentVideoQuality());
        });

        return () => {
            Player.removeEventListener(VIDEO_EVENTS.VIDEO_TRACK_CHANGED);
        };
    }, []);

    const currentVideoQuality = vodEntitlementVideoQuality || videoQuality;

    return (
        <div className="player-banner dark-theme">
            <div className="channel-wrapper">
                <ChannelLogo className="channel-logo" id={props.data.channelId} theme={Theme.Type.Dark} />
            </div>
            <Text
                className="title"
                typeface={Typeface.SERIF}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
                theme={Theme.Type.Dark}
            >
                {titlePlayer}
            </Text>
            <div className="details">
                <ContentMarkers asset={data} hideNowPlayingMarker />
                <Icons
                    className="banner-icons-container"
                    isDolby={streamInfo?.dolby}
                    parentalControl={parentalRating?.id}
                    isVisuallyImpaired={streamInfo?.visuallyImpaired}
                    isFavourited={props.isFavourited}
                    isCatchup={catchupTV}
                    isRecording={isRecording}
                    assetType={assetType}
                    videoQuality={!isTrailer ? currentVideoQuality : null}
                    isFocused
                    status={status}
                />
                <Text
                    className="metadata"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    <InnerHTML html={metaInfoPlayer} />
                </Text>
            </div>
        </div>
    );
}

export default connect((state, ownProps) => ({
    isFavourited: favouritesSelectors.public.selectIsFavourited(ownProps.data.id)(state),
}))(PlayerBanner);
