import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import translate from 'language/translate';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Hidable } from 'App/Packages/UI/Hidable';
import { actions } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Actions';
import { useElement, useFocusable } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Div } from 'App/Packages/UI/Div';
import { Overlay } from 'App/Modules/Overlay';

type Props = {
    channelId: string;
    fontSize: string;
    playerOptions: any;
    currentSubtitle: any;
};

type SubtitlesComponent = React.FunctionComponent<Props>;

export const Subtitles: SubtitlesComponent = (props) => {
    const { playerOptions, currentSubtitle, fontSize } = props;
    const dispatch = useDispatch();

    const { isFocused, onHover: onElementHover } = useElement({ ctx: Constants.Ctx.subtitles });

    const updatePreviewTextOnFocus = () => {
        dispatch(
            Overlay.actions.update(Overlay.Type.SubtitleMenu, {
                previewTextFontSize: fontSize,
            })
        );
        return onElementHover;
    };

    const { onClick, onHover } = useFocusable({
        id: Constants.Ctx.subtitles,
        ctx: Constants.Ctx.subtitles,
        isFocused,
        onClick: () => dispatch(actions.private.subtitlesOverlaySelected()),
        onEnter: () => dispatch(actions.private.subtitlesOverlaySelected()),
        onHover: updatePreviewTextOnFocus,
        onFocus: updatePreviewTextOnFocus,
    });

    const { subtitles } = playerOptions;
    const currentSubName = subtitles.find((sub: any) => sub.id === (currentSubtitle?.id ?? -1));

    return (
        <Hidable hidden={!subtitles.length}>
            <Div onClick={onClick} onHover={onHover} className="subs-container">
                <Text
                    className="audio-subtitle-title"
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_2}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {`${translate('SCREEN_AUDIO_SUBTITLES_SUBTITLES')} (${subtitles.length})`}
                </Text>
                <div className={`audio-subtitle-item ${isFocused ? 'focused' : ''}`} key={subtitles.length}>
                    <div className="subs-item-title">{translate(currentSubName ? currentSubName.name : '')}</div>
                </div>
            </Div>
        </Hidable>
    );
};
