import React, { useCallback, useMemo } from 'react';

import { Function } from 'App/Packages/Function';
import { DurationOptions } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { Folder } from 'App/Packages/UI/Folder/Modules/Folder';
import { FolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems';

const getItem = (target: HTMLDivElement, container: HTMLDivElement, id: string, ids: string[], offset: number): HTMLDivElement | null => {
    if (offset === 0) return target;
    const index = ids.indexOf(id);
    const item = container.children.item(index - offset);
    if (!item || !(item instanceof HTMLDivElement)) return null;
    return item;
};

export type UseFolderItemScroll = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    id: string,
    focused: Subscribable.Value<boolean>,
    offset?: number
) => (() => Promise<void>) & { instant: Subscribable.Value<boolean> };

export const useFolderItemScroll: UseFolderItemScroll = (ref, id, focused, offset = Constants.Items.Scroll.Offset) => {
    const items = FolderItems.use();
    const folder = Folder.use();
    const instant = Subscribable.useValue(false);

    const scrollIntoView = useCallback(async () => {
        const isInstant = instant.get();
        instant.set(false);

        const target = ref.current;
        const container = items.ref.current;
        if (!target || !container) return;
        if (!isInstant) await Function.sleep(Constants.Item.Scroll.Delay);
        if (!focused.get()) return;
        const item = getItem(target, container, id, items.ids.get(), offset);
        const duration: DurationOptions | undefined = isInstant ? { max: 0, min: 0, ratio: 0 } : undefined;
        if (!item || !(item instanceof HTMLElement)) items.scroll.to({ left: 0, duration });
        else items.scroll.to({ left: Constants.Items.Scroll.calc(item.offsetLeft - container.offsetLeft), duration });
        folder.scrollIntoView.instant.set(isInstant);
        folder.scrollIntoView();
    }, [ref, id, focused, offset, instant, items, folder]);

    return useMemo(() => Object.assign(scrollIntoView, { instant }), [scrollIntoView, instant]);
};
