import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListCreateMutationVariables = Types.Exact<{
  channelListCreateInput: Types.CreatePersonalChannelListInput;
}>;


export type ChannelListCreateMutation = { __typename?: 'Nexx4Mutations', createPersonalChannelList: { __typename?: 'CreatePersonalChannelListPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } } };

export type ChannelListCreateFragment = { __typename?: 'Nexx4Mutations', createPersonalChannelList: { __typename?: 'CreatePersonalChannelListPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind, channels: { __typename?: 'ChannelListChannelsConnection', totalCount?: number | null } } } };

export const ChannelListCreateFragmentDoc = gql`
    fragment channelListCreate on Nexx4Mutations {
  createPersonalChannelList(input: $channelListCreateInput) {
    channelList {
      ...channelList
      channels {
        totalCount
      }
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListCreateDocument = gql`
    mutation channelListCreate($channelListCreateInput: CreatePersonalChannelListInput!) {
  ...channelListCreate
}
    ${ChannelListCreateFragmentDoc}`;
export type ChannelListCreateMutationFn = Apollo.MutationFunction<ChannelListCreateMutation, ChannelListCreateMutationVariables>;

/**
 * __useChannelListCreateMutation__
 *
 * To run a mutation, you first call `useChannelListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListCreateMutation, { data, loading, error }] = useChannelListCreateMutation({
 *   variables: {
 *      channelListCreateInput: // value for 'channelListCreateInput'
 *   },
 * });
 */
export function useChannelListCreateMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListCreateMutation, ChannelListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListCreateMutation, ChannelListCreateMutationVariables>(ChannelListCreateDocument, options);
      }
export type ChannelListCreateMutationHookResult = ReturnType<typeof useChannelListCreateMutation>;
export type ChannelListCreateMutationResult = Apollo.MutationResult<ChannelListCreateMutation>;
export type ChannelListCreateMutationOptions = Apollo.BaseMutationOptions<ChannelListCreateMutation, ChannelListCreateMutationVariables>;