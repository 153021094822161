import React, { useMemo, useRef, useState } from 'react';

import { CollectionV2 } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelsReplayPermission,
    selectReplayEnabledChannelsOrderedByDefaultNumber,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { viewControlActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/actions';
import { getSelectedChannelsLength } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/utils';
import { ViewControlCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/ViewControlCtx';
import { ViewControlScreenViewGridItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/ViewControlScreen/ViewControlScreenViewGridItem';
import { ftiTranslateElements } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const COLUMNS = 6;
const MAX_ROWS = 7;

export const ViewControlScreenView: React.FunctionComponent<Screen.Props<Screen.Type.VIEW_CONTROL>> = () => {
    const dispatch = useDispatch();

    const [channels, setChannels] = useState(useSelector(selectChannelsReplayPermission));
    const initialChannelsRef = useRef(channels);

    const channelIds = useSelector(selectReplayEnabledChannelsOrderedByDefaultNumber);
    const maxChannelsToSelect = useSelector(Household.selectors.selectMaxNumberOfReplayChannels);

    const selectedChannelCount = useMemo(() => getSelectedChannelsLength(channels), [channels]);

    const onEnterItem = (channelId: string) => {
        setChannels((prevSelectedChannels) => {
            const isChannelSelected = channels[channelId];

            if (getSelectedChannelsLength(channels) >= maxChannelsToSelect && !isChannelSelected) {
                dispatch(textNotificationShow(ftiTranslateElements.viewControl.notifications.limitReached));
                return prevSelectedChannels;
            }

            return { ...prevSelectedChannels, [channelId]: !isChannelSelected };
        });
    };

    Focus.useBack(() => dispatch(viewControlActions.private.back(initialChannelsRef.current, channels)));

    return (
        <CollectionV2>
            <CollectionV2.Header>
                <CollectionV2.Header.Title>{ftiTranslateElements.viewControl.title}</CollectionV2.Header.Title>
                <CollectionV2.Header.Actions ctx={ViewControlCtx.Confirm} buttonIds={['next-button-id']}>
                    <CollectionV2.Header.Button id="next-button-id" onEnter={() => dispatch(viewControlActions.private.accept(channels))}>
                        {`${translate('FTI_VIEW_CONTROL_CONFIRM_SELECTION_BUTTON')} ${selectedChannelCount} / ${maxChannelsToSelect}`}
                    </CollectionV2.Header.Button>
                </CollectionV2.Header.Actions>
            </CollectionV2.Header>
            <CollectionV2.Grid
                items={channelIds}
                columns={COLUMNS}
                maxRows={MAX_ROWS}
                ctx={ViewControlCtx.Grid}
                onEnterItem={onEnterItem}
                className="view-control-grid"
            >
                {(item, _, isFocused) => <ViewControlScreenViewGridItem id={item} isFocused={isFocused} isSelected={!!channels[item]} />}
            </CollectionV2.Grid>
        </CollectionV2>
    );
};
