import { useEffect, useRef, useState } from 'react';

import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import Events, { GALLERY_PLAYER_HIDE, GALLERY_PLAYER_SHOW, HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import { VisibilityEvent } from '__SMART_APP_OLD__/types/events';

import { Env } from 'App/Env';

/**
 *
 * @param callback
 * @param delay
 */
export function useInterval(callback: () => void, delay?: number) {
    const savedCallback: any = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        /**
         *
         */
        function tick() {
            savedCallback.current();
        }

        if (delay) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return undefined;
    }, [delay]);
}

/**
 *
 * @param mountCallback
 * @param unmountCallback
 */
export function useComponentWillMount(mountCallback: () => void, unmountCallback?: any) {
    const willMount = useRef(true);
    if (willMount.current) {
        mountCallback();
    }

    useEffect(() => {
        willMount.current = false;
        if (unmountCallback) {
            return unmountCallback;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

interface IState {
    keyPressTimerId: ReturnType<typeof setTimeout> | null;
    playerHiddenOnBlur: boolean;
    keyJustPressed: boolean;
    cursorVisible: boolean;
}

/**
 * Manages screensaver logic based on user interactions and system events.
 *
 * @param {string} liveChannel - The current live channel (if available).
 * @param {boolean} isPlayerAvailable - Indicates whether the video player is available.
 * @param {boolean} isPlaying - Indicates whether the video is currently playing.
 *
 * @returns {{ isScreensaverActive: boolean }}
 */
export function useScreensaverLogic(liveChannel: string, isPlayerAvailable: boolean, isPlaying: boolean): { isScreensaverActive: boolean } {
    const [isScreensaverActive, setIsScreensaverActive] = useState(false);

    useEffect(() => {
        if (!Env.IsWebOS) return undefined;

        const state: IState = { keyPressTimerId: null, playerHiddenOnBlur: false, keyJustPressed: false, cursorVisible: false };

        const keydownHandler = () => {
            if (state.keyPressTimerId) {
                clearTimeout(state.keyPressTimerId);
            }
            state.keyJustPressed = true;
            state.keyPressTimerId = setTimeout(() => {
                state.keyJustPressed = false;
            }, 1000);
        };

        const cursorVisibilityChangeHandler = (event: VisibilityEvent) => {
            state.cursorVisible = event.detail.visibility;
        };

        const hidePlayerAfterTimeout = () => {
            if (!state.keyJustPressed && !state.cursorVisible) {
                if (isPlayerAvailable && isPlaying) {
                    Events.triggerEvents(GALLERY_PLAYER_HIDE);
                    Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
                    PlayerAPI.stopPlayback();
                    state.playerHiddenOnBlur = true;
                    setIsScreensaverActive(true);
                }
            }
        };

        const showHiddenPlayer = () => {
            if (state.playerHiddenOnBlur) {
                if (isPlayerAvailable && liveChannel) {
                    PlayerAPI.setChannelStream(liveChannel);
                    Events.triggerEvents(GALLERY_PLAYER_SHOW);
                    state.playerHiddenOnBlur = false;
                    setIsScreensaverActive(false);
                }
            }
        };

        window.addEventListener('blur', hidePlayerAfterTimeout);
        window.addEventListener('focus', showHiddenPlayer);
        document.addEventListener('keydown', keydownHandler);
        document.addEventListener('cursorStateChange', cursorVisibilityChangeHandler);

        return () => {
            window.removeEventListener('blur', hidePlayerAfterTimeout);
            window.removeEventListener('focus', showHiddenPlayer);
            document.removeEventListener('keydown', keydownHandler);
            document.removeEventListener('cursorStateChange', cursorVisibilityChangeHandler);
            if (state.keyPressTimerId) {
                clearTimeout(state.keyPressTimerId);
            }
        };
    }, [isPlayerAvailable, isPlaying, liveChannel]);

    return { isScreensaverActive };
}
