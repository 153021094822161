import { ConfigServiceConfig } from '__SMART_APP_OLD__/app/modules/ConfigService/ConfigServiceConfig';
import { ConfigServiceResponse, Response } from '__SMART_APP_OLD__/app/modules/ConfigService/Response';
import { TranslationsData } from '__SMART_APP_OLD__/app/modules/ConfigService/TranslationsData';
import { Platform } from '__SMART_APP_OLD__/app/platform';

import { Env } from 'App/Env';

export type { ConfigServiceConfig as Config, Response, TranslationsData };

/**
 * @namespace API.Config
 */

/**
 * @memberof API.Config
 * @description Helper functions that applies the neccessary query params
 * so the request can be successful
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @param url url of the config service for the requested resource
 * @returns configuration url with query params added if needed
 */
const applyQueryParams = (url: string): string => {
    if (Env.IsProduction || Env.isBusiness ) return url;
    if (!url.includes('v2') || url.includes('customFilters')) return url;
    return `${url}?customFilters=environment[${Env.Environment}]&customFilters=opco[${Env.Opco}]`;
};

/**
 * @memberof API.Config
 * @description Helper function that send get request to config service and
 * makes error handling a litte bit easier
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @template T The success response type can be either Config response or Translations response
 * @param url url of the config service for the requested resource
 * @returns  the raw app config data if successful request to control center
 */
export const get = async <T extends ConfigServiceConfig | TranslationsData>(url: string): Promise<Response<T>> => {
    const response = await fetch(applyQueryParams(url), { method: 'GET', headers: Platform.CONFIG_SERVICE_HEADERS });
    const data: ConfigServiceResponse<T> = await response.json();
    if (!data.success) throw data;
    return data;
};
