import { useEffect, useRef } from 'react';

import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

/**
 * This hook extends store.subscribe, so that we can receive the previous and the next value of
 * the state and gives us the ability to localize our state with selector
 *
 * @param selector
 * @param callback
 */
export const useSubscribe = <T>(selector: Selector<T>, callback: (prev: T, next: T) => void) => {
    const store = useStore();

    const callbackRef = useRef(callback);

    callbackRef.current = callback;

    useEffect(() => {
        let state = store.getState();

        return store.subscribe(() => {
            const prev = selector(state);
            const next = selector(store.getState());

            if (prev !== next) {
                callbackRef.current(prev, next);
            }

            state = store.getState();
        });
    }, [selector, store]); // selector and store never change!
};
