import { List, ListItemType, ListRenderer } from '__SMART_APP_OLD__/app/components/List';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';

import { Focus } from 'App/Packages/Focus';
import React, { useMemo } from 'react';

type LandscapeCardGridRowProps = {
    ctx: string;
    focused: string;
    items: string[];
    isDisabled: boolean;
    Card: React.FunctionComponent<CollectionItemProps>;
    onHover: (item: string) => void;
};

export type LandscapeCardGridProps = {
    ctx: string;
    items: string[];
    Card: React.FunctionComponent<CollectionItemProps>;
    className?: string;
    initialFocusedIndex?: number | (() => number);
};

const COLUMNS = 4;

const getRows = (rows: GQL.RecordingID[][]): (GQL.RecordingID[] | null)[] => [...rows, null];

const LandscapeCardGridRowComponent: React.FunctionComponent<LandscapeCardGridRowProps> = (props) => {
    const Component = props.Card;
    return (
        <div className="landscape-card-grid__row">
            {props.items.map((id) => (
                <Component
                    key={id}
                    id={id}
                    ctx={props.ctx}
                    className="landscape-card-grid__item"
                    isFocused={id === props.focused && !props.isDisabled}
                    onHover={props.onHover}
                />
            ))}
        </div>
    );
};

const LandscapeCardGridRow = React.memo(LandscapeCardGridRowComponent) as typeof LandscapeCardGridRowComponent;

const LandscapeCardGridComponent: React.FunctionComponent<LandscapeCardGridProps> = (props) => {
    const { focusedRowIndex, rows, focused, isDisabled, focus } = Focus.useGrid({
        ctx: props.ctx,
        columns: COLUMNS,
        items: props.items,
        initialFocusedIndex: props.initialFocusedIndex,
    });

    const rowsToRender = useMemo(() => getRows(rows), [rows]);

    const renderRow: ListRenderer<string[] | null> = (items) => {
        if (!items) return null;
        return (
            <LandscapeCardGridRow
                key={items.join()}
                ctx={props.ctx}
                Card={props.Card}
                items={items}
                focused={focused}
                isDisabled={isDisabled}
                onHover={focus}
            />
        );
    };

    return (
        <List
            className={`landscape-card-grid${props.className ? ` ${props.className}` : ''}`}
            firstVisibleIndex={focusedRowIndex - 1}
            itemType={ListItemType.LANDSCAPE_CARD_GRID}
            items={rowsToRender}
            renderer={renderRow}
        />
    );
};

export const LandscapeCardGrid = React.memo(LandscapeCardGridComponent) as typeof LandscapeCardGridComponent;
