import React, { useCallback } from 'react';

import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemScrollIntoView = (ref: React.MutableRefObject<HTMLDivElement | null>) => () => void;

export const useHeaderItemScrollIntoView: UseHeaderItemScrollIntoView = (ref) => {
    const items = HeaderItems.use();

    return useCallback(() => {
        const container = items.ref.current;
        const target = ref.current;
        if (!container || !target) return;
        const left = target.offsetLeft - container.offsetLeft + (target.offsetWidth - container.offsetWidth) / 2;
        items.scroll.to({ left });
    }, [ref, items]);
};
