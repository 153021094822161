import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { Folder } from 'App/Modules/Folder';
import { UI } from 'App/Packages/UI';
import React from 'react';

export type BannerCardProps = {
    selector: Selector<Folder.Item | null>;
    onFocus?: (id: string) => void;
};
export const BannerCard: React.FunctionComponent<BannerCardProps> = (props) => {
    const item = useSelector(props.selector);

    if (!item) return null;

    return (
        <UI.Folder.Card.Banner
            id={item.id}
            parentalRating={item.metadata.parentalRatingId}
            sessionType={item.metadata.sessionType}
            onFocus={props.onFocus}
        >
            <UI.Card.Banner.Thumbnail src={item.content.thumbnail} />
        </UI.Folder.Card.Banner>
    );
};
