import React, { useMemo } from 'react';

export const enum Status {
    Initial = 'Initial',
    Loading = 'Loading',
    Success = 'Success',
    Error = 'Error',
}

export type ImageContextState = {
    status: Status;
    setStatus: React.Dispatch<React.SetStateAction<Status>>;
};

export const ImageContext = React.createContext<ImageContextState>({
    status: Status.Initial,
    setStatus: () => {},
});

export type ImageProviderProps = React.PropsWithChildren<{}>;

export type ImageProviderModule = React.FunctionComponent<ImageProviderProps>;

export const ImageProvider: ImageProviderModule = (props) => {
    const [status, setStatus] = React.useState<Status>(Status.Initial);
    const value = useMemo(() => ({ status, setStatus }), [status, setStatus]);
    return <ImageContext.Provider value={value}>{props.children}</ImageContext.Provider>;
};
