import { ContentFolderKind, ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum FolderActionType {
    Loaded = '@@Folder/Loaded',
    Reset = '@@Folder/Reset',
}

export enum FolderListActionType {
    Loaded = '@@FolderList/Loaded',
    Reset = '@@FolderList/Reset',
}

export type FolderLoadedActionPayload = { data: Folder; hash: string };
export type FolderLoadedAction = PayloadedAction<FolderActionType.Loaded, FolderLoadedActionPayload>;
export type FolderResetActionPayload = { hash: string };
export type FolderResetAction = PayloadedAction<FolderActionType.Reset, FolderResetActionPayload>;
export type FolderAction = FolderLoadedAction | FolderResetAction;

export type FolderListLoadedActionPayload = { data: FolderList; hash: string };
export type FolderListLoadedAction = PayloadedAction<FolderListActionType.Loaded, FolderListLoadedActionPayload>;
export type FolderListResetActionPayload = { hash: string };
export type FolderListResetAction = PayloadedAction<FolderListActionType.Reset, FolderListResetActionPayload>;
export type FolderListAction = FolderListLoadedAction | FolderListResetAction;

export type FolderReduxAction = FolderAction | FolderListAction;

export type FolderList = {
    id: string;
    count: number;
    ids: string[];
    entities: Record<string, Folder>;
};

export type Folder = {
    id: string;
    title: string;
    type: ContentFolderKind;
    count: number;
    ids: string[];
    entities: Record<string, FolderItem>;
};

export type FolderItem = {
    id: string;
    type: ContentItemType;
    content: FolderItemContent;
    metadata: FolderItemMetadata;
    entitlements: FolderItemCardEntitlements;
    visualization: FolderItemCardVisualization;
};

export type FolderItemContent = {
    title: string;
    thumbnail: string;
    userInfo?: {
        id?: string;
        subscribed?: boolean;
    };
    info?: string;
    description?: string;
};

export type FolderItemMetadata = {
    providerId?: string;
    parentalRatingId: string;
    start?: number;
    end?: number;
    duration?: number;
    sessionType: PinSessionType;
};

export type FolderItemCardEntitlements = {
    status?: string;
    restartTV: boolean;
    pauseLiveTV: boolean;
    playback: boolean;
    playbackAvailableUntil: number;
    catchupTV: boolean;
    catchupTVAvailableUntil: number;
    networkRecording: boolean;
    networkRecordingPlannableUntil: number;
};

export type FolderItemCardVisualization = {
    isEligibleForPortraitCard: boolean;
    isEligibleForLandscapeCard: boolean;
    isEligibleForChannelCard: boolean;
    isEligibleForFolderCard: boolean;
    isEligibleForBannerCard: boolean;
};
