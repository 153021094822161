/* eslint-disable max-statements */
import { VodAssetEntitlement } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import translate from 'language/translate';

import { ContentMarkerType } from 'App/Types/ContentMarker';

import PortraitCard from './PortraitCard';
import { AssetType, DETAILS_SUBTITLES } from '../utils/Constants';
import { getTimeGap, secondsToTime } from '../utils/timeUtils';

export default class VODAsset extends PortraitCard {
    assetType: AssetType = AssetType.VOD_ASSET;

    _temporaryEntitlement: GQL.VODAssetEntitlement | null = null;

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);
        this.rawData = data;
    }

    get videoQuality() {
        return this.rawData?.entitlements?.items[0]?.product?.videoQuality;
    }

    get isPlaybackAvailable() {
        return (
            this.rawData?.entitlements?.items.some((entitlement: any) => entitlement.playback) ||
            this.rawData?.vodAssetEntitlements?.items.some((entitlement: any) => entitlement.playback)
        );
    }

    get detailPageMetaInfo() {
        const metaInfo = [];

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        if (this.duration) {
            const assetDuration = secondsToTime(this.duration);
            if (assetDuration?.length) {
                metaInfo.push(assetDuration);
            }
        }

        if (this.subtitleLanguages) {
            const firstSubtitleLanguage = Profile.selectors.selectFirstSubtitleLanguage(store.getState());
            const secondSubtitleLanguage = Profile.selectors.selectSecondSubtitleLanguage(store.getState());
            const subtitlesSet = new Set(); // to avoid duplicates, and to keep the order

            if (this.subtitleLanguages.includes(firstSubtitleLanguage)) {
                subtitlesSet.add(firstSubtitleLanguage as string);
            }
            if (this.subtitleLanguages.includes(secondSubtitleLanguage)) {
                subtitlesSet.add(secondSubtitleLanguage as string);
            }

            this.subtitleLanguages.forEach((lang: string) => {
                subtitlesSet.add(lang);
            });

            let subtitles = Array.from(subtitlesSet);

            if (subtitles.length > 3) {
                subtitles = subtitles.slice(0, 3);
            }

            const subtitlesString = `${translate(DETAILS_SUBTITLES)}: ${subtitles.join(', ').toUpperCase()}`;

            metaInfo.push(subtitlesString);
        }

        if (this.country) {
            metaInfo.push(this.country);
        }

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get temporaryEntitlement() {
        return this._temporaryEntitlement;
    }

    set temporaryEntitlement(value) {
        this._temporaryEntitlement = value;
    }

    get entitlements() {
        return this.rawData?.entitlements || this.rawData?.vodAssetEntitlements;
    }

    get isEntitled() {
        return !!this.entitlements?.itemCount;
    }

    get entitlementsKinds() {
        if (this?.rawData?.entitlements?.items[0]?.product) {
            return this.rawData?.entitlements?.items?.map((entitlement: { product: { kind: string } }) => entitlement?.product?.kind) || [];
        }

        return [];
    }

    get bookmark() {
        const bookmark = Bookmarks.selectors.selectEntity(this.id)(store.getState());
        if (bookmark && bookmark?.position >= this.duration) {
            return { ...bookmark, position: 0 };
        }
        return bookmark;
    }

    get rentKind() {
        return this.rawData?.entitlements?.items[0]?.product?.kind || [];
    }

    get rentLeft() {
        const rentExpiry = this.rawData?.entitlements?.items[0]?.playbackAvailableUntil ?? null;
        if (rentExpiry) return getTimeGap(rentExpiry);
        return '';
    }

    get availableContentMarkers(): string[] {
        const markers = [];
        if (this.rentLeft) {
            markers.push(ContentMarkerType.Rented);
        }

        return markers;
    }

    get vodAssetEntitlementId(): string {
        if (!this.isEntitled || !this.entitlements?.items?.length) return '';
        const entitlement = this.entitlements?.items?.find((e: VodAssetEntitlement) => e.playback);
        return entitlement.id;
    }

    get trailers() {
        return this.rawData?.trailers || {};
    }

    get hasTrailer() {
        return !!this.trailers.itemCount;
    }

    get trailerId() {
        return this.trailers.items?.[0]?.id;
    }

    get metaInfoPlayer() {
        const metaInfo = [];
        if (this.seasonAndEpisode || this.episodeTitle) {
            metaInfo.push(`${this.seasonAndEpisode} ${this.episodeTitle}`);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get parentalRating() {
        return this.rawData?.parentalRating?.title;
    }

    get audioLanguages() {
        return this.rawData?.metadata?.audioLanguages;
    }

    get subtitleLanguages() {
        return this.rawData?.metadata?.subtitleLanguages;
    }

    get metadata() {
        return this.rawData?.metadata;
    }

    get adult() {
        return this.rawData?.parentalRating?.adult;
    }
}
