import { TVGuideState } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';

export const initialTVGuideState: TVGuideState = {
    isLoading: false,
    focusedIndex: 0,
    time: -1,
    firstIndex: 0,
    start: -1,
};

export const TV_GUIDE_VW_PER_SECOND = 1504 / 19.2 / (2 * Time.HOUR_S);
export const TV_GUIDE_CELL_MARGIN_VW = 8 / 19.2;
export const TV_GUIDE_SHOW_ICONS_LIMIT = 10 * Time.MINUTE_S * TV_GUIDE_VW_PER_SECOND - TV_GUIDE_CELL_MARGIN_VW;
export const TV_GUIDE_SHOW_TITLE_LIMIT = 7 * Time.MINUTE_S * TV_GUIDE_VW_PER_SECOND - TV_GUIDE_CELL_MARGIN_VW;
export const TV_GUIDE_SHOW_ELIPSIS_LIMIT = 4 * Time.MINUTE_S * TV_GUIDE_VW_PER_SECOND - TV_GUIDE_CELL_MARGIN_VW;
