import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { Config } from '__SMART_APP_OLD__/app/modules/Config';
import { SERVICE_ENDPOINT } from '__SMART_APP_OLD__/app/modules/Config/Config';
import { ConfigChangeAction, ParsedConfigServiceConfig } from '__SMART_APP_OLD__/app/modules/Config/types';
import { ConfigService } from '__SMART_APP_OLD__/app/modules/ConfigService';
import DefaultConfig from '__SMART_APP_OLD__/app/modules/ConfigService/controlCenter.json';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Env } from 'App/Env';

const defaultConfigData = DefaultConfig.data as unknown as ConfigService.Config;

const genFallbackConfig = (config: ConfigService.Config): ConfigService.Config => ({
    ...config,
    api_config: { ...config?.api_config, config_id: `${config?.api_config?.config_id}_fallback` },
});

export const configChange = (config: ParsedConfigServiceConfig): ConfigChangeAction => ({
    type: ActionType.CONFIG_CHANGE,
    payload: { config },
});

export const loadConfig = (): Thunk<Promise<ConfigService.Config>> => async (dispatch) => {
    try {
        const response = await ConfigService.get<ConfigService.Config>(SERVICE_ENDPOINT[Env.Environment][Env.Opco]);
        const fallbackConfig = genFallbackConfig(response.data);
        Storage.set(Storage.Key.FALLBACK_CONFIG, fallbackConfig);
        dispatch(configChange(Config.parse(response.data)));
        return response.data;
    } catch (error) {
        const fallbackConfig = Storage.get<ConfigService.Config>(Storage.Key.FALLBACK_CONFIG) || defaultConfigData;
        dispatch(configChange(Config.parse(fallbackConfig)));
        return fallbackConfig;
    }
};
