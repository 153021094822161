import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';

export enum KeyboardActionType {
    Opened = '@keyboard/opened',
    Closed = '@keyboard/closed',
}

export type KeyboardReduxAction = KeyboardOpenedAction | KeyboardClosedAction;
export type KeyboardOpenedAction = BaseAction<KeyboardActionType.Opened>;
export type KeyboardClosedAction = BaseAction<KeyboardActionType.Closed>;

export type KeyboardState = {
    open: boolean;
};
