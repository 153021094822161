import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBingeWatchDataQueryVariables = Types.Exact<{
  currentItemId: Types.Scalars['ID']['input'];
  currentItemType: Types.ContentItemType;
}>;


export type GetBingeWatchDataQuery = { __typename?: 'Nexx4Queries', bingeWatchData?: { __typename?: 'BingeWatchData', id: string, seriesId: string, groupingInfos?: Array<{ __typename?: 'GroupingInfo', id: string, title: string, episodeCount: number } | null> | null, content: { __typename?: 'ContentFolder', items?: { __typename?: 'ContentFolderContentItemsConnection', edges: Array<{ __typename?: 'ContentFolderContentItemsEdge', id: string, node: { __typename?: 'Banner' } | { __typename?: 'Channel' } | { __typename?: 'ChannelProduct' } | { __typename?: 'Event' } | { __typename?: 'NetworkRecording' } | { __typename?: 'ProductBundle' } | { __typename?: 'Series' } | { __typename?: 'VODAsset', id: string, metadata: { __typename?: 'Metadata', title: string, episodeInfo?: { __typename?: 'EpisodeInfo', title?: string | null, season?: number | null, number?: number | null } | null } } | { __typename?: 'VODFolder' } | { __typename?: 'VODProduct' } | { __typename?: 'VODSeries' } } | null> } | null } } | null };


export const GetBingeWatchDataDocument = gql`
    query getBingeWatchData($currentItemId: ID!, $currentItemType: ContentItemType!) {
  bingeWatchData(currentItemId: $currentItemId, currentItemType: $currentItemType) {
    id
    seriesId
    groupingInfos {
      id
      title
      episodeCount
    }
    content {
      items {
        edges {
          id
          node {
            ... on VODAsset {
              id
              metadata {
                title
                episodeInfo {
                  title
                  season
                  number
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBingeWatchDataQuery__
 *
 * To run a query within a React component, call `useGetBingeWatchDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBingeWatchDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBingeWatchDataQuery({
 *   variables: {
 *      currentItemId: // value for 'currentItemId'
 *      currentItemType: // value for 'currentItemType'
 *   },
 * });
 */
export function useGetBingeWatchDataQuery(baseOptions: Apollo.QueryHookOptions<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>(GetBingeWatchDataDocument, options);
      }
export function useGetBingeWatchDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>(GetBingeWatchDataDocument, options);
        }
export function useGetBingeWatchDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>(GetBingeWatchDataDocument, options);
        }
export type GetBingeWatchDataQueryHookResult = ReturnType<typeof useGetBingeWatchDataQuery>;
export type GetBingeWatchDataLazyQueryHookResult = ReturnType<typeof useGetBingeWatchDataLazyQuery>;
export type GetBingeWatchDataSuspenseQueryHookResult = ReturnType<typeof useGetBingeWatchDataSuspenseQuery>;
export type GetBingeWatchDataQueryResult = Apollo.QueryResult<GetBingeWatchDataQuery, GetBingeWatchDataQueryVariables>;