import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';

export enum RecordingManagementActionID {
    FILTER = 'FILTER',
    SORT = 'SORT',
    SELECT = 'SELECT',
    DELETE_ALL = 'DELETE_ALL',
    DELETE = 'DELETE',
    CLEAR_ALL = 'CLEAR_ALL',
}

export type RecordingManagementFilterAction = ButtonEntity<RecordingManagementActionID.FILTER, 'FILTER'>;
export type RecordingManagementSortAction = ButtonEntity<RecordingManagementActionID.SORT, 'SORT'>;
export type RecordingManagementSelectAction = ButtonEntity<RecordingManagementActionID.SELECT, 'SELECT'>;
export type RecordingManagementClearAllAction = ButtonEntity<RecordingManagementActionID.CLEAR_ALL, 'RECORDING_MANAGE_CLEAR_ALL'>;
export type RecordingManagementDeleteAllAction = ButtonEntity<RecordingManagementActionID.DELETE_ALL, 'DELETE_ALL'>;
export type RecordingManagementDeleteAction = ButtonEntity<RecordingManagementActionID.DELETE, 'RECORDING_MANAGE_DELETE'>;

export type RecordingManagementAction =
    | RecordingManagementFilterAction
    | RecordingManagementSortAction
    | RecordingManagementSelectAction
    | RecordingManagementDeleteAllAction
    | RecordingManagementDeleteAction
    | RecordingManagementClearAllAction;

export enum RecordingManagementFocusContext {
    HEADER = 'HEADER',
    GRID = 'GRID',
}

// State

export type RecordingManagementState = {
    isTrickMode: boolean;
    filterByValue: string;
    sortByValue: string;
    selectedRecordings: GQL.RecordingID[];
};

export const enum RecomendationManagementSort {
    START = 'start',
    NAME = 'name',
    NAMEREV = 'name_rev',
    AVAILABLE = 'availableUntil',
    SIZEREV = 'size_rev',
    SIZE = 'size',
}

export const enum RecomendationManagementFilter {
    CLEAR_FILTER = 'CLEAR_FILTER',
    CONTINUE_WATCHING = 'CONTINUE_WATCHING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PLANNED = 'PLANNED',
    FAILED = 'FAILED',
    IS_SERIES_RECORDING = 'series_rec',
    IS_SINGLE_RECORDING = 'single_rec',
}

// Actions

export type SetTrickModeActionPayload = { isTrickMode: boolean };
export type SortOptionSelectedActionPayload = { sortByValue: string };
export type FilterOptionSelectedActionPayload = { filterByValue: string };
export type ResetStateActionPayload = null;
export type OnSelectActionPayload = { selected: string[] };

export type RecordingManageTrickModeAction = PayloadedAction<ActionType.RECORDING_MANAGE_SET_TRICK_MODE, SetTrickModeActionPayload>;

export type RecordingManageSortOptionSelected = PayloadedAction<
    ActionType.RECORDING_MANAGE_SET_SORT_OPTION,
    SortOptionSelectedActionPayload
>;

export type SetInitialRecordManageOnBack = PayloadedAction<ActionType.RECORDING_MANAGE_RESET_STATE, ResetStateActionPayload>;

export type RecordingManageFilterOptionSelected = PayloadedAction<
    ActionType.RECORDING_MANAGE_SET_FILTER_OPTION,
    FilterOptionSelectedActionPayload
>;

export type RecordingManageSelect = PayloadedAction<ActionType.RECORDING_MANAGE_HANDLE_SELECT, OnSelectActionPayload>;
export type RecordingManageHandleDeleteResult = PayloadedAction<ActionType.RECORDING_MANAGE_HANDLE_DELETE_RESULT, OnSelectActionPayload>;

export type RecordManageReduxAction =
    | RecordingManageTrickModeAction
    | RecordingManageSortOptionSelected
    | RecordingManageFilterOptionSelected
    | SetInitialRecordManageOnBack
    | RecordingManageSelect
    | RecordingManageHandleDeleteResult;
