import React, { useCallback, useEffect, useRef } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { loadEventsForZapperMiniEpg } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { selectEventBy, selectEventIsRestricted } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { getEventProgramType } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { Programs } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramsData/components/Programs';
import { ZapperProgram } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramsData/components/ZapperProgram';
import { Elements, EventElement } from '__SMART_APP_OLD__/components/EPG/types';
import { ProgramType } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

interface Props {
    time: number;
    channelId: string;
    isMagicMode: boolean;
    isZapperMode: boolean;
    onEventChange: (direction: 1 | -1) => void;
    onFastScrollTimeChange: (direction: 1 | -1) => void;
    onSelectEvent: (event: EventElement) => void;
    onClose: (isRestricted?: boolean) => void;
    onShowFull: () => void;
    onShowZapper: () => void;
    isVodOrCatchup?: boolean;
}

export const ProgramsData: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const liveChannelId = useSelector(selectChannelLiveId);
    const {
        channelId,
        isZapperMode,
        isMagicMode,
        onClose,
        onEventChange,
        onSelectEvent,
        onShowFull,
        onShowZapper,
        onFastScrollTimeChange,
    } = props;
    const selectedTime = isZapperMode ? Date.now() : props.time;
    const event = useSelector(selectEventBy(props.channelId, selectedTime, { equal: EqualityCompare.START }));
    const isRestricted = useSelector(selectEventIsRestricted(event?.id ?? '', PinSessionType.PIN_LIVE_TV));

    const isLiveId = liveChannelId === props.channelId;
    const contentMarker = isLiveId && !props.isVodOrCatchup ? ContentMarkerType.NowPlaying : ContentMarkerType.Live;

    const enterEventHandler = useCallback(() => {
        if (!event) return;
        const isLive = getEventProgramType(event.start, event.end, Date.now()) === ProgramType.LIVE;
        const eventElement: EventElement = {
            type: Elements.EVENT,
            eventId: event.id,
            channelId: props.channelId,
            date: event.start,
            isLive,
            isNowPlaying: isLiveId && isLive,
            restricted: isRestricted,
            isLoading: event.isLoading,
            isNoInfo: event.isNoInfo,
        };
        onSelectEvent(eventElement);
    }, [event, props.channelId, isLiveId, isRestricted, onSelectEvent]);

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            const key = e.keyCode || e.which || e.detail;
            if (key !== Key.VK_ENTER) return;
            enterEventHandler();
        };

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [enterEventHandler]);

    const requestInProgress = useRef(false);
    useEffect(() => {
        if (!isZapperMode || requestInProgress.current) return;
        requestInProgress.current = true;
        dispatch(loadEventsForZapperMiniEpg(channelId))
            .then(() => {
                requestInProgress.current = false;

                return null;
            })
            .catch((e) => {
                throw e;
            });
    }, [isZapperMode, channelId, dispatch]);

    if (isZapperMode) {
        return (
            <ZapperProgram
                channelId={channelId}
                contentMarker={contentMarker}
                isMagicMode={isMagicMode}
                eventId={event?.id}
                onBack={onClose}
                onShowFull={onShowFull}
            />
        );
    }

    return (
        <Programs
            time={props.time}
            channelId={props.channelId}
            isMagicMode={props.isMagicMode}
            contentMarker={contentMarker}
            event={event}
            onBack={onShowZapper}
            onEventChange={onEventChange}
            onFastScrollTimeChange={onFastScrollTimeChange}
        />
    );
};
