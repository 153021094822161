/* eslint-disable complexity */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */
import { ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';
import { AssetType, Route, SettingsElement, vodTypes } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { isFirstMenuElement } from '__SMART_APP_OLD__/utils/Utils';
import { Product } from 'analytics/logging/classes/Product';

import { ContentRef } from '../classes/ContentRef';
import { UIElement, UIElementParams, UIElementTypes } from '../classes/UIElement';
import { UILayer, UILayerParams, UILayerTypes } from '../classes/UILayer';
import * as ContentRefGenerators from '../contentRefGenerators';
import { UIActionEvent, UIActionEventParams, UIActionEvents, UIActionTriggers, UIStates } from '../events/UIActionEvent';

interface MenuItem {
    id: string;
    title: string;
}
interface AdditionallInfo {
    assetType?: AssetType;
    id?: string;
    trigger?: UIActionTriggers;
    menuItem?: MenuItem;
    to?: UIStates;
    path?: string;
    layer?: Partial<UILayerParams>;
    element?: Partial<UIElementParams>;
    description?: string;
}

type ToPageEvent =
    | UIActionEvents.TO_GRID_TV_GUIDE
    | UIActionEvents.TO_HOME
    | UIActionEvents.TO_MY_LIBRARY
    | UIActionEvents.TO_SEARCH
    | UIActionEvents.TO_SETTINGS;

const EventStateMap = {
    [UIActionEvents.TO_HOME]: UIStates.HOME,
    [UIActionEvents.TO_MY_LIBRARY]: UIStates.MYLIBRARY,
    [UIActionEvents.TO_GRID_TV_GUIDE]: UIStates.GRIDTVGUIDE,
    [UIActionEvents.TO_SEARCH]: UIStates.SEARCH,
    [UIActionEvents.TO_SETTINGS]: UIStates.SETTINGS,
    [UIActionEvents.PLAY_LIVE]: UIStates.FSV,
};

export const getUIStateFromPathName = (pathname: string = window.location.pathname): UIStates => {
    const [pageType, pageName] = pathname.split('/').slice(1);
    switch (pageType) {
        case 'page':
            switch (pageName) {
                case Route.HOME:
                    return UIStates.HOME;
                case Route.MY_LIBRARY:
                    return UIStates.MYLIBRARY;
                case Route.VIDEOSTORE:
                case Route.VOD_FOLDER:
                default:
                    return UIStates.STOPPED;
            }
        case 'login':
            return UIStates.LOGIN;
        case 'epg':
            return UIStates.GRIDTVGUIDE;
        case 'collection':
            return UIStates.STOPPED; // need correct type
        case 'settings':
            switch (pageName) {
                case 'main':
                    return UIStates.SETTINGS;
                case SettingsElement.LANGUAGE:
                    return UIStates.LANGUAGESELECTION;
                case SettingsElement.CHANNEL_LINEUP:
                    return UIStates.CHANNELLISTS;
                case SettingsElement.PARENTAL_CONTROL:
                case SettingsElement.PIN:
                default:
                    return UIStates.STOPPED;
            }
        case 'details':
            return UIStates.DETAILEDINFO;
        case 'play':
        case 'playChannel':
        case 'liveTV':
            return UIStates.FSV;
        case SettingsElement.DEVICE_MANAGEMENT:
        default:
            return UIStates.STOPPED;
    }
};

const getPlayButtonTitle = (event: UIActionEvents, isPlayerButton: boolean = false) => {
    switch (event) {
        case UIActionEvents.PLAY:
            return isPlayerButton ? 'Play' : 'Watch';
        case UIActionEvents.PLAY_LIVE:
            return isPlayerButton ? 'Jump to live' : 'Watch';
        case UIActionEvents.CONTINUE_WATCHING:
            return 'Resume';
        case UIActionEvents.PLAY_TRAILER:
            return 'Trailer';
        case UIActionEvents.VIEW_FROM_START:
            return 'Start over';
        case UIActionEvents.STOP_PLAYBACK:
            return 'Pause';
        default:
            return 'Watch';
    }
};

const getContentRef = (assetType: AssetType | '' = '', id: string = ''): ContentRef => {
    if (!assetType || assetType === AssetType.CHANNEL) return ContentRefGenerators.getLiveChannelContentRef(id);
    if (vodTypes.includes(assetType)) return ContentRefGenerators.getVODAssetContentRef(id);
    if (assetType === AssetType.CHANNEL_LIST) return ContentRefGenerators.getChannelListContentRef(id);
    return ContentRefGenerators.getEventContentRef(id);
};

const getFavorireUIEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo): UIActionEvent => {
    const state = getUIStateFromPathName();
    return new UIActionEvent({
        event,
        fromState: state,
        toState: state,
        trigger: UIActionTriggers.SELECT,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
        uiElement: new UIElement({
            type: UIElementTypes.BUTTON,
            title: event,
        }),
    });
};

const getUserLoginUIEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.USER_LOGIN,
        fromState: UIStates.LOGIN,
        toState: isFirstMenuElement(Route.LIVE_TV) ? UIStates.FSV : UIStates.HOME,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({
            type: UIElementTypes.BUTTON,
            title: 'Login',
        }),
    });

const getToDetailInfoActionEvent = (additionallInfo: AdditionallInfo) => {
    const params: UIActionEventParams = {
        event: UIActionEvents.TO_DETAILED_INFO,
        fromState: getUIStateFromPathName(),
        toState: UIStates.DETAILEDINFO,
        trigger: additionallInfo.trigger ?? UIActionTriggers.SELECT,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
    };

    switch (params.fromState) {
        case UIStates.GRIDTVGUIDE:
            params.uiElement = new UIElement({ type: UIElementTypes.EVENT });
            params.uiLayer = new UILayer({ type: UILayerTypes.GRID });
            break;
        case UIStates.HOME:
        case UIStates.MYLIBRARY:
            params.uiElement = new UIElement({ type: UIElementTypes.EVENT });
            params.uiLayer = new UILayer({ type: UILayerTypes.ROW });
            break;
        default:
            break;
    }

    return new UIActionEvent(params);
};

const getToPageFromMenuBarActionEvent = (event: ToPageEvent, menuItem?: MenuItem) =>
    new UIActionEvent({
        event,
        fromState: getUIStateFromPathName(),
        toState: EventStateMap[event],
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.ACTION, title: menuItem?.title }),
        uiLayer: new UILayer({ type: UILayerTypes.MENU_BAR }),
    });

const getAudioSubtitlesChangeActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const state = getUIStateFromPathName();
    const params: UIActionEventParams = {
        event,
        fromState: state,
        toState: state,
        trigger: UIActionTriggers.SELECT,
    };
    if (additionallInfo.id && additionallInfo.assetType) {
        params.contentRef = getContentRef(additionallInfo.assetType, additionallInfo.id);
    }
    return new UIActionEvent(params);
};

const getPlayActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const params: UIActionEventParams = {
        event,
        fromState: getUIStateFromPathName(),
        toState: UIStates.FSV,
        trigger: additionallInfo.trigger ?? UIActionTriggers.SELECT,
    };
    if (additionallInfo.menuItem) {
        return new UIActionEvent({
            ...params,
            contentRef: ContentRefGenerators.getLiveChannelContentRef(additionallInfo.id),
            uiElement: new UIElement({ type: UIElementTypes.ACTION, title: 'Live TV' }),
            uiLayer: new UILayer({ type: UILayerTypes.MENU_BAR }),
        });
    }
    const isPlayerButton = params.fromState === UIStates.FSV;
    return new UIActionEvent({
        ...params,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
        uiElement: new UIElement({ type: UIElementTypes.BUTTON, title: getPlayButtonTitle(event, isPlayerButton) }),
    });
};

const getToMiniEpgActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) =>
    new UIActionEvent({
        event,
        fromState: UIStates.FSV,
        toState: UIStates.VERTICALTVGUIDE,
        trigger: additionallInfo.trigger,
    });

const getPinActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const fromState = getUIStateFromPathName();
    return new UIActionEvent({
        event,
        fromState,
        toState: event === UIActionEvents.PIN_FAILED ? fromState : (additionallInfo.to as UIStates),
        trigger: UIActionTriggers.PIN,
    });
};

const getZapActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) =>
    new UIActionEvent({
        event,
        fromState: UIStates.FSV,
        toState: UIStates.FSV,
        trigger: additionallInfo.trigger,
        contentRef: ContentRefGenerators.getLiveChannelContentRef(additionallInfo.id),
    });

const getLogoutActionEvent = (event: UIActionEvents) =>
    new UIActionEvent({
        event,
        fromState: getUIStateFromPathName(),
        toState: UIStates.LOGIN,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.BUTTON, title: 'Logout' }),
    });

const getBackActionEvent = (event: UIActionEvents) => {
    const path = CustomHistory.stack[CustomHistory.stack.length - 2];
    return new UIActionEvent({
        event,
        fromState: getUIStateFromPathName(),
        toState: getUIStateFromPathName(path),
        trigger: UIActionTriggers.BACK,
    });
};

const getExitActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) =>
    new UIActionEvent({
        event,
        fromState: getUIStateFromPathName(),
        toState: UIStates.BACKGROUND,
        trigger: additionallInfo.trigger ?? UIActionTriggers.BACK,
    });

const getSelectChannelListActionEvent = (channelListId?: string) =>
    new UIActionEvent({
        event: UIActionEvents.SELECT_CHANNEL_LIST,
        fromState: UIStates.CHANNELLISTS,
        toState: UIStates.CHANNELLISTS,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.OPTION, title: channelListId }),
    });

const getSelectDateActionEvent = (selectedDate?: string) =>
    new UIActionEvent({
        event: UIActionEvents.SELECT_DATE,
        fromState: UIStates.GRIDTVGUIDE,
        toState: UIStates.GRIDTVGUIDE,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.OPTION, title: selectedDate }),
    });

const getSynopsisActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const state = getUIStateFromPathName();
    const params: UIActionEventParams = {
        event,
        fromState: state,
        toState: state,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
    };
    if (event === UIActionEvents.OPEN_FULL_SYNOPSIS) {
        params.trigger = UIActionTriggers.SELECT;
        params.uiElement = new UIElement({
            type: UIElementTypes.BUTTON,
            title: 'more-info',
        });
    } else {
        params.trigger = additionallInfo.trigger ?? UIActionTriggers.BACK;
    }
    return new UIActionEvent(params);
};

const getAcknowledgeErrorActionEvent = (additionallInfo: AdditionallInfo) => {
    const state = getUIStateFromPathName();
    const params: UIActionEventParams = {
        event: UIActionEvents.ACKNOWLEDGE_ERROR,
        fromState: state,
        toState: state,
        trigger: additionallInfo.trigger ?? UIActionTriggers.BACK,
        uiLayer: new UILayer({ type: UILayerTypes.ERROR_POPUP, title: additionallInfo.layer?.title ?? 'Error' }),
    };

    if (params.trigger === UIActionTriggers.SELECT) {
        params.uiElement = new UIElement({ type: UIElementTypes.BUTTON, title: additionallInfo.element?.title });
    }

    return new UIActionEvent(params);
};

const getRentBuyActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const state = getUIStateFromPathName();
    return new UIActionEvent({
        event,
        fromState: state,
        toState: state,
        trigger: UIActionTriggers.SELECT,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
        uiElement: new UIElement({
            type: UIElementTypes.BUTTON,
            title: event === UIActionEvents.RENT ? 'Rent' : 'Buy',
        }),
    });
};

const getEmergencyErrorActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const state = getUIStateFromPathName();
    const uiLayer = new UILayer({
        type: UILayerTypes.ERROR_NOTIFICATION,
        title: additionallInfo.description,
    });
    return new UIActionEvent({
        event,
        fromState: state,
        toState: state,
        uiLayer,
    });
};

const getCreateRecordEvent = (additionallInfo: AdditionallInfo) => {
    const params: UIActionEventParams = {
        event: UIActionEvents.RECORD,
        fromState: UIStates.DETAILEDINFO,
        toState: UIStates.DETAILEDINFO,
        trigger: UIActionTriggers.OK,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
        uiElement: new UIElement({ type: UIElementTypes.EVENT }),
    };
    const event = new UIActionEvent(params);
    return event;
};

const getDeleteRecordEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo) => {
    const params: UIActionEventParams = {
        event,
        fromState: UIStates.DETAILEDINFO,
        toState: UIStates.DETAILEDINFO,
        trigger: UIActionTriggers.OK,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
        uiElement: new UIElement({ type: UIElementTypes.EVENT }),
    };
    return new UIActionEvent(params);
};

const getManageChannelListEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo): UIActionEvent =>
    new UIActionEvent({
        event,
        fromState: UIStates.CHANNELLISTS,
        toState: UIStates.CHANNELLISTS,
        trigger: UIActionTriggers.OK,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
    });

const getCreateChannelListEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo): UIActionEvent =>
    new UIActionEvent({
        event,
        fromState: UIStates.CHANNELLISTS,
        toState: UIStates.SETTINGS,
        trigger: UIActionTriggers.BACK,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
    });

const getDeleteChannelListEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo): UIActionEvent =>
    new UIActionEvent({
        event,
        fromState: UIStates.CHANNELLISTS,
        toState: UIStates.CHANNELLISTS,
        trigger: UIActionTriggers.OK,
        contentRef: getContentRef(additionallInfo.assetType, additionallInfo.id),
    });

export const getThemeChangeUIActionEvent = (option: ThemeType) =>
    new UIActionEvent({
        event: UIActionEvents.CUSTOM_ACTION,
        fromState: UIStates.SETTINGS,
        toState: UIStates.SETTINGS,
        trigger: UIActionTriggers.SELECT,
        uiLayer: new UILayer({ title: 'THEME_SELECT_SCREEN', type: UILayerTypes.HELP }),
        uiElement: new UIElement({ title: option, type: UIElementTypes.OPTION }),
    });

export const getToTVGuideUIActionEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.TO_GRID_TV_GUIDE,
        fromState: getUIStateFromPathName(),
        toState: UIStates.GRIDTVGUIDE,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.ACTION, title: 'TV_GUIDE' }),
        uiLayer: new UILayer({ type: UILayerTypes.MENU_BAR }),
    });

export const getDisplayAdultContentActionEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.DISPLAY_ADULT_CONTENT,
        fromState: UIStates.SETTINGS,
        toState: UIStates.SETTINGS,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.OPTION, title: 'DISPLAY_ADULT_CONTENT' }),
    });

export const getDisplayRegularContentActionEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.DISPLAY_REGULAR_CONTENT,
        fromState: UIStates.SETTINGS,
        toState: UIStates.SETTINGS,
        trigger: UIActionTriggers.SELECT,
        uiElement: new UIElement({ type: UIElementTypes.OPTION, title: 'DISPLAY_REGULAR_CONTENT' }),
    });

export const getPlayMessageAttachmentEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.PLAY_MESSAGE_ATTACHMENT,
        fromState: UIStates.MESSAGES_READ,
        toState: UIStates.MESSAGES_READ,
        trigger: UIActionTriggers.OK,
        uiElement: new UIElement({ type: UIElementTypes.MESSAGE, title: 'PLAY_MESSAGE_ATTACHMENT' }),
    });

export const getOpenMessageEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.OPEN_MESSAGE,
        fromState: UIStates.MESSAGES_READ,
        toState: UIStates.MESSAGES_READ,
        trigger: UIActionTriggers.OK,
        uiElement: new UIElement({ type: UIElementTypes.MESSAGE, title: 'OPEN_MESSAGE' }),
    });

export const getDeleteMessageEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.DELETE_MESSAGE,
        fromState: UIStates.MESSAGES,
        toState: UIStates.MESSAGES,
        trigger: UIActionTriggers.OK,
        uiElement: new UIElement({ type: UIElementTypes.MESSAGE, title: 'DELETE_MESSAGE' }),
    });

export const getToManageRecordingsEvent = () =>
    new UIActionEvent({
        event: UIActionEvents.CUSTOM_ACTION,
        fromState: UIStates.MYLIBRARY,
        toState: UIStates.MYLIBRARY,
        trigger: UIActionTriggers.OK,
        uiElement: new UIElement({ type: UIElementTypes.RECORDING, title: 'MANAGE_RECORDINGS' }),
        uiLayer: new UILayer({ type: UILayerTypes.ROW }),
    });

export const getVodUpsellEvent = (event: UIActionEvents, product: Product) =>
    new UIActionEvent({
        event,
        fromState: UIStates.PURCHASE_OPTIONS,
        toState: UIStates.PURCHASE_OPTIONS,
        trigger: UIActionTriggers.OK,
        product,
    });

export const getUIActionEvent = (event: UIActionEvents, additionallInfo: AdditionallInfo = {}): UIActionEvent => {
    switch (event) {
        case UIActionEvents.AUDIO_CHANGED:
        case UIActionEvents.SUBTITLES_CHANGED:
            return getAudioSubtitlesChangeActionEvent(event, additionallInfo);
        case UIActionEvents.ADD_FAVORITE:
        case UIActionEvents.REMOVE_FAVORITE:
            return getFavorireUIEvent(event, additionallInfo);
        case UIActionEvents.USER_LOGIN:
            return getUserLoginUIEvent();
        case UIActionEvents.TO_HOME:
        case UIActionEvents.TO_SEARCH:
        case UIActionEvents.TO_SETTINGS:
        case UIActionEvents.TO_MY_LIBRARY:
        case UIActionEvents.TO_GRID_TV_GUIDE:
            return getToPageFromMenuBarActionEvent(event, additionallInfo.menuItem);
        case UIActionEvents.PLAY:
        case UIActionEvents.PLAY_LIVE:
        case UIActionEvents.PLAY_TRAILER:
        case UIActionEvents.VIEW_FROM_START:
        case UIActionEvents.CONTINUE_WATCHING:
        case UIActionEvents.STOP_PLAYBACK:
            return getPlayActionEvent(event, additionallInfo);
        case UIActionEvents.TO_DETAILED_INFO:
            return getToDetailInfoActionEvent(additionallInfo);
        case UIActionEvents.TO_VERTICAL_TV_GUIDE:
            return getToMiniEpgActionEvent(event, additionallInfo);
        case UIActionEvents.PIN_FAILED:
        case UIActionEvents.PIN_SUCCESS:
            return getPinActionEvent(event, additionallInfo);
        case UIActionEvents.SELECT_CHANNEL_LIST:
            return getSelectChannelListActionEvent(additionallInfo.element?.title);
        case UIActionEvents.ZAP:
            return getZapActionEvent(event, additionallInfo);
        case UIActionEvents.RENT:
        case UIActionEvents.BUY:
            return getRentBuyActionEvent(event, additionallInfo);
        case UIActionEvents.OPEN_FULL_SYNOPSIS:
        case UIActionEvents.CLOSE_FULL_SYNOPSIS:
            return getSynopsisActionEvent(event, additionallInfo);
        case UIActionEvents.SELECT_DATE:
            return getSelectDateActionEvent(additionallInfo.element?.title);
        case UIActionEvents.ACKNOWLEDGE_ERROR:
            return getAcknowledgeErrorActionEvent(additionallInfo);
        case UIActionEvents.BACK:
            return getBackActionEvent(event);
        case UIActionEvents.LOGOUT:
            return getLogoutActionEvent(event);
        case UIActionEvents.EXIT:
            return getExitActionEvent(event, additionallInfo);
        case UIActionEvents.SHOW_EMERGENCY:
        case UIActionEvents.ERROR_RESOLVED:
            return getEmergencyErrorActionEvent(event, additionallInfo);
        case UIActionEvents.RECORD:
            return getCreateRecordEvent(additionallInfo);
        case UIActionEvents.CANCEL_RECORDING:
        case UIActionEvents.CANCEL_SERIES_RECORDING:
        case UIActionEvents.DELETE_RECORDING:
        case UIActionEvents.DELETE_EPISODE_RECORDING:
        case UIActionEvents.DELETE_ALL_RECORDED_EPISODES:
            return getDeleteRecordEvent(event, additionallInfo);
        case UIActionEvents.MANAGE_CHANNEL_LISTS:
            return getManageChannelListEvent(event, additionallInfo);
        case UIActionEvents.CHANNEL_LIST_CHANGED:
            return getManageChannelListEvent(event, additionallInfo);
        case UIActionEvents.CHANNEL_LIST_CREATED:
            return getCreateChannelListEvent(event, additionallInfo);
        case UIActionEvents.CHANNEL_LIST_DELETED:
            return getDeleteChannelListEvent(event, additionallInfo);
        default:
            return new UIActionEvent({ event, fromState: UIStates.STOPPED, toState: UIStates.STOPPED });
    }
};
