import { useMemo } from 'react';

import { ListEngine, ListEngineConfig } from '__SMART_APP_OLD__/app/components/ListV2/ListEngine';

export interface StaticListEngineConfig extends ListEngineConfig {
    itemSize: number;
}

export class StaticListEngine<T> extends ListEngine<T> {
    private container: DOMRect;

    private list: DOMRect;

    private itemSize: number;

    private items: DOMRect[];

    protected constructor(config: StaticListEngineConfig) {
        super(config);
        this.container = new DOMRect(0, 0, config.itemSize * config.max, config.itemSize * config.max);
        this.list = new DOMRect(0, 0, 0, 0);
        this.items = [];
        this.itemSize = config.itemSize;
    }

    public static useEngine = <G>(): ListEngine<G> => {
        throw new Error('Method Forbidden. Use useStaticEngine instead.');
    };

    public static useStaticEngine = <G>(focused: number, data: G[], config: StaticListEngineConfig): ListEngine<G> => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const engine = useMemo(() => new StaticListEngine<G>(config), []);
        engine.setData(data);
        engine.setFocused(focused);
        engine.useReady();
        if (data.length <= engine.getMax()) {
            engine.setLastIndex(engine.getMax());
        }
        return engine;
    };

    public getContainer(): DOMRect {
        return this.container;
    }

    public getList(): DOMRect {
        return this.list;
    }

    public getItems(): DOMRect[] {
        return this.items;
    }

    public setData(data: T[]): void {
        super.setData(data);
        this.list = new DOMRect(0, 0, this.itemSize * this.getData().length, this.itemSize * this.getData().length);
        this.items = this.getData().map((_, i) => new DOMRect(i * this.itemSize, i * this.itemSize, this.itemSize, this.itemSize));
    }
}
