import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { AustriaBackEngine } from 'App/Modules/EnhancedBack/Implementation/Austria';
import { AdsScippingEngine } from 'App/Modules/AdsScipping';
import { AustriaAdsScippingEngine } from 'App/Modules/AdsScipping/Implementation/Austria';

import { Screen } from 'App/Modules/Screen';
import { Login } from 'App/Modules/Screen/Modules/Login';

export class Austria {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Opco.Austria.Initialize]');
        AdsScippingEngine.initialize(new AustriaAdsScippingEngine());
        EnhancedBack.initialize(new AustriaBackEngine());

        Screen.register(Screen.Type.LOGIN, Login.View, Login.lifecycle);
        return true;
    }
}
