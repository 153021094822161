import React from 'react';

import { ChannelCardNumberBase } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumberBase';
import {
    ChannelCardNumberChannelList,
    ChannelCardNumberChannelListModule,
} from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumberChannelList';
import {
    ChannelCardNumberDefaultNumber,
    ChannelCardNumberDefaultNumberModule,
} from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumberDefaultNumber';

type Props = {};

export type ChannelCardNumberModule = {
    DefaultNumber: ChannelCardNumberDefaultNumberModule;
    ChannelList: ChannelCardNumberChannelListModule;
} & React.FunctionComponent<React.PropsWithChildren<Props>>;

export const ChannelCardNumber: ChannelCardNumberModule = ChannelCardNumberBase as ChannelCardNumberModule;

ChannelCardNumber.DefaultNumber = ChannelCardNumberDefaultNumber;
ChannelCardNumber.ChannelList = ChannelCardNumberChannelList;
