import { useCallback, useEffect, useRef } from 'react';

export const useFastScroll = (
    onFastScrollChange: (isFastScroll: boolean) => void,
    callback: (direction: 1 | -1) => void,
    interval: number = 100
): [(direction: 1 | -1) => void, () => void] => {
    const fastScrollInterval = useRef<ReturnType< typeof setTimeout | any>>();
    const fastScrollDirection = useRef<1 | -1>();
    const intervalRef = useRef(interval);
    const onFastScrollChangeRef = useRef(onFastScrollChange);

    intervalRef.current = interval;
    onFastScrollChangeRef.current = onFastScrollChange;

    const fastScrollStartHandler = useCallback(
        (direction: 1 | -1) => {
            fastScrollDirection.current = direction;
            clearInterval(fastScrollInterval.current);
            onFastScrollChangeRef.current(true);
            fastScrollInterval.current = setInterval(callback, intervalRef.current, direction);
        },
        [callback]
    );

    const fastScrollEndHandler = useCallback(() => {
        onFastScrollChangeRef.current(false);
        clearInterval(fastScrollInterval.current);
        fastScrollInterval.current = undefined;
    }, []);

    useEffect(() => {
        if (fastScrollInterval.current && fastScrollDirection.current) {
            fastScrollStartHandler(fastScrollDirection.current);
        }
    }, [fastScrollStartHandler]);

    useEffect(() => fastScrollEndHandler, [fastScrollEndHandler]);

    return [fastScrollStartHandler, fastScrollEndHandler];
};
