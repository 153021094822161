import { historyActions } from '__SMART_APP_OLD__/app/modules/History/actions';
import { getInitialHistoryState } from '__SMART_APP_OLD__/app/modules/History/getInitialState';
import { historyReducer } from '__SMART_APP_OLD__/app/modules/History/reducer';
import { historySelectors } from '__SMART_APP_OLD__/app/modules/History/selectors';
import { HistoryActionType, HistoryEntity, HistoryReduxAction } from '__SMART_APP_OLD__/app/modules/History/types';

export { HistoryActionType as ActionType };

export type { HistoryEntity as Entity, HistoryReduxAction as ReduxAction };

export const getInitialState = getInitialHistoryState;

export const actions = historyActions.public;

export const selectors = historySelectors.public;

export const reducer = historyReducer;
