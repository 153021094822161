import { Mask } from 'App/Packages/UI/Mask';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import React from 'react';

const getClassName = (props: DetailCaptionProps): string => {
    const classList = ['detail__caption'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailCaptionProps = {
    typeface?: Typeface;
    size?: FontSize;
    weight?: FontWeight;
    color?: Color;
    theme?: Theme.Type | null;
    isFocused?: boolean;
    className?: string;
    children?: React.ReactNode;
    customColor?: string;
};

export type DetailCaptionComponent = React.FunctionComponent<DetailCaptionProps>;

export const DetailCaption: DetailCaptionComponent = (props) => {
    return (
        <Mask masked={null}>
            <Text
                className={getClassName(props)}
                typeface={props.typeface ?? Typeface.SANS}
                size={props.size ?? FontSize.TITLE_3}
                weight={props.weight ?? FontWeight.REGULAR}
                color={props.color ?? Color.SECONDARY}
                theme={props.theme}
                isFocused={props.isFocused}
                customColor={props.customColor}
            >
                {props.children}
            </Text>
        </Mask>
    );
};
