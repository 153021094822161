import React, { useCallback } from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { List, ListItemType, ListRenderer } from '__SMART_APP_OLD__/app/components/List';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectDeviceIds } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/actions';
import { DEVICE_MANAGEMENT_FOCUSED_ITEM_OFFSET } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/constants';
import { Description } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/Description';
import { DeviceItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/DeviceItem';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const DeviceManagementItems: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const items = useSelector(selectDeviceIds);
    const { focused, focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.X,
        items,
    });

    const firstVisible = focusedIndex - DEVICE_MANAGEMENT_FOCUSED_ITEM_OFFSET;

    const selectHandler = useCallback((id: string) => dispatch(actions.private.selected(id)), [dispatch]);

    const renderItem: ListRenderer<string> = (id) => (
        <DeviceItem
            key={id}
            id={id}
            ctx={Focus.Default}
            isFocused={focused === id && !isDisabled}
            onHover={focus}
            onEnter={selectHandler}
        />
    );

    return (
        <>
            {focusedIndex !== 0 && (
                <Div className="device-management__guard device-management__guard--left" backgroundColor={BackgroundColor.PRIMARY} />
            )}
            {focusedIndex !== items.length - 1 && (
                <Div className="device-management__guard device-management__guard--right" backgroundColor={BackgroundColor.PRIMARY} />
            )}
            <List
                className="device-management__items"
                items={items}
                itemType={ListItemType.DEVICE_ITEM}
                firstVisibleIndex={firstVisible}
                renderer={renderItem}
            />
            <Description className="device-management__description" deviceId={focused} />
        </>
    );
};
