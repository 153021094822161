import React from 'react';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

type DetailButtonRecordDeleteProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonRecordDeleteProps> = (props) => {
    return (
        <Folder.Detail.Action id="delete-record" icon={IconName.DELETE} onEnter={props.onEnter}>
            SCREEN_DETAIL_DELETE_RECORDING_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonRecordDelete = hidable(Button);

export type DetailButtonRecordDeleteModule = typeof DetailButtonRecordDelete;
