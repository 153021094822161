import { DetailComponent, DetailProps, Detail as Root } from 'App/Packages/UI/Detail/Components/Detail';
import { DetailAction, DetailActionComponent } from 'App/Packages/UI/Detail/Components/DetailAction';
import { DetailDescription, DetailDescriptionComponent } from 'App/Packages/UI/Detail/Components/DetailDescription';
import { DetailActions, DetailActionsComponent } from 'App/Packages/UI/Detail/Components/DetailActions';
import { DetailBackground, DetailBackgroundComponent } from 'App/Packages/UI/Detail/Components/DetailBackground';
import { DetailBody, DetailBodyComponent, DetailBodyProps } from 'App/Packages/UI/Detail/Components/DetailBody';
import { DetailCaption, DetailCaptionComponent, DetailCaptionProps } from 'App/Packages/UI/Detail/Components/DetailCaption';
import { DetailContentMarker, DetailContentMarkerComponent } from 'App/Packages/UI/Detail/Components/DetailContentMarker';
import {
    DetailGradientBottom,
    DetailGradientBottomComponent,
    DetailGradientBottomProps,
} from 'App/Packages/UI/Detail/Components/DetailGradientBottom';
import {
    DetailGradientLeft,
    DetailGradientLeftComponent,
    DetailGradientLeftProps,
} from 'App/Packages/UI/Detail/Components/DetailGradientLeft';
import { DetailLogo, DetailLogoComponent, DetailLogoProps } from 'App/Packages/UI/Detail/Components/DetailLogo';
import { DetailMeta, DetailMetaComponent, DetailMetaProps } from 'App/Packages/UI/Detail/Components/DetailMeta';
import { DetailMetaInfo, DetailMetaInfoComponent, DetailMetaInfoProps } from 'App/Packages/UI/Detail/Components/DetailMetaInfo';
import { DetailMetaRow, DetailMetaRowComponent, DetailMetaRowProps } from 'App/Packages/UI/Detail/Components/DetailMetaRow';
import { DetailProgress, DetailProgressComponent, DetailProgressProps } from 'App/Packages/UI/Detail/Components/DetailProgress';
import { DetailTitle, DetailTitleComponent, DetailTitleProps } from 'App/Packages/UI/Detail/Components/DetailTitle';
import { DetailScreenContainer, DetailScreenContainerModule } from 'App/Packages/UI/Detail/Components/DetailScreenContainer';
import { DetailActionModule, DetailAction as _DetailAction } from 'App/Packages/UI/Detail/Modules/DetailAction/DetailAction';
import { DetailActionsModule, DetailActions as _DetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions';
import { DetailShowMore, DetailShowMoreComponent, DetailShowMoreProps } from 'App/Packages/UI/Detail/Components/DetailShowMore';
import { Constants } from 'App/Packages/UI/Detail/Constants';
import { DetailButtonBuy, DetailButtonBuyModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonBuy';
import { DetailButtonSubscribe, DetailButtonSubscribeModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonSubscribe';
import { DetailButtonFavorite, DetailButtonFavoriteModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonFavorite';
import { DetailButtonWatch, DetailButtonWatchModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonWatch';
import { DetailButtonRent, DetailButtonRentModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonRent';
import { DetailButtonStartOver, DetailButtonStartOverModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonStartOver';
import { DetailButtonTrailer, DetailButtonTrailerModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonTrailer';
import { DetailButtonRecord, DetailButtonRecordModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonRecord';
import {
    DetailButtonRecordCancel,
    DetailButtonRecordCancelModule,
} from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonRecordCancel';
import {
    DetailButtonRecordDelete,
    DetailButtonRecordDeleteModule,
} from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonRecordDelete';
import { DetailButtonReminder, DetailButtonReminderModule } from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonReminder';
import {
    DetailButtonManageRecordings,
    DetailButtonManageRecordingsModule,
} from 'App/Packages/UI/Detail/Components/Buttons/DetailButtonManageRecordings';
import { DetailSubTitle, DetailSubTitleComponent } from 'App/Packages/UI/Detail/Components/DetailSubTitle';

export type {
    DetailBodyProps,
    DetailCaptionProps,
    DetailGradientBottomProps,
    DetailGradientLeftProps,
    DetailLogoProps,
    DetailMetaInfoProps,
    DetailMetaProps,
    DetailMetaRowProps,
    DetailProps,
    DetailTitleProps,
    DetailProgressProps,
    DetailShowMoreProps,
};

export type DetailModule = DetailComponent & {
    Constants: typeof Constants;
    ScreenContainer: DetailScreenContainerModule;
    Title: DetailTitleComponent;
    SubTitle: DetailSubTitleComponent;
    Caption: DetailCaptionComponent;
    Logo: DetailLogoComponent;
    Body: DetailBodyComponent;
    Background: DetailBackgroundComponent;
    Actions: DetailActionsComponent;
    Action: DetailActionComponent;
    Description: DetailDescriptionComponent;
    ShowMore: DetailShowMoreComponent;
    Meta: DetailMetaComponent & {
        Info: DetailMetaInfoComponent;
        Progress: DetailProgressComponent;
        Marker: DetailContentMarkerComponent;
        Row: DetailMetaRowComponent;
    };
    Gradient: {
        Left: DetailGradientLeftComponent;
        Bottom: DetailGradientBottomComponent;
    };
    Modules: {
        Actions: DetailActionsModule;
        Action: DetailActionModule;
    };
    Button: {
        Buy: DetailButtonBuyModule;
        Subscribe: DetailButtonSubscribeModule;
        StartOver: DetailButtonStartOverModule;
        Favorite: DetailButtonFavoriteModule;
        Watch: DetailButtonWatchModule;
        Rent: DetailButtonRentModule;
        Trailer: DetailButtonTrailerModule;
        Record: DetailButtonRecordModule;
        CancelRecord: DetailButtonRecordCancelModule;
        DeleteRecord: DetailButtonRecordDeleteModule;
        Reminder: DetailButtonReminderModule;
        ManageRecordings: DetailButtonManageRecordingsModule;
    };
};

export const Detail: DetailModule = Object.assign(Root, {
    Constants,
    ScreenContainer: DetailScreenContainer,
    Title: DetailTitle,
    SubTitle: DetailSubTitle,
    Caption: DetailCaption,
    Logo: DetailLogo,
    Body: DetailBody,
    Background: DetailBackground,
    Actions: DetailActions,
    Action: DetailAction,
    Description: DetailDescription,
    ShowMore: DetailShowMore,
    Meta: Object.assign(DetailMeta, {
        Info: DetailMetaInfo,
        Progress: DetailProgress,
        Marker: DetailContentMarker,
        Row: DetailMetaRow,
    }),
    Gradient: {
        Left: DetailGradientLeft,
        Bottom: DetailGradientBottom,
    },
    Modules: {
        Actions: _DetailActions,
        Action: _DetailAction,
    },
    Button: {
        Buy: DetailButtonBuy,
        Subscribe: DetailButtonSubscribe,
        StartOver: DetailButtonStartOver,
        Favorite: DetailButtonFavorite,
        Watch: DetailButtonWatch,
        Rent: DetailButtonRent,
        Trailer: DetailButtonTrailer,
        Record: DetailButtonRecord,
        CancelRecord: DetailButtonRecordCancel,
        DeleteRecord: DetailButtonRecordDelete,
        Reminder: DetailButtonReminder,
        ManageRecordings: DetailButtonManageRecordings,
    },
});
