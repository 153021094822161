import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

import { Screen } from 'App/Modules/Screen';
import { UI } from 'App/Packages/UI';
import { LoginFlowSelectorOptions } from 'App/Modules/Screen/Modules/LoginFlowSelector/View/LoginFlowSelectorOptions';

export const LoginFlowSelector: React.FunctionComponent<Screen.Props<Screen.Type.LOGIN>> = () => (
    <Layout className="login-flow-selector-screen" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <UI.Logo.XploreTV className="login-flow-selector-screen__logo" />
        <LoginFlowSelectorOptions />
    </Layout>
);
