import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailGradientLeftProps = DivPropsExtendable;

export type DetailGradientLeftComponent = React.FunctionComponent<DetailGradientLeftProps>;

export const DetailGradientLeft: DetailGradientLeftComponent = (props) => {
    return <Div defaultClassName="detail__gradient detail__gradient--left" {...props} />;
};
