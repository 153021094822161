import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { valueForItemTitle } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/constants';
import { SettingsAboutAppItemWithSelector } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/SettingsAboutAppItemWithSelector';
import { SETTINGS_ABOUT_APP_TITLE } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/types';

export type SettingsAboutAppItemProps = {
    title: SETTINGS_ABOUT_APP_TITLE;
    withBottomSeparator?: boolean;
};

export const SettingsAboutAppItem: React.FC<SettingsAboutAppItemProps> = ({ title, withBottomSeparator = false }) => {
    const value = valueForItemTitle[title];

    return (
        <>
            <Settings.Separator />

            <Settings.Item id={title} ctx="" isDisabled={true} isSecondary>
                <Settings.Item.Title>{title}</Settings.Item.Title>
                <Settings.Item.Spacer></Settings.Item.Spacer>
                <Settings.Item.Value>
                    <SettingsAboutAppItemWithSelector selector={value} />
                </Settings.Item.Value>
            </Settings.Item>

            {withBottomSeparator && <Settings.Separator />}
        </>
    );
};
