import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';

import { Screen } from 'App/Modules/Screen/index';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { Header, Folder } from 'App/Modules/Screen/Modules/DetailSeriesScreen/View/Components';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Detail } from 'App/Modules/Data/Detail';

export const DetailSeriesScreenView: React.FunctionComponent<Screen.Props<Screen.Type.VodSeriesDetail>> = (props) => {
    const dispatch = useDispatch();

    const groupTitles = useSelector(Detail.Series.selectors.selectGroupingTitles);
    const className = `detail-series ${!groupTitles.length ? '' : 'detail-series--with-tabs'}`;

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <UI.Detail.ScreenContainer>
            <UI.Folder.List className={className}>
                <Header />
                <Folder initialFocusedItemId={props.initialFocusedItemId} />
            </UI.Folder.List>
        </UI.Detail.ScreenContainer>
    );
};
