import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error as ErrModule } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Folder } from 'App/Modules/Folder';
import { Api } from 'App/Modules/RootFolder/Api';
import { Constants } from 'App/Modules/RootFolder/Constants';

const loaded = Folder.actions.list.loaded(Constants.Hash);
const reset = Folder.actions.list.reset(Constants.Hash);

const load = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const config = selectConfig(state);
        const profileId = Profile.selectors.selectId(state);
        const thumbnailHeight = config.image.thumbnail.default.height;
        const items = config.layout.collectionMaxItems || Folder.Constants.List.Folders.Items;
        const variables: Api.LoadVariables = { profileId, thumbnailHeight, items };
        const response = await Api.load(variables);
        if (!response) throw new Error('Failed to load root folder');
        const data = Folder.Mapper.toFolderList(response);
        if (!data) throw new Error('Failed to load root folder');
        dispatch(loaded(data));
        return true;
    } catch (error) {
        dispatch(ErrModule.actions.occured(ErrModule.Dispatcher.LANGUAGE_LOAD, error));
        return false;
    }
};

export const actions = {
    public: {
        load,
        reset,
    },
    private: {
        loaded,
    },
} as const;
