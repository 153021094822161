import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import { FolderFragment } from 'App/Modules/Folder/Api/fragments/folder.generated';
import {
    FolderLoadDocument,
    FolderLoadFragment,
    FolderLoadQueryResult,
    FolderLoadQueryVariables,
} from 'App/Modules/Folder/Api/query/folderLoad.generated';

export const loadListById = () => {};

export const __parseLoadById = (data: FolderLoadFragment | null | undefined): FolderFragment | null => data?.contentFolder ?? null;

export const loadById = async (variables: FolderLoadQueryVariables): Promise<FolderFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: FolderLoadQueryResult = await GraphqlClient.makeGraphqlRequest(FolderLoadDocument, variables, options);
    Error.collect(response);
    return __parseLoadById(response.data);
};
