import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    MessageActionEntity,
    MessageAttachmentEntity,
    MessageEntity,
    MessageEntityNotificationInfo,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { Utils } from '__SMART_APP_OLD__/app/utils';

export const selectMessageTriggerTable = (state: State): Record<string, MessageEntityNotificationInfo> =>
    state.data.messageEntityTable.triggerTable;

export const selectMessage = (id: GQL.MessageID) => (state: State): MessageEntity | null => state.data.messageEntityTable.entities[id];

export const selectMessageHeader = (id: GQL.MessageID) => (state: State): string => selectMessage(id)(state)?.title ?? '_';

export const selectMessageText = (id: GQL.MessageID) => (state: State): string => selectMessage(id)(state)?.message ?? '_';

export const selectMessageLabel = (id: GQL.MessageID) => (state: State): string => selectMessage(id)(state)?.label ?? '_';

export const selectMessageValidFrom = (id: GQL.MessageID) => (state: State): number => selectMessage(id)(state)?.validFrom ?? -1;

export const selectMessageValidUntil = (id: GQL.MessageID) => (state: State): number => selectMessage(id)(state)?.validUntil ?? -1;

export const selectMessageImage = (id: GQL.MessageID) => (state: State): string | null => selectMessage(id)(state)?.image ?? null;

export const selectMessageIcon = (id: GQL.MessageID) => (state: State): string | null => selectMessage(id)(state)?.icon ?? null;

export const selectMessageAttachmentIds = (id: GQL.MessageID) => (state: State) => selectMessage(id)(state)?.attachments.ids ?? [];

export const selectMessageAttachment = (id: GQL.MessageID, attachmentId: GQL.MessageAttachmentID) => (
    state: State
): MessageAttachmentEntity | null => selectMessage(id)(state)?.attachments?.entities?.[attachmentId] ?? null;

export const selectIsMessageValid = (id: GQL.MessageID) => (state: State): boolean => {
    const now = Time.selectors.select(state);
    const validFrom = selectMessageValidFrom(id)(state);
    const validUntil = selectMessageValidUntil(id)(state);
    return now >= validFrom && now <= validUntil;
};

export const selectMessageIds = (state: State): GQL.MessageID[] =>
    state.data.messageEntityTable.ids.filter((id) => selectIsMessageValid(id)(state));

export const selectMessagesTotalCount = (state: State): number => selectMessageIds(state).length;

export const selectMessageActions = (id: GQL.MessageID) => (state: State): MessageActionEntity[] => selectMessage(id)(state)?.actions ?? [];

export const selectMessageStatus = (id: GQL.MessageID) => (state: State): GQL.MessageStatus =>
    state.data.messageEntityTable.statusEntityTable[id];

export const selectIsMessageUnread = (id: GQL.MessageID) => (state: State): boolean =>
    selectMessageStatus(id)(state) === GQL.MessageStatus.New;

export const selectMessageUnreadIds = (state: State) => selectMessageIds(state).filter((id) => selectIsMessageUnread(id)(state));

export const selectMessageUnreadCount = (state: State) => selectMessageUnreadIds(state).length;

export const selectMessageLabels = (state: State) =>
    Object.keys(selectMessageIds(state).reduce((map, id) => ({ ...map, [selectMessageLabel(id)(state)]: null }), {}));

export const selectMessageDate = (id: GQL.MessageID) => (state: State): string => {
    const time = Time.selectors.select(state);
    const ts = selectMessageValidFrom(id)(state);
    if (Utils.TS.isSameDay(ts, time)) return new Date(ts).toTimeString().slice(0, 5);
    if (Utils.TS.isTheDayBefore(ts, time)) return 'YESTERDAY';
    return new Date(ts).toLocaleDateString();
};

export const selectMessageNotifInfo = (id: GQL.MessageID) => (state: State) => state.data.messageEntityTable.triggerTable[id] ?? null;

export const selectMessageTriggerTS = (id: GQL.MessageID) => (state: State): number | null =>
    state.data.messageEntityTable.triggerTable?.[id]?.nextTriggerTS ?? null;

export const selectMessageEntityTableTriggerTSStack = (state: State): number[] =>
    selectMessageIds(state)
        .reduce<number[]>((timestamps, id) => {
            const triggerTS = selectMessageTriggerTS(id)(state);
            if (!triggerTS) return timestamps;
            if (!timestamps.includes(triggerTS)) timestamps.push(triggerTS);
            return timestamps;
        }, [])
        .sort((a, b) => a - b);

export const selectMessagesByTriggerTS = (triggerTS: number) => (state: State) =>
    selectMessageIds(state).filter((id) => selectMessageTriggerTS(id)(state) === triggerTS);

export const selectIsMessageScreenPermittedToShow = (screens: GQL.MessageNotificationDisplayInfoScreens[]) => {
    const [screen] = screens;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.All) return true;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.AllExceptPlayer && !window.location.pathname.includes('play')) return true;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.Home && window.location.pathname.includes('HOME')) return true;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.MyLibrary && window.location.pathname.includes('LIBRARY')) return true;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.OnDemand && window.location.pathname.includes('VIDEOSTORE')) return true;
    if (screen === GQL.MessageNotificationDisplayInfoScreens.Player && window.location.pathname.includes('play')) return true;
    return false;
};
