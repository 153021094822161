/* eslint-disable max-classes-per-file */

class AdsEngine {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public seekOverlappingAd(playedTime: number, seekedTime: number, isLive: boolean): null | any {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getUnwatchedAdEvent(playedTime: number, isLive: boolean): null | any {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getUnwatchedAdEventInDynamicContext(isRestarted: boolean, eventType: string, positionToCheck: number): any {
        return false;
    }
}

export class AdsScippingEngine {
    public static readonly Engine = AdsEngine;

    private static engine: AdsEngine = new AdsEngine();

    public static readonly initialize = (engine: AdsEngine): void => {
        AdsScippingEngine.engine = engine;
    };

    public static readonly seekOverlappingAd = (playedTime: number, seekedTime: number, isLive: boolean) =>
        AdsScippingEngine.engine.seekOverlappingAd(playedTime, seekedTime, isLive);

    public static readonly getUnwatchedAdEvent = (playedTime: number, isLive: boolean) =>
        AdsScippingEngine.engine.getUnwatchedAdEvent(playedTime, isLive);

    public static readonly getUnwatchedAdEventInDynamicContext = (isRestarted: boolean, eventType: string, positionToCheck: number) =>
        AdsScippingEngine.engine.getUnwatchedAdEventInDynamicContext(isRestarted, eventType, positionToCheck);
}
