import React from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectNowOnTVTitle } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/selectors';

export const NowOnTVHeader: React.FunctionComponent = () => {
    const title = useSelector(selectNowOnTVTitle);

    return (
        <Div className="now-on-tv__header" backgroundColor={BackgroundColor.PRIMARY}>
            <Text
                className="now-on-tv__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_3}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {title}
            </Text>
        </Div>
    );
};
