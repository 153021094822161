import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { GroupingInfos, SeriesDetailStateItems, SeriesState } from 'App/Modules/Data/Detail/Series/Types';

const selectVodSeriesState: Selector<SeriesState> = (state) => state.dataV2.detail.vodSeries;
const selectAsset: Selector<DetailItem | null> = (state) => selectVodSeriesState(state).item;

const selectAssetById =
    (itemId: string): Selector<DetailItem | null> =>
    (state) =>
        selectVodSeriesState(state).items?.entities[itemId] ?? null;
const selectItems: Selector<SeriesDetailStateItems | null> = (state) => selectVodSeriesState(state).items;
const selectAssetObjects: Selector<any> = (state) => selectItems(state)?.assetObjects;
const selectAssetObjectById =
    (itemId: string): Selector<any> =>
    (state) =>
        selectAssetObjects(state)[itemId];
const selectItemIdsForGroupingTitle: Selector<GroupingInfos | undefined> = (state) => selectVodSeriesState(state).itemIdsForGroupTitle;
const selectGroupingTitles: Selector<string[]> = (state) => selectVodSeriesState(state).groupTitles;
const selectSelectedGroupingTitle: Selector<string> = (state) => selectVodSeriesState(state).selectedGroupTitle;
const selectScreenBackgroundImage: Selector<string> = (state) => selectVodSeriesState(state).screenBackgroundImageUrl;
const selectGroupingInfoItemIds: Selector<string[]> = (state) => {
    const groupingInfos = selectItemIdsForGroupingTitle(state);
    const selectedGroup = selectSelectedGroupingTitle(state);
    return groupingInfos?.find((group) => group?.title === selectedGroup)?.itemIds ?? [];
};

const selectEpisodesBySeasonNumber =
    (seasonNumber: number): Selector<any[]> =>
    (state) => {
        const groupingData = selectItemIdsForGroupingTitle(state);
        const episodeIds = groupingData?.find((group) => group?.title === seasonNumber.toString())?.itemIds ?? [];
        return episodeIds.map((id: string) => selectAssetObjectById(id)(state));
    };

export const selectors = {
    selectAssetById,
    selectAsset,
    selectItems,
    selectAssetObjectById,
    selectItemIdsForGroupingTitle,
    selectGroupingTitles,
    selectSelectedGroupingTitle,
    selectScreenBackgroundImage,
    selectGroupingInfoItemIds,
    selectEpisodesBySeasonNumber,
};
