import { batch } from 'react-redux';

import { EPG_VISSIBLE_CHANNELS } from '__SMART_APP_OLD__/app/constants/epg';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectChannelLiveIndex } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { loadEventsForTVGuide } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { selectSelectedChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectEPGFutureLimit,
    selectEPGPastLimit,
    selectEvent,
    selectEventBy,
    selectEventIsLoading,
    selectEventIsNoInfo,
    selectEventIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    selectIsTVGuideLoading,
    selectTVGuideEvent,
    selectTVGuideEventId,
    selectTVGuideFirstChannelId,
    selectTVGuideFirstIndex,
    selectTVGuideFocusedIndex,
    selectTVGuideLastIndex,
    selectTVGuideStart,
    selectTVGuideTime,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import {
    TVGuideCTX,
    TVGuideFirstIndexChangedAction,
    TVGuideFocusedIndexChangedAction,
    TVGuideIsLoadingChangedAction,
    TVGuideStartChangedAction,
    TVGuideTimeChangedAction,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';
import { getPrimeTimeDate } from '__SMART_APP_OLD__/utils/TvGuideUtils';
import { getToTVGuideUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Key } from 'App/Modules/Key';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Calc } from 'App/Packages/Calc';
import { Direction } from 'App/Packages/Direction';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';

export const tvGuideFocusedIndexChanged = (index: number): TVGuideFocusedIndexChangedAction => ({
    type: ActionType.TV_GUIDE_FOCUSED_INDEX_CHANGED,
    payload: { index },
});

export const tvGuideFirstIndexChanged = (index: number): TVGuideFirstIndexChangedAction => ({
    type: ActionType.TV_GUIDE_FIRST_INDEX_CHANGED,
    payload: { index },
});

export const tvGuideTimeChanged = (time: number): TVGuideTimeChangedAction => ({
    type: ActionType.TV_GUIDE_TIME_CHANGED,
    payload: { time },
});

export const tvGuideStartChanged = (time: number): TVGuideStartChangedAction => ({
    type: ActionType.TV_GUIDE_START_CHANGED,
    payload: { time },
});

export const tvGuideIsLoadingChanged = (isLoading: boolean): TVGuideIsLoadingChangedAction => ({
    type: ActionType.TV_GUIDE_LOADING_STATUS_CHANGED,
    payload: { isLoading },
});

const tvGuideSetTimeCenteredInGrid =
    (time: number): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const past = selectEPGPastLimit(state);
        const future = selectEPGFutureLimit(state);
        const timeToSet = Calc.clamp(past, time, future - 1);
        const start = Calc.clamp(past, time - Time.HOUR_MS, future - 2 * Time.HOUR_MS);
        dispatch(tvGuideTimeChanged(Calc.round(timeToSet, Time.MINUTE_MS)));
        dispatch(tvGuideStartChanged(Calc.round(start, 30 * Time.MINUTE_MS)));
    };

const tvGuideSetTimeLimitedToEPGLimits =
    (time: number): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const past = selectEPGPastLimit(state);
        const future = selectEPGFutureLimit(state);
        const timeToSet = Calc.clamp(past, time, future);
        dispatch(tvGuideTimeChanged(Calc.round(timeToSet, Time.MINUTE_MS)));
    };
/* eslint-disable max-statements */
const tvGuideHorizontalKeyTriggered =
    (x: Direction.X): Thunk<void> =>
    (dispatch, getState): void => {
        const state = getState();
        const event = selectTVGuideEvent(state);
        if (!event) return dispatch(tvGuideSetTimeCenteredInGrid(Date.now()));
        if (event.isLoading) {
            const time = selectTVGuideTime(state);
            const timeToSet = Calc.clamp(event.start - Time.MINUTE_MS, time + Time.HOUR_MS * x, event.end);
            return dispatch(tvGuideSetTimeCenteredInGrid(timeToSet));
        }
        const [searchTime, equal] = x === 1 ? [event.end, EqualityCompare.START] : [event.start, EqualityCompare.END];
        const eventToFocus = selectEventBy(event.channelId, searchTime, { equal })(state);
        if (!eventToFocus) return undefined;
        const now = Date.now();
        if (eventToFocus.start <= now && eventToFocus.end > now) return dispatch(tvGuideSetTimeCenteredInGrid(now));
        const start = selectTVGuideStart(state);
        const eventTimeTuple = [eventToFocus.start, eventToFocus.end - Time.MINUTE_MS];
        const [timeCloserToGridCenter, timeAwayFromGridCenter] = x === 1 ? eventTimeTuple : eventTimeTuple.reverse();
        if (eventToFocus.isLoading) {
            const shouldChangeStart =
                x === 1 ? timeCloserToGridCenter > start + Time.HOUR_MS : timeCloserToGridCenter < start + Time.HOUR_MS;
            if (shouldChangeStart) return dispatch(tvGuideSetTimeCenteredInGrid(timeCloserToGridCenter));
            return dispatch(tvGuideSetTimeLimitedToEPGLimits(timeCloserToGridCenter));
        }
        const isEventPartiallyInGrid = x === 1 ? eventToFocus.end > start + 2 * Time.HOUR_MS : eventToFocus.start < start;
        if (isEventPartiallyInGrid) return dispatch(tvGuideSetTimeCenteredInGrid(timeAwayFromGridCenter));
        return dispatch(tvGuideSetTimeLimitedToEPGLimits(timeCloserToGridCenter));
    };

const tvGuideVerticalKeyTriggered =
    (y: Direction.Y): Thunk<void> =>
    (dispatch, getState): void => {
        const state = getState();
        const channelIds = selectSelectedChannelListChannelIds(state);
        const focusedIndex = selectTVGuideFocusedIndex(state);
        const firstIndex = selectTVGuideFirstIndex(state);
        const updatedFocusedIndex = Utils.EPG.getIndexInRange(focusedIndex + y, channelIds.length);
        batch(() => {
            dispatch(tvGuideFocusedIndexChanged(updatedFocusedIndex));
            if (firstIndex === focusedIndex && y === -1) {
                dispatch(tvGuideFirstIndexChanged(updatedFocusedIndex));
                return;
            }
            const lastIndex = Utils.EPG.getIndexInRange(firstIndex + EPG_VISSIBLE_CHANNELS - 1, channelIds.length);
            if (lastIndex === focusedIndex && y === 1) {
                const updatedFirstIndex = Utils.EPG.getIndexInRange(firstIndex + 1, channelIds.length);
                dispatch(tvGuideFirstIndexChanged(updatedFirstIndex));
            }
        });
    };

const tvGuideGridArrowTriggered =
    ([x, y]: Direction.Tuple): Thunk<void> =>
    (dispatch): void => {
        if (x) return dispatch(tvGuideHorizontalKeyTriggered(x));
        if (y) return dispatch(tvGuideVerticalKeyTriggered(y));
        return undefined;
    };

const tvGuideTimelineArrowTriggered =
    ([, y]: Direction.Tuple): Thunk<void> =>
    (dispatch): void => {
        if (y === 1) dispatch(Screen.actions.ctx.changed(Screen.Type.TV_GUIDE, TVGuideCTX.GRID));
    };

const tvGuideArrowKeyTriggered =
    (key: number): Thunk<void> =>
    (dispatch, getState): void => {
        const state = getState();
        const ctx = Screen.selectors.selectCtx(state);
        if (ctx === TVGuideCTX.TIMELINE) return dispatch(tvGuideTimelineArrowTriggered(Direction.get(key)));
        if (ctx === TVGuideCTX.GRID) return dispatch(tvGuideGridArrowTriggered(Direction.get(key)));
        dispatch(Screen.actions.ctx.changed(Screen.Type.TV_GUIDE, TVGuideCTX.GRID));
        return undefined;
    };

const toDetailPage: Thunk<void> = (dispatch, getState) => {
    const state = getState();
    const eventId = selectTVGuideEventId(state);
    const screen = Screen.generator(Screen.Type.TV_GUIDE, state.tvGuide, TVGuideCTX.GRID);
    dispatch(History.actions.push({ screen, overlay: null }));
    dispatch(Screen.actions.unmount());
    dispatch(Screen.actions.mount(Screen.Type.Detail, { id: eventId, type: ContentItemType.Event }));
};

const tvGuideEnterTriggered = (): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const ctx = Screen.selectors.selectCtx(state);
    if (ctx === TVGuideCTX.TIMELINE) {
        dispatch(Overlay.actions.mount(Overlay.Type.SELECT, { target: Overlay.SelectTarget.DATE_PICKER }));
        return;
    }
    const eventId = selectTVGuideEventId(state);
    const isLoading = selectEventIsLoading(eventId)(state);
    const isNoInfo = selectEventIsNoInfo(eventId)(state);
    if (isLoading || isNoInfo) return;
    const isRestricted = selectEventIsRestricted(eventId, PinSessionType.PIN_LIVE_TV)(state);

    if (!isRestricted) {
        dispatch(toDetailPage);
        return;
    }

    dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));

    openPinOverlay(
        () => dispatch(toDetailPage),
        () => dispatch(Overlay.actions.unmount()),
        PinAction.ENTER,
        PinSessionType.PIN_LIVE_TV,
        false
    );
};

export const tvGuideBackTriggered = (): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const ctx = Screen.selectors.selectCtx(state);
    if (ctx === TVGuideCTX.TIMELINE) {
        dispatch(History.actions.pop());
        return;
    }
    dispatch(Screen.actions.ctx.changed(Screen.Type.TV_GUIDE, TVGuideCTX.TIMELINE));
};

export const tvGuideClickTriggered =
    (isFocused: boolean): Thunk<void> =>
    (dispatch) => {
        if (isFocused) {
            dispatch(tvGuideEnterTriggered());
        }
    };

export const tvGuideHoverTriggered =
    (eventId: string): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const event = selectEvent(eventId)(state);
        if (!event) return undefined;
        const channelIds = selectSelectedChannelListChannelIds(state);
        const focusedIndex = channelIds.indexOf(event.channelId);
        const firstIndex = selectTVGuideFirstIndex(state);
        const lastIndex = selectTVGuideLastIndex(state);
        if (focusedIndex === firstIndex) dispatch(tvGuideFirstIndexChanged(Utils.EPG.getIndexInRange(focusedIndex - 1, channelIds.length)));
        if (focusedIndex === lastIndex) dispatch(tvGuideFirstIndexChanged(Utils.EPG.getIndexInRange(firstIndex + 1, channelIds.length)));
        dispatch(tvGuideLoadData());
        dispatch(tvGuideFocusedIndexChanged(focusedIndex));
        const now = Date.now();
        if (event.start <= now && event.end > now) return dispatch(tvGuideSetTimeCenteredInGrid(now));
        const start = selectTVGuideStart(state);
        if (start >= event.start) return dispatch(tvGuideSetTimeCenteredInGrid(event.start));
        if (start + 2 * Time.HOUR_MS <= event.end) return dispatch(tvGuideSetTimeCenteredInGrid(event.end - Time.MINUTE_MS));
        return dispatch(tvGuideSetTimeLimitedToEPGLimits(event.start + (event.end - event.start) / 2));
    };

export const tvGuideKeyTriggered =
    (key: number): Thunk<void> =>
    (dispatch) => {
        switch (key) {
            case Key.VK_UP:
            case Key.VK_DOWN:
            case Key.VK_LEFT:
            case Key.VK_RIGHT:
                dispatch(tvGuideArrowKeyTriggered(key));
                break;
            case Key.VK_ENTER:
                dispatch(tvGuideEnterTriggered());
                break;
            case Key.VK_BACK:
                dispatch(tvGuideBackTriggered());
                break;
            default:
                break;
        }
    };

export const tvGuideLoadData = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    if (selectIsTVGuideLoading(state)) return;
    dispatch(tvGuideIsLoadingChanged(true));
    await dispatch(
        loadEventsForTVGuide(
            selectTVGuideFirstChannelId(state),
            selectTVGuideStart(state) - Time.HOUR_MS,
            selectTVGuideStart(state) + 3 * Time.HOUR_MS
        )
    );
    dispatch(tvGuideIsLoadingChanged(false));
};

const getTimeToSet = (option: ButtonEntity) => {
    if (option.label === translate('DAY_NAME_TODAY_NOW')) return Date.now();
    if (option.label === translate('DAY_NAME_TODAY_TONIGHT')) return getPrimeTimeDate(new Date()).getTime();
    return new Date(option.id).getTime();
};

export const tvGuideDatePickerOptionSelected =
    (option: ButtonEntity): Thunk<void> =>
    (dispatch) => {
        dispatch(tvGuideSetTimeCenteredInGrid(getTimeToSet(option)));
        dispatch(tvGuideLoadData());
    };

export const tvGuideSnackBarTriggered =
    (id: GQL.ChannelID): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const channelIds = selectSelectedChannelListChannelIds(state);
        const newFocusedIndex = channelIds.indexOf(id);
        if (newFocusedIndex === -1) return;
        const focusedIndex = selectTVGuideFocusedIndex(state);
        if (newFocusedIndex === focusedIndex) return;
        dispatch(tvGuideFocusedIndexChanged(newFocusedIndex));
        const firstIndex = selectTVGuideFirstIndex(state);
        const lastIndex = selectTVGuideLastIndex(state);
        if (
            (firstIndex < lastIndex && (newFocusedIndex > lastIndex || newFocusedIndex < firstIndex)) ||
            (firstIndex > lastIndex && newFocusedIndex > lastIndex && newFocusedIndex < firstIndex)
        ) {
            dispatch(tvGuideFirstIndexChanged(newFocusedIndex));
        }
        dispatch(tvGuideLoadData());
    };

export const toTvGuide =
    (data?: Partial<Screen.Props<Screen.Type.TV_GUIDE>>): Thunk<void> =>
    (dispatch, getState) => {
        LoggingService.getInstance().logEvent(getToTVGuideUIActionEvent());
        const state = getState();
        const focusedIndex = data?.focusedIndex ?? selectChannelLiveIndex(state);
        const firstIndex = data?.firstIndex ?? focusedIndex;
        const time = data?.time ?? Calc.round(Date.now(), Time.MINUTE_MS);
        const start = data?.start ?? Calc.round(time - Time.HOUR_MS, Time.MINUTE_MS * 30);
        dispatch(tvGuideFocusedIndexChanged(focusedIndex));
        dispatch(tvGuideFirstIndexChanged(firstIndex));
        dispatch(tvGuideTimeChanged(time));
        dispatch(tvGuideStartChanged(start));
        dispatch(tvGuideLoadData());
        dispatch(Screen.actions.mount(Screen.Type.TV_GUIDE, { focusedIndex, firstIndex, start, time }, TVGuideCTX.GRID));
    };
