import React from 'react';

import { ScreenProps, ScreenType } from 'App/Modules/Screen/Types';
import { Null } from 'App/Packages/UI/Null';
import { ScreenLifecycle } from './types';

export class Registry {
    private static readonly default: ScreenLifecycle = {
        outOfBounds: () => () => {},
        mount: () => async () => {},
        unmount: () => async () => {},
        update: () => async () => {},
    };

    private static readonly store: Partial<Record<ScreenType, [React.FunctionComponent<ScreenProps>, Partial<ScreenLifecycle>]>> = {};

    public static readonly get = <T extends ScreenType>(type: T): [React.FunctionComponent<ScreenProps<T>>, ScreenLifecycle<T>] => [
        (Registry.store[type]?.[0] as unknown as React.FunctionComponent<ScreenProps<T>>) ?? Null,
        (Registry.store[type]?.[1] as unknown as ScreenLifecycle<T>) ?? Registry.default,
    ];

    public static readonly register = <T extends ScreenType>(
        type: T,
        Component: React.FunctionComponent<ScreenProps<T>>,
        lifecycle: Partial<ScreenLifecycle<T>> = {}
    ) => {
        const FinalComponent: React.FunctionComponent<ScreenProps> = Component as unknown as React.FunctionComponent<ScreenProps>;
        const finalLifecycle: ScreenLifecycle = { ...Registry.default, ...lifecycle } as ScreenLifecycle;
        Registry.store[type] = [FinalComponent, finalLifecycle];
    };
}
