import { useEffect, useRef } from 'react';
import * as workerTimers from 'worker-timers';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIsLoggedIn } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';
import { messageSchedule } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { selectMessageEntityTableTriggerTSStack } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import { reminderSchedule } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import {
    selectReminderEntityTableTriggerTSStack,
    selectRemindersTriggerTSStackEqualityFn,
} from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { selectNotificationActiveStatus } from '__SMART_APP_OLD__/app/modules/Notification/selectors';

export const useReminderScheduling = () => {
    const triggerID = useRef<number | null>(null);
    const triggerTSStack = useSelector(selectReminderEntityTableTriggerTSStack, selectRemindersTriggerTSStackEqualityFn);
    const messageTriggerTSStack = useSelector(selectMessageEntityTableTriggerTSStack, selectRemindersTriggerTSStackEqualityFn);
    const isActive = useSelector(selectNotificationActiveStatus);
    const isLogged = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        const clearTrigger = () => {
            if (triggerID.current) {
                workerTimers.clearTimeout(triggerID.current);
                triggerID.current = null;
            }
        };
        if ((!triggerTSStack.length && !messageTriggerTSStack.length) || !isLogged || !isActive) {
            clearTrigger();
            return;
        }
        const getReadableTS = (ts: number) => new Date(ts).toLocaleTimeString();
        const getNextTrigger = (triggerTS: number): number | null => {
            const nextMsgTriggerTS = dispatch(messageSchedule(triggerTS));
            const nextNotifTriggerTS = dispatch(reminderSchedule(triggerTS));
            console.log('TRIGGER_TS -> TRIGGER: ', getReadableTS(triggerTS));
            if (!nextNotifTriggerTS && !nextMsgTriggerTS) return null;
            const nextTriggerTS =
                nextNotifTriggerTS && nextMsgTriggerTS
                    ? Math.min(nextNotifTriggerTS, nextMsgTriggerTS)
                    : nextNotifTriggerTS || nextMsgTriggerTS;
            console.log('TRIGGER_TS -> NEXT TRIGGER: ', getReadableTS(nextTriggerTS ?? 0));
            return nextTriggerTS;
        };
        const setTrigger = (triggerTS: number | null) => {
            clearTrigger();
            if (!triggerTS) return;
            const timeToTrigger = triggerTS - Date.now();
            if (timeToTrigger <= 0) {
                setTrigger(getNextTrigger(triggerTS));
                return;
            }
            console.log('TRIGGER_TS -> SET TIMER FOR: ', getReadableTS(triggerTS));
            triggerID.current = workerTimers.setTimeout(() => setTrigger(getNextTrigger(triggerTS)), timeToTrigger);
        };
        console.log('TRIGGER_TS -> STACK: ', triggerTSStack.map(getReadableTS));
        const ts = Math.min(messageTriggerTSStack[0] ?? Infinity, triggerTSStack[0] ?? Infinity);
        if (ts === Infinity) return;
        setTrigger(ts);
    }, [triggerTSStack, messageTriggerTSStack, isActive, isLogged, dispatch]);
};
