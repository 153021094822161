import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { StartupActionType, StartupChangedAction, StartupPosition } from '__SMART_APP_OLD__/app/modules/Startup/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const changedActionCreator = (position: StartupPosition): StartupChangedAction => ({
    type: StartupActionType.Changed,
    payload: { position },
});

const changed = (position: StartupPosition): Thunk<void> => (dispatch) => {
    Storage.set(Storage.Key.STARTUP_SCREEN, position);
    dispatch(changedActionCreator(position));
};

export const startupActions = { public: { changed } };
