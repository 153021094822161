import { HouseholdOnboardingInfo } from '__SMART_APP_OLD__/api/graphql/types';
import { API } from '__SMART_APP_OLD__/app/api';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { parentalRatingIdChange } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/actions';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import {
    LiveClockVisibilityChangedAction,
    MyLibraryAdultContentVisibilityChangeAction,
    ProfileActionType,
    ProfileInitialDataLoadAction,
    ProfileInitialDataPayload,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import { LoggingService } from 'analytics/loggingService';
import { TrackViewingBehaviour } from '../../TrackViewingBehaviour';

const liveClockChanged = (showLiveClock: boolean): LiveClockVisibilityChangedAction => ({
    type: ProfileActionType.SHOW_LIVE_CLOCK_CHANGED,
    payload: { showLiveClock },
});

const adultContentVisibilityChanged = (myLibraryAdultContentVisibility: boolean): MyLibraryAdultContentVisibilityChangeAction => ({
    type: ProfileActionType.MY_LIBRARY_ADULT_CONTENT_VISIBILITY_CHANGED,
    payload: { myLibraryAdultContentVisibility },
});

const initialLoad = (payload: ProfileInitialDataPayload): ProfileInitialDataLoadAction => ({
    type: ProfileActionType.PROFILE_INITIAL_DATA_LOAD,
    payload,
});

const reloadLoadProfileData: Thunk<Promise<void>> = async (dispatch) => {
    const loadMeResponse = await API.Init.loadMe();
    Preferences.audioLanguagePrimary = loadMeResponse.profilePreferences?.firstAudioLanguage;
    Preferences.audioLanguageSecondary = loadMeResponse.profilePreferences?.secondAudioLanguage;
    Preferences.subtitleLanguagePrimary = loadMeResponse.profilePreferences?.firstSubtitleLanguage;
    Preferences.subtitleLanguageSecondary = loadMeResponse.profilePreferences?.secondSubtitleLanguage;
    Storage.set(Storage.Key.USER_NAME, loadMeResponse.userName);
    Storage.set(Storage.Key.HOUSEHOLD_ID, loadMeResponse.householdId);
    Storage.set(Storage.Key.PARENTAL_RATING_RANK, loadMeResponse.parentalRatingRank);

    dispatch(
        initialLoad({
            userId: loadMeResponse.userId,
            profileId: loadMeResponse.profileId,
            userName: loadMeResponse.userName,
            masterPinCode: loadMeResponse.masterPinCode,
            profiles: loadMeResponse.profiles,
            dataUsageAllowed: loadMeResponse.dataUsageAllowed,
            preferences: loadMeResponse.profilePreferences!,
        })
    );

    dispatch(parentalRatingIdChange(loadMeResponse.parentalRatingId));
    dispatch(
        Household.actions.initialLoad({
            householdId: loadMeResponse.householdId,
            maxNumberOfConfirmedReplayChannels: loadMeResponse.maxNumberOfConfirmedReplayChannels,
            onboardingInfo: loadMeResponse.householdOnboardingInfo! as HouseholdOnboardingInfo,
        })
    );
    dispatch(TrackViewingBehaviour.actions.changed(loadMeResponse.dataUsageAllowed));
    dispatch(Household.actions.mqttBrokerUrlChanged(loadMeResponse.mqttBrokerUrl!));
    dispatch(Household.actions.mqttTopicsChanged(loadMeResponse.mqttTopics!));

    // start uar if allowed
    if (loadMeResponse.dataUsageAllowed) {
        LoggingService.getInstance().startLoggingSession();
        console.log('STARTING LOGGING SESSION');
    } else {
        console.log('CLOSING LOGGING SESSION');
        LoggingService.getInstance().closeLoggingSession();
    }
};

const myLibraryAdultContentVisibilityChanged =
    (myLibraryAdultContentVisibility: boolean): Thunk<void> =>
    (dispatch, getState) => {
        const adultContentVisibility = profileSelectors.public.selectMyLibraryAdultContentVisibility(getState());
        if (adultContentVisibility === myLibraryAdultContentVisibility) return;
        Storage.set(Storage.Key.MY_LIBRARY_ADULT_CONTENT_VISIBILITY, myLibraryAdultContentVisibility);
        dispatch(adultContentVisibilityChanged(myLibraryAdultContentVisibility));
    };

const showLiveClockChanged =
    (showLiveClock: boolean): Thunk<void> =>
    async (dispatch) => {
        try {
            Storage.set(Storage.Key.SHOW_LIVE_CLOCK, showLiveClock);
            dispatch(liveClockChanged(showLiveClock));
        } catch (error) {
            console.error(error);
        }
    };

export const profileActions = {
    public: {
        myLibraryAdultContentVisibilityChanged,
        showLiveClockChanged,
        reloadLoadProfileData,
        initialLoad,
    },
};
