import React from 'react';

const Default: CardBannerValue = {
    isFocused: false,
};

export type CardBannerValue = {
    isFocused: boolean;
};

export type CardBannerContextModule = React.Context<CardBannerValue> & { Default: CardBannerValue };

export const CardBannerContext: CardBannerContextModule = Object.assign(React.createContext(Default), { Default });
