import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { RecordingManagementActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/RecordingManagementActions';
import { selectRecordingManagementTitle } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';

export const RecordingManagementHeader: React.FunctionComponent = () => {
    const title = useSelector(selectRecordingManagementTitle);

    return (
        <div className="recording-management__header">
            <Text
                className="recording-management__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_3}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {title}
            </Text>
            <div className="recording-management__actions">
                <RecordingManagementActions />
            </div>
        </div>
    );
};
