import React from 'react';

import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';

const getClassName = (isLarge: boolean) => {
    const classlist = ['portrait-card-v3__main'];
    if (isLarge) classlist.push('portrait-card-v3__main--large');
    return classlist.join(' ');
};

export type CardPortraitMainProps = DivPropsExtendable & {};

export type CardPortraitMainComponent = React.FunctionComponent<CardPortraitMainProps>;

export const CardPortraitMain: CardPortraitMainComponent = (props) => {
    const card = useCardPortrait();
    return <Div {...props} defaultClassName={getClassName(card.isLarge)} />;
};
