import { useEffect } from 'react';

import { Function } from 'App/Packages/Function';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Header/Constants';
import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemFocused = (item: string) => Subscribable.Value<boolean>;

export const useHeaderItemFocused: UseHeaderItemFocused = (item) => {
    const headerItems = HeaderItems.use();
    const focused = Subscribable.useValue(false);

    useEffect(() => {
        const isFocused = () => !headerItems.disabled.get() && headerItems.focused.get() === item;

        const update = async () => {
            if (!isFocused()) return focused.set(false);
            await Function.sleep(Constants.Item.Focus.Delay);
            focused.set(isFocused());
            return undefined;
        };

        update();

        const unsubscribers = [headerItems.focused.subscribe(update), headerItems.disabled.subscribe(update)];

        return () => Function.call(...unsubscribers);
    }, [item, focused, headerItems]);

    return focused;
};
