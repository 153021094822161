import React from 'react';

export type AlertContextState = { onClose: React.MutableRefObject<() => void> };

export type AlertContextModule = {
    Provider: React.FunctionComponent<React.PropsWithChildren<AlertContextState>>;
} & Omit<React.Context<AlertContextState>, 'Provider'>;

const AlertContextInternal = React.createContext<AlertContextState>(null as unknown as AlertContextState);

const AlertContextProvider = AlertContextInternal.Provider as React.FunctionComponent<
    React.PropsWithChildren<{ value: AlertContextState }>
>;

const AlertProvider: React.FunctionComponent<React.PropsWithChildren<AlertContextState>> = (props) => (
    <AlertContextProvider value={props}>{props.children}</AlertContextProvider>
);

export const AlertContext: AlertContextModule = Object.assign(AlertContextInternal, {
    Provider: AlertProvider,
});

export const useAlertOnClose = () => React.useContext(AlertContextInternal).onClose;
