import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export const enum MessageDetailCTX {
    ATTACHMENT = 'ATTACHMENT',
    ACTION = 'ACTION',
    SCROLL = 'SCROLL',
}

export const enum MessageDetailKnownActionId {
    FULLSCREEN = 'FULLSCREEN',
    CLOSE = 'CLOSE',
}

// --------------------- State ----------------------

export const enum MessageDetailContainerType {
    TEXT = 'TEXT',
    SCROLL = 'SCROLL',
}

export const enum MessageDetailLayoutType {
    PLAYER_PREVIW = 'PLAYER_PREVIW',
    ATTACHMENT = 'ATTACHMENT',
    DETAIL = 'DETAIL',
    OBSERVER = 'OBSERVER',
}

export const enum MessageDetailAttachmentViewStatus {
    VISIBLE = 'VISIBLE',
    HIDDEN = 'HIDDEN',
}

export const enum MessageDetailPlayerStatus {
    OFF = 'OFF',
    PLAYING = 'PLAYING',
    ERROR = 'ERROR',
    LOADING = 'LOADING',
}

export type MessageDetailTextContainer = { type: MessageDetailContainerType.TEXT; index: number; from: number; to: number };
export type MessageDetailScrollContainer = { type: MessageDetailContainerType.SCROLL; from: number; to: number };

export type MessageDetailContainer = MessageDetailTextContainer | MessageDetailScrollContainer;

export type MessageDetailState = {
    layout: MessageDetailLayoutType;
    activeAttachmentId: GQL.MessageAttachmentID | null;
    header: 1 | 2;
    textLayout: MessageDetailContainer[];
    visibleTextContainerIndex: number;
    playerStatus: MessageDetailPlayerStatus;
    attachmentViewStatus: MessageDetailAttachmentViewStatus;
};

// --------------------- State ----------------------

// -------------------- Actions ---------------------

export type MessageDetailLayoutChangeActionPayload = { layout: MessageDetailLayoutType };
export type MessageDetailLayoutChangeAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_LAYOUT_CHANGE,
    MessageDetailLayoutChangeActionPayload
>;

export type MessageDetailActiveAttachmentIdChangeActionPayload = { activeAttachmentId: GQL.VODAssetID | null };
export type MessageDetailActiveAttachmentIdChangeAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_ACTIVE_ATTACHMENT_CHANGE,
    MessageDetailActiveAttachmentIdChangeActionPayload
>;

export type MessageDetailTextLayoutChangeActionPayload = { textLayout: MessageDetailContainer[] };
export type MessageDetailTextLayoutChangeAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_TEXT_LAYOUT_CHANGE,
    MessageDetailTextLayoutChangeActionPayload
>;

export type MessageDetailVisibleTextContainerIndexChangeActionPayload = { visibleTextContainerIndex: number };
export type MessageDetailVisibleTextContainerIndexChangeAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_VISIBLE_TEXT_CONTAINER_INDEX_CHANGE,
    MessageDetailVisibleTextContainerIndexChangeActionPayload
>;

export type MessageDetailHeaderChangeActionPayload = { header: 1 | 2 };
export type MessageDetailHeaderChangeAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_HEADER_CHANGE,
    MessageDetailHeaderChangeActionPayload
>;

export type MessageDetailPlayerStatusChangedActionPayload = { status: MessageDetailPlayerStatus };
export type MessageDetailPlayerStatusChangedAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_PLAYER_STATUS_CHANGE,
    MessageDetailPlayerStatusChangedActionPayload
>;

export type MessageDetailAttachmentStatusChangedActionPayload = { status: MessageDetailAttachmentViewStatus };
export type MessageDetailAttachmentStatusChangedAction = PayloadedAction<
    ActionType.MESSAGE_DETAIL_ATTACHMENT_STATUS_CHANGE,
    MessageDetailAttachmentStatusChangedActionPayload
>;

export type MessageDetailAction =
    | MessageDetailLayoutChangeAction
    | MessageDetailHeaderChangeAction
    | MessageDetailActiveAttachmentIdChangeAction
    | MessageDetailTextLayoutChangeAction
    | MessageDetailPlayerStatusChangedAction
    | MessageDetailAttachmentStatusChangedAction
    | MessageDetailVisibleTextContainerIndexChangeAction;

// --------------------------------------------------
