import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import {
    selectChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    channelNotInActiveChannelListNotificationShow,
    channelNotSubscribedNotificationShow,
} from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { DeepLink } from 'App/Packages/DeepLink';

const mount =
    (props: Screen.Props<Screen.Type.COLLECTION>): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
        await dispatch(Collection.actions.load(props.id));
        dispatch(Overlay.actions.unmount());
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

const toDetailPage =
    (itemId: string): Thunk<void> =>
    (dispatch, getState) => {
        const containerId = Collection.selectors.selectId(getState());
        const itemType = Collection.selectors.selectEntityType(itemId)(getState());
        const screen = Screen.generator(Screen.Type.COLLECTION, { id: containerId, focused: itemId ?? null });
        dispatch(History.actions.push({ screen, overlay: null }));
        dispatch(Screen.actions.unmount());
        switch (itemType) {
            case ContentItemType.Event:
                return dispatch(
                    Screen.actions.mount(Screen.Type.Detail, {
                        id: itemId,
                        type: ContentItemType.Event,
                    })
                );
            // return CustomHistory.go(`/details/${AssetType.EVENT}/${itemId}`); -- migrated
            case ContentItemType.Networkrecording:
                return dispatch(
                    Screen.actions.mount(Screen.Type.Detail, {
                        id: itemId,
                        type: ContentItemType.Networkrecording,
                    })
                );
            case ContentItemType.Vodasset:
                return dispatch(
                    Screen.actions.mount(Screen.Type.Detail, {
                        id: itemId,
                        type: ContentItemType.Vodasset,
                    })
                );
            // return CustomHistory.go(`/details/${AssetType.VOD_ASSET}/${itemId}`); -- migrated
            case ContentItemType.Vodseries:
                // return CustomHistory.go(`/details/${AssetType.VOD_SERIES}/${itemId}`);
                // -- migrated
                return dispatch(
                    Screen.actions.mount(Screen.Type.Detail, {
                        id: itemId,
                        type: ContentItemType.Vodseries,
                    })
                );
            case ContentItemType.Series:
                return dispatch(
                    Screen.actions.mount(Screen.Type.Detail, {
                        id: itemId,
                        type: ContentItemType.Series,
                    })
                );
            case ContentItemType.Vodfolder:
                return CustomHistory.go(`/page/${Route.VOD_FOLDER}/${itemId}`);
            default:
                return '';
        }
    };

const handleChannelEnter =
    (itemId: string, entityType: ContentItemType): Thunk<void> =>
    (dispatch, getState) => {
        const channelListId = selectSelectedChannelListId(getState());
        const channelIds = selectChannelListChannelIds(channelListId)(getState());
        const isChannelInChannelList = channelIds.find((channelId) => channelId === itemId);
        const assetType = assetTypeForContentItemType[entityType];
        const channelUserInfo = Collection.selectors.selectEntityUserInfo(itemId)(getState());
        const title = Collection.selectors.selectEntityTitle(itemId)(getState());

        if (channelUserInfo?.subscribed) {
            if (isChannelInChannelList) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id: itemId }));
                CustomHistory.go(`/playChannel/${itemId}`);
            } else {
                dispatch(channelNotInActiveChannelListNotificationShow('NOT_IN_ACTIVE_CHANNEL_LIST', NotificationIconType.INFO, title));
            }
        } else {
            dispatch(channelNotSubscribedNotificationShow('UNSUBSCRIBED_PLAYER_INFO_TEXT', NotificationIconType.INFO, title ?? ''));
        }
    };

const enterTriggered =
    (itemId: string, entityType: ContentItemType | null): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const bannerLink = Collection.selectors.selectBannerEntityLink(itemId)(state);

        if (entityType === ContentItemType.Channel) {
            dispatch(handleChannelEnter(itemId, entityType));
        }

        const action =
            entityType === ContentItemType.Banner
                ? () => dispatch(DeepLink.executeActionFromLink(bannerLink ?? ''))
                : () => dispatch(toDetailPage(itemId));

        const isRestricted = Collection.selectors.selectIsEntityRestricted(itemId)(state);

        if (!isRestricted) {
            action();
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(action, () => dispatch(Overlay.actions.unmount()));
    };

export const actions = {
    public: {
        enterTriggered,
    },
    private: {},
    lifecycle: {
        mount,
        unmount,
    },
};
