import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

export type SpinnerProps = {
    className?: string;
};

const classNameForThemeType: Record<string, string> = {
    DARK: 'spinner--dark-theme',
    LIGHT: 'spinner--light-theme',
};

const getClassName = (theme: Theme.Type, className?: string) => {
    const classList = ['spinner'];
    classList.push(classNameForThemeType[theme]);
    if (className) classList.push(className);
    return classList.join(' ');
};

export const Spinner: React.FunctionComponent<SpinnerProps> = (props) => {
    const theme = useTheme();
    const className = getClassName(theme, props.className);
    return <div className={className} style={{}} />;
};
