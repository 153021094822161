import { Result } from 'App/Modules/Data/Search/Result';
import { Suggestion } from 'App/Modules/Data/Search/Suggestion';
import { combineReducers } from 'redux';

export { Suggestion, Result };

export type State = {
    suggestions: Suggestion.State;
    results: Result.State;
};

export type ReduxAction = Suggestion.ReduxAction;

export const initialState: State = {
    suggestions: Suggestion.initialState,
    results: Result.initialState,
};

export const getInitialState = (): State => ({
    ...initialState,
    suggestions: Suggestion.getInitialState(),
    results: Result.getInitialState(),
});

export const reducer = combineReducers<State>({
    suggestions: Suggestion.reducer,
    results: Result.reducer,
});
