import { ProductKind, VodAsset } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum VodUpsellCTX {
    LEFT_COLUMN = 'LEFT_COLUMN',
    RIGHT_COLUMN = 'RIGHT_COLUMN',
    ONE_COLUMN = 'ONE_COLUMN',
}

export enum VideoQuality {
    UHD = 0,
    HD = 1,
    SD = 2,
}

export interface ProductOption extends GQL.VodProductEntitlement {
    videoQuality: string;
}

export type VodUpsellOption = {
    id: string;
    label: string;
    videoQuality: string;
};

/**
 * @namespace VodUpsell
 */
export type VodUpsellEntityTable = {
    assetId: GQL.VODAssetID;
    asset: VodAsset | null;
    temporaryEntitlement: GQL.VODAssetEntitlement | null;
    productOptions: ProductOption[];
    productKind: ProductKind | null;
    isPurchaseOn: boolean;
    entitlementId: string | null;
};

export type ResetVoUpsellStatePayload = null;
export type SetCurrentVodAssetObjectPayload = { asset: object };
export type SetProductsActionPayload = { products: ProductOption[] };
export type SelectProductActionPayload = { product: GQL.ProductEntitlementID };
export type SetVodAssetActionPayload = { assetId: GQL.ProductID };
export type SetVodUpsellOperationTypePayload = { productKind: ProductKind };
export type SetUpsellVodOptionPayload = { entitlementId: string };
export type SetUpsellTemporaryEntitlementPayload = { entitlement: GQL.VODAssetEntitlement };
export type VodTransactionPayload = { isPurchaseOn: boolean };

export type VodUpsellSetProductsAction = PayloadedAction<ActionType.VOD_UPSELL_SET_PRODUCTS, SetProductsActionPayload>;
export type VodUpsellSetPackageProductsAction = PayloadedAction<ActionType.VOD_UPSELL_SET_PACKAGE_PRODUCTS, SetProductsActionPayload>;
export type VodUpsellSelectProductAction = PayloadedAction<ActionType.VOD_UPSELL_SELECT_PRODUCT, SelectProductActionPayload>;
export type VodUpsellSetVodAssetAction = PayloadedAction<ActionType.VOD_UPSELL_SET_ASSET_ID_AND_TYPE, SetVodAssetActionPayload>;
export type VodUpsellSetOperationTypeAction = PayloadedAction<ActionType.VOD_UPSELL_SET_OPERATION_TYPE, SetVodUpsellOperationTypePayload>;
export type VodUpsellOptionSelectedAction = PayloadedAction<ActionType.VOD_UPSELL_UPSELL_OPTION_SELECTED, SetUpsellVodOptionPayload>;
export type StartVodUpsellTransactionAction = PayloadedAction<ActionType.VOD_UPSELL_START_UPSELL_TRANSACTION, VodTransactionPayload>;
export type FinishVodUpsellTransactionAction = PayloadedAction<ActionType.VOD_UPSELL_FINISH_UPSELL_TRANSACTION, VodTransactionPayload>;
export type VodUpsellSetTemporaryEntitlementAction = PayloadedAction<
    ActionType.VOD_UPSELL_SET_TEMPORARY_ENTITLEMENT,
    SetUpsellTemporaryEntitlementPayload
>;
export type VodUpsellSetCurrentAssetAction = PayloadedAction<ActionType.VOD_UPSELL_SET_CURRENT_ASSET, SetCurrentVodAssetObjectPayload>;
export type VodUpsellResetStateAction = PayloadedAction<ActionType.VOD_UPSELL_CLEAN_UP_ON_USER_LEAVE, ResetVoUpsellStatePayload>;

export type VodUpsellReduxAction =
    | VodUpsellSetProductsAction
    | VodUpsellSelectProductAction
    | VodUpsellSetVodAssetAction
    | VodUpsellSetOperationTypeAction
    | VodUpsellOptionSelectedAction
    | StartVodUpsellTransactionAction
    | FinishVodUpsellTransactionAction
    | VodUpsellSetTemporaryEntitlementAction
    | VodUpsellSetCurrentAssetAction
    | VodUpsellResetStateAction;
