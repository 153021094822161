import React from 'react';

import { Button, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';

import { Focus } from 'App/Packages/Focus';

type Props = {
    onEnter?: () => void;
}

export type SettingsHeaderActionModule = React.FunctionComponent<Props>;

export const SettingsHeaderAction: SettingsHeaderActionModule = (props) => {
    const { onEnter } = props;

    const dispatch = useDispatch();

    const { isFocused, onHover } = Focus.useElement({ ctx: SettingsCtx.Back });

    const handleBack = () => onEnter ? onEnter() : dispatch(History.actions.pop());

    return (
        <Button
            className="settings-header__action"
            id={SettingsCtx.Back}
            ctx={SettingsCtx.Back}
            isFocused={isFocused}
            icon={IconName.LEFT}
            corners={ButtonCornersStyle.ROUNDED}
            onEnter={handleBack}
            onHover={onHover}
        />
    );
};
