import React, { useCallback } from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { List, ListItemType, ListRenderer } from '__SMART_APP_OLD__/app/components/List';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectParentalRatingDescription,
    selectParentalRatingId,
    selectParentalRatingIds,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/AgeRatingSelectionScreen/actions';
import { AgeRatingSelectionScreenItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/AgeRatingSelectionScreen/AgeRatingSelectionScreenItem';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const AgeRatingSelectionScreenItems: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const selected = useSelector(selectParentalRatingId);
    const items = useSelector(selectParentalRatingIds);

    const { focused, focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.X,
        items,
        initialFocusedIndex: () => items.indexOf(selected),
    });

    const description = useSelector(selectParentalRatingDescription(focused));

    const firstVisible = focusedIndex - 2;

    const selectHandler = useCallback((id: string) => dispatch(actions.private.select(id)), [dispatch]);

    const renderItem: ListRenderer<string> = (id) => (
        <AgeRatingSelectionScreenItem
            key={id}
            id={id}
            ctx={Focus.Default}
            isFocused={focused === id && !isDisabled}
            onHover={focus}
            onEnter={selectHandler}
        />
    );

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <>
            {focusedIndex !== 0 && (
                <Div className="age-rating-selection__guard age-rating-selection__guard--left" backgroundColor={BackgroundColor.PRIMARY} />
            )}
            {focusedIndex !== items.length - 1 && (
                <Div className="age-rating-selection__guard age-rating-selection__guard--right" backgroundColor={BackgroundColor.PRIMARY} />
            )}
            <List
                className="age-rating-selection__items"
                items={items}
                itemType={ListItemType.AGE_RATING_ITEM}
                firstVisibleIndex={firstVisible}
                renderer={renderItem}
            />
            <Text
                className="age-rating-selection__description"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                {description}
            </Text>
        </>
    );
};
