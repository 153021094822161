import { useMemo, useRef } from 'react';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { Folder } from 'App/Packages/UI/Folder/Modules/Folder';
import { FolderItemValue } from 'App/Packages/UI/Folder/Modules/FolderItem/Context/FolderItemContext';
import { FolderItemsValue } from 'App/Packages/UI/Folder/Modules/FolderItems/Context/FolderItemsContext';
import { useFolderItemsEnter } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/Private/useFolderItemsEnter';
import { useFolderItemsFocus } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/Private/useFolderItemsFocus';
import { useFolderItemsFocusInitial } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/Private/useFolderItemsFocusIntial';
import { useFolderItemsIsDisabled } from 'App/Packages/UI/Folder/Modules/FolderItems/Hooks/Private/useFolderItemsIsDisabled';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';

export type UseFolderItemsValuePayload = {
    id?: string;
    ctx?: string;
    initial?: string;
    disabled?: boolean;
    offset?: number;
    enter?: (id: string) => void;
};

export type UseFolderItemsValue = (payload: UseFolderItemsValuePayload) => FolderItemsValue;

export const useFolderItemsValue: UseFolderItemsValue = (payload) => {
    const list = FolderList.use();
    const folder = Folder.use();
    const ref = useRef<HTMLDivElement>(null);
    const id = payload.id ?? folder.id;
    const ctx = payload.ctx ?? folder.ctx;
    const ids = Subscribable.useArray<string>([]);
    const store = Subscribable.useRecord<FolderItemValue>({});
    const axis = Constants.Items.Axis;
    const items = Subscribable.use(ids);
    const isDisabled = useFolderItemsIsDisabled(id);
    const { guard } = Constants.Items;
    const navigation = useListHook({ axis, ctx, items, disabled: isDisabled, guard });
    const focused = Subscribable.useValue<string>(navigation.focused);
    const disabled = Subscribable.useValue<boolean>(navigation.isDisabled);
    const offset = payload.offset ?? Constants.Items.Scroll.Offset;
    const focus = useFolderItemsFocus(navigation.focus);
    const __focus = navigation.focus;
    const enter = useFolderItemsEnter(payload.enter ?? list.enter);
    const scroll = useMemo(() => new Scroll(ref), [ref]);

    Subscribable.useSetter(focused, navigation.focused);
    Subscribable.useSetter(disabled, navigation.isDisabled);
    Subscribable.useRegister(list.ids, id);

    useFolderItemsFocusInitial(store, payload.initial);

    return useMemo<FolderItemsValue>(
        () => ({ id, ref, ctx, ids, store, focused, disabled, offset, scroll, focus, __focus, enter }),
        [id, ref, ctx, ids, focused, store, disabled, offset, scroll, focus, __focus, enter]
    );
};
