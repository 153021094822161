import LandscapeCard from './LandscapeCard';
import { AssetType } from '../utils/Constants';

export default class VODProduct extends LandscapeCard {
    private _assetType: AssetType = AssetType.VOD_PRODUCT;

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);
        this.rawData = data;
    }

    get genre() {
        return this.rawData?.genre?.title;
    }

    get detailPageMetaInfo() {
        const metaInfo = [];

        if (this.genre) {
            metaInfo.push(this.genre);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get description() {
        return this.rawData?.fullDescription;
    }

    get assetType() {
        return this._assetType;
    }
}
