import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Prompt } from 'App/Modules/Prompt';
import { actions } from 'App/Modules/Prompt/Modules/MessageConfirmDeletePrompt/Store/actions';
import { selectors } from 'App/Modules/Prompt/Modules/MessageConfirmDeletePrompt/Store/selectors';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

const actionsIds = [Translation.Key.Remove, Translation.Key.Cancel];

export const View: React.FunctionComponent<Prompt.Props<Prompt.Type.MessageConfirmDelete>> = (props) => {
    const dispatch = useDispatch();
    const title = useSelector(selectors.private.selectTitle(props.messageIds.length));

    return (
        <UI.Alert zIndex={Prompt.zIndex} onClose={() => dispatch(Prompt.actions.unmount())}>
            <UI.Alert.Title>{title}</UI.Alert.Title>
            <UI.Alert.Text>{Translation.Key.Prompt.MessageConfirmDelete.Text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action id={Translation.Key.Remove} onEnter={() => dispatch(actions.private.remove(props.messageIds))}>
                    {Translation.Key.Remove}
                </UI.Alert.Action>
                <UI.Alert.Action id={Translation.Key.Cancel} onEnter={() => dispatch(actions.private.cancel())}>
                    {Translation.Key.Cancel}
                </UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
