import { Search } from 'App/Modules/Data/Search';
import { combineReducers } from 'redux';
import { Detail } from 'App/Modules/Data/Detail';

export { Search, Detail };

export type State = {
    search: Search.State;
    detail: Detail.State;
};

export type ReduxAction = Search.ReduxAction | Detail.ReduxAction;

export const initialState: State = {
    search: Search.initialState,
    detail: Detail.initialState,
};

export const getInitialState = (): State => ({
    ...initialState,
    search: Search.getInitialState(),
    detail: Detail.initialState,
});

export const reducer = combineReducers<State>({
    search: Search.reducer,
    detail: Detail.reducer,
});
