import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { SettingsLanguageElementType } from '__SMART_APP_OLD__/app/types';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === SettingsCtx.PrimaryList && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.LANGUAGE_PREFERENCES, SettingsCtx.Back));
    }
    if (ctx === SettingsCtx.Back && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.LANGUAGE_PREFERENCES, SettingsCtx.PrimaryList));
    }
    return undefined;
};

const getOverlayTargetFromId = (id: string): Overlay.SelectTarget | null => {
    switch (id) {
        case SettingsLanguageElementType.UI:
            return Overlay.SelectTarget.LANGUAGE;
        case SettingsLanguageElementType.AUDIO_PRIMARY:
            return Overlay.SelectTarget.AUDIO_PRIMARY;
        case SettingsLanguageElementType.AUDIO_SECONDARY:
            return Overlay.SelectTarget.AUDIO_SECONDARY;
        case SettingsLanguageElementType.SUBTITLE_PRIMARY:
            return Overlay.SelectTarget.SUBTITLE_PRIMARY;
        case SettingsLanguageElementType.SUBTITLE_SECONDARY:
            return Overlay.SelectTarget.SUBTITLE_SECONDARY;
        default:
            return null;
    }
};

const enter =
    (id: string): Thunk<void> =>
    (dispatch) => {
        const target = getOverlayTargetFromId(id);
        if (!target) return;
        dispatch(Overlay.actions.mount(Overlay.Type.SELECT, { target }));
    };

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.LANGUAGE_PREFERENCES, SettingsCtx.PrimaryList));
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    public: {},
    private: {
        enter,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
} as const;
