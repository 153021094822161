import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelDefaultNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';

import { UseListReturnType as FocusUseListReturnType } from 'App/Packages/Focus/Hooks/Types';

export enum ChannelListsScreenManageChannelsItemNumberType {
    DEFAULT = 'DEFAULT',
    SEQUENCE = 'SEQUENCE',
}

type ChannelListsScreenManageChannelsItemProps = {
    id: string;
    index: number;
    ctx: string;
    navigation: FocusUseListReturnType<string>;
    numberType?: ChannelListsScreenManageChannelsItemNumberType;
    isChecked?: boolean;
    isArranging?: boolean;
    onEnter: (channelId: string) => void;
    onFocus: () => void;
};

export const ChannelListsScreenManageChannelsItem: React.FunctionComponent<ChannelListsScreenManageChannelsItemProps> = (props) => {
    const {
        id,
        ctx,
        navigation,
        index,
        numberType = ChannelListsScreenManageChannelsItemNumberType.DEFAULT,
        isChecked,
        isArranging,
        onEnter,
        onFocus,
    } = props;
    const defaultNumber = useSelector(selectChannelDefaultNumber(props.id));
    const number = numberType === ChannelListsScreenManageChannelsItemNumberType.DEFAULT ? defaultNumber : index + 1;

    return (
        <div className="channel-lists-manage-channels-screen__item">
            {index === 0 && <Settings.Section.Content.Separator />}
            <Settings.Section.Content.Item
                id={id}
                ctx={ctx}
                isFocused={navigation.focused === id && !navigation.isDisabled}
                onEnter={onEnter}
                onFocus={onFocus}
                onHover={navigation.focus}
                isSecondary
            >
                <Settings.Section.Content.Item.ChannelNumber>{number}</Settings.Section.Content.Item.ChannelNumber>
                <Settings.Section.Content.Item.ChannelLogo />
                <Settings.Section.Content.Item.ChannelName />
                <Settings.Section.Content.Item.Spacer />
                {isChecked && <Settings.Section.Content.Item.Icon.Checked />}
                {navigation.focused === id && isArranging && <Settings.Section.Content.Item.Icon.Arrange />}
            </Settings.Section.Content.Item>
            <Settings.Section.Content.Separator />
        </div>
    );
};
