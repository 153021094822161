import React from 'react';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonTrailerProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonTrailerProps> = (props) => {
    return (
        <Folder.Detail.Action id="trailer" onEnter={props.onEnter}>
            SCREEN_DETAIL_TRAILER_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonTrailer = hidable(Button);

export type DetailButtonTrailerModule = typeof DetailButtonTrailer;
