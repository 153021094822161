import { FetchPolicy } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useGetProductDetailQuery } from '__SMART_APP_OLD__/api/graphql/query/getProductDetail.generated';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { BaseDetailPage } from '__SMART_APP_OLD__/components/details/BaseDetailPage';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { UI } from 'App/Packages/UI';

import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { setDefaultVariables } from '__SMART_APP_OLD__/components/details/detailUtils';

type PropType = {
    id: string;
};

const vodProductVariable = {
    variables: {
        productId: '',
        profileId: '',
        firstFolders: 20,
        firstItems: 30,
        lastItems: 15,
        thumbnailHeight: 280,
        backgroundWidth: 1920,
        backgroundHeight: 1080,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
        itemsAfterCursor: null,
        itemsBeforeCursor: null,
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

export const VodProductDetailPage: React.FC<PropType> = ({ id }): any => {
    const config = useSelector(selectConfig);
    const [vodPoduct, setVodProduct] = useState(null);
    const [relatedStripeData, setRelatedStripeData] = useState([]);
    const [loading, setLoading] = useState(true);

    const setDetailVariables = () => {
        setDefaultVariables(vodProductVariable, config);
        vodProductVariable.variables.productId = id;

        return vodProductVariable;
    };

    const getRelatedStripe = () =>
        relatedStripeData.map((stripe: any, index) => {
            const { assets } = stripe;

            return (
                <Stripe
                    stripeId={stripe.stripeId}
                    isRelated={true}
                    dataTestId="stripe_related"
                    key={`related-stripe-${index}`}
                    className={`stripe related`}
                    assets={assets}
                    title={stripe.title}
                    sessionType={PinSessionType.PIN}
                />
            );
        });

    const { data, loading: dataLoading, error } = useGetProductDetailQuery(setDetailVariables());

    useEffect(() => {
        if (data) {
            const parsedData = createAssetObject(data.vodProduct, data.vodProduct?.__typename);
            setVodProduct(parsedData);
            setRelatedStripeData(parsedData?.relatedContent);
            setLoading(dataLoading);
        }
    }, [data, dataLoading, error]);

    return loading ? (
        <Layout backgroundColor={BackgroundColor.PRIMARY}>
            <UI.Spinner />
        </Layout>
    ) : (
        <BaseDetailPage asset={vodPoduct} getRelatedStripe={getRelatedStripe} isFavouriteNeeded={false} sessionType={PinSessionType.PIN} />
    );
};
