import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type EventEntitlementsFragment = { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean };

export const EventEntitlementsFragmentDoc = gql`
    fragment eventEntitlements on EventEntitlements {
  id
  ppvTV
  pauseLiveTV
  restartTV
  catchupTV
  catchupTVAvailableUntil
  networkRecording
  networkRecordingPlannableUntil
  needsConcurrencyToken
  __typename
}
    `;