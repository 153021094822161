import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordingQuotaLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RecordingQuotaLoadQuery = { __typename?: 'Nexx4Queries', recordingQuota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null };

export type RecordingQuotaFragment = { __typename?: 'Nexx4Queries', recordingQuota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null };

export const RecordingQuotaFragmentDoc = gql`
    fragment recordingQuota on Nexx4Queries {
  recordingQuota {
    ...quota
  }
}
    ${QuotaFragmentDoc}`;
export const RecordingQuotaLoadDocument = gql`
    query recordingQuotaLoad {
  ...recordingQuota
}
    ${RecordingQuotaFragmentDoc}`;

/**
 * __useRecordingQuotaLoadQuery__
 *
 * To run a query within a React component, call `useRecordingQuotaLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingQuotaLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingQuotaLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecordingQuotaLoadQuery(baseOptions?: Apollo.QueryHookOptions<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>(RecordingQuotaLoadDocument, options);
      }
export function useRecordingQuotaLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>(RecordingQuotaLoadDocument, options);
        }
export function useRecordingQuotaLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>(RecordingQuotaLoadDocument, options);
        }
export type RecordingQuotaLoadQueryHookResult = ReturnType<typeof useRecordingQuotaLoadQuery>;
export type RecordingQuotaLoadLazyQueryHookResult = ReturnType<typeof useRecordingQuotaLoadLazyQuery>;
export type RecordingQuotaLoadSuspenseQueryHookResult = ReturnType<typeof useRecordingQuotaLoadSuspenseQuery>;
export type RecordingQuotaLoadQueryResult = Apollo.QueryResult<RecordingQuotaLoadQuery, RecordingQuotaLoadQueryVariables>;