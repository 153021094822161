import { Value } from 'App/Packages/Subscribable/Core/Value';
import { Register, UnRegister, UnRegisterer } from 'App/Packages/Subscribable/Types';

export class Array<T> extends Value<T[]> {
    public readonly register: Register<T> = this.__register.bind(this);

    public readonly unregister: UnRegister<T> = this.__unregister.bind(this);

    private __register(value: T): UnRegisterer {
        const state = this.get();
        if (!state.includes(value)) this.set(state.concat(value));
        return () => this.__unregister(value);
    }

    private __unregister(value: T): void {
        const state = this.get();
        const index = state.indexOf(value);
        if (index === -1) return;
        const next = state.slice();
        next.splice(index, 1);
        this.set(next);
    }
}
