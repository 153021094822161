import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { initialFolderListState, initialFolderState } from 'App/Modules/Folder/Store/constants';
import { Folder, FolderActionType, FolderList, FolderListActionType } from 'App/Modules/Folder/Types';

const folderReducer =
    (hash: string): Reducer<Folder> =>
    (state, action) => {
        if (!state) return initialFolderState;
        switch (action.type) {
            case FolderActionType.Loaded: {
                if (action.payload.hash !== hash) return state;
                if (!action.payload.data) return initialFolderState;
                return action.payload.data;
            }
            case FolderActionType.Reset: {
                if (action.payload.hash !== hash) return state;
                return initialFolderState;
            }
            default:
                return state;
        }
    };
const folderListReducer =
    (hash: string): Reducer<FolderList> =>
    (state, action) => {
        if (!state) return initialFolderListState;
        switch (action.type) {
            case FolderListActionType.Loaded: {
                if (action.payload.hash !== hash) return state;
                if (!action.payload.data) return initialFolderListState;
                return action.payload.data;
            }
            case FolderListActionType.Reset: {
                if (action.payload.hash !== hash) return state;
                return initialFolderListState;
            }
            default:
                return state;
        }
    };

export const reducer = Object.assign(folderReducer, { list: folderListReducer });
