import { Screen } from 'App/Modules/Screen';
import { Constants } from 'App/Modules/Screen/Modules/Search/Constants';
import { UI } from 'App/Packages/UI';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import React from 'react';

export type SearchLayoutProps = React.PropsWithChildren<{}>;

export const SearchLayout: React.FunctionComponent<SearchLayoutProps> = (props) => {
    const { children } = props;
    return (
        <Layout className="search" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <UI.Folder.List ctx={Constants.Ctx.Results} offsetTop={Constants.Offset.Top}>
                {children}
            </UI.Folder.List>
        </Layout>
    );
};
