import { useMemo, useRef } from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionValue } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Context/SuggestionContext';
import { useSuggestionEnter } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/Private/useSuggestionEnter';
import { useSuggestionFocus } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/Private/useSuggestionFocus';
import { useSuggestionFocused } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/Private/useSuggestionFocused';
import { useSuggestionScrollIntoView } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Hooks/Private/useSuggestionScrollIntoView';
import { SuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';

export type UseSuggestionValuePayload = {
    value: string;
    enter?: (value: string) => void;
    offset?: number;
};

export type UseSuggestionValue = (payload: UseSuggestionValuePayload) => SuggestionValue;

export const useSuggestionValue: UseSuggestionValue = (payload) => {
    const list = SuggestionList.use();
    const ref = useRef<HTMLDivElement>(null);
    const { value } = payload;
    const { ctx } = list;
    const focused = useSuggestionFocused(value);
    const enter = useSuggestionEnter(value, payload.enter);
    const focus = useSuggestionFocus(value);
    const scrollIntoView = useSuggestionScrollIntoView(ref, value, focused, payload.offset);

    const item = useMemo<SuggestionValue>(
        () => ({ ref, value, ctx, focused, enter, focus, scrollIntoView }),
        [ref, value, ctx, focused, enter, focus, scrollIntoView]
    );

    Subscribable.useRegister(list.values, value);
    Subscribable.useAdd(list.store, item.value, item);

    return item;
};
