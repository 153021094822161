import React from 'react';

import { ContentFolderKind, ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { LandscapeCardGrid } from '__SMART_APP_OLD__/app/components/LandscapeCardGrid';
import { PortraitCardGrid } from '__SMART_APP_OLD__/app/components/PortraitCardGrid';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { CollectionScreenPortraitItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenPortraitItem';
import { collectionSelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/selectors';
import { CollectionScreenCard } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenCard';

import { Focus } from 'App/Packages/Focus';

export const CollectionScreenGrid: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const ids = useSelector(Collection.selectors.selectEntityIds);
    const initialFocusedIndex = useSelector(collectionSelectors.private.selectInitialFocusedIndex);
    const entities = useSelector(Collection.selectors.selectEntities);
    const kind = useSelector(Collection.selectors.selectKind);
    const isLandScapeViewCard = useSelector(collectionSelectors.private.selectIsLandScapeViewCard);
    const entityItems = ids.map((id) => entities[id]);
    const isMixedAssets =
        entityItems.some((entity) => entity?.type !== ContentItemType.Vodasset) ||
        kind === ContentFolderKind.Favourites ||
        isLandScapeViewCard;

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <>
            {isMixedAssets ? (
                <LandscapeCardGrid
                    className="collection-screen__grid"
                    ctx={Focus.Default}
                    Card={CollectionScreenCard}
                    items={ids}
                    initialFocusedIndex={initialFocusedIndex}
                />
            ) : (
                <PortraitCardGrid
                    className="collection-screen__grid"
                    ctx={Focus.Default}
                    PortraitCard={CollectionScreenPortraitItem}
                    items={ids}
                    initialFocusedIndex={initialFocusedIndex}
                />
            )}
        </>
    );
};
