import { Env } from 'App/Env';
import { Browser } from 'App/Platform/Device/Browser';
import { Titan } from 'App/Platform/Device/Titan';
import { Tizen } from 'App/Platform/Device/Tizen';
import { Vidaa } from 'App/Platform/Device/Vidaa';
import { WebOS } from 'App/Platform/Device/WebOS';

export class Device {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.Initialize]');
        if (Env.IsTizen) return Tizen.initialize();
        if (Env.IsWebOS) return WebOS.initialize();
        if (Env.IsVidaa) return Vidaa.initialize();
        if (Env.IsTitan) return Titan.initialize();
        if (Env.IsBrowser) return Browser.initialize();
        return false;
    }
}
