import { useEffect, useState } from 'react';

import { Function } from 'App/Packages/Function';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';

export type UseFolderItemsIsDisabled = (id: string) => boolean;

export const useFolderItemsIsDisabled: UseFolderItemsIsDisabled = (id) => {
    const list = FolderList.use();
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        const update = () => setDisabled(list.disabled.get() || list.focused.get() !== id);

        update();

        const unsubscribers = [list.disabled.subscribe(update), list.focused.subscribe(update)];
        return () => Function.call(...unsubscribers);
    }, [id, list]);

    return isDisabled;
};
