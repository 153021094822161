import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelListKind,
    selectChannelListName,
    selectSelectedChannelListKind,
    selectSelectedChannelListName,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { channelListsScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/actions';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListOption, getChannelListAllItems } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/constants';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

type Props = {
    hoveredChannelListId: string;
    optionSelected?: ChannelListOption;
};

type ChannelListsScreenViewRightSectionModule = React.FunctionComponent<Props>;

export const ChannelListsScreenViewOptionsSectionContent: ChannelListsScreenViewRightSectionModule = (props) => {
    const { hoveredChannelListId, optionSelected } = props;
    const dispatch = useDispatch();

    const hoveredChannelListName = useSelector(selectChannelListName(hoveredChannelListId));
    const hoveredChannelListKind = useSelector(selectChannelListKind(hoveredChannelListId));
    const activeChannelListName = useSelector(selectSelectedChannelListName);
    const activeChannelListKind = useSelector(selectSelectedChannelListKind);

    const hasHoveredOnActive = hoveredChannelListName === activeChannelListName;
    const items = getChannelListAllItems(activeChannelListKind, hoveredChannelListKind, hasHoveredOnActive);

    const { focused, isDisabled, focus } = Focus.useList({
        ctx: ChannelListsScreenCtx.ChannelListsOptions,
        axis: Axis.Y,
        items,
        resetFocusOnBlur: true,
        initialFocusedIndex: items.indexOf(optionSelected ?? items[0]),
    });

    return (
        <>
            <Settings.Section.Header>
                <Text typeface={Typeface.SANS} size={FontSize.TITLE_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
                    {hoveredChannelListName}
                </Text>
            </Settings.Section.Header>
            <Settings.Section.Content className="channel-lists-screen__list">
                <Settings.Section.Content.Separator />
                {items.map((item) => (
                    <React.Fragment key={item}>
                        <Settings.Section.Content.Item
                            id={item}
                            ctx={ChannelListsScreenCtx.ChannelListsOptions}
                            isFocused={item === focused && !isDisabled}
                            onEnter={(option) => dispatch(channelListsScreenActions.private.optionSelected(hoveredChannelListId, option))}
                            onHover={focus}
                            isSecondary
                        >
                            <Settings.Section.Content.Item.Title>{item}</Settings.Section.Content.Item.Title>
                        </Settings.Section.Content.Item>
                        <Settings.Section.Content.Separator />
                    </React.Fragment>
                ))}
            </Settings.Section.Content>
        </>
    );
};
