import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodFolderCollectionItemFragmentDoc } from './vodFolder/vodFolderCollectionItem.generated';
import { VodAssetCollectionItemFragmentDoc } from './vodAsset/vodAssetCollectionItem.generated';
import { VodProductCollectionItemFragmentDoc } from './vodProduct/vodProductCollectionItem.generated';
import { VodSeriesCollectionItemFragmentDoc } from './vodSeries/vodSeriesCollectionItem.generated';
import { SeriesCollectionItemFragmentDoc } from './series/seriesCollectionItem.generated';
import { RecordingCollectionItemFragmentDoc } from './recording/recordingCollectionItem.generated';
import { EventCollectionItemFragmentDoc } from './event/eventCollectionItem.generated';
import { ChannelCollectionItemFragmentDoc } from './channel/channelCollectionItem.generated';
import { BannerCollectionItemFragmentDoc } from './banner/bannerCollectionItem.generated';
export type CollectionItem_Banner_Fragment = { __typename: 'Banner', id: string, link: string, thumbnailAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, backgroundImageAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean } };

export type CollectionItem_Channel_Fragment = { __typename: 'Channel', id: string, title: string, description?: string | null, logoImageAlias?: { __typename: 'Image', id: string, url?: string | null } | null, userInfo?: { __typename?: 'UserChannelInfo', id: string, subscribed: boolean } | null };

export type CollectionItem_ChannelProduct_Fragment = { __typename: 'ChannelProduct' };

export type CollectionItem_Event_Fragment = { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename?: 'EventEntitlements', pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any } | null };

export type CollectionItem_NetworkRecording_Fragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, event: { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename?: 'EventEntitlements', pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any } | null } };

export type CollectionItem_ProductBundle_Fragment = { __typename: 'ProductBundle' };

export type CollectionItem_Series_Fragment = { __typename: 'Series', id: string, title: string, episodeCount: number, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, channel?: { __typename?: 'Channel', id: string } | null, seriesParentalRating: { __typename?: 'ParentalRating', id: string } };

export type CollectionItem_VodAsset_Fragment = { __typename: 'VODAsset', id: string, title: string, duration: number, metadata: { __typename?: 'Metadata', year?: number | null, shortDescription?: string | null, seriesInfo?: { __typename?: 'SeriesInfo', id: string } | null, genre?: { __typename?: 'Genre', title: string } | null }, vodAssetEntitlements: { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null } | null> }, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodAssetParentalRating: { __typename?: 'ParentalRating', id: string } };

export type CollectionItem_VodFolder_Fragment = { __typename: 'VODFolder', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodFolderParentalRating: { __typename?: 'ParentalRating', id: string } };

export type CollectionItem_VodProduct_Fragment = { __typename: 'VODProduct', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodProductParentalRating?: { __typename?: 'ParentalRating', id: string } | null };

export type CollectionItem_VodSeries_Fragment = { __typename: 'VODSeries', id: string, title: string, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodSeriesParentalRating: { __typename?: 'ParentalRating', id: string } };

export type CollectionItemFragment = CollectionItem_Banner_Fragment | CollectionItem_Channel_Fragment | CollectionItem_ChannelProduct_Fragment | CollectionItem_Event_Fragment | CollectionItem_NetworkRecording_Fragment | CollectionItem_ProductBundle_Fragment | CollectionItem_Series_Fragment | CollectionItem_VodAsset_Fragment | CollectionItem_VodFolder_Fragment | CollectionItem_VodProduct_Fragment | CollectionItem_VodSeries_Fragment;

export const CollectionItemFragmentDoc = gql`
    fragment collectionItem on ContentItem {
  ...vodFolderCollectionItem
  ...vodAssetCollectionItem
  ...vodProductCollectionItem
  ...vodSeriesCollectionItem
  ...seriesCollectionItem
  ...recordingCollectionItem
  ...eventCollectionItem
  ...channelCollectionItem
  ...bannerCollectionItem
  __typename
}
    ${VodFolderCollectionItemFragmentDoc}
${VodAssetCollectionItemFragmentDoc}
${VodProductCollectionItemFragmentDoc}
${VodSeriesCollectionItemFragmentDoc}
${SeriesCollectionItemFragmentDoc}
${RecordingCollectionItemFragmentDoc}
${EventCollectionItemFragmentDoc}
${ChannelCollectionItemFragmentDoc}
${BannerCollectionItemFragmentDoc}`;