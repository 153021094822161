import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { LandscapeCard } from '__SMART_APP_OLD__/app/components/LandscapeCard';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectEventImageVariant,
    selectEventLiveProgress,
    selectEventTitle,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';
import { nowOnTVItemEnterTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/actions';
import { selectNowOnTVEvent, selectNowOnTVIcons } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/selectors';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import React, { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

const NowOnTVCardComponent: React.FunctionComponent<CollectionItemProps> = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(selectNowOnTVEvent(props.id));
    const eventID = event?.id ?? '';
    const title = useSelector(selectEventTitle(eventID, PinSessionType.PIN_LIVE_TV));
    const description = useMemo(() => getTimeStampInfo(event?.start ?? 0, event?.end ?? 0), [event?.start, event?.end]);
    const imageVariant = useSelector(selectEventImageVariant(eventID));
    const icons = useSelector(selectNowOnTVIcons(eventID), shallowEqual);
    const progress = useSelector(selectEventLiveProgress(eventID));
    const enterHandler = useCallback(() => dispatch(nowOnTVItemEnterTriggered(props.id, eventID)), [props.id, eventID, dispatch]);

    return (
        <LandscapeCard
            id={props.id}
            ctx={props.ctx}
            className={props.className}
            channelId={props.id}
            title={title}
            description={description}
            icons={icons}
            image={event?.thumbnail}
            imageVariant={imageVariant}
            isFocused={props.isFocused}
            progressBar
            disableSelector
            disableAnimation
            progress={progress}
            progressColor={BackgroundColor.COOL_BLUE_PRIMARY}
            contentMarker={ContentMarkerType.Live}
            onEnter={enterHandler}
            onHover={props.onHover}
        />
    );
};

export const NowOnTVCard = React.memo(NowOnTVCardComponent);
