import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialDeviceEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/constants';
import { DeviceEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const deviceEntityTableReducer: Reducer<DeviceEntityTable> = (state, action) => {
    if (!state) return initialDeviceEntityTableState;
    switch (action.type) {
        case ActionType.DEVICE_LOAD_MANY: {
            if (!action.payload.devices.length) return { ...state, ids: [], entities: {} };
            const ids = Mapper.Common.toIds(action.payload.devices);
            const entities = Mapper.Device.toEntities(action.payload.devices);
            return { ...state, ids, entities };
        }
        case ActionType.DEVICE_REMOVE_ONE: {
            const ids = Mapper.Common.toFilteredIds(state.ids, [action.payload.deviceId]);
            const entities = Mapper.Common.toRemovedEntities(state.entities, [action.payload.deviceId]);
            return { ...state, ids, entities };
        }
        case ActionType.MAX_NUMBER_OF_UNMANAGED_DEVICES_CHANGE: {
            if (state.maxNumberOfUnmanagedDevices === action.payload.maxNumberOfUnmanagedDevices) return state;
            return { ...state, maxNumberOfUnmanagedDevices: action.payload.maxNumberOfUnmanagedDevices };
        }
        default:
            return state;
    }
};
