import React from 'react';

import { ChannelListsScreenCreateCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/ChannelListsScreenCreateCtx';

import { Input, InputElement } from 'App/Modules/Input';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { channelListScreenCreateActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/actions';
import { Screen } from 'App/Modules/Screen';

type ChannelListsScreenCreateViewInputProps = {
    value?: string;
    input: React.MutableRefObject<InputElement | null>;
    onFocus?: () => void;
};

export const ChannelListsScreenCreateViewInput: React.FunctionComponent<ChannelListsScreenCreateViewInputProps> = (props) => {
    const { input, value, onFocus } = props;

    const dispatch = useDispatch();

    const navigation = Focus.useElement({
        ctx: ChannelListsScreenCreateCtx.Input,
    });

    const { onClick, onHover } = Focus.useFocusable({
        id: ChannelListsScreenCreateCtx.Input,
        ctx: ChannelListsScreenCreateCtx.Input,
        isFocused: navigation.isFocused,
        onEnter: () => dispatch(channelListScreenCreateActions.private.inputEntered()),
        onHover: navigation.onHover,
        onBlur: () => input.current?.blur(),
        onFocus: () => input.current?.focus(),
    });

    return (
        <Input
            className="manage-name-screen__input"
            ref={input}
            onBlur={() => {
                dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Actions));
            }}
            onFocus={onFocus}
            onClick={onClick}
            onHover={onHover}
            value={value}
        />
    );
};
