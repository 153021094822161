import { useCallback, useRef } from 'react';

export type UseSuggestionListEnter = (enter?: (value: string) => void) => ((value: string) => void) | undefined;

export const useSuggestionListEnter: UseSuggestionListEnter = (enter) => {
    const ref = useRef(enter);
    ref.current = enter;
    const handler = useCallback((value: string) => ref.current?.(value), []);
    if (!ref.current) return undefined;
    return handler;
};
