import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Function } from 'App/Packages/Function';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';

const selected =
    (id: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.AudioSelect)(getState());
        if (!overlay?.props) return;

        await dispatch(
            Overlay.actions.mount(Overlay.Type.Loading, {
                backgroundColor: BackgroundColor.PRIMARY,
            })
        );
        const { audios, onAudioSelect, subtitleMenuProps } = overlay.props;
        const audio = audios.find((opt) => opt.id === id);

        await Function.sleep(250);
        await dispatch(Overlay.actions.unmount());
        await dispatch(Overlay.actions.mount(Overlay.Type.SubtitleMenu, subtitleMenuProps, Constants.Ctx.audio));

        if (audio) {
            dispatch(onAudioSelect(audio));
        }
    };

const getAudioNameById =
    (id: string): Thunk<string | undefined> =>
    (dispatch, getState) => {
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.AudioSelect)(getState());
        if (!overlay?.props) return undefined;

        const audio = overlay.props.audios.find((audioItem) => audioItem.id === id);
        if (audio && audio.codec?.includes('ac-3')) {
            return Translation.selectors.select(`${audio.name} - ${Translation.selectors.select('DOLBY')(getState())}`)(getState());
        }
        return Translation.selectors.select(audio ? audio.name : '')(getState());
    };

export const Actions = {
    private: {
        selected,
        getAudioNameById,
    },
};
