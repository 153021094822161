import { useState } from 'react';

import Asset from '__SMART_APP_OLD__/data/Asset';
import { SearchEvents, SearchResultFolder } from 'analytics/logging/events/SearchEvent';
import { getSearchEvent } from 'analytics/logging/factories/searchEventFactory';
import { LoggingService } from 'analytics/loggingService';

type SearchResultFolderInput = {
    assets: Asset[];
    type: string;
    title: string;
    stripeId: string;
};

const INITIAL_SEARCH_STATE = {
    searchValue: '',
    searchResults: [],
    searchDataLoaded: false,
    searchSuggestions: [],
};

export const useSearchDataService = () => {
    const [searchData, setSearchData] = useState(INITIAL_SEARCH_STATE);

    const onTermChange = (searchValue: string) => {
        setSearchData({ ...searchData, searchValue });
    };

    const onSearchData = (searchResults: any, searchValue: string, fromSuggestion: boolean = false) => {
        setSearchData({ ...searchData, searchResults, searchDataLoaded: true });

        const searchResultFolders: SearchResultFolder[] = searchResults.map(({ title, assets: totalCount }: SearchResultFolderInput) => ({
            title,
            totalCount,
        }));
        const searchEventType = fromSuggestion ? SearchEvents.CAST : SearchEvents.TEXT;

        LoggingService.getInstance().logEvent(getSearchEvent(searchEventType, searchValue, searchResultFolders));
    };

    const onSearchSuggestions = (searchSuggestions: any) => {
        setSearchData({ ...searchData, searchSuggestions });
    };

    const onResetSearchData = () => {
        setSearchData(INITIAL_SEARCH_STATE);
    };

    return {
        searchData,
        onTermChange,
        onSearchData,
        onSearchSuggestions,
        onResetSearchData,
    };
};
