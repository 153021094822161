import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type VodProductFolderItemFragment = { __typename: 'VODProduct', id: string };

export const VodProductFolderItemFragmentDoc = gql`
    fragment vodProductFolderItem on VODProduct {
  id
  __typename
}
    `;