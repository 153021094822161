import React from 'react';

import { PromptProps, PromptType } from 'App/Modules/Prompt/Types';
import { Null } from 'App/Packages/UI/Null';
import { PromptLifecycle } from './types';

export class Registry {
    private static readonly default: PromptLifecycle = {
        outOfBounds: () => () => {},
        mount: () => async () => {},
        unmount: () => async () => {},
        update: () => async () => {},
    };

    private static readonly store: Partial<Record<PromptType, [React.FunctionComponent<PromptProps>, Partial<PromptLifecycle>]>> = {};

    public static readonly get = <T extends PromptType>(type: T): [React.FunctionComponent<PromptProps<T>>, PromptLifecycle<T>] => [
        (Registry.store[type]?.[0] as unknown as React.FunctionComponent<PromptProps<T>>) ?? Null,
        (Registry.store[type]?.[1] as unknown as PromptLifecycle<T>) ?? Registry.default,
    ];

    public static readonly register = <T extends PromptType>(
        type: T,
        Component: React.FunctionComponent<PromptProps<T>>,
        lifecycle: Partial<PromptLifecycle<T>> = {}
    ) => {
        Registry.store[type] = [Component as unknown as React.FunctionComponent<PromptProps>, { ...Registry.default, ...lifecycle }];
    };
}
