import { VodSeriesState } from 'App/Modules/Data/Detail/VodSeries/Types';

export const initialVodDetailSeriesState: VodSeriesState = {
    item: null,
    assetObject: null,
    screenBackgroundImageUrl: '',
    groupTitles: [],
    items: null,
    itemIdsForGroupTitle: [],
    selectedGroupTitle: '',
    products: [],
};
