import { ButtonIconFlavour } from '__SMART_APP_OLD__/app/components/Button/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';

export const enum MessageInboxActionID {
    FILTER = 'FILTER',
    SELECT = 'SELECT',
    SELECT_ALL = 'SELECT_ALL',
    MARK_AS_READ = 'MARK_AS_READ',
    DELETE = 'DELETE',
}

export const enum MessageInboxItemActionID {
    READ_MESSAGE = 'READ_MESSAGE',
    DELETE = 'DELETE',
}

export const enum MessageInboxCTX {
    BACK = 'BACK',
    ACTIONS = 'ACTIONS',
    LIST = 'LIST',
}

export const enum MessageInboxFilter {
    NO_FILTER = 'NO_FILTER',
    UNREAD = 'UNREAD',
}

export type MessageInboxSelectAllAction = ButtonEntity<MessageInboxActionID.SELECT_ALL, 'SELECT_ALL', { icon: null; flavour: null }>;
export type MessageInboxMarkAsReadAction = ButtonEntity<
    MessageInboxActionID.MARK_AS_READ,
    'MESSAGE_INBOX_MARK_AS_READ',
    { icon: null; flavour: null }
>;
export type MessageInboxFilterAction = ButtonEntity<
    MessageInboxActionID.FILTER,
    'FILTER',
    { icon: IconName.DOWNLOAD; flavour: ButtonIconFlavour.FANCY }
>;
export type MessageInboxSelectAction = ButtonEntity<
    MessageInboxActionID.SELECT,
    'SELECT',
    { icon: IconName.SETTINGS; flavour: ButtonIconFlavour.FANCY }
>;
export type MessageInboxDeleteAction = ButtonEntity<
    MessageInboxActionID.DELETE,
    'MESSAGE_INBOX_DELETE',
    { icon: IconName.DELETE; flavour: ButtonIconFlavour.DEFAULT }
>;

export type MessageInboxItemReadMessageAction = ButtonEntity<
    MessageInboxItemActionID.READ_MESSAGE,
    'READ_MESSAGE',
    { icon: IconName.MESSAGING; flavour: ButtonIconFlavour.FANCY }
>;

export type MessageInboxItemDeleteAction = ButtonEntity<
    MessageInboxItemActionID.DELETE,
    'DELETE',
    { icon: IconName.DELETE; flavour: ButtonIconFlavour.DEFAULT }
>;

export type MessageInboxAction =
    | MessageInboxFilterAction
    | MessageInboxSelectAction
    | MessageInboxSelectAllAction
    | MessageInboxMarkAsReadAction
    | MessageInboxDeleteAction;

export type MessageInboxItemAction = MessageInboxItemReadMessageAction | MessageInboxItemDeleteAction;

// --------------------- State ----------------------

export type MessageInboxState = {
    ctx: MessageInboxCTX;
    selected: GQL.MessageID[];
    isSelectMode: boolean;
    appliedFilter: string;
};

// --------------------- State ----------------------

// -------------------- Actions ---------------------

export type MessageInboxReduxAction =
    | MessageInboxModeChangedAction
    | MessageInboxFilterChangedAction
    | MessageInboxCTXChangedAction
    | MessageInboxMessageSelectedAction
    | MessageInboxMessageSelectManyAction;

export type MessageInboxMessageSelectedActionPayload = { messageId: GQL.MessageID };
export type MessageInboxSelectedChangedActionPayload = { messageIds: GQL.MessageID[] };
export type MessageInboxFilterChangedActionPayload = { filter: string };
export type MessageInboxCTXChangedActionPayload = { ctx: string };
export type MessageInboxModeChangedAction = BaseAction<ActionType.MESSAGE_INBOX_MODE_CHANGED>;
export type MessageInboxCTXChangedAction = PayloadedAction<ActionType.MESSAGE_INBOX_CTX_CHANGED, MessageInboxCTXChangedActionPayload>;
export type MessageInboxFilterChangedAction = PayloadedAction<
    ActionType.MESSAGE_INBOX_FILTER_CHANGED,
    MessageInboxFilterChangedActionPayload
>;
export type MessageInboxMessageSelectedAction = PayloadedAction<
    ActionType.MESSAGE_INBOX_MESSAGE_SELECTED,
    MessageInboxMessageSelectedActionPayload
>;
export type MessageInboxMessageSelectManyAction = PayloadedAction<
    ActionType.MESSAGE_INBOX_SELECTED_CHANGED,
    MessageInboxSelectedChangedActionPayload
>;
// -------------------- Actions ---------------------
