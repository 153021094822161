import { useRef } from 'react';

import { Engine } from 'App/Packages/Focus/Engine';
import { KeyEvent } from 'App/Packages/Focus/Events/KeyEvent';
import { navigationHookGenerator } from 'App/Packages/Focus/Generator/navigationHookGenerator';
import { KeyEventHandler, KeyOptions } from 'App/Packages/Focus/Generator/Types';

export const keyHookGenerator = <T extends KeyEvent>(engine: Engine<T>) => {
    const useNavigation = navigationHookGenerator<T>(engine);

    const useKey = (onKey: KeyEventHandler<T>, options: KeyOptions): boolean => {
        const keys = useRef(options.keys ?? []);
        keys.current = options.keys ?? [];
        return useNavigation((event) => {
            if (keys.current.length && !keys.current.includes(event.key)) return;
            onKey(event);
        }, options);
    };

    return useKey;
};
