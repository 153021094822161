import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Variant } from '__SMART_APP_OLD__/app/components/Image';
import { EPG_VISSIBLE_CHANNELS } from '__SMART_APP_OLD__/app/constants/epg';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectSelectedChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectChannelEventIds,
    selectEvent,
    selectEventBackground,
    selectEventBy,
    selectEventEnd,
    selectEventFullDescription,
    selectEventIsCatchUpTVEnabled,
    selectEventIsDolby,
    selectEventIsLive,
    selectEventIsLoading,
    selectEventIsNoInfo,
    selectEventShouldMask,
    selectEventStart,
    selectEventTitle,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { getEventPreviewMetaInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { selectIsReminderSetForEvent } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { TV_GUIDE_CELL_MARGIN_VW, TV_GUIDE_VW_PER_SECOND } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/constants';
import { TVGuideCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/types';
import { generateDatePickerOptionFromDate } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/utils';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { formatDayName, weekdaysShort } from '__SMART_APP_OLD__/utils/timeUtils';
import { getPrimeTimeDate } from '__SMART_APP_OLD__/utils/TvGuideUtils';

import { Screen } from 'App/Modules/Screen';
import { ContentMarkerType } from 'App/Types/ContentMarker';

export const selectIsTVGuideLoading = (state: State) => state.tvGuide.isLoading;
export const selectTVGuideTime = (state: State): number => state.tvGuide.time;
export const selectTVGuideFirstIndex = (state: State): number => state.tvGuide.firstIndex;
export const selectTVGuideLastIndex = (state: State): number =>
    Utils.EPG.getIndexInRange(
        selectTVGuideFirstIndex(state) + EPG_VISSIBLE_CHANNELS - 1,
        selectSelectedChannelListChannelIds(state).length
    );

export const selectTVGuideFocusedIndex = (state: State): number => state.tvGuide.focusedIndex;
export const selectTVGuideFocusedChannelId = (state: State): GQL.ChannelID =>
    selectSelectedChannelListChannelIds(state)[selectTVGuideFocusedIndex(state)] ?? '';
export const selectTVGuideFirstChannelId = (state: State): GQL.ChannelID =>
    selectSelectedChannelListChannelIds(state)[selectTVGuideFirstIndex(state)] ?? '';
export const selectTVGuideStart = (state: State): number => state.tvGuide.start;
export const selectTVGuideEventId = (state: State): GQL.EventID =>
    selectEventBy(selectTVGuideFocusedChannelId(state), selectTVGuideTime(state), { equal: EqualityCompare.START })(state)?.id ?? '';

export const selectTVGuideEvent = (state: State) => selectEvent(selectTVGuideEventId(state))(state);
export const selectTVGuideEventIsLoading = (state: State) => selectEventIsLoading(selectTVGuideEventId(state))(state);
export const selectTVGuideEventIsNoInfo = (state: State) => selectEventIsNoInfo(selectTVGuideEventId(state))(state);
export const selectTVGuideEventIsLive = (state: State) => selectEventIsLive(selectTVGuideEventId(state))(state);
export const selectTVGuideEventShouldMask = (state: State) => {
    return selectEventShouldMask(selectTVGuideEventId(state), PinSessionType.PIN_LIVE_TV)(state);
};
export const selectTVGuideEventHeader = (state: State) => selectEventTitle(selectTVGuideEventId(state), PinSessionType.PIN_LIVE_TV)(state);

export const selectTVGuideBackgroundImage = (state: State): string | null => selectEventBackground(selectTVGuideEventId(state))(state);
export const selectTVGuideBackgroundImageVariant = (state: State): Variant => {
    if (selectTVGuideEventIsLoading(state)) return Variant.LOADING;
    if (selectTVGuideEventIsNoInfo(state)) return Variant.COVER;
    if (selectTVGuideEventShouldMask(state)) return Variant.ADULT;
    return Variant.BASE;
};

export const selectTVGuideContentMarker = (state: State): ContentMarkerType | null =>
    selectTVGuideEventIsLive(state) ? ContentMarkerType.Live : null;

export const selectTVGuideIcons = (state: State): IconName[] => {
    const icons: IconName[] = [];
    if (selectEventIsCatchUpTVEnabled(selectTVGuideEventId(state))(state)) icons.push(IconName.CATCHUP);
    if (selectIsReminderSetForEvent(selectTVGuideEventId(state))(state)) icons.push(IconName.REMINDER);
    if (selectEventIsDolby(selectTVGuideEventId(state))(state)) icons.push(IconName.DOLBY);
    return icons;
};

export const selectTVGuideMetaInfo = (state: State): string => getEventPreviewMetaInfo(selectTVGuideEvent(state));
export const selectTVGuideEventDescription = (state: State): string => selectEventFullDescription(selectTVGuideEventId(state))(state);
export const selectTVGuideTimelineDate = (state: State): string =>
    formatDayName(new Date(selectTVGuideStart(state)), 'DD dd MMM ', '', false);

export const selectTVGuideTimelineEntries = (state: State): string[] => {
    const start = selectTVGuideStart(state);
    return new Array(5).fill(0).map((_, i) => {
        const date = new Date(start + i * 30 * Time.MINUTE_MS);
        const time = date.toTimeString().slice(0, 5);
        if (time !== '00:00') return time;
        return weekdaysShort[date.getDay()];
    });
};

export const selectTVGuideVissibleChannels = (state: State): GQL.ChannelID[] => {
    const channelIds = selectSelectedChannelListChannelIds(state);
    if (channelIds.length <= EPG_VISSIBLE_CHANNELS) return channelIds;
    const firstIndex = selectTVGuideFirstIndex(state);
    if (firstIndex === -1) return channelIds.slice(0, EPG_VISSIBLE_CHANNELS);
    if (firstIndex + EPG_VISSIBLE_CHANNELS <= channelIds.length) return channelIds.slice(firstIndex, firstIndex + EPG_VISSIBLE_CHANNELS);
    return [...channelIds.slice(firstIndex), ...channelIds.slice(0, EPG_VISSIBLE_CHANNELS - (channelIds.length - firstIndex))];
};

export const selectTVGuideEvents =
    (channelId: GQL.ChannelID) =>
    (state: State): GQL.EventID[] => {
        const start = selectTVGuideStart(state);
        const end = start + 2 * Time.HOUR_MS;
        const eventIds = selectChannelEventIds(channelId)(state);
        const startIndex = eventIds.findIndex((id) => selectEventStart(id)(state) <= start && selectEventEnd(id)(state) > start);
        const endIndex = eventIds.findIndex((id) => selectEventStart(id)(state) < end && selectEventEnd(id)(state) >= end);
        return eventIds.slice(startIndex, endIndex + 1);
    };

export const selectTVGuideIsFocused = (eventId: GQL.EventID) => (state: State) =>
    eventId === selectTVGuideEventId(state) && Screen.selectors.selectCtx(state) === TVGuideCTX.GRID;

export const selectTVGuideIsTimelineFocused = (state: State) => Screen.selectors.selectCtx(state) === TVGuideCTX.TIMELINE;

export const selectTVGuideEventVWWidth = (eventId: GQL.EventID) => (state: State) => {
    const end = Math.min(selectTVGuideStart(state) + 2 * Time.HOUR_MS, selectEventEnd(eventId)(state));
    const start = Math.max(selectTVGuideStart(state), selectEventStart(eventId)(state));
    return ((end - start) / Time.SECOND_MS) * TV_GUIDE_VW_PER_SECOND - TV_GUIDE_CELL_MARGIN_VW;
};

export const selectTVGuideDatePickerOptions = (state: State): ButtonEntity[] => {
    const { past, future } = state.config.epg.visibility;
    const daysInThePast = Math.abs(Math.floor(past / Time.DAY_MS));
    const daysInTheFuture = Math.abs(Math.floor(future / Time.DAY_MS));

    return new Array(daysInTheFuture + daysInThePast + 1).fill(0).reduce((acc, _, idx) => {
        const dateTime = new Date();
        const fillThePast = idx <= daysInThePast - 1;
        const isToday = idx === daysInThePast;
        let newTime;
        if (fillThePast) {
            newTime = dateTime.setDate(dateTime.getDate() - (daysInThePast - idx));
        } else if (isToday) {
            newTime = dateTime;
        } else {
            newTime = dateTime.setDate(dateTime.getDate() + (idx - daysInThePast));
        }
        const date = new Date(newTime);

        if (isToday) {
            acc.push(generateDatePickerOptionFromDate(date, false));
            acc.push(generateDatePickerOptionFromDate(date, true));
            return acc;
        }
        const option = generateDatePickerOptionFromDate(date, false);
        acc.push(option);
        return acc;
    }, []);
};

export const selectTVGuideSelectedDatePickerOptionIndex = (state: State) => {
    const start = selectTVGuideStart(state);
    const primeDate = getPrimeTimeDate(new Date());
    const isPrime = start === primeDate.getTime();
    const selectedOption = generateDatePickerOptionFromDate(new Date(start), isPrime);
    return selectTVGuideDatePickerOptions(state).findIndex((btn) => btn.label === selectedOption.label) ?? 0;
};

export const selectTVGuideLiveLineTimeOffset = (state: State) => {
    const start = selectTVGuideStart(state);
    const time = Time.selectors.select(state);
    const offset = time - start;
    if (offset > Time.HOUR_MS * 2 || offset < 0) return -1;
    return Math.floor(offset / Time.SECOND_MS);
};

export const selectTVGuideIsSnackBarEnabled = (state: State): boolean =>
    Screen.selectors.selectIsActive(state) && Screen.selectors.selectCtx(state) === TVGuideCTX.GRID;
