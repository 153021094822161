import React from 'react';

import { useFocusable } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Div } from 'App/Packages/UI/Div';

type Props = {
    focus: (item: string) => void;
    isFocused: boolean;
    variant: string;
    onEnter: () => void;
    isActive: boolean;
};

const getClassName = (isFocused: boolean, isActive: boolean) => {
    const classList = ['text-option'];
    if (isFocused) classList.push('focused');
    if (isActive) classList.push('active');

    return classList.join(' ');
};

type SubFontStyleOptionComponent = React.FunctionComponent<Props>;

export const SubFontStyleOption: SubFontStyleOptionComponent = (props) => {
    const { focus, isFocused, variant, onEnter, isActive } = props;

    const { onClick, onHover } = useFocusable({
        id: variant,
        ctx: Constants.Ctx.fontStyles,
        isFocused,
        onHover: focus,
        onFocus: focus,
        onEnter,
    });

    return (
        <Div onHover={onHover} onClick={onClick} className={getClassName(isFocused, isActive)}>
            <span className={`text-option-${variant}`}>Aa</span>
        </Div>
    );
};
