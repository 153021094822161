import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Subscribable } from 'App/Packages/Subscribable';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

const getClassName = (theme: Theme.Type, isFocused: boolean) => {
    const classList = ['header-item', 'header-item--menu'];
    classList.push(Theme.getClassName('header-item', theme));
    if (isFocused) classList.push('header-item--focused');
    return classList.join(' ');
};

const getProps = (props: HeaderMenuItemInternalProps): DivPropsExtendable => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, onBlur, onFocus, onHover, ...rest } = props;
    return rest;
};

type HeaderMenuItemInternalProps = Omit<DivPropsExtendable, 'onFocus' | 'onBlur' | 'onClick'> & {
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
};

type HeaderMenuItemInternalComponent = React.FunctionComponent<HeaderMenuItemInternalProps>;

const HeaderMenuItemInternal: HeaderMenuItemInternalComponent = (props) => {
    const theme = useTheme();
    const item = HeaderItem.use();
    const isFocused = Subscribable.use(item.focused);

    const onFocus = (id: string) => {
        if (props.onFocus) props.onFocus(id);
        item.scrollIntoView();
    };

    const { onClick, onHover } = useFocusableHook({
        id: item.id,
        ctx: item.ctx,
        isFocused,
        onEnter: item.enter,
        onHover: item.focus,
        onBlur: props.onBlur,
        onFocus,
    });

    return (
        <Div {...getProps(props)} ref={item.ref} defaultClassName={getClassName(theme, isFocused)} onClick={onClick} onHover={onHover}>
            {props.children}
        </Div>
    );
};

export type HeaderMenuItemProps = {
    id: string;
    onEnter?: (id: string) => void;
} & HeaderMenuItemInternalProps;

export type HeaderMenuItemComponent = React.FunctionComponent<HeaderMenuItemProps>;

export const HeaderMenuItem: HeaderMenuItemComponent = (props) => (
    <HeaderItem id={props.id} onEnter={props.onEnter}>
        <HeaderMenuItemInternal>{props.children}</HeaderMenuItemInternal>
    </HeaderItem>
);
