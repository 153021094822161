import React from 'react';
import { useSelector } from 'react-redux';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';

export const CollectionScreenHeader: React.FunctionComponent = () => {
    const title = useSelector(Collection.selectors.selectTitleWithEntityCount);

    return (
        <Div className="collection-screen__header" backgroundColor={BackgroundColor.PRIMARY}>
            <Text
                className="collection-screen__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_3}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {title}
            </Text>
        </Div>
    );
};
