import { BannerCard } from 'App/Modules/Folder/Components/BannerCard';
import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { Folder } from 'App/Modules/Folder/index';
import { FolderCard } from 'App/Modules/Folder/Components/FolderCard';
import { LandscapeCard } from 'App/Modules/Folder/Components/LandscapeCard';
import { PortraitCard } from 'App/Modules/Folder/Components/PortraitCard';

export type FolderCardItemProps = {
    selector: Selector<Folder.Item | null>;
    onFocus?: (id: string) => void;
};

export const FolderCardItem: React.FunctionComponent<FolderCardItemProps> = (props) => {
    const item = useSelector(props.selector);

    if (!item) return null;
    if (item.visualization.isEligibleForBannerCard) return <BannerCard selector={props.selector} onFocus={props.onFocus} />;
    if (item.visualization.isEligibleForFolderCard) return <FolderCard selector={props.selector} onFocus={props.onFocus} />;
    if (item.visualization.isEligibleForPortraitCard) return <PortraitCard selector={props.selector} onFocus={props.onFocus} />;
    if (item.visualization.isEligibleForLandscapeCard) return <LandscapeCard selector={props.selector} onFocus={props.onFocus} />;
    return null;
};
