import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageUnreadCount } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';

export const InboxPositive: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => {
    const unreadMessagesCount = useSelector(selectMessageUnreadCount);
    return (
        <svg width={128} height={128} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M104 40C112.837 40 120 32.8366 120 24.0001C120 15.1635 112.837 8 104 8C95.1635 8 88 15.1635 88 24.0001C88 32.8366 95.1635 40 104 40ZM104 28.0001C106.209 28.0001 108 26.2092 108 24.0001C108 21.7909 106.209 20 104 20C101.791 20 100 21.7909 100 24.0001C100 26.2092 101.791 28.0001 104 28.0001Z"
                fill="#EB140A"
            />
            <g filter="url(#filter0_d_2742_11802)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M116 40.0015C112.657 42.5123 108.502 44 104 44C92.9543 44 84 35.0458 84 24.0001C84 21.1556 84.5938 18.4499 85.6642 16H24C17.37 16 12 21.37 12 28V84C12 90.63 17.37 96 24 96V109.18C24 110.96 26.16 111.85 27.41 110.59V110.6L42 96H104C110.63 96 116 90.63 116 84V40.0015Z"
                    fill="#FA8A80"
                />
            </g>
            <foreignObject x={16} y={20} width={96} height={88}>
                <Text
                    className="inbox-text"
                    typeface={Typeface.SANS}
                    size={FontSize.TITLE_1}
                    weight={FontWeight.BOLD}
                    color={Color.CUSTOM}
                    customColor="#FDC9C4"
                >
                    {unreadMessagesCount}
                </Text>
            </foreignObject>
            <defs>
                <filter
                    id="filter0_d_2742_11802"
                    x={10}
                    y={16}
                    width={108}
                    height={99.1804}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={2} />
                    <feGaussianBlur stdDeviation={1} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.72549 0 0 0 0 0.0392157 0 0 0 0 0.0196078 0 0 0 0.4 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2742_11802" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2742_11802" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
