// import { Constants } from 'App/Modules/Keyboard/Constants';
import { selectors as s } from 'App/Modules/Keyboard/Store/selectors';

export { KeyboardActionType as ActionType } from 'App/Modules/Keyboard/Store/types';
export type {
    KeyboardClosedAction as ClosedAction,
    KeyboardOpenedAction as OpenedAction,
    KeyboardState as State,
    KeyboardReduxAction as ReduxAction,
} from 'App/Modules/Keyboard/Store/types';

// export type {
//     KeyboardArrowLeftKeySchema as ArrowLeftKeySchema,
//     KeyboardArrowRightKeySchema as ArrowRightKeySchema,
//     KeyboardBackspaceKeySchema as BackspaceKeySchema,
//     KeyboardCapsLockKeySchema as CapsLockKeySchema,
//     KeyboardCharKeySchema as CharKeySchema,
//     KeyboardDefaultLayoutSchema as DefaultLayoutSchema,
//     KeyboardEnterKeyShema as EnterKeyShema,
//     KeyboardKeyLabelSchema as KeyLabelSchema,
//     KeyboardKeySchema as KeySchema,
//     KeyboardKeyType as KeyType,
//     KeyboardLanguage as Language,
//     KeyboardLanguageKeySchema as LanguageKeySchema,
//     KeyboardLayoutKeySchema as LayoutKeySchema,
//     KeyboardLayoutName as LayoutName,
//     KeyboardLayoutSchema as LayoutSchema,
//     KeyboardNumberKeySchema as NumberKeySchema,
//     KeyboardSchema as SchemaType,
//     KeyboardSpaceKeySchema as SpaceKeySchema,
//     KeyboardSymbolKeySchema as SymbolKeySchema,
//     KeyboardSymbolLayoutSchema as SymbolLayoutSchema,
// } from 'App/Modules/Keyboard/types';

export { getInitialState } from 'App/Modules/Keyboard/Store/getInitialState';
export { reducer } from 'App/Modules/Keyboard/Store/reducer';
// export { Widget } from 'App/Modules/Keyboard/Widget';
export { View } from 'App/Modules/Keyboard/View';
// export { Schema } from 'App/Modules/Keyboard/Schema';
export { Provider } from 'App/Modules/Keyboard/Provider';

export const selectors = s.public;

// export const { zIndex } = Constants;
