import { RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';

export const calcRecordingStatus = (status: RecordingStatus, startTime: number, endTime: number) => {
    const hasStarted = startTime < Date.now();
    const hasEnded = Date.now() > endTime;
    const isInProgress = hasStarted && !hasEnded;
    const isCompleted = hasEnded;

    switch (status) {
        case RecordingStatus.InProgress:
        case RecordingStatus.Planned:
            return isCompleted ? RecordingStatus.Completed : isInProgress ? RecordingStatus.InProgress : RecordingStatus.Planned;
        default:
            return status;
    }
};
