import { CardLandscapeComponent, CardLandscape as Root } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscape';
import { CardLandscapeContent, CardLandscapeContentComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeContent';
import {
    CardLandscapeDescription,
    CardLandscapeDescriptionComponent,
} from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeDescription';
import { CardLandscapeHeader, CardLandscapeHeaderComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeHeader';
import { CardLandscapeIcon, CardLandscapeIconComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeIcon';
import {
    CardLandscapeIconRecording,
    CardLandscapeIconRecordingComponent,
} from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeIconRecording';
import { CardLandscapeLogo, CardLandscapeLogoComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeLogo';
import { CardLandscapeMarker, CardLandscapeMarkerComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeMarker';
import { CardLandscapeMeta, CardLandscapeMetaComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeMeta';
import { CardLandscapeProgress, CardLandscapeProgressComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeProgress';
import { CardLandscapeSelector, CardLandscapeSelectorComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeSelector';
import {
    CardLandscapeThumbnail,
    CardLandscapeThumbnailComponent,
} from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeThumbnail';
import { CardLandscapeTitle, CardLandscapeTitleComponent } from 'App/Packages/UI/Card/CardLandscape/Components/CardLandscapeTitle';
import { Constants } from 'App/Packages/UI/Card/CardLandscape/Constants';

export type CardLandscapeModules = {
    // Constants
    Constants: typeof Constants;

    // Components
    Thumbnail: CardLandscapeThumbnailComponent;
    Logo: CardLandscapeLogoComponent;
    Selector: CardLandscapeSelectorComponent;

    Content: CardLandscapeContentComponent & {
        Meta: CardLandscapeMetaComponent & {
            Progress: CardLandscapeProgressComponent;
            Marker: CardLandscapeMarkerComponent;
        };
        Header: CardLandscapeHeaderComponent & {
            Title: CardLandscapeTitleComponent;
            Icon: CardLandscapeIconComponent & {
                Recording: CardLandscapeIconRecordingComponent;
            };
        };
        Description: CardLandscapeDescriptionComponent;
    };
};

export type CardLandscapeModule = CardLandscapeComponent & CardLandscapeModules;

export const CardLandscape: CardLandscapeModule = Object.assign(Root, {
    // Constants
    Constants,

    // Components
    Thumbnail: CardLandscapeThumbnail,
    Logo: CardLandscapeLogo,
    Selector: CardLandscapeSelector,
    Content: Object.assign(CardLandscapeContent, {
        Meta: Object.assign(CardLandscapeMeta, {
            Progress: CardLandscapeProgress,
            Marker: CardLandscapeMarker,
        }),
        Description: CardLandscapeDescription,
        Header: Object.assign(CardLandscapeHeader, {
            Title: CardLandscapeTitle,
            Icon: Object.assign(CardLandscapeIcon, {
                Recording: CardLandscapeIconRecording,
            }),
        }),
    }),
});
