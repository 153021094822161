import React, { useEffect, useRef } from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Image, ObjectFit, Variant } from '__SMART_APP_OLD__/app/components/Image';
import { LandscapeCardSelector } from '__SMART_APP_OLD__/app/components/LandscapeCard/LandscapeCardSelector';
import { ProgressBar } from '__SMART_APP_OLD__/app/components/ProgressBar';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { getTimeGapInDays } from '__SMART_APP_OLD__/utils/timeUtils';

import { Focus } from 'App/Packages/Focus';
import { ContentMarkerType } from 'App/Types/ContentMarker';

type Props = {
    className?: string;
    channelId?: GQL.ChannelID | null;
    image?: string | null;
    imageVariant?: Variant | null;
    imageOnly?: boolean | null;
    title?: string;
    icons?: IconName[] | null;
    description?: string | null;
    contentMarker?: ContentMarkerType | null;
    progressBar?: boolean;
    progress?: number;
    progressColor?: BackgroundColor;
    isSelected?: boolean;
    isSelectMode?: boolean;
    availableUntil?: number | null;
    disableSelector?: boolean;
    disableAnimation?: boolean;
    icon?: React.ReactNode;
    assetType?: string;
    isPortrait?: boolean;
    disableExpand?: boolean;
} & Focus.Focusable<string>;

const getClassName = (props: Props, isDarkTheme: boolean): string => {
    const classList = ['landscape-card-v2'];
    if (isDarkTheme) classList.push('landscape-card-v2--dark-theme');
    else classList.push('landscape-card-v2--light-theme');
    if (props.isFocused) classList.push('landscape-card-v2--focused');
    if (props.isSelected && props.isSelectMode) classList.push('landscape-card-v2--selected');
    if (props.imageOnly) classList.push('landscape-card-v2--image-only');
    if (props.description) classList.push('landscape-card-v2--description-animation');
    if (!props.disableAnimation) classList.push('landscape-card-v2--animated');
    if (props.isPortrait) classList.push('landscape-card-v2--portrait');
    if (props.disableExpand) classList.push('landscape-card-v2--disable-expand');
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

const LandscapeCardComponent: React.FunctionComponent<Props> = (props) => {
    const isDarkTheme = useIsDarkTheme();
    const className = getClassName(props, isDarkTheme);
    const backgroundColor = BackgroundColor.SECONDARY;
    const shadow = props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT;
    const iconFlavour = isDarkTheme ? IconFlavour.SOLID_DARK : IconFlavour.SOLID_LIGHT;
    const { onClick, onHover } = Focus.useFocusable({
        id: props.id,
        ctx: props.ctx,
        isFocused: props.isFocused,
        disabled: props.disabled,
        onEnter: props.onEnter,
        onHover: props.onHover,
        onBlur: props.onBlur,
        onFocus: props.onFocus,
    });

    const description = useRef<HTMLDivElement>(null);
    const content = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!content.current || !description.current) return;
        const height = description.current.clientHeight;
        content.current.style.setProperty('--content-description', `${height}px`);
    }, []);

    return (
        <Div className={className} backgroundColor={BackgroundColor.PRIMARY} shadow={shadow} onClick={onClick} onMouseOver={onHover}>
            {!props.disableSelector && (
                <LandscapeCardSelector
                    isVisible={!!props.isSelectMode && (props.isFocused || !!props.isSelected)}
                    isSelected={props.isSelectMode && props.isSelected}
                />
            )}
            <Image
                className="landscape-card-v2__image"
                src={props.image}
                objectFit={ObjectFit.CONTAIN}
                variant={props.imageVariant ?? Variant.BASE}
                assetType={props.assetType}
                noLoading
                width={416}
                height={234}
            />
            {!!props.channelId && <ChannelLogo className="landscape-card-v2__channel-logo" id={props.channelId} theme={Theme.Type.Dark} />}
            {!props.imageOnly && (
                <Div ref={content} className="landscape-card-v2__content" backgroundColor={backgroundColor} shadow={Shadow.NONE}>
                    <div className="landscape-card-v2__meta-container">
                        {(props.availableUntil && props.contentMarker === ContentMarkerType.Recorded) ||
                        props.contentMarker === ContentMarkerType.Rented ? (
                            <ContentMarker
                                className="landscape-card-v2__content-marker"
                                marker={props.contentMarker}
                                label={getTimeGapInDays(props.availableUntil)}
                            />
                        ) : (
                            <ContentMarker className="landscape-card-v2__content-marker" marker={props.contentMarker} />
                        )}

                        {props.progressBar && (
                            <ProgressBar
                                className="landscape-card-v2__progress-bar"
                                color={props.progressColor}
                                progress={props.progress ?? 0}
                            />
                        )}
                    </div>
                    <div className="landscape-card-v2__header-container">
                        <Text
                            className="landscape-card-v2__header"
                            typeface={Typeface.SERIF}
                            size={FontSize.BODY_2}
                            weight={FontWeight.BOLD}
                            color={Color.PRIMARY}
                        >
                            {props.title}
                        </Text>
                        {props.icon}
                        {props.icons?.map((name) => (
                            <Icon key={name} className="landscape-card-v2__icon" name={name} flavour={iconFlavour} />
                        ))}
                    </div>
                    {!!props.description && (
                        <Text
                            ref={description}
                            className="landscape-card-v2__description"
                            typeface={Typeface.SANS}
                            size={FontSize.CAPTION_1}
                            weight={FontWeight.REGULAR}
                            color={Color.SECONDARY}
                        >
                            {props.description}
                        </Text>
                    )}
                </Div>
            )}
        </Div>
    );
};

export const LandscapeCard = React.memo(LandscapeCardComponent) as typeof LandscapeCardComponent;
