import { SearchField } from 'App/Modules/Screen/Modules/Search/Components/SearchField';
import { SearchLayout } from 'App/Modules/Screen/Modules/Search/Components/SearchLayout';
import { SearchNotFound } from 'App/Modules/Screen/Modules/Search/Components/SearchNotFound';
import { SearchResults } from 'App/Modules/Screen/Modules/Search/Components/SearchResults';

export const Search = Object.assign(SearchLayout, {
    Field: SearchField,
    Results: SearchResults,
    NotFound: SearchNotFound,
});
