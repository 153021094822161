import { Subscribable } from 'App/Packages/Subscribable';
import { Record } from 'App/Packages/Subscribable/Subscribable';
import { HeaderItemValue } from 'App/Packages/UI/Header/Modules/HeaderItem/Context/HeaderItemContext';
import { useEffect } from 'react';

export type UseHeaderItemsFocused = (
    items: Record<HeaderItemValue>,
    initial: string | undefined,
    disabled: Subscribable.Value<boolean>
) => void;

export const useHeaderItemsFocused: UseHeaderItemsFocused = (items, initial, disabled) => {
    const item = Subscribable.useItem(items, initial);

    useEffect(() => {
        const update = async () => {
            if (!item) return;
            item.scrollIntoView();
            item.focus();
        };

        update();

        return disabled.subscribe(update);
    }, [item, disabled]);
};
