import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectRemaingLockedDaysForDevice } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import translate from 'language/translate';

interface Props {
    deviceId: string;
}

export const TimeLockedDescriptionText: React.FunctionComponent<Props> = (props) => {
    const lockedDays = useSelector(selectRemaingLockedDaysForDevice(props.deviceId));
    const lockedDaysText = `${lockedDays} ${lockedDays === 1 ? translate('MORE_DAY') : translate('MORE_DAYS')}`;
    const text = translate('SETTINGS_DEVICE_MANAGEMENT_LOCKED_DESCRIPTION').split('%locked_time%');
    return (
        <>
            {text[0]}
            <b>{lockedDaysText}</b>
            {text[1]}
        </>
    );
};
