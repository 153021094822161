import React from 'react';

import {
    CollectionV2HeaderActions,
    CollectionV2HeaderActionsModule,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActions';
import {
    CollectionV2HeaderActionsContainer,
    CollectionV2HeaderActionsContainerModule,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsContainer';
import {
    CollectionV2ActionButtonModule,
    CollectionV2HeaderButton,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderButton';
import {
    CollectionV2HeaderTitle,
    CollectionV2HeaderTitleModule,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderTitle';

export type CollectionV2HeaderProps = {};

export type CollectionV2HeaderModule = {
    Title: CollectionV2HeaderTitleModule;
    ActionsContainer: CollectionV2HeaderActionsContainerModule;
    Actions: CollectionV2HeaderActionsModule;
    Button: CollectionV2ActionButtonModule;
} & React.FunctionComponent<React.PropsWithChildren<CollectionV2HeaderProps>>;

export const CollectionV2Header: CollectionV2HeaderModule = (props) => <div className="collection-v2__header">{props.children}</div>;

CollectionV2Header.Title = CollectionV2HeaderTitle;
CollectionV2Header.ActionsContainer = CollectionV2HeaderActionsContainer;
CollectionV2Header.Actions = CollectionV2HeaderActions;
CollectionV2Header.Button = CollectionV2HeaderButton;
