import React from 'react';

import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';

const getClassName = (isLarge?: boolean) => {
    const classlist = ['portrait-card-v3__info'];
    if (isLarge) classlist.push('portrait-card-v3__info--large');
    return classlist.join(' ');
};

export type CardPortraitInfoProps = DivPropsExtendable & {};

export type CardPortraitInfoComponent = React.FunctionComponent<CardPortraitInfoProps>;

export const CardPortraitInfo: CardPortraitInfoComponent = (props) => {
    const card = useCardPortrait();
    const { children, ...rest } = props;
    return (
        <Div {...rest} defaultClassName={getClassName(card.isLarge)}>
            {card.isFocused ? children : null}
        </Div>
    );
};
