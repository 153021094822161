import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { UI } from 'App/Packages/UI';
import { Detail } from 'App/Modules/Data/Detail';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { FolderHeaderAction } from 'App/Modules/Screen/Modules/DetailVodSeriesScreen/View/Components/FolderHeaderAction';

type Props = {};

type FolderHeaderComponent = React.FunctionComponent<Props>;

export const FolderHeader: FolderHeaderComponent = () => {
    const dispatch = useDispatch();

    const groupTitles = useSelector(Detail.VodSeries.selectors.selectGroupingTitles);
    const selectedGroupTitle = useSelector(Detail.VodSeries.selectors.selectSelectedGroupingTitle);

    return (
        <UI.Folder.Header className="detail-series__seasons-tab">
            <Text
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
                className="detail-series__title"
            >
                SCREEN_DETAIL_SEASON_TAB_SELECTOR
            </Text>
            <UI.Folder.Detail.Actions id="series-actions">
                {groupTitles.map((title) => (
                    <FolderHeaderAction
                        key={title}
                        title={title}
                        isSelected={selectedGroupTitle === title}
                        onEnter={() => dispatch(Detail.VodSeries.actions.folderHeaderSelectedAction(title))}
                    />
                ))}
            </UI.Folder.Detail.Actions>
        </UI.Folder.Header>
    );
};
