import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { MessageAttachmentType } from '__SMART_APP_OLD__/app/gql/gql';
import {
    selectMessageActions,
    selectMessageAttachment,
    selectMessageAttachmentIds,
    selectMessageDate,
    selectMessageHeader,
    selectMessageImage,
    selectMessageLabel,
    selectMessageText,
    selectMessageValidUntil,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import { MessageActionEntity, MessageAttachmentEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import {
    MESSAGE_DETAIL_CLOSE_ACTION,
    MESSAGE_DETAIL_FULLSCREEN_ACTION,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import {
    MessageDetailAttachmentViewStatus,
    MessageDetailContainer,
    MessageDetailContainerType,
    MessageDetailLayoutType,
    MessageDetailPlayerStatus,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Screen } from 'App/Modules/Screen';
import { Calc } from 'App/Packages/Calc';

export const selectMessageDetailMessageId = (state: State): GQL.MessageID => {
    const screen = Screen.selectors.selectTyped(Screen.Type.MESSAGE_DETAIL)(state);
    if (!screen) return '';
    return screen.props.messageId;
};

export const selectMessageDetailShouldRenderAttachmentContainer = (state: State): boolean => {
    const messageId = selectMessageDetailMessageId(state);
    const attachmentIds = selectMessageAttachmentIds(messageId)(state);
    const image = selectMessageImage(messageId)(state);
    return !!image || !!attachmentIds?.length;
};

export const selectMessageDetailLayoutType = (state: State): MessageDetailLayoutType => state.messageDetail.layout;

export const selectMessageDetailIsPlayerPreview = (state: State): boolean =>
    state.messageDetail.layout === MessageDetailLayoutType.PLAYER_PREVIW;

export const selectMessageDetailIsAttachmentView = (state: State): boolean =>
    state.messageDetail.layout === MessageDetailLayoutType.ATTACHMENT;

export const selectMessageDetailTheme = (state: State): Theme.Type | null =>
    selectMessageDetailIsPlayerPreview(state) ? Theme.Type.Dark : null;

export const selectMessageDetailHeaderLines = (state: State): 1 | 2 => state.messageDetail.header;

export const selectMessageDetailTextLayout = (state: State): MessageDetailContainer[] => state.messageDetail.textLayout;

export const selectMessageDetailVisibleContainerIndex = (state: State): number => state.messageDetail.visibleTextContainerIndex;

export const selectMessageDetailActiveAttachmenId = (state: State): GQL.VODAssetID | null => state.messageDetail.activeAttachmentId;

export const selectMessageDetailAttachmentStatus = (state: State): MessageDetailAttachmentViewStatus =>
    state.messageDetail.attachmentViewStatus;

export const selectMessageDetailPlayerStatus = (state: State): MessageDetailPlayerStatus => state.messageDetail.playerStatus;

export const selectMessageDetailHeader = (state: State) => selectMessageHeader(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailText = (state: State) => selectMessageText(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailActions = (state: State): MessageActionEntity[] => {
    const actions = selectMessageActions(selectMessageDetailMessageId(state))(state);
    const isPlayerView = selectMessageDetailIsPlayerPreview(state);
    if (isPlayerView) return [MESSAGE_DETAIL_FULLSCREEN_ACTION, MESSAGE_DETAIL_CLOSE_ACTION, ...actions];
    return actions;
};

export const selectMessageDetailHasActions = (state: State): boolean => !!selectMessageDetailActions(state).length;

export const selectMessageDetailDate = (state: State): string => selectMessageDate(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailLabel = (state: State): string => selectMessageLabel(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailExpireDays = (state: State): number => {
    const time = Time.selectors.select(state);
    const validUntil = selectMessageValidUntil(selectMessageDetailMessageId(state))(state);
    const expiry = validUntil - time;
    return Math.floor(expiry / Time.DAY_MS) + Calc.clamp(0, expiry % Time.DAY_MS, 1);
};

export const selectMessageDetailExpiration = (state: State): string =>
    Translation.selectors
        .select('MESSAGE_DETAIL_EXPIRATION')(state)
        .replace('%count%', `${selectMessageDetailExpireDays(state) || 1}`);

export const selectMessageDetailImage = (state: State): string | null => selectMessageImage(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailAttachmentsIds = (state: State): GQL.MessageAttachmentID[] =>
    selectMessageAttachmentIds(selectMessageDetailMessageId(state))(state);

export const selectMessageDetailHasAttachments = (state: State): boolean => !!selectMessageDetailAttachmentsIds(state)?.length;

export const selectMessageDetailHasScrollTriggers = (state: State): boolean =>
    selectMessageDetailTextLayout(state).some((container) => container.type === MessageDetailContainerType.SCROLL);

export const selectMessageDetailAttachment = (id: GQL.MessageAttachmentID) => (state: State): MessageAttachmentEntity | null =>
    selectMessageAttachment(selectMessageDetailMessageId(state), id)(state);

export const selectMessageDetailAttachmentImage = (id: GQL.MessageAttachmentID) => (state: State): string | null =>
    selectMessageDetailAttachment(id)(state)?.image ?? null;

export const selectMessageDetailAttachmentTitle = (id: GQL.MessageAttachmentID) => (state: State): string =>
    selectMessageDetailAttachment(id)(state)?.title ?? '';

export const selectMessageDetailAttachmentIcon = (id: GQL.MessageAttachmentID) => (state: State): IconName =>
    selectMessageDetailAttachment(id)(state)?.type === GQL.MessageAttachmentType.Image ? IconName.IMAGE : IconName.PLAY;

export const selectMessageDetailFirstPlayableAttachment = (state: State): MessageAttachmentEntity | null => {
    const ids = selectMessageDetailAttachmentsIds(state);
    if (!ids.length) return null;
    const vodId = ids.find((id) => {
        const attachment = selectMessageDetailAttachment(id)(state);
        return attachment?.type === MessageAttachmentType.Vodasset && !!attachment.trailers.length;
    });
    if (!vodId) return null;
    return selectMessageDetailAttachment(vodId)(state);
};

export const selectMessageDetailShouldRenderActiveAttachmentImage = (): boolean => true;

export const selectMessageDetailActiveAttachmentImage = (state: State): string | null =>
    selectMessageDetailAttachmentImage(selectMessageDetailActiveAttachmenId(state) ?? '')(state);

export const selectMessageDetailIsAttachmentViewAttachmentsVissible = (state: State): boolean =>
    selectMessageDetailAttachmentStatus(state) === MessageDetailAttachmentViewStatus.VISIBLE;
