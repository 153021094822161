import { Env } from 'App/Env';

export const STORAGE_KEY = `a1-app-local-storage-${Env.Opco}-${Env.Environment}`;

export enum Key {
    USER_ID = 'userId',
    USER_NAME = 'userName',
    USER_SESSION_TOKEN = 'userSessionToken',
    REFRESH_TOKEN = 'refreshToken',
    DEVICE_ID = 'deviceId',
    HOUSEHOLD_ID = 'householdId',
    PLAYBACK_SESSION_ID = 'playbackSessionId',
    LANGUAGE = 'language',
    PREFERENCES = 'preferences',
    FALLBACK_CONFIG = 'fallbackConfig',
    PARENTAL_RATING_RANK = 'parentalRatingRank',
    SHOW_LIVE_CLOCK = 'showLiveClock',
    MY_LIBRARY_ADULT_CONTENT_VISIBILITY = 'myLibraryAdultContentVisibility',
    LIVE_CHANNEL_ID = 'liveChannelId',
    STARTUP_SCREEN = 'startupScreen',
    MESSAGE_TRIGGER_TABLE = 'messageTriggerTable',
    IDP_ACCESS_TOKEN = 'idpAccessToken',
    IDP_REFRESH_TOKEN = 'idpRefreshToken',
    IS_IDP_LOGIN = 'isIdpLogin',
    EDUCATION_STEP_COMPLETED = 'educationStepCompleted',
    APP_VERSION = 'appVersion',
    HOUSEHOLD_EXTERNAL_ID = 'householdExternalId',
}

const parseLocalStorageData = (string: string | null): Partial<Record<Key, any>> => {
    try {
        if (!string) return {};
        return JSON.parse(string);
    } catch (error) {
        return {};
    }
};

const storage = parseLocalStorageData(localStorage.getItem(STORAGE_KEY));

const save = () => localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));

export const get = <T = any>(key: Key): T | undefined => storage[key];

export const set = <T = any>(key: Key, value: T) => {
    storage[key] = value;
    save();
};

export const unset = (key: Key) => {
    delete storage[key];
    save();
};
