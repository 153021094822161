import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type TrailerFragment = { __typename?: 'Trailer', id: string, title: string };

export const TrailerFragmentDoc = gql`
    fragment trailer on Trailer {
  id
  title
}
    `;