import { DetailActionValue } from 'App/Packages/UI/Detail/Modules/DetailAction/Context/DetailActionContext';
import { useDetailActionFocused } from 'App/Packages/UI/Detail/Modules/DetailAction/Hooks/Private/useDetailActionFocused';
import { useDetailActionFocus } from 'App/Packages/UI/Detail/Modules/DetailAction/Hooks/Private/useDetailActionFocus';

import { DetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions';
import { useMemo } from 'react';
import { useDetailActionEnter } from 'App/Packages/UI/Detail/Modules/DetailAction/Hooks/Private/useDetailActionEnter';
import { Subscribable } from 'App/Packages/Subscribable';

export type UseDetailActionValue = (payload: UseDetailActionValuePayload) => DetailActionValue;
export type UseDetailActionValuePayload = {
    id: string;
    onEnter?: (id: string) => void;
    onFocus?: (id: string) => void;
};

export const useDetailActionValue: UseDetailActionValue = (payload) => {
    const { id, onEnter } = payload;
    const actions = DetailActions.use();
    const focused = useDetailActionFocused(id);
    const focus = useDetailActionFocus(id);
    const enter = useDetailActionEnter(id, onEnter);
    Subscribable.useRegister(actions.ids, id);

    return useMemo(() => ({ id, ctx: actions.ctx, focused, focus, enter }), [actions.ctx, enter, focus, focused, id]);
};
