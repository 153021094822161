import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Detail } from 'App/Modules/Data/Detail';
import { UI } from 'App/Packages/UI';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { FolderHeader } from 'App/Modules/Screen/Modules/DetailVodSeriesScreen/View/Components/FolderHeader';
import { DetailVodSeriesActions } from 'App/Modules/Screen/Modules/DetailVodSeriesScreen/Store/actions';

type FolderComponentProps = {
    initialFocusedItemId?: string;
};

type FolderComponent = React.FunctionComponent<FolderComponentProps>;

export const Folder: FolderComponent = (props) => {
    const dispatch = useDispatch();

    const groupTitles = useSelector(Detail.VodSeries.selectors.selectGroupingTitles);
    const groupingInfoItemIds = useSelector(Detail.VodSeries.selectors.selectGroupingInfoItemIds);

    if (!groupTitles.length) return null;

    return (
        <UI.Folder id="series-folder">
            <FolderHeader />
            <UI.Folder.Items
                onEnter={(itemId) => dispatch(DetailVodSeriesActions.public.folderItemSelected(itemId))}
                initial={props.initialFocusedItemId}
            >
                {groupingInfoItemIds.map((itemId) => (
                    <FolderCardItem
                        key={itemId}
                        selector={Detail.VodSeries.selectors.selectAssetById(itemId)}
                        onFocus={(id) => dispatch(Detail.VodSeries.actions.focusedItemChanged(id))}
                    />
                ))}
            </UI.Folder.Items>
        </UI.Folder>
    );
};
