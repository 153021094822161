import React, { useCallback } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';
import { Focus } from 'App/Packages/Focus';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/actions';
import { UI } from 'App/Packages/UI/index';

type CollectionScreenBannerItemProps = CollectionItemProps;

const CollectionScreenCardBannerComponent: React.FunctionComponent<CollectionScreenBannerItemProps> = (props) => {
    const dispatch = useDispatch();

    const thumbnail = useSelector(Collection.selectors.selectEntityThumbnail(props.id));
    const parentalRatingId = useSelector(Collection.selectors.selectEntityParentalRatingId(props.id));
    const entityType = useSelector(Collection.selectors.selectEntityType(props.id));

    const enterHandler = useCallback(() => dispatch(actions.public.enterTriggered(props.id, entityType)), [entityType, dispatch, props.id]);

    const { onClick, onHover } = Focus.useFocusable({ ...props, onEnter: enterHandler });

    return (
        <UI.Card.Banner
            onHover={onHover}
            onClick={onClick}
            isFocused={props.isFocused}
            sessionType={PinSessionType.PIN}
            parentalRating={parentalRatingId}
            className={props.className}
        >
            <UI.Card.Banner.Thumbnail src={thumbnail} />
        </UI.Card.Banner>
    );
};

export const CollectionScreenCardBanner = React.memo(CollectionScreenCardBannerComponent);
