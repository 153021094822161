import React from 'react';

import { CollectionV2Grid, CollectionV2GridModule } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2Grid';
import { CollectionV2Header, CollectionV2HeaderModule } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2Header';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

export type CollectionV2Props = {};

export type CollectionV2Module = {
    Header: CollectionV2HeaderModule;
    Grid: CollectionV2GridModule;
} & React.FunctionComponent<React.PropsWithChildren<CollectionV2Props>>;

export const CollectionV2: CollectionV2Module = (props) => (
    <Layout className="collection-v2" backgroundColor={BackgroundColor.PRIMARY}>
        {props.children}
    </Layout>
);

CollectionV2.Header = CollectionV2Header;
CollectionV2.Grid = CollectionV2Grid;
