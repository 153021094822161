import React from 'react';

import { HeaderItemContext, HeaderItemContextModule } from 'App/Packages/UI/Header/Modules/HeaderItem/Context/HeaderItemContext';
import { UseHeaderItem, useHeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/useHeaderItem';
import { UseHeaderItemValue, useHeaderItemValue } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/useHeaderItemValue';

export type HeaderItemProps = React.PropsWithChildren<{
    id: string;
    onEnter?: (id: string) => void;
}>;

export type HeaderItemModules = {
    // Context
    Context: HeaderItemContextModule;

    // Hooks
    use: UseHeaderItem;
    useValue: UseHeaderItemValue;
};

export type HeaderItemModule = React.FunctionComponent<HeaderItemProps> & HeaderItemModules;

export const HeaderItem: HeaderItemModule = (props) => {
    const item = HeaderItem.useValue({ id: props.id, enter: props.onEnter });
    return <HeaderItem.Context.Provider value={item}>{props.children}</HeaderItem.Context.Provider>;
};

// Context
HeaderItem.Context = HeaderItemContext;

// Hooks
HeaderItem.use = useHeaderItem;
HeaderItem.useValue = useHeaderItemValue;
