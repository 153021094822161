/* eslint-disable complexity */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-statements */
import * as Sentry from '@sentry/browser';
import { Overlay } from 'App/Modules/Overlay';
import {
    checkToken,
    checkTokenValidity,
    getDeviceLessToken,
    idpLoginCheckToken,
    idpLoginRefreshToken,
    idpLoginRevokeToken,
    loginUser,
    logoutSdsevo,
    playChannel,
    refreshToken,
    sessionHeartBeat,
} from '__SMART_APP_OLD__/api/Services';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { LogoutDocument } from '__SMART_APP_OLD__/api/graphql/mutation/logout.generated';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { selectIdpProviderActive } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import {
    AuthSessionLogActionUarAction,
    AuthSessionStatus,
    AuthSessionLegacyCallbacksTimeoutAction,
    AuthSessionStatusChangeAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import { notificationActiveStatusChange } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import Player from '__SMART_APP_OLD__/platforms/Player';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { LoggingService } from 'analytics/loggingService';
import { Screen } from 'App/Modules/Screen';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';
import { loadInit } from '__SMART_APP_OLD__/app/store/actions/data/init/init.actions';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { Route } from '__SMART_APP_OLD__/utils/Constants';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { ErrorCode } from '__SMART_APP_OLD__/app/modules/Error/types';
import { DeleteForeignDeviceDocument } from '__SMART_APP_OLD__/api/graphql/mutation/deleteForeignDevice.generated';
import { CheckForeignDeviceDeletionStatusDocument } from '__SMART_APP_OLD__/api/graphql/query/checkForeignDeviceDeleteStatus.generated';
import { ForeignDeviceDeletionStatus, HouseholdOnboardingInfo } from '__SMART_APP_OLD__/api/graphql/types';
import { delay } from '__SMART_APP_OLD__/utils/Utils';
import { CreateDeviceDocument } from '__SMART_APP_OLD__/api/graphql/mutation/createDevice.generated';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { loginErrors, graphQlErrors } from '__SMART_APP_OLD__/utils/errorHandling';
import { Env } from 'App/Env';
import { Alert } from 'App/Modules/Alert';
import { AlertType } from 'App/Modules/Alert/Types';
import { KeepSessionAliveDocument } from '__SMART_APP_OLD__/api/graphql/mutation/keepAlive.generated';
import { AccountDevicesDocument } from '__SMART_APP_OLD__/app/api/idpLogin/query/accountDevices.generated';
import { API } from '__SMART_APP_OLD__/app/api';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import { isErrorTenBulgaria, deviceNotExistingInHousehold } from './constants';
import { pinActiveStatusChanged, pinActiveStatusLiveTvChanged, pinBlockedChanged } from '../pin/actions';
import { Household } from '../Household';
import { TrackViewingBehaviour } from '../TrackViewingBehaviour';
import { maxNumberOfUnmanagedDevicesChange } from '../deviceEntityTable/actions';
import { parentalRatingIdChange } from '../parentalRatingEntityTable/actions';
import { Consent } from '../Consent';
import { ConsentInitLoad } from '../Consent/types';

export const authSessionStatusChange = (status: AuthSessionStatus): AuthSessionStatusChangeAction => ({
    type: ActionType.AUTH_SESSION_STATUS_CHANGE,
    payload: { status },
});

export const setLogAuthActionInUar = (logAction: boolean, isLoginWithCode: boolean): AuthSessionLogActionUarAction => ({
    type: ActionType.AUTH_SESSION_LOG_LOGIN_UAR,
    payload: { logAction, isLoginWithCode },
});

export const authSessionLegacyCallbackTimeoutChange = (legacyCallbacksInterval: number): AuthSessionLegacyCallbacksTimeoutAction => ({
    type: ActionType.AUTH_SESSION_START_LEGACY_CALLBACKS,
    payload: { legacyCallbacksInterval },
});

export const loginLegacyAction =
    (username: string, password: string): Thunk<Promise<boolean>> =>
    async (dispatch: any) => {
        try {
            dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
            dispatch(clearAction());
            // we prepared the state of the app now we want to start login process
            // here we want to handle exceptions and show alerts if login not ok
            dispatch(authSessionStatusChange(AuthSessionStatus.LOGIN_IN_PROGRESS));
            dispatch(setLogAuthActionInUar(true, false));
            Storage.set(Storage.Key.IS_IDP_LOGIN, false);
            GraphqlClient.initGraphQLClient();
            // handle login and all possible variations of login responses
            const loginResult = await loginUser(username, password);
            if (loginResult?.error_code === ErrorCode.ERROR_CLIENT_DEVICE_NOT_FOUND) {
                // handle if device not existing case we make a deviceless login
                // and then create the device and call again the login action
                // as we need to login again and we returned early the previous time
                await dispatch(deviceLessLogin(username, password));
                return dispatch(createDevice(username, password));
            }
            const isErrorTen = isErrorTenBulgaria(loginResult?.error_code);
            if (!isErrorTen && loginResult?.error_code) {
                // we handle the straight case for all opcos if login is not ok
                // we throw the error and show the alert, in the try catch,
                // same for Bulgaria if it is not the so called error 10
                throw new Error(loginResult.error_code);
            }
            if (isErrorTen) {
                // we handle the specific case for Bulgaria if login is not ok because of the device
                // if error it will throw the error and show the alert in the try catch
                // we have to log with the so called deviceless login to get temp token
                // if successful we can delete the device from its current
                // household and then login with the current user
                await dispatch(deviceLessLogin(username, password));
                return await dispatch(deleteDeviceAndCreateDevice(username, password));
            }
            // if we got here we know we have the login correct we save the token
            // and proceed to create the other tokens as per the legacy flow
            Storage.set(Storage.Key.REFRESH_TOKEN, loginResult.token);
            Storage.set(Storage.Key.IS_IDP_LOGIN, false);
            await refreshToken();
            // if everything moves through we are started we can call fti start
            // which will check the screens
            // if we need to show something and then call the startUserSessionAction
            // which will load the information
            // and start the callbacks for keepSessionAlive and SessionHB in the legacy flow
            dispatch(authSessionStatusChange(AuthSessionStatus.LOGGED_IN));
            await dispatch(startLegacyCallbacksAction());
            await dispatch(loadUserDataAfterAuthentication());
            return true;
        } catch (e) {
            dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
            console.log(`[LOGIN ERROR] ${JSON.stringify(e)}`);
            dispatch(Overlay.actions.unmount());
            dispatch(logoutAction());
            GraphqlClient.stopClient();
            // handle showing the error instead of the component
            dispatch(handleAuthErrorAction(e));
            return false;
        }
    };

export const handleIdpLoginFlowAction =
    (idpAccessToken: string, idpRefreshToken: string): Thunk<Promise<void>> =>
    async (dispatch: any) => {
        try {
            // here we know idp login has started we set tbe basics
            // and user actually authenticated against the idp provider now we need to handle
            // the start flow
            Storage.set(Storage.Key.IDP_ACCESS_TOKEN, idpAccessToken);
            Storage.set(Storage.Key.IDP_REFRESH_TOKEN, idpRefreshToken);
            Storage.set(Storage.Key.IS_IDP_LOGIN, true);
            dispatch(authSessionStatusChange(AuthSessionStatus.IDP_LOGIN_IN_PROGRESS));
            dispatch(setLogAuthActionInUar(true, true));
            GraphqlClient.initGraphQLClient();
            // now we need ot handle all the possible cases
            // to start the flow we need to start with me query to tell
            // sdsevo this is idp login flow
            // and then we need to check if the user and device are in the same household
            const devices = await GraphqlClient.makeGraphqlRequest(AccountDevicesDocument, {}, { fetchPolicy: 'no-cache' });
            // we have two options either it is ok response or errored response
            // first we handle if error device not existing response
            // we either have data object or errors array
            // handle device not found
            const error = devices?.errors ? devices?.errors[0]?.errorCode : '';
            const deviceNotFound = deviceNotExistingInHousehold(error);
            if (deviceNotFound) {
                // we don't need to make deviceless login ect
                // we have the bearer token the idp flow is activated
                // we just need to create the device
                // if something happens we just throw the error and handle it in the catch
                return await dispatch(createDevice(idpAccessToken, idpRefreshToken));
            }
            // handle error 10
            const isErrorTen = isErrorTenBulgaria(error);
            if (!isErrorTen && error) {
                // we handle the straight case for all opcos if login is not ok
                // we throw the error and show the alert, in the try catch,
                // same for Bulgaria if it is not the so called error 10
                throw new Error(error);
            }
            if (isErrorTen) {
                // we handle the specific case for Bulgaria if login is not ok because of the device
                // if error it will throw the error and show the alert in the try catch
                return await dispatch(deleteDeviceAndCreateDevice(idpAccessToken, idpRefreshToken));
            }
            // we handled all the logic now we can start the application
            dispatch(authSessionStatusChange(AuthSessionStatus.LOGGED_IN));
            Storage.set(Storage.Key.IS_IDP_LOGIN, true);
            return await dispatch(loadUserDataAfterAuthentication());
        } catch (error) {
            // same as legacy flow if error happens we handle it
            // and then redirect to login screens
            // and show overlay from the action not from the component
            dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
            console.log(`[LOGIN ERROR] ${JSON.stringify(error)}`);
            dispatch(Overlay.actions.unmount());
            dispatch(logoutAction());
            GraphqlClient.stopClient();
            // handle showing the error instead of the component
            return dispatch(handleAuthErrorAction(error));
        }
    };

export const initializeSessionAction = (): Thunk<Promise<void>> => async (dispatch: any) => {
    try {
        const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN) ?? false;
        dispatch(authSessionStatusChange(AuthSessionStatus.INITIALISING_SESSION));
        if (isIdpLogin) {
            // handle idp
            const isIdpTokenValid = await idpLoginCheckToken();
            if (!isIdpTokenValid) {
                await idpLoginRefreshToken();
            }
        } else {
            // handle legacy
            const token = Storage.get(Storage.Key.USER_SESSION_TOKEN);
            const isTokenValid = await checkTokenValidity(token);
            if (!isTokenValid) {
                await refreshToken();
            }
            // we need to start the callbacks for keepSessionAlive and SessionHB
            await dispatch(startLegacyCallbacksAction());
        }
        // handle data flow full profile info if all ok and then start the fti
        dispatch(authSessionStatusChange(AuthSessionStatus.LOGGED_IN));
        dispatch(setLogAuthActionInUar(false, isIdpLogin));
        return dispatch(loadUserDataAfterAuthentication());
    } catch (e) {
        // we don't want to show alerts here if login not ok, if graphql show some overlays is ok,
        // but we manually have no desire we will either log you in silently or log you out silently
        dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
        return dispatch(logoutAction());
    }
};

export const refreshFlowAction = (): Thunk<Promise<void>> => async (dispatch: any) => {
    // explicitly not using try catch here, as we want the observable to handle it in it try catch
    // if no exception then token was refreshed correctly
    // if exception occurred then we will handle it in the observable
    // and redirect to login screen and call logout
    dispatch(authSessionStatusChange(AuthSessionStatus.REFRESHING_TOKEN));
    const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN) ?? false;
    // we handle either idp or legacy refresh flow
    if (isIdpLogin) {
        await idpLoginRefreshToken();
    } else {
        await refreshToken();
    }
    dispatch(authSessionStatusChange(AuthSessionStatus.LOGGED_IN));
};

export const logoutAction = (): Thunk<Promise<void>> => async (dispatch: any, getState) => {
    try {
        const state = getState();
        dispatch(Overlay.actions.unmount());
        const idpLogin = selectIdpProviderActive(state);
        dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
        Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
        GraphqlClient.stopClient();
        await LoggingService.getInstance().closeLoggingSession();
        await Player.destroy();
        CustomHistory.clear();
        const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN);
        if (!isIdpLogin) {
            await GraphqlClient.makeGraphqlMutationRequest(LogoutDocument, {});
            await logoutSdsevo();
            const refreshTokenLocalStorage = Storage.get(Storage.Key.REFRESH_TOKEN);
            if (refreshTokenLocalStorage) {
                await checkToken(refreshTokenLocalStorage);
                await logoutSdsevo();
            }
        } else {
            const accessToken = Storage.get(Storage.Key.IDP_ACCESS_TOKEN);
            const currentRefreshToken = Storage.get(Storage.Key.IDP_REFRESH_TOKEN);
            await idpLoginRevokeToken('access_token', accessToken);
            await idpLoginRevokeToken('refresh_token', currentRefreshToken);
            await logoutSdsevo();
        }
        await GraphqlClient.destroyClient();
        const redirect = !History.selectors.select(state).some((entity) => entity.screen?.type === Screen.Type.LOGIN_WITH_EMAIL);
        dispatch(clearAction());
        if (redirect && idpLogin) {
            dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}));
            return;
        }
        if (redirect) {
            dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}));
        }
    } catch (error) {
        console.log(`[LOGOUT ERROR] logout threw ${JSON.stringify(error)}`);
        const state = getState();
        const redirect = !History.selectors.select(state).some((entity) => entity.screen?.type === Screen.Type.LOGIN_WITH_EMAIL);
        dispatch(Overlay.actions.unmount());
        dispatch(clearAction());
        if (redirect) {
            const idpLogin = selectIdpProviderActive(state);
            idpLogin
                ? dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}))
                : dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}));
        }
    }
};

export const startUserSessionAction = (): Thunk<Promise<void>> => async (dispatch: any, getState) => {
    try {
        const state = getState();
        const userId = Profile.selectors.selectUserId(state);
        Sentry.setTag('userId', userId);
        Sentry.setTag('deviceId', Platform.ID);
        GraphqlClient.initGraphQLClient();
        return dispatch(loadInit).then(() => {
            const startupScreen = Startup.selectors.select(state);
            const liveChannelId = selectChannelLiveId(state);
            dispatch(notificationActiveStatusChange(true));
            CustomHistory.clear();
            if (startupScreen === Startup.Position.Live) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY_LIVE, { id: liveChannelId }));
                CustomHistory.go(`/page/${Route.HOME}`);
                return playChannel({ input: { channelId: liveChannelId }, profileId: Profile.selectors.selectId(state) });
            }

            return CustomHistory.go(`/page/${Route.HOME}`);
        });
    } catch (e) {
        console.log(`[STARTUSERSESSIONERROR] ${JSON.stringify(e)}`);
        dispatch(authSessionStatusChange(AuthSessionStatus.NOT_LOGGED_IN));
        return dispatch(logoutAction());
    }
};

export const clearAction = (): Thunk<void> => (dispatch) => {
    Storage.unset(Storage.Key.USER_ID);
    // Storage.unset(Storage.Key.deviceId); if we clear it creates problem when you try to login
    Storage.unset(Storage.Key.USER_SESSION_TOKEN);
    Storage.unset(Storage.Key.HOUSEHOLD_ID);
    Storage.unset(Storage.Key.REFRESH_TOKEN);
    Storage.unset(Storage.Key.PREFERENCES);
    Storage.unset(Storage.Key.IDP_ACCESS_TOKEN);
    Storage.unset(Storage.Key.IDP_REFRESH_TOKEN);
    Storage.unset(Storage.Key.IS_IDP_LOGIN);
    dispatch(pinActiveStatusChanged(false));
    dispatch(pinActiveStatusLiveTvChanged(false));
    dispatch(pinBlockedChanged(-1));
};

export const deviceLessLogin =
    (username: string, password: string): Thunk<Promise<void>> =>
    async (dispatch: any) => {
        dispatch(authSessionStatusChange(AuthSessionStatus.DEVICELESS_LOGIN));
        const devicelessTokenResponse = await getDeviceLessToken(username, password);
        if (devicelessTokenResponse?.error_code) {
            throw new Error(devicelessTokenResponse.error_code);
        }
        // here we know deviceless login is ok now we need to delete the device
        // which is async operation that can take a lot of time,
        // in reality it passes for about 1.5 - 2 secs.
        // we temporarily set the token to the deviceless token
        Storage.set(Storage.Key.USER_SESSION_TOKEN, devicelessTokenResponse.token);
    };

export const deleteDeviceAndCreateDevice =
    (username: string, password: string): Thunk<Promise<void>> =>
    async (dispatch: any) => {
        dispatch(authSessionStatusChange(AuthSessionStatus.HANDLING_ERROR_10));
        // we make the delete device request if error we just throw we can't do anything else
        const deleteResult = await GraphqlClient.makeGraphqlMutationRequest(DeleteForeignDeviceDocument, {
            input: { clientGeneratedDeviceId: Platform.ID },
        });
        if (deleteResult?.errors) {
            throw new Error(deleteResult.errors[0].errorCode);
        }
        // if we got here we know the device is being deleted
        // but we need to wait until it is deleted
        // I hate that it has to be with recursion but it is the only way to do it,
        // as we need to poll until ok
        const success = await dispatch(deleteResultAction());
        if (success) {
            return dispatch(createDevice(username, password));
        }
        // if delete action does not end successfully we throw an error to handle on the upper level
        throw new Error(ErrorCode.ERROR_SEC_TOKEN_CUSTOM);
    };

export const createDevice =
    (username: string, password: string): Thunk<Promise<void>> =>
    async (dispatch: any) => {
        const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN) ?? false;
        try {
            // we need to create the device but to create the device we need to get and languages;
            dispatch(authSessionStatusChange(AuthSessionStatus.CREATE_DEVICE_IN_PROGRESS));
            await dispatch(Language.actions.load());
            const code = Language.selectors.selectDefaultCode(store.getState());
            const languageId = Language.selectors.selectIdByCode(code)(store.getState());
            const createDeviceInput = {
                input: {
                    clientGeneratedDeviceId: Platform.ID,
                    deviceType: Platform.DEVICE_TYPE,
                    name: Platform.DEVICE_NAME,
                    languageId,
                },
            };
            // handle create device if it errored or not if error throw the error
            const deviceCreateResult = await GraphqlClient.makeGraphqlMutationRequest(CreateDeviceDocument, createDeviceInput);
            if (deviceCreateResult?.errors || !deviceCreateResult?.data?.createDevice?.success) {
                throw new Error(deviceCreateResult.errors[0].errorCode);
            }
            if (isIdpLogin) {
                // in this case username password ad idp access
                // and refresh token we pass it from above;
                return dispatch(handleIdpLoginFlowAction(username, password));
            }
            await GraphqlClient.makeGraphqlMutationRequest(LogoutDocument, {});
            Storage.unset(Storage.Key.USER_SESSION_TOKEN);
            return dispatch(loginLegacyAction(username, password));
        } catch (error) {
            // @ts-ignore
            if (!isIdpLogin && error?.message === ErrorCode.HOUSEHOLD_DEVICE_LIMIT_REACHED) {
                dispatch(History.actions.clear());
                dispatch(History.actions.push({ overlay: null, screen: Screen.generator(Screen.Type.LOGIN, { username, password }) }));
                return dispatch(Screen.actions.mount(Screen.Type.DEVICE_MANAGEMENT, {}));
            }
            console.log(`[DEVICE CREATE ERROR] DEVICE CREATE threw ${JSON.stringify(error)}`);
            return undefined;
        }
    };

export const deleteResultAction = (): Thunk<Promise<boolean>> => async (dispatch: any) => {
    await delay(1500);
    const deleteResult = await GraphqlClient.makeGraphqlRequest(CheckForeignDeviceDeletionStatusDocument, {
        clientGeneratedDeviceId: Platform.ID,
    });
    if (!deleteResult) return false;
    const result = deleteResult.data.checkForeignDeviceDeletionStatus.status;
    if (result === ForeignDeviceDeletionStatus.Deleted) {
        return true;
    }
    // if not allowed is the response we return false;
    if (result === ForeignDeviceDeletionStatus.NotAllowed) {
        return false;
    }
    return dispatch(deleteResultAction());
};

export const handleAuthErrorAction =
    (error: any): Thunk<Promise<void>> =>
    async (dispatch: any) => {
        const showAlert = (params: any) => {
            dispatch(
                Alert.actions.mount(Alert.Type.LoginError, {
                    title: params.title,
                    text: params.text,
                    image: params.image,
                })
            );
        };
        switch (error?.message) {
            case ErrorCode.DEVICE_NOT_LINKED_TO_SAME_HOUSEHOLD:
            case ErrorCode.USER_NOT_LINKED_TO_CURRENT_HOUSEHOLD:
            case ErrorCode.ERROR_NOT_SAME_HOUSHOLD:
                showAlert(loginErrors.notSameHouseholdError());
                break;
            case ErrorCode.ERROR_ACCOUNT_LOCKED:
                showAlert(loginErrors.lockedAccountError());
                break;
            case ErrorCode.ERROR_CLIENT_DEVICE_NOT_ENABLED:
            case ErrorCode.ERROR_ACCOUNT_DISABLED:
                showAlert(loginErrors.disabledAccountError());
                break;
            case ErrorCode.ERROR_HOUSHOLD_NOT_ENABLED:
                showAlert(loginErrors.disabledHouseholdError());
                break;
            case ErrorCode.ERROR_DEV_TYPE_REQ_AUTH:
                showAlert(loginErrors.deviceRequireAuthenticationError());
                break;
            case ErrorCode.ERROR_SINGLE_HOUSEHOLD_FOR_DEVICE:
                showAlert(loginErrors.deviceHouseholdError());
                break;
            case ErrorCode.ERROR_SINGLE_HOUSEHOLD_FOR_USER:
                showAlert(loginErrors.userNotMemberOFHouseholdError());
                break;
            case ErrorCode.ERROR_CLIENT_DEVICE_NOT_FOUND:
                showAlert(loginErrors.deviceNotFoundError());
                break;
            case ErrorCode.ERROR_MUST_CHANGE_PASSWORD:
                showAlert(loginErrors.changePasswordError());
                break;
            case ErrorCode.ERROR_BAD_USER_NAME_OR_PASSWORD:
                showAlert(loginErrors.failed());
                break;
            case ErrorCode.ERROR_NO_SESSION:
            case ErrorCode.SDS_EVO_ERROR_LIC_LIMIT_EXCEEDED:
            case ErrorCode.ERROR_MAM_REFR_SESSIONS:
            case ErrorCode.ERROR_EXPIRED_SEC_TOKEN:
            case ErrorCode.ERROR_SEC_TOKEN_SESSION:
            case ErrorCode.ERROR_BAD_SEC_TOKEN:
            case ErrorCode.ERROR_SEC_TOKEN_CUSTOM:
                showAlert(loginErrors.tokenError());
                break;
            case ErrorCode.ERROR_DOMAIN_NOT_RESOLVED:
            case ErrorCode.DEVICE_ALREADY_EXISTS:
            case ErrorCode.BACKEND_SESSION_HEADER_MISSING: {
                const id = Env.IsCroatia && Platform.ID ? Platform.ID : null;
                showAlert({
                    ...graphQlErrors('SCREEN_LOGIN_ALERT_LOGIN_FAILED', 'ERROR_ACCOUNT_PROVISIONING', id).tvError,
                });
                break;
            }
            case ErrorCode.BE_TIMEOUT_ERROR:
                // this can happen with error 10 in Bulgaria
                // if we don't reply in time on delete device callback
                showAlert(loginErrors.errorTenError());
                break;
            case ErrorCode.HOUSEHOLD_DEVICE_LIMIT_REACHED:
            case ErrorCode.ERROR_MAX_DEVICES_REACHED:
            case ErrorCode.ERROR_LIC_LIMIT_EXCEEDED:
            case ErrorCode.ERROR_DEVICE_CREATE_FAILED:
                // or if errMaxHoushDevs we already show the graphql error:
                // deviceCreateFailed -> observe what happens we may need
                // to remove this one at one point
                // for now on this 3 cases we don't show anything for now.
                // as it cause strange behavior
                break;
            default:
                if (error?.networkError?.statusCode === 401) {
                    showAlert(loginErrors.failed());
                } else {
                    dispatch(Alert.actions.mount(AlertType.BackEndDoesntWork, {}));
                }
                break;
        }
    };

export const startLegacyCallbacksAction = (): Thunk<Promise<void>> => async (dispatch: any) => {
    const result = await GraphqlClient.makeGraphqlMutationRequest(KeepSessionAliveDocument, {});
    const callbacksInterval = result?.data?.keepSessionAlive?.sessionTimeout;
    await sessionHeartBeat();
    if (callbacksInterval) {
        dispatch(authSessionLegacyCallbackTimeoutChange(callbacksInterval * 1000));
    }
};

export const loadUserDataAfterAuthentication = (): Thunk<Promise<void>> => async (dispatch: any) => {
    // we know we have correct auth here
    // so we now get the user info and set the data in the reducers
    const userData = await API.Init.loadMe();
    Preferences.audioLanguagePrimary = userData.profilePreferences?.firstAudioLanguage;
    Preferences.audioLanguageSecondary = userData.profilePreferences?.secondAudioLanguage;
    Preferences.subtitleLanguagePrimary = userData.profilePreferences?.firstSubtitleLanguage;
    Preferences.subtitleLanguageSecondary = userData.profilePreferences?.secondSubtitleLanguage;
    Storage.set(Storage.Key.USER_NAME, userData.userName);
    Storage.set(Storage.Key.USER_ID, userData.userId);
    Storage.set(Storage.Key.HOUSEHOLD_ID, userData.householdId);
    Storage.set(Storage.Key.HOUSEHOLD_EXTERNAL_ID, userData.householdExternalId);
    Storage.set(Storage.Key.PARENTAL_RATING_RANK, userData.parentalRatingRank);
    dispatch(
        Profile.actions.initialLoad({
            preferences: userData.profilePreferences!,
            userId: userData.userId,
            profileId: userData.profileId,
            userName: userData.userName,
            profiles: userData.profiles,
            masterPinCode: userData.masterPinCode,
            dataUsageAllowed: userData.dataUsageAllowed,
        })
    );
    dispatch(Household.actions.mqttBrokerUrlChanged(userData.mqttBrokerUrl!));
    dispatch(Household.actions.mqttTopicsChanged(userData.mqttTopics!));
    dispatch(
        Household.actions.initialLoad({
            householdId: userData.householdId,
            maxNumberOfConfirmedReplayChannels: userData.maxNumberOfConfirmedReplayChannels,
            onboardingInfo: userData.householdOnboardingInfo! as HouseholdOnboardingInfo,
        })
    );
    const data = userData?.profilePreferences?.consents.reduce((acc, v) => {
        if (!v) return acc;
        const index = v.type as keyof ConsentInitLoad;
        acc[index] = { accepted: v.accepted };
        return acc;
    }, {} as ConsentInitLoad);
    dispatch(Consent.actions.initLoad(data!));
    dispatch(TrackViewingBehaviour.actions.changed(userData.dataUsageAllowed));
    dispatch(parentalRatingIdChange(userData.parentalRatingId));
    dispatch(maxNumberOfUnmanagedDevicesChange(userData.maxNumberOfUnmanagedDevices));
    dispatch(
        Fti.actions.setupInitialFTIValuesAfterAuthentication({
            masterPincodeStepCompleted: userData.masterPincodeStepCompleted ? new Date(userData.masterPincodeStepCompleted).getTime() : 0,
            communityStepCompleted: userData.communityStepCompleted ? new Date(userData.communityStepCompleted).getTime() : 0,
            replayStepCompleted: userData.replayStepCompleted ? new Date(userData.replayStepCompleted).getTime() : 0,
            ageRatingStepCompleted: userData.ageRatingStepCompleted ? new Date(userData.ageRatingStepCompleted).getTime() : 0,
            privacyStepCompleted: userData.privacyStepCompleted ? new Date(userData.privacyStepCompleted).getTime() : 0,
        })
    );
    // we loaded me data we have everything we need to start the app
    await dispatch(Fti.actions.start());
};
