import { Engine } from 'App/Packages/Focus/Engine';
import { KeyDownEvent } from 'App/Packages/Focus/Events/KeyDownEvent';

export class KeyDownEngine extends Engine<KeyDownEvent> {
    private static readonly instance: KeyDownEngine = new KeyDownEngine();

    private constructor() {
        super();
        this.attach();
    }

    public static readonly getInstance = () => KeyDownEngine.instance;

    private readonly attach = () => document.addEventListener('keydown', this.emitter);

    private readonly emitter = (event: KeyboardEvent) => this.emit(new KeyDownEvent(event.keyCode));
}
