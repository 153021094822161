import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';

const change = (): Thunk<void> => (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.CommunitySelect, {}));
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(Community.actions.load());
    dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    public: {},
    private: {
        change,
    },
    lifecycle: {
        mount,
        unmount,
    },
} as const;
