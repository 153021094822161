import { RecordingManagementCard } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/RecordingManagementCard';
import React from 'react';

import { LandscapeCardGrid } from '__SMART_APP_OLD__/app/components/LandscapeCardGrid';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectCurrentRecordings } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { RecordingManagementFocusContext } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';

export const RecordingManagementGrid: React.FunctionComponent = () => {
    const recordingIds = useSelector(selectCurrentRecordings);

    return <LandscapeCardGrid ctx={RecordingManagementFocusContext.GRID} Card={RecordingManagementCard} items={recordingIds} />;
};
