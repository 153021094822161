import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialLanguageState } from '__SMART_APP_OLD__/app/modules/Language/store/constants';
import { LanguageActionType, LanguageEntityTable } from '__SMART_APP_OLD__/app/modules/Language/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const languageReducer: Reducer<LanguageEntityTable> = (state, action) => {
    if (!state) return initialLanguageState;

    switch (action.type) {
        case LanguageActionType.LOADED: {
            if (!action.payload.languages.length) return state;
            const codes = Mapper.Language.toCodes(action.payload.languages);
            const idForCode = Mapper.Language.toIdForCode(action.payload.languages);
            return { ...state, codes, idForCode };
        }
        case LanguageActionType.CHANGED: {
            if (state.selected === action.payload.code) return state;
            return { ...state, selected: action.payload.code };
        }
        default:
            return state;
    }
};
