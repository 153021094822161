import { initialCollectionState } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/constants';
import { collectionMapper } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/mapper';
import { CollectionActionType, CollectionEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const collectionReducer: Reducer<CollectionEntityTable> = (state, action) => {
    if (!state) return initialCollectionState;
    switch (action.type) {
        case CollectionActionType.LOADED: {
            const ids = collectionMapper.toIds(action.payload.response.items);
            const entities = collectionMapper.toEntities(action.payload.response.items);
            return {
                ...state,
                id: action.payload.response.id,
                title: action.payload.response.title,
                kind: action.payload.response.kind,
                ids,
                entities,
            };
        }
        case CollectionActionType.RESET:
            return initialCollectionState;
        default:
            return state;
    }
};
