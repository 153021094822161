import { App } from 'App/Modules/App';

export class CoreAppEngine extends App.Engine {
    public readonly close: () => void = (): void => window.close();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public readonly onBack = (_event: KeyboardEvent): void => {};

    public readonly getIsInputActive = (): boolean => {
        if (!document.activeElement) return false;
        const overwirteBack = document.activeElement.getAttribute('data-overwrite-back') === 'true';
        return !overwirteBack && document.activeElement.tagName === 'INPUT';
    };
}
