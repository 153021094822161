import {
    Folder,
    FolderActionType,
    FolderList,
    FolderListActionType,
    FolderListLoadedAction,
    FolderListResetAction,
    FolderLoadedAction,
    FolderResetAction,
} from 'App/Modules/Folder/Types';

const folderLoaded =
    (hash: string) =>
    (data: Folder): FolderLoadedAction => ({
        type: FolderActionType.Loaded,
        payload: { data, hash },
    });

const folderReset = (hash: string) => (): FolderResetAction => ({
    type: FolderActionType.Reset,
    payload: { hash },
});

const folderListLoaded =
    (hash: string) =>
    (data: FolderList): FolderListLoadedAction => ({
        type: FolderListActionType.Loaded,
        payload: { data, hash },
    });

const folderListReset = (hash: string) => (): FolderListResetAction => ({
    type: FolderListActionType.Reset,
    payload: { hash },
});

export const actions = {
    loaded: folderLoaded,
    reset: folderReset,
    list: {
        loaded: folderListLoaded,
        reset: folderListReset,
    },
};
