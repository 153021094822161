import { IssueEvent, IssueSeverities, IssueTypes } from 'analytics/logging/events/IssueEvent';

export interface GQLCustomError {
    errorCode: string;
    debugMessage: string;
}

export const getGraphQLIssueEvent = (graphQLError: GQLCustomError, operationName: string, variables: unknown = {}): IssueEvent | null => {
    if (!graphQLError) return null;
    const { errorCode: code, debugMessage: detail } = graphQLError;
    const message = 'GraphQL error';
    const severity = IssueSeverities.ERROR;
    const type = IssueTypes.SERVER_MIDDLEWARE;
    const context = `GraphQL: ${operationName}${JSON.stringify(variables)
        .replace(/['"]+/g, '')
        .replace(/[:]+/g, ': ')
        .replace(/[,]+/g, ', ')}`; // remove quotes and add a space after colons and commas, to be consistent with other platforms
    return new IssueEvent(code, message, severity, type, context, detail);
};

export const getPinLockoutIssueEvent = (): IssueEvent => {
    const code = 'PIN_LOCKED';
    const message = 'PIN code locked out';
    const severity = IssueSeverities.WARN;
    const type = IssueTypes.UI;
    return new IssueEvent(code, message, severity, type);
};

// NOT USED
// export const getDeviceCrashIssueEvent = ({ message, stack }, pathname) => {
//     const code = 'DEVICE_CRASHED';
//     const severity = IssueSeverities.FATAL;
//     const type = IssueTypes.DEVICE;
//     return new IssueEvent({ code, message, severity, type, context: pathname, detail: stack });
// };

export interface PlaybackError {
    code: string;
    detail: string;
}

export const getPlaybackIssueEvent = (playbackError: PlaybackError, playbackUrl: string): IssueEvent | null => {
    if (!playbackError) return null;
    const { code, detail } = playbackError;
    return new IssueEvent(code, 'Player error', IssueSeverities.ERROR, IssueTypes.VIDEO, 'Player', detail, playbackUrl);
};

export const getMetricsIssueEvent = (maxRetriesExceeded: number, eventsDroppedFromBuffer: number): IssueEvent => {
    const severity = IssueSeverities.INFO;
    const type = IssueTypes.DEVICE;
    const code = 'EVENT_METRICS';
    const message = `${maxRetriesExceeded},${eventsDroppedFromBuffer}`;
    return new IssueEvent(code, message, severity, type);
};
