import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ProfilePermissionsFragmentDoc } from './profilePermissions.generated';
import { ProfilePreferencesFragmentDoc } from './profilePreferences.generated';
import { ProfileOnboardingInfoFragmentDoc } from './profileOnboardingInfo.generated';
export type ProfileFragment = { __typename?: 'Profile', id: string, kind: Types.ProfileKind, name: string, permissions: { __typename?: 'ProfilePermissions', maskContent: boolean, parentalRating: { __typename?: 'ParentalRating', id: string, title: string, rank: number, adult: boolean } }, preferences: { __typename?: 'ProfilePreferences', trackViewingBehaviour: boolean, firstAudioLanguage: string, firstSubtitleLanguage: string, secondAudioLanguage: string, secondSubtitleLanguage: string, consents: Array<{ __typename?: 'ProfileConsent', accepted: boolean, type: any } | null> }, onboardingInfo: { __typename?: 'ProfileOnboardingInfo', ageRatingStepCompleted?: any | null, privacyStepCompleted?: any | null } };

export const ProfileFragmentDoc = gql`
    fragment profile on Profile {
  id
  kind
  permissions {
    ...profilePermissions
  }
  name
  preferences {
    ...profilePreferences
  }
  onboardingInfo {
    ...profileOnboardingInfo
  }
}
    ${ProfilePermissionsFragmentDoc}
${ProfilePreferencesFragmentDoc}
${ProfileOnboardingInfoFragmentDoc}`;