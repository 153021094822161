import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Overlay } from 'App/Modules/Overlay';
import { Actions } from 'App/Modules/Overlay/Modules/AudioSelectOverlay/Store/Actions';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.AudioSelect>> = (props) => {
    const dispatch = useDispatch();
    const ids = props.audios.map((audioItem) => audioItem.id);

    const onClose = props.onClose || (() => dispatch(Overlay.actions.unmount()));

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={onClose}>
            <UI.Select.Header>
                <UI.Select.Title>{Translation.Key.Overlay.Select.Audios.Title}</UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options ids={ids} selected={props.currentAudioTrack?.id} onEnter={(id) => dispatch(Actions.private.selected(id))}>
                <UI.Select.Options.Children>
                    {(id) => (
                        <UI.Select.Option id={id} key={id} disableDismiss>
                            <UI.Selector selector={() => dispatch(Actions.private.getAudioNameById(id))} />
                        </UI.Select.Option>
                    )}
                </UI.Select.Options.Children>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
