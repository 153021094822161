import React, { useContext, useEffect } from 'react';

import { ImageContext, Status } from 'App/Packages/UI/Image/ImageContext';
import { ImageClassNameProps } from 'App/Packages/UI/Image/Utils';

export const enum ObjectFit {
    Cover = 'Cover',
    Contain = 'Contain',
}

export type ImageImgProps = ImageClassNameProps & {
    hidden?: boolean;
    objectFit?: ObjectFit;
    src?: string;
    alt?: string;
    width?: number;
    height?: number;
};

export type ImageImgModule = React.FunctionComponent<ImageImgProps>;

const classNameForObjectFit: Record<ObjectFit, string> = {
    [ObjectFit.Contain]: 'image-v3__img--contain',
    [ObjectFit.Cover]: 'image-v3__img--cover',
};

const getClassName = (props: ImageImgProps): string => {
    const classList = ['image-v3__img'];
    classList.push(classNameForObjectFit[props.objectFit ?? ObjectFit.Contain]);
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const ImageImg: ImageImgModule = (props) => {
    const { status, setStatus } = useContext(ImageContext);

    useEffect(() => {
        if (!props.src) setStatus(Status.Error);
        else if (props.hidden) setStatus(Status.Error);
        else setStatus(Status.Loading);
    }, [props.src, props.hidden, setStatus]);

    if (props.hidden) return null;
    if (status === Status.Error) return null;
    if (!props.src) return null;

    return (
        <img
            key={props.src}
            className={getClassName(props)}
            src={props.src}
            alt={props.alt ?? 'image'}
            width={props.width}
            height={props.height}
            decoding="async"
            loading="lazy"
            onLoad={() => setStatus(Status.Success)}
            onError={() => setStatus(Status.Error)}
        />
    );
};
