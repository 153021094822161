import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { deviceLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/actions';
import { selectDevice } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { Utils } from '__SMART_APP_OLD__/app/utils';

import { Alert } from 'App/Modules/Alert';
import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';

const back = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.pop());
};

const selected = (id: GQL.DeviceID): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const device = selectDevice(id)(state);
    if (!device) return;
    const time = Time.selectors.select(state);
    if (Utils.isDeviceElidgableForDelete(device, time)) {
        dispatch(Prompt.actions.mount(Prompt.Type.DeviceConfirmDelete, { deviceId: device.id }));
        return;
    }
    dispatch(Alert.actions.mount(Alert.Type.DeviceCannotDelete, { deviceId: device.id }));
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(deviceLoad());
    dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    public: {},
    private: {
        back,
        selected,
    },
    lifecycle: {
        mount,
        unmount,
    },
} as const;
