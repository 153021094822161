import React from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { LoginFlowSelectorConstants } from 'App/Modules/Screen/Modules/LoginFlowSelector/Constants';
import { LoginFlowSelectorOption } from 'App/Modules/Screen/Modules/LoginFlowSelector/Types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const LoginFlowSelectorOptions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { focused, focus } = Focus.useList({ ctx: Focus.Default, axis: Axis.Y, items: LoginFlowSelectorConstants.Options });

    return (
        <>
            <Button
                className="login-flow-selector-screen__button"
                id={LoginFlowSelectorOption.Code}
                ctx={Focus.Default}
                isFocused={focused === LoginFlowSelectorOption.Code}
                onEnter={() => dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_CODE, {}))}
                onHover={focus}
            >
                LOGIN_IDP_LOGIN_TEXT
            </Button>
            <Button
                className="login-flow-selector-screen__button"
                id={LoginFlowSelectorOption.Email}
                ctx={Focus.Default}
                isFocused={focused === LoginFlowSelectorOption.Email}
                onEnter={() => dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}))}
                onHover={focus}
            >
                LOGIN_USERNAME_AND_PASSWORD_TEXT
            </Button>
        </>
    );
};
