import { Screen } from 'App/Modules/Screen';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

const selectQuery: Selector<string> = (state) => Screen.selectors.selectTyped(Screen.Type.Search)(state)?.props.query ?? '';

export const selectors = {
    private: {
        selectQuery,
    },
} as const;
