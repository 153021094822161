import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Api } from 'App/Modules/Data/Detail/Root/Api';
import {
    LoadVodAssetDetailDocument,
    LoadVodAssetDetailFragment,
    LoadVodAssetDetailQueryResult,
    LoadVodAssetDetailQueryVariables,
} from 'App/Modules/Data/Detail/VodAsset/Api/vodAssetDetailLoad.generated';

export { DefaultVariables } from 'App/Modules/Data/Detail/VodAsset/Api/defaultVariables';

type LoadVodAsset = (variables: LoadVodAssetDetailQueryVariables) => Promise<LoadVodAssetDetailFragment | null>;

const __parseLoadVodAsset = (response: LoadVodAssetDetailQueryResult) => response.data || null;

export const load: LoadVodAsset = async (variables) => {
    const response: LoadVodAssetDetailQueryResult = await GraphqlClient.makeGraphqlRequest(
        LoadVodAssetDetailDocument,
        variables,
        Api.detailQueryOptions
    );
    Error.collect(response);
    return __parseLoadVodAsset(response);
};
