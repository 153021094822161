import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    ChannelEntity,
    ChannelReplayPermissionRelationEntityTable,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';
import {
    selectChannelLiveId,
    selectSelectedChannelListChannelIds,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

export const selectChannelLiveIndex = (state: State): number =>
    selectSelectedChannelListChannelIds(state).indexOf(selectChannelLiveId(state));

export const selectIsChannelIdValid =
    (id: GQL.ChannelID) =>
    (state: State): boolean =>
        !!state.data.channelEntityTable.entities[id];

export const selectChannelSwitchId = (state: State): GQL.ChannelID => state.data.channelEntityTable.channelSwitchId;

export const selectShouldCancelChannelStream =
    (channelId: GQL.ChannelID) =>
    (state: State): boolean => {
        const channelSwitchId = selectChannelSwitchId(state);
        return !!channelSwitchId && channelSwitchId !== channelId;
    };

export const selectChannelIds = (state: State): GQL.ChannelID[] => state.data.channelEntityTable.ids;

export const selectChannel =
    (id: GQL.ChannelID) =>
    (state: State): ChannelEntity | null =>
        state.data.channelEntityTable.entities[id] ?? null;

export const selectChannelName =
    (id: GQL.ChannelID) =>
    (state: State): string =>
        selectChannel(id)(state)?.title ?? '';

export const selectChannelLiveTVEntitlement =
    (id: GQL.ChannelID) =>
    (state: State): boolean =>
        selectChannel(id)(state)?.liveTV ?? false;

export const selectLiveChannelLiveTVEntitilement = (state: State): boolean =>
    selectChannelLiveTVEntitlement(selectChannelLiveId(state))(state);

export const selectChannelLogo =
    (id: GQL.ChannelID, theme: Theme.Type) =>
    (state: State): string | null =>
        selectChannel(id)(state)?.logo?.[theme] ?? null;

export const selectChannelNetworkRecording =
    (id: GQL.ChannelID) =>
    (state: State): boolean =>
        selectChannel(id)(state)?.networkRecording ?? false;
export const selectChannelCatchupTv =
    (id: GQL.ChannelID) =>
    (state: State): boolean =>
        selectChannel(id)(state)?.catchupTV ?? false;

export const selectChannelDefaultNumber =
    (id: string) =>
    (state: State): number =>
        selectChannel(id)(state)?.defaultNumber ?? 0;

export const selectChannelsReplayPermission = (state: State): ChannelReplayPermissionRelationEntityTable =>
    state.data.channelEntityTable.replayPermissionRelationEntityTable;

export const selectChannelPauseLiveTv = (id: GQL.ChannelID) => (state: State) => !!selectChannel(id)(state)?.pauseLiveTV;

export const selectReplayEnabledChannelsOrderedByDefaultNumber = (state: State): GQL.ChannelID[] =>
    selectChannelIds(state)
        .filter((channelId) => selectChannelPauseLiveTv(channelId)(state))
        .sort((prevId, nextId) => selectChannelDefaultNumber(prevId)(state) - selectChannelDefaultNumber(nextId)(state));
