import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardLandscapeTitleProps) => {
    const classlist = ['landscape-card-v3__title'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeTitleProps = React.PropsWithChildren<{
    className?: string;
}>;

export type CardLandscapeTitleComponent = React.FunctionComponent<CardLandscapeTitleProps>;

export const CardLandscapeTitle: CardLandscapeTitleComponent = (props) => (
    <Text className={getClassName(props)} typeface={Typeface.SERIF} size={FontSize.BODY_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
        <Mask.Title>{props.children}</Mask.Title>
    </Text>
);
