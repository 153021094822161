import React from 'react';
import { shallowEqual } from 'react-redux';

import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectTVGuideEvents } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { TVGuideEventCell } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideEventCell';

interface Props {
    channelId: GQL.ChannelID;
}

export const TVGuideEventList: React.FunctionComponent<Props> = (props) => {
    const eventIds = useSelector(selectTVGuideEvents(props.channelId), shallowEqual);

    return (
        <div className="tv-guide__event-list">
            {eventIds.map((id) => (
                <TVGuideEventCell key={id} eventId={id} />
            ))}
        </div>
    );
};
