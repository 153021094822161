import { useCallback } from 'react';

import { Folder } from 'App/Packages/UI/Folder/Modules/Folder';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';

export type UseFolderItemsFocus = (focus: (id: string) => void) => (id: string) => void;

export const useFolderItemsFocus: UseFolderItemsFocus = (focus) => {
    const folder = Folder.use();
    const folderList = FolderList.use();

    return useCallback(
        (id) => {
            const isFolderFocused = folderList.focused.get() === folder.id;
            if (!isFolderFocused) folderList.focus(folder.id);
            focus(id);
        },
        [folder, folderList, focus]
    );
};
