import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { FavouritesFragmentDoc } from '../fragments/favourites.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FavouritesLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FavouritesLoadQuery = { __typename?: 'Nexx4Queries', favourites?: { __typename?: 'FavouritableItemCatalog', items: Array<{ __typename?: 'Event', id: string } | { __typename?: 'NetworkRecording', id: string } | { __typename?: 'VODAsset', id: string } | { __typename?: 'VODProduct', id: string } | null> } | null };

export type FavouritesLoadFragment = { __typename?: 'Nexx4Queries', favourites?: { __typename?: 'FavouritableItemCatalog', items: Array<{ __typename?: 'Event', id: string } | { __typename?: 'NetworkRecording', id: string } | { __typename?: 'VODAsset', id: string } | { __typename?: 'VODProduct', id: string } | null> } | null };

export const FavouritesLoadFragmentDoc = gql`
    fragment favouritesLoad on Nexx4Queries {
  favourites {
    ...favourites
  }
}
    ${FavouritesFragmentDoc}`;
export const FavouritesLoadDocument = gql`
    query favouritesLoad {
  ...favouritesLoad
}
    ${FavouritesLoadFragmentDoc}`;

/**
 * __useFavouritesLoadQuery__
 *
 * To run a query within a React component, call `useFavouritesLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavouritesLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavouritesLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useFavouritesLoadQuery(baseOptions?: Apollo.QueryHookOptions<FavouritesLoadQuery, FavouritesLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FavouritesLoadQuery, FavouritesLoadQueryVariables>(FavouritesLoadDocument, options);
      }
export function useFavouritesLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FavouritesLoadQuery, FavouritesLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FavouritesLoadQuery, FavouritesLoadQueryVariables>(FavouritesLoadDocument, options);
        }
export function useFavouritesLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FavouritesLoadQuery, FavouritesLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FavouritesLoadQuery, FavouritesLoadQueryVariables>(FavouritesLoadDocument, options);
        }
export type FavouritesLoadQueryHookResult = ReturnType<typeof useFavouritesLoadQuery>;
export type FavouritesLoadLazyQueryHookResult = ReturnType<typeof useFavouritesLoadLazyQuery>;
export type FavouritesLoadSuspenseQueryHookResult = ReturnType<typeof useFavouritesLoadSuspenseQuery>;
export type FavouritesLoadQueryResult = Apollo.QueryResult<FavouritesLoadQuery, FavouritesLoadQueryVariables>;