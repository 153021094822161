import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodSeriesFolderItemFragment = { __typename: 'VODSeries', id: string, title: string, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export const VodSeriesFolderItemFragmentDoc = gql`
    fragment vodSeriesFolderItem on VODSeries {
  id
  title
  seasonInfos {
    number
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  parentalRating {
    ...parentalRatingRelation
  }
  __typename
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;