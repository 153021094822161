import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { ProfileEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/types';

export const initialProfileState: ProfileEntityTable = {
    profileId: '',
    userId: '',
    userName: '',
    masterPinCode: '',
    profiles: [],
    dataUsageAllowed: true,
    myLibraryAdultContentVisibility: Storage.get<boolean>(Storage.Key.MY_LIBRARY_ADULT_CONTENT_VISIBILITY)! || false,
    showLiveClock: Storage.get<boolean>(Storage.Key.SHOW_LIVE_CLOCK) || false,
    preferences: null,
};
