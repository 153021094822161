import React from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';

import {
    ImageAdult,
    ImageAdultLandscapeModule,
    ImageAdultLandscapeProps,
    ImageAdultModule,
    ImageAdultPortraitModule,
    ImageAdultPortraitProps,
    ImageAdultProps,
    Orientation,
} from 'App/Packages/UI/Image/ImageAdult';
import { ImageProvider } from 'App/Packages/UI/Image/ImageContext';
import { ImageCover, ImageCoverModule, ImageCoverProps } from 'App/Packages/UI/Image/ImageCover';
import { ImageImg, ImageImgModule, ImageImgProps, ObjectFit } from 'App/Packages/UI/Image/ImageImg';
import { ImageLoading, ImageLoadingModule, ImageLoadingProps } from 'App/Packages/UI/Image/ImageLoading';
import { ImageClassNameProps } from 'App/Packages/UI/Image/Utils';

export type {
    ImageAdultLandscapeModule,
    ImageAdultLandscapeProps,
    ImageAdultModule,
    ImageAdultPortraitModule,
    ImageAdultPortraitProps,
    ImageAdultProps,
    ImageCoverModule,
    ImageCoverProps,
    ImageImgModule,
    ImageImgProps,
    ImageLoadingModule,
    ImageLoadingProps,
};

export { ObjectFit, Orientation };

export type ImageProps = ImageClassNameProps & React.PropsWithChildren<{}>;

export type ImageModule = {
    Loading: ImageLoadingModule;
    Adult: ImageAdultModule;
    Cover: ImageCoverModule;
    Img: ImageImgModule;
} & React.FunctionComponent<ImageProps>;

const getClassName = (className?: string): string => {
    const classList = ['image-v3'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export const Image: ImageModule = (props) => (
    <Div className={getClassName(props.className)} backgroundColor={BackgroundColor.PRIMARY}>
        <ImageProvider>{props.children}</ImageProvider>
    </Div>
);

Image.Loading = ImageLoading;
Image.Adult = ImageAdult;
Image.Cover = ImageCover;
Image.Img = ImageImg;
