import React from 'react';

import { useAlertActionsContext } from 'App/Packages/UI/Alert/AlertActionsContext';
import { ChildRenderer, Children } from 'App/Packages/UI/Children';

export type AlertActionsChildrenProps = {
    children: ChildRenderer<string>;
};

export type AlertActionsChildrenModule = {} & React.FunctionComponent<AlertActionsChildrenProps>;

export const AlertActionsChildren: AlertActionsChildrenModule = (props) => {
    const { ids } = useAlertActionsContext();
    return <Children items={ids}>{props.children}</Children>;
};
