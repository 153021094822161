import { useMemo, useRef } from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { FolderItemValue } from 'App/Packages/UI/Folder/Modules/FolderItem/Context/FolderItemContext';
import { useFolderItemEnter } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/Private/useFolderItemEnter';
import { useFolderItemFocus } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/Private/useFolderItemFocus';
import { useFolderItemFocused } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/Private/useFolderItemFocused';
import { useFolderItemScroll } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/Private/useFolderItemScrollIntoView';
import { FolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems';

export type UseFolderItemValuePayload = {
    id: string;
    enter?: (id: string) => void;
    offset?: number;
};

export type UseFolderItemValue = (payload: UseFolderItemValuePayload) => FolderItemValue;

export const useFolderItemValue: UseFolderItemValue = (payload) => {
    const items = FolderItems.use();
    const ref = useRef<HTMLDivElement>(null);
    const { id } = payload;
    const { ctx } = items;
    const focused = useFolderItemFocused(id);
    const enter = useFolderItemEnter(id, payload.enter);
    const focus = useFolderItemFocus(id);
    const scrollIntoView = useFolderItemScroll(ref, id, focused, payload.offset);

    const item = useMemo<FolderItemValue>(
        () => ({ ref, id, ctx, focused, enter, focus, scrollIntoView }),
        [ref, id, ctx, focused, enter, focus, scrollIntoView]
    );

    Subscribable.useRegister(items.ids, id);
    Subscribable.useAdd(items.store, item.id, item);

    return item;
};
