import React from 'react';

import { Constants } from 'App/Packages/UI/Card/CardLandscape/Constants';
import { Image, ObjectFit, Orientation } from 'App/Packages/UI/Image';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardLandscapeThumbnailProps) => {
    const classList = ['landscape-card-v3__thumbnail'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type CardLandscapeThumbnailProps = {
    className?: string;
    src?: string;
};

export type CardLandscapeThumbnailComponent = React.FunctionComponent<CardLandscapeThumbnailProps>;

export const CardLandscapeThumbnail: CardLandscapeThumbnailComponent = (props) => {
    return (
        <Mask>
            {(shouldMask) => (
                <Image className={getClassName(props)}>
                    <Image.Loading hidden={shouldMask} />
                    <Image.Cover hidden={shouldMask} />
                    <Image.Adult orientation={Orientation.Landscape} hidden={!shouldMask} />
                    <Image.Img
                        src={props.src}
                        objectFit={ObjectFit.Contain}
                        width={Constants.Thumbnail.Width}
                        height={Constants.Thumbnail.Height}
                        hidden={shouldMask}
                    />
                </Image>
            )}
        </Mask>
    );
};
