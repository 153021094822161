import React from 'react';

import { Button, ButtonAnimation, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';

export const ClosingScreenView: React.FunctionComponent<Screen.Props<Screen.Type.CLOSING>> = () => {
    const dispatch = useDispatch();
    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <Layout className="closing" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <UI.Logo.XploreTV className="closing__logo" />
            <Button
                id="closing"
                ctx={Focus.Default}
                className="closing__button"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                onHover={Function.noop}
                onEnter={() => dispatch(Fti.actions.next(Screen.Type.CLOSING))}
                isFocused
            >
                CONTINUE_BUTTON
            </Button>
        </Layout>
    );
};
