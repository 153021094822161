import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Switch } from '__SMART_APP_OLD__/app/components/Switch';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { labelForPrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/constants';
import { privacyPolicySelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/selectors';
import { PrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/types';

import { Focus } from 'App/Packages/Focus';

const getClassName = (isFocused: boolean) => {
    const classList = ['privacy-policy__option'];
    if (isFocused) {
        classList.push('privacy-policy__option--focused');
    }
    return classList.join(' ');
};

export const PrivacyPolicyScreenOption: React.FunctionComponent<Focus.Focusable<PrivacyPolicyItemType>> = (props) => {
    const isToggled = useSelector(privacyPolicySelectors.private.selectIsAccepted(props.id));
    const disabled = props?.id === PrivacyPolicyItemType.REQUIRED;

    const { onClick, onHover } = Focus.useFocusable(props);

    Focus.useHorizontalNavigation(() => props.onEnter?.(props.id), { disabled: !props.isFocused || disabled });

    return (
        <Div
            className={getClassName(props.isFocused)}
            backgroundColor={BackgroundColor.PRIMARY}
            shadow={props.isFocused ? Shadow.BUTTON_SELECTED : Shadow.NONE}
            onClick={onClick}
            onMouseDown={onHover}
        >
            <Text
                className="privacy-policy__name"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                color={Color.PRIMARY}
                weight={FontWeight.REGULAR}
            >
                {labelForPrivacyPolicyItemType[props.id]}
            </Text>
            <Switch
                className="privacy-policy__switch"
                isFocused={!disabled ? props.isFocused : false}
                isToggled={isToggled}
                isDisabled={disabled}
                disableBackgroundChange
            />
        </Div>
    );
};
