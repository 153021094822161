export enum ErrorDispatcher {
    MessageLoad = 'Message::Load',
    MessageRead = 'Message::Read',
    MessageRemove = 'Message::Remove',

    DeviceLoad = 'Device::Load',
    DeviceRemove = 'Device::Remove',

    ParentalRatingLoad = 'ParentalRating::Load',
    ParentalRatingChange = 'ParentalRating::Change',

    ChannelListChange = 'ChannelList::Change',
    ChannelListReorder = 'ChannelList::Reorder',
    ChannelListLoad = 'ChannelList::Load',
    ChannelListChannelsLoad = 'ChannelList::Channels::Load',
    ChannelListCreate = 'ChannelList::Create',
    ChannelListRename = 'ChannelList::Rename',
    ChannelListUpdate = 'ChannelList::Update',
    ChannelListRemove = 'ChannelList::Remove',

    CHANNEL_LOAD = 'CHANNEL_LOAD',

    CHANNEL_LIST_CHANNEL_EVENT_LOAD = 'CHANNEL_LIST_CHANNEL_EVENT_LOAD',
    CHANNEL_LIST_CHANNEL_EVENT_LOAD_PRECISE = 'CHANNEL_LIST_CHANNEL_EVENT_LOAD_PRECISE',
    CHANNEL_EVENT_LOAD = 'CHANNEL_EVENT_LOAD',
    CHANNEL_EVENT_LOAD_PRECISE = 'CHANNEL_EVENT_LOAD_PRECISE',
    EVENT_LOAD = 'EVENT_LOAD',

    BOOKMARKS_LOAD = 'BOOKMARKS_LOAD',
    BOOKMARKS_SAVE = 'BOOKMARKS_SAVE',

    REMINDER_CREATE = 'REMINDER_CREATE',
    REMINDER_LOAD = 'REMINDER_LOAD',
    REMINDER_DELETE = 'REMINDER_DELETE',

    RECORDING_LOAD = 'RECORDING_LOAD',
    RECORDING_DELETE = 'RECORDING_DELETE',

    COMMUNITY_LOAD = 'COMMUNITY_LOAD',
    COMMUNITY_CHANGE = 'COMMUNITY_CHANGE',

    CONSENT_SAVE = 'CONSENT_SAVE',

    FAVOURITES_LOAD = 'FAVOURITES_LOAD',
    FAVOURITES_ADD = 'FAVOURITES_ADD',
    FAVOURITES_REMOVE = 'FAVOURITES_REMOVE',
    FAVOURITES_TOGGLE = 'FAVOURITES_TOGGLE',

    LANGUAGE_LOAD = 'LANGUAGE_LOAD',
    LANGUAGE_CHANGE = 'LANGUAGE_CHANGE',

    FTI_START = 'FTI_START',
    FTI_COMPLETE_STEP = 'FTI_COMPLETE_STEP',

    PROFILE_LOAD = 'PROFILE_LOAD',

    FolderLoad = 'Folder::Load',
    FolderListLoad = 'FolderList::Load',

    SearchSuggestionsLoad = 'Search::Suggestions::Load',
    SearchResultsLoad = 'Search::Results::Load',
}
