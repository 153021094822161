import { Record } from 'App/Packages/Subscribable/Core/Record';
import { Add, Remove, Setter, Subscribe, UnSubscribe } from 'App/Packages/Subscribable/Types';

export class MockedRecord<T> extends Record<T> {
    public readonly set: Setter<{ [key: string]: T }> = async () => {};

    public readonly subscribe: Subscribe = () => () => {};

    public readonly unsubscribe: UnSubscribe = () => {};

    public readonly add: Add<T> = () => () => {};

    public readonly remove: Remove = () => {};
}
