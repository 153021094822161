/* eslint-disable max-lines */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { connect } from 'react-redux';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { selectPlayerSkipBackwardStep, selectPlayerSkipForwardStep } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { seekNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { PLAYER_SEEK } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import { NavKey } from '__SMART_APP_OLD__/utils/Constants';
import { calculateLinearProgress } from '__SMART_APP_OLD__/utils/dataUtils';
import State from '__SMART_APP_OLD__/utils/State';
import { convertMilliseconds } from '__SMART_APP_OLD__/utils/timeUtils';
import { debounce } from '__SMART_APP_OLD__/utils/Utils';
import translate from 'language/translate';
import { AdsScippingEngine } from 'App/Modules/AdsScipping';

import { Key } from 'App/Modules/Key';

class LinearProgressBar extends React.Component {
    manualUpdateIntervalId = null;

    nodeRef = React.createRef();

    rangeRef = React.createRef();

    seeking = false;

    // we use this property as buffer to show notification with
    // amount seeked when player ui is not visible
    seekedAmount = 0;

    skipping = false;

    programEnded = false;

    amountToSeekForward = convertMilliseconds(selectPlayerSkipForwardStep(store.getState())) || 60;

    amountToSeekBack = convertMilliseconds(selectPlayerSkipBackwardStep(store.getState())) || 30;

    seekWithDebounce = debounce(() => {
        this.seek();
    }, 1000);

    seekDisabled = false;

    constructor(props) {
        super(props);

        this.state = {
            isPlaying: Player.isPlaying(),
            currentTime: Player.getPlayedTime(),
            seekAmount: 0,
            seekInProgress: false,
            switchStreamInProgress: false,
            seekToStart: this.getShouldPlayFromStart(),
        };

        this.keysToBeHandled = [
            Key.VK_LEFT,
            Key.VK_RIGHT,
            Key.VK_REWIND,
            Key.VK_FAST_FWD,
            Key.VK_ENTER,
            Key.VK_UP,
            Key.VK_CHAN_UP,
            Key.VK_CHAN_DOWN,
        ];
    }

    componentDidMount() {
        Player.addEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.addEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.addEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        // Using VIDEO_EVENTS.STATE_CHANGED instead of VIDEO_EVENTS.SEEKED due to
        // a bug with seeking logic on slow internet.
        // With STATE_CHANGED we make sure the seeking works when the video is already loaded.
        Player.addEventListener(VIDEO_EVENTS.STATE_CHANGED, this.onStateChange);
        Player.addEventListener(VIDEO_EVENTS.SEEKING, this.onBeforeSeek);
        Player.addEventListener(VIDEO_EVENTS.ERROR, this.startManulIntervalUpdateTask);
        Player.addEventListener(VIDEO_EVENTS.LOADEDDATA, this.onStreamLoaded);
        Events.addEventListener(PLAYER_SEEK, this.handlePlayerSeek);
    }

    componentWillUnmount() {
        Player.removeEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.removeEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.removeEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        Player.removeEventListener(VIDEO_EVENTS.STATE_CHANGED, this.onStateChange);
        Player.removeEventListener(VIDEO_EVENTS.SEEKING, this.onBeforeSeek);
        Player.removeEventListener(VIDEO_EVENTS.ERROR, this.startManulIntervalUpdateTask);
        Player.removeEventListener(VIDEO_EVENTS.LOADEDDATA, this.onStreamLoaded);
        Events.removeEventListener(PLAYER_SEEK, this.handlePlayerSeek);
        this.clearManualUpdateIntervalId();
    }

    getShouldPlayFromStart = () => State.hasState(NavKey.PAGE_WITH_PLAYER) && State.get(NavKey.PAGE_WITH_PLAYER).playFromStart;

    handlePlayerSeek = ({ keyCode, eventType }) => {
        this.handleKeyEvent(keyCode, undefined, eventType);
    };

    startManulIntervalUpdateTask = () => {
        if (!this.manualUpdateIntervalId) {
            this.manualUpdateIntervalId = setInterval(this.onTimeUpdate, 1000);
        }
    };

    clearManualUpdateIntervalId = () => {
        clearInterval(this.manualUpdateIntervalId);
        this.manualUpdateIntervalId = null;
    };

    onEnter = () => {
        if (this.state.seekInProgress) return;
        Player.playPause();
    };

    onPlay = () => {
        // Make the actual seeking here to be sure, that the player is in the correct state.
        const { seekAmount } = this.state;
        if (this.aboutToBeSeeked && !this.seeking && seekAmount) {
            this.doSeek();
        }

        this.setState({ isPlaying: true });
        this.clearManualUpdateIntervalId();
        this.seeking = false;
    };

    setSeekToStartFlag = (seekToStart) => {
        this.setState({ seekToStart });
    };

    onSeekToStart = () => {
        this.setSeekToStartFlag(true);
    };

    seekToStart = (debounced = false) => {
        // We need to save timeshift value as big value in the past.
        // Than doSeek function will align with program start time
        const timeshiftPosition = this.currentStreamPosition();
        Player.setTimeshift(timeshiftPosition - 1000000000);
        const seekToStartAmount = this.getSeekToStartAmount();
        const playedTime = Player.getPlayedTime();
        const seekAmount = playedTime === 0 ? this.props.data.leadIn : seekToStartAmount;
        this.setState({ seekAmount, seekToStart: false }, debounced ? this.seekWithDebounce : this.doSeek);
    };

    getSeekToStartAmount = () => {
        const playedTime = this.getCalculatedPlayedTime();
        return playedTime * -1;
    };

    onStreamLoaded = () => {
        const { seekToStart, seekAmount } = this.state;

        if (seekToStart) {
            this.seekToStart();
        } else if (seekAmount) {
            this.setState({ switchStreamInProgress: false }, this.doSeek);
        }
    };

    onPause = () => {
        this.setState({ isPlaying: false });
        Player.setTimeshift(Player.getTimeshift() || Date.now());
        this.startManulIntervalUpdateTask();
    };

    onTimeUpdate = () => {
        const {
            data: { duration },
            onEnded,
        } = this.props;
        let { seekInProgress } = this.state;
        Player.playerHandleTimeUpdate();
        const currentTime = this.getCurrentPlaybackTime();
        this.seekDisabled = Player.isBuffering();
        const isPlaying = Player.isPlaying();
        if (isPlaying && seekInProgress) {
            seekInProgress = false;
        }
        this.setState(
            {
                currentTime,
                isPlaying,
                seekInProgress,
            },
            () => {
                const [, remainingTime] = this.getRawDisplayTime();
                const isCurrentEventEnded = currentTime >= duration / 1000;
                const isCurrentPlaybackEnded = Player.getTimeshift() ? isCurrentEventEnded && remainingTime <= 0 : isCurrentEventEnded;
                if (isCurrentPlaybackEnded && !this.programEnded) {
                    onEnded();
                }
                this.programEnded = isCurrentPlaybackEnded;
            }
        );
    };

    /**
     * Returns amount of time since beginning of current program till live position.
     * Approx equals to (Date.now() - startDateTime.getTime()) / 1000
     * @returns - seconds
     */
    getCurrentPlaybackTime() {
        const playedTimeUTC = this.currentStreamPosition();
        const startTimeUTC = playedTimeUTC < this.props.data?.startDateTime ? playedTimeUTC : this.props.data?.startDateTime;
        return Math.max(this.props.data?.startDateTime ? (Date.now() - startTimeUTC) / 1000 : 0, 0);
    }

    /**
     * Returns amount of time since beginning of current program till playhead.
     * Approx equals to (Player.getPlayedTimeUTC() - startDateTime.getTime()) / 1000.
     * @returns - seconds
     */
    getCalculatedPlayedTime() {
        const startTimeUTC = this.props.data?.startDateTime || Date.now();
        const playedTimeUTC = this.currentStreamPosition();
        return (playedTimeUTC - startTimeUTC) / 1000;
    }

    /**
     * Extracted from this.seek to allow seeking after the player is in a valid state.
     * This method will do the actual seeking, the other ones are only preparing the state.
     */
    // eslint-disable-next-line max-statements
    doSeek = () => {
        const { hasManifestFromStart } = this.props;
        const { seekAmount, switchStreamInProgress } = this.state;

        if (switchStreamInProgress) return;

        const minStartSeekPosition = Player.getMinStartSeekPosition();
        const playedTime = Player.getPlayedTime();
        if (minStartSeekPosition - playedTime > seekAmount && !hasManifestFromStart) {
            const { switchToRestartStream } = this.props;
            if (switchToRestartStream) {
                this.timeshiftBeforeSwitch = Player.getTimeshift();
                switchToRestartStream();
                this.setState({ switchStreamInProgress: true });
                return;
            }
        }

        let timeshiftPosition = this.currentStreamPosition(true) + seekAmount * 1000;
        if (timeshiftPosition < this.props.data?.startDateTime) {
            timeshiftPosition = this.props.data?.startDateTime;
        }

        const jumpToLiveTriggerWindow = 500; // 500ms
        if (timeshiftPosition + jumpToLiveTriggerWindow >= Date.now()) {
            if (this.props.onJumpToLive) this.props.onJumpToLive();
            Player.setTimeshift(0);
            this.timeshiftBeforeSwitch = null;
            this.seeking = true;
            this.seekedAmount = seekAmount;
            this.setState({ seekAmount: 0 });
            return;
        }
        const newTimeShift = timeshiftPosition < Date.now() ? timeshiftPosition : Date.now();
        Player.setTimeshift(newTimeShift);
        const time =
            Player.getPlayedTime() === 0 ? (newTimeShift - this.props.data?.startDateTime) / 1000 : Player.getPlayedTime() + seekAmount;
        Player.seek(Math.max(time, this.props.data?.leadIn || 0));
        this.timeshiftBeforeSwitch = null;
        this.seeking = true;
        this.seekedAmount = seekAmount; // buffering only manual seeking with mediabuttons or etc
        this.setState({ seekAmount: 0 });
    };

    /**
     * Handling the seeking
     */
    seek = () => {
        const { seekAmount, isPlaying } = this.state;
        if (this.state.seekInProgress || seekAmount === 0) return;
        this.aboutToBeSeeked = true;
        if (isPlaying) {
            // If playing => player state is valid, seek here, don't wait for the onPlay callback.
            // It will probably not be executed anyway.
            this.doSeek();
        } else {
            Player.play();
        }
    };

    onBeforeSeek = () => {
        this.setState({ seekInProgress: true });
    };

    onStateChange = () => {
        if ((!this.seeking && this.seekedAmount) || Player.isBuffering()) {
            return;
        }

        this.seekDisabled = false;
        this.seeking = false;
        this.skipping = false;
        this.aboutToBeSeeked = false;
        this.setState({ seekInProgress: false });
        if (this.seekedAmount && this.isPlayerUiHidden()) {
            this.displaySeekSnackbar(this.seekedAmount, this.seekedAmount > 0 ? 'forward' : 'backward');
        }
        this.seekedAmount = 0;
    };

    onProgressChanged = () => {
        if (this.seekDisabled) return;
        const { data } = this.props;
        const { duration } = data;
        const progressToSeek = Number(this.rangeRef ? this.rangeRef.current.value : 0) / 100;
        const secondsToSeek = (duration / 1000) * progressToSeek;
        const seekDelta = secondsToSeek - this.getCalculatedPlayedTime();
        this.skipping = true;
        this.setState({ seekAmount: seekDelta }, this.seekWithDebounce);
    };

    isPlayerUiHidden = () => !!document.querySelector('#player-ui.hidden');

    displaySeekSnackbar = (duration, direction) => {
        if (!direction) return;
        const seekIcon = direction === 'forward' ? NotificationIconType.SEEK_FORWARD : NotificationIconType.SEEK_BACKWARD;
        const text = this.secondsToTime(Math.abs(duration), true);
        this.props.notificationShow(text, seekIcon);
    };

    seekToPointer = (event) => {
        const { seekAmount } = this.state;
        const progressBar = document.getElementById('player-progress-bar');
        const currentEvent = event.mouseEvent ? event.mouseEvent : event;
        const watched = currentEvent?.target?.parentNode?.firstElementChild;
        if (watched) {
            const watchedClientRect = watched?.getBoundingClientRect();
            const progressBarClientRect = progressBar?.getBoundingClientRect();
            const allProgramTime = (this.props.data?.endDateTime - this.props.data?.startDateTime) / 1000; // seconds of the program
            const secondsInOnePX = allProgramTime / progressBarClientRect?.width;
            const seekAmountPX = currentEvent.clientX - watchedClientRect?.right;
            const seekDelta = secondsInOnePX * seekAmountPX;
            if (Number.isFinite(seekDelta)) {
                this.skipping = true;
                this.setState({ seekAmount: seekAmount + seekDelta }, this.seekWithDebounce);
                return true;
            }
        }
        return false;
    };

    onMouseMove = (event) => {
        if (Focus.isDragging && this.props.allowSeek) {
            return this.seekToPointer(event);
        }
        return false;
    };

    onRewind = (event, eventType) => {
        this.seekWithDebounce.cancel();
        this.skipping = true;
        this.setState((prevState) => this.seekState(prevState, event.type ?? eventType, 'back'), this.seekWithDebounce);
        return true;
    };

    onFastForward = (event, eventType) => {
        if (Player.seekingIsAllowed()) {
            this.seekWithDebounce.cancel();
            this.skipping = true;
            this.setState((prevState) => this.seekState(prevState, event.type ?? eventType, 'forward'), this.seekWithDebounce);
            return true;
        }
        this.props.textNotificationShow('NOTIFICATION_TRICK_PLAY_BLOCKED', 3000);
        return true;
    };

    handleKeyEvent = (keyCode, event, eventType) => {
        const { allowSeek, handleNavigateUp } = this.props;
        if (!allowSeek) return false;
        const { currentTime } = this.state;

        if (!this.keysToBeHandled.includes(keyCode)) {
            return false;
        }

        this.props.resetTimer();

        if (keyCode === Key.VK_CHAN_UP || keyCode === Key.VK_CHAN_DOWN) {
            this.seekDisabled = true;
            return false;
        }
        this.seekDisabled = Player.isBuffering();
        if ((!this.seeking || Focus.isMagicMode) && currentTime !== 0) {
            if (keyCode === Key.VK_ENTER && event) {
                return this.seekToPointer(event);
            }

            switch (keyCode) {
                case Key.VK_REWIND:
                case Key.VK_LEFT:
                    return this.onRewind(event, eventType);
                case Key.VK_FAST_FWD:
                case Key.VK_RIGHT:
                    return this.onFastForward(event, eventType);
                case Key.VK_UP:
                    return handleNavigateUp();
                default:
            }
        }
        return true;
    };

    // eslint-disable-next-line max-statements
    seekState = (prevState, eventType, direction) => {
        const seekStep = direction === 'back' ? this.amountToSeekBack : this.amountToSeekForward;
        if (direction === 'back' && this.getCurrentPlaybackTime() > 0) {
            const seekAmount = prevState.seekAmount - seekStep;
            const diff = this.currentStreamPosition(true) / 1000 + prevState.seekAmount - this.props.data?.startDateTime / 1000;
            if (diff <= 0) {
                return prevState;
            }
            return { seekAmount };
        }
        if (direction === 'forward') {
            const playedPlayerTime = this.props.hasManifestFromStart ? Player.getPlayedTime() : Player.getPlayedTime() * 1000;
            const amount = this.props.hasManifestFromStart ? prevState.seekAmount + seekStep : (prevState.seekAmount + seekStep) * 1000;
            const positionToCheck = playedPlayerTime + amount;
            const { adEvent, shouldBlock } = AdsScippingEngine.getUnwatchedAdEventInDynamicContext(
                this.props.hasManifestFromStart,
                positionToCheck,
                eventType
            );
            if (shouldBlock) {
                const adStartTime = this.props.hasManifestFromStart ? adEvent?.startTime : adEvent?.startTime * 1000;
                const maxSeekAmount = adStartTime - playedPlayerTime;
                const maxSeek = this.props.hasManifestFromStart ? maxSeekAmount : maxSeekAmount / 1000;
                this.props.textNotificationShow('NOTIFICATION_TRICK_PLAY_BLOCKED', 3000);
                return {
                    seekAmount: maxSeek,
                };
            }
            const seekAmount = prevState.seekAmount + seekStep;
            if (this.currentStreamPosition() + seekAmount * 1000 > Date.now()) {
                const deltaToEnd = Math.floor(Date.now() - this.currentStreamPosition()) / 1000;
                return {
                    seekAmount: deltaToEnd,
                };
            }
            return {
                seekAmount,
            };
        }
        return prevState;
    };

    secondsToTime = (duration, isPlayerSeekNotification = false) => {
        if (duration && duration >= 0) {
            let seconds = duration;
            let minutes = Math.floor(seconds / 60) % 60;
            let hours = Math.floor(seconds / 3600);

            hours = hours >= 1 ? `${hours}:` : '';
            minutes = minutes >= 10 ? minutes : `0${minutes}`;
            seconds = Math.floor(seconds % 60);
            seconds = seconds >= 10 ? seconds : `0${seconds}`;

            if (isPlayerSeekNotification) {
                const secondsText = `${seconds} ${translate('SCREEN_PLAYER_SKIP_SECONDS')}`;
                const minutesText = `${minutes} ${translate('SCREEN_PLAYER_SKIP_MINUTES')}`;

                return `${minutes >= 1 ? minutesText : ''} ${secondsText}`;
            }

            return `${hours}${minutes}:${seconds}`;
        }
        return '00:00';
    };

    getRawDisplayTime = () => {
        const timePosition = this.currentStreamPosition(true);
        let playedTime = this.state.currentTime + this.state.seekAmount;
        let remainingTime = this.state.currentTime - this.state.seekAmount;

        try {
            playedTime = (timePosition - this.props.data?.startDateTime) / 1000;
            remainingTime = (this.props.data?.endDateTime - timePosition) / 1000;
            if (remainingTime <= 1) {
                remainingTime = 0;
            }
        } catch (e) {
            console.log('e', e);
        }

        playedTime = Math.max(playedTime, 0);
        remainingTime = Math.max(remainingTime, 0);
        return [playedTime, remainingTime];
    };

    getDisplayTime = () => {
        const [playedTime, remainingTime] = this.getRawDisplayTime();
        return [this.secondsToTime(playedTime), this.secondsToTime(remainingTime)];
    };

    getProgress = () => calculateLinearProgress(this.props.data?.duration, this.props.data?.startDateTime, 0);

    /**
     * Returns playhead position as UTC timestamp in milliseconds.
     * @param isTimeshift
     * @returns - milliseconds
     */
    currentStreamPosition = (isTimeshift = false) => {
        const timeshift = Player.getTimeshift();

        if (isTimeshift) {
            return this.timeshiftBeforeSwitch || timeshift || Date.now();
        }
        if (timeshift) {
            return Player.getPlayedTimeUTC() * 1000 || timeshift;
        }
        return Date.now();
    };

    getTimeshiftPercentage = () =>
        calculateLinearProgress(
            this.props.data?.duration,
            this.props.data?.startDateTime,
            this.currentStreamPosition(true) + this.state.seekAmount * 1000
        );

    generateAdIntervals = () => {
        const { hasManifestFromStart } = this.props;
        const delta = hasManifestFromStart ? 1 : 1000;
        const programDuration = hasManifestFromStart ? this.props.data.duration / 1000 : this.props.data.duration / 100;
        if (!hasManifestFromStart) {
            return Player.adCues
                .filter((adEvent) => {
                    const { duration: adDuration, startTime } = adEvent;
                    const adOutsideProgram =
                        startTime * delta < this.props.data?.startDateTime || startTime * delta + adDuration > this.props.data?.endDateTime;
                    if (adOutsideProgram) return false;
                    return true;
                })
                .map((adEvent) => {
                    const { duration: adDuration, startTime, id } = adEvent;
                    const relativeDuration = (adDuration * delta) / programDuration;
                    const relativeStartTime = (startTime * delta - this.props.data?.startDateTime) / programDuration;
                    const width = relativeStartTime + relativeDuration > 100 ? 100 - relativeStartTime : relativeDuration;
                    return <span key={id} className="adEvent" style={{ width: `${width}%`, left: `${relativeStartTime}%` }}></span>;
                });
        }
        return Player.adCues
            .filter((ad) => {
                const { startTime, endTime } = ad;
                const remove = startTime === 0 && endTime <= (this.props.data?.leadIn ?? 0);
                return !remove;
            })
            .map((adEvent) => {
                const { duration: adDuration, startTime, id } = adEvent;
                const relativeDuration = (adDuration * 100) / programDuration;
                const relativeStartTime = ((startTime - this.props.data.leadIn) * 100) / programDuration;
                const width = relativeStartTime + relativeDuration > 100 ? 100 - relativeStartTime : relativeDuration;
                return <span key={id} className="adEvent" style={{ width: `${width}%`, left: `${relativeStartTime}%` }}></span>;
            });
    };

    render() {
        const { onCustomRect, allowSeek } = this.props;
        const type = allowSeek ? Focusable : 'div';
        const progress = this.getTimeshiftPercentage();
        const liveProgress = this.getProgress();
        const displayTime = this.getDisplayTime();
        return (
            <div className="progress-bar-container">
                <Text
                    className="duration-played"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {displayTime[0]}
                </Text>
                {React.createElement(
                    type,
                    {
                        id: 'player-progress-bar',
                        className: 'player-progress-bar scrubber-wrapper',
                        onKey: this.handleKeyEvent,
                        onEnter: this.onEnter,
                        onMouseMove: this.onMouseMove,
                        onCustomRect,
                        ref: this.nodeRef,
                    },
                    <div className="total">
                        <span
                            className={`watched ${
                                progress === liveProgress || (this.timeshiftBeforeSwitch || Player.getTimeshift()) === 0
                                    ? 'live-progress'
                                    : 'catchup-progress'
                            }`}
                            style={{ width: `${progress > 100 ? 0 : progress}%` }}
                        />
                        <span className="buffered" style={{ width: `${liveProgress}%` }} />
                        {this.generateAdIntervals()}
                        <input
                            className="scrubber"
                            type="range"
                            min="0"
                            max="100"
                            value={progress > 100 ? 0 : progress}
                            step="any"
                            ref={this.rangeRef}
                            onInput={() => this.onProgressChanged()}
                            onChange={() => this.onProgressChanged()}
                        />
                    </div>
                )}
                <Text
                    className="duration-remaining"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >{`-${displayTime[1]}`}</Text>
            </div>
        );
    }
}

export default connect(
    null,
    (dispatch) => ({
        notificationShow: (text, icon) => dispatch(seekNotificationShow(text, icon)),
        textNotificationShow: (text) => dispatch(textNotificationShow(text)),
    }),
    null,
    { forwardRef: true }
)(LinearProgressBar);
