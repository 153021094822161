import React, { useMemo } from 'react';

import { DynamicListEngine } from '__SMART_APP_OLD__/app/components/ListV2/';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectParentalRatingId } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { parentalControlActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ParentalControlScreen/actions';
import { SETTINGS_ADULT_CONTENT } from '__SMART_APP_OLD__/app/modules/Screen/modules/ParentalControlScreen/constants';
import { parentalControlSelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/ParentalControlScreen/selectors';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const ParentalControlScreenView: React.FunctionComponent<Screen.Props<Screen.Type.PIN_CODE>> = () => {
    const dispatch = useDispatch();
    const elements = useSelector(parentalControlSelectors.private.selectItems);
    const items = useMemo(() => elements.map((element) => element.id), [elements]);
    const currentParentalRatingId = useSelector(selectParentalRatingId);
    const adultOptionLabel = useSelector(parentalControlSelectors.private.selectAdultOptionLabel);

    const handleEnter = (id: string) => dispatch(parentalControlActions.private.enter(id));

    const { focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: SettingsCtx.PrimaryList,
        axis: Axis.Y,
        items,
    });

    const engine = DynamicListEngine.useEngine(focusedIndex, items, { axis: Axis.Y, max: 8, offset: 1 });

    Focus.useBack(() => dispatch(History.actions.pop()));
    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action />
                <Settings.Header.Title>SETTINGS_PARENTAL_CONTROL</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <Settings.Section.Content>
                    <Settings.Section.Content.List engine={engine}>
                        {(item, index) => (
                            <>
                                {item === SETTINGS_ADULT_CONTENT && (
                                    <>
                                        <Settings.Section.Content.Divider />
                                        <Settings.Section.Content.Separator />
                                    </>
                                )}
                                {engine.getFirstIndex() === index && <Settings.Section.Content.Separator />}
                                <Settings.Section.Content.Item
                                    id={item}
                                    ctx={SettingsCtx.PrimaryList}
                                    isFocused={engine.getIsFocused(index) && !isDisabled}
                                    isHidden={!engine.getIsRendered(index)}
                                    onEnter={handleEnter}
                                    onHover={focus}
                                >
                                    <Settings.Section.Content.Item.Title>{elements[index].title}</Settings.Section.Content.Item.Title>
                                    <Settings.Section.Content.Item.Spacer />
                                    {currentParentalRatingId === item && <Settings.Section.Content.Item.Icon.Checked />}
                                    {item === SETTINGS_ADULT_CONTENT && (
                                        <Settings.Section.Content.Item.Value>{adultOptionLabel}</Settings.Section.Content.Item.Value>
                                    )}
                                </Settings.Section.Content.Item>
                                <Settings.Section.Content.Separator />
                            </>
                        )}
                    </Settings.Section.Content.List>
                </Settings.Section.Content>
            </Settings.Section>
            <Settings.Section>
                <Settings.Section.Header>
                    <Settings.Section.Header.Title>{elements[focusedIndex].title}</Settings.Section.Header.Title>
                </Settings.Section.Header>
                <Settings.Section.Content>
                    <Settings.Section.Content.Text>{elements[focusedIndex].info}</Settings.Section.Content.Text>
                </Settings.Section.Content>
            </Settings.Section>
        </Settings>
    );
};
