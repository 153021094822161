import { KeyboardSuggestion } from 'App/Modules/Keyboard/Components/KeyboardSuggestion';
import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React, { useMemo, useRef } from 'react';

export type KeyboardSuggestionListProps = {
    keyboard: KeyboardElement;
};

export const KeyboardSuggestionList: React.FunctionComponent<KeyboardSuggestionListProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const scroll = useMemo(() => new Scroll(ref), []);

    const suggestions = Subscribable.use(props.keyboard.suggestions.suggestions);
    if (!suggestions.length) return null;
    return (
        <UI.Keyboard.Header>
            <div ref={ref} className="keyboard__suggestions">
                {suggestions.map((suggestion) => (
                    <KeyboardSuggestion key={suggestion.value} keyboard={props.keyboard} element={suggestion} scroll={scroll} />
                ))}
            </div>
        </UI.Keyboard.Header>
    );
};
