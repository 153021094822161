import React from 'react';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { SuggestionList as SuggestionListModule } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';
import { SuggestionListProps as SuggestionListModuleProps } from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Types';

const SuggestionListInternal: React.FunctionComponent<DivPropsExtendable> = (props) => {
    const items = SuggestionListModule.use();
    return <Div {...props} defaultClassName="suggestion-list" ref={items.ref} />;
};

export type SuggestionListContainerProps = DivPropsExtendable & SuggestionListModuleProps;

export type SuggestionListComponent = React.FunctionComponent<SuggestionListContainerProps>;

export const SuggestionList: SuggestionListComponent = (props) => {
    const { ctx, disabled, offset, initial, onEnter, ...rest } = props;
    return (
        <SuggestionListModule ctx={ctx} disabled={disabled} offset={offset} initial={initial} onEnter={onEnter}>
            <SuggestionListInternal {...rest} />
        </SuggestionListModule>
    );
};
