import { MenuTypes } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Types';

export const Constants = {
    Ctx: {
        audio: MenuTypes.AUDIO,
        subtitles: MenuTypes.SUBTITLES,
        fontSizes: MenuTypes.FONT_SIZES,
        fontStyles: MenuTypes.TEXT_FONT_STYLES,
    },
};
