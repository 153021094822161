import LandscapeCard from './LandscapeCard';
import { AssetType } from '../utils/Constants';

export default class ProductBundle extends LandscapeCard {
    private _assetType: AssetType = AssetType.PRODUCT_BUNDLE;

    public get assetType(): AssetType {
        return this._assetType;
    }
}
