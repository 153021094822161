import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { ProductOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';

export enum VodSeriesActionTypes {
    Load = '@Detail/VodSeries/Load',
    Reset = '@Detail/VodSeries/Reset',
    FolderHeaderSelected = '@Detail/VodSeries/FolderHeaderSelected',
    ItemFocusChanged = '@Detail/VodSeries/ItemFocusChanged',
}

export type ItemIdsForGroupTitle = {
    title: string;
    itemIds: string[];
} | null;

export type GroupingInfos = ItemIdsForGroupTitle[];

export type VodSeriesDetailLoadedActionPayload = VodSeriesState;
export type VodSeriesDetailFolderHeaderSelectedActionPayload = string;
export type VodSeriesDetailItemFocusChangedActionPayload = { itemId: string; products: ProductOption[] };

export type VodSeriesDetailLoadedAction = PayloadedAction<VodSeriesActionTypes.Load, VodSeriesDetailLoadedActionPayload>;
export type VodSeriesDetailResetAction = BaseAction<VodSeriesActionTypes.Reset>;
export type VodSeriesDetailFolderHeaderSelectedAction = PayloadedAction<
    VodSeriesActionTypes.FolderHeaderSelected,
    VodSeriesDetailFolderHeaderSelectedActionPayload
>;
export type VodSeriesDetailItemFocusChangedAction = PayloadedAction<
    VodSeriesActionTypes.ItemFocusChanged,
    VodSeriesDetailItemFocusChangedActionPayload
>;

export type ReduxAction =
    | VodSeriesDetailLoadedAction
    | VodSeriesDetailFolderHeaderSelectedAction
    | VodSeriesDetailItemFocusChangedAction
    | VodSeriesDetailResetAction;

export type VodSeriesDetailStateItemsEntities = Record<string, DetailItem>;

export type VodSeriesDetailStateAssetObjects = Record<string, any>;

export type VodSeriesDetailStateItems = {
    ids: string[];
    entities: VodSeriesDetailStateItemsEntities;
    assetObjects: VodSeriesDetailStateAssetObjects;
};

export type VodSeriesState = {
    item: DetailItem | null;
    assetObject: any;
    screenBackgroundImageUrl: string;
    groupTitles: string[];
    items: VodSeriesDetailStateItems | null;
    itemIdsForGroupTitle?: GroupingInfos;
    selectedGroupTitle: string;
    products: ProductOption[];
};
