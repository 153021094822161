import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelNetworkSeriesRecordingMutationVariables = Types.Exact<{
  input: Types.CancelNetworkSeriesRecordingInput;
}>;


export type CancelNetworkSeriesRecordingMutation = { __typename?: 'Nexx4Mutations', cancelNetworkSeriesRecording: { __typename: 'CancelNetworkSeriesRecordingPayload', events: Array<{ __typename?: 'Event', id: string } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export type CancelNetworkSeriesRecordingFragment = { __typename?: 'Nexx4Mutations', cancelNetworkSeriesRecording: { __typename: 'CancelNetworkSeriesRecordingPayload', events: Array<{ __typename?: 'Event', id: string } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export const CancelNetworkSeriesRecordingFragmentDoc = gql`
    fragment cancelNetworkSeriesRecording on Nexx4Mutations {
  cancelNetworkSeriesRecording(input: $input) {
    events {
      id
    }
    quota {
      ...quota
    }
    __typename
  }
}
    ${QuotaFragmentDoc}`;
export const CancelNetworkSeriesRecordingDocument = gql`
    mutation cancelNetworkSeriesRecording($input: CancelNetworkSeriesRecordingInput!) {
  ...cancelNetworkSeriesRecording
}
    ${CancelNetworkSeriesRecordingFragmentDoc}`;
export type CancelNetworkSeriesRecordingMutationFn = Apollo.MutationFunction<CancelNetworkSeriesRecordingMutation, CancelNetworkSeriesRecordingMutationVariables>;

/**
 * __useCancelNetworkSeriesRecordingMutation__
 *
 * To run a mutation, you first call `useCancelNetworkSeriesRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNetworkSeriesRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNetworkSeriesRecordingMutation, { data, loading, error }] = useCancelNetworkSeriesRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelNetworkSeriesRecordingMutation(baseOptions?: Apollo.MutationHookOptions<CancelNetworkSeriesRecordingMutation, CancelNetworkSeriesRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelNetworkSeriesRecordingMutation, CancelNetworkSeriesRecordingMutationVariables>(CancelNetworkSeriesRecordingDocument, options);
      }
export type CancelNetworkSeriesRecordingMutationHookResult = ReturnType<typeof useCancelNetworkSeriesRecordingMutation>;
export type CancelNetworkSeriesRecordingMutationResult = Apollo.MutationResult<CancelNetworkSeriesRecordingMutation>;
export type CancelNetworkSeriesRecordingMutationOptions = Apollo.BaseMutationOptions<CancelNetworkSeriesRecordingMutation, CancelNetworkSeriesRecordingMutationVariables>;