import React from 'react';

import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionValue } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Context/SuggestionContext';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants/index';

const Default: SuggestionListValue = {
    ref: { current: null },
    ctx: FocusDefault,
    values: new Subscribable.MockedArray<string>([]),
    store: new Subscribable.MockedRecord<SuggestionValue>({}),
    focused: new Subscribable.MockedValue<string>(''),
    disabled: new Subscribable.MockedValue<boolean>(false),
    offset: 0,
    scroll: {} as Scroll,
    focus: () => {},
};

export type SuggestionListValue = {
    ref: React.MutableRefObject<HTMLDivElement | null>;
    ctx: string;
    initial?: string;
    values: Subscribable.Array<string>;
    store: Subscribable.Record<SuggestionValue>;
    focused: Subscribable.Value<string>;
    disabled: Subscribable.Value<boolean>;
    offset: number;
    scroll: Scroll;
    focus: (value: string) => void;
    enter?: (value: string) => void;
};

export type SuggestionListContextModule = React.Context<SuggestionListValue> & { Default: SuggestionListValue };

export const SuggestionListContext: SuggestionListContextModule = Object.assign(React.createContext(Default), { Default });
