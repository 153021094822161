import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { CollectionScreenGrid } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenGrid';
import { CollectionScreenHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenHeader';

import { Screen } from 'App/Modules/Screen';

export const CollectionScreenView: React.FunctionComponent<Screen.Props<Screen.Type.COLLECTION>> = () => (
    <Layout className="collection-screen" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <CollectionScreenHeader />
        <div className="collection-screen__grid-container">
            <CollectionScreenGrid />
        </div>
    </Layout>
);
