import { combineReducers } from 'redux';

import { configReducer } from '__SMART_APP_OLD__/app/modules/Config/reducer';
import { dataReducer } from '__SMART_APP_OLD__/app/modules/Data/reducer';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Header } from '__SMART_APP_OLD__/app/modules/Header';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { notificationReducer } from '__SMART_APP_OLD__/app/modules/Notification/reducer';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { Player } from '__SMART_APP_OLD__/app/modules/Player';
import { channelListsScreenManageReducer } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/reducer';
import { messageDetailReducer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/reducer';
import { messageInboxReducer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/reducer';
import { recordingManagementReducer } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/reducer';
import { tvGuideReducer } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/reducer';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { Action } from '__SMART_APP_OLD__/app/store/types/Action';

import { Alert } from 'App/Modules/Alert';
import { Keyboard } from 'App/Modules/Keyboard';
import { MyLibraryData } from 'App/Modules/MyLibraryData';
import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { RootFolder } from 'App/Modules/RootFolder';
import { Screen } from 'App/Modules/Screen';
import { Data } from 'App/Modules/Data';
import { VodFolderData } from 'App/Modules/VodFolderData';
import { State } from '__SMART_APP_OLD__/app/store/types/state/state';

export const reducer = combineReducers<State, Action>({
    notification: notificationReducer,
    data: dataReducer,
    config: configReducer,
    recordingManagement: recordingManagementReducer,
    messageDetail: messageDetailReducer,
    messageInbox: messageInboxReducer,
    tvGuide: tvGuideReducer,
    channelListsManageScreen: channelListsScreenManageReducer,
    // Refactored
    vodUpsell: VodUpsell.reducer,
    time: Time.reducer,
    theme: Theme.reducer,
    startup: Startup.reducer,
    language: Language.reducer,
    translation: Translation.reducer,
    fti: Fti.reducer,
    history: History.reducer,
    player: Player.reducer,
    header: Header.reducer,
    // New App Modules
    rootFolder: RootFolder.reducer,
    myLibrary: MyLibraryData.reducer,
    vodFolder: VodFolderData.reducer,
    alert: Alert.reducer,
    prompt: Prompt.reducer,
    overlay: Overlay.reducer,
    screen: Screen.reducer,
    keyboard: Keyboard.reducer,
    dataV2: Data.reducer,
});
