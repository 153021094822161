import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { BookmarkFragmentDoc } from '../fragments/bookmark.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookmarksLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BookmarksLoadQuery = { __typename?: 'Nexx4Queries', bookmarks?: { __typename?: 'BookmarkCatalog', items: Array<{ __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null } | null> } | null };

export type BookmarksLoadFragment = { __typename?: 'Nexx4Queries', bookmarks?: { __typename?: 'BookmarkCatalog', items: Array<{ __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null } | null> } | null };

export const BookmarksLoadFragmentDoc = gql`
    fragment bookmarksLoad on Nexx4Queries {
  bookmarks {
    items {
      ...bookmark
    }
  }
}
    ${BookmarkFragmentDoc}`;
export const BookmarksLoadDocument = gql`
    query bookmarksLoad {
  ...bookmarksLoad
}
    ${BookmarksLoadFragmentDoc}`;

/**
 * __useBookmarksLoadQuery__
 *
 * To run a query within a React component, call `useBookmarksLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookmarksLoadQuery(baseOptions?: Apollo.QueryHookOptions<BookmarksLoadQuery, BookmarksLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookmarksLoadQuery, BookmarksLoadQueryVariables>(BookmarksLoadDocument, options);
      }
export function useBookmarksLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookmarksLoadQuery, BookmarksLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookmarksLoadQuery, BookmarksLoadQueryVariables>(BookmarksLoadDocument, options);
        }
export function useBookmarksLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BookmarksLoadQuery, BookmarksLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookmarksLoadQuery, BookmarksLoadQueryVariables>(BookmarksLoadDocument, options);
        }
export type BookmarksLoadQueryHookResult = ReturnType<typeof useBookmarksLoadQuery>;
export type BookmarksLoadLazyQueryHookResult = ReturnType<typeof useBookmarksLoadLazyQuery>;
export type BookmarksLoadSuspenseQueryHookResult = ReturnType<typeof useBookmarksLoadSuspenseQuery>;
export type BookmarksLoadQueryResult = Apollo.QueryResult<BookmarksLoadQuery, BookmarksLoadQueryVariables>;