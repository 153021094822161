import React from 'react';

import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import Node from '__SMART_APP_OLD__/navigation/Node';

export default class Container extends Focusable {
    render() {
        const {
            children,
            className,
            id,
            onFocus,
            onBlur,
            onEnter,
            onKey,
            onMouseMove,
            onFocusChanged,
            style,
            tag,
            preserveFocus,
            isCollection = false,
            focusDelay = true,
            getLastFocusedTile,
            dataTestId,
            scrollTop,
        } = this.props;
        const Component = tag || 'div';
        return (
            <Component
                container="true"
                style={style}
                ref={(node) => {
                    this.nodeRef = new Node(
                        node,
                        true,
                        preserveFocus,
                        onFocus,
                        onBlur,
                        onEnter,
                        onKey,
                        onMouseMove,
                        onFocusChanged,
                        null,
                        null,
                        isCollection,
                        focusDelay,
                        getLastFocusedTile,
                        null,
                        scrollTop
                    );
                }}
                className={className}
                id={id}
                data-test-id={dataTestId}
            >
                {children}
            </Component>
        );
    }
}
