import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { FavouritableItemFragmentDoc } from '../fragments/favouritableItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FavouriteItemMutationVariables = Types.Exact<{
  input: Types.FavouriteItemInput;
}>;


export type FavouriteItemMutation = { __typename?: 'Nexx4Mutations', favouriteItem: { __typename?: 'FavouriteItemPayload', item: { __typename: 'Event', id: string, expiry: any } | { __typename: 'NetworkRecording', id: string, expiry: any } | { __typename: 'VODAsset', id: string, expiry: any } | { __typename: 'VODProduct', id: string, expiry: any } } };


export const FavouriteItemDocument = gql`
    mutation favouriteItem($input: FavouriteItemInput!) {
  favouriteItem(input: $input) {
    item {
      ...favouritableItem
    }
  }
}
    ${FavouritableItemFragmentDoc}`;
export type FavouriteItemMutationFn = Apollo.MutationFunction<FavouriteItemMutation, FavouriteItemMutationVariables>;

/**
 * __useFavouriteItemMutation__
 *
 * To run a mutation, you first call `useFavouriteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavouriteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favouriteItemMutation, { data, loading, error }] = useFavouriteItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavouriteItemMutation(baseOptions?: Apollo.MutationHookOptions<FavouriteItemMutation, FavouriteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavouriteItemMutation, FavouriteItemMutationVariables>(FavouriteItemDocument, options);
      }
export type FavouriteItemMutationHookResult = ReturnType<typeof useFavouriteItemMutation>;
export type FavouriteItemMutationResult = Apollo.MutationResult<FavouriteItemMutation>;
export type FavouriteItemMutationOptions = Apollo.BaseMutationOptions<FavouriteItemMutation, FavouriteItemMutationVariables>;