import { Calc } from 'App/Packages/Calc';
import { Guard as FocusGuard } from 'App/Packages/Focus/Types/Guard';
import { Axis } from 'App/Types/Axis';

const listGuard: FocusGuard<string> = (e) => e.x === 0;
const itemsGuard: FocusGuard<string> = (e) => e.y === 0;

export const Constants = {
    Ctx: 'Folder',
    List: {
        Axis: Axis.Y,
        guard: listGuard,
        Scroll: {
            Offset: {
                Margin: Calc.pixel(8),
                Header: Calc.pixel(232),
                Default: Calc.pixel(128),
                calc: (value: number) => value - Constants.List.Scroll.Offset.Margin,
            },
        },
    },
    Items: {
        Axis: Axis.X,
        guard: itemsGuard,
        Scroll: {
            Offset: 0,
            Left: Calc.pixel(96),
            calc: (value: number) => value - Constants.Items.Scroll.Left,
        },
    },
    Item: {
        Id: {
            More: 'MORE',
        },
        Focus: {
            Delay: 75,
        },
        Scroll: {
            Delay: 100,
        },
    },
} as const;
