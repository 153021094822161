import { GQL } from '__SMART_APP_OLD__/app/gql';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { audioLanguageListMapping } from '__SMART_APP_OLD__/utils/dataUtils';
import { VideoQuality } from 'App/Types/VideoQuality';
import translate from 'language/translate';
import React from 'react';
import { ReactComponent as VisuallyImpairedIcon } from 'styles/assets/ico-visually-impaired.svg';

export type VideoQualityType = string | Partial<Record<VideoQuality, VideoQuality>>;

interface Props {
    className?: string;
    status: string | null;
    assetType?: string;
    videoQuality?: VideoQualityType;
    parentalControl?: string;
    audioLanguages?: string[];
    subtitleLanguages?: string[];
    isFocused?: boolean;
    isReminderSet?: boolean;
    isCatchup?: boolean;
    isDolby?: boolean;
    isRecording?: boolean;
    isRecordable?: boolean;
    isVisuallyImpaired?: boolean;
    isFavourited: boolean;
}

const mapAgeRatings = {
    FSK0: '0+',
    FSK6: '6+',
    FSK12: '12+',
    FSK16: '16+',
    FSK18: '18',
    'FSK 0': '0+',
    'FSK 6': '6+',
    'FSK 12': '12+',
    'FSK 16': '16+',
    'FSK 18': '18',
    'FSK18-ADULT': '18+',
};

export const Icons: React.FunctionComponent<Props> = React.memo((props) => {
    const {
        className,
        status,
        assetType,
        videoQuality,
        parentalControl,
        audioLanguages,
        subtitleLanguages,
        isFocused,
        isReminderSet,
        isCatchup,
        isDolby,
        isRecording,
        isRecordable,
        isVisuallyImpaired,
        isFavourited,
    } = props;

    const adaptedAudioLanguages = Array.from(new Set(audioLanguages));
    const icons = [];

    if (isFavourited) {
        icons.push(<div key="icon-favorite" className="icon icon-favorite" />);
    }

    if (!isRecording && isRecordable) {
        icons.push(<div key="icon-rec-planned" className="icon icon-rec-planned" />);
    }

    if (isCatchup && !isRecording) {
        icons.push(<div key="icon-catchup" className="icon icon-catchup" />);
    }

    if (isDolby) {
        icons.push(<div key="icon-dolby" className="icon icon-dolby" />);
    }

    if (assetType === AssetType.VOD_SERIES || assetType === AssetType.SERIES) {
        icons.push(<div key="icon-folder" className="icon icon-folder" />);
    }

    if (isReminderSet) {
        icons.push(<div key="icon-reminder" className="icon icon-reminder" />);
    }

    if (isRecording && (status === GQL.RecordingStatus.Planned || !status)) {
        icons.push(<div key="icon-rec-planned" className="icon icon-rec-planned" />);
    } else if (isRecording && status === GQL.RecordingStatus.InProgress) {
        icons.push(<div key="icon-rec-inprogress" className="icon icon-rec-inprogress" />);
    } else if (isRecording && status === GQL.RecordingStatus.Completed) {
        icons.push(<div key="icon-rec-completed" className="icon icon-rec-completed" />);
    } else if (isRecording && status === GQL.RecordingStatus.Failed) {
        icons.push(<div key="icon-rec-failed" className="icon icon-rec-failed" />);
    }

    if (parentalControl) {
        icons.push(
            <div key="icon-parental-rating" className="icon icon-parental-rating">
                {mapAgeRatings[parentalControl as keyof typeof mapAgeRatings] || parentalControl}
            </div>
        );
    }

    if (videoQuality === VideoQuality.SD || (typeof videoQuality === 'object' && videoQuality?.SD === VideoQuality.SD)) {
        icons.push(
            <div key="icon-vod-sd" className="icon icon-vod-sd">
                {VideoQuality.SD}
            </div>
        );
    }

    if (videoQuality === VideoQuality.HD || (typeof videoQuality === 'object' && videoQuality?.HD === VideoQuality.HD)) {
        icons.push(
            <div key="icon-vod-hd" className="icon icon-vod-hd">
                {VideoQuality.HD}
            </div>
        );
    }

    if (videoQuality === VideoQuality.UHD || (typeof videoQuality === 'object' && videoQuality?.UHD === VideoQuality.UHD)) {
        icons.push(
            <div key="icon-vod-uhd" className="icon icon-vod-uhd">
                {VideoQuality.UHD}
            </div>
        );
    }

    adaptedAudioLanguages?.forEach((lang, i) => {
        const languagesMapping = audioLanguageListMapping();
        const iconLanguageCode = languagesMapping.find((el) => el.backendCode === lang)?.iso_code;
        if (iconLanguageCode) {
            icons.push(
                <div key={`icon-audio-language-${i}`} className="icon icon-audio-language">
                    {iconLanguageCode.toUpperCase()}
                </div>
            );
        }
    });

    if (subtitleLanguages) {
        icons.push(
            <div key="icon-subtitle-language" className="icon icon-subtitle-language">
                {translate('SUBTITLES_ICON_TITLE')}
            </div>
        );
    }

    if (isVisuallyImpaired) {
        icons.push(<VisuallyImpairedIcon className="icon icon-visually-impaired" />);
    }

    if (!icons.length) return null;

    return <div className={`icon-container ${isFocused ? 'focused ' : ''} ${className || ''}`}>{icons}</div>;
});
