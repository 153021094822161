import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Screen } from 'App/Modules/Screen';

const onBack = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.pop());
    dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}));
};

const to = (): Thunk<void> => (dispatch) => {
    const screen = Screen.generator(Screen.Type.LOGIN_WITH_EMAIL, {});
    dispatch(History.actions.push({ screen, overlay: null }));
    dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}));
};

export const actions = {
    private: {
        onBack,
    },
    public: {
        to,
    },
} as const;
