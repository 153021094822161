import React from 'react';

import { ProgressBar } from '__SMART_APP_OLD__/app/components/ProgressBar';
import { ProgressBarProps } from '__SMART_APP_OLD__/app/components/ProgressBar/ProgressBar';

import { hidable } from 'App/Packages/UI/Hidable';

const getClassName = (props: DetailProgressProps): string => {
    const classList = ['detail__progress'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type DetailProgressProps = ProgressBarProps;

const Component: React.FunctionComponent<DetailProgressProps> = (props) => {
    return <ProgressBar className={getClassName(props)} color={props.color} progress={props.progress} theme={props.theme} />;
};

export const DetailProgress = hidable(Component);

export type DetailProgressComponent = typeof DetailProgress;
