import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import React from 'react';

export const LoginWithCodeTitle: React.FunctionComponent = () => {
    return (
        <Text
            className="login-with-code__title"
            size={FontSize.TITLE_1}
            typeface={Typeface.SERIF}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            LOGIN_WITH_CODE_HEADER_TITLE
        </Text>
    );
};
