import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { pinCodeActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/PinCodeScreen/actions';
import { pinCodeSelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/PinCodeScreen/selectors';
import { SettingsElement, itemsForSetting } from '__SMART_APP_OLD__/utils/Constants';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const changePinTitle = itemsForSetting[SettingsElement.PIN][0].title;

export const PinCodeScreenView: React.FunctionComponent<Screen.Props<Screen.Type.PIN_CODE>> = () => {
    const dispatch = useDispatch();
    const pinTypeElement = useSelector(pinCodeSelectors.private.selectPin);
    const item = pinTypeElement.id;

    const handleEnter = () => dispatch(pinCodeActions.private.changePin(pinTypeElement.id));

    const { isFocused } = Focus.useElement({ ctx: SettingsCtx.PrimaryList });
    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action />
                <Settings.Header.Title>{pinTypeElement.title}</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <Settings.Section.Content>
                    <Settings.Section.Content.Item id={item} ctx={SettingsCtx.PrimaryList} isFocused={isFocused} onEnter={handleEnter}>
                        <Settings.Section.Content.Item.Title>{changePinTitle}</Settings.Section.Content.Item.Title>
                    </Settings.Section.Content.Item>
                    <Settings.Section.Content.Separator />
                </Settings.Section.Content>
            </Settings.Section>
            <Settings.Section>
                <Settings.Section.Header>
                    <Settings.Section.Header.Title>{changePinTitle}</Settings.Section.Header.Title>
                </Settings.Section.Header>
            </Settings.Section>
        </Settings>
    );
};
