import React from 'react';

export type AlertActionsContextState = {
    ids: string[];
    focused: string;
    disabled: boolean;
    onHover: (id: string) => void;
    onEnter?: (id: string) => void;
};

export type AlertActionsContextModule = {
    Provider: React.FunctionComponent<React.PropsWithChildren<AlertActionsContextState>>;
} & Omit<React.Context<AlertActionsContextState>, 'Provider'>;

const AlertActionsContextInternal = React.createContext<AlertActionsContextState>({} as AlertActionsContextState);

const AlertActionsContextProvider = AlertActionsContextInternal.Provider as React.FunctionComponent<
    React.PropsWithChildren<{ value: AlertActionsContextState }>
>;

const AlertActionsProvider: React.FunctionComponent<React.PropsWithChildren<AlertActionsContextState>> = (props) => (
    <AlertActionsContextProvider value={props}>{props.children}</AlertActionsContextProvider>
);

export const AlertActionsContext: AlertActionsContextModule = Object.assign(AlertActionsContextInternal, {
    Provider: AlertActionsProvider,
});

export const useAlertActionsContext = () => React.useContext(AlertActionsContextInternal);
