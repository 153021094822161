import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { RecordingDetailedFragmentDoc } from '../fragments/recording_detailed.generated';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNetworkRecordingMutationVariables = Types.Exact<{
  input: Types.CreateNetworkRecordingInput;
  backgroundWidth: Types.Scalars['Int']['input'];
  backgroundHeight: Types.Scalars['Int']['input'];
}>;


export type CreateNetworkRecordingMutation = { __typename?: 'Nexx4Mutations', createNetworkRecording: { __typename: 'CreateNetworkRecordingPayload', recording: { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } }, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export type CreateNetworkRecordingFragment = { __typename?: 'Nexx4Mutations', createNetworkRecording: { __typename: 'CreateNetworkRecordingPayload', recording: { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } }, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export const CreateNetworkRecordingFragmentDoc = gql`
    fragment createNetworkRecording on Nexx4Mutations {
  createNetworkRecording(input: $input) {
    recording {
      ...recordingDetailed
    }
    quota {
      ...quota
    }
    __typename
  }
}
    ${RecordingDetailedFragmentDoc}
${QuotaFragmentDoc}`;
export const CreateNetworkRecordingDocument = gql`
    mutation createNetworkRecording($input: CreateNetworkRecordingInput!, $backgroundWidth: Int!, $backgroundHeight: Int!) {
  ...createNetworkRecording
}
    ${CreateNetworkRecordingFragmentDoc}`;
export type CreateNetworkRecordingMutationFn = Apollo.MutationFunction<CreateNetworkRecordingMutation, CreateNetworkRecordingMutationVariables>;

/**
 * __useCreateNetworkRecordingMutation__
 *
 * To run a mutation, you first call `useCreateNetworkRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkRecordingMutation, { data, loading, error }] = useCreateNetworkRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      backgroundWidth: // value for 'backgroundWidth'
 *      backgroundHeight: // value for 'backgroundHeight'
 *   },
 * });
 */
export function useCreateNetworkRecordingMutation(baseOptions?: Apollo.MutationHookOptions<CreateNetworkRecordingMutation, CreateNetworkRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNetworkRecordingMutation, CreateNetworkRecordingMutationVariables>(CreateNetworkRecordingDocument, options);
      }
export type CreateNetworkRecordingMutationHookResult = ReturnType<typeof useCreateNetworkRecordingMutation>;
export type CreateNetworkRecordingMutationResult = Apollo.MutationResult<CreateNetworkRecordingMutation>;
export type CreateNetworkRecordingMutationOptions = Apollo.BaseMutationOptions<CreateNetworkRecordingMutation, CreateNetworkRecordingMutationVariables>;