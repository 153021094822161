import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { Folder } from 'App/Modules/Folder';
import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import { loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';

import {
    DetailRecordingLoadedAction,
    DetailRecordingLoadedActionPayload,
    DetailRecordingResetAction,
    RecordingActionTypes,
} from 'App/Modules/Data/Detail/NetworkRecording/Types';
import { initialRecordingDetailReducerState } from 'App/Modules/Data/Detail/NetworkRecording/Store/initialState';
import { Api } from 'App/Modules/Data/Detail/NetworkRecording/Api';

export const loaded = (payload: DetailRecordingLoadedActionPayload): DetailRecordingLoadedAction => ({
    type: RecordingActionTypes.Load,
    payload,
});

export const reset = (): DetailRecordingResetAction => ({
    type: RecordingActionTypes.Reset,
});

const load =
    (variables: Api.LoadVariables): Thunk<Promise<void>> =>
    async (dispatch) => {
        const data = await Api.load(variables);

        if (!data || !data.recording) return;

        const item = Mapper.toDetailItem(data.recording);

        if (!item) return;

        const folders = data.recording?.relatedContent ? Folder.Mapper.toFolderList(data.recording?.relatedContent) : null;

        const assetObject = createAssetObject(data.recording, data.recording.__typename);

        if (assetObject.channelId) {
            await dispatch(
                loadEventsForChannel(assetObject.channelId, assetObject.startTime.getTime(), assetObject.endTime.getTime() + Time.MINUTE_MS)
            );
        }

        dispatch(loaded({ item, assetObject, folders: folders ?? initialRecordingDetailReducerState.folders }));
    };

export const actions = {
    public: {
        load,
        reset,
    },
    private: {},
} as const;
