import { useCallback, useRef } from 'react';

import { SuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';

export type UseSuggestionEnter = (value: string, enter?: (value: string) => void) => () => void;

export const useSuggestionEnter: UseSuggestionEnter = (value, enter) => {
    const list = SuggestionList.use();
    const enterRef = useRef(enter);
    enterRef.current = enter;

    return useCallback(() => {
        if (enterRef.current) return enterRef.current(value);
        if (list.enter) return list.enter(value);
        return undefined;
    }, [value, list]);
};
