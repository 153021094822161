import React from 'react';

import { ReactComponent as SolidDarkIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/solidDark.svg';
import { ReactComponent as SolidLightIconRecord } from '__SMART_APP_OLD__/app/components/Icon/icons/record/solidLight.svg';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';

export type RecordingIconProps = {
    className?: string;
    isFocused?: boolean;
    status: GQL.RecordingStatus;
};

const getClassName = (className: string | undefined, isFocused: boolean, status: GQL.RecordingStatus, isDark: boolean) => {
    const classlist = ['recording-icon'];
    if (isFocused) classlist.push('recording-icon--focused');
    if (status === GQL.RecordingStatus.Planned) classlist.push('recording-icon--planned');
    if (status === GQL.RecordingStatus.Completed) classlist.push('recording-icon--completed');
    if (status === GQL.RecordingStatus.InProgress) classlist.push('recording-icon--in-progress');
    if (isDark) classlist.push('recording-icon--dark-theme');
    else classlist.push('recording-icon--light-theme');
    if (className) classlist.push(className);
    return classlist.join(' ');
};

const RecordingIcon: React.FunctionComponent<RecordingIconProps> = (props) => {
    const isDark = useIsDarkTheme();
    const Icon = isDark ? SolidDarkIconRecord : SolidLightIconRecord;
    return <Icon className={getClassName(props.className, !!props.isFocused, props.status, isDark)} />;
};

export default RecordingIcon;
