import React, { useRef } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

interface Props {
    isVisible: boolean;
}

const getClassName = (isVisible: boolean, wasVisible: boolean) => {
    const classList = ['device-item__remove-button'];
    if (isVisible) classList.push('device-item__remove-button--fade-in');
    else if (wasVisible) classList.push('device-item__remove-button--slide-out');
    return classList.join(' ');
};

export const RemoveButton: React.FunctionComponent<Props> = (props) => {
    const wasVisible = useRef(false);
    const className = getClassName(props.isVisible, wasVisible.current);
    if (!props.isVisible && !wasVisible.current) return null;
    if (!props.isVisible && wasVisible.current) wasVisible.current = false;

    const animationEndHandler = (event: React.AnimationEvent<HTMLDivElement>) => {
        wasVisible.current = event.animationName === 'fade-in';
    };

    return (
        <div onAnimationEnd={animationEndHandler} className={className}>
            <Text typeface={Typeface.SANS} size={FontSize.BODY_1} weight={FontWeight.BOLD} color={Color.PRIMARY} isFocused>
                REMOVE_BUTTON
            </Text>
        </div>
    );
};
