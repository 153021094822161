import { Widget } from 'App/Modules/Keyboard/Widget';
import { actions } from 'App/Modules/Keyboard/Store/actions';
import { selectors } from 'App/Modules/Keyboard/Store/selectors';
import { Focus } from 'App/Packages/Focus';
import React from 'react';

export const View: React.FunctionComponent = () => {
    return (
        <Focus.Provider
            ctx={selectors.private.ctx.select}
            setCtx={actions.private.ctx.set}
            isActive={selectors.public.selectIsActive}
            outOfBounds={actions.lifecycle.outOfBounds}
        >
            <Widget />
        </Focus.Provider>
    );
};
