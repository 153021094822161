import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelCatchupTv,
    selectChannelNetworkRecording,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectSelectedChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';

interface Props {
    channelId: GQL.ChannelID;
}

export const TVGuideChannelDetail: React.FunctionComponent<Props> = (props) => {
    const { channelId } = props;

    const channelNumber = useSelector(selectSelectedChannelListChannelNumber(channelId));
    const isChannelRecordable = useSelector(selectChannelNetworkRecording(channelId));
    const isChannelCatchup = useSelector(selectChannelCatchupTv(channelId));
    return (
        <div className="tv-guide__channel-detail">
            <div className="tv-guide__icons-container">
                {isChannelCatchup && <Icon className="tv-guide__icon tv-guide__catchup-icon" name={IconName.START_OVER} />}
                {isChannelRecordable && <Icon className="tv-guide__icon tv-guide__recording-icon" name={IconName.RECORD} />}
            </div>
            <ChannelLogo className="tv-guide__channel-logo" id={channelId} />
            <Text
                className="tv-guide__channel-number"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {channelNumber}
            </Text>
        </div>
    );
};
