import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { AppearanceScreenOptions } from '__SMART_APP_OLD__/app/modules/Screen/modules/AppearanceScreen/AppearanceScreenOptions';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

export const AppearanceScreenView: React.FunctionComponent<Screen.Props<Screen.Type.APPEARANCE>> = () => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <Layout
            className="appearance-screen"
            containerClassName="appearance-screen__container"
            backgroundColor={BackgroundColor.PRIMARY}
            zIndex={Screen.zIndex}
        >
            <Text
                className="appearance-screen__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                SETTINGS_APPEARANCE_TITLE
            </Text>
            <Text
                className="appearance-screen__subtitle"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                SETTINGS_APPEARANCE_BODY
            </Text>
            <div className="appearance-screen__options-container">
                <AppearanceScreenOptions />
            </div>
        </Layout>
    );
};
