import React from 'react';

import { MaskText } from 'App/Packages/UI/Mask/MaskText';

export type MaskDescriptionProps = React.PropsWithChildren<{}>;

export type MaskDescriptionModule = React.FunctionComponent<MaskDescriptionProps>;

export const MaskDescription: MaskDescriptionModule = (props) => (
    <MaskText mask="PARENTAL_CONTROL_MASKED_DESCRIPTION">{props.children}</MaskText>
);
