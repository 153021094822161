export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** AdSignallingType parser */
  AdSignallingType: { input: any; output: any; }
  /** ConsentType parser */
  ConsentType: { input: any; output: any; }
  /** Date parser */
  Date: { input: any; output: any; }
  /** DeviceEnablementPolicyType parser */
  DeviceEnablementPolicyType: { input: any; output: any; }
};

/**  Models additional actions with link to other screens */
export type Action = Cacheable & {
  __typename?: 'Action';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The title of the action which contains the text for the action */
  title: Scalars['String']['output'];
  /**  The uri for the action, a Nexx4 deeplink url or a plain webbrowser url. */
  uri: Scalars['String']['output'];
};

/**  Models the list of actions of the message */
export type ActionCatalog = Cacheable & Catalog & {
  __typename?: 'ActionCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Action>>;
};

/**  The information needed to select the active episode of a series when the series screen is first opened. */
export type ActiveEpisodeInfo = {
  __typename?: 'ActiveEpisodeInfo';
  /**  The edge for the active episode. */
  edge: ContentFolderContentItemsEdge;
  /**  The id of the group (in the groupingInfos list) to which this active episode belongs. */
  groupId: Scalars['ID']['output'];
};

/**
 *  The scenarios that can be allowed or blocked during ad playback.
 *  Note: additional AdPlaybackRestriction types can be added in the future. In order to remain backward compatible, clients must
 *  be able to ignore unknown AdPlaybackResctriction scenarios.
 */
export enum AdPlaybackRestriction {
  /**
   *  Indicates that fast forwarding through ads and skipping in ads is not allowed.
   *  The adPlaybackPreRoll of the PlaybackInfo will specify the number of seconds before the start of the event which is considered to be the ad pre-roll. If
   *  adPlaybackPreRoll is not specified, it should be considered 0.
   */
  BlockSkipAndFastForward = 'BLOCK_SKIP_AND_FAST_FORWARD',
  /**
   *  Indicates that trickplay is blocked during the last minute of the ad block preceding the recorded event.
   *  If the event is preceded by an ad block, adPlaybackPreRoll of the Playbackinfo specifies the number of seconds before the end of that ad block which is
   *  considered to be the ad pre-roll. If adPlaybackPreRoll is not specified, the default pre-roll duration of the client should be used instead.
   */
  BlockSkipAndFastForwardForFirstAdblock = 'BLOCK_SKIP_AND_FAST_FORWARD_FOR_FIRST_ADBLOCK'
}

export enum AppSorting {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type ArchiveNetworkRecordingInput = {
  /**  Must refer to the "id" of a NetworkRecording object */
  recordingId: Scalars['ID']['input'];
};

export type ArchiveNetworkRecordingPayload = {
  __typename?: 'ArchiveNetworkRecordingPayload';
  /**  Archiving a recording could have an impact on the archive quota */
  archiveQuota?: Maybe<Quota>;
  /**  The impacted recording (and linked Event). */
  recording: NetworkRecording;
};

export type Banner = Cacheable & {
  __typename?: 'Banner';
  /**
   *  Background image, converted to the given width and height.
   *  Since these images should fill up the entire screen, the client application knows the preferred dimensions.
   */
  backgroundImage: Image;
  /**  The multilingual description of what this banner represents */
  description: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  The link for a banner, which can be a link to a specific screen in the nexx4 application,
   *  a link to a 3rd party application, a 3rd party application deeplink or a deeplink to a specific page in the opco selfcare.
   */
  link: Scalars['String']['output'];
  /**  This is needed for parental control and relates to the item that is linked to */
  parentalRating: ParentalRating;
  /**
   *  Thumbnail image which will be converted to the given height.
   *  The aspect ratio is kept intact.
   */
  thumbnail: Image;
  /**  The multilingual title of the banner */
  title: Scalars['String']['output'];
};


export type BannerBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


export type BannerThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  The data that is required to display the binge watching UI on a client device, triggered by reaching
 *  the credits of an episode.
 */
export type BingeWatchData = Cacheable & {
  __typename?: 'BingeWatchData';
  /**
   *  The information about the episode that should be active (selected) when the series is first shown. This will be the episode that was
   *  supplied in the binge watching query.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  activeEpisode?: Maybe<ActiveEpisodeInfo>;
  /**  The available episodes of this series. These episodes will be sorted in the order that is required for binge watching functionality. */
  content: ContentFolder;
  expiry: Scalars['Date']['output'];
  /**
   *  This returns an array of GroupInfo objects that can be used when 'jumping' towards a group using the group selector.
   *  The grouping will be done as is required for the displaying of binge watch data.
   */
  groupingInfos?: Maybe<Array<Maybe<GroupingInfo>>>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  The ID of the series for which this binge watching data is applicable.
   *  This ID should contain all information needed to be able to retrieve the series with the applicable
   *  series query (either EPG or VOD, dependant on the context) and as such may be different from the id
   *  that is available in the SeriesInfo object (e.g. it may contain an indication to specify that only
   *  recordings should be taken into account).
   */
  seriesId: Scalars['String']['output'];
};


/**
 *  The data that is required to display the binge watching UI on a client device, triggered by reaching
 *  the credits of an episode.
 */
export type BingeWatchDataActiveEpisodeArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  suggested expiry: 5 minutes */
export type Bookmark = Cacheable & {
  __typename?: 'Bookmark';
  /**
   *  optional: active audio track at the time the bookmark was created
   *  this is the same string as set by the client when creating the bookmark
   */
  audio?: Maybe<Scalars['String']['output']>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  Make this optional because you can also retrieve a bookmark via the personal infos. */
  item?: Maybe<BookmarkableItem>;
  /**  position in the stream in seconds */
  position: Scalars['Int']['output'];
  /**
   *  optional: active subtitle track at the time the bookmark was created
   *  this is the same string as set by the client when creating the bookmark
   */
  subtitle?: Maybe<Scalars['String']['output']>;
};

export type BookmarkCatalog = Cacheable & Catalog & {
  __typename?: 'BookmarkCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Bookmark>>;
};

/**  Items that can have a bookmark. */
export type BookmarkableItem = Event | NetworkRecording | VodAsset;

/**
 *  let's define all interfaces first
 *  each object that should be cached by the client, has to implement the Cacheable interfaces
 *  based on the ID and the expiry, the client can create a normalized cache
 */
export type Cacheable = {
  /**
   *  some additional info regarding mutations:
   *  the return value of a mutation shall indicate which object has been changed directly by the mutation
   *  furthermore, every mutation has its own set of rules regarding which objects should be removed from the client app's cache
   *  example 1: "recordEvent" will purge all RecordingCatalogs from the cache, as well as the Event with the given ID
   *  example 2: "setCurrentProfile" will purge all Personal*Info objects from the cache, since these belong to a different profile and are no longer needed
   *  these purge operations will be implemented as logic in the GraphQL client
   */
  expiry: Scalars['Date']['output'];
  /**  ID should at least be unique per type */
  id: Scalars['ID']['output'];
};

export type CancelNetworkSeriesRecordingInput = {
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type CancelNetworkSeriesRecordingPayload = {
  __typename?: 'CancelNetworkSeriesRecordingPayload';
  /**  A collection of cancelled events. */
  events: Array<Maybe<Event>>;
  /**  After cancelling, new episodes of the entire series will not be recorded. */
  quota?: Maybe<Quota>;
  /**  Other impacted recordings, if any. The cancelled recording is not present in this array. */
  recordings?: Maybe<Array<Maybe<Recording>>>;
};

/**  Input for the cancelReminder mutation */
export type CancelReminderInput = {
  eventId: Scalars['ID']['input'];
};

/**  Result of the cancelReminder mutation */
export type CancelReminderPayload = {
  __typename?: 'CancelReminderPayload';
  /**  The event will returned because it has changed */
  event: Event;
};

export type CancelSeasonOfNetworkSeriesRecordingInput = {
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type CancelSeasonOfNetworkSeriesRecordingPayload = {
  __typename?: 'CancelSeasonOfNetworkSeriesRecordingPayload';
  /**  A collection of cancelled events. */
  events: Array<Maybe<Event>>;
  /**  After cancelling, new episodes of this season will not be recorded. */
  quota?: Maybe<Quota>;
  /**  Other impacted recordings, if any. The cancelled recording(s) is/are not present in this array. */
  recordings?: Maybe<Array<Maybe<Recording>>>;
};

export type CancelVodTransactionInput = {
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  /**  this entitlement is returned in the response to startVODTransaction */
  vodAssetEntitlementId: Scalars['ID']['input'];
};

export type CancelVodTransactionPayload = {
  __typename?: 'CancelVODTransactionPayload';
  /**  whether cancellation was succesful */
  success: Scalars['Boolean']['output'];
};

/**
 *  the Catalog interface makes sure that the clients can use Catalogs (a collection of objects) in a consistent way
 *  we would have liked this Catalog interface to also have an array of items
 *  we could declare "items: [Cacheable]!" here, but we cannot override this in eg. EventCatalog as "items: [Event]!",
 *  because it's not considered a correct polymorphic override
 *  using "items: [Cacheable]!" in a concrete Catalog (EventCatalog) is also not desired,
 *  because it doesn't give a clear indication as to what can be expected as objects inside an EventCatalog (for server nor client)
 *  therefore, we have the *convention* that every concrete Catalog type in this schema
 *  will have an "items: [...]!" field that contains an array of concrete objects
 *  for example: EventCatalog has an "items: [Event]!" field
 *  this way, it's clear for both client and server what should be in an EventCatalog and it avoids unnecessary type casting
 *  a Catalog always represents a complete collection of objects, meaning that it does not support pagination
 *  for the use cases were pagination is required, a Connection should be used (see below)
 */
export type Catalog = {
  /**  how many items are in this Catalog? */
  itemCount: Scalars['Int']['output'];
};

export type CatchupEventInput = {
  eventId: Scalars['ID']['input'];
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

export type CatchupEventPayload = {
  __typename?: 'CatchupEventPayload';
  playbackInfo: TimeshiftPlaybackInfo;
};

export type ChangeActiveProfileInput = {
  /**  The ID of the profile to activate for the current device. */
  profileId: Scalars['ID']['input'];
};

export type ChangeActiveProfilePayload = {
  __typename?: 'ChangeActiveProfilePayload';
  device: Device;
};

export type ChangeChannelListNameInput = {
  /**  The id of the channel list for which to change the name. */
  channelListId: Scalars['ID']['input'];
  /**  The new name for the channel list */
  name: Scalars['String']['input'];
};

/**  Result of changeChannelListName mutation */
export type ChangeChannelListNamePayload = {
  __typename?: 'ChangeChannelListNamePayload';
  /**  The updated channel list */
  channelList: ChannelList;
};

/**  Input for changeChannelPreferences mutation */
export type ChangeChannelPreferencesInput = {
  /**
   *  Channel specific audio language preference, stored on profile level.
   *  Format: ISO639-2T language code, e.g. "eng", "nld"
   *  Usage:
   *  - Setting the value to a new code creates a new or overrides the existing value.
   *  - "" (empty string): Audio/subtitle language preference is not filled in.
   *  - Setting the value to null clears the preference (equals to "").
   *  - Not passing the field leaves the existing value intact.
   */
  audioLanguage?: InputMaybe<Scalars['String']['input']>;
  /**  Identifies the Channel */
  channelId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Channel specific subtitle language preference, stored on profile level.
   *  Format: ISO639-2T language code, e.g. "eng", "nld"
   *  Usage:
   *  - Setting the value to a new code creates a new or overrides the existing value.
   *  - "none": Corresponds to language setting "None", which means no subtitles should be shown.
   *  - "" (empty string): Audio/subtitle language preference is not filled in.
   *  - Setting the value to null clears the preference (equals to "").
   *  - Not passing the field leaves the existing value intact.
   */
  subtitleLanguage?: InputMaybe<Scalars['String']['input']>;
};

/**  Result of changeChannelPreferences mutation */
export type ChangeChannelPreferencesPayload = {
  __typename?: 'ChangeChannelPreferencesPayload';
  /**  The changed channel */
  channel: Channel;
};

/**  used to (de)activate the device tracking consent */
export type ChangeDeviceAppTrackingConsentInput = {
  /**  Flag that indicates if the device gives app tracking consent. */
  enabled: Scalars['Boolean']['input'];
};

export type ChangeDeviceAppTrackingConsentPayload = {
  __typename?: 'ChangeDeviceAppTrackingConsentPayload';
  device: Device;
};

export type ChangeDeviceDrmNetworkDeviceIdInput = {
  /**  The DRM network device id to set */
  drmNetworkDeviceId: Scalars['String']['input'];
};

export type ChangeDeviceDrmNetworkDeviceIdPayload = {
  __typename?: 'ChangeDeviceDRMNetworkDeviceIdPayload';
  device: Device;
};

/**  used to (de)activate a device enablement policy */
export type ChangeDeviceEnablementPolicyInput = {
  /**  id of the device  you'd like to set */
  deviceEnablementPolicyId: Scalars['ID']['input'];
  /**  id of the device you'd like to enable or disable the policy for */
  deviceId: Scalars['ID']['input'];
  /**  Flag that indicates the state of device enablement policy. */
  enabled: Scalars['Boolean']['input'];
};

export type ChangeDeviceEnablementPolicyPayload = {
  __typename?: 'ChangeDeviceEnablementPolicyPayload';
  device: Device;
};

export type ChangeDeviceNameInput = {
  /**
   *  the id of the device to change the name
   *  when it's not provided as a parameter in the mutation, the name of the currently authenticated device is changed
   *  the name can be changed from another device in the same household
   *  e.g. this allows to easily rename a STB using a mobile device which has a keyboard.
   */
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  name can be anything the end user would like
   *  examples: living room, bedroom, office, Dad's phone, etc.
   */
  name: Scalars['String']['input'];
};

export type ChangeDeviceNamePayload = {
  __typename?: 'ChangeDeviceNamePayload';
  device: Device;
};

/**  used to (de)activate the device preview mode */
export type ChangeDevicePreviewModeInput = {
  /**  Flag that indicates the state of device preview mode. */
  enabled: Scalars['Boolean']['input'];
};

export type ChangeDevicePreviewModePayload = {
  __typename?: 'ChangeDevicePreviewModePayload';
  device: Device;
};

/**
 *  The input data for a device state change.
 *  Clients should only set those properties that have changed. Any property that is not set (or set to null) will retain the state that it already has on the back-end.
 */
export type ChangeDeviceStateInput = {
  /**  The cause that triggered this device state change. */
  cause: StateChangeCause;
  /**  The ID of a newly linked AVS device (e.g. in AVS component for Alexa on an STB) */
  linkedAVSDeviceId?: InputMaybe<Scalars['String']['input']>;
  /**  The ID of the type of a newly linked AVS device */
  linkedAVSDeviceTypeId?: InputMaybe<Scalars['String']['input']>;
  /**  Flag to indicate if the device is muted or not. */
  muted?: InputMaybe<Scalars['Boolean']['input']>;
  /**  The new playback status of the device. */
  playbackStatus?: InputMaybe<PlaybackStatus>;
  /**  Flag to indicate if the device is powered on or not (standby mode is also considered powered off). */
  poweredOn?: InputMaybe<Scalars['Boolean']['input']>;
  /**  The current volume of the device as a value from 0 to 100. A muted device can still have a volume value that is higher then 0. */
  volume?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeDeviceStatePayload = {
  __typename?: 'ChangeDeviceStatePayload';
  success: Scalars['Boolean']['output'];
};

export type ChangeDeviceVideoQualityLimitInput = {
  /**  The new Video Quality Limit for this device. */
  videoQualityLimitKind: VideoQualityLimitKind;
};

export type ChangeDeviceVideoQualityLimitPayload = {
  __typename?: 'ChangeDeviceVideoQualityLimitPayload';
  device: Device;
};

export type ChangeHouseholdChannelSortLanguageInput = {
  /**  ISO639-2T code of the language to set. */
  language: Scalars['String']['input'];
};

export type ChangeHouseholdChannelSortLanguagePayload = {
  __typename?: 'ChangeHouseholdChannelSortLanguagePayload';
  household: Household;
};

export type ChangeHouseholdCommunityInput = {
  communityId: Scalars['ID']['input'];
};

export type ChangeHouseholdCommunityPayload = {
  __typename?: 'ChangeHouseholdCommunityPayload';
  household: Household;
};

/**
 *  the [...]StepCompleted booleans can be set to true, false or be omitted:
 *  - true sets/updates the [...]StepCompleted in Household.householdOnboardingInfo to the current time
 *  - false clears it, setting it to null
 *  - omitting it, doesn't change it
 */
export type ChangeHouseholdOnboardingInfoInput = {
  communityStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  masterPincodeStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  privacyStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  profilesStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  replayStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangeHouseholdOnboardingInfoPayload = {
  __typename?: 'ChangeHouseholdOnboardingInfoPayload';
  onboardingInfo?: Maybe<HouseholdOnboardingInfo>;
};

/**  these are all the preferences that can be changed on Household level */
export type ChangeHouseholdPreferencesInput = {
  agreedToTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  displayNonAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  personalAdvertisingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /**  @deprecated on 2020-07-24: The viewing behaviour tracking should be stored on each profile instead of on the household (see ChangeProfilePreferencesInput) */
  trackViewingBehaviour?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangeHouseholdPreferencesPayload = {
  __typename?: 'ChangeHouseholdPreferencesPayload';
  /**  changing a Household preference obviously has an impact on the Household */
  household: Household;
};

/**  Input for changeInitialChannelList mutation */
export type ChangeInitialChannelListInput = {
  /**  Identifies the new initial channel list. */
  channelListId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  Result of changeInitialChannelList mutation */
export type ChangeInitialChannelListPayload = {
  __typename?: 'ChangeInitialChannelListPayload';
  /**  The new initial channel list */
  channelList: ChannelList;
};

export type ChangeLanguageInput = {
  /**  this ID should match the ID of an existing Language object */
  id: Scalars['ID']['input'];
};

export type ChangeLanguagePayload = {
  __typename?: 'ChangeLanguagePayload';
  /**  the UI language is set on the Device */
  device: Device;
};

export type ChangeMasterPincodeInput = {
  /**  back-office should validate that the master pincode consists of 4 digits! */
  masterPincode: Scalars['String']['input'];
};

export type ChangeMasterPincodePayload = {
  __typename?: 'ChangeMasterPincodePayload';
  /**  masterPincode is a field of Household */
  household: Household;
};

export type ChangeNetworkRecordingInput = {
  /**
   *  for now, deleteProtected is the only field that can be changed
   *  but in the future, other fields may be added, therefore it's not marked as mandatory
   */
  deleteProtected?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  recordingId: Scalars['ID']['input'];
};

export type ChangeNetworkRecordingPayload = {
  __typename?: 'ChangeNetworkRecordingPayload';
  recording: NetworkRecording;
};

/**
 *  the [...]StepCompleted booleans can be set to true, false or be omitted:
 *  - true sets/updates the [...]StepCompleted in Profile.profileOnboardingInfo to the current time
 *  - false clears it, setting it to null
 *  - omitting it, doesn't change it
 */
export type ChangeProfileOnboardingInfoInput = {
  ageRatingStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  privacyStepCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangeProfileOnboardingInfoPayload = {
  __typename?: 'ChangeProfileOnboardingInfoPayload';
  onboardingInfo?: Maybe<ProfileOnboardingInfo>;
};

export type ChangeProfilePermissionsInput = {
  accessSearch?: InputMaybe<Scalars['Boolean']['input']>;
  canPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  createRecordings?: InputMaybe<Scalars['Boolean']['input']>;
  displayBlockedChannels?: InputMaybe<Scalars['Boolean']['input']>;
  editChannelLists?: InputMaybe<Scalars['Boolean']['input']>;
  editMyLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *  fields only applicable to a Kids profile:
   *  @deprecated on 2020-11-04 to avoid confusion with the logoutPincode on type Profile. This one won't be used.
   */
  logoutPincode?: InputMaybe<Scalars['String']['input']>;
  manageApps?: InputMaybe<Scalars['Boolean']['input']>;
  maskContent?: InputMaybe<Scalars['Boolean']['input']>;
  otherProfilesContent?: InputMaybe<Scalars['Boolean']['input']>;
  /**  parentalRatingId refers to the ID of an existing "ParentalRating" object */
  parentalRatingId?: InputMaybe<Scalars['ID']['input']>;
  profileId: Scalars['ID']['input'];
  /**
   *  The monthly expenditure limit for purchases
   *  -1 is used to depict an unlimited value
   */
  purchaseLimit?: InputMaybe<Scalars['Float']['input']>;
  purchaseProtection?: InputMaybe<ProfileProtection>;
  resortApps?: InputMaybe<Scalars['Boolean']['input']>;
  /**  "singleChannelList" must be null if this profile is not limited to a specific ChannelList */
  singleChannelListId?: InputMaybe<Scalars['ID']['input']>;
};

export type ChangeProfilePermissionsPayload = {
  __typename?: 'ChangeProfilePermissionsPayload';
  profile: Profile;
};

export type ChangeProfilePincodeInput = {
  currentPincode: Scalars['String']['input'];
  newPincode: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};

export type ChangeProfilePincodePayload = {
  __typename?: 'ChangeProfilePincodePayload';
  profile: Profile;
};

export type ChangeProfilePreferencesInput = {
  avatar?: InputMaybe<ProfileAvatarType>;
  /**
   *  The consents that have been approved or denied for this profile
   *  If there are additional consents that have already been set on the back-end but are not in the provided list, these consents are left as-is
   *  Note that for certain legal consents (e.g. related to GDPR), it is not sufficient to store a Boolean in the back-end, but the date of
   *  acceptance must be stored instead. The date stored in the back-end for an approved consent should reflect the time when the consent went from
   *  undefined/denied to approved (i.e. if the client "updates" a consent to approved while it was already approved in the back-end, the stored timestamp
   *  should not be updated). This date is also returned to the client when requesting the profile consents.
   */
  consents?: InputMaybe<Array<InputMaybe<ProfileConsentInput>>>;
  /**
   *  Language preferences.
   *  Format: ISO639-2T language code, e.g. "eng", "nld"
   *  Usage:
   *  - Setting the value to a new code creates a new or overrides the existing value.
   *  - "none": Only applicable for subtitle language. Corresponds to language setting "None", which means no subtitles should be shown.
   *  - "" (empty string): Audio/subtitle language preference is not filled in.
   *  - Setting the value to null clears the preference (equals to "").
   *  - Not passing the field leaves the existing value intact.
   *  Changing one of the language preferences will reset all channel preferences (see also mutation
   *  resetProfilePreferences).
   */
  firstAudioLanguage?: InputMaybe<Scalars['String']['input']>;
  firstSubtitleLanguage?: InputMaybe<Scalars['String']['input']>;
  hardOfHearing?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *  When changing the ProfileKind, the parentalRatingId (part of the ProfilePermissions) will be changed automatically
   *  by the back-end to the DefaultProfileKindParentalRating of the ProfileKind if the rank of the parentalRating is
   *  set higher than the default parental rating of the profile kind.  If it is equal or less, the parentalRatingId will
   *  not be changed.
   *  Clients will read the parentalRatingId in this mutation's response and update the UI accordingly.
   *  See PR-261 for more details.
   */
  kind?: InputMaybe<ProfileKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
  protection?: InputMaybe<ProfileProtection>;
  secondAudioLanguage?: InputMaybe<Scalars['String']['input']>;
  secondSubtitleLanguage?: InputMaybe<Scalars['String']['input']>;
  /**  @deprecated on 2022-10-26: Replaced by the 'consents' property: it must be possible for different types of tracking/personalisation consents to be given. */
  trackViewingBehaviour?: InputMaybe<Scalars['Boolean']['input']>;
  visuallyImpaired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChangeProfilePreferencesPayload = {
  __typename?: 'ChangeProfilePreferencesPayload';
  profile: Profile;
};

/**  suggested expiry: 1 hour */
export type Channel = Cacheable & {
  __typename?: 'Channel';
  afterTime?: Maybe<Scalars['Int']['output']>;
  /**  since these images should fill up the entire screen, the client app knows the preferred dimensions */
  backgroundImage?: Maybe<Image>;
  /**  guard times in seconds for (n)PVR */
  beforeTime?: Maybe<Scalars['Int']['output']>;
  /**  the call to action content for barker channels */
  callToActionContent?: Maybe<ContentFolder>;
  /**
   *  this is the standard number as specified by the Operator and it is required when performing the "channels" query (on STB)
   *  user defined numbering can be obtained using the "ChannelListChannelsEdge.channelListNumber" field instead which
   *  allows a channel to have different numbers in different channel lists.
   */
  defaultNumber?: Maybe<Scalars['Int']['output']>;
  /**  multilingual description of the Channel, as it will be shown in the Detailed Channel Info screen. */
  description?: Maybe<Scalars['String']['output']>;
  /**
   *  if it's a DVB channel, this information should be available
   *  for DVB channels, the client won't send a "playChannel" request to the server
   *  it will use the information in DvbInfo to start playback
   *  note that content access rights (if any) should be checked by the CA system on the client
   */
  dvbInfo?: Maybe<DvbInfo>;
  /**  Channel entitlements */
  entitlements?: Maybe<ChannelEntitlements>;
  /**
   *  start: date and time in ISO8601
   *  blockDurations: a list of positive values, expressed in seconds. Server should return an error if this is negative.
   *  Returns a list of EventCatalogs, representing consecutive blocks of events starting from start, each having a duration as listed in blockDurations.
   *  So the returned EventCatalogs have these start times:
   *  EventCatalogs[0].start = start
   *  EventCatalogs[0].duration = blockDurations[0]
   *  EventCatalogs[i].start = EventCatalogs[i-1].start + EventCatalogs[i-1].duration
   *  EventCatalogs[i].duration = blockDurations[i]
   *  A block contains all events that have a start time inside the block or in case there is an event that starts before and ends after the block, that one is listed.
   *  The block is only empty if there isn't any EPG data at all. So all events for which:
   *  ((eventStart >= start) AND (eventStart < (start + duration)))
   *  OR
   *  ((eventStart < start) AND (eventEnd >= (start + duration)))
   */
  eventBlocks?: Maybe<Array<Maybe<EventCatalog>>>;
  /**
   * # methods to fetch EPG data:
   *  start: date and time in ISO8601
   *  duration: a positive value, expressed in seconds. Server should return an error if this is negative.
   *  returns all events that are (even partially) inside the timeframe indicated by start and duration). So all events for which:
   *  (eventStart < (start + duration)) AND (eventEnd >= start)
   */
  events?: Maybe<EventCatalog>;
  /**
   *  this method should return the indicated number of previous and following events
   *  the event playing at "time" should always be returned
   *  if previous == following == 0, only the currently playing event will be returned
   *  I'm open to suggestions for a better name
   *  previous and following will be positive integers, otherwise an error should be returned
   */
  eventsAt?: Maybe<EventCatalog>;
  expiry: Scalars['Date']['output'];
  /**
   *  When devices are unable to reach the back-office and are thus unable to execute a PlayChannel request
   *  they can use this fallbackinfo to tune in that situation.
   *  We are reusing the ChannelPlaybackInfo type here, however, the sessionid and heartbeat
   *  will not be used when using as fallbackInfo.  Those fields will not be filled in.
   */
  fallbackInfo?: Maybe<ChannelPlaybackInfo>;
  /**  how to display the channel in case it is configured to be hybrid */
  hybridDisplayBehaviour: HybridChannelDisplayBehaviour;
  /**
   *  how to playback the channel in case it is configured to be hybrid
   *  the client should be resilient to cases such as configuration error where
   *  the hybrid playback behaviour can be set to FORCE_DVB even when there may
   *  be not be a DvbInfo type available or the DVB channel is not found during
   *  scan. The client should not play the channel in these cases.
   */
  hybridPlaybackBehaviour: HybridChannelPlaybackBehaviour;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # all meta-info for this Channel: */
  kind: ChannelKind;
  /**
   *  Needed to support DVB-C time shift (PLTV) on flash memory for TV and Radio channels
   *  so that the network bandwidth requirements can be lowered. Can be different from the buffer size
   *  which is returned in TimeshiftPlaybackInfo.maximumBufferSize. Size is in seconds.
   */
  localTimeshiftMaximumBufferSize?: Maybe<Scalars['Int']['output']>;
  /**
   *  the client app should provide the required image dimensions
   *  these are integers (not a single enum), so we can easily add new image formats
   *  note: this may require an image server (but not necessarily)
   *  when using monochrome logos, the colour manipulation is done by the clients
   *  this is used for example in the Channel Grid screen for STB
   *  If flavour is not provided, ImageFlavour.NORMAL is assumed.
   */
  logo?: Maybe<Image>;
  parentalRating: ParentalRating;
  /**
   *  All profile-specific info is grouped into this separate type.
   *  if profileId is not passed, the id of the active profile will be used instead.
   */
  personalInfo?: Maybe<PersonalChannelInfo>;
  /**  Background image shown when watching a radio channel */
  playBackgroundImage?: Maybe<Image>;
  /**  Background image shown when not subscribed to channel and not in trial period */
  promotionalBackgroundImage?: Maybe<Image>;
  /**  related content for this Channel, listed as rows on the "Detailed Channel Info" screen */
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  If there is timed metadata on this channel, defines the source of this timed metadata.
   *  Additional types of sources may be added in the future, so clients must be able to ignore unknown sources.
   */
  timedMetadataSource?: Maybe<ChannelTimedMetadataSource>;
  /**  multilingual title of the Channel, as it will be shown in the UI */
  title: Scalars['String']['output'];
  /**  Background image shown when not subscribed to channel and in trial period */
  trialBackgroundImage?: Maybe<Image>;
  /**  Number of times per day, per device, that the trial background image banner should be displayed when a trial is active and tuning to this channel */
  trialBackgroundImageDisplayTimesPerDay: Scalars['Int']['output'];
  /**  Trial (temporary) channel entitlements */
  trialEntitlements?: Maybe<TrialChannelEntitlements>;
  /**
   *  user specific fields are grouped into this separate type:
   *  note that this information is not profile specific
   */
  userInfo?: Maybe<UserChannelInfo>;
};


/**  suggested expiry: 1 hour */
export type ChannelBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelEventBlocksArgs = {
  blockDurations: Array<InputMaybe<Scalars['Int']['input']>>;
  start: Scalars['Date']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelEventsArgs = {
  duration: Scalars['Int']['input'];
  start: Scalars['Date']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelEventsAtArgs = {
  following: Scalars['Int']['input'];
  previous: Scalars['Int']['input'];
  time: Scalars['Date']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelLogoArgs = {
  flavour?: InputMaybe<ImageFlavour>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  suggested expiry: 1 hour */
export type ChannelPlayBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelPromotionalBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 1 hour */
export type ChannelTrialBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/**
 *  Channel to ChannelEntitlements should be a 1:1 relationship
 *  suggested expiry: 30 minutes or less
 */
export type ChannelEntitlements = Cacheable & {
  __typename?: 'ChannelEntitlements';
  catchupTV: Scalars['Boolean']['output'];
  expiry: Scalars['Date']['output'];
  /**
   *  flag to indicate if the household replay permissions have been confirmed for this channel (optional since this may not have been configured yet for this channel).
   *  if householdConfirmedReplayPermissions is set to 'true', PauseLiveTV, CatchupTV, ... features on events will be enabled, based on the available products for the current
   *  session. If householdConfirmedReplayPermissions is set to false, none of these features will be enabled for the events on this channel. If the permissions
   *  have not been confirmed yet (i.e. this value is null), features of the event will be enabled as if the permissions were confirmed.
   *  Note: in deployments where the client does not have to confirm these permissions, this field must be set to 'true', not to 'null'. Otherwise, the client
   *  might detect a channel without permissions configured, and trigger a permissions configuration screen.
   */
  householdConfirmedReplayPermissions?: Maybe<Scalars['Boolean']['output']>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  These fields are only intended to be used in off-line mode.  These values can be cached and used in case no per-event entitlements can be retrieved.
   *  several of these entitlements are valid in this case as these features can be realised with a local HD.  Not sure if liveTV and networkRecording have any value here.
   *  In case of normal operation, the event entitlements need to be used instead.
   */
  liveTV: Scalars['Boolean']['output'];
  localRecording: Scalars['Boolean']['output'];
  networkRecording: Scalars['Boolean']['output'];
  pauseLiveTV: Scalars['Boolean']['output'];
  restartTV: Scalars['Boolean']['output'];
};

/**
 *  if multiple filter params are set, only channels that match all set filter params should
 *  be returned (i.e. filter params are combined using the AND operator).
 */
export type ChannelFilterParams = {
  /**  Overwrites possible filtering of adult channels (e.g. for a kids profile). */
  forceReturnAdultChannels?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Ignores the ProfilePermissions.displayBlockedChannels field and always returns blocked channels. */
  forceReturnBlockedChannels?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *  allows filtering of channels based on whether a household confirmed permissions is set or
   *  not (i.e. null or non-null).
   *  If this value is set to false, only channels which don't have a permissions configured
   *  are returned. If this value is set to true, only channels which have a permissions
   *  configured are returned. If this value is left empty, no filtering will be done based
   *  on the permissions of the channel.
   */
  householdConfirmedReplayPermissionsSet?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *  allows filtering of channels based on whether a household has the required features to
   *  perform replay actions on it. If set to true, only channels for which the household is
   *  allowed to perform replay are included. If set to false, only channels for which the
   *  household is not allowed to perform replay are included. If this value is left empty, no
   *  filtering will be done based on the household replay features
   *  this filter field does not take the householdConfirmedReplayPermission of a channel into
   *  account when determining whether replay is enabled for the channel or not. Only the product
   *  entitlement checking will be taken into account for this field.
   */
  replayEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ChannelKind {
  Radio = 'RADIO',
  Tv = 'TV'
}

/**
 *  suggested expiry: 30 minutes
 *  rationale: changes to "OPERATOR" ChannelLists should propagate reasonably quick
 */
export type ChannelList = Cacheable & {
  __typename?: 'ChannelList';
  /**
   *  The "last viewed" channel could be different for each profile. The channel returned will be the active channel for the currently active profile.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  activeChannel?: Maybe<Channel>;
  /**
   *  pagination is mainly required for the EPG use case, i.e.:
   *  fetch EPG data for channel 1-9, for the current time window
   *  an interesting note from the Relay spec:
   *  Including a value for both first and last is strongly discouraged, as it is likely to lead to confusing queries and results.
   *  The forceReturnBlockedChannels parameter allows blocked channels to be included even if the current profile permissions specify
   *  that blocked channels should not be displayed. If left empty, this property will default to false. It should be set to true when
   *  retrieving channels for channel list editing.
   *  The forceReturnAdultChannels parameters allows adult channels to be included even if they would normally be filtered out (e.g.
   *  for a kids profile). If left empty, this property will default to false.
   */
  channels: ChannelListChannelsConnection;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  see enum below */
  kind: ChannelListKind;
  /**
   *  in case this is a user created ChannelList, the name is not multilingual
   *  if the kind is OPERATOR or DYNAMIC, the name is multilingual
   */
  name: Scalars['String']['output'];
};


/**
 *  suggested expiry: 30 minutes
 *  rationale: changes to "OPERATOR" ChannelLists should propagate reasonably quick
 */
export type ChannelListActiveChannelArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 *  suggested expiry: 30 minutes
 *  rationale: changes to "OPERATOR" ChannelLists should propagate reasonably quick
 */
export type ChannelListChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceReturnAdultChannels?: InputMaybe<Scalars['Boolean']['input']>;
  forceReturnBlockedChannels?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**  suggested expiry: 30 minutes */
export type ChannelListCatalog = Cacheable & Catalog & {
  __typename?: 'ChannelListCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<ChannelList>>;
};

/**  suggested expiry: 30 minutes */
export type ChannelListChannelsConnection = Cacheable & Connection & {
  __typename?: 'ChannelListChannelsConnection';
  edges: Array<Maybe<ChannelListChannelsEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many Channels are available in total in this ChannelList? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/**  suggested expiry: DEFAULT */
export type ChannelListChannelsEdge = Cacheable & Edge & {
  __typename?: 'ChannelListChannelsEdge';
  /**  Relation fields for the ChannelList - Channel relation */
  channelListNumber: Scalars['Int']['output'];
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: Channel;
};

export enum ChannelListKind {
  /**
   *  this kind can be used for a "placeholder" ChannelList where the client will store all locally scanned DVB channels
   *  note that the associated list of channels should be empty in this case
   */
  Dvb = 'DVB',
  /**
   *  e.g. TV, Radio, HD, etc.
   *  this kind of lists don't have to be provided by the back-office
   *  but they could be generated by "local" resolvers (e.g. in a DVB-C environment)
   */
  Dynamic = 'DYNAMIC',
  /**
   *  indication that this is the complete set of Channels available in the line-up
   *  if the client does a local scan, it will add DVB channels to this list
   */
  Full = 'FULL',
  /**  e.g. Sports, Movies, Documentaries, Kids, etc. */
  Operator = 'OPERATOR',
  /**  e.g. My favourites, My kids, Bedroom, etc. */
  Subscriber = 'SUBSCRIBER'
}

/**
 *  suggested expiry: it depends...
 *  for example: some deployments may require "universal" channels, which means that the playback URL depends on the available bandwidth
 *  this requirement probably results in a rather short expiry
 *  other deployments may have more "static" URLs, that can be cached much longer
 *  however, the event-blocking use case should also be taken into account
 *  this means that the expiry can probably not be longer than the end time of the currently playing event on this particular channel
 */
export type ChannelPlaybackInfo = Cacheable & PlaybackInfo & {
  __typename?: 'ChannelPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /**  this allows the client to do parental rating checks etc. */
  channel: Channel;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  expiry: Scalars['Date']['output'];
  heartbeat?: Maybe<Heartbeat>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
};

/**
 *  suggested expiry: DEFAULT
 *  this type is equivalent to the "channel package" concept as described in the functional spec
 *  it's a product that contains a number of channels and can be bought using the purchaseChannelProduct mutation
 *  note that adding/removing channels to a product results in a new ChannelProductChannelsConnection, which has a shorter expiry
 */
export type ChannelProduct = Cacheable & Product & {
  __typename?: 'ChannelProduct';
  backgroundImage?: Maybe<Image>;
  /**
   *  which channels belong to this product?
   *  @deprecated on 2018-05-04: use the "relatedContent" field instead, which will also contain the channels
   */
  channels: ChannelProductChannelsConnection;
  /**
   * # Specific
   *  multilingual description of this commercial product, as entered by the Operator in the CMS
   *  @deprecated on 2018-05-04: use the "shortDescription" and "fullDescription" fields instead
   */
  description?: Maybe<Scalars['String']['output']>;
  entitlement?: Maybe<ProductEntitlement>;
  expiry: Scalars['Date']['output'];
  fullDescription: Scalars['String']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  kind: ProductKind;
  price?: Maybe<Price>;
  purchaseInfo?: Maybe<ProductPurchaseInfo>;
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  Optional deeplink URL for the package in the selfcare webapp.
   *  If specified (not null nor empty string), the client will open this URL in the browser instead of the client's native Detailed Package Info screen.
   */
  selfcareUrl?: Maybe<Scalars['String']['output']>;
  shortDescription: Scalars['String']['output'];
  /**
   *  multilingual subtitle string of the product
   *  GraphQL server implementation note: following variables should be replaced by the server:
   *  - ${channelCount}: the number of channels in this product.
   *  e.g. "${channelCount} channels for you and your family" => "40 channels for you and your family"
   */
  subtitle: Scalars['String']['output'];
  thumbnail?: Maybe<Image>;
  /** # Product interface: */
  title: Scalars['String']['output'];
  trailers: TrailerCatalog;
};


/**
 *  suggested expiry: DEFAULT
 *  this type is equivalent to the "channel package" concept as described in the functional spec
 *  it's a product that contains a number of channels and can be bought using the purchaseChannelProduct mutation
 *  note that adding/removing channels to a product results in a new ChannelProductChannelsConnection, which has a shorter expiry
 */
export type ChannelProductBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  suggested expiry: DEFAULT
 *  this type is equivalent to the "channel package" concept as described in the functional spec
 *  it's a product that contains a number of channels and can be bought using the purchaseChannelProduct mutation
 *  note that adding/removing channels to a product results in a new ChannelProductChannelsConnection, which has a shorter expiry
 */
export type ChannelProductChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  suggested expiry: DEFAULT
 *  this type is equivalent to the "channel package" concept as described in the functional spec
 *  it's a product that contains a number of channels and can be bought using the purchaseChannelProduct mutation
 *  note that adding/removing channels to a product results in a new ChannelProductChannelsConnection, which has a shorter expiry
 */
export type ChannelProductShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  suggested expiry: DEFAULT
 *  this type is equivalent to the "channel package" concept as described in the functional spec
 *  it's a product that contains a number of channels and can be bought using the purchaseChannelProduct mutation
 *  note that adding/removing channels to a product results in a new ChannelProductChannelsConnection, which has a shorter expiry
 */
export type ChannelProductThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**  suggested expiry: DEFAULT */
export type ChannelProductCatalog = Cacheable & Catalog & {
  __typename?: 'ChannelProductCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<ChannelProduct>>;
};

/**  suggested expiry: 30 minutes */
export type ChannelProductChannelsConnection = Cacheable & Connection & {
  __typename?: 'ChannelProductChannelsConnection';
  edges: Array<Maybe<ChannelProductChannelsEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many Channels are available in total in this ChannelProduct? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/**  suggested expiry: DEFAULT */
export type ChannelProductChannelsEdge = Cacheable & Edge & {
  __typename?: 'ChannelProductChannelsEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: Channel;
};

export enum ChannelSortingMode {
  /**
   *  alphabetical sorting to be used for e.g. the channel blocking dialog
   *  the sorting is performed according to the title of the channel (for the active language of the requesting client)
   */
  Alphabetical = 'ALPHABETICAL',
  Default = 'DEFAULT',
  /**
   *  sorting to be used for the channel management settings screen
   *  the resulting sort logic might be configurable serverside per deployment, the first option is required to be implemented.
   *  note that replay enabled indicates whether replay is possible for the channel, not whether replay is turned on for the household, that is indicated by householdConfirmedReplayPermissions.
   *  1. list all replay enabled channels at the top for which the replay permission hasn't been confirmed yet (replayEnabled=true and householdConfirmedReplayPermissions=null),
   *     followed by all remaining channels
   *     preserve DEFAULT order as secondary order: in case all channels are replayEnabled and none have been confirmed yet, this is the same as DEFAULT
   *  2. list all replay enabled channels at the top for which the replay permission hasn't been confirmed yet (replayEnabled=true and householdConfirmedReplayPermissions=null),
   *     followed by all for which it is confirmed to true (replayEnabled=true and householdConfirmedReplayPermissions=true), followed by all remaining channels
   *     preserve DEFAULT order as secondary order: in case all channels are replayEnabled and none have been confirmed yet, this is the same as DEFAULT
   */
  ReplayPermissions = 'REPLAY_PERMISSIONS'
}

export type ChannelSortingParams = {
  /**  when not specified, DEFAULT is used. */
  mode?: InputMaybe<ChannelSortingMode>;
};

export enum ChannelTimedMetadataSource {
  /**  The timed metadata should be retrieved from the backend using the timedMetadata query */
  BackendQuery = 'BACKEND_QUERY',
  /**  The timed metadata is embedded in the stream */
  Stream = 'STREAM'
}

/**  suggested expiry: 30 minutes */
export type ChannelsConnection = Cacheable & Connection & {
  __typename?: 'ChannelsConnection';
  edges: Array<Maybe<ChannelsEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many Channels are available in total? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/**  suggested expiry: DEFAULT */
export type ChannelsEdge = Cacheable & Edge & {
  __typename?: 'ChannelsEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: Channel;
};

export type ClearRecommendationHistoryInput = {
  /**  The ID for which the recommendation history should be cleared. */
  profileId: Scalars['ID']['input'];
};

export type ClearRecommendationHistoryPayload = {
  __typename?: 'ClearRecommendationHistoryPayload';
  success: Scalars['Boolean']['output'];
};

export type ClearWatchHistoryInput = {
  /**  The ID for which the watch history should be cleared. */
  profileId: Scalars['ID']['input'];
};

export type ClearWatchHistoryPayload = {
  __typename?: 'ClearWatchHistoryPayload';
  success: Scalars['Boolean']['output'];
};

/**  suggested expiry: DEFAULT */
export type Community = Cacheable & {
  __typename?: 'Community';
  /**  an additional multilingual description of what this community represents and why certain users may want to be part of it */
  description?: Maybe<Scalars['String']['output']>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  the multilingual name of the Community, eg. Vlaanderen, Limburg, Athens, etc. */
  title: Scalars['String']['output'];
};

/**  suggested expiry: DEFAULT */
export type CommunityCatalog = Cacheable & Catalog & {
  __typename?: 'CommunityCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Community>>;
};

export type CompleteVodTransactionInput = {
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  /**  this entitlement is returned in the response to startVODTransaction */
  vodAssetEntitlementId: Scalars['ID']['input'];
};

export type CompleteVodTransactionPayload = {
  __typename?: 'CompleteVODTransactionPayload';
  /**  via the VODAsset, the entitlements can be updated */
  asset: VodAsset;
};

export enum ConflictAutoResolution {
  /**  The recording status will change from CONFLICT to FAILED if not manually resolved before the event starts. */
  Fail = 'FAIL',
  /**  The recording status will change from CONFLICT to IN_PROGRESS (or COMPLETE, depending on the current time) */
  Record = 'RECORD'
}

/**  interfaces & types related to the Relay pagination concept: https://facebook.github.io/relay/graphql/connections.htm */
export type Connection = {
  /**
   *  same polymorphism problem as with Catalogs & Cacheables
   *  unfortunately, an array of eg. Channels is not compliant with an array of Edges, even if Channel implements Edge
   *  edges: [Edge]!
   */
  pageInfo: PageInfo;
  /**  for (virtually) infinite lists, totalCount is unknown (and irrelevant), so it should be null */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ContentDeliveryKind {
  /**  eg. http://cdn.somewhere.net/dash/channelY.mpd */
  Dash = 'DASH',
  /**
   *  represented by DVB triplet: original network id, transport stream id, service id
   *  eg. dvb://100.32.101
   */
  Dvb = 'DVB',
  /**
   *  eg. "http://cdn.somewhere.net/hls/channelX.m3u8"
   *  will probably contain an access token
   */
  Hls = 'HLS',
  /**
   *  used for progressive HTTP streaming
   *  eg. http://cdn.somewhere.net/streams/movieOne.mpeg
   */
  Http = 'HTTP',
  /**  eg. "udp://224.1.2.3:4567" */
  Multicast = 'MULTICAST',
  /**  eg. rtsp://192.168.1.123/vod/movie.ts */
  Rtsp = 'RTSP'
}

export type ContentFolder = Cacheable & {
  __typename?: 'ContentFolder';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  includeCursor defines whether or not the item that belongs to the cursor that has been passed in, will be included in the response.
   *  It will be;
   *  - prepended at the front when using the afterCursor
   *  - appended to the back when using the beforeCursor
   *  by default or when not passed in, it will be false
   */
  items?: Maybe<ContentFolderContentItemsConnection>;
  kind: ContentFolderKind;
  /**
   *  Timestamp that indicates when the client should reload this contentfolder because the content may have changed.
   *  Can be null if not relevant
   *  The back-office must ensure that Cacheable.expiry <= refreshAt.
   *  Clients should:
   *  - use the server UTC time and not the local device UTC time to compare with
   *  - always do a safety check when planning a timer to refresh: if this time is already the past, they should not plan a refresh.
   */
  refreshAt?: Maybe<Scalars['Date']['output']>;
  /**
   *  this is the "row title" as mentioned in the functional spec
   *  multilingual value, describing what's being shown in this content row
   */
  title: Scalars['String']['output'];
};


export type ContentFolderItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeCursor?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentFolderContentItemsConnection = Cacheable & Connection & {
  __typename?: 'ContentFolderContentItemsConnection';
  edges: Array<Maybe<ContentFolderContentItemsEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many ContentItems are available in total? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/**  suggested expiry: DEFAULT */
export type ContentFolderContentItemsEdge = Cacheable & Edge & {
  __typename?: 'ContentFolderContentItemsEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: ContentItem;
};

/**
 *  The type of a content folder, identifying what the source was for building the content of this folder.
 *  This type might influence the way that the content folder is shown on the client.
 *  E.g. a Recordings content folder will display the quota in the header of the folder when displayed in the My Library screen.
 */
export enum ContentFolderKind {
  ArchivedRecordings = 'ARCHIVED_RECORDINGS',
  ContinueWatching = 'CONTINUE_WATCHING',
  Epg = 'EPG',
  Favourites = 'FAVOURITES',
  Purchases = 'PURCHASES',
  Recommendation = 'RECOMMENDATION',
  Recordings = 'RECORDINGS',
  RecordingConflicts = 'RECORDING_CONFLICTS',
  Reminders = 'REMINDERS',
  Search = 'SEARCH',
  Vod = 'VOD'
}

export type ContentFolderList = Cacheable & {
  __typename?: 'ContentFolderList';
  expiry: Scalars['Date']['output'];
  folders: ContentFolderListContentFoldersConnection;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};


export type ContentFolderListFoldersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentFolderListContentFoldersConnection = Cacheable & Connection & {
  __typename?: 'ContentFolderListContentFoldersConnection';
  edges: Array<Maybe<ContentFolderListContentFoldersEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many ContentFolders are available in total in this ContentFolderList? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ContentFolderListContentFoldersEdge = Cacheable & Edge & {
  __typename?: 'ContentFolderListContentFoldersEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: ContentFolder;
};

/**  this union will be extended later on (eg. Promotion) */
export type ContentItem = Banner | Channel | ChannelProduct | Event | NetworkRecording | ProductBundle | Series | VodAsset | VodFolder | VodProduct | VodSeries;

/**
 *  A catalog with content items.
 *  Suggested expiry: DEFAULT
 */
export type ContentItemCatalog = Cacheable & Catalog & {
  __typename?: 'ContentItemCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<ContentItem>>;
};

/**  The types of content items that are available. */
export enum ContentItemType {
  Banner = 'BANNER',
  Channel = 'CHANNEL',
  Channelproduct = 'CHANNELPRODUCT',
  Event = 'EVENT',
  Networkrecording = 'NETWORKRECORDING',
  Productbundle = 'PRODUCTBUNDLE',
  Series = 'SERIES',
  Vodasset = 'VODASSET',
  Vodfolder = 'VODFOLDER',
  Vodproduct = 'VODPRODUCT',
  Vodseries = 'VODSERIES'
}

/**  The content provider of a third party asset */
export type ContentProvider = Cacheable & {
  __typename?: 'ContentProvider';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The logo of the content provider */
  logo?: Maybe<Image>;
};


/**  The content provider of a third party asset */
export type ContentProviderLogoArgs = {
  flavour?: InputMaybe<ImageFlavour>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/**
 *  for createDevice, only the fields are passed that are really
 *  required by the back-offices to create a device
 *  the back-office might or might not set the passed fields
 *  required by other back-offices
 *  so this might be extended with extra fields later on, and the
 *  client must do a changeDevice as an extra mutiation afterwards
 *  to ensure that it is set on all back-offices
 */
export type CreateDeviceInput = {
  clientGeneratedDeviceId: Scalars['String']['input'];
  deviceType: DeviceType;
  /**  if languageId is not provided, the device will get the default language as configured in the back-office */
  languageId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/**
 *  Contains a boolean that indicates if the Device was successfully created.
 *  Notice that an error will be raised when the Device couldn't be created so the
 *  value false will never be returned. We need to return something so we use a boolean.
 *  The newly created Device itself can not be returned because its details can be
 *  unknown or unaccessible by the back-office GraphQL layer at this point.
 */
export type CreateDevicePayload = {
  __typename?: 'CreateDevicePayload';
  /**
   *  Indicates if the Nexx 4.0 Device should reauthenticate in order to proceed. This
   *  depends on the back-office. For example for SDS Evo this boolean will always equal
   *  true while for SDS Cloud it will always be false.
   */
  reauthenticate: Scalars['Boolean']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateNetworkRecordingInput = {
  /**  must refer to the "id" of an Event object */
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateNetworkRecordingPayload = {
  __typename?: 'CreateNetworkRecordingPayload';
  /**  creating a new recording could have an impact on the quota */
  quota?: Maybe<Quota>;
  /**  clients should refresh the Event linked to this Recording (can be done via the Recording -> Event relation) */
  recording: NetworkRecording;
};

export type CreateNetworkSeriesRecordingInput = {
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateNetworkSeriesRecordingPayload = {
  __typename?: 'CreateNetworkSeriesRecordingPayload';
  quota?: Maybe<Quota>;
  recordings: Array<Maybe<NetworkRecording>>;
};

export type CreatePersonalChannelListInput = {
  /**
   *  Since a channel list is not allowed to contain no channels in the client, the list of channels for the new channel list is required.
   *  This list of channels must contain at least one channel id.
   */
  channels: Array<InputMaybe<Scalars['ID']['input']>>;
  /**  The name of the new channel list */
  name: Scalars['String']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  Result of createPersonalChannelList mutation */
export type CreatePersonalChannelListPayload = {
  __typename?: 'CreatePersonalChannelListPayload';
  /**  The created channel list */
  channelList: ChannelList;
};

export type CreateProfileInput = {
  avatar?: InputMaybe<ProfileAvatarType>;
  kind: ProfileKind;
  name: Scalars['String']['input'];
  /**  Optional. If none is provided, a default avatar type will be used by the back-end. */
  parentalRatingId: Scalars['ID']['input'];
};

export type CreateProfilePayload = {
  __typename?: 'CreateProfilePayload';
  profile: Profile;
};

export type CreateProfilePincodeInput = {
  pincode: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};

export type CreateProfilePincodePayload = {
  __typename?: 'CreateProfilePincodePayload';
  profile: Profile;
};

/**  Defines the kind of modulation that is used for automatic channel scanning by STB */
export enum DvbModulationKind {
  Qam16 = 'QAM16',
  Qam32 = 'QAM32',
  Qam64 = 'QAM64',
  Qam128 = 'QAM128',
  Qam256 = 'QAM256'
}

/**  This type contains DVB channel scanning parameters for allowing STB to do an automatic channel scan, in which they'll read the NIT DVB tables on the right transport stream */
export type DvbScanParameters = Cacheable & {
  __typename?: 'DVBScanParameters';
  expiry: Scalars['Date']['output'];
  /**  The nit_frequency in Hz */
  frequency: Scalars['Int']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The kind of QAM modulation */
  modulation: DvbModulationKind;
  /**  The symbol_rate in symbols/s */
  symbolRate: Scalars['Int']['output'];
};

/**  suggested expiry: DEFAULT */
export type DefaultProfileKindParentalRating = Cacheable & {
  __typename?: 'DefaultProfileKindParentalRating';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  parentalRating: ParentalRating;
  profileKind: ProfileKind;
};

/**  suggested expiry: DEFAULT */
export type DefaultProfileKindParentalRatingCatalog = Cacheable & Catalog & {
  __typename?: 'DefaultProfileKindParentalRatingCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<DefaultProfileKindParentalRating>>;
};

export type DeleteAllDownloadsPayload = {
  __typename?: 'DeleteAllDownloadsPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteDeviceInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDevicePayload = {
  __typename?: 'DeleteDevicePayload';
  household: Household;
};

export type DeleteEpisodesOfNetworkSeriesRecordingInput = {
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteEpisodesOfNetworkSeriesRecordingPayload = {
  __typename?: 'DeleteEpisodesOfNetworkSeriesRecordingPayload';
  /**  A collection of deleted events. */
  events: Array<Maybe<Event>>;
  /**  After deleting, new episodes will still be recorded. */
  quota?: Maybe<Quota>;
  /**  Other impacted recordings, if any. The deleted recording is not present in this array. */
  recordings?: Maybe<Array<Maybe<Recording>>>;
};

export type DeleteEventBookmarkInput = {
  eventId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteEventBookmarkPayload = {
  __typename?: 'DeleteEventBookmarkPayload';
  event: Event;
};

export type DeleteForeignDeviceInput = {
  /**  The clientGeneratedDeviceId of the device that has to be deleted from its household */
  clientGeneratedDeviceId: Scalars['String']['input'];
};

export type DeleteForeignDevicePayload = {
  __typename?: 'DeleteForeignDevicePayload';
  status: ForeignDeviceDeletionStatus;
};

/**  Input for the deleteMessage mutation */
export type DeleteMessageInput = {
  messageId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  Result of the deleteMessage mutation */
export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteProfileInput = {
  profileId: Scalars['ID']['input'];
};

export type DeleteProfilePayload = {
  __typename?: 'DeleteProfilePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteProfilePincodeInput = {
  currentPincode: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};

export type DeleteProfilePincodePayload = {
  __typename?: 'DeleteProfilePincodePayload';
  profile: Profile;
};

export type DeleteRecordingBookmarkInput = {
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  recordingId: Scalars['ID']['input'];
};

export type DeleteRecordingBookmarkPayload = {
  __typename?: 'DeleteRecordingBookmarkPayload';
  recording: Recording;
};

export type DeleteRecordingInput = {
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  recordingId: Scalars['ID']['input'];
};

export type DeleteRecordingPayload = {
  __typename?: 'DeleteRecordingPayload';
  /**  The impacted event. Null in case of manual recording. */
  event?: Maybe<Event>;
  /**  Deleting a recording could have an impact on the quota. */
  quota?: Maybe<Quota>;
  /**  Other impacted recordings, if any. The deleted recording is not present in this array. */
  recordings?: Maybe<Array<Maybe<Recording>>>;
};

export type DeleteVodBookmarkInput = {
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  vodAssetId: Scalars['ID']['input'];
};

export type DeleteVodBookmarkPayload = {
  __typename?: 'DeleteVODBookmarkPayload';
  vodAsset: VodAsset;
};

export type DetermineCommunityInput = {
  /**  Operator specific data, typically a JSON structure */
  data: Scalars['String']['input'];
};

export type DetermineCommunityPayload = {
  __typename?: 'DetermineCommunityPayload';
  household: Household;
};

/**  suggested expiry: infinity */
export type Device = Cacheable & {
  __typename?: 'Device';
  /**  The currently active profile for this device */
  activeProfile: Profile;
  /**  Indicates if the device has given its consent to share data with 3rd parties. e.g. To allow App Tracking Transparency on iOS */
  appTrackingConsent?: Maybe<Scalars['Boolean']['output']>;
  /**
   *  the id generated by the device to identify and register itself
   *  the HTTP header "deviceId" will always contain this value
   *  might not be unique over households
   */
  clientGeneratedId: Scalars['String']['output'];
  /**
   *  Certain channel providers dictate that (concurrent) playback of some channels is limited to a household's
   *  set-top box and one (or more) specific mobile device(s) that the user can select.
   *  A DeviceEnablementPolicy describes if this device is enabled to playback content for a certain channel provider.
   */
  deviceEnablementPolicies: Array<Maybe<DeviceEnablementPolicy>>;
  /**  the device type */
  deviceType: DeviceType;
  /**  The DRM network device id, as specified using the changeDeviceDRMNetworkDeviceId mutation. Can be null if not set yet. */
  drmNetworkDeviceId?: Maybe<Scalars['String']['output']>;
  /**
   *  Indicates which events should be logged by the client, e.g. user activity reporting.
   *  An empty array indicates that event logging is completely disabled.
   *  This is an optional field for backward compatibility. When not present, it is to be interpreted as an empty array (logging disabled).
   *  If track viewing behaviour is off, event logging will be disabled.
   */
  eventLoggingOptions?: Maybe<Array<Maybe<EventLoggingOption>>>;
  expiry: Scalars['Date']['output'];
  /**
   *  Optional fingerprint identifier, currently only used for managed (STB) devices.
   *  Length of the identifier should be limited to a maximum of 12 characters to allow it to be displayed correctly.
   */
  fingerprintId?: Maybe<Scalars['String']['output']>;
  /**
   *  made it available in GraphQL to:
   *  - be able to distinguish in documentation when "id" or "deviceGeneratedId" is expected.
   *  - prevent the need to translate/lookup the back-office id from the passed deviceGeneratedId when used in GraphQL.
   */
  id: Scalars['ID']['output'];
  /**  the UI language is set on Device level (not on profile level) */
  language: Language;
  /**
   *  the name could be set by the CRM during provisioning
   *  or by the end user (client app) when registering a new device
   *  the name is shown to the end user for inter-device communication features, such as media casting, remote recordings, etc.
   *  it's optional since it's not always provided for managed devices, in which case it's up to the client app to set/update the name
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   *  Indicates if the device is set to preview mode (see the changeDevicePreviewMode mutation).
   *  It allows marketing users to easily evaluate a new configuration (e.g. rows) on real devices by publishing
   *  to devices in "Preview mode" rather than all "Normal mode" devices.
   */
  previewModeEnabled: Scalars['Boolean']['output'];
  /**  The VOD asset to be played as quick guide. */
  quickGuideVideo?: Maybe<VodAsset>;
  /**
   *  specifies the date and time when the device was registered
   *  generated by the back-office
   */
  registrationTime: Scalars['Date']['output'];
  /**
   *  Indicates if the device can be removed (unregistered) by the viewer or not.
   *  When true, the removableFrom field can be used to specify a date until when the device can not be removed.
   */
  removable: Scalars['Boolean']['output'];
  /**
   *  Specifies a date and a time until when the device cannot be removed (unregistered) by the user,
   *  to avoid abuse of a household being shared by too many devices.
   *  This field should only be used if the value of removable equals true.
   *  If removableFrom is set and removable is true, the device can be removed via deleteDevice from this date and time onwards.
   *  If removableFrom equals null and removable is true, the device can be removed without a time limitation.
   */
  removableFrom?: Maybe<Scalars['Date']['output']>;
  /**
   *  this indicates whether this Device can be renamed at all
   *  it will be used in device management, when device X is trying to rename device Y (in the same Household of course)
   */
  renameable: Scalars['Boolean']['output'];
  /**
   *  The video quality limit setting for the current device. Indicates in the back-end which constraints should be placed on the used playback
   *  quality when there are limitations of how much bandwith a household is allowed to use.
   */
  videoQualityLimitKind?: Maybe<VideoQualityLimitKind>;
};

export type DeviceCatalog = Cacheable & Catalog & {
  __typename?: 'DeviceCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Device>>;
};

/**  suggested expiry: DEFAULT */
export type DeviceEnablementPolicy = Cacheable & {
  __typename?: 'DeviceEnablementPolicy';
  /**  flag that indicates the activation state of device enablement policy */
  enabled: Scalars['Boolean']['output'];
  /**  not allowed to deactivate this device enablement policy before this date */
  enabledUntil?: Maybe<Scalars['Date']['output']>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  specifies how many devices that can have this policy enabled. If this property is empty/null, there is no such limit. */
  maxNumberDevices?: Maybe<Scalars['Int']['output']>;
  /**  abbreviated title for the device enablement policy, e.g. 'NOVA' */
  shortTitle?: Maybe<Scalars['String']['output']>;
  /**  title of this device enablement policy, e.g. 'Nova Enabled Device' */
  title?: Maybe<Scalars['String']['output']>;
  /**
   *  The type of this policy. Clients can use this type to determine if certain functionality should be activated based on
   *  the presence and state of the policy, e.g. in the case of Nova (show a message in device management and activate master
   *  device functionality) or EST/DTO (show icons in device management to display if a device has EST/DTO enabled).
   *  The type can be empty if no client-side functionality is to be activated by the policy.
   */
  type?: Maybe<Scalars['DeviceEnablementPolicyType']['output']>;
};

export enum DeviceType {
  AmazonFireTv = 'AMAZON_FIRE_TV',
  AndroidPhone = 'ANDROID_PHONE',
  AndroidStb = 'ANDROID_STB',
  AndroidTablet = 'ANDROID_TABLET',
  Chromecast = 'CHROMECAST',
  /**  Used for e.g. Alexa, Google Assistant, ... */
  Controller = 'CONTROLLER',
  IosPhone = 'IOS_PHONE',
  IosTablet = 'IOS_TABLET',
  /**  @deprecated on 2019-05-17: use the more specific LINUX_<browser> values instead. */
  Linux = 'LINUX',
  LinuxChrome = 'LINUX_CHROME',
  LinuxFirefox = 'LINUX_FIREFOX',
  /**  @deprecated on 2019-05-17: use the more specific MACOS_<browser> values instead. */
  Macos = 'MACOS',
  MacosChrome = 'MACOS_CHROME',
  MacosEdge = 'MACOS_EDGE',
  MacosFirefox = 'MACOS_FIREFOX',
  MacosSafari = 'MACOS_SAFARI',
  NativeDvbStb = 'NATIVE_DVB_STB',
  NativeDvbStbLite = 'NATIVE_DVB_STB_LITE',
  NativeStb = 'NATIVE_STB',
  NativeStbIptvAdsl = 'NATIVE_STB_IPTV_ADSL',
  NativeStbLite = 'NATIVE_STB_LITE',
  NativeStbLiteOtt = 'NATIVE_STB_LITE_OTT',
  NativeStbOtt = 'NATIVE_STB_OTT',
  SmartTvAndroidTv = 'SMART_TV_ANDROID_TV',
  SmartTvSaphios = 'SMART_TV_SAPHIOS',
  SmartTvTizen = 'SMART_TV_TIZEN',
  SmartTvTvos = 'SMART_TV_TVOS',
  SmartTvVidaaos = 'SMART_TV_VIDAAOS',
  SmartTvWebos = 'SMART_TV_WEBOS',
  /**  @deprecated on 2019-05-17: use the more specific WINDOWS_PC_<browser> values instead. */
  WindowsPc = 'WINDOWS_PC',
  WindowsPcChrome = 'WINDOWS_PC_CHROME',
  WindowsPcEdge = 'WINDOWS_PC_EDGE',
  WindowsPcFirefox = 'WINDOWS_PC_FIREFOX',
  WindowsPcInternetExplorer = 'WINDOWS_PC_INTERNET_EXPLORER'
}

export enum DownloadStatus {
  /**  The download of the asset has failed. The back-end may delete downloads that have been set to the FAILED state. */
  Completed = 'COMPLETED',
  /**  The download has completed and is now stored on the device. */
  Deleted = 'DELETED',
  /**  The asset is being downloaded. This includes downloads that are paused, but can be resumed later on. */
  Failed = 'FAILED',
  /**  The download has been requested using the startVODDownload mutation, but hasn't started yet. */
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type DownloadVodAssetInput = {
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
  /**
   *  The id of a VODAssetEntitlement that shows the user is permitted to watch the Asset.
   *  If needed the back-office can use that VODAssetEntitlement to determine the Product that
   *  was purchased to get it.
   */
  vodAssetEntitlementId: Scalars['ID']['input'];
  vodAssetId: Scalars['ID']['input'];
};

export type DownloadVodAssetPayload = {
  __typename?: 'DownloadVODAssetPayload';
  playbackInfo: VodPlaybackInfo;
};

export type DvbInfo = Cacheable & {
  __typename?: 'DvbInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  Alternative way of supporting channel matching for some deployments.
   *  String-based so that any value may be passed.
   *  There can be multiple values because in some cases multiple values will map onto the same GQL channel.
   */
  linkingIdentifiers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *  the triplet {original network ID, transport stream ID and service ID} uniquely identifies a DVB channel
   *  this DVB URI scheme is specified in TS 102 851 [15], clause 6.4
   *  this information is sufficient for a DVB STB to select the correct channel
   *  for hybrid DVB/OTT-playback, these IDs are used to match channels with channels found during DVB scan
   *  to indicate an ID of the triplet is to be ignored when matching, value -1 is used (e.g.: onid: -1, tsid: -1, sid: 123)
   */
  onid: Scalars['Int']['output'];
  sid: Scalars['Int']['output'];
  tsid: Scalars['Int']['output'];
};

export type Edge = {
  /**
   *  from the Relay spec:
   *  An "Edge Type" must contain a field called node.
   *  This field must return either a Scalar, Enum, Object, Interface, Union, or a Non-Null wrapper around one of those types.
   *  Notably, this field cannot return a list.
   *  this implies that we cannot define "node" in this interface, because it could be a union
   *  node: Node!
   */
  cursor: Scalars['String']['output'];
  /**
   *  A percentage value which approximates the "location" of the edge relative to the total number of items in the connection to which it belongs.
   *  The value can be calculated as follows: index / (total number of items) * 100.
   *  Theoretically there are cases where the total number of items in a connection is not known, in that case this value cannot be calculated and it will be null.
   */
  locationIndicator?: Maybe<Scalars['Int']['output']>;
};

/**
 *  Determines what the client application should do when the requested stream reaches the end of the stream.
 *  The advantage of the server indicating this behaviour, is that the operator can easily change the configuration to make the application behave differently.
 */
export enum EndOfStreamBehaviour {
  /**
   *  When the stream ends, the client should start a new stream for the next event on the channel.
   *  Applicable to CatchupTV and RestartTV.
   */
  NextEvent = 'NEXT_EVENT',
  /**  When the stream ends, the client should pause the stream. */
  Pause = 'PAUSE',
  /**
   *  When the stream ends, the client should resume playback at a 1x rate again.
   *  Probably only be used for TimeshiftTV streams.
   */
  Play = 'PLAY',
  /**  When the stream ends, the client should start LiveTV playback. */
  ToLive = 'TO_LIVE'
}

/**  Defines environment config data for clients like parameters used for automatic DVB channel scanning by STB */
export type Environment = Cacheable & {
  __typename?: 'Environment';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The DVB Scan Parameters used to do automatic DVB channel scanning */
  scanParameters?: Maybe<DvbScanParameters>;
};

/**  suggested expiry: DEFAULT */
export type EpisodeInfo = Cacheable & {
  __typename?: 'EpisodeInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  the episode fields are not always available in the incoming metadata, therefor they're optional
   *  the number of the episode within the season
   *  OR within the total number of episodes, if season is not applicable (eg. "BBC News at Six")
   */
  number?: Maybe<Scalars['Int']['output']>;
  /**  season is not always applicable */
  season?: Maybe<Scalars['Int']['output']>;
  /**  not every episode will have its own title, so this is optional */
  title?: Maybe<Scalars['String']['output']>;
};

/**  suggested expiry: 30 minutes or less */
export type Event = Cacheable & {
  __typename?: 'Event';
  /**
   *  we currently assume that the same background image is used for EPG screens (Vertical TV Guide, Grid, etc.) and Detailed info screens
   *  this way, there's no "flickering" in the UI when going from eg. Grid to Detailed info
   */
  backgroundImage?: Maybe<Image>;
  /**
   *  flag indicating that this EPG event is not available to this device
   *  this information is static in the sense that it can't be influenced by the device or the end user (eg. by changing location or network connection)
   *  it's not part of the metadata, because it may be different for "live" events vs. re-runs
   *  it's also not user or profile specific
   *  a typical use case is the following:
   *  the operator has the rights to broadcast a certain sports channel,
   *  but if there's a Champions League game, the rights are not available for the country the operator is in
   *  @deprecated on 2019-02-08: use the "liveTV" eventEntitlement field instead
   */
  blackout?: Maybe<Scalars['Boolean']['output']>;
  /**
   *  note that this introduces a circular reference
   *  but we need this when retrieving Events via favourites, search, reminders, etc.
   */
  channel?: Maybe<Channel>;
  /**  The content provider of the event */
  contentProvider?: Maybe<ContentProvider>;
  end: Scalars['Date']['output'];
  /**
   *  the entitlements for this EPG event are in a separate object, which could have a different expiry
   *  this object could be null if there was a (temporary) problem fetching the entitlements
   *  in this case, the application will indicate this to the user, while still showing the static EPG metadata (title, start, end, etc.)
   */
  entitlements?: Maybe<EventEntitlements>;
  /**
   *  this additional ID is only required as an "external" ID
   *  for example: when using EIT data, this ID might be used to map this Event to an EIT Event
   */
  eventId?: Maybe<Scalars['ID']['output']>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  we could have declared Metadata as an interface
   *  however, its expiry can be much longer than the expiry of an Event (or a VODAsset)
   *  so it makes sense to retrieve it as a separate object
   */
  metadata: Metadata;
  /**
   *  list of parental guidance identifiers like ["urn:mpeg:Kijkwijzer:Fear", "urn:mpeg:Kijkwijzer:Profanity"]
   *  clients should be forward-compatible and filter out values for which they do not have an icon
   */
  parentalGuidanceIdentifiers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**  this has to be part of the Event, since we need this information without having to download Metadata */
  parentalRating: ParentalRating;
  /**
   *  All profile-specific info is grouped into this separate type.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  personalInfo?: Maybe<PersonalEventInfo>;
  /**  flag indicating if this event is a Pay Per View event. */
  ppv: Scalars['Boolean']['output'];
  /**  related content for this Event, listed as rows on the "Detailed TV Info" screen */
  relatedContent?: Maybe<ContentFolderList>;
  /**  @deprecated on 2017-12-07: use the "thumbnail" field instead */
  smallImage?: Maybe<Image>;
  start: Scalars['Date']['output'];
  startOverPlaybackContinuePosition?: Maybe<Scalars['Int']['output']>;
  /**
   *  The playback positions to use during StartOver continuous play. All positions are relative to the start of the event stream
   *  (i.e. event start - startOverTVBeforeTime).
   *  The startOverPlaybackStartPosition indicates the position to use when starting playback of this event as the first event in a
   *  continuous StartOver playback scenario. The startOverPlaybackStopPosition indicates the position at which playback of this event
   *  should be stopped and playback of the next applicable event should be started in order to perform continuous playback. The
   *  startOverPlaybackContinuePosition indicates the position to use when starting playback of this event after another event was
   *  stopped in the StartOver continuous playback scenario.
   */
  startOverPlaybackStartPosition?: Maybe<Scalars['Int']['output']>;
  startOverPlaybackStopPosition?: Maybe<Scalars['Int']['output']>;
  startOverTVAfterTime?: Maybe<Scalars['Int']['output']>;
  /**
   *  guard times for StartOverTV (i.e. RestartTV and CatchupTV combined), expressed in seconds
   *  these values can be used to correctly visualise progress or the bookmark position
   *  note that these shall be null if StartOverTV is not enabled for the channel where this Event is broadcasted on
   *  this implies that the BO shouldn't do any rights/entitlements checks for this device or household when resolving this request
   */
  startOverTVBeforeTime?: Maybe<Scalars['Int']['output']>;
  /**  contains info about availability of certain items on the stream, indicated on the TV guide by icons, like Dolby Sound, hardOfHearing, ... */
  streamInfo: StreamInfo;
  /**
   *  List of deeplinks to 3rd-party EPG event related assets
   *  Can contain multiple links of different 3rd-party apps, e.g.:
   *  https://www.rtbf.be/auvio/epg?c=2391203274812&w=20-TCFFM481-000-PR-1
   *  The contentProvider field may NOT be used to determine the app, it should be derived by parsing the link itself.
   */
  thirdPartyLinks?: Maybe<StringCatalog>;
  /**
   *  Events always have a landscape thumbnail
   *  with the height parameter, the client indicates the preferred height of the thumbnail image
   */
  thumbnail?: Maybe<Image>;
  /**  multilingual title of the Event, as it will be shown in the UI */
  title: Scalars['String']['output'];
  /**  This can be used to link an event in the back-office to an event in a broadcasted stream */
  transmissionId?: Maybe<Scalars['ID']['output']>;
};


/**  suggested expiry: 30 minutes or less */
export type EventBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 30 minutes or less */
export type EventPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  suggested expiry: 30 minutes or less */
export type EventSmallImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: 30 minutes or less */
export type EventThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  suggested expiry: same as for Event (30 minutes or less)
 *  the expiry doesn't have to be calculated, since the EventCatalog contents will remain the same if the same query is done later on
 */
export type EventCatalog = Cacheable & Catalog & {
  __typename?: 'EventCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Event>>;
};

/**  restriction that prohibits playback on a device that requires a token for per-event concurrency. */
export type EventConcurrencyPlaybackRestriction = PlaybackRestriction & {
  __typename?: 'EventConcurrencyPlaybackRestriction';
  /** # PlaybackRestriction interface */
  message: Scalars['String']['output'];
};

/**
 *  entitlements are set on household (account) level, not on profile level (like PersonalEventInfo)
 *  note that the Event <-> EventEntitlements could be a 1:1 relationship
 *  however, the entitlements may also be set on a channel level in the back-office, in which case the same EventEntitlements apply to all Events on a particular channel
 *  suggested expiry: 30 minutes or less
 */
export type EventEntitlements = Cacheable & {
  __typename?: 'EventEntitlements';
  catchupTV: Scalars['Boolean']['output'];
  /**
   *  defines the CatchupTV availability window for this event
   *  we currently assume that the window starts when the event finishes (= Event.end field)
   *  based on this information, the client can calculate/refresh the CatchupTV icon indication and action, without having to perform a new request to the back-office
   */
  catchupTVAvailableUntil: Scalars['Date']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  if householdConfirmedReplayPermissions is configured for the channel of this event, and it is set to false, none of the replay features will be enabled for the event.
   *  if householdConfirmedReplayPermissions is set to true, the timestamp of when it was set to true (if this was done by the client) will be taken into account: events
   *  that are before this timestamp will have none of the replay features enabled. Events that start after the timestamp will have their features enabled based on the
   *  product/feature entitlement checks.
   *  Replaces the field "blackout" on "event" (note that the value is the inverted).
   *  There does not seem to be a reason to treat live entitlements differently from any other non-live entitlements.
   */
  liveTV: Scalars['Boolean']['output'];
  /**
   *  Client side verified field to determine if an event can be played on the actual device.
   *  This particular playback restriction targets per-event concurrency limitations and is realised through a token distribution.
   *  If the token is set, the device is considered to be granted access. Other devices that do not have the token will stop playback on event boundary.
   *  we currently assume a single token can be distributed, counting for every playout mode.
   */
  needsConcurrencyToken: Scalars['Boolean']['output'];
  /**  Indicates whether planning the recording is allowed. Playback of the recording is indicated by the "allowPlayback" field on the recording. */
  networkRecording: Scalars['Boolean']['output'];
  /**
   *  Defines until which time it is possible to schedule the event as a network recording. For example:
   *  - If a past event has a networkRecordingPlannableUntil in the future, it is still possible to record this event until that date
   *  - If a currently playing event has a networkRecordingPlannableUntil that matches the end date of the event, the event can only be recorded when it hasn't started yet, or when it is playing.
   *  - If a currently playing event has a networkRecordingPlannableUntil that matches the start date of the event, the event can only be scheduled for recording if it hasn't started yet.
   */
  networkRecordingPlannableUntil: Scalars['Date']['output'];
  pauseLiveTV: Scalars['Boolean']['output'];
  /**  Indicates if the subscriber is allowed to play this event through a valid Pay Per View product. */
  ppvTV: Scalars['Boolean']['output'];
  restartTV: Scalars['Boolean']['output'];
};

/**
 *  Defines the event logging option to be enabled.
 *  For a more detailed information, see the Zappware Analytics Platform schema specification:
 *  https://zappware.atlassian.net/wiki/spaces/ZAP40/pages/853049432/ZAP+API+Schema
 */
export enum EventLoggingOption {
  /**  All events are logged (all concepts) */
  All = 'ALL'
}

/**  there's no need to cache this info, since this kind of playback is "on request", contrary to eg. ChannelPlaybackInfo */
export type EventPlaybackInfo = PlaybackInfo & {
  __typename?: 'EventPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  /**  this allows the client to do parental rating checks etc. */
  event: Event;
  heartbeat?: Maybe<Heartbeat>;
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
};

/**
 *  This union contains all item types that can be marked as Favourite. It will be a sub-set of the types in the ContentItem union.
 *  A union is preferred over a "Favouritable" interface, because the "favourited" flag is not defined in the item type itself, but part of the Personal*Info type
 *  The "Matrix of actions" defines which items can be marked as Favourite.
 *  Currently, these are: Events (incl. episodes), Recordings (incl. episodes), On Demand Videos (incl. episodes), On Demand Packages
 *  Note that a union cannot refer to an interface (e.g. Recording), but only to actual types (e.g. NetworkRecording)
 */
export type FavouritableItem = Event | NetworkRecording | VodAsset | VodProduct;

export type FavouritableItemCatalog = Cacheable & Catalog & {
  __typename?: 'FavouritableItemCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<FavouritableItem>>;
};

export enum FavouritableItemKind {
  Event = 'EVENT',
  NetworkRecording = 'NETWORK_RECORDING',
  VodAsset = 'VOD_ASSET',
  VodProduct = 'VOD_PRODUCT'
}

export type FavouriteItemInput = {
  itemId: Scalars['ID']['input'];
  itemKind: FavouritableItemKind;
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type FavouriteItemPayload = {
  __typename?: 'FavouriteItemPayload';
  item: FavouritableItem;
};

/**  Represents the deletion status when deleting a foreign device from its household. */
export enum ForeignDeviceDeletionStatus {
  /**  Indicates that the device is deleted from its household */
  Deleted = 'DELETED',
  /**  Indicates that the operation is not allowed */
  NotAllowed = 'NOT_ALLOWED',
  /**  Indicates that the deletion is in progress */
  Pending = 'PENDING'
}

/**  This payload type encapsulates the ForeignDeviceDeletionStatus enum */
export type ForeignDeviceDeletionStatusPayload = {
  __typename?: 'ForeignDeviceDeletionStatusPayload';
  status: ForeignDeviceDeletionStatus;
};

export type FullPaginationParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/**  Result of the generatePairingCode mutation */
export type GeneratePairingCodePayload = {
  __typename?: 'GeneratePairingCodePayload';
  pairingCode: Scalars['String']['output'];
};

/**  suggested expiry: DEFAULT */
export type Genre = Cacheable & {
  __typename?: 'Genre';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  multilingual title of the Genre, as it will be shown in the UI */
  title: Scalars['String']['output'];
};

/**  suggested expiry: DEFAULT */
export type GenreCatalog = Cacheable & Catalog & {
  __typename?: 'GenreCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Genre>>;
};

export type GetDiagnosticsUploadDetailsPayload = {
  __typename?: 'GetDiagnosticsUploadDetailsPayload';
  url: Scalars['String']['output'];
};

export type GraphQlHeartbeat = {
  __typename?: 'GraphQLHeartbeat';
  /**  the interval in seconds */
  interval: Scalars['Int']['output'];
};

/**  Expected behaviour when a group is selected in the UI. */
export enum GroupInfoSelectBehaviour {
  /**  The last item of the group should be selected. */
  SelectEnd = 'SELECT_END',
  /**
   *  The item that is closest to "now" should be selected.
   *  The client will have to detect which item in the group is nearest to the current time.
   *  If the backend were to pass a cursor to identify this item, this cursor would become outdated as time passes,
   *  while the client might remain in the same screen with 'outdated' information.
   */
  SelectNow = 'SELECT_NOW',
  /**  The first item of the group should be selected. */
  SelectStart = 'SELECT_START'
}

/**
 *  The grouping info for a list of items, e.g. in a series to group items by season.
 *  The meaning of a group depends on the context in which it is used. In a series
 *  object, this can for example be grouping by season, or grouping by time (when
 *  the same series is displayed in the search context).
 */
export type GroupingInfo = Cacheable & {
  __typename?: 'GroupingInfo';
  /**  Cursor for the last episode of the group. */
  endCursor: Scalars['String']['output'];
  /**  Number of episodes in this group (necessary for correctly rendering the progress bar when jumping between groups, or for requesting the items in the group if the 'nearest to now' item is to be selected). */
  episodeCount: Scalars['Int']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  Expected behaviour when a group is selected in the UI. */
  selectBehaviour: GroupInfoSelectBehaviour;
  /**  Cursor for the first episode of the group. */
  startCursor: Scalars['String']['output'];
  /**  Multilingual title of the group (e.g. season: "Season 1", "Season 2", "2015", "2016", ... or time: "Past", "Today", "Future", ...). */
  title: Scalars['String']['output'];
};

/**
 *  let's define specific heartbeat types for specific integrations
 *  this allows us to differentiate easily
 *  HttpHeartbeat will only be used for the SDS Evo integration
 *  SDS Cloud heartbeats will be done using a GraphQL mutation
 */
export type Heartbeat = GraphQlHeartbeat | HttpHeartbeat;

/**  suggested expiry: DEFAULT */
export type Household = Cacheable & {
  __typename?: 'Household';
  agreedToTermsAndConditions: Scalars['Boolean']['output'];
  /**
   *  Indicates if it is allowed to move the operator-defined channel lists in this
   *  household using the moveChannelList mutation. If the moving of operator
   *  channel lists is not allowed, operator-defined channel lists will always be
   *  the first channel lists, and it is not allowed to move a user-defined
   *  channel list in front of an operator defined channel list.
   */
  canMoveOperatorChannelLists: Scalars['Boolean']['output'];
  /**
   *  The language that will be used for sorting channels when sorting according to the "default sort order" (e.g. in the full channel list). This will be a ISO639-2T code. Can be null if no language has been set.
   *  A change of this value can have an impact on the defaultNumber of Channels.
   */
  channelSortLanguage?: Maybe<Scalars['String']['output']>;
  /**
   *  a Household *can* belong to a Community
   *  this link can be created by the CRM (eg. when registering the device)
   *  or it could be set by the end user, via the ChangeHouseholdCommunity mutation
   */
  community?: Maybe<Community>;
  /**
   *  this catalog contains all the devices in this Household
   *  it can be used for device management, media casting, etc.
   */
  devices: DeviceCatalog;
  /**
   *  the spec defines the following behaviour for the "displayNonAdultContent" flag:
   *  If the last watched channel features adult (18+) content, by default the 1st channel of the last active channel list (of the logged in profile) is tuned to instead.
   *  If this channel also features adult content, the 1st channel of the All channels channel list is tuned to.
   *  This behaviour avoids uncomfortable situations when starting the application or logging in to a certain profile.
   *  This applies to both Forced-and Voluntary log in.
   *  This behaviour (regarding adult content) can be deactivated in Settings (Profiles section: "Only display non-adult content when logging in").
   *  When deactivated, the last tuned channel of the last active channel list of the logged profile, is always displayed, regardless of its type of content.
   */
  displayNonAdultContent: Scalars['Boolean']['output'];
  expiry: Scalars['Date']['output'];
  /**  The external id of this household */
  externalId?: Maybe<Scalars['String']['output']>;
  /**
   *  Indicates if this household has a registered controller device.
   *  Since controller devices are not included in the list of household devices, clients should use this property and not the devices property to determine if there is
   *  a controller device present in the household.
   */
  hasControllerDevice: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  masterPincode: Scalars['String']['output'];
  /**
   *  The maximum number of channels that are allowed to have their replay permissions enabled in this household
   *  (see setHouseholdConfirmedReplayPermissions mutation and householdConfirmedReplayPermissions field on ChannelEntitlement).
   *  This is an optional field: if it is null, there is no limit on the number of channels.
   */
  maxNumberOfConfirmedReplayChannels?: Maybe<Scalars['Int']['output']>;
  /**
   *  allows the client app to display a message like "You've reached the maximum of 3 registered devices"
   *  it reflects the maximum number of devices that will register themselves (therefore "unmanaged")
   *  there's currently no use case that requires us to retrieve the max number of "managed" devices
   *  it should be possible to retrieve this info from a device that is not registered yet (i.e. using a device-less session token)
   *  a value of -1 must be used to depict "unlimited"
   */
  maxNumberOfUnmanagedDevices: Scalars['Int']['output'];
  onboardingInfo: HouseholdOnboardingInfo;
  /**
   *  If set to true, the user will receive personalised/targeted advertisement replacements.
   *  If set to false, the user will receive generic/not-targeted advertisement replacements.
   *  Can be null if unset/not yet set.
   */
  personalAdvertisingOptIn?: Maybe<Scalars['Boolean']['output']>;
  /**
   *  Indicates if preview mode is allowed for devices of this household.
   *  Used by devices to know if the previewModeEnabled setting must be shown or not.
   */
  previewModeAllowed: Scalars['Boolean']['output'];
  /**
   *  there should always be at least 1 profile
   *  note that the ! only means that the array can't be null
   *  it doesn't mean it can't be empty, unfortunately
   */
  profiles: ProfileCatalog;
  /**  @deprecated on 2020-07-24: The viewing behaviour tracking should be stored on each profile instead of on the household (see ProfilePreferences) */
  trackViewingBehaviour: Scalars['Boolean']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type HouseholdConfirmedReplayPermission = {
  /**  The channel on which to apply the permission */
  channelId: Scalars['ID']['input'];
  /**
   *  If set to true, NPVR, CatchupTV, PauseLiveTV, ... features will be enabled on this channel.
   *  If set to false, these features will be disabled
   */
  enabled: Scalars['Boolean']['input'];
};

/**  suggested expiry: DEFAULT */
export type HouseholdOnboardingInfo = Cacheable & {
  __typename?: 'HouseholdOnboardingInfo';
  /**
   *  The date on which the user has completed the community selection/confirmation onboarding screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeHouseholdOnboardingInfo" mutation.
   */
  communityStepCompleted?: Maybe<Scalars['Date']['output']>;
  expiry: Scalars['Date']['output'];
  /**
   * # Cacheable interface:
   *  The id should not change over time in order for caching to work correctly (the mutation returns the HouseholdOnboardingInfo as payload and not the Household).
   */
  id: Scalars['ID']['output'];
  /**
   *  The date on which the user has completed the master pincode onboarding screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeHouseholdOnboardingInfo" mutation.
   */
  masterPincodeStepCompleted?: Maybe<Scalars['Date']['output']>;
  /**
   *  The date on which the user has completed the privacy agree/disagree onboarding screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeHouseholdOnboardingInfo" mutation.
   *  @deprecated on 2020-07-24: The privacy step must be completed for each profile instead of for the household (see ProfileOnboardingInfo).
   */
  privacyStepCompleted?: Maybe<Scalars['Date']['output']>;
  /**
   *  The date on which the user has completed the profiles screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeHouseholdOnboardingInfo" mutation.
   */
  profilesStepCompleted?: Maybe<Scalars['Date']['output']>;
  /**
   *  The date on which the user has completed the channel management (replay) screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeHouseholdOnboardingInfo" mutation.
   *  This state may only be used to know whether the channel management (replay) step was shown at least once before. It may NOT be used to determine if the step must be shown!
   *  The client application needs to know whether this channel management step was already done at least once because the UI is a bit different based on that fact:
   *  - the first time, no indication (red dot) should be added to channels for which householdConfirmedReplayPermissions hasn't been set yet, because all of them or not set.
   *  - the next time, when new replay enabled channels become available, they should have the indication.
   */
  replayStepCompleted?: Maybe<Scalars['Date']['output']>;
};

export type HttpHeartbeat = {
  __typename?: 'HttpHeartbeat';
  /**
   *  when sending a heartbeat to the back-office, it will probably check the HTTP headers for authentication info (eg. SDS Evo does this)
   *  this boolean indicates that the client should include those headers when doing the heartbeat request
   */
  includeAuthHeaders: Scalars['Boolean']['output'];
  /**  the interval in seconds */
  interval: Scalars['Int']['output'];
  /**
   *  the URL to be used for the heart-beat request
   *  this URL has to include the parameters that should be used, such as device ID, session ID, etc.
   *  it's done this way for maximum flexibility, i.e. if the back-office changes something about the heartbeat operation (eg. additional parameter),
   *  it only affects this URL and no client upgrade is required
   */
  url: Scalars['String']['output'];
};

export enum HybridChannelDisplayBehaviour {
  /**  always show the channel */
  Always = 'ALWAYS',
  /**  only show the channel when found in DVB scan */
  OnlyIfOnDvb = 'ONLY_IF_ON_DVB'
}

export enum HybridChannelPlaybackBehaviour {
  /**  force DVB playback, do not use the GraphQL playbackUrl */
  ForceDvb = 'FORCE_DVB',
  /**  force the GraphQL playbackUrl, do not use DVB playback */
  ForceGraphqlPlaybackUrl = 'FORCE_GRAPHQL_PLAYBACK_URL',
  /**  first try DVB playback, otherwise fallback to the GraphQL playbackUrl */
  PreferDvb = 'PREFER_DVB'
}

/**
 *  suggested expiry: DEFAULT
 *  Image is a separate type, to allow a longer expiry time
 *  also, we may want to add fields to this type in the future
 *  note: the CDN has to indicate how long the actual image file should be cached by the client, using HTTP headers
 */
export type Image = Cacheable & {
  __typename?: 'Image';
  expiry: Scalars['Date']['output'];
  height: Scalars['Int']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /**  these fields allow the client apps to do the layout of items (eg. in a "content row"), before the image files are downloaded */
  width: Scalars['Int']['output'];
};

export enum ImageFlavour {
  /**  For dark backgrounds (used only for full-colour design) */
  Inverted = 'INVERTED',
  /**  For light backgrounds (used for both full-colour design or monochrome design) */
  Normal = 'NORMAL'
}

/**  This type represents an encapsulated Image object where the width and height params can be passed */
export type ImageItem = Cacheable & {
  __typename?: 'ImageItem';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
};


/**  This type represents an encapsulated Image object where the width and height params can be passed */
export type ImageItemImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/**  restriction that prohibits playback on a jailbroken/rooted device */
export type JailbrokenPlaybackRestriction = PlaybackRestriction & {
  __typename?: 'JailbrokenPlaybackRestriction';
  /** # PlaybackRestriction interface */
  message: Scalars['String']['output'];
};

export type KeepSessionAlivePayload = {
  __typename?: 'KeepSessionAlivePayload';
  /**
   *  The number of seconds that the session will remain alive after the keepSessionAlive mutation was executed. If no sessionTimeout is
   *  returned, the client doesn't have to any additional keepSessionAlive calls in the future to stop the session from expiring (i.e.
   *  sessions don't time out on this server)
   *  The clients do not need to subtract any extra margin since this is already done on the server (if needed)
   */
  sessionTimeout?: Maybe<Scalars['Int']['output']>;
};

/**
 *  suggested expiry: DEFAULT
 *  it's Cacheable, so that the client can resolve the query locally in the following (common) use case:
 *  1. user enters "ben" -> "searchKeywords(ben)" query on server
 *  2. user adds a character, eg. "benx" -> "searchKeywords(benx)" query on server
 *  3. user deletes the last character 'x' -> "searchKeywords(ben)" is resolved in client cache
 *  it's a Catalog (not a Connection) because the number of keywords is limited to a certain (configurable) maximum:
 *  "The number of tabs in the cast-row is limited to 15 (configurable by the operator)."
 *  this implies that this maximum number must be configured in the back-office/middleware
 */
export type KeywordCatalog = Cacheable & Catalog & {
  __typename?: 'KeywordCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Scalars['String']['output']>>;
};

/**  suggested expiry: DEFAULT */
export type Language = Cacheable & {
  __typename?: 'Language';
  /**
   *  this is the 3-letter ISO639-2T language code
   *  see https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes
   */
  code: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /**
   * # Cacheable interface:
   *  the id could be a guid or it could refer to an actual language code, locale, etc.
   *  this should be opaque to the client but may be used by the middleware/back-office to identify this language object
   */
  id: Scalars['ID']['output'];
  /**
   *  this is the "human readable" title of the language (English, Deutsch, Nederlands, etc.)
   *  note that this title may be dependent on the currently active language!
   */
  title: Scalars['String']['output'];
};

/**  suggested expiry: DEFAULT */
export type LanguageCatalog = Cacheable & Catalog & {
  __typename?: 'LanguageCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Language>>;
};

export type ListedRecording = NetworkRecording;

/**  Messaging */
export type Message = Cacheable & {
  __typename?: 'Message';
  /**  Optional actions to go to other screens */
  actions?: Maybe<ActionCatalog>;
  /**  Optional attachments of the message */
  attachments?: Maybe<MessageAttachmentCatalog>;
  /**  Optional background image for the Message. */
  backgroundImage?: Maybe<Image>;
  /**  NOTIFICATION, INBOX or PROMOTION. Determines whether or not the message should be shown as notification or as a promotion. */
  displayKind: MessageDisplayKind;
  expiry: Scalars['Date']['output'];
  /**  Free-to-choose icon to be shown to the side of the notification. */
  icon?: Maybe<Image>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  Multilingual label of the message. Ex: promotion, sale, A1 Finance Department, A1 Xplore TV, ... . */
  label: Scalars['String']['output'];
  /**
   *  The optional uri for the link, a Nexx4 deeplink url or a plain webbrowser url.
   *  @deprecated on 2019-11-26: use the 'actions' field instead
   */
  link?: Maybe<Scalars['String']['output']>;
  /**  Multilingual body of the message. */
  message: Scalars['String']['output'];
  /**  Can be set if displayKind == NOTIFICATION. Contains additional display parameters. */
  notificationInfo?: Maybe<MessageNotificationDisplayInfo>;
  /**  Can be set if displayKind == PROMOTION. Contains additional display parameters. */
  promotionInfo?: Maybe<MessagePromotionDisplayInfo>;
  /**  Current message lifecycle. */
  status: MessageStatus;
  /**  The date at which the message was last modified. */
  timestamp: Scalars['Date']['output'];
  /**  Multilingual title of the message. */
  title: Scalars['String']['output'];
  /**  The date from which the message is valid (and should be shown). */
  validFrom: Scalars['Date']['output'];
  /**  The date after which the message is invalid (and will be deleted/shouldn't be shown anymore). */
  validUntil: Scalars['Date']['output'];
};


/**  Messaging */
export type MessageBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  Messaging */
export type MessageIconArgs = {
  flavour?: InputMaybe<ImageFlavour>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/**  Message attachments can be either Images or VODAssets */
export type MessageAttachment = ImageItem | VodAsset;

/**  Models the list of attachments of the message */
export type MessageAttachmentCatalog = Cacheable & Catalog & {
  __typename?: 'MessageAttachmentCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<MessageAttachment>>;
};

/**
 *  This determines on the client whether or not to show it only in the inbox, as a notification (and in the inbox),
 *  or only as a promotion (and not in the inbox).
 */
export enum MessageDisplayKind {
  Inbox = 'INBOX',
  Notification = 'NOTIFICATION',
  Promotion = 'PROMOTION'
}

/**
 *  The MessageFilterParams allow the client to request subsets of messages for specific use cases in different parts of the application.
 *  For example:
 *  1) all messages of displayKind NOTIFICATION that have validity ACTIVE and SCHEDULED for scheduling the ui notification pop-up
 *  2) all messages of displayKind NOTIFICATION and INBOX that have validity ACTIVE (validFrom <= now) for displaying them in the inbox
 */
export type MessageFilterParams = {
  displayKind?: InputMaybe<Array<InputMaybe<MessageDisplayKind>>>;
  status?: InputMaybe<Array<InputMaybe<MessageStatus>>>;
  validity?: InputMaybe<Array<InputMaybe<MessageValidity>>>;
};

/**  Models additional display parameters when the MessageDisplayKind is NOTIFICATION. */
export type MessageNotificationDisplayInfo = Cacheable & {
  __typename?: 'MessageNotificationDisplayInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  How long (in seconds) the delay is between subsequent re-appearances of the notification (if repeatTimes != null) */
  repeatDelay?: Maybe<Scalars['Int']['output']>;
  /**  How many times the notification reappears after being closed (manually or after time-out), in seconds. */
  repeatTimes?: Maybe<Scalars['Int']['output']>;
  /**
   *  Screens in the application on which the Notification is allowed to appear.
   *  Messaging MVP2: This will for now only contain one value (if any)
   */
  screens?: Maybe<Array<Maybe<MessageNotificationDisplayInfoScreens>>>;
  /**  The timeout (in seconds) after which the notification is automatically hidden. */
  timeout?: Maybe<Scalars['Int']['output']>;
};

/**  Defines the possible screens that a Message notification should appear on */
export enum MessageNotificationDisplayInfoScreens {
  All = 'ALL',
  AllExceptPlayer = 'ALL_EXCEPT_PLAYER',
  Home = 'HOME',
  MyLibrary = 'MY_LIBRARY',
  OnDemand = 'ON_DEMAND',
  Player = 'PLAYER'
}

/**  Models additional display parameters when the MessageDisplayKind is PROMOTION. */
export type MessagePromotionDisplayInfo = Cacheable & {
  __typename?: 'MessagePromotionDisplayInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  How long (in seconds) the delay is between subsequent re-appearances of the promotion (if repeatTimes != null) */
  repeatDelay?: Maybe<Scalars['Int']['output']>;
  /**  How many times the promotion reappears after being closed (manually or after time-out), in seconds. */
  repeatTimes?: Maybe<Scalars['Int']['output']>;
  /**  Screens in the application on which the promotion is allowed to appear. */
  screens?: Maybe<Array<Maybe<MessagePromotionDisplayInfoScreens>>>;
  /**  The timeout (in seconds) after which the promotion is automatically hidden. */
  timeout?: Maybe<Scalars['Int']['output']>;
  /**  The list of UI events that can trigger the display of this PROMOTION. */
  triggers: Array<Maybe<MessagePromotionDisplayInfoTriggers>>;
};

/**  Defines the possible screens that a Message promotion should appear on */
export enum MessagePromotionDisplayInfoScreens {
  All = 'ALL',
  AllExceptPlayer = 'ALL_EXCEPT_PLAYER',
  Home = 'HOME',
  MyLibrary = 'MY_LIBRARY',
  OnDemand = 'ON_DEMAND',
  Player = 'PLAYER'
}

/**  When to display the Promotion Message: at startup / when coming out of standby */
export enum MessagePromotionDisplayInfoTriggers {
  StartUp = 'START_UP',
  WakeUp = 'WAKE_UP'
}

/**  Defines the status of the message. */
export enum MessageStatus {
  New = 'NEW',
  Read = 'READ'
}

/**
 *  Defines the validity state of the message.
 *  SCHEDULED means validFrom > now
 *  ACTIVE means validFrom <= now
 */
export enum MessageValidity {
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED'
}

/**
 *  Allows us to return a collection of messages.
 *  NOTE: The expiry of the message should be shorter than the polling interval (for caching reasons). This way
 *  the client always has the correct version of the data.
 */
export type MessagesConnection = Cacheable & Connection & {
  __typename?: 'MessagesConnection';
  edges: Array<Maybe<MessagesEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
  /**  The number of unread messages available in the backend */
  totalUnreadCount: Scalars['Int']['output'];
};

export type MessagesEdge = Cacheable & Edge & {
  __typename?: 'MessagesEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: Message;
};

/**
 *  suggested expiry: DEFAULT
 *  if we make this a separate type (instead of an interface), it can be cached separately (longer expiry)
 *  as a future improvement, this data could be served on a CDN
 *  in that case, the JSON contents should follow the exact same structure as defined here
 */
export type Metadata = Cacheable & {
  __typename?: 'Metadata';
  /**  actors and directors names should be returned as "firstName lastName", e.g. "Brad Pitt" */
  actors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**  Format: ISO639-2T language code, e.g. "eng", "nld" */
  audioLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *  preferably in ISO 3166-1 alpha-2 (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2), although this may depend on the incoming metadata
   *  it's probably not necessary for the middleware to convert the incoming metadata to this ISO standard
   */
  country?: Maybe<Scalars['String']['output']>;
  directors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  episodeInfo?: Maybe<EpisodeInfo>;
  expiry: Scalars['Date']['output'];
  /**  no maxLength required here */
  fullDescription?: Maybe<Scalars['String']['output']>;
  /**  genre is not mandatory */
  genre?: Maybe<Genre>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  if not applicable, this should be null */
  originalTitle?: Maybe<Scalars['String']['output']>;
  /**  this program or movie may be rated from multiple sources */
  ratings?: Maybe<Array<Maybe<Rating>>>;
  /**  an Event or VODAsset does not necessarily belong to a Series, therefore: optional fields */
  seriesInfo?: Maybe<SeriesInfo>;
  /**
   *  the amount of characters could depend on the area where this is shown in the UI
   *  note that this is an optional parameter
   */
  shortDescription?: Maybe<Scalars['String']['output']>;
  subtitleLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title: Scalars['String']['output'];
  /**  year of production as an absolute value, eg. 2017 */
  year?: Maybe<Scalars['Int']['output']>;
};


/**
 *  suggested expiry: DEFAULT
 *  if we make this a separate type (instead of an interface), it can be cached separately (longer expiry)
 *  as a future improvement, this data could be served on a CDN
 *  in that case, the JSON contents should follow the exact same structure as defined here
 */
export type MetadataShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};

export type NetworkRecording = Cacheable & Recording & {
  __typename?: 'NetworkRecording';
  /**
   *  Events in the after time of the recording. Can be multiple.
   *  The client checks this and requires pincode entry if necessary, based on the age rating of these events.
   *  If these lists are not available or empty, the Event.parentalRating values of the recorded event should be used to determine if pincode entry is required.
   */
  afterTimeEvents?: Maybe<Array<Maybe<Event>>>;
  /**  Allows or disallows archiving of the recording. */
  allowArchiving: Scalars['Boolean']['output'];
  /**
   *  field to allow/disallow playback of the recording itself.
   *  in a 360 context, each device should have the option to present all recordings within a household, but not necessarily be able to play each of them.
   */
  allowPlayback: Scalars['Boolean']['output'];
  /**
   *  The date & time until when this network recording will remain available.
   *  If null, there is no expiry on the recording availability (will not expire)
   */
  availableUntil?: Maybe<Scalars['Date']['output']>;
  /**
   *  Events in the before time of the recording. Can be multiple.
   *  The client checks this and requires pincode entry if necessary, based on the age rating of these events.
   *  If these lists are not available or empty, the Event.parentalRating values of the recorded event should be used to determine if pincode entry is required.
   */
  beforeTimeEvents?: Maybe<Array<Maybe<Event>>>;
  channel?: Maybe<Channel>;
  channelName?: Maybe<Scalars['String']['output']>;
  conflictAutoResolution?: Maybe<ConflictAutoResolution>;
  conflictedRecordings?: Maybe<Array<Maybe<Recording>>>;
  deleteProtected: Scalars['Boolean']['output'];
  end: Scalars['Date']['output'];
  /**  network recordings are always event-based, so this is mandatory */
  event: Event;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  personalInfo: PersonalRecordingInfo;
  /**  related content for this NetworkRecording, listed as rows on the "Detailed Recording Info" screen */
  relatedContent?: Maybe<ContentFolderList>;
  size: Scalars['Int']['output'];
  /** # Recording interface: */
  start: Scalars['Date']['output'];
  status: RecordingStatus;
};


export type NetworkRecordingPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4Mutations = {
  __typename?: 'Nexx4Mutations';
  /**
   *  when calling addChannel for a Channel that's already in the ChannelList, it will be moved to the new position
   *  if the position parameter is not included, the channel should be added at the end of the list, or moved to the end if already present
   *  @deprecated on 2018-05-26: use the "setChannelListChannels" mutation instead
   */
  addChannel: ChannelList;
  /**  Archive a recorded event or episode (completed or partial recording). */
  archiveNetworkRecording: ArchiveNetworkRecordingPayload;
  /**
   *  these operations have an impact on the PersonalChannelInfo object that's associated to the Channel
   *  if profileId is not passed, the id of the active profile will be used instead.
   */
  blockChannel: Channel;
  /**  Cancels all planned recordings of episodes of the entire series. All future episodes of the series that was cancelled won't be recorded anymore. Existing recordings won't be deleted. */
  cancelNetworkSeriesRecording: CancelNetworkSeriesRecordingPayload;
  cancelReminder: CancelReminderPayload;
  /**  Cancels all planned recordings of episodes of the season to which the event belongs to. The cancelled season won't be planned in the future anymore. Future episodes of other seasons in the series will still be recorded. Existing recordings won't be deleted. */
  cancelSeasonOfNetworkSeriesRecording: CancelSeasonOfNetworkSeriesRecordingPayload;
  /**
   *  only if playback fails after doing a startVODTransaction, the client must call cancelVODTransaction to indicate that the purchase cannot be completed
   *  this mutation can not be used to "remove" a completed rental/purchase (if you would ever even want to do that)
   */
  cancelVODTransaction: CancelVodTransactionPayload;
  catchupEvent: CatchupEventPayload;
  /**
   *  Sets the currently active profile.
   *  The active profile will be stored on the device level. Changing the active profile will influence any data
   *  that is profile-specific (e.g. the personalInfo properties of Events, Recordings, etc). When changing the active
   *  profile, the client must make sure that any profile-specific data is removed from the local cache.
   */
  changeActiveProfile: ChangeActiveProfilePayload;
  /**  Update the name of a personal channel list. The names of operator channel lists can not be changed. Only personal channel lists can be renamed. */
  changeChannelListName: ChangeChannelListNamePayload;
  /**  change personal Channel preferences, stored on Profile level */
  changeChannelPreferences: ChangeChannelPreferencesPayload;
  /**  Allows the device to update its App Tracking consent. */
  changeDeviceAppTrackingConsent: ChangeDeviceAppTrackingConsentPayload;
  /**  Allows the device to update the DRM network device id. */
  changeDeviceDRMNetworkDeviceId: ChangeDeviceDrmNetworkDeviceIdPayload;
  /**  end-user changes the device enablement policy */
  changeDeviceEnablementPolicy: ChangeDeviceEnablementPolicyPayload;
  /**  end-user changes the name of their device */
  changeDeviceName: ChangeDeviceNamePayload;
  /**  marketing user (de)activates the device preview mode */
  changeDevicePreviewMode: ChangeDevicePreviewModePayload;
  /**
   *  Allows the device to update its state in the backend (e.g. for state reporting towards Alexa).
   *  For states that may change multiple times in a short period (e.g. volume increases), clients should build in a short delay in the state change
   *  reporting, and only report the resulting end state to avoid bursts of state changes for the same property.
   */
  changeDeviceState: ChangeDeviceStatePayload;
  /**  Allows the device to update its Video Quality Limit. */
  changeDeviceVideoQualityLimit: ChangeDeviceVideoQualityLimitPayload;
  /**  Change the channelSortLanguage that has to be used for the Houshold. A change of the channel sort language can have an impact on the defaultNumber of Channels. */
  changeHouseholdChannelSortLanguage: ChangeHouseholdChannelSortLanguagePayload;
  changeHouseholdCommunity: ChangeHouseholdCommunityPayload;
  /**  *** Onboarding *** */
  changeHouseholdOnboardingInfo: ChangeHouseholdOnboardingInfoPayload;
  changeHouseholdPreferences: ChangeHouseholdPreferencesPayload;
  changeInitialChannelList: ChangeInitialChannelListPayload;
  /**  end-user changes the UI language */
  changeLanguage: ChangeLanguagePayload;
  /**  *** Household/Profiles/Users *** */
  changeMasterPincode: ChangeMasterPincodePayload;
  changeNetworkRecording: ChangeNetworkRecordingPayload;
  changeProfileLogoutPincode: ChangeProfilePincodePayload;
  changeProfileOnboardingInfo: ChangeProfileOnboardingInfoPayload;
  changeProfilePermissions: ChangeProfilePermissionsPayload;
  changeProfilePincode: ChangeProfilePincodePayload;
  changeProfilePreferences: ChangeProfilePreferencesPayload;
  /**  Remove the historical data used to generate recommendations */
  clearRecommendationHistory: ClearRecommendationHistoryPayload;
  /**  Remove the stored bookmarks for the current profile */
  clearWatchHistory: ClearWatchHistoryPayload;
  completeVODTransaction: CompleteVodTransactionPayload;
  /**  @deprecated on 2018-05-27: use the "createPersonalChannelList" mutation instead */
  createChannelList: ChannelList;
  /**  *** Device registration and management *** */
  createDevice: CreateDevicePayload;
  /**  *** Recordings *** */
  createNetworkRecording: CreateNetworkRecordingPayload;
  /**  Creating a series recording will plan all future episodes of the entire series that the event belongs to, regardless of the order of the seasons. */
  createNetworkSeriesRecording: CreateNetworkSeriesRecordingPayload;
  /**
   *  *** all of the following mutations must be updated to the new structure! ***
   *  *** Channels ***
   *  Create a new personal channel list
   */
  createPersonalChannelList: CreatePersonalChannelListPayload;
  createProfile: CreateProfilePayload;
  createProfileLogoutPincode: CreateProfilePincodePayload;
  createProfilePincode: CreateProfilePincodePayload;
  /**
   *  Used by clients to remove all existing download instances for the current device.
   *  Clients should only use this method in the scenario where the app has been newly installed, and it detects that the "EST/DTO" DeviceEnablementPolicy
   *  is already enabled. This indicates that the app was previously uninstalled while the device was enabled for "EST/DTO", so any download instances that
   *  still exist for this device should be removed from the back-end since they will also have been removed from the client device.
   */
  deleteAllDownloads: DeleteAllDownloadsPayload;
  deleteChannelList: ChannelListCatalog;
  deleteDevice: DeleteDevicePayload;
  /**  Deletes all completely recorded episodes of the entire series, but won't cancel any on-going or planned recordings. */
  deleteEpisodesOfNetworkSeriesRecording: DeleteEpisodesOfNetworkSeriesRecordingPayload;
  deleteEventBookmark: DeleteEventBookmarkPayload;
  /**  deletes a foreign device from its household. */
  deleteForeignDevice: DeleteForeignDevicePayload;
  /**  Deletes a message by its Id. */
  deleteMessage: DeleteMessagePayload;
  deleteProfile: DeleteProfilePayload;
  deleteProfileLogoutPincode: DeleteProfilePincodePayload;
  deleteProfilePincode: DeleteProfilePincodePayload;
  /**  the server should throw an error if deleting the recording fails (for whatever reason) */
  deleteRecording: DeleteRecordingPayload;
  deleteRecordingBookmark: DeleteRecordingBookmarkPayload;
  deleteVODBookmark: DeleteVodBookmarkPayload;
  /**  Clients pass info (e.g. channel scan results) from which the community can be derived. */
  determineCommunity: DetermineCommunityPayload;
  downloadVODAsset: DownloadVodAssetPayload;
  /**
   *  An error can be returned if the maximum number (back-office configurable, e.g. 50) of favourites is reached.
   *  In that case, the viewer needs to unfavourite items from the favourite list first.
   */
  favouriteItem: FavouriteItemPayload;
  /**
   *  Generates and returns a pairing code that can be used to register a new device in the household of the
   *  current device using the pairing code authentication feature.
   */
  generatePairingCode: GeneratePairingCodePayload;
  /**  SDSCloud: will return an url that is valid for a short time that can be used to upload diagnostics data to S3 */
  getDiagnosticsUploadDetails: GetDiagnosticsUploadDetailsPayload;
  /**
   *  *** Session management ***
   *  Used by clients to make sure that the current session doesn't expire.
   *  The GraphQL server may depend on other thirdparty servers with their own connections and sessions. Since not all queries
   *  and mutations will cause these other sessions to be used (and be kept alive), the GraphQL server implementation can make
   *  sure that all other connections are also kept active when this keep-alive mutation is called.
   */
  keepSessionAlive: KeepSessionAlivePayload;
  /**  Allows resources that are allocated for the current client-server session to be released */
  logout: Scalars['Boolean']['output'];
  /**  the following 2 operations return the complete set of available channel lists */
  moveChannelList: ChannelListCatalog;
  /**
   *  *** General ***
   *  First time activation message when inital setup is done on client
   */
  notifyDeviceActivation: NotifyDeviceActivationPayload;
  pauseLiveChannel: PauseLiveChannelPayload;
  /**  *** Playback *** */
  playChannel: PlayChannelPayload;
  playPPVEvent: PlayPpvEventPayload;
  playRecording: PlayRecordingPayload;
  playTrailer: PlayTrailerPayload;
  playVODAsset: PlayVodAssetPayload;
  /**
   *  the back-office should return an error if the client tries to purchase a ChannelProduct that is not upsellable
   *  this means that this mutation will only work if the ProductKind is SUBSCRIPTION_UPSELLABLE, assuming that TRANSACTION ChannelProducts are not
   *  @deprecated on 2018-05-04: use the "purchaseUpsellProduct" mutation instead
   */
  purchaseChannelProduct: PurchaseChannelProductPayload;
  /**
   *  Purchase an upsell product.
   *  If the product does not exist, or the price is expired, this method will return an error.
   */
  purchaseUpsellProduct: PurchaseUpsellProductPayload;
  /**
   *  Purchase a VOD product with ProductKind RENTAL or PURCHASE. This method should be used when the VODProduct is associated with a series or boxset.
   *  If the VODProduct is associated with a single asset, use the purchase flow below (startVODTransaction, ...).
   */
  purchaseVODProduct: PurchaseVodProductPayload;
  /**
   *  *** Messaging ***
   *  Marks a message as read by its Id.
   */
  readMessage: ReadMessagePayload;
  /**  @deprecated on 2018-05-26: use the "setChannelListChannels" mutation instead */
  removeChannel: ChannelList;
  resetProfilePreferences: ResetProfilePreferencesPayload;
  restartEvent: RestartEventPayload;
  /**
   *  *** MQTT ***
   *  Sends an MQTT message to a specific device
   */
  sendMQTTMessageToDevice: SendMqttMessageToDevicePayload;
  sendPlaybackHeartbeat: SendPlaybackHeartbeatPayload;
  /**  Update the channels of a personal channel list. */
  setChannelListChannels: SetChannelListChannelsPayload;
  setEventBookmark: SetEventBookmarkPayload;
  /**  update the confirmed permissions of the current household */
  setHouseholdConfirmedReplayPermissions: SetHouseholdConfirmedReplayPermissionsPayload;
  setRecordingBookmark: SetRecordingBookmarkPayload;
  /**  *** Reminders *** */
  setReminder: SetReminderPayload;
  setVODBookmark: SetVodBookmarkPayload;
  /**  Use the start & complete PPV purchase mutation to get playback rights for a PPV event. */
  startAndCompletePPVTransaction: StartAndCompletePpvTransactionPayload;
  /**
   *  use the start & complete VOD purchase mutations to rent (aka TVOD) or purchase (aka EST) a VOD product associated with a single asset
   *  these will result in an error if the VODProduct kind is not RENTAL or PURCHASE
   *  summary of the call flow:
   *  1. startVODTransaction
   *  2. playVODAsset - using the (temporary) entitlement from StartVODTransactionPayload
   *  3. completeVODTransaction
   */
  startVODTransaction: StartVodTransactionPayload;
  /**
   *  this method can be used by the client when a playback session is stopped, but is not replaced by another session
   *  for example: closing a picture-in-picture window
   *  passing an empty string as sessionId in the StopPlaybackInput will stop all the playback sessions that belong to the device
   */
  stopPlayback: StopPlaybackPayload;
  /**  Unarchive an archived recording of an event or episode (completed or partial recording). */
  unarchiveNetworkRecording: UnarchiveNetworkRecordingPayload;
  /**  if profileId is not passed, the id of the active profile will be used instead. */
  unblockChannel: Channel;
  unfavouriteItem: UnfavouriteItemPayload;
  updateDownload: UpdateDownloadPayload;
  /**  Used by clients to upload their "internal" device settings (i.e. all relevant settings not otherwise stored in the backend), for diagnostics purposes */
  uploadInternalDeviceSettings: UploadInternalDeviceSettingsPayload;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsAddChannelArgs = {
  channelId: Scalars['ID']['input'];
  channelListId: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsArchiveNetworkRecordingArgs = {
  input: ArchiveNetworkRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsBlockChannelArgs = {
  channelId: Scalars['ID']['input'];
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCancelNetworkSeriesRecordingArgs = {
  input: CancelNetworkSeriesRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCancelReminderArgs = {
  input: CancelReminderInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCancelSeasonOfNetworkSeriesRecordingArgs = {
  input: CancelSeasonOfNetworkSeriesRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCancelVodTransactionArgs = {
  input: CancelVodTransactionInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCatchupEventArgs = {
  input: CatchupEventInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeActiveProfileArgs = {
  input: ChangeActiveProfileInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeChannelListNameArgs = {
  input: ChangeChannelListNameInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeChannelPreferencesArgs = {
  input: ChangeChannelPreferencesInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceAppTrackingConsentArgs = {
  input: ChangeDeviceAppTrackingConsentInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceDrmNetworkDeviceIdArgs = {
  input: ChangeDeviceDrmNetworkDeviceIdInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceEnablementPolicyArgs = {
  input: ChangeDeviceEnablementPolicyInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceNameArgs = {
  input: ChangeDeviceNameInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDevicePreviewModeArgs = {
  input: ChangeDevicePreviewModeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceStateArgs = {
  input: ChangeDeviceStateInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeDeviceVideoQualityLimitArgs = {
  input: ChangeDeviceVideoQualityLimitInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeHouseholdChannelSortLanguageArgs = {
  input: ChangeHouseholdChannelSortLanguageInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeHouseholdCommunityArgs = {
  input: ChangeHouseholdCommunityInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeHouseholdOnboardingInfoArgs = {
  input: ChangeHouseholdOnboardingInfoInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeHouseholdPreferencesArgs = {
  input: ChangeHouseholdPreferencesInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeInitialChannelListArgs = {
  input: ChangeInitialChannelListInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeLanguageArgs = {
  input: ChangeLanguageInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeMasterPincodeArgs = {
  input: ChangeMasterPincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeNetworkRecordingArgs = {
  input: ChangeNetworkRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeProfileLogoutPincodeArgs = {
  input: ChangeProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeProfileOnboardingInfoArgs = {
  input: ChangeProfileOnboardingInfoInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeProfilePermissionsArgs = {
  input: ChangeProfilePermissionsInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeProfilePincodeArgs = {
  input: ChangeProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsChangeProfilePreferencesArgs = {
  input: ChangeProfilePreferencesInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsClearRecommendationHistoryArgs = {
  input: ClearRecommendationHistoryInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsClearWatchHistoryArgs = {
  input: ClearWatchHistoryInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCompleteVodTransactionArgs = {
  input: CompleteVodTransactionInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateChannelListArgs = {
  name: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateDeviceArgs = {
  input: CreateDeviceInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateNetworkRecordingArgs = {
  input: CreateNetworkRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateNetworkSeriesRecordingArgs = {
  input: CreateNetworkSeriesRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreatePersonalChannelListArgs = {
  input: CreatePersonalChannelListInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateProfileArgs = {
  input: CreateProfileInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateProfileLogoutPincodeArgs = {
  input: CreateProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsCreateProfilePincodeArgs = {
  input: CreateProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteChannelListArgs = {
  channelListId: Scalars['ID']['input'];
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteEpisodesOfNetworkSeriesRecordingArgs = {
  input: DeleteEpisodesOfNetworkSeriesRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteEventBookmarkArgs = {
  input: DeleteEventBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteForeignDeviceArgs = {
  input: DeleteForeignDeviceInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteMessageArgs = {
  input: DeleteMessageInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteProfileArgs = {
  input: DeleteProfileInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteProfileLogoutPincodeArgs = {
  input: DeleteProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteProfilePincodeArgs = {
  input: DeleteProfilePincodeInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteRecordingArgs = {
  input: DeleteRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteRecordingBookmarkArgs = {
  input: DeleteRecordingBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDeleteVodBookmarkArgs = {
  input: DeleteVodBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDetermineCommunityArgs = {
  input: DetermineCommunityInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsDownloadVodAssetArgs = {
  input: DownloadVodAssetInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsFavouriteItemArgs = {
  input: FavouriteItemInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsMoveChannelListArgs = {
  channelListId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPauseLiveChannelArgs = {
  input: PauseLiveChannelInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPlayChannelArgs = {
  input: PlayChannelInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPlayPpvEventArgs = {
  input: PlayPpvEventInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPlayRecordingArgs = {
  input: PlayRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPlayTrailerArgs = {
  input: PlayTrailerInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPlayVodAssetArgs = {
  input: PlayVodAssetInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPurchaseChannelProductArgs = {
  input: PurchaseChannelProductInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPurchaseUpsellProductArgs = {
  input: PurchaseUpsellProductInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsPurchaseVodProductArgs = {
  input: PurchaseVodProductInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsReadMessageArgs = {
  input: ReadMessageInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsRemoveChannelArgs = {
  channelId: Scalars['ID']['input'];
  channelListId: Scalars['ID']['input'];
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsResetProfilePreferencesArgs = {
  input: ResetProfilePreferencesInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsRestartEventArgs = {
  input: RestartEventInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSendMqttMessageToDeviceArgs = {
  input: SendMqttMessageToDeviceInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSendPlaybackHeartbeatArgs = {
  input: SendPlaybackHeartbeatInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetChannelListChannelsArgs = {
  input: SetChannelListChannelsInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetEventBookmarkArgs = {
  input: SetEventBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetHouseholdConfirmedReplayPermissionsArgs = {
  input: SetHouseholdConfirmedReplayPermissionsInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetRecordingBookmarkArgs = {
  input: SetRecordingBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetReminderArgs = {
  input: SetReminderInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsSetVodBookmarkArgs = {
  input: SetVodBookmarkInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsStartAndCompletePpvTransactionArgs = {
  input: StartAndCompletePpvTransactionInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsStartVodTransactionArgs = {
  input: StartVodTransactionInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsStopPlaybackArgs = {
  input: StopPlaybackInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsUnarchiveNetworkRecordingArgs = {
  input: UnarchiveNetworkRecordingInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsUnblockChannelArgs = {
  channelId: Scalars['ID']['input'];
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsUnfavouriteItemArgs = {
  input: UnfavouriteItemInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsUpdateDownloadArgs = {
  input: UpdateDownloadInput;
};


/**
 *  all mutations follow the same structure: `<operationName>(input: <operationName>Input!): <operationName>Payload`
 *  inspired by https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
 *  yes, I'm aware that this results in some freakishly long names
 *  several input types define optional fields, which means that they should be left unchanged if not provided as a parameter in the mutation
 *  general naming convention: create, change, delete
 */
export type Nexx4MutationsUploadInternalDeviceSettingsArgs = {
  input: UploadInternalDeviceSettingsInput;
};

/**  Nexx4 queries */
export type Nexx4Queries = {
  __typename?: 'Nexx4Queries';
  /**  fetch the list of all the available communities the end user can choose from */
  availableCommunities?: Maybe<CommunityCatalog>;
  availableParentalRatings?: Maybe<ParentalRatingCatalog>;
  /**  Retrieves a banner */
  banner: Banner;
  /**
   *  Retrieves the data needed for displaying binge watch functionality when the end of the supplied currentItem has been reached.
   *  The response can be null if no binge watching is available for the supplied content item (e.g. not part of series, or only one item available in the series).
   */
  bingeWatchData?: Maybe<BingeWatchData>;
  /**  return all bookmarks for the current profile */
  bookmarks?: Maybe<BookmarkCatalog>;
  /**  @deprecated on 2017-10-19: use the "catchupEvent" mutation instead */
  catchupEvent?: Maybe<EventPlaybackInfo>;
  channel?: Maybe<Channel>;
  channelList?: Maybe<ChannelList>;
  /**  Parameter 'profileId' is deliberately _not_ deprecated: as another profile, I need to be able to edit a Kids profile, and assign a channel list of that Kids profile to be the singleChannelList of the Kids profile. */
  channelLists?: Maybe<ChannelListCatalog>;
  /**  Used to retrieve a ChannelProduct. */
  channelProduct?: Maybe<ChannelProduct>;
  /**
   *  use the following query to find out to which products a certain Channel belongs
   *  @deprecated on 2018-05-04: use the "upsellProducts" query instead
   */
  channelProducts?: Maybe<ChannelProductCatalog>;
  /**
   *  retrieves all available channels with an optional filter and optional sorting
   *  Note that blocked channels are included based on the ProfilePermissions.displayBlockedChannels field and ChannelFilterParams.forceReturnBlockedChannels field.
   */
  channels?: Maybe<ChannelsConnection>;
  /**  Retrieves the status from the deletion of a foreign device from its household. */
  checkForeignDeviceDeletionStatus: ForeignDeviceDeletionStatusPayload;
  /**
   *  Checks if the supplied pincode is correct.
   *  Through the pincodeKinds parameter, the client can indicate which types of pincodes should be
   *  checked (e.g. only master pincode for entering certain setting screens, or both the profile and
   *  the master pincode to unblock adult content).
   *  The return value will indicate if the supplied pincode matches one of the pincodes indicated
   *  by the supplied pincodeKinds.
   */
  checkPincode: Scalars['Boolean']['output'];
  /**
   *  Using the contentFolder query the application is able to request a piece/Page of the contentFolder data.
   *  (can be used when retrieving the data in pages/chunks)
   */
  contentFolder?: Maybe<ContentFolder>;
  /**  this allows the client app to open a specific part of the VOD catalogue (eg. jump directly to the HBO catalogue) */
  contentFolderList?: Maybe<ContentFolderList>;
  /**
   *  Retrieves multiple ContentItem instances
   *  The content items to be retrieved are defined by the combination of their ID and their type, which are
   *  provided in the 'ids' and 'types' array parameters. As such, these arrays must be of equal length since
   *  the ID supplied at a specific position in the ids array will be used together with the ContentItemType at
   *  that position in the types array to identify the item that is to be retrieved.
   *  The response catalog should contain one entry for each of the requested content items.
   *  If one of the requested items can not be returned (it could not be found, or an error occured while retrieving
   *  that specific item), this should not trigger an error for the whole query. A catalog instance should still be
   *  returned with the other requested items that can be retrieved.
   *  If an item is requested that can not be found in the back-end, then a null value should be returned for that
   *  item in the response catalog (no error is to be included for this). If another error occured while retrieving a
   *  specific item that was requested, then a null value should be returned for that item in the response catalog,
   *  and an error should be added to the response that indicates why the retrieval of the item failed. This error
   *  should include a 'path' property that indicates to which item it applies
   *  (see https://spec.graphql.org/October2021/#sec-Errors for the path property).
   *  Note: it is still possible that a more generic error (e.g. authentication failure) results in an error response
   *  without a catalog instance in the response data.
   */
  contentItems: ContentItemCatalog;
  defaultProfileKindParentalRatings?: Maybe<DefaultProfileKindParentalRatingCatalog>;
  /**  Used to retrieve environment data */
  environment?: Maybe<Environment>;
  event?: Maybe<Event>;
  /**  return all the favourites for the current profile */
  favourites?: Maybe<FavouritableItemCatalog>;
  /**
   *  *** Genres ***
   *  Retrieves all available genres.
   */
  genres?: Maybe<GenreCatalog>;
  /**
   *  one or more items shown in the header of the "Home" screen (for Nexx 4.0 web and mobile tablet clients only)
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  homeHeader?: Maybe<ContentFolder>;
  /**
   *  the rows (e.g. "For you") shown on the "Home" screen
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  homeRows?: Maybe<ContentFolderList>;
  /**
   *  *** Household/Profiles/Users ***
   *  @deprecated on 2017-08-31: use the "me" query instead
   */
  household?: Maybe<Household>;
  /**
   *  *** Channels & EPG ***
   *  this could be the channelList stored by the user (for a particular profile)
   *  or it could be a fixed channelList defined by the operator
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  initialChannelList?: Maybe<ChannelList>;
  /**  *** General *** */
  languages?: Maybe<LanguageCatalog>;
  /**  *** me, myself and I *** */
  me?: Maybe<User>;
  /**
   *  Retrieves a single message by its Id.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  message: Message;
  /**
   *  Messaging
   *  Retrieves the list of messages
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  messages: MessagesConnection;
  /**
   *  *** My Library ***
   *  see Functional spec - "My Library" chapter for a detailed description of the requirements
   *  the current assumption is that the back-office will apply the profile-specific logic described there
   *  note that we currently only support one profile (the Family profile)
   *  the "onlyDisplayAdultContent" boolean indicates that the user wants to see regular content (false) or adult content only (true)
   *  validation of the master pincode will be done by the client app before doing this query
   *  this also includes any (optional) recommendation rows
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  myLibrary?: Maybe<ContentFolderList>;
  /**
   *  one or more items shown in the header of the "My Library" screen (for Nexx 4.0 web and mobile clients only)
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  myLibraryHeader?: Maybe<ContentFolder>;
  /**
   *  Retrieves the pause content for the on demand asset.
   *  The ContentFolder can contain all ContentItemKinds.
   *  The return value will be null if no pause content available.
   */
  pauseContentForOnDemand?: Maybe<ContentFolder>;
  /**
   *  Retrieves the pause content for the tv events of a channel.
   *  The ContentFolder can contain all ContentItemKinds.
   *  The return value will be null if no pause content available.
   */
  pauseContentForTV?: Maybe<ContentFolder>;
  /**  @deprecated on 2017-10-19: use the "pauseLiveChannel" mutation instead */
  pauseLiveEvent?: Maybe<EventPlaybackInfo>;
  /**
   *  *** Playback ***
   *  @deprecated on 2017-10-19: use the "playChannel" mutation instead
   */
  playChannel?: Maybe<ChannelPlaybackInfo>;
  /**  @deprecated on 2017-10-19: use the "playRecording" mutation instead */
  playRecording?: Maybe<RecordingPlaybackInfo>;
  /**  Used to retrieve a ProductBundle. */
  productBundle?: Maybe<ProductBundle>;
  radioChannelLists?: Maybe<ChannelListCatalog>;
  /**  @deprecated on 2018-05-18: use specific queries (e.g. homeRows, search, myLibrary, vodRootContentFolderList, ...) or the relatedContent field returning a ContentFolderList instead */
  recommendationGrid?: Maybe<RecommendationGrid>;
  /**
   *  *** "For you" ***
   *  itemId can refer to the ID of a channel, recording, event, vod movie, feature, product, etc.
   *  this is only relevant for Detailed Info screens
   *  @deprecated on 2018-05-18: use specific queries (e.g. homeRows, search, myLibrary, vodRootContentFolderList, ...) or the relatedContent field returning a ContentFolderList instead
   */
  recommendationRows?: Maybe<RecommendationListCatalog>;
  /**
   *  Lists the channels to be enabled for replay when the "Recommended" button is pressed in the replay management screen.
   *  Null when there is no limit (the field "maxNumberOfConfirmedReplayChannels" on Household is null).
   *  In that case, the client should show a "Select all" button instead and select all channels that can be enabled for replay.
   */
  recommendedReplayChannels?: Maybe<ChannelsConnection>;
  recording?: Maybe<Recording>;
  recordingArchiveQuota?: Maybe<Quota>;
  /**
   *  quota can be retrieved as a separate query
   *  this means it's not forcibly linked to a Household/Device/User/...
   *  the client doesn't (shouldn't) really care what entity the quota is linked to
   */
  recordingQuota?: Maybe<Quota>;
  /**
   *  *** Recordings & Reminders ***
   *  Contains all recordings for which:
   *  ((startDate == undefined) OR ((recording.start >= startDate)) AND
   *  ((endDate == undefined) OR (recording.start < endDate)))
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  recordings?: Maybe<RecordingsConnection>;
  reminder?: Maybe<Reminder>;
  /**
   *  Contains all reminders for which:
   *  ((startDate == undefined) OR ((event.start >= startDate)) AND
   *  ((endDate == undefined) OR (event.Start < endDate)))
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  reminders?: Maybe<ReminderCatalog>;
  /**
   *  *** Resource Bundles ***
   *  retrieves the resource bundle info.
   *  The optional deviceType and language param will only be used when resourceBundles are requested with a deviceless session, otherwise the real DeviceType and language will be used
   *  The optional language will be used for language-specific resourceBundles. The expected format is the ISO639-2T language code, e.g. "eng", "nld"
   */
  resourceBundles?: Maybe<ResourceBundlesPayload>;
  /**  @deprecated on 2017-10-19: use the "restartEvent" mutation instead */
  restartEvent?: Maybe<EventPlaybackInfo>;
  /**
   *  the results will be shown as individual rows, per type of content (EPG, Recordings, On Demand, etc.)
   *  each row is a ContentFolder, therefore this query returns a ContentFolderList
   *  for further info: see "Search" chapter in 4.0 Functional Spec
   *  note that the keyword param may represent the word (prefix) that the user has typed in (eg. "ben")
   *  but it could also be one of the selected cast members (eg. "Ben Affleck")
   *  the functional spec states: "By default, searching takes place in the cast (actors and directors), title and original title (if present)."
   *  so for now, there's no need to create different search queries for keyword vs cast member
   *  see the searchRows query for the rows to be shown when no search has been executed yet
   *  note: the SearchFilter input object is mandatory, but contains only nullable fields, so that the user can search without using any filters
   */
  search?: Maybe<ContentFolderList>;
  /**  Retrieves the genres that can be used in the filtering of search results. */
  searchGenres?: Maybe<GenreCatalog>;
  /**
   *  *** Search ***
   *  get a list of keywords for a given input string
   *  note that the spec limits this to "cast members", but it makes sense to define this as a more generic term in this API
   *  the client expects that the input string itself is not returned as an item in this Catalog!
   *  these keywords will be used in the "search" query to get the actual results
   *  the back-office should return an error if the input String doesn't have enough characters (e.g. minimum 3)
   */
  searchKeywords?: Maybe<KeywordCatalog>;
  /**  the rows (e.g.: "Trending") shown on the "Search" screen when opened and no search has been executed yet */
  searchRows?: Maybe<ContentFolderList>;
  series?: Maybe<Series>;
  /**
   *  retrieve the seriesinfo of the series that are involved in recordings for the household
   *  these SeriesRecordingInfo objects can be used to determine which episodes/seasons are cancelled in the household
   */
  seriesRecordings?: Maybe<SeriesRecordingInfoCatalog>;
  /**  Returns the rows (e.g. "My subscriptions", "Available Subscription packages") shown on the "Subscriptions" screen */
  subscriptionsRows?: Maybe<ContentFolderList>;
  /**
   *  When a Channel has a timedMetadataSource with value BACKEND_QUERY, this query retrieves the deeplink URL for
   *  the channel with the supplied channelId at the supplied (optional) playback position. If no timed metadata is
   *  available for the supplied parameters, null will be returned.
   */
  timedMetadata?: Maybe<Scalars['String']['output']>;
  /**  Gets a list of trending searches (most executed searches) */
  trendingSearches?: Maybe<StringCatalog>;
  /**
   *  Use the following query to retrieve the upsell products depending on the given context (e.g. channel).
   *  Parameters:
   *  - profileId: Deprecated: Even if supplied, the ID of the active profile will be used instead.
   *  - kind: Defines the context from where upsell products are requested.
   *  - objectId: Depends on the context kind; in case of CHANNEL contexts, the objectId refers to a channel ID,
   *    in case of VOD_ASSET contexts, the objectId refers to a VOD asset ID.
   *  Note that in a VOD_ASSET context, VODProducts of all ProductKinds (SVOD, EST, TVOD) will be returned by this query.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  upsellProducts?: Maybe<ProductCatalog>;
  /**
   *  Retrieves the corresponding GraphQL Schema version
   *  e.g. v27.0.0
   */
  version: Scalars['String']['output'];
  versionInfo?: Maybe<VersionInfo>;
  vodAsset?: Maybe<VodAsset>;
  vodFolder?: Maybe<VodFolder>;
  vodProduct?: Maybe<VodProduct>;
  /**
   *  returns a collection of products linked to this particular VODAsset
   *  the returned VODProductCatalog shall only contain VODProducts that:
   *  * are available for purchase on this client device
   *  * don't have an entitlement for the user performing the request
   *  this means that the client app can show a single "Buy/Rent" action for each VODProduct in this Catalog
   *  this is a separate query for performance reasons
   *  if there would be a direct VODAsset -> VODProducts relation, queries may become too complex and impose a high load on the back-office
   *  @deprecated on 2018-09-28: use the "upsellProducts" query instead
   */
  vodProducts?: Maybe<VodProductCatalog>;
  /**
   *  this is the content being shown when the user opens the "regular" On Demand app
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  vodRootContentFolderList?: Maybe<ContentFolderList>;
  vodSeries?: Maybe<VodSeries>;
};


/**  Nexx4 queries */
export type Nexx4QueriesBannerArgs = {
  bannerId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesBingeWatchDataArgs = {
  currentItemId: Scalars['ID']['input'];
  currentItemType: ContentItemType;
};


/**  Nexx4 queries */
export type Nexx4QueriesCatchupEventArgs = {
  eventId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelListArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelListsArgs = {
  kindFilter?: InputMaybe<Array<InputMaybe<ChannelListKind>>>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelProductArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelProductsArgs = {
  channelId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesChannelsArgs = {
  filter?: InputMaybe<ChannelFilterParams>;
  pagination: FullPaginationParams;
  sorting?: InputMaybe<ChannelSortingParams>;
};


/**  Nexx4 queries */
export type Nexx4QueriesCheckForeignDeviceDeletionStatusArgs = {
  clientGeneratedDeviceId: Scalars['String']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesCheckPincodeArgs = {
  pincode: Scalars['String']['input'];
  pincodeKinds: Array<InputMaybe<PincodeKind>>;
  profileId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesContentFolderArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesContentFolderListArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesContentItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  types: Array<InputMaybe<ContentItemType>>;
};


/**  Nexx4 queries */
export type Nexx4QueriesEventArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesHomeHeaderArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesHomeRowsArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesInitialChannelListArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesMessageArgs = {
  messageId: Scalars['ID']['input'];
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesMessagesArgs = {
  filter: MessageFilterParams;
  pagination: FullPaginationParams;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesMyLibraryArgs = {
  onlyDisplayAdultContent: Scalars['Boolean']['input'];
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesMyLibraryHeaderArgs = {
  onlyDisplayAdultContent: Scalars['Boolean']['input'];
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesPauseContentForOnDemandArgs = {
  assetId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesPauseContentForTvArgs = {
  channelId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesPauseLiveEventArgs = {
  eventId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesPlayChannelArgs = {
  channelId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesPlayRecordingArgs = {
  recordingId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesProductBundleArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesRadioChannelListsArgs = {
  kindFilter?: InputMaybe<Array<InputMaybe<ChannelListKind>>>;
};


/**  Nexx4 queries */
export type Nexx4QueriesRecommendationGridArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesRecommendationRowsArgs = {
  itemId?: InputMaybe<Scalars['ID']['input']>;
  page: PageWithRecommendations;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesRecordingArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesRecordingsArgs = {
  filter?: InputMaybe<RecordingsFilterParams>;
  pagination: FullPaginationParams;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesReminderArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesRemindersArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesResourceBundlesArgs = {
  appVersion: Scalars['String']['input'];
  deviceType?: InputMaybe<DeviceType>;
  language?: InputMaybe<Scalars['String']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesRestartEventArgs = {
  eventId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesSearchArgs = {
  context: SearchContext;
  filter: SearchFilter;
  keyword: Scalars['String']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesSearchKeywordsArgs = {
  filter: SearchFilter;
  input: Scalars['String']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesSearchRowsArgs = {
  context: SearchContext;
};


/**  Nexx4 queries */
export type Nexx4QueriesSeriesArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesSubscriptionsRowsArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesTimedMetadataArgs = {
  channelId: Scalars['ID']['input'];
  playbackPosition?: InputMaybe<Scalars['Date']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesUpsellProductsArgs = {
  kind: UpsellProductsContextKind;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesVodAssetArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesVodFolderArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesVodProductArgs = {
  id: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesVodProductsArgs = {
  assetId: Scalars['ID']['input'];
};


/**  Nexx4 queries */
export type Nexx4QueriesVodRootContentFolderListArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  Nexx4 queries */
export type Nexx4QueriesVodSeriesArgs = {
  id: Scalars['ID']['input'];
};

/**  Result of the notifyDeviceActivation mutation */
export type NotifyDeviceActivationPayload = {
  __typename?: 'NotifyDeviceActivationPayload';
  success: Scalars['Boolean']['output'];
};

/**  restriction that prohibits playback on a device that's connected to an output device (e.g. HDMI converter) */
export type OutputPlaybackRestriction = PlaybackRestriction & {
  __typename?: 'OutputPlaybackRestriction';
  /** # PlaybackRestriction interface */
  message: Scalars['String']['output'];
};

/**
 *  suggested expiry: DEFAULT
 *  The product that is used for a PayPerView event.
 */
export type PpvProduct = Cacheable & Product & {
  __typename?: 'PPVProduct';
  backgroundImage?: Maybe<Image>;
  entitlement?: Maybe<ProductEntitlement>;
  expiry: Scalars['Date']['output'];
  fullDescription: Scalars['String']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  kind: ProductKind;
  price?: Maybe<Price>;
  purchaseInfo?: Maybe<ProductPurchaseInfo>;
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  Optional deeplink URL for the package in the selfcare webapp.
   *  If specified (not null nor empty string), the client will open this URL in the browser instead of the client's native Detailed Package Info screen.
   */
  selfcareUrl?: Maybe<Scalars['String']['output']>;
  shortDescription: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  thumbnail?: Maybe<Image>;
  /** # Product interface: */
  title: Scalars['String']['output'];
  trailers: TrailerCatalog;
};


/**
 *  suggested expiry: DEFAULT
 *  The product that is used for a PayPerView event.
 */
export type PpvProductBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  suggested expiry: DEFAULT
 *  The product that is used for a PayPerView event.
 */
export type PpvProductShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  suggested expiry: DEFAULT
 *  The product that is used for a PayPerView event.
 */
export type PpvProductThumbnailArgs = {
  height: Scalars['Int']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /**  when navigating forwards, the cursor to start from */
  endCursor?: Maybe<Scalars['String']['output']>;
  /**  when navigating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /**  when navigating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /**  when navigating backwards, the cursor to start from */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/**  @deprecated on 2018-05-18: use specific queries (e.g. homeRows, search, myLibrary, vodRootContentFolderList, ...) or the relatedContent field returning a ContentFolderList instead */
export enum PageWithRecommendations {
  DetailedChannel = 'DETAILED_CHANNEL',
  DetailedChannelProduct = 'DETAILED_CHANNEL_PRODUCT',
  DetailedFeature = 'DETAILED_FEATURE',
  DetailedMainPackage = 'DETAILED_MAIN_PACKAGE',
  DetailedPromotion = 'DETAILED_PROMOTION',
  DetailedRec = 'DETAILED_REC',
  DetailedRecSeries = 'DETAILED_REC_SERIES',
  DetailedTv = 'DETAILED_TV',
  DetailedTvSeries = 'DETAILED_TV_SERIES',
  DetailedVodMovie = 'DETAILED_VOD_MOVIE',
  DetailedVodProduct = 'DETAILED_VOD_PRODUCT',
  DetailedVodSeries = 'DETAILED_VOD_SERIES',
  Home = 'HOME',
  Mylibrary = 'MYLIBRARY',
  Ondemand = 'ONDEMAND',
  Search = 'SEARCH'
}

/**  suggested expiry: DEFAULT */
export type ParentalRating = Cacheable & {
  __typename?: 'ParentalRating';
  /**
   *  In addition to the rating rank, we also require a flag that indicates whether the content is adult.
   *  This triggers additional presentation mechanisms in the UI.
   */
  adult: Scalars['Boolean']['output'];
  /**
   *  multilingual description of the rating as shown in the UI
   *  for example: "Suitable for all ages"
   */
  description: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /**
   *  the image size for this icon may be different for detailed info vs profile preferences
   *  but if the back-office doesn't support multiple sizes for this icon, the client will scale it
   *  the icon is optional - if it's not available, we'll use the title
   *  If flavour is not provided, ImageFlavour.NORMAL is assumed.
   */
  icon?: Maybe<Image>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  All parental rating schemes must support a linear ranking scheme which orders the different rankings.
   *  A low rank value implies a high suitability for all ages.
   *  The UI uses this ranking to determine if a pincode should be requested to unlock the playback of the item.
   *  Unlocking is done by comparing the ratingTreshold of the logged-in subscriber (household or viewer) with the rank.
   */
  rank: Scalars['Int']['output'];
  /**
   *  multilingual title of the rating, as shown in the UI (eg. PG, U18, 16+)
   *  this obviously depends on local regulations
   */
  title: Scalars['String']['output'];
};


/**  suggested expiry: DEFAULT */
export type ParentalRatingIconArgs = {
  flavour?: InputMaybe<ImageFlavour>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/**  suggested expiry: DEFAULT */
export type ParentalRatingCatalog = Cacheable & Catalog & {
  __typename?: 'ParentalRatingCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<ParentalRating>>;
};

export type PauseLiveChannelInput = {
  channelId: Scalars['ID']['input'];
  eventId?: InputMaybe<Scalars['ID']['input']>;
  /**  pausedAt is the date and time in UTC to resume from */
  pausedAt: Scalars['Date']['input'];
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
  /**
   *  tunedAt is the date and time in UTC when the client tuned to the channel
   *  For deployments where PauseLiveTV is not allowed to rewind before the tunedAt timestamp, this parameter must be filled in.
   */
  tunedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type PauseLiveChannelPayload = {
  __typename?: 'PauseLiveChannelPayload';
  playbackInfo: TimeshiftPlaybackInfo;
};

export type PersonalChannelInfo = Cacheable & {
  __typename?: 'PersonalChannelInfo';
  /**
   *  Channel specific audio language preference, stored on profile level.
   *  Format: ISO639-2T language code, e.g. "eng", "nld"
   *  Special values (deviating from ISO639-2T):
   *  - "" (empty string): Audio/subtitle language preference is not filled in.
   */
  audioLanguage?: Maybe<Scalars['String']['output']>;
  /**
   *  does this channel require the master pincode to be viewed?
   *  this value can be changed by the clients via the "blockChannel" and "unblockChannel" mutations
   */
  blocked: Scalars['Boolean']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  Channel specific subtitle language preference, stored on profile level.
   *  Format: ISO639-2T language code, e.g. "eng", "nld"
   *  Special values (deviating from ISO639-2T):
   *  - "none": Corresponds to language setting "None", which means no subtitles should be shown.
   *  - "" (empty string): Audio/subtitle language preference is not filled in.
   */
  subtitleLanguage?: Maybe<Scalars['String']['output']>;
};

/**  suggested expiry: 30 minutes or less */
export type PersonalEventInfo = Cacheable & {
  __typename?: 'PersonalEventInfo';
  /**
   *  bookmark can be set during RestartTV or CatchupTV
   *  it's not necessary to split into 2 separate bookmarks
   *  if there's no bookmark available, this should be null
   */
  bookmark?: Maybe<Bookmark>;
  expiry: Scalars['Date']['output'];
  /**
   *  Indicates if the event is in the my favourites list of the current profile (not other profiles, even if the current profile is allowed to view or edit content of other profiles).
   *  It is either favourited or it's not, therefore this can't be null.
   */
  favourited: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  not set by current profile: value should be -1
   *  user can set a "PERSONAL" rating between 0 <= rating <= 5
   */
  rating?: Maybe<Rating>;
  /**
   *  recordings are always linked to a profile
   *  note that the same event can be recorded by multiple profiles
   *  for local PVR, there can be > 1 recordings when the user has recorded multiple parts
   *  using the filter parameter, the client can decide whether to request all linked recordings, or only a specific type of recordings
   *  this is an optional parameter: omitting it means that recordings of all types will be returned
   *  using aliases, the client could even request local and network recordings in separate lists
   */
  recordings?: Maybe<Array<Maybe<Recording>>>;
  /**
   *  a reminder is either set, or it's not
   *  therefor, this can't be null
   *  @deprecated on 2020-02-18: cache the list of reminders on the client and determine if a reminder is set with this list instead to avoid invalidating the client cache upon cancelling reminders
   */
  reminderSet: Scalars['Boolean']['output'];
  /**
   *  Indicates if the series to which this event belongs is currently scheduled as a series recording.
   *  This flag can be true even if the event itself will not be recorded (e.g. if this event is a rerun of an episode that has already been recorded in the past).
   *  This flag can be null (e.g. if the event doesn't belong to a series).
   *  Clients can use this flag to determine if series related recording actions should be shown on this event.
   */
  seriesIsPlannedForRecording?: Maybe<Scalars['Boolean']['output']>;
};


/**  suggested expiry: 30 minutes or less */
export type PersonalEventInfoRecordingsArgs = {
  kindFilter?: InputMaybe<RecordingKind>;
};

export type PersonalProductInfo = Cacheable & {
  __typename?: 'PersonalProductInfo';
  expiry: Scalars['Date']['output'];
  /**
   *  Indicates if the Product is in the my favourites list of the current profile (not other profiles, even if the current profile is allowed to view or edit content of other profiles).
   *  It is either favourited or it's not, therefore this can't be null.
   */
  favourited: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};

/**  suggested expiry: 5 minutes */
export type PersonalRecordingInfo = Cacheable & {
  __typename?: 'PersonalRecordingInfo';
  /**  True if the recording was archived. This can only apply to NetworkRecordings, so the value will always be false for other recordings kinds. */
  archived: Scalars['Boolean']['output'];
  /**  this should be null if no bookmark is set */
  bookmark?: Maybe<Bookmark>;
  expiry: Scalars['Date']['output'];
  /**
   *  Indicates if the recording is in the my favourites list of the current profile (not other profiles, even if the current profile is allowed to view or edit content of other profiles).
   *  It is either favourited or it's not, therefore this can't be null.
   */
  favourited: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  Indicates if the recording is part of a series recording or a single recording (even if the parent Event has a SeriesInfo). */
  partOfSeriesRecording: Scalars['Boolean']['output'];
  /**  Indicates if recording the season has been cancelled (or was never planned). */
  seasonCancelled: Scalars['Boolean']['output'];
  /**  Indicates if recording the entire series has been cancelled (or was never planned). */
  seriesCancelled: Scalars['Boolean']['output'];
  /**  Indicates if the series has completed recordings. */
  seriesHasCompletedRecordings: Scalars['Boolean']['output'];
  /**
   *  Indicates until which moment an archived recording can be unarchived. Not null when archived = true.
   *  If the recording cannot be unarchived, the only other option is to delete it.
   */
  unarchiveUntil?: Maybe<Scalars['Date']['output']>;
};

export type PersonalSeriesRecordingInfo = {
  __typename?: 'PersonalSeriesRecordingInfo';
  archiveStatus: SeriesRecordingArchiveStatus;
};

export type PersonalVodInfo = Cacheable & {
  __typename?: 'PersonalVODInfo';
  bookmark?: Maybe<Bookmark>;
  expiry: Scalars['Date']['output'];
  /**
   *  Indicates if the VODAsset is in the my favourites list of the current profile (not other profiles, even if the current profile is allowed to view or edit content of other profiles).
   *  It is either favourited or it's not, therefore this can't be null.
   */
  favourited: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};

export enum PincodeKind {
  /**  The master pincode. */
  Master = 'MASTER',
  /**  A profile pincode. */
  Profile = 'PROFILE',
  /**  A profile logout pincode. */
  ProfileLogout = 'PROFILE_LOGOUT'
}

export type PlayChannelInput = {
  channelId: Scalars['ID']['input'];
  /**
   *  the "replaceSessionId" parameter indicates that the back-office should stop the previously created session with the given ID first
   *  this is the sessionId that was defined in a previously requested PlaybackInfo object
   *  it's an optional parameter because the client may not always want to replace a previously created session
   *  for example: when starting a picture-in-picture stream
   */
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string field has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

export type PlayChannelPayload = {
  __typename?: 'PlayChannelPayload';
  playbackInfo: ChannelPlaybackInfo;
};

export type PlayPpvEventInput = {
  eventId: Scalars['ID']['input'];
  /**
   *  the "replaceSessionId" parameter indicates that the back-office should stop the previously created session with the given ID first
   *  this is the sessionId that was defined in a previously requested PlaybackInfo object
   *  it's an optional parameter because the client may not always want to replace a previously created session
   *  for example: when starting a picture-in-picture stream
   */
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string field has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

export type PlayPpvEventPayload = {
  __typename?: 'PlayPPVEventPayload';
  playbackInfo: ChannelPlaybackInfo;
};

export type PlayRecordingInput = {
  recordingId: Scalars['ID']['input'];
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

export type PlayRecordingPayload = {
  __typename?: 'PlayRecordingPayload';
  playbackInfo: RecordingPlaybackInfo;
};

export type PlayTrailerInput = {
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
  trailerId: Scalars['ID']['input'];
};

export type PlayTrailerPayload = {
  __typename?: 'PlayTrailerPayload';
  playbackInfo: TrailerPlaybackInfo;
};

export type PlayVodAssetInput = {
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
  /**
   *  The id of a VODAssetEntitlement that shows the user is permitted to watch the Asset.
   *  If needed the back-office can use that VODAssetEntitlement to determine the Product that
   *  was purchased to get it.
   */
  vodAssetEntitlementId: Scalars['ID']['input'];
  vodAssetId: Scalars['ID']['input'];
};

export type PlayVodAssetPayload = {
  __typename?: 'PlayVODAssetPayload';
  playbackInfo: VodPlaybackInfo;
};

/**
 *  there may be reasons why the back-office cannot (and/or should not) return PlaybackInfo to the client, such as:
 *  not subscribed, geo-fending, concurrency limit reached, etc.
 *  these "exceptions" should be reported via the standard GraphQL error mechanism
 */
export type PlaybackInfo = {
  /**
   *  The length to be used as ad pre-roll duration (in seconds).
   *  The interpretation to be given to this property depends on the value of the adPlaybackRestrictions property. The
   *  AdPlaybackRestriction enum provides more details for this.
   */
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  /**
   *  Specifies if any restrictions apply to the ads that are in this playback session. Any AdPlaybackRestriction scenario that is
   *  included in this list is not allowed to be performed during ads in this playback session.
   *  If empty or null, no restrictions are applicable.
   *  Note: additional AdPlaybackRestriction types can be added in the future. In order to remain backward compatible, clients must
   *  be able to ignore unknown AdPlaybackResctriction scenarios.
   */
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  /**  Using the adSignallingType clients know how ad blocks should be handled in specific cases. */
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /**  see definition below */
  deliveryKind: ContentDeliveryKind;
  /**
   *  heartbeat must be null if:
   *  - this is not supported or required for a certain back-office integration
   *  - it's not applicable to a certain type of stream
   */
  heartbeat?: Maybe<Heartbeat>;
  /**  playback might be limited to some kind of restriction, see definition below */
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  /**  contains the parameters needed to start a RTSP stream */
  rtspHeaders?: Maybe<RtspHeaders>;
  /**
   *  this represents the session that was created by the back-office on an external component in the streaming infrastructure
   *  it's optional as this may not be relevant for all back-office implementations, or only for specific types of streaming scenarios
   */
  sessionId?: Maybe<Scalars['ID']['output']>;
  /**
   *  Specifies if any restrictions apply to trickplay in this playback session. Any TrickplayRestriction type that is
   *  included in this list is not allowed to be performed in this playback session.
   *  If empty or null, no restrictions are applicable.
   *  Note: additional TrickplayRestriction types can be added in the future. In order to remain backward compatible, clients must
   *  be able to ignore unknown TrickplayRestriction types.
   */
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  /**
   *  In case of ContentDeliveryKind "RTSP", this contains the controller url
   *  the URL may be very different for each deliveryKind
   *  but as long as it's serializable, we'll be fine
   */
  url: Scalars['String']['output'];
};

/**  playback restrictions are restrictions that can only be detected by the client (e.g. device connected to HDMI, rooted device, ...) */
export type PlaybackRestriction = {
  /**
   *  description of the restriction that can be presented to the user if it's violated
   *  the kind of PlaybackRestriction can be derived by parsing the '__typename' field
   */
  message: Scalars['String']['output'];
};

export enum PlaybackStatus {
  Paused = 'PAUSED',
  Playing = 'PLAYING',
  Stopped = 'STOPPED'
}

/**
 *  suggested expiry: 5 minutes (depends on validity in the BO (SDS Evo))
 *  it's obvious that we should always show the most accurate, up-to-date price to the end user
 */
export type Price = Cacheable & {
  __typename?: 'Price';
  amount: Scalars['Float']['output'];
  /**
   *  currency as provided by the operator (or content provider?)
   *  it's the operator's choice whether to use eg. "USD" or "$"
   */
  currency: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /**  Indicates if a coupon can be used */
  hasCoupon?: Maybe<Scalars['Boolean']['output']>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};

/**
 *  Represents a Product.
 *  A product can be a ChannelProduct, VODProduct, ProductBundle, ...
 */
export type Product = {
  /**
   *  Background image, converted to the given width and height.
   *  Since these images should fill up the entire screen, the client application knows the preferred dimensions.
   */
  backgroundImage?: Maybe<Image>;
  /**  Entitlement for this product. Is null if there is no entitlement. */
  entitlement?: Maybe<ProductEntitlement>;
  /**  full description of the product */
  fullDescription: Scalars['String']['output'];
  /**
   *  Defines the kind of product.
   *  Note that this may actually depend on the authenticated user (if the BO supports such rules).
   *  E.g. a product may not be purchased depending on the authenticated user or context.
   */
  kind: ProductKind;
  /**
   *  price should be null if the product is free
   *  if it's a subscription product, the client currently assumes the price is a monthly fee
   */
  price?: Maybe<Price>;
  /**
   *  Optional information on the purchase fulfilment.
   *  This field can be null if the back-office does not provide or support purchase information,
   *  when the product is not yet purchased, or when the purchase is completed.
   */
  purchaseInfo?: Maybe<ProductPurchaseInfo>;
  /**
   *  related content for this Product, listed as rows on the "Detailed Product Info" (e.g.: "Detailed Main Package Info", "Detailed Channel Package Info", "Detailed On Demand Package", ...) screen
   *  this can contain content folders such as "Included", "More from", "More like", etc.
   *  the actual content can be defined by the operator (i.e. via the marketing console)
   *  it can be null if there is no related content available (for whatever reason)
   */
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  Optional deeplink URL for the package in the selfcare webapp.
   *  If specified (not null nor empty string), the client will open this URL in the browser instead of the client's native Detailed Package Info screen.
   */
  selfcareUrl?: Maybe<Scalars['String']['output']>;
  /**
   *  Short description of the product, limited by maxLength number of characters.
   *  If maxLength is not provided, the entire shortDescription should be returned.
   */
  shortDescription: Scalars['String']['output'];
  /**  multilingual subtitle of the product */
  subtitle: Scalars['String']['output'];
  /**
   *  Thumbnail image, always landscape, and converted to the given height.
   *  The aspect ratio is kept intact.
   */
  thumbnail?: Maybe<Image>;
  /**  multilingual title of the Product, as it will be shown in the UI */
  title: Scalars['String']['output'];
  /**  if this product doesn't have a trailer, the catalog should have an empty array */
  trailers: TrailerCatalog;
};


/**
 *  Represents a Product.
 *  A product can be a ChannelProduct, VODProduct, ProductBundle, ...
 */
export type ProductBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  Represents a Product.
 *  A product can be a ChannelProduct, VODProduct, ProductBundle, ...
 */
export type ProductShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  Represents a Product.
 *  A product can be a ChannelProduct, VODProduct, ProductBundle, ...
 */
export type ProductThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  suggested expiry: DEFAULT
 *  This type is equivalent to the "(main) package" concept as described in the functional spec
 *  It is a product that contains one or more sub-products.
 */
export type ProductBundle = Cacheable & Product & {
  __typename?: 'ProductBundle';
  backgroundImage?: Maybe<Image>;
  entitlement?: Maybe<ProductEntitlement>;
  expiry: Scalars['Date']['output'];
  fullDescription: Scalars['String']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  kind: ProductKind;
  price?: Maybe<Price>;
  /**
   * # Specific
   *  Bundled sub-products
   */
  products: ProductCatalog;
  purchaseInfo?: Maybe<ProductPurchaseInfo>;
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  Optional deeplink URL for the package in the selfcare webapp.
   *  If specified (not null nor empty string), the client will open this URL in the browser instead of the client's native Detailed Package Info screen.
   */
  selfcareUrl?: Maybe<Scalars['String']['output']>;
  shortDescription: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  thumbnail?: Maybe<Image>;
  /** # Product interface: */
  title: Scalars['String']['output'];
  trailers: TrailerCatalog;
};


/**
 *  suggested expiry: DEFAULT
 *  This type is equivalent to the "(main) package" concept as described in the functional spec
 *  It is a product that contains one or more sub-products.
 */
export type ProductBundleBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  suggested expiry: DEFAULT
 *  This type is equivalent to the "(main) package" concept as described in the functional spec
 *  It is a product that contains one or more sub-products.
 */
export type ProductBundleShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};


/**
 *  suggested expiry: DEFAULT
 *  This type is equivalent to the "(main) package" concept as described in the functional spec
 *  It is a product that contains one or more sub-products.
 */
export type ProductBundleThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  Collection of Products.
 *  suggested expiry: DEFAULT
 */
export type ProductCatalog = Cacheable & Catalog & {
  __typename?: 'ProductCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  /**  The items */
  items: Array<Maybe<Product>>;
};

/**  Indicates if a Product is entitled. */
export type ProductEntitlement = Cacheable & {
  __typename?: 'ProductEntitlement';
  /**  Until what date & time is this Product entitled? */
  availableUntil?: Maybe<Scalars['Date']['output']>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};

export enum ProductKind {
  /**  this product is available for everyone, free of charge */
  Free = 'FREE',
  /**
   *  this product is available for a "one-time" purchase and provides unlimited availability to the linked content
   *  for VOD content, this is the EST (Electronic Sell-through) scenario and the product can be acquired via startVODTransaction
   */
  Purchase = 'PURCHASE',
  /**
   *  this product gives the user access to the linked content for a limited period of time
   *  for VOD content, this is the TVOD scenario and the product can be acquired via startVODTransaction
   */
  Rental = 'RENTAL',
  /**
   *  a monthly fee is billed for this product
   *  cannot be purchased directly in the client app using the Pincode
   */
  Subscription = 'SUBSCRIPTION',
  /**  same as subscription, but this product kind can be purchased via Pincode */
  SubscriptionUpsellable = 'SUBSCRIPTION_UPSELLABLE'
}

/**
 *  Suggested expiry: depends on how fast a purchase product is activated.
 *  Contains product purchase information.
 *  Objects of this type only live during purchase fulfilment. Before the purchase or after the fulfilment,
 *  the information is not present.
 */
export type ProductPurchaseInfo = Cacheable & {
  __typename?: 'ProductPurchaseInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The purchase status. */
  status: ProductPurchaseStatus;
};

/**  Represents the purchase status of a product. */
export enum ProductPurchaseStatus {
  /**  Indicates that a household has a product, but the product is being activated (e.g. provisioning still ongoing). */
  InProgress = 'IN_PROGRESS',
  /**  Indicates that a household doesn't have a product */
  NotSubscribed = 'NOT_SUBSCRIBED',
  /**  Indicates that a household has a product and it is activated */
  Subscribed = 'SUBSCRIBED'
}

/**  suggested expiry: DEFAULT */
export type Profile = Cacheable & {
  __typename?: 'Profile';
  avatar: ProfileAvatar;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  see enum below */
  kind: ProfileKind;
  /**  The pincode that must be entered when logging out of this profile. If null, no pincode is needed to log out. */
  logoutPincode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onboardingInfo: ProfileOnboardingInfo;
  permissions: ProfilePermissions;
  /**  note: pincode is not mandatory (depends on ProfileProtection) */
  pincode?: Maybe<Scalars['String']['output']>;
  preferences: ProfilePreferences;
  /** # pincode behaviour */
  protection?: Maybe<ProfileProtection>;
};

export type ProfileAvatar = Cacheable & {
  __typename?: 'ProfileAvatar';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  type: ProfileAvatarType;
};

export enum ProfileAvatarType {
  Dad = 'DAD',
  Daughter = 'DAUGHTER',
  Family = 'FAMILY',
  Kids = 'KIDS',
  Mom = 'MOM',
  Parents = 'PARENTS',
  Son = 'SON'
}

/**  suggested expiry: DEFAULT */
export type ProfileCatalog = Cacheable & Catalog & {
  __typename?: 'ProfileCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Profile>>;
};

/**
 *  An entry in the ProfilePreferences consents list.
 *  Indicates if a specific consent was accepted or declined, and at what time.
 */
export type ProfileConsent = {
  __typename?: 'ProfileConsent';
  /**  Flag to indicate if the consent was accepted or declined. */
  accepted: Scalars['Boolean']['output'];
  /**
   *  The date at which this consent was accepted or declined.
   *  This date indicates when the consent was last updated from undefined/declined to accepted, or from undefined/accepted to declined.
   */
  date: Scalars['Date']['output'];
  /**
   *  The type of the consent.
   *  The possible values that are used for the consent types can be operator specific.
   */
  type: Scalars['ConsentType']['output'];
};

/**  A consent input entry that can be used in the consents array of ChangeProfilePreferencesInput */
export type ProfileConsentInput = {
  /**  This consent is accepted if this property is set to true, or declined if this property is set to false */
  accepted: Scalars['Boolean']['input'];
  /**  The type of the consent. The possible consent types can be different for different operators (see the ConsentType scalar definition). */
  type: Scalars['ConsentType']['input'];
};

export enum ProfileKind {
  Family = 'FAMILY',
  Kids = 'KIDS',
  Other = 'OTHER',
  Parent = 'PARENT'
}

/**  suggested expiry: DEFAULT */
export type ProfileOnboardingInfo = Cacheable & {
  __typename?: 'ProfileOnboardingInfo';
  /**
   *  The date on which the user has completed the age rating onboarding screen or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeProfileOnboardingInfo" mutation.
   */
  ageRatingStepCompleted?: Maybe<Scalars['Date']['output']>;
  expiry: Scalars['Date']['output'];
  /**
   * # Cacheable interface:
   *  The id should not change over time in order for caching to work correctly (the mutation returns the ProfileOnboardingInfo as payload and not the Profile).
   */
  id: Scalars['ID']['output'];
  /**
   *  The date on which the user has completed the privacy agree/disagree onboarding screen for the profile or null if he hasn't yet.
   *  This is only set/updated by explicitly calling the "changeProfileOnboardingInfo" mutation.
   */
  privacyStepCompleted?: Maybe<Scalars['Date']['output']>;
};

/**  suggested expiry: DEFAULT */
export type ProfilePermissions = Cacheable & {
  __typename?: 'ProfilePermissions';
  accessSearch: Scalars['Boolean']['output'];
  canPurchase: Scalars['Boolean']['output'];
  createRecordings: Scalars['Boolean']['output'];
  displayBlockedChannels: Scalars['Boolean']['output'];
  editChannelLists: Scalars['Boolean']['output'];
  editMyLibrary: Scalars['Boolean']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  the following permissions are only relevant for a Kids profile
   *  @deprecated on 2020-11-04 to avoid confusion with the logoutPincode on type Profile. This one won't be used.
   */
  logoutPincode?: Maybe<Scalars['String']['output']>;
  manageApps: Scalars['Boolean']['output'];
  /**
   *  Represents the "Mask not-allowed content" setting. When set to true, the client should mask any content for which the logged in
   *  profile does not have the required parental rating.
   *  Masking content means: replacing the title, description, thumbnail, background, ... with something family friendly.
   */
  maskContent: Scalars['Boolean']['output'];
  otherProfilesContent: Scalars['Boolean']['output'];
  /** # permissions: */
  parentalRating: ParentalRating;
  /**
   *  The monthly expenditure limit for purchases
   *  -1 is used to depict an unlimited value
   */
  purchaseLimit: Scalars['Float']['output'];
  purchaseProtection: ProfileProtection;
  resortApps: Scalars['Boolean']['output'];
  /**  "singleChannelList" must be null if this profile is not limited to a specific ChannelList */
  singleChannelList?: Maybe<ChannelList>;
};

/**  suggested expiry: DEFAULT */
export type ProfilePreferences = Cacheable & {
  __typename?: 'ProfilePreferences';
  /** # general preferences/settings */
  appSorting: AppSorting;
  /**  @deprecated on 2020-07-29: use avatar on Profile instead. */
  avatar?: Maybe<Image>;
  /**  The consents (e.g. track viewing behaviour) that the profile has approved or declined. */
  consents: Array<Maybe<ProfileConsent>>;
  displayNotSubscribedChannels: Scalars['Boolean']['output'];
  expiry: Scalars['Date']['output'];
  /**
   *  Special values (deviating from ISO639-2T):
   *  - "none": Only applicable for subtitle language. Corresponds to language setting "None", which means no subtitles should be shown.
   */
  firstAudioLanguage: Scalars['String']['output'];
  firstSubtitleLanguage: Scalars['String']['output'];
  hardOfHearing: Scalars['Boolean']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  secondAudioLanguage: Scalars['String']['output'];
  secondSubtitleLanguage: Scalars['String']['output'];
  /**  @deprecated on 2022-10-26: Replaced by the 'consents' property: it must be possible for different types of tracking/personalisation consents to be given. */
  trackViewingBehaviour: Scalars['Boolean']['output'];
  visuallyImpaired: Scalars['Boolean']['output'];
};


/**  suggested expiry: DEFAULT */
export type ProfilePreferencesAvatarArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export enum ProfileProtection {
  Masterpin = 'MASTERPIN',
  None = 'NONE',
  Pincode = 'PINCODE'
}

export type PurchaseChannelProductInput = {
  /**  identifies the channel product to be purchased. This ID may be client specific (depend on household, device, ...) */
  channelProductId: Scalars['ID']['input'];
};

/**  by returning the ChannelProduct in the payload, the client is able to update the "subscribed" status for all channels in this product */
export type PurchaseChannelProductPayload = {
  __typename?: 'PurchaseChannelProductPayload';
  channelProduct: ChannelProduct;
};

/**  Input for purchasing an upsell product */
export type PurchaseUpsellProductInput = {
  /**  The coupon that can be used for a VOD Product purchase */
  coupon?: InputMaybe<Scalars['String']['input']>;
  /**  Identifies the price for which the upsell product is purchased */
  priceId: Scalars['ID']['input'];
  /**  Identifies the product to be purchased */
  productId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  Return type used when the upsell product is purchased. */
export type PurchaseUpsellProductPayload = {
  __typename?: 'PurchaseUpsellProductPayload';
  /**  Defines the purchased product. */
  product: Product;
};

export type PurchaseVodProductInput = {
  /**  The coupon that can be used for a VOD Product purchase */
  coupon?: InputMaybe<Scalars['String']['input']>;
  /**
   *  what price did I agree with?
   *  BO could return a "invalid price" error if this price is no longer valid
   */
  priceId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  what product do I want to buy?
   *  this could be a specific quality, a trilogy pack, a temporary offer, etc.
   */
  vodProductId: Scalars['ID']['input'];
};

export type PurchaseVodProductPayload = {
  __typename?: 'PurchaseVODProductPayload';
  /**  via the VODProduct, the updated assets and entitlements can be retrieved */
  product: VodProduct;
};

/**  suggested expiry: 5 minutes */
export type Quota = Cacheable & {
  __typename?: 'Quota';
  /**
   *  the total amount of quota that can be used
   *  units are determined by the "kind" field
   *  use a value of -1 to depict unlimited quota
   *  see also the example for the "used" field
   */
  allowed: Scalars['Int']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  kind?: Maybe<QuotaKind>;
  /**
   *  the absolute value of the used quota
   *  units are determined by the "kind" field
   *  e.g.: the user has 10 hours of allowed NPVR quota and currently has 7 hours 15 minutes used by recordings
   *  expressed in seconds, the "allowed" field would be 3600 and the "used" field would be 26100
   *  based on this information, the client app can show that 72.5% of the available quota is in use
   *  or it can simply use the absolute values
   */
  used: Scalars['Int']['output'];
};

export enum QuotaKind {
  /**  expressed in seconds */
  Duration = 'DURATION',
  /**  expressed in megabytes (MB = 1.000.000 bytes), not mebibytes */
  Storage = 'STORAGE'
}

/**  suggested expiry: DEFAULT */
export type Rating = Cacheable & {
  __typename?: 'Rating';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  name is optional
   *  it could indicate the source of the rating (User community, IMDB, Rotten Tomatoes, etc.)
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   *  the value should always be expressed in percentages (0..100)
   *  so this means the middleware (or back-office) may need to do a calculation for the different rating schemes
   */
  value: Scalars['Int']['output'];
};

/**  Input for the readMessage mutation */
export type ReadMessageInput = {
  messageId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**  Result of the readMessage mutation */
export type ReadMessagePayload = {
  __typename?: 'ReadMessagePayload';
  message: Message;
};

/**
 *  the term "Recommendation" is to be taken very liberally
 *  for example: there could be a catalog containing the 10 most recent recordings (incl. manual recordings)
 *  @deprecated on 2018-05-18: use ContentItem instead
 */
export type Recommendation = Channel | ChannelProduct | Event | NetworkRecording;

/**
 *  suggested expiry: 5 minutes
 *  @deprecated on 2018-05-18: not used, if added again, should use ContentItem instead
 */
export type RecommendationGrid = Cacheable & {
  __typename?: 'RecommendationGrid';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  items: Array<Maybe<RecommendationGridItem>>;
  title: Scalars['String']['output'];
};

/**
 *  suggested expiry: 5 minutes
 *  @deprecated on 2018-05-18: not used, if added again, should use ContentItem instead
 */
export type RecommendationGridItem = Cacheable & {
  __typename?: 'RecommendationGridItem';
  content: Recommendation;
  expiry: Scalars['Date']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

/**
 *  suggested expiry: 5 minutes
 *  @deprecated on 2018-05-18: use ContentFolder instead
 */
export type RecommendationList = Cacheable & Catalog & {
  __typename?: 'RecommendationList';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Recommendation>>;
  /**
   *  this is the "row title" as mentioned in the functional spec
   *  multilingual value, describing what's being shown in this RecommendationList
   */
  title: Scalars['String']['output'];
};

/**
 *  suggested expiry: 5 minutes
 *  @deprecated on 2018-05-18: use ContentFolderList instead
 */
export type RecommendationListCatalog = Cacheable & Catalog & {
  __typename?: 'RecommendationListCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<RecommendationList>>;
};

export type Recording = {
  /**
   *  the channel can only be null if it doesn't exist in the line-up anymore
   *  in this exceptional case, the client application will show the channelName instead
   */
  channel?: Maybe<Channel>;
  channelName?: Maybe<Scalars['String']['output']>;
  /**
   *  Indicates how conflicted recordings will be resolved when the user takes no action to resolve the conflict when the EPG event starts.
   *  This field can be used for updating the UI without a roundtrip to the backend.
   */
  conflictAutoResolution?: Maybe<ConflictAutoResolution>;
  /**  Other conflicted recordings when status is CONFLICT. */
  conflictedRecordings?: Maybe<Array<Maybe<Recording>>>;
  /**  every recording can be protected from automatic deletion */
  deleteProtected: Scalars['Boolean']['output'];
  end: Scalars['Date']['output'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  personalInfo: PersonalRecordingInfo;
  /**
   *  the impact of this recording on the available quota
   *  units are determined by the "kind" field of the quota returned by the recordingQuota/recordingArchiveQuota query, e.g.:
   *  1. DURATION: duration of the recording (expressed in seconds)
   *  2. STORAGE: storage size (expressed in megabytes)
   */
  size: Scalars['Int']['output'];
  /**  these are the planned start & end times: */
  start: Scalars['Date']['output'];
  /**  see enum below */
  status: RecordingStatus;
};


export type RecordingPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export enum RecordingKind {
  Network = 'NETWORK'
}

/**  note: there's no reason to cache this, so it's not a Cacheable */
export type RecordingPlaybackInfo = PlaybackInfo & {
  __typename?: 'RecordingPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  /**
   *  The "end of the episode" which triggers the display of the Episode-row (binge watching)
   *  This is a time in seconds before the end of the stream. This includes the guard time.
   *  Can be null if the Recording being played is not an episode
   */
  endOfEpisodeOffset?: Maybe<Scalars['Int']['output']>;
  heartbeat?: Maybe<Heartbeat>;
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  recording: Recording;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
};

export enum RecordingStatus {
  Completed = 'COMPLETED',
  Conflict = 'CONFLICT',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED'
}

/**
 *  suggested expiry: calculated
 *  the expiry should be equal to the time of the first state change for any of the Recordings in this set
 *  for example: a client device requests its list of recordings at 10:13 AM
 *  in this list, one recording is in progress until 10:30 AM and the other recordings are completed
 *  the expiry should be set to 10:30 AM in this case
 *  similar logic of course applies to planned recordings
 */
export type RecordingsConnection = Cacheable & Connection & {
  __typename?: 'RecordingsConnection';
  edges: Array<Maybe<RecordingsEdge>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Connection interface: */
  pageInfo: PageInfo;
  /**  how many recordings are available that match the given filter parameters (start, end, kind, ...) ? */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RecordingsEdge = Cacheable & Edge & {
  __typename?: 'RecordingsEdge';
  /** # Edge interface: */
  cursor: Scalars['String']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  locationIndicator?: Maybe<Scalars['Int']['output']>;
  node: ListedRecording;
};

export type RecordingsFilterParams = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Array<InputMaybe<RecordingStatus>>>;
};

/**  suggested expiry: DEFAULT */
export type Reminder = Cacheable & {
  __typename?: 'Reminder';
  /**  The reminder offset from start of the event in seconds */
  beforeTime: Scalars['Int']['output'];
  event: Event;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
};

/**  suggested expiry: 5 minutes */
export type ReminderCatalog = Cacheable & Catalog & {
  __typename?: 'ReminderCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Reminder>>;
};

export type ResetProfilePreferencesInput = {
  profileId: Scalars['ID']['input'];
  /**
   *  Flag to reset all channel preferences to defaults for the Profile with the given profileId:
   *  audioLanguage and subtitleLanguage are removed from each Channel.personalInfo(profileId) object.
   */
  resetChannelPreferences: Scalars['Boolean']['input'];
};

export type ResetProfilePreferencesPayload = {
  __typename?: 'ResetProfilePreferencesPayload';
  /**
   *  To update the GraphQL clients' cache - after resetting the channel preferences - we need to be able to retrieve
   *  the PersonalChannelInfo for each channel from the mutations' response. To make sure we can access every available channel,
   *  this payload should give access to the full channellist.
   */
  fullChannelList: ChannelList;
  profile: Profile;
};

/**  Suggested expiry: 5 minutes */
export type ResourceBundle = Cacheable & {
  __typename?: 'ResourceBundle';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  the location to download the resource bundle file */
  url: Scalars['String']['output'];
  /**
   *  changes if the resource bundle content changes.
   *  client checks it with the one cached on the client to determine if it needs to download the resource bundle.
   *  might be any string value but must be unique e.g.:
   *    - a manual value entered in a mapping config file
   *    - hash of the file contents combined with a last modified date from the resource bundle file (only modified date is not unique!)
   *    - ...
   *  client must check if different and not check greater than.
   *  for the "App" resource bundle, it's synonymous with the appVersion.
   */
  version: Scalars['String']['output'];
};

export type ResourceBundlesPayload = {
  __typename?: 'ResourceBundlesPayload';
  /**
   *  the latest application resource bundle
   *  can be null if no bundle is configured or none are matching when filtering (on e.g. appVersion, community, deviceType)
   */
  application?: Maybe<ResourceBundle>;
  /**
   *  the latest resource bundles compatible with the current app version
   *  if an application resource bundle update is available and will be performed,
   *  a new request must be made to get the resourcebundles compatible with the new appVersion
   *  can be null if no bundle is configured or none are matching when filtering (on e.g. appVersion, community, deviceType)
   */
  configuration?: Maybe<ResourceBundle>;
  localization?: Maybe<ResourceBundle>;
  /**  the remote control resourcebundle allows clients to update the firmware on the rcu */
  remotecontrol?: Maybe<ResourceBundle>;
  skin?: Maybe<ResourceBundle>;
};

export type RestartEventInput = {
  eventId: Scalars['ID']['input'];
  replaceSessionId?: InputMaybe<Scalars['ID']['input']>;
  /**
   *  Differentiate the playbackMutation behaviour, based on a network specific parameter.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  2 network identification mechanisms are known :
   *  1) the field can contain the public IP address of the client
   *  The streaming network ip address that will be provided by the client for HLS setup over public internet
   *  If not specified, playback will be done over the same network as the middleware/back-office request
   *  If specified, a different network will be used.
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   *  the returned streaming url will reference a different streamer, based on the parameter value
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

export type RestartEventPayload = {
  __typename?: 'RestartEventPayload';
  playbackInfo: TimeshiftPlaybackInfo;
};

/**
 *  this type contains the parameters needed to start a RTSP stream
 *  it's the result of the RTSP session setup
 */
export type RtspHeaders = {
  __typename?: 'RtspHeaders';
  /**
   *  the RTSP session header
   *  e.g.: "37352258406418045020;timeout=60"
   */
  session?: Maybe<Scalars['String']['output']>;
  /**
   *  the RTSP transport header isn't split into separate fields, because there might be playout-system specific info in there.
   *  e.g.: "MP2T/H2221/UDP;unicast;destination=192.168.0.16;client_port=11206;source=192.168.0.138;server_port=7726"
   */
  transport?: Maybe<Scalars['String']['output']>;
};

export enum SearchContentType {
  Events = 'EVENTS',
  GoogleSearch = 'GOOGLE_SEARCH',
  OnDemand = 'ON_DEMAND',
  Recordings = 'RECORDINGS'
}

export type SearchContext = {
  /**
   *  Search offers context-sensitive search result-sorting, based on the screen where they are initiated.
   *  The default behaviour: All types of content are searched (regardless of the location where the search is initiated from) and the order of the search result-rows depends on the location where the search was triggered
   *  (see spec for further details)
   *  so this field specifies the "context" where the search was initiated from
   *  if omitted, search results are sorted by the default sorting order: TV events, On Demand, Recordings
   */
  orderContentType?: InputMaybe<SearchContentType>;
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 *  this input variable lists all possible Search filters currently described in the spec
 *  if a certain filter field is not included in the input object for the "search" query, this filter must not be applied to the search operation
 *  for example: using "minimumRating: 0" in the query, is redundant and it's encouraged to omit minimumRating from the query if the end user doesn't want to filter on rating
 *  the same goes for e.g. "paidContent: ALL"
 *  a special note on arrays (e.g. audioLanguages, genreIds, etc.): specifying an empty array must be interpreted the same way as omitting the filter field
 */
export type SearchFilter = {
  /**
   *  quote from the spec:
   *  Audio-language; which contains a list of audio-languages, The default is All.
   *  When a specific (set of) audio-language(s) is selected, it filters the search results on the selected audio-language(s).
   *  So by using this filter the viewer can search for content in his/her own language(s).
   *  For example, "English" is selected, as a result all items which feature an english audio track are displayed, the rest is hidden.
   *  these strings should be 3-letter ISO639-2T language codes (see additional info in ProfilePreferences type)
   */
  audioLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   *  quote from the spec:
   *  Filter; which contains a list of content-providers (operator, HBO, Netflix, Amazon, ...); The default is All.
   *  When a specific (set of) content-provider(s) is selected, it filters the search results on the selected content-provider(s).
   *  For example, "Netflix" is selected, as a result all Netflix items are displayed, the rest is hidden.
   */
  contentProviders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   *  quote from the spec:
   *  Content-type; which contains a list of content-types (TV events, Recordings (excludes planned recordings), On Demand). The default is All.
   *  When a specific (set of) type(s) is selected, it filters the search results on the selected type(s).
   *  For example, "On Demand" is selected, as a result all On Demand items are displayed, the rest is hidden.
   */
  contentTypes?: InputMaybe<Array<InputMaybe<SearchContentType>>>;
  /**
   *  quote from the spec:
   *  Genre; which contains a list of genres. The default is All.
   *  When a specific (set of) genre(s) is selected, it filters the search results on the selected genre(s).
   *  For example, "Action" is selected, as a result all items which feature the Action genre are displayed, the rest is hidden.
   *  Note that Adult can also be present as a genre but it is never included in All. Selecting Adult requires the entry of the Master pincode/Profile pincode with enough rights.
   *  @see Genre, GenreCatalog
   */
  genreIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /**
   *  quote from the spec:
   *  Hard of Hearing: which contains 2 values: Only with hard of hearing and Only without hard of hearing. By default nothing is selected thus no filtering occurs.
   *  So by using this filter the viewer can search for content with(out) hard of hearing tracks.
   *  For example, "Only with hard of hearing" is selected, as a result all items which feature an hard of hearing track are displayed, the rest is hidden.
   */
  hardOfHearing?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *  quote from the spec:
   *  Rating; which contains a list of rating (1 to 5 stars). The default is 5.
   *  When a specific rating is selected, it filters the search results on the selected rating and higher.
   *  In the filter value-selection it also automatically selects the higher ratings to indicate this. So when the 3 stars-value is selected, 4 stars and 5 stars are automatically also selected.
   *  For example, "3 stars" is selected (and 4-and 5 stars automatically), as a result all items with rating 3, 4 or 5 stars are displayed, the rest is hidden.
   */
  minimumRating?: InputMaybe<Scalars['Int']['input']>;
  /**
   *  quote from the spec:
   *  Paid/Free & purchased; which contains All, Only free & purchased content, Only paid content. The default is All.
   *  This allows to filter between:
   *  a. Paid: paying (to be rented, purchased, subscribed).
   *  b. Free & purchased: included (rented, purchased, subscribed) and free content.
   *  c. Both.
   */
  paidContent?: InputMaybe<SearchPaidOrIncludedContent>;
  /**
   *  quote from the spec:
   *  Subtitle-language; which contains a list of subtitle-languages, The default is All.
   *  When a specific (set of) subtitle-language(s) is selected, it filters the search results on the selected subtitle-language(s).
   *  So by using this filter the viewer can search for content in his/her own language(s).
   *  For example, "English" is selected, as a result all items which feature an english subtitle track are displayed, the rest is hidden.
   *  these strings should be 3-letter ISO639-2T language codes (see additional info in ProfilePreferences type)
   */
  subtitleLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   *  quote from the spec:
   *  Audio description; which contains 2 values: Only with audio descriptions and Only without audio descriptions. By default nothing is selected thus no filtering occurs.
   *  So by using this filter the viewer can search for content with(out) audio description tracks.
   *  For example, "Only with audio descriptions" is selected, as a result all items which feature an audio description track are displayed, the rest is hidden.
   */
  visuallyImpaired?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SearchPaidOrIncludedContent {
  All = 'ALL',
  Included = 'INCLUDED',
  Paid = 'PAID'
}

export type SeasonInfo = {
  __typename?: 'SeasonInfo';
  /**  The cursor for the first episode of the season */
  cursor?: Maybe<Scalars['String']['output']>;
  /**  The # of episodes in this season (neccesary for correctly rendering the progress bar when jumping between seasons.) */
  episodeCount?: Maybe<Scalars['Int']['output']>;
  /**  The number of the season */
  number?: Maybe<Scalars['Int']['output']>;
};

export type SendMqttMessageToDeviceInput = {
  /**  The message to send */
  message: Scalars['String']['input'];
  /**  The device to which the MQTT message should be sent */
  targetDeviceId: Scalars['ID']['input'];
};

export type SendMqttMessageToDevicePayload = {
  __typename?: 'SendMQTTMessageToDevicePayload';
  success: Scalars['Boolean']['output'];
};

export type SendPlaybackHeartbeatInput = {
  /**  session ID is part of playbackInfo */
  sessionId: Scalars['ID']['input'];
};

export type SendPlaybackHeartbeatPayload = {
  __typename?: 'SendPlaybackHeartbeatPayload';
  /**  allows for interval updates */
  interval: Scalars['Int']['output'];
  /**  if multiple sessions are open, this ID identifies the session this heartbeat was sent for */
  sessionId: Scalars['ID']['output'];
};

export type Series = Cacheable & {
  __typename?: 'Series';
  /**
   *  The information about the episode that should be active (selected) when the series is first shown.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  activeEpisode?: Maybe<ActiveEpisodeInfo>;
  /**  since these images should fill up the entire screen, the client app knows the preferred dimensions */
  backgroundImage?: Maybe<Image>;
  /**  The channel on which the series is broadcast. */
  channel?: Maybe<Channel>;
  /**  The available episodes of this series. */
  content: ContentFolder;
  /**   @deprecated on 2018-09-19: use activeEpisode instead */
  edgeForActiveEpisode: ContentFolderContentItemsEdge;
  /**  The total amount of all episodes in this series object. See "Indication of number of episodes" in the functional spec. */
  episodeCount: Scalars['Int']['output'];
  expiry: Scalars['Date']['output'];
  /**
   *  This returns an array of GroupInfo objects that can be used when 'jumping' towards a group using the group selector.
   *  The meaning of a group depends on the context, and can be a season or a time-based subdivision of the series episodes.
   */
  groupingInfos?: Maybe<Array<Maybe<GroupingInfo>>>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  this is needed for parental control */
  parentalRating: ParentalRating;
  personalSeriesRecordingInfo?: Maybe<PersonalSeriesRecordingInfo>;
  /**
   *  This returns an array of SeasonsInfo objects that can be used when 'jumping' towards a specific season using the season selector.
   *  @deprecated on 2018-09-18: use groupingInfos instead
   */
  seasonInfos?: Maybe<Array<Maybe<SeasonInfo>>>;
  /**  A subtitle for the series, if applicable. */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**  always landscape */
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
};


export type SeriesActiveEpisodeArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


export type SeriesBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


export type SeriesEdgeForActiveEpisodeArgs = {
  profileId: Scalars['ID']['input'];
};


export type SeriesThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**  suggested expiry: DEFAULT */
export type SeriesInfo = Cacheable & {
  __typename?: 'SeriesInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  multilingual
   *  we may not always have a series title in the incoming metadata
   */
  title?: Maybe<Scalars['String']['output']>;
};

export enum SeriesRecordingArchiveStatus {
  /**  all episode recordings of this series are archived */
  FullyArchived = 'FULLY_ARCHIVED',
  /**
   *  the archive icon should not be shown
   *  currently only past/ongoing recordings row will have status that is different from NOT_APPLICABLE
   */
  NotApplicable = 'NOT_APPLICABLE',
  /**  no episode recordings of this series are archived */
  NotArchived = 'NOT_ARCHIVED',
  /**  some episode recordings of this series are archived, while others are not */
  PartiallyArchived = 'PARTIALLY_ARCHIVED'
}

/**
 *  A SeriesRecordingInfo object is the link between a non-personalized SeriesInfo object used for Recordings
 *  and a household/user.  When this objects exists, it means the enduser has chosen to record this series.
 *  We must know in this case if individual events are cancelled or Seasons are cancelled in order to present the right info to the household.
 */
export type SeriesRecordingInfo = Cacheable & {
  __typename?: 'SeriesRecordingInfo';
  /**  Seasons which are cancelled by the household in this series. */
  cancelledSeasons?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  This is the series for which this series recording object exists. */
  seriesInfo: SeriesInfo;
};

/**
 *  Catalog containing all the Recording Series that are actually planned by a user.  So, if such an object exists
 *  it means the user expects recordings for this series to be created.
 */
export type SeriesRecordingInfoCatalog = Cacheable & Catalog & {
  __typename?: 'SeriesRecordingInfoCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<SeriesRecordingInfo>>;
};

export type SetChannelListChannelsInput = {
  /**  The id of the channel list on which to set the channels */
  channelListId: Scalars['ID']['input'];
  /**
   *  The IDs of the new list of channels for this channel list. The supplied list of channels will replace the current channels in the channel list.
   *  It is not allowed to pass an empty list as new list of channels.
   */
  channels: Array<InputMaybe<Scalars['ID']['input']>>;
};

/**  Result of setChannelListChannels mutation */
export type SetChannelListChannelsPayload = {
  __typename?: 'SetChannelListChannelsPayload';
  /**  The updated channel list */
  channelList: ChannelList;
};

export type SetEventBookmarkInput = {
  /**
   *  the "audio" and "subtitle" parameters should identify the active audio/subtitle track at the time this bookmark is being created
   *  it will at least contain the language descriptor of the active track, possibly extended with additional information (such as audio type)
   *  all clients should be able to interpret this string and activate the correct audio/subtitle track when resuming playback of the event
   */
  audio?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

export type SetEventBookmarkPayload = {
  __typename?: 'SetEventBookmarkPayload';
  event: Event;
};

export type SetHouseholdConfirmedReplayPermissionsInput = {
  /**
   *  The list of householdConfirmedReplayPermissions to apply (applicable in A1 deployment).
   *  This value will overwrite existing householdConfirmedReplayPermissions settings. Any channel which
   *  is not part of this list, will be considered to have no householdConfirmedReplayPermissions configured.
   */
  householdConfirmedReplayPermissions: Array<InputMaybe<HouseholdConfirmedReplayPermission>>;
};

export type SetHouseholdConfirmedReplayPermissionsPayload = {
  __typename?: 'SetHouseholdConfirmedReplayPermissionsPayload';
  success: Scalars['Boolean']['output'];
};

export type SetRecordingBookmarkInput = {
  /**  see description about audio & subtitle tracks at "BookmarkEventInput" */
  audio?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  recordingId: Scalars['ID']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

export type SetRecordingBookmarkPayload = {
  __typename?: 'SetRecordingBookmarkPayload';
  recording: Recording;
};

/**  Input for the setReminder mutation */
export type SetReminderInput = {
  eventId: Scalars['ID']['input'];
};

/**  Result of the setReminder mutation */
export type SetReminderPayload = {
  __typename?: 'SetReminderPayload';
  reminder: Reminder;
};

export type SetVodBookmarkInput = {
  /**  see description about audio & subtitle tracks at "BookmarkEventInput" */
  audio?: InputMaybe<Scalars['String']['input']>;
  /**
   *  If this bookmark was created at an earlier date (e.g. when viewing a DTO/EST asset while offline), this property will specify when the bookmark was created.
   *  The back-end will be responsible for comparing this timestamp with any existing bookmarks on the VOD asset, and determining which bookmark should be used.
   *  If this property is not provided (or set to null), the back-end will use the current time as bookmark creation timestamp.
   */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  position: Scalars['Int']['input'];
  /**
   *  The profileId should be provided if the bookmark is for another profile than the active profile (e.g. when setting bookmarks that result from offline DTO viewing that used a different
   *  profile). If the profileId is not provided by the client, the back-end will use the currently active profile.
   */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  vodAssetId: Scalars['ID']['input'];
};

export type SetVodBookmarkPayload = {
  __typename?: 'SetVODBookmarkPayload';
  vodAsset: VodAsset;
};

export type StartAndCompletePpvTransactionInput = {
  /**  which event do I want to watch? */
  eventId: Scalars['ID']['input'];
  /**  what product do I want to buy? */
  ppvProductId: Scalars['ID']['input'];
  /**
   *  what price did I agree with?
   *  BO could return a "invalid price" error if this price is no longer valid
   */
  priceId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type StartAndCompletePpvTransactionPayload = {
  __typename?: 'StartAndCompletePPVTransactionPayload';
  /**  The PPV event that was purchased. */
  event: Event;
};

export type StartVodTransactionInput = {
  /**  The coupon that can be used for the VOD purchase */
  coupon?: InputMaybe<Scalars['String']['input']>;
  /**
   *  what price did I agree with?
   *  BO could return a "invalid price" error if this price is no longer valid
   */
  priceId: Scalars['ID']['input'];
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
  /**  what movie do I want to watch? */
  vodAssetId: Scalars['ID']['input'];
  /**
   *  what product do I want to buy?
   *  this could be a specific quality, a trilogy pack, a temporary offer, etc.
   */
  vodProductId: Scalars['ID']['input'];
};

export type StartVodTransactionPayload = {
  __typename?: 'StartVODTransactionPayload';
  /**
   *  this is the entitlement that should be used in the "CompleteVODTransaction" mutation
   *  it can also be used in the "playVODAsset" mutation
   *  This is most likely a "temporary" entitlement (only valid until CompleteVODTransaction is called).
   *  It could be a "final" entitlement, but this wouldn't change the purchase flow: CompleteVODTransaction should still be called with this entitlement as input.
   */
  entitlement: VodAssetEntitlement;
};

export enum StateChangeCause {
  /**  An external app triggered the state change (e.g. a virtual remote control on a mobile device) */
  App = 'APP',
  /**  A logical operation triggered the state change (e.g. going to standby on a timeout due to inactivity) */
  Logic = 'LOGIC',
  /**  A physical interaction triggered the state change (e.g. pressing a button on the remote or the device) */
  Physical = 'PHYSICAL',
  /**  A voice command triggered the state change (e.g. an Alexa voice command) */
  Voice = 'VOICE'
}

export type StopPlaybackInput = {
  /**  passing an empty string as sessionId will stop all the playback sessions that belong to the device */
  sessionId: Scalars['ID']['input'];
  /**
   *  in alignment with the parameter added to the playbackMutation behaviour, at least for the Wind context, the parameter is also required when stopping a session.
   *  to avoid that a different parameter will have to be defined for every mechanism that identifies the network a free format string parameter has been defined.
   *  A client configuration has to ensure that the content of the field corresponds to the supported mechanism within the deployed ecosystem.
   *  for stop on 1 network identification mechanism is known (aligned with the 2nd scenario, part of the playbackMutation) :
   *  2) the field contains a value, identifying a connection over a public or private network :
   *     - for public network : 'public' or if available 'public_ipaddress' with ipaddress an identifying ip address of a specific public subnet
   *     - for private network : 'private' or if available 'private_ipaddress' with ipaddress an identifying ip address of a specific private subnet
   */
  streamingNetworkIpAddress?: InputMaybe<Scalars['String']['input']>;
};

/**
 *  I have no idea what to return here, so let's just use a Boolean for now
 *  while actually, if this fails, an error should be returned, and StopPlaybackPayload is null
 */
export type StopPlaybackPayload = {
  __typename?: 'StopPlaybackPayload';
  success: Scalars['Boolean']['output'];
};

/**  Contains info about availability of certain items on the stream, indicated on the TV guide by icons */
export type StreamInfo = {
  __typename?: 'StreamInfo';
  dolby: Scalars['Boolean']['output'];
  hardOfHearing: Scalars['Boolean']['output'];
  /**  There are more than one non-visuallyImpaired audio languages available */
  multipleAudioLanguages: Scalars['Boolean']['output'];
  /**  There is at least one non-hardOfHearing subtitle track */
  subtitles: Scalars['Boolean']['output'];
  visuallyImpaired: Scalars['Boolean']['output'];
};

/**
 *  A catalog with string items.
 *  Suggested expiry: DEFAULT
 */
export type StringCatalog = Cacheable & Catalog & {
  __typename?: 'StringCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Scalars['String']['output']>>;
};

/**  there's no need to cache this info, since this kind of playback is "on request", contrary to eg. ChannelPlaybackInfo */
export type TimeshiftPlaybackInfo = PlaybackInfo & {
  __typename?: 'TimeshiftPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  /**  the desired behaviour when the EOS is reached (e.g. after fast-forwarding). */
  endOfStreamBehaviour: EndOfStreamBehaviour;
  /**  this allows the client to do parental rating checks etc. */
  event: Event;
  heartbeat?: Maybe<Heartbeat>;
  /**  the maximum size of the buffer in seconds */
  maximumBufferSize?: Maybe<Scalars['Int']['output']>;
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  /**
   *  the (expected) absolute end time of the stream (also includes possible afterTime) if there is a fixed end time
   *  in case of RestartTV or event-based PauseLiveTV, a date time in the future is returned
   *  null in case of buffer-based PauseLiveTV, where the end equals the current time
   */
  streamEnd?: Maybe<Scalars['Date']['output']>;
  /**
   *  the earliest absolute start time of the stream (also includes possible beforeTime)
   *  null in case it is buffer-based and the entire buffer is available
   *  streamStart = max(streamStart, maximumBufferSize ? (now - maxBufferSize) : streamStart))
   */
  streamStart?: Maybe<Scalars['Date']['output']>;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
};

/**  can be played with the "playTrailer" mutation */
export type Trailer = Cacheable & {
  __typename?: 'Trailer';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  during playback of a trailer, we need information (metadata) of the item (asset or product) the trailer is linked to
   *  TrailerPlaybackInfo links to the Trailer object, which links to the actual content item
   */
  linkedItem: TrailerContentItem;
  /**
   *  this title will be shown as the "label" of the action button that starts playback of the trailer
   *  if the metadata of the trailer has a specific title, this would then be shown in the UI
   *  if this metadata is not available, the back-office/middleware should return a fixed label, i.e. "Trailer" in the preferred language of the user
   */
  title: Scalars['String']['output'];
};

/**
 *  It's possible that multiple different Trailers exist for a VODAsset, VODProduct, ...
 *  If multiple video qualities of the same Trailer exist, the back-office will always use the highest quality. Therefore
 *  these qualities won't be shown to the user.
 */
export type TrailerCatalog = Cacheable & Catalog & {
  __typename?: 'TrailerCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<Trailer>>;
};

/**
 *  this is a specific union that defines which content items a trailer can link to
 *  additional types can be added if/when necessary
 *  note that a single content item can link to multiple trailers (see TrailerCatalog), but not vice versa, at least not in this schema
 *  this means that a trailer object is always a unique combination of trailer+contentItem (asset, product, ...)
 *  see "linkedItem" in Trailer
 */
export type TrailerContentItem = VodAsset | VodProduct;

export type TrailerPlaybackInfo = PlaybackInfo & {
  __typename?: 'TrailerPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  heartbeat?: Maybe<Heartbeat>;
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  trailer: Trailer;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
};

/**
 *  Channel to TrialChannelEntitlements should be a 1:1 relationship
 *  suggested expiry: 30 minutes or less
 */
export type TrialChannelEntitlements = Cacheable & {
  __typename?: 'TrialChannelEntitlements';
  catchupTV: Scalars['Boolean']['output'];
  endDate: Scalars['Date']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  See explanation in type ChannelEntitlements */
  liveTV: Scalars['Boolean']['output'];
  localRecording: Scalars['Boolean']['output'];
  networkRecording: Scalars['Boolean']['output'];
  pauseLiveTV: Scalars['Boolean']['output'];
  restartTV: Scalars['Boolean']['output'];
  /**  Validity period for the entitlement. Entitlement is only active if startDate < now < endDate. */
  startDate: Scalars['Date']['output'];
};

/**
 *  The trickplay actions that can be blocked during playback.
 *  Note: additional TrickplayRestriction types can be added in the future. In order to remain backward compatible, clients must
 *  be able to ignore unknown TrickplayRestriction types.
 */
export enum TrickplayRestriction {
  /**  Indicates that fast forwarding is not allowed. */
  FastForward = 'FAST_FORWARD',
  /**  Indicates that pausing is not allowed. */
  Pause = 'PAUSE',
  /**  Indicates that rewinding is not allowed. */
  Rewind = 'REWIND',
  /**  Indicates that skipping and dragging backward (if applicable) is not allowed. */
  SkipBackward = 'SKIP_BACKWARD',
  /**  Indicates that skipping and dragging forward (if applicable) is not allowed. */
  SkipForward = 'SKIP_FORWARD'
}

export type UnarchiveNetworkRecordingInput = {
  /**  Must refer to the "id" of a NetworkRecording object */
  recordingId: Scalars['ID']['input'];
};

export type UnarchiveNetworkRecordingPayload = {
  __typename?: 'UnarchiveNetworkRecordingPayload';
  /**  Unarchiving a recording could have an impact on the archive quota. */
  archiveQuota?: Maybe<Quota>;
  /**  The impacted recording. */
  recording: NetworkRecording;
};

export type UnfavouriteItemInput = {
  itemId: Scalars['ID']['input'];
  itemKind: FavouritableItemKind;
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type UnfavouriteItemPayload = {
  __typename?: 'UnfavouriteItemPayload';
  item: FavouritableItem;
};

export type UpdateDownloadInput = {
  /**  The new status of the download. */
  status: DownloadStatus;
  /**  The id of the VOD asset that is being downloaded. */
  vodAssetId: Scalars['ID']['input'];
};

export type UpdateDownloadPayload = {
  __typename?: 'UpdateDownloadPayload';
  success: Scalars['Boolean']['output'];
};

export type UploadInternalDeviceSettingsInput = {
  /**
   *  JSON list of key-value pairs, as a string, in the form "{'XXXXXX' : 'abc','YYYYYYY' : 'def','ZZZZZZZ' : 'ghi'}"
   *  limited to 200KB by SDSCloud
   */
  settings: Scalars['String']['input'];
};

export type UploadInternalDeviceSettingsPayload = {
  __typename?: 'UploadInternalDeviceSettingsPayload';
  success: Scalars['Boolean']['output'];
};

/**  Defines the kind of context from where upsell products are retrieved. */
export enum UpsellProductsContextKind {
  /**  Channel upsell context. */
  Channel = 'CHANNEL',
  /**  Event (PPV) upsell context. */
  Event = 'EVENT',
  /**  VOD Asset upsell context. */
  VodAsset = 'VOD_ASSET'
}

/**
 *  the User represents the entity that is currently authenticated
 *  this could be either a pre-provisioned device (most commonly a STB)
 *  or a username + password combination representing an actual person (identity)
 *  in both cases, the User object is linked to a certain Device, i.e. the client device that performed the authentication
 *  note that both "household" and "device" could have been separate root queries, but to limit the number of root queries, they're grouped into the User type
 *  suggested expiry: infinity
 */
export type User = Cacheable & {
  __typename?: 'User';
  /**  The address of the currently authenticated user */
  address?: Maybe<Scalars['String']['output']>;
  /**  the device being used by the currently authenticated client app */
  device: Device;
  expiry: Scalars['Date']['output'];
  /**
   *  this is the first name of the currently authenticated user (eg. "John")
   *  it should be provided by the CRM when provisioning users
   *  it's nullable, because in some cases, the CRM may not be able to provide this information
   *  in case of managed devices that don't explicitly perform user authentication, this should be the name of the master user
   *  or in other words: the one that is the household's main contact for the operator
   *  the name will be displayed during the onboarding process: "Welcome, John!"
   */
  firstName?: Maybe<Scalars['String']['output']>;
  /**  Whether this User is in guest mode. */
  guestMode: Scalars['Boolean']['output'];
  household: Household;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  The last name of the currently authenticated user (e.g. "Doe" for the full name "John Doe") */
  lastName?: Maybe<Scalars['String']['output']>;
  /**  MQTT broker FQDN that client should use for subscribing to topics */
  mqttBrokerUrl?: Maybe<Scalars['String']['output']>;
  /**
   *  MQTT topics that client devices must subscribe to in order to receive push notifications relevant to them (optional).
   *  Will be null if they could not be retrieved from the back-office.
   */
  mqttTopics?: Maybe<StringCatalog>;
  /**  The phone number of the currently authenticated user */
  phone?: Maybe<Scalars['String']['output']>;
  /**  The user name of the currently authenticated user */
  userName?: Maybe<Scalars['String']['output']>;
};

/**  suggested expiry: 1 hour */
export type UserChannelInfo = Cacheable & {
  __typename?: 'UserChannelInfo';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  indicator in the UI that the user has purchased a subscription license
   *  Note: CatchupTV and RestartTV flags are set on Event level
   *  @deprecated on 2017-11-02: use the "Channel.entitlements.liveTV" field instead
   */
  subscribed: Scalars['Boolean']['output'];
};

/**  note that a VODAsset does not have a "quality" field, which is actually linked to the purchasing process */
export type VodAsset = Cacheable & {
  __typename?: 'VODAsset';
  /**  since these images should fill up the entire screen, the client app knows the preferred dimensions */
  backgroundImage?: Maybe<Image>;
  /**  The content provider of the VOD asset */
  contentProvider?: Maybe<ContentProvider>;
  /**  duration in seconds */
  duration: Scalars['Int']['output'];
  /**
   *  Catalog that contains the entitlements that can be used to play this VOD Asset, with playback true.
   *  If the user isn't entitled to play the VOD Asset, this Catalog will be empty. This means entitlements
   *  with playback false will not be returned.
   *  The client can assume this Catalog will always be empty or contain exactly one VODAssetEntitlement.
   *  Later on we could support the use case in which the user purchased different Products for watching the
   *  Asset. In that case each Product purchase would lead to an additional Entitlement and the user can select
   *  which Entitlement should be used to play the asset. For now the back-office will return one random Entitlement
   *  in that case. For example, the user could have purchased 2 products that allow to watch the VOD Asset with 2
   *  people at the same time, meaning 4 people can watch at the same time. The users should be able to select
   *  which Product to use because otherwise only 2 people will be able to watch the Asset simultaneously.
   *  If the user can play different qualities of the Asset, the back-office will always return an entitlement for playback
   *  of the highest quality.
   */
  entitlements: VodAssetEntitlementCatalog;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  metadata: Metadata;
  /**  this is needed for parental control */
  parentalRating: ParentalRating;
  /**
   *  Contains a bookmark and this should always be set/available for all qualities.
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  personalInfo?: Maybe<PersonalVodInfo>;
  /**
   *  related content for this VODAsset, listed as rows on the "Detailed On Demand Video Info" screen
   *  this can contain content folders such as "More from", "More like", etc.
   *  the actual content can be defined by the operator (i.e. via the marketing console)
   *  it can be null if there is no related content available (for whatever reason)
   */
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  List of deeplinks to the 3rd party VOD Assets
   *  Can contain multiple links of different 3rd party apps, e.g.:
   *  https://www.netflix.com/title/70291117
   *  https://app.primevideo.com/detail?gti=amzn1.dv.gti.d8b5f3d9-97da-0995-3db2-2a873c78c9ac
   *  https://www.youtube.com/watch?v=vetqtTdUdmw
   *  The contentProvider field may NOT be used to determine the app, it should be derived by parsing the link itself.
   */
  thirdPartyLinks?: Maybe<StringCatalog>;
  /**
   *  with the height parameter, the client indicates the preferred height of the thumbnail image
   *  as described in the functional spec, VOD assets that are part of a series should have a landscape thumbnail
   *  VOD assets that are not part of a series, should have a portrait thumbnail
   */
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
  /**  if this product doesn't have a trailer, the catalog should have an empty array */
  trailers: TrailerCatalog;
};


/**  note that a VODAsset does not have a "quality" field, which is actually linked to the purchasing process */
export type VodAssetBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  note that a VODAsset does not have a "quality" field, which is actually linked to the purchasing process */
export type VodAssetPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  note that a VODAsset does not have a "quality" field, which is actually linked to the purchasing process */
export type VodAssetThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  Indicates if a certain Asset can be played by the user or not.
 *  suggested expiry: 5 minutes
 */
export type VodAssetEntitlement = Cacheable & {
  __typename?: 'VODAssetEntitlement';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  is this user entitled to play this VODAsset?
   *  or in other words: does he have an SVOD subscription or an active TVOD purchase that allows him/her to play this On Demand item?
   */
  playback: Scalars['Boolean']['output'];
  /**
   *  The back-office should calculate the longest available expiration, if the asset is part of multiple purchased products.
   *  This information is shown inside the "content row", see "Item representation" in the functional spec.
   *  This shall be null in one of two cases:
   *  1. the user doesn't have a playback entitlement
   *  2. this is an SVOD-only entitlement, in which case the expiration is not relevant and should not be shown to the user
   */
  playbackAvailableUntil?: Maybe<Scalars['Date']['output']>;
  /**
   *  The VOD Product that was purchased by the user to receive this Entitlement. Can be null since playback can be false.
   *  This relationship is needed for the use case in which the user purchased multiple Products for watching the same Asset since
   *  the titles of those Products could be shown (as described in VODAsset this use case isn't supported at this moment). The VideoQuality
   *  that can be played of this Asset can also be extracted from this Product. Notice that the Product might contain a VideoQuality of 4K
   *  while only an SD or HD version of the Asset exists.
   */
  product?: Maybe<VodProduct>;
  /**
   *  Whether this Entitlement is only temporarily available (during setup of a TVOD/EST-purchase) and will be replaced by a permanent
   *  Entitlement upon completion of the purchase
   */
  temporary: Scalars['Boolean']['output'];
};

/**
 *  we don't expect to have a huge amount of entitlements in this collection, therefore: no pagination required
 *  suggested expiry: 5 minutes
 */
export type VodAssetEntitlementCatalog = Cacheable & Catalog & {
  __typename?: 'VODAssetEntitlementCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<VodAssetEntitlement>>;
};

/**
 *  we define a specific type for VOD folders, so that we can also define specific fields, such as the thumbnail.
 *  a similar approach could be used for other "container" types, if we come across that use case.
 *  if everything was a ContentFolderList, this type would have a collection of all container-specific fields.
 */
export type VodFolder = Cacheable & {
  __typename?: 'VODFolder';
  /**  since these images should fill up the entire screen, the client app knows the preferred dimensions */
  backgroundImage?: Maybe<Image>;
  /**
   *  the contents of a VODFolder is certainly a ContentFolderList (not just a ContentFolder)
   *  because the user navigates 2 levels deeper when entering a VOD folder
   *  extract from the functional spec (STB):
   *  ---
   *  When the viewer navigates into a folder (and the folder contains a mix of items and folders), the application goes two levels deeper:
   *  1. The first row contains all the child assets (items present in the chosen folder).
   *  2. The child catalogs (folders present in the chosen folder) are shown as subsequent rows.
   *  3. The grandchildren assets/catalogs are shown as items within those rows.
   *  ---
   *  note that if a certain VODFolder doesn't have any sub-folders, the ContentFolderList shall contain just 1 ContentFolder, which contains all of the child items (assets, products, promos, etc.)
   *  the title of the ContentFolder is the title of the VODFolder (aka VOD node) in this case
   */
  content: ContentFolderList;
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**
   *  this must be null if "useParentFolder" is false
   *  if useParentFolder == true and parentContentFolderList == null (for whatever reason), the client should still use the default ContentFolderList relation
   */
  parentContentFolderList?: Maybe<ContentFolderList>;
  /**  this is needed for parental control */
  parentalRating: ParentalRating;
  /**  VOD folder thumbnails are always in landscape mode */
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
  /**
   *  we also have to take the following requirement into account:
   *  If the viewer navigates into a folder containing only items, only a single row would be displayed, which is not ideal in terms of usability. Therefore, in that case, the parent catalog is displayed and the targeted row is focused. For example:
   *  In row "Movies alphabetically" in the catalogue, the following folders are displayed: "A-C", "D-F", G-K", ...
   *  When selecting "D-F", a new page with only a single row containing only the items inside "D-F" would be displayed.
   *  In that case, a new page with the parent catalog ("Movies alphabetically") is displayed with the following rows:
   *  Row 1: the A-C catalog with all its items.
   *  Row 2: the D-F catalog with all its items, this row will be focused automatically.
   *  Row 3: the G-K catalog with all its items.
   *  in this case, the following Boolean will be true, so that the client knows that it should use the parentContentFolderListID instead of the "default" ContentFolderList relation
   */
  useParentFolder: Scalars['Boolean']['output'];
};


/**
 *  we define a specific type for VOD folders, so that we can also define specific fields, such as the thumbnail.
 *  a similar approach could be used for other "container" types, if we come across that use case.
 *  if everything was a ContentFolderList, this type would have a collection of all container-specific fields.
 */
export type VodFolderBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  we define a specific type for VOD folders, so that we can also define specific fields, such as the thumbnail.
 *  a similar approach could be used for other "container" types, if we come across that use case.
 *  if everything was a ContentFolderList, this type would have a collection of all container-specific fields.
 */
export type VodFolderThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**  note: there's no reason to cache this, so it's not a Cacheable */
export type VodPlaybackInfo = PlaybackInfo & {
  __typename?: 'VODPlaybackInfo';
  adPlaybackPreRoll?: Maybe<Scalars['Int']['output']>;
  adPlaybackRestrictions?: Maybe<Array<Maybe<AdPlaybackRestriction>>>;
  adSignallingType?: Maybe<Scalars['AdSignallingType']['output']>;
  /** # PlaybackInfo interface: */
  deliveryKind: ContentDeliveryKind;
  /**
   *  The "end of the episode" which triggers the display of the Episode-row (binge watching)
   *  This is a time in seconds before the end of the stream.
   *  Can be null if the VODAsset being played is not an episode
   */
  endOfEpisodeOffset?: Maybe<Scalars['Int']['output']>;
  heartbeat?: Maybe<Heartbeat>;
  playbackRestrictions?: Maybe<Array<Maybe<PlaybackRestriction>>>;
  rtspHeaders?: Maybe<RtspHeaders>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  trickplayRestrictions?: Maybe<Array<Maybe<TrickplayRestriction>>>;
  url: Scalars['String']['output'];
  /**  A reference to the asset that's being played. */
  vodAsset: VodAsset;
};

/**  suggested expiry: DEFAULT (this type mainly contains metadata, not user specific info) */
export type VodProduct = Cacheable & Product & {
  __typename?: 'VODProduct';
  /**  actors and directors names should be returned as "firstName lastName", e.g. "Brad Pitt" */
  actors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   *  for TVOD products, this is the list of assets you get access to when buying this TVOD product
   *  we currently don't support the scenario where the user can select a VOD asset from a list of assets when purchasing a product (i.e. the "n-out-of-m" scenario)
   *  can be null if this is not a TVOD product
   */
  availableAssets?: Maybe<ContentFolder>;
  backgroundImage?: Maybe<Image>;
  directors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entitlement?: Maybe<ProductEntitlement>;
  expiry: Scalars['Date']['output'];
  fullDescription: Scalars['String']['output'];
  /**  genre is not always applicable, which is why this is a nullable field */
  genre?: Maybe<Genre>;
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  kind: ProductKind;
  /**
   * # Specific:
   *  original title could be relevant for TVOD products
   *  if not applicable, this should be null
   */
  originalTitle?: Maybe<Scalars['String']['output']>;
  /**
   *  Whether this product is considered a package product.
   *  A package product is a concept in the functional specification for a product that either:
   *  - is an SVOD or FVOD product,
   *  - is an EST or TVOD product that contains more than a single asset (a single folder, series or season is still considered a package product).
   */
  packageProduct: Scalars['Boolean']['output'];
  /**  age rating is not always applicable, which is why this is a nullable field */
  parentalRating?: Maybe<ParentalRating>;
  /**  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead. */
  personalInfo?: Maybe<PersonalProductInfo>;
  /**
   *  Price of the product. Can be null if there is already an entitlement. If a product is not entitled,
   *  but a price could still not be determined by the back-office, a GraphQL error is thrown.
   */
  price?: Maybe<Price>;
  purchaseInfo?: Maybe<ProductPurchaseInfo>;
  relatedContent?: Maybe<ContentFolderList>;
  /**
   *  Optional deeplink URL for the package in the selfcare webapp.
   *  If specified (not null nor empty string), the client will open this URL in the browser instead of the client's native Detailed Package Info screen.
   */
  selfcareUrl?: Maybe<Scalars['String']['output']>;
  shortDescription: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  thumbnail?: Maybe<Image>;
  /** # Product interface: */
  title: Scalars['String']['output'];
  trailers: TrailerCatalog;
  /**
   *  Identifies which video qualities (SD, HD, 4K) can be played when purchasing this Product. Notice that purchasing
   *  a product with a certain video quality (e.g. 4K) also permits to watch Assets with lower qualities (e.g. HD and SD).
   *  Can be null when this Product doesn't put any constraints on the qualities that can be played, i.e. all available
   *  qualities can be played.
   */
  videoQuality?: Maybe<VideoQuality>;
};


/**  suggested expiry: DEFAULT (this type mainly contains metadata, not user specific info) */
export type VodProductBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**  suggested expiry: DEFAULT (this type mainly contains metadata, not user specific info) */
export type VodProductPersonalInfoArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**  suggested expiry: DEFAULT (this type mainly contains metadata, not user specific info) */
export type VodProductShortDescriptionArgs = {
  maxLength?: InputMaybe<Scalars['Int']['input']>;
};


/**  suggested expiry: DEFAULT (this type mainly contains metadata, not user specific info) */
export type VodProductThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  we don't expect to have a huge amount of products in this collection, therefore: no pagination required
 *  suggested expiry: 5 minutes
 */
export type VodProductCatalog = Cacheable & Catalog & {
  __typename?: 'VODProductCatalog';
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /** # Catalog interface: */
  itemCount: Scalars['Int']['output'];
  items: Array<Maybe<VodProduct>>;
};

/**
 *  a VODSeries object could represent either:
 *  * an entire series (with or without season grouping)
 *  * a collection of seasons of that series
 *  * a single season
 */
export type VodSeries = Cacheable & {
  __typename?: 'VODSeries';
  /**  since these images should fill up the entire screen, the client app knows the preferred dimensions */
  backgroundImage?: Maybe<Image>;
  /**
   *  the contents of this folder and its sorting are defined in the functional spec (chapter Detailed On Demand Series Info):
   *  3. d. Episode-row, which contains all the episodes of all seasons of the displayed series.
   *  Since this depends on the entry-point (the item which is selected to open this screen), it can be the episodes of a single season or multiple seasons.
   *  (see further details in the spec regarding the inclusion of one or more VOD packages in this ContentFolder)
   *  4. The episodes are sorted, based on the season information; the 1st episode of the 1st season first. The same applies for the seasons in the Season-selector; 1st season first, then the 2nd season, and so on.
   */
  content: ContentFolder;
  /**
   *  there are very specific rules for the default displayed item when going into the Detailed On Demand Series Info screen
   *  see functional spec chapter "Detailed On Demand Series Info", item "5. The default displayed item when opening Detailed On Demand Series Info varies: ..."
   *  note that this chapter also states that there may be one or more VOD packages (aka products) present at the start of the episode row
   *  in other words: the first n ContentItems in the linked ContentFolder could be VODProducts instead of VODAssets
   *  Parameter 'profileId' was @deprecated on 2020-07-10: the profileId property is no longer used (even if provided). The id of the active profile will be used instead.
   */
  edgeForActiveEpisode: ContentFolderContentItemsEdge;
  /**
   *  when the user selects a season number, this function can be used to "jump" to the correct episode
   *  by returning the Edge, we have access to the ContentItem (node, e.g. VODAsset) and the cursor which is required for pagination (fetch next/previous x items)
   *  @deprecated on 2018-04-24: use "vodSeries" query instead with 'items' field and includeCursor: true
   */
  edgeForSeason: ContentFolderContentItemsEdge;
  /**
   *  the total amount of all episodes in this series object
   *  see "Indication of number of episodes" in the functional spec
   */
  episodeCount: Scalars['Int']['output'];
  expiry: Scalars['Date']['output'];
  /** # Cacheable interface: */
  id: Scalars['ID']['output'];
  /**  this is needed for parental control */
  parentalRating: ParentalRating;
  /**
   *  related content for this VODSeries, listed as rows on the "Detailed On Demand Series Info" screen
   *  this can contain content folders such as More from", "More like", etc.
   *  the actual content can be defined by the operator (i.e. via the marketing console)
   *  it can be null if there is no related content available (for whatever reason)
   */
  relatedContent?: Maybe<ContentFolderList>;
  /**  this returns an array of SeasonsInfo objects that can be used when 'jumping' towards a specific season using the season selector. */
  seasonInfos?: Maybe<Array<Maybe<SeasonInfo>>>;
  /**
   *  a series is not necessarily grouped into seasons, so this could be null
   *  for a single season, this array will contain just 1 element
   *  @deprecated on 2018-07-09: use seasonInfos instead
   */
  seasonNumbers?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /**  always landscape */
  thumbnail?: Maybe<Image>;
  title: Scalars['String']['output'];
};


/**
 *  a VODSeries object could represent either:
 *  * an entire series (with or without season grouping)
 *  * a collection of seasons of that series
 *  * a single season
 */
export type VodSeriesBackgroundImageArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


/**
 *  a VODSeries object could represent either:
 *  * an entire series (with or without season grouping)
 *  * a collection of seasons of that series
 *  * a single season
 */
export type VodSeriesEdgeForActiveEpisodeArgs = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


/**
 *  a VODSeries object could represent either:
 *  * an entire series (with or without season grouping)
 *  * a collection of seasons of that series
 *  * a single season
 */
export type VodSeriesEdgeForSeasonArgs = {
  seasonNumber: Scalars['Int']['input'];
};


/**
 *  a VODSeries object could represent either:
 *  * an entire series (with or without season grouping)
 *  * a collection of seasons of that series
 *  * a single season
 */
export type VodSeriesThumbnailArgs = {
  height: Scalars['Int']['input'];
};

/**
 *  this is for development information purposes only!
 *  this query should not be used in production code,
 *  nor should it be used to associate any form of business logic with the response!
 */
export type VersionInfo = {
  __typename?: 'VersionInfo';
  /**
   *  "generic" field that will contain information about the GraphQL end-point
   *  could contain information about: API version, what's (not) supported, build date, etc.
   */
  description: Scalars['String']['output'];
};

export enum VideoQuality {
  Hd = 'HD',
  Sd = 'SD',
  /**  Ultra-HD (4K) */
  Uhd = 'UHD'
}

export enum VideoQualityLimitKind {
  BestEffort = 'BEST_EFFORT',
  BestQuality = 'BEST_QUALITY',
  BetterQuality = 'BETTER_QUALITY',
  GoodQuality = 'GOOD_QUALITY'
}

/**  restriction that limits playback to a maximum resolution */
export type VideoQualityPlaybackRestriction = PlaybackRestriction & {
  __typename?: 'VideoQualityPlaybackRestriction';
  /** # PlaybackRestriction interface */
  message: Scalars['String']['output'];
  /**  video quality limitation */
  quality: VideoQuality;
};
