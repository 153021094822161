import React from 'react';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Subscribable } from 'App/Packages/Subscribable';
import { Card } from 'App/Packages/UI/Card';
import { FolderItem } from 'App/Packages/UI/Folder/Modules/FolderItem';
import { FolderItemProps } from 'App/Packages/UI/Folder/Modules/FolderItem/Types';

type FolderCardMoreInternalProps = React.PropsWithChildren<{
    isLarge?: boolean;
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
}>;

const FolderCardMoreInternal: React.FunctionComponent<FolderCardMoreInternalProps> = (props) => {
    const item = FolderItem.use();
    const isFocused = Subscribable.use(item.focused);

    const onFocus = (id: string) => {
        item.scrollIntoView();
        if (props.onFocus) props.onFocus(id);
    };

    const { onClick, onHover } = useFocusableHook({
        id: item.id,
        ctx: item.ctx,
        isFocused,
        onEnter: item.enter,
        onHover: item.focus,
        onBlur: props.onBlur,
        onFocus,
    });

    return (
        <Card.More
            ref={item.ref}
            className="folder-item"
            isFocused={isFocused}
            isLarge={props.isLarge}
            onClick={onClick}
            onHover={onHover}
        />
    );
};

export type FolderCardMoreProps = FolderCardMoreInternalProps & FolderItemProps;

export type FolderCardMoreComponent = React.FunctionComponent<FolderCardMoreProps>;

export const FolderCardMore: FolderCardMoreComponent = (props) => (
    <FolderItem id={props.id} offset={props.offset} onEnter={props.onEnter}>
        <FolderCardMoreInternal isLarge={props.isLarge} onBlur={props.onBlur} onFocus={props.onFocus}>
            {props.children}
        </FolderCardMoreInternal>
    </FolderItem>
);
