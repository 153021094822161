import { actions as A } from 'App/Modules/Data/Detail/VodAsset/Store/actions';
import { detailVodAssetReducer } from 'App/Modules/Data/Detail/VodAsset/Store/reducer';

export { initialVodAssetDetailReducerState as initialState } from 'App/Modules/Data/Detail/VodAsset/Store/initialState';
export { selectors } from 'App/Modules/Data/Detail/VodAsset/Store/selectors';
export { Api } from 'App/Modules/Data/Detail/VodAsset/Api';

export const reducer = detailVodAssetReducer;
export const actions = A.public;
export type { VodAssetState as State, ReduxAction } from 'App/Modules/Data/Detail/VodAsset/Types';
