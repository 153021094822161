import { initialCommunityState } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/constants';
import { communityMapper } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/mapper';
import { CommunityActionType, CommunityEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const communityReducer: Reducer<CommunityEntityTable> = (state, action) => {
    if (!state) return initialCommunityState;

    switch (action.type) {
        case CommunityActionType.LOADED: {
            const ids = communityMapper.toIds(action.payload.communities);
            const entities = communityMapper.toEntities(action.payload.communities);
            return { ...state, ids, entities };
        }
        case CommunityActionType.CHANGED:
            if (state.selected === action.payload.communityId) return state;
            return { ...state, selected: action.payload.communityId };
        default:
            return state;
    }
};
