import { ImageFlavour } from '__SMART_APP_OLD__/api/graphql/types';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { formatDateTime, formatDayName, getFormattedDuration, getServerTime } from '__SMART_APP_OLD__/utils/timeUtils';
import translate from 'language/translate';

export default class Asset {
    rawData: any;

    factoryFunc: any = false;

    _shouldMask: boolean = false;

    _shouldRestrict: boolean = false;

    _programType: string = '';

    _attachments: any = null;

    constructor(data: any, factoryFunc: any) {
        this.rawData = data;
        this.factoryFunc = factoryFunc;
    }

    get isPlaybackAvailable(): any {
        return false;
    }

    get catchupTV(): boolean {
        return false;
    }

    get programType(): string {
        return this._programType;
    }

    set programType(programType: string) {
        this._programType = programType;
    }

    get isReplayAvailable(): boolean {
        return false;
    }

    get leadIn(): number {
        return 0;
    }

    get actors() {
        return this.rawData?.metadata?.actors || [];
    }

    get ageRatingRank() {
        return this.rawData?.parentalRating?.rank;
    }

    // used on program preview
    get availableContentMarkers(): string[] {
        return [];
    }

    get backendAssetType() {
        return this.rawData?.__typename || null;
    }

    get channelId() {
        return this.rawData?.channel?.id;
    }

    get channelLogo() {
        return {
            [ImageFlavour.Normal]: this.rawData?.channel?.[ImageFlavour.Normal]?.url,
            [ImageFlavour.Inverted]: this.rawData?.channel?.[ImageFlavour.Inverted]?.url,
        };
    }

    get channelName() {
        return this.rawData?.channel?.title;
    }

    get country() {
        return this.rawData?.metadata?.country;
    }

    get description() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_DESCRIPTION');
        }

        if (this.seasonAndEpisode) {
            const description = [];
            description.push(this.seasonAndEpisode);
            description.push(this.rawData?.metadata?.fullDescription);
            return description.join(' ');
        }
        return this.rawData?.metadata?.fullDescription;
    }

    get seriesId() {
        return this.rawData?.content?.items?.edges[0]?.node?.id;
    }

    get descriptionShort() {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_DESCRIPTION');
        }

        return this.rawData?.metadata?.shortDescription;
    }

    get directors() {
        return this.rawData?.metadata?.directors || [];
    }

    get duration() {
        return this.rawData?.duration || 0;
    }

    get endDateTime() {
        return new Date();
    }

    get episodeOrdinal() {
        return this.rawData?.metadata?.episodeInfo?.number;
    }

    get episodeTitle() {
        return this.rawData?.metadata?.episodeInfo?.title || '';
    }

    get favourited() {
        return this.rawData?.personalInfo?.favourited;
    }

    get galleryMetaInfo() {
        const metaInfo = [];

        if (this.country) {
            metaInfo.push(this.country);
        }

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres.split(2));
        }

        if (this.duration) {
            metaInfo.push(getFormattedDuration(this.duration));
        }
        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get genres() {
        return this.rawData?.metadata?.genre?.title;
    }

    get id() {
        return this.rawData?.id;
    }

    get image() {
        return this.rawData?.thumbnail?.url;
    }

    get isAdult() {
        return !!this.rawData?.parentalRating?.adult;
    }

    // used for epg program preview and detail pages
    get landscapeImage() {
        return this.rawData?.backgroundImage?.url;
    }

    // Confluence: Channel Name HD | Date | Start-End time | Year | Genre | IMDB Rating
    get programPreviewMetaInfo() {
        const metaInfo = [];

        if (!this.channelLogo[ImageFlavour.Normal] && !this.channelLogo[ImageFlavour.Inverted] && this.channelName) {
            metaInfo.push(this.channelName);
        }
        metaInfo.push(this.shortMetaInfo);

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get releaseYear() {
        return this.rawData?.metadata?.year;
    }

    get seasonAndEpisode() {
        const seasonAndEpisode = [];
        const season = this.seasonOrdinal;
        const episode = this.episodeOrdinal;

        if (season) {
            seasonAndEpisode.push(`${translate('HOVER_SEASON')}${season}`);
        }

        if (episode) {
            seasonAndEpisode.push(`${translate('HOVER_EPISODE')}${episode}`);
        }

        return seasonAndEpisode.join(' ');
    }

    get seasonOrdinal() {
        return this.rawData?.metadata?.episodeInfo?.season;
    }

    get shortMetaInfo() {
        return '';
    }

    get shouldMask() {
        return this._shouldMask;
    }

    set shouldMask(shouldMask) {
        this._shouldMask = shouldMask;
    }

    get shouldRestrict() {
        return this._shouldRestrict;
    }

    set shouldRestrict(shouldRestrict) {
        this._shouldRestrict = shouldRestrict;
    }

    get startDateTime() {
        return new Date();
    }

    get subtitle() {
        let subtitle;

        if (this.seasonAndEpisode && this.episodeTitle) {
            subtitle = `${this.seasonAndEpisode} ${this.episodeTitle}`;
        } else subtitle = null;

        return subtitle;
    }

    get title(): string {
        if (this.shouldMask) {
            return translate('PARENTAL_CONTROL_MASKED_TITLE');
        }
        return this.rawData?.title;
    }

    get titlePlayer() {
        return this.rawData?.title;
    }

    get utcEndTime() {
        return getServerTime(this.rawData.end);
    }

    get utcStartTime() {
        return getServerTime(this.rawData.start);
    }

    getTimeStampInfo(dayFormat: string = 'DD dd MMM') {
        const info = [];
        const date = formatDayName(this.startDateTime, dayFormat, '');
        const hours = `${formatDateTime(this.startDateTime, 'HH:mm')} - ${formatDateTime(this.endDateTime, 'HH:mm')}`;

        info.push(`${date}`);
        info.push(`${hours}`);
        return info.join('&nbsp; I&nbsp; ');
    }

    get bookmark() {
        return Bookmarks.selectors.selectEntity(this.id)(store.getState());
    }

    get progress() {
        let progress;

        if (this.bookmark) progress = ((this.bookmark?.position ?? 0) * 100) / this.duration;
        else progress = 0;

        if (progress >= 100) {
            progress = 0;
        }

        return progress;
    }

    get relatedContent() {
        const relatedContent = this.rawData?.relatedContent;
        if (relatedContent) {
            const edges: any[] = relatedContent.folders?.edges || [];
            return edges.map((edge) => ({
                assets: edge?.node?.firstItems?.edges?.map((item: any) => this.factoryFunc(item?.node, item?.node?.__typename)),
                type: edge?.node?.kind,
                title: edge?.node?.title,
                stripeId: edge?.node?.id,
            }));
        }

        return [];
    }

    set attachedAssets(assets: any) {
        this._attachments = assets;
    }

    get attachedAssets() {
        return this._attachments;
    }
}
