import { selectIsNotificationsActive } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Alert } from 'App/Modules/Alert';
import { Keyboard } from 'App/Modules/Keyboard';
import { OverlayEntity, OverlayType } from 'App/Modules/Overlay/Types';
import { Prompt } from 'App/Modules/Prompt';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';

const select = (state: State): OverlayEntity | null => state.overlay;

const selectTyped =
    <T extends OverlayType>(type: T) =>
    (state: State): OverlayEntity<T> | null => {
        const overlay = select(state);
        if (!overlay) return null;
        if (overlay.type !== type) return null;
        return overlay as OverlayEntity<T>;
    };

const selectType = (state: State): OverlayType | null => select(state)?.type ?? null;

const selectIsLayerActive = (state: State): boolean =>
    !Keyboard.selectors.selectIsActive(state) &&
    !Alert.selectors.selectIsActive(state) &&
    !selectIsNotificationsActive(state) &&
    !Prompt.selectors.selectIsActive(state);

const selectIsActive = (state: State): boolean => {
    if (!state.overlay) return false;
    if (state.overlay.noFocus) return false;
    return selectIsLayerActive(state);
};

const selectCtx = (state: State): string => select(state)?.ctx ?? FocusDefault;

const selectIsSplashVisible = (state: State): boolean => selectType(state) === OverlayType.Splash;

export const selectors = {
    public: {
        select,
        selectCtx,
        selectType,
        selectTyped,
        selectIsActive,
        selectIsLayerActive,
        splash: {
            selectIsVisible: selectIsSplashVisible,
        },
    },
    private: {},
} as const;
