import React from 'react';

type Props = {
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
    center?: boolean;
};

export type ChannelCardContainerModule = React.FunctionComponent<React.PropsWithChildren<Props>>;

const getStyleModifiers = (props: Props) => {
    const modifiers = [
        props.top && '--top',
        props.bottom && '--bottom',
        props.left && '--left',
        props.right && '--right',
        props.center && '--center',
    ].filter(Boolean);

    return modifiers.map((value) => `channel-card-v2__container${value}`).join(' ');
};

export const ChannelCardContainer: ChannelCardContainerModule = (props) => {
    const modifiers = getStyleModifiers(props);
    return <div className={`channel-card-v2__container ${modifiers || ''}`}>{props.children}</div>;
};
