import React from 'react';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonManageRecordingsProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonManageRecordingsProps> = (props) => {
    return (
        <Folder.Detail.Action id="manage-series" onEnter={props.onEnter}>
            SCREEN_DETAIL_MANAGE_SERIES_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonManageRecordings = hidable(Button);

export type DetailButtonManageRecordingsModule = typeof DetailButtonManageRecordings;
