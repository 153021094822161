import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    BrandType,
    ConnectionType,
    DeviceName,
    GeneralDeviceType,
    NetworkState,
    PlatformInterface,
    TransportType,
} from '__SMART_APP_OLD__/app/platform/types';

import { Env } from 'App/Env';

const notImplementedFunction = () => {
    throw new Error('NOT_IMPLEMENTED');
};

export const getConnectionType = (): Promise<ConnectionType> =>
    Promise.resolve(navigator.onLine ? ConnectionType.ETHERNET : ConnectionType.DISCONNECTED);

export const getTransportType = (): Promise<TransportType> =>
    Promise.resolve(navigator.onLine ? TransportType.ETHERNET : TransportType.OFFLINE);

export const getNetworkState = (): Promise<NetworkState> => getTransportType().then((transport) => ({ ipAddress: '0.0.0.0', transport }));

export const networkStateChangeHandlerGenerator = async (platform: PlatformInterface) => {
    platform.IS_ON_LINE = navigator.onLine;
    const state = await platform.getNetworkState();
    const networkState = { current: state, prev: state };
    return async () => {
        networkState.prev = networkState.current;
        networkState.current = await platform.getNetworkState();
        platform.IS_ON_LINE = navigator.onLine;
        platform.onNetworkStateChange(networkState.current, networkState.prev);
    };
};

export const attachNetworkStateListener = async (platform: PlatformInterface) => {
    const networkStateChangeHandler = await networkStateChangeHandlerGenerator(platform);
    window.addEventListener('offline', networkStateChangeHandler);
    window.addEventListener('online', networkStateChangeHandler);
};

export const loadDeviceSDK = (src: string, onLoad: (r: () => void) => void = (r) => r()): Promise<void> =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
            console.log(onLoad);
            console.log(src);
            onLoad(resolve);
        };
        script.onerror = reject;
        script.src = src;
        document.getElementsByTagName('head')[0].appendChild(script);
    });

export const buildHeadersForConfigService = (platform: PlatformInterface) => {
    platform.CONFIG_SERVICE_HEADERS.Accept = 'application/json';
    platform.CONFIG_SERVICE_HEADERS['x-device-type'] = 'smart_tv';
    platform.CONFIG_SERVICE_HEADERS['x-client-app-version'] = Env.Version;
    platform.CONFIG_SERVICE_HEADERS['x-operating-system-version'] = Env.Version;
    platform.CONFIG_SERVICE_HEADERS['x-operating-system'] = 'tizen'; // we use tizen for all devices
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isVersion = (version: string): boolean => false;

export const buildDefaultPlatform = (): PlatformInterface => ({
    READY: Promise.resolve(),
    IS_ON_LINE: navigator.onLine,
    SYSTEM_LOCALE: navigator.language.toLowerCase() || 'en',
    DEVICE_TYPE: GQL.DeviceType.SmartTvTvos,
    GENERAL_DEVICE_TYPE: GeneralDeviceType.PC,
    DEVICE_NAME: DeviceName.PC,
    MANUFACTURER: 'n/a',
    ID: 'n/a',
    OS: 'n/a',
    OS_VERSION: 'n/a',
    MODEL: 'n/a',
    CONFIG_SERVICE_HEADERS: {},
    BRAND: BrandType.GOOGLE,
    onNetworkStateChange: notImplementedFunction,
    getConnectionType,
    getTransportType,
    getNetworkState,
    isVersion,
});
