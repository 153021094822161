import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';

const getClassName = (props: CardLandscapeIconProps) => {
    const classlist = ['landscape-card-v3__icon'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeIconProps = {
    className?: string;
    flavour?: IconFlavour;
    type?: IconType;
    isFocused?: boolean;
    name: IconName;
};

export type CardLandscapeIconComponent = React.FunctionComponent<CardLandscapeIconProps>;

export const CardLandscapeIcon: CardLandscapeIconComponent = (props) => <Icon {...props} className={getClassName(props)} />;
