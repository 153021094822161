import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { initialState } from 'App/Modules/Overlay/Store/initialState';
import { OverlayActionType, OverlayState } from 'App/Modules/Overlay/Types';

export const reducer: Reducer<OverlayState> = (state, action) => {
    if (state === undefined) return initialState;

    switch (action.type) {
        case OverlayActionType.Changed:
            if (!action.payload.overlay) return null;
            return action.payload.overlay;
        default:
            return state;
    }
};
