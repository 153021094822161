/* eslint-disable complexity */
import { ContentItemType, FavouritableItemKind } from '__SMART_APP_OLD__/api/graphql/types';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { getServerTime, isFuture, secondsToTime } from '__SMART_APP_OLD__/utils/timeUtils';
import { DETAILS_SUBTITLES, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';
import { ProductOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { getVideoQuality } from '__SMART_APP_OLD__/utils/dataUtils';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { DetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/detailItem/detailItem.generated';
import { RecordingDetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/recording/recordingDetailItem.generated';
import { EventDetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/event/eventDetailItem.generated';
import { SeriesDetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/series/seriesDetailitem.generated';
import { VodAssetDetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/vodAsset/vodAssetDetailItem.generated';
import { VodProductDetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/vodProduct/vodProductDetailItem.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';

export const toDetailItem = (data: DetailItemFragment | null, products?: ProductOption[]) => {
    if (!data) return null;

    switch (data.__typename) {
        case 'NetworkRecording':
            return toRecordingDetailItem(data);
        case 'Event':
            return toEventDetailItem(data);
        case 'VODAsset':
            return toVodAssetDetailItem(data, products ?? []);
        case 'Series':
            return toSeriesDetailItem(data);
        case 'VODProduct':
            return toVodProductDetailItem(data);
        default:
            return null;
    }
};

const toRecordingDetailItem = (data: RecordingDetailItemFragment): DetailItem => {
    return {
        id: data.id,
        eventId: data.event.id,
        type: ContentItemType.Networkrecording,
        content: {
            title: data.event.title,
            thumbnail: data.event.thumbnail?.url ?? '',
            backgroundImage: data.event.backgroundImage?.url ?? '',
            channelId: data.event.channel?.id ?? '',
            info: getTimeStampInfo(new Date(data.event.start).getTime(), new Date(data.event.end).getTime()),
            description: data.event.metadata.fullDescription ?? '',
            shortDescription: data.event.metadata.shortDescription ?? '',
        },
        metadata: {
            parentalRatingId: data.event.parentalRating?.id ?? '',
            audioLanguages: (data.event.metadata.audioLanguages?.filter(Boolean) as string[]) ?? [],
            subtitleLanguages: (data.event.metadata.subtitleLanguages?.filter(Boolean) as string[]) ?? [],
            providerId: data.event.channel?.id ?? undefined,
            duration: (new Date(data.event.end).getTime() - new Date(data.event.start).getTime()) / 1000,
            start: new Date(data.event.start).getTime(),
            end: new Date(data.event.end).getTime(),
            sessionType: PinSessionType.PIN_LIVE_TV,
            favouriteItemType: FavouritableItemKind.NetworkRecording,
        },
        entitlements: {
            status: data.status ?? '',
            dolby: data.event.streamInfo.dolby ?? false,
            isVisuallyImpaired: data.event.streamInfo.visuallyImpaired ?? false,
            restartTV: data.event.entitlements?.restartTV ?? false,
            pauseLiveTV: data.event.entitlements?.pauseLiveTV ?? false,
            playback: data.status === GQL.RecordingStatus.Completed,
            playbackAvailableUntil: 0,
            catchupTV: data.event.entitlements?.catchupTV ?? false,
            recording: true,
            catchupTVAvailableUntil: new Date(data.event.entitlements?.catchupTVAvailableUntil ?? 0).getTime(),
            networkRecording: data.event.entitlements?.networkRecording ?? false,
            networkRecordingPlannableUntil: new Date(data.event.entitlements?.networkRecordingPlannableUntil ?? 0).getTime(),
            programType: ProgramType.RECORDING,
        },
        visualization: {
            isEligibleForPortraitCard: false,
            isEligibleForLandscapeCard: true,
            isEligibleForChannelCard: false,
            isEligibleForFolderCard: false,
            isEligibleForBannerCard: false,
        },
    };
};

// eslint-disable-next-line react-func/max-lines-per-function
const toEventDetailItem = (data: EventDetailItemFragment): DetailItem => {
    const isCatchupTv = !!data.entitlements?.catchupTV && isFuture(data.entitlements.catchupTVAvailableUntil) && !isFuture(data.start);
    const programType = getProgramType(data.start, data.end);
    const isLive = programType === ProgramType.LIVE;
    const isPlaybackAvailable = (isCatchupTv || isLive) && !isFuture(data.start);

    return {
        id: data.id,
        type: ContentItemType.Event,
        content: {
            title: data.title,
            thumbnail: data.thumbnail?.url ?? '',
            backgroundImage: data.backgroundImage?.url ?? '',
            channelId: data.channel?.id ?? '',
            info: getTimeStampInfo(new Date(data.start).getTime(), new Date(data.end).getTime()),
            seasonInfo: getEventSeasonMetaInfo(data),
            description: data.metadata.fullDescription ?? '',
            shortDescription: data.metadata.shortDescription ?? '',
        },
        metadata: {
            parentalRatingId: data.parentalRating?.id ?? '',
            audioLanguages: (data.metadata.audioLanguages?.filter(Boolean) as string[]) ?? [],
            subtitleLanguages: (data.metadata.subtitleLanguages?.filter(Boolean) as string[]) ?? [],
            providerId: data.channel?.id ?? undefined,
            duration: (new Date(data.end).getTime() - new Date(data.start).getTime()) / 1000,
            start: new Date(data.start).getTime(),
            end: new Date(data.end).getTime(),
            sessionType: PinSessionType.PIN_LIVE_TV,
            favouriteItemType: FavouritableItemKind.Event,
            actors: (data.metadata.actors?.filter(Boolean) as string[]) ?? [],
            directors: (data.metadata.directors?.filter(Boolean) as string[]) ?? [],
            episodeInfo: getEpisodeInfo(data),
        },
        entitlements: {
            status: '',
            dolby: data.streamInfo.dolby ?? false,
            isVisuallyImpaired: data.streamInfo.visuallyImpaired ?? false,
            restartTV: data.entitlements?.restartTV ?? false,
            pauseLiveTV: data.entitlements?.pauseLiveTV ?? false,
            playback: isPlaybackAvailable,
            playbackAvailableUntil: 0,
            catchupTV: data.entitlements?.catchupTV ?? false,
            recording: false,
            catchupTVAvailableUntil: new Date(data.entitlements?.catchupTVAvailableUntil ?? 0).getTime(),
            networkRecording: data.entitlements?.networkRecording ?? false,
            networkRecordingPlannableUntil: new Date(data.entitlements?.networkRecordingPlannableUntil ?? 0).getTime(),
            programType,
        },
        visualization: {
            isEligibleForPortraitCard: false,
            isEligibleForLandscapeCard: true,
            isEligibleForChannelCard: false,
            isEligibleForFolderCard: false,
            isEligibleForBannerCard: false,
        },
    };
};

// eslint-disable-next-line react-func/max-lines-per-function
const toVodAssetDetailItem = (data: VodAssetDetailItemFragment, products: ProductOption[]): DetailItem => {
    return {
        id: data.id,
        type: ContentItemType.Vodasset,
        content: {
            title: data.title,
            thumbnail: data.thumbnail?.url ?? '',
            backgroundImage: data.backgroundImage?.url ?? '',
            channelId: '',
            info: getVodAssetMeta(data),
            seasonInfo: getVodAssetMeta(data),
            description: data.metadata.fullDescription ?? '',
            shortDescription: data.metadata.shortDescription ?? '',
        },
        metadata: {
            parentalRatingId: data.parentalRating?.id ?? '',
            audioLanguages: (data.metadata.audioLanguages?.filter(Boolean) as string[]) ?? [],
            subtitleLanguages: (data.metadata.subtitleLanguages?.filter(Boolean) as string[]) ?? [],
            providerId: undefined,
            duration: data.duration ?? 0,
            sessionType: PinSessionType.PIN,
            favouriteItemType: FavouritableItemKind.VodAsset,
            videoQuality: getVideoQuality({
                upsellProducts: {
                    items: [...products, ...data.vodAssetEntitlements.items.map((item) => item?.product)],
                },
            }),
            actors: (data.metadata.actors?.filter(Boolean) as string[]) ?? [],
            directors: (data.metadata.directors?.filter(Boolean) as string[]) ?? [],
            hasTrailer: data.trailers.itemCount > 0,
            episodeInfo: getEpisodeInfo(data),
            seasonOrdinal: data.metadata.episodeInfo?.season ?? null,
            episodeOrdinal: data.metadata.episodeInfo?.number ?? null,
        },
        entitlements: {
            status: '',
            dolby: false,
            isVisuallyImpaired: false,
            restartTV: false,
            pauseLiveTV: false,
            playback:
                data.vodAssetEntitlements?.items.some((entitlement: any) => entitlement.playback) ||
                data.vodAssetEntitlements?.items.some((entitlement: any) => entitlement.playback),
            playbackAvailableUntil: new Date(data.vodAssetEntitlements.items[0]?.playbackAvailableUntil ?? 0).getTime(),
            catchupTV: false,
            recording: false,
            catchupTVAvailableUntil: 0,
            networkRecording: false,
            networkRecordingPlannableUntil: 0,
            programType: ProgramType.VOD,
        },
        visualization: {
            isEligibleForPortraitCard: true,
            isEligibleForLandscapeCard: false,
            isEligibleForChannelCard: false,
            isEligibleForFolderCard: false,
            isEligibleForBannerCard: false,
        },
    };
};

const toSeriesDetailItem = (data: SeriesDetailItemFragment): DetailItem => {
    return {
        id: data.id,
        type: ContentItemType.Series,
        content: {
            title: data.title,
            thumbnail: data.thumbnail?.url ?? '',
            backgroundImage: data.backgroundImage?.url ?? '',
            description: '',
            shortDescription: '',
            info: '',
        },
        metadata: {
            audioLanguages: [],
            subtitleLanguages: [],
            favouriteItemType: null,
            parentalRatingId: data.parentalRating.id,
            sessionType: PinSessionType.PIN,
        },
        entitlements: {
            status: '',
            dolby: false,
            isVisuallyImpaired: false,
            restartTV: false,
            pauseLiveTV: false,
            catchupTV: false,
            recording: false,
            catchupTVAvailableUntil: 0,
            networkRecording: false,
            networkRecordingPlannableUntil: 0,
            programType: null,
            playback: false,
            playbackAvailableUntil: 0,
        },
        visualization: {
            isEligibleForPortraitCard: false,
            isEligibleForLandscapeCard: true,
            isEligibleForChannelCard: false,
            isEligibleForFolderCard: false,
            isEligibleForBannerCard: false,
        },
    };
};

const toVodProductDetailItem = (data: VodProductDetailItemFragment): DetailItem => {
    return {
        id: data.id,
        type: ContentItemType.Vodproduct,
        content: {
            title: data.title,
            thumbnail: data.thumbnail?.url ?? '',
            backgroundImage: data.backgroundImage?.url ?? '',
            description: data.fullDescription,
            shortDescription: data.shortDescription,
            info: '',
        },
        metadata: {
            audioLanguages: [],
            subtitleLanguages: [],
            actors: (data.actors?.filter(Boolean) as string[]) ?? [],
            directors: (data.directors?.filter(Boolean) as string[]) ?? [],
            favouriteItemType: null,
            parentalRatingId: data.productParentalRating?.id ?? '',
            sessionType: PinSessionType.PIN,
        },
        entitlements: {
            status: '',
            dolby: false,
            isVisuallyImpaired: false,
            restartTV: false,
            pauseLiveTV: false,
            catchupTV: false,
            recording: false,
            catchupTVAvailableUntil: 0,
            networkRecording: false,
            networkRecordingPlannableUntil: 0,
            programType: null,
            playback: false,
            playbackAvailableUntil: 0,
        },
        visualization: {
            isEligibleForPortraitCard: true,
            isEligibleForLandscapeCard: false,
            isEligibleForChannelCard: false,
            isEligibleForFolderCard: false,
            isEligibleForBannerCard: false,
        },
    };
};

// Helpers
const getVodAssetMeta = (item: VodAssetDetailItemFragment): string => {
    const entries = [];

    if (item.metadata?.genre?.title) entries.push(item.metadata.genre.title);
    if (item.duration) entries.push(secondsToTime(item.duration).split('&nbsp;').join('\xa0'));
    if (item.metadata.subtitleLanguages?.length) {
        entries.push(`${translate(DETAILS_SUBTITLES)}: ${item.metadata.subtitleLanguages.slice(0, 3)?.join(', ').toUpperCase()}`);
    }
    if (item.metadata.country) entries.push(item.metadata.country);
    if (item.metadata?.year) entries.push(item.metadata.year);

    return entries.join('\xa0\xa0I\xa0\xa0');
};

const getEpisodeInfo = (item: VodAssetDetailItemFragment | EventDetailItemFragment) => {
    const { episodeInfo } = item.metadata;
    if (!episodeInfo) return '';
    return `${translate('HOVER_SEASON')}${episodeInfo.season} ${translate('HOVER_EPISODE')}${item.metadata.episodeInfo?.number} ${episodeInfo.title}`;
};

const getEventSeasonMetaInfo = (item: EventDetailItemFragment) => {
    const metaInfo = [];

    metaInfo.push(getTimeStampInfo(new Date(item.start).getTime(), new Date(item.end).getTime()));

    if (item.metadata.year) {
        metaInfo.push(item.metadata.year);
    }

    if (item.metadata.genre?.title) {
        metaInfo.push(item.metadata.genre.title);
    }

    return metaInfo.join('\xa0\xa0I\xa0\xa0');
};

const getProgramType = (start: string, end: string): string => {
    const currentDate = getServerTime();
    const currentTime = currentDate.getTime();

    const utcStartTime = getServerTime(start).getTime();
    const utcEndTime = getServerTime(end).getTime();

    if (utcStartTime <= currentTime && utcEndTime > currentTime) {
        return ProgramType.LIVE;
    }
    if (utcStartTime > currentTime && utcEndTime > currentTime) {
        return ProgramType.FUTURE;
    }
    return ProgramType.CATCHUP;
};
