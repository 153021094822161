import { actions as A } from 'App/Modules/Data/Detail/Event/Store/actions';
import { detailEventReducer } from 'App/Modules/Data/Detail/Event/Store/reducer';
import { initialEventDetailReducerState } from './Store/initialState';

export { Api } from 'App/Modules/Data/Detail/Event/Api';
export { selectors } from 'App/Modules/Data/Detail/Event/Store/selectors';

export const reducer = detailEventReducer;
export const actions = A.public;
export const initialState = initialEventDetailReducerState;
export type { DetailEventState as State, ReduxAction } from 'App/Modules/Data/Detail/Event/Types';
