import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardPortraitPriceProps): string => {
    const classList = ['portrait-card-v3__price'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type CardPortraitPriceProps = React.PropsWithChildren<{ className?: string }>;

export type CardPortraitPriceComponent = React.FunctionComponent<CardPortraitPriceProps>;

export const CardPortraitPrice: CardPortraitPriceComponent = (props) => {
    const card = useCardPortrait();
    return (
        <Mask masked={null}>
            <Text
                className={getClassName(props)}
                typeface={Typeface.SANS}
                size={card.isLarge ? FontSize.CAPTION_1 : FontSize.CAPTION_2}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
            >
                {props.children}
            </Text>
        </Mask>
    );
};
