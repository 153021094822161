import { actions as A } from 'App/Modules/Data/Detail/NetworkRecording/Store/actions';
import { detailRecordingReducer } from 'App/Modules/Data/Detail/NetworkRecording/Store/reducer';
import { initialRecordingDetailReducerState } from './Store/initialState';

export { Api } from 'App/Modules/Data/Detail/NetworkRecording/Api';
export { selectors } from 'App/Modules/Data/Detail/NetworkRecording/Store/selectors';

export const reducer = detailRecordingReducer;
export const actions = A.public;
export const initialState = initialRecordingDetailReducerState;
export type { DetailRecordingState as State, ReduxAction } from 'App/Modules/Data/Detail/NetworkRecording/Types';
