import { DBSchema, IDBPDatabase, openDB } from 'idb';

import { Event } from 'analytics/types';

export const IndexedDBKey: 'nexx4uar_database' = 'nexx4uar_database';

export enum ObjectStores {
    BATCH_BUFFER = 'batch-buffer',
    RETRY_BUFFER = 'retry-buffer',
    METRICS = 'metrics',
}

export enum LoggingMetrics {
    MAX_RETRIES_EXCEEDED = 'max-retries-exceeded',
    EVENTS_DROPPED_FROM_BUFFER = 'events-dropped-from-buffer',
}

export interface Nexx4UARDB extends DBSchema {
    'batch-buffer': {
        key: number;
        value: Event;
    };
    'retry-buffer': {
        key: number;
        value: Event;
    };
    metrics: {
        key: LoggingMetrics;
        value: number;
    };
}

export const LoggingMetricsArray: LoggingMetrics[] = [LoggingMetrics.MAX_RETRIES_EXCEEDED, LoggingMetrics.EVENTS_DROPPED_FROM_BUFFER];
export const ObjectStoresArray: ObjectStores[] = [ObjectStores.BATCH_BUFFER, ObjectStores.RETRY_BUFFER, ObjectStores.METRICS];

export class LoggingDB {
    isSupported(): boolean {
        return 'indexedDB' in window;
    }

    private async getConnection(): Promise<IDBPDatabase<Nexx4UARDB> | null> {
        try {
            return await openDB(IndexedDBKey, 2, {
                upgrade(db) {
                    ObjectStoresArray.forEach((objectStore) => {
                        db.createObjectStore(objectStore, { autoIncrement: true });
                    });
                },
            });
        } catch (error) {
            console.log(`INDEXEDDB opening connection failed with ${error}`);
            return null;
        }
    }

    private async getObjectStoreSize(objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER): Promise<number | null> {
        console.log(`INDEXEDDB Getting ${objectStoreName} size`);
        const connection = await this.getConnection();
        if (!connection) return null;
        try {
            return await connection.count(objectStoreName);
        } catch (error) {
            console.log(`INDEXEDDB count of ${objectStoreName} size failed with ${error}`);
            return null;
        } finally {
            connection.close();
        }
    }

    private async getAllEventKeysFromObjectStore(
        objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER
    ): Promise<number[] | null> {
        console.log(`INDEXEDDB getting all event keys from ${objectStoreName}`);
        const connection = await this.getConnection();
        if (!connection) return null;
        try {
            return await connection.getAllKeys(objectStoreName);
        } catch (error) {
            console.log(`INDEXEDDB getting all event keys from ${objectStoreName} failed with ${error}`);
            return null;
        } finally {
            connection.close();
        }
    }

    private async getAllEventsFromObjectStore(
        objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER
    ): Promise<Event[] | null> {
        console.log(`INDEXEDDB getting all events from ${objectStoreName}`);
        const connection = await this.getConnection();
        if (!connection) return null;
        try {
            return await connection.getAll(objectStoreName);
        } catch (error) {
            console.log(`INDEXEDDB getting all events from ${objectStoreName} failed with  ${error}`);
            return null;
        } finally {
            connection.close();
        }
    }

    private async deleteEventsFromObjectStoreUntilKey(
        objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER,
        deleteUntilKey: number
    ): Promise<void> {
        console.log(`INDEXEDDB Deleting events from ${objectStoreName} until key: `, deleteUntilKey);
        const connection = await this.getConnection();
        if (!connection) return;
        try {
            await connection.delete(objectStoreName, IDBKeyRange.upperBound(deleteUntilKey));
        } catch (error) {
            console.log(`INDEXEDDB Deleting events from ${objectStoreName} until key: ${deleteUntilKey} failed with ${error}`);
        } finally {
            connection.close();
        }
    }

    private async addEventToObjectStore(
        objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER,
        event: Event
    ): Promise<void> {
        console.log(`INDEXEDDB Adding event to ${objectStoreName}: `, event);
        const connection = await this.getConnection();
        if (!connection) return;
        try {
            await connection.add(objectStoreName, event);
        } catch (error) {
            console.log(`INDEXEDDB Adding event to ${objectStoreName}: ${event} failed with ${error}`);
        } finally {
            connection.close();
        }
    }

    private async addMultipleEventsToObjectStore(
        objectStoreName: ObjectStores.BATCH_BUFFER | ObjectStores.RETRY_BUFFER,
        events: Event[]
    ): Promise<void> {
        console.log(`INDEXEDDB Adding multiple events to ${objectStoreName}: `, events);
        const connection = await this.getConnection();
        if (!connection) return;
        try {
            const tx = connection.transaction(objectStoreName, 'readwrite');
            const results = await Promise.allSettled([...events.map((event) => tx.store.add(event)), tx.done]);
            results
                .filter((result) => result.status === 'rejected')
                .map(
                    (result) =>
                        `INDEXEDDB Adding multiple events to ${objectStoreName}: ${events} failed with ${
                            (result as PromiseRejectedResult).reason
                        }`
                )
                .forEach(console.log);
        } catch (error) {
            console.log(`INDEXEDDB Adding multiple events to ${objectStoreName}: ${events} failed with ${error}`);
        } finally {
            connection.close();
        }
    }

    private async setMetric(metricName: LoggingMetrics, value: number): Promise<void> {
        const connection = await this.getConnection();
        if (!connection || !value) return;
        try {
            await connection.put(ObjectStores.METRICS, value, metricName);
        } catch (error) {
            console.log(`INDEXEDDB Incrementing metric ${metricName} failed with ${error}`);
        } finally {
            connection?.close();
        }
    }

    addEventToBatchBuffer = (event: Event): Promise<void> => this.addEventToObjectStore(ObjectStores.BATCH_BUFFER, event);

    addEventToRetryBuffer = (event: Event): Promise<void> => this.addEventToObjectStore(ObjectStores.RETRY_BUFFER, event);

    addMultipleEventsToBatchBuffer = (events: Event[]): Promise<void> =>
        this.addMultipleEventsToObjectStore(ObjectStores.BATCH_BUFFER, events);

    addMultipleEventsToRetryBuffer = (events: Event[]): Promise<void> =>
        this.addMultipleEventsToObjectStore(ObjectStores.RETRY_BUFFER, events);

    getBatchBufferSize = (): Promise<number | null> => this.getObjectStoreSize(ObjectStores.BATCH_BUFFER);

    getRetryBufferSize = (): Promise<number | null> => this.getObjectStoreSize(ObjectStores.RETRY_BUFFER);

    getAllEventsFromBatchBuffer = (): Promise<Event[] | null> => this.getAllEventsFromObjectStore(ObjectStores.BATCH_BUFFER);

    getAllEventsFromRetryBuffer = (): Promise<Event[] | null> => this.getAllEventsFromObjectStore(ObjectStores.RETRY_BUFFER);

    getAllEventKeysFromBatchBuffer = (): Promise<number[] | null> => this.getAllEventKeysFromObjectStore(ObjectStores.BATCH_BUFFER);

    getAllEventKeysFromRetryBuffer = (): Promise<number[] | null> => this.getAllEventKeysFromObjectStore(ObjectStores.RETRY_BUFFER);

    deleteEventsFromBatchBufferUntilKey = (deleteUntilKey: number) =>
        this.deleteEventsFromObjectStoreUntilKey(ObjectStores.BATCH_BUFFER, deleteUntilKey);

    deleteEventsFromRetryBufferUntilKey = (deleteUntilKey: number) =>
        this.deleteEventsFromObjectStoreUntilKey(ObjectStores.RETRY_BUFFER, deleteUntilKey);

    async transferEventsToRetryBuffer() {
        console.log('INDEXEDDB transferring events to retry-buffer');
        try {
            const allBatchEvents = await this.getAllEventsFromBatchBuffer();
            if (!allBatchEvents) throw new Error('Batch events not found');
            await this.addMultipleEventsToRetryBuffer(allBatchEvents);
            const allBatchEventKeys = await this.getAllEventKeysFromBatchBuffer();
            if (!allBatchEventKeys?.length) throw new Error('No event keys');
            const deleteUntilEventKey = allBatchEventKeys.pop();
            if (deleteUntilEventKey === undefined) throw new Error('No Event key');
            return await this.deleteEventsFromBatchBufferUntilKey(deleteUntilEventKey);
        } catch (error) {
            console.log(`INDEXEDDB transferring events to retry-buffer failed with ${error}`);
            return null;
        }
    }

    async purgeRetryBuffer(retryBufferMaxSize: number, retryBufferPurgeSize: number): Promise<number | null> {
        console.log('INDEXEDDB purging retry-buffer');
        try {
            const retryBufferSize = await this.getRetryBufferSize();
            if (retryBufferSize === null) throw new Error('Buffer size not found');
            const bufferOverflow = retryBufferSize - retryBufferMaxSize;
            const allRetryEventKeys = await this.getAllEventKeysFromRetryBuffer();
            if (!allRetryEventKeys) throw new Error('Event keys not found');
            const purgeCount = bufferOverflow + retryBufferPurgeSize;
            const deleteUntilEventKey = allRetryEventKeys[purgeCount - 1] || allRetryEventKeys.pop();
            if (deleteUntilEventKey === undefined) throw new Error('No Event key');
            await this.deleteEventsFromRetryBufferUntilKey(deleteUntilEventKey);
            return purgeCount;
        } catch (error) {
            console.log(`INDEXEDDB purging retry-buffer with ${error}`);
            return null;
        }
    }

    async getMetric(metricName: LoggingMetrics): Promise<number> {
        console.log(`INDEXEDDB getting metric ${metricName}`);
        const connection = await this.getConnection();
        if (!connection) throw new Error();
        try {
            const metric: number | undefined = await connection.get(ObjectStores.METRICS, metricName);
            if (metric === undefined) throw new Error('Metric not found!');
            return metric;
        } catch (error) {
            throw new Error(`INDEXEDDB getting metric ${metricName} failed with ${error}`);
        } finally {
            connection?.close();
        }
    }

    async incrementMetric(metricName: LoggingMetrics, count: number = 1): Promise<void> {
        console.log(`INDEXEDDB Incrementing metric ${metricName}`);
        try {
            const metric = await this.getMetric(metricName);
            await this.setMetric(metricName, count + metric);
        } catch (error) {
            console.log(`INDEXEDDB Incrementing metric ${metricName} failed with ${error}`);
        }
    }

    async resetMetrics(): Promise<void> {
        console.log('INDEXEDDB Resetting metrics');
        const connection = await this.getConnection();
        if (!connection) return;
        try {
            const tx = connection.transaction(ObjectStores.METRICS, 'readwrite');
            const results = await Promise.allSettled([...LoggingMetricsArray.map((metricName) => tx.store.put(0, metricName)), tx.done]);
            results
                .filter((result) => result.status !== 'fulfilled')
                .map((result) => `INDEXEDDB resetting metrics failed with ${(result as PromiseRejectedResult).reason}`)
                .forEach(console.log);
        } catch (error) {
            console.log(`INDEXEDDB resetting metrics failed with ${error}`);
        } finally {
            connection?.close();
        }
    }
}
