import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionBase, SuggestionBaseProps } from 'App/Packages/UI/Suggestion/Base/SuggestionBase';
import {
    Suggestion as SuggestionModule,
    SuggestionProps as SuggestionModuleProps,
} from 'App/Packages/UI/Suggestion/Modules/Suggestion/Suggestion';
import React from 'react';

export type SuggestionProps = SuggestionModuleProps & SuggestionBaseProps;

export const Suggestion: React.FunctionComponent<SuggestionProps> = (props) => {
    return (
        <SuggestionModule value={props.value} offset={props.offset} onEnter={props.onEnter}>
            <SuggestionInternal {...props}>{props.children}</SuggestionInternal>
        </SuggestionModule>
    );
};

const SuggestionInternal = (props: SuggestionBaseProps) => {
    const suggestion = SuggestionModule.use();
    const focused = Subscribable.use(suggestion.focused);
    const { onClick, onHover } = useFocusableHook({
        id: suggestion.value,
        ctx: suggestion.ctx,
        isFocused: focused,
        onEnter: suggestion.enter,
        onHover: suggestion.focus,
        onFocus: suggestion.scrollIntoView,
    });

    return (
        <SuggestionBase ref={suggestion.ref} focused={focused} onClick={onClick} onHover={onHover} {...props}>
            {props.children}
        </SuggestionBase>
    );
};
