import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialMessageEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/constants';
import { MessageEntityNotificationInfo, MessageEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const messageEntityTableReducer: Reducer<MessageEntityTable> = (state, action) => {
    if (!state) return initialMessageEntityTableState;

    switch (action.type) {
        case ActionType.MESSAGE_LOAD_ONE: {
            const entity = Mapper.Message.toEntity(action.payload.message);
            const ids = [...state.ids, entity.id];
            const entities = { ...state.entities, [entity.id]: entity };
            const statusEntityTable = { ...state.statusEntityTable, [entity.id]: action.payload.message.status };
            const triggerRecord = Mapper.Message.toTriggerRecord(action.payload.message, state.triggerTable[action.payload.message.id]);
            const triggerTable = triggerRecord ? { ...state.triggerTable, [entity.id]: triggerRecord } : state.triggerTable;
            return { ...state, ids, entities, statusEntityTable, triggerTable };
        }
        case ActionType.MESSAGE_LOAD_MANY: {
            const ids = Mapper.Common.toIds(action.payload.messages);
            const entities = Mapper.Message.toEntities(action.payload.messages);
            const statusEntityTable = Mapper.Message.toStatusEntityTable(action.payload.messages);
            const triggerTable = Mapper.Message.toTriggerTable(action.payload.messages, state.triggerTable);
            return { ...state, ids, entities, statusEntityTable, triggerTable };
        }
        case ActionType.MESSAGE_READ_MANY: {
            const statusEntityTable = Mapper.Message.toReadUpdatedStatusEntityTable(state.statusEntityTable, action.payload.messageIds);
            const triggerTable = Mapper.Common.toRemovedEntities(state.triggerTable, action.payload.messageIds);
            return { ...state, statusEntityTable, triggerTable };
        }
        case ActionType.MESSAGE_REMOVE_MANY: {
            const ids = Mapper.Common.toFilteredIds(state.ids, action.payload.messageIds);
            const statusEntityTable = Mapper.Common.toRemovedEntities(state.statusEntityTable, action.payload.messageIds);
            const entities = Mapper.Common.toRemovedEntities(state.entities, action.payload.messageIds);
            const triggerTable = Mapper.Common.toRemovedEntities(state.triggerTable, action.payload.messageIds);
            return { ...state, ids, triggerTable, statusEntityTable, entities };
        }
        case ActionType.MESSAGE_TRIGGER_ONE: {
            const info = state.triggerTable[action.payload.messageId];
            if (!info) return state;
            if ((info.repeatTimes ?? 0) + 1 === info.triggeredTimes) return state;
            const triggeredTimes = info.triggeredTimes + 1;
            const time = action.payload.ts + (info.repeatDelay ?? 1) * 1000;
            const nextTriggerTS = (info.repeatTimes ?? 0) + 1 === triggeredTimes ? 0 : time;
            const updated: MessageEntityNotificationInfo = { ...info, triggeredTimes, nextTriggerTS };
            return { ...state, triggerTable: { ...state.triggerTable, [action.payload.messageId]: updated } };
        }
        default:
            return state;
    }
};
