import React, { useState } from 'react';

import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { ReactComponent as CoverImage } from 'styles/assets/logos/Xplore_TV_Placeholder_Logo.svg';
import { ReactComponent as LandscapeAdultImage } from '__SMART_APP_OLD__/app/components/Image/assets/landscapeAdult.svg';
import { ReactComponent as LoadingImage } from '__SMART_APP_OLD__/app/components/Image/assets/loading.svg';
import { ReactComponent as PortraitAdultImage } from '__SMART_APP_OLD__/app/components/Image/assets/portraitAdult.svg';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';

interface Props {
    orientation?: Orientation;
    objectFit?: ObjectFit;
    variant?: Variant;
    assetType?: string;
    src?: string | null;
    noLoading?: boolean;
    className?: string;
    width?: number;
    height?: number;
}

export const enum Orientation {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT',
}

export const enum ObjectFit {
    CONTAIN = 'CONTAIN',
    COVER = 'COVER',
}

export const enum Variant {
    LOADING = 'LOADING',
    ADULT = 'ADULT',
    COVER = 'COVER',
    BASE = 'BASE',
}

const enum Status {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    LOADING = 'LOADING',
}

const classNameForObjectFit: Record<ObjectFit, string> = {
    [ObjectFit.CONTAIN]: 'image-v2__img--contain',
    [ObjectFit.COVER]: 'image-v2__img--cover',
};

const classNameForThemeType: Record<Theme.Type, string> = {
    DARK: 'image-v2 image-v2--dark-theme',
    LIGHT: 'image-v2 image-v2--light-theme',
};

const getContainerClassName = (theme: Theme.Type, className?: string): string => {
    const classList = ['image-v2__container'];
    classList.push(classNameForThemeType[theme]);
    if (className) classList.push(className);
    return classList.join(' ');
};

const getClassName = (objectFit: ObjectFit = ObjectFit.CONTAIN) => {
    const classList = ['image-v2__img'];
    classList.push(classNameForObjectFit[objectFit]);
    return classList.join(' ');
};

interface AdultProps {
    orientation: Orientation;
}

const Adult: React.FunctionComponent<AdultProps> = (props) =>
    props.orientation === Orientation.LANDSCAPE ? (
        <LandscapeAdultImage className="image-v2__adult" />
    ) : (
        <PortraitAdultImage className="image-v2__adult" />
    );

const Loading: React.FunctionComponent = () => (
    <div className="image-v2__loading">
        <LoadingImage />
    </div>
);

const Cover: React.FunctionComponent = () => <CoverImage className="image-v2__cover" />;

export const Image: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const [status, setStatus] = useState(Status.LOADING);
    const containerClassName = getContainerClassName(theme, props.className);

    if (props.variant === Variant.ADULT) {
        return (
            <div className={containerClassName}>
                <Adult orientation={props.orientation ?? Orientation.LANDSCAPE} />
            </div>
        );
    }

    if (props.variant === Variant.LOADING && !props.noLoading) {
        return (
            <div className={containerClassName}>
                <Loading />
            </div>
        );
    }

    if (status === Status.ERROR || props.variant === Variant.COVER || !props.src) {
        return (
            <div className={containerClassName}>
                <Cover />
            </div>
        );
    }

    const className = getClassName(props.objectFit);

    return (
        <div className={containerClassName}>
            {/* check if vod asset */}
            {props.assetType === ContentItemType.Vodasset && (
                <>
                    <CoverImageWithFallback src={props.src} alt="cover-image" />
                    <div className="landscape-overlay"></div>
                </>
            )}

            {status === Status.LOADING && !props.noLoading && <Loading />}
            <img
                className={className}
                src={props.src}
                alt="image"
                width={props.width}
                height={props.height}
                decoding="async"
                loading="lazy"
                onLoad={() => setStatus(Status.SUCCESS)}
                onError={() => setStatus(Status.ERROR)}
            />
        </div>
    );
};
