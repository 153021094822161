import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SettingsSectionContentItemChannelNumber } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelNumber';
import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import { selectChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';

export type SettingsSectionContentItemChannelNumberChannelListProps = { id: string };

type Component = React.FunctionComponent<SettingsSectionContentItemChannelNumberChannelListProps>;

export const SettingsSectionContentItemChannelNumberChannelList: Component = (props) => {
    const { id } = useContext(SettingsSectionContentItemContext);
    const number = useSelector(selectChannelListChannelNumber(props.id, id));
    return <SettingsSectionContentItemChannelNumber>{number}</SettingsSectionContentItemChannelNumber>;
};
