import { useCallback, useEffect, useRef } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Env } from 'App/Env';
import { useCtx } from 'App/Packages/Focus/Hooks/useCtx';
import { useEnter } from 'App/Packages/Focus/Hooks/useEnter';
import { useHover } from 'App/Packages/Focus/Hooks/useHover';
import { useIsActive } from 'App/Packages/Focus/Hooks/useIsActive';
import { useSetCtx } from 'App/Packages/Focus/Hooks/useSetCtx';
import { Focusable } from 'App/Packages/Focus/Types/Focusable';
import { UseFocusableReturnType } from './Types';

export const useFocusable = <T>(options: Focusable<T>): UseFocusableReturnType => {
    const dispatch = useDispatch();
    const setCtx = useSetCtx();
    const ctx = useCtx();
    const isActive = useIsActive();
    const wasFocused = useRef(false);
    const isHoverDisabled = options.disabled || !isActive || (options.isFocused && ctx === options.ctx);
    const isEnterDisabled = options.disabled || !isActive || !options.isFocused || ctx !== options.ctx;

    const id = useRef(options.id);
    const isHoverDisabledRef = useRef(isHoverDisabled);
    const isEnterDisabledRef = useRef(isEnterDisabled);
    const onClickRef = useRef(options.onClick);
    const onHoverRef = useRef(options.onHover);
    const onEnterRef = useRef(options.onEnter);
    const onFocusRef = useRef(options.onFocus);
    const onBlurRef = useRef(options.onBlur);

    id.current = options.id;
    isHoverDisabledRef.current = isHoverDisabled;
    isEnterDisabledRef.current = isEnterDisabled;
    onClickRef.current = options.onClick;
    onHoverRef.current = options.onHover;
    onEnterRef.current = options.onEnter;
    onFocusRef.current = options.onFocus;
    onBlurRef.current = options.onBlur;

    const onClick = useCallback(() => {
        if (isEnterDisabledRef.current) return;
        if (!Env.IsMouseSupported) return;
        if (typeof onClickRef.current === 'function') onClickRef.current(id.current);
        else if (typeof onEnterRef.current === 'function') onEnterRef.current(id.current);
    }, []);

    const onHover = useHover(() => {
        if (isHoverDisabledRef.current) return;
        dispatch(setCtx(options.ctx));
        if (typeof onHoverRef.current !== 'function') return;
        onHoverRef.current(id.current);
    });

    const enterHandler = () => {
        if (typeof onEnterRef.current !== 'function') return;
        onEnterRef.current(id.current);
    };

    useEffect(() => {
        if (options.isFocused && !wasFocused.current && typeof onFocusRef.current === 'function') onFocusRef.current(id.current);
        if (!options.isFocused && wasFocused.current && typeof onBlurRef.current === 'function') onBlurRef.current(id.current);
        if (options.isFocused !== wasFocused.current) wasFocused.current = options.isFocused;
    }, [options.isFocused]);

    useEnter(enterHandler, { disabled: isEnterDisabled });

    return { onClick, onHover, isEnterDisabled, isHoverDisabled };
};
