import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';

type Props = { isSelected: boolean };

export type ChannelCardSelectedModule = React.FunctionComponent<Props>;

export const ChannelCardSelected: ChannelCardSelectedModule = (props) => {
    if (!props.isSelected) return null;

    return <Icon className={`channel-card-v2__selected`} name={IconName.OK} type={IconType.SOLID} />;
};
