export default class Node {
    // Reference to the DOM node
    nodeRef;

    // Stores the onFocus, onBlur, onEnter events
    events;

    // Container flag, will not set the 'focused' class but will trigger onFocus/onBlur events
    isContainer;

    // Used for smooth scrolling by adding a slight delay when focusing elements
    focusDelay;

    isCollection;

    // A flag that indicates whether the node should be focused when it's hovered with the pointer
    usePointer;

    // In some cases you want to preserve the focused element inside the container.
    // Note: This should be used only if the elements of the container do not change,
    // since it saves the DOM ref!
    preserveFocus;

    // The Node that is preserved
    preservedNode;

    constructor(
        ref,
        container,
        preserveFocus,
        onFocus,
        onBlur,
        onEnter,
        onKey,
        onMouseMove,
        onFocusChanged,
        onCustomRect,
        usePointer,
        isCollection = false,
        focusDelay = true,
        getLastFocusedTile,
        scrollable,
        scrollTop
    ) {
        this.focusDelay = focusDelay;
        this.nodeRef = ref;
        this.isContainer = container;
        this.isCollection = isCollection;
        this.usePointer = usePointer;
        this.preserveFocus = preserveFocus;
        this.getLastFocusedTile = getLastFocusedTile;
        this.events = {
            onFocus,
            onBlur,
            onEnter,
            onKey,
            onMouseMove,
            onFocusChanged,
            onCustomRect,
        };
        this.scrollable = true;
        this.scrollTop = scrollTop;
    }

    /**
     * Returns the DOM reference
     * @returns Reference to the DOM node
     */
    get ref() {
        return this.nodeRef;
    }

    /**
     * Sets the 'focused' class on a DOM and triggers the onFocus event if it is set on the Node
     */
    setFocus() {
        this.nodeRef.classList.add('focused');
        if (this.events.onFocus) {
            this.events.onFocus();
        }
    }

    /**
     * Removes the 'focused' class on a DOM and triggers the onBlur event if it is set on the Node
     */
    removeFocus() {
        this.nodeRef.classList.remove('focused');
        if (this.events.onBlur) {
            this.events.onBlur();
        }
    }

    /**
     * Triggers the onEnter event if it is set on the Node
     */
    onEnter() {
        if (this.events.onEnter) {
            this.events.onEnter(this.nodeRef);
        }
    }
}
