import React from 'react';

export type HiddenProps = {
    hidden?: boolean;
};

export type HidableComponent = React.FunctionComponent<React.PropsWithChildren<HiddenProps>>;

export type HidableHocReturnType<T> = React.FunctionComponent<(T extends React.FunctionComponent<infer U> ? U : {}) & HiddenProps>;

export const hidable =
    <T,>(Component: T): HidableHocReturnType<T> =>
    (props) => {
        const { hidden, ...rest } = props;
        if (hidden) return null;
        const Comp = Component as React.FunctionComponent;
        return <Comp {...rest} />;
    };

export const Hidable: HidableComponent = (props) => {
    const { hidden, children } = props;
    if (hidden) return null;
    return <>{children}</>;
};
