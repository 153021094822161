import React from 'react';

import { SettingsSectionContentItemIconArrange } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemIconArrange';
import { SettingsSectionContentItemIconArrowRight } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemIconArrowRight';
import { SettingsSectionContentItemIconChecked } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemIconChecked';
import { SettingsSectionContentItemIconEditable } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemIconEditable';

export type SettingsSectionContentItemIconModule = {
    Editable: React.FunctionComponent;
    ArrowRight: React.FunctionComponent;
    Checked: React.FunctionComponent;
    Arrange: React.FunctionComponent;
} & React.FunctionComponent;

export const SettingsSectionContentItemIcon: SettingsSectionContentItemIconModule = () => null;

SettingsSectionContentItemIcon.Editable = SettingsSectionContentItemIconEditable;
SettingsSectionContentItemIcon.ArrowRight = SettingsSectionContentItemIconArrowRight;
SettingsSectionContentItemIcon.Checked = SettingsSectionContentItemIconChecked;
SettingsSectionContentItemIcon.Arrange = SettingsSectionContentItemIconArrange;
