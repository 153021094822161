import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { Button, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import React from 'react';

export type LoginWithEmailFormSubmitProps = {
    ctx: string;
    onEnter: () => void;
    onHover: (id: LoginElement) => void;
    isFocused: boolean;
    disabled: boolean;
};

export const LoginWithEmailFormSubmit: React.FunctionComponent<LoginWithEmailFormSubmitProps> = (props) => {
    return (
        <Button className="login-with-email-form-submit" id={LoginElement.Submit} width={ButtonWidth.FULL} {...props}>
            SCREEN_LOGIN_BUTTON
        </Button>
    );
};
