import React, { useMemo } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { NOTIFICATION_Z_INDEX, NOTIFICATION_Z_INDEX_INCREMENT } from '__SMART_APP_OLD__/app/constants/zIndex';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { notificationAnimationEndTriggered } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { NOTIFICATION_OFFSET } from '__SMART_APP_OLD__/app/modules/Notification/constants';
import { NotificationActions } from '__SMART_APP_OLD__/app/modules/Notification/NotificationActions';
import { NotificationIcon } from '__SMART_APP_OLD__/app/modules/Notification/NotificationIcon';
import { selectIsNotificationActive, selectNotification } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import {
    NotificationAnimation,
    NotificationIconType,
    NotificationType,
    NotificationVisibilityStatus,
} from '__SMART_APP_OLD__/app/modules/Notification/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    id: string;
    index: number;
}

const classNameForThemeType: Record<Theme.Type, string> = {
    [Theme.Type.Dark]: 'notification-v2--dark-theme',
    [Theme.Type.Light]: 'notification-v2--light-theme',
};

const getContainerStyle = (index: number, timer: number): React.CSSProperties =>
    ({
        zIndex: NOTIFICATION_Z_INDEX + index * NOTIFICATION_Z_INDEX_INCREMENT,
        transform: `translateZ(0) translateX(-${index * NOTIFICATION_OFFSET}vw) translateY(-${index * NOTIFICATION_OFFSET}vw)`,
        '--index': index.toString(),
        '--timer': `${timer}s`,
    }) as React.CSSProperties;

const getClassName = (theme: Theme.Type, type: NotificationType, status: NotificationVisibilityStatus, isDisabled: boolean): string => {
    const classList = ['notification-v2'];
    classList.push(classNameForThemeType[theme]);
    if (status === NotificationVisibilityStatus.ANIMATE_IN) classList.push('notification-v2--animate-in');
    if (status === NotificationVisibilityStatus.ANIMATE_OUT) classList.push('notification-v2--animate-out');
    if (status === NotificationVisibilityStatus.VISIBLE) classList.push(isDisabled ? 'notification-v2--paused' : 'notification-v2--active');

    if (type === NotificationType.SEEK) classList.push('notification-v2--seek');
    return classList.join(' ');
};

const getContentClassName = (iconType: NotificationIconType | string): string => {
    const classList = ['notification-v2__content'];
    if (iconType === NotificationIconType.SEEK_FORWARD) classList.push('notification-v2__content--reverse');
    return classList.join(' ');
};

export const NotificationView: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const notification = useSelector(selectNotification(props.id))!;
    const isNotificationActive = useSelector(selectIsNotificationActive(props.id));
    const isVisible = notification.status === NotificationVisibilityStatus.VISIBLE;
    const isDisabled = !isVisible || !isNotificationActive;
    const containerStyles = useMemo(() => getContainerStyle(props.index, notification.timer), [props.index, notification.timer]);
    const className = useMemo(
        () => getClassName(theme, notification.type, notification.status, isDisabled),
        [theme, notification.type, notification.status, isDisabled]
    );
    const contentClassName = useMemo(() => getContentClassName(notification?.icon || ''), [notification.icon]);
    const dispatch = useDispatch();
    const animationEndHandler = (event: React.AnimationEvent) => {
        if (
            event.animationName !== NotificationAnimation.ANIMATE_IN &&
            event.animationName !== NotificationAnimation.ANIMATE_OUT &&
            event.animationName !== NotificationAnimation.TIMER
        ) {
            return;
        }
        dispatch(notificationAnimationEndTriggered(props.id, event.animationName));
    };

    return (
        <Theme.Provider theme={Theme.Type.Dark}>
            <div className="notification-v2__container" style={containerStyles}>
                <div className={className} onAnimationEnd={animationEndHandler}>
                    <div className={contentClassName}>
                        {notification.icon && <NotificationIcon icon={notification.icon} />}
                        <div className="notification-v2__flex-helper">
                            <Text
                                className="notification-v2__title"
                                typeface={Typeface.SANS}
                                size={FontSize.BODY_1}
                                weight={FontWeight.BOLD}
                                color={Color.PRIMARY}
                            >
                                {notification.title}
                            </Text>
                            {notification.subtitle && (
                                <Text
                                    className="notification-v2__subtitle"
                                    typeface={Typeface.SANS}
                                    size={FontSize.CAPTION_1}
                                    weight={FontWeight.REGULAR}
                                    color={Color.SECONDARY}
                                >
                                    {notification.subtitle}
                                </Text>
                            )}
                        </div>
                    </div>
                    {!!notification.actions?.length && (
                        <NotificationActions id={props.id} actions={notification.actions} isDisabled={isDisabled} />
                    )}
                </div>
            </div>
        </Theme.Provider>
    );
};
