import { FetchPolicy } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';

import { useGetTvSeriesDetailLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/getTvSeriesDetail.generated';
import { ContentFolderContentItemsEdge } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { BaseDetailPage } from '__SMART_APP_OLD__/components/details/BaseDetailPage';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import TVSeason from '__SMART_APP_OLD__/data/TVSeason';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { NavKey, StripeAutoType } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import State from '__SMART_APP_OLD__/utils/State';
import translate from 'language/translate';

import { Key } from 'App/Modules/Key';
import { UI } from 'App/Packages/UI';

import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { setDefaultVariables } from '__SMART_APP_OLD__/components/details/detailUtils';

type PropType = {
    id: string;
};

const detailVariables = {
    variables: {
        tvSeriesId: '',
        profileId: '',
        backgroundWidth: 1920,
        backgroundHeight: 780,
        thumbnailHeight: 280,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
        first: 100,
        after: undefined as string | undefined,
    },
    fetchPolicy: 'no-cache' as FetchPolicy,
};

export const EpisodeTvProgramDetailPage: React.FC<PropType> = ({ id }) => {
    const config = useSelector(selectConfig);
    const [series, setSeries] = useState<any | null>(null);
    const [episode, setEpisode] = useState<any | null>(null);
    const [selectedSeason, setSelectedSeason] = useState<number>(0);
    const [loadingState, setLoadingState] = useState<boolean>(true);

    const seasonElements = useRef<any>([]);
    const stripeNodeRef = useRef<any>([]);

    const setDetailVariables = () => {
        setDefaultVariables(detailVariables, config);
        detailVariables.variables.tvSeriesId = id;

        return detailVariables;
    };

    const [getTVSeriesDetailData, { data, loading, error }] = useGetTvSeriesDetailLazyQuery(setDetailVariables());

    const onEnter = (selectedIndex: number) => {
        setSelectedSeason(selectedIndex);
        State.set(NavKey.DETAIL_PAGE, { selectedSeason: selectedIndex, id });
        Focus.focus(stripeNodeRef.current[0]);
    };

    const onAssetChange = ({ activeIndex }: { activeIndex: number }) => {
        if (series.seasons.length > 0 && series.seasons[selectedSeason].episodes?.length) {
            setEpisode(series.seasons[selectedSeason].episodes[activeIndex]);
        } else {
            setEpisode(series.episodes[activeIndex]);
        }
    };

    const onStripeKey = (key: number) => {
        if (seasonElements.current.length) {
            switch (key) {
                case Key.VK_UP:
                    Focus.focus(seasonElements.current[selectedSeason].nodeRef.nodeRef);
                    return true;
                default:
                    return false;
            }
        }

        return false;
    };

    const getRelatedStripe = () => {
        const parsedEpisodes = series?.seasons?.[selectedSeason]?.episodes;
        return (
            <>
                {series.seasons.length > 1 && (
                    <div className="seasons-line">
                        <Text
                            className="season-title"
                            typeface={Typeface.SANS}
                            size={FontSize.BODY_1}
                            weight={FontWeight.BOLD}
                            color={Color.PRIMARY}
                        >
                            SCREEN_DETAIL_SEASON_TAB_SELECTOR
                        </Text>
                        <Container className="seasons-list" id="seasons-list">
                            {series.seasons.map((season: TVSeason, index: number) => (
                                <Focusable
                                    className={`grouping ${index === selectedSeason ? 'selected' : ''}`}
                                    id={index}
                                    key={season.id}
                                    dataTestId={`main-menu-${index}`}
                                    onEnter={() => {
                                        onEnter(index);
                                    }}
                                    ref={(ref) => {
                                        if (ref) {
                                            seasonElements.current[index] = ref;
                                        }
                                    }}
                                    scrollTop
                                >
                                    {season.ordinal}
                                </Focusable>
                            ))}
                        </Container>
                    </div>
                )}

                {series.seasons.length === 1 && (
                    <div className="seasons-line">
                        <Text
                            className="season-title"
                            typeface={Typeface.SANS}
                            size={FontSize.BODY_1}
                            weight={FontWeight.BOLD}
                            color={Color.PRIMARY}
                        >
                            {`${translate('SCREEN_DETAIL_SEASON_TAB_SELECTOR')} ${series.seasons[0].ordinal}`}
                        </Text>
                    </div>
                )}

                <Stripe
                    id={series.id}
                    dataTestId={'stripe_related'}
                    key={selectedSeason}
                    className={`stripe related`}
                    isRelated={true}
                    assets={parsedEpisodes?.length ? parsedEpisodes : series?.episodes}
                    onFocusChanged={onAssetChange}
                    onKey={onStripeKey}
                    scrollTop
                    autoPlayType={StripeAutoType.SEASON}
                    sessionType={PinSessionType.PIN_LIVE_TV}
                    ref={(ref: any) => {
                        if (ref) {
                            // @ts-ignore
                            if (ref.nodeRef?.nodeRef?.nodeRef?.children) {
                                // @ts-ignore
                                stripeNodeRef.current = ref.nodeRef?.nodeRef?.nodeRef?.children;
                            }
                        }
                    }}
                />
            </>
        );
    };

    const contentFolderContentItemsEdges = useRef<ContentFolderContentItemsEdge[]>([]);

    useEffect(() => {
        if (data) {
            contentFolderContentItemsEdges.current.push(...((data.series?.content?.items?.edges ?? []) as ContentFolderContentItemsEdge[]));
            if (data.series?.content.items?.pageInfo.hasNextPage) {
                detailVariables.variables.after = (data.series?.content.items?.pageInfo.endCursor || undefined) as string | undefined;
                getTVSeriesDetailData(detailVariables);
                return;
            }
            const fullData = {
                ...data.series,
                content: {
                    ...data.series?.content,
                    items: { ...data.series?.content.items, edges: [...contentFolderContentItemsEdges.current] },
                },
            };
            const parsedData = createAssetObject(fullData, data.series?.__typename);
            const sessionEpisodes = parsedData?.seasons?.[selectedSeason]?.episodes;
            const existingEpisodes = sessionEpisodes?.length ? sessionEpisodes : parsedData?.episodes;
            const selectedEpisode =
                (episode && existingEpisodes?.find((newEpisode: any) => newEpisode.id === episode.id)) || existingEpisodes?.[0];

            if (!selectedEpisode) {
                History.back();
                return;
            }

            setSeries(parsedData);
            setEpisode(selectedEpisode);
            setLoadingState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    useEffect(() => {
        if (detailVariables.variables.after) detailVariables.variables.after = undefined;
        getTVSeriesDetailData(setDetailVariables());
        if (State.hasState(NavKey.DETAIL_PAGE)) {
            const detailPageState = State.get(NavKey.DETAIL_PAGE);
            if (detailPageState.id === id && detailPageState.selectedSeason) {
                setSelectedSeason(detailPageState.selectedSeason);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loadingState ? (
        <Layout backgroundColor={BackgroundColor.PRIMARY}>
            <UI.Spinner />
        </Layout>
    ) : (
        <BaseDetailPage
            asset={series}
            selectedAsset={episode}
            getRelatedStripe={getRelatedStripe}
            sessionType={PinSessionType.PIN_LIVE_TV}
        />
    );
};
