import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Translation as Translations } from 'App/Modules/Translation';

const selectTitle =
    (count: number) =>
    (state: State): string =>
        Translation.selectors
            .select(Translations.Key.Prompt.MessageConfirmDelete.Title.Value)(state)
            .replace(Translations.Key.Prompt.MessageConfirmDelete.Title.Params.Count, count.toString());

export const selectors = {
    private: {
        selectTitle,
    },
};
