import React from 'react';

import { Color } from '__SMART_APP_OLD__/app/components/Text';

import { DetailMetaInfo, DetailMetaInfoProps } from 'App/Packages/UI/Detail/Components/DetailMetaInfo';

export type DetailDescriptionComponent = React.FunctionComponent<DetailMetaInfoProps>;

const getClassName = (className?: string) => {
    const classNames = ['detail__description'];
    if (className) classNames.push(className);
    return classNames.join(' ');
};

export const DetailDescription: DetailDescriptionComponent = (props) => {
    const { className, color = Color.PRIMARY, ...rest } = props;

    return (
        <DetailMetaInfo className={getClassName(className)} color={color} {...rest}>
            {props.children}
        </DetailMetaInfo>
    );
};
