import { channelLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';

const mount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(Overlay.actions.unmount());
    if (selectChannelIds(getState()).length) return;
    await dispatch(channelLoad());
};

export const actions = {
    lifecycle: {
        mount,
    },
} as const;
