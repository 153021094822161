import React from 'react';

import translate from 'language/translate';

/**
 * PlayerHints component provides hints for using the player.
 *
 * @returns {JSX.Element} The rendered PlayerHints component.
 */
export default function PlayerHints() {
    return (
        <div className="player-hints-container">
            <div className="left-hint">{translate('SCREEN_PLAYER_HINT_CONTENT_DISCOVERY')}</div>
            <div className="right-hint">{translate('SCREEN_PLAYER_HINT_TV_GUIDE')}</div>
        </div>
    );
}
