import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

const NoSearchResults = () => (
    <div className="search-no-results">
        <Text
            className="search-no-results-title"
            size={FontSize.TITLE_2}
            typeface={Typeface.SERIF}
            weight={FontWeight.BOLD}
            color={Color.SECONDARY}
        >
            SCREEN_SEARCH_NO_RESULTS
        </Text>
        <Text
            className="search-no-results-description"
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            SCREEN_SEARCH_NO_RESULTS_SUBTITLE
        </Text>
    </div>
);

export default NoSearchResults;
