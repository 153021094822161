import React, { useMemo } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Quota } from '__SMART_APP_OLD__/app/gql/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import {
    selectRecordingManagementCleanUpSpaceSelected,
    selectRecordingManagementIsTrickMode,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import translate from 'language/translate';

import { Translation } from 'App/Modules/Translation';

const avgMovieInSeconds = 7200;
const avgShowInSeconds = 2400;

const { TitleUnlimited, TimeHours, SkipMinutes, TitleNoSpace, Title, Used, FreeUpSpace } = Translation.Key.RecordingQuota;

export const RecordingQuota: React.FunctionComponent = () => {
    const isRecordingTrickMode = useSelector(selectRecordingManagementIsTrickMode);
    const cleanUpSizeSelected = useSelector(selectRecordingManagementCleanUpSpaceSelected);
    const recordingQuota = useSelector(Household.selectors.selectRecordingQuota);
    const { allowed, used } = recordingQuota as Quota;
    const percentage = useMemo(() => {
        if (allowed === -1) return 15;
        return ((used - cleanUpSizeSelected) / allowed) * 100;
    }, [allowed, cleanUpSizeSelected, used]);
    const percentageCleanUp = useMemo(() => {
        if (allowed === -1) return 15;
        return (used / allowed) * 100;
    }, [allowed, used]);

    const quoteText = useMemo(() => {
        if (allowed === -1) return TitleUnlimited;
        const storageDifference = allowed - used;
        if (storageDifference <= 0) return TitleNoSpace;
        const minutes = Math.max(Math.floor((storageDifference % 3600) / 60), 0);
        const hour = Math.max(Math.floor(storageDifference / 3600), 0);
        const movies = Math.max(Math.floor(storageDifference / avgMovieInSeconds), 0);
        const shows = Math.max(Math.floor(storageDifference / avgShowInSeconds), 0);

        let text = '';
        if (hour > 0) text += `${hour}${translate(TimeHours)} `;
        if (minutes > 0) text += `${minutes}${translate(SkipMinutes)} `;
        text += translate(Translation.Key.Left);
        if (movies > 0 || shows > 0) {
            text += ` - ${translate(Title).replace('%movies_count%', `${movies}`).replace('%shows_count%', `${shows}`)}`;
        }
        return text;
    }, [allowed, used]);

    return (
        <div className="recording-quota">
            <div className="recording-quota__progress-bar">
                <div className="recording-quota__progress" style={{ width: `${percentage}%` }} />
                {isRecordingTrickMode && <div className="recording-quota__progress-cleanup" style={{ width: `${percentageCleanUp}%` }} />}
            </div>
            <div className="recording-quota__legend">
                <Text
                    className="recording-quota__legend-item recording-quota__legend-item--used"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    <span />
                    {translate(Used)}
                </Text>
                {isRecordingTrickMode && (
                    <Text
                        className="recording-quota__legend-item recording-quota__legend-item--freed"
                        typeface={Typeface.SANS}
                        size={FontSize.CAPTION_1}
                        weight={FontWeight.REGULAR}
                        color={Color.SECONDARY}
                    >
                        <span />
                        {translate(FreeUpSpace)}
                    </Text>
                )}
                <Text
                    className="recording-quota__legend-item recording-quota__legend-item--left"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    <span />
                    {translate(quoteText)}
                </Text>
            </div>
        </div>
    );
};
