import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { getTheme } from '__SMART_APP_OLD__/app/modules/Theme/integration';
import { themeSelectors } from '__SMART_APP_OLD__/app/modules/Theme/selectors';
import { ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';

type Props = {
    theme: ThemeType;
};

export const ThemeContext = React.createContext<ThemeType>(getTheme());

const ThemeContextProvider = ThemeContext.Provider as React.FunctionComponent<React.PropsWithChildren<{ value: ThemeType }>>;

export const ThemeProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => (
    <ThemeContextProvider value={props.theme}>{props.children}</ThemeContextProvider>
);

export const RootThemeProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => (
    <ThemeProvider theme={useSelector(themeSelectors.public.select)}>{props.children}</ThemeProvider>
);
