import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelPersonalInfoFragment = { __typename: 'PersonalChannelInfo', id: string, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null };

export const ChannelPersonalInfoFragmentDoc = gql`
    fragment channelPersonalInfo on PersonalChannelInfo {
  id
  blocked
  audioLanguage
  subtitleLanguage
  __typename
}
    `;