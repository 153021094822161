import React, { useMemo } from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    className?: string;
    containerClassName?: string;
    children: React.ReactNode;
    backgroundColor: BackgroundColor;
    zIndex?: number;
    theme?: Theme.Type;
}

const getClassName = (props: Props) => {
    const classList = ['layout-v2'];
    if (props.containerClassName) classList.push(props.containerClassName);
    return classList.join(' ');
};

const getContentClassName = (props: Props) => {
    const classList = ['layout-v2__content'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const Layout: React.FunctionComponent<Props> = (props) => {
    const theme = props.backgroundColor === BackgroundColor.OVERLAY ? Theme.Type.Dark : props.theme;
    const style = useMemo(() => ({ zIndex: props.zIndex }), [props.zIndex]);
    const className = getClassName(props);
    const contentClassName = getContentClassName(props);

    return (
        <Div className={className} backgroundColor={props.backgroundColor} shadow={Shadow.NONE} contentTheme={theme} style={style}>
            <div className={contentClassName}>{props.children}</div>
        </Div>
    );
};
