export const Key = {
    Ok: 'OK_BUTTON',
    No: 'BUTTON_NO',
    Yes: 'BUTTON_YES',
    TryAgain: 'TRY_AGAIN_BUTTON',
    Cancel: 'CANCEL_BUTTON',
    Remove: 'REMOVE_BUTTON',
    QuitApp: 'QUIT_APP_BUTTON',
    Retry: 'BUTTON_RETRY',
    Logout: 'SCREEN_SETTINGS_LOGOUT',
    Shown: 'OPTION_SHOWN',
    Hidden: 'OPTION_HIDDEN',
    Left: 'LEFT',
    Theme: {
        Light: 'OPTION_LIGHT_THEME',
        Dark: 'OPTION_DARK_THEME',
    },
    Alert: {
        CannotRemoveDevice: {
            Title: {
                Value: 'SCREEN_ALERT_CANNOT_REMOVE_DEVICE_TITLE',
                Params: { DeviceName: '%registered_device_name%' },
            },
            Text: {
                CurrentDevice: 'SCREEN_ALERT_CANNOT_REMOVE_DEVICE_DESCRIPTION_CURRENT_DEVICE',
                STB: 'SCREEN_ALERT_CANNOT_REMOVE_DEVICE_DESCRIPTION_STB',
                OTT: {
                    Value: 'SCREEN_ALERT_CANNOT_REMOVE_DEVICE_DESCRIPTION_OTT',
                    Params: { DateTime: '%remove_device_date_time%' },
                },
            },
        },
        InternetConnectionLost: {
            Title: 'SCREEN_ALERT_NO_NETWORK_CONNECTION_TITLE',
            Text: 'SCREEN_ALERT_NO_NETWORK_CONNECTION_DESCRIPTION',
        },
    },
    Prompt: {
        DeviceConfirmDelete: {
            Title: 'SCREEN_ALERT_REMOVE_DEVICE_CONFIRMATION_TITLE',
            Text: 'SCREEN_ALERT_REMOVE_DEVICE_CONFIRMATION_DESCRIPTION',
        },
        MessageConfirmDelete: {
            Title: {
                Value: 'MESSAGE_INBOX_DELETE_PROMPT_TITLE',
                Params: { Count: '%count%' },
            },
            Text: 'MESSAGE_INBOX_DELETE_PROMPT_DESCRIPTION',
        },
        ManageRecordingConfirmDelete: {
            Title: {
                Value: 'SCREEN_ALERT_RECORDING_MANAGE_DELETE_RECORDINGS_TITLE',
                Params: { Count: '%count%' },
            },
            Text: 'SCREEN_ALERT_RECORDING_MANAGE_DELETE_RECORDINGS_TEXT',
        },
        ManageSeriesRecordingConfirm: {
            Title: 'MANAGE_SERIES_RECORDING_CONFIRMATION_ALERT_TITLE',
            Text: 'MANAGE_SERIES_RECORDING_CONFIRMATION_ALERT_BODY',
            Buttons: {
                DeleteSeries: 'DELETE_ALL_SERIES_RECORDING',
                CancelSeries: 'CANCEL_ALL_SERIES_RECORDING',
            },
        },
        RecordingConfirmCreate: {
            Title: 'CREATE_RECORDING_CONFIRMATION_ALERT_TITLE',
            Text: 'CREATE_RECORDING_CONFIRMATION_ALERT_BODY',
        },
        RecordingConfirmCreateSeries: {
            Title: 'CREATE_RECORDING_CONFIRMATION_ALERT_TITLE',
            Text: 'CREATE_RECORDING_CONFIRMATION_ALERT_BODY',
            Buttons: {
                Record: 'CREATE_ONLY_CURRENT_RECORDING',
                RecordSeries: 'CREATE_ALL_SERIES_RECORDING',
            },
        },
        RecordingConfirmCancel: {
            Title: 'CANCEL_RECORDING_CONFIRMATION_ALERT_TITLE',
            Text: 'CANCEL_RECORDING_CONFIRMATION_ALERT_BODY',
        },
        RecordingConfirmDelete: {
            Title: 'DELETE_RECORDING_CONFIRMATION_ALERT_TITLE',
            Text: 'DELETE_RECORDING_CONFIRMATION_ALERT_BODY',
        },
        Logout: {
            Title: 'SCREEN_ALERT_LOGOUT_HEADER',
            Text: 'SCREEN_ALERT_LOGOUT_SUBHEADER',
        },
        ChannelListConfirmDelete: {
            Title: {
                Value: 'SCREEN_ALERT_DELETE_CHANNEL_LIST_CONFIRMATION_TITLE',
                Params: { Name: '%name%' },
            },
            Text: 'SCREEN_ALERT_DELETE_CHANNEL_LIST_CONFIRMATION_DESCRIPTION',
        },
        AppExit: {
            Title: 'SCREEN_ALERT_APP_EXIT_HEADER',
            Text: 'SCREEN_ALERT_APP_EXIT_SUBHEADER',
        },
    },
    Overlay: {
        Splash: {
            Text: 'FTI_WELCOME_SCREEN_LOADING',
        },
        Select: {
            Community: {
                Title: 'SETTINGS_REGION_SELECTION',
            },
            Theme: {
                Title: 'SETTINGS_APPEARANCE_SELECTION',
            },
            MessageFilter: {
                Title: 'FILTER',
            },
            RecordingFilter: {
                Title: 'FILTER',
            },
            RecordingSort: {
                Title: 'SORT',
            },
            DatePicker: {
                Title: 'EPG_DATE_PICKER_DIALOG_TITLE',
            },
            Language: {
                Title: 'SETTINGS_LANGUAGE_UI_LANGUAGE',
            },
            AudioPrimary: {
                Title: 'SETTINGS_LANGUAGE_AUDIO_PRIMARY',
            },
            AudioSecondary: {
                Title: 'SETTINGS_LANGUAGE_AUDIO_SECONDARY',
            },
            SubtitlePrimary: {
                Title: 'SETTINGS_LANGUAGE_SUBTITLE_PRIMARY',
            },
            SubtitleSecondary: {
                Title: 'SETTINGS_LANGUAGE_SUBTITLE_SECONDARY',
            },
            Startup: {
                Title: 'SETTINGS_STARTUP_BEHAVIOR',
                Option: {
                    Live: 'STARTUP_BEHAVIOR_LIVE_PLAYBACK',
                    Home: 'STARTUP_BEHAVIOR_HOME',
                },
            },
            AdultContent: {
                Title: 'SETTINGS_ADULT_CONTENT',
            },
            LiveClock: {
                Title: 'SETTINGS_LIVE_CLOCK',
                Option: {
                    Off: 'LIVE_CLOCK_OFF',
                    On: 'LIVE_CLOCK_ON',
                },
            },
            ChannelLists: {
                Title: 'MAIN_MENU_CHANNEL_LISTS',
            },
            Subtitles: {
                Title: 'SCREEN_AUDIO_SUBTITLES_SUBTITLES',
            },
            Audios: {
                Title: 'SCREEN_AUDIO_SUBTITLES_AUDIO_LANGUAGE',
            },
        },
        Loading: {
            ChannelList: {
                Text: 'CHANNEL_LIST_LOADING',
            },
        },
    },
    Screen: {
        Settings: {
            Title: 'SETTINGS_SECTION_TITLE',
            Options: {
                Language: {
                    Title: 'SETTINGS_LANGUAGE',
                    Hint: 'SETTINGS_LANGUAGE_HINT',
                },
                ChannelLineup: {
                    Title: 'SETTINGS_CHANNEL_LINEUP',
                    Hint: 'SETTINGS_CHANNEL_LINEUP_HINT',
                },
                Clock: {
                    Title: 'SETTINGS_LIVE_CLOCK',
                    Hint: 'SETTINGS_LIVE_CLOCK_HINT',
                },
                ParentalControl: {
                    Title: 'SETTINGS_PARENTAL_CONTROL',
                    Hint: 'SETTINGS_PARENTAL_CONTROL_HINT',
                },
                PinProtection: {
                    Title: 'SETTINGS_PIN',
                    Hint: 'SETTINGS_PIN_HINT',
                },
                Appearance: {
                    Title: 'SETTINGS_APPEARANCE_TITLE',
                    Hint: 'SETTINGS_APPEARANCE_HINT',
                },
                DeviceManagement: {
                    Title: 'SETTINGS_DEVICE_MANAGEMENT',
                    Hint: 'SETTINGS_DEVICE_MANAGEMENT_HINT',
                },
                StartUpBehavior: {
                    Title: 'STARTUP_BEHAVIOR',
                    Hint: 'STARTUP_BEHAVIOR_HINT',
                },
                PrivacyPolicy: {
                    Title: 'SETTINGS_PRIVACY_POLICY',
                    Hint: 'SETTINGS_PRIVACY_POLICY_HINT',
                },
                MyRecordings: {
                    Title: 'SETTINGS_SCREEN_RECORDINGS_STORAGE_TITLE',
                    Hint: 'SETTINGS_SCREEN_RECORDINGS_STORAGE_HINT',
                    NoData: 'RECORDINGS_NO_DATA_AVAILABLE',
                },
                LogOut: {
                    Title: 'SCREEN_SETTINGS_LOGOUT',
                    Hint: 'SCREEN_SETTINGS_LOGOUT_HINT',
                },
                ViewControl: {
                    Title: 'SETTINGS_VIEW_CONTROL',
                    Hint: 'SETTINGS_VIEW_CONTROL_HINT',
                },
                Imprint: {
                    Title: 'SETTINGS_IMPRINT',
                    Hint: 'SETTINGS_IMPRINT_HINT',
                    OverlayTitle: 'SETTINGS_IMPRINT_TITLE',
                    OverlayBody: 'SETTINGS_IMPRINT_BODY',
                },
                Region: {
                    Title: 'SETTINGS_REGION_SELECTION',
                    Hint: 'SETTINGS_REGION_SELECTION_HINT ',
                },
            },
        },
        ChannelListsCreate: {
            Create: {
                Title: 'SETTINGS_CHANNEL_LINEUP_CREATE_LIST_HINT_TITLE',
                Description: 'SETTINGS_CHANNEL_LINEUP_CREATE_LIST_HINT_DESCRIPTION',
                Success: 'CONTINUE_BUTTON',
                Failure: 'CANCEL_BUTTON',
            },
            Edit: {
                Title: 'SETTINGS_CHANNEL_LINEUP_RENAME_LIST_TITLE',
                Description: 'SETTINGS_CHANNEL_LINEUP_RENAME_LIST_DESCRIPTION',
                Success: 'RENAME_BUTTON',
                Failure: 'CANCEL_BUTTON',
            },
        },
        ChannelListsManage: {
            AllChannels: 'SETTINGS_CHANNEL_LINEUP_DEFAULT',
        },
    },
    Notification: {
        ChannelListDeletedSuccessfully: 'NOTIFICATION_CHANNEL_LIST_DELETED_SUCCESSFULLY',
    },
    RecordingQuota: {
        Title: 'RECORDINGS_STORAGE_TITLE',
        TitleUnlimited: 'RECORDINGS_STORAGE_TITLE_UNLIMITED',
        TitleNoSpace: 'RECORDINGS_STORAGE_TITLE_NO_SPACE',
        TimeHours: 'SCREEN_DETAIL_TIME_HOURS',
        SkipMinutes: 'SCREEN_PLAYER_SKIP_MINUTES',
        Used: 'USED',
        FreeUpSpace: 'RECORDING_MANAGE_FREE_UP_SPACE',
    },
} as const;
