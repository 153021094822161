import { Default as FocusDefault } from 'App/Packages/Focus/Constants';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

const selectCtx = () => FocusDefault;

// TODO: this is super strange selectIsLayerActive
const selectIsLayerActive: Selector<boolean> = () => true;

const selectIsActive: Selector<boolean> = (state) => state.keyboard.open && selectIsLayerActive(state);

export const selectors = {
    public: {
        selectIsActive,
        selectIsLayerActive,
    },
    private: {
        ctx: {
            select: selectCtx,
        },
    },
};
