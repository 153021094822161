import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageReadMutationVariables = Types.Exact<{
  messageReadInput: Types.ReadMessageInput;
}>;


export type MessageReadMutation = { __typename?: 'Nexx4Mutations', readMessage: { __typename?: 'ReadMessagePayload', message: { __typename?: 'Message', id: string } } };

export type MessageReadFragment = { __typename?: 'Nexx4Mutations', readMessage: { __typename?: 'ReadMessagePayload', message: { __typename?: 'Message', id: string } } };

export const MessageReadFragmentDoc = gql`
    fragment messageRead on Nexx4Mutations {
  readMessage(input: $messageReadInput) {
    message {
      id
    }
  }
}
    `;
export const MessageReadDocument = gql`
    mutation messageRead($messageReadInput: ReadMessageInput!) {
  ...messageRead
}
    ${MessageReadFragmentDoc}`;
export type MessageReadMutationFn = Apollo.MutationFunction<MessageReadMutation, MessageReadMutationVariables>;

/**
 * __useMessageReadMutation__
 *
 * To run a mutation, you first call `useMessageReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageReadMutation, { data, loading, error }] = useMessageReadMutation({
 *   variables: {
 *      messageReadInput: // value for 'messageReadInput'
 *   },
 * });
 */
export function useMessageReadMutation(baseOptions?: Apollo.MutationHookOptions<MessageReadMutation, MessageReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageReadMutation, MessageReadMutationVariables>(MessageReadDocument, options);
      }
export type MessageReadMutationHookResult = ReturnType<typeof useMessageReadMutation>;
export type MessageReadMutationResult = Apollo.MutationResult<MessageReadMutation>;
export type MessageReadMutationOptions = Apollo.BaseMutationOptions<MessageReadMutation, MessageReadMutationVariables>;