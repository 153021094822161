import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialChannelListEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/constants';
import { ChannelListEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const channelListEntityTableReducer: Reducer<ChannelListEntityTable> = (state, action) => {
    if (!state) return initialChannelListEntityTableState;
    switch (action.type) {
        case ActionType.CHANNEL_LIST_ID_CHANGE: {
            if (action.payload.channelListId === state.selectedId) return state;
            if (!state.ids.includes(action.payload.channelListId)) return state;
            return { ...state, selectedId: action.payload.channelListId };
        }
        case ActionType.CHANNEL_LIST_ORDER_CHANGE: {
            if (action.payload.channelListIds.length !== state.ids.length) return state;
            if (state.ids.some((id) => !action.payload.channelListIds.includes(id))) return state;
            return { ...state, ids: action.payload.channelListIds };
        }
        case ActionType.CHANNEL_LIST_LOAD_MANY: {
            if (!action.payload.channelLists.length) return state;
            const ids = Mapper.Common.toIds(action.payload.channelLists);
            const entities = Mapper.ChannelList.toEntities(action.payload.channelLists);
            return { ...state, ids, entities, channelTotalCountRelationEntityTable: action.payload.channelListChannelTotalCount };
        }
        case ActionType.CHANNEL_LIST_CHANNELS_LOAD_ONE: {
            if (!action.payload.channelListId || !action.payload.channels.length) return state;
            const channelNumberRelationEntityTable = Mapper.ChannelList.toUpdatedChannelNumberRelationEntityTable(
                state.channelNumberRelationEntityTable,
                action.payload.channelListId,
                action.payload.channels
            );
            const channelsRelationEntityTable = Mapper.ChannelList.toUpdatedChannelsRelationEntityTable(
                state.channelsRelationEntityTable,
                action.payload.channelListId,
                action.payload.channels
            );
            return { ...state, channelNumberRelationEntityTable, channelsRelationEntityTable };
        }
        case ActionType.CHANNEL_LIST_CREATE_ONE: {
            if (state.ids.includes(action.payload.channelList.id)) return state;
            const ids = [...state.ids, action.payload.channelList.id];
            const entities = Mapper.ChannelList.toAddedEntities(state.entities, action.payload.channelList);
            const channelTotalCountRelationEntityTable = Mapper.Common.toUpdatedEntities(
                state.channelTotalCountRelationEntityTable,
                action.payload.channelList.id,
                action.payload.totalCount
            );
            return { ...state, entities, ids, channelTotalCountRelationEntityTable };
        }
        case ActionType.CHANNEL_LIST_RENAME_ONE: {
            if (!state.ids.includes(action.payload.channelListId)) return state;
            const entities = Mapper.ChannelList.toRenamedEntities(state.entities, action.payload.channelListId, action.payload.name);
            return { ...state, entities };
        }
        case ActionType.CHANNEL_LIST_UPDATE_ONE: {
            const channelTotalCountRelationEntityTable = Mapper.Common.toUpdatedEntities(
                state.channelTotalCountRelationEntityTable,
                action.payload.channelListId,
                action.payload.totalCount
            );
            return { ...state, channelTotalCountRelationEntityTable };
        }
        case ActionType.CHANNEL_LIST_REMOVE_ONE: {
            if (!state.ids.includes(action.payload.channelListId)) return state;
            const channelListIds = [action.payload.channelListId];
            const ids = Mapper.Common.toFilteredIds(state.ids, channelListIds);
            const entities = Mapper.Common.toRemovedEntities(state.entities, channelListIds);
            const channelTotalCountRelationEntityTable = Mapper.Common.toRemovedEntities(
                state.channelTotalCountRelationEntityTable,
                channelListIds
            );
            const channelNumberRelationEntityTable = Mapper.Common.toRemovedEntities(
                state.channelNumberRelationEntityTable,
                channelListIds
            );
            const channelsRelationEntityTable = Mapper.Common.toRemovedEntities(state.channelsRelationEntityTable, channelListIds);
            return {
                ...state,
                ids,
                entities,
                channelTotalCountRelationEntityTable,
                channelNumberRelationEntityTable,
                channelsRelationEntityTable,
            };
        }
        default:
            return state;
    }
};
