import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { PromptActionType, PromptState } from 'App/Modules/Prompt/Types';

export const reducer: Reducer<PromptState> = (state, action) => {
    if (state === undefined) return null;

    switch (action.type) {
        case PromptActionType.Changed: {
            if (!action.payload.prompt) return null;
            return action.payload.prompt;
        }
        default:
            return state;
    }
};
