import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Screen } from 'App/Modules/Screen';
import { UI } from 'App/Packages/UI';
import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { Subscribable } from 'App/Packages/Subscribable';
import { LoggingService } from 'analytics/loggingService';
import { getToManageRecordingsEvent } from 'analytics/logging/factories/uiActionEventFactory';

interface MyLibraryQuotaButtonProps {
    initial: string | undefined;
}

export const MyLibraryQuotaButton: React.FunctionComponent<MyLibraryQuotaButtonProps> = ({ initial }) => {
    const dispatch = useDispatch();

    const folder = UI.Folder.Api.Root.use();
    const list = UI.Folder.Api.List.use();
    const buttonId = `${folder.id}-button`;
    const ids = Subscribable.use(list.ids);

    Subscribable.useRegister(list.ids, buttonId);
    const initialized = useRef(false);

    useEffect(() => {
        if (!ids.length || !initial || initialized.current) return;
        if (!ids.includes(initial)) return;
        folder.scrollIntoView.instant.set(true);
        list.focus(initial);
        initialized.current = true;
    }, [initial, list, folder, ids]);

    const selector = useCallback(
        (id: string) => {
            return id === buttonId;
        },
        [buttonId]
    );

    const isFocused = Subscribable.useSelector(list.focused, selector);

    return (
        <Button
            id={buttonId}
            width={ButtonWidth.FIT}
            corners={ButtonCornersStyle.ROUNDED}
            ctx={UI.Folder.Constants.Ctx}
            isFocused={isFocused}
            onFocus={() => folder.scrollIntoView()}
            onEnter={async () => {
                await dispatch(Screen.actions.update(Screen.Type.MyLibrary, { focusedFolderId: folder.id, focusedItemId: buttonId }));
                dispatch(History.actions.push());
                dispatch(Screen.actions.mount(Screen.Type.RECORDING_MANAGEMENT, {}));
                LoggingService.getInstance().logEvent(getToManageRecordingsEvent());
            }}
        >
            MANAGE_RECORDINGS_KEY
        </Button>
    );
};
