// @ts-ignore
import { clpp } from '@castlabs/prestoplay';

import { isHD } from '__SMART_APP_OLD__/utils/displayUtils';

import { Environment } from 'App/Types/Environment';
import { Opco } from 'App/Types/Opco';

/**
 * @namespace Utils
 */

/**
 * @description An type containing the AssetType
 * @enum
 * @memberof Utils
 */
export enum AssetType {
    EVENT = 'Event',
    NETWORK_RECORDING = 'NetworkRecording',
    SERIES = 'Series',
    SEASON = 'Season',
    EPISODE = 'Episode',
    CHANNEL = 'Channel',
    CHANNEL_PRODUCT = 'ChannelProduct',
    CHANNEL_LIST = 'ChannelList',
    PRODUCT_BUNDLE = 'ProductBundle',
    BANNER = 'Banner',
    SIMPLE_PROGRAM = 'SimpleProgram',
    VOD_FOLDER = 'VODFolder',
    VOD_ASSET = 'VODAsset',
    VOD_PRODUCT = 'VODProduct',
    VOD_SERIES = 'VODSeries',
    TRAILER = 'Trailer',
    // PERSON = 'person',
    // TV_CHANNEL = 'tv_channel',
}

export const vodTypes = [AssetType.VOD_PRODUCT, AssetType.VOD_ASSET, AssetType.VOD_FOLDER, AssetType.VOD_SERIES];

/**
 * @description An object containing the player types
 * @enum
 * @memberof Utils
 */
export enum PlayerType {
    EVENT = 'Event',
    NETWORK_RECORDING = 'NetworkRecording',
    VOD_ASSET = 'VODAsset',
    EPISODE = 'Episode',
    TRAILER = 'trailer',
}

/**
 * @description An object containing the stripe auto types
 * @enum
 * @memberof Utils
 */
export enum StripeAutoType {
    SEASON = 'Season',
}

/**
 * @description An object containing the program types
 * @enum
 * @memberof Utils
 */
export enum ProgramType {
    LIVE = 'live',
    CATCHUP = 'catchup',
    FUTURE = 'future',
    VOD = 'vod',
    RECORDING = 'recording',
}

/**
 * @description An object containing the application Route
 * @enum
 * @memberof Utils
 */
export enum Route {
    HOME = 'HOME',
    MY_LIBRARY = 'LIBRARY',
    COLLECTION = 'COLLECTION',
    LIVE_TV = 'LIVE_TV',
    VIDEOSTORE = 'VIDEOSTORE',
    VOD_FOLDER = 'VOD_FOLDER',
}

/**
 * @description An array containing the Route with playback
 * @constant
 * @readonly
 * @memberof Utils
 */
export const routesWithPlayback = [/HOME$/, /playChannel/, /play/] as const;

/**
 * @description An object containing the navigation keys
 * @enum
 * @memberof Utils
 */
export enum NavKey {
    STRIPE = 'Stripe',
    STRIPES = 'Stripes',
    COLLECTION = 'Collection',
    MINI_EPG = 'MiniEpg',
    EPG = 'EPG',
    PAGE_WITH_PLAYER = 'PageWithPlayer',
    DETAIL_PAGE = 'DetailPage',
    MENU = 'Menu',
    SETTINGS = 'Settings',
    SEARCH_RESULTS = 'SearchResults',
    SEARCH = 'Search',
}

/**
 * @description An object containing the miniEPG configuration
 * @constant
 * @readonly
 * @memberof Utils
 */
export const miniEpgConfig = {
    maxChannelCardsOnPage: 6,
    maxProgramCardsOnPage: 5,
    zapperTimeout: 5000,
    fullEpgTimeout: 15000,
    fastScrollTimeout: 2000,
    debounceEventsDownload: 250,
    fastScrollUpdateInterval: 25,
    debouncefastScrollStop: 500,
    debounceChannelsFastScroll: 500,
    throttleChannelsNavigation: 100,
    throttleEventsNavigation: 150,
} as const;

/**
 * @description An object containing the snackbar configuration
 * @constant
 * @readonly
 * @memberof Utils
 */
export const snackbarConfig = {
    keyPressTimeout: 2000,
    numberKeysLimit: 3,
} as const;

/**
 * @description An object containing the stripe configuration
 * @constant
 * @readonly
 * @memberof Utils
 */
export const stripeConfig = {
    numOfAssetsForCollection: 30,
} as const;

/**
 *
 * @description An object containing the magic remote configuration
 * @constant
 * @readonly
 * @memberof Utils
 */
export const magicRemoteConfig = {
    minRemoteDelay: 200,
    remoteDelay: 600,
    rightSideMinWidth: isHD ? (2 / 3) * 1800 : 1800,
    leftSideMinWidth: isHD ? (2 / 3) * 300 : 300,
    leftSideMaxWidth: isHD ? (2 / 3) * 400 : 400,
} as const;

/**
 * @description An object containing the more information overlay configuration
 * @constant
 * @readonly
 * @memberof Utils
 */
export const moreInfoOverlayConfig = {
    smallContentSize: isHD ? (2 / 3) * 720 : 720, // 1080 - top margin (264) - bottom margin (96)
    bigContentSize: isHD ? (2 / 3) * 888 : 888, // 1080 - bottom & top margin (2x96)
    scrollStep: isHD ? (2 / 3) * 200 : 200,
} as const;

/**
 * @description An object containing the header shadow configuration
 * @constant
 * @memberof Utils
 */
export const headerShadow = {
    additionalInterval: isHD ? (2 / 3) * 80 : 80,
    maxVisiblePart: 30,
} as const;

/**
 * @description the margins added left-right that allows us to see the box-shadow around the item
 * @constant
 * @memberof Utils
 */
export const headerDimensionCorrections = {
    leftCorrection: 30,
    rightCorrection: 30,
} as const;

/**
 * @description An object containing the stripe types
 * @enum
 * @memberof Utils
 */
export enum StripeType {
    MIXED = 'mixed',
    SERIES = 'series',
    TV_PROGRAM = 'tv_program',
    TV_NOW_NEXT = 'tv_now_next',
    MOVIE = 'movie',
    GENRE = 'genre',
    GALLERY = 'gallery',
    RECORDING = 'recording',
    PERSON = 'person',
    TV_CHANNEL = 'tv_channel',
    ASSET_DETAILS = 'asset_details',
}

/**
 * @description An object containing the components that have player
 * @enum
 * @memberof Utils
 */
export enum ComponentsWithPlayer {
    PLAYER_UI = 'playerUI',
    EPG = 'EPG',
    MINI_EPG = 'miniEPG',
    SUBTITLES = 'subtitles',
}

/**
 * @description An object containing the detail page types
 * @enum
 * @memberof Utils
 */
export enum DetailPageType {
    EVENT = 'Event',
    MOVIE = 'VODAsset',
    VOD_SERIES = 'VODSeries',
    SERIES = 'Series',
    EPISODE = 'episode',
    VOD_PRODUCT = 'VODProduct',
    NETWORK_RECORDING = 'NetworkRecording',
}

/**
 * @description A string containing the pin input character
 * @constant
 * @memberof Utils
 */
export const SELECTED_PIN_INPUT_CHAR = '*';

/**
 * @description default pin input character
 * @constant
 * @memberof Utils
 */
export const DEFAULT_PIN_INPUT_CHAR = '–';

/**
 * @constant
 * @enum
 * @memberof Utils
 */
export enum PinAction {
    ENTER = 'enter-pin',
    CHANGE = 'change-pin',
    FIRST_SETUP = 'setup-pin',
}

/**
 * @description An object containing the different pin action states
 * @enum
 * @memberof Utils
 */
export enum PinActionState {
    FIRST = 'first-try',
    WRONG = 'wrong-pin',
    BLOCKED = 'blocked-pin',
    REENTER = 'reenter-pin',
}

export type PinActionInfo = {
    pinOverlayTitle: string;
    pinOverlayText: string;
    pinOverlayHint: string;
};

/**
 * @constant
 * @memberof Utils
 */
export const pinKeysByType: Record<PinAction, Partial<Record<PinActionState, PinActionInfo>>> = {
    [PinAction.ENTER]: {
        [PinActionState.FIRST]: {
            pinOverlayTitle: 'SYSTEM_PIN_ENTRY_SCREEN_TITLE',
            pinOverlayText: 'SYSTEM_PIN_ENTRY_SCREEN_BODY',
            pinOverlayHint: '',
        },
        [PinActionState.WRONG]: {
            pinOverlayTitle: 'SYSTEM_PIN_ENTRY_SCREEN_RE_ENTER_TITLE',
            pinOverlayText: 'SYSTEM_PIN_ENTRY_SCREEN_RE_ENTER_BODY',
            pinOverlayHint: 'SYSTEM_PIN_ENTRY_SCREEN_RE_ENTER_HINT',
        },
        [PinActionState.BLOCKED]: {
            pinOverlayTitle: 'SYSTEM_PIN_ENTRY_SCREEN_BLOCKED_TITLE',
            pinOverlayText: 'SYSTEM_PIN_ENTRY_SCREEN_BLOCKED_BODY',
            pinOverlayHint: 'SYSTEM_PIN_ENTRY_SCREEN_BLOCKED_HINT',
        },
    },
    [PinAction.CHANGE]: {
        [PinActionState.FIRST]: {
            pinOverlayTitle: 'CHANGE_SYSTEM_PIN_SCREEN_TITLE',
            pinOverlayText: 'CHANGE_SYSTEM_PIN_SCREEN_BODY',
            pinOverlayHint: '',
        },
        [PinActionState.REENTER]: {
            pinOverlayTitle: 'CHANGE_SYSTEM_PIN_SCREEN_CONFIRM_TITLE',
            pinOverlayText: 'CHANGE_SYSTEM_PIN_SCREEN_CONFIRM_BODY',
            pinOverlayHint: '',
        },
        [PinActionState.WRONG]: {
            pinOverlayTitle: 'CHANGE_SYSTEM_PIN_SCREEN_RE_ENTER_TITLE',
            pinOverlayText: 'CHANGE_SYSTEM_PIN_SCREEN_RE_ENTER_BODY',
            pinOverlayHint: '',
        },
    },
    [PinAction.FIRST_SETUP]: {
        [PinActionState.FIRST]: {
            pinOverlayTitle: 'FTI_SET_SYSTEM_PIN_TITLE',
            pinOverlayText: 'FTI_SET_SYSTEM_PIN_DESCRIPTION',
            pinOverlayHint: '',
        },
        [PinActionState.REENTER]: {
            pinOverlayTitle: 'FTI_SET_SYSTEM_PIN_CONFIRM_TITLE',
            pinOverlayText: 'FTI_SET_SYSTEM_PIN_CONFIRM_DESCRIPTION',
            pinOverlayHint: '',
        },
        [PinActionState.WRONG]: {
            pinOverlayTitle: 'FTI_SET_SYSTEM_PIN_REENTER_TITLE',
            pinOverlayText: 'FTI_SET_SYSTEM_PIN_REENTER_DESCRIPTION',
            pinOverlayHint: '',
        },
    },
};

/**
 * @constant
 * @enum
 * @memberof Utils
 */
export enum PinStyle {
    Overlay = 'pin-entry-overlay',
    Fti = 'pin-fti-setup',
}

/**
 * @description An object containing the settings elements
 * @enum
 * @memberof Utils
 */
export enum SettingsElement {
    CHANNEL_LINEUP = 'lineup',
    LANGUAGE = 'languages',
    PRIVACY_POLICY = 'policy',
    LOGOUT = 'logout',
    PIN = 'pin',
    PARENTAL_CONTROL = 'parental-control',
    VIEW_CONTROL = 'view-control',
    DEVICE_MANAGEMENT = 'device-management',
    REGION = 'region',
    NVPR = 'npvr',
    CLOCK = 'clock',
    APPEARANCE = 'appearance',
    STARTUP_BEHAVIOR = 'startupBehavior',
    IMPRINT = 'imprint',
}

/**
 * @description An enum containing the setting items
 * @enum
 * @memberof Utils
 */
export enum SettingsItem {
    LINK = 'Link',
    SELECT = 'Select',
    INTERNAL = 'Internal',
    ACTION = 'Action',
    CHANNEL_LIST = 'Channel-List',
    CHANNEL = 'Channel',
    NPVR = 'Npvr',
}

/**
 * @description An object containing the policy options
 * @enum
 * @memberof Utils
 */
export enum PolicyOption {
    POLICY_CONSENT = 'policy_consent',
}

/**
 * @constant
 * @memberof Utils
 */
export const itemsForSetting = {
    [SettingsElement.PIN]: [
        {
            id: 'SETTINGS_PIN_CHANGE_SYSTEM_PIN',
            title: 'SETTINGS_PIN_CHANGE_SYSTEM_PIN',
            type: 'Action',
        },
    ],
    [SettingsElement.VIEW_CONTROL]: [
        {
            id: 'SETTINGS_VIEW_CONTROL',
            title: 'SETTINGS_VIEW_CONTROL',
        },
    ],
} as const;

/**
 * @constant
 * @memberof Utils
 */
export const scrollableContainerId = 'scrollable-container';

/**
 * @description An object containing the search contexts
 * @enum
 * @memberof Utils
 */
export enum SearchContext {
    events = 'EVENTS',
    recordings = 'RECORDINGS',
    onDemand = 'ON_DEMAND',
}

/**
 * @description An object containing the policy content options
 * @constant
 * @memberof Utils
 */
export const policyConsentOptions = [
    {
        id: 0,
        title: 'SETTINGS_PRIVACY_POLICY_CONSENT_ALLOW',
    },
    {
        id: 1,
        title: 'SETTINGS_PRIVACY_POLICY_CONSENT_CONTINUE',
    },
] as const;

/**
 * @constant
 * @memberof Utils
 */
export const WIDE_PLAYER_VIEW_ANIMATION_DURATION = 200;

/**
 * @description Threshold in ms
 * @constant
 * @memberof Utils
 */
export const LONG_PRESS_DURATION_THRESHOLD = 350;

/**
 * @description Threshold in ms
 * @constant
 * @memberof Utils
 */
export const SHORT_PRESS_THROTTLE = 350;

/**
 * @description Threshold in ms
 * @constant
 * @memberof Utils
 */
export const CHANNEL_SWITCH_PLAYER_TIMEOUT = 400;

/**
 * @description An object containing the settings items length
 * @constant
 * @memberof Utils
 */
export const settingsItemsLength = {
    stringMaxLength: 25,
    titleMinLength: 10,
    titleMaxLength: 33,
} as const;
/**
 * @constant
 * @memberof Utils
 */
export const relocatePlayerTargets = {
    PLAYER: {
        classNames: ['default-position', 'black-background-color'],
    },
    HOME: {
        classNames: ['gallery-player', 'default-background-color'],
    },
    NORMAL: {
        classNames: ['default-background-color'],
    },
} as const;

/**
 * @constant
 * @memberof Utils
 */
export const ftiTranslateElements = {
    viewControl: {
        title: 'SETTINGS_VIEW_CONTROL',
        description: 'FTI_VIEW_CONTROL_DESCRIPTION',
        buttons: {
            manage: {
                id: 'manage-selection',
                btnLabel: 'FTI_VIEW_CONTROL_SELECT_CHANNELS_BUTTON',
            },
            accept: {
                id: 'accept-recommended',
                btnLabel: 'FTI_VIEW_CONTROL_ACCEPT_RECOMMENDED_BUTTON',
            },
        },
        notifications: {
            limitReached: 'NOTIFICATION_VIEW_CONTROL_LIMIT_REACHED',
            noChannelsSelected: 'NOTIFICATION_VIEW_CONTROL_NO_SELECTED_CHANNEL',
        },
    },
} as const;

/**
 * @constant
 * @memberof Utils
 */
export const playerDownloadErrors = [
    clpp.Error.Code.UNSUPPORTED_SCHEME,
    clpp.Error.Code.BAD_HTTP_STATUS,
    clpp.Error.Code.HTTP_ERROR,
    clpp.Error.Code.TIMEOUT,
    clpp.Error.Code.MALFORMED_DATA_URI,
    clpp.Error.Code.UNKNOWN_DATA_URI_ENCODING,
    clpp.Error.Code.INVALID_XML,
    clpp.Error.Code.DASH_XLINK_DEPTH_LIMIT,
];

/**
 * @description An enum containing the map between stream type and its presentation string
 * @enum
 * @author SmartTVBG@a1.bg
 * @date 02/20/2022
 * @memberof Utils
 */
export enum DeliveryKind {
    DASH = 'MPEG-DASH',
    HLS = 'HLS',
    DVB = 'DVB',
    MULTICAST = 'Unicast ABV HLS',
    RTSP = 'RTSP',
    HTTP = 'HTTP',
}

/**
 * @description A hashmap containing the map between
 * country name from process.env and shortened name
 * @enum
 * @author SmartTVBG@a1.bg
 * @date 09/12/2022
 * @memberof Utils
 */
export const OpcoViewer = {
    [Opco.Austria]: 'at',
    [Opco.Bulgaria]: 'bg',
    [Opco.Croatia]: 'hr',
    [Opco.Slovenia]: 'si',
    [Opco.Macedonia]: 'mk',
    [Opco.Serbia]: 'rs',
};

/**
 * @description A hashmap containing the map between the country process.env
 * value and the viewer prefix  agreed for reporting pattern.
 * @enum
 * @author SmartTVBG@a1.bg
 * @date 09/12/2022
 * @memberof Utils
 */
export const viewerForOpco = {
    [Opco.Austria]: OpcoViewer[Opco.Austria],
    [Opco.Bulgaria]: OpcoViewer[Opco.Bulgaria],
    [Opco.Croatia]: OpcoViewer[Opco.Croatia],
    [Opco.Slovenia]: OpcoViewer[Opco.Slovenia],
    [Opco.Macedonia]: OpcoViewer[Opco.Macedonia],
    [Opco.Serbia]: OpcoViewer[Opco.Serbia],
} as const;

/**
 * @description An Enum for development environments
 * @enum
 * @author SmartTVBG@a1.bg
 * @constant
 * @date 09/12/2022
 * @memberof Utils
 */
export const shortEnvironmentName: Record<Environment, string> = {
    [Environment.Development]: 'dev',
    [Environment.Integration]: 'prod',
    [Environment.Production]: 'prod',
    [Environment.Business]: 'business',
};

export enum Operator {
    // Austria
    A1AT = 'A1AT',

    // Bulgaria
    A1BG = 'A1BG',

    // Croatia
    A1HR = 'A1HR',

    // Slovenia
    A1SI = 'A1SI',

    // Macedonia
    A1MK = 'A1MK',

    // Serbia
    A1RS = 'A1RS',
}

/**
 * @description An enum containing the map between the country process.env value
 * and the opco prefix agreed for reporting pattern.
 * @author SmartTVBG@a1.bg
 * @enum
 * @date 09/12/2022
 * @memberof Utils
 */
export const operatorForOpco: Record<Opco, Operator> = {
    [Opco.Austria]: Operator.A1AT,
    [Opco.Bulgaria]: Operator.A1BG,
    [Opco.Croatia]: Operator.A1HR,
    [Opco.Slovenia]: Operator.A1SI,
    [Opco.Macedonia]: Operator.A1MK,
    [Opco.Serbia]: Operator.A1RS,
};

/**
 * @description Used in AssetDetails metainfo
 * @see {AssetDetails}
 * @memberof Utils
 */
export const DETAILS_SUBTITLES = 'DETAILS_SUBTITLES';

/**
 * @enum
 * @memberof Utils
 */
export enum PlayerNotificationIcon {
    PLAY = 'play',
    PAUSE = 'pause',
}
