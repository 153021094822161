import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { channelListScreenCreateActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/actions';
import { ChannelListsScreenCreateViewActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/ChannelListsScreenCreateViewActions';
import { ChannelListsScreenCreateViewInput } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/ChannelListsScreenCreateViewInput';
import { CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/constants';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { InputElement } from 'App/Modules/Input';

export const ChannelListsScreenCreateView: React.FunctionComponent<Screen.Props<Screen.Type.CHANNEL_LISTS_CREATE>> = ({
    initialInputValue = '',
    ...props
}) => {
    const { mode, channelListId } = props;
    const ref = React.useRef<InputElement | null>(null);

    const dispatch = useDispatch();

    Focus.useBack((event) => {
        dispatch(channelListScreenCreateActions.private.back(event));
    });

    return (
        <div className="manage-name-screen">
            <Text
                className="manage-name-screen__header"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS[mode].title}
            </Text>
            <Text
                className="manage-name-screen__description"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                {CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS[mode].description}
            </Text>
            <ChannelListsScreenCreateViewInput value={initialInputValue} input={ref} />
            <ChannelListsScreenCreateViewActions
                mode={mode}
                newChannelListName={ref.current?.value?.get() ?? initialInputValue}
                channelListId={channelListId}
            />
        </div>
    );
};
