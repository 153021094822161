import { Subscribable } from 'App/Packages/Subscribable';
import React from 'react';

export type DetailActionsValue = {
    ctx: string;
    ids: Subscribable.Array<string>;
    isFocused: Subscribable.Value<boolean>;
    focused: Subscribable.Value<string>;
    enter: (id: string) => void;
    focus: (id: string) => void;
};

export const DetailActionsContext = React.createContext<DetailActionsValue>({} as DetailActionsValue);
