import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { CommunitySelectionScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen/CommunitySelectionScreenActions';
import { CommunitySelectionScreenSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen/CommunitySelectionScreenSelected';

import { Screen } from 'App/Modules/Screen';

export const CommunitySelectionScreenView: React.FunctionComponent<Screen.Props<Screen.Type.COMMUNITY_SELECTION>> = () => (
    <Layout className="community-selection" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <Text
            className="community-selection__title"
            typeface={Typeface.SANS}
            size={FontSize.TITLE_2}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            SETTINGS_REGION_SELECTION
        </Text>
        <Text
            className="community-selection__subtitle"
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            FTI_REGION_SELECTION_DESCRIPTION
        </Text>
        <CommunitySelectionScreenSelected />
        <div className="community-selection__actions">
            <CommunitySelectionScreenActions />
        </div>
    </Layout>
);
