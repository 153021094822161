/* eslint-disable max-lines */
import isEmpty from 'lodash.isempty';
import React, { PureComponent } from 'react';

import { ProductKind } from '__SMART_APP_OLD__/api/graphql/types';
import { playChannel, playVod } from '__SMART_APP_OLD__/api/Tuner';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import {
    selectBackgroundLandscapeHeight,
    selectBackgroundWidth,
    selectIsSeriesRecordingAllowed,
    selectIsVodUpsellOptions,
    selectResumeThreshold,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { reminderCancel, reminderCreate } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { selectReminderByEventId } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { selectRecordingByEventId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { AssetDetailsBody } from '__SMART_APP_OLD__/components/common/AssetDetailsBody';
import Button from '__SMART_APP_OLD__/components/common/Button';
import ContentMarkers from '__SMART_APP_OLD__/components/common/ContentMarkers';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import FavouriteButton from '__SMART_APP_OLD__/components/details/FavouriteButton';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Events, { CONTINUE_WATCHING_CHANGED, GALLERY_PLAYER_HIDE, GALLERY_PLAYER_SHOW } from '__SMART_APP_OLD__/config/Events';
import NetworkRecording from '__SMART_APP_OLD__/data/NetworkRecording';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { AssetType, PinAction, PlayerType, ProgramType, Route, StripeType } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { isCatchupAsset, isRecordingAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import State from '__SMART_APP_OLD__/utils/State';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { RecordingPlanningTypes } from 'analytics/logging/events/RecordingEvent';
import { ReminderEvents } from 'analytics/logging/events/ReminderEvent';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getDeleteRecordingEvent, getPlanRecordingEvent } from 'analytics/logging/factories/recordingEventFactory';
import { getReminderEvent } from 'analytics/logging/factories/reminderEventFactory';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Env } from 'App/Env';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Prompt } from '../../../App/Modules/Prompt';
import { PromptType } from '../../../App/Modules/Prompt/Types';

class AssetDetails extends PureComponent {
    buttons = {
        watch: {
            ref: null,
            setRef: () => {},
        },
        createRec: {
            ref: null,
            setRef: () => {},
        },
        cancelRec: {
            ref: null,
            setRef: () => {},
        },
        manageSeries: {
            ref: null,
            setRef: () => {},
        },
        delRec: {
            ref: null,
            setRef: () => {},
        },
        reminder: {
            ref: null,
            setRef: () => {},
        },
        buy: {
            ref: null,
            setRef: () => {},
        },
        subscribe: {
            ref: null,
            setRef: () => {},
        },
        rent: {
            ref: null,
            setRef: () => {},
        },
        startOver: {
            ref: null,
            setRef: () => {},
        },
        trailer: {
            ref: null,
            setRef: () => {},
        },
        fav: {
            ref: null,
            setRef: () => {},
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            isLogoLoaded: true,
            playerHiddenAfterTimeout: false,
            focused: null,
            isDeleteRecording: false,
        };
        Object.keys(this.buttons).forEach((key) => {
            this.buttons[key].setRef = this.setBtnRef(key);
        });
    }

    get focusedAsset() {
        return this.props.focusedAsset || {};
    }

    get bookmark() {
        return this.props.bookmark;
    }

    get canResume() {
        const { isPlaybackAvailable, progress, isReplayAvailable, programType } = this.focusedAsset;
        const { resumeThreshold } = this.props;
        const isLive = programType === ProgramType.LIVE;
        const liveProgress = Math.round((Date.now() - this.focusedAsset.startTime) / 1000);
        const isLiveRestartAvailable = isLive && liveProgress > 0 && isReplayAvailable;
        const isCatchupRestartAvailable = this.bookmark && progress > 0 && progress < resumeThreshold;
        return isPlaybackAvailable && (isLiveRestartAvailable || isCatchupRestartAvailable);
    }

    componentDidMount() {
        if (document.location.pathname.includes('details')) {
            this.setFocusOnBtn();
        }

        Events.addEventListener(GALLERY_PLAYER_SHOW, this.showGalleryPlayer);
        Events.addEventListener(GALLERY_PLAYER_HIDE, this.hideGalleryPlayer);
    }

    componentDidUpdate() {
        this.checkFocusedClassNameTimeoutId = setTimeout(this.checkFocusedClassName, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.checkFocusTimeoutId);
        clearTimeout(this.checkFocusedClassNameTimeoutId);
        if (!this.props.vodPurchaseOn) {
            this.props.clearVodUpsellPurchaseOff();
        }
        Events.removeEventListener(GALLERY_PLAYER_SHOW, this.showGalleryPlayer);
        Events.removeEventListener(GALLERY_PLAYER_HIDE, this.hideGalleryPlayer);
    }

    setBtnRef(key) {
        return (ref) => {
            this.buttons[key].ref = ref?.nodeRef?.nodeRef || null;
        };
    }

    onFocus() {
        State.set('Stripe', { stripeId: StripeType.ASSET_DETAILS });
    }

    showGalleryPlayer = () => {
        this.setState({ playerHiddenAfterTimeout: false });
    };

    hideGalleryPlayer = () => {
        this.setState({ playerHiddenAfterTimeout: true });
    };

    setFocusOnBtn = () => {
        const btnKey = Object.keys(this.buttons).find((key) => this.buttons[key].ref);

        if (btnKey) {
            Focus.focus(this.buttons[btnKey].ref);
            return;
        }

        if (document.getElementById('more-info')) {
            Focus.blur();
            Focus.focus(document.getElementById('more-info'));
            return;
        }

        // Need to check is focus set for any focusable element,
        //  if focus is not set => set to first focusable element
        this.checkFocusTimeoutId = setTimeout(this.checkFocus, 500);
    };

    checkFocus = () => {
        if (!Focus.focused) {
            const focusable = document.getElementsByClassName('focusable');
            Focus.focus(focusable[0]);
        }
    };

    checkFocusedClassName = () => {
        if (!Focus.focused) return;
        if (!Focus.focused.ref.classList.contains('focused')) {
            Focus.focused.ref.classList.add('focused');
        }
    };

    // for cathups
    // if startover - bookmark should point to leadIn end position
    // else - checking if real bookmark was saved
    getCatchupBookmarkPosition = (isStartOver) => {
        if (isStartOver) {
            return this.focusedAsset.leadIn;
        }
        return this.bookmark.position || this.focusedAsset.leadIn;
    };

    getIsRecorded = () => !!this.props.recording;

    getRecordingStatus = () => this.props.recording?.status;

    getIsSeriesRecording = () => this.props.recording?.isSeriesRecording;

    startPlayback = (uiActionEventType, isStartOver = false, isTrailer = false) => {
        changePlayerVisibility(true);

        const isCatchup = isCatchupAsset(this.focusedAsset);
        const isRecording = isRecordingAsset(this.focusedAsset);
        if (this.focusedAsset.programType === ProgramType.LIVE) {
            const event = getUIActionEvent(UIActionEvents.PLAY_LIVE, { id: this.focusedAsset.channelId });
            LoggingService.getInstance().logEvent(event);
            playChannel(this.focusedAsset.channelId, false, isStartOver);
        } else {
            const event = getUIActionEvent(uiActionEventType, {
                id: this.focusedAsset.id,
                assetType: this.focusedAsset.assetType,
            });
            LoggingService.getInstance().logEvent(event);
            playVod(this.focusedAsset, {
                // for catchups we do not apply startover logic and manipulate with bookmarks
                // instead, because of leadIn parameter nature
                isStartOver: isCatchup ? false : isStartOver,
                bookmarkPosition: isCatchup || isRecording ? this.getCatchupBookmarkPosition(isStartOver) : undefined,
                isTrailer,
            });
        }
    };

    onStartOverPressed = () => {
        const {
            program: { shouldRestrict },
        } = this.props;

        if (shouldRestrict) {
            openPinOverlay(() => {
                this.startPlayback(UIActionEvents.VIEW_FROM_START, true);
            });
        } else {
            this.startPlayback(UIActionEvents.VIEW_FROM_START, true);
        }
    };

    onWatchPressed = () => {
        const {
            program: { shouldRestrict },
            sessionType,
            unmount,
        } = this.props;
        const isLive = this.focusedAsset.programType === ProgramType.LIVE;
        const event = !isLive && this.canResume ? UIActionEvents.CONTINUE_WATCHING : UIActionEvents.PLAY;
        const isStartOver = !isLive && !this.canResume;

        if (shouldRestrict) {
            openPinOverlay(
                () => {
                    this.startPlayback(event, isStartOver);
                },
                unmount,
                PinAction.ENTER,
                sessionType
            );
        } else {
            this.startPlayback(event, isStartOver);
        }
    };

    onTrailerPressed = () => {
        const {
            program: { shouldRestrict },
        } = this.props;

        if (shouldRestrict) {
            openPinOverlay(() => {
                this.startPlayback(UIActionEvents.PLAY_TRAILER, false, true);
            });
        } else {
            this.startPlayback(UIActionEvents.PLAY_TRAILER, false, true);
        }
    };

    onBuyPressed = () => {
        const { id, assetType } = this.focusedAsset;
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BUY, { id, assetType }));
        this.props.setVodUpsellOperationType(ProductKind.Purchase);
        this.props.vodUpsellOptions.purchaseIsAllowed ? this.props.setVodUpsellOverlay() : this.props.setVodUpsellForbiddenPrompt();
    };

    onSubscribePressed = () => {
        const { id, assetType } = this.focusedAsset;
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BUY, { id, assetType }));
        this.props.setVodUpsellOperationType(ProductKind.SubscriptionUpsellable);
        this.props.vodUpsellOptions.subscribeIsAllowed ? this.props.setVodUpsellOverlay() : this.props.setVodUpsellForbiddenPrompt();
    };

    onRentPressed = () => {
        const { id, assetType } = this.focusedAsset;
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.RENT, { id, assetType }));
        this.props.setVodUpsellOperationType(ProductKind.Rental);
        this.props.vodUpsellOptions.rentIsAllowed ? this.props.setVodUpsellOverlay() : this.props.setVodUpsellForbiddenPrompt();
    };

    getEventId = (program) => {
        switch (program?.assetType) {
            case AssetType.EVENT:
                return program?.id;
            case AssetType.SERIES:
                return program?.seriesId;
            case AssetType.NETWORK_RECORDING:
                return program?.eventId;
            default:
                return program?.id;
        }
    };

    getRecordingId = () => {
        if (this.props.recording) return this.props.recording?.id;
        if (this.props.focusedAsset?.assetType === AssetType.NETWORK_RECORDING) return this.props.focusedAsset?.id;
        return null;
    };

    handleRedirectAfterDelRec = () => {
        // absolute hack of fix, to make it work and browser to not do shit
        // we get the last path and replace it with my librabry,
        // but then we clear the custom history so on next back yo go to home, or you need
        // to press 2 times back and now at least no strange behaviors
        CustomHistory.clear();
        this.props.toMyLibrary();
    };

    handleContinueWatchingAfterDeleteRecording = () => {
        if (!this.bookmark) return;
        Events.triggerEvents(CONTINUE_WATCHING_CHANGED);
    };

    handleLayoutChangeAfterRecordingAction() {
        if (this.props.focusedAsset instanceof NetworkRecording) this.handleRedirectAfterDelRec();
        else this.setFocusOnBtn();
    }

    async logUarEventsForCreateRecording() {
        const id = this.props.focusedAsset?.id;
        const isSeriesEvent = this.props.focusedAsset?.isSeriesEvent;
        const uiActionEvent = getUIActionEvent(UIActionEvents.RECORD, { id, assetType: this.props.focusedAsset?.assetType, isSeriesEvent });
        const recordingEvent = await getPlanRecordingEvent(id, RecordingPlanningTypes.EPG, isSeriesEvent);
        LoggingService.getInstance().logEvent(uiActionEvent);
        LoggingService.getInstance().logEvent(recordingEvent);
    }

    logUarEventsForDeleteSeriesRecording() {
        const eventId = this.getEventId(this.props.focusedAsset);
        const isFuture = this.getRecordingStatus() === GQL.RecordingStatus.Planned;
        const action = isFuture ? UIActionEvents.CANCEL_SERIES_RECORDING : UIActionEvents.DELETE_ALL_RECORDED_EPISODES;
        const event = getUIActionEvent(action, { id: eventId, assetType: this.props.focusedAsset.assetType, event: action });
        LoggingService.getInstance().logEvent(event);
        LoggingService.getInstance().logEvent(getDeleteRecordingEvent(eventId, true, isFuture));
    }

    onRecordButtonPressed = async () => {
        const { program, isSeriesRecordingAllowed } = this.props;
        const isSeries = program.isSeriesEvent || window.location.pathname.includes(AssetType.SERIES);
        const eventId = this.getEventId(this.props.focusedAsset);

        if (isSeries && isSeriesRecordingAllowed) {
            this.props.showConfirmRecordingSeriesPrompt(eventId);
        } else {
            this.props.showConfirmRecordingPrompt(eventId);
        }
        await this.logUarEventsForCreateRecording();
        this.setFocusOnBtn();
    };

    onStopRecordButtonPressed = () => {
        const isSeries = this.props.program.isSeriesEvent || window.location.pathname.includes(AssetType.SERIES);
        const event = getUIActionEvent(isSeries ? UIActionEvents.STOP_SERIES_RECORDING : UIActionEvents.STOP_RECORDING, {
            id: this.focusedAsset.id,
            assetType: this.focusedAsset.assetType,
        });

        const recordingId = this.getRecordingId();
        this.props.showConfirmCancelRecordingPrompt(recordingId);
        LoggingService.getInstance().logEvent(event);
        this.setFocusOnBtn();
    };

    onManageSeriesButtonPressed = () => {
        this.props.showConfirmCancelSeriesRecordingPrompt(this.getEventId(this.props.focusedAsset));
        this.logUarEventsForDeleteSeriesRecording();
        this.handleContinueWatchingAfterDeleteRecording(this.props.focusedAsset.bookmark);
        this.setFocusOnBtn();
    };

    onDeleteRecordingPressed = async () => {
        const recordingId = this.getRecordingId();
        this.props.showConfirmDeleteRecordingPrompt(recordingId);
        this.setFocusOnBtn();
    };

    reminderButtonHandler = () => {
        const eventType = this.props.reminder ? ReminderEvents.CANCEL : ReminderEvents.PLAN;

        if (eventType === ReminderEvents.CANCEL) {
            this.props.reminderCancel();
        } else {
            this.props.reminderSet();
        }

        LoggingService.getInstance().logEvent(getReminderEvent(eventType, this.props.focusedAsset.id));
    };

    getRecordButtons = () => (
        <Button
            key="record-button"
            className="record-button"
            dataTestId="button_record"
            label={translate('SCREEN_DETAIL_RECORD_BUTTON', 'Create recording')}
            onEnter={this.onRecordButtonPressed}
            onFocus={this.onFocus}
            ref={this.buttons.createRec.setRef}
            icon={IconName.RECORD}
            scrollTop
        />
    );

    getStopRecordButton = () => (
        <Button
            key="stop-record-button"
            className="cancel-recording-button"
            dataTestId="button_stop_record"
            label={translate('SCREEN_DETAIL_STOP_RECORDING_BUTTON', 'Cancel recording')}
            onEnter={this.onStopRecordButtonPressed}
            onFocus={this.onFocus}
            ref={this.buttons.cancelRec.setRef}
            icon={IconName.CLOSE}
            scrollTop
        />
    );

    getManageSeriesButton = () => (
        <Button
            key="manage-serries-button"
            className="manage-recording-button"
            dataTestId="manage_series_button"
            label={translate('SCREEN_DETAIL_MANAGE_SERIES_BUTTON', 'Manage series')}
            onEnter={this.onManageSeriesButtonPressed}
            onFocus={this.onFocus}
            ref={this.buttons.manageSeries.setRef}
            scrollTop
        />
    );

    getDeleteRecButton = () => (
        <Button
            key="delete-recording-button"
            className="delete-recording-button"
            label={translate('SCREEN_DETAIL_DELETE_RECORDING_BUTTON', 'Delete recording')}
            onEnter={this.onDeleteRecordingPressed}
            onFocus={this.onFocus}
            ref={this.buttons.delRec.setRef}
            icon={IconName.DELETE}
            scrollTop
        />
    );

    getReminderButton() {
        const className = this.props.reminder ? 'cancel-reminder-button' : 'set-reminder-button';
        const label = this.props.reminder ? 'SCREEN_DETAIL_CANCEL_REMINDER_BUTTON' : 'SCREEN_DETAIL_SET_REMINDER_BUTTON';
        return (
            <Button
                key="reminder-button"
                className={className}
                label={translate(label)}
                onEnter={this.reminderButtonHandler}
                onFocus={this.onFocus}
                ref={this.buttons.reminder.setRef}
                scrollTop
            />
        );
    }

    getFavoriteButton() {
        const { program, isFavouriteNeeded = true } = this.props;
        let favouriteAsset = program;
        if (
            program &&
            (program.assetType === AssetType.SERIES || program.assetType === AssetType.VOD_SERIES || program.assetType === AssetType.SEASON)
        ) {
            if (!Object.values(PlayerType).includes(this.focusedAsset.assetType)) {
                return null;
            }

            favouriteAsset = this.focusedAsset;
        }

        if (!isFavouriteNeeded || (this.canResume && window.location.pathname.includes(`/page/${Route.HOME}`))) {
            return null;
        }

        const props = {
            asset: favouriteAsset,
            onFocus: this.onFocus,
            ref: this.buttons.fav.setRef,
        };

        return <FavouriteButton {...props} key="favourite-button" />;
    }

    // eslint-disable-next-line complexity,max-statements
    getButtons = () => {
        const { isButtonsNeeded = true } = this.props;

        if (!isButtonsNeeded) return null;
        const { additionalButtons = [], playerTargetDetails } = this.props;
        const isRecordable = this.props.focusedAsset?.isRecordable;
        const isPlayerAvailable = playerTargetDetails?.isPlayerAvailable;
        const { isEntitled, isPlaybackAvailable, hasTrailer } = this.focusedAsset;
        const buttons = [];
        if (isEntitled) {
            const getPlayButtonLabel = (asset) => {
                const { assetType } = asset;
                const isLive = this.focusedAsset.programType === ProgramType.LIVE;
                const label = this.canResume && !isLive ? translate('SCREEN_DETAIL_RESUME_BUTTON') : translate('SCREEN_DETAIL_PLAY_BUTTON');
                if (assetType === AssetType.EPISODE) {
                    const { seasonOrdinal, episodeOrdinal } = asset;
                    return `${label} ${translate('HOVER_SEASON')}${seasonOrdinal} ${translate('HOVER_EPISODE')}${episodeOrdinal}`;
                }
                return label;
            };

            if (isPlaybackAvailable) {
                buttons.push(
                    <Button
                        key="watch-button"
                        className="watch-button"
                        dataTestId="button_watch"
                        label={getPlayButtonLabel(this.focusedAsset)}
                        onEnter={this.onWatchPressed}
                        onFocus={this.onFocus}
                        ref={this.buttons.watch.setRef}
                        icon={IconName.PLAY}
                        scrollTop
                    />
                );
            }

            if (this.canResume) {
                buttons.push(
                    <Button
                        key="startover-button"
                        className="startover-button"
                        dataTestId="button_startover"
                        label={translate('SCREEN_DETAIL_START_OVER_BUTTON')}
                        onEnter={this.onStartOverPressed}
                        onFocus={this.onFocus}
                        ref={this.buttons.startOver.setRef}
                        icon={IconName.START_OVER}
                        scrollTop
                    />
                );
            }

            if (
                isRecordable &&
                !window.location.pathname.includes(`/page/${Route.HOME}`) &&
                !window.location.pathname.includes(`/page/${Route.MY_LIBRARY}`) &&
                !Env.isBusiness
            ) {
                const isRecorded = this.getIsRecorded();
                const status = this.getRecordingStatus();
                const isSeriesRecording = this.getIsSeriesRecording();

                if (isRecorded && isSeriesRecording && this.props.isSeriesRecordingAllowed) buttons.push(this.getManageSeriesButton());
                if (isRecorded && status === GQL.RecordingStatus.Completed) buttons.push(this.getDeleteRecButton());
                if (isRecorded && status !== GQL.RecordingStatus.Completed) buttons.push(this.getStopRecordButton());
                if (!isRecorded) buttons.push(this.getRecordButtons());
            }

            if (this.focusedAsset.isFuture && this.focusedAsset.assetType === AssetType.EVENT) {
                buttons.push(this.getReminderButton());
            }
        }

        if (this.props.assetCanBeRented) {
            const rentButton = (
                <Button
                    key="rent-button"
                    className="rent-button"
                    dataTestId="button_rent"
                    label={translate('SCREEN_DETAIL_RENT_BUTTON')}
                    onEnter={this.onRentPressed}
                    onFocus={this.onFocus}
                    ref={this.buttons.rent.setRef}
                    icon={IconName.BUY}
                    scrollTop
                />
            );

            if (!buttons.includes(rentButton)) {
                buttons.push(rentButton);
            }
        }

        if (this.props.assetCanBeSubscribed) {
            const subscribeButton = (
                <Button
                    key="subscribe-button"
                    className="rent-button"
                    dataTestId="button_rent"
                    label={translate('SCREEN_DETAIL_SUBSCRIBE_BUTTON')}
                    onEnter={this.onSubscribePressed}
                    onFocus={this.onFocus}
                    ref={this.buttons.subscribe.setRef}
                    icon={IconName.BUY}
                    scrollTop
                />
            );

            if (!buttons.includes(subscribeButton)) {
                buttons.push(subscribeButton);
            }
        }
        if (this.props.assetCanBePurchased) {
            const buyButton = (
                <Button
                    key="buy-button"
                    className="buy-button"
                    dataTestId="button_buy"
                    label={translate('SCREEN_DETAIL_BUY_BUTTON')}
                    onEnter={this.onBuyPressed}
                    onFocus={this.onFocus}
                    ref={this.buttons.buy.setRef}
                    icon={IconName.BUY}
                    scrollTop
                />
            );

            if (!buttons.includes(buyButton)) {
                buttons.push(buyButton);
            }
        }

        if (hasTrailer) {
            buttons.push(
                <Button
                    key="trailer-button"
                    className="trailer-button"
                    dataTestId="button_trailer"
                    label={translate('SCREEN_DETAIL_TRAILER_BUTTON')}
                    onEnter={this.onTrailerPressed}
                    onFocus={this.onFocus}
                    ref={this.buttons.trailer.setRef}
                    scrollTop
                />
            );
        }

        if (!Env.isBusiness) {
            buttons.push(this.getFavoriteButton());
        }

        if (isPlayerAvailable) {
            return buttons;
        }
        return [...buttons, ...additionalButtons];
    };

    getAssetVideoQuality = () => {
        const { videoQuality, program } = this.props;
        const upsellProductQualities = {};
        program.entitlements?.items?.forEach((entitlement) => {
            if (entitlement.product) {
                upsellProductQualities[entitlement.product.videoQuality] = entitlement.product.videoQuality;
            }
        });
        return isEmpty(upsellProductQualities) ? videoQuality : upsellProductQualities;
    };

    render() {
        const { program, metaInfo, children, subtitle, playerTargetDetails, hideLiveMarker = false } = this.props;
        const { playerHiddenAfterTimeout } = this.state;
        const isPlayerAvailable = playerTargetDetails?.isPlayerAvailable;
        let programTitle = 'EPG_LOADING';
        const imageSrc = program?.landscapeImage;
        const buttons = this.getButtons();
        const currentProgramId = Player?.program?.id;
        const videoQuality = this.getAssetVideoQuality();
        if (program) {
            programTitle = program.title;
        }
        const isRecording = isRecordingAsset(this.focusedAsset);

        const { catchupTV, streamInfo, status, assetType } = this.props.focusedAsset || program;

        return (
            <AssetDetailsBody
                channelId={this.props.channelId}
                isHD={program?.programData?.isHD}
                playerView={this.props.playerView}
                title={programTitle}
                subtitle={subtitle}
                metaInfo={metaInfo}
                metaInfoComponent={
                    <div>
                        <ContentMarkers
                            key={`content-marker-${currentProgramId}`}
                            className="program-marker"
                            asset={program}
                            hideLiveMarker={hideLiveMarker}
                            hideNextMarker
                            hideNowPlayingMarker
                        />
                        <Icons
                            isDolby={streamInfo?.dolby}
                            isCatchup={catchupTV}
                            isRecording={isRecording}
                            assetType={assetType}
                            isReminderSet={!!this.props.reminder}
                            status={status}
                            videoQuality={videoQuality}
                            parentalControl={program.parentalRating}
                            audioLanguages={program.audioLanguages}
                            subtitleLanguages={program.subtitleLanguages}
                            isVisuallyImpaired={streamInfo?.visuallyImpaired}
                        />
                    </div>
                }
                imageComponent={
                    (!isPlayerAvailable || playerHiddenAfterTimeout) && (
                        <CoverImageWithFallback
                            key={imageSrc}
                            src={imageSrc}
                            alt={programTitle}
                            mask={program?.shouldMask}
                            forceLoading={!program || program?.isLoadingCell}
                        />
                    )
                }
            >
                {children}
                <Container className="buttons-container" onFocus={this.setFocusOnBtn}>
                    {!!buttons?.length && buttons}
                </Container>
            </AssetDetailsBody>
        );
    }
}

export default connect(
    (state, props) => ({
        bookmark: Bookmarks.selectors.selectEntity(props.focusedAsset.id)(state),
        reminder: selectReminderByEventId(props.focusedAsset.id)(state),
        backgroundWidth: selectBackgroundWidth(state),
        backgroundHeight: selectBackgroundLandscapeHeight(state),
        recording: selectRecordingByEventId(props.focusedAsset.id)(state),
        assetCanBeRented: VodUpsell.selectors.selectAssetCanBeRented(state),
        assetCanBePurchased: VodUpsell.selectors.selectAssetCanBePurchased(state),
        assetCanBeSubscribed: VodUpsell.selectors.selectAssetCanBeSubscribed(state),
        vodUpsellOptions: selectIsVodUpsellOptions(state),
        vodPurchaseOn: VodUpsell.selectors.selectIsPurchaseInProgress(state),
        profileId: Profile.selectors.selectId(state),
        isSeriesRecordingAllowed: selectIsSeriesRecordingAllowed(state),
        resumeThreshold: selectResumeThreshold(state),
    }),
    (dispatch, props) => ({
        notificationShow: (text) => dispatch(textNotificationShow(text)),
        saveBookmark: (payload) => dispatch(Bookmarks.actions.save(payload)),
        reminderSet: () => dispatch(reminderCreate(props.focusedAsset.id)),
        reminderCancel: () => dispatch(reminderCancel(props.focusedAsset.id)),
        showConfirmRecordingPrompt: (eventId) => dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCreate, { eventId })),
        showConfirmRecordingSeriesPrompt: (eventId) => dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCreateSeries, { eventId })),
        showConfirmDeleteRecordingPrompt: (recordingId) =>
            dispatch(Prompt.actions.mount(PromptType.RecordingConfirmDelete, { recordingId })),
        showConfirmCancelRecordingPrompt: (recordingId) =>
            dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCancel, { recordingId })),
        showConfirmCancelSeriesRecordingPrompt: (eventId) =>
            dispatch(Prompt.actions.mount(PromptType.RecordingConfirmCancelSeries, { eventId })),
        setVodUpsellOperationType: (productKind) => dispatch(VodUpsell.actions.setUpsellOperation(productKind)),
        setVodUpsellOverlay: () => dispatch(VodUpsell.actions.overlayRaised),
        setVodUpsellForbiddenPrompt: () => dispatch(VodUpsell.actions.notAllowedPromptRaised),
        clearVodUpsellPurchaseOff: () => dispatch(VodUpsell.actions.resetState()),
        unmount: () => dispatch(Overlay.actions.unmount()),
        toMyLibrary: () => dispatch(Screen.actions.mount(Screen.Type.MyLibrary, {})),
    })
)(AssetDetails);
