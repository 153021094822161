import { KeyEnterIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/Enter/KeyEnterIcon';
import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import React from 'react';

export type KeyEnterProps = KeyProps & {};

export type KeyEnterComponent = React.FunctionComponent<KeyEnterProps>;

export const KeyEnter: KeyEnterComponent = (props) => {
    const { size = 'x2', variant = 'accent', children = KeyEnterIconInsance, ...other } = props;

    return (
        <Key size={size} variant={variant} {...other}>
            {children}
        </Key>
    );
};
