import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import {
    BrandType,
    ConnectionType,
    DeviceName,
    GeneralDeviceType,
    NetworkState,
    PlatformInterface,
    TransportType,
} from '__SMART_APP_OLD__/app/platform/types';
import { generateUniqueId } from '__SMART_APP_OLD__/utils/generateId';

import { Env } from 'App/Env';
import { Key } from 'App/Modules/Key';

enum TizenSystemKey {
    TIZEN_ID = 'http://tizen.org/system/tizenid', // Remark: Tizen ID is a randomly generated value based on the model name.
    OS = 'http://tizen.org/system/platform.name',
    MANUFACTURER = 'http://tizen.org/system/manufacturer',
    MODEL = 'http://tizen.org/system/model_name',
}

const TIZEN_OS = 'Tizen';
const TIZEN_MANUFACTURER = 'Samsung';
const TIZEN_SDK_URL = '$WEBAPIS/webapis/webapis.js';

const USED_KEYS = [
    Key.VK_CHAN_UP,
    Key.VK_CHAN_DOWN,
    Key.VK_STOP,
    Key.VK_PAUSE,
    Key.VK_PLAY,
    Key.VK_PLAY_PAUSE,
    Key.VK_REWIND,
    Key.VK_FAST_FWD,
    Key.VK_RECORD,
];

const registerKeys = () => {
    if (!window.tizen) return;
    window.tizen.tvinputdevice
        .getSupportedKeys()
        .filter((key) => (key.code >= 48 && key.code <= 57) || USED_KEYS.includes(key.code))
        .forEach((key) => window.tizen.tvinputdevice.registerKey(key.name));
};

const turnScreenSaverOff = () => {
    if (!window?.webapis || typeof window?.webapis?.appcommon?.setScreenSaver !== 'function') return;
    window?.webapis?.appcommon?.setScreenSaver(window?.webapis?.appcommon?.AppCommonScreenSaverState?.SCREEN_SAVER_OFF);
};

const getTizenSystemInfo = (key: TizenSystemKey, fallback: string = 'n/a') => {
    if (!window.tizen || !window?.tizen?.systeminfo) return fallback;
    try {
        return window?.tizen?.systeminfo.getCapability(key);
    } catch (e) {
        return fallback;
    }
};

const getID = () => {
    const id = Storage.get(Storage.Key.DEVICE_ID);
    if (id) return id;
    if (!window?.webapis) return generateUniqueId();
    return window?.webapis?.productinfo.getDuid() || generateUniqueId();
};

const getOSVersion = () => Env.UserAgent.match(/tizen\s{1,}(\d[0-9]*\.{1}\d[0-9]*)/gi)?.[0]?.split?.(' ')?.[1] || 'n/a';

const getIp = () => {
    try {
        return window?.webapis?.network?.getIp?.();
    } catch (e) {
        return '0.0.0.0';
    }
};

const getWiFiSsid = () => {
    try {
        return window?.webapis?.network?.getWiFiSsid?.();
    } catch (e) {
        return 'n/a';
    }
};

const getIsOnline = () => {
    try {
        return window?.webapis?.network?.isConnectedToGateway?.();
    } catch {
        return false;
    }
};

export const getConnectionType = (): Promise<ConnectionType> => {
    try {
        /**
         * Check if the device is online.
         *  If we rely solely on {{connectionType}},
         *  it seems to still receive an online connection type such as:
         *  WIFI|CELLULAR|ETHERNET.
         */
        if (!getIsOnline()) return Promise.resolve(ConnectionType.DISCONNECTED);
        const connectionType = window?.webapis?.network?.getActiveConnectionType?.();
        switch (connectionType) {
            case window?.webapis?.network?.NetworkActiveConnectionType?.DISCONNECTED: {
                return Promise.resolve(ConnectionType.DISCONNECTED);
            }
            case window?.webapis?.network?.NetworkActiveConnectionType?.WIFI: {
                return Promise.resolve(ConnectionType.WIFI);
            }
            case window?.webapis?.network?.NetworkActiveConnectionType?.CELLULAR: {
                return Promise.resolve(ConnectionType.CELLULAR);
            }
            case window?.webapis?.network?.NetworkActiveConnectionType?.ETHERNET: {
                return Promise.resolve(ConnectionType.ETHERNET);
            }
            default: {
                return Promise.resolve(ConnectionType.OTHER);
            }
        }
    } catch {
        return Promise.resolve(ConnectionType.DISCONNECTED);
    }
};

export const getTransportType = async (): Promise<TransportType> => {
    const connectionType = await getConnectionType();
    switch (connectionType) {
        case ConnectionType.DISCONNECTED:
            return TransportType.OFFLINE;
        case ConnectionType.WIFI:
            return TransportType.WIFI;
        case ConnectionType.CELLULAR:
            return TransportType.CELLULAR;
        case ConnectionType.ETHERNET:
            return TransportType.ETHERNET;
        default:
            return TransportType.OTHER;
    }
};

const getNetworkState = async (): Promise<NetworkState> => {
    const transport = await getTransportType();
    const ipAddress = getIp();
    const networkState: NetworkState = { ipAddress, transport };
    if (transport === TransportType.WIFI) networkState.ssid = getWiFiSsid();
    return networkState;
};

const networkStateChangeHandlerGenerator = async (platform: PlatformInterface): Promise<() => void> => {
    const previousNetworkState: { value: NetworkState } = { value: await getNetworkState() };
    platform.IS_ON_LINE = getIsOnline();
    return async () => {
        platform.IS_ON_LINE = getIsOnline();
        const newNetworkState: NetworkState = await getNetworkState();
        platform.onNetworkStateChange(newNetworkState, previousNetworkState.value);
        previousNetworkState.value = newNetworkState;
    };
};

// https://developer.samsung.com/smarttv/develop/guides/fundamentals/checking-network-status.html
const attachNetworkStateListener = async (platform: PlatformInterface) => {
    const networkStateChangeHandler = await networkStateChangeHandlerGenerator(platform);
    const networkListenerId = window?.webapis?.network?.addNetworkStateChangeListener?.(networkStateChangeHandler);
    if (typeof networkListenerId !== 'number' || networkListenerId === -1) {
        window.addEventListener('offline', networkStateChangeHandler);
        window.addEventListener('online', networkStateChangeHandler);
    }
};

const isVersion = (version: string): boolean => Env.IsTizen && Env.UserAgent.includes(version);

export const initializeTizen = async (platform: PlatformInterface): Promise<void> => {
    await Common.loadDeviceSDK(TIZEN_SDK_URL);
    registerKeys();
    turnScreenSaverOff();
    platform.DEVICE_TYPE = GQL.DeviceType.SmartTvTizen;
    platform.GENERAL_DEVICE_TYPE = GeneralDeviceType.TV;
    platform.DEVICE_NAME = DeviceName.SAMSUNG_TV;
    platform.OS = getTizenSystemInfo(TizenSystemKey.OS, TIZEN_OS);
    platform.MODEL = getTizenSystemInfo(TizenSystemKey.MODEL);
    platform.MANUFACTURER = getTizenSystemInfo(TizenSystemKey.MANUFACTURER, TIZEN_MANUFACTURER);
    platform.OS_VERSION = getOSVersion();
    platform.ID = getID();
    platform.BRAND = BrandType.SAMSUNG;
    platform.getConnectionType = getConnectionType;
    platform.getTransportType = getTransportType;
    platform.getNetworkState = getNetworkState;
    platform.isVersion = isVersion;
    attachNetworkStateListener(platform);
    return Promise.resolve();
};
