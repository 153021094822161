import React, { useCallback, useMemo, useRef } from 'react';

import { Constants } from 'App/Packages/UI/Folder/Constants';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';
import { Subscribable } from 'App/Packages/Subscribable';
import { FolderListValue } from 'App/Packages/UI/Folder/Modules/FolderList/Context/FolderListContext';

export type UseFolderScrollIntoViewGetTopPayload = {
    default: number;
    list: FolderListValue;
    target: HTMLElement;
    container: HTMLElement;
};

export type UseFolderScrollIntoViewGetTop = (payload: UseFolderScrollIntoViewGetTopPayload) => number;

export type UseFolderScrollIntoView = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    getTop?: UseFolderScrollIntoViewGetTop
) => (() => Promise<void>) & { instant: Subscribable.Value<boolean> };

const getTopDefault: UseFolderScrollIntoViewGetTop = (base) => base.default;

export const useFolderScrollIntoView: UseFolderScrollIntoView = (ref, getTop = getTopDefault) => {
    const list = FolderList.use();
    const instant = Subscribable.useValue(false);
    const getTopRef = useRef(getTop);
    getTopRef.current = getTop;

    const scrollIntoView = useCallback(async () => {
        const isInstant = instant.get();
        instant.set(false);

        const target = ref.current;
        const container = list.ref.current;
        if (!target || !container) return;
        const base = Constants.List.Scroll.Offset.calc(target.offsetTop - container.offsetTop - list.offsetTop);
        const payload: UseFolderScrollIntoViewGetTopPayload = { default: base, list, target, container };
        const top = getTopRef.current(payload);
        const duration = isInstant ? { max: 0, min: 0, ratio: 0 } : undefined;
        list.scroll.to({ top, duration });
    }, [ref, instant, list]);

    return useMemo(() => Object.assign(scrollIntoView, { instant }), [scrollIntoView, instant]);
};
