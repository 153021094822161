import React from 'react';

import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { FolderList as FolderListModule } from 'App/Packages/UI/Folder/Modules/FolderList';
import { FolderListProps as FolderListModuleProps } from 'App/Packages/UI/Folder/Modules/FolderList/Types';

const getStyles = (paddingTop: number, styles: React.CSSProperties = {}) => ({ ...styles, paddingTop: `${paddingTop}px` });

const FolderListInternal: React.FunctionComponent<DivPropsExtendable> = (props) => {
    const list = FolderListModule.use();
    const { style, ...rest } = props;
    return <Div {...rest} defaultClassName="folder-list" style={getStyles(list.offsetTop, style)} ref={list.ref} />;
};

export type FolderListProps = DivPropsExtendable & FolderListModuleProps;

export type FolderListComponent = React.FunctionComponent<FolderListProps>;

export const FolderList: FolderListComponent = (props) => {
    const { ctx, offsetTop, disabled, onEnter, ...rest } = props;

    return (
        <FolderListModule ctx={ctx} offsetTop={offsetTop} disabled={disabled} onEnter={onEnter}>
            <FolderListInternal {...rest} />
        </FolderListModule>
    );
};
