import React from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { TVGuideBackgroundImage } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideBackgroundImage';

export const TVGuideBackground: React.FunctionComponent = () => (
    <div className="tv-guide__background">
        <TVGuideBackgroundImage />
        <Div className="tv-guide__left-gradient" backgroundColor={BackgroundColor.LEFT_GRADIENT} />
        <Div className="tv-guide__bottom-gradient" backgroundColor={BackgroundColor.BOTTOM_GRADIENT} />
    </div>
);
