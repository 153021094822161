import React from 'react';

import { Logo } from 'App/Packages/UI/Logo';

const getClassName = (className?: string): string => {
    const classList = ['qr-code__logo'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type QRCodeLogoProps = {
    className?: string;
};

export type QRCodeLogoModule = React.FunctionComponent<QRCodeLogoProps>;

export const QRCodeLogo: QRCodeLogoModule = (props) => (
    <Logo.XTV className={getClassName(props.className)} tvTextClassName="qr-code__tv-text" />
);
