import { Direction } from 'App/Packages/Direction';
import { NavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';

export class KeyEvent extends NavigationEvent {
    public readonly key: number;

    public readonly isKeyEvent: boolean = true;

    public constructor(key: number) {
        super(...Direction.get(key));
        this.key = key;
    }
}
