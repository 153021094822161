/* eslint-disable max-statements */
/* eslint-disable react-func/max-lines-per-function */
import { MqttClient } from 'mqtt';
import mqtt from 'mqtt/dist/mqtt';
import { useEffect, useState } from 'react';

import { getToken } from '__SMART_APP_OLD__/api/Services';
import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIsLoggedIn } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { messageLoadById } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { reminderLoadFuture } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { recordingsLoadMany } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { isJson } from '__SMART_APP_OLD__/utils/Utils';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';

export enum Topics {
    RECORDINGS = 'NPVRS_CHG',
    REMINDERS = 'REMINDERS_CHG',
    NOTIFICATION = 'NOTIF',
}

export const useMqtt = () => {
    const [isMqttEnabled, setMqttEnabled] = useState(false);
    const store = useStore();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const mqttTopics = useSelector(Household.selectors.selectMqttTopics);
    const mqttBrokerUrl = useSelector(Household.selectors.selectMqttBrokerUrl);

    useEffect(() => {
        let mqttClient: MqttClient;

        const connectToMQTT = async () => {
            try {
                if (!mqttBrokerUrl) {
                    console.log('[MQTT] Broker URL not provided');
                    return;
                }
                if (mqttTopics.length === 0 || !isLoggedIn) {
                    console.log('[MQTT] User not authenticated or MQTT not configured');
                    return;
                }
                const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN) ?? false;
                let token: string | undefined = '';
                if (isIdpLogin) {
                    token = Storage.get(Storage.Key.IDP_ACCESS_TOKEN);
                } else {
                    const { token: legacyToken } = await getToken();
                    token = legacyToken;
                }
                if (!token) {
                    console.log('[MQTT] no token to be used provided');
                    return;
                }

                mqttClient = mqtt.connect(`${mqttBrokerUrl}/mqtt`, {
                    username: Platform.ID,
                    password: token,
                });

                mqttClient.on('connect', () => {
                    console.log('[MQTT] Connected to MQTT broker');
                    setMqttEnabled(true);

                    mqttClient.subscribe(mqttTopics, (error) => {
                        if (error) {
                            console.error(`[MQTT] Failed to subscribe: ${error}`);
                        } else {
                            console.log(`[MQTT] Subscribed to topics: ${mqttTopics.toString()}`);
                        }
                    });
                });

                mqttClient.on('error', (error) => {
                    console.error(`[MQTT] Error connecting to MQTT broker: ${error}`);
                });

                mqttClient.on('offline', () => {
                    console.error('[MQTT] MQTT broker is OFFLINE or does NOT exist');
                    mqttClient.end();
                });

                mqttClient.on('message', async (topic, message) => {
                    const payload = message.toString('utf-8');
                    const isJsonString = isJson(payload);
                    if (!isJsonString) return;
                    const { type } = JSON.parse(payload);

                    switch (type) {
                        case Topics.RECORDINGS: {
                            console.log('[MQTT] Recordings changed, refreshing MyLibrary');
                            await store.dispatch(recordingsLoadMany());
                            break;
                        }
                        case Topics.REMINDERS: {
                            console.log('[MQTT] Reminders changed, updating Reminders');
                            await store.dispatch(reminderLoadFuture());
                            break;
                        }
                        case Topics.NOTIFICATION: {
                            try {
                                console.log('[MQTT] Notification received, updating notifications');
                                const { args } = JSON.parse(message.toString()); // TODO: Check if .toString() is required.
                                await store.dispatch(messageLoadById(args.notif_id));
                            } catch (error) {
                                console.error(`[MQTT] Error fetching notification: ${error}`);
                            }
                            break;
                        }
                        default: {
                            console.log('[MQTT] Unknown message type');
                            break;
                        }
                    }
                });
            } catch (e) {
                console.error(`[MQTT] Error connecting to MQTT broker threw: ${e}`);
            }
        };
        try {
            connectToMQTT();
        } catch (e) {
            console.error(`[MQTT] Error connecting to MQTT broker threw: ${e}`);
        }

        return () => {
            if (!mqttClient) return;

            if (mqttTopics) {
                mqttClient.unsubscribe(mqttTopics, (error: Error) => {
                    if (error) {
                        console.error(`[MQTT] Failed to subscribe: ${error}`);
                    } else {
                        console.log(`[MQTT] Unsubscribed to topics: ${mqttTopics.toString()}`);
                    }
                });
            }

            mqttClient.end(false, () => {
                console.log('[MQTT] Disconnected from MQTT broker');
                setMqttEnabled(false);
            });
        };
    }, [store, isLoggedIn, mqttTopics, mqttBrokerUrl]);

    return isMqttEnabled;
};
