import { FetchPolicy } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useGetEventDetailLazyQuery } from '__SMART_APP_OLD__/api/graphql/query/getEventDetail.generated';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { BaseDetailPage } from '__SMART_APP_OLD__/components/details/BaseDetailPage';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import ProgramEvent from '__SMART_APP_OLD__/data/ProgramEvent';

import { UI } from 'App/Packages/UI';

import { setDefaultVariables } from './detailUtils';

// TODO: Kris, old event detail page to be deleted.
type PropType = {
    id: string;
};

const detailVariables = {
    variables: {
        eventId: '',
        profileId: '',
        firstFolders: 20,
        firstItems: 30,
        lastItems: 15,
        thumbnailHeight: 280,
        backgroundWidth: 1920,
        backgroundHeight: 780,
        channelLogoHeight: 216,
        channelLogoWidth: 384,
        foldersAfterCursor: null,
        itemsAfterCursor: null,
        itemsBeforeCursor: null,
    },
    fetchPolicy: 'network-only' as FetchPolicy,
};

export const ProgramEventDetailPage: React.FC<PropType> = ({ id }) => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const [asset, setAsset] = useState<ProgramEvent | null>(null);
    const [relatedStripeData, setRelatedStripeData] = useState([]);
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [loadedAssetId, setLoadedAssetId] = useState<string>('');

    const setDetailVariables = () => {
        setDefaultVariables(detailVariables, config);
        detailVariables.variables.eventId = id;

        return detailVariables;
    };

    const [loadEventDetail, { data: lazyData, loading: lazyLoading, error: lazyError }] = useGetEventDetailLazyQuery(setDetailVariables());

    const getRelatedStripe = () =>
        relatedStripeData.map((stripe: any, index) => {
            const { assets } = stripe;
            return (
                <Stripe
                    stripeId={stripe.stripeId}
                    isRelated={true}
                    dataTestId="stripe_related"
                    key={`related-stripe-${index}`}
                    className={`stripe related`}
                    assets={assets}
                    title={stripe.title}
                    sessionType={PinSessionType.PIN_LIVE_TV}
                />
            );
        });

    useEffect(() => {
        if (loadedAssetId !== undefined && loadedAssetId !== id) {
            loadEventDetail(setDetailVariables());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (lazyData) {
            const parsedData = createAssetObject(lazyData.event, lazyData.event?.__typename);
            if (parsedData.channelId) {
                dispatch(
                    loadEventsForChannel(
                        parsedData.channelId,
                        parsedData.startTime.getTime(),
                        parsedData.endTime.getTime() + Time.MINUTE_MS
                    )
                );
            }
            setAsset(parsedData);
            setRelatedStripeData(parsedData?.relatedContent);
            setLoadingState(false);
            setLoadedAssetId(id);
        }
    }, [lazyData, lazyLoading, lazyError, id, dispatch]);

    return loadingState ? (
        <Layout backgroundColor={BackgroundColor.PRIMARY}>
            <UI.Spinner />
        </Layout>
    ) : (
        <BaseDetailPage asset={asset} programType={null} getRelatedStripe={getRelatedStripe} sessionType={PinSessionType.PIN_LIVE_TV} />
    );
};
