const selectFocusedIndex = (state: any) => state?.header?.focusedIndex;
const selectSelectedIndex = (state: any) => state?.header?.selectedIndex;
const selectSelectedIndexesHistory = (state: any) => state?.header?.selectedIndexesHistory;

export const headerSelectors = {
    private: {},
    public: {
        selectFocusedIndex,
        selectSelectedIndex,
        selectSelectedIndexesHistory,
    },
};
