import { channelListRename } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListsScreenCreateCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/ChannelListsScreenCreateCtx';
import {
    ChannelListsScreenViewMode,
    ChannelListsCreateViewActionId,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';
import { ChannelListsScreenManageMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { Key } from 'App/Modules/Key';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Input));
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === ChannelListsScreenCreateCtx.Input && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Actions));
    }

    if (ctx === ChannelListsScreenCreateCtx.Actions && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Input));
    }

    return undefined;
};

const back =
    (event: { key: number }): Thunk<void> =>
    (dispatch, getState) => {
        const ctx = Screen.selectors.selectCtx(getState());
        if (ctx === ChannelListsScreenCreateCtx.Input && event.key === Key.VK_BACK_SPACE) return;
        if (ctx === ChannelListsScreenCreateCtx.Input && event.key === Key.VK_BACK) {
            dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Actions));
            return;
        }

        dispatch(History.actions.pop());
    };

const enter =
    (
        actionId: ChannelListsCreateViewActionId,
        mode: ChannelListsScreenViewMode,
        newChannelListName: string,
        channelListId?: string
    ): Thunk<void> =>
    async (dispatch) => {
        if (actionId === ChannelListsCreateViewActionId.CANCEL) {
            dispatch(History.actions.pop());
            return;
        }

        if (mode === ChannelListsScreenViewMode.CREATE) {
            if (!newChannelListName.trim()) {
                dispatch(textNotificationShow('NOTIFICATION_CHANNEL_LIST_EMPTY_NAME'));
                return;
            }

            dispatch(Screen.actions.update(Screen.Type.CHANNEL_LISTS_CREATE, { initialInputValue: newChannelListName }));
            dispatch(History.actions.push());
            dispatch(
                Screen.actions.mount(
                    Screen.Type.CHANNEL_LISTS_MANAGE,
                    {
                        channelListId,
                        channelListName: newChannelListName,
                        mode: ChannelListsScreenManageMode.CREATE,
                    },
                    ChannelListsScreenCtx.CreateChannelList
                )
            );
            return;
        }

        if (mode === ChannelListsScreenViewMode.EDIT && !!channelListId) {
            if (!newChannelListName.trim()) {
                dispatch(textNotificationShow('NOTIFICATION_CHANNEL_LIST_EMPTY_NAME'));
                return;
            }

            await dispatch(channelListRename(channelListId, newChannelListName));
            dispatch(History.actions.pop());
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.CHANNEL_LIST_CHANGED, { id: channelListId }));
            dispatch(textNotificationShow('NOTIFICATION_CHANNEL_LIST_RENAMED_SUCCESSFULLY'));
        }
    };

const inputEntered = (): Thunk<void> => (dispatch) =>
    dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_CREATE, ChannelListsScreenCreateCtx.Actions));

export const channelListScreenCreateActions = {
    private: {
        back,
        enter,
        inputEntered,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
