import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '__SMART_APP_OLD__/components/common/Button';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { FontSize } from '__SMART_APP_OLD__/app/components/Text';

import { Function } from 'App/Packages/Function';

const SCROLL_OFFSET = 300;

const SearchSuggestions = ({ tags, onSuggestionPressed, onFocusChanged = Function.noop, focusPlaced, showSuggestions }) => {
    const [suggestions, setSuggestions] = useState([]);
    const firstButtonRef = useRef(null);
    const containerRef = useRef(null);
    const isSuggestionsHoverDisabledRef = useRef(false);

    const handleFocusChanged = (focusedNode, prevNode) => {
        onFocusChanged(prevNode);

        const focused = focusedNode.nodeRef;
        const container = containerRef.current?.nodeRef?.nodeRef;
        if (!focused?.classList?.contains?.('focused')) return true;
        if (!container || !focused) {
            return false;
        }

        const focusedBoundingClientRect = focused.getBoundingClientRect();
        const containerBoundingClientRect = container.getBoundingClientRect();

        let scrollAmount = 0;

        if (focusedBoundingClientRect.left < SCROLL_OFFSET) {
            scrollAmount = focusedBoundingClientRect.left - SCROLL_OFFSET;
        } else if (focusedBoundingClientRect.right > containerBoundingClientRect.right - SCROLL_OFFSET) {
            scrollAmount = focusedBoundingClientRect.right - (containerBoundingClientRect.right - SCROLL_OFFSET);
        }

        if (scrollAmount) {
            container.scrollLeft += scrollAmount;
        }

        return true;
    };

    const handleMouseMove = useCallback((event) => {
        const container = containerRef.current?.nodeRef?.nodeRef;

        if (container.contains(event.target)) {
            return isSuggestionsHoverDisabledRef.current;
        }

        return false;
    }, []);

    const handleSuggestionsPressed = useCallback(
        (suggestion) => {
            isSuggestionsHoverDisabledRef.current = true;
            onSuggestionPressed(suggestion);
        },
        [onSuggestionPressed]
    );

    useEffect(() => {
        isSuggestionsHoverDisabledRef.current = false;
        setSuggestions(tags);
    }, [tags]);

    useEffect(() => {
        if (!focusPlaced && showSuggestions) {
            Focus.focus(firstButtonRef.current);
        }
    }, [focusPlaced, showSuggestions]);

    if (!suggestions || suggestions.length === 0) {
        return null;
    }

    return (
        <>
            <Container
                className="search-suggestions-container"
                ref={containerRef}
                onFocusChanged={handleFocusChanged}
                onMouseMove={handleMouseMove}
            >
                {suggestions.map((suggestion, index) => (
                    <Button
                        className="suggestion-button"
                        label={suggestion}
                        size={FontSize.BODY_2}
                        key={`suggestion-button-${suggestion}`}
                        ref={(node) => {
                            if (node) {
                                if (index === 0) {
                                    firstButtonRef.current = node.nodeRef;

                                    if (!focusPlaced) {
                                        Focus.focus(firstButtonRef.current?.nodeRef?.nodeRef);
                                    }
                                }
                            }
                        }}
                        onEnter={() => handleSuggestionsPressed(suggestion)}
                    />
                ))}
            </Container>
        </>
    );
};

export default SearchSuggestions;
