import { Calc } from 'App/Packages/Calc';

export const Constants = {
    Offset: {
        Top: Calc.pixel(64),
    },
    Ctx: {
        Field: 'Search.Field',
        Suggestions: 'Search.Suggestions',
        Results: 'Search.Results',
    },
    Suggestion: {
        Offset: {
            Left: Calc.pixel(96),
            Margin: Calc.pixel(8),
        },
    },
} as const;
