import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
export type ChannelFolderItemFragment = { __typename: 'Channel', id: string, title: string, description?: string | null, logoImageAlias?: { __typename: 'Image', id: string, url?: string | null } | null, userInfo?: { __typename?: 'UserChannelInfo', id: string, subscribed: boolean } | null };

export const ChannelFolderItemFragmentDoc = gql`
    fragment channelFolderItem on Channel {
  id
  title
  description
  logoImageAlias: logo(width: 384, height: 216) {
    ...image
  }
  userInfo {
    id
    subscribed
  }
  __typename
}
    ${ImageFragmentDoc}`;