import { HeaderComponent, HeaderProps, Header as Root } from 'App/Packages/UI/Header/Components/Header';
import { HeaderClock, HeaderClockComponent, HeaderClockProps } from 'App/Packages/UI/Header/Components/HeaderClock';
import { HeaderIconItem, HeaderIconItemComponent, HeaderIconItemProps } from 'App/Packages/UI/Header/Components/HeaderIconItem';
import {
    HeaderIconItemChannelLists,
    HeaderIconItemChannelListsComponent,
    HeaderIconItemChannelListsProps,
} from 'App/Packages/UI/Header/Components/HeaderIconItemChannelLists';
import {
    HeaderIconItemMessageInbox,
    HeaderIconItemMessageInboxComponent,
    HeaderIconItemMessageInboxProps,
} from 'App/Packages/UI/Header/Components/HeaderIconItemMessageInbox';
import {
    HeaderIconItemSearch,
    HeaderIconItemSearchComponent,
    HeaderIconItemSearchProps,
} from 'App/Packages/UI/Header/Components/HeaderIconItemSearch';
import {
    HeaderIconItemSettings,
    HeaderIconItemSettingsComponent,
    HeaderIconItemSettingsProps,
} from 'App/Packages/UI/Header/Components/HeaderIconItemSettings';
import { HeaderLogo, HeaderLogoComponent, HeaderLogoProps } from 'App/Packages/UI/Header/Components/HeaderLogo';
import { HeaderMain, HeaderMainComponent, HeaderMainProps } from 'App/Packages/UI/Header/Components/HeaderMain';
import { HeaderMenuItem, HeaderMenuItemComponent, HeaderMenuItemProps } from 'App/Packages/UI/Header/Components/HeaderMenuItem';
import {
    HeaderItemIndicator,
    HeaderItemIndicatorComponent,
    HeaderItemIndicatorProps,
} from 'App/Packages/UI/Header/Components/HeaderMenuItemIndicator';
import {
    HeaderMenuItemLabel,
    HeaderMenuItemLabelComponent,
    HeaderMenuItemLabelProps,
} from 'App/Packages/UI/Header/Components/HeaderMenuItemLabel';
import { HeaderStatic, HeaderStaticComponent, HeaderStaticProps } from 'App/Packages/UI/Header/Components/HeaderStatic';
import { Constants } from 'App/Packages/UI/Header/Constants';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';
import { HeaderItemModule } from 'App/Packages/UI/Header/Modules/HeaderItem/Types';
import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';
import { HeaderItemsModule } from 'App/Packages/UI/Header/Modules/HeaderItems/Types';

// Root
export type { HeaderComponent, HeaderProps };

// Logo
export type { HeaderLogoComponent, HeaderLogoProps };

// Clock
export type { HeaderClockComponent, HeaderClockProps };

// Main
export type { HeaderMainComponent, HeaderMainProps };

// Static
export type { HeaderStaticComponent, HeaderStaticProps };

// MenuItem
export type { HeaderMenuItemComponent, HeaderMenuItemProps };

// MenuItemIndicator
export type { HeaderItemIndicatorComponent, HeaderItemIndicatorProps };

// MenuItemLabel
export type { HeaderMenuItemLabelComponent, HeaderMenuItemLabelProps };

// IconItem
export type { HeaderIconItemComponent, HeaderIconItemProps };

// IconItemChannelLists
export type { HeaderIconItemChannelListsComponent, HeaderIconItemChannelListsProps };

// IconItemMessageInbox
export type { HeaderIconItemMessageInboxComponent, HeaderIconItemMessageInboxProps };

// IconItemSearch
export type { HeaderIconItemSearchComponent, HeaderIconItemSearchProps };

// IconItemSettings
export type { HeaderIconItemSettingsComponent, HeaderIconItemSettingsProps };

// Modules
export * from 'App/Packages/UI/Header/Modules/HeaderItem/Types';
export * from 'App/Packages/UI/Header/Modules/HeaderItems/Types';

export type HeaderMenuItemModules = {
    // Components
    Indicator: HeaderItemIndicatorComponent;
    Label: HeaderMenuItemLabelComponent;
};

export type HeaderMenuItemModule = HeaderMenuItemComponent & HeaderMenuItemModules;

export type HeaderIconItemModules = {
    // Components
    ChannelLists: HeaderIconItemChannelListsComponent;
    MessageInbox: HeaderIconItemMessageInboxComponent;
    Search: HeaderIconItemSearchComponent;
    Settings: HeaderIconItemSettingsComponent;
};

export type HeaderIconItemModule = HeaderIconItemComponent & HeaderIconItemModules;

export type HeaderModules = {
    // Constants
    Constants: typeof Constants;

    // Components
    Logo: HeaderLogoComponent;
    Clock: HeaderClockComponent;
    Main: HeaderMainComponent;
    Static: HeaderStaticComponent;
    MenuItem: HeaderMenuItemModule;
    IconItem: HeaderIconItemModule;

    // Modules
    Items: HeaderItemsModule;
    Item: HeaderItemModule;
};

export type HeaderModule = HeaderComponent & HeaderModules;

export const Header: HeaderModule = Object.assign(Root, {
    // Constants
    Constants,

    // Components
    Logo: HeaderLogo,
    Clock: HeaderClock,
    Main: HeaderMain,
    Static: HeaderStatic,
    MenuItem: Object.assign(HeaderMenuItem, {
        // Components
        Indicator: HeaderItemIndicator,
        Label: HeaderMenuItemLabel,
    }),
    IconItem: Object.assign(HeaderIconItem, {
        // Components
        ChannelLists: HeaderIconItemChannelLists,
        MessageInbox: HeaderIconItemMessageInbox,
        Search: HeaderIconItemSearch,
        Settings: HeaderIconItemSettings,
    }),

    // Modules
    Items: HeaderItems,
    Item: HeaderItem,
});
