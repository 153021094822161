import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';

import { Registry } from 'App/Modules/Prompt/Registry';
import { actions } from 'App/Modules/Prompt/Store/actions';
import { selectors } from 'App/Modules/Prompt/Store/selectors';
import { Focus } from 'App/Packages/Focus';

export const View: React.FunctionComponent = () => {
    const prompt = useSelector(selectors.public.select);
    if (!prompt) return null;
    const [Component, lifecycle] = Registry.get(prompt.type);

    return (
        <Focus.Provider
            ctx={selectors.public.selectCtx}
            setCtx={actions.public.ctx.set}
            isActive={selectors.public.selectIsActive}
            outOfBounds={lifecycle.outOfBounds}
        >
            <Component {...prompt.props} />
        </Focus.Provider>
    );
};
