import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelListFragment = { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind };

export const ChannelListFragmentDoc = gql`
    fragment channelList on ChannelList {
  id
  name
  kind
  __typename
}
    `;