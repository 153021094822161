import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentFragmentDoc, ParentalRatingFragmentFragmentDoc, TrailersFragmentFragmentDoc, VodAssetEntitlementCatalogFragmentFragmentDoc, VodAssetEntitlementFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc, CacheInfoFragmentFragmentDoc, PageInfoFragmentFragmentDoc, ChannelLogosFragmentFragmentDoc, PersonalChannelInfoFragmentFragmentDoc, ChannelEntitlementsFragmentFragmentDoc, EventEntitlementsFragmentFragmentDoc, PersonalEventInfoFragmentFragmentDoc, MetadataFragmentFragmentDoc, PersonalRecordingInfoFragmentFragmentDoc, GenreFragmentFragmentDoc, VodProductFrogmentFragmentDoc, PersonalVodInfoFragmentFragmentDoc, PriceFragmentFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/api/graphql/query/fragments.generated';
export type BannerFolderItemFragment = { __typename: 'Banner', id: string, link: string, thumbnailAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, backgroundImageAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean } };

export const BannerFolderItemFragmentDoc = gql`
    fragment bannerFolderItem on Banner {
  id
  link
  thumbnailAlias: thumbnail(height: $thumbnailHeight) {
    ...imageFragment
  }
  backgroundImageAlias: backgroundImage(width: 1920, height: 1080) {
    ...imageFragment
  }
  parentalRating {
    ...parentalRatingFragment
  }
  __typename
}
    ${ImageFragmentFragmentDoc}
${ParentalRatingFragmentFragmentDoc}`;