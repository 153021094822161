import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    ChannelAudioRelationEntityTable,
    ChannelBlockedRelationEntityTable,
    ChannelEntity,
    ChannelReplayPermissionRelationEntityTable,
    ChannelSubtitleRelationEntityTable,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';

export const toEntity = (channel: GQL.Channel): ChannelEntity => ({
    id: channel.id,
    kind: channel.kind,
    title: channel.title,
    defaultNumber: channel.defaultNumber ?? 0,
    parentalRatingId: channel.parentalRating.id,
    logo: {
        LIGHT: channel.NORMAL?.url ?? null,
        DARK: channel.INVERTED?.url ?? null,
    },
    background: channel.backgroundImage?.url ?? null,
    playBackground: channel.playBackgroundImage?.url ?? null,
    afterTime: channel.afterTime ?? null,
    beforeTime: channel.beforeTime ?? null,
    liveTV: channel.entitlements?.liveTV ?? false,
    pauseLiveTV: channel.entitlements?.pauseLiveTV ?? false,
    restartTV: channel.entitlements?.restartTV ?? false,
    catchupTV: channel.entitlements?.catchupTV ?? false,
    networkRecording: channel.entitlements?.networkRecording ?? false,
});

export const toEntities = (channels: GQL.Channel[]): Record<GQL.ChannelID, ChannelEntity> =>
    channels.reduce<Record<GQL.ChannelID, ChannelEntity>>((acc, ch) => {
        acc[ch.id] = toEntity(ch);
        return acc;
    }, {});

export const toAudioRelationEntityTable = (channels: GQL.Channel[]): ChannelAudioRelationEntityTable =>
    channels.reduce<ChannelAudioRelationEntityTable>((acc, ch) => {
        acc[ch.id] = ch.personalInfo?.audioLanguage ?? '';
        return acc;
    }, {});

export const toSubtitleRelationEntityTable = (channels: GQL.Channel[]): ChannelSubtitleRelationEntityTable =>
    channels.reduce<ChannelSubtitleRelationEntityTable>((acc, ch) => {
        acc[ch.id] = ch.personalInfo?.subtitleLanguage ?? '';
        return acc;
    }, {});

export const toBlockedRelationEntityTable = (channels: GQL.Channel[]): ChannelBlockedRelationEntityTable =>
    channels.reduce<ChannelBlockedRelationEntityTable>((acc, ch) => {
        acc[ch.id] = ch.personalInfo?.blocked ?? false;
        return acc;
    }, {});

export const toReplayPermissionRelationEntityTable = (channels: GQL.Channel[]): ChannelReplayPermissionRelationEntityTable =>
    channels.reduce<ChannelReplayPermissionRelationEntityTable>((acc, ch) => {
        acc[ch.id] = ch.entitlements?.householdConfirmedReplayPermissions ?? null;
        return acc;
    }, {});
