import React from 'react';
import { shallowEqual } from 'react-redux';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { tvGuideClickTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import {
    selectTVGuideIsTimelineFocused,
    selectTVGuideTimelineDate,
    selectTVGuideTimelineEntries,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { TVGuideCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/types';

import { Screen } from 'App/Modules/Screen';
import { Function } from 'App/Packages/Function';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { Env } from 'App/Env';

const handleMouseLeaveAndOverEvents = (ctx: TVGuideCTX) => {
    if (!Env.IsMouseSupported) return;
    if (ctx === TVGuideCTX.GRID) store.dispatch(Screen.actions.ctx.changed(Screen.Type.TV_GUIDE, TVGuideCTX.GRID));
    if (ctx === TVGuideCTX.TIMELINE) store.dispatch(Screen.actions.ctx.changed(Screen.Type.TV_GUIDE, TVGuideCTX.TIMELINE));
};

export const TVGuideTimeline: React.FunctionComponent = () => {
    const date = useSelector(selectTVGuideTimelineDate);
    const entries = useSelector(selectTVGuideTimelineEntries, shallowEqual);
    const isFocused = useSelector(selectTVGuideIsTimelineFocused);
    const dispatch = useDispatch();

    if (isFocused) {
        return (
            <div
                className="tv-guide__timeline"
                onMouseLeave={() => handleMouseLeaveAndOverEvents(TVGuideCTX.GRID)}
                onClick={() => dispatch(tvGuideClickTriggered(isFocused))}
            >
                <Button
                    id={TVGuideCTX.TIMELINE}
                    ctx={TVGuideCTX.TIMELINE}
                    className="tv-guide__date-picker"
                    isFocused
                    onEnter={Function.noop}
                    onHover={Function.noop}
                >
                    {date}
                </Button>
            </div>
        );
    }

    return (
        <div className="tv-guide__timeline" onMouseOver={() => handleMouseLeaveAndOverEvents(TVGuideCTX.TIMELINE)}>
            <Text
                className="tv-guide__date"
                typeface={Typeface.SERIF}
                size={FontSize.BODY_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {date}
            </Text>
            <div className="tv-guide__hours">
                {entries.map((entry) => (
                    <Text key={entry} typeface={Typeface.SANS} size={FontSize.BODY_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
                        {entry}
                    </Text>
                ))}
            </div>
        </div>
    );
};
