import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { initialFtiState } from '__SMART_APP_OLD__/app/modules/Fti/store/constants';
import { FtiCompletedTable } from '__SMART_APP_OLD__/app/modules/Fti/store/types';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Env } from 'App/Env';

export const getInitialFtiState = (): FtiCompletedTable => ({
    ...initialFtiState,
    languageStepCompleted: Language.isSet() || Env.isBusiness ? 1 : 0,
    themeStepCompleted: Theme.isSet() ? 1 : 0,
    startupStepCompleted: Startup.isSet() || Env.isBusiness ? 1 : 0,
    educationStepCompleted: Storage.get(Storage.Key.EDUCATION_STEP_COMPLETED) ?? 0,
});
