import React, { useRef } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

import { useBack as useBackHook } from 'App/Packages/Focus/Hooks/useBack';
import { SelectContext } from 'App/Packages/UI/Select/SelectContext';
import { SelectFooter, SelectFooterModule } from 'App/Packages/UI/Select/SelectFooter';
import { SelectHeader, SelectHeaderModule, SelectHeaderProps } from 'App/Packages/UI/Select/SelectHeader';
import { SelectOption, SelectOptionModule, SelectOptionProps } from 'App/Packages/UI/Select/SelectOption';
import { SelectOptionEmptyModule } from 'App/Packages/UI/Select/SelectOptionEmpty';
import { SelectOptions, SelectOptionsModule, SelectOptionsProps } from 'App/Packages/UI/Select/SelectOptions';
import { SelectOptionsChildrenModule, SelectOptionsChildrenProps } from 'App/Packages/UI/Select/SelectOptionsChildren';
import { SelectOptionsContainerModule, SelectOptionsContainerProps } from 'App/Packages/UI/Select/SelectOptionsContainer';
import { SelectOptionsContextModule, SelectOptionsContextState } from 'App/Packages/UI/Select/SelectOptionsContext';
import { SelectTitle, SelectTitleModule, SelectTitleProps } from 'App/Packages/UI/Select/SelectTitle';

export type {
    SelectFooterModule,
    SelectHeaderModule,
    SelectHeaderProps,
    SelectOptionEmptyModule,
    SelectOptionModule,
    SelectOptionProps,
    SelectOptionsChildrenModule,
    SelectOptionsChildrenProps,
    SelectOptionsContainerModule,
    SelectOptionsContainerProps,
    SelectOptionsContextModule,
    SelectOptionsContextState,
    SelectOptionsModule,
    SelectOptionsProps,
    SelectTitleModule,
    SelectTitleProps,
};

export type SelectProps = {
    zIndex: number;
    children: React.ReactNode;
    onClose: () => void;
};

export type SelectModule = {
    Header: SelectHeaderModule;
    Title: SelectTitleModule;
    Option: SelectOptionModule;
    Options: SelectOptionsModule;
    Footer: SelectFooterModule;
} & React.FunctionComponent<SelectProps>;

export const Select: SelectModule = (props) => {
    const onClose = useRef(props.onClose);
    onClose.current = props.onClose;
    useBackHook(onClose.current);

    return (
        <Layout className="select" backgroundColor={BackgroundColor.OVERLAY} zIndex={props.zIndex}>
            <SelectContext.Provider onClose={onClose}>{props.children}</SelectContext.Provider>
        </Layout>
    );
};

Select.Header = SelectHeader;
Select.Title = SelectTitle;
Select.Footer = SelectFooter;
Select.Option = SelectOption;
Select.Options = SelectOptions;
