import React from 'react';
import {
    UseDetailActionValue,
    UseDetailActionValuePayload,
    useDetailActionValue,
} from 'App/Packages/UI/Detail/Modules/DetailAction/Hooks/useDetailActionValue';
import { DetailActionContext } from 'App/Packages/UI/Detail/Modules/DetailAction/Context/DetailActionContext';
import { UseDetailAction, useDetailAction } from 'App/Packages/UI/Detail/Modules/DetailAction/Hooks/useDetailAction';

export type DetailActionModule = React.FunctionComponent<DetailActionProps> & DetailActionModules;
export type DetailActionModules = {
    Context: typeof DetailActionContext;
    use: UseDetailAction;
    useValue: UseDetailActionValue;
};
export type DetailActionProps = UseDetailActionValuePayload & {
    children: React.ReactNode;
};

export const DetailAction: DetailActionModule = (props) => {
    const action = DetailAction.useValue(props);
    return <DetailAction.Context.Provider value={action}> {props.children} </DetailAction.Context.Provider>;
};

// Context
DetailAction.Context = DetailActionContext;

// Hooks
DetailAction.use = useDetailAction;
DetailAction.useValue = useDetailActionValue;
