import { ConfigService } from '__SMART_APP_OLD__/app/modules/ConfigService';
import { translationSelectors } from '__SMART_APP_OLD__/app/modules/Translation/selectors';
import {
    TranslationActionType,
    TranslationLoadedAction,
    TranslationLoadedActionPayload,
} from '__SMART_APP_OLD__/app/modules/Translation/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import DefaultTranslations from 'language/default.json';

const loaded = (payload: TranslationLoadedActionPayload): TranslationLoadedAction => ({ type: TranslationActionType.LOADED, payload });

const load = (code: string): Thunk<Promise<ConfigService.TranslationsData>> => async (dispatch, getState) => {
    try {
        const data = translationSelectors.private.selectData(code)(getState());
        if (data) return data;
        const url = translationSelectors.private.selectUrl(code)(getState());
        const response = await ConfigService.get<ConfigService.TranslationsData>(url);
        dispatch(loaded({ code, data: response.data }));
        return response.data;
    } catch (error) {
        const response = (DefaultTranslations as unknown) as ConfigService.Response<ConfigService.TranslationsData>;
        dispatch(loaded({ code, data: response.data }));
        return response.data;
    }
};

export const translationActions = { public: { load, loaded } };
