import React from 'react';

import { useShouldMask } from 'App/Packages/UI/Mask/Hooks';
import { Translation } from 'App/Packages/UI/Translation';

export type MaskTextProps = React.PropsWithChildren<{ mask: string }>;

export type MaskTextModule = React.FunctionComponent<MaskTextProps>;

export const MaskText: MaskTextModule = (props) => (useShouldMask() ? <Translation>{props.mask}</Translation> : <>{props.children}</>);
