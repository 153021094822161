import React, { useEffect, useState } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';

import { LoginBackground } from '__SMART_APP_OLD__/app/components/LoginBackground';
import { Screen } from 'App/Modules/Screen';
import { LoginConstants } from 'App/Modules/Screen/Modules/Login/Constants';
import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { LoginFlowSwitch } from 'App/Modules/Screen/Modules/Login/View/LoginFlowSwitch';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { Axis } from 'App/Types/Axis';
import { LoginWithEmailFormContainer } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormContainer';
import { LoginWithEmailForm } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailForm';
import { LoginWithEmailGuide } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailGuide';
import { LoginWithCodeTitle } from 'App/Modules/Screen/Modules/LoginWithCode/View/LoginWithCodeTitle';
import { LoginWithCodeContent } from 'App/Modules/Screen/Modules/LoginWithCode/View/LoginWithCodeContent';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { actions } from 'App/Modules/Screen/Modules/Login/Store/actions';

export const Login: React.FunctionComponent<Screen.Props<Screen.Type.LOGIN>> = (props) => {
    const dispatch = useDispatch();
    const [isCode, setIsCode] = useState(true);

    const items = isCode ? LoginConstants.Elements.Code : LoginConstants.Elements.Email;
    const { focused, focus } = Focus.useList({ ctx: Focus.Default, axis: Axis.Y, items });

    useEffect(() => {
        dispatch(History.actions.push({ overlay: null, screen: Screen.generator(Screen.Type.LOGIN_WITH_CODE, {}) }));
    }, [dispatch]);

    useEffect(() => {
        if (props.username && props.password) {
            dispatch(actions.private.loginAfterDeviceDeletion(props.username, props.password));
        }
    }, [dispatch, props.password, props.username]);

    return (
        <Layout className="login" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <LoginBackground className="login__background" />
            <div className={`login__container login__container--${isCode ? 'code' : 'email'}`}>
                <UI.Logo.XploreTV className="login__logo" />
                <LoginFlowSwitch
                    ctx={Focus.Default}
                    on={isCode}
                    isFocused={focused === LoginElement.FlowSwitch}
                    onEnter={() => {
                        setIsCode(!isCode);
                        dispatch(History.actions.clear());
                        !isCode
                            ? dispatch(History.actions.push({ overlay: null, screen: Screen.generator(Screen.Type.LOGIN_WITH_CODE, {}) }))
                            : dispatch(History.actions.push({ overlay: null, screen: Screen.generator(Screen.Type.LOGIN_WITH_EMAIL, {}) }));
                    }}
                    onHover={focus}
                />
                {isCode ? (
                    <>
                        <LoginWithCodeTitle />
                        <LoginWithCodeContent onFail={() => dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}))} />
                    </>
                ) : (
                    <LoginWithEmailFormContainer>
                        <LoginWithEmailForm ctx={Focus.Default} focused={focused} onFocus={focus} />
                        <LoginWithEmailGuide />
                    </LoginWithEmailFormContainer>
                )}
            </div>
        </Layout>
    );
};
