import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ReminderEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';

export const toEntity = (reminder: GQL.Reminder): ReminderEntity => ({
    id: reminder.id,
    channelId: reminder.event.channel?.id ?? '',
    eventId: reminder.event.id,
    start: new Date(reminder.event.start).getTime(),
    title: reminder.event.title,
});

export const toEntities = (reminders: GQL.Reminder[]): Record<GQL.ReminderID, ReminderEntity> =>
    reminders.reduce<Record<GQL.ReminderID, ReminderEntity>>((acc, rem) => {
        acc[rem.id] = toEntity(rem);
        return acc;
    }, {});
