import React from 'react';

import { Spinner } from 'App/Packages/UI/Spinner';

export type SelectOptionsContainerProps = {
    loading?: boolean;
    children: React.ReactNode;
};

export type SelectOptionsContainerModule = {} & React.FunctionComponent<SelectOptionsContainerProps>;

export const SelectOptionsContainer: SelectOptionsContainerModule = (props) => (
    <div className="select__options-container">{props.loading ? <Spinner /> : props.children}</div>
);
