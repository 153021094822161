import React from 'react';

import { Button, ButtonCornersStyle, ButtonIconFlavour } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageDetailScrollButtonTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import { selectMessageDetailVisibleContainerIndex } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailCTX, MessageDetailScrollContainer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

interface Props {
    container: MessageDetailScrollContainer;
    isFocused: boolean;
    onHover: (id: MessageDetailScrollContainer) => void;
}

export const MessageDetailScrollTrigger: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const index = useSelector(selectMessageDetailVisibleContainerIndex);
    const icon = index > props.container.from ? IconName.UP : IconName.DOWN;
    return (
        <div className="message-detail__scroll-trigger-container">
            <Button
                ctx={MessageDetailCTX.SCROLL}
                id={props.container}
                className="message-detail__scroll-button"
                corners={ButtonCornersStyle.ROUNDED}
                icon={icon}
                iconFlavour={ButtonIconFlavour.DEFAULT}
                isFocused={props.isFocused}
                disabled={index !== props.container.from && index !== props.container.to}
                onEnter={(container) => dispatch(messageDetailScrollButtonTriggered(container))}
                onHover={props.onHover}
            />
        </div>
    );
};
