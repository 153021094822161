import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Alert } from 'App/Modules/Alert';
import { UI } from 'App/Packages/UI';
import translate from 'language/translate';
import { Translation } from 'App/Modules/Translation';

export const View: React.FunctionComponent<Alert.Props<Alert.Type.InternetConnectionLost>> = () => {
    const dispatch = useDispatch();

    return (
        <UI.Alert zIndex={Alert.zIndex} onClose={() => dispatch(Alert.actions.unmount())}>
            <UI.Alert.Title>{translate(Translation.Key.Alert.InternetConnectionLost.Title)}</UI.Alert.Title>
            <UI.Alert.Text>{translate(Translation.Key.Alert.InternetConnectionLost.Text)}</UI.Alert.Text>
        </UI.Alert>
    );
};
