/* eslint-disable max-statements */
/* eslint-disable no-undef */
/* eslint-disable max-lines */
import { clpp } from '@castlabs/prestoplay';
import * as Conviva from '@convivainc/conviva-js-coresdk/conviva-core-sdk';
import { by639_2T } from 'iso-language-codes';
import isEmpty from 'lodash.isempty';
import { PlayerEngine } from '__SMART_APP_OLD__/platforms/flavours';

import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import {
    selectDefaultAudioLanguage,
    selectDefaultSubtitleLanguage,
    selectDrmProtection,
    selectLowLatencyChannelsList,
    selectPlayReadyDrmSettings,
    selectPlayerSettings,
    selectPlayerSettingsCatchup,
    selectPlayerSettingsLowLatency,
    selectWidewineDrmSettings,
    selectAvailableAudioLanguages,
    selectAvailableSubtitleLanguages,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectShouldCancelChannelStream } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import {
    AssetType,
    DeliveryKind,
    ProgramType,
    operatorForOpco,
    relocatePlayerTargets,
    shortEnvironmentName,
    viewerForOpco,
} from '__SMART_APP_OLD__/utils/Constants';
import { isNil } from '__SMART_APP_OLD__/utils/isNil';
import { PlaybackEvents } from 'analytics/logging/events/PlaybackEvent';
import { getPlaybackIssueEvent } from 'analytics/logging/factories/issueEventFactory';
import { getPlaybackEvent } from 'analytics/logging/factories/playbackEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { VideoQuality } from 'App/Types/VideoQuality';

window.Conviva = Conviva;

/**
 * @description Contains all the player functionality present in smart project
 * value and the viewer prefix  agreed for reporting pattern.
 * @author SmartTVBG@a1.bg
 * @date 22/01/2023
 * @namespace Player
 */

export const VIDEO_EVENTS = {
    BUFFERING_STARTED: clpp.events.BUFFERING_STARTED,
    BUFFERING_ENDED: clpp.events.BUFFERING_ENDED,
    AUDIO_TRACK_CHANGED: clpp.events.AUDIO_TRACK_CHANGED,
    VIDEO_TRACK_CHANGED: clpp.events.VIDEO_TRACK_CHANGED,
    TIMELINE_CUE_ADDED: clpp.events.TIMELINE_CUE_ADDED,
    TIMELINE_CUE_ENTER: clpp.events.TIMELINE_CUE_ENTER,
    TIMELINE_CUE_EXIT: clpp.events.TIMELINE_CUE_EXIT,
    SEEKING: clpp.events.SEEKING,
    SEEKED: clpp.events.SEEKED,
    PLAY: clpp.events.PLAY,
    ERROR: clpp.events.ERROR,
    LOADSTART: clpp.events.LOAD_START,
    LOADEDDATA: clpp.events.LOADEDMETADATA,
    STATE_CHANGED: clpp.events.STATE_CHANGED,
    AUTOPLAY_NOT_ALLOWED: clpp.events.AUTOPLAY_NOT_ALLOWED,
    // media player events https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events
    // castlabs can listen and for them explicitly as they propagate it
    VOLUMECHANGE: 'volumechange',
    TIMEUPDATE: 'timeupdate',
    CANPLAY: 'canplay',
    PLAYING: 'playing',
    PAUSE: 'pause',
    ENDED: 'ended',
    DURATIONCHANGE: 'durationchange',
    WAITING: 'waiting',
    CANPLAYTHROUGH: 'canplaythrough',
    TIMESHIFTUPDATE: 'timeshiftupdate',
};

const disableSubtitleOption = {
    id: -1,
    iso_code: 'off',
    name: 'SCREEN_AUDIO_SUBTITLES_SUBTITLES_OFF',
    ppTrack: null,
    lang: 'off',
    type: clpp.Track.Type.TEXT,
};

class Player {
    _blocked = false;

    deliveryKind = 'DASH';

    player = null;

    program = null; // Used to identify the currently running program

    initialized = false;

    adPlaybackPreRoll = 0;

    adPlaybackRestrictions = [];

    positionBeforeNetworkLost = null;

    streamUrl = null;

    playerLocation = relocatePlayerTargets.NORMAL.id;

    _videoServerSessionId = null;

    bookmark = null;

    adCues = [];

    canSeek = true;

    mmCastlabsPlugin = null;

    isSsaiStream = false;

    viewerId = null;

    operator = null;

    profileId = null;

    householdId = null;

    deviceBrand = null;

    deviceMetadata = null;

    deviceType = null;

    prevTimeUpdateData = 0;

    tunedAt = Date.now();

    timeshift = 0;

    get disableAdSkippingPrevetion() {
        return !Env.isAdBlockingEnv;
    }

    get videoServerSessionId() {
        return this._videoServerSessionId;
    }

    set videoServerSessionId(id) {
        this._videoServerSessionId = id;
    }

    // blocked - show player blocked or not.
    // case when player can be blocked and we should
    // wait - channel switch animation - we should start buffering but do not start
    // playback until channel switch animation will be hidden
    get blocked() {
        return this._blocked;
    }

    set blocked(value) {
        this._blocked = value;
        if (!this.isPaused()) {
            this.play();
        }
    }

    setProgram(program) {
        this.program = program;
    }

    setPositionBeforeNetworkLost(value) {
        if (typeof value !== 'number') {
            return;
        }
        this.positionBeforeNetworkLost = value;
    }

    getBookmark() {
        return this.bookmark;
    }

    setBookmark(value) {
        if (typeof value !== 'number') {
            return;
        }
        this.bookmark = value;
    }

    setIsSeekingBlocked = (canSeek, ad) => {
        if (!Env.isAdBlockingEnv) {
            this.canSeek = true;
            return;
        }
        this.canSeek = canSeek;
        if (canSeek) {
            const index = this.adCues.findIndex((adCue) => adCue.id === ad.id);
            if (index === -1) return;
            const updatedCues = [...this.adCues];
            updatedCues[index].watched = true;
            this.adCues = updatedCues;
        } else {
            // edge case if the event is first and we marked it manually it is watched
            const index = this.adCues.findIndex((adCue) => adCue.id === ad.id);
            if (index === -1) return;
            this.canSeek = this.adCues[index].watched;
        }
    };

    listenForAdCues = (adEvent) => {
        const ad = this.isSsaiStream ? adEvent : adEvent.detail;
        const duration = ad.endTime - ad.startTime;
        const index = this.adCues.findIndex((adCue) => adCue.id === ad.id);
        if (index !== -1) {
            const oldCue = this.adCues[index];
            const newCue = { ...ad, duration, watched: oldCue.watched };
            this.adCues.splice(index, 1, newCue);
            return;
        }
        const watched = !this.isLive() && ad.startTime === 0 && this.adPlaybackPreRoll === 0;
        this.adCues.push({ ...ad, duration, watched });
    };

    seekOverlappingAd = (playedTime, seekedTime, isLive) => {
        const overlappedAdEvent = this.adCues
            .sort((a, b) => a.startTime - b.startTime)
            .find((adEvent) => {
                const { startTime, endTime, watched } = adEvent;
                const addStart = isLive ? startTime * 1000 : startTime;
                return (playedTime >= addStart || seekedTime >= addStart || (seekedTime <= endTime && playedTime >= addStart)) && !watched;
            });
        if (overlappedAdEvent) {
            return overlappedAdEvent.startTime;
        }
        return seekedTime;
    };

    getUnwatchedAdEvent = (playedTime, isLive) =>
        this.adCues
            .sort((a, b) => a.startTime - b.startTime)
            .find((adEvent) => {
                const { startTime, watched } = adEvent;
                const startAdTime = isLive ? startTime * 1000 : startTime;

                return playedTime >= startAdTime && !watched;
            });

    /**
     * @description A function starting the actual conviva playback report.
     * @author SmartTVBG@a1.bg
     * @date 09/12/2022
     * @function startSession
     * @param contentInfo the current played asset info.
     * @param  deliveryKind  the value of current presentation type.
     * @memberof Player
     * @returns returns nothing updating directly the current content info ref
     */
    setContentData = (contentInfo, deliveryKind) => {
        switch (contentInfo?.programType) {
            case ProgramType.LIVE:
                return this.addMetaDataLive(contentInfo, deliveryKind);
            case ProgramType.RECORDING:
            case ProgramType.CATCHUP:
                return this.addMetaDataCatchUpNpvr(contentInfo, deliveryKind);
            case ProgramType.VOD:
                return this.addMetaDataVod(contentInfo, deliveryKind);
            default:
                return contentInfo;
        }
    };

    /**
     * @param deliveryKind string representing current playback type of manifest
     * @description A function creating the contentMetadata object and populating base values in it.
     * @author SmartTVBG@a1.bg
     * @date 19/01/2023
     * @function setBaseSessionData
     * @returns  updated contentMetadata hash map with session content values.
     * @memberof Player
     */
    setBaseSessionData = (deliveryKind) => {
        const contentMetadata = {};
        contentMetadata.operator = this.operator;
        contentMetadata.householdId = this.householdId;
        contentMetadata.externalDeviceId = Platform.ID;
        contentMetadata.streamProtocol = DeliveryKind[deliveryKind];
        contentMetadata.screen = `${window.screen.width}x${window.screen.height}`;
        return contentMetadata;
    };

    /**
     * @description A function adding metadata for nvpr asset in conviva.
     * @author SmartTVBG@a1.bg
     * @date 09/01/2023
     * @function addMetaDataCatchUpNpvr
     * @param contentInfo the next playable asset info.
     * @param deliveryKind the kind of current catchup playback
     * @returns contentMetadata  the hash map with values to populate conviva tags.
     * @memberof Player
     */
    addMetaDataCatchUpNpvr = (contentInfo, deliveryKind) => {
        if (!contentInfo) {
            return {};
        }
        const contentMetadata = this.setBaseSessionData(deliveryKind);
        // manually predefined data
        contentMetadata['c3.cm.contentType'] = contentInfo?.assetType === AssetType.NETWORK_RECORDING ? 'NPVR' : 'Catchup';
        contentMetadata['c3.cm.channel'] = contentInfo?.channel?.title;
        contentMetadata[Conviva.Constants.IS_LIVE] = Conviva.Constants.StreamType.VOD;
        contentMetadata['c3.cm.brand'] = 'NA';
        contentMetadata['c3.cm.affiliate'] = 'NA';
        contentMetadata['c3.cm.categoryType'] = 'Event';
        contentMetadata['c3.cm.name'] = contentInfo.title;
        contentMetadata['c3.cm.id'] = contentInfo.id;
        contentMetadata['c3.cm.seriesName'] = contentInfo?.rawData?.metadata?.seriesInfo?.title ?? 'NA'; // conditional
        contentMetadata['c3.cm.seasonNumber'] = contentInfo?.rawData?.metadata?.episodeInfo?.season ?? 'NA'; // conditional
        contentMetadata['c3.cm.showTitle'] = contentInfo.title;
        contentMetadata['c3.cm.episodeNumber'] = contentInfo?.metadata?.episodeInfo?.number ?? 'NA';
        contentMetadata['c3.cm.genre'] = contentInfo?.rawData?.metadata?.genre?.title ?? 'NA';
        contentMetadata['c3.cm.genreList'] = 'NA'; // maybe in the future or we can change the graphql
        let currentAssetInfo = `[${contentInfo.id.toString().match(/\d*/gm)[0]}] ${contentInfo.title}`;
        if (contentInfo?.rawData?.metadata?.episodeInfo?.season && contentInfo?.rawData?.metadata?.episodeInfo?.number) {
            currentAssetInfo = `[${contentInfo.id}] ${contentInfo.title} (${contentInfo?.rawData?.metadata?.episodeInfo?.season}/${contentInfo?.rawData?.metadata?.episodeInfo?.number})`;
        }
        contentMetadata['c3.app.version'] = Env.Version;
        contentMetadata[Conviva.Constants.PLAYER_NAME] = 'SmartTVApp';
        contentMetadata[Conviva.Constants.VIEWER_ID] = this.viewerId;
        contentMetadata[Conviva.Constants.ASSET_NAME] = currentAssetInfo;
        contentMetadata[Conviva.Constants.STREAM_URL] = this.streamUrl;
        contentMetadata[Conviva.Constants.DURATION] = contentInfo.duration;
        return contentMetadata;
    };

    /**
     * @description A function adding metadata for live asset in conviva.
     * @author SmartTVBG@a1.bg
     * @date 09/01/2023
     * @function addMetaDataLive
     * @param contentInfo the next playable asset info.
     * @param deliveryKind the kind of current live playback
     * @returns contentMetadata  the hash map with values to populate conviva tags.
     * @memberof Player
     */
    addMetaDataLive = (contentInfo, deliveryKind) => {
        if (!contentInfo) {
            return {};
        }
        const contentMetadata = this.setBaseSessionData(deliveryKind);
        // manually predefined data
        contentMetadata['c3.cm.contentType'] = 'Live';
        contentMetadata['c3.cm.channel'] = contentInfo?.channelName;
        contentMetadata['c3.cm.brand'] = 'NA';
        contentMetadata['c3.cm.affiliate'] = 'NA';
        contentMetadata['c3.cm.categoryType'] = contentInfo?.assetType;
        contentMetadata['c3.cm.name'] = 'NA';
        contentMetadata['c3.cm.id'] = contentInfo.channelId;
        contentMetadata['c3.cm.seriesName'] = 'NA'; // conditional
        contentMetadata['c3.cm.seasonNumber'] = 'NA'; // conditional
        contentMetadata['c3.cm.showTitle'] = 'NA';
        contentMetadata['c3.cm.episodeNumber'] = 'NA';
        contentMetadata['c3.cm.genre'] = 'NA';
        contentMetadata['c3.cm.genreList'] = 'NA'; // maybe in the future or we can change the graphql
        const currentAssetInfo = `[${contentInfo.channelId}] ${contentInfo.channelName}`;
        contentMetadata[Conviva.Constants.VIEWER_ID] = this.viewerId;
        contentMetadata[Conviva.Constants.ASSET_NAME] = currentAssetInfo;
        contentMetadata[Conviva.Constants.STREAM_URL] = this.streamUrl;
        contentMetadata[Conviva.Constants.IS_LIVE] = Conviva.Constants.StreamType.LIVE;
        contentMetadata[Conviva.Constants.DURATION] = this.getDuration();
        contentMetadata['c3.app.version'] = Env.Version;
        contentMetadata[Conviva.Constants.PLAYER_NAME] = 'SmartTVApp';
        contentMetadata[Conviva.Constants.VIEWER_ID] = this.viewerId;
        contentMetadata[Conviva.Constants.ASSET_NAME] = currentAssetInfo;
        contentMetadata[Conviva.Constants.STREAM_URL] = this.streamUrl;
        contentMetadata[Conviva.Constants.DURATION] = contentInfo.duration;
        return contentMetadata;
    };

    /**
     * @description A function adding metadata for vod asset in conviva.
     * @author SmartTVBG@a1.bg
     * @date 09/01/2023
     * @function addMetaDataVod
     * @param contentInfo the next playable asset info.
     * @param deliveryKind the kind of current vod playback
     * @returns contentMetadata  the hash map with values to populate conviva tags.
     * @memberof Player
     */
    addMetaDataVod = (contentInfo, deliveryKind) => {
        if (!contentInfo) {
            return {};
        }
        const contentMetadata = this.setBaseSessionData(deliveryKind);
        // manually predefined data
        contentMetadata['c3.cm.contentType'] = 'VOD';
        contentMetadata['c3.cm.channel'] = 'NA'; // this is vod no channel
        contentMetadata['c3.cm.brand'] = 'NA';
        contentMetadata['c3.cm.affiliate'] = 'NA';
        contentMetadata['c3.cm.categoryType'] = 'VOD';
        contentMetadata['c3.cm.name'] = contentInfo.title;
        contentMetadata['c3.cm.id'] = contentInfo.id;
        contentMetadata['c3.cm.seriesName'] = contentInfo?.rawData?.metadata?.seriesInfo?.title ?? 'NA'; // conditional
        contentMetadata['c3.cm.seasonNumber'] = contentInfo?.rawData?.metadata?.episodeInfo?.season ?? 'NA'; // conditional
        contentMetadata['c3.cm.showTitle'] = contentInfo.title;
        contentMetadata['c3.cm.episodeNumber'] = contentInfo?.metadata?.episodeInfo?.number ?? 'NA';
        contentMetadata['c3.cm.genre'] = contentInfo?.rawData?.metadata?.genre?.title ?? 'NA';
        contentMetadata['c3.cm.genreList'] = 'NA'; // maybe in the future or we can change the graphql
        let currentAssetInfo = `[${contentInfo.id}] ${contentInfo.title}`;
        if (contentInfo.isTrailer) {
            currentAssetInfo = `[${contentInfo.id}_T] ${contentInfo.title}`;
        }

        if (contentInfo?.rawData?.metadata?.episodeInfo?.season && contentInfo?.rawData?.metadata?.episodeInfo?.number) {
            currentAssetInfo = `[${contentInfo.id}] ${contentInfo.title} (${contentInfo?.rawData?.metadata?.episodeInfo?.season}/${contentInfo?.rawData?.metadata?.episodeInfo?.number})`;
        }
        contentMetadata['c3.app.version'] = Env.Version;
        contentMetadata[Conviva.Constants.PLAYER_NAME] = 'SmartTVApp';
        contentMetadata[Conviva.Constants.VIEWER_ID] = this.viewerId;
        contentMetadata[Conviva.Constants.ASSET_NAME] = currentAssetInfo;
        contentMetadata[Conviva.Constants.STREAM_URL] = this.streamUrl;
        contentMetadata[Conviva.Constants.DURATION] = contentInfo.duration;
        return contentMetadata;
    };

    /**
     * @description A function which derives the device tag for conviva from system parameters
     * @author SmartTVBG@a1.bg
     * @date 09/01/2023
     * @function getDeviceCategory
     * @returns the device category value
     * @memberof Player
     */
    getDeviceCategory = () => {
        if (Env.IsBrowser) {
            return Conviva.Constants.DeviceCategory.DESKTOP_APP;
        }
        if (Env.IsTizen) {
            return Conviva.Constants.DeviceCategory.SAMSUNG_TV;
        }
        if (Env.IsWebOS) {
            return Conviva.Constants.DeviceCategory.LG_TV;
        }
        return Conviva.Constants.DeviceCategory.SMART_TV;
    };

    /**
     * @description A function which derives the device metadata for conviva
     *  from system and device parameters for current device.
     * @author SmartTVBG@a1.bg
     * @date 09/12/2022
     * @function setupDeviceInfo
     * @returns deviceMetadata the object containing information
     * for current device which conviva requests.
     * @memberof Player
     */
    setupDeviceInfo = () => {
        const deviceMetadata = {};
        deviceMetadata[Conviva.Constants.DeviceMetadata.BRAND] = Platform.BRAND;
        deviceMetadata[Conviva.Constants.DeviceMetadata.CATEGORY] = this.deviceCategory;
        deviceMetadata[Conviva.Constants.DeviceMetadata.MANUFACTURER] = Platform.MANUFACTURER;
        deviceMetadata[Conviva.Constants.DeviceMetadata.MODEL] = Platform.MODEL;
        deviceMetadata[Conviva.Constants.DeviceMetadata.OS_NAME] = Platform.OS;
        deviceMetadata[Conviva.Constants.DeviceMetadata.OS_VERSION] = Platform.OS_VERSION;
        deviceMetadata[Conviva.Constants.DeviceMetadata.TYPE] = this.deviceType;
        deviceMetadata[Conviva.Constants.DeviceMetadata.VERSION] = '';
        return deviceMetadata;
    };

    setupBaseInfo = () => {
        this.householdId = Storage.get(Storage.Key.HOUSEHOLD_ID);
        this.viewerId = `a1_${viewerForOpco[Env.Opco]}_${shortEnvironmentName[Env.Environment]}_${this.householdId}`;
        this.operator = operatorForOpco[Env.Opco];
        this.profileId = Profile.selectors.selectId(store.getState());
        this.deviceBrand = Platform.BRAND;
        this.deviceCategory = this.getDeviceCategory(this.systemInformation);
        this.deviceMetadata = this.setupDeviceInfo();
        this.deviceType = Env.IsBrowser ? Conviva.Constants.DeviceType.DESKTOP : Conviva.Constants.DeviceType.SMARTTV;
    };

    setDrmValues = () => {
        const streamIsDrmProtected = selectDrmProtection(store.getState());
        if (!streamIsDrmProtected) return null;
        const playreadyLicenseServer = selectPlayReadyDrmSettings(store.getState());
        const widevineLicenseServer = selectWidewineDrmSettings(store.getState());
        const customData = {
            widevineLicenseUrl: `${widevineLicenseServer}?deviceId=${Platform.ID}`,
            playReadyLicenseUrl: `${playreadyLicenseServer}?deviceId=${btoa(Platform.ID)}`,
        };
        if (!widevineLicenseServer) delete customData.widevineLicenseUrl;
        if (!playreadyLicenseServer) delete customData.playReadyLicenseUrl;
        return { env: 'HeaderDrm', customData };
    };

    initialize = () => {
        if (this.initialized) return;
        this.setupBaseInfo();
        const drm = this.setDrmValues();
        this.player = PlayerEngine.initialize(drm, this.viewerId);
        this.initialized = true;
        this.player.on(VIDEO_EVENTS.TIMELINE_CUE_ADDED, this.listenForAdCues);
        this.player.on(VIDEO_EVENTS.TIMELINE_CUE_ENTER, this.handleTimelineCueEnter);
        this.player.on(VIDEO_EVENTS.TIMELINE_CUE_EXIT, this.handleTimelineCueExit);
        this.player.on(VIDEO_EVENTS.ERROR, this.handleError);
        this.player.on(VIDEO_EVENTS.STATE_CHANGED, this.setUarForPlayerEvent);
        this.player.on(VIDEO_EVENTS.SEEKED, this.handleSeeked);

        console.log('[Player] instance created');
    };

    initializeMediaMelonPlugin = () => {
        if (typeof CastlabsMMSSJSIntgr !== 'function') return;
        if (this.mmCastlabsPlugin) return;
        this.mmCastlabsPlugin = CastlabsMMSSJSIntgr();
        const castlabsVersion = this.getPlayerVersion();
        // Register with Mediamelon SDK
        this.mmCastlabsPlugin.registerMMSmartStreaming('castlabs', '1922042005', this.profileId, '', '', '');
        this.mmCastlabsPlugin.reportPlayerInfo('castlabs', 'prestoPlay', castlabsVersion);
    };

    setStream = async (playUrl, options = {}) => {
        try {
            const {
                autoplay,
                playedTime,
                isLive,
                deliveryKind,
                adPlaybackPreRoll,
                adPlaybackRestrictions,
                channelId,
                shouldCheckChannelSwitchRaceCondition,
                clearAds,
            } = options;
            if (selectShouldCancelChannelStream(channelId)(store.getState()) && shouldCheckChannelSwitchRaceCondition) return undefined;
            if (isNil(playUrl)) {
                return this.playerStop();
            }
            this.adPlaybackPreRoll = adPlaybackPreRoll ?? 0;
            this.adPlaybackRestrictions = adPlaybackRestrictions ?? [];
            // TODO: can't remove timeshift before refactoring player ui progress bar player ui
            this.timeshift = 0;
            this.deliveryKind = deliveryKind;
            this.streamUrl = playUrl;
            this.canSeek = true;
            this.tuneAt = Date.now();
            if (clearAds) {
                this.adCues = [];
            }
            if (this.isSsaiStream) {
                this.mmCastlabsPlugin?.closeMediaMelonSDK();
            }
            this.isSsaiStream = false;
            const [url, ssaiToken] = this.getDataFromStreamUrl(playUrl);
            if (ssaiToken) {
                this.handleServerAdInsertion(url, ssaiToken, isLive);
            }
            return this.playerLoadContent(url, isLive, autoplay, playedTime, deliveryKind, channelId);
        } catch (error) {
            console.warn('[Player] Can not set stream', error);
            return undefined;
        }
    };

    setupPlayerBeforeStreaming = (deviceConnectionType, contentInfo, isLive, url, autoplay, startTime, deliveryKind, channelId) => {
        const { preferredAudioLanguages, preferredTextLanguages } = this.handleLanguagePreferences();
        const playerLoadSetup = {
            source: url,
            autoplay,
            startTime,
            preferredAudioLanguage: preferredAudioLanguages,
            preferredTextLanguage: preferredTextLanguages,
            conviva: {
                customerKey: Env.Conviva.Key,
                // serviceUrl: Env.Conviva.TouchStone.Url, // used only to test conviva
                viewerId: this.viewerId,
                enableAdInsights: false,
                customTags: {
                    operator: operatorForOpco[Env.Opco],
                    householdId: this.householdId,
                    profileId: this.profileId,
                    streamProtocol: DeliveryKind[deliveryKind],
                    screen: `${window.screen.width}x${window.screen.height}`,
                    ...contentInfo,
                },
                playerName: 'SmartTVApp',
                deviceType: this.deviceType,
                deviceCategory: this.deviceCategory,
                deviceBrand: this.deviceBrand,
                deviceMetadata: this.deviceMetadata,
                connectionType: deviceConnectionType,
            },
        };
        const settings = selectPlayerSettings(store.getState());
        const settingsLowLatency = selectPlayerSettingsLowLatency(store.getState());
        const settingsCatchup = selectPlayerSettingsCatchup(store.getState());
        const lowLatencyChannels = selectLowLatencyChannelsList(store.getState());
        const playbackSettings = isLive
            ? settings
            : typeof settingsCatchup === 'object' && Object.keys(settingsCatchup).length !== 0
              ? settingsCatchup
              : settings;
        const updateSetupValues =
            isLive && lowLatencyChannels.includes(channelId)
                ? typeof settingsLowLatency === 'object' && Object.keys(settingsLowLatency)
                    ? settingsLowLatency
                    : playbackSettings
                : playbackSettings;
        return {
            ...updateSetupValues,
            ...playerLoadSetup,
        };
    };

    playerLoadContent = async (url, isLive, autoplay, playedTime, deliveryKind, channelId) => {
        const initialSeekValue = this.bookmark || this.positionBeforeNetworkLost;
        const startTime = isLive
            ? null
            : typeof playedTime === 'number'
              ? playedTime
              : !isNil(initialSeekValue) && !isLive
                ? this.getPlaybackStartTimeForAdSkippingPrevetion(initialSeekValue)
                : null;
        console.log(`[Player] play url: ${url}`);
        console.log(`[Player] start position ${startTime}`);

        const deviceConnectionType = await Platform.getConnectionType();
        const contentInfo = this.setContentData(this.program, deliveryKind);
        const playerLoadSetup = this.setupPlayerBeforeStreaming(
            deviceConnectionType,
            contentInfo,
            isLive,
            url,
            autoplay,
            startTime,
            deliveryKind,
            channelId
        );
        return this.player.load(playerLoadSetup);
    };

    playerHandleTimeUpdate = () => {
        if (!this.isDynamic()) return;
        if (!this.isPlaying()) {
            this.prevTimeUpdateData = 0;
            return;
        }
        if (this.prevTimeUpdateData && this.timeshift) {
            this.timeshift += Date.now() - this.prevTimeUpdateData;
        }
        this.prevTimeUpdateData = Date.now();
    };

    handleSeeked = () => {
        this.prevTimeUpdateData = 0;
    };

    checkIfSo6392t = (lang) =>
        /^[a-z]{3}$/.test(lang) && lang !== 'off' && lang !== 'ola' && lang !== 'und' && lang !== 'mlt' && !isEmpty(lang);

    setInitialSubtitles = () => {
        const { preferredTextLanguages } = this.handleLanguagePreferences();
        const { subtitles } = this.getPlayerOptions();
        const preferences = preferredTextLanguages ?? [];
        const subtitleLang = preferences.find((item) => subtitles.some((track) => track.language === item)) || null;
        const subtitle = subtitles.find((track) => track.language === subtitleLang);
        if (
            !subtitle ||
            subtitle.language === 'off' ||
            subtitle.language === 'none' ||
            subtitle.language === 'ola' ||
            subtitle.language === 'und'
        ) {
            return this.disableSubtitles();
        }
        return this.setSubtitleStream(subtitle);
    };

    handleLanguagePreferences = () => {
        try {
            const {
                currentChannelAudioLanguage,
                audioLanguagePrimary,
                audioLanguageSecondary,
                currentChannelSubtitleLanguage,
                subtitleLanguagePrimary,
                subtitleLanguageSecondary,
            } = Preferences;

            const defaultAudioLanguageConfig = selectDefaultAudioLanguage(store.getState());
            const defaultSubtitleLanguageConfig = selectDefaultSubtitleLanguage(store.getState());

            const defaultSubtitleLanguage = this.checkIfSo6392t(defaultSubtitleLanguageConfig)
                ? by639_2T[defaultSubtitleLanguageConfig].iso639_1
                : defaultSubtitleLanguageConfig;
            const defaultAudioLanguage = this.checkIfSo6392t(defaultAudioLanguageConfig)
                ? by639_2T[defaultAudioLanguageConfig].iso639_1
                : '';
            const currentChannelAudio = this.checkIfSo6392t(currentChannelAudioLanguage)
                ? by639_2T[currentChannelAudioLanguage].iso639_1
                : currentChannelAudioLanguage;
            const currentPrimaryAudioLang = this.checkIfSo6392t(audioLanguagePrimary) ? by639_2T[audioLanguagePrimary].iso639_1 : '';
            const currentSecondaryAudioLang = this.checkIfSo6392t(audioLanguageSecondary) ? by639_2T[audioLanguageSecondary].iso639_1 : '';
            const currentChannelSubLanguage = this.checkIfSo6392t(currentChannelSubtitleLanguage)
                ? by639_2T[currentChannelSubtitleLanguage].iso639_1
                : currentChannelSubtitleLanguage;
            const currentPrimarySubLang = this.checkIfSo6392t(subtitleLanguagePrimary)
                ? by639_2T[subtitleLanguagePrimary].iso639_1
                : subtitleLanguagePrimary;
            const currentSecondarySubLang = this.checkIfSo6392t(subtitleLanguageSecondary)
                ? by639_2T[subtitleLanguageSecondary].iso639_1
                : subtitleLanguageSecondary;
            const preferredAudioLanguages = [
                currentPrimaryAudioLang,
                currentSecondaryAudioLang,
                currentChannelAudio,
                defaultAudioLanguage,
            ].filter(Boolean);
            const preferredTextLanguages = [
                currentPrimarySubLang,
                currentSecondarySubLang,
                currentChannelSubLanguage,
                defaultSubtitleLanguage,
            ].filter(Boolean);
            return { preferredAudioLanguages, preferredTextLanguages };
        } catch (err) {
            console.log(`[Player] handling starting languages failed with ${err}`);
            return { preferredAudioLanguages: '', preferredTextLanguages: '' };
        }
    };

    handleServerAdInsertion = (url, ssaiToken, isLive) => {
        this.initializeMediaMelonPlugin();
        if (!this.mmCastlabsPlugin) return;
        const streamType = url.includes('dash') ? 'dash' : 'hls';
        this.isSsaiStream = true;
        // eslint-disable-next-line new-cap
        const mmSSAITestPlugin = new mmNowtilusSSAIPlugin(this.player);
        const mmVideoAssetInfo = null; // not mandatory setting null
        const nowtilusConfig = {
            isLive,
            streamType,
            apiKey: Env.NowTilus.Key,
            url,
            context: 'web',
            ifa: 'ifa',
            enablePolling: true,
        };
        mmSSAITestPlugin.setup(url, ssaiToken, nowtilusConfig);
        mmSSAITestPlugin.addListener('onCueTimelineAdded', (_, adTimeline) => {
            const adInfo = adTimeline[0];
            const ad = {
                id: adInfo.adId,
                startTime: adInfo.startTime / 1000,
                endTime: adInfo.endTime / 1000,
                watched: !this.isLive() && adInfo.startTime === 0 && this.adPlaybackPreRoll === 0,
            };
            this.listenForAdCues(ad);
        });

        mmSSAITestPlugin.addListener('onCueTimelineEnter', (adinfo) => {
            const ad = {
                id: adinfo.adId,
                start: adinfo.startTime / 1000,
                duration: adinfo.adDuration,
                end: adinfo.startTime / 1000 + adinfo.adDuration,
                watched: !this.isLive() && adInfo.startTime === 0 && this.adPlaybackPreRoll === 0,
            };
            this.setIsSeekingBlocked(false, ad);
        });

        mmSSAITestPlugin.addListener('onCueTimelineExit', (adinfo) => {
            const ad = {
                id: adinfo.adId,
                start: adinfo.startTime / 1000,
                duration: adinfo.adDuration,
                end: adinfo.startTime / 1000 + adinfo.adDuration,
                watched: true,
            };
            this.setIsSeekingBlocked(true, ad);
        });

        this.mmCastlabsPlugin.initialize(this.player, url, mmVideoAssetInfo, mmSSAITestPlugin, isLive);
    };

    getDataFromStreamUrl = (streamUrl) => {
        let url = streamUrl;
        let token = '';
        if (url.includes('vast-data=')) {
            const splitUrl = url.split('vast-data=');
            const clearUrlWithoutVastData = splitUrl[0];
            url = clearUrlWithoutVastData.substring(0, clearUrlWithoutVastData.length - 1);
            token = atob(splitUrl[1].split('&token=')[0]);
        }

        return [url, token];
    };

    getPlaybackStartTimeForAdSkippingPrevetion = (initialSeekValue = 0) => {
        if (this.disableAdSkippingPrevetion) {
            return initialSeekValue;
        }
        const leadIn = this.program?.leadIn ?? 0;
        if (initialSeekValue > leadIn) {
            return initialSeekValue;
        }
        const shiftedLeadIn = leadIn - this.adPlaybackPreRoll;
        return shiftedLeadIn;
    };

    seekToLive = (autoplay = false) => {
        try {
            if (!this.getTimeshift()) {
                return;
            }
            const secondsToLive = (Date.now() - this.getTimeshift()) / 1000;
            this.seek(this.getPlayedTime() + secondsToLive);
            this.setTimeshift(0);
            if (autoplay) {
                setTimeout(this.play, 1000);
            }
        } catch (error) {
            console.warn('[Player] Can not seek to live', error);
        }
    };

    playerStop = () => {
        if (this.isSsaiStream) {
            this.mmCastlabsPlugin.closeMediaMelonSDK();
        }
        this.isSsaiStream = false;
        return this?.player?.release().catch(console.error);
    };

    handleTimelineCueEnter = (event) => {
        if (this.isSsaiStream) return;
        this.setIsSeekingBlocked(false, event.detail);
    };

    handleTimelineCueExit = (event) => {
        if (!this.isSsaiStream && !this.isPlaying()) {
            this.canSeek = true;
            return;
        }
        if (this.isSsaiStream) return;
        this.setIsSeekingBlocked(true, event.detail);
    };

    handleError = (error) => {
        const errorInfo = error.detail;
        this.canSeek = true;
        // 5 properties:  severity category code data cause
        switch (errorInfo.category) {
            case clpp.Error.Category.NETWORK:
                this.logError({ title: 'ERR_PLAYER_NETWORK_MESSAGE', code: errorInfo.code, detail: errorInfo?.data ?? errorInfo?.cause });
                break;
            case clpp.Error.Category.MANIFEST:
                this.logError({ title: 'ERR_PLAYER_MANIFEST_MESSAGE', code: errorInfo.code, detail: errorInfo?.data ?? errorInfo?.cause });
                break;
            case clpp.Error.Category.STREAMING:
                this.logError({ title: 'ERR_PLAYER_STREAMING_MESSAGE', code: errorInfo.code, detail: errorInfo?.data ?? errorInfo?.cause });
                break;
            case clpp.Error.Category.DRM:
                this.logError({ title: 'ERR_PLAYER_DRM_MESSAGE', code: errorInfo.code, detail: errorInfo?.data ?? errorInfo?.cause });
                break;
            case clpp.Error.Category.CAST:
            case clpp.Error.Category.PLAYER:
            default:
                this.logError({ title: 'ERR_PLAYER_GENERIC_MESSAGE', code: errorInfo.code, detail: errorInfo?.data ?? errorInfo?.cause });
                break;
        }
        this.program = null;
    };

    setUarForPlayerEvent = (stateChanged) => {
        const {
            detail: { currentState },
        } = stateChanged;
        const event =
            currentState === clpp.Player.State.BUFFERING
                ? PlaybackEvents.BUFFER
                : currentState === clpp.Player.State.PLAYING
                  ? PlaybackEvents.PLAY
                  : currentState === clpp.Player.State.PAUSED
                    ? PlaybackEvents.PAUSE
                    : null;
        if (!event) return;
        if (!this.program) return;
        const date = new Date().getTime();
        const playerTime = this.getPlayedTime();
        const isLive = this.player?.isLive();
        const playedTime = isLive ? Math.round((date - (this.program?.startDateTime ?? 0)) / 1000) : playerTime;
        const rendition = this?.player?.getTrackManager()?.getVideoRendition();
        const activeVideoTrack = {
            id: rendition?.track?.id ?? '',
            width: rendition?.width || 0,
            height: rendition?.height || 0,
            bandwidth: rendition?.bandwidth ? Math.abs(Math.round(rendition.bandwidth / 1024)) : 0,
        };

        LoggingService.getInstance().logEvent(getPlaybackEvent(event, this.videoServerSessionId, playedTime, activeVideoTrack, isLive));
    };

    logError = (data) => {
        console.error(`[PLAYER] A player error occurred: ${JSON.stringify(data?.detail).substring(0, 200)} with code: ${data?.code}`);
        LoggingService.getInstance().logEvent(getPlaybackIssueEvent({ code: data?.code, detail: data?.detail }, this.streamUrl));
    };

    stop = async () => {
        try {
            this.program = null;
            this.timeshift = 0;
            this.prevTimeUpdateData = 0;
            await this.playerStop();
        } catch (error) {
            // here we report the error and end the session.
            console.log('Can not stop player', error);
        }
    };

    destroy = async () => {
        try {
            if (!this.isInitialized()) return null;
            this.stop();
            this.player.off(VIDEO_EVENTS.STATE_CHANGED, this.setUarForPlayerEvent);
            this.player.off(VIDEO_EVENTS.ERROR, this.handleError);
            this.player.off(VIDEO_EVENTS.TIMELINE_CUE_ADDED, this.listenForAdCues);
            this.player.off(VIDEO_EVENTS.TIMELINE_CUE_ENTER, this.handleTimelineCueEnter);
            this.player.off(VIDEO_EVENTS.TIMELINE_CUE_EXIT, this.handleTimelineCueExit);
            this.player.off(VIDEO_EVENTS.SEEKED, this.handleSeeked);
            this.initialized = false;
            this.adCues = [];
            console.log('[Player] instance destroyed');
            return await this.player.destroy();
        } catch (error) {
            console.error('Can not destroy player, error');
            return null;
        }
    };

    isDynamic = () => {
        try {
            return this.player?.isLive() || this.player?.getDuration() === Infinity;
        } catch (error) {
            console.warn('[Player] Can not call is dynamic', error);
            return false;
        }
    };

    pause = async () => {
        try {
            await this.player.pause();
        } catch (error) {
            console.warn('[Player] Can not pause', error);
        }
    };

    isPaused = () => this.player.isPaused();

    play = async () => {
        try {
            await this.player.play();
        } catch (error) {
            console.warn('[Player] Can not play', error);
        }
    };

    playPause = async () => {
        try {
            if (this.isPlaying()) {
                await this.pause();
            } else {
                await this.play();
            }
        } catch (error) {
            console.warn('[Player] Can not play/pause', error);
        }
    };

    seek = async (seconds) => {
        try {
            if (!Number.isFinite(seconds)) return;
            // TODO to be investigated
            // it's a hack to make startOver work.
            // A mistake should be found in calculations in the LinearProgressBar
            // await this.player.seek(seconds);
            const streamStart = this.player?.getSeekRange()?.start;
            if (Platform.isVersion('tizen 5.5')) {
                await this.player.pause();
                this.addOneTimeEventListener(VIDEO_EVENTS.SEEKED, () => {
                    if (!this.isPlaying()) {
                        this.player.play();
                    }
                });
            }

            if (typeof streamStart === 'number' && streamStart > seconds) {
                await this.player.seek(streamStart + 1);
            } else {
                await this.player.seek(seconds);
            }
        } catch (error) {
            console.warn('[Player] Can not seek', error);
        }
    };

    seekingIsAllowed = () => this.canSeek;

    playbackStats = () => this.player.getStats();

    isPlaying = () => this.player.getState() === clpp.Player.State.PLAYING;

    isBuffering = () => this.player.getState() === clpp.Player.State.BUFFERING;

    isPreparing = () => this.player.getState() === clpp.Player.State.PREPARING;

    isError = () => this.player.getState() === clpp.Player.State.ERROR;

    isIdle = () => this.player.getState() === clpp.Player.State.IDLE;

    getPlayerVersion = () => clpp.version;

    playerState = () => this.player.getState();

    isLive = () => this.player.isLive();

    isInitialized = () => this.initialized;

    getTimeshift = () => this.timeshift;

    getTunedAt = () => (this.tunedAt ? new Date(this.tuneAt).toISOString() : new Date().toISOString());

    getPausedAt = () => new Date(this.getPlayedTime(false) * 1000).toISOString();

    getConfiguration = () => this.player.getConfiguration();

    getTextDisplayer = () => this.player.getTextDisplayer();

    setFontSize = (size) => this.player.getTextDisplayer().setFontSize(size);

    getFontSize = () => this.player.getTextDisplayer().getFontSize();

    setFontColor = (color) => this.getTextDisplayer().setFontColor(color);

    getFontColor = () => this.player.getTextDisplayer().getFontColor();

    getEdgeColor = () => this.player.getTextDisplayer().getEdgeColor();

    setEdgeType = (type) => this.player.getTextDisplayer().setEdgeType(type);

    getEdgeType = () => this.player.getTextDisplayer().getEdgeType();

    setBackgroundColor = (bgColor) => this.getTextDisplayer().setBackgroundColor(bgColor);

    getBackgroundColor = () => this.getTextDisplayer().getBackgroundColor();

    setTimeshift = (value) => {
        try {
            if (this.isDynamic()) {
                this.timeshift = value;
            }
        } catch (error) {
            console.warn('[Player] Can not set timeshift', error);
        }
    };

    getPlayerOptions = () => {
        const audio = this.getAudioStreams();
        const subtitles = this.getSubtitleStreams();
        return {
            audio,
            subtitles,
        };
    };

    getAudioStreams = () => {
        try {
            const audioLanguages = selectAvailableAudioLanguages(store.getState());
            const tracks = this.player
                .getTrackManager()
                .getAudioTracks()
                .map((track) => ({
                    ...track,
                    iso_code: audioLanguages[track.language].languageCode_639_2T,
                    name: audioLanguages[track.language].translationKey || 'unknown',
                    codec: track?.codec,
                }));

            return [...tracks];
        } catch (error) {
            console.warn('[Player] Can not get audio streams', error);
            return [];
        }
    };

    setAudioStream = (nextAudioTrack) => {
        try {
            if (isEmpty(nextAudioTrack)) {
                console.warn('[Player] Audio track id is undefined, audio will not change');
                return;
            }
            const newTrack = this.player.getTrackManager().findAudioTrack({ id: nextAudioTrack.id });
            this.player?.getTrackManager()?.setAudioTrack(newTrack);
        } catch (error) {
            console.warn('[Player] Can not set audio stream', error);
        }
    };

    mute = () => this.player.setMuted(true);

    unMute = () => this.player.setMuted(false);

    getSubtitleStreams = () => {
        const subtitleLanguages = selectAvailableSubtitleLanguages(store.getState());
        try {
            const subtitles = this.player
                .getTrackManager()
                .getTextTracks()
                ?.map((track) => {
                    const isHoH = track?.roles?.includes('caption');
                    const trackLang = isHoH
                        ? `${subtitleLanguages[track.language]?.languageCode_639_2T}_HoH`
                        : subtitleLanguages[track.language]?.languageCode_639_2T;
                    return {
                        ...track,
                        iso_code: trackLang,
                        name: subtitleLanguages[track.language].translationKey,
                    };
                })
                .filter((track) => track.name !== null);

            return [disableSubtitleOption, ...subtitles];
        } catch (error) {
            return [];
        }
    };

    setSubtitleStream = (subtitleOption) => {
        try {
            const newTrack = this.player?.getTrackManager()?.findTextTrack({ id: subtitleOption.id });
            this.player?.getTrackManager()?.setTextTrack(newTrack);
        } catch (error) {
            console.warn('[Player] Can not set subtitle', error);
        }
    };

    getCurrentAudioTrack = () => this.player.getTrackManager().getAudioTrack();

    getCurrentSubtitle = () => this.player.getTrackManager().getTextTrack();

    getCurrentVideoTrack = () => this.player.getTrackManager().getVideoTrack();

    getCurrentVideoRendition = () => this.player.getTrackManager().getVideoRendition();

    getCurrentAudioRendition = () => this.player.getTrackManager().getAudioRendition();

    disableSubtitles = () => this.setSubtitleStream(disableSubtitleOption);

    getDuration = () => {
        try {
            return this.player?.getDuration() || 0;
        } catch (error) {
            console.warn('[Player] Can not get duration', error);
            return null;
        }
    };

    getLiveDuration = () => {
        try {
            const ranges = this.player.getSeekRange();
            return ranges.end - ranges.start;
        } catch (error) {
            console.warn('[Player] Can not get live duration', error);
            return null;
        }
    };

    // TODO: should find way to remove it maybe or at least have 1 method not two
    getPlayedTime = (isFloor = true) => {
        try {
            const playerTime = this.player?.getPosition();

            return isFloor ? Math.floor(playerTime) : playerTime;
        } catch (error) {
            console.warn('[Player] Can not get played time', error);
            return null;
        }
    };

    // TODO: should find way to remove it maybe

    getPlayedTimeUTC = (isFloor = true) => {
        try {
            const playedTimeUtc = (this.player.getPresentationStartTime() ?? 0) + (this.player?.getPosition() ?? 0);
            return isFloor ? Math.floor(playedTimeUtc) : playedTimeUtc;
        } catch (error) {
            console.warn('[Player] Can not get played time UTC', error);
            return null;
        }
    };

    getPositionToEnd = () => {
        try {
            const playerCurrentTime = this.player?.getPosition();
            const playerEndTime = this.player?.getSeekRange()?.end;

            return Math.floor(playerEndTime - playerCurrentTime);
        } catch (error) {
            console.warn('[Player] Can not get time left until end of asset', error);
            return null;
        }
    };

    suspend = async () => this.pause();

    restore = async () => this.play();

    getMinStartSeekPosition = () => {
        try {
            const seekable = this.player.getSeekRange();
            return seekable.start;
        } catch (error) {
            console.warn('[Player] Can not get min start seek position', error);
            return null;
        }
    };

    // TODO: should find way to remove it
    isCatchupDynamic = (assetData) => {
        const isCatchup = assetData?.assetType === AssetType.EVENT && assetData?.programType === ProgramType.CATCHUP;
        return this.isDynamic() && isCatchup;
    };

    // TODO: should find way to remove it
    getDynamicCatchupPlayedTime = (isFloor = true) => {
        try {
            return Math.max(this.getPlayedTimeUTC(isFloor) - this.program.startTime / 1000 + (this.program?.leadIn ?? 0), 0);
        } catch (error) {
            console.warn('[Player] Can not get dynamic catchup played time', error);
            return NaN;
        }
    };

    // TODO: should find way to remove it
    getDynamicCatchupStartPosition = () => {
        try {
            return this.getPlayedTime() - this.getDynamicCatchupPlayedTime();
        } catch (error) {
            console.warn('[Player] Can not get dynamic catchup start position', error);
            return NaN;
        }
    };

    // TODO: should find way to remove it
    getDynamicBookmark = (bookmark) => {
        if (!bookmark) {
            return null;
        }
        return this.getDynamicCatchupStartPosition() + this.bookmark;
    };

    getCurrentVideoQuality = () => {
        try {
            const rendition = this.player.getTrackManager().getVideoRendition();
            if (!rendition) return VideoQuality.HD;

            const { width, height } = rendition;
            if (width < 1280 && height < 720) {
                return VideoQuality.SD;
            }
            // adapted to our channels
            if (width <= 1920 && height <= 1080) {
                return VideoQuality.HD;
            }
            return VideoQuality.UHD;
        } catch (error) {
            console.warn('[Player] Can not get video track', error);
            return null;
        }
    };

    addEventListener = (event, callBack) => {
        if (!this.player) return;
        this.player.on(event, callBack);
    };

    removeEventListener = (event, callBack) => {
        if (!this.player) return;
        this.player.off(event, callBack);
    };

    addOneTimeEventListener = (event, callBack) => {
        if (!this.player) return;
        this.player.one(event, callBack);
    };
}

export default window.PlayerPlatform = new Player();
