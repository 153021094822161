import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { messageLoad, messageRead, messageRemove } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { selectMessageStatus, selectMessagesTotalCount } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { toMessageDetail } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import {
    selectMessageInboxIsFilterApplied,
    selectMessageInboxIsSelectMode,
    selectMessageInboxMessagesIds,
    selectMessageInboxSelectedIds,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';
import {
    MessageInboxActionID,
    MessageInboxCTX,
    MessageInboxFilter,
    MessageInboxFilterChangedAction,
    MessageInboxItemActionID,
    MessageInboxMessageSelectedAction,
    MessageInboxModeChangedAction,
    MessageInboxMessageSelectManyAction as MessageInboxSelectedChangedAction,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

// Action Creators
export const messageInboxModeChanged = (): MessageInboxModeChangedAction => ({
    type: ActionType.MESSAGE_INBOX_MODE_CHANGED,
});

export const messageInboxFilterChanged = (filter: string): MessageInboxFilterChangedAction => ({
    type: ActionType.MESSAGE_INBOX_FILTER_CHANGED,
    payload: { filter },
});

export const messageInboxMessageSelected = (messageId: GQL.MessageID): MessageInboxMessageSelectedAction => ({
    type: ActionType.MESSAGE_INBOX_MESSAGE_SELECTED,
    payload: { messageId },
});

export const messageInboxSelectedChanged = (messageIds: GQL.MessageID[]): MessageInboxSelectedChangedAction => ({
    type: ActionType.MESSAGE_INBOX_SELECTED_CHANGED,
    payload: { messageIds },
});

// Message Inbox Thunks
const actionForMessageInboxActionID: Record<MessageInboxActionID, () => Thunk<void>> = {
    [MessageInboxActionID.FILTER]: () => (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.MessageFilterSelect, {}));
    },
    [MessageInboxActionID.SELECT]: () => (dispatch, getState) => {
        if (!selectMessagesTotalCount(getState())) {
            dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.ACTIONS));
        } else {
            dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
        }
        dispatch(messageInboxModeChanged());
    },
    [MessageInboxActionID.SELECT_ALL]: () => (dispatch, getState) => {
        const messageIds = selectMessageInboxMessagesIds(getState());
        dispatch(messageInboxSelectedChanged(messageIds));
    },
    [MessageInboxActionID.MARK_AS_READ]: () => async (dispatch, getState) => {
        const messageIds = selectMessageInboxSelectedIds(getState());
        if (!messageIds.length) {
            dispatch(textNotificationShow('NOTIFICATION_SELECT_ITEM_ACTION_GUARD'));
            return;
        }
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        await dispatch(messageRead(messageIds));
        dispatch(messageInboxSelectedChanged([]));
        dispatch(messageInboxModeChanged());
        dispatch(Overlay.actions.unmount());
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
    },
    [MessageInboxActionID.DELETE]: () => async (dispatch, getState) => {
        const messageIds = selectMessageInboxSelectedIds(getState());
        if (!messageIds.length) {
            dispatch(textNotificationShow('NOTIFICATION_SELECT_ITEM_ACTION_GUARD'));
            return;
        }
        dispatch(Prompt.actions.mount(Prompt.Type.MessageConfirmDelete, { messageIds }));
    },
};

export const messageInboxActionSelected =
    (action: MessageInboxActionID): Thunk<void> =>
    (dispatch) =>
        dispatch(actionForMessageInboxActionID[action]());

export const messageInboxBackTriggered: Thunk<void> = (dispatch, getState) => {
    const state = getState();
    const isSelectMode = selectMessageInboxIsSelectMode(state);
    if (isSelectMode) {
        dispatch(messageInboxModeChanged());
        return;
    }
    const isFilterApplied = selectMessageInboxIsFilterApplied(state);
    if (isFilterApplied) {
        dispatch(messageInboxFilterChanged(MessageInboxFilter.NO_FILTER));
        return;
    }
    dispatch(History.actions.pop());
};

export const messageInboxRemoveSelectedItems = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
    const messageIds = selectMessageInboxSelectedIds(getState());
    await dispatch(messageRemove(messageIds));
    dispatch(messageInboxSelectedChanged([]));
    dispatch(messageInboxModeChanged());
    dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
};

// Message Inbox Item Thunks
const actionForMessageInboxItemActionID: Record<MessageInboxItemActionID, (messageId: GQL.MessageID) => Thunk<void>> = {
    [MessageInboxItemActionID.DELETE]: (messageId) => (dispatch) => {
        dispatch(Prompt.actions.mount(Prompt.Type.MessageConfirmDelete, { messageIds: [messageId] }));
    },
    [MessageInboxItemActionID.READ_MESSAGE]: (messageId) => async (dispatch, getState) => {
        const status = selectMessageStatus(messageId)(getState());
        if (status === GQL.MessageStatus.New) {
            dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
            await dispatch(messageRead([messageId]));
            dispatch(Overlay.actions.unmount());
        }
        dispatch(
            History.actions.push({ overlay: null, screen: Screen.generator(Screen.Type.MESSAGE_INBOX, { focused: messageId ?? null }) })
        );
        dispatch(toMessageDetail(messageId));
    },
};

export const messageInboxItemActionSelected =
    (id: GQL.MessageID, action: MessageInboxItemActionID): Thunk<void> =>
    (dispatch) => {
        dispatch(actionForMessageInboxItemActionID[action](id));
    };

export const messageInboxItemSelected =
    (id: GQL.MessageID): Thunk<void> =>
    (dispatch) => {
        dispatch(messageInboxMessageSelected(id));
    };

export const toMessageInbox =
    (messageId?: GQL.MessageID): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
        dispatch(Screen.actions.mount(Screen.Type.MESSAGE_INBOX, { focused: messageId ?? null }));
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
        await dispatch(messageLoad());
        dispatch(Overlay.actions.unmount());
    };

export const messageInboxInitializeCTX = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const messagesCount = selectMessagesTotalCount(state);
    const context = messagesCount === 0 ? MessageInboxCTX.ACTIONS : MessageInboxCTX.LIST;
    dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, context));
};

export const messageInboxOutOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch, getState) => {
    if (ctx === MessageInboxCTX.BACK && event.x === 1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.ACTIONS));
        return;
    }
    if (ctx === MessageInboxCTX.BACK && event.y === 1) {
        if (!selectMessagesTotalCount(getState())) return;
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
        return;
    }
    if (ctx === MessageInboxCTX.ACTIONS && event.x === -1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.BACK));
        return;
    }
    if (ctx === MessageInboxCTX.ACTIONS && event.y === 1) {
        if (!selectMessagesTotalCount(getState())) return;
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.LIST));
        return;
    }
    if (ctx === MessageInboxCTX.LIST && event.y === -1) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MESSAGE_INBOX, MessageInboxCTX.ACTIONS));
    }
};

export const messageInboxLifeCycle = {
    mount: messageInboxInitializeCTX,
    outOfBounds: messageInboxOutOfBounds,
};
