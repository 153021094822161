import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import Asset from '__SMART_APP_OLD__/data/Asset';

import NetworkRecording from '__SMART_APP_OLD__/data/NetworkRecording';
import ProgramEvent from '__SMART_APP_OLD__/data/ProgramEvent';
import Series from '__SMART_APP_OLD__/data/Series';
import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import VODSeries from '__SMART_APP_OLD__/data/VODSeries';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

export enum ActionTypes {
    Load = 'MyLibraryHeaderData/Load',
    LoadAsset = 'MyLibraryHeaderData/LoadAsset',
}

export type MyLibraryHeaderAsset = Asset | NetworkRecording | ProgramEvent | VODAsset | Series | VODSeries;

export type MyLibraryHeaderLoadAction = PayloadedAction<ActionTypes.Load, DetailItem | null>;
export type MyLibraryHeaderLoadAssetAction = PayloadedAction<ActionTypes.LoadAsset, MyLibraryHeaderAsset>;
