import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { initialState } from 'App/Modules/Alert/Store/initialState';
import { AlertActionType, AlertState } from 'App/Modules/Alert/Types';

export const reducer: Reducer<AlertState> = (state, action) => {
    if (state === undefined) return initialState;

    switch (action.type) {
        case AlertActionType.Changed:
            if (!action.payload.alert) return null;
            return action.payload.alert;
        default:
            return state;
    }
};
