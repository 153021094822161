import React, { useCallback } from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ButtonAnimation, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageInboxActionSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { MESSAGE_INBOX_ACTION_FOR_ACTION_ID } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/constants';
import {
    selectMessageActionsTranslation,
    selectMessageInboxActions,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';
import { MessageInboxActionID, MessageInboxCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';
import { store } from '__SMART_APP_OLD__/app/store/store';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const MessageInboxActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const actions = useSelector(selectMessageInboxActions);
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: MessageInboxCTX.ACTIONS,
        axis: Axis.X,
        items: actions,
        resetFocusOnBlur: true,
        resetFocusIndex: 0,
    });

    const enterHandler = useCallback((action: MessageInboxActionID) => dispatch(messageInboxActionSelected(action)), [dispatch]);
    return (
        <>
            {actions.map((action) => (
                <Button
                    key={action}
                    id={action}
                    ctx={MessageInboxCTX.ACTIONS}
                    className="message-inbox__action"
                    isFocused={focused === action && !isDisabled}
                    width={ButtonWidth.FIT}
                    corners={ButtonCornersStyle.ROUNDED}
                    animation={ButtonAnimation.NONE}
                    icon={MESSAGE_INBOX_ACTION_FOR_ACTION_ID[action].icon}
                    iconFlavour={MESSAGE_INBOX_ACTION_FOR_ACTION_ID[action].flavour}
                    onEnter={enterHandler}
                    onHover={focus}
                >
                    {selectMessageActionsTranslation(MESSAGE_INBOX_ACTION_FOR_ACTION_ID[action].label)(store.getState())}
                </Button>
            ))}
        </>
    );
};
