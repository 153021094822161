import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type UpsellProductsFragment = { __typename?: 'ProductCatalog', id: string, items: Array<{ __typename?: 'ChannelProduct' } | { __typename?: 'PPVProduct' } | { __typename?: 'ProductBundle' } | { __typename?: 'VODProduct', title: string, id: string, videoQuality?: Types.VideoQuality | null, packageProduct: boolean, kind: Types.ProductKind, price?: { __typename?: 'Price', id: string, expiry: any, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, expiry: any, availableUntil?: any | null } | null } | null> };

export const UpsellProductsFragmentDoc = gql`
    fragment upsellProducts on ProductCatalog {
  id
  items {
    ... on VODProduct {
      title
      id
      videoQuality
      packageProduct
      price {
        id
        expiry
        amount
        currency
      }
      kind
      entitlement {
        id
        expiry
        availableUntil
      }
    }
  }
}
    `;