import React from 'react';
import { connect } from 'react-redux';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import {
    selectPlayerCatchupPlaybackThreshold,
    selectPlayerSkipBackwardStep,
    selectPlayerSkipForwardStep,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { seekNotificationShow, textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Events, { PLAYER_SEEK } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import { calculateVodProgress } from '__SMART_APP_OLD__/utils/dataUtils';
import { convertMilliseconds } from '__SMART_APP_OLD__/utils/timeUtils';
import { debounce } from '__SMART_APP_OLD__/utils/Utils';
import translate from 'language/translate';

import { Key } from 'App/Modules/Key';
import { AdsScippingEngine } from 'App/Modules/AdsScipping';
import { Env } from 'App/Env';

class VodProgressBar extends React.Component {
    nodeRef = React.createRef();

    rangeRef = React.createRef();

    seeking = false;

    skipping = false;

    amountToSeek = 0;

    prePadding = 0;

    postPadding = 0;

    seekWithDebounce = debounce((secondToSeekAt) => {
        this.seek(secondToSeekAt);
    }, 1000);

    seekDisabled = false;

    constructor(props) {
        super(props);
        const {
            data: { streamPrePadding, streamPostPadding },
        } = this.props;
        const skipForward = selectPlayerSkipForwardStep(store.getState());
        const skipBackward = selectPlayerSkipBackwardStep(store.getState());
        this.prePadding = streamPrePadding || 0;
        this.postPadding = streamPostPadding || 0;
        this.state = {
            initialized: false,
            isPlaying: Player.isPlaying(),
            currentTime: 0,
            seekAmount: 0,
            duration: this.getDuration(),
            amountToSeekForward: convertMilliseconds(skipForward) || convertMilliseconds(60),
            amountToSeekBack: convertMilliseconds(skipBackward) || convertMilliseconds(30),
            buffered: 0,
        };
        this.keysToBeHandled = [
            Key.VK_LEFT,
            Key.VK_RIGHT,
            Key.VK_REWIND,
            Key.VK_FAST_FWD,
            Key.VK_ENTER,
            Key.VK_CHAN_UP,
            Key.VK_CHAN_DOWN,
            Key.VK_UP,
        ];
    }

    componentDidMount() {
        Player.addEventListener(VIDEO_EVENTS.LOADEDDATA, this.handleLoadedData);
        Player.addEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.addEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.addEventListener(VIDEO_EVENTS.SEEKED, this.onSeeked);
        Player.addEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        Player.addEventListener(VIDEO_EVENTS.DURATIONCHANGE, this.onDurationChange);
        Events.addEventListener(PLAYER_SEEK, this.handlePlayerSeek);
        window.addEventListener('keyup', this.handlePlayerSeekKeyup);
    }

    componentDidUpdate() {
        if (this.state.currentTime !== Player.getPlayedTime()) {
            this.props.onTimeUpdate?.(this.state.currentTime);
        }
    }

    componentWillUnmount() {
        Player.removeEventListener(VIDEO_EVENTS.LOADEDDATA, this.handleLoadedData);
        Player.removeEventListener(VIDEO_EVENTS.PLAY, this.onPlay);
        Player.removeEventListener(VIDEO_EVENTS.PAUSE, this.onPause);
        Player.removeEventListener(VIDEO_EVENTS.SEEKED, this.onSeeked);
        Player.removeEventListener(VIDEO_EVENTS.BUFFERING_STARTED, this.onBufferingProgress);
        Events.removeEventListener(PLAYER_SEEK, this.handlePlayerSeek);
        Player.removeEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        Player.removeEventListener(VIDEO_EVENTS.DURATIONCHANGE, this.onDurationChange);
        window.removeEventListener('keyup', this.handlePlayerSeekKeyup);

        this.onPlayerHide();
    }

    isDynamic = () => {
        const { data } = this.props;
        return Player.isCatchupDynamic(data);
    };

    getDuration = () => {
        const { data } = this.props;
        if (data.isTrailer) {
            return Player.getDuration();
        }
        return data.duration + (data?.leadIn ?? 0) + (data?.leadOut ?? 0);
    };

    getPlayedTime = () => {
        if (this.isDynamic()) {
            return Player.getDynamicCatchupPlayedTime();
        }
        return Player.getPlayedTime();
    };

    onEnter = () => {
        if (!this.seeking) {
            Player.playPause();
        }
    };

    handleLoadedData = () => {
        this.setState({ seekAmount: 0 });
    };

    onPlay = () => {
        this.setState({
            isPlaying: true,
            initialized: true,
        });
    };

    onPause = () => {
        this.setState({
            isPlaying: false,
        });

        const { saveBookmark } = this.props;
        if (typeof saveBookmark === 'function') {
            saveBookmark();
        }
    };

    onPlayerHide = () => {
        const { isPlaying } = this.state;
        if (isPlaying) {
            Player.pause();
        }
    };

    onProgressChanged = () => {
        if (this.seekDisabled) return;
        const secondToSeekAt = (Number(this.rangeRef ? this.rangeRef.current.value : 0) * this.getDuration()) / 100;
        const unwatchedAdEvent = Player.getUnwatchedAdEvent(secondToSeekAt, false);
        const { currentTime } = this.state;
        const insideAd = unwatchedAdEvent && currentTime >= unwatchedAdEvent.startTime && currentTime <= unwatchedAdEvent.endTime;
        const isSeekingForward = secondToSeekAt > currentTime;

        let seekPosition = unwatchedAdEvent?.startTime || secondToSeekAt;
        if (insideAd) {
            seekPosition = isSeekingForward ? currentTime : secondToSeekAt;

            // Showing the notification that the user cannot seek further
            if (isSeekingForward) {
                this.props.textNotificationShow('NOTIFICATION_TRICK_PLAY_BLOCKED', 3000);
                return;
            }
        }

        const duration = this.getDuration();

        if (seekPosition >= duration && !this.isDynamic()) {
            seekPosition = duration - 1;
        }
        this.seeking = true;
        this.setState({ currentTime: seekPosition });

        this.seekWithDebounce(seekPosition);
    };

    onSeeked = () => {
        if (!this.seeking && this.state.seekAmount) {
            return;
        }

        this.seekDisabled = false;
        this.seeking = false;
        this.skipping = false;
        if (this.amountToSeek !== 0) {
            this.displaySeekSnackbar(this.amountToSeek, this.amountToSeek > 0 ? 'forward' : 'backward');
            this.amountToSeek = 0;
        }
        this.setState({ seekAmount: 0 });
        this.onTimeUpdate();
    };

    displaySeekSnackbar = (duration, direction) => {
        if (direction) {
            const seekIcon = direction === 'forward' ? NotificationIconType.SEEK_FORWARD : NotificationIconType.SEEK_BACKWARD;
            const text = this.secondsToTime(duration, true);
            this.props.notificationShow(text, seekIcon);
        }
    };

    onDurationChange = () => {
        this.setState({ duration: this.getDuration(), currentTime: this.getPlayedTime(), initialized: true });
    };

    onTimeUpdate = () => {
        const { seekAmount, initialized } = this.state;
        Player.playerHandleTimeUpdate();
        const duration = this.getDuration();
        const playedTime = this.getPlayedTime();
        this.seekDisabled = false;

        if (playedTime) {
            if (seekAmount === 0 && !this.seeking) {
                this.setState({ currentTime: playedTime });
            }
        }
        // duration might come as a huge number in minutes, which is a replacement value
        // this is a fast, hacked solution, but it works...
        const sevenDayLimit = 7 * 24 * 60 * 60;

        if (!initialized && !(duration === Number.POSITIVE_INFINITY || duration === Number.NEGATIVE_INFINITY) && duration < sevenDayLimit) {
            this.setState({ initialized: true, duration });
        }
    };

    handlePlayerSeekKeyup = (event) => {
        const focusedEl = Focus.focused?.nodeRef;
        const scrubberWrapper = document.querySelector('.scrubber-wrapper');
        if (focusedEl !== scrubberWrapper) return false;
        const { keyCode, type } = event;
        switch (keyCode) {
            case Key.VK_REWIND:
            case Key.VK_FAST_FWD:
                this.handleKeyEvent(keyCode, event, type, true);
                return true;
            case Key.VK_LEFT:
            case Key.VK_RIGHT: {
                this.handleKeyEvent(keyCode, event, type, true);
                return true;
            }
            default:
                return false;
        }
    };

    handlePlayerSeek = ({ keyCode, eventType, playerUiVisible = false }) => {
        this.handleKeyEvent(keyCode, undefined, eventType, playerUiVisible);
    };

    seekToPointer = (event) => {
        const { seekAmount, currentTime } = this.state;
        const currentEvent = event.mouseEvent ? event.mouseEvent : event;
        const watched = currentEvent?.target?.parentNode?.firstElementChild;

        const intCurrentTime = Math.round(currentTime);
        const unwatchedAdEvent = AdsScippingEngine.getUnwatchedAdEvent(intCurrentTime, false);
        const insideAd = unwatchedAdEvent && intCurrentTime >= unwatchedAdEvent?.startTime && intCurrentTime <= unwatchedAdEvent?.endTime;
        if (insideAd) {
            this.props.textNotificationShow('NOTIFICATION_TRICK_PLAY_BLOCKED', 3000);
            return false;
        }

        if (watched) {
            this.skipping = true;
            const watchedClientRect = watched.getBoundingClientRect();
            if (!watchedClientRect?.width) {
                return false;
            }
            // eslint-disable-next-line no-unsafe-optional-chaining
            const secondsInOnePX = currentTime / watchedClientRect?.width;
            const seekAmountPX = currentEvent.clientX - watchedClientRect.right;

            this.setState(
                {
                    seekAmount: seekAmount + secondsInOnePX * seekAmountPX,
                    currentTime: currentTime + secondsInOnePX * seekAmountPX,
                },
                this.seekWithDebounce
            );
            return true;
        }
        return false;
    };

    onMouseMove = (event) => {
        if (Focus.isDragging) {
            return this.seekToPointer(event);
        }
        return false;
    };

    // eslint-disable-next-line max-statements,react-func/max-lines-per-function, complexity
    handleKeyEvent = (keyCode, event, eventType, playerUiVisible = true) => {
        const { currentTime } = this.state;
        if (!this.keysToBeHandled.includes(keyCode)) {
            return false;
        }
        this.props.resetTimer();

        if (keyCode === Key.VK_CHAN_UP || keyCode === Key.VK_CHAN_DOWN) {
            this.seekDisabled = true;
            return false;
        }
        this.seekDisabled = false;
        // long press will be handled separately
        if (currentTime !== 0) {
            if (keyCode === Key.VK_ENTER && event) {
                return this.seekToPointer(event);
            }
            const { amountToSeekForward, amountToSeekBack, duration } = this.state;

            switch (keyCode) {
                case Key.VK_REWIND:
                case Key.VK_LEFT: {
                    this.skipping = true;
                    if (!playerUiVisible) {
                        // while no player UI is visible, we save the values and display
                        // the total amount in the snackbar
                        this.amountToSeek += Math.max(-amountToSeekBack, -currentTime);
                    }

                    // For future clarification after testing on both platforms:
                    // when the progress bar is focused and the user presses LEFT LEFT LEFT,
                    // then it’s -45sec.
                    // If the user presses RIGHT (+30sec) before it jumps,
                    // the action performed will be +30sec.
                    // So the jumping will reset,
                    // it will not add up the steps from the first action/s if the actual
                    // jumping was not performed.
                    // Now the steps are added and the jumping is not reset.

                    this.setState(
                        {
                            seekAmount: this.calculateSeekAmount(keyCode),
                            currentTime: this.calculateCurrentTime(keyCode, event?.type ?? eventType),
                        },
                        this.seekWithDebounce
                    );
                    break;
                }
                case Key.VK_FAST_FWD:
                case Key.VK_RIGHT:
                    if (Player.seekingIsAllowed()) {
                        this.skipping = true;
                        if (!playerUiVisible) {
                            // while no player UI is visible,
                            // we save the values and display the total amount in the snackbar
                            this.amountToSeek += Math.min(amountToSeekForward, duration - currentTime);
                        }

                        // For future clarification after testing on both platforms:
                        // when the progress bar is focused and the user presses LEFT LEFT LEFT,
                        // then it’s -45sec.
                        // If the user presses RIGHT (+30sec) before it jumps,
                        // the action performed will be +30sec.
                        // So the jumping will reset,
                        // it will not add up the steps from the first action/s
                        // if the actual jumping was not performed.
                        // Now the steps are added and the jumping is not reset.

                        this.setState(
                            {
                                seekAmount: this.calculateSeekAmount(keyCode),
                                currentTime: this.calculateCurrentTime(keyCode, event?.type ?? eventType),
                            },
                            this.seekWithDebounce
                        );
                    } else {
                        this.props.textNotificationShow('NOTIFICATION_TRICK_PLAY_BLOCKED', 3000);
                    }

                    break;
                case Key.VK_UP:
                    return this.props.handleNavigateUp();
                default:
            }
        }
        return true;
    };

    calculateSeekAmount = (keyCode) => {
        const { seekAmount, amountToSeekForward, amountToSeekBack, duration, currentTime } = this.state;
        const totalSeekAmount = seekAmount + amountToSeekForward;
        // the total seek amount must be adjusted,
        // so that it won't cause the current time to land on an Ad time interval

        return [Key.VK_RIGHT, Key.VK_FAST_FWD].includes(keyCode)
            ? Math.min(currentTime + totalSeekAmount, duration - currentTime)
            : Math.max(seekAmount - amountToSeekBack, -currentTime);
    };

    calculateCurrentTime = (keyCode, eventType) => {
        const { currentTime, amountToSeekForward, amountToSeekBack, duration } = this.state;
        if (eventType !== 'keyup' && Env.IsSlovenia) {
            return [Key.VK_RIGHT, Key.VK_FAST_FWD].includes(keyCode)
                ? Math.min(currentTime + amountToSeekForward, duration - 5)
                : Math.max(currentTime - amountToSeekBack, 0);
        }
        const adOverlappingAdjustedTime = AdsScippingEngine.seekOverlappingAd(currentTime, currentTime + amountToSeekForward, false);
        // the calculated current time must be adjusted so it won't land inside Ad time interval
        return [Key.VK_RIGHT, Key.VK_FAST_FWD].includes(keyCode)
            ? Math.min(adOverlappingAdjustedTime, duration - 5)
            : Math.max(currentTime - amountToSeekBack, 0);
    };

    secondsToTime = (duration, isPlayerSeekNotification = false) => {
        let isNegative = false;
        if (duration < 0) {
            duration = -duration;
            isNegative = true;
        }
        let seconds = duration;
        let minutes = Math.floor(seconds / 60) % 60;
        let hours = Math.floor(seconds / 3600);

        hours = hours >= 1 ? `${hours}:` : '';
        minutes = isPlayerSeekNotification ? minutes : minutes >= 10 ? minutes : `0${minutes}`;
        seconds = Math.floor(seconds % 60);
        seconds = seconds >= 10 ? seconds : `0${seconds}`;

        if (isPlayerSeekNotification) {
            const secondsText = `${seconds} ${translate('SCREEN_PLAYER_SKIP_SECONDS')}`;
            const minutesText = `${minutes} ${translate('SCREEN_PLAYER_SKIP_MINUTES')}`;

            return `${minutes >= 1 ? minutesText : ''} ${secondsText}`;
        }

        return `${isNegative ? '-' : ''}${hours}${minutes}:${seconds}`;
    };

    getDisplayTime = () => {
        const { currentTime = 0, duration = 0, initialized } = this.state; // duration = stream duration detected by the Player. it will include the paddings
        const { setButtonsUpdated, buttonsUpdateThreshold } = this.props;
        const isValidPlaybackState = initialized && !Number.isNaN(currentTime) && !Number.isNaN(duration);
        const programDuration = duration - (this.prePadding + this.postPadding); // duration of the video without paddings
        const playedTime = currentTime - this.prePadding;
        const remainingTime = -(duration - currentTime - this.postPadding);

        if (setButtonsUpdated && isValidPlaybackState && Math.floor(playedTime) === buttonsUpdateThreshold) {
            setButtonsUpdated();
        }

        return isValidPlaybackState
            ? [
                  this.secondsToTime(playedTime > programDuration ? programDuration : playedTime),
                  `${remainingTime > 0 ? '+' : ''}${this.secondsToTime(remainingTime)}`,
              ]
            : ['00:00', '00:00'];
    };

    getProgress = () => {
        const { currentTime, seekAmount, duration, initialized } = this.state;
        return initialized ? calculateVodProgress(duration, !this.skipping ? currentTime + seekAmount : currentTime) : 0;
    };

    seek = (secondToSeekAt = null) => {
        const { onSeek } = this.props;

        const { currentTime } = this.state;

        const position = typeof secondToSeekAt === 'number' ? secondToSeekAt : currentTime;
        const handleSeekResult = typeof onSeek === 'function' && onSeek(position);
        if (handleSeekResult) return;

        this.seeking = true;

        if (this.isDynamic()) {
            const dynamicCatchupStartPosition = Player.getDynamicCatchupStartPosition();
            const positionToSeek = position + dynamicCatchupStartPosition;
            const catchupThreshold = selectPlayerCatchupPlaybackThreshold(store.getState());
            const minSeekablePosition = dynamicCatchupStartPosition + catchupThreshold;
            Player.seek(Math.max(positionToSeek, minSeekablePosition));
        } else {
            Player.seek(Math.max(0, position));
        }
    };

    generateAdIntervals = () =>
        Player.adCues.map((adEvent) => {
            const { duration: programDuration } = this.state;
            const { duration: adDuration, startTime, id } = adEvent;
            const relativeDuration = (adDuration * 100) / programDuration;
            const relativeStartTime = (startTime * 100) / programDuration;
            const width = relativeStartTime + relativeDuration > 100 ? 100 - relativeStartTime : relativeDuration;
            return <span key={id} className="adEvent" style={{ width: `${width}%`, left: `${relativeStartTime}%` }}></span>;
        });

    render() {
        const progress = this.getProgress();
        const displayTime = this.getDisplayTime();
        const { onCustomRect } = this.props;
        const { buffered } = this.state;
        return (
            <div className="progress-bar-container">
                <Text
                    className="duration-played"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {displayTime[0]}
                </Text>
                <Focusable
                    className="player-progress-bar scrubber-wrapper"
                    onKey={this.handleKeyEvent}
                    onEnter={this.onEnter}
                    onMouseMove={this.onMouseMove}
                    onCustomRect={onCustomRect}
                    ref={this.nodeRef}
                    focusDelay={false}
                >
                    <span className="watched" style={{ width: `${progress}%` }} />
                    <span className="buffered" style={{ width: `${buffered - progress}%` }} />

                    {this.generateAdIntervals()}
                    <input
                        className="scrubber"
                        type="range"
                        min="0"
                        max="100"
                        value={progress > 100 ? 0 : progress}
                        step="any"
                        ref={this.rangeRef}
                        onInput={() => this.onProgressChanged()}
                        onChange={() => this.onProgressChanged()}
                    />
                </Focusable>
                <Text
                    className="duration-remaining"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >{`${displayTime[1]}`}</Text>
            </div>
        );
    }
}

export default connect(
    null,
    (dispatch) => ({
        notificationShow: (text, icon) => dispatch(seekNotificationShow(text, icon)),
        textNotificationShow: (text) => dispatch(textNotificationShow(text)),
    }),
    null,
    { forwardRef: true }
)(VodProgressBar);
