import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrendingSearchesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTrendingSearchesQuery = { __typename?: 'Nexx4Queries', trendingSearches?: { __typename?: 'StringCatalog', items: Array<string | null> } | null };


export const GetTrendingSearchesDocument = gql`
    query getTrendingSearches {
  trendingSearches {
    items
  }
}
    `;

/**
 * __useGetTrendingSearchesQuery__
 *
 * To run a query within a React component, call `useGetTrendingSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrendingSearchesQuery(baseOptions?: Apollo.QueryHookOptions<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>(GetTrendingSearchesDocument, options);
      }
export function useGetTrendingSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>(GetTrendingSearchesDocument, options);
        }
export function useGetTrendingSearchesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>(GetTrendingSearchesDocument, options);
        }
export type GetTrendingSearchesQueryHookResult = ReturnType<typeof useGetTrendingSearchesQuery>;
export type GetTrendingSearchesLazyQueryHookResult = ReturnType<typeof useGetTrendingSearchesLazyQuery>;
export type GetTrendingSearchesSuspenseQueryHookResult = ReturnType<typeof useGetTrendingSearchesSuspenseQuery>;
export type GetTrendingSearchesQueryResult = Apollo.QueryResult<GetTrendingSearchesQuery, GetTrendingSearchesQueryVariables>;