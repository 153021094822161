import { DetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions';
import { useCallback } from 'react';

export type UseDetailActionFocus = (id: string) => () => void;

export const useDetailActionFocus: UseDetailActionFocus = (id) => {
    const actions = DetailActions.use();

    return useCallback(() => actions.focus(id), [id, actions]);
};
