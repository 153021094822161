import { KeyboardState } from 'App/Modules/Keyboard/Store/types';

const opened: KeyboardState = { open: true };
const closed: KeyboardState = { open: false };
const initial: KeyboardState = { open: false };

export const states = {
    opened,
    closed,
    initial,
};
