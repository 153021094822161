import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ParentalRatingFragmentDoc } from '../fragments/parentalRating.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ParentalRatingLoadManyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ParentalRatingLoadManyQuery = { __typename?: 'Nexx4Queries', availableParentalRatings?: { __typename?: 'ParentalRatingCatalog', items: Array<{ __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } | null> } | null };

export type ParentalRatingLoadManyFragment = { __typename?: 'Nexx4Queries', availableParentalRatings?: { __typename?: 'ParentalRatingCatalog', items: Array<{ __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } | null> } | null };

export const ParentalRatingLoadManyFragmentDoc = gql`
    fragment parentalRatingLoadMany on Nexx4Queries {
  availableParentalRatings {
    items {
      ...parentalRating
    }
  }
}
    ${ParentalRatingFragmentDoc}`;
export const ParentalRatingLoadManyDocument = gql`
    query parentalRatingLoadMany {
  ...parentalRatingLoadMany
}
    ${ParentalRatingLoadManyFragmentDoc}`;

/**
 * __useParentalRatingLoadManyQuery__
 *
 * To run a query within a React component, call `useParentalRatingLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentalRatingLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentalRatingLoadManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentalRatingLoadManyQuery(baseOptions?: Apollo.QueryHookOptions<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>(ParentalRatingLoadManyDocument, options);
      }
export function useParentalRatingLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>(ParentalRatingLoadManyDocument, options);
        }
export function useParentalRatingLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>(ParentalRatingLoadManyDocument, options);
        }
export type ParentalRatingLoadManyQueryHookResult = ReturnType<typeof useParentalRatingLoadManyQuery>;
export type ParentalRatingLoadManyLazyQueryHookResult = ReturnType<typeof useParentalRatingLoadManyLazyQuery>;
export type ParentalRatingLoadManySuspenseQueryHookResult = ReturnType<typeof useParentalRatingLoadManySuspenseQuery>;
export type ParentalRatingLoadManyQueryResult = Apollo.QueryResult<ParentalRatingLoadManyQuery, ParentalRatingLoadManyQueryVariables>;