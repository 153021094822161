import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteForeignDeviceMutationVariables = Types.Exact<{
  input: Types.DeleteForeignDeviceInput;
}>;


export type DeleteForeignDeviceMutation = { __typename?: 'Nexx4Mutations', deleteForeignDevice: { __typename?: 'DeleteForeignDevicePayload', status: Types.ForeignDeviceDeletionStatus } };


export const DeleteForeignDeviceDocument = gql`
    mutation deleteForeignDevice($input: DeleteForeignDeviceInput!) {
  deleteForeignDevice(input: $input) {
    status
  }
}
    `;
export type DeleteForeignDeviceMutationFn = Apollo.MutationFunction<DeleteForeignDeviceMutation, DeleteForeignDeviceMutationVariables>;

/**
 * __useDeleteForeignDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteForeignDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteForeignDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteForeignDeviceMutation, { data, loading, error }] = useDeleteForeignDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteForeignDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteForeignDeviceMutation, DeleteForeignDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteForeignDeviceMutation, DeleteForeignDeviceMutationVariables>(DeleteForeignDeviceDocument, options);
      }
export type DeleteForeignDeviceMutationHookResult = ReturnType<typeof useDeleteForeignDeviceMutation>;
export type DeleteForeignDeviceMutationResult = Apollo.MutationResult<DeleteForeignDeviceMutation>;
export type DeleteForeignDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteForeignDeviceMutation, DeleteForeignDeviceMutationVariables>;