import { HttpRequestEvent, RequestMethods } from 'analytics/logging/events/HttpRequestEvent';

export const getGraphQLHttpRequestEvent = (operationName: string, response: Response, latency: number): HttpRequestEvent | null => {
    if (!operationName || !response) return null;
    const { url: requestURL, status: statusCode, statusText: statusDescription } = response;
    const requestMethod = RequestMethods.POST;

    if (operationName && requestURL && statusCode) {
        return new HttpRequestEvent(latency, operationName, requestMethod, requestURL, statusCode, statusDescription);
    }

    return null;
};
