import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { EventDetailItemFragmentDoc } from '../event/eventDetailItem.generated';
import { RecordingDetailItemFragmentDoc } from '../recording/recordingDetailItem.generated';
import { SeriesDetailItemFragmentDoc } from '../series/seriesDetailitem.generated';
import { VodAssetDetailItemFragmentDoc } from '../vodAsset/vodAssetDetailItem.generated';
import { ChannelDetailItemFragmentDoc } from '../channel/channelDetailItem.generated';
import { VodSeriesDetailItemFragmentDoc } from '../vodSeries/vodSeriesDetailItem.generated';
import { ChannelProductDetailItemFragmentDoc } from '../channelProduct/channelProductFolderItem.generated';
import { BannerDetailItemFragmentDoc } from '../banner/bannerDetailItem.generated';
import { VodProductDetailItemFragmentDoc } from '../vodProduct/vodProductDetailItem.generated';
export type DetailItem_Banner_Fragment = { __typename: 'Banner', id: string };

export type DetailItem_Channel_Fragment = { __typename: 'Channel', id: string };

export type DetailItem_ChannelProduct_Fragment = { __typename: 'ChannelProduct', id: string };

export type DetailItem_Event_Fragment = { __typename: 'Event', id: string, title: string, start: any, end: any, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export type DetailItem_NetworkRecording_Fragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, allowPlayback: boolean, size: number, channelName?: string | null, personalRecordingInfo: { __typename: 'PersonalRecordingInfo', id: string, expiry: any, favourited: boolean, partOfSeriesRecording: boolean, seasonCancelled: boolean, seriesCancelled: boolean }, event: { __typename: 'Event', id: string, title: string, start: any, end: any, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null } };

export type DetailItem_ProductBundle_Fragment = { __typename: 'ProductBundle', id: string };

export type DetailItem_Series_Fragment = { __typename: 'Series', id: string, title: string, subtitle?: string | null, episodeCount: number, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean }, groupingInfos?: Array<{ __typename?: 'GroupingInfo', episodeCount: number, startCursor: string, endCursor: string, title: string, selectBehaviour: Types.GroupInfoSelectBehaviour } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export type DetailItem_VodAsset_Fragment = { __typename: 'VODAsset', id: string, title: string, duration: number, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, trailers: { __typename: 'TrailerCatalog', id: string, itemCount: number, items: Array<{ __typename: 'Trailer', id: string, expiry: any, title: string } | null> }, parentalRating: { __typename?: 'ParentalRating', id: string }, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, vodAssetEntitlements: { __typename: 'VODAssetEntitlementCatalog', id: string, expiry: any, itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', videoQuality?: Types.VideoQuality | null } | null } | null> }, personalInfo?: { __typename: 'PersonalVODInfo', favourited: boolean } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export type DetailItem_VodFolder_Fragment = { __typename: 'VODFolder', id: string };

export type DetailItem_VodProduct_Fragment = { __typename: 'VODProduct', id: string, title: string, kind: Types.ProductKind, shortDescription: string, fullDescription: string, originalTitle?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, trailers: { __typename: 'TrailerCatalog', id: string, itemCount: number, items: Array<{ __typename: 'Trailer', id: string, expiry: any, title: string } | null> }, entitlement?: { __typename: 'ProductEntitlement', availableUntil?: any | null } | null, purchaseInfo?: { __typename: 'ProductPurchaseInfo', id: string, expiry: any, status: Types.ProductPurchaseStatus } | null, productParentalRating?: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null, personalProductInfo?: { __typename: 'PersonalProductInfo', favourited: boolean, id: string, expiry: any } | null };

export type DetailItem_VodSeries_Fragment = { __typename: 'VODSeries', id: string, title: string, seasonInfos?: Array<{ __typename?: 'SeasonInfo', episodeCount?: number | null, cursor?: string | null, number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export type DetailItemFragment = DetailItem_Banner_Fragment | DetailItem_Channel_Fragment | DetailItem_ChannelProduct_Fragment | DetailItem_Event_Fragment | DetailItem_NetworkRecording_Fragment | DetailItem_ProductBundle_Fragment | DetailItem_Series_Fragment | DetailItem_VodAsset_Fragment | DetailItem_VodFolder_Fragment | DetailItem_VodProduct_Fragment | DetailItem_VodSeries_Fragment;

export const DetailItemFragmentDoc = gql`
    fragment detailItem on ContentItem {
  ... on Event {
    ...eventDetailItem
  }
  ... on NetworkRecording {
    ...recordingDetailItem
  }
  ... on Series {
    ...seriesDetailItem
  }
  ... on VODAsset {
    ...vodAssetDetailItem
  }
  ... on Channel {
    ...channelDetailItem
  }
  ... on VODSeries {
    ...vodSeriesDetailItem
  }
  ... on ChannelProduct {
    ...channelProductDetailItem
  }
  ... on Banner {
    ...bannerDetailItem
  }
  ... on VODProduct {
    ...vodProductDetailItem
  }
  ... on ProductBundle {
    id
  }
  ... on VODFolder {
    id
  }
  __typename
}
    ${EventDetailItemFragmentDoc}
${RecordingDetailItemFragmentDoc}
${SeriesDetailItemFragmentDoc}
${VodAssetDetailItemFragmentDoc}
${ChannelDetailItemFragmentDoc}
${VodSeriesDetailItemFragmentDoc}
${ChannelProductDetailItemFragmentDoc}
${BannerDetailItemFragmentDoc}
${VodProductDetailItemFragmentDoc}`;