import React, { useMemo } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageDetailScrollTrigger } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailScrollTrigger';
import { MessageDetailText } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailText';
import {
    selectMessageDetailTextLayout,
    selectMessageDetailVisibleContainerIndex,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import {
    MessageDetailCTX,
    MessageDetailContainer,
    MessageDetailContainerType,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

const getItems = (layout: MessageDetailContainer[]) => layout.filter((container) => container.type === MessageDetailContainerType.SCROLL);

export const MessageDetailContentContainer: React.FunctionComponent = () => {
    const layout = useSelector(selectMessageDetailTextLayout);
    const index = useSelector(selectMessageDetailVisibleContainerIndex);
    const items = useMemo(() => getItems(layout), [layout]);
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: MessageDetailCTX.SCROLL,
        axis: Axis.Y,
        items,
        guard: (event, f) => (f.from === index && event.y === -1) || (f.to === index && event.y === 1),
    });

    return (
        <>
            {layout.map((container) =>
                container.type === MessageDetailContainerType.TEXT ? (
                    <MessageDetailText key={`${container.type}-${container.from}-${container.to}`} container={container} />
                ) : (
                    <MessageDetailScrollTrigger
                        key={`${container.type}-${container.from}-${container.to}`}
                        container={container}
                        isFocused={container === focused && !isDisabled}
                        onHover={focus}
                    />
                )
            )}
        </>
    );
};
