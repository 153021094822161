import React from 'react';

import { NetworkRecordingDetailPage } from '__SMART_APP_OLD__/components/details/NetworkRecordingDetailPage';
import { ProgramEventDetailPage } from '__SMART_APP_OLD__/components/details/ProgramEventDetailPage';
import { SeriesDetailPage } from '__SMART_APP_OLD__/components/details/SeriesDetailPage';
import VerticalContainer from '__SMART_APP_OLD__/navigation/VerticalContainer';
import { DetailPageType } from '__SMART_APP_OLD__/utils/Constants';

import { EpisodeTvProgramDetailPage } from './EpisodeTvProgramDetailPage';
import { VodProductDetailPage } from './VodProductDetailPage';

type PropType = {
    computedMatch: {
        params: any;
        isExact: boolean;
        path: string;
        url: string;
    };
};

export const DetailPage: React.FC<PropType> = ({ computedMatch }) => {
    const type = computedMatch?.params?.type;
    const id = computedMatch?.params?.id;
    let DetailPageComponent = ProgramEventDetailPage;

    switch (type) {
        case DetailPageType.EVENT:
            // DetailPageComponent = ProgramEventDetailPage; // migrated
            break;
        case DetailPageType.NETWORK_RECORDING:
            DetailPageComponent = NetworkRecordingDetailPage;
            break;
        case DetailPageType.VOD_SERIES:
            // DetailPageComponent = SeriesDetailPage; // migrated
            break;
        case DetailPageType.MOVIE:
            // DetailPageComponent = MovieDetailPage; // migrated
            break;
        case DetailPageType.SERIES:
            DetailPageComponent = EpisodeTvProgramDetailPage;
            break;
        case DetailPageType.VOD_PRODUCT:
            DetailPageComponent = VodProductDetailPage;
            break;
        default:
            DetailPageComponent = ProgramEventDetailPage;
            break;
    }

    return (
        <VerticalContainer key={id} className={`detail-page ${type}`}>
            <DetailPageComponent id={id} />
        </VerticalContainer>
    );
};
