import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectMessageDetailHeader,
    selectMessageDetailHeaderLines,
    selectMessageDetailTheme,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

const getClassName = (lines: 1 | 2): string => {
    const classList = ['message-detail__header'];
    if (lines === 1) classList.push('message-detail__header--one-line');
    return classList.join(' ');
};

export const MessageDetailHeader: React.FunctionComponent = () => {
    const header = useSelector(selectMessageDetailHeader);
    const lines = useSelector(selectMessageDetailHeaderLines);
    const theme = useSelector(selectMessageDetailTheme);
    const className = getClassName(lines);
    return (
        <Text
            className={className}
            typeface={Typeface.SERIF}
            size={FontSize.TITLE_1}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
            theme={theme}
        >
            {header}
        </Text>
    );
};
