import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import { by639_2T } from 'iso-language-codes';

const selectSelected = (state: State) => state.language.selected;

const selectSelectedId = (state: State) => state.language.idForCode[state.language.selected];

const selectCodes = (state: State) => state.language.codes;

const selectIdByCode = (code: string) => (state: State) => state.language.idForCode[code];

const selectTitle = (code: string) => `SETTINGS_${by639_2T[code as keyof typeof by639_2T].iso639_1.toUpperCase()}`;

const selectSelectedTitle = (state: State) => selectTitle(selectSelected(state));

const selectDefaultCode = (state: State) => state.config.language.defaultUILanguageCode;

const selectOptions = (state: State): ButtonEntity[] => selectCodes(state).map((id) => ({ id, label: selectTitle(id) }));

const selectOptionIndex = (state: State): number => selectCodes(state).indexOf(selectSelected(state));

export const languageSelectors = {
    public: {
        selectSelected,
        selectSelectedId,
        selectCodes,
        selectIdByCode,
        selectTitle,
        selectSelectedTitle,
        selectDefaultCode,
        selectOptions,
        selectOptionIndex,
    },
};
