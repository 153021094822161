import { initialPinState } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/constants';
import { PinState } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const pinReducer: Reducer<PinState> = (state, action) => {
    if (!state) return initialPinState;
    switch (action.type) {
        case ActionType.PIN_ACTIVE_STATUS_CHANGED:
            if (state.isActive === action.payload.isActive) return state;
            return { ...state, isActive: action.payload.isActive };
        case ActionType.PIN_ACTIVE_STATUS_LIVE_TV_CHANGED:
            if (state.isLiveTvActive === action.payload.isLiveTvActive) return state;
            return { ...state, isLiveTvActive: action.payload.isLiveTvActive };
        case ActionType.PIN_BLOCKED_CHANGED:
            if (state.blockedUntil === action.payload.time) return state;
            return { ...state, blockedUntil: action.payload.time };
        case ActionType.PIN_FORCE_PROTECTION_CHANGED:
            if (state.forceProtection === action.payload.forceProtection) return state;
            return { ...state, forceProtection: action.payload.forceProtection };
        default:
            return state;
    }
};
