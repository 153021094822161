import { StartupPosition } from '__SMART_APP_OLD__/app/modules/Startup/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const select = (state: State): StartupPosition => state.startup;

export const startSelectors = {
    public: {
        select,
    },
} as const;
