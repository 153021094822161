import React, { ProviderProps } from 'react';

import {
    CollectionV2HeaderActionsChildren,
    CollectionV2HeaderActionsChildrenModule,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsChildren';
import { CollectionV2HeaderActionsContainer } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsContainer';
import { ActionsContext, IActionsContext } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsContext';
import {
    CollectionV2ActionButtonModule,
    CollectionV2HeaderButton,
} from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderButton';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export type CollectionV2HeaderActionsProps = {
    ctx: string;
    buttonIds: string[];
    onEnter?: (item: string) => void;
    children: React.ReactNode;
};

export type CollectionV2HeaderActionsModule = {
    Button: CollectionV2ActionButtonModule;
    Children: CollectionV2HeaderActionsChildrenModule;
} & React.FunctionComponent<CollectionV2HeaderActionsProps>;

const ActionsProvider = ActionsContext.Provider as React.FunctionComponent<ProviderProps<IActionsContext>>;

export const CollectionV2HeaderActions: CollectionV2HeaderActionsModule = (props) => {
    const { focused, focus } = Focus.useList({
        ctx: props.ctx,
        axis: Axis.X,
        items: props.buttonIds,
    });

    const contextValue = {
        focused,
        ctx: props.ctx,
        onHover: focus,
        onEnter: props.onEnter,
        items: props.buttonIds,
    };

    return (
        <CollectionV2HeaderActionsContainer>
            <ActionsProvider value={contextValue}>{props.children}</ActionsProvider>
        </CollectionV2HeaderActionsContainer>
    );
};

CollectionV2HeaderActions.Button = CollectionV2HeaderButton;
CollectionV2HeaderActions.Children = CollectionV2HeaderActionsChildren;
