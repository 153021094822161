import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type VodAssetCollectionItemEntitlementsItemsFragment = { __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null };

export const VodAssetCollectionItemEntitlementsItemsFragmentDoc = gql`
    fragment vodAssetCollectionItemEntitlementsItems on VODAssetEntitlement {
  id
  playback
  playbackAvailableUntil
}
    `;