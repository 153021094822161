import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

export enum SETTINGS_ABOUT_APP_TITLE {
    DEVICE_ID = 'SETTINGS_ABOUT_UNIQUE_DEVICE_ID',
    OS_VERSION = 'SETTINGS_ABOUT_APP_OS_VERSION',
    CONFIG_ID = 'SETTINGS_SYSTEM_INFORMATION_CONFIG_ID',
    APP_VERSION = 'SETTINGS_SYSTEM_INFORMATION_APP_VERSION',
    APP_USER_ACCOUNT = 'SETTINGS_ABOUT_APP_USER_ACCOUNT',
    TV_MODEL = 'SETTINGS_SYSTEM_INFORMATION_TV_MODEL',
}

export type SettingsAboutAppValue = Selector<string>;
