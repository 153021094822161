import React, { useCallback } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { tvGuideSnackBarTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import { selectTVGuideIsSnackBarEnabled } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import SnackBar from '__SMART_APP_OLD__/components/common/SnackBar';

export const TVGuideSnackbar: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isEnabled = useSelector(selectTVGuideIsSnackBarEnabled);
    const triggerSnackbar = useCallback((id: string) => dispatch(tvGuideSnackBarTriggered(id)), [dispatch]);

    if (!isEnabled) return null;

    return <SnackBar callback={triggerSnackbar} />;
};
