import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageDetailActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailActions';
import { MessageDetailInfo } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailInfo';
import {
    selectMessageDetailHasActions,
    selectMessageDetailTheme,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

export const MessageDetailActionsContainer: React.FunctionComponent = () => {
    const hasActions = useSelector(selectMessageDetailHasActions);
    const theme = useSelector(selectMessageDetailTheme);
    return (
        <div className="message-detail__actions-container">
            <Div className="message-detail__actions" backgroundColor={BackgroundColor.NONE} shadow={Shadow.NONE} contentTheme={theme}>
                {hasActions && <MessageDetailActions />}
            </Div>
            <MessageDetailInfo />
        </div>
    );
};
