import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Translation } from 'App/Packages/UI/Translation';

const getClassName = (props: CardMoreProps) => {
    const classlist = ['more-card'];
    if (props.isLarge) classlist.push('more-card--large');
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

const getContentClassName = (isFocused: boolean) => {
    const classlist = ['more-card__content'];
    if (isFocused) classlist.push('more-card__content--focused');
    return classlist.join(' ');
};

export type CardMoreProps = {
    className?: string;
    isFocused?: boolean;
    isLarge?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHover?: React.MouseEventHandler<HTMLDivElement>;
} & React.RefAttributes<HTMLDivElement>;

export type CardMoreModules = {};

export type CardMoreModule = React.FunctionComponent<CardMoreProps> & CardMoreModules;

export const CardMore: CardMoreModule = React.forwardRef((props, ref) => (
    <div className={getClassName(props)} onClick={props.onClick} onMouseOver={props.onHover} ref={ref}>
        <Div
            className={getContentClassName(!!props.isFocused)}
            backgroundColor={props.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
            shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
        >
            <Text
                className="more-card__text"
                color={Color.PRIMARY}
                size={FontSize.BODY_2}
                typeface={Typeface.SANS}
                weight={FontWeight.BOLD}
            >
                <Translation>VIEW_ALL</Translation>
            </Text>
        </Div>
    </div>
));
