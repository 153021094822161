import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type BannerDetailItemFragment = { __typename: 'Banner', id: string };

export const BannerDetailItemFragmentDoc = gql`
    fragment bannerDetailItem on Banner {
  id
  __typename
}
    `;