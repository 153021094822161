import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeyboardBodyProps = DivPropsExtendable & {};

export type KeyboardBodyComponent = React.FunctionComponent<KeyboardBodyProps>;

export const KeyboardBody: KeyboardBodyComponent = (props) => {
    const { children, ...other } = props;
    return (
        <Div defaultClassName="keyboard__body" {...other}>
            {children}
        </Div>
    );
};
