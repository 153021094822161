import { VodAsset } from '__SMART_APP_OLD__/api/graphql/types';
import { VodUpsellEntityTable } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const initialState: VodUpsellEntityTable = {
    assetId: '',
    asset: null,
    productOptions: [],
    temporaryEntitlement: null,
    productKind: null,
    isPurchaseOn: false,
    entitlementId: null,
};

export const vodUpsellReducer: Reducer<VodUpsellEntityTable> = (state, action) => {
    if (!state) return initialState;
    switch (action.type) {
        case ActionType.VOD_UPSELL_SET_CURRENT_ASSET:
            return { ...state, asset: action.payload.asset as VodAsset };
        case ActionType.VOD_UPSELL_SET_PRODUCTS:
            return { ...state, productOptions: action.payload.products };
        case ActionType.VOD_UPSELL_SELECT_PRODUCT:
            return state;
        case ActionType.VOD_UPSELL_SET_ASSET_ID_AND_TYPE:
            return { ...state, assetId: action.payload.assetId };
        case ActionType.VOD_UPSELL_SET_OPERATION_TYPE:
            return { ...state, productKind: action.payload.productKind };
        case ActionType.VOD_UPSELL_UPSELL_OPTION_SELECTED:
            return { ...state, entitlementId: action.payload.entitlementId };
        case ActionType.VOD_UPSELL_START_UPSELL_TRANSACTION:
        case ActionType.VOD_UPSELL_FINISH_UPSELL_TRANSACTION:
            return { ...state, isPurchaseOn: action.payload.isPurchaseOn };
        case ActionType.VOD_UPSELL_SET_TEMPORARY_ENTITLEMENT:
            return { ...state, temporaryEntitlement: action.payload.entitlement };
        case ActionType.VOD_UPSELL_CLEAN_UP_ON_USER_LEAVE:
            return initialState;
        default:
            return state;
    }
};
