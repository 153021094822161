import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import {
    NotificationAction,
    NotificationActionID,
    NotificationAnimation,
    NotificationButtonIconFlavour,
    NotificationVisibilityStatus,
    UINotificationEntityTable,
} from '__SMART_APP_OLD__/app/modules/Notification/types';

export const NOTIFICATION_OFFSET = 1.25; // 24px = 1.25vw

export const NOTIFICATION_ACTION_FOR_ACTION_ID: Record<NotificationActionID, NotificationAction> = {
    [NotificationActionID.WATCH]: { id: NotificationActionID.WATCH, label: 'SCREEN_DETAIL_PLAY_BUTTON' },
    [NotificationActionID.SNOOZE]: { id: NotificationActionID.SNOOZE, label: 'SNOOZE_BUTTON' },
    [NotificationActionID.RECORD]: { id: NotificationActionID.RECORD, label: 'SCREEN_PLAYER_RECORD_BUTTON' },
    [NotificationActionID.CANCEL]: { id: NotificationActionID.CANCEL, label: 'CANCEL_BUTTON' },
    [NotificationActionID.READ_MESSAGE]: { id: NotificationActionID.READ_MESSAGE, label: 'READ_MESSAGE' },
    [NotificationActionID.GO_TO_LIVE]: { id: NotificationActionID.GO_TO_LIVE, label: 'GO_TO_LIVE' },
    [NotificationActionID.GO_TO_CHANNEL_LIST]: { id: NotificationActionID.GO_TO_CHANNEL_LIST, label: 'MAIN_MENU_CHANNEL_LISTS' },
};

export const BUTTON_ICON_FOR_NOTIFICATION_ACTION_ID: Record<NotificationActionID, IconName> = {
    [NotificationActionID.WATCH]: IconName.PLAY,
    [NotificationActionID.SNOOZE]: IconName.REMINDER, // FIX
    [NotificationActionID.RECORD]: IconName.RECORD,
    [NotificationActionID.CANCEL]: IconName.CLOSE,
    [NotificationActionID.READ_MESSAGE]: IconName.MESSAGING,
    [NotificationActionID.GO_TO_LIVE]: IconName.TIMER,
    [NotificationActionID.GO_TO_CHANNEL_LIST]: IconName.COGWHEEL,
};

export const BUTTON_ICON_FLAVOUR_FOR_NOTIFICATION_ACTION_ID: Record<NotificationActionID, NotificationButtonIconFlavour> = {
    [NotificationActionID.WATCH]: NotificationButtonIconFlavour.DEFAULT,
    [NotificationActionID.SNOOZE]: NotificationButtonIconFlavour.DEFAULT,
    [NotificationActionID.RECORD]: NotificationButtonIconFlavour.DEFAULT,
    [NotificationActionID.CANCEL]: NotificationButtonIconFlavour.FANCY,
    [NotificationActionID.READ_MESSAGE]: NotificationButtonIconFlavour.DEFAULT,
    [NotificationActionID.GO_TO_LIVE]: NotificationButtonIconFlavour.DEFAULT,
    [NotificationActionID.GO_TO_CHANNEL_LIST]: NotificationButtonIconFlavour.FANCY,
};

export const COMPATIBLE_STATUS_TRANSITIONS_FOR_NOTIFICATION_STATUS: Record<NotificationVisibilityStatus, NotificationVisibilityStatus> = {
    [NotificationVisibilityStatus.ANIMATE_IN]: NotificationVisibilityStatus.VISIBLE,
    [NotificationVisibilityStatus.VISIBLE]: NotificationVisibilityStatus.ANIMATE_OUT,
    [NotificationVisibilityStatus.ANIMATE_OUT]: NotificationVisibilityStatus.HIDDEN,
    [NotificationVisibilityStatus.HIDDEN]: NotificationVisibilityStatus.ANIMATE_IN,
};

export const NOTIFICATION_STATUS_FOR_ANIMATION_NAME: Record<NotificationAnimation, NotificationVisibilityStatus> = {
    [NotificationAnimation.ANIMATE_IN]: NotificationVisibilityStatus.VISIBLE,
    [NotificationAnimation.ANIMATE_OUT]: NotificationVisibilityStatus.HIDDEN,
    [NotificationAnimation.TIMER]: NotificationVisibilityStatus.ANIMATE_OUT,
};

export const MESSAGE_NOTIFICATION_ACTIONS = [NotificationActionID.READ_MESSAGE, NotificationActionID.CANCEL];

export const initialNotificationState: UINotificationEntityTable = { isActive: false, entities: {}, stack: [] };
