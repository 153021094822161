import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ChannelUpdateReplayPermissionDocument,
    ChannelUpdateReplayPermissionMutationOptions,
    ChannelUpdateReplayPermissionMutationResult,
} from '__SMART_APP_OLD__/app/api/channel/mutation/channelUpdateReplayPermission.generated';
import {
    ChannelLoadManyDocument,
    ChannelLoadManyFragment,
    ChannelLoadManyQueryResult,
    ChannelLoadManyQueryVariables,
} from '__SMART_APP_OLD__/app/api/channel/query/channelLoadMany.generated';
import {
    ChannelLoadManyEventDocument,
    ChannelLoadManyEventFragment,
    ChannelLoadManyEventQueryResult,
    ChannelLoadManyEventQueryVariables,
} from '__SMART_APP_OLD__/app/api/channel/query/channelLoadManyEvent.generated';
import {
    ChannelLoadManyEventPreciseDocument,
    ChannelLoadManyEventPreciseFragment,
    ChannelLoadManyEventPreciseQueryResult,
    ChannelLoadManyEventPreciseQueryVariables,
} from '__SMART_APP_OLD__/app/api/channel/query/channelLoadManyEventPrecise.generated';
import {
    ChannelLoadTotalCountDocument,
    ChannelLoadTotalCountFragment,
    ChannelLoadTotalCountQueryResult,
} from '__SMART_APP_OLD__/app/api/channel/query/channelLoadTotalCount.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

/**
 * @namespace API.Channel
 */

export type LoadTotalCountResponse = { totalCount: number };

/**
 * @memberof API.Channel
 * @description Function that parses GQL Response to LoadInitialIdResponse
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @param [response] response from gql
 * @returns parsed info for total channels available
 */
export const __parseLoadTotalCount = (response?: ChannelLoadTotalCountFragment): LoadTotalCountResponse => {
    const totalCount = response?.channels?.totalCount ?? 0;
    return { totalCount };
};

/**
 * @memberof API.Channel
 * @description Loads the total count of channels available for the smart tv client
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @returns raw info for total channels available
 */
export const loadTotalCount = async (): Promise<LoadTotalCountResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelLoadTotalCountQueryResult = await GraphqlClient.makeGraphqlRequest(ChannelLoadTotalCountDocument, {}, options);
    Error.collect(response);
    return __parseLoadTotalCount(response.data);
};

export type LoadManyResponse = { channels: GQL.Channel[] };

export const __parseLoadMany = (response?: ChannelLoadManyFragment): LoadManyResponse => {
    const channels = (response?.channels?.edges ?? []).map((edge) => edge?.node).filter<GQL.Channel>((node): node is GQL.Channel => !!node);
    return { channels };
};

export const loadMany = async (variables: ChannelLoadManyQueryVariables): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(ChannelLoadManyDocument, variables, options);
    Error.collect(response);
    return __parseLoadMany(response.data);
};

export type LoadManyEventResponse = { events: GQL.Event[] };

export const __parseLoadManyEvent = (response?: ChannelLoadManyEventFragment): LoadManyEventResponse => {
    const events = (response?.channel?.events?.items ?? []).filter(Boolean) as GQL.Event[];
    return { events };
};

export const loadManyEvent = async (variables: ChannelLoadManyEventQueryVariables): Promise<LoadManyEventResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelLoadManyEventQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelLoadManyEventDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadManyEvent(response.data);
};

export type LoadManyEventPreciseResponse = { events: GQL.Event[] };

export const __parseLoadManyEventPrecise = (response?: ChannelLoadManyEventPreciseFragment): LoadManyEventPreciseResponse => {
    const events = (response?.channel?.eventsAt?.items ?? []).filter(Boolean) as GQL.Event[];
    return { events };
};

export const loadManyEventPrecise = async (variables: ChannelLoadManyEventPreciseQueryVariables): Promise<LoadManyEventPreciseResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelLoadManyEventPreciseQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelLoadManyEventPreciseDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadManyEventPrecise(response.data);
};

export const __parseUpdateChannelsReplayPermission = (response?: ChannelUpdateReplayPermissionMutationResult): boolean =>
    response?.data?.setHouseholdConfirmedReplayPermissions?.success ?? false;

export const updateChannelsReplayPermission = async (mappedChannels: { channelId: string; enabled: boolean }[]) => {
    const options = { fetchPolicy: 'no-cache' } as ChannelUpdateReplayPermissionMutationOptions;
    const variables = {
        input: {
            householdConfirmedReplayPermissions: mappedChannels,
        },
    };

    const response: ChannelUpdateReplayPermissionMutationResult = await GraphqlClient.makeGraphqlRequest(
        ChannelUpdateReplayPermissionDocument,
        variables,
        options
    );

    Error.collect(response);

    return __parseUpdateChannelsReplayPermission(response);
};
