import React from 'react';

import { Button, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';
import { Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { channelListScreenCreateActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/actions';
import { ChannelListsScreenCreateCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/ChannelListsScreenCreateCtx';
import { CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/constants';
import {
    ChannelListsScreenViewMode,
    ChannelListsCreateViewActionId,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

type Props = {
    mode: ChannelListsScreenViewMode;
    newChannelListName: string;
    channelListId?: string;
};

const actionIds = [ChannelListsCreateViewActionId.CONTINUE, ChannelListsCreateViewActionId.CANCEL];

const getActionTitle = (mode: ChannelListsScreenViewMode) => ({
    [ChannelListsCreateViewActionId.CONTINUE]: CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS[mode].success,
    [ChannelListsCreateViewActionId.CANCEL]: CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS[mode].failure,
});

export const ChannelListsScreenCreateViewActions: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();

    const { mode, newChannelListName, channelListId } = props;
    const { focused, focus, isDisabled } = Focus.useList({
        ctx: ChannelListsScreenCreateCtx.Actions,
        axis: Axis.X,
        items: actionIds,
    });

    const onEnter = (actionId: ChannelListsCreateViewActionId) => {
        dispatch(channelListScreenCreateActions.private.enter(actionId, props.mode, newChannelListName, channelListId));
    };

    return (
        <div className="manage-name-screen__button-container">
            {actionIds.map((id) => (
                <Button
                    key={id}
                    id={id}
                    ctx={ChannelListsScreenCreateCtx.Actions}
                    className="manage-name-screen__button"
                    typeface={Typeface.SANS}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === id && !isDisabled}
                    onClick={onEnter}
                    onEnter={onEnter}
                    onHover={focus}
                >
                    {getActionTitle(mode)[id]}
                </Button>
            ))}
        </div>
    );
};
