import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountDevicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountDevicesQuery = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', id: string, household: { __typename?: 'Household', devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string } | null> } }, device: { __typename?: 'Device', id: string } } | null };

export type AccountDevicesFragment = { __typename?: 'Nexx4Queries', me?: { __typename?: 'User', id: string, device: { __typename?: 'Device', id: string, registrationTime: any }, household: { __typename?: 'Household', devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string } | null> } } } | null };

export const AccountDevicesFragmentDoc = gql`
    fragment accountDevices on Nexx4Queries {
  me {
    id
    device {
      id
      registrationTime
    }
    household {
      devices {
        items {
          id
        }
      }
    }
  }
}
    `;
export const AccountDevicesDocument = gql`
    query accountDevices {
  me {
    id
    household {
      devices {
        items {
          id
        }
      }
    }
    device {
      id
    }
  }
}
    `;

/**
 * __useAccountDevicesQuery__
 *
 * To run a query within a React component, call `useAccountDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDevicesQuery(baseOptions?: Apollo.QueryHookOptions<AccountDevicesQuery, AccountDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDevicesQuery, AccountDevicesQueryVariables>(AccountDevicesDocument, options);
      }
export function useAccountDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDevicesQuery, AccountDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDevicesQuery, AccountDevicesQueryVariables>(AccountDevicesDocument, options);
        }
export function useAccountDevicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountDevicesQuery, AccountDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDevicesQuery, AccountDevicesQueryVariables>(AccountDevicesDocument, options);
        }
export type AccountDevicesQueryHookResult = ReturnType<typeof useAccountDevicesQuery>;
export type AccountDevicesLazyQueryHookResult = ReturnType<typeof useAccountDevicesLazyQuery>;
export type AccountDevicesSuspenseQueryHookResult = ReturnType<typeof useAccountDevicesSuspenseQuery>;
export type AccountDevicesQueryResult = Apollo.QueryResult<AccountDevicesQuery, AccountDevicesQueryVariables>;