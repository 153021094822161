import { Card, CardFolderModules, CardLandscapeModules, CardPortraitModules } from 'App/Packages/UI/Card';
import { CardBannerModules } from 'App/Packages/UI/Card/CardBanner/CardBanner';
import { FolderComponent, Folder as Root } from 'App/Packages/UI/Folder/Components/Folder';
import { FolderCardBanner, FolderCardBannerComponent } from 'App/Packages/UI/Folder/Components/FolderCardBanner';
import { FolderCardFolder, FolderCardFolderComponent } from 'App/Packages/UI/Folder/Components/FolderCardFolder';
import { FolderCardLandscape, FolderCardLandscapeComponent } from 'App/Packages/UI/Folder/Components/FolderCardLandscape';
import { FolderCardMore, FolderCardMoreComponent } from 'App/Packages/UI/Folder/Components/FolderCardMore';
import { FolderCardPortrait, FolderCardPortraitComponent } from 'App/Packages/UI/Folder/Components/FolderCardPortrait';
import { FolderDetailAction, FolderDetailActionComponent } from 'App/Packages/UI/Folder/Components/FolderDetailAction';
import { FolderDetailActions, FolderDetailActionsComponent } from 'App/Packages/UI/Folder/Components/FolderDetailActions';
import { FolderHeader, FolderHeaderComponent } from 'App/Packages/UI/Folder/Components/FolderHeader';
import { FolderItems, FolderItemsComponent } from 'App/Packages/UI/Folder/Components/FolderItems';
import { FolderList, FolderListComponent } from 'App/Packages/UI/Folder/Components/FolderList';
import { Constants } from 'App/Packages/UI/Folder/Constants';
import { Folder as FolderRoot } from 'App/Packages/UI/Folder/Modules/Folder';
import { FolderRootModule } from 'App/Packages/UI/Folder/Modules/Folder/Types';
import { FolderItem } from 'App/Packages/UI/Folder/Modules/FolderItem';
import { FolderItemModule } from 'App/Packages/UI/Folder/Modules/FolderItem/Types';
import { FolderItems as FolderItemsRoot } from 'App/Packages/UI/Folder/Modules/FolderItems';
import { FolderItemsModule } from 'App/Packages/UI/Folder/Modules/FolderItems/Types';
import { FolderList as FolderListRoot } from 'App/Packages/UI/Folder/Modules/FolderList';
import { FolderListModule } from 'App/Packages/UI/Folder/Modules/FolderList/Types';

export * from 'App/Packages/UI/Folder/Modules/Folder/Types';
export * from 'App/Packages/UI/Folder/Modules/FolderItem/Types';
export * from 'App/Packages/UI/Folder/Modules/FolderItems/Types';
export * from 'App/Packages/UI/Folder/Modules/FolderList/Types';

export type FolderModule = FolderComponent & {
    Constants: typeof Constants;
    Api: {
        Root: FolderRootModule;
        List: FolderListModule;
        Items: FolderItemsModule;
        Item: FolderItemModule;
    };
    Header: FolderHeaderComponent;
    List: FolderListComponent;
    Items: FolderItemsComponent;
    Detail: {
        Actions: FolderDetailActionsComponent;
        Action: FolderDetailActionComponent;
    };
    Card: {
        More: FolderCardMoreComponent;
        Folder: FolderCardFolderComponent & CardFolderModules;
        Portrait: FolderCardPortraitComponent & CardPortraitModules;
        Landscape: FolderCardLandscapeComponent & CardLandscapeModules;
        Banner: FolderCardBannerComponent & CardBannerModules;
    };
};

export const Folder: FolderModule = Object.assign(Root, {
    Constants,
    Api: {
        Root: FolderRoot,
        List: FolderListRoot,
        Items: FolderItemsRoot,
        Item: FolderItem,
    },
    Header: FolderHeader,
    List: FolderList,
    Items: FolderItems,
    Detail: {
        Actions: FolderDetailActions,
        Action: FolderDetailAction,
    },
    Card: {
        More: FolderCardMore,
        Folder: Object.assign(FolderCardFolder, {
            Constants: Card.Folder.Constants,
            Gradient: Card.Folder.Gradient,
            Title: Card.Folder.Title,
            Thumbnail: Card.Folder.Thumbnail,
        }),
        Portrait: Object.assign(FolderCardPortrait, {
            Constants: Card.Portrait.Constants,
            Main: Card.Portrait.Main,
            Content: Card.Portrait.Content,
        }),
        Landscape: Object.assign(FolderCardLandscape, {
            Constants: Card.Landscape.Constants,
            Content: Card.Landscape.Content,
            Logo: Card.Landscape.Logo,
            Selector: Card.Landscape.Selector,
            Thumbnail: Card.Landscape.Thumbnail,
        }),
        Banner: Object.assign(FolderCardBanner, {
            Constants: Card.Banner.Constants,
            Thumbnail: Card.Banner.Thumbnail,
        }),
    },
});
