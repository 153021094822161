import React from 'react';

import { MessageInboxIcon } from '__SMART_APP_OLD__/app/components/Icon/MessageInboxIcon';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

export type HeaderIconItemMessageInboxProps = {};

export type HeaderIconItemMessageInboxComponent = React.FunctionComponent<HeaderIconItemMessageInboxProps>;

export const HeaderIconItemMessageInbox: HeaderIconItemMessageInboxComponent = () => {
    const item = HeaderItem.use();
    const isFocused = Subscribable.use(item.focused);
    return <MessageInboxIcon className="header-item__icon header-item__icon--message-inbox" isFocused={isFocused} />;
};
