import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { DevicesFragmentDoc } from '../fragments/devices.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeviceRemoveMutationVariables = Types.Exact<{
  deviceRemoveInput: Types.DeleteDeviceInput;
}>;


export type DeviceRemoveMutation = { __typename?: 'Nexx4Mutations', deleteDevice: { __typename?: 'DeleteDevicePayload', household: { __typename?: 'Household', devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> } | null> } } } };

export type DeviceRemoveFragment = { __typename?: 'Nexx4Mutations', deleteDevice: { __typename?: 'DeleteDevicePayload', household: { __typename?: 'Household', devices: { __typename?: 'DeviceCatalog', items: Array<{ __typename?: 'Device', id: string, name?: string | null, deviceType: Types.DeviceType, removable: boolean, removableFrom?: any | null, deviceEnablementPolicies: Array<{ __typename?: 'DeviceEnablementPolicy', title?: string | null, enabled: boolean } | null> } | null> } } } };

export const DeviceRemoveFragmentDoc = gql`
    fragment deviceRemove on Nexx4Mutations {
  deleteDevice(input: $deviceRemoveInput) {
    household {
      ...devices
    }
  }
}
    ${DevicesFragmentDoc}`;
export const DeviceRemoveDocument = gql`
    mutation deviceRemove($deviceRemoveInput: DeleteDeviceInput!) {
  ...deviceRemove
}
    ${DeviceRemoveFragmentDoc}`;
export type DeviceRemoveMutationFn = Apollo.MutationFunction<DeviceRemoveMutation, DeviceRemoveMutationVariables>;

/**
 * __useDeviceRemoveMutation__
 *
 * To run a mutation, you first call `useDeviceRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceRemoveMutation, { data, loading, error }] = useDeviceRemoveMutation({
 *   variables: {
 *      deviceRemoveInput: // value for 'deviceRemoveInput'
 *   },
 * });
 */
export function useDeviceRemoveMutation(baseOptions?: Apollo.MutationHookOptions<DeviceRemoveMutation, DeviceRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceRemoveMutation, DeviceRemoveMutationVariables>(DeviceRemoveDocument, options);
      }
export type DeviceRemoveMutationHookResult = ReturnType<typeof useDeviceRemoveMutation>;
export type DeviceRemoveMutationResult = Apollo.MutationResult<DeviceRemoveMutation>;
export type DeviceRemoveMutationOptions = Apollo.BaseMutationOptions<DeviceRemoveMutation, DeviceRemoveMutationVariables>;