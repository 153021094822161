import { EntityTable } from '__SMART_APP_OLD__/app/types/entity';

export const toRemovedEntities = <T>(table: Record<string, T>, ids: string[]): Record<string, T> => {
    if (!ids.length) return table;
    const updated = { ...table };
    ids.forEach((id) => delete updated[id]);
    return updated;
};

export const toUpdatedEntities = <T>(table: Record<string, T>, id: string, value: T): Record<string, T> => {
    if (table[id] === value) return table;
    return { ...table, [id]: value };
};

export const toFilteredIds = (ids: string[], idsToRemove: string[]): string[] => ids.filter((id) => !idsToRemove.includes(id));

export const toIds = <T extends { id: string }>(entities: T[]): string[] => entities.map((entity) => entity.id);

export const toEntities = <T extends { id: string }>(entities: T[]): Record<string, T> => {
    if (!entities.length) return {};
    return entities.reduce<Record<string, T>>((acc, ent) => {
        acc[ent.id] = ent;
        return acc;
    }, {});
};

export const toMerged = <T>(a: Record<string, T>, b: Record<string, T>): Record<string, T> => ({ ...a, ...b });

export const toEntityTable = <T extends { id: string }>(entities: T[]): EntityTable<string, T> => ({
    ids: toIds(entities),
    entities: toEntities(entities),
});
