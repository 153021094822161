import { useCallback, useRef } from 'react';

export type UseDetailActionsEnter = (enter?: (id: string) => void) => (id: string) => void;

export const useDetailActionsEnter: UseDetailActionsEnter = (enter) => {
    const enterRef = useRef(enter);
    enterRef.current = enter;

    return useCallback((id) => {
        if (enterRef.current) enterRef.current(id);
    }, []);
};
