import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListChannelsEdgeFragmentDoc } from '../fragments/channelListChannelsEdge.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListLoadManyChannelRelationQueryVariables = Types.Exact<{
  channelListId: Types.Scalars['ID']['input'];
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ChannelListLoadManyChannelRelationQuery = { __typename?: 'Nexx4Queries', channelList?: { __typename: 'ChannelList', id: string, channels: { __typename?: 'ChannelListChannelsConnection', edges: Array<{ __typename: 'ChannelListChannelsEdge', id: string, channelListNumber: number, node: { __typename?: 'Channel', id: string } } | null> } } | null };

export type ChannelListLoadManyChannelRelationFragment = { __typename?: 'Nexx4Queries', channelList?: { __typename: 'ChannelList', id: string, channels: { __typename?: 'ChannelListChannelsConnection', edges: Array<{ __typename: 'ChannelListChannelsEdge', id: string, channelListNumber: number, node: { __typename?: 'Channel', id: string } } | null> } } | null };

export const ChannelListLoadManyChannelRelationFragmentDoc = gql`
    fragment channelListLoadManyChannelRelation on Nexx4Queries {
  channelList(id: $channelListId) {
    id
    channels(first: $first, after: $after) {
      edges {
        ...channelListChannelsEdge
      }
    }
    __typename
  }
}
    ${ChannelListChannelsEdgeFragmentDoc}`;
export const ChannelListLoadManyChannelRelationDocument = gql`
    query channelListLoadManyChannelRelation($channelListId: ID!, $first: Int!, $after: String) {
  ...channelListLoadManyChannelRelation
}
    ${ChannelListLoadManyChannelRelationFragmentDoc}`;

/**
 * __useChannelListLoadManyChannelRelationQuery__
 *
 * To run a query within a React component, call `useChannelListLoadManyChannelRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelListLoadManyChannelRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelListLoadManyChannelRelationQuery({
 *   variables: {
 *      channelListId: // value for 'channelListId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChannelListLoadManyChannelRelationQuery(baseOptions: Apollo.QueryHookOptions<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>(ChannelListLoadManyChannelRelationDocument, options);
      }
export function useChannelListLoadManyChannelRelationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>(ChannelListLoadManyChannelRelationDocument, options);
        }
export function useChannelListLoadManyChannelRelationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>(ChannelListLoadManyChannelRelationDocument, options);
        }
export type ChannelListLoadManyChannelRelationQueryHookResult = ReturnType<typeof useChannelListLoadManyChannelRelationQuery>;
export type ChannelListLoadManyChannelRelationLazyQueryHookResult = ReturnType<typeof useChannelListLoadManyChannelRelationLazyQuery>;
export type ChannelListLoadManyChannelRelationSuspenseQueryHookResult = ReturnType<typeof useChannelListLoadManyChannelRelationSuspenseQuery>;
export type ChannelListLoadManyChannelRelationQueryResult = Apollo.QueryResult<ChannelListLoadManyChannelRelationQuery, ChannelListLoadManyChannelRelationQueryVariables>;