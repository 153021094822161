import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { BookmarkFragmentDoc } from '../fragments/bookmark.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetRecordingBookmarkMutationVariables = Types.Exact<{
  input: Types.SetRecordingBookmarkInput;
  profileId: Types.Scalars['ID']['input'];
}>;


export type SetRecordingBookmarkMutation = { __typename?: 'Nexx4Mutations', setRecordingBookmark: { __typename?: 'SetRecordingBookmarkPayload', recording: { __typename?: 'NetworkRecording', personalInfo: { __typename?: 'PersonalRecordingInfo', bookmark?: { __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null } | null } } } };


export const SetRecordingBookmarkDocument = gql`
    mutation setRecordingBookmark($input: SetRecordingBookmarkInput!, $profileId: ID!) {
  setRecordingBookmark(input: $input) {
    recording {
      personalInfo(profileId: $profileId) {
        bookmark {
          ...bookmark
        }
      }
    }
  }
}
    ${BookmarkFragmentDoc}`;
export type SetRecordingBookmarkMutationFn = Apollo.MutationFunction<SetRecordingBookmarkMutation, SetRecordingBookmarkMutationVariables>;

/**
 * __useSetRecordingBookmarkMutation__
 *
 * To run a mutation, you first call `useSetRecordingBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRecordingBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRecordingBookmarkMutation, { data, loading, error }] = useSetRecordingBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useSetRecordingBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<SetRecordingBookmarkMutation, SetRecordingBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRecordingBookmarkMutation, SetRecordingBookmarkMutationVariables>(SetRecordingBookmarkDocument, options);
      }
export type SetRecordingBookmarkMutationHookResult = ReturnType<typeof useSetRecordingBookmarkMutation>;
export type SetRecordingBookmarkMutationResult = Apollo.MutationResult<SetRecordingBookmarkMutation>;
export type SetRecordingBookmarkMutationOptions = Apollo.BaseMutationOptions<SetRecordingBookmarkMutation, SetRecordingBookmarkMutationVariables>;