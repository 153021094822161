import { GQL } from '__SMART_APP_OLD__/app/gql';
import { DeviceEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/types';
import { Platform } from '__SMART_APP_OLD__/app/platform';

export const isSTBDevice = (deviceType?: GQL.DeviceType): boolean => (deviceType ? deviceType.includes('STB') : false);
export const isCurrentDevice = (targetId?: string): boolean => targetId === Platform.ID;
export const isTimeLockedDevice = (device: DeviceEntity | null, timestamp: number): boolean =>
    !device || device.removableFrom > timestamp || !device.removable;
export const isDeviceElidgableForDelete = (device: DeviceEntity, ts: number) =>
    !isSTBDevice(device.deviceType) && !isCurrentDevice(device.id) && !isTimeLockedDevice(device, ts);
