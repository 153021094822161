import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonStartOverProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonStartOverProps> = (props) => {
    return (
        <Folder.Detail.Action id="start-over" icon={IconName.START_OVER} onEnter={props.onEnter}>
            SCREEN_DETAIL_START_OVER_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonStartOver = hidable(Button);

export type DetailButtonStartOverModule = typeof DetailButtonStartOver;
