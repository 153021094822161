import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { parentalRatings } from '__SMART_APP_OLD__/app/modules/Screen/modules/ParentalControlScreen/constants';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

const selectItems = () => parentalRatings;

const selectAdultOptionLabel = (state: State) => Profile.selectors.selectMyLibraryAdultContentVisibilityLabel(state);

export const parentalControlSelectors = {
    private: {
        selectItems,
        selectAdultOptionLabel,
    },
    public: {},
};
