import { Value } from 'App/Packages/Subscribable/Core/Value';
import { Setter, Subscribe, UnSubscribe } from 'App/Packages/Subscribable/Types';

export class MockedValue<T> extends Value<T> {
    public readonly set: Setter<T> = async () => {};

    public readonly subscribe: Subscribe = () => () => {};

    public readonly unsubscribe: UnSubscribe = () => {};
}
