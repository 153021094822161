import React from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { Constants } from 'App/Packages/UI/Folder/Constants';

const Default: FolderValue = {
    id: '',
    ctx: Constants.Ctx,
    ref: { current: null },
    disabled: new Subscribable.MockedValue<boolean>(false),
    scrollIntoView: Object.assign(async () => {}, { instant: new Subscribable.MockedValue<boolean>(false) }),
};

export type FolderValue = {
    id: string;
    ref: React.MutableRefObject<HTMLDivElement | null>;
    ctx: string;
    disabled: Subscribable.Value<boolean>;
    scrollIntoView: (() => Promise<void>) & { instant: Subscribable.Value<boolean> };
};

export type FolderContextModule = React.Context<FolderValue> & { Default: FolderValue };

export const FolderContext: FolderContextModule = Object.assign(React.createContext<FolderValue>(Default), { Default });
