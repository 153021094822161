import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Actions
export type EventReduxAction = EventInitAction | EventLoadManyAction;
export type EventLoadManyActionPayload = { events: Record<GQL.ChannelID, GQL.Event[]>; start: number; end: number };
export type EventInitActionPayload = { channels: GQL.ChannelID[]; start: number; end: number };
export type EventLoadManyAction = PayloadedAction<ActionType.EVENT_LOAD_MANY, EventLoadManyActionPayload>;
export type EventInitAction = PayloadedAction<ActionType.EVENT_INIT, EventInitActionPayload>;

// State
export type EventEntityTable = {
    entities: Record<GQL.EventID, EventEntity>;
    channelEventRelationOrderTable: Record<GQL.ChannelID, GQL.EventID[]>;
};

export type EventEntity = {
    id: GQL.EventID;
    title: string;
    start: number;
    end: number;
    parentalRatingId: GQL.ParentalRatingID;
    channelId: GQL.ChannelID;
    thumbnail: string | null;
    background: string | null;
    metadata: MetadataEntity;
    entitlements: EventEntitlementsEntity;
    startOverTVBeforeTime: number | null;
    startOverTVAfterTime: number | null;
    startOverPlaybackStartPosition: number | null;
    startOverPlaybackContinuePosition: number | null;
    startOverPlaybackStopPosition: number | null;
    streamInfo: StreamInfoEntity;
    isLoading: boolean;
    isNoInfo: boolean;
};

export type EventEntitlementsEntity = {
    // liveTV: boolean;
    ppvTV: boolean;
    pauseLiveTV: boolean;
    restartTV: boolean;
    catchupTV: boolean;
    catchupTVAvailableUntil: number;
    networkRecording: boolean;
    networkRecordingPlannableUntil: number;
};

export type MetadataEntity = {
    id: GQL.MetadataID;
    title: string;
    originalTitle: string | null;
    shortDescription: string | null;
    fullDescription: string | null;
    actors: string[];
    directors: string[];
    country: string | null;
    year: number | null;
    audio: string[];
    subtitle: string[];
    genre: GenreEntity | null;
    seriesInfo: SeriesInfoEntity | null;
    episodeInfo: EpisodeInfoEntity | null;
};

export type GenreEntity = {
    id: GQL.GenreID;
    title: string;
};

export type SeriesInfoEntity = {
    id: GQL.SeriesInfoID;
    title: string | null;
};

export type EpisodeInfoEntity = {
    id: GQL.EpisodeInfoID;
    number: number | null;
    title: string | null;
    season: number | null;
};

export type StreamInfoEntity = {
    hardOfHearing: boolean;
    visuallyImpaired: boolean;
    dolby: boolean;
    multipleAudioLanguages: boolean;
    subtitles: boolean;
};

export enum PlaceHolderEventType {
    NO_INFO = 'NO_INFO',
    LOADING = 'LOADING',
}

export const enum EqualityCompare {
    START = 'START',
    END = 'END',
    BOTH = 'BOTH',
    NONE = 'NONE',
}

export type SelectEventOpts = {
    noLoading?: boolean;
    equal?: EqualityCompare;
};

// export type EventFavouritedRelationEntityTable = Record<GQL.EventID, boolean>;
// export type EventRecordingRelationTable = Record<GQL.EventID, GQL.RecordingID>;
// export type EventIsPartOfSeriesRecordingRelationEntityTable = Record<GQL.EventID, boolean>;
// export type EventReminderRelationTable = Record<GQL.EventID, GQL.ReminderID>;

// export type EventBookmarkRelationEntityTable = Record<GQL.EventID, BookmarkEntity>;

// export type BookmarkEntity = {
//     id: GQL.BookmarkID;
//     position: number;
//     audio: string;
//     subtitle: string;
// };
