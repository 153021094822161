import { EventDetailLoadQueryVariables } from './eventDetailLoad.generated';

export const DefaultVariables: EventDetailLoadQueryVariables = {
    eventId: '',
    thumbnailHeight: 280,
    backgroundWidth: 1920,
    backgroundHeight: 780,
    folderFirst: 20,
    folderListFirst: 10,
};
