import {
    RecomendationManagementFilter,
    RecomendationManagementSort,
    RecordingManagementAction,
    RecordingManagementActionID,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types/button';
import { RecordingEntity } from '__SMART_APP_OLD__/app/store/types/state/data/recording';
import translate from 'language/translate';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';

export const RECORDING_MANAGEMENT_ACTION_FOR_ACTION_ID: Record<RecordingManagementActionID, RecordingManagementAction> = {
    [RecordingManagementActionID.SORT]: { id: RecordingManagementActionID.SORT, label: 'SORT' },
    [RecordingManagementActionID.SELECT]: { id: RecordingManagementActionID.SELECT, label: 'SELECT' },
    [RecordingManagementActionID.FILTER]: { id: RecordingManagementActionID.FILTER, label: 'FILTER' },
    [RecordingManagementActionID.CLEAR_ALL]: { id: RecordingManagementActionID.CLEAR_ALL, label: 'RECORDING_MANAGE_CLEAR_ALL' },
    [RecordingManagementActionID.DELETE_ALL]: { id: RecordingManagementActionID.DELETE_ALL, label: 'DELETE_ALL' },
    [RecordingManagementActionID.DELETE]: { id: RecordingManagementActionID.DELETE, label: 'RECORDING_MANAGE_DELETE' },
};

export const RECORDING_MANAGEMENT_BASE_ACTION_IDS: RecordingManagementActionID[] = [
    RecordingManagementActionID.FILTER,
    RecordingManagementActionID.SORT,
    RecordingManagementActionID.SELECT,
];

export const RECORDING_MANAGEMENT_TRICK_MODE_BASE_ACTION_IDS: RecordingManagementActionID[] = [
    RecordingManagementActionID.DELETE_ALL,
    RecordingManagementActionID.DELETE,
];

export const RECORDING_MANAGEMENT_TRICK_MODE_FULL_ACTION_IDS: RecordingManagementActionID[] = [
    RecordingManagementActionID.CLEAR_ALL,
    RecordingManagementActionID.DELETE_ALL,
    RecordingManagementActionID.DELETE,
];

export const RECORDING_MANAGEMENT_SORTING_OPTIONS: ButtonEntity[] = [
    { id: RecomendationManagementSort.START, label: 'RECORDING_MANAGE_END_DATE' },
    { id: RecomendationManagementSort.NAME, label: 'RECORDING_MANAGE_NAME_ASC' },
    { id: RecomendationManagementSort.NAMEREV, label: 'RECORDING_MANAGE_NAME_DESC' },
    { id: RecomendationManagementSort.AVAILABLE, label: 'RECORDING_MANAGE_EXPIRATION_DATE' },
    { id: RecomendationManagementSort.SIZE, label: 'RECORDING_MANAGE_SIZE_ASC' },
    { id: RecomendationManagementSort.SIZEREV, label: 'RECORDING_MANAGE_SIZE_DESC' },
];
export const RECORDING_MANAGEMENT_FILTERING_OPTIONS = [
    { id: RecomendationManagementFilter.CLEAR_FILTER, label: 'RECORDING_MANAGE_CLEAR_FILTER' },
    { id: RecomendationManagementFilter.CONTINUE_WATCHING, label: 'RECORDING_MANAGE_CONTINUE_WATCHING' },
    { id: RecomendationManagementFilter.COMPLETED, label: 'RECORDING_MANAGE_COMPLETED_STATE' },
    { id: RecomendationManagementFilter.IN_PROGRESS, label: 'RECORDING_MANAGE_IN_PROGRESS_STATE' },
    { id: RecomendationManagementFilter.FAILED, label: 'RECORDING_MANAGE_FAILED_STATE' },
    { id: RecomendationManagementFilter.IS_SERIES_RECORDING, label: 'RECORDING_MANAGE_SERIES_RECORDING' },
    { id: RecomendationManagementFilter.IS_SINGLE_RECORDING, label: 'RECORDING_MANAGE_IS_SINGLE_RECORDING' },
    { id: RecomendationManagementFilter.PLANNED, label: 'RECORDING_MANAGE_PLANNED_STATE' },
];

export const playerMetaInfoRecording = (recording: RecordingEntity): string => {
    const metaInfo: string[] = [];
    const seasonAndEpisode = [];
    const { season, episode, episodeTitle } = recording;

    if (season) {
        seasonAndEpisode.push(`${translate('HOVER_SEASON')}${season}`);
    }

    if (episode) {
        seasonAndEpisode.push(`${translate('HOVER_EPISODE')}${episode}`);
    }

    if (episodeTitle) {
        seasonAndEpisode.push(episodeTitle);
    }

    metaInfo.push(seasonAndEpisode.join(' '));
    metaInfo.push(getTimeStampInfo(recording.start, recording.end));
    return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
};
