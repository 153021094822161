import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { KeyboardKeyElement } from 'App/Modules/Keyboard/Element/KeyboardKeyElement';
import KeyboardSuggestionElement from 'App/Modules/Keyboard/Element/KeyboardSuggestionElement';
import { KeyboardTargetElement } from 'App/Modules/Keyboard/Element/KeyboardTargetElement';
import { Calc } from 'App/Packages/Calc';
import { Function } from 'App/Packages/Function';
import { Subscribable } from 'App/Packages/Subscribable';

export type InputInit = {
    id?: string;
    value?: string;
    hidden?: boolean;
    autoFocus?: boolean;
    onChange?: (target: InputElement) => void;
    onFocus?: (target: InputElement) => void;
    onBlur?: (target: InputElement) => void;
    onComplete?: (target: InputElement) => void;
};

export class InputElement implements KeyboardTargetElement {
    public readonly id: string;

    public readonly target: KeyboardElement;

    public readonly value: Subscribable.Value<string>;

    public readonly cursor: Subscribable.Value<number>;

    public readonly focused: Subscribable.Value<boolean>;

    public readonly hidden: Subscribable.Value<boolean>;

    public readonly onChange: (target: InputElement) => void;

    public readonly onFocus: (target: InputElement) => void;

    public readonly onBlur: (target: InputElement) => void;

    public readonly onComplete: (target: InputElement) => void;

    constructor(target: KeyboardElement, init: InputInit = {}) {
        const {
            id = '',
            value = '',
            hidden = false,
            autoFocus = false,
            onChange = Function.noop,
            onComplete = Function.noop,
            onFocus = Function.noop,
            onBlur = Function.noop,
        } = init;

        this.target = target;

        this.id = id;
        this.value = new Subscribable.Value(value);
        this.cursor = new Subscribable.Value(value.length);
        this.focused = new Subscribable.Value(false);
        this.hidden = new Subscribable.Value(hidden);

        this.onChange = onChange;
        this.onFocus = onFocus;
        this.onBlur = onBlur;
        this.onComplete = onComplete;

        this.value.subscribe(() => this.onChange(this));
        this.focused.subscribe(() => (this.focused.get() ? this.onFocus(this) : this.onBlur(this)));

        if (autoFocus) this.focus();
    }

    public focus() {
        if (this.focused.get()) return;
        this.focused.set(true);
        this.target.open(this);
    }

    public blur() {
        if (!this.focused.get()) return;
        this.focused.set(false);
        this.target.close();
    }

    public clear() {
        this.value.set('');
        this.cursor.set(0);
    }

    public toggleVisibility() {
        this.hidden.set(!this.hidden.get());
    }

    public keypress(key: KeyboardKeyElement | KeyboardSuggestionElement) {
        if (key instanceof KeyboardSuggestionElement) return this.addChar(key.value);
        if (key.schema.type === 'key.enter') return this.enterKeyPressed(key);
        if (key.schema.type === 'key.backspace') return this.backspaceKeyPressed(key);
        if (key.schema.type === 'key.space') return this.spaceKeyPressed(key);
        if (key.schema.type === 'key.char') return this.charKeyPressed(key);
        if (key.schema.type === 'key.number') return this.numberKeyPressed(key);
        if (key.schema.type === 'key.symbol') return this.symbolKeyPressed(key);
        if (key.schema.type === 'key.arrow.left') return this.arrowLeftKeyPressed(key);
        if (key.schema.type === 'key.arrow.right') return this.arrowRightKeyPressed(key);
        return undefined;
    }

    private enterKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.enter') return;
        this.onComplete(this);
        this.blur();
    }

    private backspaceKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.backspace') return;
        this.removeChar();
    }

    private spaceKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.space') return;
        this.addChar(' ');
    }

    private charKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.char') return;
        const char = this.target.capslock.get() ? key.schema.value.toUpperCase() : key.schema.value.toLowerCase();
        this.addChar(char);
    }

    private numberKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.number') return;
        this.addChar(key.schema.value);
    }

    private symbolKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.symbol') return;
        this.addChar(key.schema.value);
    }

    private arrowLeftKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.arrow.left') return;
        this.moveCursor(-1);
    }

    private arrowRightKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.arrow.right') return;
        this.moveCursor(1);
    }

    private moveCursor(offset: number) {
        this.cursor.set(Calc.clamp(0, this.cursor.get() + offset, this.value.get().length));
    }

    private addChar(char: string) {
        const value = this.value.get();
        const cursor = this.cursor.get();
        this.value.set(value.slice(0, cursor) + char + value.slice(cursor));
        this.moveCursor(char.length);
    }

    private removeChar() {
        const value = this.value.get();
        const cursor = this.cursor.get();
        if (cursor === 0) return;
        this.value.set(value.slice(0, cursor - 1) + value.slice(cursor));
        this.moveCursor(-1);
    }
}
