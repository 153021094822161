import {
    SearchSuggestionLoadDocument,
    SearchSuggestionLoadFragment,
    SearchSuggestionLoadQueryResult,
} from 'App/Modules/Data/Search/Suggestion/Api/Query/searchSuggestionLoad.generated';
import {
    SearchTrendingSuggestionLoadDocument,
    SearchTrendingSuggestionLoadFragment,
} from 'App/Modules/Data/Search/Suggestion/Api/Query/searchTrendingSuggestionLoad.generated';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

const options = { fetchPolicy: 'no-cache' };

const suggestionQuery = (input: string): Promise<SearchSuggestionLoadQueryResult> =>
    GraphqlClient.makeGraphqlRequest(SearchSuggestionLoadDocument, { searchSuggestionInput: input, searchSuggestionfilter: {} }, options);

const trendingSuggestionQuery = (): Promise<SearchSuggestionLoadQueryResult> =>
    GraphqlClient.makeGraphqlRequest(SearchTrendingSuggestionLoadDocument, {}, options);

const query = (input?: string): Promise<SearchSuggestionLoadQueryResult | SearchSuggestionLoadQueryResult> =>
    input ? suggestionQuery(input) : trendingSuggestionQuery();

export const __parseLoad = (response: SearchSuggestionLoadFragment | SearchTrendingSuggestionLoadFragment | null | undefined): string[] => {
    return [...new Set(response?.suggestion?.items ?? [])]
        .filter((item): item is string => !!item)
        .filter((item) => item.split('').some((char) => char !== item.charAt(0)));
};

export const load = async (input?: string): Promise<string[]> => {
    const response = await query(input);
    Error.collect(response);
    return __parseLoad(response.data);
};
