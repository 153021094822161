import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
import { EventCollectionItemEntitlementsFragmentDoc } from './eventCollectionItemEntitlements.generated';
export type EventCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type EventCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type EventCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type EventCollectionItem_Event_Fragment = { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename?: 'EventEntitlements', pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any } | null };

export type EventCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type EventCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type EventCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type EventCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type EventCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type EventCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type EventCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type EventCollectionItemFragment = EventCollectionItem_Banner_Fragment | EventCollectionItem_Channel_Fragment | EventCollectionItem_ChannelProduct_Fragment | EventCollectionItem_Event_Fragment | EventCollectionItem_NetworkRecording_Fragment | EventCollectionItem_ProductBundle_Fragment | EventCollectionItem_Series_Fragment | EventCollectionItem_VodAsset_Fragment | EventCollectionItem_VodFolder_Fragment | EventCollectionItem_VodProduct_Fragment | EventCollectionItem_VodSeries_Fragment;

export const EventCollectionItemFragmentDoc = gql`
    fragment eventCollectionItem on ContentItem {
  ... on Event {
    id
    title
    start
    end
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    parentalRating {
      ...parentalRatingRelation
    }
    channel {
      id
    }
    entitlements {
      ...eventCollectionItemEntitlements
    }
    __typename
  }
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}
${EventCollectionItemEntitlementsFragmentDoc}`;