import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectTVGuideVissibleChannels } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { TVGuideRow } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideRow';

export const TVGuideRows: React.FunctionComponent = () => {
    const channelIds = useSelector(selectTVGuideVissibleChannels, shallowEqual);

    return (
        <>
            {channelIds.map((id) => (
                <TVGuideRow key={id} channelId={id} />
            ))}
        </>
    );
};
