import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageRemoveMutationVariables = Types.Exact<{
  messageRemoveInput: Types.DeleteMessageInput;
}>;


export type MessageRemoveMutation = { __typename?: 'Nexx4Mutations', deleteMessage: { __typename?: 'DeleteMessagePayload', success: boolean } };

export type MessageRemoveFragment = { __typename?: 'Nexx4Mutations', deleteMessage: { __typename?: 'DeleteMessagePayload', success: boolean } };

export const MessageRemoveFragmentDoc = gql`
    fragment messageRemove on Nexx4Mutations {
  deleteMessage(input: $messageRemoveInput) {
    success
  }
}
    `;
export const MessageRemoveDocument = gql`
    mutation messageRemove($messageRemoveInput: DeleteMessageInput!) {
  ...messageRemove
}
    ${MessageRemoveFragmentDoc}`;
export type MessageRemoveMutationFn = Apollo.MutationFunction<MessageRemoveMutation, MessageRemoveMutationVariables>;

/**
 * __useMessageRemoveMutation__
 *
 * To run a mutation, you first call `useMessageRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageRemoveMutation, { data, loading, error }] = useMessageRemoveMutation({
 *   variables: {
 *      messageRemoveInput: // value for 'messageRemoveInput'
 *   },
 * });
 */
export function useMessageRemoveMutation(baseOptions?: Apollo.MutationHookOptions<MessageRemoveMutation, MessageRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageRemoveMutation, MessageRemoveMutationVariables>(MessageRemoveDocument, options);
      }
export type MessageRemoveMutationHookResult = ReturnType<typeof useMessageRemoveMutation>;
export type MessageRemoveMutationResult = Apollo.MutationResult<MessageRemoveMutation>;
export type MessageRemoveMutationOptions = Apollo.BaseMutationOptions<MessageRemoveMutation, MessageRemoveMutationVariables>;