import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { VodFolderData } from 'App/Modules/VodFolderData';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Folder } from 'App/Modules/Folder';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { AssetType, PinAction } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';

const mount: Screen.LifecycleMethod<Screen.Type.VodFolders> = (props) => async (dispatch) => {
    await dispatch(Overlay.actions.noFocus.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(VodFolderData.actions.load(props.folderId));

    dispatch(Screen.actions.ctx.changed(Screen.Type.VodFolders, UI.Folder.Constants.Ctx));
    await Function.sleep(1500);
    await dispatch(Overlay.actions.unmount());
};

const unmount: Screen.LifecycleMethod<Screen.Type.VodFolders> = () => async (dispatch) => {
    dispatch(VodFolderData.actions.reset());
    await dispatch(Overlay.actions.unmount());
};

const lifecycle: Partial<Screen.Lifecycle<Screen.Type.VodFolders>> = {
    mount,
    unmount,
};

const toDetailPage =
    (
        item: Folder.Item,
        payload: {
            focusedFolderId: string;
            focusedItemId: string;
        }
    ): Thunk<Promise<void>> =>
    async (dispatch) => {
        const assetType = assetTypeForContentItemType[item.type];
        if (!assetType) return;
        const isFolder = assetType === AssetType.VOD_FOLDER;
        if (!isFolder) {
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: item.id }));
        }
        await dispatch(Screen.actions.update(Screen.Type.VodFolders, payload));
        dispatch(History.actions.push());
        dispatch(Screen.actions.unmount());
        if (isFolder) {
            dispatch(Screen.actions.mount(Screen.Type.VodFolders, { folderId: item.id }));
            return;
        }

        dispatch(
            Screen.actions.mount(Screen.Type.Detail, {
                id: item.id,
                type: item.type,
                oldOperation: () => CustomHistory.go(`/details/${assetType}/${item.id}`), // TODO: Kris - remove when all detail screens are refactored
            })
        );
    };

const selected =
    (folderId: string, itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const payload = { focusedFolderId: folderId, focusedItemId: itemId };
        if (itemId === UI.Folder.Constants.Item.Id.More) {
            await dispatch(Screen.actions.update(Screen.Type.VodFolders, payload));
            dispatch(History.actions.push());
            dispatch(Screen.actions.mount(Screen.Type.COLLECTION, { id: folderId }));
            return;
        }

        const state = getState();
        const folderSelector = VodFolderData.selectors.selectFolder(folderId);
        const itemSelector = Folder.selectors.selectItem(folderSelector)(itemId);
        const item = itemSelector(state);
        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, PinSessionType.PIN)(state);

        if (!item) return;

        if (!isRestricted) {
            dispatch(toDetailPage(item, payload));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toDetailPage(item, payload)),
            () => dispatch(Overlay.actions.unmount()),
            PinAction.ENTER,
            PinSessionType.PIN,
            item.type === ContentItemType.Vodfolder // @note Adult folder shouldn't start a session
        );
    };

export const actions = {
    public: {},
    private: {
        selected,
    },
    lifecycle,
};
