import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

export type HeaderIconItemSettingsProps = {};

export type HeaderIconItemSettingsComponent = React.FunctionComponent<HeaderIconItemSettingsProps>;

export const HeaderIconItemSettings: HeaderIconItemSettingsComponent = () => {
    const item = HeaderItem.use();
    const isFocused = Subscribable.use(item.focused);
    return <Icon className="header-item__icon header-item__icon--settings" name={IconName.COGWHEEL} isFocused={isFocused} />;
};
