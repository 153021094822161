import React, { useState } from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelListIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListsScreenViewMainSectionContent } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenViewMainSectionContent';
import { ChannelListsScreenViewOptionsSectionContent } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenViewOptionsSectionContent';
import { swappableItemsInterceptor } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/navigationInterceptors';

import { Screen } from 'App/Modules/Screen';
import { useSubscribe } from 'App/Modules/Store/Hooks/useSubscribe';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { Axis } from 'App/Types/Axis';

const VISIBLE_ITEMS = 7;

export const ChannelListsScreenView: React.FunctionComponent<Screen.Props<Screen.Type.CHANNEL_LISTS>> = (props) => {
    const { isArranging, initialFocusedId, optionSelected } = props;

    const channelListsIds = useSelector(selectChannelListIds);

    const [arrangeableChannelListsIds, setArrangeableChannelListsIds] = useState(channelListsIds);
    const initialFocusedIndex = arrangeableChannelListsIds.indexOf(initialFocusedId!);
    const [firstVisibleIndex, setFirstVisibleIndex] = useState(() =>
        Function.calculateNextFirstVisibleIndex(initialFocusedIndex, initialFocusedIndex, VISIBLE_ITEMS, arrangeableChannelListsIds.length)
    );

    const navigation = Focus.useList({
        ctx: ChannelListsScreenCtx.ChannelLists,
        axis: Axis.Y,
        items: arrangeableChannelListsIds,
        disableOutOfBounds: isArranging,
        initialFocusedIndex,
        guard: (event) => {
            if (isArranging) {
                return event.isNavigationEvent && event.x === 0;
            }

            return true;
        },
        interceptor: (event, focused) => {
            if (!isArranging) return;

            swappableItemsInterceptor(arrangeableChannelListsIds, setArrangeableChannelListsIds)(event, focused);
            const nextIndexToBeFocused = event.y === -1 ? navigation.focusedIndex - 1 : navigation.focusedIndex + 1;
            onFocus(nextIndexToBeFocused);
        },
    });

    const onFocus = (index?: number) => {
        const idx = isArranging ? index! : navigation.focusedIndex;
        setFirstVisibleIndex(
            Function.calculateNextFirstVisibleIndex(idx, firstVisibleIndex, VISIBLE_ITEMS, arrangeableChannelListsIds.length)
        );
    };

    useSubscribe(selectChannelListIds, (_, next) => setArrangeableChannelListsIds(next));

    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action />
                <Settings.Header.Title>SETTINGS_CHANNEL_LINEUP</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <ChannelListsScreenViewMainSectionContent
                    navigation={navigation}
                    arrangeableChannelListsIds={arrangeableChannelListsIds}
                    isArranging={!!isArranging}
                    firstVisibleIndex={firstVisibleIndex}
                    onFocus={onFocus}
                />
            </Settings.Section>
            <Settings.Section>
                <ChannelListsScreenViewOptionsSectionContent optionSelected={optionSelected} hoveredChannelListId={navigation.focused} />
            </Settings.Section>
        </Settings>
    );
};
