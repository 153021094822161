import { applyTheme, setTheme } from '__SMART_APP_OLD__/app/modules/Theme/integration';
import { ThemeActionType, ThemeChangedAction, ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { getThemeChangeUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

const changed = (theme: ThemeType): ThemeChangedAction => ({
    type: ThemeActionType.Changed,
    payload: { theme },
});

const applied =
    (theme: ThemeType): Thunk<void> =>
    (dispatch) => {
        applyTheme(theme);
        dispatch(changed(theme));
    };

const set =
    (theme: ThemeType): Thunk<void> =>
    (dispatch) => {
        setTheme(theme);
        dispatch(changed(theme));
        LoggingService.getInstance().logEvent(getThemeChangeUIActionEvent(theme));
    };

export const themeActions = {
    public: {
        applied,
        set,
    },
    private: {
        changed,
    },
};
