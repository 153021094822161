import { InputElement } from 'App/Modules/Input';
import { LoginWithEmailFormField } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormField';
import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import React from 'react';

export type LoginWithEmailFormPasswordFieldProps = {
    ctx: string;
    field: React.RefObject<InputElement>;
    isFocused: boolean;
    onHover: (id: LoginElement) => void;
    onComplete: (target: InputElement) => void;
};

export const LoginWithEmailFormPasswordField: React.FunctionComponent<LoginWithEmailFormPasswordFieldProps> = (props) => (
    <LoginWithEmailFormField id={LoginElement.PasswordField} hidden placeholder="SCREEN_LOGIN_PASSWORD_FIELD" {...props} />
);
