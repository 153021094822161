import React from 'react';

import { useFocusable } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Div } from 'App/Packages/UI/Div';

type Props = {
    focus: (item: string) => void;
    isFocused: boolean;
    size: string;
    onEnter: () => void;
    isActive: boolean;
};

const getClassName = (isActive: boolean, isFocused: boolean, size: string) => {
    const classList = [`font-size-${size}`];
    if (isFocused) classList.push('focused');
    if (isActive) classList.push('active');

    return classList.join(' ');
};

type SubFontSizeOptionComponent = React.FunctionComponent<Props>;

export const SubFontSizeOption: SubFontSizeOptionComponent = (props) => {
    const { focus, isFocused, size, onEnter, isActive } = props;

    const { onClick, onHover } = useFocusable({
        id: size,
        ctx: Constants.Ctx.fontSizes,
        isFocused,
        onHover: focus,
        onFocus: focus,
        onClick: onEnter,
        onEnter,
    });

    return (
        <Div onHover={onHover} key={size} onClick={onClick} className="font-size-option">
            <span className={getClassName(isActive, isFocused, size)}>Aa</span>
        </Div>
    );
};
