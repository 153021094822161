import { Env } from 'App/Env';
import { AuthSessionState, AuthSessionStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/types';
import { ErrorCode } from '__SMART_APP_OLD__/app/modules/Error/types';

export const initialAuthSessionState: AuthSessionState = {
    status: AuthSessionStatus.NOT_LOGGED_IN,
    logAction: false,
    isLoginWithCode: false,
    legacyCallbacksInterval: 810000,
};

export const isErrorTenBulgaria = (error: string): boolean => {
    if (!error) return false;
    if (!Env.IsBulgaria) return false;
    return (
        error === ErrorCode.ERROR_NOT_SAME_HOUSHOLD ||
        error === ErrorCode.DEVICE_NOT_LINKED_TO_SAME_HOUSEHOLD ||
        error === ErrorCode.USER_NOT_LINKED_TO_CURRENT_HOUSEHOLD
    );
};

export const deviceNotExistingInHousehold = (error: string): boolean => {
    if (!error) return false;
    return error === ErrorCode.DEVICE_NOT_FOUND;
};
