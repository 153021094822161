import { Calc } from 'App/Packages/Calc';
import React from 'react';

const Default = {
    min: 150,
    ratio: 0.75,
    max: 750,
};

export type Options = ({ left: number; top?: undefined } | { left?: undefined; top: number }) & { duration?: DurationOptions };

export type DurationOptions = { min?: number; ratio?: number; max?: number };

type ScrollProp = 'scrollLeft' | 'scrollTop';

type State = {
    version: number;
    start: number;
    end: number;
    duration: number;
    distance: number;
    scrollProp: ScrollProp;
    scrollStart: number;
    scrollEnd: number;
};
export class Scroll {
    public readonly ref: React.MutableRefObject<HTMLDivElement | null>;

    private version: number;

    private now: number;

    public constructor(ref: React.MutableRefObject<HTMLDivElement | null>) {
        this.ref = ref;
        this.version = 0;
        this.now = Date.now();
    }

    public top(): number {
        return 0;
    }

    public bottom(): number {
        const target = this.ref.current;
        if (!target) return 0;
        return target.scrollHeight;
    }

    public left(): number {
        const target = this.ref.current;
        if (!target) return 0;
        return target.scrollWidth;
    }

    public right(): number {
        return 0;
    }

    public to(options: Options): void {
        if (!this.ref.current) return;
        const state = this.state(options);
        if (!state) return;
        if (state.distance === 0) return;
        this.activate(state.version);
        this.bootstrap(state);
    }

    public by(options: Options): void {
        if (!this.ref.current) return;
        if (options.left) {
            const left = this.ref.current.scrollLeft + options.left;
            this.to({ duration: options.duration, left });
            return;
        }
        if (!options.top) return;
        const top = this.ref.current.scrollTop + options.top;
        this.to({ duration: options.duration, top });
    }

    private activate(version: number): void {
        this.version = version;
    }

    private bootstrap(state: State): void {
        window.requestAnimationFrame(() => this.tick(state));
    }

    private tick(state: State): void {
        this.now = Date.now();
        if (this.frame(state)) return;
        window.requestAnimationFrame(() => this.tick(state));
    }

    // ----------------------------
    // Render Current Frame
    // ----------------------------
    private frame(state: State): boolean {
        if (!this.ref.current) return true;
        if (this.version !== state.version) return true;
        const current = this.ref.current[state.scrollProp];
        const progress = this.progress(state.start, state.end);
        const value = Math.round(state.scrollStart + state.distance * progress);
        this.ref.current[state.scrollProp] = value;
        if (progress === 1) return true;
        return this.ref.current[state.scrollProp] !== value && this.ref.current[state.scrollProp] === current;
    }

    private progress(start: number, end: number): number {
        if (this.now <= start) return 0;
        if (this.now >= end) return 1;
        return (this.now - start) / (end - start);
    }

    // ----------------------------
    //  State
    // ----------------------------
    private state(options: Options): State | null {
        if (!this.ref.current) return null;
        const isLeft = options.left !== undefined;
        const scrollProp: ScrollProp = isLeft ? 'scrollLeft' : 'scrollTop';
        const version = this.version + 1;
        const scrollStart = this.ref.current[scrollProp];
        const scrollEnd = isLeft ? options.left : options.top;
        const distance = scrollEnd - scrollStart;
        const duration = this.duration(distance, options.duration);
        const start = Date.now();
        const end = start + duration;

        return { version, start, end, duration, distance, scrollProp, scrollStart, scrollEnd };
    }

    private duration(distance: number, options: DurationOptions | undefined): number {
        if (distance === 0) return 0;
        const min = options?.min ?? Default.min;
        const ratio = options?.ratio ?? Default.ratio;
        const max = options?.max ?? Default.max;
        return Calc.clamp(min, Math.round(Math.abs(distance) * ratio), max);
    }
}
