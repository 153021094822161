import React, { useState } from 'react';

import LazyLoadingAnimate from '__SMART_APP_OLD__/components/common/LazyLoadingAnimate';
import { ReactComponent as PlaceholderLogo } from 'styles/assets/logos/Xplore_TV_Placeholder_Logo.svg';

interface Props {
    src?: string;
    alt: string;
    className?: string;
    objectFit?: 'cover' | 'contain';
    mask?: boolean;
    forceLoading?: boolean;
    forceCover?: boolean;
}

enum Modes {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
}

export const CoverImageWithFallback: React.FunctionComponent<Props> = (props) => {
    const { src, alt, mask, className = '', objectFit = 'cover', forceLoading, forceCover } = props;
    const [mode, setMode] = useState<Modes>(forceCover ? Modes.ERROR : Modes.LOADING);

    const loading = mode === Modes.LOADING;
    const success = mode === Modes.SUCCESS ? 'visible' : 'hidden';
    const error = mode === Modes.ERROR ? 'visible' : 'hidden';

    const modeSetHandlerGenerator = (selected: Modes) => () => {
        if (forceLoading) return;
        setMode(selected);
    };

    if (mask) {
        return (
            <div className={`image-container ${objectFit} ${className}`}>
                <div className="cover-adult-content" />
            </div>
        );
    }

    return (
        <div className={`image-container ${objectFit} ${className}`}>
            {mode === Modes.LOADING && <LazyLoadingAnimate className={`cover-animation ${loading}`} />}
            <div className={`cover-placeholder ${error}`}>
                <PlaceholderLogo />
            </div>
            {!forceLoading && !forceCover && (
                <img
                    className={`${success} ${className}`}
                    src={src || ''}
                    alt={alt}
                    onLoad={modeSetHandlerGenerator(Modes.SUCCESS)}
                    onError={modeSetHandlerGenerator(Modes.ERROR)}
                />
            )}
        </div>
    );
};
