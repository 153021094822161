import React, { useRef, useEffect } from 'react';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import translate from 'language/translate';
import Button from '__SMART_APP_OLD__/components/common/Button';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { Key, NumberKeys } from 'App/Modules/Key';

interface Props {
    nextAsset: any;
    timeLeft: number;
    onEnterWatchNext: () => void;
    onEnterCancel: () => void;
    onClose: () => void;
}

export const BingeWatchDialog: React.FunctionComponent<Props> = (props) => {
    const btnRef = useRef<Container | null>(null);

    useEffect(() => {
        if (btnRef.current?.nodeRef) {
            Focus.focus(btnRef.current.nodeRef as unknown as null);
        }
    }, []);

    const containerKeyPressHandler = (key: number): boolean => {
        if (NumberKeys.includes(key)) return true;
        switch (key) {
            case Key.VK_BACK:
                props.onClose();
                return true;
            case Key.VK_UP:
            case Key.VK_DOWN:
                return true;
            default:
                return false;
        }
    };

    const generateTitle = () => {
        const { metadata } = props.nextAsset;
        let title = null;

        if (!metadata.episodeInfo || !metadata.episodeInfo.title) title = metadata.title;
        else if (!metadata.episodeInfo.number || !metadata.episodeInfo.season) title = metadata.episodeInfo.title;
        else {
            const seasonText = translate('HOVER_SEASON');
            const episodeText = translate('HOVER_EPISODE');
            title = `${seasonText}${metadata.episodeInfo.season} ${episodeText}${metadata.episodeInfo.number} ${metadata.episodeInfo.title}`;
        }

        return title;
    };

    return (
        <div className="binge-watch-dialog-overlay">
            <div className="binge-watch-dialog">
                <Text
                    className="binge-watch-dialog__title"
                    typeface={Typeface.SERIF}
                    size={FontSize.TITLE_2}
                    weight={FontWeight.BOLD}
                    color={Color.CUSTOM}
                    customColor="#FAFAFA"
                >
                    {generateTitle()}
                </Text>
                <Text
                    className="binge-watch-dialog__subtitle"
                    typeface={Typeface.SANS}
                    size={FontSize.TITLE_3}
                    weight={FontWeight.REGULAR}
                    color={Color.CUSTOM}
                    customColor="#F2F2F299"
                >
                    {`${translate('MODULE_BINGE_WATCHING_NEXT')} ${props.timeLeft} ${props.timeLeft > 1 ? translate('MODULE_BINGE_WATCHING_SECONDS') : translate('MODULE_BINGE_WATCHING_SECOND')}`}
                </Text>
                <Container className="binge-watch-dialog-actions" onKey={containerKeyPressHandler}>
                    <Button
                        className="binge-watch-dialog-actions__action binge-watch-dialog-actions__action--with-offset"
                        onEnter={props.onEnterCancel}
                        label="MODULE_BINGE_WATCHING_CANCEL_BUTTON"
                    />
                    <Button
                        ref={(ref: any) => {
                            if (ref) {
                                btnRef.current = ref?.nodeRef?.nodeRef || null;
                            }
                        }}
                        className="binge-watch-dialog-actions__action"
                        onEnter={props.onEnterWatchNext}
                        label="MODULE_BINGE_WATCHING_NEXT_BUTTON"
                    />
                </Container>
            </div>
        </div>
    );
};
