import React from 'react';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectEventEnd,
    selectEventIsLoading,
    selectEventIsNoInfo,
    selectEventStart,
    selectEventTitle,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { getTimeStampInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import { Elements } from '__SMART_APP_OLD__/components/EPG/types';

import { ContentMarkerType } from 'App/Types/ContentMarker';

interface Props {
    className?: string;
    eventId: GQL.EventID;
    channelId: string;
    contentMarker?: ContentMarkerType;
}

export const PlaceHolderProgramCard: React.FunctionComponent<Props> = (props) => {
    const title = useSelector(selectEventTitle(props.eventId));
    const isLoading = useSelector(selectEventIsLoading(props.eventId));
    const isNoInfo = useSelector(selectEventIsNoInfo(props.eventId));
    const start = useSelector(selectEventStart(props.eventId));
    const end = useSelector(selectEventEnd(props.eventId));
    const metaInfo = getTimeStampInfo(start, end);

    return (
        <div
            className={`program-card ${props.className || ''}`}
            data-element={Elements.EVENT}
            data-channel-id={props.channelId}
            data-event-id={props.eventId}
            data-event-restricted={false}
            data-event-date={start}
            data-event-is-live={false}
            data-event-is-now-playing={false}
        >
            <div className="program-cover-area">
                <div className="program-cover">
                    <CoverImageWithFallback alt={title} forceLoading={isLoading} forceCover={isNoInfo} />
                    <div className="cover-overlay">
                        <ContentMarker marker={props.contentMarker} className="program" />
                    </div>
                </div>
            </div>
            <div className="program-metadata">
                <Text
                    className="program-title"
                    typeface={Typeface.SERIF}
                    size={FontSize.BODY_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {title}
                </Text>
                <Text typeface={Typeface.SANS} size={FontSize.CAPTION_2} weight={FontWeight.REGULAR} color={Color.SECONDARY}>
                    <InnerHTML className="program-meta-info" html={metaInfo} />
                </Text>
            </div>
        </div>
    );
};
