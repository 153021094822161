import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { SettingsHeader, SettingsHeaderModule } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsHeader';
import { SettingsSection, SettingsSectionModule } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSection';
import {
    SettingsSectionContentItem,
    SettingsSectionContentItemModule,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItem';
import {
    SettingsSectionContentSeparator,
    SettingsSectionContentSeparatorType,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentSeparator';

export type SettingsProps = {};

export type SettingsModule = {
    Header: SettingsHeaderModule;
    Section: SettingsSectionModule;
    Item: SettingsSectionContentItemModule;
    Separator: SettingsSectionContentSeparatorType;
} & React.FunctionComponent<React.PropsWithChildren<SettingsProps>>;

export const Settings: SettingsModule = (props) => (
    <Layout className="settings" backgroundColor={BackgroundColor.PRIMARY}>
        {props.children}
    </Layout>
);

Settings.Header = SettingsHeader;
Settings.Section = SettingsSection;
Settings.Item = SettingsSectionContentItem;
Settings.Separator = SettingsSectionContentSeparator;
