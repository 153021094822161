import React from 'react';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

type DetailButtonRecordProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonRecordProps> = (props) => {
    return (
        <Folder.Detail.Action id="record" icon={IconName.RECORD} onEnter={props.onEnter}>
            SCREEN_DETAIL_RECORD_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonRecord = hidable(Button);

export type DetailButtonRecordModule = typeof DetailButtonRecord;
