import { AuthSessionStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

export const selectAuthSessionStatus = (state: State): AuthSessionStatus => state.data.authSession.status;

export const selectIsLoggedIn = (state: State): boolean => state.data.authSession.status === AuthSessionStatus.LOGGED_IN;
export const selectIsLoginWithCode = (state: State): boolean => state.data.authSession.isLoginWithCode;
export const selectLogAuthAction = (state: State): boolean => state.data.authSession.logAction;

export const selectLegacyCallbacksInterval = (state: State): number => state.data.authSession.legacyCallbacksInterval;
