import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { useCardPortrait } from 'App/Packages/UI/Card/CardPortrait/Hooks/useCardPortrait';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (isLarge: boolean, className?: string): string => {
    const classList = ['portrait-card-v3__description'];
    if (isLarge) classList.push('portrait-card-v3__description--large');
    if (className) classList.push(className);
    return classList.join(' ');
};

export type CardPortraitDescriptionProps = React.PropsWithChildren<{ className?: string }>;

export type CardPortraitDescriptionComponent = React.FunctionComponent<CardPortraitDescriptionProps>;

export const CardPortraitDescription: CardPortraitDescriptionComponent = (props) => {
    const card = useCardPortrait();
    return (
        <Text
            className={getClassName(card.isLarge, props.className)}
            typeface={Typeface.SANS}
            size={card.isLarge ? FontSize.CAPTION_1 : FontSize.CAPTION_2}
            weight={FontWeight.REGULAR}
            color={Color.PRIMARY}
        >
            <Mask.Description>{props.children}</Mask.Description>
        </Text>
    );
};
