import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
export type SeriesCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type SeriesCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type SeriesCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type SeriesCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type SeriesCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type SeriesCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type SeriesCollectionItem_Series_Fragment = { __typename: 'Series', id: string, title: string, episodeCount: number, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, channel?: { __typename?: 'Channel', id: string } | null, seriesParentalRating: { __typename?: 'ParentalRating', id: string } };

export type SeriesCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type SeriesCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type SeriesCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type SeriesCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type SeriesCollectionItemFragment = SeriesCollectionItem_Banner_Fragment | SeriesCollectionItem_Channel_Fragment | SeriesCollectionItem_ChannelProduct_Fragment | SeriesCollectionItem_Event_Fragment | SeriesCollectionItem_NetworkRecording_Fragment | SeriesCollectionItem_ProductBundle_Fragment | SeriesCollectionItem_Series_Fragment | SeriesCollectionItem_VodAsset_Fragment | SeriesCollectionItem_VodFolder_Fragment | SeriesCollectionItem_VodProduct_Fragment | SeriesCollectionItem_VodSeries_Fragment;

export const SeriesCollectionItemFragmentDoc = gql`
    fragment seriesCollectionItem on ContentItem {
  ... on Series {
    id
    title
    episodeCount
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    channel {
      id
    }
    seriesParentalRating: parentalRating {
      ...parentalRatingRelation
    }
    __typename
  }
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;