import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { LoginBackground } from '__SMART_APP_OLD__/app/components/LoginBackground';

import { Screen } from 'App/Modules/Screen';
import { actions } from 'App/Modules/Screen/Modules/LoginWithEmail/Store/actions';
import { LoginWithEmailContainer } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailContainer';
import { LoginWithEmailFormContainer } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormContainer';
import { LoginWithEmailFormWithNavigation } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormWithNavigation';
import { LoginWithEmailGuide } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailGuide';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIdpProviderActive } from '__SMART_APP_OLD__/app/modules/Config/selectors';

export const LoginWithEmail: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isIdpActive = useSelector(selectIdpProviderActive);
    Focus.useBack(() => dispatch(actions.private.onBack()), { disabled: !isIdpActive });
    return (
        <Layout className="login-with-email" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <LoginBackground />
            <LoginWithEmailContainer>
                <UI.Logo.XploreTV className="login-with-email__logo" />
                <LoginWithEmailFormContainer>
                    <LoginWithEmailFormWithNavigation />
                    <LoginWithEmailGuide />
                </LoginWithEmailFormContainer>
            </LoginWithEmailContainer>
        </Layout>
    );
};
