import throttle from 'lodash.throttle';
import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import RecordingIcon from '__SMART_APP_OLD__/app/components/Icon/RecordingIcon';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelLiveTVEntitlement } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectEventChannelID, selectEventTitle } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { selectIsReminderSetForEvent } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import {
    TV_GUIDE_SHOW_ELIPSIS_LIMIT,
    TV_GUIDE_SHOW_ICONS_LIMIT,
    TV_GUIDE_SHOW_TITLE_LIMIT,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/constants';
import { selectTVGuideEventVWWidth, selectTVGuideIsFocused } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';

import { Env } from 'App/Env';

import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import {
    selectEventHasRecording,
    selectRecordingStatusForEvent,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { tvGuideClickTriggered, tvGuideHoverTriggered } from './actions';

interface Props {
    eventId: GQL.EventID;
}

const getClassName = (isFocused: boolean, width: number, subscribed: boolean): string => {
    const classList = ['tv-guide__event-cell'];
    if (isFocused) classList.push('tv-guide__event-cell--focused');
    if (width < TV_GUIDE_SHOW_TITLE_LIMIT) classList.push('tv-guide__event-cell--centered');
    if (!subscribed) classList.push('tv-guide__event-cell--unsubscribed');
    return classList.join(' ');
};

const getStyle = (width: number): React.CSSProperties => ({
    width: `${width}vw`,
    maxWidth: `${width}vw`,
    flexBasis: `${width}vw`,
});

const getText = (width: number, title: string): string => {
    if (width < TV_GUIDE_SHOW_ELIPSIS_LIMIT) return '';
    if (width < TV_GUIDE_SHOW_TITLE_LIMIT) return '...';
    return title;
};

const handleHover = throttle((eventId: string) => {
    if (!Env.IsMouseSupported) return;
    store.dispatch(tvGuideHoverTriggered(eventId));
}, 250);

export const TVGuideEventCell: React.FunctionComponent<Props> = (props) => {
    const { eventId } = props;
    const isFocused = useSelector(selectTVGuideIsFocused(eventId), shallowEqual);
    const width = useSelector(selectTVGuideEventVWWidth(eventId), shallowEqual);
    const hasRecording = useSelector(selectEventHasRecording(eventId));
    const isReminderSet = useSelector(selectIsReminderSetForEvent(eventId));
    const channelId = useSelector(selectEventChannelID(eventId));
    const subscribed = useSelector(selectChannelLiveTVEntitlement(channelId));
    const status = useSelector(selectRecordingStatusForEvent(eventId));

    const header = useSelector(selectEventTitle(eventId, PinSessionType.PIN_LIVE_TV), shallowEqual);
    const style = useMemo(() => getStyle(width), [width]);
    const background = isFocused ? BackgroundColor.BUTTON_HOT_RED : BackgroundColor.BUTTON_SECONDARY;
    const shadow = isFocused ? Shadow.BUTTON_SELECTED : Shadow.BUTTON;
    const className = getClassName(isFocused, width, subscribed);
    const text = getText(width, header);
    const shouldShowIcons = width > TV_GUIDE_SHOW_ICONS_LIMIT;
    const dispatch = useDispatch();

    return (
        <Div
            className={className}
            backgroundColor={background}
            shadow={shadow}
            style={style}
            onClick={() => dispatch(tvGuideClickTriggered(isFocused))}
            onMouseOver={() => handleHover(eventId)}
        >
            <Text
                className="tv-guide__cell-text"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={isFocused ? FontWeight.BOLD : FontWeight.REGULAR}
                color={Color.PRIMARY}
                isFocused={isFocused}
            >
                {text}
            </Text>
            {shouldShowIcons && hasRecording && !!status && (
                <RecordingIcon className="tv-guide__cell-icon" status={status} isFocused={isFocused} />
            )}
            {shouldShowIcons && isReminderSet && <Icon className="tv-guide__cell-icon" name={IconName.REMINDER} isFocused={isFocused} />}
        </Div>
    );
};
