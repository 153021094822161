import { DetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions';
import { useCallback, useRef } from 'react';

export type UseDetailActionEnter = (id: string, onEnter?: (id: string) => void) => () => void;

export const useDetailActionEnter: UseDetailActionEnter = (id, onEnter) => {
    const actions = DetailActions.use();
    const onEnterRef = useRef(onEnter);
    onEnterRef.current = onEnter;

    return useCallback(() => {
        if (onEnterRef.current) return onEnterRef.current(id);
        return actions.enter(id);
    }, [id, actions]);
};
