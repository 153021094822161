import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum PinSessionType {
    PIN = 'PIN',
    PIN_LIVE_TV = 'PIN_LIVE_TV',
}

// Actions
export type PinReduxAction =
    | PinActiveStatusChangedAction
    | PinBlockedChangedAction
    | PinActiveStatusLiveTvChangedAction
    | PinForceProtectionChangedAction;

export type PinActiveStatusChangedActionPayload = { isActive: boolean };
export type PinActiveStatusLiveTvChangedActionPayload = { isLiveTvActive: boolean };
export type PinBlockedChangedActionPayload = { time: number };
export type PinIsForceProtectionChangedActionPayload = { forceProtection: boolean };

export type PinActiveStatusChangedAction = PayloadedAction<ActionType.PIN_ACTIVE_STATUS_CHANGED, PinActiveStatusChangedActionPayload>;
export type PinActiveStatusLiveTvChangedAction = PayloadedAction<
    ActionType.PIN_ACTIVE_STATUS_LIVE_TV_CHANGED,
    PinActiveStatusLiveTvChangedActionPayload
>;
export type PinBlockedChangedAction = PayloadedAction<ActionType.PIN_BLOCKED_CHANGED, PinBlockedChangedActionPayload>;
export type PinForceProtectionChangedAction = PayloadedAction<
    ActionType.PIN_FORCE_PROTECTION_CHANGED,
    PinIsForceProtectionChangedActionPayload
>;

// State
export type PinState = {
    isActive: boolean;
    isLiveTvActive: boolean;
    blockedUntil: number;
    forceProtection: boolean;
};
