import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PurchaseUpsellProductMutationVariables = Types.Exact<{
  input: Types.PurchaseUpsellProductInput;
}>;


export type PurchaseUpsellProductMutation = { __typename?: 'Nexx4Mutations', purchaseUpsellProduct: { __typename?: 'PurchaseUpsellProductPayload', product: { __typename?: 'ChannelProduct' } | { __typename?: 'PPVProduct' } | { __typename?: 'ProductBundle' } | { __typename?: 'VODProduct', title: string, kind: Types.ProductKind, price?: { __typename?: 'Price', id: string, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, availableUntil?: any | null } | null } } };

export type PurchaseUpsellProductFragment = { __typename?: 'Nexx4Mutations', purchaseUpsellProduct: { __typename?: 'PurchaseUpsellProductPayload', product: { __typename?: 'ChannelProduct' } | { __typename?: 'PPVProduct' } | { __typename?: 'ProductBundle' } | { __typename?: 'VODProduct', title: string, kind: Types.ProductKind, price?: { __typename?: 'Price', id: string, amount: number, currency: string } | null, entitlement?: { __typename?: 'ProductEntitlement', id: string, availableUntil?: any | null } | null } } };

export const PurchaseUpsellProductFragmentDoc = gql`
    fragment purchaseUpsellProduct on Nexx4Mutations {
  purchaseUpsellProduct(input: $input) {
    product {
      ... on VODProduct {
        title
        kind
        price {
          id
          amount
          currency
        }
        entitlement {
          id
          availableUntil
        }
      }
    }
  }
}
    `;
export const PurchaseUpsellProductDocument = gql`
    mutation purchaseUpsellProduct($input: PurchaseUpsellProductInput!) {
  ...purchaseUpsellProduct
}
    ${PurchaseUpsellProductFragmentDoc}`;
export type PurchaseUpsellProductMutationFn = Apollo.MutationFunction<PurchaseUpsellProductMutation, PurchaseUpsellProductMutationVariables>;

/**
 * __usePurchaseUpsellProductMutation__
 *
 * To run a mutation, you first call `usePurchaseUpsellProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseUpsellProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseUpsellProductMutation, { data, loading, error }] = usePurchaseUpsellProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseUpsellProductMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseUpsellProductMutation, PurchaseUpsellProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseUpsellProductMutation, PurchaseUpsellProductMutationVariables>(PurchaseUpsellProductDocument, options);
      }
export type PurchaseUpsellProductMutationHookResult = ReturnType<typeof usePurchaseUpsellProductMutation>;
export type PurchaseUpsellProductMutationResult = Apollo.MutationResult<PurchaseUpsellProductMutation>;
export type PurchaseUpsellProductMutationOptions = Apollo.BaseMutationOptions<PurchaseUpsellProductMutation, PurchaseUpsellProductMutationVariables>;