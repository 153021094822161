import { useContext } from 'react';

import {
    SuggestionListContext,
    SuggestionListValue,
} from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Context/SuggestionListContext';

export type UseSuggestionList = () => SuggestionListValue;

export const useSuggestionList: UseSuggestionList = () => useContext(SuggestionListContext) ?? SuggestionListContext.Default;
