import React, { useMemo } from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { SettingsSectionContentItemChannelLogo } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelLogo';
import { SettingsSectionContentItemChannelName } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelName';
import {
    SettingsSectionContentItemChannelNumber,
    SettingsSectionContentItemChannelNumberModule,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelNumber';
import {
    SettingsSectionContentItemContext,
    SettingsSectionContentItemContextProvider,
    SettingsSectionContentItemContextProviderProps,
    SettingsSectionContentItemContextState,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import {
    SettingsSectionContentItemIcon,
    SettingsSectionContentItemIconModule,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemIcon';
import { SettingsSectionContentItemSpacer } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemSpacer';
import { SettingsSectionContentItemTitle } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemTitle';
import { SettingsSectionContentItemValue } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemValue';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Function } from 'App/Packages/Function';

export type SettingsSectionContentItemProps<T extends string = string> = {
    id: T;
    ctx: string;
    // When true, element is rendered with opacity 0
    isHidden?: boolean;
    // Optimize for performance
    // When true, only the element wrapper is rendered
    isRemoved?: boolean;
    isSecondary?: boolean;
    isFocused?: boolean;
    isActive?: boolean;
    isDisabled?: boolean;
    // Optimize for performance
    // Used in screens such as Manage Channels screen
    // where the height of the item is fixed to 80px (default height)
    disableDynamicMeasure?: boolean;
    children?: React.ReactNode;
    onEnter?: (id: T) => void;
    onHover?: (id: T) => void;
    onFocus?: (id: T) => void;
};

export type SettingsSectionContentItemModule = {
    Title: React.FunctionComponent<React.PropsWithChildren>;
    Icon: SettingsSectionContentItemIconModule;
    Value: React.FunctionComponent<React.PropsWithChildren>;
    Spacer: React.FunctionComponent;
    ChannelLogo: React.FunctionComponent;
    ChannelName: React.FunctionComponent;
    ChannelNumber: SettingsSectionContentItemChannelNumberModule;
    Context: React.Context<SettingsSectionContentItemContextState>;
    Provider: React.FunctionComponent<SettingsSectionContentItemContextProviderProps>;
} & (<T extends string = string>(
    props: SettingsSectionContentItemProps<T>
) => ReturnType<React.FunctionComponent<SettingsSectionContentItemProps<T>>>);

const getSettingsItemClassName = (isActive: boolean) => {
    const classList = ['settings-section-content-item'];
    if (isActive) classList.push('settings-section-content-item--active');
    return classList.join(' ');
};

const getClassName = (isFocused: boolean) => {
    const classlist = ['settings-section-content-item__focus'];
    if (isFocused) classlist.push('settings-section-content-item__focus--focused');
    return classlist.join(' ');
};

const getBackgroundColor = (isFocused: boolean, isSecondary: boolean) => (isSecondary || isFocused ? undefined : BackgroundColor.SECONDARY);

export const SettingsSectionContentItem: SettingsSectionContentItemModule = (props) => {
    const value = useMemo<SettingsSectionContentItemContextState>(
        () => ({
            id: props.id,
            isSecondary: !!props.isSecondary,
            isFocused: !!props.isFocused,
            isActive: !!props.isActive,
            isHidden: !!props.isHidden,
            isRemoved: !!props.isRemoved,
            isDisabled: !!props.isDisabled,
        }),
        [props.id, props.isSecondary, props.isFocused, props.isActive, props.isHidden, props.isRemoved, props.isDisabled]
    );

    const { onClick, onHover } = useFocusableHook({
        ctx: props.ctx,
        id: props.id,
        isFocused: value.isFocused,
        disabled: value.isDisabled,
        onEnter: props.onEnter ?? Function.noop,
        onHover: props.onHover ?? Function.noop,
        onFocus: props.onFocus,
    });

    return (
        <SettingsSectionContentItemContextProvider value={value}>
            <Div
                className={getSettingsItemClassName(value.isActive)}
                backgroundColor={getBackgroundColor(value.isFocused, value.isSecondary)}
                shadow={Shadow.BUTTON}
                onClick={onClick}
                onMouseOver={onHover}
            >
                {props.isHidden ? null : props.children}
                <Div
                    className={getClassName(value.isFocused)}
                    backgroundColor={BackgroundColor.BUTTON_HOT_RED}
                    shadow={Shadow.BUTTON_SELECTED}
                />
            </Div>
        </SettingsSectionContentItemContextProvider>
    );
};

SettingsSectionContentItem.Title = SettingsSectionContentItemTitle;
SettingsSectionContentItem.Icon = SettingsSectionContentItemIcon;
SettingsSectionContentItem.Value = SettingsSectionContentItemValue;
SettingsSectionContentItem.Spacer = SettingsSectionContentItemSpacer;
SettingsSectionContentItem.ChannelLogo = SettingsSectionContentItemChannelLogo;
SettingsSectionContentItem.ChannelName = SettingsSectionContentItemChannelName;
SettingsSectionContentItem.ChannelNumber = SettingsSectionContentItemChannelNumber;
SettingsSectionContentItem.Context = SettingsSectionContentItemContext;
SettingsSectionContentItem.Provider = SettingsSectionContentItemContextProvider;
