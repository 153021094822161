import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { CollectionItemFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/collectionItem.generated';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import { CollectionLoadDocument, CollectionLoadQueryResult, CollectionLoadQueryVariables } from './query/collectionLoad.generated';

export type LoadVariables = {
    id: string;
    thumbnailHeight: number;
};

export type Collection = {
    id: string;
    title: string;
    kind: GQL.ContentFolderKind;
    items: CollectionItemFragment[];
};

const defaultCollection: Collection = {
    id: '',
    title: '',
    kind: GQL.ContentFolderKind.Recommendation,
    items: [],
};

const getCollection = () => ({ ...defaultCollection });

const getItems = (response: CollectionLoadQueryResult): CollectionItemFragment[] => {
    if (!response) return [];
    if (!response.data?.contentFolder) return [];
    if (!response.data?.contentFolder.items?.edges) return [];
    return response.data.contentFolder.items.edges.map((edge) => edge?.node).filter(Boolean) as CollectionItemFragment[];
};

const recursiveLoad = async (cursor: string | null = null, vars: LoadVariables, collection: Collection): Promise<Collection> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: CollectionLoadQueryVariables = { ...vars, cursor, items: 99 };
    const response: CollectionLoadQueryResult = await GraphqlClient.makeGraphqlRequest(CollectionLoadDocument, variables, options);
    Error.collect(response);
    collection.id = response.data?.contentFolder?.id || '';
    collection.title = response.data?.contentFolder?.title || '';
    collection.kind = response.data?.contentFolder?.kind || GQL.ContentFolderKind.Recommendation;
    collection.items = [...collection.items, ...getItems(response)];

    if (!response?.data?.contentFolder?.items?.pageInfo?.hasNextPage) return collection;
    const next = response?.data?.contentFolder?.items?.pageInfo?.endCursor;
    if (!next) return collection;
    return recursiveLoad(next, vars, collection);
};

export const load = async (vars: LoadVariables) => recursiveLoad(null, vars, getCollection());
