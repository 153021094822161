import Player from '__SMART_APP_OLD__/platforms/Player';
import { Layer } from 'analytics/logging/classes/Layer';
import { PlaybackEvent, PlaybackEvents } from 'analytics/logging/events/PlaybackEvent';

export interface ActiveVideoTrack {
    id: string;
    width: number;
    height: number;
    bandwidth: number;
}
export const getPlaybackEvent = (
    event: PlaybackEvents,
    videoServerSessionId: string = Player.videoServerSessionId ?? '',
    playerCurrentTime: number = Player.getPlayedTime(),
    activeVideoTrack: ActiveVideoTrack = Player.getCurrentVideoTrack(),
    isLive: boolean = Player.isLive()
): PlaybackEvent | null => {
    let delay: number = 0;
    let position: number;
    if (isLive) {
        position = playerCurrentTime * 1000; // Converting to milliseconds
        const now = new Date().getTime();
        delay = position === 0 ? 0 : Math.round(now - position);
    } else {
        position = Math.round(playerCurrentTime * 1000);
    }
    let layer: Layer | undefined;
    if (event === PlaybackEvents.PLAY) {
        if (!activeVideoTrack) return null;
        const { id, width: hRes, height: vRes, bandwidth: bitrate } = activeVideoTrack || {};
        layer = new Layer({ id, hRes, vRes, bitrate });
    }
    return new PlaybackEvent(event, delay, position, layer, videoServerSessionId);
};
