import React, { useMemo } from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { TV_GUIDE_VW_PER_SECOND } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/constants';
import { selectTVGuideLiveLineTimeOffset } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';

const getStyle = (offset: number): React.CSSProperties => ({
    transform: `translateZ(0) translateX(${TV_GUIDE_VW_PER_SECOND * offset}vw)`,
});

export const TVGuideLiveLine: React.FunctionComponent = () => {
    const timeOffset = useSelector(selectTVGuideLiveLineTimeOffset);
    const style = useMemo(() => getStyle(timeOffset), [timeOffset]);
    if (timeOffset === -1) return null;

    return <Div className="tv-guide__live-line" style={style} backgroundColor={BackgroundColor.COOL_BLUE_PRIMARY} />;
};
