import { combineReducers } from 'redux';

import { Event } from 'App/Modules/Data/Detail/Event';
import { Series } from 'App/Modules/Data/Detail/Series';
import { VodAsset } from 'App/Modules/Data/Detail/VodAsset';
import { Root } from 'App/Modules/Data/Detail/Root';
import { VodSeries } from 'App/Modules/Data/Detail/VodSeries';
import { Recording } from 'App/Modules/Data/Detail/NetworkRecording';

export { Root, Series, Event, VodAsset, VodSeries, Recording };
export { initialDetailReducerState as initialState } from 'App/Modules/Data/Detail/Root/Store/initialState';

export type State = {
    event: Event.State;
    series: Series.State;
    vodAsset: VodAsset.State;
    vodSeries: VodSeries.State;
    recording: Recording.State;
};
export type ReduxAction = Event.ReduxAction | VodAsset.ReduxAction | Series.ReduxAction | VodSeries.ReduxAction | Recording.ReduxAction;
export type { DetailItem as Item } from 'App/Modules/Data/Detail/Root/Types/Item';
export const reducer = combineReducers({
    event: Event.reducer,
    series: Series.reducer,
    vodAsset: VodAsset.reducer,
    recording: Recording.reducer,
    vodSeries: VodSeries.reducer,
});
export const Api = {
    event: Event.Api,
    series: Series.Api,
    vodAsset: VodAsset.Api,
    vodSeries: VodSeries.Api,
    recording: Recording.Api,
};
