import { initialTranslationState } from '__SMART_APP_OLD__/app/modules/Translation/constants';
import { TranslationActionType, TranslationEntityTable } from '__SMART_APP_OLD__/app/modules/Translation/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const translationReducer: Reducer<TranslationEntityTable> = (state, action) => {
    if (!state) return initialTranslationState;

    switch (action.type) {
        case TranslationActionType.LOADED:
            return { ...state, [action.payload.code]: action.payload.data };
        default:
            return state;
    }
};
