import { GQL } from '__SMART_APP_OLD__/app/gql';
import { loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectSelectedChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { selectEventBy } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import {
    EqualityCompare,
    EventEntity,
    MetadataEntity,
    SelectEventOpts,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { selectParentalRatingIsAdult } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { AssetType, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import { formatDateTime, formatDayName } from '__SMART_APP_OLD__/utils/timeUtils';
import translate from 'language/translate';

import { ContentMarkerType } from 'App/Types/ContentMarker';

export const compareTimeForEqualityCompare: Record<EqualityCompare, (evt: EventEntity, time: number) => boolean> = {
    [EqualityCompare.BOTH]: (evt, time) => evt.start <= time && evt.end >= time,
    [EqualityCompare.START]: (evt, time) => evt.start <= time && evt.end > time,
    [EqualityCompare.END]: (evt, time) => evt.start < time && evt.end >= time,
    [EqualityCompare.NONE]: (evt, time) => evt.start < time && evt.end > time,
};

export const isEventInRange = (evt: EventEntity | null, time: number, equal: EqualityCompare, noLoading: boolean): boolean =>
    !!evt && (!noLoading || !evt.isLoading) && compareTimeForEqualityCompare[equal](evt, time);

export const getEventForChannel = async (channelId: GQL.ChannelID, time: number, opts?: SelectEventOpts): Promise<EventEntity | null> => {
    const event = selectEventBy(channelId, time, opts)(store.getState());
    if (!event) return null;
    if (!event.isLoading) return event;
    await store.dispatch(loadEventsForChannel(channelId, time));
    return selectEventBy(channelId, time, opts)(store.getState());
};

export const getEventProgramType = (start: number, end: number, time: number) => {
    if (time < start) return ProgramType.FUTURE;
    if (time > end) return ProgramType.CATCHUP;
    if (time >= start && time <= end) return ProgramType.LIVE;
    return ProgramType.VOD;
};

export const getTimeStampInfo = (start: number, end: number, format: string = 'DD dd MMM') => {
    const info = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const date = formatDayName(startDate, format, '');
    const hours = `${formatDateTime(startDate, 'HH:mm')} - ${formatDateTime(endDate, 'HH:mm')}`;
    info.push(`${date}`);
    info.push(`${hours}`);
    return info.join('\xa0\xa0I\xa0\xa0');
};

export const getEventSeasonAndEpisode = (event: EventEntity): string => {
    const seasonAndEpisode = [];
    const season = event.metadata?.episodeInfo?.season;
    const episode = event.metadata?.episodeInfo?.number;
    if (season) seasonAndEpisode.push(`${translate('HOVER_SEASON')}${season}`);
    if (episode) seasonAndEpisode.push(`${translate('HOVER_EPISODE')}${episode}`);
    return seasonAndEpisode.join(' ');
};

export const getEventMetaInfo = (event: EventEntity): string => {
    const metaInfo = [];
    const year = event.metadata?.year;
    const genre = event.metadata?.genre?.title;
    metaInfo.push(getTimeStampInfo(event.start, event.end));
    if (year) metaInfo.push(year);
    if (genre) metaInfo.push(genre);
    return metaInfo.join('\xa0\xa0I\xa0\xa0');
};

export const isCatchupTV = (event: EventEntity | null, time: number = Date.now()): boolean => {
    if (!event) return false;
    return event.entitlements?.catchupTV && event.entitlements.catchupTVAvailableUntil > time && event.start < time;
};

export const getEventPreviewMetaInfo = (event: EventEntity | null): string => {
    if (!event) return '';
    const metaInfo = [];
    const year = event.metadata?.year;
    const genre = event.metadata?.genre?.title;
    metaInfo.push(getTimeStampInfo(event.start, event.end));
    if (year) metaInfo.push(year);
    if (genre) metaInfo.push(genre);
    return metaInfo.join('\xa0\xa0I\xa0\xa0');
};

export const getEventFullDescription = (event: EventEntity | null): string => {
    if (!event) return '';
    const seasonAndEpisode = getEventSeasonAndEpisode(event);
    if (seasonAndEpisode) return `${seasonAndEpisode} ${event?.metadata?.fullDescription}`;
    return event?.metadata?.fullDescription ?? event?.metadata?.shortDescription ?? '';
};

export const getEventDurationInSeconds = (start: number, end: number): number => (end - start) / Time.SECOND_MS;

export type LivePlayerData = {
    id: GQL.EventID;
    title: string;
    titlePlayer: string;
    subTitle: string;
    startDateTime: number;
    endDateTime: number;
    start: number;
    end: number;
    duration: number;
    assetType: AssetType.EVENT;
    programType: string;
    metaInfoPlayer: string;
    availableContentMarkers: ContentMarkerType[];
    // channel,
    metadata: MetadataEntity;
    isAdult: boolean;
    isRecordable: boolean;
    isNoInfo: boolean;
    isLoading: boolean;
    recordings: any[]; // TODO change
    leadIn: number;
    channelId: GQL.ChannelID;
    channelLogo: Record<Theme.Type, string | null>;
    channelName: string;
    channelNumber: number;
    liveTV: boolean;
    restartTV: boolean;
    parentalRating: string;
};

export const getDataForLivePlayer = async (channelId: GQL.ChannelID): Promise<LivePlayerData | null> => {
    const state = store.getState();
    const time = Date.now();
    const channel = selectChannel(channelId)(state);
    if (!channel) return null;
    const channelNumber = selectSelectedChannelListChannelNumber(channel.id)(state);
    const event = await getEventForChannel(channel?.id, time, { equal: EqualityCompare.START });
    if (!event) return null;
    const isAdult = selectParentalRatingIsAdult(event.parentalRatingId)(state);
    const isRecordable = Utils.isEventRecordable(
        event.entitlements.networkRecording,
        event.start,
        event.end,
        event.entitlements.networkRecordingPlannableUntil
    );
    return {
        id: event.id,
        title: event.title,
        titlePlayer: event.title,
        subTitle: `${event.metadata?.episodeInfo?.season} ${event.metadata?.episodeInfo?.title}`,
        startDateTime: event.start,
        endDateTime: event.end,
        start: event.start,
        end: event.end,
        duration: event.end - event.start,
        assetType: AssetType.EVENT,
        programType: getEventProgramType(event.start, event.end, time),
        metaInfoPlayer: getEventMetaInfo(event),
        availableContentMarkers: [ContentMarkerType.Live],
        // channel,
        metadata: event.metadata,
        isAdult,
        isRecordable,
        isNoInfo: event.isNoInfo,
        isLoading: event.isLoading,
        recordings: [], // TODO change
        leadIn: event.startOverTVBeforeTime ?? 0,
        channelId: channel.id,
        channelLogo: channel.logo,
        channelName: channel.title,
        channelNumber,
        liveTV: channel.liveTV,
        restartTV: channel.restartTV,
        parentalRating: event.parentalRatingId,
    };
};
