import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentFragmentDoc, ParentalRatingFragmentFragmentDoc, TrailersFragmentFragmentDoc, VodAssetEntitlementCatalogFragmentFragmentDoc, VodAssetEntitlementFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc, CacheInfoFragmentFragmentDoc, PageInfoFragmentFragmentDoc, ChannelLogosFragmentFragmentDoc, PersonalChannelInfoFragmentFragmentDoc, ChannelEntitlementsFragmentFragmentDoc, EventEntitlementsFragmentFragmentDoc, PersonalEventInfoFragmentFragmentDoc, MetadataFragmentFragmentDoc, PersonalRecordingInfoFragmentFragmentDoc, GenreFragmentFragmentDoc, VodProductFrogmentFragmentDoc, PersonalVodInfoFragmentFragmentDoc, PriceFragmentFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/api/graphql/query/fragments.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
import { MetadataFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/metadata.generated';
import { ImageFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
export type VodAssetDetailItemFragment = { __typename: 'VODAsset', id: string, title: string, duration: number, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, trailers: { __typename: 'TrailerCatalog', id: string, itemCount: number, items: Array<{ __typename: 'Trailer', id: string, expiry: any, title: string } | null> }, parentalRating: { __typename?: 'ParentalRating', id: string }, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, vodAssetEntitlements: { __typename: 'VODAssetEntitlementCatalog', id: string, expiry: any, itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', videoQuality?: Types.VideoQuality | null } | null } | null> }, personalInfo?: { __typename: 'PersonalVODInfo', favourited: boolean } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export const VodAssetDetailItemFragmentDoc = gql`
    fragment vodAssetDetailItem on VODAsset {
  id
  title
  duration
  backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
    ...imageFragment
  }
  trailers {
    ...trailersFragment
  }
  parentalRating {
    ...parentalRatingRelation
  }
  metadata {
    ...metadata
  }
  vodAssetEntitlements: entitlements {
    ...VODAssetEntitlementCatalogFragment
    items {
      ...VODAssetEntitlementFragment
    }
  }
  personalInfo(profileId: $profileId) {
    favourited
    __typename
  }
  trailers {
    ...trailersFragment
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  __typename
}
    ${ImageFragmentFragmentDoc}
${TrailersFragmentFragmentDoc}
${ParentalRatingRelationFragmentDoc}
${MetadataFragmentDoc}
${VodAssetEntitlementCatalogFragmentFragmentDoc}
${VodAssetEntitlementFragmentFragmentDoc}
${ImageFragmentDoc}`;