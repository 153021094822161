import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Label } from '__SMART_APP_OLD__/app/components/Label';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectMessageDetailDate,
    selectMessageDetailExpiration,
    selectMessageDetailLabel,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

export const MessageDetailInfo: React.FunctionComponent = () => {
    const date = useSelector(selectMessageDetailDate);
    const label = useSelector(selectMessageDetailLabel);
    const expiration = useSelector(selectMessageDetailExpiration);
    return (
        <div className="message-detail__info">
            <Text
                className="message-detail__date"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.BOLD}
                color={Color.SECONDARY}
            >
                {date}
            </Text>
            {!!label && (
                <Label className="message-detail__label" color={BackgroundColor.COOL_BLUE_PRIMARY}>
                    {label}
                </Label>
            )}
            <Text
                className="message-detail__expiration"
                typeface={Typeface.SANS}
                size={FontSize.CAPTION_1}
                weight={FontWeight.BOLD}
                color={Color.SECONDARY}
            >
                {expiration}
            </Text>
        </div>
    );
};
