import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionValue } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Context/SuggestionContext';
import { useEffect, useRef } from 'react';

export type UseSuggestionListFocusInitial = (store: Subscribable.Record<SuggestionValue>, initial: string | undefined) => void;

export const useSuggestionListFocusInitial: UseSuggestionListFocusInitial = (store, initial) => {
    const initialized = useRef(false);

    const item = Subscribable.useItem(store, initial);

    useEffect(() => {
        if (initialized.current) return;
        if (!item) return;
        item.scrollIntoView.instant.set(true);
        item.focus();
        initialized.current = true;
    }, [item]);
};
