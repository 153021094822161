import { initialThemeState } from '__SMART_APP_OLD__/app/modules/Theme/constants';
import { ThemeActionType, ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const themeReducer: Reducer<ThemeType> = (state, action): ThemeType => {
    if (!state) return initialThemeState;
    switch (action.type) {
        case ThemeActionType.Changed:
            return action.payload.theme;
        default:
            return state;
    }
};
