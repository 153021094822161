import React from 'react';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';

import { ContentMarkerType } from 'App/Types/ContentMarker';

const getClassName = (props: CardLandscapeMarkerProps) => {
    const classlist = ['landscape-card-v3__marker'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeMarkerProps = {
    marker?: ContentMarkerType | null;
    label?: string;
    className?: string;
};

export type CardLandscapeMarkerComponent = React.FunctionComponent<CardLandscapeMarkerProps>;

export const CardLandscapeMarker: CardLandscapeMarkerComponent = (props) => (
    <ContentMarker className={getClassName(props)} label={props.label} marker={props.marker} />
);
