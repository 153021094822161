import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type EventCollectionItemEntitlementsFragment = { __typename?: 'EventEntitlements', pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any };

export const EventCollectionItemEntitlementsFragmentDoc = gql`
    fragment eventCollectionItemEntitlements on EventEntitlements {
  pauseLiveTV
  restartTV
  catchupTV
  catchupTVAvailableUntil
}
    `;