import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { channelListChange } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Translation } from 'App/Modules/Translation';
import { Function } from 'App/Packages/Function';

const selected =
    (id: string): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        await dispatch(
            Overlay.actions.mount(Overlay.Type.Loading, {
                backgroundColor: BackgroundColor.PRIMARY,
                text: Translation.Key.Overlay.Loading.ChannelList.Text,
            })
        );
        const success = await dispatch(channelListChange(id));

        await Function.sleep(250);
        await dispatch(Overlay.actions.unmount());

        return success;
    };

export const Actions = {
    private: {
        selected,
    },
};
