import throttle from 'lodash.throttle';
import React, { useEffect, useRef } from 'react';

import { Engine } from 'App/Packages/Focus/Engine';
import { NavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';
import { useIsActive } from 'App/Packages/Focus/Hooks/useIsActive';
import { NavigationEventHandler, NavigationOptions } from './Types';

const navigationSubscribeGenerator =
    <T extends NavigationEvent>(engine: Engine<T>) =>
    (onNavigate: React.MutableRefObject<NavigationEventHandler<T>>, disabled: boolean, delay: number) => {
        const isActive = useIsActive();
        const isDisabled = disabled || !isActive;

        useEffect(() => {
            if (isDisabled) return undefined;
            return engine.subscribe(throttle((event: T) => onNavigate.current(event), delay));
        }, [delay, isDisabled, onNavigate]);

        return isDisabled;
    };

export const navigationHookGenerator = <T extends NavigationEvent>(engine: Engine<T>) => {
    const useSubscribe = navigationSubscribeGenerator<T>(engine);
    const useNavigation = (onNavigate: NavigationEventHandler<T>, options: NavigationOptions = {}): boolean => {
        const onNavigateRef = useRef(onNavigate);
        onNavigateRef.current = onNavigate;
        return useSubscribe(onNavigateRef, options.disabled ?? false, options.throttle ?? 0);
    };

    return useNavigation;
};
