import React from 'react';

import {
    SuggestionListContext,
    SuggestionListContextModule,
} from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Context/SuggestionListContext';
import { UseSuggestionList, useSuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Hooks/useSuggestionList';
import {
    UseSuggestionListValue,
    useSuggestionListValue,
} from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Hooks/useSuggestionListValue';

export type SuggestionListProps = React.PropsWithChildren<{
    ctx: string;
    initial?: string;
    disabled?: boolean;
    offset?: number;
    onEnter?: (value: string) => void;
}>;

export type SuggestionListModule = React.FunctionComponent<SuggestionListProps> & {
    // Context
    Context: SuggestionListContextModule;

    // Hooks
    use: UseSuggestionList;
    useValue: UseSuggestionListValue;
};

export const SuggestionList: SuggestionListModule = (props) => {
    const list = SuggestionList.useValue({
        ctx: props.ctx,
        offset: props.offset,
        initial: props.initial,
        enter: props.onEnter,
    });
    return <SuggestionList.Context.Provider value={list}>{props.children}</SuggestionList.Context.Provider>;
};

// Context
SuggestionList.Context = SuggestionListContext;

// Hooks
SuggestionList.use = useSuggestionList;
SuggestionList.useValue = useSuggestionListValue;
