export enum MenuItemType {
    Home = 'HOME',
    MyLibrary = 'LIBRARY',
    LiveTV = 'LIVE_TV',
    VideoStore = 'VIDEOSTORE',
    NowOnTV = 'NOW_ON_TV',
    TVGuide = 'EPG',
    Playground = 'PLAYGROUND',
}

export enum MenuIconItemType {
    ChannelLists = 'ChannelLists',
    MessageInbox = 'MessageInbox',
    Search = 'Search',
    Settings = 'Settings',
}
