import isEmpty from 'lodash.isempty';

import { selectAvailableAudioLanguages, selectAvailableSubtitleLanguages } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectIsLiveTvPinActive, selectPinIsActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { AssetType, ProgramType } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

/**
 * @namespace Utils
 */

/**
 * @description A function that calculates the vod progress
 * @param duration duration
 * @param lastPlayPosition lastPlayPosition
 * @returns progress
 * @memberof Utils
 */
export const calculateVodProgress = (duration, lastPlayPosition) =>
    duration !== undefined && lastPlayPosition !== undefined ? Math.min((lastPlayPosition * 100) / duration) : null;

export const calculateLinearProgress = (duration, startTime, timeshift = 0) => {
    const timeToSeek = timeshift && timeshift < Date.now() ? timeshift : Date.now();
    let calculatedProgress = 0;
    if (duration !== undefined && startTime !== undefined) {
        calculatedProgress = Math.min(((timeToSeek - startTime) * 100) / duration, 100);
        calculatedProgress = Math.max(calculatedProgress, 0);
    }
    return calculatedProgress;
};
/**
 * @description A function that restricts/masks the asset based on parental rating
 * @param asset asset
 * @param parentalRating parentalRating
 * @param sessionType sessionType
 * @memberof Utils
 */
export const maskAsset = (asset, parentalRating, sessionType) => {
    if (!asset || parentalRating === null || parentalRating === undefined) {
        return;
    }
    const pinSessionActive =
        sessionType === PinSessionType.PIN ? selectPinIsActive(store.getState()) : selectIsLiveTvPinActive(store.getState());

    const restrictAdult = asset?.isAdult && asset?.ageRatingRank >= parentalRating && !pinSessionActive;
    const restrictNormal = !asset?.isAdult && asset?.ageRatingRank > parentalRating && !pinSessionActive;
    asset.shouldRestrict = restrictAdult || restrictNormal;
    asset.shouldMask = asset.isAdult && !pinSessionActive;
};

/**
 * @description A function that parses the backendLanguage id
 * @returns Array of language-objects containing the backend language ID
 * @memberof Utils
 */
export const parseBackendLanguageId = () =>
    Language.selectors.selectCodes(store.getState()).map((code) => ({
        code,
        title: Language.selectors.selectTitle(code)(store.getState()),
    }));

/**
 *@description A function that returns a mapping with audio languages
 *@returns ListMappingRecord[] Mapping
 *@memberof Utils
 */
export const audioLanguageListMapping = () => {
    const audioLanguageList = selectAvailableAudioLanguages(store.getState());
    return Object.keys(audioLanguageList).map((langCode) => ({
        title: audioLanguageList[langCode].translationKey,
        iso_code: langCode,
        backendCode: audioLanguageList[langCode].languageCode_639_2T,
    }));
};

/**
 *@description A function that returns a mapping with the subtitles languages
 * @returns ListMappingRecord[] Mapping
 * @memberof Utils
 */
export const subtitleLanguageListMapping = () => {
    const subtitleLanguageList = selectAvailableSubtitleLanguages(store.getState());
    return Object.keys(subtitleLanguageList).map((langCode) => ({
        title: subtitleLanguageList[langCode].translationKey,
        iso_code: langCode,
        backendCode: subtitleLanguageList[langCode].languageCode_639_2T,
    }));
};

/**
 * @description A function that check if the asset is a catchup asset
 * @param assetData assetData
 * @returns {boolean} is the asset a catchup asset
 * @memberof Utils
 */
export const isCatchupAsset = (assetData) => assetData.assetType === AssetType.EVENT && assetData.programType === ProgramType.CATCHUP;

/**
 * @description A function that checks if the asset is a recording
 * @param assetData assetData
 * @returns {boolean} is the asset a recording
 * @memberof Utils
 */
export const isRecordingAsset = (assetData) => assetData.assetType === AssetType.NETWORK_RECORDING;

/**
 * @description A function that checks if the asset is an episode
 * @param assetData assetData
 * @returns {boolean} is the asset an episode
 * @memberof Utils
 */
export const isVodEpisodeAsset = (assetData) => assetData.assetType === AssetType.EPISODE;

/**
 * @description A function that returns the video quality of an asset
 * @param data data
 * @returns the video quality
 * @memberof Utils
 */
export const getVideoQuality = (data) => {
    const videoQ = {};
    data.upsellProducts?.items?.forEach((product) => {
        if (product?.__typename !== 'VODProduct' || !product.videoQuality) return;
        videoQ[product.videoQuality] = product.videoQuality;
    });
    return videoQ;
};

/**
 *@description A function that returns the recording quota data
 * @param data data
 * @returns recording quota data
 * @memberof Utils
 */
export const getRecordingQuotaData = (data) => {
    if (isEmpty(data)) {
        return { calculated: false };
    }
    const allowed = data?.allowed ?? 0;
    const used = data?.used ?? 0;
    const storageDifference = allowed - used;
    const percentage = (used / allowed) * 100;
    const hours = Math.floor(Math.abs(storageDifference / 3600));
    const minutes = Math.floor(Math.abs((storageDifference % 3600) / 60));
    const calculatedMinutes = minutes === 60 ? '00' : minutes;
    const calculatedPercentage = percentage === 0 ? 1 : percentage;
    const calculatedHour = hours < 1 ? 0 : hours;
    const text = `${translate('RECORDINGS_STORAGE_TITLE')} ${calculatedHour}${translate(
        'SCREEN_DETAIL_TIME_HOURS'
    )} ${calculatedMinutes} ${translate('SCREEN_PLAYER_SKIP_MINUTES')}`;
    return { text, percentage: calculatedPercentage, calculated: true };
};
