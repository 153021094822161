export interface LayerParams {
    id: string;
    hRes: number;
    vRes: number;
    bitrate: number;
}

/**
 * @class Layer
 */
export class Layer {
    private readonly id: string;

    private readonly hRes: number;

    private readonly vRes: number;

    private readonly bitrate: number;

    /**
     *
     * @param root0
     * @param root0.id
     * @param root0.hRes
     * @param root0.vRes
     * @param root0.bitrate
     */
    constructor({ id, hRes, vRes, bitrate }: LayerParams) {
        // Required props
        this.id = id;
        this.hRes = hRes;
        this.vRes = vRes;
        this.bitrate = bitrate;
    }
}
