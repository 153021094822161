import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { ScreenActionType, ScreenState } from 'App/Modules/Screen/Types';

export const reducer: Reducer<ScreenState> = (state, action) => {
    if (state === undefined) return null;

    switch (action.type) {
        case ScreenActionType.CHANGED: {
            if (!action.payload.screen) return null;
            return action.payload.screen;
        }
        default:
            return state;
    }
};
