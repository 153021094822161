import { Data } from 'App/Modules/Data';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';
import { Folder } from 'App/Modules/Folder';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Constants } from 'App/Modules/Screen/Modules/Search/Constants';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    channelNotInActiveChannelListNotificationShow,
    channelNotSubscribedNotificationShow,
    textNotificationShow,
} from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { AssetType, Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

const mount: Screen.LifecycleMethod<Screen.Type.Search> = (props) => async (dispatch) => {
    await dispatch(Overlay.actions.noFocus.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await Promise.all([
        dispatch(Data.Search.Suggestion.actions.load(props.query)),
        dispatch(Data.Search.Result.actions.load({ query: props.query })),
    ]);
    if (!props.focusedFolderId && !props.focusedItemId) {
        await dispatch(Overlay.actions.unmount());
        dispatch(Screen.actions.ctx.changed(Screen.Type.Search, Constants.Ctx.Field));
        return;
    }
    dispatch(Screen.actions.ctx.changed(Screen.Type.Search, Constants.Ctx.Results));
    await Function.sleep(2000);
    await dispatch(Overlay.actions.unmount());
};

const unmount: Screen.LifecycleMethod<Screen.Type.Search> = () => async (dispatch) => {
    dispatch(Data.Search.Suggestion.actions.clear());
    dispatch(Data.Search.Result.actions.clear());
    await dispatch(Overlay.actions.unmount());
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch, getState) => {
    const results = Data.Search.Result.selectors.select(getState());

    const hasResults = results.ids.length > 0;

    if (ctx === Constants.Ctx.Field && event.y === 1) {
        if (hasResults) return dispatch(Screen.actions.ctx.changed(Screen.Type.Search, Constants.Ctx.Results));
        return undefined;
    }

    if (event.y === 1 && hasResults) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.Search, Constants.Ctx.Results));
    }

    if (ctx === Constants.Ctx.Results && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.Search, Constants.Ctx.Field));
    }

    return undefined;
};

const toDetailPage =
    (item: Folder.Item, props: Screen.Props<Screen.Type.Search>): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        if (item.type === ContentItemType.Networkrecording && selectRecordingStatus(item.id)(state) === GQL.RecordingStatus.Failed) {
            dispatch(textNotificationShow('NOTIFICATION_FAILED_RECORDING_NO_DETAIL_PAGE'));
            return;
        }
        const assetType = assetTypeForContentItemType[item.type];
        if (!assetType) return;
        const isFolder = assetType === AssetType.VOD_FOLDER;
        const path = isFolder ? `/page/${Route.VOD_FOLDER}/${item.id}` : `/details/${assetType}/${item.id}`;
        if (!isFolder) {
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: item.id }));
        }
        await dispatch(Screen.actions.update(Screen.Type.Search, props));
        dispatch(History.actions.push());
        dispatch(Screen.actions.unmount());

        if (isFolder) {
            CustomHistory.go(path);
            return;
        }

        dispatch(
            Screen.actions.mount(Screen.Type.Detail, {
                id: item.id,
                type: item.type,
                oldOperation: () => CustomHistory.go(path),
            })
        );
    };

const handleChannelsEnter =
    (item: Folder.Item, props: Screen.Props<Screen.Type.Search>): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const channelListId = selectSelectedChannelListId(getState());
        const channelIds = selectChannelListChannelIds(channelListId)(getState());
        const isChannelInChannelList = channelIds.find((channelId) => channelId === item?.id);
        const assetType = assetTypeForContentItemType[item.type];

        await dispatch(Screen.actions.update(Screen.Type.Search, props));
        dispatch(History.actions.push());
        dispatch(Screen.actions.unmount());

        if (item?.content.userInfo?.subscribed) {
            if (isChannelInChannelList) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.PLAY, { assetType, id: item.id }));
                CustomHistory.go(`/playChannel/${item.id}`);
            } else {
                dispatch(
                    channelNotInActiveChannelListNotificationShow(
                        'NOT_IN_ACTIVE_CHANNEL_LIST',
                        NotificationIconType.INFO,
                        item.content.title
                    )
                );
            }
        } else {
            dispatch(
                channelNotSubscribedNotificationShow('UNSUBSCRIBED_PLAYER_INFO_TEXT', NotificationIconType.INFO, item?.content.title ?? '')
            );
        }
    };

const selected =
    (folderId: string, itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const { props = {} } = Screen.selectors.selectTyped(Screen.Type.Search)(getState()) ?? {};
        const payload: Screen.Props<Screen.Type.Search> = { ...props, focusedFolderId: folderId, focusedItemId: itemId };
        if (itemId === UI.Folder.Constants.Item.Id.More) {
            await dispatch(Screen.actions.update(Screen.Type.Search, payload));
            dispatch(History.actions.push());
            dispatch(Screen.actions.mount(Screen.Type.COLLECTION, { id: folderId }));
            return;
        }

        const folderSelector = Data.Search.Result.selectors.selectFolder(folderId);
        const itemSelector = Folder.selectors.selectItem(folderSelector)(itemId);
        const item = itemSelector(getState());

        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, PinSessionType.PIN)(getState());

        if (!item) return;

        if (item?.type === 'CHANNEL') {
            dispatch(handleChannelsEnter(item, payload));
            return;
        }

        if (!isRestricted) {
            dispatch(toDetailPage(item, payload));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toDetailPage(item, payload)),
            () => dispatch(Overlay.actions.unmount())
        );
    };

export const actions = {
    public: {},
    private: {
        selected,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
