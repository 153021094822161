import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { BannerFolderItemFragmentDoc } from '../../../../../../../../../App/Modules/Folder/Api/fragments/folderItem/banner/bannerFolderItem.generated';
export type BannerCollectionItem_Banner_Fragment = { __typename: 'Banner', id: string, link: string, thumbnailAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, backgroundImageAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean } };

export type BannerCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type BannerCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type BannerCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type BannerCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type BannerCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type BannerCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type BannerCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type BannerCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type BannerCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type BannerCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type BannerCollectionItemFragment = BannerCollectionItem_Banner_Fragment | BannerCollectionItem_Channel_Fragment | BannerCollectionItem_ChannelProduct_Fragment | BannerCollectionItem_Event_Fragment | BannerCollectionItem_NetworkRecording_Fragment | BannerCollectionItem_ProductBundle_Fragment | BannerCollectionItem_Series_Fragment | BannerCollectionItem_VodAsset_Fragment | BannerCollectionItem_VodFolder_Fragment | BannerCollectionItem_VodProduct_Fragment | BannerCollectionItem_VodSeries_Fragment;

export const BannerCollectionItemFragmentDoc = gql`
    fragment bannerCollectionItem on ContentItem {
  ... on Banner {
    ...bannerFolderItem
    __typename
  }
}
    ${BannerFolderItemFragmentDoc}`;