import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import React from 'react';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { useSelector } from 'react-redux';
import { VodFolderData } from 'App/Modules/VodFolderData';
import { Folder } from 'App/Modules/Folder';
import { FolderCardItem } from 'App/Modules/Folder/Components/FolderCardItem';
import { MoreCard } from 'App/Modules/Folder/Components/MoreCard';
import { actions } from 'App/Modules/Screen/Modules/VodFolders/Store/actions';

export const View: React.FunctionComponent<Screen.Props<Screen.Type.VodFolders>> = (props) => {
    const dispatch = useDispatch();
    Focus.useBack(() => dispatch(History.actions.pop()));
    const folders = useSelector(VodFolderData.selectors.selectFoldersIds);

    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <UI.Folder.List offsetTop={UI.Folder.Constants.List.Scroll.Offset.Default}>
                {folders.map((id) => {
                    const selector = VodFolderData.selectors.selectFolder(id);
                    return (
                        <UI.Folder key={id} id={id}>
                            <UI.Folder.Header>
                                <UI.Selector selector={Folder.selectors.selectTitle(selector)} />
                            </UI.Folder.Header>
                            <UI.Folder.Items
                                initial={props.focusedFolderId === id ? props.focusedItemId : undefined}
                                onEnter={(itemId) => dispatch(actions.private.selected(id, itemId))}
                            >
                                <UI.Selector selector={Folder.selectors.selectItemsIds(selector)}>
                                    {(ids) =>
                                        ids.map((itemId) => (
                                            <FolderCardItem key={itemId} selector={Folder.selectors.selectItem(selector)(itemId)} />
                                        ))
                                    }
                                </UI.Selector>
                                <MoreCard id={UI.Folder.Constants.Item.Id.More} selector={selector} />
                            </UI.Folder.Items>
                        </UI.Folder>
                    );
                })}
            </UI.Folder.List>
        </Layout>
    );
};
