const HD_HEIGHT = 720;
/**
 * @namespace Utils
 */

/**
 * @description Is client device HD
 * @constant
 * @type {boolean}
 */
export const isHD = document.documentElement.clientHeight === HD_HEIGHT;
