import { Subscribable } from 'App/Packages/Subscribable';
import { DetailActionsProps, DetailActions } from 'App/Packages/UI/Detail/Components/DetailActions';
import { FolderList } from 'App/Packages/UI/Folder/Modules/FolderList';
import React, { useEffect } from 'react';

export type FolderDetailActionsProps = Omit<DetailActionsProps, 'ctx' | 'isFocused'> & {
    id: string;
};

export type FolderDetailActionsComponent = React.FunctionComponent<FolderDetailActionsProps>;

export const FolderDetailActions: FolderDetailActionsComponent = (props) => {
    const folderList = FolderList.use();
    Subscribable.useRegister(folderList.ids, props.id);
    const [focused, setFocused] = React.useState(false);

    useEffect(() => {
        const isFocused = () => folderList.focused.get() === props.id;
        const update = () => setFocused(isFocused());
        update();
        return folderList.focused.subscribe(update);
    }, [folderList, props.id]);

    return (
        <DetailActions isFocused={focused} ctx={folderList.ctx} className={props.className}>
            {props.children}
        </DetailActions>
    );
};
