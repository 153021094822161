import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import { ProductOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';

import { Folder } from 'App/Modules/Folder';
import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import { LoadVodAssetDetailQueryVariables } from 'App/Modules/Data/Detail/VodAsset/Api/vodAssetDetailLoad.generated';
import { initialVodAssetDetailReducerState } from 'App/Modules/Data/Detail/VodAsset/Store/initialState';
import { Api } from 'App/Modules/Data/Detail/VodAsset/Api';
import {
    DetailVodAssetLoadedAction,
    DetailVodAssetLoadedActionPayload,
    DetailVodAssetResetAction,
    VodAssetActionTypes,
} from 'App/Modules/Data/Detail/VodAsset/Types/Types';

export const loaded = (payload: DetailVodAssetLoadedActionPayload): DetailVodAssetLoadedAction => ({
    type: VodAssetActionTypes.Load,
    payload,
});

export const reset = (): DetailVodAssetResetAction => ({
    type: VodAssetActionTypes.Reset,
});

export const load =
    (variables: LoadVodAssetDetailQueryVariables): Thunk<Promise<void>> =>
    async (dispatch) => {
        const data = await Api.load(variables);

        if (!data || !data.vodAsset) return;

        const products = (data.upsellProducts?.items as ProductOption[]) ?? [];
        const item = Mapper.toDetailItem(data.vodAsset, products);

        if (!item) return;

        const folders = data.vodAsset?.relatedContent ? Folder.Mapper.toFolderList(data.vodAsset?.relatedContent) : null;

        const assetObject = createAssetObject(data.vodAsset, data.vodAsset?.__typename);

        dispatch(loaded({ item, assetObject, folders: folders ?? initialVodAssetDetailReducerState.folders, products }));

        dispatch(VodUpsell.actions.setCurrentAsset(assetObject));
        dispatch(VodUpsell.actions.setProducts(products ?? []));
        dispatch(VodUpsell.actions.setVodAsset(data.vodAsset.id));
    };

export const actions = {
    public: {
        load,
        reset,
    },
    private: {},
} as const;
