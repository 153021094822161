import { DetailActionsContext } from 'App/Packages/UI/Detail/Modules/DetailActions/Context/DetailActionsContext';
import { UseDetailActions, useDetailActions } from 'App/Packages/UI/Detail/Modules/DetailActions/Hooks/useDetailActions';
import {
    UseDetailActionsValue,
    UseDetailActionsValuePayload,
    useDetailActionsValue,
} from 'App/Packages/UI/Detail/Modules/DetailActions/Hooks/useDetailActionsValue';
import React from 'react';

export type DetailActionsProps = UseDetailActionsValuePayload & {
    children: React.ReactNode;
};

export type DetailActionsModule = React.FunctionComponent<DetailActionsProps> & {
    use: UseDetailActions;
    useValue: UseDetailActionsValue;
    Context: typeof DetailActionsContext;
};

export const DetailActions: DetailActionsModule = (props) => {
    const value = useDetailActionsValue(props);
    const key = value.ids.get().join('');
    return (
        <DetailActionsContext.Provider key={key} value={value}>
            {props.children}
        </DetailActionsContext.Provider>
    );
};

// Hooks
DetailActions.use = useDetailActions;
DetailActions.useValue = useDetailActionsValue;

// Contexts
DetailActions.Context = DetailActionsContext;
