import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { EventConcepts } from 'analytics/types/concepts';

import { SessionTypes, getLoggingSessions } from '../LoggingSession';
import { generateUuid } from '../loggingUtils';

export enum IssueTypes {
    UI = 'UI',
    SERVER_MIDDLEWARE = 'Server Middleware',
    BACK_OFFICE = 'Back-Office',
    CASDRM = 'CAS-DRM',
    DEVICE = 'Device',
    VIDEO = 'Video',
    RECOMMENDATION = 'Recommendation',
    OTHER = 'Other',
}

export enum IssueSeverities {
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
}

/**
 * @class IssueEvent
 */
export class IssueEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.ISSUE;

    private readonly event: string = 'produce';

    private readonly linkedSessionId: string;

    /**
     *
     * @param code
     * @param message
     * @param severity
     * @param type
     * @param context
     * @param detail
     * @param playbackUrl
     */
    constructor(
        private readonly code: string,
        private readonly message: string,
        private readonly severity: IssueSeverities,
        private readonly type: IssueTypes,
        private context?: string,
        private detail?: string,
        private playbackUrl?: string
    ) {
        super(generateUuid());

        this.code = code;
        this.message = message;
        this.severity = severity;
        this.type = type;

        const loggingSessions = getLoggingSessions();
        switch (type) {
            case IssueTypes.VIDEO:
                this.linkedSessionId = loggingSessions[SessionTypes.VIEW] || '';
                break;
            default:
                this.linkedSessionId = loggingSessions[SessionTypes.USER] || loggingSessions[SessionTypes.APPLICATION] || '';
                break;
        }

        if (context) this.context = context;
        else delete this.context;

        if (playbackUrl) this.playbackUrl = playbackUrl;
        else delete this.playbackUrl;

        if (detail) this.detail = detail;
        else delete this.detail;
    }
}
