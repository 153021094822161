import { Env } from 'App/Env';

const getKey = (key: string, fallback: number): number => {
    const value: unknown = window[key as any];
    if (typeof value === 'number') return value;
    return fallback;
};

const VK_0 = getKey('VK_0', 48);
const VK_1 = getKey('VK_1', 49);
const VK_2 = getKey('VK_2', 50);
const VK_3 = getKey('VK_3', 51);
const VK_4 = getKey('VK_4', 52);
const VK_5 = getKey('VK_5', 53);
const VK_6 = getKey('VK_6', 54);
const VK_7 = getKey('VK_7', 55);
const VK_8 = getKey('VK_8', 56);
const VK_9 = getKey('VK_9', 57);
const VK_LEFT = getKey('VK_LEFT', 37);
const VK_UP = getKey('VK_UP', 38);
const VK_RIGHT = getKey('VK_RIGHT', 39);
const VK_DOWN = getKey('VK_DOWN', 40);
const VK_RED = getKey('VK_RED', 403);
const VK_GREEN = getKey('VK_GREEN', 404);
const VK_YELLOW = getKey('VK_YELLOW', 405);
const VK_BLUE = getKey('VK_BLUE', 406);
const VK_ENTER = getKey('VK_ENTER', 13);
const VK_MENU = getKey('VK_MENU', 18);
const VK_REWIND = getKey('VK_REWIND', 412);
const VK_FAST_FWD = getKey('VK_FAST_FWD', 417);
const VK_STOP = getKey('VK_STOP', 413);
const VK_PLAY = getKey('VK_PLAY', 415);
const VK_RECORD = getKey('VK_RECORD', 416);
const VK_PAUSE = getKey('VK_PAUSE', 19);
const VK_SPACE = getKey('VK_SPACE', 32);
const VK_INFO = getKey('VK_INFO', 457);
const VK_BACK_SPACE = getKey('VK_BACK_SPACE', 8);
const VK_PREV = getKey('VK_PREV', 10232);
const VK_NEXT = getKey('VK_NEXT', 10233);
const VK_MINUS = getKey('VK_MINUS', 189);
const VK_CHANNEL_PREV = getKey('VK_CHANNEL_PREV', 10190);
const VK_KEY_EXIT = getKey('VK_KEY_EXIT', 10182);
const VK_SEARCH = getKey('VK_SEARCH', 10225);
const VK_CHANNEL_LIST = getKey('VK_CHANNEL_LIST', 10073);
const VK_DONE = getKey('VK_DONE', 65376);
const VK_CANCEL = getKey('VK_CANCEL', 65385);
const VK_FAV = getKey('VK_FAV', 1011);
const VK_Q_VIEW = getKey('VK_Q_VIEW', 1007);
const VK_CURSOR_ON = getKey('VK_CURSOR_ON', 1536);
const VK_CURSOR_OFF = getKey('VK_CURSOR_OFF', 1537);

const BROWSER_KEYS = {
    VK_PLAY_PAUSE: 10252,
    VK_BACK: 8,
    VK_ESC: 8,
    VK_CAPTION: 10221,
    VK_CHAN_UP: 427,
    VK_CHAN_DOWN: 428,
};

const HISENSE_KEYS = {
    VK_PLAY_PAUSE: 463,
    VK_BACK: 8,
    VK_ESC: 27,
    VK_CAPTION: 10221,
    VK_CHAN_UP: 427,
    VK_CHAN_DOWN: 428,
};

const PHILIPS_KEYS = {
    VK_PLAY_PAUSE: getKey('VK_PLAY_PAUSE', 10252),
    VK_BACK: getKey('VK_BACK', 8),
    VK_ESC: getKey('VK_ESC', 27),
    VK_CAPTION: getKey('VK_CAPTION', 10221),
    VK_CHAN_UP: getKey('VK_CHAN_UP', 33),
    VK_CHAN_DOWN: getKey('VK_CHAN_DOWN', 34),
};

const TIZEN_KEYS = {
    VK_PLAY_PAUSE: 10252,
    VK_BACK: 10009,
    VK_ESC: 27,
    VK_CAPTION: 10221,
    VK_CHAN_UP: 427,
    VK_CHAN_DOWN: 428,
};

const WEBOS_KEYS = {
    VK_PLAY_PAUSE: 10252,
    VK_BACK: 461,
    VK_ESC: 27,
    VK_CAPTION: 460,
    VK_CHAN_UP: 33,
    VK_CHAN_DOWN: 34,
};

export const NumberKeys = [VK_0, VK_1, VK_2, VK_3, VK_4, VK_5, VK_6, VK_7, VK_8, VK_9];

export const Key = {
    VK_0,
    VK_1,
    VK_2,
    VK_3,
    VK_4,
    VK_5,
    VK_6,
    VK_7,
    VK_8,
    VK_9,
    VK_LEFT,
    VK_UP,
    VK_RIGHT,
    VK_DOWN,
    VK_RED,
    VK_GREEN,
    VK_YELLOW,
    VK_BLUE,
    VK_ENTER,
    VK_MENU,
    VK_REWIND,
    VK_FAST_FWD,
    VK_STOP,
    VK_PLAY,
    VK_RECORD,
    VK_PAUSE,
    VK_SPACE,
    VK_INFO,
    VK_BACK_SPACE,
    VK_PREV,
    VK_NEXT,
    VK_MINUS,
    VK_CHANNEL_PREV,
    VK_KEY_EXIT,
    VK_SEARCH,
    VK_CHANNEL_LIST,
    VK_DONE,
    VK_CANCEL,
    VK_CURSOR_ON,
    VK_CURSOR_OFF,
    VK_FAV,
    VK_Q_VIEW,
    ...BROWSER_KEYS,
    ...(Env.IsTizen ? TIZEN_KEYS : {}),
    ...(Env.IsWebOS ? WEBOS_KEYS : {}),
    ...(Env.IsVidaa ? HISENSE_KEYS : {}),
    ...(Env.IsTitan ? PHILIPS_KEYS : {}),
};
