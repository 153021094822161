import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { NowOnTVGrid } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/NowOnTVGrid';
import { NowOnTVHeader } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/NowOnTVHeader';

import { Screen } from 'App/Modules/Screen';

export const NowOnTV: React.FunctionComponent = () => (
    <Layout className="now-on-tv" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <NowOnTVHeader />
        <div className="now-on-tv__grid-container">
            <NowOnTVGrid />
        </div>
    </Layout>
);
