import React from 'react';

import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageActionEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import {
    messageDetailActionSelected,
    messageDetailActionsBlured,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import {
    selectMessageDetailActions,
    selectMessageDetailIsPlayerPreview,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const MessageDetailActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const actions = useSelector(selectMessageDetailActions);
    const isPlayerPreview = useSelector(selectMessageDetailIsPlayerPreview);
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: MessageDetailCTX.ACTION,
        axis: Axis.X,
        items: actions,
        resetFocusOnBlur: true,
    });

    const enterHandler = (action: MessageActionEntity) => dispatch(messageDetailActionSelected(action));
    const blurHandler = () => dispatch(messageDetailActionsBlured());

    return (
        <>
            {actions.map((action) => (
                <Button
                    key={action.id}
                    id={action}
                    ctx={MessageDetailCTX.ACTION}
                    className="message-detail__action"
                    isFocused={focused === action && !isDisabled}
                    width={ButtonWidth.FIT}
                    isPrimary={isPlayerPreview}
                    corners={ButtonCornersStyle.ROUNDED}
                    onEnter={enterHandler}
                    onHover={focus}
                    onBlur={blurHandler}
                >
                    {action.title}
                </Button>
            ))}
        </>
    );
};
