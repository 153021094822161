import React, { useCallback, useEffect } from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { recordingManagementActionTaken } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { RECORDING_MANAGEMENT_ACTION_FOR_ACTION_ID } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/constants';
import {
    selectCurrentRecordings,
    selectRecordingManagementActionTranslation,
    selectRecordingManagementActions,
    selectRecordingManagementIsTrickMode,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import {
    RecordingManagementActionID,
    RecordingManagementFocusContext,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';
import { store } from '__SMART_APP_OLD__/app/store/store';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const RecordingManagementActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const actions = useSelector(selectRecordingManagementActions);
    const isTrickMode = useSelector(selectRecordingManagementIsTrickMode);
    const selectedRecordings = useSelector(selectCurrentRecordings);
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: RecordingManagementFocusContext.HEADER,
        axis: Axis.X,
        items: actions,
    });

    useEffect(() => {}, [selectedRecordings]);

    const enterHandler = useCallback(
        (actionId: RecordingManagementActionID) => {
            dispatch(recordingManagementActionTaken(actionId));
        },
        [dispatch]
    );

    return (
        <>
            {actions.map((id) => (
                <Button
                    key={id}
                    id={id}
                    ctx={RecordingManagementFocusContext.HEADER}
                    className="recording-management__action"
                    width={ButtonWidth.FIT}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === id && !isDisabled}
                    onEnter={() => {
                        const simulateDisabled =
                            isTrickMode && selectedRecordings.length === 0 && id === RecordingManagementActionID.DELETE;
                        if (simulateDisabled) return;
                        enterHandler(id);
                    }}
                    onHover={focus}
                >
                    {selectRecordingManagementActionTranslation(RECORDING_MANAGEMENT_ACTION_FOR_ACTION_ID[id].label)(store.getState())}
                </Button>
            ))}
        </>
    );
};
