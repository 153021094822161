import LandscapeCard from '__SMART_APP_OLD__/data/LandscapeCard';
import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import VODSeason from '__SMART_APP_OLD__/data/VODSeason';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import translate from 'language/translate';

export default class VODSeries extends LandscapeCard {
    private _assetType: string = AssetType.VOD_SERIES;

    seasons: Array<VODSeason> = [];

    episodes: Array<VODAsset> = [];

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);

        if (data?.seasonInfos?.length) {
            this.seasons = data?.seasonInfos.map(
                (season: VODSeason) => new VODSeason(season, data?.content?.firstItems?.edges, factoryFunc)
            );
        }
    }

    get shortMetaInfo() {
        const metaInfo = [];

        if (this.seasonNumbers) {
            metaInfo.push(
                `${this.seasonNumbers} ${this.seasonNumbers === 1 ? translate('SCREEN_CARDS_SEASON') : translate('SCREEN_CARDS_SEASONS')}`
            );
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }

    get relatedMetaInfo() {
        return this.shortMetaInfo;
    }

    get detailPageMetaInfo() {
        return this.genres;
    }

    get genres() {
        return this.seasons[0]?.episodes?.[0]?.genres;
    }

    get assetType(): string {
        return this._assetType;
    }
}
