import Asset from './Asset';
import { secondsToTime } from '../utils/timeUtils';

export default class PortraitCard extends Asset {
    get shortMetaInfo() {
        const metaInfo = [];

        if (this.releaseYear) {
            metaInfo.push(this.releaseYear);
        }

        if (this.genres) {
            metaInfo.push(this.genres);
        }

        if (this.duration) {
            const assetDuration = secondsToTime(this.duration);
            metaInfo.push(assetDuration);
        }

        return metaInfo.join('&nbsp;&nbsp;I&nbsp;&nbsp;');
    }
}
