import { ContentFolderKind } from '__SMART_APP_OLD__/api/graphql/types';
import { CollectionEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';

export const initialCollectionState: CollectionEntityTable = {
    ids: [],
    title: '',
    kind: ContentFolderKind.Recommendation,
    id: '',
    entities: {},
};
