import Preferences from '__SMART_APP_OLD__/config/Preferences';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { PlayerType } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';

export const playChannel = (channelCodeName: any = null, isReplaceNeeded: boolean = false, isStartOver: boolean = false) => {
    console.log('[Tuner] Play channel:', channelCodeName);
    if (channelCodeName) {
        if (document.location.pathname.indexOf('/playChannel') !== -1 || isReplaceNeeded) {
            History.replace(`/playChannel/${channelCodeName}`, { playFromStart: isStartOver } as never);
        } else {
            History.go(`/playChannel/${channelCodeName}`, { playFromStart: isStartOver } as never);
        }

        Preferences.lastWatchedChannel = channelCodeName;
    }
};

interface IPlayVod {
    isTrailer: boolean;
    isStartOver: boolean;
    isReplaceNeeded?: boolean;
    bookmarkPosition?: number;
}

export const playVod = (asset: any, options: IPlayVod) => {
    console.log('[Tuner] Play vod:', asset, options);
    const { isTrailer, isReplaceNeeded, isStartOver, bookmarkPosition: optionsBookmarkPosition = NaN } = options;
    const { assetType, bookmark, id } = asset;
    let bookmarkValue = Number.isNaN(optionsBookmarkPosition) ? bookmark?.position || 0 : optionsBookmarkPosition;
    const playerType = isTrailer ? PlayerType.TRAILER : assetType;
    const isRecording = playerType === PlayerType.NETWORK_RECORDING;
    if (isTrailer || (isStartOver && !isRecording)) {
        bookmarkValue = 0;
    } else if (isStartOver && isRecording) {
        bookmarkValue = asset?.leadIn ?? 0;
    }

    Player.setProgram(asset);
    if (document.location.pathname.indexOf('/play/') !== -1 || isReplaceNeeded) {
        History.replace(`/play/${playerType}/${id}/${bookmarkValue}`);
    } else {
        History.go(`/play/${playerType}/${id}/${bookmarkValue}`);
    }
};
