import React from 'react';

export type KeyCapsLockIconProps = {
    active?: boolean;
};

export type KeyCapsLockIconComponent = React.FunctionComponent<KeyCapsLockIconProps>;

export const KeyCapsLockIcon: KeyCapsLockIconComponent = React.memo((props) => (
    <svg
        className={['key__icon', props.active ? 'key__icon--fill' : 'key__icon--stroke key__icon--no-fill'].join(' ')}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.08 25.396H9.92a.95.95 0 0 1-.95-.95v-8.634H4.515c-.867 0-1.28-1.065-.641-1.65l10.482-9.59a.95.95 0 0 1 1.297.014m4.427 20.81a.95.95 0 0 0 .95-.95v-8.634h4.03c.858 0 1.276-1.046.656-1.637l-10.063-9.59"
            strokeWidth="1.5"
        />
    </svg>
));
