import React, { useMemo } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';

type Props = {
    typeface: Typeface;
    size: FontSize;
    weight: FontWeight;
    color: Color;
    theme?: Theme.Type | null;
    isFocused?: boolean;
    className?: string;
    children?: React.ReactNode;
    customColor?: string;
};

export enum FontSize {
    BIG_1 = 'BIG_1',
    BIG_2 = 'BIG_2',
    TITLE_1 = 'TITLE_1',
    TITLE_2 = 'TITLE_2',
    TITLE_3 = 'TITLE_3',
    BODY_1 = 'BODY_1',
    BODY_2 = 'BODY_2',
    CAPTION_1 = 'CAPTION_1',
    CAPTION_2 = 'CAPTION_2',
    CAPTION_3 = 'CAPTION_3',
}

export enum Color {
    PRIMARY = 'PRIMARY',
    PRIMARY_INVERTED = 'PRIMARY_INVERTED',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY',
    QUATERNARY = 'QUATERNARY',
    CUSTOM = 'CUSTOM',
}

export enum FontWeight {
    REGULAR = 'REGULAR',
    BOLD = 'BOLD',
}

export enum Typeface {
    SANS = 'SANS',
    SERIF = 'SERIF',
}

const classNameForTypeface: Record<Typeface, string> = {
    [Typeface.SANS]: 'text-v2--sans',
    [Typeface.SERIF]: 'text-v2--serif',
};

const classNameForFontStyle: Record<FontSize, string> = {
    [FontSize.BIG_1]: 'text-v2--big-1',
    [FontSize.BIG_2]: 'text-v2--big-2',
    [FontSize.TITLE_1]: 'text-v2--title-1',
    [FontSize.TITLE_2]: 'text-v2--title-2',
    [FontSize.TITLE_3]: 'text-v2--title-3',
    [FontSize.BODY_1]: 'text-v2--body-1',
    [FontSize.BODY_2]: 'text-v2--body-2',
    [FontSize.CAPTION_1]: 'text-v2--caption-1',
    [FontSize.CAPTION_2]: 'text-v2--caption-2',
    [FontSize.CAPTION_3]: 'text-v2--caption-3',
};

const classNameForFontWeight: Record<FontWeight, string> = {
    [FontWeight.REGULAR]: 'text-v2--regular',
    [FontWeight.BOLD]: 'text-v2--bold',
};

const classNameForColor: Record<Color, string> = {
    [Color.PRIMARY]: 'text-v2--primary',
    [Color.PRIMARY_INVERTED]: 'text-v2--primary-inverted',
    [Color.SECONDARY]: 'text-v2--secondary',
    [Color.TERTIARY]: 'text-v2--tertiary',
    [Color.QUATERNARY]: 'text-v2--quatenary',
    [Color.CUSTOM]: '',
};

const classNameForThemeType: Record<string, string> = {
    DARK: 'text-v2--dark-theme',
    LIGHT: 'text-v2--light-theme',
};

const getClassName = (props: Props, theme: Theme.Type): string => {
    const classList = ['text-v2'];
    classList.push(classNameForTypeface[props.typeface]);
    classList.push(classNameForFontStyle[props.size]);
    classList.push(classNameForFontWeight[props.weight]);
    classList.push(classNameForColor[props.color]);
    classList.push(classNameForThemeType[theme]);
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const Text = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const theme = useTheme();
    const className = getClassName(props, props.isFocused ? Theme.Type.Dark : props.theme ?? theme);
    const text = useSelector(Translation.selectors.select(typeof props.children === 'string' ? props.children : ''));
    const children = typeof props.children === 'string' ? text : props.children;
    const style = useMemo(() => (props.customColor ? { color: props.customColor } : undefined), [props.customColor]);

    return (
        <div className={className} ref={ref} style={style}>
            {children}
        </div>
    );
}) as React.FunctionComponent<Props & React.RefAttributes<HTMLDivElement>>;
