import { GQL } from '__SMART_APP_OLD__/app/gql';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum CommunityActionType {
    LOADED = '@community/loaded',
    CHANGED = '@community/changed',
}

// Action
export type CommunityReduxAction = CommunityLoadedAction | CommunityChangedAction;

export type CommunityLoadedActionPayload = { communities: GQL.Community[] };
export type CommunityLoadedAction = PayloadedAction<CommunityActionType.LOADED, CommunityLoadedActionPayload>;

export type CommunityChangedActionPayload = { communityId: string };
export type CommunityChangedAction = PayloadedAction<CommunityActionType.CHANGED, CommunityChangedActionPayload>;

// State
export type CommunityEntity = {
    id: string;
    title: string;
};

export type CommunityEntityTable = {
    selected: string;
    ids: string[];
    entities: Record<string, CommunityEntity>;
};
