import { headerActions } from '__SMART_APP_OLD__/app/modules/Header/actions';
import { headerReducer } from '__SMART_APP_OLD__/app/modules/Header/reducer';
import { headerSelectors } from '__SMART_APP_OLD__/app/modules/Header/selectors';
import { HeaderData, HeaderReduxAction } from '__SMART_APP_OLD__/app/modules/Header/types';

export type { HeaderData as State, HeaderReduxAction as Action };
export const reducer = headerReducer;

export const actions = headerActions.public;
export const selectors = headerSelectors.public;
