import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector as SelectorFn } from '__SMART_APP_OLD__/app/store/types/Selector';

export type SelectorProps<T> = {
    selector: SelectorFn<T>;
    children?: (value: T) => React.ReactNode;
};

export const Selector = <T,>(props: SelectorProps<T>): ReturnType<React.FunctionComponent<SelectorProps<T>>> => {
    const value = useSelector(props.selector);
    if (!props.children) return <>{value}</>;
    return <>{props.children(value)}</>;
};
