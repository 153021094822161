import { initialTrackViewingBehaviourState } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/constants';
import { TrackViewingBehaviourActionType } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const trackViewingBehaviourReducer: Reducer<boolean> = (state, action) => {
    if (state === undefined) return initialTrackViewingBehaviourState;

    switch (action.type) {
        case TrackViewingBehaviourActionType.CHANGED:
            return action.payload.value;
        default:
            return state;
    }
};
