export const NOTIFICATION_SHOW = 'notification-show';
export const NOTIFICATION_HIDE = 'notification-hide';
export const MORE_OVERLAY_SHOW = 'more-info-show';
export const MORE_OVERLAY_HIDE = 'more-info-hide';
export const PIN_OVERLAY_SHOW = 'pin-overlay-show';
export const PIN_OVERLAY_HIDE = 'pin-overlay-hide';
export const PIN_DATA_UPDATE = 'pin-data-update';
export const PIN_BLOCKED = 'pin-blocked';
export const PIN_UNBLOCKED = 'pin-unblocked';
export const INTERNET_CONNECTION_ON = 'internet-connection-on';
export const INTERNET_CONNECTION_OFF = 'internet-connection-off';
export const PLAYER_SEEK = 'player-seek';
export const SEARCH_HIDE_TOP_AREA = 'search-hide-top-area';
export const SEARCH_SHOW_TOP_AREA = 'search-show-top-area';
export const SHOW_CHANNEL_SWITCH_DIALOG = 'show-channel-switch-dialog';
export const HIDE_CHANNEL_SWITCH_DIALOG = 'hide-channel-switch-dialog';
export const CONTINUE_WATCHING_CHANGED = 'continue-watching-changed';
export const CLEAR_SEARCH_HISTORY = 'clear-search-history';
export const CONTAINER_SCROLLED = 'container-scrolled';
export const DARK_MODE_CHANGED = 'dark-mode-changed';
export const GALLERY_PLAYER_HIDE = 'gallery-player-hide';
export const GALLERY_PLAYER_SHOW = 'gallery-player-show';
export const USER_ACTIVITY = 'user-activity';

export const HOME_CHANGED = 'home-changed';
export const LANGUAGE_CHANGED = 'language-changed';
/**
 * Manages global events across the app
 */
class Events {
    events = {};

    addEventListener(type, callback, options = { once: false }) {
        if (!this.events[type]) this.events[type] = [];

        if (options.once) {
            const wrappedHandler = (...args) => {
                callback(...args);
                this.removeEventListener(type, wrappedHandler);
            };

            this.events[type].push(wrappedHandler);
        } else {
            this.events[type].push(callback);
        }
    }

    removeEventListener(type, callback) {
        if (!this.events[type]) return false;
        const events = this.events[type];
        const index = events.indexOf(callback);

        if (index < 0) return false;
        this.events[type].splice(index, 1);
        return true;
    }

    getTypeEvents(type) {
        if (!this.events[type]) return [];
        const events = this.events[type];
        return events;
    }

    addEventListenerIfNotExist(type, callback) {
        if (!this.events[type]) this.events[type] = [];

        const events = this.events[type];
        const index = events.indexOf(callback);

        if (index < 0) this.events[type].push(callback);

        return true;
    }

    triggerEvents(type, args = {}) {
        if (!this.events[type]) return false;

        Promise.resolve()
            .then(() => {
                const events = this.events[type];
                events.forEach((callback) => {
                    try {
                        // eslint-disable-next-line promise/no-callback-in-promise
                        callback(args);
                    } catch (exc) {
                        console.error('Error in callback type:', type, 'Ref:', callback);
                    }
                });

                return null;
            })
            .catch((e) => {
                throw e;
            });

        return true;
    }
}

export default new Events();
