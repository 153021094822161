import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import { initializeBrowser } from '__SMART_APP_OLD__/app/platform/initializers/browser';
import { initializeHisense } from '__SMART_APP_OLD__/app/platform/initializers/hisense';
import { initializePhilips } from '__SMART_APP_OLD__/app/platform/initializers/philips';
import { initializeTizen } from '__SMART_APP_OLD__/app/platform/initializers/tizen';
import { initializeWebOs } from '__SMART_APP_OLD__/app/platform/initializers/webos';
import { PlatformInterface } from '__SMART_APP_OLD__/app/platform/types';

import { Env } from 'App/Env';

const initializePlatform = async (platform: PlatformInterface): Promise<void> => {
    if (Env.IsWebOS) return initializeWebOs(platform);
    if (Env.IsTizen) return initializeTizen(platform);
    if (Env.IsVidaa) return initializeHisense(platform);
    if (Env.IsTitan) return initializePhilips(platform);
    return initializeBrowser(platform);
};

const postInitializePlatform = (platform: PlatformInterface) => {
    Common.buildHeadersForConfigService(platform);
    Storage.set(Storage.Key.DEVICE_ID, platform.ID);
};

export const initialize = (): PlatformInterface => {
    const platform: PlatformInterface = Common.buildDefaultPlatform();
    platform.READY = initializePlatform(platform).then(() => postInitializePlatform(platform));
    return platform;
};
