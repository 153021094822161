import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Startup } from '__SMART_APP_OLD__/app/modules/Startup';

import { Overlay } from 'App/Modules/Overlay';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.StartUpSelect>> = () => {
    const dispatch = useDispatch();
    const selected = useSelector(Startup.selectors.select);

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={() => dispatch(Overlay.actions.unmount())}>
            <UI.Select.Header>
                <UI.Select.Title>{Translation.Key.Overlay.Select.Startup.Title}</UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options ids={Startup.optionIds} selected={selected}>
                <UI.Select.Option id={Startup.Position.Live} onEnter={() => dispatch(Startup.actions.changed(Startup.Position.Live))}>
                    <UI.Translation>{Translation.Key.Overlay.Select.Startup.Option.Live}</UI.Translation>
                </UI.Select.Option>
                <UI.Select.Option id={Startup.Position.Home} onEnter={() => dispatch(Startup.actions.changed(Startup.Position.Home))}>
                    <UI.Translation>{Translation.Key.Overlay.Select.Startup.Option.Home}</UI.Translation>
                </UI.Select.Option>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
