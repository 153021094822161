import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { RecordingDetailedFragmentDoc } from '../fragments/recording_detailed.generated';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNetworkSeriesRecordingMutationVariables = Types.Exact<{
  input: Types.CreateNetworkSeriesRecordingInput;
  backgroundWidth: Types.Scalars['Int']['input'];
  backgroundHeight: Types.Scalars['Int']['input'];
}>;


export type CreateNetworkSeriesRecordingMutation = { __typename?: 'Nexx4Mutations', createNetworkSeriesRecording: { __typename: 'CreateNetworkSeriesRecordingPayload', recordings: Array<{ __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export type CreateNetworkSeriesRecordingFragment = { __typename?: 'Nexx4Mutations', createNetworkSeriesRecording: { __typename: 'CreateNetworkSeriesRecordingPayload', recordings: Array<{ __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, size: number, start: any, end: any, availableUntil?: any | null, deleteProtected: boolean, personalInfo: { __typename?: 'PersonalRecordingInfo', favourited: boolean, partOfSeriesRecording: boolean }, channel?: { __typename?: 'Channel', id: string, beforeTime?: number | null, afterTime?: number | null } | null, event: { __typename?: 'Event', id: string, title: string, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } } } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export const CreateNetworkSeriesRecordingFragmentDoc = gql`
    fragment createNetworkSeriesRecording on Nexx4Mutations {
  createNetworkSeriesRecording(input: $input) {
    recordings {
      ...recordingDetailed
    }
    quota {
      ...quota
    }
    __typename
  }
}
    ${RecordingDetailedFragmentDoc}
${QuotaFragmentDoc}`;
export const CreateNetworkSeriesRecordingDocument = gql`
    mutation createNetworkSeriesRecording($input: CreateNetworkSeriesRecordingInput!, $backgroundWidth: Int!, $backgroundHeight: Int!) {
  ...createNetworkSeriesRecording
}
    ${CreateNetworkSeriesRecordingFragmentDoc}`;
export type CreateNetworkSeriesRecordingMutationFn = Apollo.MutationFunction<CreateNetworkSeriesRecordingMutation, CreateNetworkSeriesRecordingMutationVariables>;

/**
 * __useCreateNetworkSeriesRecordingMutation__
 *
 * To run a mutation, you first call `useCreateNetworkSeriesRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkSeriesRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkSeriesRecordingMutation, { data, loading, error }] = useCreateNetworkSeriesRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      backgroundWidth: // value for 'backgroundWidth'
 *      backgroundHeight: // value for 'backgroundHeight'
 *   },
 * });
 */
export function useCreateNetworkSeriesRecordingMutation(baseOptions?: Apollo.MutationHookOptions<CreateNetworkSeriesRecordingMutation, CreateNetworkSeriesRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNetworkSeriesRecordingMutation, CreateNetworkSeriesRecordingMutationVariables>(CreateNetworkSeriesRecordingDocument, options);
      }
export type CreateNetworkSeriesRecordingMutationHookResult = ReturnType<typeof useCreateNetworkSeriesRecordingMutation>;
export type CreateNetworkSeriesRecordingMutationResult = Apollo.MutationResult<CreateNetworkSeriesRecordingMutation>;
export type CreateNetworkSeriesRecordingMutationOptions = Apollo.BaseMutationOptions<CreateNetworkSeriesRecordingMutation, CreateNetworkSeriesRecordingMutationVariables>;