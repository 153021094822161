import { EventConcepts } from 'analytics/types/concepts';

import { AbstractEvent } from './AbstractEvent';
import { NetworkConnection } from '../classes/NetworkConnection';
import { generateUuid } from '../loggingUtils';

export enum NetworkEvents {
    SWITCH_TRANSPORT = 'switch transport',
    SWITCH_IP = 'switch ip',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
}

export class NetworkEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.NETWORK;

    constructor(
        private readonly event: NetworkEvents,
        private readonly connection: NetworkConnection
    ) {
        super(generateUuid());
    }
}
