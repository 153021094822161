import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectEventChannelID, selectEventTitle } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { messageRead, messageTriggerOne } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { selectMessageStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import {
    reminderBackTriggered,
    reminderNotificationActionTriggered,
    reminderStatusChange,
} from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/actions';
import { ReminderEntityStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { NOTIFICATION_STATUS_FOR_ANIMATION_NAME } from '__SMART_APP_OLD__/app/modules/Notification/constants';
import { selectNotification, selectNotificationStack } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import {
    NotificationActionID,
    NotificationActiveStatusChangeAction,
    NotificationAnimation,
    NotificationHideAction,
    NotificationIconType,
    NotificationShowAction,
    NotificationStatusChangeAction,
    NotificationStatusChangeActionPayload,
    NotificationType,
    NotificationVisibilityStatus,
} from '__SMART_APP_OLD__/app/modules/Notification/types';
import { toMessageDetail } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { SearchContext } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import translate from 'language/translate';

import { Screen } from 'App/Modules/Screen';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { notificationShow, textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';

export { notificationShow, textNotificationShow };

export const notificationHide = (notificationId: string): NotificationHideAction => ({
    type: ActionType.NOTIFICATION_HIDE,
    payload: { notificationId },
});

export const notificationStatusChange = (payload: NotificationStatusChangeActionPayload): NotificationStatusChangeAction => ({
    type: ActionType.NOTIFICATION_STATUS_CHANGE,
    payload,
});

export const notificationActiveStatusChange = (isActive: boolean): NotificationActiveStatusChangeAction => ({
    type: ActionType.NOTIFICATION_ACTIVE_STATUS_CHANGE,
    payload: { isActive },
});

export const seekNotificationShow = (
    title: string,
    icon: NotificationIconType.SEEK_BACKWARD | NotificationIconType.SEEK_FORWARD
): NotificationShowAction =>
    notificationShow({ id: title, type: NotificationType.SEEK, status: NotificationVisibilityStatus.ANIMATE_IN, timer: 3, title, icon });

export const channelNotSubscribedNotificationShow = (
    title: string,
    icon: NotificationIconType.INFO,
    channelTitle: string
): NotificationShowAction =>
    notificationShow({
        id: title,
        type: NotificationType.BASIC,
        status: NotificationVisibilityStatus.ANIMATE_IN,
        timer: 3,
        title: `${translate(title)} ${channelTitle}`,
        icon,
    });

export const channelNotInActiveChannelListNotificationShow = (
    title: string,
    icon: NotificationIconType.INFO,
    channelTitle: string
): NotificationShowAction =>
    notificationShow({
        id: title,
        type: NotificationType.CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST,
        status: NotificationVisibilityStatus.ANIMATE_IN,
        timer: 30,
        title: `${channelTitle} ${translate(title)}`,
        icon,
        actions: [NotificationActionID.GO_TO_CHANNEL_LIST, NotificationActionID.CANCEL],
    });

export const eventNotAvailableNotificationShow =
    (eventId: GQL.EventID): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const eventName = selectEventTitle(eventId)(state);
        const channelId = selectEventChannelID(eventId)(state);
        const channelName = selectChannelName(channelId)(state);
        const config = selectConfig(state);
        dispatch(
            notificationShow({
                id: eventId,
                icon: NotificationIconType.REMINDER,
                timer: config.eventNotAvailable.timer,
                status: NotificationVisibilityStatus.ANIMATE_IN,
                title: `${eventName} NOT_ALLOWED_BY_OWNER`,
                subtitle: `${translate('ON')} ${channelName}`,
                actions: [NotificationActionID.GO_TO_LIVE, NotificationActionID.CANCEL],
                type: NotificationType.EVENT_NOT_AVAILABLE,
            })
        );
    };

export const notificationBackTriggered =
    (notificationId: string): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const notification = selectNotification(notificationId)(state);
        if (!notification || !notification.actions?.length) return;
        dispatch(notificationStatusChange({ notificationId, status: NotificationVisibilityStatus.ANIMATE_OUT }));
        switch (notification.type) {
            case NotificationType.REMINDER:
                dispatch(reminderBackTriggered(notificationId));
                break;
            case NotificationType.EVENT_NOT_AVAILABLE:
                dispatch(History.actions.clearReduxBeforeNavigation());
                dispatch(Screen.actions.mount(Screen.Type.Detail, { id: notification.id, type: ContentItemType.Event }));
                break;
            case NotificationType.MESSAGE:
                dispatch(messageTriggerOne(notificationId));
                break;
            case NotificationType.CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST:
            case NotificationType.BASIC:
            case NotificationType.SEEK:
            default:
                break;
        }
    };

export const notificationAnimationEndTriggered =
    (notificationId: string, animation: NotificationAnimation): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const notification = selectNotification(notificationId)(state);
        if (!notification) return;
        const status = NOTIFICATION_STATUS_FOR_ANIMATION_NAME[animation];
        dispatch(notificationStatusChange({ notificationId, status }));
        if (animation === NotificationAnimation.ANIMATE_OUT) dispatch(notificationHide(notification.id));
        switch (notification.type) {
            case NotificationType.REMINDER:
                if (animation !== NotificationAnimation.TIMER) break;
                dispatch(reminderStatusChange({ reminderId: notificationId, status: ReminderEntityStatus.DISMISSED }));
                break;
            case NotificationType.MESSAGE:
                if (animation !== NotificationAnimation.TIMER) break;
                dispatch(messageTriggerOne(notificationId));
                break;
            case NotificationType.EVENT_NOT_AVAILABLE:
                if (animation !== NotificationAnimation.TIMER) break;
                dispatch(History.actions.clearReduxBeforeNavigation(true));
                playChannel(selectEventChannelID(notification.id)(getState()), true);
                break;
            case NotificationType.CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST:
            case NotificationType.BASIC:
            case NotificationType.SEEK:
            default:
                break;
        }
    };

const messageNotificationReadSelected =
    (messageId: GQL.MessageID): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const status = selectMessageStatus(messageId)(getState());
        if (status === GQL.MessageStatus.New) {
            await dispatch(messageRead([messageId]));
        }
        dispatch(toMessageDetail(messageId));
        dispatch(messageTriggerOne(messageId));
    };

const channelNotInActiveChannelList =
    (action: NotificationActionID): Thunk<void> =>
    (dispatch) => {
        switch (action) {
            case NotificationActionID.GO_TO_CHANNEL_LIST:
                dispatch(History.actions.clearReduxBeforeNavigation(true));
                dispatch(Screen.actions.mount(Screen.Type.CHANNEL_LISTS, {}, ChannelListsScreenCtx.ChannelLists));
                break;
            case NotificationActionID.CANCEL:
                if (window.location.pathname.includes('search')) {
                    dispatch(History.actions.clearReduxBeforeNavigation());
                    CustomHistory.go(`/search/${SearchContext.events}`);
                }
                break;
            default:
                break;
        }
    };

export const notificationActionTriggered =
    (notificationId: string, action: NotificationActionID): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const notification = selectNotification(notificationId)(state);
        if (!notification || !notification.actions?.length) return;
        dispatch(notificationStatusChange({ notificationId, status: NotificationVisibilityStatus.ANIMATE_OUT }));
        switch (notification.type) {
            case NotificationType.REMINDER:
                dispatch(reminderNotificationActionTriggered(notificationId, action));
                break;
            case NotificationType.MESSAGE: {
                switch (action) {
                    case NotificationActionID.READ_MESSAGE:
                        dispatch(messageNotificationReadSelected(notificationId));
                        selectNotificationStack(state).forEach((id) =>
                            dispatch(notificationStatusChange({ notificationId: id, status: NotificationVisibilityStatus.ANIMATE_OUT }))
                        );
                        break;
                    case NotificationActionID.CANCEL:
                        dispatch(messageTriggerOne(notificationId));
                        break;
                    default:
                        break;
                }
                break;
            }
            case NotificationType.EVENT_NOT_AVAILABLE: {
                switch (action) {
                    case NotificationActionID.GO_TO_LIVE:
                        dispatch(History.actions.clearReduxBeforeNavigation(true));
                        playChannel(selectEventChannelID(notification.id)(getState()), true);
                        break;
                    case NotificationActionID.CANCEL:
                        dispatch(History.actions.clearReduxBeforeNavigation());
                        dispatch(Screen.actions.mount(Screen.Type.Detail, { id: notification.id, type: ContentItemType.Event }));
                        break;
                    default:
                        break;
                }
                break;
            }
            case NotificationType.CHANNEL_NOT_IN_ACTIVE_CHANNEL_LIST: {
                dispatch(channelNotInActiveChannelList(action));
                break;
            }
            case NotificationType.BASIC:
            case NotificationType.SEEK:
            default:
                break;
        }
    };
