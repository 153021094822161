import React from 'react';

import { UI } from 'App/Packages/UI';

type Props = {
    title: string;
    isSelected: boolean;
    onEnter: () => void;
    className?: string;
};

type FolderHeaderActionComponent = React.FunctionComponent<Props>;

const getClassName = (className?: string) => {
    const classNames = ['detail-series__action'];
    if (className) classNames.push(className);
    return classNames.join(' ');
};

export const FolderHeaderAction: FolderHeaderActionComponent = (props) => {
    const { title, isSelected, onEnter } = props;

    const className = getClassName(props.className);

    return (
        <UI.Folder.Detail.Action id={title} onEnter={onEnter} className={className} isTransparent={!isSelected}>
            {title}
        </UI.Folder.Detail.Action>
    );
};
