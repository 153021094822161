import React, { memo } from 'react';

import { Button, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from "__SMART_APP_OLD__/app/hooks/useDispatch";
import { channelListsScreenActions } from "__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/actions";
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';

import { Focus } from 'App/Packages/Focus';

const ChannelListsScreenViewCreateButtonComponent = () => {
    const dispatch = useDispatch();

    const { isFocused, onHover } = Focus.useElement({ ctx: ChannelListsScreenCtx.CreateChannelList });

    const enterHandler = () => dispatch(channelListsScreenActions.private.createSelected());

    return (
        <Button
            id='create-channel-list-button'
            ctx={ChannelListsScreenCtx.CreateChannelList}
            typeface={Typeface.SANS}
            className="action-create-channel-list"
            isFocused={isFocused}
            corners={ButtonCornersStyle.ROUNDED}
            onHover={onHover}
            onEnter={enterHandler}
            onClick={enterHandler}
            icon={IconName.ADD}
        >
            SETTINGS_CHANNEL_LINEUP_CREATE_LIST_BUTTON
        </Button>
    );
};

export const ChannelListsScreenViewCreateButton = memo(ChannelListsScreenViewCreateButtonComponent);
