export enum UILayerTypes {
    BANNER = 'banner',
    BINGE_WATCHING_BANNER = 'bingewatchingbanner',
    CAROUSEL = 'carousel',
    COLUMN = 'column',
    ERROR_NOTIFICATION = 'errornotification',
    ERROR_POPUP = 'errorpopup',
    GRID = 'grid',
    HELP = 'help',
    MANAGE_SERIES = 'manageseries',
    MENU_BAR = 'menubar',
    MESSAGE_NOTIFICATION = 'messagenotification',
    MORE_SCREEN = 'morescreen',
    PROMO_POPUP = 'promopopup',
    RECORDING_CONFLICT = 'recordingconflict',
    RECORDING_SETTINGS = 'recordingsettings',
    REMINDER_POPUP = 'reminderpopup',
    ROW = 'row',
    STARTUP_MESSAGE = 'startupmessage',
    TITLE_BAR = 'titlebar',
    VIRTUAL_BANNER = 'virtualbanner',
    WHATS_ON_NOW_BANNER = 'whatsonnowbanner',
}
export interface UILayerParams {
    type: UILayerTypes;
    title?: string;
}

export class UILayer {
    private readonly type: UILayerTypes;

    private title?: string;

    constructor({ title, type }: UILayerParams) {
        this.type = type;
        if (title) this.title = title;
        else delete this.title;
    }
}
