import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export type HeaderData = {
    focusedIndex: number;
    selectedIndex: number;
    selectedIndexesHistory: number[];
};

export enum HeaderActionType {
    FOCUSED_INDEX_CHANGED = '@header/focusedIndex/changed',
    SELECTED_INDEX_CHANGED = '@header/selectedIndex/changed',
    ADD_INDEX_TO_HISTORY = '@header/selectedIndex/history/add',
    REMOVE_INDEX_FROM_HISTORY = '@header/selectedIndex/history/remove',
    CLEAR_INDEXES_HISTORY = '@header/selectedIndex/history/clear',
}

export type HeaderReduxAction =
    | HeaderFocusedIndexChangedAction
    | HeaderSelectedIndexChangedAction
    | HeaderAddIndexToHistoryAction
    | HeaderRemoveIndexFromHistoryAction
    | HeaderClearIndexesHistoryAction;

export type HeaderFocusedIndexChangedAction = PayloadedAction<HeaderActionType.FOCUSED_INDEX_CHANGED, number>;
export type HeaderSelectedIndexChangedAction = PayloadedAction<HeaderActionType.SELECTED_INDEX_CHANGED, number>;
export type HeaderAddIndexToHistoryAction = PayloadedAction<HeaderActionType.SELECTED_INDEX_CHANGED, number>;
export type HeaderRemoveIndexFromHistoryAction = PayloadedAction<HeaderActionType.SELECTED_INDEX_CHANGED, number>;
export type HeaderClearIndexesHistoryAction = BaseAction<HeaderActionType.CLEAR_INDEXES_HISTORY>;
