import { Registry } from 'App/Modules/Alert/Registry';
import { actions as alertActions } from 'App/Modules/Alert/Store/actions';
import { selectors as alertSelectors } from 'App/Modules/Alert/Store/selectors';

export { generator } from 'App/Modules/Alert/Store/generator';
export { getInitialState } from 'App/Modules/Alert/Store/getInitialState';
export { reducer } from 'App/Modules/Alert/Store/reducer';
export { AlertActionType as ActionType, AlertType as Type } from 'App/Modules/Alert/Types';
export type {
    AlertChangedAction as ChangedAction,
    AlertChangedActionPayload as ChangedActionPayload,
    AlertProps as Props,
    AlertState as State,
} from 'App/Modules/Alert/Types';
export { View } from 'App/Modules/Alert/View';

export const zIndex = 1500;
export const actions = alertActions.public;
export const selectors = alertSelectors.public;
export const { register } = Registry;
