import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    ChannelListChannelNumberRelationEntityTable,
    ChannelListChannelsRelationEntityTable,
    ChannelListEntity,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/types';

export const toEntity = (channelList: GQL.ChannelList): ChannelListEntity => ({
    id: channelList.id,
    kind: channelList.kind,
    name: channelList.name,
});

export const toEntities = (channelLists: GQL.ChannelList[]): Record<GQL.ChannelListID, ChannelListEntity> =>
    channelLists.reduce<Record<GQL.ChannelListID, ChannelListEntity>>((acc, ch) => {
        acc[ch.id] = toEntity(ch);
        return acc;
    }, {});

export const toRenamedEntity = (entity: ChannelListEntity, name: string): ChannelListEntity => ({ ...entity, name });

export const toRenamedEntities = (
    entities: Record<GQL.ChannelListID, ChannelListEntity>,
    channelListId: GQL.ChannelListID,
    name: string
): Record<GQL.ChannelListID, ChannelListEntity> => {
    if (!entities[channelListId]) return entities;
    return { ...entities, [channelListId]: toRenamedEntity(entities[channelListId], name) };
};

export const toAddedEntities = (
    entities: Record<GQL.ChannelListID, ChannelListEntity>,
    channelList: GQL.ChannelList
): Record<GQL.ChannelListID, ChannelListEntity> => ({ ...entities, [channelList.id]: toEntity(channelList) });

export const toUpdatedChannelsRelationEntityTable = (
    table: ChannelListChannelsRelationEntityTable,
    channelListId: GQL.ChannelListID,
    edges: GQL.ChannelListChannelEdge[]
): ChannelListChannelsRelationEntityTable => ({ ...table, [channelListId]: edges.map((edge) => edge.node.id) });

export const toUpdatedChannelNumberRelationEntityTable = (
    table: ChannelListChannelNumberRelationEntityTable,
    channelListId: GQL.ChannelListID,
    edges: GQL.ChannelListChannelEdge[]
): ChannelListChannelNumberRelationEntityTable => ({
    ...table,
    [channelListId]: edges.reduce<Record<GQL.ChannelID, number>>((acc, edge) => {
        acc[edge.node.id] = edge.channelListNumber;
        return acc;
    }, {}),
});
