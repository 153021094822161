/* eslint-disable import/no-mutable-exports */
/**
 * @namespace Utils
 */
/**
 * @description 'hidden' | 'mozHidden' | 'webkitHidden'
 * @type {string}
 * @memberof Utils
 */
let VISIBILITY_PROP: 'hidden' | 'mozHidden' | 'webkitHidden';

/**
 * @description 'visibilitychange' | 'mozvisibilitychange' | 'webkitvisibilitychange'
 * @type {string}
 * @memberof Utils
 */
let CHANGE_EVENT: 'visibilitychange' | 'mozvisibilitychange' | 'webkitvisibilitychange';

if (typeof document.hidden !== 'undefined') {
    VISIBILITY_PROP = 'hidden';
    CHANGE_EVENT = 'visibilitychange';
    // @ts-ignore
} else if (typeof document.mozHidden !== 'undefined') {
    VISIBILITY_PROP = 'mozHidden';
    CHANGE_EVENT = 'mozvisibilitychange';
    // @ts-ignore
} else if (typeof document.webkitHidden !== 'undefined') {
    VISIBILITY_PROP = 'webkitHidden';
    CHANGE_EVENT = 'webkitvisibilitychange';
}

export { VISIBILITY_PROP, CHANGE_EVENT };
