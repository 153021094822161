import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ChangeChannelListNameInput,
    ChangeInitialChannelListInput,
    CreatePersonalChannelListInput,
    SetChannelListChannelsInput,
} from '__SMART_APP_OLD__/api/graphql/types';
import { ChannelListChannelsEdgeFragment } from '__SMART_APP_OLD__/app/api/channelList/fragments/channelListChannelsEdge.generated';
import {
    ChannelListChangeDocument,
    ChannelListChangeFragment,
    ChannelListChangeMutationResult,
    ChannelListChangeMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListChange.generated';
import {
    ChannelListCreateDocument,
    ChannelListCreateFragment,
    ChannelListCreateMutationResult,
    ChannelListCreateMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListCreate.generated';
import {
    ChannelListMoveDocument,
    ChannelListMoveFragment,
    ChannelListMoveMutationResult,
    ChannelListMoveMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListMove.generated';
import {
    ChannelListRemoveDocument,
    ChannelListRemoveFragment,
    ChannelListRemoveMutationResult,
    ChannelListRemoveMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListRemove.generated';
import {
    ChannelListRenameDocument,
    ChannelListRenameFragment,
    ChannelListRenameMutationResult,
    ChannelListRenameMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListRename.generated';
import {
    ChannelListUpdateDocument,
    ChannelListUpdateFragment,
    ChannelListUpdateMutationResult,
    ChannelListUpdateMutationVariables,
} from '__SMART_APP_OLD__/app/api/channelList/mutation/channelListUpdate.generated';
import {
    ChannelListLoadInitialIdDocument,
    ChannelListLoadInitialIdFragment,
    ChannelListLoadInitialIdQueryResult,
    ChannelListLoadInitialIdQueryVariables,
} from '__SMART_APP_OLD__/app/api/channelList/query/channelListLoadInitialId.generated';
import {
    ChannelListLoadManyDocument,
    ChannelListLoadManyFragment,
    ChannelListLoadManyQueryResult,
    ChannelListLoadManyQueryVariables,
} from '__SMART_APP_OLD__/app/api/channelList/query/channelListLoadMany.generated';
import {
    ChannelListLoadManyChannelEventDocument,
    ChannelListLoadManyChannelEventFragment,
    ChannelListLoadManyChannelEventQueryResult,
    ChannelListLoadManyChannelEventQueryVariables,
} from '__SMART_APP_OLD__/app/api/channelList/query/channelListLoadManyChannelEvent.generated';
import {
    ChannelListLoadManyChannelEventPreciseDocument,
    ChannelListLoadManyChannelEventPreciseFragment,
    ChannelListLoadManyChannelEventPreciseQueryResult,
    ChannelListLoadManyChannelEventPreciseQueryVariables,
} from '__SMART_APP_OLD__/app/api/channelList/query/channelListLoadManyChannelEventPrecise.generated';
import {
    ChannelListLoadManyChannelRelationDocument,
    ChannelListLoadManyChannelRelationFragment,
    ChannelListLoadManyChannelRelationQueryResult,
    ChannelListLoadManyChannelRelationQueryVariables,
} from '__SMART_APP_OLD__/app/api/channelList/query/channelListLoadManyChannelRelation.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

/**
 * @namespace API.ChannelList
 */

export type CreateResponse = { channelList: GQL.ChannelList | null; totalCount: number };

/**
 * @memberof API.ChannelList
 * @description parses gql response to CreateResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns result of create channellist request and its total channels
 */
export const __parseCreate = (response?: ChannelListCreateFragment | null): CreateResponse => {
    const channelList = response?.createPersonalChannelList?.channelList ?? null;
    const totalCount = channelList?.channels.totalCount ?? 0;
    return { channelList, totalCount };
};

/**
 * @memberof API.ChannelList
 * @description Creates a personal channel list with the provided name and channels
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param channelListCreateInput need data for the creation of
 * a channel list
 * @returns raw response of create channel list request.
 */
export const create = async (channelListCreateInput: CreatePersonalChannelListInput): Promise<CreateResponse> => {
    const variables: ChannelListCreateMutationVariables = { channelListCreateInput };
    const response: ChannelListCreateMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListCreateDocument, variables);
    Error.collect(response);
    return __parseCreate(response.data);
};

export type LoadInitialIdResponse = { id: GQL.ChannelListID };

/**
 * @memberof API.ChannelList
 * @description Function that parses GQL Response to LoadInitialIdResponse
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @param [response] response from gql
 * @returns object with the initial channelList id.
 */
export const __parseLoadInitialId = (response?: ChannelListLoadInitialIdFragment): LoadInitialIdResponse => {
    const id = response?.initialChannelList?.id ?? '';
    return { id };
};

/**
 * @memberof API.ChannelList
 * @description Loads the id of the initial channel list for the current profile
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @param profileId id of the currently used profile
 * @returns the raw initial channel list data
 */
export const loadInitialId = async (profileId: GQL.ProfileID): Promise<LoadInitialIdResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: ChannelListLoadInitialIdQueryVariables = { profileId };
    const response: ChannelListLoadInitialIdQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelListLoadInitialIdDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadInitialId(response.data);
};

export type LoadManyResponse = {
    channelLists: GQL.ChannelList[];
    channelListChannelCount: Record<GQL.ChannelListID, number>;
};

/**
 * @memberof API.ChannelList
 * @description Function that parses GQL Response to LoadManyResponse
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @param [response] response from gql
 * @returns parsed full list of channel lists data.
 */
export const __parseLoadMany = (response?: ChannelListLoadManyFragment): LoadManyResponse =>
    (response?.channelLists?.items ?? []).reduce<LoadManyResponse>(
        (result, channelListWithChannels) => {
            if (!channelListWithChannels) return result;
            const { channels, ...channelList } = channelListWithChannels;
            result.channelLists.push(channelList);
            result.channelListChannelCount[channelList.id] = channels.totalCount ?? 0;
            return result;
        },
        { channelLists: [], channelListChannelCount: {} }
    );

/**
 * @memberof API.ChannelList
 * @description Loads all channel lists and the number of channels for each of them
 * @author SmartTVBG@a1.bg
 * @date 10/02/2023
 * @param profileId id of the currently used profile
 * @returns raw response of all channel lists data
 */
export const loadMany = async (profileId: GQL.ProfileID): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: ChannelListLoadManyQueryVariables = { profileId };
    const response: ChannelListLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelListLoadManyDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadMany(response.data);
};

export type LoadManyChannelRelationResponse = {
    channelListId: GQL.ChannelListID;
    edges: ChannelListChannelsEdgeFragment[];
};

/**
 * @memberof API.ChannelList
 * @description parses gql response to LoadManyChannelRelationResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info for relationship between channel list and its channels
 */
export const __parseLoadManyChannelRelation = (response?: ChannelListLoadManyChannelRelationFragment): LoadManyChannelRelationResponse => {
    const channelListId = response?.channelList?.id ?? '';
    const edges = (response?.channelList?.channels?.edges ?? []).filter(Boolean) as ChannelListChannelsEdgeFragment[];
    return { channelListId, edges };
};

/**
 * @memberof API.ChannelList
 * @description Loads channels relation edges for the specified channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param variables obj containing:
 * id of the chennel list and pagination data
 * @returns raw info for relationship between channel list and its channels
 */
export const loadManyChannelRelation = async (
    variables: ChannelListLoadManyChannelRelationQueryVariables
): Promise<LoadManyChannelRelationResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelListLoadManyChannelRelationQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelListLoadManyChannelRelationDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadManyChannelRelation(response.data);
};

export type LoadManyChannelEventResponse = {
    channelEvents: Record<GQL.ChannelID, GQL.Event[]>;
};

/**
 * @memberof API.ChannelList
 * @description parses gql response to LoadManyChannelEventResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info for channel events of a channel in channel list.
 */
export const __parseLoadManyChannelEvent = (response?: ChannelListLoadManyChannelEventFragment): LoadManyChannelEventResponse => {
    const channelEvents = (response?.channelList?.channels?.edges ?? []).reduce<Record<GQL.ChannelID, GQL.Event[]>>((result, entry) => {
        if (!entry?.node) return result;
        const events = (entry.node.events?.items ?? []).filter(Boolean) as GQL.Event[];
        return { ...result, [entry.node.id]: events };
    }, {});
    return { channelEvents };
};

/**
 * @memberof API.ChannelList
 * @description Loads Events for the requested channels from the specified channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param variables contains the channel list id
 * pagination data and image size data needed for the request
 * @returns raw info for channel events of a channel in channel list.
 */
export const loadManyChannelEvent = async (
    variables: ChannelListLoadManyChannelEventQueryVariables
): Promise<LoadManyChannelEventResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelListLoadManyChannelEventQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelListLoadManyChannelEventDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadManyChannelEvent(response.data);
};

export type LoadManyChannelEventPreciseResponse = {
    channelEvents: Record<GQL.ChannelID, GQL.Event[]>;
};

/**
 * @memberof API.ChannelList
 * @description parses gql response to LoadManyChannelEventPreciseResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info for time block of channel
 */
export const __parseLoadManyChannelPreciseEvent = (
    response?: ChannelListLoadManyChannelEventPreciseFragment
): LoadManyChannelEventPreciseResponse => {
    const channelEvents = (response?.channelList?.channels?.edges ?? []).reduce<Record<GQL.ChannelID, GQL.Event[]>>((result, entry) => {
        if (!entry?.node) return result;
        const events = (entry.node.eventsAt?.items ?? []).filter(Boolean) as GQL.Event[];
        return { ...result, [entry.node.id]: events };
    }, {});
    return { channelEvents };
};

/**
 * @memberof API.ChannelList
 * @description Loads Events for the requested channels from the specified channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param variables contains the
 * channel list id pagination data and image size data needed for the request
 * @returns raw info for time block of channel
 */
export const loadManyChannelEventPrecise = async (
    variables: ChannelListLoadManyChannelEventPreciseQueryVariables
): Promise<LoadManyChannelEventPreciseResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: ChannelListLoadManyChannelEventPreciseQueryResult = await GraphqlClient.makeGraphqlRequest(
        ChannelListLoadManyChannelEventPreciseDocument,
        variables,
        options
    );
    Error.collect(response);
    return __parseLoadManyChannelEvent(response.data);
};

export type RenameResponse = { channelList: GQL.ChannelList | null };

/**
 * @memberof API.ChannelList
 * @description parses gql response to RenameResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info after rename channel list request.
 */
export const __parseRename = (response?: ChannelListRenameFragment | null): RenameResponse => {
    const channelList = response?.changeChannelListName?.channelList ?? null;
    return { channelList };
};

/**
 * @memberof API.ChannelList
 * @description changes the name of the requested channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param channelListRenameInput input for the request
 * containing the id of the channel list and the new name
 * @returns raw info after rename channel list request.
 */
export const rename = async (channelListRenameInput: ChangeChannelListNameInput): Promise<RenameResponse> => {
    const variables: ChannelListRenameMutationVariables = { channelListRenameInput };
    const response: ChannelListRenameMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListRenameDocument, variables);
    Error.collect(response);
    return __parseRename(response.data);
};

export type ChangeResponse = { channelList: GQL.ChannelList | null };

/**
 * @memberof API.ChannelList
 * @description parses gql response to ChangeResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info after change channel list request.
 */
export const __parseChange = (response?: ChannelListChangeFragment | null): ChangeResponse => {
    const channelList = response?.changeInitialChannelList?.channelList ?? null;
    return { channelList };
};

/**
 * @memberof API.ChannelList
 * @description changes the active channel list for the requested profile
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param channelListChangeInput input that contains the profile id
 * and the channel list id
 * @returns raw info after rename channel list request.
 */
export const change = async (channelListChangeInput: ChangeInitialChannelListInput): Promise<ChangeResponse> => {
    const variables: ChannelListChangeMutationVariables = { channelListChangeInput };
    const response: ChannelListChangeMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListChangeDocument, variables);
    Error.collect(response);
    return __parseChange(response.data);
};

export type UpdateResponse = { channelList: GQL.ChannelList | null; totalCount: number };

/**
 * @memberof API.ChannelList
 * @description parses gql response to UpdateResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info after update channel list request.
 */
export const __parseUpdate = (response?: ChannelListUpdateFragment | null): UpdateResponse => {
    const channelList = response?.setChannelListChannels?.channelList ?? null;
    const totalCount = channelList?.channels.totalCount ?? 0;
    return { channelList, totalCount };
};

/**
 * @memberof API.ChannelList
 * @description updates the channels of the requested channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param channelListUpdateInput input containing id of the
 * channel list and the ids of the new channels
 * @returns raw info after rename channel list request.
 */
export const update = async (channelListUpdateInput: SetChannelListChannelsInput): Promise<UpdateResponse> => {
    const variables: ChannelListUpdateMutationVariables = { channelListUpdateInput };
    const response: ChannelListUpdateMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListUpdateDocument, variables);
    Error.collect(response);
    return __parseUpdate(response.data);
};

export type MoveResponse = { channelLists: GQL.ChannelList[] };

/**
 * @memberof API.ChannelList
 * @description parses gql response to MoveResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info after move channel list request.
 */
export const __parseMove = (response?: ChannelListMoveFragment | null): MoveResponse => {
    const channelLists = (response?.moveChannelList?.items ?? []).filter(Boolean) as GQL.ChannelList[];
    return { channelLists };
};

/**
 * @memberof API.ChannelList
 * @description chnages the position of the requested channel list
 * in relation of the other channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param variables input containing id
 * of the channel list and the new position in the list
 * @returns raw info after rename channel list request.
 */
export const move = async (variables: ChannelListMoveMutationVariables): Promise<MoveResponse> => {
    const response: ChannelListMoveMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListMoveDocument, variables);
    Error.collect(response);
    return __parseMove(response.data);
};

export type RemoveResponse = { channelLists: GQL.ChannelList[] };

/**
 * @memberof API.ChannelList
 * @description parses gql response to RemoveResponse
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param [response] response from gql
 * @returns parsed info after remove channel list request.
 */
export const __parseRemove = (response?: ChannelListRemoveFragment | null): RemoveResponse => {
    const channelLists = (response?.deleteChannelList?.items ?? []).filter(Boolean) as GQL.ChannelList[];
    return { channelLists };
};

/**
 * @memberof API.ChannelList
 * @description Removes a user created channel list
 * @author SmartTVBG@a1.bg
 * @date 13/02/2023
 * @param channelListId id of the requested channel list
 * @returns raw info after rename channel list request.
 */
export const remove = async (channelListId: GQL.ChannelListID): Promise<RemoveResponse> => {
    const variables: ChannelListRemoveMutationVariables = { channelListId };
    const response: ChannelListRemoveMutationResult = await GraphqlClient.makeGraphqlMutationRequest(ChannelListRemoveDocument, variables);
    Error.collect(response);
    return __parseRemove(response.data);
};
