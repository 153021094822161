import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import VODEpisode from '__SMART_APP_OLD__/data/VODEpisode';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

export default class VODSeason {
    episodes: VODAsset[] = [];

    assetType = AssetType.SEASON;

    rawData: any;

    constructor(data: any, allSeriesEpisodes: any[], factoryFunc: any) {
        this.rawData = data;

        if (data && allSeriesEpisodes?.length) {
            this.episodes = allSeriesEpisodes
                .filter((episode: any) => episode.node?.metadata?.episodeInfo?.season === data.number)
                .map((episode: any) => new VODEpisode(episode?.node, factoryFunc));
        }
    }

    get id() {
        return this.rawData?.id;
    }

    get ordinal() {
        return this.rawData?.number;
    }

    get isAdult() {
        return null;
    }

    get title() {
        return null;
    }

    get numberOfEpisodes() {
        return null;
    }

    get shortDescription() {
        return null;
    }

    get lastEpisode() {
        return null;
    }
}
