import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ReminderFragmentDoc } from '../fragments/reminder.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReminderCreateMutationVariables = Types.Exact<{
  reminderCreateInput: Types.SetReminderInput;
}>;


export type ReminderCreateMutation = { __typename?: 'Nexx4Mutations', setReminder: { __typename?: 'SetReminderPayload', reminder: { __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } } } };

export type ReminderCreateFragment = { __typename?: 'Nexx4Mutations', setReminder: { __typename?: 'SetReminderPayload', reminder: { __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } } } };

export const ReminderCreateFragmentDoc = gql`
    fragment reminderCreate on Nexx4Mutations {
  setReminder(input: $reminderCreateInput) {
    reminder {
      ...reminder
    }
  }
}
    ${ReminderFragmentDoc}`;
export const ReminderCreateDocument = gql`
    mutation reminderCreate($reminderCreateInput: SetReminderInput!) {
  ...reminderCreate
}
    ${ReminderCreateFragmentDoc}`;
export type ReminderCreateMutationFn = Apollo.MutationFunction<ReminderCreateMutation, ReminderCreateMutationVariables>;

/**
 * __useReminderCreateMutation__
 *
 * To run a mutation, you first call `useReminderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderCreateMutation, { data, loading, error }] = useReminderCreateMutation({
 *   variables: {
 *      reminderCreateInput: // value for 'reminderCreateInput'
 *   },
 * });
 */
export function useReminderCreateMutation(baseOptions?: Apollo.MutationHookOptions<ReminderCreateMutation, ReminderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReminderCreateMutation, ReminderCreateMutationVariables>(ReminderCreateDocument, options);
      }
export type ReminderCreateMutationHookResult = ReturnType<typeof useReminderCreateMutation>;
export type ReminderCreateMutationResult = Apollo.MutationResult<ReminderCreateMutation>;
export type ReminderCreateMutationOptions = Apollo.BaseMutationOptions<ReminderCreateMutation, ReminderCreateMutationVariables>;