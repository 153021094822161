import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client';

import { ParsedConfigServiceConfig } from '__SMART_APP_OLD__/app/modules/Config/types';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';

type VariablesOptions = {
    variables: Record<string, string | number | null | boolean | undefined>;
    fetchPolicy: FetchPolicy | WatchQueryFetchPolicy;
};

type SetDefaultVariables = <T extends VariablesOptions>(variablesOptions: T, config: ParsedConfigServiceConfig, id?: boolean) => T;

export const setDefaultVariables: SetDefaultVariables = (variablesOptions, config, includeProfileId = true) => {
    const { variables } = variablesOptions;

    includeProfileId && (variables.profileId = Profile.selectors.selectId(store.getState()));
    variables.channelLogoHeight = config.image.logo.large.height;
    variables.channelLogoWidth = config.image.logo.large.width;
    variables.thumbnailHeight = config.image.thumbnail.default.height;
    variables.backgroundHeight = config.image.background.landscape;
    variables.backgroundWidth = config.image.background.width;

    return variablesOptions;
};
