import React from 'react';

export type KeyLanguageIconProps = {};

export type KeyLanguageIconComponent = React.FunctionComponent<KeyLanguageIconProps>;

export const KeyLanguageIcon: KeyLanguageIconComponent = React.memo(() => (
    <svg className="key__icon key__icon--fill" width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.329 15.938a9.5 9.5 0 0 0-.383-3.75h-3.584a15.2 15.2 0 0 1-.001 5.624h5.534C24.647 22.666 20.243 26.25 15 26.25 8.787 26.25 3.75 21.213 3.75 15q0-.474.038-.937h1.883a9.5 9.5 0 0 0 .383 3.75h3.584a15.2 15.2 0 0 1 .001-5.625H4.104C5.354 7.335 9.758 3.75 15 3.75c6.213 0 11.25 5.037 11.25 11.25q0 .473-.038.938zm-9.327 8.437h-.012a15.1 15.1 0 0 1-2.753-4.687h5.522a15.2 15.2 0 0 1-2.757 4.687m2.75-.41a9.4 9.4 0 0 0 5.369-4.277h-3.24a16.4 16.4 0 0 1-2.129 4.277m5.369-13.652a9.4 9.4 0 0 0-5.362-4.276 16.3 16.3 0 0 1 2.126 4.276zm-8.11-4.688h-.014a15.2 15.2 0 0 0-2.756 4.688h5.522a15.1 15.1 0 0 0-2.752-4.688m-2.763.41a9.4 9.4 0 0 0-5.369 4.278h3.24a16.4 16.4 0 0 1 2.129-4.277M6.879 19.689a9.4 9.4 0 0 0 5.362 4.275 16.3 16.3 0 0 1-2.126-4.276zm11.414-1.875h-6.588a13.7 13.7 0 0 1 .002-5.625h6.588a13.7 13.7 0 0 1-.002 5.624"
        />
    </svg>
));

export const KeyLanguageIconInsance = <KeyLanguageIcon />;
