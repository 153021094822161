import React from 'react';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Subscribable } from 'App/Packages/Subscribable';
import { Card } from 'App/Packages/UI/Card';
import { FolderItem } from 'App/Packages/UI/Folder/Modules/FolderItem';
import { FolderItemProps } from 'App/Packages/UI/Folder/Modules/FolderItem/Types';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

type FolderCardLandscapeInternalProps = React.PropsWithChildren<{
    parentalRating?: string;
    sessionType?: PinSessionType;
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
}>;

const FolderCardLandscapeInternal: React.FunctionComponent<FolderCardLandscapeInternalProps> = (props) => {
    const item = FolderItem.use();
    const isFocused = Subscribable.use(item.focused);

    const onFocus = (id: string) => {
        item.scrollIntoView();
        if (props.onFocus) props.onFocus(id);
    };

    const { onClick, onHover } = useFocusableHook({
        id: item.id,
        ctx: item.ctx,
        isFocused,
        onEnter: item.enter,
        onHover: item.focus,
        onBlur: props.onBlur,
        onFocus,
    });

    return (
        <Card.Landscape
            ref={item.ref}
            className="folder-item"
            isFocused={isFocused}
            parentalRating={props.parentalRating}
            sessionType={props.sessionType}
            onClick={onClick}
            onHover={onHover}
        >
            {props.children}
        </Card.Landscape>
    );
};

export type FolderCardLandscapeProps = FolderCardLandscapeInternalProps & FolderItemProps;

export type FolderCardLandscapeComponent = React.FunctionComponent<FolderCardLandscapeProps>;

export const FolderCardLandscape: FolderCardLandscapeComponent = (props) => (
    <FolderItem id={props.id} offset={props.offset} onEnter={props.onEnter}>
        <FolderCardLandscapeInternal parentalRating={props.parentalRating} onBlur={props.onBlur} onFocus={props.onFocus}>
            {props.children}
        </FolderCardLandscapeInternal>
    </FolderItem>
);
