import { ButtonIconFlavour } from '__SMART_APP_OLD__/app/components/Button/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { LabelPayload } from '__SMART_APP_OLD__/app/components/Label';
import {
    MessageInboxAction,
    MessageInboxActionID,
    MessageInboxItemAction,
    MessageInboxItemActionID,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';

export const MESSAGE_INBOX_ACTION_FOR_ACTION_ID: Record<MessageInboxActionID, MessageInboxAction> = {
    [MessageInboxActionID.FILTER]: {
        id: MessageInboxActionID.FILTER,
        label: 'FILTER',
        icon: IconName.DOWNLOAD,
        flavour: ButtonIconFlavour.FANCY,
    },
    [MessageInboxActionID.SELECT]: {
        id: MessageInboxActionID.SELECT,
        label: 'SELECT',
        icon: IconName.SETTINGS,
        flavour: ButtonIconFlavour.FANCY,
    },
    [MessageInboxActionID.SELECT_ALL]: {
        id: MessageInboxActionID.SELECT_ALL,
        label: 'SELECT_ALL',
        icon: null,
        flavour: null,
    },
    [MessageInboxActionID.DELETE]: {
        id: MessageInboxActionID.DELETE,
        label: 'MESSAGE_INBOX_DELETE',
        icon: IconName.DELETE,
        flavour: ButtonIconFlavour.DEFAULT,
    },
    [MessageInboxActionID.MARK_AS_READ]: {
        id: MessageInboxActionID.MARK_AS_READ,
        label: 'MESSAGE_INBOX_MARK_AS_READ',
        icon: null,
        flavour: null,
    },
};

export const MESSAGE_INBOX_ITEM_ACTION_FOR_ACTION_ID: Record<MessageInboxItemActionID, MessageInboxItemAction> = {
    [MessageInboxItemActionID.READ_MESSAGE]: {
        id: MessageInboxItemActionID.READ_MESSAGE,
        label: 'READ_MESSAGE',
        icon: IconName.MESSAGING,
        flavour: ButtonIconFlavour.FANCY,
    },
    [MessageInboxItemActionID.DELETE]: {
        id: MessageInboxItemActionID.DELETE,
        label: 'DELETE',
        icon: IconName.DELETE,
        flavour: ButtonIconFlavour.DEFAULT,
    },
};

export const MESSAGE_INBOX_DEFAULT_ACTION_IDS = [MessageInboxActionID.FILTER, MessageInboxActionID.SELECT];
export const MESSAGE_INBOX_FILTER_ACTION_IDS = [MessageInboxActionID.SELECT];
export const MESSAGE_INBOX_SELECT_ACTION_IDS = [
    MessageInboxActionID.SELECT_ALL,
    MessageInboxActionID.MARK_AS_READ,
    MessageInboxActionID.DELETE,
];

export const MESSAGE_INBOX_ITEM_ACTION_IDS = [MessageInboxItemActionID.READ_MESSAGE, MessageInboxItemActionID.DELETE];

export const UNREAD_MESSAGE_LABEL: LabelPayload = { color: BackgroundColor.HOT_RED_PRIMARY, text: 'UNREAD' };
