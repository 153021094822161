import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum PromptActionType {
    Changed = '@prompt/changed',
}

export type PromptReduxAction = PromptChangedAction;
export type PromptChangedActionPayload = { prompt: PromptState };
export type PromptChangedAction = PayloadedAction<PromptActionType.Changed, PromptChangedActionPayload>;

export enum PromptType {
    DeviceConfirmDelete = 'DEVICE_CONFIRM_DELETE',
    RecordingManagementConfirmDelete = 'RECORDING_MANAGEMENT_CONFIRM_DELETE',
    RecordingConfirmDelete = 'RECORDING_CONFIRM_DELETE',
    RecordingConfirmCreate = 'RECORDING_CONFIRM_CREATE',
    RecordingConfirmCreateSeries = 'RECORDING_CONFIRM_CREATE_SERIES',
    RecordingConfirmCancel = 'RECORDING_CONFIRM_CANCEL',
    RecordingConfirmCancelSeries = 'RECORDING_CONFIRM_CANCEL_SERIES',
    MessageConfirmDelete = 'MESSAGE_CONFIRM_DELETE',
    AppExitPrompt = 'APP_EXIT_PROMPT',
    ChannelListConfirmDelete = 'ChannelListConfirmDelete',
    Logout = 'LOGOUT',
}

export type PromptState = PromptEntity | null;

export type PromptEntity<T extends PromptType = PromptType> = {
    type: T;
    ctx: string;
    props: PromptProps<T>;
};

export type PromptProps<T extends PromptType = PromptType> = T extends PromptType.DeviceConfirmDelete
    ? DeviceConfirmDeletePromptProps
    : T extends PromptType.RecordingManagementConfirmDelete
      ? RecordingManagementConfirmDeletePromptProps
      : T extends PromptType.MessageConfirmDelete
        ? MessageConfirmDeletePromptProps
        : T extends PromptType.Logout
          ? LogoutPromptProps
          : T extends PromptType.ChannelListConfirmDelete
            ? ChannelListConfirmDeleteProps
            : T extends PromptType.RecordingConfirmCreate
              ? RecordingConfirmCreatePromptProps
              : T extends PromptType.RecordingConfirmCreateSeries
                ? RecordingConfirmCreatePromptProps
                : T extends PromptType.RecordingConfirmDelete
                  ? RecordingConfirmDeletePromptProps
                  : T extends PromptType.RecordingConfirmCancel
                    ? RecordingConfirmDeletePromptProps
                    : T extends PromptType.RecordingConfirmCancelSeries
                      ? RecordingConfirmCancelSeriesProps
                      : T extends PromptType.AppExitPrompt
                        ? AppExitPromptProps
                        :
                              | DeviceConfirmDeletePromptProps
                              | RecordingManagementConfirmDeletePromptProps
                              | MessageConfirmDeletePromptProps
                              | ChannelListConfirmDeleteProps
                              | LogoutPromptProps;

type DeviceConfirmDeletePromptProps = {
    deviceId: string;
};

type RecordingConfirmCreatePromptProps = {
    eventId: string;
};

type RecordingConfirmDeletePromptProps = {
    recordingId: string;
};

type RecordingConfirmCancelSeriesProps = {
    eventId: string;
};

type RecordingManagementConfirmDeletePromptProps = {
    recordingIds: string[];
};

type MessageConfirmDeletePromptProps = {
    messageIds: string[];
};

type ChannelListConfirmDeleteProps = {
    id: string;
};

type LogoutPromptProps = {};

type AppExitPromptProps = {};
