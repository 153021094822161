import { batch } from 'react-redux';

import { API } from '__SMART_APP_OLD__/app/api';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import {
    selectIsMessageScreenPermittedToShow,
    selectMessageEntityTableTriggerTSStack,
    selectMessageHeader,
    selectMessageIcon,
    selectMessageNotifInfo,
    selectMessageText,
    selectMessagesByTriggerTS,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';
import {
    MessageLoadManyAction,
    MessageLoadOneAction,
    MessageReadManyAction,
    MessageRemoveManyAction,
    MessageTriggeredAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/types';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { notificationShow } from '__SMART_APP_OLD__/app/modules/Notification/shared/actions';
import { MESSAGE_NOTIFICATION_ACTIONS } from '__SMART_APP_OLD__/app/modules/Notification/constants';
import { NotificationIconType, NotificationType, NotificationVisibilityStatus } from '__SMART_APP_OLD__/app/modules/Notification/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { Utils } from '__SMART_APP_OLD__/app/utils';

import { Cursor } from 'App/Packages/Cursor';

export const messageLoadOne = (message: GQL.Message): MessageLoadOneAction => ({
    type: ActionType.MESSAGE_LOAD_ONE,
    payload: { message },
});

export const messageLoadMany = (messages: GQL.Message[]): MessageLoadManyAction => ({
    type: ActionType.MESSAGE_LOAD_MANY,
    payload: { messages },
});

export const messageReadMany = (messageIds: GQL.MessageID[]): MessageReadManyAction => ({
    type: ActionType.MESSAGE_READ_MANY,
    payload: { messageIds },
});

export const messageRemoveMany = (messageIds: GQL.MessageID[]): MessageRemoveManyAction => ({
    type: ActionType.MESSAGE_REMOVE_MANY,
    payload: { messageIds },
});

export const messageTriggerOne = (messageId: GQL.MessageID): MessageTriggeredAction => ({
    type: ActionType.MESSAGE_TRIGGER_ONE,
    payload: { messageId, ts: Date.now() },
});

export const messageLoadById =
    (messageId: GQL.MessageID): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const response = await API.Message.loadOne({
                messageId,
                messageAttachmentImageHeight: config.image.background.landscape,
                messageAttachmentImageWidth: config.image.background.width,
                messageBackgroundHeight: config.image.background.landscape,
                messageBackgroundWidth: config.image.background.width,
                messageIconHeight: config.image.logo.default.height,
                messageIconWidth: config.image.logo.default.width,
            });
            const message = response?.message;
            dispatch(messageLoadOne(message));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.MessageLoad, error));
            return false;
        }
    };

export const messageLoad =
    (loadTotalCountResponse?: API.Message.LoadTotalCountResponse): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const profileId = Profile.selectors.selectId(state);
            const config = selectConfig(state);
            const totalCountResponse = loadTotalCountResponse ?? (await API.Message.loadTotalCount({ profileId }));
            const batches = Cursor.batch(0, totalCountResponse.totalCount);
            const responses = batches.map((pagination) =>
                API.Message.loadMany({
                    profileId,
                    messageFilter: {},
                    messagePagination: pagination,
                    messageAttachmentImageHeight: config.image.background.landscape,
                    messageAttachmentImageWidth: config.image.background.width,
                    messageBackgroundHeight: config.image.background.landscape,
                    messageBackgroundWidth: config.image.background.width,
                    messageIconHeight: config.image.logo.default.height,
                    messageIconWidth: config.image.logo.default.width,
                })
            );
            const messages = (await Utils.Promise.allFulfilled(responses)).reduce<GQL.Message[]>(
                (acc, res) => [...acc, ...res.messages],
                []
            );
            dispatch(messageLoadMany(messages));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.MessageLoad, error));
            return false;
        }
    };

export const messageRead =
    (messageIds: GQL.MessageID[]): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const profileId = Profile.selectors.selectId(state);
            const promises = messageIds.map((messageId) => API.Message.read({ messageReadInput: { profileId, messageId } }));
            await Utils.Promise.allFulfilled(promises);
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.MessageRead, error));
            return false;
        } finally {
            dispatch(messageReadMany(messageIds));
        }
    };

export const messageRemove =
    (messageIds: GQL.MessageID[]): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const profileId = Profile.selectors.selectId(state);
            const promises = messageIds.map((messageId) => API.Message.remove({ messageRemoveInput: { profileId, messageId } }));
            await Utils.Promise.allFulfilled(promises);
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.MessageRemove, error));
            return false;
        } finally {
            dispatch(messageRemoveMany(messageIds));
        }
    };

export const messageSchedule =
    (triggerTS: number): Thunk<number | null> =>
    (dispatch, getState) => {
        const state = getState();
        const triggerTSStack = selectMessageEntityTableTriggerTSStack(state);
        if (!triggerTSStack.length) return null;
        const index = triggerTSStack.indexOf(triggerTS);
        const nextTriggerTS = triggerTSStack[index + 1] ?? null;
        if (index === -1) return nextTriggerTS;
        const messageIds = selectMessagesByTriggerTS(triggerTS)(state);
        if (!messageIds.length) return nextTriggerTS;
        batch(() => {
            messageIds
                .filter((id) => {
                    const notifInfo = selectMessageNotifInfo(id)(state);
                    const isPermitted = selectIsMessageScreenPermittedToShow(notifInfo.screens);
                    return isPermitted;
                })
                .forEach((id) => {
                    const notifInfo = selectMessageNotifInfo(id)(state);
                    const title = selectMessageHeader(id)(state);
                    const subtitle = selectMessageText(id)(state);
                    const icon = selectMessageIcon(id)(state);

                    dispatch(
                        notificationShow({
                            id,
                            type: NotificationType.MESSAGE,
                            status: NotificationVisibilityStatus.ANIMATE_IN,
                            title,
                            subtitle,
                            actions: MESSAGE_NOTIFICATION_ACTIONS,
                            timer: notifInfo.timeout,
                            icon: icon || NotificationIconType.MESSAGE,
                        })
                    );
                });
        });
        return nextTriggerTS;
    };
