import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { EventActionTypes, DetailEventState } from 'App/Modules/Data/Detail/Event/Types';
import { initialEventDetailReducerState } from 'App/Modules/Data/Detail/Event/Store/initialState';

export const detailEventReducer: Reducer<DetailEventState> = (state, action) => {
    if (!state) return initialEventDetailReducerState;

    switch (action.type) {
        case EventActionTypes.Load: {
            return {
                item: action.payload.item,
                assetObject: action.payload.assetObject,
                folders: action.payload.folders,
            };
        }
        case EventActionTypes.Reset:
            return initialEventDetailReducerState;
        default:
            return state;
    }
};
