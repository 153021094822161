import React, { useCallback, useEffect } from 'react';

import StaticProgressBar from '__SMART_APP_OLD__/components/common/StaticProgressBar';

const LiveProgressBar = ({ startTime, endTime, className = '' }) => {
    const updateInterval = React.useRef(0);
    const startTimeTimer = React.useRef(0);
    const updateIntervalTimer = React.useRef(0);
    const duration = endTime - startTime;

    const getPercentage = useCallback(() => {
        if (endTime <= new Date().getTime()) return null;

        return ((new Date().getTime() - startTime) * 100) / duration;
    }, [duration, endTime, startTime]);

    const [progress, setProgress] = React.useState(getPercentage);

    const calcProgress = useCallback(() => {
        if (endTime <= new Date().getTime()) {
            // program has ended, stop interval, hide progressbar
            clearInterval(updateInterval.current);
            setProgress(null);
        } else {
            setProgress(getPercentage());
        }
    }, [endTime, getPercentage]);

    const startLiveUpdate = useCallback(() => {
        setProgress(getPercentage());

        // currently running, progress bar needs to update each minute
        clearInterval(updateInterval.current);

        // interval should start at exact minute, to avoid delay of seconds
        const diffTilEnd = endTime - new Date().getTime();

        clearTimeout(updateIntervalTimer.current);
        updateIntervalTimer.current = setTimeout(() => {
            clearInterval(updateInterval.current);
            updateInterval.current = setInterval(() => {
                calcProgress();
            }, 10 * 1000);
            calcProgress();
        }, diffTilEnd % (60 * 1000));
    }, [calcProgress, endTime, getPercentage]);

    useEffect(() => {
        const now = new Date().getTime();

        if (endTime <= now) {
            // if past program, no need for live progress bar at all
            setProgress(null);
        }

        if (startTime <= now && endTime > now) {
            startLiveUpdate();
        }

        if (startTime > now) {
            // future program, have to see when it gets live
            const distance = startTime - now;
            clearTimeout(startTimeTimer.current);
            startTimeTimer.current = setTimeout(() => {
                clearTimeout(startTimeTimer.current);
                startLiveUpdate();
            }, distance);
        }

        return () => {
            clearInterval(updateInterval.current);
            clearTimeout(startTimeTimer.current);
            clearTimeout(updateIntervalTimer.current);
        };
    }, [startTime, endTime, startLiveUpdate]);

    if (!startTime || !endTime) {
        return null;
    }

    return <StaticProgressBar percentage={progress} className={className} isLive={true} />;
};

export default LiveProgressBar;
