import {
    RecordingDetailLoadDocument,
    RecordingDetailLoadQueryResult,
} from 'App/Modules/Data/Detail/NetworkRecording/Api/networkRecordingDetailLoad.generated';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadVariables = {
    recordingId: string;
    profileId: string;
    folderFirst: number;
    folderAfter: string | null;
    folderListFirst: number;
    folderListAfter: string | null;
    thumbnailHeight: number;
    backgroundWidth: number;
    backgroundHeight: number;
};

const __load = async (variables: LoadVariables) => {
    const response: RecordingDetailLoadQueryResult = await GraphqlClient.makeGraphqlRequest(RecordingDetailLoadDocument, variables, {
        fetchPolicy: 'no-cache',
    });

    Error.collect(response);
    return response.data || null;
};

export const load = async (variables: LoadVariables) => {
    const response = await __load(variables);
    return response;
};
