import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

import { initialDetailSeriesState } from 'App/Modules/Data/Detail/Series/Store/initialState';
import { SeriesActionTypes, SeriesState } from 'App/Modules/Data/Detail/Series/Types';

export const detailSeriesReducer: Reducer<SeriesState> = (state, action) => {
    if (!state) return initialDetailSeriesState;

    switch (action.type) {
        case SeriesActionTypes.Load:
            return {
                ...state,
                item: action.payload.item,
                assetObject: action.payload.assetObject,
                items: action.payload.items,
                groupTitles: action.payload.groupTitles,
                itemIdsForGroupTitle: action.payload.itemIdsForGroupTitle,
                selectedGroupTitle: action.payload.selectedGroupTitle,
                screenBackgroundImageUrl: action.payload.screenBackgroundImageUrl,
            };
        case SeriesActionTypes.FolderHeaderSelected:
            return {
                ...state,
                selectedGroupTitle: action.payload,
            };
        case SeriesActionTypes.ItemFocusChanged:
            if (!state.items) return state;
            return {
                ...state,
                item: state.items.entities[action.payload],
            };
        case SeriesActionTypes.Reset:
            return initialDetailSeriesState;
        default:
            return state;
    }
};
