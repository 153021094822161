import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';

export default class Button extends React.PureComponent {
    nodeRef = null;

    focused = false;

    constructor(props) {
        super(props);
        this.state = { focused: false };
    }

    onFocus = () => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus();
        }

        this.focused = true;
        this.setState({ focused: true });
    };

    onBlur = () => {
        this.focused = false;
        this.setState({ focused: false });
    };

    renderIcon = () => {
        const { icon } = this.props;
        if (icon) {
            return <Icon className="button__icon" name={icon} type={IconType.SOLID} isFocused={this.focused} />;
        }
        return null;
    };

    render() {
        const {
            id,
            className,
            onEnter,
            onKey,
            onCustomRect,
            label,
            stashId,
            dataTestId,
            scrollTop,
            backgroundClassName = '',
            size,
            children,
        } = this.props;

        const btnClassName = `button ${className || ''}   ${backgroundClassName || ''}`;

        return (
            <Focusable
                id={id}
                className={btnClassName}
                onEnter={onEnter}
                onKey={onKey}
                onCustomRect={onCustomRect}
                scrollTop={scrollTop}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                dataTestId={dataTestId || `button_${label?.toLowerCase().split(' ').join('_')}`}
                ref={(node) => {
                    this.nodeRef = node;
                }}
                stashId={stashId}
                focusDelay={false}
            >
                {this.renderIcon()}
                <Text
                    className="button-text"
                    typeface={Typeface.SERIF}
                    size={size ?? FontSize.BODY_1}
                    weight={FontWeight.BOLD}
                    color={Color.CUSTOM}
                >
                    {label}
                </Text>
                {children}
            </Focusable>
        );
    }
}
