import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import { formatDayName } from '__SMART_APP_OLD__/utils/timeUtils';

interface Props {
    time: number;
}

export const ProgramInfo: React.FunctionComponent<Props> = React.memo((props) => (
    <div className="program-info-container">
        <Text typeface={Typeface.SANS} size={FontSize.BODY_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
            <InnerHTML
                className="program-info"
                html={formatDayName(new Date(props.time), 'DD dd MMM &nbsp;&nbsp;|&nbsp;&nbsp;HH:mm', ' ', true)}
            />
        </Text>
    </div>
));
