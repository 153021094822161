import React, { useCallback } from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ButtonAnimation, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button/Button';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageInboxItemActionSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import {
    MESSAGE_INBOX_ITEM_ACTION_FOR_ACTION_ID,
    MESSAGE_INBOX_ITEM_ACTION_IDS,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/constants';
import { selectMessageInboxIsSelectMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';
import { MessageInboxCTX, MessageInboxItemActionID } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

interface Props {
    id: GQL.MessageID;
    isFocused: boolean;
}

export const MessageInboxItemActions: React.FunctionComponent<Props> = (props) => {
    const dispatch = useDispatch();
    const isSelectMode = useSelector(selectMessageInboxIsSelectMode);

    const { focused, isDisabled, focus } = Focus.useList({
        ctx: MessageInboxCTX.LIST,
        axis: Axis.X,
        items: MESSAGE_INBOX_ITEM_ACTION_IDS,
        disabled: !props.isFocused || isSelectMode,
        disableOutOfBounds: true,
        resetFocusOnBlur: true,
    });

    const enterHandler = useCallback(
        (action: MessageInboxItemActionID) => {
            dispatch(messageInboxItemActionSelected(props.id, action));
        },
        [props.id, dispatch]
    );

    return (
        <>
            {MESSAGE_INBOX_ITEM_ACTION_IDS.map((id) => (
                <Button
                    key={id}
                    id={id}
                    ctx={MessageInboxCTX.LIST}
                    className="message-inbox-item__action"
                    width={ButtonWidth.FIT}
                    animation={ButtonAnimation.NONE}
                    icon={MESSAGE_INBOX_ITEM_ACTION_FOR_ACTION_ID[id].icon}
                    iconFlavour={MESSAGE_INBOX_ITEM_ACTION_FOR_ACTION_ID[id].flavour}
                    corners={ButtonCornersStyle.ROUNDED}
                    isFocused={focused === id && !isDisabled && props.isFocused}
                    // disableTransition
                    onEnter={enterHandler}
                    onHover={focus}
                >
                    {MESSAGE_INBOX_ITEM_ACTION_FOR_ACTION_ID[id].label}
                </Button>
            ))}
        </>
    );
};
