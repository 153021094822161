import React from 'react';

import { Constants } from 'App/Packages/UI/Card/CardBanner/Constants';
import { Image, ObjectFit, Orientation } from 'App/Packages/UI/Image';
import { Mask } from 'App/Packages/UI/Mask';

const getClassName = (props: CardBannerThumbnailProps) => {
    const classList = ['banner-card-v3__thumbnail'];
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export type CardBannerThumbnailProps = {
    className?: string;
    src?: string;
};

export type CardBannerThumbnailComponent = React.FunctionComponent<CardBannerThumbnailProps>;

export const CardBannerThumbnail: CardBannerThumbnailComponent = (props) => (
    <Mask>
        {(shouldMask) => (
            <Image className={getClassName(props)}>
                <Image.Loading hidden={shouldMask} />
                <Image.Cover hidden={shouldMask} />
                <Image.Adult orientation={Orientation.Landscape} hidden={!shouldMask} />
                <Image.Img
                    src={props.src}
                    objectFit={ObjectFit.Cover}
                    width={Constants.Thumbnail.Width}
                    height={Constants.Thumbnail.Height}
                    hidden={shouldMask}
                />
            </Image>
        )}
    </Mask>
);
