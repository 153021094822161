import { DetailRecordingState } from 'App/Modules/Data/Detail/NetworkRecording/Types/Types';

export const initialRecordingDetailReducerState: DetailRecordingState = {
    item: null,
    assetObject: null,
    folders: {
        id: '',
        count: 0,
        ids: [],
        entities: {},
    },
};
