import {
    PinActiveStatusChangedAction,
    PinActiveStatusLiveTvChangedAction,
    PinBlockedChangedAction,
    PinForceProtectionChangedAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';

export const pinActiveStatusChanged = (isActive: boolean): PinActiveStatusChangedAction => ({
    type: ActionType.PIN_ACTIVE_STATUS_CHANGED,
    payload: { isActive },
});

export const pinActiveStatusLiveTvChanged = (isLiveTvActive: boolean): PinActiveStatusLiveTvChangedAction => ({
    type: ActionType.PIN_ACTIVE_STATUS_LIVE_TV_CHANGED,
    payload: { isLiveTvActive },
});

export const pinBlockedChanged = (time: number): PinBlockedChangedAction => ({
    type: ActionType.PIN_BLOCKED_CHANGED,
    payload: { time },
});

export const pinForceProtectionChanged = (forceProtection: boolean): PinForceProtectionChangedAction => ({
    type: ActionType.PIN_FORCE_PROTECTION_CHANGED,
    payload: { forceProtection },
});
