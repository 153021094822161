import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { AssetType, PinAction, Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

import { Folder } from 'App/Modules/Folder';
import { Overlay } from 'App/Modules/Overlay';
import { RootFolder } from 'App/Modules/RootFolder';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { MenuIconItemType, MenuItemType } from 'App/Types/Menu';

const mount: Screen.LifecycleMethod<Screen.Type.VideoStore> = (props) => async (dispatch) => {
    await dispatch(Overlay.actions.noFocus.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(RootFolder.actions.load());
    if (props.focusedFolderId === undefined && props.focusedItemId === undefined && props.focusedHeaderId !== undefined) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.VideoStore, UI.Header.Constants.Ctx));
    } else {
        dispatch(Screen.actions.ctx.changed(Screen.Type.VideoStore, UI.Folder.Constants.Ctx));
    }
    await Function.sleep(1500);
    await dispatch(Overlay.actions.unmount());
};

const unmount: Screen.LifecycleMethod<Screen.Type.VideoStore> = () => async (dispatch) => {
    dispatch(RootFolder.actions.reset());
    await dispatch(Overlay.actions.unmount());
};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === UI.Header.Constants.Ctx && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.VideoStore, UI.Folder.Constants.Ctx));
    }
    if (ctx === UI.Folder.Constants.Ctx && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.VideoStore, UI.Header.Constants.Ctx));
    }
    return undefined;
};

const lifecycle: Partial<Screen.Lifecycle<Screen.Type.VideoStore>> = {
    mount,
    unmount,
    outOfBounds,
};

const toDetailPage =
    (
        item: Folder.Item,
        payload: {
            focusedFolderId: string;
            focusedItemId: string;
        }
    ): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        if (item.type === ContentItemType.Networkrecording && selectRecordingStatus(item.id)(state) === GQL.RecordingStatus.Failed) {
            dispatch(textNotificationShow('NOTIFICATION_FAILED_RECORDING_NO_DETAIL_PAGE'));
            return;
        }
        const assetType = assetTypeForContentItemType[item.type];
        if (!assetType) return;
        const isFolder = assetType === AssetType.VOD_FOLDER;
        const path = isFolder ? `/page/${Route.VOD_FOLDER}/${item.id}` : `/details/${assetType}/${item.id}`;
        if (!isFolder) {
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: item.id }));
        }
        await dispatch(Screen.actions.update(Screen.Type.VideoStore, payload));
        dispatch(History.actions.push());
        dispatch(Screen.actions.unmount());

        if (isFolder) {
            dispatch(Screen.actions.mount(Screen.Type.VodFolders, { folderId: item.id }));
            return;
        }

        dispatch(
            Screen.actions.mount(Screen.Type.Detail, {
                id: item.id,
                type: item.type,
                oldOperation: () => CustomHistory.go(path),
            })
        );
    };

const selected =
    (folderId: string, itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const payload = { focusedFolderId: folderId, focusedItemId: itemId };
        if (itemId === UI.Folder.Constants.Item.Id.More) {
            await dispatch(Screen.actions.update(Screen.Type.VideoStore, payload));
            dispatch(History.actions.push());
            dispatch(Screen.actions.mount(Screen.Type.COLLECTION, { id: folderId }));
            return;
        }

        const state = getState();
        const folderSelector = RootFolder.selectors.selectFolder(folderId);
        const itemSelector = Folder.selectors.selectItem(folderSelector)(itemId);
        const item = itemSelector(state);
        if (!item) return;
        const assetType = assetTypeForContentItemType[item.type];

        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, PinSessionType.PIN)(state);

        if (!item) return;

        if (!isRestricted) {
            dispatch(toDetailPage(item, payload));
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => dispatch(toDetailPage(item, payload)),
            () => dispatch(Overlay.actions.unmount()),
            PinAction.ENTER,
            PinSessionType.PIN,
            assetType === AssetType.VOD_FOLDER // @note Adult folder shouldn't start a session
        );
    };

const headerSelected =
    (id: MenuItemType | MenuIconItemType): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(
            Screen.actions.update(Screen.Type.VideoStore, {
                focusedHeaderId: id,
                focusedFolderId: undefined,
                focusedItemId: undefined,
            })
        );

export const actions = {
    lifecycle,
    public: {},
    private: {
        header: {
            selected: headerSelected,
        },
        selected,
    },
};
