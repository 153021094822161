import { Registry } from 'App/Modules/Overlay/Registry';
import { actions as overlayActions } from 'App/Modules/Overlay/Store/actions';
import { selectors as overlaySelectors } from 'App/Modules/Overlay/Store/selectors';

export { generator } from 'App/Modules/Overlay/Store/generator';
export { getInitialState } from 'App/Modules/Overlay/Store/getInitialState';
export { reducer } from 'App/Modules/Overlay/Store/reducer';
export { OverlayActionType as ActionType, SelectOverlayTarget as SelectTarget, OverlayType as Type } from 'App/Modules/Overlay/Types';
export type {
    OverlayChangedAction as ChangedAction,
    OverlayChangedActionPayload as ChangedActionPayload,
    OverlayEntity as Entity,
    OverlayProps as Props,
} from 'App/Modules/Overlay/Types';
export { View } from 'App/Modules/Overlay/View';

export const zIndex = 100;
export const actions = overlayActions.public;
export const selectors = overlaySelectors.public;
export const { register } = Registry;
