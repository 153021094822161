import { API } from '__SMART_APP_OLD__/app/api';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    DeviceLoadManyAction,
    DeviceRemoveOneAction,
    MaxNumberOfUnmanagedDevicesChangeAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

export const deviceLoadMany = (devices: GQL.Device[]): DeviceLoadManyAction => ({
    type: ActionType.DEVICE_LOAD_MANY,
    payload: { devices },
});

export const deviceRemoveOne = (deviceId: GQL.DeviceID): DeviceRemoveOneAction => ({
    type: ActionType.DEVICE_REMOVE_ONE,
    payload: { deviceId },
});

export const maxNumberOfUnmanagedDevicesChange = (maxNumberOfUnmanagedDevices: number): MaxNumberOfUnmanagedDevicesChangeAction => ({
    type: ActionType.MAX_NUMBER_OF_UNMANAGED_DEVICES_CHANGE,
    payload: { maxNumberOfUnmanagedDevices },
});

export const deviceLoad = (): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        const response = await API.Device.loadMany();
        dispatch(deviceLoadMany(response.devices));
        dispatch(maxNumberOfUnmanagedDevicesChange(response.maxNumberOfUnmanagedDevices));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.DeviceLoad, error));
        return false;
    }
};

export const deviceRemove =
    (targetId: string): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        try {
            await API.Device.remove(targetId);
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.DeviceRemove, error));
            return false;
        } finally {
            dispatch(deviceRemoveOne(targetId));
        }
    };
