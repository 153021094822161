import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import translate from 'language/translate';

import { selectIsSubtitlesEditable, selectSubFontStyles } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Hidable } from 'App/Packages/UI/Hidable';
import { actions } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Actions';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useCtx, useList } from 'App/Packages/Focus/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';
import { Axis } from 'App/Types/Axis';
import { SubFontStyleOption } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/SubFontStyleOption';
import { Overlay } from 'App/Modules/Overlay';

type Props = {
    fontColor: string;
    fontSize: string;
    backgroundColor: string;
    previewTextFontColor: string;
    previewTextBackgroundColor: string;
    playerSetFontColor: (value: string) => void;
    playerSetBackgroundColor: (value: string) => void;
    playerSetEdgeType: (value: string) => void;
};

type SubtitleFontStylesComponent = React.FunctionComponent<Props>;

export const SubtitleFontStyles: SubtitleFontStylesComponent = (props) => {
    const {
        fontColor,
        backgroundColor,
        previewTextFontColor,
        previewTextBackgroundColor,
        fontSize,
        playerSetFontColor,
        playerSetBackgroundColor,
        playerSetEdgeType,
    } = props;
    const isSubtitlesEditable = useSelector(selectIsSubtitlesEditable);
    const subFontStyleOptions = useSelector(selectSubFontStyles);

    const dispatch = useDispatch();
    const ctx = useCtx();
    const { focus, focused } = useList({
        ctx: Constants.Ctx.fontStyles,
        axis: Axis.X,
        items: subFontStyleOptions.map((option) => option.variant),
    });

    const updatePreviewTextOnFocus = (variant: string) => {
        const subElement = subFontStyleOptions.find((option) => option.variant === variant);
        if (!subElement) return undefined;
        dispatch(
            Overlay.actions.update(Overlay.Type.SubtitleMenu, {
                previewTextFontColor: subElement.color,
                previewTextBackgroundColor: subElement.bgColor,
                previewTextFontSize: fontSize,
                edgeType: subElement.edgeType,
            })
        );
        return focus(subElement.variant);
    };

    const fontStyles = subFontStyleOptions.map((option, index: number) => {
        return (
            <SubFontStyleOption
                key={index}
                isActive={option.color === fontColor && option.bgColor === backgroundColor}
                isFocused={focused === option.variant && ctx === Constants.Ctx.fontStyles}
                variant={option.variant}
                focus={updatePreviewTextOnFocus}
                onEnter={() =>
                    dispatch(
                        actions.private.updateBackgroundAndColor(
                            option.color,
                            option.bgColor,
                            option.variant,
                            option.edgeType,
                            playerSetFontColor,
                            playerSetBackgroundColor,
                            playerSetEdgeType
                        )
                    )
                }
            />
        );
    });

    const currentlySelectedTextOption = subFontStyleOptions.find(
        (element) => element.color === fontColor && element.bgColor === backgroundColor
    );
    const currentlyHoveredTextOption = subFontStyleOptions.find(
        (element) => element.color === previewTextFontColor && element.bgColor === previewTextBackgroundColor
    );

    const selectedLabel = currentlySelectedTextOption ? currentlySelectedTextOption.name : '';
    const hoveredLabel = currentlyHoveredTextOption ? currentlyHoveredTextOption.name : '';

    return (
        <Hidable hidden={!subFontStyleOptions.length}>
            <div className="text-options-container">
                <Text
                    className={`text-options-title ${!isSubtitlesEditable ? 'padLock' : ''}`}
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_2}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {`${translate('SUB_STYLE_LABEL')}: ${translate(hoveredLabel || selectedLabel)}`}
                </Text>
                <div className="text-options">{fontStyles}</div>
            </div>
        </Hidable>
    );
};
