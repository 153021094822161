import React, { useEffect, useRef } from 'react';

import { Image, Orientation } from '__SMART_APP_OLD__/app/components/Image';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageDetailPlayerStatusChanged } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import {
    selectMessageDetailActiveAttachmenId,
    selectMessageDetailActiveAttachmentImage,
    selectMessageDetailIsAttachmentView,
    selectMessageDetailIsPlayerPreview,
    selectMessageDetailPlayerStatus,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailPlayerStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';

import { UI } from 'App/Packages/UI';

const getClassName = (playerStatus: MessageDetailPlayerStatus, isVisible: boolean, wasVisible: boolean): string => {
    const classList = ['message-detail__atcive-attachment-image'];
    if (playerStatus === MessageDetailPlayerStatus.PLAYING) classList.push('message-detail__atcive-attachment-image--hidden');
    if (isVisible && wasVisible) classList.push('message-detail__atcive-attachment-image--animate');
    return classList.join(' ');
};

export const MessageDetailActiveAttachmentImage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const image = useSelector(selectMessageDetailActiveAttachmentImage);
    const activeId = useSelector(selectMessageDetailActiveAttachmenId);
    const playerStatus = useSelector(selectMessageDetailPlayerStatus);
    const isAttachmentView = useSelector(selectMessageDetailIsAttachmentView);
    const isPlayerView = useSelector(selectMessageDetailIsPlayerPreview);
    const isVisible = isAttachmentView || isPlayerView;
    const wasVisible = useRef(isVisible);
    const className = getClassName(playerStatus, isVisible, wasVisible.current);
    wasVisible.current = isVisible;

    useEffect(() => {
        const canPlayHandler = () => {
            Player.play();
            dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.PLAYING));
        };
        const errorHandler = () => dispatch(messageDetailPlayerStatusChanged(MessageDetailPlayerStatus.ERROR));
        Player.addEventListener(VIDEO_EVENTS.CANPLAY, canPlayHandler);
        Player.addEventListener(VIDEO_EVENTS.ERROR, errorHandler);

        return () => {
            Player.removeEventListener(VIDEO_EVENTS.CANPLAY, canPlayHandler);
            Player.removeEventListener(VIDEO_EVENTS.ERROR, errorHandler);
        };
    }, [dispatch]);

    return (
        <>
            {playerStatus === MessageDetailPlayerStatus.LOADING && <UI.Spinner className="message-detail__player-loading" />}
            <Image className={className} key={activeId ?? ''} src={image} orientation={Orientation.LANDSCAPE} />
        </>
    );
};
