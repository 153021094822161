import { selectDevice } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { Utils } from '__SMART_APP_OLD__/app/utils';

import { Translation as Translations } from 'App/Modules/Translation';

const selectTitle = (id: string) => (state: State): string => {
    const device = selectDevice(id)(state);
    if (!device) return '';
    return Translation.selectors
        .select(Translations.Key.Alert.CannotRemoveDevice.Title.Value)(state)
        .replace(Translations.Key.Alert.CannotRemoveDevice.Title.Params.DeviceName, `“${device.name}”`);
};

const selectText = (id: string) => (state: State): string => {
    const device = selectDevice(id)(state);
    if (!device) return '';
    if (Utils.isCurrentDevice(id)) {
        return Translations.Key.Alert.CannotRemoveDevice.Text.CurrentDevice;
    }
    if (Utils.isSTBDevice(device.deviceType)) {
        return Translations.Key.Alert.CannotRemoveDevice.Text.STB;
    }
    return Translation.selectors
        .select(Translations.Key.Alert.CannotRemoveDevice.Text.OTT.Value)(state)
        .replace(
            Translations.Key.Alert.CannotRemoveDevice.Text.OTT.Params.DateTime,
            `${new Date(device.removableFrom).toLocaleDateString()}`
        );
};

export const selectors = {
    private: {
        selectTitle,
        selectText,
    },
} as const;
