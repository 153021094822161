import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { DetailItemFragment } from 'App/Modules/Data/Detail/Root/Api/fragments/detailItem/detailItem.generated';

import { Folder } from 'App/Modules/Folder';
import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';
import {
    MyLibraryDataLoadDocument,
    MyLibraryDataLoadQueryResult,
    MyLibraryDataLoadQueryVariables,
} from 'App/Modules/MyLibraryData/Api/query/MyLibraryDataLoad.generated';
import {
    MyLibraryHeaderDataLoadDocument,
    MyLibraryHeaderDataLoadQueryVariables,
} from 'App/Modules/MyLibraryData/Api/query/MyLibraryHeaderDataLoad.generated';

const __load = async (
    variables: MyLibraryDataLoadQueryVariables,
    folderList: FolderListFragment | null = null
): Promise<FolderListFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: MyLibraryDataLoadQueryResult = await GraphqlClient.makeGraphqlRequest(MyLibraryDataLoadDocument, variables, options);
    Error.collect(response);
    const page = response.data?.myLibrary?.folders.pageInfo;
    if (!page) return folderList;
    const data = response.data?.myLibrary;
    if (!data) return folderList;
    const next = Folder.Util.merge(folderList, data);
    if (!page.hasNextPage) return next;
    const nextCursor = page.endCursor;
    if (!nextCursor) return next;
    const nextVariables: MyLibraryDataLoadQueryVariables = { ...variables, folderListAfter: nextCursor };
    return __load(nextVariables, next);
};

const __loadHeader = async (variables: MyLibraryHeaderDataLoadQueryVariables): Promise<DetailItemFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response = await GraphqlClient.makeGraphqlRequest(MyLibraryHeaderDataLoadDocument, variables, options);
    Error.collect(response);
    return response.data?.myLibraryHeader?.items?.edges[0]?.node;
};

export type LoadVariables = {
    profileId: string;
    thumbnailHeight: number;
    items: number;
    showAdultContent: boolean;
    backgroundHeight: number;
    backgroundWidth: number;
    folderListFirst?: number;
    folderListAfter?: string;
};

export const load = async (
    vars: LoadVariables
): Promise<{
    myLibraryData: FolderListFragment | null;
    myLibraryHeaderData: DetailItemFragment | null;
}> => {
    const variables: MyLibraryDataLoadQueryVariables = {
        profileId: vars.profileId,
        thumbnailHeight: vars.thumbnailHeight,
        folderListFirst: Folder.Constants.List.Folders.Max,
        folderListAfter: undefined,
        folderFirst: vars.items,
        folderAfter: undefined,
        showAdultContent: vars.showAdultContent,
    };

    const headerVariables: MyLibraryHeaderDataLoadQueryVariables = {
        profileId: vars.profileId,
        thumbnailHeight: vars.thumbnailHeight,
        showAdultContent: vars.showAdultContent,
        backgroundHeight: vars.backgroundHeight,
        backgroundWidth: vars.backgroundWidth,
    };

    const myLibraryData = await __load(variables);
    const myLibraryHeaderData = await __loadHeader(headerVariables);

    return { myLibraryData, myLibraryHeaderData };
};
