import { profileActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/actions';
import { getInitialProfileState } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/getInitialState';
import { profileReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/reducer';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { ProfileActionType, ProfileEntityTable, ProfileReduxAction } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/types';

export { ProfileActionType as ActionType };

export type { ProfileEntityTable as EntityTable, ProfileReduxAction as ReduxAction };

export const getInitialState = getInitialProfileState;

export const reducer = profileReducer;

export const selectors = profileSelectors.public;

export const actions = profileActions.public;
