import BannerCard from '__SMART_APP_OLD__/components/common/cards/BannerCard';
import CircleCard from '__SMART_APP_OLD__/components/common/cards/CircleCard';
import FolderCard from '__SMART_APP_OLD__/components/common/cards/FolderCard';
import LandscapeCard from '__SMART_APP_OLD__/components/common/cards/LandscapeCard';
import PortraitCard from '__SMART_APP_OLD__/components/common/cards/PortraitCard';
import { AssetType, StripeType } from '__SMART_APP_OLD__/utils/Constants';

/**
 * @namespace Utils
 */

/**
 * @description Get asset card component
 * @param assetType - object containing different asset types
 * @returns the component that is returned based on the assetType
 * @memberof Utils
 * @function getAssetCardComponent
 */
export const getAssetCardComponent = (assetType) => {
    switch (assetType) {
        case AssetType.EVENT:
        case AssetType.NETWORK_RECORDING:
        case AssetType.SERIES:
        case AssetType.CHANNEL:
        case AssetType.CHANNEL_PRODUCT:
        case AssetType.PRODUCT_BUNDLE:
        case AssetType.VOD_SERIES:
        case AssetType.EPISODE:
            return LandscapeCard;
        case AssetType.VOD_ASSET:
            return PortraitCard;
        case AssetType.VOD_FOLDER:
        case AssetType.VOD_PRODUCT:
            return FolderCard;
        case AssetType.BANNER:
            return BannerCard;
        // case AssetType.PERSON:
        //     return CircleCard;
        // case AssetType.TV_CHANNEL:
        //     return ChannelCard;
        default:
            console.warn("Don't know how to handle type:", assetType);
            return undefined;
    }
};

/**
 * @param collectionType object containing different collection types
 * @returns  component that is returned based on collection type
 * @memberof Utils
 * @function getAssetCollectionCardComponent
 */
export const getAssetCollectionCardComponent = (collectionType) => {
    switch (collectionType) {
        case StripeType.TV_PROGRAM:
        case StripeType.TV_NOW_NEXT:
        case StripeType.SERIES:
        case StripeType.MIXED:
            return LandscapeCard;
        case StripeType.MOVIE:
            return PortraitCard;
        case AssetType.PERSON:
            return CircleCard;
        default:
            console.warn("Don't know how to handle type:", collectionType);
            return undefined;
    }
};

/**
 * @description a function that returns all landscape cards constants
 * @returns array of AssetType
 * @memberof Utils
 * @function landscapeCardsConstants
 */
export const landscapeCardsConstants = () => [
    AssetType.EVENT,
    AssetType.NETWORK_RECORDING,
    AssetType.SERIES,
    AssetType.CHANNEL,
    AssetType.CHANNEL_PRODUCT,
    AssetType.PRODUCT_BUNDLE,
    AssetType.BANNER,
    AssetType.VOD_FOLDER,
    AssetType.VOD_SERIES,
    AssetType.EPISODE,
];
