import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIsDeviceSTB, selectIsDeviceTimeLocked } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { TimeLockedDescriptionText } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/TimeLockedDescription';
import { Utils } from '__SMART_APP_OLD__/app/utils';

interface Props {
    deviceId: string;
    className: string;
}

const getText = (deviceId: string, isCurrentDevice: boolean, isStbDevice: boolean, isTimeLockedDevice: boolean) => {
    if (isCurrentDevice) return 'SETTINGS_DEVICE_MANAGEMENT_LOCKED_DESCRIPTION_CURRENT_DEVICE';
    if (isStbDevice) return 'SETTINGS_DEVICE_MANAGEMENT_LOCKED_DESCRIPTION_STB';
    if (isTimeLockedDevice) return <TimeLockedDescriptionText deviceId={deviceId} />;
    return null;
};

export const Description: React.FunctionComponent<Props> = (props) => {
    const isCurrentDevice = Utils.isCurrentDevice(props.deviceId);
    const isStbDevice = useSelector(selectIsDeviceSTB(props.deviceId));
    const isTimeLockedDevice = useSelector(selectIsDeviceTimeLocked(props.deviceId));
    if (!isCurrentDevice && !isStbDevice && !isTimeLockedDevice) return null;
    const text = getText(props.deviceId, isCurrentDevice, isStbDevice, isTimeLockedDevice);

    return (
        <Text
            className={props.className}
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            {text}
        </Text>
    );
};
