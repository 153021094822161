import React from 'react';

import { Logo, LogoXploreTVProps } from 'App/Packages/UI/Logo';

const getClassName = (className?: string) => {
    const classList = ['header-v2__logo'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type HeaderLogoProps = LogoXploreTVProps;

export type HeaderLogoComponent = React.FunctionComponent<HeaderLogoProps>;

export const HeaderLogo: HeaderLogoComponent = (props) => <Logo.XploreTV {...props} className={getClassName(props.className)} />;
