import { ChannelListKind } from '__SMART_APP_OLD__/api/graphql/types';

export enum ChannelListOption {
    Activate = 'SETTINGS_CHANNEL_LINEUP_ACTIVATE',
    ManageChannelLists = 'SETTINGS_CHANNEL_MANAGE',
    Rearrange = 'SETTINGS_CHANNEL_LINEUP_REORDER',
    Rename = 'SETTINGS_CHANNEL_LINEUP_RENAME',
    Delete = 'SETTINGS_CHANNEL_LINEUP_DELETE',
}

export const getChannelListAllItems = (
    activeChannelListKind: string | undefined,
    hoveredChannelListKind: string | undefined,
    hasHoveredOnActive: boolean
): ChannelListOption[] => {
    if (hoveredChannelListKind === ChannelListKind.Operator) {
        if (hasHoveredOnActive) return [ChannelListOption.Rearrange];
        return [ChannelListOption.Activate, ChannelListOption.Rearrange];
    }
    if (hoveredChannelListKind === ChannelListKind.Full) {
        if (hasHoveredOnActive) return [ChannelListOption.Rearrange];
        return [ChannelListOption.Activate, ChannelListOption.Rearrange];
    }

    if (
        hoveredChannelListKind === ChannelListKind.Subscriber &&
        activeChannelListKind === ChannelListKind.Subscriber &&
        hasHoveredOnActive
    ) {
        return [ChannelListOption.ManageChannelLists, ChannelListOption.Rearrange, ChannelListOption.Rename];
    }

    return [
        ChannelListOption.Activate,
        ChannelListOption.ManageChannelLists,
        ChannelListOption.Rearrange,
        ChannelListOption.Rename,
        ChannelListOption.Delete,
    ];
};
