import { KeyboardLayoutSchema } from 'App/Modules/Keyboard/types';

export const Mk: KeyboardLayoutSchema = {
    name: 'default',
    language: 'mk',
    keys: [
        [
            { type: 'key.number', value: '1' },
            { type: 'key.number', value: '2' },
            { type: 'key.number', value: '3' },
            { type: 'key.number', value: '4' },
            { type: 'key.number', value: '5' },
            { type: 'key.number', value: '6' },
            { type: 'key.number', value: '7' },
            { type: 'key.number', value: '8' },
            { type: 'key.number', value: '9' },
            { type: 'key.number', value: '0' },
            { type: 'key.symbol', value: '@' },
        ],
        [
            { type: 'key.char', value: 'љ' },
            { type: 'key.char', value: 'њ' },
            { type: 'key.char', value: 'е' },
            { type: 'key.char', value: 'р' },
            { type: 'key.char', value: 'т' },
            { type: 'key.char', value: 'ѕ' },
            { type: 'key.char', value: 'у' },
            { type: 'key.char', value: 'и' },
            { type: 'key.char', value: 'о' },
            { type: 'key.char', value: 'п' },
            { type: 'key.char', value: 'ш' },
            { type: 'key.char', value: 'ѓ' },
        ],
        [
            { type: 'key.char', value: 'а' },
            { type: 'key.char', value: 'с' },
            { type: 'key.char', value: 'д' },
            { type: 'key.char', value: 'ф' },
            { type: 'key.char', value: 'г' },
            { type: 'key.char', value: 'х' },
            { type: 'key.char', value: 'ј' },
            { type: 'key.char', value: 'к' },
            { type: 'key.char', value: 'л' },
            { type: 'key.char', value: 'ч' },
            { type: 'key.char', value: 'ќ' },
        ],
        [
            { type: 'key.capslock' },
            { type: 'key.char', value: 'з' },
            { type: 'key.char', value: 'џ' },
            { type: 'key.char', value: 'ц' },
            { type: 'key.char', value: 'в' },
            { type: 'key.char', value: 'б' },
            { type: 'key.char', value: 'н' },
            { type: 'key.char', value: 'м' },
            { type: 'key.char', value: 'ж' },
            { type: 'key.symbol', value: '.com', label: { size: 'small' } },
            { type: 'key.backspace' },
        ],
        [
            { type: 'key.layout' },
            { type: 'key.language' },
            { type: 'key.arrow.left' },
            { type: 'key.arrow.right' },
            { type: 'key.space' },
            { type: 'key.symbol', value: '-' },
            { type: 'key.symbol', value: '.' },
            { type: 'key.enter' },
        ],
    ],
};
