import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StopPlaybackMutationVariables = Types.Exact<{
  input: Types.StopPlaybackInput;
}>;


export type StopPlaybackMutation = { __typename?: 'Nexx4Mutations', stopPlayback: { __typename: 'StopPlaybackPayload', success: boolean } };


export const StopPlaybackDocument = gql`
    mutation stopPlayback($input: StopPlaybackInput!) {
  stopPlayback(input: $input) {
    success
    __typename
  }
}
    `;
export type StopPlaybackMutationFn = Apollo.MutationFunction<StopPlaybackMutation, StopPlaybackMutationVariables>;

/**
 * __useStopPlaybackMutation__
 *
 * To run a mutation, you first call `useStopPlaybackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopPlaybackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopPlaybackMutation, { data, loading, error }] = useStopPlaybackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopPlaybackMutation(baseOptions?: Apollo.MutationHookOptions<StopPlaybackMutation, StopPlaybackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopPlaybackMutation, StopPlaybackMutationVariables>(StopPlaybackDocument, options);
      }
export type StopPlaybackMutationHookResult = ReturnType<typeof useStopPlaybackMutation>;
export type StopPlaybackMutationResult = Apollo.MutationResult<StopPlaybackMutation>;
export type StopPlaybackMutationOptions = Apollo.BaseMutationOptions<StopPlaybackMutation, StopPlaybackMutationVariables>;