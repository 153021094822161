import { useCallback } from 'react';

import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemFocus = (id: string) => () => void;

export const useHeaderItemFocus: UseHeaderItemFocus = (id) => {
    const items = HeaderItems.use();

    return useCallback(() => {
        const isItemFocused = items.focused.get() === id;
        if (!isItemFocused) items.focus(id);
    }, [id, items]);
};
