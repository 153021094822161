import React, { useCallback } from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/AppearanceScreen/actions';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const AppearanceScreenOptions: React.FunctionComponent = () => {
    const theme = useSelector(Theme.selectors.select);
    const dispatch = useDispatch();
    const { focused, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items: Theme.optionIds,
        initialFocusedIndex: Theme.optionIndexForType[theme],
    });

    const enterHandler = useCallback((option: Theme.Type) => dispatch(actions.private.selected(option)), [dispatch]);
    const focusHandler = useCallback((option: Theme.Type) => dispatch(actions.private.focused(option)), [dispatch]);

    return (
        <>
            {Theme.optionIds.map((id) => (
                <Button
                    key={id}
                    id={id}
                    ctx={Focus.Default}
                    className="appearance-screen__option"
                    isFocused={focused === id}
                    width={ButtonWidth.FULL}
                    corners={ButtonCornersStyle.ROUNDED}
                    onHover={focus}
                    onEnter={enterHandler}
                    onFocus={focusHandler}
                >
                    {Theme.optionLabelForType[id]}
                </Button>
            ))}
        </>
    );
};
