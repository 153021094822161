import { states } from 'App/Modules/Keyboard/Store/states';
import { KeyboardActionType, KeyboardState } from 'App/Modules/Keyboard/Store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const reducer: Reducer<KeyboardState> = (state, action) => {
    if (!state) return states.initial;
    switch (action.type) {
        case KeyboardActionType.Opened:
            if (state.open) return state;
            return states.opened;
        case KeyboardActionType.Closed:
            if (!state.open) return state;
            return states.closed;
        default:
            return state;
    }
};
