import { initialHistoryState } from '__SMART_APP_OLD__/app/modules/History/constants';
import { HistoryActionType, HistoryEntity } from '__SMART_APP_OLD__/app/modules/History/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const historyReducer: Reducer<HistoryEntity[]> = (state, action) => {
    if (!state) return initialHistoryState;
    switch (action.type) {
        case HistoryActionType.PUSH:
            return [action.payload.entity, ...state];
        case HistoryActionType.POP:
            // if (state.length === 1) return state;
            // uncomment when all routes are moved to redux
            return state.slice(1);
        case HistoryActionType.CLEAR:
            return [];
        default:
            return state;
    }
};
