import React from 'react';

import { Button, ButtonAnimation, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguageSelectionScreen/actions';
import { LanguageSelectionAction } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguageSelectionScreen/types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

const items = [LanguageSelectionAction.CHANGE, LanguageSelectionAction.CONTINUE];

export const LanguageSelectionScreenActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { focused, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items,
        initialFocusedIndex: 1,
    });

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <>
            <Button
                id={LanguageSelectionAction.CHANGE}
                ctx={Focus.Default}
                className="language-selection__action"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                width={ButtonWidth.FULL}
                onHover={focus}
                onEnter={() => dispatch(actions.private.change())}
                isFocused={focused === LanguageSelectionAction.CHANGE && !isDisabled}
            >
                FTI_UI_LANGUAGE_CHANGE_LANGUAGE_BUTTON
            </Button>
            <Button
                id={LanguageSelectionAction.CONTINUE}
                ctx={Focus.Default}
                className="language-selection__action"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                width={ButtonWidth.FULL}
                onHover={focus}
                onEnter={() => dispatch(Fti.actions.next(Screen.Type.LANGUAGE_SELECTION))}
                isFocused={focused === LanguageSelectionAction.CONTINUE && !isDisabled}
            >
                CONTINUE_BUTTON
            </Button>
        </>
    );
};
