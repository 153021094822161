import React from 'react';

import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { ChannelListsScreenManageChannelsCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsCtx';
import {
    ChannelListsScreenManageChannelsItem,
    ChannelListsScreenManageChannelsItemNumberType,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsItem';
import { CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/constants';

import { UseListReturnType as FocusUseListReturnType } from 'App/Packages/Focus/Hooks/Types';
import { Function } from 'App/Packages/Function';

type Props = {
    addedChannelIds: string[];
    channelListName: string;
    navigation: FocusUseListReturnType<string>;
    isArranging: boolean;
    firstVisibleIndex: number;
    setFirstVisibleIndex: React.Dispatch<React.SetStateAction<number>>;
    onEnter: () => void;
};

export const ChannelListsScreenManageChannelsViewAddedSection: React.FunctionComponent<Props> = (props) => {
    const { channelListName, addedChannelIds, navigation, isArranging, firstVisibleIndex, setFirstVisibleIndex, onEnter } = props;

    const onFocus = () => {
        setFirstVisibleIndex(
            Function.calculateNextFirstVisibleIndex(
                navigation.focusedIndex,
                firstVisibleIndex,
                CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS,
                addedChannelIds.length
            )
        );
    };

    const renderItem = (channelId: string, index: number) => (
        <ChannelListsScreenManageChannelsItem
            key={channelId}
            id={channelId}
            index={index}
            ctx={ChannelListsScreenManageChannelsCtx.Added}
            navigation={navigation}
            numberType={ChannelListsScreenManageChannelsItemNumberType.SEQUENCE}
            isArranging={isArranging}
            onEnter={onEnter}
            onFocus={onFocus}
        />
    );

    return (
        <>
            <Settings.Section.Header>
                <Settings.Section.Header.Title>{channelListName}</Settings.Section.Header.Title>
            </Settings.Section.Header>
            <Settings.Section.Content className="channel-lists-manage-screen__list">
                <List
                    firstVisibleIndex={firstVisibleIndex}
                    className={'settings-section-content__list'}
                    items={addedChannelIds}
                    itemType={ListItemType.CHANNEL_ITEM}
                    renderer={renderItem}
                />
            </Settings.Section.Content>
        </>
    );
};
