import { CardPortraitComponent, CardPortrait as Root } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortrait';
import { CardPortraitCaption, CardPortraitCaptionComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitCaption';
import {
    CardPortraitDescription,
    CardPortraitDescriptionComponent,
} from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitDescription';
import { CardPortraitIcons, CardPortraitIconsComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitIcons';
import { CardPortraitInfo, CardPortraitInfoComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitInfo';
import { CardPortraitMain, CardPortraitMainComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitMain';
import { CardPortraitMarker, CardPortraitMarkerComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitMarker';
import { CardPortraitMeta, CardPortraitMetaComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitMeta';
import { CardPortraitPrice, CardPortraitPriceComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitPrice';
import { CardPortraitProgress, CardPortraitProgressComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitProgress';
import { CardPortraitThumbnail, CardPortraitThumbnailComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitThumbnail';
import { CardPortraitTitle, CardPortraitTitleComponent } from 'App/Packages/UI/Card/CardPortrait/Components/CardPortraitTitle';
import { Constants } from 'App/Packages/UI/Card/CardPortrait/Constants';

export type CardPortraitModules = {
    // Constants
    Constants: typeof Constants;

    // Components
    Main: CardPortraitMainComponent & {
        Thumbnail: CardPortraitThumbnailComponent;
        Meta: CardPortraitMetaComponent & {
            Progress: CardPortraitProgressComponent;
            Marker: CardPortraitMarkerComponent;
        };
    };
    Content: CardPortraitInfoComponent & {
        Title: CardPortraitTitleComponent;
        Caption: CardPortraitCaptionComponent;
        Description: CardPortraitDescriptionComponent;
        Price: CardPortraitPriceComponent;
        Icons: CardPortraitIconsComponent;
    };

    // Context

    // Hooks
};

export type CardPortraitModule = CardPortraitComponent & CardPortraitModules;

export const CardPortrait: CardPortraitModule = Object.assign(Root, {
    // Constants
    Constants,

    // Components
    Main: Object.assign(CardPortraitMain, {
        Thumbnail: CardPortraitThumbnail,
        Meta: Object.assign(CardPortraitMeta, {
            Progress: CardPortraitProgress,
            Marker: CardPortraitMarker,
        }),
    }),
    Content: Object.assign(CardPortraitInfo, {
        Title: CardPortraitTitle,
        Caption: CardPortraitCaption,
        Description: CardPortraitDescription,
        Price: CardPortraitPrice,
        Icons: CardPortraitIcons,
    }),
});
