import React from 'react';

import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { UI } from 'App/Packages/UI';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Env } from 'App/Env';
import { Detail } from 'App/Modules/Data/Detail';
import { DetailVodAssetActions } from 'App/Modules/Screen/Modules/DetailVodAssetScreen/Store/actions';
import { DetailRootScreen } from 'App/Modules/Screen/Modules/DetailRootScreen';

export const Header = () => {
    const dispatch = useDispatch();
    const assetSelector = Detail.VodAsset.selectors.selectAsset;
    const asset = useSelector(assetSelector);

    const assetType = useSelector(Detail.Root.selectors.selectAssetType(assetSelector));
    const contentMarker = useSelector(Detail.Root.selectors.selectAssetContentMarker(assetSelector));
    const availableUntil = useSelector(Detail.Root.selectors.selectAssetAvailableUntil(assetSelector));
    const reminder = useSelector(Detail.Root.selectors.selectAssetReminder(assetSelector));
    const assetProgress = useSelector(Detail.Root.selectors.selectAssetProgress(assetSelector));
    const isFavourited = useSelector(Detail.Root.selectors.selectAssetIsFavourited(assetSelector));
    const assetHasTrailer = useSelector(Detail.Root.selectors.selectAssetHasTrailer(assetSelector));
    const watchButtonText = useSelector(Detail.Root.selectors.selectAssetWatchButtonText(assetSelector));
    const assetTitle = useSelector(Detail.Root.selectors.selectAssetTitle(assetSelector));
    const assetActors = useSelector(Detail.Root.selectors.selectAssetActors(assetSelector));
    const assetDirectors = useSelector(Detail.Root.selectors.selectAssetDirectors(assetSelector));
    const assetDescription = useSelector(Detail.Root.selectors.selectAssetDescription(assetSelector));

    const assetCanBeStartedOver = useSelector(Detail.Root.selectors.selectAssetCanBeStartedOver(assetSelector));
    const isProgressVisible = useSelector(Detail.Root.selectors.selectAssetIsProgressVisible(assetSelector));
    const assetCanBeRented = useSelector(Detail.Root.selectors.selectAssetCanBeRented);
    const assetCanBeSubscribed = useSelector(Detail.Root.selectors.selectAssetCanBeSubscribed);
    const assetCanBePurchased = useSelector(Detail.Root.selectors.selectAssetCanBePurchased);

    console.log(asset);

    if (!asset) return false;

    return (
        <UI.Detail parentalRating={asset.metadata.parentalRatingId}>
            <UI.Detail.Gradient.Left />
            <UI.Detail.Gradient.Bottom />
            <UI.Detail.Background src={asset.content.backgroundImage} />
            <UI.Detail.Body style={{ paddingTop: '96px' }}>
                <UI.Detail.Title>{asset.content.title}</UI.Detail.Title>
                <UI.Detail.Meta>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Marker marker={contentMarker} label={availableUntil} />
                        <Icons
                            className="detail__icons"
                            status={null}
                            parentalControl={asset.metadata.parentalRatingId}
                            audioLanguages={asset.metadata.audioLanguages as string[]}
                            subtitleLanguages={asset.metadata.subtitleLanguages as string[]}
                            videoQuality={asset.metadata?.videoQuality}
                            isReminderSet={!!reminder}
                            isVisuallyImpaired={asset.entitlements.isVisuallyImpaired}
                            isFavourited={isFavourited}
                        />
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Progress hidden={!isProgressVisible} progress={assetProgress} />
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Info>{asset.content.info}</UI.Detail.Meta.Info>
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Description>{asset.content.shortDescription}</UI.Detail.Description>
                        <UI.Detail.ShowMore
                            onEnter={() =>
                                dispatch(
                                    DetailVodAssetActions.public.showMoreSelected(assetTitle, assetActors, assetDirectors, assetDescription)
                                )
                            }
                        />
                    </UI.Detail.Meta.Row>
                </UI.Detail.Meta>
                <UI.Folder.Detail.Actions id="detail-actions">
                    <UI.Detail.Button.Watch
                        hidden={!asset.entitlements.playback}
                        text={watchButtonText}
                        onEnter={() => dispatch(DetailVodAssetActions.public.watchSelected())}
                    />
                    <UI.Detail.Button.StartOver
                        hidden={!assetCanBeStartedOver}
                        onEnter={() => dispatch(DetailVodAssetActions.public.startOverSelected())}
                    />
                    <UI.Detail.Button.Rent
                        hidden={!assetCanBeRented}
                        onEnter={() => dispatch(DetailVodAssetActions.public.rentSelected())}
                    />
                    <UI.Detail.Button.Buy
                        hidden={!assetCanBePurchased}
                        onEnter={() => dispatch(DetailVodAssetActions.public.buySelected())}
                    />
                    <UI.Detail.Button.Subscribe
                        hidden={!assetCanBeSubscribed}
                        onEnter={() => dispatch(DetailVodAssetActions.public.subscribeSelected())}
                    />
                    <UI.Detail.Button.Trailer
                        hidden={!assetHasTrailer}
                        onEnter={() => dispatch(DetailVodAssetActions.public.trailerSelected())}
                    />
                    <UI.Detail.Button.Favorite
                        hidden={Env.isBusiness}
                        isFavourited={isFavourited}
                        onEnter={() => dispatch(DetailRootScreen.actions.addToFavouritesSelected(asset.id, isFavourited, assetType))}
                    />
                </UI.Folder.Detail.Actions>
            </UI.Detail.Body>
        </UI.Detail>
    );
};
