import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Exception } from '__SMART_APP_OLD__/app/modules/Error/Exception';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { Api } from 'App/Modules/Data/Search/Result/Api';
import { Constants } from 'App/Modules/Data/Search/Result/Constants';

import { Folder } from 'App/Modules/Folder';

const loaded = Folder.actions.list.loaded(Constants.Hash);
const clear = Folder.actions.list.reset(Constants.Hash);

export type LoadPayload = Pick<Api.LoadVariables, 'query' | 'filters' | 'context'>;

const load =
    (payload: LoadPayload): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const profileId = Profile.selectors.selectId(state);
            const thumbnailHeight = config.image.thumbnail.default.height;
            const items = config.layout.collectionMaxItems || Folder.Constants.List.Folders.Items;
            const response = await Api.load({ profileId, thumbnailHeight, items, ...payload });
            if (!response) throw new Exception(Error.Code.UNEXPECTED_ERROR);
            const data = Folder.Mapper.toFolderList(response);
            if (!data) throw new Exception(Error.Code.UNEXPECTED_ERROR);
            dispatch(loaded(data));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.SearchResultsLoad, error));
            return false;
        }
    };

export const actions = {
    public: {
        load,
        loaded,
        clear,
    },
} as const;
