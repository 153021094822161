import { initialPlayerState } from '__SMART_APP_OLD__/app/modules/Player/constants';
import { PlayerActionType, PlayerData } from '__SMART_APP_OLD__/app/modules/Player/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const playerReducer: Reducer<PlayerData> = (state, action) => {
    if (state === undefined) return initialPlayerState;

    switch (action.type) {
        case PlayerActionType.VISIBLE_COMPONENT_CHANGED:
            return { ...state, visibleComponent: action.payload };
        default:
            return state;
    }
};
