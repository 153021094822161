import React from 'react';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Folder } from 'App/Modules/Folder';
import { RecordingQuota } from '__SMART_APP_OLD__/components/common/RecordingQuota';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { ContentFolderKind, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { MyLibraryQuotaButton } from 'App/Modules/Screen/Modules/MyLibrary/View/components/MyLibraryQuotaButton';

export const MyLibraryQuota = ({ selector, initial }: { selector: Selector<Folder.Entity | null>; initial: string | undefined }) => {
    const folder = useSelector(selector);
    const entities = useSelector(Folder.selectors.selectItemsEntities(selector));

    if (!folder) return null;
    if (
        folder.type !== ContentFolderKind.Recordings ||
        Object.values(entities).some((entity) => entity.entitlements.status === RecordingStatus.Planned)
    ) {
        return null;
    }

    return (
        <div className="my-library__recording_quota">
            <RecordingQuota />
            <MyLibraryQuotaButton initial={initial} />
        </div>
    );
};
