import { DetailRecordingState } from 'App/Modules/Data/Detail/NetworkRecording/Types';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { Folder } from 'App/Modules/Folder';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

const selectState: Selector<DetailRecordingState> = (state) => state.dataV2.detail.recording;
const selectAsset: Selector<DetailItem | null> = (state) => selectState(state).item;
const selectAssetObject: Selector<any> = (state) => selectState(state).assetObject;
const selectFolders: Selector<Folder.List | null> = (state) => selectState(state).folders;

export const selectors = {
    selectAsset,
    selectAssetObject,
    selectFolder: Folder.selectors.list.selectFolder(selectFolders),
    selectFoldersIds: Folder.selectors.list.selectFoldersIds(selectFolders),
    selectFoldersEntities: Folder.selectors.list.selectFoldersEntities(selectFolders),
};
