import * as workerTimers from 'worker-timers';

import { ConfigServiceConfigUARConfig } from '__SMART_APP_OLD__/app/modules/ConfigService/ConfigServiceConfig';
import { selectIsLoggedIn } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { isApplicationVisible, subscribeVisibilityChangeHandler } from '__SMART_APP_OLD__/utils/visibilityChange';
import { SessionEvent, SessionEvents } from 'analytics/logging/events/SessionEvent';
import * as issueEventFactory from 'analytics/logging/factories/issueEventFactory';
import * as sessionEventFactory from 'analytics/logging/factories/sessionEventFactory';
import * as loggingApi from 'analytics/logging/loggingApi';
import { LoggingDB, LoggingMetrics } from 'analytics/logging/LoggingDB';
import * as LoggingSession from 'analytics/logging/LoggingSession';
import { Event } from 'analytics/types';

export class LoggingService {
    private attemptCount: number;

    private batchTimerId: number | null;

    private loggingStarted: boolean;

    private retryTimerId: number | null;

    private metricsTimerId: number | null;

    private batchRequestOngoing: boolean;

    private retryRequestOngoing: boolean;

    private config: ConfigServiceConfigUARConfig = {
        endpoint: '',
        batchBuffer: { maxRetries: 5, maxSize: 100, timer: 60 },
        retryBuffer: { maxSize: 100000, purgeSize: 0, requestSize: 500, timer: 300 },
        metrics: { timer: 900 },
    };

    private readonly loggingDB: LoggingDB;

    private static instance: LoggingService;

    private constructor() {
        this.loggingDB = new LoggingDB();
        this.attemptCount = 0;
        this.loggingStarted = false;
        this.batchTimerId = null;
        this.retryTimerId = null;
        this.metricsTimerId = null;
        this.batchRequestOngoing = false;
        this.retryRequestOngoing = false;
        window.LoggingService = this;
        subscribeVisibilityChangeHandler(() => LoggingService.instance.changeLoggingStatusOnVisibilityChange());
    }

    static getInstance(): LoggingService {
        if (!LoggingService.instance) {
            LoggingService.instance = new LoggingService();
        }
        return LoggingService.instance;
    }

    static hasInstance(): boolean {
        return !!LoggingService.instance;
    }

    public setConfig(config: ConfigServiceConfigUARConfig): void {
        this.config = config;
    }

    async changeLoggingStatusOnVisibilityChange(): Promise<void> {
        if (!LoggingService.hasInstance()) return;
        const userIsAuthenticated = selectIsLoggedIn(store.getState());
        if (!userIsAuthenticated) return;
        if (isApplicationVisible()) {
            const dataUsageAllowed = profileSelectors.public.selectDataUsageAllowed(store.getState());
            if (!dataUsageAllowed) return;
            await this.startLoggingSession();
        }
        if (!isApplicationVisible()) {
            console.log('STOP ON VISIBILITY CHANGE');
            await this.closeLoggingSession();
        }
    }

    async startLoggingSession(): Promise<void> {
        if (!this.isLoggingEnabled()) {
            this.cancelTimers();
            return;
        }
        if (this.loggingStarted) {
            return;
        }
        this.startTimers();
        console.log(`UAR: initiated logging activity`);
        this.loggingStarted = true;
        await this.logEvent(sessionEventFactory.getAppStartEvent());
        await this.logEvent(sessionEventFactory.getUserStartEvent());
    }

    async closeLoggingSession(): Promise<void> {
        if (!this.loggingStarted) return;
        await this.logEvent(sessionEventFactory.getUserStopEvent());
        await this.logEvent(sessionEventFactory.getAppStopEvent());

        try {
            const allEvents = await this.loggingDB.getAllEventsFromBatchBuffer();
            if (!allEvents?.length) throw new Error('UAR no accumulated events canceling the request');
            const batches = [];
            while (allEvents.length) batches.push(allEvents.splice(0, this.config.batchBuffer.maxSize));
            await Promise.allSettled(batches.map((batch) => loggingApi.sendBatchToServer(this.config.endpoint, batch)));
            const allBatchEventKeys = await this.loggingDB.getAllEventKeysFromBatchBuffer();
            if (!allBatchEventKeys) return;
            const deleteUntilKey = allBatchEventKeys[allBatchEventKeys.length - 1];
            if (deleteUntilKey === undefined) throw new Error('Delete key not found');
            await this.loggingDB.deleteEventsFromBatchBufferUntilKey(deleteUntilKey);
        } catch (error) {
            console.log(error);
        } finally {
            this.cancelTimers();
            this.loggingStarted = false;
            this.batchRequestOngoing = false;
            console.info(`UAR  send gathered events`);
        }
    }

    async logEvent(event?: Event): Promise<void> {
        if (!this.loggingDB.isSupported() || !this.loggingStarted) return;

        if (!event) return;

        if (!this.isLoggingEnabled()) {
            this.cancelTimers();
            return;
        }
        try {
            await this.loggingDB.addEventToBatchBuffer(event);
            if (event instanceof SessionEvent) {
                this.updateSessions(event);
                if (!LoggingSession.isAnySessionStarted()) {
                    await this.sendBatchBuffer();
                    this.cancelTimers();
                    return;
                }
            }
            await this.checkIfBatchBufferFull();
        } catch (error) {
            console.error(error);
        }
    }

    private async checkIfBatchBufferFull(): Promise<void> {
        const size = await this.loggingDB.getBatchBufferSize();
        if (!size || size < this.config.batchBuffer.maxSize) return;
        await this.sendBatchBuffer();
    }

    private async sendBatchBuffer(): Promise<void> {
        if (this.batchRequestOngoing) return;
        this.batchRequestOngoing = true;
        try {
            const allEvents = await this.loggingDB.getAllEventsFromBatchBuffer();
            if (!allEvents?.length) throw new Error(`UAR no acumulated events canceling the request`);
            const batch = allEvents.slice(0, this.config.batchBuffer.maxSize);
            const response = await loggingApi.sendBatchToServer(this.config.endpoint, batch);
            if (!response) throw new Error('UAR sending batchBuffer to backend failed');
            await this.processBatchBufferResponse(response?.status, batch.length);
        } catch (error) {
            console.log(error);
        } finally {
            console.info(`UAR  send gathered events`);
            this.batchRequestOngoing = false;
        }
    }

    private async processBatchBufferResponse(status: number, requestSize: number): Promise<void> {
        if (this.shouldClearBuffer(status)) {
            this.attemptCount = 0;
            const allBatchEventKeys = await this.loggingDB.getAllEventKeysFromBatchBuffer();
            if (!allBatchEventKeys) return;
            const deleteUntilKey = allBatchEventKeys[requestSize - 1];
            if (deleteUntilKey === undefined) throw new Error('Delete key not found');
            await this.loggingDB.deleteEventsFromBatchBufferUntilKey(deleteUntilKey);
            return;
        }
        this.attemptCount += 1;
        if (this.attemptCount <= this.config.batchBuffer.maxRetries) return;
        this.attemptCount = 0;
        // Transfer events to retry-buffer.
        await this.loggingDB.transferEventsToRetryBuffer();
        // Increment maxRetriesExceeded count
        await this.loggingDB.incrementMetric(LoggingMetrics.MAX_RETRIES_EXCEEDED);
        const retryBufferSize = await this.loggingDB.getRetryBufferSize();
        if (retryBufferSize === null || retryBufferSize <= this.config.retryBuffer.maxSize) return;
        // Purge retry-buffer.
        const purgeCount = await this.loggingDB.purgeRetryBuffer(this.config.retryBuffer.maxSize, this.config.retryBuffer.purgeSize);
        if (!purgeCount) return;
        // Increment eventsDroppedFromBuffer count
        await this.loggingDB.incrementMetric(LoggingMetrics.EVENTS_DROPPED_FROM_BUFFER, purgeCount);
    }

    private async sendRetryBuffer(): Promise<void> {
        if (this.retryRequestOngoing) return;
        this.retryRequestOngoing = true;

        try {
            const allEvents = await this.loggingDB.getAllEventsFromRetryBuffer();
            if (!allEvents || allEvents?.length === 0) return;
            const batch = allEvents.slice(0, this.config.retryBuffer.requestSize);
            const response = await loggingApi.sendBatchToServer(this.config.endpoint, batch);
            if (!response) throw new Error('UAR sending batchBuffer to backend failed');
            await this.processRetryBufferResponse(response?.status, batch.length);
        } catch (error) {
            console.error(error);
        } finally {
            this.retryRequestOngoing = false;
        }
    }

    private async processRetryBufferResponse(status: number, requestSize: number): Promise<void> {
        if (!this.shouldClearBuffer(status)) return;
        try {
            const allRetryEventKeys = await this.loggingDB.getAllEventKeysFromRetryBuffer();
            if (!allRetryEventKeys) throw new Error('Retry buffer keys not found');
            const deleteUntilKey = allRetryEventKeys[requestSize - 1];
            if (deleteUntilKey === undefined) throw new Error('Delete key not found');
            await this.loggingDB.deleteEventsFromRetryBufferUntilKey(deleteUntilKey);
        } catch (error) {
            console.error(`UAR processing buffer error ${error}`);
        } finally {
            console.info(`UAR finished processing retry response`);
        }
    }

    private isLoggingEnabled(): boolean {
        return (
            this.config.batchBuffer.timer > 0 &&
            this.config.batchBuffer.maxSize > 0 &&
            this.config.batchBuffer.maxRetries >= 0 &&
            this.config.retryBuffer.timer > 0 &&
            this.config.retryBuffer.maxSize > 0 &&
            this.config.retryBuffer.purgeSize >= 0 &&
            this.config.retryBuffer.requestSize > 0 &&
            this.config.metrics.timer > 0
        );
    }

    private updateSessions(sessionEvent: SessionEvent): LoggingSession.LoggingSessions {
        const sessions = LoggingSession.getLoggingSessions();
        const updatedSessions: LoggingSession.LoggingSessions = { ...sessions };
        const { sessionType, event, sessionId } = sessionEvent;
        if (event === SessionEvents.START) updatedSessions[sessionType] = sessionId;
        if (event === SessionEvents.STOP) updatedSessions[sessionType] = null;
        LoggingSession.setLoggingSessions(updatedSessions);
        return updatedSessions;
    }

    private shouldClearBuffer(status: number): boolean {
        const clearBatchBufferStatusCodes = [200, 202, 304, 501];
        if (!status) return false;
        return clearBatchBufferStatusCodes.includes(status) || (status.toString()[0] === '4' && status !== 401);
    }

    private startTimers(): void {
        this.startBatchBufferTimer();
        this.startRetryBufferTimer();
        this.startMetricsTimer();
    }

    private cancelTimers(): void {
        if (this.batchTimerId !== null) workerTimers.clearInterval(this.batchTimerId);
        if (this.retryTimerId !== null) workerTimers.clearInterval(this.retryTimerId);
        if (this.metricsTimerId !== null) workerTimers.clearInterval(this.metricsTimerId);
        this.batchTimerId = null;
        this.retryTimerId = null;
        this.metricsTimerId = null;
    }

    private startBatchBufferTimer(): void {
        if (this.batchTimerId !== null) workerTimers.clearInterval(this.batchTimerId);
        this.batchTimerId = workerTimers.setInterval(this.sendBatchBuffer.bind(this), this.config.batchBuffer.timer * 1000);
    }

    private startRetryBufferTimer(): void {
        if (this.retryTimerId !== null) workerTimers.clearInterval(this.retryTimerId);
        this.retryTimerId = workerTimers.setInterval(this.sendRetryBuffer.bind(this), this.config.retryBuffer.timer * 1000);
    }

    private startMetricsTimer(): void {
        if (this.metricsTimerId !== null) workerTimers.clearInterval(this.metricsTimerId);
        this.metricsTimerId = workerTimers.setInterval(async () => {
            try {
                const maxRetriesExceeded = await this.loggingDB.getMetric(LoggingMetrics.MAX_RETRIES_EXCEEDED);
                const eventsDroppedFromBuffer = await this.loggingDB.getMetric(LoggingMetrics.EVENTS_DROPPED_FROM_BUFFER);
                if (maxRetriesExceeded <= 0 && eventsDroppedFromBuffer <= 0) return;
                await this.logEvent(issueEventFactory.getMetricsIssueEvent(maxRetriesExceeded, eventsDroppedFromBuffer));
                await this.loggingDB.resetMetrics();
            } catch (error) {
                console.log(error);
            }
        }, this.config.metrics.timer * 1000);
    }
}
