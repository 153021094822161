import React from 'react';

const getClassName = (props: CardLandscapeHeaderProps) => {
    const classlist = ['landscape-card-v3__header'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardLandscapeHeaderProps = React.PropsWithChildren<{
    className?: string;
}>;

export type CardLandscapeHeaderComponent = React.FunctionComponent<CardLandscapeHeaderProps>;

export const CardLandscapeHeader: CardLandscapeHeaderComponent = (props) => <div className={getClassName(props)}>{props.children}</div>;
