import { useEffect } from 'react';
import * as workerTimers from 'worker-timers';

import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { useDispatch } from './useDispatch';

export const useInitialize = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const tickIntervalId = workerTimers.setInterval(() => dispatch(Time.actions.changed(Date.now())), 1000 * 60);

        return () => {
            workerTimers.clearInterval(tickIntervalId);
        };
    }, [dispatch]);
};
