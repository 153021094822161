import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import { Folder } from 'App/Modules/Folder';
import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';
import {
    RootFolderLoadDocument,
    RootFolderLoadQueryResult,
    RootFolderLoadQueryVariables,
} from 'App/Modules/RootFolder/Api/query/rootFolderLoad.generated';

const __load = async (
    variables: RootFolderLoadQueryVariables,
    folderList: FolderListFragment | null = null
): Promise<FolderListFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: RootFolderLoadQueryResult = await GraphqlClient.makeGraphqlRequest(RootFolderLoadDocument, variables, options);
    Error.collect(response);
    const page = response.data?.vodRootContentFolderList?.folders.pageInfo;
    if (!page) return folderList;
    const data = response.data?.vodRootContentFolderList;
    if (!data) return folderList;
    const next = Folder.Util.merge(folderList, data);
    if (!page.hasNextPage) return next;
    const nextCursor = page.endCursor;
    if (!nextCursor) return next;
    const nextVariables: RootFolderLoadQueryVariables = { ...variables, folderListAfter: nextCursor };
    return __load(nextVariables, next);
};

export type LoadVariables = {
    profileId: string;
    thumbnailHeight: number;
    items: number;
};

export const load = async (vars: LoadVariables): Promise<FolderListFragment | null> => {
    const variables: RootFolderLoadQueryVariables = {
        profileId: vars.profileId,
        thumbnailHeight: vars.thumbnailHeight,
        folderListFirst: Folder.Constants.List.Folders.Max,
        folderListAfter: undefined,
        folderFirst: vars.items,
        folderAfter: undefined,
    };
    const response = await __load(variables);
    return response;
};
