import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { AgeRatingSelectionScreenItems } from '__SMART_APP_OLD__/app/modules/Screen/modules/AgeRatingSelectionScreen/AgeRatingSelectionScreenItems';

import { Screen } from 'App/Modules/Screen';

export const AgeRatingSelectionScreenView: React.FunctionComponent<Screen.Props<Screen.Type.AGE_RATING_SELECTION>> = () => (
    <Layout className="age-rating-selection" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <Text
            className="age-rating-selection__title"
            typeface={Typeface.SANS}
            size={FontSize.TITLE_2}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            SETTINGS_PARENTAL_CONTROL
        </Text>
        <Text
            className="age-rating-selection__subtitle"
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.PRIMARY}
        >
            SETTINGS_PARENTAL_CONTROL_HINT
        </Text>
        <AgeRatingSelectionScreenItems />
    </Layout>
);
