import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    FavouriteItemDocument,
    FavouriteItemMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/api/mutation/favouriteItem.generated';
import {
    UnfavouriteItemDocument,
    UnfavouriteItemMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/api/mutation/unfavouriteItem.generated';
import {
    FavouritesLoadDocument,
    FavouritesLoadFragment,
    FavouritesLoadQueryResult,
    FavouritesLoadQueryVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/api/query/favouritesLoad.generated';
import { FavouritesEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadResponse = {
    favourites: FavouritesEntity[];
};

export type ChangeResponse = {
    id: string;
};

export const __parseLoad = (response?: FavouritesLoadFragment): LoadResponse => {
    const favourites = (response?.favourites?.items ?? [])
        .map<FavouritesEntity | null>((obj) => {
            if (!obj || !obj.id) return null;
            return { id: obj.id };
        })
        .filter((obj): obj is FavouritesEntity => !!obj);

    return { favourites };
};

export const load = async (): Promise<LoadResponse> => {
    const variables: FavouritesLoadQueryVariables = {};
    const options = { fetchPolicy: 'no-cache' };
    const response: FavouritesLoadQueryResult = await GraphqlClient.makeGraphqlRequest(FavouritesLoadDocument, variables, options);
    Error.collect(response);
    return __parseLoad(response.data);
};

export const add = async (variables: FavouriteItemMutationVariables) => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(FavouriteItemDocument, variables);
    Error.collect(response);
};

export const remove = async (variables: UnfavouriteItemMutationVariables) => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(UnfavouriteItemDocument, variables);
    Error.collect(response);
};
