import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelDetailItemFragment = { __typename: 'Channel', id: string };

export const ChannelDetailItemFragmentDoc = gql`
    fragment channelDetailItem on Channel {
  id
  __typename
}
    `;