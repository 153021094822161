import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Events, { PIN_OVERLAY_HIDE } from '__SMART_APP_OLD__/config/Events';
import { PinAction, PinStyle } from '__SMART_APP_OLD__/utils/Constants';

import { Screen } from 'App/Modules/Screen';

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    openPinOverlay(
        () => {
            // needed just for entering PIN, not for modifying the PIN
        },
        async (success: any) => {
            if (!success) return;
            await dispatch(Fti.actions.next(Screen.Type.MASTER_PIN_CODE));
        },
        PinAction.FIRST_SETUP,
        PinSessionType.PIN,
        false,
        { layout: PinStyle.Fti },
        false
    );
};

const unmount = (): Thunk<Promise<void>> => async () => {
    Events.triggerEvents(PIN_OVERLAY_HIDE);
};

export const masterPinCodeActions = {
    lifecycle: {
        mount,
        unmount,
    },
};
