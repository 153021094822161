import React from 'react';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectRecordingAvailableUntil } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { getTimeGapInDays } from '__SMART_APP_OLD__/utils/timeUtils';

import { ContentMarkerType } from 'App/Types/ContentMarker';

class ContentMarkers extends React.PureComponent {
    findMorePrioritizeMarker(asset) {
        if (this.props.config.contentMarkers[this.props.forceMarker]) {
            return { marker: this.props.forceMarker };
        }
        const availableAssetContentMarkers = asset.availableContentMarkers || [];

        const { rentLeft } = asset;
        if (Player.program?.id === asset?.id && !this.props.hideNowPlayingMarker) {
            availableAssetContentMarkers.unshift(ContentMarkerType.NowPlaying);
        }

        if (this.props.hideLiveMarker) {
            const liveMarkerIndex = availableAssetContentMarkers.findIndex((marker) => marker === ContentMarkerType.Live);
            if (liveMarkerIndex !== -1) {
                availableAssetContentMarkers.splice(liveMarkerIndex, 1);
            }
        }

        if (availableAssetContentMarkers?.some((marker) => marker === ContentMarkerType.Recorded)) {
            return {
                marker: ContentMarkerType.Recorded,
                label: getTimeGapInDays(selectRecordingAvailableUntil(asset.id)(store.getState())),
            };
        }

        if (!availableAssetContentMarkers?.length) return null;

        if (availableAssetContentMarkers?.some((marker) => marker === ContentMarkerType.Rented)) {
            return { marker: ContentMarkerType.Rented, label: rentLeft };
        }

        return { marker: availableAssetContentMarkers[0] };
    }

    render() {
        const { className, asset, hideNextMarker, forceMarker } = this.props;

        if (!asset && !forceMarker) {
            return null;
        }

        const config = this.findMorePrioritizeMarker(asset);
        if (!config) return null;

        if (!config.marker || (config.marker === ContentMarkerType.Next && hideNextMarker)) return null;

        return <ContentMarker className={className} marker={config.marker} label={config.label} />;
    }
}

export default connect((state) => ({
    config: selectConfig(state),
}))(ContentMarkers);
