import { initialFtiState } from '__SMART_APP_OLD__/app/modules/Fti/store/constants';
import { FtiActionType, FtiCompletedTable } from '__SMART_APP_OLD__/app/modules/Fti/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const ftiReducer: Reducer<FtiCompletedTable> = (state, action) => {
    if (!state) return initialFtiState;

    switch (action.type) {
        case FtiActionType.AGE_RATING_STEP_COMPLETED_CHANGED:
            return { ...state, ageRatingStepCompleted: action.payload.value };
        case FtiActionType.COMMUNITY_STEP_COMPLETED_CHANGED:
            return { ...state, communityStepCompleted: action.payload.value };
        case FtiActionType.EDUCATION_STEP_COMPLETED_CHANGED:
            return { ...state, educationStepCompleted: action.payload.value };
        case FtiActionType.LANGUAGE_STEP_COMPLETED_CHANGED:
            return { ...state, languageStepCompleted: action.payload.value };
        case FtiActionType.MASTER_PIN_CODE_STEP_COMPLETED_CHANGED:
            return { ...state, masterPincodeStepCompleted: action.payload.value };
        case FtiActionType.PRIVACY_POLICY_STEP_COMPLETED_CHANGED:
            return { ...state, privacyStepCompleted: action.payload.value };
        case FtiActionType.REPLAY_STEP_COMPLETED_CHANGED:
            return { ...state, replayStepCompleted: action.payload.value };
        case FtiActionType.THEME_STEP_COMPLETED_CHANGED:
            return { ...state, themeStepCompleted: action.payload.value };
        case FtiActionType.STARTUP_STEP_COMPLETED_CHANGED:
            return { ...state, startupStepCompleted: action.payload.value };
        case FtiActionType.CLOSING_STEP_COMPLETED_CHANGED:
            return { ...state, closingStepCompleted: action.payload.value };
        case FtiActionType.WELCOME_STEP_COMPLETED_CHANGED:
            return { ...state, welcomeStepCompleted: action.payload.value };
        case FtiActionType.SETUP_INITIAL_FTI_VALUES_AFTER_AUTHENTICATION:
            return {
                ...state,
                ageRatingStepCompleted: action.payload.ageRatingStepCompleted,
                masterPincodeStepCompleted: action.payload.masterPincodeStepCompleted,
                communityStepCompleted: action.payload.communityStepCompleted,
                replayStepCompleted: action.payload.replayStepCompleted,
                privacyStepCompleted: action.payload.privacyStepCompleted,
            };
        default:
            return state;
    }
};
