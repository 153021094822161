import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type EpisodeInfoFragment = { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null };

export const EpisodeInfoFragmentDoc = gql`
    fragment episodeInfo on EpisodeInfo {
  id
  number
  title
  season
  __typename
}
    `;