import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { RecordingRemoveDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/recordingRemove.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { removeRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getDeleteRecordingEvent } from 'analytics/logging/factories/recordingEventFactory';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

const notificationTextForRecordingStatus = {
    [GQL.RecordingStatus.InProgress]: 'NOTIFICATION_RECORDING_STOPPED',
    [GQL.RecordingStatus.Completed]: 'NOTIFICATION_SINGLE_RECORDING_DELETED',
    [GQL.RecordingStatus.Planned]: 'NOTIFICATION_RECORDING_CANCELLED',
};

const deleteRecording =
    (recordingId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const recordingStatus = selectRecordingStatus(recordingId)(getState());
            const isFuture = recordingStatus === GQL.RecordingStatus.Planned;
            const action = isFuture ? UIActionEvents.CANCEL_RECORDING : UIActionEvents.DELETE_RECORDING;
            const event = getUIActionEvent(action, { id: recordingId, assetType: AssetType.NETWORK_RECORDING });

            const result = await GraphqlClient.makeGraphqlMutationRequest(RecordingRemoveDocument, { input: { recordingId } });
            if (!result || result.errors) throw new Error('mutation failed');

            dispatch(removeRecording([result?.data?.deleteRecording?.event?.id]));
            dispatch(Household.actions.recordingQuotaLoad(result?.data?.deleteRecording?.quota));
            // @ts-ignore
            dispatch(textNotificationShow(notificationTextForRecordingStatus[recordingStatus]));
            LoggingService.getInstance().logEvent(event);
            LoggingService.getInstance().logEvent(getDeleteRecordingEvent(recordingId, false, isFuture));
        } catch {
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_CANCEL_FAILED'));
        }
    };

export const actions = {
    private: {
        deleteRecording,
    },
};
