import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    MessageReadDocument,
    MessageReadMutationResult,
    MessageReadMutationVariables,
} from '__SMART_APP_OLD__/app/api/message/mutation/messageRead.generated';
import {
    MessageRemoveDocument,
    MessageRemoveMutationResult,
    MessageRemoveMutationVariables,
} from '__SMART_APP_OLD__/app/api/message/mutation/messageRemove.generated';
import {
    MessageLoadManyDocument,
    MessageLoadManyFragment,
    MessageLoadManyQueryResult,
    MessageLoadManyQueryVariables,
} from '__SMART_APP_OLD__/app/api/message/query/messageLoadMany.generated';
import {
    MessageLoadOneDocument,
    MessageLoadOneFragment,
    MessageLoadOneQueryResult,
    MessageLoadOneQueryVariables,
} from '__SMART_APP_OLD__/app/api/message/query/messageLoadOne.generated';
import {
    MessageLoadTotalCountDocument,
    MessageLoadTotalCountFragment,
    MessageLoadTotalCountQueryResult,
    MessageLoadTotalCountQueryVariables,
} from '__SMART_APP_OLD__/app/api/message/query/messageLoadTotalCount.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadOneResponse = { message: GQL.Message };

export const __parseLoadOne = (response?: MessageLoadOneFragment): LoadOneResponse => {
    const message = (response?.message ?? {}) as GQL.Message;
    return { message };
};

export const loadOne = async (variables: MessageLoadOneQueryVariables): Promise<LoadOneResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: MessageLoadOneQueryResult = await GraphqlClient.makeGraphqlRequest(MessageLoadOneDocument, variables, options);
    Error.collect(response);

    return __parseLoadOne(response.data);
};

export type LoadManyResponse = { messages: GQL.Message[] };

export const __parseLoadMany = (response?: MessageLoadManyFragment): LoadManyResponse => {
    const messages = (response?.messages?.edges ?? []).map((edge) => edge?.node ?? null).filter(Boolean) as GQL.Message[];
    return { messages };
};

export const loadMany = async (variables: MessageLoadManyQueryVariables): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: MessageLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(MessageLoadManyDocument, variables, options);
    Error.collect(response);

    return __parseLoadMany(response.data);
};

export type LoadTotalCountResponse = { totalCount: number };
export const __parseLoadTotalCount = (response?: MessageLoadTotalCountFragment): LoadTotalCountResponse => {
    const totalCount = response?.messages?.totalCount ?? 0;
    return { totalCount };
};

export const loadTotalCount = async (variables: MessageLoadTotalCountQueryVariables) => {
    const options = { fetchPolicy: 'no-cache' };
    const response: MessageLoadTotalCountQueryResult = await GraphqlClient.makeGraphqlRequest(
        MessageLoadTotalCountDocument,
        variables,
        options
    );
    Error.collect(response);

    return __parseLoadTotalCount(response.data);
};

export type ReadResponse = { messageId: GQL.MessageID };

export const read = async (variables: MessageReadMutationVariables): Promise<ReadResponse> => {
    const response: MessageReadMutationResult = await GraphqlClient.makeGraphqlMutationRequest(MessageReadDocument, variables);
    Error.collect(response);

    return { messageId: variables.messageReadInput.messageId };
};

export type RemoveResponse = { messageId: GQL.MessageID };

export const remove = async (variables: MessageRemoveMutationVariables): Promise<RemoveResponse> => {
    const response: MessageRemoveMutationResult = await GraphqlClient.makeGraphqlMutationRequest(MessageRemoveDocument, variables);
    Error.collect(response);

    return { messageId: variables.messageRemoveInput.messageId };
};
