import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListChangeMutationVariables = Types.Exact<{
  channelListChangeInput: Types.ChangeInitialChannelListInput;
}>;


export type ChannelListChangeMutation = { __typename?: 'Nexx4Mutations', changeInitialChannelList: { __typename?: 'ChangeInitialChannelListPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } } };

export type ChannelListChangeFragment = { __typename?: 'Nexx4Mutations', changeInitialChannelList: { __typename?: 'ChangeInitialChannelListPayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } } };

export const ChannelListChangeFragmentDoc = gql`
    fragment channelListChange on Nexx4Mutations {
  changeInitialChannelList(input: $channelListChangeInput) {
    channelList {
      ...channelList
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListChangeDocument = gql`
    mutation channelListChange($channelListChangeInput: ChangeInitialChannelListInput!) {
  ...channelListChange
}
    ${ChannelListChangeFragmentDoc}`;
export type ChannelListChangeMutationFn = Apollo.MutationFunction<ChannelListChangeMutation, ChannelListChangeMutationVariables>;

/**
 * __useChannelListChangeMutation__
 *
 * To run a mutation, you first call `useChannelListChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListChangeMutation, { data, loading, error }] = useChannelListChangeMutation({
 *   variables: {
 *      channelListChangeInput: // value for 'channelListChangeInput'
 *   },
 * });
 */
export function useChannelListChangeMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListChangeMutation, ChannelListChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListChangeMutation, ChannelListChangeMutationVariables>(ChannelListChangeDocument, options);
      }
export type ChannelListChangeMutationHookResult = ReturnType<typeof useChannelListChangeMutation>;
export type ChannelListChangeMutationResult = Apollo.MutationResult<ChannelListChangeMutation>;
export type ChannelListChangeMutationOptions = Apollo.BaseMutationOptions<ChannelListChangeMutation, ChannelListChangeMutationVariables>;