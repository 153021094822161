import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';

export const LoginConstants = {
    Elements: {
        Email: [
            LoginElement.FlowSwitch,
            LoginElement.UserNameField,
            LoginElement.PasswordField,
            LoginElement.PasswordSwitch,
            LoginElement.Submit,
        ],
        Code: [LoginElement.FlowSwitch],
    },
};
