import React, { useContext } from 'react';

import { ActionsContext } from '__SMART_APP_OLD__/app/components/CollectionV2/CollectionV2HeaderActionsContext';

import { UI } from 'App/Packages/UI';

type Props = {
    children: UI.ChildRenderer<string>;
};

export type CollectionV2HeaderActionsChildrenModule = React.FunctionComponent<Props>;

export const CollectionV2HeaderActionsChildren: CollectionV2HeaderActionsChildrenModule = (props) => {
    const { items } = useContext(ActionsContext);

    return <UI.Children items={items}>{props.children}</UI.Children>;
};
