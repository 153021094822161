import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Alert } from 'App/Modules/Alert';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

const actionsIds = [Translation.Key.Ok];

export const View: React.FunctionComponent<Alert.Props<Alert.Type.Basic>> = (props) => {
    const dispatch = useDispatch();

    return (
        <UI.Alert zIndex={Alert.zIndex} onClose={() => dispatch(Alert.actions.unmount())}>
            <UI.Alert.Title>{props.title}</UI.Alert.Title>
            <UI.Alert.Text>{props.text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action id={Translation.Key.Ok}>{Translation.Key.Ok}</UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
