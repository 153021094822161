import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { getTimeGapInDays } from '__SMART_APP_OLD__/utils/timeUtils';

import { Folder } from 'App/Modules/Folder/index';
import { UI } from 'App/Packages/UI';
import { ContentMarkerType } from 'App/Types/ContentMarker';

type LandscapeCardContentMarkerProps = {
    selector: Selector<Folder.Item | null>;
};

const LandscapeCardContentMarker: React.FunctionComponent<LandscapeCardContentMarkerProps> = (props) => {
    const marker = useSelector(Folder.selectors.item.selectContentMarker(props.selector));
    const availableUntil = useSelector(Folder.selectors.item.selectAvailableUntil(props.selector));

    if (!marker) return null;

    if (!availableUntil || marker === ContentMarkerType.Live) return <UI.Folder.Card.Landscape.Content.Meta.Marker marker={marker} />;

    return <UI.Folder.Card.Landscape.Content.Meta.Marker marker={marker} label={getTimeGapInDays(availableUntil)} />;
};

type LandscapeCardProgressProps = {
    selector: Selector<Folder.Item | null>;
};

const LandscapeCardProgress: React.FunctionComponent<LandscapeCardProgressProps> = (props) => {
    const progress = useSelector(Folder.selectors.item.selectProgress(props.selector));
    const color = useSelector(Folder.selectors.item.selectProgressColor(props.selector));

    if (!progress) return null;

    return <UI.Folder.Card.Landscape.Content.Meta.Progress progress={progress} color={color} />;
};

export type LandscapeCardProps = {
    selector: Selector<Folder.Item | null>;
    onFocus?: (id: string) => void;
};

export const LandscapeCard: React.FunctionComponent<LandscapeCardProps> = (props) => {
    const item = useSelector(props.selector);
    const icons = useSelector(Folder.selectors.item.selectIcons(props.selector));
    const recordingStatus = useSelector(Folder.selectors.item.selectRecordingStatus(props.selector));

    if (!item) return null;

    return (
        <UI.Folder.Card.Landscape
            id={item.id}
            parentalRating={item.metadata.parentalRatingId}
            sessionType={item.metadata.sessionType}
            onFocus={props.onFocus}
        >
            <UI.Folder.Card.Landscape.Thumbnail src={item.content.thumbnail} />
            <UI.Folder.Card.Landscape.Logo id={item.metadata.providerId} />
            <UI.Folder.Card.Landscape.Content>
                <UI.Folder.Card.Landscape.Content.Meta>
                    <LandscapeCardContentMarker selector={props.selector} />
                    <LandscapeCardProgress selector={props.selector} />
                </UI.Folder.Card.Landscape.Content.Meta>
                <UI.Folder.Card.Landscape.Content.Header>
                    <UI.Folder.Card.Landscape.Content.Header.Title>{item.content.title}</UI.Folder.Card.Landscape.Content.Header.Title>
                    {recordingStatus && <UI.Folder.Card.Landscape.Content.Header.Icon.Recording status={recordingStatus} />}
                    {recordingStatus === null &&
                        icons.map((icon) => <UI.Folder.Card.Landscape.Content.Header.Icon key={icon} name={icon} />)}
                </UI.Folder.Card.Landscape.Content.Header>
                <UI.Folder.Card.Landscape.Content.Description>{item.content.info}</UI.Folder.Card.Landscape.Content.Description>
            </UI.Folder.Card.Landscape.Content>
        </UI.Folder.Card.Landscape>
    );
};
