import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import { Mask } from 'App/Packages/UI/Mask';
import React from 'react';

export type DetailProps = {
    parentalRating?: string;
    offsetTop?: number;
} & DivPropsExtendable;

export type DetailComponent = React.FunctionComponent<DetailProps>;

export const Detail: DetailComponent = (props) => {
    const { parentalRating, offsetTop, style, children, ...rest } = props;
    return (
        <Mask.Provider parentalRatingId={parentalRating}>
            <Div
                defaultClassName="detail"
                style={{
                    ...style,
                    marginTop: `-${offsetTop}px`,
                }}
                {...rest}
            >
                {children}
            </Div>
        </Mask.Provider>
    );
};
