import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ReminderFragment = { __typename: 'Reminder', id: string, event: { __typename: 'Event', id: string, start: any, title: string, channel?: { __typename?: 'Channel', id: string } | null } };

export const ReminderFragmentDoc = gql`
    fragment reminder on Reminder {
  id
  event {
    id
    start
    title
    channel {
      id
    }
    __typename
  }
  __typename
}
    `;