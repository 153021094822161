import { Direction } from 'App/Packages/Direction';

export class NavigationEvent {
    public readonly x: Direction.X = 0;

    public readonly y: Direction.Y = 0;

    public readonly isNavigationEvent: boolean = true;

    public constructor(x: Direction.X, y: Direction.Y) {
        this.x = x;
        this.y = y;
    }
}
