import { KeySpace as Root, KeySpaceComponent, KeySpaceProps } from 'App/Packages/UI/Keyboard/Components/Key/Space/KeySpace';
import {
    KeySpaceIcon,
    KeySpaceIconInsance,
    KeySpaceIconComponent,
    KeySpaceIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/Space/KeySpaceIcon';

export type { KeySpaceComponent, KeySpaceProps, KeySpaceIconComponent, KeySpaceIconProps };

const KeySpace = Object.assign(Root, {
    Icon: Object.assign(KeySpaceIcon, {
        Instance: KeySpaceIconInsance,
    }),
});

export { KeySpace };
