import React, { useCallback, useState } from 'react';

import { ImageFlavour } from '__SMART_APP_OLD__/api/graphql/types';
import { ReactComponent as LockIcon } from '__SMART_APP_OLD__/app/components/Icon/icons/lockClosed/positive.svg';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIsReminderSetForEvent } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { useIsLive } from '__SMART_APP_OLD__/common/useIsLive';
import ContentMarkers from '__SMART_APP_OLD__/components/common/ContentMarkers';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import LiveProgressBar from '__SMART_APP_OLD__/components/common/LiveProgressBar';
import StaticProgressBar from '__SMART_APP_OLD__/components/common/StaticProgressBar';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

import { Function } from 'App/Packages/Function';

import { InnerHTML } from '../InnerHTML';

/**
 * @description Function that render the Landscape metadata. Using it to avoid duplicate code
 * @param title
 * @param data
 * @param withOnlyFolderIcon
 * @param isReminderSet
 * @param metaInfo
 * @param isFocused
 * @returns Landscape metadata
 */
function renderLandscapeMetaData(title, data, withOnlyFolderIcon, isReminderSet, metaInfo, isFocused) {
    return (
        <div className="landscape-metadata">
            <div className="landscape-metadata-header">
                <Text
                    className="landscape-title"
                    typeface={Typeface.SERIF}
                    size={FontSize.BODY_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {title}
                </Text>
                <div className="icons">
                    <Icons
                        assetType={data?.assetType}
                        isCatchup={data?.catchupTV}
                        isDolby={data?.streamInfo?.isDolby}
                        isRecording={!withOnlyFolderIcon && data?.isRecording}
                        isReminderSet={!withOnlyFolderIcon && isReminderSet}
                        status={data?.status}
                    />
                </div>
            </div>
            {isFocused && metaInfo && (
                <Text
                    className="landscape-info"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    <InnerHTML html={metaInfo} />
                </Text>
            )}
        </div>
    );
}

const LandscapeCard = React.forwardRef(
    // eslint-disable-next-line complexity
    (
        {
            isSeeAllCard = false,
            data,
            onFocus = Function.noop,
            onEnter,
            onKey,
            isRelated,
            id,
            dataTestId,
            isCollection,
            isLandscapeType,
            hideNowPlayingMarker,
        },
        ref
    ) => {
        const [isFocused, setIsFocused] = useState(false);

        const handleFocus = useCallback(() => {
            onFocus();
            setIsFocused(true);
        }, [onFocus]);

        const handleBlur = useCallback(() => {
            setIsFocused(false);
        }, []);
        const {
            image,
            title,
            rawData,
            shortMetaInfo,
            progress,
            showPlay,
            channelLogo,
            assetType,
            relatedMetaInfo,
            startTime,
            endTime,
            shouldMask,
        } = data;

        const metaInfo = isRelated || assetType === AssetType.EPISODE ? relatedMetaInfo : shortMetaInfo;
        const isLive = useIsLive(new Date(startTime), new Date(endTime));
        const isReminderSet = useSelector(selectIsReminderSetForEvent(data?.id));
        const withOnlyFolderIcon =
            assetType === AssetType.VOD_FOLDER ||
            assetType === AssetType.VOD_SERIES ||
            assetType === AssetType.SERIES ||
            assetType === AssetType.SEASON;

        const isChannel = assetType === AssetType.CHANNEL;
        const imageHandler = isChannel ? rawData?.logo?.url : image;
        const isSubscribed = isChannel && (rawData?.userInfo?.subscribed ?? false);

        return (
            <Focusable
                className="landscape-card"
                onEnter={onEnter}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKey={onKey}
                ref={ref}
                focusDelay={false}
                id={id}
                dataTestId={dataTestId}
            >
                <div
                    className={`card-inner-container ${isChannel && isSubscribed ? 'unlockedBackground' : ''} ${
                        isChannel && !isSubscribed ? 'lockedBackground' : ''
                    }`}
                >
                    {!isSeeAllCard ? (
                        <>
                            <div className="landscape-cover-area">
                                <div className="landscape-cover">
                                    <CoverImageWithFallback
                                        src={imageHandler}
                                        alt={title}
                                        mask={shouldMask}
                                        forceLoading={!data || data?.isLoadingCell}
                                    />
                                    {isCollection && !isLandscapeType && !shouldMask && (
                                        <>
                                            <div className="landscape-overlay"></div>
                                            <div className="image-container-inner">
                                                <CoverImageWithFallback
                                                    className="card-image"
                                                    src={imageHandler}
                                                    alt={title}
                                                    mask={shouldMask}
                                                    objectFit="contain"
                                                    forceLoading={!data || data?.isLoadingCell}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {assetType === 'movie' && (
                                        <>
                                            <div className="overlay" />
                                            <CoverImageWithFallback
                                                className="card-image"
                                                src={imageHandler}
                                                alt={title}
                                                mask={shouldMask}
                                                forceLoading={!data || data?.isLoadingCell}
                                                objectFit="contain"
                                            />
                                        </>
                                    )}
                                    {isChannel && (
                                        <>
                                            <CoverImageWithFallback
                                                className={`card-image search-channel ${!isSubscribed ? 'lockedBackground' : ''}`}
                                                src={imageHandler}
                                                alt={title}
                                                mask={shouldMask || false}
                                                subscribed={isSubscribed}
                                                forceLoading={!data || data?.isLoadingCell}
                                                objectFit="contain"
                                            />
                                        </>
                                    )}
                                </div>
                                {showPlay && (
                                    <div className="asset-card-play-button">
                                        <div className="play-icon" />
                                    </div>
                                )}
                                {channelLogo?.[ImageFlavour.Inverted] && (
                                    <img className="logo" src={channelLogo?.[ImageFlavour.Inverted]} alt="channel-logo" />
                                )}
                                {isChannel && !isSubscribed && (
                                    <>
                                        <div className={`${!isSubscribed ? 'locked' : ''}`} />
                                        <LockIcon className="lock-icon" />
                                    </>
                                )}
                                <div className="cover-overlay">
                                    <ContentMarkers
                                        className="landscape"
                                        asset={data}
                                        hideNowPlayingMarker={hideNowPlayingMarker}
                                        hideLiveMarker={!isLive}
                                    />
                                    {isLive ? (
                                        <LiveProgressBar startTime={startTime} endTime={endTime} />
                                    ) : (
                                        <StaticProgressBar percentage={progress} />
                                    )}
                                </div>
                            </div>
                            {!isChannel && renderLandscapeMetaData(title, data, withOnlyFolderIcon, isReminderSet, metaInfo, isFocused)}

                            {isChannel &&
                                isFocused &&
                                isSubscribed &&
                                renderLandscapeMetaData(title, data, withOnlyFolderIcon, isReminderSet, metaInfo, isFocused)}
                        </>
                    ) : (
                        <Text
                            className="see-all"
                            typeface={Typeface.SANS}
                            size={FontSize.BODY_1}
                            weight={FontWeight.BOLD}
                            color={Color.PRIMARY}
                        >
                            {title}
                        </Text>
                    )}
                </div>
            </Focusable>
        );
    }
);

LandscapeCard.displayName = 'LandscapeCard';

export default LandscapeCard;
