import { Calc } from 'App/Packages/Calc';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UseListReturnType } from 'App/Packages/Focus/Hooks/Types';

type AdjacentIndexInterceptor = <T extends string = string>(
    firstVisibleIndex: number,
    adjacentItemsLength: number,
    adjacentFirstVisibleIndex: number,
    focusedIndex: number,
    adjacentNavigation: UseListReturnType<string>
) => (event: Focus.NavigationEvent, focused: T) => void;

export const adjacentIndexInterceptor: AdjacentIndexInterceptor =
    (firstVisibleIndex, adjacentItemsLength, adjacentFirstVisibleIndex, focusedIndex, adjacentNavigation) => (event) => {
        const fromIndex = focusedIndex - firstVisibleIndex;
        const indexToFocus = Math.min(adjacentItemsLength - 1, adjacentFirstVisibleIndex + fromIndex);

        if (event.y === 0 && event.x !== 0 && event.isNavigationEvent) {
            adjacentNavigation.focusAt(indexToFocus);
        }
    };

type SwappableItemsInterceptor = <T extends string = string>(items: string[], setItems: any) => Focus.Interceptor<T>;

export const swappableItemsInterceptor: SwappableItemsInterceptor = (items, setItems) => (event, focused) => {
    if (event.y === 1) {
        const from = items.indexOf(focused);
        const to = Calc.clamp(0, from + 1, items.length - 1);
        setItems(Function.swap(items, from, to));

        return;
    }
    if (event.y === -1) {
        const from = items.indexOf(focused);
        const to = Calc.clamp(0, from - 1, items.length - 1);
        setItems(Function.swap(items, from, to));
    }
};
