import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { formatDayName } from '__SMART_APP_OLD__/utils/timeUtils';

interface Props {
    date: Date;
}

export const DateCard: React.FunctionComponent<Props> = (props) => (
    <Text
        className="date-card"
        typeface={Typeface.SANS}
        size={FontSize.BODY_1}
        weight={FontWeight.REGULAR}
        color={Color.PRIMARY}
        theme={Theme.Type.Dark}
    >
        {formatDayName(props.date, 'dd MMMM', ', ', true)}
    </Text>
);
