import { selectPrivacyPolicyShowLegacyGdpr } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { TrackViewingBehaviour } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { ConsentTypeForPrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/constants';
import { PrivacyPolicyButtonType, PrivacyPolicyItemType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const save = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    if (selectPrivacyPolicyShowLegacyGdpr(getState())) {
        await dispatch(TrackViewingBehaviour.actions.save());
        return;
    }
    await dispatch(Consent.actions.save());
};

const back = (type: Screen.PrivacyPolicyType): Thunk<Promise<void>> => async (dispatch) => {
    if (type === Screen.PrivacyPolicyType.FTI) {
        dispatch(Fti.actions.back());
        return;
    }
    dispatch(History.actions.pop());
};

const selected = (action: PrivacyPolicyItemType | PrivacyPolicyButtonType): Thunk<void> => (dispatch) => {
    switch (action) {
        case PrivacyPolicyItemType.REQUIRED:
            break;
        case PrivacyPolicyItemType.ADVERTISING:
        case PrivacyPolicyItemType.ANALYTICS:
        case PrivacyPolicyItemType.RECOMMENDATIONS:
        case PrivacyPolicyItemType.USER_BEHAVIOR:
            dispatch(Consent.actions.toggled(ConsentTypeForPrivacyPolicyItemType[action]));
            break;
        case PrivacyPolicyItemType.TRACK_VIEWING_BEHAVIOUR:
            dispatch(TrackViewingBehaviour.actions.toggled());
            break;
        case PrivacyPolicyButtonType.ACCEPT_ALL:
            dispatch(Consent.actions.acceptAll());
            break;
        case PrivacyPolicyButtonType.CONTINUE:
            dispatch(Fti.actions.next(Screen.Type.PRIVACY_POLICY));
            break;
        case PrivacyPolicyButtonType.READ_MORE:
            dispatch(
                Overlay.actions.mount(Overlay.Type.MORE_INFO, {
                    title: 'SETTINGS_PRIVACY_POLICY_TITLE',
                    text: 'SETTINGS_PRIVACY_POLICY_BODY',
                })
            );
            break;
        default:
            break;
    }
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(save());
};

export const privacyPolicyActions = {
    private: {
        back,
        selected,
    },
    lifecycle: {
        mount,
        unmount,
    },
};
