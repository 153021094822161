import { Subscribable } from 'App/Packages/Subscribable';

export default class KeyboardSuggestionElement {
    public readonly value: string;

    public readonly focused: Subscribable.Value<boolean>;

    constructor(value: string = '') {
        this.value = value;
        this.focused = new Subscribable.Value<boolean>(false);
    }

    public focus() {
        this.focused.set(true);
    }

    public blur() {
        this.focused.set(false);
    }
}
