import { selectAvailableAudioLanguages, selectAvailableSubtitleLanguages } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Language as LanguageDetails } from '__SMART_APP_OLD__/app/modules/Config/types';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import {
    selectSelectOverlayRecordingFilterOptions,
    selectSelectOverlayRecordingSortOptions,
    selectSelectOverlaySelectedRecordingFilterOptionIndex,
    selectSelectOverlaySelectedRecordingSortOptionIndex,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import {
    selectTVGuideDatePickerOptions,
    selectTVGuideSelectedDatePickerOptionIndex,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';

import { Overlay } from 'App/Modules/Overlay';

// To be moved to Profile Preferences Module when created
const selectAudioSubtitleOptionsGenerator =
    (selectList: (state: State) => Record<string, LanguageDetails>) =>
    (state: State): ButtonEntity[] => {
        const list = selectList(state);
        return Object.keys(list).map((langCode) => ({
            label: list[langCode].translationKey,
            id: list[langCode].languageCode_639_2T,
        }));
    };

const selectAudioSubtitleOptionIndexGenerator =
    (
        selectList: (state: State) => ButtonEntity[],
        key: 'firstAudioLanguage' | 'secondAudioLanguage' | 'firstSubtitleLanguage' | 'secondSubtitleLanguage'
    ) =>
    (state: State): number => {
        const profilePreferences = Profile.selectors.selectPreferences(state);
        const list = selectList(state);
        const selected = profilePreferences?.[key] ?? '';
        return list.findIndex((item) => item.id === selected);
    };

const selectAudioOptions = selectAudioSubtitleOptionsGenerator(selectAvailableAudioLanguages);
const selectSubtitleOptions = selectAudioSubtitleOptionsGenerator(selectAvailableSubtitleLanguages);

/**
 *
 * !!!IMPORTANT!!!
 *
 * DO NOT ADD SELECT OVERLAY TARGETS
 *
 * CREATE DIFFERENT OVERLAY TYPES INSTEAD
 *
 * EXAMPLE:
 *  - Overlay.Type.CommunitySelect
 *  - Overlay.Type.ThemeSelect
 *  - Overlay.Type.AdultContentSelect
 *  - Overlay.Type.StartUpSelect
 *  - Overlay.Type.MessageFilterSelect
 *  - Overlay.Type.LiveClockSelect
 *  - Overlay.Type.ChannelListSelect
 *
 * Implementaions of these types can be found in src/App/Modules/Overlay/Modules/**
 *
 */

const optionsSelectorForTarget: Record<Overlay.SelectTarget, (state: State) => ButtonEntity[]> = {
    [Overlay.SelectTarget.RECORDING_FILTER]: selectSelectOverlayRecordingFilterOptions,
    [Overlay.SelectTarget.RECORDING_SORT]: selectSelectOverlayRecordingSortOptions,
    [Overlay.SelectTarget.DATE_PICKER]: selectTVGuideDatePickerOptions,
    [Overlay.SelectTarget.LANGUAGE]: Language.selectors.selectOptions,
    [Overlay.SelectTarget.AUDIO_PRIMARY]: selectAudioOptions,
    [Overlay.SelectTarget.AUDIO_SECONDARY]: selectAudioOptions,
    [Overlay.SelectTarget.SUBTITLE_PRIMARY]: selectSubtitleOptions,
    [Overlay.SelectTarget.SUBTITLE_SECONDARY]: selectSubtitleOptions,
};

const selectedSelectorForTarget: Record<Overlay.SelectTarget, (state: State) => number> = {
    [Overlay.SelectTarget.RECORDING_FILTER]: selectSelectOverlaySelectedRecordingFilterOptionIndex,
    [Overlay.SelectTarget.RECORDING_SORT]: selectSelectOverlaySelectedRecordingSortOptionIndex,
    [Overlay.SelectTarget.DATE_PICKER]: selectTVGuideSelectedDatePickerOptionIndex,
    [Overlay.SelectTarget.LANGUAGE]: Language.selectors.selectOptionIndex,
    [Overlay.SelectTarget.AUDIO_PRIMARY]: selectAudioSubtitleOptionIndexGenerator(selectAudioOptions, 'firstAudioLanguage'),
    [Overlay.SelectTarget.AUDIO_SECONDARY]: selectAudioSubtitleOptionIndexGenerator(selectAudioOptions, 'secondAudioLanguage'),
    [Overlay.SelectTarget.SUBTITLE_PRIMARY]: selectAudioSubtitleOptionIndexGenerator(selectSubtitleOptions, 'firstSubtitleLanguage'),
    [Overlay.SelectTarget.SUBTITLE_SECONDARY]: selectAudioSubtitleOptionIndexGenerator(selectSubtitleOptions, 'secondSubtitleLanguage'),
};

const selectOptions =
    (target: Overlay.SelectTarget) =>
    (state: State): ButtonEntity[] =>
        optionsSelectorForTarget[target](state);

const selectSelectedIndex =
    (target: Overlay.SelectTarget) =>
    (state: State): number =>
        selectedSelectorForTarget[target](state);

const selectIsLoading =
    (target: Overlay.SelectTarget) =>
    (state: State): boolean =>
        !optionsSelectorForTarget[target](state).length;

export const selectors = {
    private: {
        selectOptions,
        selectSelectedIndex,
        selectIsLoading,
    },
};
