import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

export type HeaderIconItemChannelListsProps = {};

export type HeaderIconItemChannelListsComponent = React.FunctionComponent<HeaderIconItemChannelListsProps>;

export const HeaderIconItemChannelLists: HeaderIconItemChannelListsComponent = () => {
    const item = HeaderItem.use();
    const isFocused = Subscribable.use(item.focused);
    return <Icon className="header-item__icon header-item__icon--channel-lists" name={IconName.CHANNEL_LIST} isFocused={isFocused} />;
};
