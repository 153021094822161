import { useEffect } from 'react';

import { Function } from 'App/Packages/Function';
import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionList } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';
import { Constants } from 'App/Packages/UI/Suggestion/Constants';

export type UseSuggestionFocused = (value: string) => Subscribable.Value<boolean>;

export const useSuggestionFocused: UseSuggestionFocused = (value) => {
    const list = SuggestionList.use();
    const focused = Subscribable.useValue(false);

    useEffect(() => {
        const isFocused = () => !list.disabled.get() && list.focused.get() === value;
        const update = async () => {
            if (!isFocused()) return focused.set(false);
            await Function.sleep(Constants.Item.Focus.Delay);
            focused.set(isFocused());
            return undefined;
        };

        update();

        const unsubscribers = [list.focused.subscribe(update), list.disabled.subscribe(update)];
        return () => Function.call(...unsubscribers);
    }, [value, focused, list]);

    return focused;
};
