import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import AppConfigJson from "__SMART_APP_OLD__/app/modules/ConfigService/controlCenter.json";

class Preferences {
    prefs = {};

    constructor() {
        this.prefs = Storage.get(Storage.Key.PREFERENCES) || {};
    }

    save() {
        Storage.set(Storage.Key.PREFERENCES, this.prefs);
    }

    unset(key) {
        delete this.prefs[key];
        this.save();
    }

    get diskSpaceManagement() {
        return this.prefs.diskSpaceManagement || AppConfigJson.preferencesDefault.diskSpaceManagement;
    }

    set diskSpaceManagement(value) {
        this.prefs.diskSpaceManagement = value;
        this.save();
    }

    get audioLanguagePrimary() {
        return this.prefs.audioLanguagePrimary;
    }

    set audioLanguagePrimary(value) {
        this.prefs.audioLanguagePrimary = value;
        this.save();
    }

    get audioLanguageSecondary() {
        return this.prefs.audioLanguageSecondary;
    }

    set audioLanguageSecondary(value) {
        this.prefs.audioLanguageSecondary = value;
        this.save();
    }

    get subtitleLanguagePrimary() {
        return this.prefs.subtitleLanguagePrimary;
    }

    set subtitleLanguagePrimary(value) {
        this.prefs.subtitleLanguagePrimary = value;
        this.save();
    }

    get subtitleLanguageSecondary() {
        return this.prefs.subtitleLanguageSecondary;
    }

    set subtitleLanguageSecondary(value) {
        this.prefs.subtitleLanguageSecondary = value;
        this.save();
    }

    get currentChannelSubtitleLanguage() {
        return this.prefs.currentChannelSubtitleLanguage;
    }

    set currentChannelSubtitleLanguage(value) {
        this.prefs.currentChannelSubtitleLanguage = value;
        this.save();
    }

    get currentChannelAudioLanguage() {
        return this.prefs.currentChannelAudioLanguage;
    }

    set currentChannelAudioLanguage(value) {
        this.prefs.currentChannelAudioLanguage = value;
        this.save();
    }

    get bingeWatching() {
        // eslint-disable-next-line no-prototype-builtins
        if (this.prefs.hasOwnProperty('bingeWatching')) {
            return this.prefs.bingeWatching;
        }
        return AppConfigJson.preferencesDefault.bingeWatching;
    }

    set bingeWatching(value) {
        this.prefs.bingeWatching = value;
        this.save();
    }

    get personalizeRecommendations() {
        // eslint-disable-next-line no-prototype-builtins
        if (this.prefs.hasOwnProperty('personalizeRecommendations')) {
            return this.prefs.personalizeRecommendations;
        }
        return AppConfigJson.preferencesDefault.personalizeRecommendations;
    }

    set personalizeRecommendations(value) {
        this.prefs.personalizeRecommendations = value;
        this.save();
    }

    get lastWatchedChannel() {
        // eslint-disable-next-line no-prototype-builtins
        if (this.prefs.hasOwnProperty('lastWatchedChannel')) {
            return this.prefs.lastWatchedChannel;
        }
        return null;
    }

    set lastWatchedChannel(value) {
        this.prefs.lastWatchedChannel = value;
        this.save();
    }

    get channelToBeChanged() {
        return this.prefs.channelToBeChanged || false;
    }

    set channelToBeChanged(value) {
        this.prefs.channelToBeChanged = value;
        this.save();
    }
}

export default new Preferences();
