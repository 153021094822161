import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import { BrandType, DeviceName, GeneralDeviceType, PlatformInterface } from '__SMART_APP_OLD__/app/platform/types';
import { generateUniqueId } from '__SMART_APP_OLD__/utils/generateId';

const BROWSER_OS = 'browser';
const BROWSER_MODEL = '123456789';
const BROWSER_MANUFACTURER = 'pc';
const BROWSER_OS_VERSION = 'n/a';

const getID = (): string => {
    const id = Storage.get(Storage.Key.DEVICE_ID);
    if (id) return id;
    return `${generateUniqueId()}-${generateUniqueId()}-${generateUniqueId()}-${generateUniqueId()}-${generateUniqueId()}`;
};

export const initializeBrowser = (platform: PlatformInterface): Promise<void> => {
    platform.DEVICE_TYPE = GQL.DeviceType.SmartTvTvos;
    platform.GENERAL_DEVICE_TYPE = GeneralDeviceType.PC;
    platform.DEVICE_NAME = DeviceName.PC;
    platform.OS = BROWSER_OS;
    platform.MODEL = BROWSER_MODEL;
    platform.MANUFACTURER = BROWSER_MANUFACTURER;
    platform.OS_VERSION = BROWSER_OS_VERSION;
    platform.ID = getID();
    platform.BRAND = BrandType.GOOGLE;
    Common.attachNetworkStateListener(platform);
    return Promise.resolve();
};
