import React from 'react';

const getClassName = (active: boolean, block: string | undefined) => {
    const classList = ['input-v8__char'];
    if (block) classList.push(`${block}__char`);
    if (active) classList.push('input-v8__char--active');
    if (block && active) classList.push(`${block}__char--active`);
    return classList.join(' ');
};

export type InputCharProps = {
    active: boolean;
    children: string;
    block?: string;
    hidden?: boolean;
};

export const InputChar: React.FunctionComponent<InputCharProps> = (props) => {
    const { active, block, hidden, children } = props;
    const child = children === '' ? '' : hidden ? '•' : children === ' ' ? '\xa0' : children;
    return <span className={getClassName(active, block)}>{child}</span>;
};
