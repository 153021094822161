import React from 'react';

import { Button, ButtonAnimation, ButtonCornersStyle } from '__SMART_APP_OLD__/app/components/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { EducationScreenItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/EducationScreen/EducationScreenItem';
import { EducationScreenCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/EducationScreen/types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const EducationScreenView: React.FunctionComponent<Screen.Props<Screen.Type.EDUCATION>> = (props) => {
    const dispatch = useDispatch();
    const ctx = Focus.useCtx();
    const setCtx = Focus.useSetCtx();

    const { isFocused, onHover } = Focus.useElement({ ctx: EducationScreenCTX.BUTTON });

    const { focused, focusedIndex, focus } = Focus.useList({
        axis: Axis.X,
        ctx: EducationScreenCTX.LIST,
        items: props.content,
    });

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <Layout className="education-screen" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <List
                className="education-screen__list"
                firstVisibleIndex={focusedIndex}
                items={props.content}
                itemType={ListItemType.EDUCATION_ITEM}
                renderer={(item) => (
                    <EducationScreenItem
                        key={item.id}
                        image={item.image}
                        isFocused={item === focused && ctx === EducationScreenCTX.LIST}
                        onHover={() => {
                            focus(item);
                            if (ctx === EducationScreenCTX.LIST) return;
                            dispatch(setCtx(EducationScreenCTX.LIST));
                        }}
                    />
                )}
            />
            <Text
                className="education-screen__title"
                typeface={Typeface.SANS}
                size={FontSize.TITLE_2}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                {focused.title}
            </Text>
            <Text
                className="education-screen__text"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.SECONDARY}
            >
                {focused.text}
            </Text>
            <Button
                id="closing"
                ctx={EducationScreenCTX.BUTTON}
                className="education-screen__button"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                onHover={onHover}
                onEnter={() => dispatch(Fti.actions.next(Screen.Type.EDUCATION))}
                isFocused={isFocused}
            >
                CONTINUE_BUTTON
            </Button>
        </Layout>
    );
};
