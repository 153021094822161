import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeyboardHeaderProps = DivPropsExtendable & {};

export const KeyboardHeader: React.FunctionComponent<KeyboardHeaderProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Div defaultClassName="keyboard__header" {...rest}>
            {children}
        </Div>
    );
};
