import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ConsentFragment = { __typename?: 'ProfileConsent', accepted: boolean, type: any };

export const ConsentFragmentDoc = gql`
    fragment consent on ProfileConsent {
  accepted
  type
}
    `;