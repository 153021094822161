import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum SuggestionActionType {
    Loaded = '@data/search/suggestion/loaded',
    Clear = '@data/search/suggestion/clear',
}

export type SuggestionReduxAction = SuggestionLoadedAction | SuggestionClearAction;

export type SuggestionLoadedActionPayload = { suggestions: string[] };
export type SuggestionLoadedAction = PayloadedAction<SuggestionActionType.Loaded, SuggestionLoadedActionPayload>;

export type SuggestionClearAction = BaseAction<SuggestionActionType.Clear>;

export type SuggestionState = string[];
