import { Suggestion as Root } from 'App/Packages/UI/Suggestion/Components/Suggestion';
import { SuggestionList as ListModule } from 'App/Packages/UI/Suggestion/Modules/SuggestionList';
import { Suggestion as RootModule } from 'App/Packages/UI/Suggestion/Modules/Suggestion';
import { SuggestionList } from 'App/Packages/UI/Suggestion/Components/SuggestionList';
import { SuggestionListBase } from 'App/Packages/UI/Suggestion/Base/SuggestionListBase';
import { SuggestionBase } from 'App/Packages/UI/Suggestion/Base/SuggestionBase';

export const Suggestion = Object.assign(Root, {
    Module: Object.assign(RootModule, {
        List: ListModule,
    }),

    Base: Object.assign(SuggestionBase, {
        List: SuggestionListBase,
    }),

    List: SuggestionList,
});
