import isEmpty from 'lodash.isempty';

import { CatchupEventMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/catchupEvent.generated';
import { PauseLiveChannelMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/pauseLiveTv.generated';
import { PlayRecordingMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/playRecording.generated';
import { PlayTrailerMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/playTrailer.generated';
import { PlayVodAssetMutationVariables } from '__SMART_APP_OLD__/api/graphql/mutation/playVODAsset.generated';
import {
    AdPlaybackRestriction,
    ChannelPlaybackInfo,
    HttpHeartbeat,
    Maybe,
    PersonalChannelInfo,
    TimeshiftPlaybackInfo,
    TrailerPlaybackInfo,
    VodPlaybackInfo,
} from '__SMART_APP_OLD__/api/graphql/types';
import {
    pauseLiveEvent,
    playCatchup,
    playChannel,
    playRecording,
    playRestart,
    playTrailer,
    playVODAsset,
    stopPlayback,
} from '__SMART_APP_OLD__/api/Services';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { selectShouldCancelChannelStream } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import Player from '__SMART_APP_OLD__/platforms/Player';

interface graphQLError {
    debugMessage: string;
    errorCode: string;
    message: string;
}

interface SetStreamOptions {
    shouldCheckChannelSwitchRaceCondition?: boolean;
    channelId?: string;
    autoplay?: boolean;
    playedTime?: number | null;
    adPlaybackRestrictions?: Maybe<Maybe<AdPlaybackRestriction>[]>;
    adPlaybackPreRoll?: number | null;
    isLive?: boolean;
    deliveryKind?: string;
    clearAds?: boolean;
}

class PlayerAPI {
    heartbeatIntervalId: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000);

    stopSessionPending: boolean = false;

    // id of channel which was requested to play
    requestedChannelId: undefined | string;

    // status of play channel request (in porgress or not)
    // we should wait playChannel request before sending next,
    // we should use correct play session id for the next playChannel reuquest
    getChannelSessionInProgress: boolean = false;

    get sessionId() {
        return Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID)!;
    }

    set sessionId(value: string | null) {
        Player.videoServerSessionId = value as null;
        Storage.set(Storage.Key.PLAYBACK_SESSION_ID, value);
    }

    stopPreviousPlaySession = () => {
        clearInterval(this.heartbeatIntervalId);
    };

    setChannelStream(channelId = '') {
        this.requestedChannelId = channelId;
        if (!this.getChannelSessionInProgress || this.shouldCancelChannelStream(channelId)) {
            return this.startChannelPlayback(channelId);
        }

        return Promise.resolve();
    }

    shouldCancelChannelStream(channelId: string): boolean {
        return selectShouldCancelChannelStream(channelId)(store.getState());
    }

    // eslint-disable-next-line react-func/max-lines-per-function
    startChannelPlayback(channelId = ''): Promise<void> | undefined {
        this.getChannelSessionInProgress = true;
        this.stopPreviousPlaySession();
        const profileId = Profile.selectors.selectId(store.getState());
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        if (this.shouldCancelChannelStream(channelId)) {
            this.getChannelSessionInProgress = false;
            return undefined;
        }
        const playChannelInput = {
            input: {
                channelId,
                replaceSessionId,
            },
            profileId,
        };
        return playChannel(playChannelInput)
            .then((playbackInfo: ChannelPlaybackInfo): Promise<void> | void | undefined => {
                this.getChannelSessionInProgress = false;
                this.heartbeat(<HttpHeartbeat>playbackInfo?.heartbeat);
                const playbackSessionId = playbackInfo?.sessionId || '';
                this.sessionId = playbackSessionId;
                this.setPrefsTracks(<PersonalChannelInfo>playbackInfo?.channel?.personalInfo);
                if (isEmpty(playbackInfo)) {
                    throw new Error('Request failed and returned empty playbackInfo');
                }
                const playbackUrl = playbackInfo.url;

                if (this.requestedChannelId === playbackInfo?.channel?.id) {
                    const { adPlaybackPreRoll, adPlaybackRestrictions } = playbackInfo;
                    if (this.shouldCancelChannelStream(channelId)) return undefined;
                    return this.setStream(playbackUrl, {
                        channelId,
                        autoplay: true,
                        isLive: true,
                        adPlaybackPreRoll,
                        adPlaybackRestrictions,
                        deliveryKind: playbackInfo.deliveryKind,
                        shouldCheckChannelSwitchRaceCondition: true,
                        clearAds: true,
                    });
                }
                return this.startChannelPlayback(this.requestedChannelId);
            })
            .catch((e: any) => {
                this.getChannelSessionInProgress = false;
                if (e.graphQLErrors) {
                    e.graphQLErrors.map((error: graphQLError) => {
                        const { message, debugMessage, errorCode } = error;
                        const errorString = `[graphQL] An error occured: ${message} with error code: ${errorCode} and debugMessage: ${debugMessage}`;
                        console.log(errorString);
                        return { message, debugMessage, errorCode };
                    });
                }

                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setRestartStream(eventId: string, playedTime?: number) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        const playRestartInput = {
            input: {
                eventId,
                replaceSessionId,
            },
        };
        return playRestart(playRestartInput)
            .then((playbackInfo: TimeshiftPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('Restart is not available');
                }
                const playbackUrl = playbackInfo.url;

                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                return this.setStream(playbackUrl, {
                    autoplay: true,
                    playedTime,
                    adPlaybackPreRoll,
                    isLive: true,
                    adPlaybackRestrictions,
                    shouldCheckChannelSwitchRaceCondition: false,
                    deliveryKind,
                    clearAds: false,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setCatchupStream(eventId: string) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        const playCatchupInput: CatchupEventMutationVariables = {
            input: {
                eventId,
                replaceSessionId,
            },
        };
        return playCatchup(playCatchupInput)
            .then((playbackInfo: TimeshiftPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('Catchup is not available');
                }
                const playbackUrl = playbackInfo.url;
                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                return this.setStream(playbackUrl, {
                    adPlaybackPreRoll,
                    adPlaybackRestrictions,
                    isLive: false,
                    autoplay: true,
                    shouldCheckChannelSwitchRaceCondition: false,
                    deliveryKind,
                    clearAds: true,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setRecordingStream(recordingId: string) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        const playRecordingInput: PlayRecordingMutationVariables = {
            input: {
                recordingId,
                replaceSessionId,
            },
        };
        return playRecording(playRecordingInput)
            .then((playbackInfo: TimeshiftPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('NetworkRecording is not available');
                }
                const playbackUrl = playbackInfo.url;
                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                return this.setStream(playbackUrl, {
                    adPlaybackPreRoll,
                    adPlaybackRestrictions,
                    isLive: false,
                    autoplay: true,
                    deliveryKind,
                    clearAds: true,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setVODStream(vodAssetId: string, vodAssetEntitlementId: string) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        const playVodInput: PlayVodAssetMutationVariables = {
            input: {
                replaceSessionId,
                vodAssetEntitlementId,
                vodAssetId,
            },
        };
        return playVODAsset(playVodInput)
            .then((playbackInfo: VodPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('VOD is not available');
                }
                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const playbackUrl = playbackInfo.url;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                return this.setStream(playbackUrl, {
                    adPlaybackPreRoll,
                    adPlaybackRestrictions,
                    isLive: false,
                    autoplay: true,
                    deliveryKind,
                    clearAds: true,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setTrailerStream(trailerId: string) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        const playTrailerInput: PlayTrailerMutationVariables = {
            input: {
                replaceSessionId,
                trailerId,
            },
        };
        return playTrailer(playTrailerInput)
            .then((playbackInfo: TrailerPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('Trailer is not available');
                }
                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                return this.setStream(playbackInfo.url, {
                    adPlaybackPreRoll,
                    adPlaybackRestrictions,
                    isLive: false,
                    autoplay: true,
                    deliveryKind,
                    clearAds: true,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setPauseLiveStream(variables: PauseLiveChannelMutationVariables) {
        this.stopPreviousPlaySession();
        const replaceSessionId = Storage.get<string>(Storage.Key.PLAYBACK_SESSION_ID);
        variables.input.replaceSessionId = replaceSessionId;
        return pauseLiveEvent(variables)
            .then((playbackInfo: TimeshiftPlaybackInfo) => {
                if (!playbackInfo) {
                    throw new Error('Catchup is not available');
                }
                const playbackUrl = playbackInfo.url;
                this.heartbeat(<HttpHeartbeat>playbackInfo.heartbeat);
                const playbackSessionId = playbackInfo.sessionId || null;
                this.sessionId = playbackSessionId;
                const { adPlaybackPreRoll, adPlaybackRestrictions, deliveryKind } = playbackInfo;
                const playedTime = Player.getPlayedTime() ?? null;

                return this.setStream(playbackUrl, {
                    adPlaybackPreRoll,
                    adPlaybackRestrictions,
                    isLive: true,
                    autoplay: true,
                    shouldCheckChannelSwitchRaceCondition: false,
                    deliveryKind,
                    clearAds: false,
                    playedTime,
                });
            })
            .catch(() => {
                this.onPlayerError();
                Player.setProgram(null);
            });
    }

    setStream(playUrl: string, options: SetStreamOptions = {}) {
        const {
            autoplay = true,
            playedTime,
            deliveryKind,
            isLive,
            adPlaybackPreRoll,
            adPlaybackRestrictions,
            channelId,
            shouldCheckChannelSwitchRaceCondition,
            clearAds,
        } = options;
        Player.setStream(playUrl, {
            channelId,
            autoplay,
            deliveryKind,
            isLive,
            playedTime,
            adPlaybackPreRoll,
            adPlaybackRestrictions,
            shouldCheckChannelSwitchRaceCondition,
            clearAds,
        });
    }

    onPlayerError = () => {
        if (Player.isPlaying()) {
            Player.stop();
        }
        this.sessionId = '';
    };

    heartbeat(heartbeatInfo: HttpHeartbeat | undefined) {
        clearInterval(this.heartbeatIntervalId);
        if (heartbeatInfo) {
            this.heartbeatIntervalId = setInterval(() => {
                if (!heartbeatInfo.url) return;
                fetch(heartbeatInfo.url, { credentials: 'include' }).catch(console.error);
            }, heartbeatInfo.interval * 1000);
        }
    }

    setPrefsTracks(personalInfo: PersonalChannelInfo) {
        Preferences.currentChannelAudioLanguage = personalInfo?.audioLanguage ?? '';
        Preferences.currentChannelSubtitleLanguage = personalInfo?.subtitleLanguage ?? '';
    }

    stopPlayback(stopPlayer = true) {
        if (this.stopSessionPending || !this.sessionId) return null;
        clearInterval(this.heartbeatIntervalId);
        if (stopPlayer) {
            Player.stop();
        }
        this.stopSessionPending = true;

        return stopPlayback(this.sessionId)
            .then(() => {
                this.stopSessionPending = false;
                this.sessionId = '';

                return null;
            })
            .catch((e: any) => {
                this.stopSessionPending = false;
                this.sessionId = '';
                console.log(e);
            });
    }
}

export default new PlayerAPI();
