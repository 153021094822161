import React from 'react';

export type KeyEnterIconProps = {};

export type KeyEnterIconComponent = React.FunctionComponent<KeyEnterIconProps>;

export const KeyEnterIcon: KeyEnterIconComponent = React.memo(() => (
    <svg
        className="key__icon key__icon--stroke key__icon--no-fill"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5 15h18.5m1.5.7L17.5 8m7.5 6.3L17.5 22" strokeWidth="2" />
    </svg>
));

export const KeyEnterIconInsance = <KeyEnterIcon />;
