import React, { useCallback } from 'react';

import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageDetailAttachmentSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/actions';
import { MessageDetailAttachment } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailAttachment';
import {
    selectMessageDetailActiveAttachmenId,
    selectMessageDetailAttachmentsIds,
    selectMessageDetailIsAttachmentView,
    selectMessageDetailIsAttachmentViewAttachmentsVissible,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const MessageDetailAttachments: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const items = useSelector(selectMessageDetailAttachmentsIds);
    const activeId = useSelector(selectMessageDetailActiveAttachmenId);
    const isAttachmentView = useSelector(selectMessageDetailIsAttachmentView);
    const attachmentVissible = useSelector(selectMessageDetailIsAttachmentViewAttachmentsVissible);
    const { focused, focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: MessageDetailCTX.ATTACHMENT,
        axis: Axis.X,
        items,
        initialFocusedIndex: () => Math.max(0, items.indexOf(activeId ?? '')),
        guard: () => !isAttachmentView || (isAttachmentView && attachmentVissible),
    });

    const enterHandler = useCallback((id: GQL.MessageAttachmentID) => dispatch(messageDetailAttachmentSelected(id)), [dispatch]);

    return (
        <List
            className="message-detail__attachments"
            firstVisibleIndex={focusedIndex}
            items={items}
            itemType={ListItemType.MESSAGE_DETAIL_ATTACHMENT}
            renderer={(id) => (
                <MessageDetailAttachment
                    key={id}
                    id={id}
                    ctx={MessageDetailCTX.ATTACHMENT}
                    isFocused={focused === id && !isDisabled}
                    onEnter={enterHandler}
                    onHover={focus}
                />
            )}
        />
    );
};
