import React from 'react';

import { Image, ObjectFit, Orientation, Variant } from '__SMART_APP_OLD__/app/components/Image';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { AssetType, PinAction, Route } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

const FolderCard = React.forwardRef(({ data, onFocus, onKey, dataTestId, shouldMask }, ref) => {
    const { title, image, id, assetType } = data;

    const onEnter = () => {
        switch (assetType) {
            case AssetType.VOD_FOLDER:
                if (data.adult) {
                    openPinOverlay(
                        () => {
                            History.go(`/page/${Route.VOD_FOLDER}/${id}`);
                        },
                        () => {},
                        PinAction.ENTER,
                        PinSessionType.PIN,
                        true
                    );
                } else {
                    History.go(`/page/${Route.VOD_FOLDER}/${id}`);
                }
                break;
            case AssetType.VOD_PRODUCT:
                LoggingService.getInstance().logEvent(
                    getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType: AssetType.VOD_PRODUCT, id })
                );
                History.go(`/details/${AssetType.VOD_PRODUCT}/${id}`);
                break;
            default:
        }
    };

    const imgVariant = (mask, loading) => {
        if (mask) {
            return Variant.ADULT;
        }
        if (loading) {
            return Variant.LOADING;
        }
        return Variant.BASE;
    };

    return (
        <Focusable
            className="folder-card"
            dataTestId={dataTestId}
            onEnter={onEnter}
            onFocus={onFocus}
            onKey={onKey}
            ref={ref}
            focusDelay={false}
        >
            <div className="folder-card-inner">
                <div className="folder-cover">
                    {/* <CoverImageWithFallback
                        objectFit="contain"
                        src={image}
                        mask={shouldMask}
                        alt={title}
                        forceLoading={!data || data?.isLoadingCell}
                    /> */}
                    <Image
                        variant={imgVariant(shouldMask, !data || data?.isLoadingCell)}
                        orientation={Orientation.LANDSCAPE}
                        objectFit={ObjectFit.COVER}
                        src={image}
                    />
                </div>
                <div className="folder-gradient" />
                <Text
                    className="folder-title"
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_1}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                    theme={Theme.Type.Dark}
                >
                    {title}
                </Text>
            </div>
        </Focusable>
    );
});

export default FolderCard;
