import { useCallback } from 'react';

import { FolderItems } from 'App/Packages/UI/Folder/Modules/FolderItems';

export type UseFolderItemFocus = (id: string) => () => void;

export const useFolderItemFocus: UseFolderItemFocus = (id) => {
    const items = FolderItems.use();

    return useCallback(() => items.focus(id), [id, items]);
};
