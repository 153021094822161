import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { Folder } from 'App/Modules/Folder';
import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';

export enum EventActionTypes {
    Load = '@Detail/Event/Load',
    Reset = '@Detail/Event/Reset',
}

export type DetailEventLoadedActionPayload = DetailEventState;

export type DetailEventLoadedAction = PayloadedAction<EventActionTypes.Load, DetailEventLoadedActionPayload>;
export type DetailEventResetAction = BaseAction<EventActionTypes.Reset>;

export type ReduxAction = DetailEventLoadedAction | DetailEventResetAction;

export type DetailEventState = {
    item: DetailItem | null;
    assetObject: any;
    folders: Folder.List;
};
