import React, { useEffect, useRef, useState } from 'react';
import translate from 'language/translate';

import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

type PropType = {
    className: string;
    relatedContent: any;
    currentId: string;
    onFocus: () => void;
    isRelatedContentFocused?: boolean;
    onKey?: (keyCode: number) => void;
    updatePlayerData?: (asset: any) => void;
};

const formatRelatedContent = (contentData: any) =>
    contentData.folders.edges.map((edge: any) => ({
        assets: edge?.node?.firstItems?.edges?.map((item: any) => createAssetObject(item?.node, item?.node?.__typename)),
        type: edge?.node?.kind,
        title: edge?.node?.title,
        stripeId: edge?.node?.id,
    }));

const formatSeries = (seriesData: any) => [
    {
        assets: seriesData,
        type: seriesData[0].assetType,
        title: `${seriesData[0].title} - ${translate('HOVER_SEASON')}${seriesData[0].seasonOrdinal}`,
        stripeId: seriesData[0].id,
    },
];

export const PlayerRelatedContent: React.FC<PropType> = React.forwardRef(
    ({ className, relatedContent, onFocus, onKey, isRelatedContentFocused, updatePlayerData, currentId }, forwardedRef) => {
        const [stripes, setStripes] = useState([]);
        const [episode, setEpisode] = useState<any | null>(null);
        const firstElementNodeRef = useRef(null);
        const nodeRef: React.MutableRefObject<Container | null> = useRef(null);
        const firstStripeScroll: React.MutableRefObject<boolean> = useRef(true);
        const isSeriesSeason = relatedContent[0] && relatedContent[0].assetType === AssetType.EPISODE;
        const currentItemIndex = isSeriesSeason ? relatedContent.findIndex((asset: any) => asset.id === currentId) : 0;

        useEffect(() => {
            const generatedStripes = isSeriesSeason ? formatSeries(relatedContent) : formatRelatedContent(relatedContent);
            setStripes(generatedStripes);
        }, [relatedContent, isSeriesSeason]);

        useEffect(() => {
            if (isRelatedContentFocused) {
                Stripe.clearActiveIndexStore();
                Focus.focus(firstElementNodeRef.current);
            } else {
                firstStripeScroll.current = true;
            }
        }, [isRelatedContentFocused]);

        const onFocusContainer = () => {
            if (typeof onFocus === 'function') {
                onFocus();
            }
            if (!stripes.length) return false;
            if (!isRelatedContentFocused) return false;
            if (!firstStripeScroll.current) return false;
            const { stripeId } = stripes[0];
            const firstStripe = document.getElementById(stripeId);
            if (!firstStripe) return false;
            const childDiv = Array.from(firstStripe.children).find((child) => child.classList.contains('tiles'));
            if (!childDiv) return false;
            firstStripeScroll.current = false;
            const child = childDiv.children?.[currentItemIndex];
            if (!child) return false;
            child.setAttribute('scrollLeft', 'start');
            return true;
        };

        const onAssetChange = ({ activeIndex }: any) => {
            if (!isSeriesSeason) return;
            setEpisode(relatedContent[activeIndex]);
        };

        return (
            <Container ref={nodeRef} className={className}>
                {stripes.map((stripe: any, index: number) => {
                    const { assets } = stripe;
                    const resumePlayback = episode?.id === currentId;

                    return (
                        <Stripe
                            ref={(ref: any) => {
                                if (index === 0 && ref && typeof forwardedRef === 'function') {
                                    if (ref.nodeRef?.nodeRef?.nodeRef?.children[currentItemIndex]) {
                                        const firstElementRef = ref.nodeRef?.nodeRef?.nodeRef?.children[currentItemIndex];
                                        firstElementNodeRef.current = firstElementRef;
                                        forwardedRef(firstElementRef);
                                    }
                                }
                            }}
                            stripeId={stripe.stripeId}
                            isRelated={true}
                            dataTestId="stripe_related"
                            key={`related-stripe-${stripe.stripeId}`}
                            className="stripe related"
                            assets={assets}
                            episode={episode}
                            title={stripe.title}
                            onFocus={onFocusContainer}
                            onFocusChanged={onAssetChange}
                            updatePlayerData={updatePlayerData}
                            resumePlayback={resumePlayback}
                            onKey={onKey}
                            disableAutoFocus
                        />
                    );
                })}
            </Container>
        );
    }
);
