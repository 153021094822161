import { useEffect, useState } from 'react';

import { Value } from 'App/Packages/Subscribable/Core/Value';
import { Selector } from 'App/Packages/Subscribable/Types';

export type UseSelector = <T, R>(subscribable: Value<T>, selector: Selector<T, R>) => R;

export const useSelector: UseSelector = (subscribable, selector) => {
    const [value, setValue] = useState(() => selector(subscribable.get()));
    useEffect(() => {
        setValue(selector(subscribable.get()));
        return subscribable.subscribe(() => setValue(selector(subscribable.get())));
    }, [subscribable, selector]);
    return value;
};
