import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { VodAssetCollectionItemMetadataFragmentDoc } from './vodAssetCollectionItemMetadata.generated';
import { VodAssetCollectionItemEntitlementsFragmentDoc } from './vodAssetCollectionItemEntitlements.generated';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodAssetCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type VodAssetCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type VodAssetCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type VodAssetCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type VodAssetCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type VodAssetCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type VodAssetCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type VodAssetCollectionItem_VodAsset_Fragment = { __typename: 'VODAsset', id: string, title: string, duration: number, metadata: { __typename?: 'Metadata', year?: number | null, shortDescription?: string | null, seriesInfo?: { __typename?: 'SeriesInfo', id: string } | null, genre?: { __typename?: 'Genre', title: string } | null }, vodAssetEntitlements: { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null } | null> }, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodAssetParentalRating: { __typename?: 'ParentalRating', id: string } };

export type VodAssetCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type VodAssetCollectionItem_VodProduct_Fragment = { __typename?: 'VODProduct' };

export type VodAssetCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type VodAssetCollectionItemFragment = VodAssetCollectionItem_Banner_Fragment | VodAssetCollectionItem_Channel_Fragment | VodAssetCollectionItem_ChannelProduct_Fragment | VodAssetCollectionItem_Event_Fragment | VodAssetCollectionItem_NetworkRecording_Fragment | VodAssetCollectionItem_ProductBundle_Fragment | VodAssetCollectionItem_Series_Fragment | VodAssetCollectionItem_VodAsset_Fragment | VodAssetCollectionItem_VodFolder_Fragment | VodAssetCollectionItem_VodProduct_Fragment | VodAssetCollectionItem_VodSeries_Fragment;

export const VodAssetCollectionItemFragmentDoc = gql`
    fragment vodAssetCollectionItem on ContentItem {
  ... on VODAsset {
    id
    title
    duration
    metadata {
      ...vodAssetCollectionItemMetadata
    }
    vodAssetEntitlements: entitlements {
      ...vodAssetCollectionItemEntitlements
    }
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    vodAssetParentalRating: parentalRating {
      ...parentalRatingRelation
    }
    __typename
  }
}
    ${VodAssetCollectionItemMetadataFragmentDoc}
${VodAssetCollectionItemEntitlementsFragmentDoc}
${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;