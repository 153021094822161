import React from 'react';

const Default: CardLandscapeValue = {
    isFocused: false,
};

export type CardLandscapeValue = {
    isFocused: boolean;
};

export type CardLandscapeContextModule = React.Context<CardLandscapeValue> & { Default: CardLandscapeValue };

export const CardLandscapeContext: CardLandscapeContextModule = Object.assign(React.createContext(Default), { Default });
