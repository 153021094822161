import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

interface Props {
    isVisible: boolean;
    isSelected?: boolean;
}

const getClassName = (props: Props): string => {
    const classList = ['landscape-card-v2__select'];
    if (!props.isVisible) classList.push('landscape-card-v2__select--hidden');
    return classList.join(' ');
};

export const LandscapeCardSelector: React.FunctionComponent<Props> = (props) => {
    const className = getClassName(props);
    return (
        <>
            <Div className={className} backgroundColor={BackgroundColor.OVERLAY} shadow={Shadow.NONE} theme={Theme.Type.Dark}>
                {props.isSelected ? (
                    <Icon className="landscape-card-v2__select-icon" name={IconName.OK} flavour={IconFlavour.POSITIVE} />
                ) : (
                    <Icon className="landscape-card-v2__select-icon" name={IconName.CHECKBOX} flavour={IconFlavour.SOLID_DARK} />
                )}
            </Div>
        </>
    );
};
