import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { trackViewingBehaviourApi } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/api';
import { trackViewingBehaviourSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/selectors';
import {
    TrackViewingBehaviourActionType,
    TrackViewingBehaviourChangedAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour/store/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

export const changed = (value: boolean): TrackViewingBehaviourChangedAction => ({
    type: TrackViewingBehaviourActionType.CHANGED,
    payload: { value },
});

export const toggled = (): Thunk<void> => (dispatch, getState) => {
    dispatch(changed(!trackViewingBehaviourSelectors.public.select(getState())));
};

export const save = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const profileId = profileSelectors.public.selectId(state);
    const value = trackViewingBehaviourSelectors.public.select(getState());
    dispatch(changed(trackViewingBehaviourSelectors.public.select(getState())));
    await trackViewingBehaviourApi.change(profileId, value);
};

export const trackViewingBehaviourActions = {
    public: {
        changed,
        toggled,
        save,
    },
    private: {},
};
