import React from 'react';

import { Image, ObjectFit, Orientation } from '__SMART_APP_OLD__/app/components/Image';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageImage } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';

interface Props {
    id: GQL.MessageID;
}

export const MessageInboxItemImageComponent: React.FunctionComponent<Props> = (props) => {
    const image = useSelector(selectMessageImage(props.id));

    return <Image className="message-inbox-item__image" src={image} objectFit={ObjectFit.COVER} orientation={Orientation.LANDSCAPE} />;
};

export const MessageInboxItemImage = React.memo(MessageInboxItemImageComponent);
