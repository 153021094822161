import { Quota } from '__SMART_APP_OLD__/api/graphql/types';
import {
    HouseholdActionType,
    HouseholdInitialLoadPayload,
    InitialLoadAction,
    MqttBrokerUrlChangedAction,
    MqttTopicsChangedAction,
    RecordingQuotaLoadAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/types';

const mqttTopicsChanged = (mqttTopics: string[]): MqttTopicsChangedAction => ({
    type: HouseholdActionType.MQTT_TOPICS_CHANGED,
    payload: { mqttTopics },
});

const mqttBrokerUrlChanged = (mqttBrokerUrl: string): MqttBrokerUrlChangedAction => ({
    type: HouseholdActionType.MQTT_BROKER_URL_CHANGED,
    payload: { mqttBrokerUrl },
});

const recordingQuotaLoad = (recordingQuota: Quota): RecordingQuotaLoadAction => ({
    type: HouseholdActionType.RECORDING_QUOTA_LOAD,
    payload: { recordingQuota },
});

const initialLoad = (payload: HouseholdInitialLoadPayload): InitialLoadAction => ({
    type: HouseholdActionType.INITIAL_LOAD,
    payload,
});

export const householdActions = {
    public: {
        mqttTopicsChanged,
        mqttBrokerUrlChanged,
        recordingQuotaLoad,
        initialLoad,
    },
};
