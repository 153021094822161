import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeyLabelSize = 'medium' | 'small';
export type KeyLabelVariant = 'uppercase' | 'lowercase';

export type KeyLabelProps = DivPropsExtendable & {
    size?: KeyLabelSize;
    variant?: KeyLabelVariant;
};

export type KeyLabelComponent = React.FunctionComponent<KeyLabelProps>;

export const KeyLabel: KeyLabelComponent = (props) => {
    const { size = 'medium', variant = 'lowercase', children, ...other } = props;
    return (
        <Div defaultClassName={`key__label key__label--${size} key__label--${variant}`} {...other}>
            {children}
        </Div>
    );
};
