import * as Types from '../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LanguageChangeMutationVariables = Types.Exact<{
  languageChangeInput: Types.ChangeLanguageInput;
}>;


export type LanguageChangeMutation = { __typename?: 'Nexx4Mutations', changeLanguage: { __typename?: 'ChangeLanguagePayload', device: { __typename?: 'Device', id: string } } };

export type LanguageChangeFragment = { __typename?: 'Nexx4Mutations', changeLanguage: { __typename?: 'ChangeLanguagePayload', device: { __typename?: 'Device', id: string } } };

export const LanguageChangeFragmentDoc = gql`
    fragment languageChange on Nexx4Mutations {
  changeLanguage(input: $languageChangeInput) {
    device {
      id
    }
  }
}
    `;
export const LanguageChangeDocument = gql`
    mutation languageChange($languageChangeInput: ChangeLanguageInput!) {
  ...languageChange
}
    ${LanguageChangeFragmentDoc}`;
export type LanguageChangeMutationFn = Apollo.MutationFunction<LanguageChangeMutation, LanguageChangeMutationVariables>;

/**
 * __useLanguageChangeMutation__
 *
 * To run a mutation, you first call `useLanguageChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLanguageChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [languageChangeMutation, { data, loading, error }] = useLanguageChangeMutation({
 *   variables: {
 *      languageChangeInput: // value for 'languageChangeInput'
 *   },
 * });
 */
export function useLanguageChangeMutation(baseOptions?: Apollo.MutationHookOptions<LanguageChangeMutation, LanguageChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LanguageChangeMutation, LanguageChangeMutationVariables>(LanguageChangeDocument, options);
      }
export type LanguageChangeMutationHookResult = ReturnType<typeof useLanguageChangeMutation>;
export type LanguageChangeMutationResult = Apollo.MutationResult<LanguageChangeMutation>;
export type LanguageChangeMutationOptions = Apollo.BaseMutationOptions<LanguageChangeMutation, LanguageChangeMutationVariables>;