import React from 'react';

import { Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Image, ObjectFit, Orientation } from '__SMART_APP_OLD__/app/components/Image';

export type EducationScreenItemProps = {
    image: string;
    isFocused: boolean;
    onHover: () => void;
};

const getClassName = (isFocused: boolean): string => {
    const classlist = ['education-screen__item'];
    if (isFocused) classlist.push('education-screen__item--focused');
    return classlist.join(' ');
};

export const EducationScreenItem: React.FunctionComponent<EducationScreenItemProps> = (props) => (
    <Div
        className={getClassName(props.isFocused)}
        shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
        onMouseOver={props.onHover}
    >
        <Image
            className="education-screen__image"
            src={props.image}
            width={960}
            height={540}
            objectFit={ObjectFit.CONTAIN}
            orientation={Orientation.LANDSCAPE}
            noLoading
        />
    </Div>
);
