import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';
import React from 'react';

import { playVod } from '__SMART_APP_OLD__/api/Tuner';
import RecordingIcon from '__SMART_APP_OLD__/app/components/Icon/RecordingIcon';
import { LandscapeCard } from '__SMART_APP_OLD__/app/components/LandscapeCard';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { recordingManagementItemSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import {
    selectRecording,
    selectRecordingAvailableUntil,
    selectRecordingManagementChannelId,
    selectRecordingManagementContentMarkerType,
    selectRecordingManagementImage,
    selectRecordingManagementIsRecordingSelected,
    selectRecordingManagementIsTrickMode,
    selectRecordingManagementItemMetaInfo,
    selectRecordingManagementItemTitle,
    selectRecordingManagementProgressBar,
    selectRecordingStatus,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { playerMetaInfoRecording } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/constants';

import { Screen } from 'App/Modules/Screen';

const RecordingManagementCardComponent: React.FunctionComponent<CollectionItemProps> = (props) => {
    const dispatch = useDispatch();
    const availableUntil = useSelector(selectRecordingAvailableUntil(props.id));
    const metaInfo = useSelector(selectRecordingManagementItemMetaInfo(props.id));
    const title = useSelector(selectRecordingManagementItemTitle(props.id));
    const image = useSelector(selectRecordingManagementImage(props.id));
    const channelId = useSelector(selectRecordingManagementChannelId(props.id));
    const [progressBarColor, progress] = useSelector(selectRecordingManagementProgressBar(props.id));
    const isTrickMode = useSelector(selectRecordingManagementIsTrickMode);
    const isSelected = useSelector(selectRecordingManagementIsRecordingSelected(props.id));
    const contentMarker = useSelector(selectRecordingManagementContentMarkerType(props.id));
    const recording = useSelector(selectRecording(props.id));
    const bookmark = useSelector(Bookmarks.selectors.selectEntity(props.id));
    const status = useSelector(selectRecordingStatus(props.id));

    const onEnter = (id: GQL.RecordingID) => {
        if (isTrickMode) {
            dispatch(recordingManagementItemSelected(id));
            return;
        }

        if (status !== GQL.RecordingStatus.Completed) return;
        dispatch(Screen.actions.unmount());

        const asset = {
            ...recording,
            metaInfoPlayer: playerMetaInfoRecording(recording),
            availableContentMarkers: [ContentMarkerType.Recorded],
        };
        playVod(asset, {
            isStartOver: false,
            bookmarkPosition: bookmark?.position || recording.leadIn,
            isTrailer: false,
            isReplaceNeeded: true,
        });
    };

    return (
        <LandscapeCard
            id={props.id}
            ctx={props.ctx}
            className={props.className}
            isFocused={props.isFocused}
            isSelected={isSelected}
            isSelectMode={isTrickMode}
            image={image}
            title={title}
            description={metaInfo}
            channelId={channelId}
            progressBar={!!progress}
            progress={progress}
            progressColor={progressBarColor}
            contentMarker={contentMarker}
            disableAnimation
            onEnter={onEnter}
            onHover={props.onHover}
            availableUntil={availableUntil}
            icon={!!status && <RecordingIcon className="landscape-card-v2__icon" status={status} />}
        />
    );
};

export const RecordingManagementCard = React.memo(RecordingManagementCardComponent);
