import React from 'react';

import Catalog from '__SMART_APP_OLD__/api/Catalog';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import Header from '__SMART_APP_OLD__/components/Header';
import { HomePage } from '__SMART_APP_OLD__/components/pages/HomePage';
import Stripes from '__SMART_APP_OLD__/components/stripe/Stripes';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { NavKey, Route } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Key } from 'App/Modules/Key';
import { UI } from 'App/Packages/UI';

export default class Page extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.brandingRef = React.createRef();
    }

    get isCollection() {
        return this.props.component === NavKey.COLLECTION;
    }

    get subPage() {
        return this.props.computedMatch?.params?.subPageId || false;
    }

    onFocusStripeChange = (activeIndex) => {
        if (!this.brandingRef?.current) return;
        if (activeIndex === 0) {
            this.brandingRef.current.style.display = 'block';
        } else {
            this.brandingRef.current.style.display = 'none';
        }
    };

    subPageTitleOnKey = (key) => key === Key.VK_UP;

    getComponent() {
        const { menuId } = this.state;
        const {
            component,
            computedMatch: {
                params: { pageId, subPageId },
            },
        } = this.props;
        if (!menuId && !this.isCollection && !this.subPage && !pageId) {
            return null;
        }

        const sourcePageId = pageId || menuId;
        // for main menu items decide the used component
        if (!component) {
            switch (pageId) {
                case Route.HOME:
                    return (
                        <HomePage
                            filterId={sourcePageId}
                            configSource={Catalog.getStripesConfig}
                            onFocusStripeChange={this.onFocusStripeChange}
                        />
                    );
                default:
                    break;
            }
        }

        // page with stripes
        return (
            <>
                {subPageId && (
                    <div className="subpage-header">
                        <div className="subpage-title-container">
                            <Focusable
                                className="subpage-title-arrow"
                                onEnter={() => {
                                    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
                                    History.back();
                                }}
                                onKey={this.subPageTitleOnKey}
                            >
                                <div className="arrow icon-arrow-left" />
                            </Focusable>
                            <Text
                                className="subpage-title"
                                typeface={Typeface.SANS}
                                size={FontSize.TITLE_3}
                                weight={FontWeight.BOLD}
                                color={Color.PRIMARY}
                            ></Text>
                        </div>
                        <UI.Clock className="clock" />
                    </div>
                )}
                <Stripes filterId={sourcePageId} configSource={Catalog.getStripesConfig} onFocusStripeChange={this.onFocusStripeChange} />
            </>
        );
    }

    onMenuChange = (menuId) => {
        this.setState({
            menuId,
        });
    };

    get showHeader() {
        return !this.isCollection && !this.subPage;
    }

    render() {
        const {
            computedMatch: {
                params: { pageId, subPageId },
            },
        } = this.props;
        const Component = this.getComponent();

        return (
            <div className={`page ${subPageId || pageId}-page`}>
                {this.showHeader && <Header onMenuChange={this.onMenuChange} pageId={pageId} />}
                {Component}
            </div>
        );
    }
}
