import React, { useEffect } from 'react';

import PlayVod from '__SMART_APP_OLD__/components/PlayVod';
import Player from '__SMART_APP_OLD__/platforms/Player';

type PropType = {
    computedMatch: {
        params: any;
        isExact: boolean;
        path: string;
        url: string;
    };
};

let lastTunedProgram: any = null;
let lastTunedBookmark: number = 0;

export const PlayVodData: React.FC<PropType> = ({ computedMatch }) => {
    const id = computedMatch?.params?.id;
    const type = computedMatch?.params?.type;
    const bookmark = computedMatch?.params?.bookmark;

    if (Player.program && (Player.program as any).id === id) {
        lastTunedProgram = Player.program;
        lastTunedBookmark = bookmark;
    }

    useEffect(
        () => () => {
            lastTunedBookmark = Player.getPlayedTime() || bookmark;
        },
        [bookmark]
    );

    return <PlayVod asset={lastTunedProgram} id={id} type={type} bookmark={lastTunedBookmark} />;
};
