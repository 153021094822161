import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelListFragmentDoc } from '../fragments/channelList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelListRenameMutationVariables = Types.Exact<{
  channelListRenameInput: Types.ChangeChannelListNameInput;
}>;


export type ChannelListRenameMutation = { __typename?: 'Nexx4Mutations', changeChannelListName: { __typename?: 'ChangeChannelListNamePayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } } };

export type ChannelListRenameFragment = { __typename?: 'Nexx4Mutations', changeChannelListName: { __typename?: 'ChangeChannelListNamePayload', channelList: { __typename: 'ChannelList', id: string, name: string, kind: Types.ChannelListKind } } };

export const ChannelListRenameFragmentDoc = gql`
    fragment channelListRename on Nexx4Mutations {
  changeChannelListName(input: $channelListRenameInput) {
    channelList {
      ...channelList
    }
  }
}
    ${ChannelListFragmentDoc}`;
export const ChannelListRenameDocument = gql`
    mutation channelListRename($channelListRenameInput: ChangeChannelListNameInput!) {
  ...channelListRename
}
    ${ChannelListRenameFragmentDoc}`;
export type ChannelListRenameMutationFn = Apollo.MutationFunction<ChannelListRenameMutation, ChannelListRenameMutationVariables>;

/**
 * __useChannelListRenameMutation__
 *
 * To run a mutation, you first call `useChannelListRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelListRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelListRenameMutation, { data, loading, error }] = useChannelListRenameMutation({
 *   variables: {
 *      channelListRenameInput: // value for 'channelListRenameInput'
 *   },
 * });
 */
export function useChannelListRenameMutation(baseOptions?: Apollo.MutationHookOptions<ChannelListRenameMutation, ChannelListRenameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelListRenameMutation, ChannelListRenameMutationVariables>(ChannelListRenameDocument, options);
      }
export type ChannelListRenameMutationHookResult = ReturnType<typeof useChannelListRenameMutation>;
export type ChannelListRenameMutationResult = Apollo.MutationResult<ChannelListRenameMutation>;
export type ChannelListRenameMutationOptions = Apollo.BaseMutationOptions<ChannelListRenameMutation, ChannelListRenameMutationVariables>;