import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectChannelListIds,
    selectChannelListName,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';

import { Overlay } from 'App/Modules/Overlay';
import { Actions } from 'App/Modules/Overlay/Modules/ChannelListSelectOverlay/Store/Actions';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.ChannelListSelect>> = () => {
    const dispatch = useDispatch();
    const selected = useSelector(selectSelectedChannelListId);
    const ids = useSelector(selectChannelListIds);

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={() => dispatch(Overlay.actions.unmount())}>
            <UI.Select.Header>
                <UI.Select.Title>{Translation.Key.Overlay.Select.ChannelLists.Title}</UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options ids={ids} selected={selected} onEnter={(id) => dispatch(Actions.private.selected(id))}>
                <UI.Select.Options.Children>
                    {(id) => (
                        <UI.Select.Option id={id} key={id} disableDismiss>
                            <UI.Selector selector={selectChannelListName(id)} />
                        </UI.Select.Option>
                    )}
                </UI.Select.Options.Children>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
