import { State } from '__SMART_APP_OLD__/app/store/types/state';

const select = (state: State) => state.time;

const selectDate = (state: State): Date => new Date(state.time);

const selectDateEqualityFn = (a: Date, b: Date): boolean => a.getTime() === b.getTime();

const selectString = (state: State) => new Date(state.time).toTimeString().slice(0, 5);

export const timeSelectors = {
    public: {
        select,
        selectDate,
        selectDateEqualityFn,
        selectString,
    },
};
