import React from 'react';

import { useFocusable as useFocusableHook } from 'App/Packages/Focus/Hooks/useFocusable';
import { Subscribable } from 'App/Packages/Subscribable';
import { Card } from 'App/Packages/UI/Card';
import { FolderItem } from 'App/Packages/UI/Folder/Modules/FolderItem';
import { FolderItemProps } from 'App/Packages/UI/Folder/Modules/FolderItem/Types';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

type FolderCardPortraitInternalProps = React.PropsWithChildren<{
    parentalRating?: string;
    sessionType?: PinSessionType;
    disableExpand?: boolean;
    disableScale?: boolean;
    isLarge?: boolean;
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
}>;

const FolderCardPortraitInternal: React.FunctionComponent<FolderCardPortraitInternalProps> = (props) => {
    const item = FolderItem.use();
    const isFocused = Subscribable.use(item.focused);

    const onFocus = (id: string) => {
        item.scrollIntoView();
        if (props.onFocus) props.onFocus(id);
    };

    const onExpand = () => {
        item.scrollIntoView();
    };

    const { onClick, onHover } = useFocusableHook({
        id: item.id,
        ctx: item.ctx,
        isFocused,
        onEnter: item.enter,
        onHover: item.focus,
        onBlur: props.onBlur,
        onFocus,
    });

    return (
        <Card.Portrait
            ref={item.ref}
            className="folder-item"
            isFocused={isFocused}
            parentalRating={props.parentalRating}
            sessionType={props.sessionType}
            disableScale={props.disableScale}
            disableExpand={props.disableExpand}
            isLarge={props.isLarge}
            onClick={onClick}
            onHover={onHover}
            onExpand={onExpand}
        >
            {props.children}
        </Card.Portrait>
    );
};

export type FolderCardPortraitProps = FolderCardPortraitInternalProps & FolderItemProps;

export type FolderCardPortraitComponent = React.FunctionComponent<FolderCardPortraitProps>;

export const FolderCardPortrait: FolderCardPortraitComponent = (props) => (
    <FolderItem id={props.id} offset={props.offset} onEnter={props.onEnter}>
        <FolderCardPortraitInternal
            parentalRating={props.parentalRating}
            disableExpand={props.disableExpand}
            disableScale={props.disableScale}
            isLarge={props.isLarge}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        >
            {props.children}
        </FolderCardPortraitInternal>
    </FolderItem>
);
