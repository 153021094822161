import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Registry } from 'App/Modules/Prompt/Registry';
import { generator } from 'App/Modules/Prompt/Store/generator';
import { selectors } from 'App/Modules/Prompt/Store/selectors';
import { PromptActionType, PromptChangedAction, PromptEntity, PromptProps, PromptType } from 'App/Modules/Prompt/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';

const changed = (prompt: PromptEntity | null): PromptChangedAction => ({
    type: PromptActionType.Changed,
    payload: { prompt },
});

const update =
    <T extends PromptType>(type: T, props: Partial<PromptProps<T>> = {}, ctx?: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const prompt = selectors.public.selectTyped(type)(getState());
        if (!prompt) return;
        const newProps: PromptProps<T> = { ...prompt.props, ...props } as unknown as PromptProps<T>;
        dispatch(changed(generator(type, newProps, ctx ?? prompt.ctx)));
        await dispatch(Registry.get(type)[1].update(newProps, ctx ?? prompt.ctx));
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const prompt = selectors.public.select(getState());
    if (!prompt) return;
    await dispatch(Registry.get(prompt.type)[1].unmount(prompt.props, prompt.ctx));
    dispatch(changed(null));
};

const mount =
    <T extends PromptType>(type: T, props: PromptProps<T>, ctx: string = FocusDefault): Thunk<Promise<void>> =>
    async (dispatch) => {
        await dispatch(unmount());
        dispatch(changed(generator(type, props, ctx)));
        await dispatch(Registry.get(type)[1].mount(props, ctx));
    };

const ctxChanged =
    (type: PromptType, ctx: string): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(update(type, {}, ctx));

const setCtx =
    (ctx: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const prompt = selectors.public.select(getState());
        if (!prompt) return;
        if (prompt.ctx === ctx) return;
        dispatch(ctxChanged(prompt.type, ctx));
    };

export const actions = {
    public: {
        mount,
        unmount,
        update,
        ctx: {
            set: setCtx,
            changed: ctxChanged,
        },
    },
    private: {
        changed,
    },
} as const;
