import { Api } from 'App/Modules/Data/Search/Suggestion/Api';
import {
    SuggestionActionType,
    SuggestionLoadedAction,
    SuggestionClearAction,
    SuggestionState,
} from 'App/Modules/Data/Search/Suggestion/Types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = (suggestions: SuggestionState): SuggestionLoadedAction => ({
    type: SuggestionActionType.Loaded,
    payload: { suggestions },
});

const clear = (): SuggestionClearAction => ({
    type: SuggestionActionType.Clear,
});

const load =
    (input?: string): Thunk<Promise<string[] | null>> =>
    async (dispatch) => {
        try {
            const suggestions = await Api.load(input);
            dispatch(loaded(suggestions));
            return suggestions;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.SearchSuggestionsLoad, error));
            return null;
        }
    };

export const actions = {
    public: {
        load,
        loaded,
        clear,
    },
};
