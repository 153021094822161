import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlayVodAssetMutationVariables = Types.Exact<{
  input: Types.PlayVodAssetInput;
}>;


export type PlayVodAssetMutation = { __typename?: 'Nexx4Mutations', playVODAsset: { __typename?: 'PlayVODAssetPayload', playbackInfo: { __typename?: 'VODPlaybackInfo', deliveryKind: Types.ContentDeliveryKind, url: string, endOfEpisodeOffset?: number | null, sessionId?: string | null, adPlaybackPreRoll?: number | null, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const PlayVodAssetDocument = gql`
    mutation playVODAsset($input: PlayVODAssetInput!) {
  playVODAsset(input: $input) {
    playbackInfo {
      deliveryKind
      url
      endOfEpisodeOffset
      sessionId
      adPlaybackPreRoll
      adPlaybackRestrictions
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type PlayVodAssetMutationFn = Apollo.MutationFunction<PlayVodAssetMutation, PlayVodAssetMutationVariables>;

/**
 * __usePlayVodAssetMutation__
 *
 * To run a mutation, you first call `usePlayVodAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayVodAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playVodAssetMutation, { data, loading, error }] = usePlayVodAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlayVodAssetMutation(baseOptions?: Apollo.MutationHookOptions<PlayVodAssetMutation, PlayVodAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayVodAssetMutation, PlayVodAssetMutationVariables>(PlayVodAssetDocument, options);
      }
export type PlayVodAssetMutationHookResult = ReturnType<typeof usePlayVodAssetMutation>;
export type PlayVodAssetMutationResult = Apollo.MutationResult<PlayVodAssetMutation>;
export type PlayVodAssetMutationOptions = Apollo.BaseMutationOptions<PlayVodAssetMutation, PlayVodAssetMutationVariables>;