import React, { useCallback } from 'react';

import {
    Button,
    ButtonAnimation,
    ButtonCornersStyle,
    ButtonIconFlavour,
    ButtonWidth,
} from '__SMART_APP_OLD__/app/components/Button/Button';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { messageInboxBackTriggered } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import { MessageInboxCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';

import { Focus } from 'App/Packages/Focus';

export const MessageInboxBackButton: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { onHover, isFocused } = Focus.useElement({ ctx: MessageInboxCTX.BACK });

    const enterHandler = useCallback(() => dispatch(messageInboxBackTriggered), [dispatch]);

    return (
        <Button
            id={MessageInboxCTX.BACK}
            ctx={MessageInboxCTX.BACK}
            className="message-inbox__back"
            isFocused={isFocused}
            width={ButtonWidth.FIT}
            corners={ButtonCornersStyle.ROUNDED}
            icon={IconName.LEFT}
            iconFlavour={ButtonIconFlavour.DEFAULT}
            animation={ButtonAnimation.NONE}
            onEnter={enterHandler}
            onHover={onHover}
        />
    );
};
