import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';
import { ReminderEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';

type DetailButtonReminderProps = {
    onEnter: () => void;
    reminder: ReminderEntity | null;
};

const Button: React.FunctionComponent<DetailButtonReminderProps> = (props) => {
    const { reminder } = props;

    const reminderButtonText = reminder ? 'SCREEN_DETAIL_CANCEL_REMINDER_BUTTON' : 'SCREEN_DETAIL_SET_REMINDER_BUTTON';

    return (
        <Folder.Detail.Action id="reminder" icon={IconName.REMINDER} onEnter={props.onEnter}>
            {reminderButtonText}
        </Folder.Detail.Action>
    );
};

export const DetailButtonReminder = hidable(Button);

export type DetailButtonReminderModule = typeof DetailButtonReminder;
