import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ChannelListEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/types';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Cursor, CursorBatch } from 'App/Packages/Cursor';

export const selectChannelListIds = (state: State) => state.data.channelListEntityTable.ids;

export const selectChannelLiveId = (state: State): GQL.ChannelID => state.data.channelEntityTable.liveId;

export const selectChannelListOptions = (state: State): ChannelListEntity[] => {
    const ids = selectChannelListIds(state);
    return ids.map((id) => state.data.channelListEntityTable.entities[id]);
};

export const selectChannelList =
    (id: GQL.ChannelListID) =>
    (state: State): ChannelListEntity | null =>
        state.data.channelListEntityTable.entities[id] ?? null;

export const selectChannelListName = (id: GQL.ChannelListID) => (state: State) => selectChannelList(id)(state)?.name ?? '';

export const selectChannelListChannelIds =
    (channelListId: GQL.ChannelListID) =>
    (state: State): GQL.ChannelID[] =>
        state.data.channelListEntityTable.channelsRelationEntityTable[channelListId] ?? [];

export const selectChannelListChannelIndex =
    (channelListId: GQL.ChannelListID, channelId: GQL.ChannelID) =>
    (state: State): number =>
        selectChannelListChannelIds(channelListId)(state).indexOf(channelId);

export const selectChannelListChannelTotalCount =
    (channelListId: GQL.ChannelListID) =>
    (state: State): number =>
        state.data.channelListEntityTable.channelTotalCountRelationEntityTable[channelListId] ?? 0;

export const selectChannelListChannelNumber =
    (channelListId: GQL.ChannelListID, channelId: GQL.ChannelID) =>
    (state: State): number =>
        state.data.channelListEntityTable.channelNumberRelationEntityTable?.[channelListId]?.[channelId] ?? 0;

export const selectAreChannelListChannelsLoaded =
    (channelListId: GQL.ChannelListID) =>
    (state: State): boolean =>
        !!selectChannelListChannelIds(channelListId)(state).length;

export const selectChannelListLiveChannelId =
    (channelListId: GQL.ChannelListID) =>
    (state: State): GQL.ChannelID => {
        const liveId = selectChannelLiveId(state);
        const channelIds = selectChannelListChannelIds(channelListId)(state);
        if (channelIds.includes(liveId)) return liveId;
        return channelIds[0];
    };

export const selectChannelListChannelBatches = (channelListId: GQL.ChannelListID, channelIds: GQL.ChannelID[]) => (state: State) =>
    channelIds
        .map((id) => selectChannelListChannelIndex(channelListId, id)(state))
        .filter((idx) => idx !== -1)
        .sort((a, b) => b - a)
        .reduce<number[][]>((acc, idx) => (acc[0][0] - 1 === idx ? [[idx, ...acc[0]], ...acc.slice(1)] : [[idx], ...acc]), [[]])
        .filter((arr) => arr.length)
        .reduce<CursorBatch[]>((acc, arr) => [...acc, ...Cursor.batch(arr[0], arr.length)], []);

export const selectSelectedChannelListId = (state: State): GQL.ChannelListID => state.data.channelListEntityTable.selectedId;

export const selectSelectedChannelListChannelIds = (state: State) => selectChannelListChannelIds(selectSelectedChannelListId(state))(state);

export const selectSelectedChannelListLiveChannelId = (state: State): GQL.ChannelID => {
    const selectedId = selectSelectedChannelListId(state);
    return selectChannelListLiveChannelId(selectedId)(state);
};

export const selectChannelListChannelNumberTable = (channelListId: GQL.ChannelListID) => (state: State) =>
    state.data.channelListEntityTable.channelNumberRelationEntityTable?.[channelListId] ?? {};

export const selectSelectedChannelListChannelNumberTable = (state: State) =>
    selectChannelListChannelNumberTable(selectSelectedChannelListId(state))(state);

export const selectSelectedChannelListChannelNumber =
    (channelId: GQL.ChannelID) =>
    (state: State): number =>
        selectSelectedChannelListChannelNumberTable(state)?.[channelId] ?? 0;

export const selectSelectedChannelListName = (state: State) => selectChannelListName(selectSelectedChannelListId(state))(state);

export const selectSelectedChannelListKind = (state: State) => selectChannelListKind(selectSelectedChannelListId(state))(state);

export const selectChannelListKind = (channelListId: GQL.ChannelListID) => (state: State) => selectChannelList(channelListId)(state)?.kind;
