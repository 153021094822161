import { Record } from 'App/Packages/Subscribable/Core/Record';
import { useEffect, useState } from 'react';

export type UseItem = <T>(record: Record<T>, key?: string) => T;

export const useItem: UseItem = (record, key = '') => {
    const [value, setValue] = useState(() => record.item(key));

    useEffect(() => {
        if (!key) return undefined;
        setValue(record.item(key));
        return record.subscribe(() => setValue(record.item(key)));
    }, [record, key]);

    return value;
};
