import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReminderRemoveMutationVariables = Types.Exact<{
  reminderRemoveInput: Types.CancelReminderInput;
}>;


export type ReminderRemoveMutation = { __typename?: 'Nexx4Mutations', cancelReminder: { __typename?: 'CancelReminderPayload', event: { __typename?: 'Event', id: string } } };

export type ReminderRemoveFragment = { __typename?: 'Nexx4Mutations', cancelReminder: { __typename?: 'CancelReminderPayload', event: { __typename?: 'Event', id: string } } };

export const ReminderRemoveFragmentDoc = gql`
    fragment reminderRemove on Nexx4Mutations {
  cancelReminder(input: $reminderRemoveInput) {
    event {
      id
    }
  }
}
    `;
export const ReminderRemoveDocument = gql`
    mutation reminderRemove($reminderRemoveInput: CancelReminderInput!) {
  ...reminderRemove
}
    ${ReminderRemoveFragmentDoc}`;
export type ReminderRemoveMutationFn = Apollo.MutationFunction<ReminderRemoveMutation, ReminderRemoveMutationVariables>;

/**
 * __useReminderRemoveMutation__
 *
 * To run a mutation, you first call `useReminderRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderRemoveMutation, { data, loading, error }] = useReminderRemoveMutation({
 *   variables: {
 *      reminderRemoveInput: // value for 'reminderRemoveInput'
 *   },
 * });
 */
export function useReminderRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ReminderRemoveMutation, ReminderRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReminderRemoveMutation, ReminderRemoveMutationVariables>(ReminderRemoveDocument, options);
      }
export type ReminderRemoveMutationHookResult = ReturnType<typeof useReminderRemoveMutation>;
export type ReminderRemoveMutationResult = Apollo.MutationResult<ReminderRemoveMutation>;
export type ReminderRemoveMutationOptions = Apollo.BaseMutationOptions<ReminderRemoveMutation, ReminderRemoveMutationVariables>;