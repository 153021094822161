import { VodSeriesDetailLoadQueryVariables } from './vodSeriesDetailLoad.generated';

export const DefaultVariables: VodSeriesDetailLoadQueryVariables = {
    vodSeriesId: '',
    profileId: '',
    folderFirst: 100,
    folderAfter: null,
    backgroundWidth: 1920,
    backgroundHeight: 780,
    thumbnailHeight: 280,
};
