import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { communityApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/api';
import { communitySelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/selectors';
import {
    CommunityActionType,
    CommunityChangedAction,
    CommunityEntity,
    CommunityLoadedAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = (communities: CommunityEntity[]): CommunityLoadedAction => ({
    type: CommunityActionType.LOADED,
    payload: { communities },
});

const changed = (communityId: string): CommunityChangedAction => ({
    type: CommunityActionType.CHANGED,
    payload: { communityId },
});

const load = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        if (communitySelectors.public.selectIds(state).length) return true;
        const response = await communityApi.load();
        const ids = selectConfig(state).fti.regionOptionsIdsToFilter;
        const communities = response.communities.filter((c) => !ids.includes(c.id));
        dispatch(changed(response.selected));
        dispatch(loaded(communities));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.COMMUNITY_LOAD, error));
        return false;
    }
};

const change = (communityId: string): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        await communityApi.change(communityId);
        dispatch(changed(communityId));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.COMMUNITY_CHANGE, error));
        return false;
    }
};

export const communityActions = {
    public: {
        load,
        change,
    },
    private: {
        loaded,
        changed,
    },
};
