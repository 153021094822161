import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { messageInboxFilterChanged } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/actions';
import {
    selectMessageInboxFilter,
    selectSelectOverlayMessageInboxFilterOptions,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';

import { Overlay } from 'App/Modules/Overlay';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.MessageFilterSelect>> = () => {
    const dispatch = useDispatch();

    const ids = useSelector(selectSelectOverlayMessageInboxFilterOptions);
    const selected = useSelector(selectMessageInboxFilter);

    return (
        <UI.Select zIndex={Overlay.zIndex} onClose={() => dispatch(Overlay.actions.unmount())}>
            <UI.Select.Header>
                <UI.Select.Title>{Translation.Key.Overlay.Select.MessageFilter.Title}</UI.Select.Title>
            </UI.Select.Header>
            <UI.Select.Options ids={ids} selected={selected} onEnter={(id) => dispatch(messageInboxFilterChanged(id))}>
                <UI.Select.Options.Children>
                    {(id) => (
                        <UI.Select.Option id={id} key={id}>
                            <UI.Translation>{id}</UI.Translation>
                        </UI.Select.Option>
                    )}
                </UI.Select.Options.Children>
            </UI.Select.Options>
            <UI.Select.Footer />
        </UI.Select>
    );
};
