import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { channelListChannelEventLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import {
    selectSelectedChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectEventIsLoading,
    selectEventIsNoInfo,
    selectEventIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

export const toNowOnTV = (channelID?: GQL.ChannelID): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const channelIDs = selectSelectedChannelListChannelIds(state);
    const id = (channelIDs.includes(channelID ?? '') ? channelID : channelIDs[0]) ?? null;
    dispatch(Screen.actions.mount(Screen.Type.NOW_ON_TV, { channelID: id }));
};

export const nowOnTVBackTriggered: Thunk<void> = (dispatch) => {
    dispatch(History.actions.pop());
};

const toPlayerPage = (channelID: GQL.ChannelID): Thunk<void> => (dispatch) => {
    const screen = Screen.generator(Screen.Type.NOW_ON_TV, { channelID });
    dispatch(History.actions.push({ screen, overlay: null }));
    dispatch(Screen.actions.unmount());
    playChannel(channelID);
};

export const nowOnTVItemEnterTriggered = (channelId: GQL.ChannelID, eventId: GQL.EventID): Thunk<void> => (dispatch, getState) => {
    const state = getState();
    const isLoading = selectEventIsLoading(eventId)(state);
    const isNoInfo = selectEventIsNoInfo(eventId)(state);
    if (isLoading || isNoInfo) return;

    const isRestricted = selectEventIsRestricted(eventId)(state);
    if (!isRestricted) {
        dispatch(toPlayerPage(channelId));
        return;
    }

    dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
    openPinOverlay(
        () => dispatch(toPlayerPage(channelId)),
        () => dispatch(Overlay.actions.unmount())
    );
};

export const nowOnTVLoadData = (time: number): Thunk<Promise<boolean>> => (dispatch, getState) => {
    const state = getState();
    const channelListId = selectSelectedChannelListId(state);
    const channelIds = selectSelectedChannelListChannelIds(state);
    return dispatch(channelListChannelEventLoad(channelListId, channelIds, time, time + Time.HOUR_MS));
};
