import { ReminderEntityStatus, ReminderEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';

export const TRIGGER_TS_OFFSET_FOR_REMINDER_ENTITY_STATUS: Record<ReminderEntityStatus, number> = {
    [ReminderEntityStatus.ACTIVE]: 300 * Time.SECOND_MS,
    [ReminderEntityStatus.SNOOZED]: 60 * Time.SECOND_MS,
    [ReminderEntityStatus.DISMISSED]: Time.SECOND_MS,
};

// Lowest value -> highest priority
export const REMINDER_STATUS_PRIORITY: Record<ReminderEntityStatus, number> = {
    [ReminderEntityStatus.DISMISSED]: 1,
    [ReminderEntityStatus.SNOOZED]: 2,
    [ReminderEntityStatus.ACTIVE]: 3,
};

export const initialReminderEntityTableState: ReminderEntityTable = {
    ids: [],
    entities: {},
    statusTable: {},
    triggerTable: {},
    eventReminderRelationTable: {},
};

export const REMINDER_MINIMUM_TIME_BEFORE_TRIGGER = 420000; // 7 minutes
