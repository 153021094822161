import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { CreateNetworkRecordingDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/createRecording.generated';
import { CreateNetworkSeriesRecordingDocument } from '__SMART_APP_OLD__/app/api/recording/mutation/createSeriesRecording.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectEventIsFuture } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { addRecording, addSeriesRecording } from '__SMART_APP_OLD__/app/store/actions/data/recordings/recordings.actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const create =
    (eventId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const variables = {
                input: {
                    eventId,
                },
                backgroundHeight: 1020,
                backgroundWidth: 1920,
            };
            const result = await GraphqlClient.makeGraphqlMutationRequest(CreateNetworkRecordingDocument, variables);
            const { quota, recording } = result.data.createNetworkRecording;
            const isFuture = selectEventIsFuture(eventId)(getState());

            if (!result || result.errors) throw new Error('mutation failed');
            dispatch(addRecording(recording));
            dispatch(Household.actions.recordingQuotaLoad(quota));
            dispatch(textNotificationShow(isFuture ? 'NOTIFICATION_RECORDING_SCHEDULED' : 'NOTIFICATION_RECORDING_STARTED'));
        } catch {
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_START_FAILED'));
        }
    };

const createSeries =
    (eventId: string): Thunk<Promise<void>> =>
    async (dispatch) => {
        try {
            const variables = {
                input: {
                    eventId,
                },
                backgroundHeight: 1020,
                backgroundWidth: 1920,
            };
            const result = await GraphqlClient.makeGraphqlMutationRequest(CreateNetworkSeriesRecordingDocument, variables);
            if (!result || result.errors) throw new Error('mutation failed');
            const recording = result?.data?.createNetworkSeriesRecording?.recordings?.find?.(
                (r: GQL.Recording) => r?.event?.id === eventId
            );

            if (!result || result.errors) throw new Error('mutation failed');
            dispatch(addSeriesRecording(recording));
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_STARTED'));
            dispatch(Household.actions.recordingQuotaLoad(result?.data?.createNetworkSeriesRecording?.quota));
        } catch {
            dispatch(textNotificationShow('NOTIFICATION_RECORDING_START_FAILED'));
        }
    };
export const actions = {
    private: {
        create,
        createSeries,
    },
};
