import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
import { EventEntitlementsFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/event/fragments/eventEntitlements.generated';
export type EventFolderItemFragment = { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null };

export const EventFolderItemFragmentDoc = gql`
    fragment eventFolderItem on Event {
  id
  title
  start
  end
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  parentalRating {
    ...parentalRatingRelation
  }
  channel {
    id
  }
  entitlements {
    ...eventEntitlements
  }
  __typename
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}
${EventEntitlementsFragmentDoc}`;