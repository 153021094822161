import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { selectConfigName } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';

export class Device {
    private readonly contextName: string;

    private readonly contextVersion: string;

    private readonly deviceId: string;

    private readonly deviceModel: string;

    private readonly applicationVersion: string = process.env.REACT_APP_VERSION;

    private readonly applicationName: string = 'SmartTv application';

    private readonly applicationProvider: string = 'A1';

    private readonly deviceControl: string = 'Unmanaged';

    private readonly deviceType: string = 'Smart Tv';

    // it is the same ad devicetype for now but because croatia ...
    private readonly device_category: string = 'Smart Tv';

    private readonly account_code: string = '';

    // Optional props
    private readonly configurationName: string;

    constructor() {
        this.contextName = `${Platform.OS}/${Platform.OS_VERSION}`;
        this.contextVersion = `${Platform.OS}/${Platform.OS_VERSION}`;
        this.deviceId = Platform.ID;
        this.deviceModel = Platform.MODEL;
        this.device_category = Platform.DEVICE_TYPE;
        this.account_code = Storage.get(Storage.Key.HOUSEHOLD_EXTERNAL_ID) || '';
        this.configurationName = selectConfigName(store.getState());
    }
}
