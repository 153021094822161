import React from 'react';

import { FolderItemContext, FolderItemContextModule } from 'App/Packages/UI/Folder/Modules/FolderItem/Context/FolderItemContext';
import { UseFolderItem, useFolderItem } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/useFolderItem';
import { UseFolderItemValue, useFolderItemValue } from 'App/Packages/UI/Folder/Modules/FolderItem/Hooks/useFolderItemValue';

export type FolderItemProps = React.PropsWithChildren<{
    id: string;
    offset?: number;
    onEnter?: (id: string) => void;
}>;

export type FolderItemModule = React.FunctionComponent<FolderItemProps> & {
    // Contexts
    Context: FolderItemContextModule;

    // Hooks
    use: UseFolderItem;
    useValue: UseFolderItemValue;
};

export const FolderItem: FolderItemModule = (props) => {
    const item = FolderItem.useValue({ id: props.id, offset: props.offset, enter: props.onEnter });
    return <FolderItem.Context.Provider value={item}>{props.children}</FolderItem.Context.Provider>;
};

// Contexts
FolderItem.Context = FolderItemContext;

// Hooks
FolderItem.use = useFolderItem;
FolderItem.useValue = useFolderItemValue;
