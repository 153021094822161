import { KeyboardLayoutSchema } from 'App/Modules/Keyboard/types';

export const Symbol: KeyboardLayoutSchema = {
    name: 'symbol',
    language: null,
    keys: [
        [
            { type: 'key.number', value: '1' },
            { type: 'key.number', value: '2' },
            { type: 'key.number', value: '3' },
            { type: 'key.number', value: '4' },
            { type: 'key.number', value: '5' },
            { type: 'key.number', value: '6' },
            { type: 'key.number', value: '7' },
            { type: 'key.number', value: '8' },
            { type: 'key.number', value: '9' },
            { type: 'key.number', value: '0' },
        ],
        [
            { type: 'key.symbol', value: '@' },
            { type: 'key.symbol', value: '#' },
            { type: 'key.symbol', value: '$' },
            { type: 'key.symbol', value: '_' },
            { type: 'key.symbol', value: '&' },
            { type: 'key.symbol', value: '-' },
            { type: 'key.symbol', value: '+' },
            { type: 'key.symbol', value: '(' },
            { type: 'key.symbol', value: ')' },
            { type: 'key.symbol', value: '/' },
        ],
        [
            { type: 'key.symbol', value: '=' },
            { type: 'key.symbol', value: '*' },
            { type: 'key.symbol', value: '"' },
            { type: 'key.symbol', value: "'" },
            { type: 'key.symbol', value: ':' },
            { type: 'key.symbol', value: ';' },
            { type: 'key.symbol', value: '!' },
            { type: 'key.symbol', value: '?' },
            { type: 'key.symbol', value: '%' },
            { type: 'key.backspace' },
        ],
        [
            { type: 'key.layout' },
            { type: 'key.language' },
            { type: 'key.arrow.left' },
            { type: 'key.arrow.right' },
            { type: 'key.space' },
            { type: 'key.symbol', value: ',' },
            { type: 'key.symbol', value: '.' },
            { type: 'key.enter' },
        ],
    ],
};
