import React from 'react';

import { List, ListItemType, ListRenderer } from '__SMART_APP_OLD__/app/components/List';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageInboxItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/MessageInboxItem';
import {
    selectMessageInboxInitialFocusedIndex,
    selectMessageInboxMessagesIds,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';
import { MessageInboxCTX } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const MessageInboxList: React.FunctionComponent = () => {
    const initialFocusedIndex = useSelector(selectMessageInboxInitialFocusedIndex);
    const items = useSelector(selectMessageInboxMessagesIds);
    const { focused, focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: MessageInboxCTX.LIST,
        axis: Axis.Y,
        items,
        initialFocusedIndex,
        resetFocusOnBlur: true,
        resetFocusIndex: () => 0,
    });

    const renderItem: ListRenderer<GQL.MessageID> = (item) => (
        <MessageInboxItem key={item} id={item} isFocused={item === focused && !isDisabled} onHover={focus} />
    );

    return (
        <List
            className="message-inbox__list"
            firstVisibleIndex={focusedIndex - 1}
            itemType={ListItemType.MESSAGE_INBOX_ITEM}
            items={items}
            renderer={renderItem}
        />
    );
};
