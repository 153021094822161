import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { initialChannelEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/constants';
import { ChannelEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const channelEntityTableReducer: Reducer<ChannelEntityTable> = (state, action) => {
    if (!state) return initialChannelEntityTableState;

    switch (action.type) {
        case ActionType.CHANNEL_LOAD_MANY: {
            if (!action.payload.channels.length) return state;
            const ids = Mapper.Common.toIds(action.payload.channels);
            const entities = Mapper.Channel.toEntities(action.payload.channels);
            const audioRelationEntityTable = Mapper.Channel.toAudioRelationEntityTable(action.payload.channels);
            const subtitleRelationEntityTable = Mapper.Channel.toSubtitleRelationEntityTable(action.payload.channels);
            const blockedRelationEntityTable = Mapper.Channel.toBlockedRelationEntityTable(action.payload.channels);
            const replayPermissionRelationEntityTable = Mapper.Channel.toReplayPermissionRelationEntityTable(action.payload.channels);
            return {
                ...state,
                ids,
                entities,
                audioRelationEntityTable,
                subtitleRelationEntityTable,
                blockedRelationEntityTable,
                replayPermissionRelationEntityTable,
            };
        }
        case ActionType.CHANNEL_LIVE_ID_CHANGE: {
            if (action.payload.liveId === state.liveId) return state;
            if (!state.ids.includes(action.payload.liveId)) return state;
            return { ...state, liveId: action.payload.liveId };
        }
        case ActionType.CHANNEL_SWITCH_ID_CHANGE: {
            if (action.payload.channelSwitchId === state.channelSwitchId) return state;
            if (action.payload.channelSwitchId && !state.ids.includes(action.payload.channelSwitchId)) return state;
            return { ...state, channelSwitchId: action.payload.channelSwitchId };
        }
        case ActionType.CHANNEL_AUDIO_CHANGE: {
            if (!state.ids.includes(action.payload.channelId)) return state;
            const audioRelationEntityTable = Mapper.Common.toUpdatedEntities(
                state.audioRelationEntityTable,
                action.payload.channelId,
                action.payload.audio
            );
            return { ...state, audioRelationEntityTable };
        }
        case ActionType.CHANNEL_SUBTITLE_CHANGE: {
            if (!state.ids.includes(action.payload.channelId)) return state;
            const subtitleRelationEntityTable = Mapper.Common.toUpdatedEntities(
                state.subtitleRelationEntityTable,
                action.payload.channelId,
                action.payload.subtitle
            );
            return { ...state, subtitleRelationEntityTable };
        }
        case ActionType.CHANNEL_BLOCKED_CHANGE: {
            if (!state.ids.includes(action.payload.channelId)) return state;
            const blockedRelationEntityTable = Mapper.Common.toUpdatedEntities(
                state.blockedRelationEntityTable,
                action.payload.channelId,
                action.payload.blocked
            );
            return { ...state, blockedRelationEntityTable };
        }
        case ActionType.CHANNEL_REPLAY_PERMISSION_CHANGE: {
            const replayPermissionRelationEntityTable = action.payload.channels;
            return { ...state, replayPermissionRelationEntityTable };
        }
        default:
            return state;
    }
};
