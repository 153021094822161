import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export type SearchNotFoundProps = {};

export const SearchNotFound: React.FunctionComponent<SearchNotFoundProps> = () => (
    <div className="search-not-found">
        <Text
            className="search-not-found__title"
            size={FontSize.TITLE_2}
            typeface={Typeface.SERIF}
            weight={FontWeight.BOLD}
            color={Color.SECONDARY}
        >
            SCREEN_SEARCH_NO_RESULTS
        </Text>
        <Text
            className="search-not-found__hint"
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            SCREEN_SEARCH_NO_RESULTS_SUBTITLE
        </Text>
    </div>
);
