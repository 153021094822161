import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ParentalRatingEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/types';

export const toEntity = (parentalRating: GQL.ParentalRating): ParentalRatingEntity => ({
    id: parentalRating.id,
    title: parentalRating.title,
    description: parentalRating.description,
    adult: parentalRating.adult,
    rank: parentalRating.rank,
});

export const toEntities = (parentalRatings: GQL.ParentalRating[]): Record<GQL.ParentalRatingID, ParentalRatingEntity> =>
    parentalRatings.reduce<Record<GQL.ParentalRatingID, ParentalRatingEntity>>((table, entity) => {
        table[entity.id] = toEntity(entity);
        return table;
    }, {});

export const toIds = (parentalRatings: GQL.ParentalRating[]): GQL.ParentalRatingID[] => parentalRatings.map((entity) => entity.id);
