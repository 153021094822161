import { BaseAction } from '__SMART_APP_OLD__/app/store/types/BaseAction';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

import { OverlayEntity } from 'App/Modules/Overlay/Types';
import { ScreenEntity } from 'App/Modules/Screen/Types';

// Action Type
export enum HistoryActionType {
    PUSH = '@history/push',
    POP = '@history/pop',
    CLEAR = '@history/clear',
}

// Actions
export type HistoryReduxAction = HistoryPushAction | HistoryPopAction | HistoryClearAction;
export type HistoryPushActionPayload = { entity: HistoryEntity };
export type HistoryPushAction = PayloadedAction<HistoryActionType.PUSH, HistoryPushActionPayload>;
export type HistoryPopAction = BaseAction<HistoryActionType.POP>;
export type HistoryClearAction = BaseAction<HistoryActionType.CLEAR>;

// State
export type HistoryEntity = {
    overlay: OverlayEntity | null;
    screen: ScreenEntity | null;
};
