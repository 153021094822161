import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailGradientBottomProps = DivPropsExtendable;

export type DetailGradientBottomComponent = React.FunctionComponent<DetailGradientBottomProps>;

export const DetailGradientBottom: DetailGradientBottomComponent = (props) => {
    return <Div defaultClassName="detail__gradient detail__gradient--bottom" {...props} />;
};
