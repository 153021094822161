import React, { useMemo } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MESSAGE_DETAIL_TEXT_LINE_HEIGHT_VW } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/constants';
import { selectMessageDetailText } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailTextContainer } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

interface Props {
    container: MessageDetailTextContainer;
}

const getStyle = (container: MessageDetailTextContainer): React.CSSProperties =>
    ({
        '--max-lines': container.to,
        height: `${(container.to - container.from) * MESSAGE_DETAIL_TEXT_LINE_HEIGHT_VW}vw`,
    } as React.CSSProperties);

export const MessageDetailText: React.FunctionComponent<Props> = (props) => {
    const text = useSelector(selectMessageDetailText);
    const style = useMemo(() => getStyle(props.container), [props.container]);

    return (
        <div className="message-detail__text-container" style={style}>
            <Text
                className="message-detail__text"
                typeface={Typeface.SANS}
                size={FontSize.BODY_1}
                weight={FontWeight.REGULAR}
                color={Color.PRIMARY}
            >
                {text}
            </Text>
        </div>
    );
};
