class Elements {
    nodes = [];

    stash = {};

    stashIdStack = ['_default_'];

    constructor() {
        window.Elements = this;
    }

    pushStash(focused) {
        const stashId = this.getActiveStash();
        if (!this.stash[stashId]) {
            this.stash[stashId] = {};
        }
        this.stash[stashId].nodes = this.nodes;
        this.stash[stashId].focused = focused;
        this.nodes = [];
    }

    popStash() {
        this.stashIdStack.pop();
        const stashId = this.getActiveStash();
        if (!stashId) return null;
        const { focused } = this.stash[stashId];
        this.nodes = this.stash[stashId].nodes;
        this.stash[stashId] = {};
        return focused;
    }

    updateFocusInStash(node) {
        this.stashIdStack.forEach((stashId) => {
            if (this.stash[stashId] && this.stash[stashId].nodes) {
                for (let i = 0; i < this.stash[stashId].nodes.length; i += 1) {
                    if (this.stash[stashId].nodes[i] === node) {
                        this.stash[stashId].focused = node;
                        return;
                    }
                }
            }
        });
    }

    getActiveStash() {
        return this.stashIdStack.slice(-1)[0];
    }

    /**
     * Necessary to add stashId after pushStash. Focusable element make it after render.
     * During render any stashed elements could add in the this.nodes
     * because new stashId hasn't registered.
     * This is why we need to push stashId immediately
     * @param stashId Stash identification
     */
    addStashId(stashId = '_default_') {
        if (!this.stashIdStack.includes(stashId)) {
            this.stashIdStack.push(stashId);
        }
    }

    add(ref, stashId = '_default_') {
        let activeStash = this.getActiveStash();
        if (!this.stashIdStack.includes(stashId)) {
            this.stashIdStack.push(stashId);
            activeStash = stashId;
        }
        if (activeStash === stashId) {
            this.nodes.push(ref);
        } else {
            this.stash[stashId].nodes.push(ref);
        }
    }

    remove(ref) {
        if (!ref) {
            return;
        }

        for (let i = this.stashIdStack.length - 1; i >= 0; i -= 1) {
            const stashId = this.stashIdStack[i];
            const activeStash = this.getActiveStash();

            if (activeStash === stashId) {
                for (let j = 0; j < this.nodes.length; j += 1) {
                    if (this.nodes[j].nodeRef === ref.nodeRef) {
                        this.nodes.splice(j, 1);
                        return;
                    }
                }
            }

            // check if unmounted nodes exist in the stash
            if (this.stash[stashId]) {
                if (this.stash[stashId].focused === ref) {
                    this.stash[stashId].focused = null;
                }
                if (this.stash[stashId].nodes) {
                    for (let j = 0; j < this.stash[stashId].nodes.length; j += 1) {
                        // eslint-disable-next-line max-depth
                        if (this.stash[stashId].nodes[j].nodeRef === ref.nodeRef) {
                            this.stash[stashId].nodes.splice(j, 1);
                            return;
                        }
                    }
                }
            }
        }
    }

    /**
     * Finds a Node based on a DOM reference
     * @param ref Reference to the DOM node
     * @returns Node if found
     */
    findNodeByRef(ref) {
        for (let i = 0; i < this.nodes?.length; i += 1) {
            if (!this.nodes[i].isContainer && this.nodes[i].nodeRef === ref) {
                return this.nodes[i];
            }
        }
        return null;
    }

    findContainerByRef(ref) {
        for (let i = 0; i < this.nodes?.length; i += 1) {
            if (this.nodes[i].isContainer && this.nodes[i].nodeRef === ref) {
                return this.nodes[i];
            }
        }
        return null;
    }

    preserve() {
        if (!this.savedNodes) {
            this.savedNodes = this.nodes;
            this.nodes = [];
        }
    }

    restore() {
        if (this.savedNodes) {
            this.nodes = this.savedNodes;
            delete this.savedNodes;
        }
    }
}

export default window.Elements = new Elements();
