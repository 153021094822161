import {
    ChannelListsManageScreenActionType,
    ChannelListsManageScreenState,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

const initialState = {
    addedChannelIds: [],
};

export const channelListsScreenManageReducer: Reducer<ChannelListsManageScreenState> = (state, action) => {
    if (!state) return initialState;

    switch (action.type) {
        case ChannelListsManageScreenActionType.CHANNEL_LISTS_MANAGE_SCREEN_ADDED_CHANNEL_IDS_CHANGED:
            return { ...state, addedChannelIds: action.payload.channelIds };
        default:
            return state;
    }
};
