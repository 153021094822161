import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';

import { playChannel } from '__SMART_APP_OLD__/api/Tuner';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelSwitchTimeout } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { channelSwitchIdChange } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import {
    selectChannelLiveTVEntitlement,
    selectChannelSwitchId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { loadEventsForChannelSwitch } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import {
    selectEventBy,
    selectEventIsRestricted,
    selectEventShouldMask,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { selectPinIsActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG, SHOW_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { CHANNEL_SWITCH_PLAYER_TIMEOUT, PinAction } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';

const keysToChangeChannel = [Key.VK_CHAN_DOWN, Key.VK_CHAN_UP, Key.VK_UP, Key.VK_DOWN];

const isPlayerPage = (): boolean => window.location.pathname.includes('/playChannel/');

const playChannelDebounce = debounce(playChannel, CHANNEL_SWITCH_PLAYER_TIMEOUT, {
    leading: true,
    trailing: true,
});

export const useChannelSwitchingBusinessLogic = () => {
    const dispatch = useDispatch();
    const channelSwitchId = useSelector(selectChannelSwitchId);
    const channelSwitchDialogTimeout = useSelector(selectChannelSwitchTimeout);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [isPinDialogVisible, setPinDialogVisible] = useState(false);
    const event = useSelector(selectEventBy(channelSwitchId, Date.now(), { equal: EqualityCompare.START }));
    const isSubscribed = useSelector(selectChannelLiveTVEntitlement(channelSwitchId));
    const isRestricted = useSelector(selectEventIsRestricted(event?.id ?? '', PinSessionType.PIN_LIVE_TV));
    const shouldMask = useSelector(selectEventShouldMask(event?.id ?? '', PinSessionType.PIN_LIVE_TV));
    const isLoading = !useSelector(selectPinIsActive) && !!event?.isLoading;
    const forceSwitching = useRef(false);
    const closeCallback = useRef<((b: boolean) => void) | null>(null);
    const shouldHideWithoutAnimation = forceSwitching.current && !isDialogVisible;

    useEffect(() => {
        if (!Player.isInitialized() || !isPlayerPage()) return;
        if (!channelSwitchId || isPinDialogVisible || isRestricted || !isSubscribed) {
            playChannelDebounce.cancel();
        } else {
            playChannelDebounce(channelSwitchId);
        }
    }, [isSubscribed, isLoading, isRestricted, isPinDialogVisible, channelSwitchId]);

    useEffect(() => {
        if (!Player.isInitialized() || !isPlayerPage()) return;
        Player.stop();
    }, [channelSwitchId]);

    useEffect(() => {
        if (!isLoading && !isRestricted && isSubscribed && !isPinDialogVisible && isDialogVisible) {
            const hideTimeoutId = setTimeout(setIsDialogVisible, channelSwitchDialogTimeout, false);

            return () => {
                clearTimeout(hideTimeoutId);
            };
        }

        return undefined;
    }, [isLoading, isRestricted, isSubscribed, channelSwitchId, isPinDialogVisible, isDialogVisible, channelSwitchDialogTimeout]);

    useEffect(() => {
        const hideDialog = () => {
            if (isLoading || isRestricted || !isSubscribed) return;
            setIsDialogVisible(false);
        };

        Events.addEventListener(HIDE_CHANNEL_SWITCH_DIALOG, hideDialog);
        return () => {
            Events.removeEventListener(HIDE_CHANNEL_SWITCH_DIALOG, hideDialog);
        };
    }, [isLoading, isRestricted, isSubscribed]);

    useEffect(() => {
        const hideDialog = () => {
            if (isPlayerPage()) return;
            setIsDialogVisible(false);
        };

        Events.addEventListener(HIDE_CHANNEL_SWITCH_DIALOG, hideDialog);
        return () => {
            Events.removeEventListener(HIDE_CHANNEL_SWITCH_DIALOG, hideDialog);
        };
    }, []);

    useEffect(() => {
        const showDialog = (opts?: { forceSwitching: boolean; channelId: string; closeCallback: () => void }) => {
            if (!isPlayerPage()) return;
            if (!opts) return;
            forceSwitching.current = opts.forceSwitching;
            setIsDialogVisible(true);
            dispatch(channelSwitchIdChange(opts.channelId));
            dispatch(loadEventsForChannelSwitch());
            closeCallback.current = opts?.closeCallback;
        };

        Events.addEventListener(SHOW_CHANNEL_SWITCH_DIALOG, showDialog);
        return () => {
            Events.removeEventListener(SHOW_CHANNEL_SWITCH_DIALOG, showDialog);
        };
    }, [dispatch]);

    useEffect(() => {
        const onKey = (key: number) => {
            if (isPinDialogVisible || !isDialogVisible || !isPlayerPage()) return false;
            switch (key) {
                case Key.VK_BACK:
                    if (!isLoading && (isRestricted || !isSubscribed)) {
                        Player.stop();
                        setIsDialogVisible(false);
                        return false;
                    }
                    if (!isLoading) setIsDialogVisible(false);
                    return true;
                case Key.VK_ENTER:
                    if (!isSubscribed || isLoading) return true;
                    if (isRestricted) {
                        setPinDialogVisible(true);
                        openPinOverlay(
                            () => {
                                setPinDialogVisible(false);
                                setIsDialogVisible(false);
                                playChannelDebounce.cancel();
                                playChannelDebounce(channelSwitchId);
                                if (closeCallback.current) {
                                    closeCallback.current(true);
                                    closeCallback.current = null;
                                }
                            },
                            () => {
                                setPinDialogVisible(false);
                            },
                            PinAction.ENTER,
                            PinSessionType.PIN_LIVE_TV
                        );
                        return true;
                    }
                    return false;
                default:
                    return !keysToChangeChannel.includes(key);
            }
        };

        Focus.addEventListener('onkey', onKey);
        return () => {
            Focus.removeEventListener('onkey', onKey);
        };
    }, [isPinDialogVisible, isDialogVisible, isLoading, isRestricted, isSubscribed, channelSwitchId]);

    useEffect(() => () => playChannelDebounce.cancel(), []);

    return {
        isDialogVisible,
        event,
        isRestricted,
        isSubscribed,
        shouldHideWithoutAnimation,
        isPlayerPage: isPlayerPage(),
        shouldMask,
    };
};
