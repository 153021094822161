import { selectCurrentParentalRatingRank } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { Profile as ProfileModule } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { ContentRef } from 'analytics/logging/classes/ContentRef';
import { Device } from 'analytics/logging/classes/Device';
import { Household } from 'analytics/logging/classes/Household';
import { Profile, ProfileType } from 'analytics/logging/classes/Profile';
import { DeliveryProtocols, VideoSource, ViewData, ViewServices } from 'analytics/logging/classes/ViewData';
import * as contentRefGenerator from 'analytics/logging/contentRefGenerators';
import { SessionEvent, SessionEvents, UserTypes } from 'analytics/logging/events/SessionEvent';
import { SessionTypes, getAppStartEventTime } from 'analytics/logging/LoggingSession';
import * as loggingUtils from 'analytics/logging/loggingUtils';
import { selectIsLoginWithCode, selectLogAuthAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';

// ------------------------------------
// App Session Events
// ------------------------------------
export const getAppStartEvent = (): SessionEvent => {
    const eventTime = getAppStartEventTime();
    const device = new Device();
    return new SessionEvent(SessionEvents.START, SessionTypes.APPLICATION, { device }, undefined, undefined, undefined, eventTime);
};

export const getAppStopEvent = (): SessionEvent => new SessionEvent(SessionEvents.STOP, SessionTypes.APPLICATION);

// ------------------------------------
// User Session Events
// ------------------------------------
export const getUserStartEvent = (): SessionEvent => {
    const state = store.getState();
    const kind = ProfileModule.selectors.selectKind(state);
    const profileId = ProfileModule.selectors.selectId(state);
    const profileAgeRating = selectCurrentParentalRatingRank(state);
    const userId = ProfileModule.selectors.selectUserId(state);
    const logAction = selectLogAuthAction(state);
    const isLoginWithCode = selectIsLoginWithCode(state);
    const userType = kind.toLowerCase() === ProfileType.FAMILY.toLowerCase() ? UserTypes.MASTER : UserTypes.OTHER;
    const household = new Household();
    let profileType: ProfileType;
    switch ((kind as string).toUpperCase()) {
        case ProfileType.FAMILY:
            profileType = ProfileType.FAMILY;
            break;
        case ProfileType.KIDS:
            profileType = ProfileType.KIDS;
            break;
        default:
            profileType = ProfileType.OTHER;
            break;
    }
    const profile = new Profile(profileId, profileType, profileAgeRating);
    const customData = logAction ? { isLoginWithCode } : undefined;
    return new SessionEvent(
        SessionEvents.START,
        SessionTypes.USER,
        undefined,
        {
            household,
            profile,
            user: {
                userId,
                userType,
            },
        },
        undefined,
        customData
    );
};

export const getUserStopEvent = (): SessionEvent => new SessionEvent(SessionEvents.STOP, SessionTypes.USER);

// ------------------------------------
// View Session Events
// ------------------------------------
const getViewStartEvent = (
    contentRef: ContentRef,
    service: ViewServices,
    url: string,
    eventTime: number = new Date().getTime()
): SessionEvent => {
    const deliveryProtocol = loggingUtils.getDeliveryProtocolFromUrl(url) as DeliveryProtocols;
    const videoSource: VideoSource = { playbackURL: url };
    const viewData = new ViewData({ contentRef, service, deliveryProtocol, videoSource });
    return new SessionEvent(SessionEvents.START, SessionTypes.VIEW, undefined, undefined, undefined, viewData, eventTime);
};

export const getViewStopEvent = (): SessionEvent | undefined => {
    const sessionEvent = new SessionEvent(SessionEvents.STOP, SessionTypes.VIEW);

    return sessionEvent.sessionId ? sessionEvent : undefined;
};

export const getLiveViewStartEvent = (channelId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getLiveChannelContentRef(channelId);
    return getViewStartEvent(contentRef, ViewServices.LIVE, url);
};

export const getPauseLiveViewStartEvent = (channelId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getLiveChannelContentRef(channelId);
    return getViewStartEvent(contentRef, ViewServices.NPLTV, url);
};

export const getCatchupViewStartEvent = (eventId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getEventContentRef(eventId);
    return getViewStartEvent(contentRef, ViewServices.CATCHUP, url);
};

export const getRecordingViewStartEvent = (recordingId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getRecordingContentRef(recordingId);
    return getViewStartEvent(contentRef, ViewServices.NPVR, url);
};

export const getRestartViewStartEvent = (eventId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getEventContentRef(eventId);
    return getViewStartEvent(contentRef, ViewServices.RESTART, url);
};

export const getVODViewStartEvent = (vodAssetId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getVODAssetContentRef(vodAssetId);
    return getViewStartEvent(contentRef, ViewServices.VOD, url);
};

export const getTrailerViewStartEvent = (trailerId: string, url: string): SessionEvent => {
    const contentRef = contentRefGenerator.getTrailerContentRef(trailerId);
    return getViewStartEvent(contentRef, ViewServices.VOD, url);
};
