import React, { useCallback, useEffect } from 'react';

import { LandscapeCardGrid } from '__SMART_APP_OLD__/app/components/LandscapeCardGrid';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { nowOnTVBackTriggered, nowOnTVLoadData } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/actions';
import {
    selectNowOnTVHasLoadingEvents,
    selectNowOnTVInitialFocusedItem,
    selectNowOnTVItemsIds,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { NowOnTVCard } from '__SMART_APP_OLD__/app/modules/Screen/modules/NowOnTV/NowOnTVCard';

import { Calc } from 'App/Packages/Calc';
import { Focus } from 'App/Packages/Focus';

export const NowOnTVGrid: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const ids = useSelector(selectNowOnTVItemsIds);
    const time = useSelector(Time.selectors.select);
    const initialFocusedItem = useSelector(selectNowOnTVInitialFocusedItem);
    const hasLoadingEvents = useSelector(selectNowOnTVHasLoadingEvents);

    const initialFocusedIndex = useCallback(
        () => Calc.clamp(0, ids.indexOf(initialFocusedItem ?? ''), ids.length - 1),
        [ids, initialFocusedItem]
    );

    useEffect(() => {
        if (!hasLoadingEvents) return;
        dispatch(nowOnTVLoadData(time));
    }, [hasLoadingEvents, time, dispatch]);

    Focus.useBack(() => dispatch(nowOnTVBackTriggered));

    return (
        <LandscapeCardGrid
            className="now-on-tv__grid"
            ctx={Focus.Default}
            Card={NowOnTVCard}
            items={ids}
            initialFocusedIndex={initialFocusedIndex}
        />
    );
};
