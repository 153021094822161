import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { selectRecording } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { Folder } from 'App/Modules/Folder';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { MyLibraryHeaderAsset } from 'App/Modules/MyLibraryData/Store/types';

const select: Selector<Folder.List> = (state) => state.myLibrary.myLibraryData;
const selectHeader: Selector<DetailItem | null> = (state) => state.myLibrary.headerData;
const selectHeaderItemIsLive =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const item = selector(state);
        if (!item) return false;
        const now = Time.selectors.select(state);
        if (!item.metadata.start || !item.metadata.end) return false;
        return item.metadata.start <= now && item.metadata.end >= now;
    };
const selectHeaderItemAvailableUntil =
    (selector: Selector<DetailItem | null>): Selector<number | undefined> =>
    (state) => {
        const item = selector(state);
        if (!item) return undefined;
        if (item.type === ContentItemType.Networkrecording) return selectRecording(item.id)(state)?.availableUntil;
        if (item.type === ContentItemType.Vodasset) return item.entitlements.playbackAvailableUntil;
        return undefined;
    };
const selectHeaderItemProgress =
    (selector: Selector<DetailItem | null>): Selector<number> =>
    (state) => {
        const item = selector(state);
        if (!item) return 0;
        const isLive = selectHeaderItemIsLive(selector)(state);
        if (!isLive) {
            const bookmark = Bookmarks.selectors.selectEntity(item.id)(state);
            if (!bookmark) return 0;
            return bookmark.progress;
        }
        const now = Time.selectors.select(state);
        if (!item.metadata.start || !item.metadata.end) return 0;
        if (now < item.metadata.start) return 0;
        if (now > item.metadata.end) return 0;
        return ((now - item.metadata.start) / (item.metadata.end - item.metadata.start)) * 100;
    };

const selectHeaderItemContentMarker =
    (selector: Selector<DetailItem | null>): Selector<ContentMarkerType | undefined> =>
    (state) => {
        const item = selector(state);
        if (!item) return undefined;
        const isLive = selectHeaderItemIsLive(selector)(state);
        const now = Time.selectors.select(state);
        if (isLive) return ContentMarkerType.Live;
        if (item.type === ContentItemType.Networkrecording) return ContentMarkerType.Recorded;
        if (item.entitlements.playback && item.entitlements.playbackAvailableUntil > now) return ContentMarkerType.Rented;
        return undefined;
    };

// @todo remove after mapping the assets
const selectAsset: Selector<MyLibraryHeaderAsset | null> = (state) => state.myLibrary.assetData;

export const selectors = {
    public: {
        select,
        selectId: Folder.selectors.list.selectId(select),
        selectCount: Folder.selectors.list.selectCount(select),
        selectFolder: Folder.selectors.list.selectFolder(select),
        selectFoldersIds: Folder.selectors.list.selectFoldersIds(select),
        selectFoldersEntities: Folder.selectors.list.selectFoldersEntities(select),
        selectHeader,
        selectAsset,
        selectHeaderProgress: selectHeaderItemProgress(selectHeader),
        selectHeaderContentMarker: selectHeaderItemContentMarker(selectHeader),
        selectHeaderAvailableUntil: selectHeaderItemAvailableUntil,
    },
    private: {},
} as const;
