import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { EventFragmentDoc } from '../../event/fragments/event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelLoadManyEventQueryVariables = Types.Exact<{
  channelId: Types.Scalars['ID']['input'];
  start: Types.Scalars['Date']['input'];
  duration: Types.Scalars['Int']['input'];
  backgroundHeight: Types.Scalars['Int']['input'];
  backgroundWidth: Types.Scalars['Int']['input'];
  thumbnailHeight: Types.Scalars['Int']['input'];
}>;


export type ChannelLoadManyEventQuery = { __typename?: 'Nexx4Queries', channel?: { __typename?: 'Channel', events?: { __typename?: 'EventCatalog', items: Array<{ __typename: 'Event', id: string, title: string, start: any, end: any, startOverTVBeforeTime?: number | null, startOverTVAfterTime?: number | null, startOverPlaybackStartPosition?: number | null, startOverPlaybackContinuePosition?: number | null, startOverPlaybackStopPosition?: number | null, parentalRating: { __typename?: 'ParentalRating', id: string }, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, channel?: { __typename?: 'Channel', id: string } | null } | null> } | null } | null };

export type ChannelLoadManyEventFragment = { __typename?: 'Nexx4Queries', channel?: { __typename?: 'Channel', events?: { __typename?: 'EventCatalog', items: Array<{ __typename: 'Event', id: string, title: string, start: any, end: any, startOverTVBeforeTime?: number | null, startOverTVAfterTime?: number | null, startOverPlaybackStartPosition?: number | null, startOverPlaybackContinuePosition?: number | null, startOverPlaybackStopPosition?: number | null, parentalRating: { __typename?: 'ParentalRating', id: string }, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, channel?: { __typename?: 'Channel', id: string } | null } | null> } | null } | null };

export const ChannelLoadManyEventFragmentDoc = gql`
    fragment channelLoadManyEvent on Nexx4Queries {
  channel(id: $channelId) {
    events(start: $start, duration: $duration) {
      items {
        ...event
      }
    }
  }
}
    ${EventFragmentDoc}`;
export const ChannelLoadManyEventDocument = gql`
    query channelLoadManyEvent($channelId: ID!, $start: Date!, $duration: Int!, $backgroundHeight: Int!, $backgroundWidth: Int!, $thumbnailHeight: Int!) {
  ...channelLoadManyEvent
}
    ${ChannelLoadManyEventFragmentDoc}`;

/**
 * __useChannelLoadManyEventQuery__
 *
 * To run a query within a React component, call `useChannelLoadManyEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelLoadManyEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelLoadManyEventQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      start: // value for 'start'
 *      duration: // value for 'duration'
 *      backgroundHeight: // value for 'backgroundHeight'
 *      backgroundWidth: // value for 'backgroundWidth'
 *      thumbnailHeight: // value for 'thumbnailHeight'
 *   },
 * });
 */
export function useChannelLoadManyEventQuery(baseOptions: Apollo.QueryHookOptions<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>(ChannelLoadManyEventDocument, options);
      }
export function useChannelLoadManyEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>(ChannelLoadManyEventDocument, options);
        }
export function useChannelLoadManyEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>(ChannelLoadManyEventDocument, options);
        }
export type ChannelLoadManyEventQueryHookResult = ReturnType<typeof useChannelLoadManyEventQuery>;
export type ChannelLoadManyEventLazyQueryHookResult = ReturnType<typeof useChannelLoadManyEventLazyQuery>;
export type ChannelLoadManyEventSuspenseQueryHookResult = ReturnType<typeof useChannelLoadManyEventSuspenseQuery>;
export type ChannelLoadManyEventQueryResult = Apollo.QueryResult<ChannelLoadManyEventQuery, ChannelLoadManyEventQueryVariables>;