import React from 'react';

import {
    SettingsSectionContentItemChannelNumberChannelList,
    SettingsSectionContentItemChannelNumberChannelListProps,
} from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelNumberChannelList';
import { SettingsSectionContentItemChannelNumberDefault } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelNumberDefault';
import { SettingsSectionContentItemText } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemText';

export type SettingsSectionContentItemChannelNumberModule = {
    Default: React.FunctionComponent;
    ChannelList: React.FunctionComponent<React.PropsWithChildren<SettingsSectionContentItemChannelNumberChannelListProps>>;
} & React.FunctionComponent<React.PropsWithChildren>;

export const SettingsSectionContentItemChannelNumber: SettingsSectionContentItemChannelNumberModule = (props) => (
    <SettingsSectionContentItemText className="settings-section-content-item__channel-number">
        {props.children}
    </SettingsSectionContentItemText>
);

SettingsSectionContentItemChannelNumber.Default = SettingsSectionContentItemChannelNumberDefault;
SettingsSectionContentItemChannelNumber.ChannelList = SettingsSectionContentItemChannelNumberChannelList;
