import { useMemo, useRef } from 'react';

import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Scroll } from 'App/Packages/Scroll';
import { Subscribable } from 'App/Packages/Subscribable';
import { SuggestionListValue } from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Context/SuggestionListContext';
import { SuggestionValue } from 'App/Packages/UI/Suggestion/Modules/Suggestion/Context/SuggestionContext';
import { Axis } from 'App/Types/Axis';
import { useSuggestionListEnter } from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Hooks/Private/useSuggestionListEnter';
import { useSuggestionListFocusInitial } from 'App/Packages/UI/Suggestion/Modules/SuggestionList/Hooks/Private/useSuggestionListFocusIntial';

export type UseSuggestionListValuePayload = {
    ctx: string;
    initial?: string;
    disabled?: boolean;
    offset?: number;
    enter?: (value: string) => void;
};

export type UseSuggestionListValue = (payload: UseSuggestionListValuePayload) => SuggestionListValue;

export const useSuggestionListValue: UseSuggestionListValue = (payload) => {
    const ref = useRef<HTMLDivElement>(null);
    const { ctx } = payload;
    const values = Subscribable.useArray<string>([]);
    const store = Subscribable.useRecord<SuggestionValue>({});
    const axis = Axis.X;
    const items = Subscribable.use(values);
    const navigation = useListHook({ axis, ctx, items });
    const focused = Subscribable.useValue<string>(navigation.focused);
    const disabled = Subscribable.useValue<boolean>(navigation.isDisabled);
    const offset = payload.offset ?? 0;
    const { focus } = navigation;
    const enter = useSuggestionListEnter(payload.enter);
    const scroll = useMemo(() => new Scroll(ref), [ref]);

    Subscribable.useSetter(focused, navigation.focused);
    Subscribable.useSetter(disabled, navigation.isDisabled);

    useSuggestionListFocusInitial(store, payload.initial);

    return useMemo<SuggestionListValue>(
        () => ({ ref, ctx, values, store, focused, disabled, offset, scroll, focus, enter }),
        [ref, ctx, values, focused, store, disabled, offset, scroll, focus, enter]
    );
};
