import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../parentalRating/fragments/parentalRatingRelation.generated';
import { ChannelPersonalInfoFragmentDoc } from './channelPersonalInfo.generated';
import { ChannelEntitlementsFragmentDoc } from './channelEntitlements.generated';
export type ChannelFragment = { __typename: 'Channel', id: string, kind: Types.ChannelKind, title: string, defaultNumber?: number | null, beforeTime?: number | null, afterTime?: number | null, NORMAL?: { __typename: 'Image', id: string, url?: string | null } | null, INVERTED?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, playBackgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, personalInfo?: { __typename: 'PersonalChannelInfo', id: string, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null } | null, entitlements?: { __typename: 'ChannelEntitlements', id: string, liveTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, networkRecording: boolean, householdConfirmedReplayPermissions?: boolean | null } | null };

export const ChannelFragmentDoc = gql`
    fragment channel on Channel {
  id
  kind
  title
  defaultNumber
  beforeTime
  afterTime
  NORMAL: logo(
    width: $channelLogoWidth
    height: $channelLogoHeight
    flavour: NORMAL
  ) {
    ...image
  }
  INVERTED: logo(
    width: $channelLogoWidth
    height: $channelLogoHeight
    flavour: INVERTED
  ) {
    ...image
  }
  backgroundImage(
    width: $channelBackgroundWidth
    height: $channelBackgroundHeight
  ) {
    ...image
  }
  playBackgroundImage(
    width: $channelBackgroundWidth
    height: $channelBackgroundHeight
  ) {
    ...image
  }
  parentalRating {
    ...parentalRatingRelation
  }
  personalInfo(profileId: $profileId) {
    ...channelPersonalInfo
  }
  entitlements {
    ...channelEntitlements
  }
  __typename
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}
${ChannelPersonalInfoFragmentDoc}
${ChannelEntitlementsFragmentDoc}`;