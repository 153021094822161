import React, { useContext } from 'react';

import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export type SettingsSectionContentItemTextProps = {
    className?: string;
};

const getClassName = (props: SettingsSectionContentItemTextProps): string => {
    const classlist = ['settings-section-content-item__text'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export const SettingsSectionContentItemText: React.FunctionComponent<React.PropsWithChildren<SettingsSectionContentItemTextProps>> = (
    props
) => {
    const { isFocused } = useContext(SettingsSectionContentItemContext);
    const color = isFocused ? Color.PRIMARY_INVERTED : Color.PRIMARY;
    const weight = isFocused ? FontWeight.BOLD : FontWeight.REGULAR;

    return (
        <Text className={getClassName(props)} typeface={Typeface.SANS} size={FontSize.BODY_1} weight={weight} color={color}>
            {props.children}
        </Text>
    );
};
