import { batch } from 'react-redux';

import { consentApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/api';
import { consentSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/store/selectors';
import {
    ConsentActionType,
    ConsentChangedAction,
    ConsentChangedActionPayload,
    ConsentInitLoadAction,
    ConsentInitLoadActionPayload,
    ConsentType,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/types';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const changed = (payload: ConsentChangedActionPayload): ConsentChangedAction => ({
    type: ConsentActionType.CHANGED,
    payload,
});

const initLoad = (payload: ConsentInitLoadActionPayload): ConsentInitLoadAction => ({
    type: ConsentActionType.INITLOADALL,
    payload,
});

const toggled =
    (type: ConsentType): Thunk<void> =>
    (dispatch, getState) => {
        dispatch(changed({ type, accepted: !consentSelectors.public.selectIsAccepted(type)(getState()) }));
    };

const acceptAll = (): Thunk<void> => (dispatch) => {
    batch(() => Object.values(ConsentType).forEach((type) => dispatch(changed({ type, accepted: true }))));
};

const load = (): Thunk<Promise<void>> => async (dispatch) => {
    const response = await consentApi.load();
    const values = Object.values(ConsentType);
    const payloads = response.consents
        .filter((c) => values.includes(c.type))
        .map<ConsentChangedActionPayload>((c) => ({ type: c.type, accepted: c.accepted }));
    batch(() => payloads.forEach((payload) => dispatch(changed(payload))));
};

const save = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const profileId = profileSelectors.public.selectId(state);
        const consents = consentSelectors.private.selectGql(state);
        await consentApi.save(profileId, consents);
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.CONSENT_SAVE, error));
        return false;
    }
};

export const consentActions = {
    public: {
        changed,
        toggled,
        acceptAll,
        initLoad,
        load,
        save,
    },
    private: {},
};
