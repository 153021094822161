import { Registry } from 'App/Modules/Prompt/Registry';
import { actions as promptActions } from 'App/Modules/Prompt/Store/actions';
import { selectors as promptSelectors } from 'App/Modules/Prompt/Store/selectors';

export { generator } from 'App/Modules/Prompt/Store/generator';
export { getInitialState } from 'App/Modules/Prompt/Store/getInitialState';
export { reducer } from 'App/Modules/Prompt/Store/reducer';
export { PromptActionType as ActionType, PromptType as Type } from 'App/Modules/Prompt/Types';
export type {
    PromptChangedAction as ChangedAction,
    PromptChangedActionPayload as ChangedActionPayload,
    PromptEntity as Entity,
    PromptProps as Props,
    PromptState as State,
} from 'App/Modules/Prompt/Types';
export { View } from 'App/Modules/Prompt/View';

export const zIndex = 200;
export const actions = promptActions.public;
export const selectors = promptSelectors.public;
export const { register } = Registry;
