import { InputElement } from 'App/Modules/Input';
import { LoginWithEmailFormField } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormField';
import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import React from 'react';

export type LoginWithEmailFormUserNameFieldProps = {
    ctx: string;
    field: React.RefObject<InputElement>;
    isFocused: boolean;
    onHover: (id: LoginElement) => void;
    onComplete: (target: InputElement) => void;
};

export const LoginWithEmailFormUserNameField: React.FunctionComponent<LoginWithEmailFormUserNameFieldProps> = (props) => (
    <LoginWithEmailFormField
        id={LoginElement.UserNameField}
        value={Storage.get<string>(Storage.Key.USER_NAME)}
        placeholder="SCREEN_LOGIN_EMAIL_FIELD"
        {...props}
    />
);
