import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { generateUuid } from 'analytics/logging/loggingUtils';
import { EventConcepts } from 'analytics/types/concepts';

import { Household } from '../classes/Household';
import { Profile } from '../classes/Profile';
import { ViewData } from '../classes/ViewData';
import { SessionTypes, getLoggingSessions } from '../LoggingSession';

export enum SessionEvents {
    START = 'start',
    STOP = 'stop',
}

export interface UserData {
    household: Household;
    profile: Profile;
    user: User;
}

export enum UserTypes {
    MASTER = 'Master',
    OTHER = 'Other',
}

interface User {
    userId: string;
    userType: UserTypes;
}
/**
 * @class SessionEvent
 */
export class SessionEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.SESSION;

    readonly sessionId: string;

    private readonly linkedSessionId: string;

    /**
     * @param event
     * @param sessionType
     * @param applicationData
     * @param userData
     * @param viewData
     * @param customData
     * @param eventTime
     */
    constructor(
        readonly event: SessionEvents,
        readonly sessionType: SessionTypes,
        private applicationData?: any,
        private userData?: UserData,
        private viewData?: ViewData,
        protected customData: Record<string, any> = {},

        eventTime?: number
    ) {
        super(generateUuid());
        const loggingSessions = getLoggingSessions();
        this.event = event;
        this.sessionType = sessionType;
        switch (event) {
            case SessionEvents.START:
                this.sessionId = generateUuid();
                break;
            case SessionEvents.STOP:
                this.sessionId = loggingSessions[sessionType]!;
                break;
            default:
                this.sessionId = '';
                break;
        }

        // Optional props
        if (applicationData) this.applicationData = applicationData;
        else delete this.applicationData;

        if (userData) this.userData = userData;
        else delete this.userData;

        if (viewData) this.viewData = viewData;
        else delete this.viewData;

        if (eventTime) this.eventTime = eventTime;

        if (customData) {
            this.customData = customData;
        }

        switch (sessionType) {
            case SessionTypes.USER:
                this.linkedSessionId = loggingSessions[SessionTypes.APPLICATION] || '';
                break;
            case SessionTypes.VIEW:
                this.linkedSessionId = loggingSessions[SessionTypes.USER] || loggingSessions[SessionTypes.APPLICATION] || '';
                break;
            default:
                this.linkedSessionId = '';
                break;
        }
    }
}
