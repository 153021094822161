import { devToolsEnhancer } from '@redux-devtools/extension';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { getInitialState } from '__SMART_APP_OLD__/app/store/getInitialState';
import { reducer } from '__SMART_APP_OLD__/app/store/reducers/root.reducer';
import { Action } from '__SMART_APP_OLD__/app/store/types/Action';
import { CTX } from '__SMART_APP_OLD__/app/store/types/CTX';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Env } from 'App/Env';

export const configureStore = () => {
    const initialState = getInitialState();
    const configuredThunkMiddleware = thunkMiddleware.withExtraArgument<CTX, State, Action>({});
    const middlewares = applyMiddleware(configuredThunkMiddleware);
    if (Env.IsProduction) {
        const enhancers = compose(middlewares);
        return createStore(reducer, initialState, enhancers);
    }
    const enhancers = compose(middlewares, devToolsEnhancer({ serialize: false, trace: false, name: 'XploreTV', traceLimit: 25 }));
    return createStore(reducer, initialState, enhancers);
};

export const store = configureStore();
