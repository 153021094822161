import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type StreamInfoFragment = { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean };

export const StreamInfoFragmentDoc = gql`
    fragment streamInfo on StreamInfo {
  hardOfHearing
  visuallyImpaired
  dolby
  multipleAudioLanguages
  subtitles
}
    `;