import { App } from 'App/Modules/App';
import { VidaaAppEngine } from 'App/Modules/App/Implementation/Vidaa';

export class Vidaa {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Device.Vidaa.Initialize]');
        App.initialize(new VidaaAppEngine());
        return true;
    }
}
