import React, { useEffect, useMemo, useState } from 'react';

import { Axis } from 'App/Types/Axis';

export type ScrollableProps = {
    delay?: number;
    transition?: number;
} & ScrollableStaticProps;

export type ScrollableStaticProps = {
    className?: string;
    axis: Axis;
    top: number; // step index to scroll to
    step: number; // step size in vw
    steps: number; // total number of steps
    children?: React.ReactNode;
};

export type ScrollableModule = {
    Static: React.FunctionComponent<ScrollableStaticProps>;
} & React.FunctionComponent<ScrollableProps>;

const getRootClassName = (className: string | undefined): string => {
    const classlist = ['scrollable'];

    if (className) classlist.push(className);

    return classlist.join(' ');
};

const getTrackClassName = (axis: Axis): string => {
    const classlist = ['scrollable__track'];

    if (axis === Axis.X) classlist.push('scrollable__track--x');
    else if (axis === Axis.Y) classlist.push('scrollable__track--y');

    return classlist.join(' ');
};

const getTranslateValue = (index: number, step: number) => `${step * index}vw`;
const getTranslate = (axis: Axis, index: number, step: number) => `translate${axis}(-${getTranslateValue(index, step)})`;
const getTransitionDuration = (transition?: number | undefined): string => `${transition ?? 0}ms`;
const getHeight = (axis: Axis, step: number): string => (axis === Axis.Y ? `${step}vw` : '100%');
const getWidth = (axis: Axis, step: number): string => (axis === Axis.X ? `${step}vw` : '100%');
const getStyles = (axis: Axis, step: number, index: number, transition?: number): React.CSSProperties => ({
    height: getHeight(axis, step),
    width: getWidth(axis, step),
    transform: getTranslate(axis, index, step),
    transitionDuration: getTransitionDuration(transition),
});

const makeScrollRequest = (callback: () => void, delay?: number) => {
    if (!delay) {
        callback();
        return undefined;
    }
    const timer = setTimeout(callback, delay ?? 0);
    return () => clearTimeout(timer);
};

const ScrollableStatic: React.FunctionComponent<ScrollableStaticProps> = (props) => {
    const { top, axis, step } = props;
    const styles = useMemo(() => getStyles(axis, step, top), [axis, step, top]);

    return (
        <div className={getRootClassName(props.className)}>
            <div className={getTrackClassName(props.axis)} style={styles}>
                {props.children}
            </div>
        </div>
    );
};

export const Scrollable: ScrollableModule = (props) => {
    const { top, axis, step, transition, delay } = props;
    const [index, setIndex] = useState<number>(top);
    const styles = useMemo(() => getStyles(axis, step, index, transition), [axis, step, index, transition]);
    useEffect(() => makeScrollRequest(() => setIndex(top), delay), [top, delay]);

    return (
        <div className={getRootClassName(props.className)}>
            <div className={getTrackClassName(props.axis)} style={styles}>
                {props.children}
            </div>
        </div>
    );
};

Scrollable.Static = ScrollableStatic;
