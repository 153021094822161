import React from 'react';

import { BackgroundColor, Div } from '__SMART_APP_OLD__/app/components/Div';

import { UI } from 'App/Packages/UI';

export const TVGuideClock: React.FunctionComponent = () => (
    <>
        <Div className="tv-guide__clock-background" backgroundColor={BackgroundColor.PRIMARY} />
        <UI.Clock className="tv-guide__clock" />
    </>
);
