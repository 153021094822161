import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartEventMutationVariables = Types.Exact<{
  input: Types.RestartEventInput;
}>;


export type RestartEventMutation = { __typename?: 'Nexx4Mutations', restartEvent: { __typename?: 'RestartEventPayload', playbackInfo: { __typename?: 'TimeshiftPlaybackInfo', url: string, streamStart?: any | null, streamEnd?: any | null, sessionId?: string | null, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, adPlaybackPreRoll?: number | null, heartbeat?: { __typename?: 'GraphQLHeartbeat' } | { __typename?: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const RestartEventDocument = gql`
    mutation restartEvent($input: RestartEventInput!) {
  restartEvent(input: $input) {
    playbackInfo {
      url
      streamStart
      streamEnd
      sessionId
      adPlaybackRestrictions
      adPlaybackPreRoll
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
        }
      }
    }
  }
}
    `;
export type RestartEventMutationFn = Apollo.MutationFunction<RestartEventMutation, RestartEventMutationVariables>;

/**
 * __useRestartEventMutation__
 *
 * To run a mutation, you first call `useRestartEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartEventMutation, { data, loading, error }] = useRestartEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartEventMutation(baseOptions?: Apollo.MutationHookOptions<RestartEventMutation, RestartEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartEventMutation, RestartEventMutationVariables>(RestartEventDocument, options);
      }
export type RestartEventMutationHookResult = ReturnType<typeof useRestartEventMutation>;
export type RestartEventMutationResult = Apollo.MutationResult<RestartEventMutation>;
export type RestartEventMutationOptions = Apollo.BaseMutationOptions<RestartEventMutation, RestartEventMutationVariables>;