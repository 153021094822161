import React from 'react';
import QRCodeComponent from 'react-qr-code';

import { QRCodeLogo, QRCodeLogoModule, QRCodeLogoProps } from 'App/Packages/UI/QRCode/QRCodeLogo';

const getClassName = (className?: string): string => {
    const classList = ['qr-code'];
    if (className) classList.push(className);
    return classList.join(' ');
};

export type { QRCodeLogoModule, QRCodeLogoProps };

export type QRCodeProps = React.PropsWithChildren<{
    url: string;
    className?: string;
}>;

export type QRCodeModule = React.FunctionComponent<QRCodeProps> & {
    Logo: QRCodeLogoModule;
};

export const QRCode: QRCodeModule = (props) => (
    <div className={getClassName(props.className)}>
        <QRCodeComponent className="qr-code__component" level="H" value={props.url} />
        {props.children}
    </div>
);

QRCode.Logo = QRCodeLogo;
