import React from 'react';

import { FolderListContext, FolderListContextModule } from 'App/Packages/UI/Folder/Modules/FolderList/Context/FolderListContext';
import { UseFolderList, useFolderList } from 'App/Packages/UI/Folder/Modules/FolderList/Hooks/useFolderList';
import { UseFolderListValue, useFolderListValue } from 'App/Packages/UI/Folder/Modules/FolderList/Hooks/useFolderListValue';

export type FolderListProps = React.PropsWithChildren<{
    ctx?: string;
    disabled?: boolean;
    offsetTop?: number;
    onEnter?: (id: string) => void;
}>;

export type FolderListModule = React.FunctionComponent<FolderListProps> & {
    // Context
    Context: FolderListContextModule;

    // Hooks
    use: UseFolderList;
    useValue: UseFolderListValue;
};

export const FolderList: FolderListModule = (props) => {
    const list = FolderList.useValue({ ctx: props.ctx, offsetTop: props.offsetTop, disabled: props.disabled, enter: props.onEnter });
    return <FolderList.Context.Provider value={list}>{props.children}</FolderList.Context.Provider>;
};

// Context
FolderList.Context = FolderListContext;

// Hooks
FolderList.use = useFolderList;
FolderList.useValue = useFolderListValue;
