import { useMemo } from 'react';

import { ListEngine, ListEngineConfig } from '__SMART_APP_OLD__/app/components/ListV2/ListEngine';

export class DynamicListEngine<T> extends ListEngine<T> {
    public static useEngine = <G>(focused: number, data: G[], config: ListEngineConfig): ListEngine<G> => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const engine = useMemo(() => new DynamicListEngine<G>(config), []);
        engine.setData(data);
        engine.setFocused(focused);
        engine.useReady();
        if (data.length <= engine.getMax()) {
            engine.setLastIndex(engine.getMax());
        }
        return engine;
    };

    public getContainer(): DOMRect {
        return this.getRef()?.parentElement?.getBoundingClientRect?.() ?? new DOMRect(0, 0, 0, 0);
    }

    public getList(): DOMRect {
        return this.getRef()?.getBoundingClientRect?.() ?? new DOMRect(0, 0, 0, 0);
    }

    public getItems(): DOMRect[] {
        return Array.from(this.getRef()?.children ?? []).map((child) => child.getBoundingClientRect());
    }
}
