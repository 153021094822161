import { clpp } from '@castlabs/prestoplay';
import React, { useEffect, useState } from 'react';

import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import translate from 'language/translate';

const PlayerError = () => {
    const [errorMessage, setErrorMessage] = useState('ERR_PLAYER_GENERIC_MESSAGE');
    const [errorCode, setErrorCode] = useState(null);
    const [isError, setIsError] = useState(false);

    const onNoError = () => {
        setErrorMessage('ERR_PLAYER_GENERIC_MESSAGE');
        setErrorCode(null);
        setIsError(false);
    };

    const onError = (error) => {
        const errorInfo = error.detail;
        // CODE 7000 playback failed because another started this is why we hide it.
        if (errorInfo?.code && errorInfo.code === 7000) return;
        switch (error.category) {
            case clpp.Error.Category.NETWORK:
                setErrorMessage('ERR_PLAYER_NETWORK_MESSAGE');
                break;
            case clpp.Error.Category.MANIFEST:
                setErrorMessage('ERR_PLAYER_MANIFEST_MESSAGE');
                break;
            case clpp.Error.Category.STREAMING:
                setErrorMessage('ERR_PLAYER_STREAMING_MESSAGE');
                break;
            case clpp.Error.Category.DRM:
                setErrorMessage('ERR_PLAYER_DRM_MESSAGE');
                break;
            case clpp.Error.Category.CAST:
            case clpp.Error.Category.PLAYER:
            default:
                setErrorMessage('ERR_PLAYER_GENERIC_MESSAGE');
                break;
        }
        setErrorCode(errorInfo?.code);
        setIsError(true);
        PlayerAPI.stopPlayback(false);
        Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
    };

    useEffect(() => {
        Player.addEventListener(VIDEO_EVENTS.ERROR, onError);
        Player.addEventListener(VIDEO_EVENTS.LOADSTART, onNoError);

        return () => {
            Player.removeEventListener(VIDEO_EVENTS.ERROR, onError);
            Player.removeEventListener(VIDEO_EVENTS.LOADSTART, onNoError);
        };
    }, []);

    return (
        isError && (
            <div className="player-message-container">
                <div className="player-message-wrapper">
                    <Text
                        className="player-message-text"
                        typeface={Typeface.SANS}
                        size={FontSize.TITLE_3}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                        theme={Theme.Type.Dark}
                    >
                        {errorMessage}
                    </Text>
                    <Text
                        className="player-message-code"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_2}
                        weight={FontWeight.REGULAR}
                        color={Color.SECONDARY}
                        theme={Theme.Type.Dark}
                    >
                        {translate('ERROR_CODE_PLAYER')} {errorCode}
                    </Text>
                </div>
            </div>
        )
    );
};

export default PlayerError;
