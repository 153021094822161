import { setupDefaultPlayer } from '__SMART_APP_OLD__/platforms/flavours/defaultFlavour';
import { setupBroadpeakPlayer } from '__SMART_APP_OLD__/platforms/flavours/broadpeakFlavour';
import { selectPlayerSettings } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { Env } from 'App/Env';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { clpp } from '@castlabs/prestoplay';

export class PlayerEngine {
    public static initialize(drm: object | null, householdId: string): clpp.Player {
        const videoDomName = 'tv-player';
        const settings = selectPlayerSettings(store.getState());
        const currentVersion = Env.Version;
        const lastVersion = Storage.get(Storage.Key.APP_VERSION);
        if (!lastVersion || lastVersion !== currentVersion) {
            Storage.set(Storage.Key.APP_VERSION, currentVersion);
        }
        const webOSStartupPatch = Env.IsWebOS ? currentVersion === lastVersion : false;
        console.log(`[PlayerEngine.Initialized] with webOSStartupPatch value: ${webOSStartupPatch}`);
        const isBroadpeakEnv = Env.IsMacedonia || Env.IsCroatia;
        return isBroadpeakEnv
            ? setupBroadpeakPlayer(videoDomName, drm, householdId, settings, webOSStartupPatch)
            : setupDefaultPlayer(videoDomName, drm, householdId, settings, webOSStartupPatch);
    }
}
