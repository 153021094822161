import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen/selectors';

export const CommunitySelectionScreenSelected: React.FunctionComponent = () => {
    const title = useSelector(selectors.private.selectSelected);
    return (
        <Text
            className="community-selection__selected"
            typeface={Typeface.SANS}
            size={FontSize.BIG_1}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            {title}
        </Text>
    );
};
