import React, { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

type SerializerFunc = (text: string) => string;

interface Props {
    html: string;
    tag?: 'div' | 'p';
    className?: string;
    serializer?: SerializerFunc;
}

const defaultSerializer: SerializerFunc = (text: string) =>
    sanitizeHtml(text.replaceAll('\n', '</br>'), {
        allowedTags: ['br', 'ul', 'ol', 'li', 'div', 'hr', 'p', 'b'],
        disallowedTagsMode: 'discard',
    });

const InnerHTML: React.FC<Props> = ({ html = '', tag: Tag = 'div', serializer = defaultSerializer, ...restProps }) => {
    const serializedHTML = useMemo(() => serializer(html), [serializer, html]);

    return <Tag dangerouslySetInnerHTML={{ __html: serializedHTML }} {...restProps} />;
};

export { InnerHTML };
