import { API } from '__SMART_APP_OLD__/app/api';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { channelLiveChange, loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectChannelSwitchId, selectIsChannelIdValid } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import {
    selectChannelLiveId,
    selectAreChannelListChannelsLoaded,
    selectChannelListChannelBatches,
    selectChannelListChannelTotalCount,
    selectSelectedChannelListChannelIds,
    selectSelectedChannelListId,
    selectSelectedChannelListLiveChannelId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    ChannelListChannelsLoadOneAction,
    ChannelListCreateOneAction,
    ChannelListIdChangeAction,
    ChannelListLoadManyAction,
    ChannelListOrderChangeAction,
    ChannelListRemoveOneAction,
    ChannelListRenameOneAction,
    ChannelListUpdateOneAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/types';
import { eventLoadMany } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/actions';
import {
    selectEPGLimited,
    selectEventBy,
    selectFilteredChannelIdsWithLoadingEvents,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Exception } from '__SMART_APP_OLD__/app/modules/Error/Exception';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { Utils } from '__SMART_APP_OLD__/app/utils';

import { Cursor } from 'App/Packages/Cursor';

export const channelListIdChange = (channelListId: GQL.ChannelListID): ChannelListIdChangeAction => ({
    type: ActionType.CHANNEL_LIST_ID_CHANGE,
    payload: { channelListId },
});

export const channelListOrderChange = (channelListIds: GQL.ChannelListID[]): ChannelListOrderChangeAction => ({
    type: ActionType.CHANNEL_LIST_ORDER_CHANGE,
    payload: { channelListIds },
});

export const channelListLoadMany = (
    channelLists: GQL.ChannelList[],
    channelListChannelTotalCount: Record<GQL.ChannelListID, number>
): ChannelListLoadManyAction => ({
    type: ActionType.CHANNEL_LIST_LOAD_MANY,
    payload: { channelLists, channelListChannelTotalCount },
});

export const channelListChannelsLoadOne = (
    channelListId: GQL.ChannelListID,
    channels: GQL.ChannelListChannelEdge[]
): ChannelListChannelsLoadOneAction => ({
    type: ActionType.CHANNEL_LIST_CHANNELS_LOAD_ONE,
    payload: { channelListId, channels },
});

export const channelListCreateOne = (channelList: GQL.ChannelList, totalCount: number): ChannelListCreateOneAction => ({
    type: ActionType.CHANNEL_LIST_CREATE_ONE,
    payload: { channelList, totalCount },
});

export const channelListRenameOne = (channelListId: GQL.ChannelListID, name: string): ChannelListRenameOneAction => ({
    type: ActionType.CHANNEL_LIST_RENAME_ONE,
    payload: { channelListId, name },
});

export const channelListUpdateOne = (channelListId: GQL.ChannelListID, totalCount: number): ChannelListUpdateOneAction => ({
    type: ActionType.CHANNEL_LIST_UPDATE_ONE,
    payload: { channelListId, totalCount },
});

export const channelListRemoveOne = (channelListId: GQL.ChannelListID): ChannelListRemoveOneAction => ({
    type: ActionType.CHANNEL_LIST_REMOVE_ONE,
    payload: { channelListId },
});

export const reloadChannelLists = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const profileId = Profile.selectors.selectUserId(state);
    await dispatch(channelListLoad());
    const response = await API.ChannelList.loadInitialId(profileId);
    await dispatch(channelListChange(response.id, false));
};

export const channelListChannelsLoad =
    (channelListId: GQL.ChannelListID): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const totalCount = selectChannelListChannelTotalCount(channelListId)(state);
            const batches = Cursor.batch(0, totalCount);
            const responses = batches.map((batch) => API.ChannelList.loadManyChannelRelation({ channelListId, ...batch }));
            const channels = (await Utils.Promise.allFulfilled(responses))
                .reduce<GQL.ChannelListChannelEdge[]>((acc, res) => [...acc, ...res.edges], [])
                .filter((edge) => selectIsChannelIdValid(edge.node.id)(state));
            dispatch(channelListChannelsLoadOne(channelListId, channels));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListChannelsLoad, error));
            return false;
        }
    };

export const channelListChange =
    (channelListId: GQL.ChannelListID, init?: boolean): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const previousSelectedChannelListId = selectSelectedChannelListId(state);
        dispatch(channelListIdChange(channelListId));

        try {
            const areChannelsLoaded = selectAreChannelListChannelsLoaded(channelListId)(state);
            if (!areChannelsLoaded) await dispatch(channelListChannelsLoad(channelListId));

            if (!init) {
                const profileId = Profile.selectors.selectId(state);
                await API.ChannelList.change({ channelListId, profileId });
            }
            const liveId = selectSelectedChannelListLiveChannelId(getState());
            dispatch(channelLiveChange(liveId));
            return true;
        } catch (error) {
            dispatch(channelListIdChange(previousSelectedChannelListId));
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListChange, error));
            return false;
        }
    };

export const channelListCreate =
    (name: string, channels: GQL.ChannelID[]): Thunk<Promise<GQL.ChannelListID | null>> =>
    async (dispatch, getState) => {
        try {
            const profileId = Profile.selectors.selectId(getState());
            const response = await API.ChannelList.create({ profileId, name, channels });
            if (!response.channelList) throw new Exception(Error.Code.CHANNEL_LIST_NOT_FOUND, Error.Dispatcher.ChannelListCreate);
            dispatch(channelListCreateOne(response.channelList, response.totalCount));
            await dispatch(channelListChannelsLoad(response.channelList.id));
            return response.channelList.id;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListCreate, error));
            return null;
        }
    };

export const channelListReorder =
    (channelLists: GQL.ChannelListID[], channelListId: GQL.ChannelListID): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        try {
            await API.ChannelList.move({ channelListId, position: channelLists.indexOf(channelListId) });
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListReorder, error));
            return false;
        } finally {
            dispatch(channelListOrderChange(channelLists));
        }
    };

export const channelListLoad = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const profileId = Profile.selectors.selectId(getState());
        const response = await API.ChannelList.loadMany(profileId);
        dispatch(channelListLoadMany(response.channelLists, response.channelListChannelCount));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.ChannelListLoad, error));
        return false;
    }
};

export const channelListRename =
    (channelListId: GQL.ChannelListID, name: string): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        try {
            await API.ChannelList.rename({ channelListId, name });
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListRename, error));
            return false;
        } finally {
            dispatch(channelListRenameOne(channelListId, name));
        }
    };

export const channelListUpdate =
    (channelListId: GQL.ChannelListID, channels: GQL.ChannelID[]): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const response = await API.ChannelList.update({ channelListId, channels });
            dispatch(channelListUpdateOne(channelListId, response.totalCount));
            await dispatch(channelListChannelsLoad(channelListId));
            const state = getState();
            const selectedId = selectSelectedChannelListId(state);
            const liveChannelId = selectChannelLiveId(state);
            if (selectedId === channelListId && !channels.includes(liveChannelId)) dispatch(channelLiveChange(channels[0]));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListUpdate, error));
            return false;
        }
    };

export const channelListRemove =
    (channelListId: GQL.ChannelListID): Thunk<Promise<boolean>> =>
    async (dispatch) => {
        try {
            await API.ChannelList.remove(channelListId);
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.ChannelListRemove, error));
            return false;
        } finally {
            dispatch(channelListRemoveOne(channelListId));
        }
    };

export const channelListChannelEventLoad =
    (channelListId: GQL.ChannelListID, channelIds: GQL.ChannelID[], startTime: number, endTime: number): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const [start, end] = selectEPGLimited(startTime, endTime)(state);
            const duration = Math.max(Math.floor((end - start) / Time.SECOND_MS), Time.MINUTE_S * 10);
            const filteredIds = selectFilteredChannelIdsWithLoadingEvents(channelIds, start, end)(state);
            if (!filteredIds.length) return true;
            const batches = selectChannelListChannelBatches(channelListId, filteredIds)(state);
            const promises = batches.map<Promise<API.ChannelList.LoadManyChannelEventResponse>>((batch) =>
                API.ChannelList.loadManyChannelEvent({
                    ...batch,
                    channelListId,
                    start: new Date(start),
                    duration,
                    backgroundHeight: config.image.background.landscape,
                    backgroundWidth: config.image.background.width,
                    thumbnailHeight: config.image.thumbnail.small.height,
                })
            );
            const responses = await Utils.Promise.allFulfilled(promises);
            const events = responses.reduce<Record<GQL.ChannelID, GQL.Event[]>>(
                (acc, response) => ({ ...acc, ...response.channelEvents }),
                {}
            );

            dispatch(eventLoadMany({ events, start, end }));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.CHANNEL_LIST_CHANNEL_EVENT_LOAD, error));
            return false;
        }
    };

export const loadEventsForSelectedChannelList =
    (
        vissibleChannels: GQL.ChannelID[] | null,
        channelsToCheck: GQL.ChannelID[] | null,
        channelsToLoad: GQL.ChannelID[],
        startTime: number,
        endTime?: number
    ): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const channelListId = selectSelectedChannelListId(state);
        const [start, end] = selectEPGLimited(startTime, endTime ?? startTime)(state);
        if (vissibleChannels === null || channelsToCheck === null) {
            return dispatch(channelListChannelEventLoad(channelListId, channelsToLoad, start, end));
        }
        const filteredVissibleChannels = selectFilteredChannelIdsWithLoadingEvents(vissibleChannels ?? [], start, end)(state);

        if (filteredVissibleChannels.length) {
            await dispatch(channelListChannelEventLoad(channelListId, filteredVissibleChannels, start, end));
        }
        const stateAfterLoad = getState();
        const filteredChannelsToCheck = selectFilteredChannelIdsWithLoadingEvents(channelsToCheck ?? [], start, end)(stateAfterLoad);
        if (filteredChannelsToCheck.length || channelsToCheck === null) {
            return dispatch(channelListChannelEventLoad(channelListId, channelsToLoad, start, end));
        }
        return true;
    };

export const loadInitialEventsForEPG = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const liveId = selectChannelLiveId(state);
    const channelIds = selectSelectedChannelListChannelIds(state);
    const channels = Utils.EPG.getChannelsIds(channelIds.indexOf(liveId), channelIds);
    const now = Date.now();
    const start = now - Time.HOUR_MS * 3;
    const end = now + Time.HOUR_MS * 3;
    return dispatch(loadEventsForSelectedChannelList(null, null, channels[1], start, end));
};

export const loadEventsForTVGuide =
    (firstVissibleChannelId: GQL.ChannelID, start: number, end?: number): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const channelIds = selectSelectedChannelListChannelIds(state);
        const [visible, toCheck, toLoad] = Utils.EPG.getChannelsIds(channelIds.indexOf(firstVissibleChannelId), channelIds);
        return dispatch(loadEventsForSelectedChannelList(visible, toCheck, toLoad, start, end ?? start));
    };

export const loadLiveEventsForSelectedChannelList =
    (channelIds: GQL.ChannelID[]): Thunk<Promise<boolean>> =>
    async (dispatch) =>
        dispatch(loadEventsForSelectedChannelList(null, null, channelIds, Date.now()));

export const loadEventsForZapperMiniEpg =
    (channelId: GQL.ChannelID): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        const state = getState();
        const now = Date.now();
        const event = selectEventBy(channelId, now)(state);
        if (event?.isLoading) {
            await dispatch(loadEventsForChannel(channelId, now));
        }
        return dispatch(loadEventsForTVGuide(channelId, now));
    };

export const loadEventsForChannelSwitch = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const now = Date.now();
    const channelSwitchId = selectChannelSwitchId(state);
    const event = selectEventBy(channelSwitchId, now)(state);
    if (event && !event.isLoading === false) return true;
    return dispatch(loadEventsForChannel(channelSwitchId, now));
};
