import React, { useMemo } from 'react';

import { ChannelCardContext, ChannelCardContextType } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { ChannelCardContainer, ChannelCardContainerModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContainer';
import { ChannelCardFocus, ChannelCardFocusModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardFocus';
import { ChannelCardLogo, ChannelCardLogoModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardLogo';
import { ChannelCardName, ChannelCardNameModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardName';
import { ChannelCardNumber, ChannelCardNumberModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumber';
import { ChannelCardSelected, ChannelCardSelectedModule } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardSelected';
import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';

type Props = { id: GQL.ChannelID; isFocused: boolean; className?: string };

type ChannelCardModule = {
    Container: ChannelCardContainerModule;
    Focus: ChannelCardFocusModule;
    Logo: ChannelCardLogoModule;
    Number: ChannelCardNumberModule;
    Selected: ChannelCardSelectedModule;
    Name: ChannelCardNameModule;
} & React.FunctionComponent<React.PropsWithChildren<Props>>;

export const ChannelCard: ChannelCardModule = (props) => {
    const value = useMemo<ChannelCardContextType>(
        () => ({
            id: props.id,
            isFocused: props.isFocused,
        }),
        [props.id, props.isFocused]
    );

    const className = `channel-card-v2${props.className ? ` ${props.className}` : ''}${props.isFocused ? ' channel-card-v2--focused' : ''}`;
    const shadow = props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT;

    return (
        <Div className={className} backgroundColor={BackgroundColor.SECONDARY} shadow={shadow}>
            <ChannelCardContext.Provider value={value}>
                <ChannelCardContainer top left right bottom>
                    {props.children}
                </ChannelCardContainer>
            </ChannelCardContext.Provider>
        </Div>
    );
};

ChannelCard.Container = ChannelCardContainer;
ChannelCard.Focus = ChannelCardFocus;
ChannelCard.Logo = ChannelCardLogo;
ChannelCard.Number = ChannelCardNumber;
ChannelCard.Selected = ChannelCardSelected;
ChannelCard.Name = ChannelCardName;
