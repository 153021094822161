import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonWatchProps = {
    text: string;
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonWatchProps> = (props) => {
    return (
        <Folder.Detail.Action id="watch" icon={IconName.PLAY} onEnter={props.onEnter}>
            {props.text}
        </Folder.Detail.Action>
    );
};

export const DetailButtonWatch = hidable(Button);

export type DetailButtonWatchModule = typeof DetailButtonWatch;
