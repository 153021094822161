import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonBuyProps = {
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonBuyProps> = (props) => {
    return (
        <Folder.Detail.Action id="buy-button" icon={IconName.BUY} onEnter={props.onEnter}>
            SCREEN_DETAIL_BUY_BUTTON
        </Folder.Detail.Action>
    );
};

export const DetailButtonBuy = hidable(Button);

export type DetailButtonBuyModule = typeof DetailButtonBuy;
