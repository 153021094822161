import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import {
    selectDevice,
    selectIsDeviceEstDto,
    selectIsDeviceLocked,
} from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/selectors';
import { RemoveButton } from '__SMART_APP_OLD__/app/modules/Screen/modules/DeviceManagementScreen/RemoveButton';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { ReactComponent as LaptopIcon } from 'styles/assets/icon-laptop.svg';
import { ReactComponent as MobileAndroidIcon } from 'styles/assets/icon-mobile-android.svg';
import { ReactComponent as MobileIosIcon } from 'styles/assets/icon-mobile-ios.svg';
import { ReactComponent as TabletAndroidIcon } from 'styles/assets/icon-tablet-android.svg';
import { ReactComponent as TabletIosIcon } from 'styles/assets/icon-tablet-ios.svg';
import { ReactComponent as TVIcon } from 'styles/assets/icon-tv-default.svg';
import { ReactComponent as TVFireIcon } from 'styles/assets/icon-tv-fire.svg';
import { ReactComponent as TVHisenseIcon } from 'styles/assets/icon-tv-hisense.svg';
import { ReactComponent as TVLGIcon } from 'styles/assets/icon-tv-lg.svg';
import { ReactComponent as TVSamsungIcon } from 'styles/assets/icon-tv-samsung.svg';
import { ReactComponent as StbA1Icon } from 'styles/assets/icon-tv-stb-a1.svg';
import { ReactComponent as EstDtoIcon } from 'styles/assets/icon_est_dto.svg';

import { Focus } from 'App/Packages/Focus';

export const iconForDeviceType: Record<GQL.DeviceType, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
    [GQL.DeviceType.AmazonFireTv]: TVFireIcon,
    [GQL.DeviceType.AndroidPhone]: MobileAndroidIcon,
    [GQL.DeviceType.AndroidStb]: TVIcon,
    [GQL.DeviceType.AndroidTablet]: TabletAndroidIcon,
    [GQL.DeviceType.Chromecast]: () => null, // no icon
    [GQL.DeviceType.Controller]: () => null, // no icon
    [GQL.DeviceType.IosPhone]: MobileIosIcon,
    [GQL.DeviceType.IosTablet]: TabletIosIcon,
    [GQL.DeviceType.LinuxChrome]: LaptopIcon,
    [GQL.DeviceType.LinuxFirefox]: LaptopIcon,
    [GQL.DeviceType.MacosChrome]: LaptopIcon,
    [GQL.DeviceType.MacosEdge]: LaptopIcon,
    [GQL.DeviceType.MacosFirefox]: LaptopIcon,
    [GQL.DeviceType.MacosSafari]: LaptopIcon,
    [GQL.DeviceType.NativeDvbStb]: TVIcon,
    [GQL.DeviceType.NativeDvbStbLite]: TVIcon,
    [GQL.DeviceType.NativeStb]: StbA1Icon,
    [GQL.DeviceType.NativeStbIptvAdsl]: TVIcon,
    [GQL.DeviceType.NativeStbLite]: TVIcon,
    [GQL.DeviceType.NativeStbLiteOtt]: TVIcon,
    [GQL.DeviceType.NativeStbOtt]: TVIcon,
    [GQL.DeviceType.SmartTvAndroidTv]: TVIcon,
    [GQL.DeviceType.SmartTvSaphios]: TVIcon,
    [GQL.DeviceType.SmartTvTizen]: TVSamsungIcon,
    [GQL.DeviceType.SmartTvTvos]: TVIcon,
    [GQL.DeviceType.SmartTvVidaaos]: TVHisenseIcon,
    [GQL.DeviceType.SmartTvWebos]: TVLGIcon,
    [GQL.DeviceType.WindowsPcChrome]: LaptopIcon,
    [GQL.DeviceType.WindowsPcEdge]: LaptopIcon,
    [GQL.DeviceType.WindowsPcFirefox]: LaptopIcon,
    [GQL.DeviceType.WindowsPcInternetExplorer]: LaptopIcon,
    [GQL.DeviceType.Linux]: LaptopIcon,
    [GQL.DeviceType.Macos]: LaptopIcon,
    [GQL.DeviceType.WindowsPc]: LaptopIcon,
};

const classNameForThemeType: Record<Theme.Type, string> = {
    [Theme.Type.Dark]: 'device-item--dark-theme',
    [Theme.Type.Light]: 'device-item--light-theme',
};

const getItemClassName = (isFocused: boolean, isLocked: boolean, theme: Theme.Type) => {
    const classList = ['device-item'];
    if (isFocused) classList.push('device-item--focused');
    if (isLocked) classList.push('device-item--locked');
    classList.push(classNameForThemeType[theme]);
    return classList.join(' ');
};

export const DeviceItem: React.FunctionComponent<Focus.Focusable<string>> = React.memo((props) => {
    const theme = useTheme();
    const device = useSelector(selectDevice(props.id));
    const isLocked = useSelector(selectIsDeviceLocked(props.id));
    const isEstDtoDevice = useSelector(selectIsDeviceEstDto(props.id));
    const { onClick, onHover } = Focus.useFocusable(props);

    if (!device) return null;
    const className = getItemClassName(props.isFocused, isLocked, theme);
    const Icon = iconForDeviceType[device.deviceType];

    return (
        <div onClick={onClick} onMouseOver={onHover}>
            <div className={className}>
                {!isLocked && <RemoveButton isVisible={props.isFocused} />}
                {isEstDtoDevice && <EstDtoIcon className="device-item__est" />}
                <Icon className="device-item__icon" />
                <Text
                    className="device-item__name"
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_1}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                >
                    {device.name}
                </Text>
            </div>
        </div>
    );
});
