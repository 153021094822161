import { actions } from 'App/Modules/Prompt/Modules/RecordingConfirmCancelSeriesPrompt/Store/actions';
import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Prompt } from 'App/Modules/Prompt';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

const actionsIds = [
    Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.DeleteSeries,
    Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.CancelSeries,
];

export const View: React.FunctionComponent<Prompt.Props<Prompt.Type.RecordingConfirmCancelSeries>> = (props) => {
    const dispatch = useDispatch();
    const { eventId } = props;

    return (
        <UI.Alert
            zIndex={Prompt.zIndex}
            onClose={() => {
                dispatch(Prompt.actions.unmount());
            }}
        >
            <UI.Alert.Title>{Translation.Key.Prompt.ManageSeriesRecordingConfirm.Title}</UI.Alert.Title>
            <UI.Alert.Text>{Translation.Key.Prompt.ManageSeriesRecordingConfirm.Text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action
                    id={Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.DeleteSeries}
                    onEnter={() => {
                        dispatch(actions.private.deleteSeries(eventId));
                    }}
                >
                    {Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.DeleteSeries}
                </UI.Alert.Action>
                <UI.Alert.Action
                    id={Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.CancelSeries}
                    onEnter={() => {
                        dispatch(actions.private.cancelSeries(eventId));
                    }}
                >
                    {Translation.Key.Prompt.ManageSeriesRecordingConfirm.Buttons.CancelSeries}
                </UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
