import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ConsentFragmentDoc } from '../../../modules/Data/modules/Consent/api/fragments/consent.generated';
export type ProfilePreferencesFragment = { __typename?: 'ProfilePreferences', trackViewingBehaviour: boolean, firstAudioLanguage: string, firstSubtitleLanguage: string, secondAudioLanguage: string, secondSubtitleLanguage: string, consents: Array<{ __typename?: 'ProfileConsent', accepted: boolean, type: any } | null> };

export const ProfilePreferencesFragmentDoc = gql`
    fragment profilePreferences on ProfilePreferences {
  trackViewingBehaviour
  firstAudioLanguage
  firstSubtitleLanguage
  secondAudioLanguage
  secondSubtitleLanguage
  consents {
    ...consent
  }
}
    ${ConsentFragmentDoc}`;