import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import {
    channelListChannelsLoad,
    channelListCreate,
    channelListUpdate,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { selectChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { ChannelListsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/ChannelListsScreenCtx';
import { ChannelListOption } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreen/constants';
import { ChannelListsScreenViewMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';
import { ChannelListsScreenManageChannelsCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsCtx';
import {
    ChannelListsManageScreenActionType,
    ChannelListsManageScreenAddedChannelIdsChangedAction,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { OutOfBounds as FocusOutOfBounds } from 'App/Packages/Focus/Types/OutOfBounds';

const addedChannelIdsChangedAction = (channelIds: string[]): ChannelListsManageScreenAddedChannelIdsChangedAction => ({
    type: ChannelListsManageScreenActionType.CHANNEL_LISTS_MANAGE_SCREEN_ADDED_CHANNEL_IDS_CHANGED,
    payload: { channelIds },
});

const updateChannelIds =
    (newAddedChannelIds: string[]): Thunk<void> =>
    (dispatch) => {
        dispatch(addedChannelIdsChangedAction(newAddedChannelIds));
    };

const mount =
    (props: Screen.Props<Screen.Type.CHANNEL_LISTS_MANAGE>, ctx: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();

        if (props.channelListId) {
            const addedChannelIds = selectChannelListChannelIds(props.channelListId)(state);
            dispatch(addedChannelIdsChangedAction(addedChannelIds));
            await dispatch(channelListChannelsLoad(props.channelListId));
        }

        if (ctx === ChannelListsScreenManageChannelsCtx.All) return;

        await dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_MANAGE, ChannelListsScreenManageChannelsCtx.All));
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
    dispatch(addedChannelIdsChangedAction([]));
};

const outOfBounds: FocusOutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === SettingsCtx.Back && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_MANAGE, ChannelListsScreenManageChannelsCtx.All));
    }

    if (ctx === ChannelListsScreenManageChannelsCtx.All && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_MANAGE, SettingsCtx.Back));
    }

    if (ctx === ChannelListsScreenManageChannelsCtx.All && event.x === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_MANAGE, ChannelListsScreenManageChannelsCtx.Added));
    }

    if (ctx === ChannelListsScreenManageChannelsCtx.Added && event.x === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.CHANNEL_LISTS_MANAGE, ChannelListsScreenManageChannelsCtx.All));
    }

    return undefined;
};

const createModeBack =
    (addedChannelIds: string[], channelListName: string): Thunk<void> =>
    async (dispatch) => {
        if (addedChannelIds.length === 0) {
            await dispatch(
                Screen.actions.mount(Screen.Type.CHANNEL_LISTS_CREATE, {
                    mode: ChannelListsScreenViewMode.CREATE,
                    initialInputValue: channelListName,
                })
            );
            dispatch(History.actions.pop());
            return;
        }
        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        const channelListId = await dispatch(channelListCreate(channelListName, addedChannelIds));
        dispatch(History.actions.pop());
        dispatch(History.actions.pop());
        await dispatch(
            Screen.actions.mount(Screen.Type.CHANNEL_LISTS, { initialFocusedId: channelListId }, ChannelListsScreenCtx.ChannelLists)
        );
        await dispatch(Overlay.actions.unmount());

        if (!channelListId) return;

        LoggingService.getInstance().logEvent(
            getUIActionEvent(UIActionEvents.CHANNEL_LIST_CREATED, {
                id: channelListId,
                assetType: AssetType.CHANNEL_LIST,
            })
        );
        dispatch(textNotificationShow('NOTIFICATION_CHANNEL_LIST_CREATED_SUCCESSFULLY'));
    };

const updateModeBack =
    (channelListId: string, addedChannelIds: string[], initialAddedItems: string[]): Thunk<void> =>
    async (dispatch) => {
        if (JSON.stringify(addedChannelIds) === JSON.stringify(initialAddedItems)) {
            dispatch(History.actions.pop());
            return;
        }

        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        const success = await dispatch(channelListUpdate(channelListId, addedChannelIds));
        dispatch(History.actions.pop());
        await dispatch(
            Screen.actions.mount(
                Screen.Type.CHANNEL_LISTS,
                {
                    initialFocusedId: channelListId,
                    optionSelected: ChannelListOption.ManageChannelLists,
                },
                ChannelListsScreenCtx.ChannelListsOptions
            )
        );
        await dispatch(Overlay.actions.unmount());

        if (!success) return;

        LoggingService.getInstance().logEvent(
            getUIActionEvent(UIActionEvents.CHANNEL_LIST_CHANGED, {
                id: channelListId,
                assetType: AssetType.CHANNEL_LIST,
            })
        );
        dispatch(textNotificationShow('NOTIFICATION_CHANNEL_LIST_EDITED_SUCCESSFULLY'));
    };

export const channelListsScreenManageActions = {
    private: {
        createModeBack,
        updateModeBack,
        addedChannelIdsChangedAction,
        updateChannelIds,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
