/* eslint-disable max-lines */
import { clpp } from '@castlabs/prestoplay';
import React from 'react';

import { API } from '__SMART_APP_OLD__/app/api';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { playChannel, playVod } from '__SMART_APP_OLD__/api/Tuner';
import { Image, Orientation } from '__SMART_APP_OLD__/app/components/Image';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import {
    selectBookmarkStartTimeout,
    selectHideControlsAfter,
    selectLiveCatchupThreshold,
    selectShowCatchupIcon,
    selectStartOverMinPosition,
    selectBackgroundLandscapeHeight,
    selectBackgroundWidth,
    selectThumbnailDefaultHeight,
    selectBingeWatchingThreshold,
    selectIsBingeWatchingEnabled,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { NotificationIconType } from '__SMART_APP_OLD__/app/modules/Notification/types';
import {
    selectChannelListChannelIds,
    selectSelectedChannelListId,
} from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { BingeWatchDialog } from '__SMART_APP_OLD__/components/BingeWatchDialog';
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { GetBingeWatchDataDocument } from '__SMART_APP_OLD__/api/graphql/query/getBingeWatchData.generated';
import { channelLiveIdChange, loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { selectChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import {
    selectEventBackground,
    selectEventBy,
    selectEventChannelID,
    selectEventIsLive,
    selectEventIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import {
    selectCurrentParentalRatingRank,
    selectParentalRatingIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { pinActiveStatusChanged, pinActiveStatusLiveTvChanged } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/actions';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import {
    channelNotInActiveChannelListNotificationShow,
    eventNotAvailableNotificationShow,
    textNotificationShow,
} from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { Player as PlayerModule } from '__SMART_APP_OLD__/app/modules/Player';
import { selectRecordingManagementChannelId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import PlayerError from '__SMART_APP_OLD__/components/common/PlayerError';
import { MiniEPG } from '__SMART_APP_OLD__/components/EPG';
import { openOnVisibilityChangePinOverlay, openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import PlayerUI from '__SMART_APP_OLD__/components/playerUI/PlayerUI';
import Events, { INTERNET_CONNECTION_OFF, INTERNET_CONNECTION_ON, PLAYER_SEEK } from '__SMART_APP_OLD__/config/Events';
import Preferences from '__SMART_APP_OLD__/config/Preferences';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player, { VIDEO_EVENTS } from '__SMART_APP_OLD__/platforms/Player';
import {
    AssetType,
    NavKey,
    PinAction,
    PlayerNotificationIcon,
    PlayerType,
    ProgramType,
    playerDownloadErrors,
} from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import { isCatchupAsset, isRecordingAsset, isVodEpisodeAsset, maskAsset } from '__SMART_APP_OLD__/utils/dataUtils';
import State from '__SMART_APP_OLD__/utils/State';
import { convertMilliseconds } from '__SMART_APP_OLD__/utils/timeUtils';
import { changePlayerVisibility, debounce } from '__SMART_APP_OLD__/utils/Utils';
import {
    isApplicationVisible,
    subscribeVisibilityChangeHandler,
    unsubscribeVisibilityChangeHandler,
} from '__SMART_APP_OLD__/utils/visibilityChange';
import { PlaybackEvents } from 'analytics/logging/events/PlaybackEvent';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Env } from 'App/Env';
import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { Key } from 'App/Modules/Key';
import { Function } from 'App/Packages/Function';
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { UI } from 'App/Packages/UI';
import { StandByService } from 'App/Modules/Common/Components/StandByService';
import { Keyboard } from 'App/Modules/Keyboard';
import { Overlay } from 'App/Modules/Overlay';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';

class PlayVod extends React.Component {
    data = null;

    streamInitiated = false;

    timerId;

    iconTimerId;

    channelId = null;

    nextAsset = null;

    prevAsset = null;

    isEventSwitchingInProgress = false;

    isEventSwitchingBlocked = true;

    preSeekTimeUpdateValue = 0;

    positionBeforeNetworkLost = null;

    resetPlayerUITimer = debounce(() => {
        const { visibleComponent } = this.state;
        if (!this.isShowingPlayerUI(visibleComponent) || this.isFreezedUI) {
            return;
        }
        this.hidePlayerUI();
        this.toggleNotificationButton(false);
    }, this.props.hideControlsAfter);

    constructor(props) {
        super(props);
        const { asset, bookmark } = props;

        this.state = {
            visibleComponent: null,
            showNotificationButton: false,
            showReplayButton: false,
            shouldShowBingeWatchDialog: false,
            showBingeWatchDialog: false,
            isBingeWatchingCancelled: false,
            backgroundImage: null,
            notificationIconToShow: null,
            hasInternetConnection: true,
        };
        this.data = asset;
        Player.setBookmark(Number(bookmark));
        if (asset.channelId) this.channelId = asset.channelId;
        this.playVod();
    }

    componentDidMount() {
        changePlayerVisibility(true);
        this.showPlayerUI();
        if (!Focus.focused) {
            this.focusContainer();
        }

        Events.addEventListener(INTERNET_CONNECTION_ON, this.onInternetConnectionRestored);
        Events.addEventListener(INTERNET_CONNECTION_OFF, this.onInternetConnectionLost);
        Player.addEventListener(VIDEO_EVENTS.SEEKED, this.onSeeked);
        Player.addEventListener(VIDEO_EVENTS.ERROR, this.handlePlayerRestart);
        Player.addEventListener(VIDEO_EVENTS.LOADEDDATA, this.handleLoadedData);
        Player.addEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        Player.addEventListener(VIDEO_EVENTS.CANPLAY, this.onCanPlay);
        Player.addEventListener(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange);

        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);

        subscribeVisibilityChangeHandler(this.onVisibilityChange);

        Player.unMute();

        const { assetType } = this.data;
        this.updateNextAsset(assetType);
    }

    componentDidUpdate(prevProps, prevState) {
        const { id: prevId } = prevProps;
        const { id } = this.props;
        const { assetType } = this.data;
        if (prevId !== id) {
            this.updateNextAsset(assetType);
            this.playVod();
            this.showPlayerUI();
            return;
        }

        if (!prevState.showBingeWatchDialog && this.state.showBingeWatchDialog && this.state.shouldShowBingeWatchDialog) {
            this.showBingeWatchDialog();
        }

        if (this.state.notificationIconToShow === PlaybackEvents.PLAY) {
            clearTimeout(this.timerId);
            this.iconTimerId = setTimeout(() => {
                this.toggleShowNotificationIcon(null);
            }, 1000);
        }

        this.setAssets(assetType);
    }

    componentWillUnmount() {
        Events.removeEventListener(INTERNET_CONNECTION_ON, this.onInternetConnectionRestored);
        Events.removeEventListener(INTERNET_CONNECTION_OFF, this.onInternetConnectionLost);
        Player.removeEventListener(VIDEO_EVENTS.SEEKED, this.onSeeked);
        Player.removeEventListener(VIDEO_EVENTS.LOADEDDATA, this.handleLoadedData);
        Player.removeEventListener(VIDEO_EVENTS.TIMEUPDATE, this.onTimeUpdate);
        Player.removeEventListener(VIDEO_EVENTS.ERROR, this.handlePlayerRestart);
        Player.removeEventListener(VIDEO_EVENTS.CANPLAY, this.onCanPlay);
        Player.removeEventListener(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange);

        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        unsubscribeVisibilityChangeHandler(this.onVisibilityChange);
        PlayerAPI.stopPlayback();
        clearTimeout(this.timerId);
        clearTimeout(this.iconTimerId);
        clearTimeout(this.repeatSeekInCatchUpTimeout);
    }

    async updateNextAsset(assetType) {
        switch (assetType) {
            case AssetType.EVENT: {
                this.getBulkPrevAssets();
                this.getProgramEventContinuousPlaybackData();
                break;
            }
            case AssetType.EPISODE: {
                if (!this.props.isBingeWatchingEnabled) break;
                const nextAsset = await this.getNextEpisode();
                this.setNextEpisode(nextAsset);
                this.setState({
                    showBingeWatchDialog: false,
                    shouldShowBingeWatchDialog: true,
                    isBingeWatchingCancelled: false,
                });
                break;
            }
            default:
                break;
        }
    }

    async setAssets(assetType) {
        if (this.nextAsset) return;
        switch (assetType) {
            case AssetType.EVENT: {
                const nextAsset = this.getNextAsset();
                const prevAsset = this.getPrevAsset();
                this.setNextAsset(nextAsset);
                this.setPrevAsset(prevAsset);
                break;
            }
            case AssetType.EPISODE: {
                if (!this.props.isBingeWatchingEnabled) break;
                const nextAsset = await this.getNextEpisode();
                this.setNextEpisode(nextAsset);
                this.setState({ shouldShowBingeWatchDialog: true });
                break;
            }
            default:
                break;
        }
    }

    getNextEpisode = async () => {
        const { id } = this.props;
        if (!isVodEpisodeAsset(this.data)) return null;

        const bingeWatchDataVariables = {
            variables: {
                currentItemId: id,
                currentItemType: ContentItemType.Vodasset,
            },
            fetchPolicy: 'no-cache',
        };
        const bingeWatchResponse = await GraphqlClient.makeGraphqlRequest(
            GetBingeWatchDataDocument,
            bingeWatchDataVariables.variables,
            bingeWatchDataVariables.fetchPolicy
        );
        const bingeableAssets = bingeWatchResponse.data?.bingeWatchData?.content?.items?.edges;
        const currentAssetIndex = bingeableAssets.findIndex((asset) => asset.node?.id === id);
        if (currentAssetIndex + 1 > bingeableAssets.length) return null;

        const vodAssetResponse = await API.Vod.vodAsset({
            vodAssetId: bingeableAssets[currentAssetIndex + 1].node.id,
            thumbnailHeight: this.props.vodAssetThumbnailHeight,
            backgroundHeight: this.props.vodAssetBackgroundHeight,
            backgroundWidth: this.props.vodAssetBackgroundHeight,
        });

        return vodAssetResponse?.data?.vodAsset;
    };

    setNextEpisode = (nextEpisode) => {
        if (!nextEpisode || nextEpisode.isLoading) return;
        const newAsset = createAssetObject(nextEpisode, AssetType.VOD_ASSET);
        const { attachedAssets } = this.data;
        if (attachedAssets) newAsset.attachedAssets = attachedAssets;
        this.nextAsset = newAsset;
    };

    getNextEvent = () => {
        const { channelId, endTime } = this.data;
        const state = store.getState();
        const nextEventStartTime = new Date(endTime).getTime();
        return selectEventBy(channelId, nextEventStartTime, { equal: EqualityCompare.START, noLoading: false })(state);
    };

    getNextAsset = () => {
        const state = store.getState();
        const channel = selectChannel(this.data.channelId)(state);
        const nextEvent = this.getNextEvent();
        return createAssetObject({ ...nextEvent, channel }, AssetType.EVENT);
    };

    setNextAsset = (nextAsset) => {
        if (!nextAsset || nextAsset.isLoading) return;
        this.nextAsset = nextAsset;
    };

    getProgramEventContinuousPlaybackData = async () => {
        const { channelId, endTime } = this.data;
        if (channelId) this.channelId = channelId;
        const nextAsset = this.getNextAsset();
        // if there is no data we are trying to download it and repeat data preparation again
        if (!nextAsset || nextAsset.isLoading) {
            this.nextAsset = null;
            const nextAssetStartTime = new Date(endTime).getTime();
            this.props.loadEventsForChannel(this.channelId, nextAssetStartTime, nextAssetStartTime + Time.MINUTE_MS);
            return;
        }
        this.setNextAsset(nextAsset);
        const state = store.getState();
        const afterNextAssetStartTime = new Date(endTime).getTime();
        const afterNextAsset = selectEventBy(channelId, afterNextAssetStartTime, { equal: EqualityCompare.START, noLoading: false })(state);
        if (!afterNextAsset || afterNextAsset.isLoading) {
            this.props.loadEventsForChannel(this.channelId, afterNextAssetStartTime, afterNextAssetStartTime + Time.MINUTE_MS);
        }
    };

    getPrevEvent = () => {
        const { channelId, startTime } = this.data;
        const state = store.getState();
        const prevAssetEndTime = new Date(startTime).getTime();
        return selectEventBy(channelId, prevAssetEndTime, { equal: EqualityCompare.END, noLoading: false })(state);
    };

    getPrevAsset = () => {
        const state = store.getState();
        const channel = selectChannel(this.data.channelId)(state);
        const prevEvent = this.getPrevEvent();
        return createAssetObject({ ...prevEvent, channel }, AssetType.EVENT);
    };

    setPrevAsset = (prevAsset) => {
        if (!prevAsset || prevAsset.isLoading) return;
        this.prevAsset = prevAsset;
    };

    setCurrentAsset = (asset) => {
        if (!asset || asset.id === this.data.id) return;
        this.data = asset;
    };

    getBulkPrevAssets = async () => {
        const { channelId, startTime } = this.data;
        if (channelId) this.channelId = channelId;
        let prevAsset = this.getPrevAsset();
        if (!prevAsset || prevAsset.isLoading || prevAsset.rawData.isLoading) {
            this.prevAsset = null;
            const prevAssetEndTime = new Date(startTime).getTime();
            await this.props.loadEventsForChannel(this.channelId, prevAssetEndTime - Time.HOUR_MS * 5, prevAssetEndTime);
            prevAsset = this.getPrevAsset();
        }
        this.setPrevAsset(prevAsset);
    };

    getShouldSwitchToNextCatchup(position = 0) {
        if (!this.isProgramEvent()) return false;
        const shouldSwitchCatchup = position > this.getDuration() - this.data.leadOut;
        if (!this.nextAsset || !shouldSwitchCatchup) return false;
        return !!this.nextAsset && shouldSwitchCatchup;
    }

    onTimeUpdate = () => {
        let startOverBtnThreshold = convertMilliseconds(this.props.startOverMinPosition);
        if (this.isProgramEvent()) {
            startOverBtnThreshold += this.data.leadIn;
        }
        this.setState({ showReplayButton: this.getPlayedTime() >= startOverBtnThreshold });

        if (this.getShouldSwitchToNextCatchup(this.getPlayedTime())) this.playEvent(this.nextAsset);

        if (
            this.state.shouldShowBingeWatchDialog &&
            !this.state.showBingeWatchDialog &&
            !this.state.isBingeWatchingCancelled &&
            !!this.nextAsset &&
            Player.getPositionToEnd() <= convertMilliseconds(this.props.bingeWatchingThreshold, 's')
        ) {
            this.setState({ showBingeWatchDialog: true });
        }
    };

    preSeekTimeUpdateHandler = (time = 0) => {
        this.preSeekTimeUpdateValue = time;
    };

    getPlayedTime = (isFloor = true) => {
        if (this.isDynamic()) {
            return Player.getDynamicCatchupPlayedTime(isFloor);
        }
        return Player.getPlayedTime(isFloor);
    };

    getDuration = () => {
        if (this.data.isTrailer) {
            return Player.getDuration();
        }
        return this.data.duration + (this.data?.leadIn ?? 0) + (this.data?.leadOut ?? 0);
    };

    isDynamic = () => Player.isCatchupDynamic(this.data);

    handlePlayFromStartDynamic = () => {
        const startPosition = Player.getDynamicCatchupStartPosition() + this.data.leadIn;
        const minSeekablePosition = Player.getDynamicCatchupStartPosition() + this.props.liveCatchupThreshold;
        Player.seek(Math.max(startPosition, minSeekablePosition));
    };

    handlePlayerRestart = async (error) => {
        // is internet connection was lost or number of attempts exceeded
        // we stop the player and show debugging message
        if (!window.navigator.onLine) return;
        const isDownLoadError = playerDownloadErrors.includes(error.detail.code);
        // player error is not connected to http request no need to restart player return early
        if (isDownLoadError) {
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
            History.back();
        }
    };

    handleLoadedData = () => {
        const { bookmark } = this.props;

        if (!this.isDynamic()) {
            this.isEventSwitchingBlocked = false;
        }

        if (this.isDynamic() && !Number(bookmark)) {
            this.handlePlayFromStartDynamic();
        }
        this.isEventSwitchingInProgress = false;
    };

    handlePlayFromStart = () => {
        if (this.isDynamic()) {
            this.handlePlayFromStartDynamic();
        } else {
            const startPosition = this.isProgramEvent() || isRecordingAsset(this.data) ? this.data.leadIn : 0;
            Player.seek(startPosition);
        }
    };

    onSeeked = () => {
        this.preSeekTimeUpdateValue = 0;
        if (this.timerId !== undefined) {
            clearTimeout(this.timerId);
            this.timerId = undefined;
        }

        this.timerId = setTimeout(() => {
            this.timerId = undefined;
            if (Player.getPlayedTime(false) === 0) {
                Player.seek(1);
            }
        }, 1500);

        if (this.isEventSwitchingBlocked && !Player.getBookmark()) {
            this.isEventSwitchingBlocked = false;
        }
    };

    onInternetConnectionLost = () => {
        this.setState({ hasInternetConnection: false });
        this.positionBeforeNetworkLost = Player.getPlayedTime();
        if (Player.isPlaying()) {
            this.toggleNotificationButton(true);
            Player.pause();
        }
    };

    onInternetConnectionRestored = () => {
        this.setState({ hasInternetConnection: true });
        this.toggleNotificationButton(false);
        if (!Player.isError() && !Player.isPlaying() && !Player.isIdle()) {
            Player.play();
            return;
        }
        Player.setPositionBeforeNetworkLost(Number(this.positionBeforeNetworkLost));
        this.playVod();
    };

    playVod = () => {
        const { type, id } = this.props;
        const state = store.getState();
        const eventChannelId = selectEventChannelID(id)(state);
        const recordingChannelId = selectRecordingManagementChannelId(id)(state);
        console.log('[PlayVod] Try to start vod playback', type, id);
        switch (type) {
            case PlayerType.EVENT:
                Preferences.lastWatchedChannel = eventChannelId || this.data.channelId;
                this.props.channelLiveIdChange(eventChannelId || this.data.channelId);
                Player.setProgram(this.data);
                PlayerAPI.setCatchupStream(id);
                this.data.isTrailer = this.data ? false : null;
                break;
            case PlayerType.NETWORK_RECORDING:
                Preferences.lastWatchedChannel = recordingChannelId;
                this.props.channelLiveIdChange(recordingChannelId);
                Player.setProgram(this.data);
                PlayerAPI.setRecordingStream(id);
                this.data.isTrailer = this.data ? false : null;
                break;
            case PlayerType.EPISODE:
            case PlayerType.VOD_ASSET: {
                const { vodAssetEntitlementId, temporaryEntitlement } = this.data;
                const entitlement = vodAssetEntitlementId || temporaryEntitlement?.id;
                Player.setProgram(this.data);
                PlayerAPI.setVODStream(id, entitlement);
                this.data.programType = ProgramType.VOD;
                this.data.isTrailer = false;
                break;
            }
            case PlayerType.TRAILER: {
                const { trailerId } = this.data;
                Player.setProgram(this.data);
                PlayerAPI.setTrailerStream(trailerId);
                this.data.programType = ProgramType.VOD;
                this.data.isTrailer = true;
                break;
            }

            default:
                break;
        }
    };

    focusContainer = () => {
        if (this.containerRef) {
            Focus.focus(this.containerRef);
        }
    };

    isProgramEvent = () => isCatchupAsset(this.data);

    isShowingPlayerUI = (visibleComponent) => visibleComponent === PlayerModule.VisibleComponent.PLAYER_UI;

    noVisibleComponent = (visibleComponent) => visibleComponent === null;

    onEnded = () => {
        if (this.isEventSwitchingInProgress) return;
        if (this.isProgramEvent() && this.nextAsset) {
            this.playEvent(this.nextAsset);
        } else if (!this.state.isBingeWatchingCancelled && isVodEpisodeAsset(this.data) && this.nextAsset) {
            this.playNextEpisode();
        } else {
            Player.stop();
            History.back();
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        }
    };

    onCanPlay = () => {
        this.props.completeIfVodUpsellTransaction();
        if (!this.streamInitiated) {
            Player.setInitialSubtitles();
            this.streamInitiated = true;
        }
    };

    playNextEpisode = async () => {
        if (this.isEventSwitchingInProgress || this.isEventSwitchingBlocked) return undefined;
        this.setState({
            showBingeWatchDialog: false,
            shouldShowBingeWatchDialog: false,
        });
        this.hideOverlay();
        this.saveBookmark(true);
        this.isEventSwitchingInProgress = true;
        this.isEventSwitchingBlocked = true;
        const isRestricted = selectParentalRatingIsRestricted(this.nextAsset.parentalRating, PinSessionType.PIN)(store.getState());
        await Player.stop();
        if (isRestricted) {
            this.props.notificationShow('PIN_REQUIRED_EVENT_RESTRICTED_NOTIFICATION', 5);
            return new Promise((resolve) => openPinOverlay(Function.noop, (status) => resolve(!!status)))
                .then(async (status) => {
                    if (!status) {
                        return History.go(`/details/${AssetType.VOD_SERIES}/${this.nextAsset.seriesId}`, {
                            episodeData: {
                                seasonOrdinal: this.nextAsset.seasonOrdinal,
                                episodeOrdinal: this.nextAsset.episodeOrdinal,
                            },
                        });
                    }
                    return this.handleSwitchingEpisode();
                })
                .catch(() => {
                    this.isEventSwitchingInProgress = false;
                    this.isEventSwitchingBlocked = false;
                    return History.go(`/details/${AssetType.VOD_SERIES}/${this.nextAsset.seriesId}`, {
                        episodeData: {
                            seasonOrdinal: this.nextAsset.seasonOrdinal,
                            episodeOrdinal: this.nextAsset.episodeOrdinal,
                        },
                    });
                });
        }
        return this.handleSwitchingEpisode();
    };

    playEvent = async (asset) => {
        if (this.isEventSwitchingInProgress || this.isEventSwitchingBlocked) return undefined;
        this.saveBookmark(true);
        this.isEventSwitchingInProgress = true;
        this.isEventSwitchingBlocked = true;
        const isRestricted = selectEventIsRestricted(asset.id, PinSessionType.PIN_LIVE_TV)(store.getState());
        if (isRestricted) {
            await Player.stop();
            this.props.notificationShow('PIN_REQUIRED_EVENT_RESTRICTED_NOTIFICATION', 5);
            openPinOverlay(
                () => {
                    this.handleSwitchingEvent(asset);
                },
                (status) => {
                    if (!status) {
                        this.isEventSwitchingBlocked = false;
                        this.isEventSwitchingInProgress = false;
                        History.go(`/details/${AssetType.EVENT}/${this.data.id}`);
                    }
                },
                PinAction.ENTER,
                PinSessionType.PIN_LIVE_TV,
                false
            );
            // eslint-disable-next-line consistent-return
            return;
        }
        return this.handleSwitchingEvent(asset);
    };

    onVisibilityChange = () => {
        if (isApplicationVisible()) {
            const parentalRatingRank = selectCurrentParentalRatingRank(store.getState());
            maskAsset(this.data, parentalRatingRank, PinSessionType.PIN);
            const isRestricted = this.data.shouldRestrict && this.data.shouldMask;
            if (isRestricted) {
                Focus.stash();
                openOnVisibilityChangePinOverlay(PinSessionType.PIN);
                return;
            }
            Player.restore();
        } else {
            this.props.pinActiveStatusChanged(false);
            this.props.pinLiveStatusChanged(false);
            Player.suspend();
        }
    };

    handleSwitchingEvent = (asset) => {
        if (selectEventIsLive(asset.id)(store.getState())) {
            State.set(NavKey.PAGE_WITH_PLAYER, { visibleComponent: PlayerModule.VisibleComponent.PLAYER_UI, playFromStart: true });
            playChannel(this.channelId, true);
            return;
        }

        if (!asset.catchupTV) {
            this.hidePlayerUI();
            this.setState({ backgroundImage: selectEventBackground(asset.id)(store.getState()) });
            this.props.eventNotAvailableNotificationShow(asset.id);
            return;
        }

        this.data = asset;
        const startFrom = this.data.leadIn;
        Player.setBookmark(startFrom);
        playVod(asset, {
            isStartOver: false,
            isTrailer: false,
            isReplaceNeeded: true,
            bookmarkPosition: startFrom,
        });
    };

    handleSwitchingEpisode = () => {
        this.data = this.nextAsset;

        const { bookmark, vodAssetEntitlementId, seasonOrdinal, episodeOrdinal } = this.data;
        const startFrom = bookmark?.position ?? 0;

        if (!vodAssetEntitlementId || vodAssetEntitlementId === '') {
            return History.go(`/details/${AssetType.VOD_SERIES}/${this.data.seriesId}`, {
                episodeData: {
                    seasonOrdinal,
                    episodeOrdinal,
                },
            });
        }

        Player.setBookmark(startFrom);
        return playVod(this.data, {
            isStartOver: false,
            isTrailer: false,
            isReplaceNeeded: true,
            bookmarkPosition: startFrom,
        });
    };

    /**
     *
     * @param watched - when event was watched we should save the whole duration as a bookmark
     * position, to remove event from Continue Watching stripe.
     *
     * @returns {void}
     */
    saveBookmark = (watched = false) => {
        const { id, assetType } = this.data;
        const playedTime = this.preSeekTimeUpdateValue || Player.getPlayedTime();
        // while the event switching is in progress,
        // Player.getPlayedTime() still returns the played time of the previous event.
        const bookmarkStartTimeout = this.isProgramEvent()
            ? convertMilliseconds(this.props.bookmarkStartTimeout) + (this?.data?.leadIn ?? 0)
            : convertMilliseconds(this.props.bookmarkStartTimeout);

        const setBookmark = playedTime >= bookmarkStartTimeout && !this.isEventSwitchingInProgress;
        if (setBookmark) {
            const bookmark = watched ? Math.floor(this.getDuration()) : Math.floor(playedTime);
            switch (assetType) {
                case AssetType.EVENT: {
                    this.props.saveBookmark({ position: bookmark, eventId: id });
                    break;
                }
                case AssetType.NETWORK_RECORDING: {
                    this.props.saveBookmark({ position: bookmark, recordingId: id });
                    break;
                }
                case AssetType.VOD_ASSET:
                case AssetType.EPISODE: {
                    this.props.saveBookmark({ position: bookmark, vodAssetId: id });
                    break;
                }
                default:
                    break;
            }
        }
    };

    stopPlayer = async () => {
        this.saveBookmark();
        await Player.stop();
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        History.back();
    };

    showMiniEPG = () => {
        if (this.state.visibleComponent) return false;

        const channelListId = selectSelectedChannelListId(store.getState());
        const channelIds = selectChannelListChannelIds(channelListId)(store.getState());
        const isChannelInChannelList = channelIds.find((channelId) => channelId === this.channelId);
        if (!isChannelInChannelList) {
            this.props.channelNotInActiveChannelListNotificationShow(
                'NOT_IN_ACTIVE_CHANNEL_LIST',
                NotificationIconType.INFO,
                this.data.channelName
            );
            return true;
        }

        this.setState({ visibleComponent: PlayerModule.VisibleComponent.MINI_EPG });
        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: PlayerModule.VisibleComponent.MINI_EPG,
        });
        return false;
    };

    handleFastForwardAndRewind = (keyCode, evenType, playerUiVisible) => {
        const { visibleComponent } = this.state;
        const focusedEl = Focus.focused?.nodeRef;
        const scrubberWrapper = document.querySelector('.scrubber-wrapper');

        if (focusedEl !== scrubberWrapper && this.noVisibleComponent(visibleComponent)) {
            return Events.triggerEvents(PLAYER_SEEK, { keyCode, evenType, playerUiVisible });
        }
        return false;
    };

    handlePlayerSeekWithArrowKeys = (visibleComponent, playerUiVisible, keyCode) => {
        const state = store.getState();
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitleMenu)(state);

        if (overlay) return;
        if (this.noVisibleComponent(visibleComponent)) {
            Events.triggerEvents(PLAYER_SEEK, { keyCode, playerUiVisible });
        }
    };

    handleShowMiniEPG = () => {
        const state = store.getState();
        const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitleMenu)(state);
        if (overlay) return;
        this.showMiniEPG();
    };

    // eslint-disable-next-line complexity
    handleKeyDown = (event) => {
        const { keyCode } = event;
        const { visibleComponent } = this.state;
        const { assetType } = this.data;
        const playerUiVisible = this.isShowingPlayerUI(visibleComponent);
        if (assetType !== AssetType.VOD_ASSET && assetType !== AssetType.TRAILER && assetType !== AssetType.EPISODE) {
            switch (keyCode) {
                case Key.VK_LEFT:
                case Key.VK_RIGHT: {
                    this.handleShowMiniEPG();
                    return false;
                }
                case Key.VK_REWIND:
                case Key.VK_FAST_FWD:
                    return this.handleFastForwardAndRewind(keyCode, event.type, playerUiVisible);
                case Key.VK_STOP:
                    this.stopPlayer();
                    return false;
                case Key.VK_BACK:
                    event.preventDefault();
                    this.hidePlayerUI();
                    return false;
                default:
                    return false;
            }
        }

        switch (keyCode) {
            case Key.VK_REWIND:
            case Key.VK_FAST_FWD:
                this.handleFastForwardAndRewind(keyCode, event.type, playerUiVisible);
                break;
            case Key.VK_LEFT:
            case Key.VK_RIGHT: {
                this.handlePlayerSeekWithArrowKeys(visibleComponent, playerUiVisible, keyCode);
                break;
            }
            case Key.VK_STOP:
                this.stopPlayer();
                break;
            case Key.VK_BACK:
                event.preventDefault();
                this.hidePlayerUI();
                break;
            default:
                break;
        }
        return true;
    };

    isSnackBarEnabled = () => {
        const { visibleComponent } = this.state;
        const { type, isKeyboardOpen } = this.props;

        return (
            (this.isShowingPlayerUI(visibleComponent) || this.noVisibleComponent(visibleComponent)) &&
            type !== AssetType.VOD_ASSET &&
            type !== AssetType.TRAILER &&
            type !== AssetType.EPISODE &&
            !isKeyboardOpen
        );
    };

    handleKeyUp = (event) => {
        if (Env.IsTizen) {
            clearInterval(this.keyDownInterval);
        }
        const { keyCode } = event;
        const { visibleComponent } = this.state;
        const { assetType } = this.data;
        const playerUiVisible = this.isShowingPlayerUI(visibleComponent);

        if (playerUiVisible) return false;
        if (assetType !== AssetType.VOD_ASSET && assetType !== AssetType.TRAILER && assetType !== AssetType.EPISODE) {
            switch (keyCode) {
                case Key.VK_REWIND:
                case Key.VK_FAST_FWD:
                    return this.handleFastForwardAndRewind(keyCode, playerUiVisible);
                default:
                    return false;
            }
        }

        switch (keyCode) {
            case Key.VK_REWIND:
            case Key.VK_FAST_FWD:
                return this.handleFastForwardAndRewind(keyCode, playerUiVisible);
            case Key.VK_LEFT:
            case Key.VK_RIGHT: {
                return this.handleFastForwardAndRewind(keyCode, playerUiVisible);
            }
            default:
                break;
        }
        return true;
    };

    onKey = (keyCode) => {
        const { visibleComponent } = this.state;
        // when no componentVisible
        // on BACK go back to detail page
        // on LEFT/RIGHT mini epg
        if (this.noVisibleComponent(visibleComponent)) {
            switch (keyCode) {
                case Key.VK_CURSOR_ON:
                    this.showPlayerUI(false);
                    break;
                case Key.VK_CURSOR_OFF:
                    this.hidePlayerUI();
                    break;
                case Key.VK_BACK:
                case Key.VK_STOP:
                    this.saveBookmark();
                    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
                    Player.stop();
                    this.props.playerBack(this.props.type);
                    break;
                case Key.VK_LEFT:
                case Key.VK_REWIND:
                case Key.VK_RIGHT:
                case Key.VK_FAST_FWD:
                    break;
                case Key.VK_PLAY_PAUSE:
                    Player.playPause();
                    this.toggleNotificationButton(true);
                    return true;
                case Key.VK_PAUSE:
                    if (Player.isPlaying()) {
                        Player.pause();
                        this.toggleNotificationButton(true);
                    }
                    return true;
                case Key.VK_PLAY:
                    if (!Player.isPlaying()) {
                        Player.play();
                    }
                    return true;
                default:
                    this.showPlayerUI();
                    break;
            }
            return true;
        }
        return false;
    };

    toggleShowNotificationIcon = (showIcon) => {
        this.setState({ notificationIconToShow: showIcon });
    };

    getNotificationIconsOnHiddenPlayer = () => {
        const { notificationIconToShow, visibleComponent } = this.state;
        if (!notificationIconToShow) return null;
        const iconClassNames =
            notificationIconToShow === PlaybackEvents.PLAY ? 'icon-play icon-play-button' : 'icon-pause icon-pause-button';

        return (
            this.noVisibleComponent(visibleComponent) && (
                <div className="player-notification-icons-container">
                    <div className="icon-circle icon-circle-button" />
                    <div className={iconClassNames}></div>
                </div>
            )
        );
    };

    handlePlayerStateChange = (stateChanged) => {
        const {
            detail: { currentState, previousState },
        } = stateChanged;
        switch (currentState) {
            case clpp.Player.State.PLAYING:
                if (previousState !== clpp.Player.State.PAUSED) {
                    this.toggleShowNotificationIcon(null);
                    return;
                }
                this.toggleShowNotificationIcon(PlayerNotificationIcon.PLAY);
                break;
            case clpp.Player.State.PAUSED:
                this.toggleShowNotificationIcon(PlayerNotificationIcon.PAUSE);
                break;
            default:
                this.toggleShowNotificationIcon(null);
                break;
        }
    };

    showPlayerUI = (hideWithDelay = true) => {
        this.setState({ visibleComponent: PlayerModule.VisibleComponent.PLAYER_UI });
        if (hideWithDelay) {
            this.resetPlayerUITimer();
        } else {
            this.isFreezedUI = true;
        }
    };

    hidePlayerUI = (hideWithDelay = false) => {
        if (hideWithDelay) {
            this.resetPlayerUITimer();
        } else {
            this.focusContainer();
            this.setState({ visibleComponent: null });
        }
        this.isFreezedUI = false;
    };

    hideSubtitleMenuOverlay = () => {
        this.setState({ visibleComponent: PlayerModule.VisibleComponent.PLAYER_UI });
        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: PlayerModule.VisibleComponent.PLAYER_UI,
        });
    };

    showSubtitlesMenu = () => {
        this.props.showSubtitleMenu(this.hideSubtitleMenuOverlay);
    };

    showBingeWatchDialog = () => {
        this.setState({ visibleComponent: PlayerModule.VisibleComponent.BINGE_WATCH_DIALOG });
        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: PlayerModule.VisibleComponent.BINGE_WATCH_DIALOG,
        });
    };

    toggleNotificationButton = (showNotification) => {
        this.setState({ showNotificationButton: showNotification });
    };

    getClock = () => {
        const { showLiveClock } = this.props;
        const { visibleComponent } = this.state;
        if (visibleComponent !== PlayerModule.VisibleComponent.MINI_EPG && showLiveClock) return <UI.Clock className="live-clock" />;
        return null;
    };

    getIcons = () => (
        <div className="playVod-icons">
            {this.getClock()}
            <Icons
                status={this.data.status}
                isRecording={isRecordingAsset(this.data) && this.props.showCatchupIcon}
                isCatchup={isCatchupAsset(this.data) && this.props.showCatchupIcon}
            />
        </div>
    );

    handleSeek = (position) => {
        const shouldSwitchToNextCatchup = this.getShouldSwitchToNextCatchup(position);
        if (shouldSwitchToNextCatchup) this.playEvent(this.nextAsset);
        return shouldSwitchToNextCatchup;
    };

    getPlayerUI = () => {
        const { visibleComponent, showNotificationButton, showReplayButton } = this.state;
        const { type: assetKind } = this.props;
        const isSnackBarEnabled = this.isSnackBarEnabled();

        if (this.data) {
            return (
                <PlayerUI
                    data={this.data}
                    onSeek={this.handleSeek}
                    onEnded={this.onEnded}
                    saveBookmark={this.saveBookmark}
                    showUI={this.showPlayerUI}
                    hideUI={this.hidePlayerUI}
                    isLinearPlay={false}
                    snackBarEnabled={isSnackBarEnabled}
                    showSubtitlesMenu={this.showSubtitlesMenu}
                    resetTimer={this.resetPlayerUITimer}
                    className={this.isShowingPlayerUI(visibleComponent) ? '' : 'hidden'}
                    toggleNotificationButton={this.toggleNotificationButton}
                    showNotificationButton={showNotificationButton}
                    showReplayButton={showReplayButton}
                    isTrailer={assetKind === PlayerType.TRAILER}
                    onPlayFromStart={this.handlePlayFromStart}
                    onTimeUpdate={this.preSeekTimeUpdateHandler}
                    sessionType={PinSessionType.PIN}
                    isNextAssetAvailable={!!this.nextAsset}
                    playNextEvent={() => this.playEvent(this.nextAsset)}
                    playPrevEvent={() => this.playEvent(this.prevAsset)}
                    nextEventId={this.nextAsset?.id}
                    prevEventId={this.prevAsset?.id}
                    updatePlayerData={this.setCurrentAsset}
                />
            );
        }
        return null;
    };

    hideOverlay = (showPlayerUI = false) => {
        if (showPlayerUI) {
            this.showPlayerUI();
        } else {
            this.focusContainer();
            this.setState({ visibleComponent: null });
        }

        State.set(NavKey.PAGE_WITH_PLAYER, {
            visibleComponent: null,
        });
    };

    getVisibleComponent = (visibleComponent) => {
        switch (visibleComponent) {
            case PlayerModule.VisibleComponent.MINI_EPG:
                return <MiniEPG isVodOrCatchup={true} onClose={this.hideOverlay} />;
            case PlayerModule.VisibleComponent.BINGE_WATCH_DIALOG:
                return (
                    <BingeWatchDialog
                        nextAsset={this.nextAsset}
                        timeLeft={Player.getPositionToEnd()}
                        onEnterWatchNext={() => {
                            this.playNextEpisode();
                        }}
                        onEnterCancel={() => {
                            this.setState({
                                showBingeWatchDialog: false,
                                isBingeWatchingCancelled: true,
                            });
                            this.hideOverlay();
                        }}
                        onClose={() => {
                            this.setState({
                                shouldShowBingeWatchDialog: false,
                                showBingeWatchDialog: false,
                            });
                            this.hideOverlay();
                        }}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <Container
                className="page"
                onKey={this.onKey}
                ref={(ref) => {
                    if (ref) {
                        this.containerRef = ref.nodeRef || null;
                    }
                }}
            >
                {this.state.backgroundImage && (
                    <Image className="event-background" orientation={Orientation.LANDSCAPE} src={this.state.backgroundImage} />
                )}
                {this.state.hasInternetConnection && <PlayerError />}
                {this.getIcons()}
                {this.getNotificationIconsOnHiddenPlayer()}
                {this.getPlayerUI()}
                {this.getVisibleComponent(this.state.visibleComponent)}
                <StandByService />
            </Container>
        );
    }
}

export default connect(
    (state) => ({
        showCatchupIcon: selectShowCatchupIcon(state),
        showLiveClock: Profile.selectors.selectShowLiveClock(state),
        hideControlsAfter: selectHideControlsAfter(state),
        bookmarkStartTimeout: selectBookmarkStartTimeout(state),
        liveCatchupThreshold: selectLiveCatchupThreshold(state),
        startOverMinPosition: selectStartOverMinPosition(state),
        vodAssetThumbnailHeight: selectThumbnailDefaultHeight(state),
        vodAssetBackgroundHeight: selectBackgroundWidth(state),
        vodAssetBackgroundWidth: selectBackgroundLandscapeHeight(state),
        bingeWatchingThreshold: selectBingeWatchingThreshold(state),
        isBingeWatchingEnabled: selectIsBingeWatchingEnabled(state),
        isKeyboardOpen: Keyboard.selectors.selectIsActive(state),
    }),
    (dispatch) => ({
        loadEventsForChannel: (id, start, end) => dispatch(loadEventsForChannel(id, start, end)),
        pinActiveStatusChanged: (isActive) => dispatch(pinActiveStatusChanged(isActive)),
        pinLiveStatusChanged: (isActive) => dispatch(pinActiveStatusLiveTvChanged(isActive)),
        completeIfVodUpsellTransaction: () => dispatch(VodUpsell.actions.completeTransaction),
        notificationShow: (title, timer) => dispatch(textNotificationShow(title, timer)),
        eventNotAvailableNotificationShow: (id) => dispatch(eventNotAvailableNotificationShow(id)),
        saveBookmark: (payload) => dispatch(Bookmarks.actions.save(payload)),
        channelLiveIdChange: (id) => dispatch(channelLiveIdChange(id)),
        playerBack: (type) => dispatch(EnhancedBack.onPlayerBack(type)),
        channelNotInActiveChannelListNotificationShow: (text, icon, channelTitle) =>
            dispatch(channelNotInActiveChannelListNotificationShow(text, icon, channelTitle)),
        showSubtitleMenu: (onClose) =>
            dispatch(
                Overlay.actions.mount(
                    Overlay.Type.SubtitleMenu,
                    {
                        onClose,
                        playerOptions: Player.getPlayerOptions(),
                        currentSubtitle: Player.getCurrentSubtitle(),
                        currentAudioTrack: Player.getCurrentAudioTrack(),
                        fontSize: Player.getFontSize(),
                        playerSetFontSize: Player.setFontSize,
                        fontColor: Player.getFontColor(),
                        playerSetFontColor: Player.setFontColor,
                        backgroundColor: Player.getBackgroundColor(),
                        playerSetBackgroundColor: Player.setBackgroundColor,
                        edgeColor: Player.getEdgeColor(),
                        edgeType: Player.getEdgeType(),
                        playerSetEdgeType: Player.setEdgeType,
                        playerSetSubtitleStream: Player.setSubtitleStream,
                        playerSetAudioStream: Player.setAudioStream,
                    },
                    Constants.Ctx.subtitles
                )
            ),
    })
)(PlayVod);
