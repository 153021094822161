import React, { useState, useCallback } from 'react';

import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';

import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';

const BannerCard = React.forwardRef((props, ref) => {
    const { data, onFocus = Function.noop, onEnter, onKey, id, dataTestId } = props;
    const {
        rawData: { thumbnailAlias, parentalRating },
    } = data;

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = useCallback(() => {
        onFocus();
        setIsFocused(true);
    }, [onFocus]);

    const handleBlur = useCallback(() => setIsFocused(false), []);

    return (
        <Focusable
            key={id}
            className={'banner-card-v3 banner-card-v3--with-margin'}
            onEnter={onEnter}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKey={onKey}
            ref={ref}
            focusDelay={false}
            id={id}
            dataTestId={dataTestId}
        >
            <UI.Card.Banner
                key={id}
                onClick={onEnter}
                sessionType={PinSessionType.PIN}
                parentalRating={parentalRating?.id}
                isFocused={isFocused}
                disableTransition
            >
                <UI.Card.Banner.Thumbnail src={thumbnailAlias?.url} />
            </UI.Card.Banner>
        </Focusable>
    );
});

BannerCard.displayName = 'BannerCard';

export default BannerCard;
