import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import {
    RecordingRemoveDocument,
    RecordingRemoveMutationResult,
    RecordingRemoveMutationVariables,
} from './mutation/recordingRemove.generated';
import {
    RecordingLoadManyDocument,
    RecordingLoadManyFragment,
    RecordingLoadManyQueryResult,
    RecordingLoadManyQueryVariables,
} from './query/recordingLoadMany.generated';
import { RecordingQuotaLoadDocument, RecordingQuotaLoadQueryResult } from './query/recordingQuota.generated';

export type LoadManyResponse = {
    recordings: GQL.Recording[];
};

/**
 * @memberof API.Recording
 * @description Function that parses GQL Response to LoadManyResponse
 * @author SmartTVBG@a1.bg
 * @date 21/03/2023
 * @param [response] response from gql
 * @returns object with the parsed available recordings from BE.
 */
export const __parseLoadMany = (response?: RecordingLoadManyFragment): LoadManyResponse => {
    const recordings = (response?.recordings?.edges.map((edge) => edge?.node) ?? []) as GQL.Recording[];
    return { recordings };
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 21/03/2023
 * @param recordingVariables options to be used with the request
 * @returns the parsed raw data from be for available recordings.
 */
export const loadMany = async (recordingVariables: RecordingLoadManyQueryVariables): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: RecordingLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(
        RecordingLoadManyDocument,
        recordingVariables,
        options
    );
    Error.collect(response);

    return __parseLoadMany(response.data);
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 26/03/2023
 * @param deleteRecordingVariables object with the id to delete
 * @returns response from BE after deleting a recording.
 */
export const deleteRecording = async (
    deleteRecordingVariables: RecordingRemoveMutationVariables
): Promise<RecordingRemoveMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(RecordingRemoveDocument, deleteRecordingVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 10/05/2023
 * @returns after be response returns the current recording quota for the user.
 */
export const recordingQuotaRequest = async (): Promise<RecordingQuotaLoadQueryResult> => {
    const options = { fetchPolicy: 'no-cache' };
    const response = await GraphqlClient.makeGraphqlRequest(RecordingQuotaLoadDocument, {}, options);
    Error.collect(response);

    return response;
};
