import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';

import { Selector } from 'App/Packages/UI/Selector';

export type ClockProps = { className?: string; theme?: Theme.Type };

export type ClockComponent = React.FunctionComponent<ClockProps>;

export type ClockModules = {};

export type ClockModule = ClockComponent & ClockModules;

export const Clock: React.FunctionComponent<ClockProps> = (props) => (
    <Text
        className={props.className}
        typeface={Typeface.SANS}
        size={FontSize.TITLE_3}
        weight={FontWeight.REGULAR}
        color={Color.PRIMARY}
        theme={props.theme}
    >
        <Selector selector={Time.selectors.selectString} />
    </Text>
);
