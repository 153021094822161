import PortraitCard from './PortraitCard';
import { AssetType } from '../utils/Constants';

export default class VODFolder extends PortraitCard {
    private _assetType: AssetType = AssetType.VOD_FOLDER;

    constructor(data: any, factoryFunc: any) {
        super(data, factoryFunc);
        this.rawData = data;
    }

    get adult() {
        return this.rawData?.parentalRating.adult;
    }

    get assetType(): AssetType {
        return this._assetType;
    }
}
