import React from 'react';

import { selectIsSubtitlesEditable } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Overlay } from 'App/Modules/Overlay';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Focus } from 'App/Packages/Focus';
import { Audios } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/Audios';
import { Subtitles } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/Subtitles';
import { SubtitleFontSizes } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/SubtitleFontSizes';
import { SubtitleFontStyles } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Components/SubtitleFontStyles';
import translate from 'language/translate';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.SubtitleMenu>> = (props) => {
    const {
        channelId,
        onClose,
        playerOptions,
        currentSubtitle,
        currentAudioTrack,
        fontSize,
        previewTextFontSize,
        fontColor,
        previewTextFontColor,
        backgroundColor,
        previewTextBackgroundColor,
        edgeColor,
        edgeType,
        playerSetFontSize,
        playerSetFontColor,
        playerSetBackgroundColor,
        playerSetEdgeType,
    } = props;
    const dispatch = useDispatch();
    const isSubtitlesEditable = useSelector(selectIsSubtitlesEditable);

    Focus.useBack(() => {
        onClose();
        dispatch(Overlay.actions.unmount());
    });

    return (
        <div className="subtitle-menu-container">
            <div
                style={{
                    color: previewTextFontColor || fontColor,
                    backgroundColor: previewTextBackgroundColor || backgroundColor,
                    fontSize: previewTextFontSize || fontSize,
                    textShadow: `4px 4px 2px ${edgeType !== 'none' ? edgeColor : 'transparent'}`,
                }}
                className="sample-text"
            >
                {translate('SUB_SAMPLE_TEXT')}
            </div>
            <div className="subtitle-menu-wrapper">
                {!isSubtitlesEditable && <div className="disabled" />}
                <Audios channelId={channelId} playerOptions={playerOptions} currentAudioTrack={currentAudioTrack} />
                <Subtitles channelId={channelId} playerOptions={playerOptions} currentSubtitle={currentSubtitle} fontSize={fontSize} />
                <SubtitleFontSizes
                    fontSize={fontSize}
                    previewTextFontSize={previewTextFontSize}
                    fontColor={fontColor}
                    backgroundColor={backgroundColor}
                    playerSetFontSize={playerSetFontSize}
                />
                <SubtitleFontStyles
                    fontColor={fontColor}
                    previewTextFontColor={previewTextFontColor}
                    previewTextBackgroundColor={previewTextBackgroundColor}
                    backgroundColor={backgroundColor}
                    fontSize={fontSize}
                    playerSetFontColor={playerSetFontColor}
                    playerSetBackgroundColor={playerSetBackgroundColor}
                    playerSetEdgeType={playerSetEdgeType}
                />
            </div>
        </div>
    );
};
