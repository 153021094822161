import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodProductCollectionItem_Banner_Fragment = { __typename?: 'Banner' };

export type VodProductCollectionItem_Channel_Fragment = { __typename?: 'Channel' };

export type VodProductCollectionItem_ChannelProduct_Fragment = { __typename?: 'ChannelProduct' };

export type VodProductCollectionItem_Event_Fragment = { __typename?: 'Event' };

export type VodProductCollectionItem_NetworkRecording_Fragment = { __typename?: 'NetworkRecording' };

export type VodProductCollectionItem_ProductBundle_Fragment = { __typename?: 'ProductBundle' };

export type VodProductCollectionItem_Series_Fragment = { __typename?: 'Series' };

export type VodProductCollectionItem_VodAsset_Fragment = { __typename?: 'VODAsset' };

export type VodProductCollectionItem_VodFolder_Fragment = { __typename?: 'VODFolder' };

export type VodProductCollectionItem_VodProduct_Fragment = { __typename: 'VODProduct', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, vodProductParentalRating?: { __typename?: 'ParentalRating', id: string } | null };

export type VodProductCollectionItem_VodSeries_Fragment = { __typename?: 'VODSeries' };

export type VodProductCollectionItemFragment = VodProductCollectionItem_Banner_Fragment | VodProductCollectionItem_Channel_Fragment | VodProductCollectionItem_ChannelProduct_Fragment | VodProductCollectionItem_Event_Fragment | VodProductCollectionItem_NetworkRecording_Fragment | VodProductCollectionItem_ProductBundle_Fragment | VodProductCollectionItem_Series_Fragment | VodProductCollectionItem_VodAsset_Fragment | VodProductCollectionItem_VodFolder_Fragment | VodProductCollectionItem_VodProduct_Fragment | VodProductCollectionItem_VodSeries_Fragment;

export const VodProductCollectionItemFragmentDoc = gql`
    fragment vodProductCollectionItem on ContentItem {
  ... on VODProduct {
    id
    title
    thumbnail(height: $thumbnailHeight) {
      ...image
    }
    vodProductParentalRating: parentalRating {
      ...parentalRatingRelation
    }
    __typename
  }
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;