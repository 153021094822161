import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import {
    changeScreenOptions,
    detailsForOptionId,
    pinProtectedOptions,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/constants';
import { SettingsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsScreenCtx';
import { SettingsScreenOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

const updated =
    (itemIndex: number, firstVisibleIndex: number): Thunk<Promise<void>> =>
    async (dispatch) => {
        await dispatch(
            Screen.actions.update(Screen.Type.SETTINGS, {
                lastHoveredIndex: itemIndex,
                lastFirstVisibleIndex: firstVisibleIndex,
            })
        );
        dispatch(History.actions.push());
    };

const entered =
    (id: SettingsScreenOptionId, itemIndex: number, firstVisibleIndex: number): Thunk<void> =>
    async (dispatch) => {
        const actionToExecute = detailsForOptionId[id].action;

        if (actionToExecute === null) return;

        if (pinProtectedOptions.includes(id)) {
            await dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));

            openPinOverlay(
                async () => {
                    await dispatch(updated(itemIndex, firstVisibleIndex));
                    await dispatch(Overlay.actions.unmount());
                    dispatch(actionToExecute());
                },
                () => dispatch(Overlay.actions.unmount()),
                PinAction.ENTER,
                PinSessionType.PIN,
                true
            );

            return;
        }

        if (changeScreenOptions.includes(id)) {
            await dispatch(updated(itemIndex, firstVisibleIndex));
        }

        dispatch(actionToExecute());
    };

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === SettingsCtx.Back && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.SETTINGS, SettingsScreenCtx.Options));
    }

    if (ctx === SettingsScreenCtx.Options && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.SETTINGS, SettingsCtx.Back));
    }

    return undefined;
};

const back = (): Thunk<void> => (dispatch) => {
    dispatch(History.actions.pop());
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    await dispatch(Overlay.actions.unmount());
    dispatch(Screen.actions.ctx.changed(Screen.Type.SETTINGS, SettingsScreenCtx.Options));
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const settingsScreenActions = {
    private: {
        back,
        entered,
    },
    lifecycle: {
        mount,
        unmount,
        outOfBounds,
    },
};
