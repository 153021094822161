/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-statements */
import { ContentItemType, ProductKind } from '__SMART_APP_OLD__/api/graphql/types';
import { playVod } from '__SMART_APP_OLD__/api/Tuner';
import { API } from '__SMART_APP_OLD__/app/api';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectBackgroundLandscapeHeight,
    selectBackgroundWidth,
    selectThumbnailDefaultHeight,
} from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { vodUpsellSelectors } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/selectors';
import {
    FinishVodUpsellTransactionAction,
    ProductOption,
    StartVodUpsellTransactionAction,
    VodUpsellCTX,
    VodUpsellOptionSelectedAction,
    VodUpsellResetStateAction,
    VodUpsellSetCurrentAssetAction,
    VodUpsellSetOperationTypeAction,
    VodUpsellSetProductsAction,
    VodUpsellSetTemporaryEntitlementAction,
    VodUpsellSetVodAssetAction,
} from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import { PinAction } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { delay } from '__SMART_APP_OLD__/utils/Utils';
import { PaymentTypes, Product, ProductTypes } from 'analytics/logging/classes/Product';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getVodUpsellEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Alert } from 'App/Modules/Alert';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';

/**
 * @memberof VodUpsell
 * @description action to set current upsell products
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param products available upsell product items for vod asset
 * @returns thunk action object with configured info
 */
const setProducts = (products: ProductOption[]): VodUpsellSetProductsAction => ({
    type: ActionType.VOD_UPSELL_SET_PRODUCTS,
    payload: { products },
});

/**
 * @memberof VodUpsell
 * @description action to set current asset id of interest
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param assetId current vod asset id
 * @returns thunk action object with configured info
 */
const setVodAsset = (assetId: GQL.ProductID): VodUpsellSetVodAssetAction => ({
    type: ActionType.VOD_UPSELL_SET_ASSET_ID_AND_TYPE,
    payload: { assetId },
});

/**
 * @memberof VodUpsell
 * @param dispatch generic redux function
 * @description action to set upsell overlay up
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 */
const overlayRaised: Thunk<void> = (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.VOD_UPSELL, {}));
};

/**
 * @memberof VodUpsell
 * @description action to set current upsell operation type
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param productKind current upsell operation type chosen
 * @returns thunk action object with configured info
 */
const setUpsellOperation = (productKind: ProductKind): VodUpsellSetOperationTypeAction => ({
    type: ActionType.VOD_UPSELL_SET_OPERATION_TYPE,
    payload: { productKind },
});

/**
 * @memberof VodUpsell
 * @description action used in overlay select to set current upsell item option selected
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param entitlementId current upsell operation type chosen
 * @returns thunk action object with configured info
 */
const vodUpsellPackageOptionSelectedAction = (entitlementId: string): VodUpsellOptionSelectedAction => ({
    type: ActionType.VOD_UPSELL_UPSELL_OPTION_SELECTED,
    payload: { entitlementId },
});

/**
 * @memberof VodUpsell
 * @description action setting purchase flag to true to indicate start of upsell action
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param isPurchaseOn boolean flag for operation
 * @returns  thunk action object with configured info
 */
const startVodUpsellTransactionAction = (isPurchaseOn: boolean): StartVodUpsellTransactionAction => ({
    type: ActionType.VOD_UPSELL_START_UPSELL_TRANSACTION,
    payload: { isPurchaseOn },
});

/**
 * @memberof VodUpsell
 * @description action setting purchase flag to false to indicate end of upsell action
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param isPurchaseOn boolean flag for operation
 * @returns  thunk action object with configured info
 */
const stopVodUpsellTransactionAction = (isPurchaseOn: boolean): FinishVodUpsellTransactionAction => ({
    type: ActionType.VOD_UPSELL_FINISH_UPSELL_TRANSACTION,
    payload: { isPurchaseOn },
});

/**
 * @memberof VodUpsell
 * @description action setting temporary entitlement
 * returned from StartVODTransaction for TVOD or package TVOD
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param entitlement current temp entitlement for vod asset
 * @returns thunk action object with configured info
 */
const setTemporaryVodEntitlementAction = (entitlement: GQL.VODAssetEntitlement): VodUpsellSetTemporaryEntitlementAction => ({
    type: ActionType.VOD_UPSELL_SET_TEMPORARY_ENTITLEMENT,
    payload: { entitlement },
});

/**
 * @memberof VodUpsell
 * @description action setting object with metadata for current vod asset
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param asset object with metadata for current vod asset target of upsell action
 * @returns thunk action object with configured info
 */
const setCurrentAsset = (asset: object): VodUpsellSetCurrentAssetAction => ({
    type: ActionType.VOD_UPSELL_SET_CURRENT_ASSET,
    payload: { asset },
});

/**
 * @param dispatch generic redux function
 * @param getState generic redux function
 * @memberof VodUpsell
 * @description action canceling TVOD transaction
 * started with StartVODTransaction and the whole upsell flow on backend error
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 */
const cancelTransactionOnBackendError: Thunk<void> = async (dispatch, getState) => {
    const state = getState();
    const isPurchaseOn = vodUpsellSelectors.public.selectIsPurchaseInProgress(state);
    if (!isPurchaseOn) return;
    const isSingleProduct = vodUpsellSelectors.public.selectIsSingleVodTransaction(state);
    const entitlement = vodUpsellSelectors.public.selectCurrentTemporaryEntitlement(state);

    const vodAssetEntitlementId = entitlement ? entitlement.id : '';
    if (vodAssetEntitlementId && isSingleProduct) {
        const result = await API.Vod.cancelVodTransaction({ input: { vodAssetEntitlementId } });
        const text = result.data?.cancelVODTransaction.success
            ? 'VOD_UPSELL_SUCCESSFULLY_CANCELED_TRANSACTION'
            : 'VOD_UPSELL_FAILED_TO_CANCEL_TRANSACTION';
        dispatch(textNotificationShow(text));
    }

    dispatch(stopVodUpsellTransactionAction(false));
    CustomHistory.back();
};

/**
 * @param dispatch generic redux function
 * @param getState generic redux function
 * @memberof VodUpsell
 * @description action starting backend interactions
 * for upsell based on asset and upsell product chosen
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 */
const vodUpsellTransactionStartedAction: Thunk<void> = async (dispatch, getState) => {
    const state = getState();
    const product = vodUpsellSelectors.public.selectOptionSelected(state);
    const asset = vodUpsellSelectors.public.selectCurrentVodAsset(state);
    let isPlayVodStarting = false;

    if (!product || !asset) {
        dispatch(Overlay.actions.unmount());
        dispatch(textNotificationShow('VOD_UPSELL_NO_ITEM_SELECTED'));
        CustomHistory.back();
        return;
    }
    dispatch(startVodUpsellTransactionAction(true));
    const vodAssetId = vodUpsellSelectors.public.selectCurrentAssetIdSelected(state);
    const isSingleItem = vodUpsellSelectors.public.selectIsSingleVodTransaction(state);
    const optionKind = vodUpsellSelectors.public.selectOptionTypeSelected(state);
    try {
        if (isSingleItem) {
            const result = await API.Vod.startVodTransaction({
                input: { vodAssetId, vodProductId: product.id, priceId: product.price?.id! },
            });
            const entitlement = result.data?.startVODTransaction.entitlement;
            if (!entitlement) throw new Error('Failed clearing up');
            dispatch(setTemporaryVodEntitlementAction(entitlement));
            // @ts-ignore
            asset.temporaryEntitlement = entitlement;
            await delay(1000);

            isPlayVodStarting = true;
            playVod(asset, {
                isStartOver: false,
                bookmarkPosition: undefined,
                isTrailer: false,
            });
        }
        if (optionKind === ProductKind.SubscriptionUpsellable) {
            // purchase upsell product
            const result = await API.Vod.purchaseUpsellProduct({ input: { priceId: product.price?.id!, productId: product.id } });
            const failed = result.data === null || result.data === undefined;
            if (failed) throw new Error('Failed clearing up');
            const thumbnailHeight = selectThumbnailDefaultHeight(state);
            const backgroundHeight = selectBackgroundWidth(state);
            const backgroundWidth = selectBackgroundLandscapeHeight(state);
            const assetReload = await API.Vod.vodAsset({ vodAssetId, thumbnailHeight, backgroundHeight, backgroundWidth });
            const currentAssetPackage = new VODAsset(assetReload.data?.vodAsset, null);
            const assetRight = assetReload.data?.vodAsset?.entitlements.items.find((item) => item?.playback);
            currentAssetPackage.temporaryEntitlement = assetRight!;
            await delay(1000);

            isPlayVodStarting = true;
            playVod(currentAssetPackage, {
                isStartOver: false,
                bookmarkPosition: undefined,
                isTrailer: false,
            });
        }
        if (!isSingleItem && optionKind !== ProductKind.SubscriptionUpsellable) {
            // purchase vod product
            const result = await API.Vod.purchaseVodProduct({ input: { vodProductId: product.id, priceId: product.price?.id! } });
            const failed = result.data === null || result.data === undefined;
            if (failed) throw new Error('Failed clearing up');
            const thumbnailHeight = selectThumbnailDefaultHeight(state);
            const backgroundHeight = selectBackgroundWidth(state);
            const backgroundWidth = selectBackgroundLandscapeHeight(state);
            const assetReload = await API.Vod.vodAsset({ vodAssetId, thumbnailHeight, backgroundHeight, backgroundWidth });
            const currentAssetPackage = new VODAsset(assetReload.data?.vodAsset, null);
            const assetRight = assetReload.data?.vodAsset?.entitlements.items.find((item) => item?.playback);
            currentAssetPackage.temporaryEntitlement = assetRight!;
            await delay(1000);

            isPlayVodStarting = true;
            playVod(currentAssetPackage, {
                isStartOver: false,
                bookmarkPosition: undefined,
                isTrailer: false,
            });
        }

        if (!isPlayVodStarting) {
            const screen = Screen.generator(Screen.Type.Detail, { id: vodAssetId, type: ContentItemType.Vodasset });
            dispatch(History.actions.push({ screen, overlay: null }));
        } else {
            dispatch(History.actions.push());
        }

        dispatch(Screen.actions.unmount());
        dispatch(Overlay.actions.unmount());

        LoggingService.getInstance().logEvent(
            getVodUpsellEvent(
                optionKind === ProductKind.Rental ? UIActionEvents.RENT : UIActionEvents.SUBSCRIBE,
                new Product({
                    productId: product.id,
                    productName: product.title,
                    productType: ProductTypes.VOD,
                    paymentType: PaymentTypes[product.kind as keyof typeof PaymentTypes],
                    price: { currency: product.price!.currency, amount: product.price!.amount },
                })
            )
        );
    } catch (error) {
        dispatch(textNotificationShow('VOD_UPSELL_TRANSACTION_FAILED'));
        dispatch(stopVodUpsellTransactionAction(false));
        dispatch(Overlay.actions.unmount());
    }
};

/**
 * @memberof VodUpsell
 * @description action asking for pin before initiating upsell transaction start
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param id id up the upsell item selected
 * @returns pin overlay to confirm vod purchase with user pin
 */
const vodUpsell =
    (id: string): Thunk<void> =>
    (dispatch) => {
        dispatch(Overlay.actions.unmount());
        dispatch(vodUpsellPackageOptionSelectedAction(id));
        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => {
                dispatch(Overlay.actions.unmount());
                dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
                dispatch(vodUpsellTransactionStartedAction);
            },
            () => dispatch(Overlay.actions.unmount()),
            PinAction.ENTER,
            PinSessionType.PIN,
            true
        );
    };

const completeTransaction: Thunk<void> = async (dispatch, getState) => {
    const state = getState();
    const isPurchaseOn = vodUpsellSelectors.public.selectIsPurchaseInProgress(state);
    if (!isPurchaseOn) return;
    const isSingleProduct = vodUpsellSelectors.public.selectIsSingleVodTransaction(state);
    const temporaryEntitlement = vodUpsellSelectors.public.selectCurrentTemporaryEntitlement(state);
    const vodAssetEntitlementId = temporaryEntitlement ? temporaryEntitlement?.id : '';
    if (isSingleProduct && vodAssetEntitlementId) {
        const result = await API.Vod.completeVodTransaction({ input: { vodAssetEntitlementId } });
        const text = result.data?.completeVODTransaction.asset.entitlements.items.some((item) => item?.playback)
            ? 'VOD_UPSELL_SUCCESSFULLY_COMPLETED_TRANSACTION'
            : 'VOD_UPSELL_FAILED_TO_COMPLETE_TRANSACTION';
        dispatch(textNotificationShow(text));
    }
    dispatch(stopVodUpsellTransactionAction(false));
};

/**
 * @memberof VodUpsell
 * @description action setting object with metadata for current vod asset
 * @author SmartTVBG@a1.bg
 * @date 25/06/2023
 * @returns thunk action object with configured info
 */
const resetState = (): VodUpsellResetStateAction => ({
    type: ActionType.VOD_UPSELL_CLEAN_UP_ON_USER_LEAVE,
    payload: null,
});

/**
 * @memberof VodUpsell
 * @param dispatch generic redux function
 * @param getState
 * @description action to set upsell forbidden overlay up
 * @author SmartTVBG@a1.bg
 * @date 15/07/2023
 */
const notAllowedPromptRaised: Thunk<void> = (dispatch, getState) => {
    const state = getState();
    const title = vodUpsellSelectors.public.selectForbiddenOverlayTitle(state);
    const text = vodUpsellSelectors.public.selectForbiddenOverlayText(state);
    dispatch(Alert.actions.mount(Alert.Type.Basic, { title, text }));
};

const initializeCTX = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const packageOptions = vodUpsellSelectors.public.selectPackageOptions(getState());
    if (packageOptions.length) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.VOD_UPSELL, VodUpsellCTX.LEFT_COLUMN));
        return;
    }
    dispatch(Overlay.actions.ctx.changed(Overlay.Type.VOD_UPSELL, VodUpsellCTX.ONE_COLUMN));
};

const vodUpsellOutOfBounds =
    (event: Focus.NavigationEvent, ctx: string): Thunk<Promise<void>> =>
    async (dispatch) => {
        if (ctx === VodUpsellCTX.LEFT_COLUMN && event.x === 1) {
            dispatch(Overlay.actions.ctx.changed(Overlay.Type.VOD_UPSELL, VodUpsellCTX.RIGHT_COLUMN));
            return;
        }
        if (ctx === VodUpsellCTX.RIGHT_COLUMN && event.x === -1) {
            dispatch(Overlay.actions.ctx.changed(Overlay.Type.VOD_UPSELL, VodUpsellCTX.LEFT_COLUMN));
        }
    };

const vodUpsellLifecycle = {
    mount: initializeCTX,
    outOfBounds: vodUpsellOutOfBounds,
};

export const vodUpsellActions = {
    public: {
        notAllowedPromptRaised,
        resetState,
        vodUpsell,
        cancelTransactionOnBackendError,
        setCurrentAsset,
        setUpsellOperation,
        overlayRaised,
        setVodAsset,
        setProducts,
        completeTransaction,
    },
    lifecycle: vodUpsellLifecycle,
};
