import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { ChannelCardNumberBase } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardNumberBase';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelDefaultNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';

type Props = {};

export type ChannelCardNumberDefaultNumberModule = React.FunctionComponent<Props>;

export const ChannelCardNumberDefaultNumber: ChannelCardNumberDefaultNumberModule = () => {
    const { id } = useContext(ChannelCardContext);
    const number = useSelector(selectChannelDefaultNumber(id));

    return <ChannelCardNumberBase>{number}</ChannelCardNumberBase>;
};
