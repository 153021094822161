import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { NotificationView } from '__SMART_APP_OLD__/app/modules/Notification/NotificationView';
import { selectIsNotificationsActive, selectNotificationStack } from '__SMART_APP_OLD__/app/modules/Notification/selectors';

import { Focus } from 'App/Packages/Focus';

const selectCtx = () => Focus.Default;
const outOfBounds: Focus.OutOfBounds = () => () => {};
const setCtx = () => () => {};

export const NotificationOrchestrator: React.FunctionComponent = () => {
    const notificationStack = useSelector(selectNotificationStack);
    return (
        <Focus.Provider ctx={selectCtx} isActive={selectIsNotificationsActive} outOfBounds={outOfBounds} setCtx={setCtx}>
            {notificationStack.map((id, index) => (
                <NotificationView key={id} id={id} index={index} />
            ))}
        </Focus.Provider>
    );
};
