import { CollectionScreenCardBanner } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenCardBanner';
import { CollectionScreenCardLandscape } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/CollectionScreenCardLandscape';
import React from 'react';

import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';

export const CollectionScreenCard: React.FunctionComponent<CollectionItemProps> = (props) => {
    const entityType = useSelector(Collection.selectors.selectEntityType(props.id));

    if (entityType === ContentItemType.Banner) return <CollectionScreenCardBanner {...props} />;

    return <CollectionScreenCardLandscape {...props} />;
};
