import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelProductFolderItemFragment = { __typename: 'ChannelProduct', id: string };

export const ChannelProductFolderItemFragmentDoc = gql`
    fragment channelProductFolderItem on ChannelProduct {
  id
  __typename
}
    `;