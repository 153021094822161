import React, { useContext } from 'react';

import { ChannelCardContext } from '__SMART_APP_OLD__/app/components/ChannelCard/ChannelCardContext';
import { Div } from '__SMART_APP_OLD__/app/components/Div';

import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';

type Props<T extends string = string> = Omit<Focus.Focusable<T>, 'id' | 'isFocused'>;

export type ChannelCardFocusModule = React.FunctionComponent<React.PropsWithChildren<Props>>;

export const ChannelCardFocus: ChannelCardFocusModule = (props) => {
    const { id, isFocused } = useContext(ChannelCardContext);

    const { onClick, onHover } = Focus.useFocusable({
        id,
        ctx: props.ctx,
        isFocused,
        disabled: props.disabled,
        onEnter: props.onEnter,
        onHover: props.onHover,
        onBlur: props.onBlur,
        onFocus: props.onFocus,
    });

    return (
        <Div
            onClick={onClick}
            onMouseOver={onHover}
            onBlur={props.onBlur?.(id) ?? Function.noop}
            onFocus={props.onFocus?.(id) ?? Function.noop}
        >
            {props.children}
        </Div>
    );
};
