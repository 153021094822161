import { LoadVodAssetDetailQueryVariables } from 'App/Modules/Data/Detail/VodAsset/Api/vodAssetDetailLoad.generated';

export const DefaultVariables: LoadVodAssetDetailQueryVariables = {
    vodAssetId: '',
    profileId: '',
    folderFirst: 20,
    folderAfter: undefined,
    folderListFirst: 5,
    folderListAfter: undefined,
    thumbnailHeight: 280,
    backgroundWidth: 1920,
    backgroundHeight: 1080,
};
