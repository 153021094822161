import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageUnreadCount } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';

export const InboxSolidDark: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => {
    const unreadMessagesCount = useSelector(selectMessageUnreadCount);
    return (
        <svg width={128} height={128} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2742_11764)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M104 40C112.837 40 120 32.8366 120 24.0001C120 15.1635 112.837 8 104 8C95.1635 8 88 15.1635 88 24.0001C88 32.8366 95.1635 40 104 40ZM104 28.0001C106.209 28.0001 108 26.2092 108 24.0001C108 21.7909 106.209 20 104 20C101.791 20 100 21.7909 100 24.0001C100 26.2092 101.791 28.0001 104 28.0001Z"
                    fill="#DA3326"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M108 43.6V80C108 84.4108 104.411 88 99.9884 88L48.282 88.0564L36 100.339V88H28C23.5892 88 20 84.4108 20 80V32C20 27.5892 23.5892 24 28 24H84C84 21.1556 84.5938 18.4498 85.6642 16H28C19.1772 16 12 23.178 12 32V80C12 88.822 19.1772 96 28 96V112H35.6516L51.6056 96.0468L100 96C108.823 96 116 88.822 116 80V40.0015C113.674 41.7484 110.956 43 108 43.6Z"
                    fill="#FAFAFA"
                />
            </g>
            <foreignObject x={16} y={20} width={96} height={88}>
                <Text
                    className="inbox-text"
                    typeface={Typeface.SANS}
                    size={FontSize.TITLE_1}
                    weight={FontWeight.BOLD}
                    color={Color.CUSTOM}
                    customColor="#FAFAFA"
                >
                    {unreadMessagesCount}
                </Text>
            </foreignObject>
            <defs>
                <clipPath id="clip0_2742_11764">
                    <rect width={128} height={128} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
