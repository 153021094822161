import { useEffect } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Calc } from 'App/Packages/Calc';

import { NavigationEventHandler } from 'App/Packages/Focus/Generator/Types';
import { useArrow } from 'App/Packages/Focus/Hooks/useArrow';
import { useOutOfBounds } from 'App/Packages/Focus/Hooks/useOutOfBounds';
import { useWheel } from 'App/Packages/Focus/Hooks/useWheel';
import { UseNavigationHandlerOptions } from './Types';

export const useNavigationHandler = <T>(options: UseNavigationHandlerOptions<T>): NavigationEventHandler => {
    const { ctx, focused, columns, lastIndex, disableOutOfBounds, setFocusedIndex, isOutOfBounds, guard, interceptor } = options;
    const dispatch = useDispatch();
    const outOfBounds = useOutOfBounds();

    const navigationHandler: NavigationEventHandler = (event) => {
        if (typeof interceptor === 'function') interceptor(event, focused);
        if (typeof guard === 'function' && !guard(event, focused)) return;
        if (!disableOutOfBounds && isOutOfBounds(event)) {
            dispatch(outOfBounds(event, ctx));
            return;
        }

        setFocusedIndex((current) => Calc.clamp(0, current + (event.x + event.y * columns), lastIndex));
    };

    useEffect(() => setFocusedIndex((index) => Calc.clamp(0, index, lastIndex)), [lastIndex, setFocusedIndex]);

    return navigationHandler;
};

export const useHandler = (handler: NavigationEventHandler, disabled: boolean): boolean => {
    useWheel(handler, { disabled });
    return useArrow(handler, { disabled });
};
