import { Getter, Setter, Subscribe, Subscriber, UnSubscribe, UnSubscriber } from 'App/Packages/Subscribable/Types';

export class Value<T> {
    private state: T;

    private readonly subscribers: Subscriber[];

    constructor(init: T) {
        this.state = init;
        this.subscribers = [];
    }

    public readonly get: Getter<T> = this.__get.bind(this);

    public readonly set: Setter<T> = this.__set.bind(this);

    public readonly subscribe: Subscribe = this.__subscribe.bind(this);

    public readonly unsubscribe: UnSubscribe = this.__unsubscribe.bind(this);

    private __get(): T {
        return this.state;
    }

    private async __set(next: T): Promise<void> {
        if (this.state === next) return;
        this.state = next;
        await Promise.resolve();
        if (this.state !== next) return;
        this.__notify();
    }

    private __subscribe(callback: Subscriber): UnSubscriber {
        this.subscribers.push(callback);
        return () => this.__unsubscribe(callback);
    }

    private __unsubscribe(callback: Subscriber): void {
        const index = this.subscribers.indexOf(callback);
        if (index !== -1) this.subscribers.splice(index, 1);
    }

    private __notify(): void {
        this.subscribers.forEach((callback) => callback());
    }
}
