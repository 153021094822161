import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import {
    CancelVodTransactionDocument,
    CancelVodTransactionMutationResult,
    CancelVodTransactionMutationVariables,
} from './mutation/cancelVodTransaction.generated';
import {
    CompleteVodTransactionDocument,
    CompleteVodTransactionMutationResult,
    CompleteVodTransactionMutationVariables,
} from './mutation/completeVodTransaction.generated';
import {
    PurchaseUpsellProductDocument,
    PurchaseUpsellProductMutationResult,
    PurchaseUpsellProductMutationVariables,
} from './mutation/purchaseBundle.generated';
import {
    PurchaseVodProductDocument,
    PurchaseVodProductMutationResult,
    PurchaseVodProductMutationVariables,
} from './mutation/purchaseVodProduct.generated';
import {
    StartVodTransactionDocument,
    StartVodTransactionMutationResult,
    StartVodTransactionMutationVariables,
} from './mutation/startVodTransaction.generated';
import { VodAssetDocument, VodAssetQueryResult, VodAssetQueryVariables } from './query/vodAsset.generated';

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 15/04/2023
 * @param {StartVodTransactionMutationVariables} startVodTransactionVariables
 * info for starting single vod transaction
 * @returns  {StartVodTransactionMutationResult}
 */

export const startVodTransaction = async (
    startVodTransactionVariables: StartVodTransactionMutationVariables
): Promise<StartVodTransactionMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(StartVodTransactionDocument, startVodTransactionVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 15/04/2023
 * @param {CompleteVodTransactionMutationVariables} completeVodTransactionVariables
 * info for completing vod transaction
 * @returns  {CompleteVodTransactionMutationResult}
 */

export const completeVodTransaction = async (
    completeVodTransactionVariables: CompleteVodTransactionMutationVariables
): Promise<CompleteVodTransactionMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(CompleteVodTransactionDocument, completeVodTransactionVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 15/04/2023
 * @param {CancelVodTransactionMutationVariables} cancelVodTransactionVariables
 * info for canceling vod transaction
 * @returns  {CancelVodTransactionMutationResult}
 */

export const cancelVodTransaction = async (
    cancelVodTransactionVariables: CancelVodTransactionMutationVariables
): Promise<CancelVodTransactionMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(CancelVodTransactionDocument, cancelVodTransactionVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 15/04/2023
 * @param {PurchaseVodProductMutationVariables} purchaseVodProductVariables
 * info for purchasing vod product
 * @returns  {PurchaseVodProductMutationResult}
 */

export const purchaseVodProduct = async (
    purchaseVodProductVariables: PurchaseVodProductMutationVariables
): Promise<PurchaseVodProductMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(PurchaseVodProductDocument, purchaseVodProductVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 15/04/2023
 * @param {PurchaseUpsellProductMutationVariables} purchaseProductVariables
 * info for purchasing upsell product
 * @returns  {PurchaseUpsellProductMutationResult}
 */

export const purchaseUpsellProduct = async (
    purchaseProductVariables: PurchaseUpsellProductMutationVariables
): Promise<PurchaseUpsellProductMutationResult> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(PurchaseUpsellProductDocument, purchaseProductVariables);
    Error.collect(response);

    return response;
};

/**
 * @memberof API.Recording
 * @description Function that handles graphql request and response
 * @author SmartTVBG@a1.bg
 * @date 16/04/2023
 * @param {VodAssetQueryVariables} vodAssetVariables
 * info needed to get vod asset information
 * @returns  {VodAssetQueryResult}
 */

export const vodAsset = async (vodAssetVariables: VodAssetQueryVariables): Promise<VodAssetQueryResult> => {
    const response = await GraphqlClient.makeGraphqlRequest(VodAssetDocument, vodAssetVariables, { fetchPolicy: 'network-only' });
    Error.collect(response);

    return response;
};
