import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { logoutAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';

const logout = (): Thunk<void> => async (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
    dispatch(logoutAction());
    LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.LOGOUT));
    dispatch(Overlay.actions.unmount());
};

const cancel = (): Thunk<void> => (dispatch) => dispatch(Prompt.actions.unmount());

export const actions = {
    private: {
        logout,
        cancel,
    },
};
