import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import {
    LanguageChangeDocument,
    LanguageChangeMutationResult,
    LanguageChangeMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Language/api/mutation/languageChange.generated';
import {
    LanguageLoadManyDocument,
    LanguageLoadManyFragment,
    LanguageLoadManyQueryResult,
} from '__SMART_APP_OLD__/app/modules/Language/api/query/languageLoadMany.generated';

export type LoadManyResponse = { languages: GQL.Language[] };

export const __parseLoad = (response?: LanguageLoadManyFragment): LoadManyResponse => {
    if (!response) return { languages: [] };
    const languages = (response?.languages?.items ?? []).filter(Boolean) as GQL.Language[];
    return { languages };
};

export const load = async (): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: LanguageLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(LanguageLoadManyDocument, {}, options);
    Error.collect(response);

    return __parseLoad(response.data);
};

export const change = async (languageId: GQL.LanguageID): Promise<void> => {
    const variables: LanguageChangeMutationVariables = { languageChangeInput: { id: languageId } };
    const response: LanguageChangeMutationResult = await GraphqlClient.makeGraphqlMutationRequest(LanguageChangeDocument, variables);
    Error.collect(response);
};
