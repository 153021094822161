import { Registry } from 'App/Modules/Screen/Registry';

import { actions as screenActions } from 'App/Modules/Screen/Store/actions';
import { selectors as screenSelectors } from 'App/Modules/Screen/Store/selectors';

export { generator } from 'App/Modules/Screen/Store/generator';
export { getInitialState } from 'App/Modules/Screen/Store/getInitialState';
export { reducer } from 'App/Modules/Screen/Store/reducer';
export { ScreenActionType as ActionType, PrivacyPolicyScreenType as PrivacyPolicyType, ScreenType as Type } from 'App/Modules/Screen/Types';
export type {
    ScreenChangedAction as ChangedAction,
    ScreenChangedActionPayload as ChangedActionPayload,
    EducationScreenContentItem,
    ScreenEntity as Entity,
    ScreenProps as Props,
    ScreenReduxAction as ReduxAction,
    ScreenState as State,
} from 'App/Modules/Screen/Types';
export type { ScreenLifecycle as Lifecycle, ScreenLifecycleMethod as LifecycleMethod } from 'App/Modules/Screen/Registry/types';
export { View } from 'App/Modules/Screen/View';

export const zIndex = 10;
export const actions = screenActions.public;
export const selectors = screenSelectors.public;
export const { register } = Registry;
