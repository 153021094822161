import { Folder } from 'App/Modules/Folder';
import { Api } from 'App/Modules/VodFolderData/Api';
import { Constants } from 'App/Modules/VodFolderData/Constants';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Error as ErrModule } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = Folder.actions.list.loaded(Constants.Hash);
const reset = Folder.actions.list.reset(Constants.Hash);

const load =
    (folderId: string): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const config = selectConfig(state);
            const profileId = Profile.selectors.selectId(state);

            const variables: Api.LoadVariables = {
                id: folderId,
                profileId,
                thumbnailHeight: config.image.thumbnail.default.height,
                backgroundHeight: config.image.background.landscape,
                backgroundWidth: config.image.background.width,
                folderListFirst: 50,
                folderFirst: 30,
                folderListAfter: null,
                folderAfter: null,
                channelLogoHeight: 41,
                channelLogoWidth: 112,
            };
            const response = await Api.load(variables);
            if (!response) if (!response) throw new Error('Failed to load root folder');
            const data = Folder.Mapper.toFolderList(response);

            if (!data) throw new Error('Failed to load root folder');

            dispatch(loaded(data));
            return true;
        } catch (error) {
            dispatch(ErrModule.actions.occured(ErrModule.Dispatcher.FolderListLoad, error));
            return false;
        }
    };

export const actions = {
    lifecycle: {},
    public: {
        load,
        reset,
    },
    private: {
        loaded,
    },
};
