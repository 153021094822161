import { settingsAboutAppSelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/selectors';
import {
    SETTINGS_ABOUT_APP_TITLE,
    SettingsAboutAppValue,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/types';
import { Platform } from '__SMART_APP_OLD__/app/platform';

import { Env } from 'App/Env';

export const NA = 'SETTINGS_SYSTEM_INFORMATION_INFO_NA';

export const valueForItemTitle: Record<SETTINGS_ABOUT_APP_TITLE, SettingsAboutAppValue> = {
    [SETTINGS_ABOUT_APP_TITLE.APP_VERSION]: () => Env.Version || NA,
    [SETTINGS_ABOUT_APP_TITLE.DEVICE_ID]: () => Platform.ID,
    [SETTINGS_ABOUT_APP_TITLE.OS_VERSION]: () => `${Platform.OS} ${Platform.OS_VERSION}`,
    [SETTINGS_ABOUT_APP_TITLE.CONFIG_ID]: settingsAboutAppSelectors.private.selectConfigId || NA,
    [SETTINGS_ABOUT_APP_TITLE.APP_USER_ACCOUNT]: settingsAboutAppSelectors.private.selectProfileUserName || NA,
    [SETTINGS_ABOUT_APP_TITLE.TV_MODEL]: () => Platform.MODEL || NA,
} as const;
