import { GQL } from '__SMART_APP_OLD__/app/gql';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';
import { RecordingEntity, RecordingEntityTable } from '__SMART_APP_OLD__/app/store/types/state/data/recording';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

const initialState: RecordingEntityTable = { ids: [], entities: {}, eventRecordMap: {} };

const mapGQLRecordingToRecordingEntity = (recording: GQL.Recording): RecordingEntity => ({
    id: recording.id,
    start: new Date(recording.start).getTime(),
    end: new Date(recording.end).getTime(),
    duration: (new Date(recording.end).getTime() - new Date(recording.start).getTime()) / 1000,
    status: recording.status,
    eventId: recording?.event?.id,
    channelId: recording?.channel?.id,
    genre: recording?.event?.metadata?.genre ?? null,
    parentalRating: recording?.event?.parentalRating,
    title: recording.event.title,
    size: recording.size,
    availableUntil: recording.availableUntil ? new Date(recording.availableUntil).getTime() : undefined,
    isSeriesRecording: recording.personalInfo.partOfSeriesRecording,
    isFavoured: recording.personalInfo.favourited,
    leadOut: recording?.channel?.afterTime ?? 0,
    leadIn: recording?.channel?.beforeTime ?? 0,
    recordingImage: recording.event.backgroundImage?.url ?? null,
    episode: recording?.event?.metadata?.episodeInfo?.number ?? null,
    season: recording?.event?.metadata?.episodeInfo?.season ?? null,
    assetType: AssetType.NETWORK_RECORDING,
    // playerInfo
    titlePlayer: recording?.event?.title ?? '',
    isRecording: true,
    episodeTitle: recording?.event?.metadata?.episodeInfo?.title || '',
});

const mapRecordingEntityIdToEventEntityId = (recordings: RecordingEntity[]): Record<GQL.RecordingID, GQL.EventID> =>
    recordings.reduce<Record<GQL.EventID, GQL.RecordingID>>((acc, record) => ({ ...acc, [record.eventId!]: record.id }), {});

const reduceGQLRecordingsToRecordingEnitities = (recordings: GQL.Recording[]): Record<string, RecordingEntity> =>
    recordings.reduce<Record<string, RecordingEntity>>(
        (map, recording) => ({ ...map, [recording.id]: mapGQLRecordingToRecordingEntity(recording) }),
        {}
    );

export const recordingEntityTableReducer: Reducer<RecordingEntityTable> = (state, action) => {
    if (!state) return initialState;
    switch (action.type) {
        case ActionType.RECORDING_LOAD_MANY_SUCCESS: {
            if (!action.payload.recordings.length) return { ids: [], entities: {}, eventRecordMap: {} };
            const ids = action.payload.recordings.map((recording) => recording.id);
            const entities = reduceGQLRecordingsToRecordingEnitities(action.payload.recordings);
            const eventRecordMap = mapRecordingEntityIdToEventEntityId(Object.values(entities));
            return { ids, entities, eventRecordMap };
        }
        case ActionType.RECORDING_REMOVE_RECORDING:
        case ActionType.RECORDING_DELETE_SUCCESS: {
            const copiedArray = Object.entries(state.entities);
            const recordingsMap = Object.fromEntries(
                copiedArray.filter(([, record]) => !action.payload.removedIds.includes(record.eventId as string))
            );
            const copiedEventRecordMap = Object.entries(state.eventRecordMap);
            const eventRecordMap = Object.fromEntries(copiedEventRecordMap.filter(([key]) => !action.payload.removedIds.includes(key)));
            const newIds = Object.keys(recordingsMap);
            return { ...state, ids: newIds, entities: recordingsMap, eventRecordMap };
        }
        case ActionType.RECORDING_ADD_RECORDING: {
            const record = mapGQLRecordingToRecordingEntity(action.payload.recording);
            const entities = { ...state.entities, [record.id]: record };
            const ids = [...state.ids, record.id];
            const eventRecordMap = { ...state.eventRecordMap, [record.eventId!]: record.id };
            return { ...state, entities, eventRecordMap, ids };
        }

        case ActionType.RECORDING_ADD_SERIES_RECORDING: {
            const recordings = reduceGQLRecordingsToRecordingEnitities(action.payload.recordings);
            const entities = { ...state.entities, ...recordings };
            const ids = Object.keys(entities);
            const newConnections = mapRecordingEntityIdToEventEntityId(Object.values(recordings));
            const eventRecordMap = { ...state.eventRecordMap, ...newConnections };
            return { ...state, ids, entities, eventRecordMap };
        }

        default:
            return state;
    }
};
