import { Calc } from 'App/Packages/Calc';

export const Constants = {
    Thumbnail: {
        Width: Calc.pixel(192),
        Height: Calc.pixel(288),
        Large: {
            Width: Calc.pixel(272),
            Height: Calc.pixel(408),
        },
    },
} as const;
