import React, { useContext } from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconName, IconType } from '__SMART_APP_OLD__/app/components/Icon/types';
import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';

export const SettingsSectionContentItemIconEditable: React.FunctionComponent = () => {
    const { isFocused } = useContext(SettingsSectionContentItemContext);
    return (
        <Icon
            className="settings-section-content-item__icon settings-section-content-item__icon--editable"
            name={IconName.EDIT}
            type={IconType.SOLID}
            isFocused={isFocused}
        />
    );
};
