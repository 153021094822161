import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { LoginBackground } from '__SMART_APP_OLD__/app/components/LoginBackground';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Screen } from 'App/Modules/Screen';
import { LoginWithCodeContent } from 'App/Modules/Screen/Modules/LoginWithCode/View/LoginWithCodeContent';
import { LoginWithCodeTitle } from 'App/Modules/Screen/Modules/LoginWithCode/View/LoginWithCodeTitle';
import { Focus } from 'App/Packages/Focus';
import { UI } from 'App/Packages/UI';

export const LoginWithCode: React.FunctionComponent<Screen.Props<Screen.Type.LOGIN_WITH_CODE>> = () => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(Screen.actions.mount(Screen.Type.LOGIN, {})));

    return (
        <Layout className="login-with-code" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <LoginBackground className="login-with-code__background" />
            <div className="login-with-code__container">
                <UI.Logo.XploreTV className="login-with-code__logo" />
                <LoginWithCodeTitle />
                <LoginWithCodeContent onFail={() => dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_EMAIL, {}))} />
            </div>
        </Layout>
    );
};
