import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentFragmentDoc, ParentalRatingFragmentFragmentDoc, TrailersFragmentFragmentDoc, VodAssetEntitlementCatalogFragmentFragmentDoc, VodAssetEntitlementFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc, CacheInfoFragmentFragmentDoc, PageInfoFragmentFragmentDoc, ChannelLogosFragmentFragmentDoc, PersonalChannelInfoFragmentFragmentDoc, ChannelEntitlementsFragmentFragmentDoc, EventEntitlementsFragmentFragmentDoc, PersonalEventInfoFragmentFragmentDoc, MetadataFragmentFragmentDoc, PersonalRecordingInfoFragmentFragmentDoc, GenreFragmentFragmentDoc, VodProductFrogmentFragmentDoc, PersonalVodInfoFragmentFragmentDoc, PriceFragmentFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/api/graphql/query/fragments.generated';
import { ImageFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
export type SeriesDetailItemFragment = { __typename: 'Series', id: string, title: string, subtitle?: string | null, episodeCount: number, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean }, groupingInfos?: Array<{ __typename?: 'GroupingInfo', episodeCount: number, startCursor: string, endCursor: string, title: string, selectBehaviour: Types.GroupInfoSelectBehaviour } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export const SeriesDetailItemFragmentDoc = gql`
    fragment seriesDetailItem on Series {
  id
  title
  subtitle
  backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
    ...imageFragment
  }
  parentalRating {
    ...parentalRatingFragment
  }
  episodeCount
  groupingInfos {
    episodeCount
    startCursor
    endCursor
    title
    selectBehaviour
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  __typename
}
    ${ImageFragmentFragmentDoc}
${ParentalRatingFragmentFragmentDoc}
${ImageFragmentDoc}`;