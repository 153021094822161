import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

export default class Channel {
    rawData: any;

    assetType: string = AssetType.CHANNEL;

    cursor: string;

    _orderNumber: number | null = null;

    _programs: Event[] = [];

    _isFavorite: boolean = false;

    constructor(data: any, factoryFunc: (rawData: any, type: AssetType) => any, cursor: string = '') {
        this.rawData = data;
        this.cursor = cursor;
        if (data.currentEvent?.itemCount) {
            const programs = data.currentEvent?.items?.map((program: Event) => factoryFunc(program, AssetType.EVENT));
            this.programs = programs;
        }
    }

    get id() {
        return this.rawData?.id;
    }

    get isEntitled() {
        return this.rawData?.userInfo?.subscribed;
    }

    get isFavorite() {
        return this._isFavorite;
        // return this.rawData?.OnFavorites || this.checkChannelIsFavorite() || false;
    }

    set isFavorite(isFavorite) {
        this._isFavorite = isFavorite;
    }

    get orderNumber() {
        return this._orderNumber || this.rawData?.defaultNumber;
    }

    set orderNumber(number) {
        this._orderNumber = number;
    }

    get programs() {
        return this._programs;
    }

    set programs(programs) {
        this._programs = programs;
    }

    get title() {
        return this.rawData?.title;
    }

    get entitlements() {
        return this.rawData.entitlements || {};
    }

    get restartTV() {
        return this.entitlements.restartTV;
    }

    get channelSubtitlePreference() {
        return this.rawData?.personalInfo?.subtitleLanguage;
    }

    get channelAudioPreference() {
        return this.rawData?.personalInfo?.audioLanguage;
    }

    get afterTime() {
        return this.rawData?.afterTime || 0;
    }

    get beforeTime() {
        return this.rawData?.beforeTime || 0;
    }

    get ageRatingRank() {
        return this.rawData?.parentalRating?.rank || 6;
    }

    // get isHD() {
    //     return this.rawData?.IsHD || false;
    // }
    //
    // get isLocked() {
    //     // not subscribed/entitled
    //     const entitlement = this.rawData?.Products?.Product[0] || {};
    //     return !(
    //         entitlement.Type &&
    //         entitlement.Type === 'Subscription' &&
    //         entitlement.EntitlementState &&
    //         entitlement.EntitlementState === 'Entitled'
    //     );
    // }

    // get isAdult() {
    //     return this.rawData?.IsAdult || false;
    // }
    //
}
