import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { InnerHTML } from './InnerHTML';

interface Props {
    playerView?: boolean;
    channelId: GQL.ChannelID;
    isHD?: boolean;
    title: string;
    subtitle?: string;
    metaInfo: string;
    imageComponent?: React.ReactNode;
    metaInfoComponent?: React.ReactNode;
    children?: React.ReactNode;
}

export const AssetDetailsBody: React.FunctionComponent<Props> = (props) => {
    const theme = props.playerView ? Theme.Type.Dark : null;
    return (
        <div className={`program-details-container ${props.playerView ? 'player-view dark-theme' : ''}`}>
            <div className="left-gradient" />
            <div className="bottom-gradient" />
            <div className="program-details">
                <div className="program-cover">{props.imageComponent}</div>
                <div className="details-container">
                    <div className="channel-container">
                        <ChannelLogo className="channel-logo" id={props.channelId} theme={theme} />
                        {props.isHD && <div className="icon-hd" />}
                    </div>
                    <Text
                        className="title"
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        isFocused={props.playerView}
                    >
                        {props.title}
                    </Text>
                    {!!props.subtitle && (
                        <Text
                            className="subtitle"
                            typeface={Typeface.SANS}
                            size={FontSize.TITLE_3}
                            weight={FontWeight.REGULAR}
                            color={Color.SECONDARY}
                        >
                            <InnerHTML html={props.subtitle} />
                        </Text>
                    )}
                    {!props.playerView && (
                        <div className="meta-info-line">
                            {props.metaInfoComponent}
                            <Text
                                className="meta-info"
                                typeface={Typeface.SANS}
                                size={FontSize.BODY_2}
                                weight={FontWeight.REGULAR}
                                color={Color.SECONDARY}
                            >
                                <InnerHTML html={props.metaInfo} />
                            </Text>
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </div>
    );
};
