import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { CollectionApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/api';
import {
    CollectionActionType,
    CollectionLoadedAction,
    CollectionLoadedActionPayload,
    CollectionResetAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = (payload: CollectionLoadedActionPayload): CollectionLoadedAction => ({
    type: CollectionActionType.LOADED,
    payload,
});

const reset = (): CollectionResetAction => ({
    type: CollectionActionType.RESET,
});

const load = (id: string): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const config = selectConfig(state);
        const variables: CollectionApi.LoadVariables = {
            id,
            thumbnailHeight: config.image.thumbnail.default.height,
        };
        const response = await CollectionApi.load(variables);
        dispatch(loaded({ response }));
        return true;
    } catch (error) {
        return false;
    }
};

export const collectionActions = {
    public: {
        load,
        reset,
    },
    private: {
        loaded,
    },
};
