/* eslint-disable react-func/max-lines-per-function */
import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { ProfileConsent } from '__SMART_APP_OLD__/api/graphql/types';
import { Channel } from '__SMART_APP_OLD__/app/api/channel';
import { ChannelList } from '__SMART_APP_OLD__/app/api/channelList';
import { InitLoadDocument, InitLoadQueryResult, InitLoadQueryVariables } from '__SMART_APP_OLD__/app/api/init/query/initLoad.generated';
import { InitLoadMeDocument, InitLoadMeFragment, InitLoadMeQueryResult } from '__SMART_APP_OLD__/app/api/init/query/initLoadMe.generated';
import { Message } from '__SMART_APP_OLD__/app/api/message';
import { ParentalRating } from '__SMART_APP_OLD__/app/api/parentalRating';
import { Reminder } from '__SMART_APP_OLD__/app/api/reminder';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { HouseholdOnboardingInfo, ParentalRatingInfo, ProfileInfo, ProfilePreferences, Quota } from '__SMART_APP_OLD__/app/gql/gql';
import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

import { Env } from 'App/Env';

/**
 * @namespace API.Init
 */

export type LoadResponse = {
    parentalRatingLoadManyResponse: ParentalRating.LoadManyResponse;
    reminderLoadManyResponse: Reminder.LoadManyResponse;
    channelListLoadInitialIdResponse: ChannelList.LoadInitialIdResponse;
    channelListLoadManyResponse: ChannelList.LoadManyResponse;
    channelLoadTotalCountResponse: Channel.LoadTotalCountResponse;
    messageLoadTotalCountResponse: Message.LoadTotalCountResponse;
    recordingQuota: Quota;
};

export const load = async (profileId: GQL.ProfileID): Promise<LoadResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const variables: InitLoadQueryVariables = {
        profileId,
    };
    const response: InitLoadQueryResult = await GraphqlClient.makeGraphqlRequest(InitLoadDocument, variables, options);
    Error.collect(response);

    return {
        parentalRatingLoadManyResponse: ParentalRating.__parseLoadMany(response.data),
        reminderLoadManyResponse: Reminder.__parseLoadMany(response.data),
        channelListLoadInitialIdResponse: ChannelList.__parseLoadInitialId(response.data),
        channelListLoadManyResponse: ChannelList.__parseLoadMany(response.data),
        channelLoadTotalCountResponse: Channel.__parseLoadTotalCount(response.data),
        messageLoadTotalCountResponse: Message.__parseLoadTotalCount(response.data),
        recordingQuota: response.data?.recordingQuota!,
    };
};

export type LoadMeResponse = {
    userId: string;
    profileId: string;
    householdId: string;
    householdExternalId: string;
    maxNumberOfUnmanagedDevices: number;
    maxNumberOfConfirmedReplayChannels: number;
    profiles: ProfileInfo[];
    parentalRating: ParentalRatingInfo | undefined;
    parentalRatingRank: number;
    parentalRatingId: string;
    householdOnboardingInfo: HouseholdOnboardingInfo | undefined;
    masterPinCode: string;
    profilePreferences: ProfilePreferences | undefined;
    userName: string;
    dataUsageAllowed: boolean;
    rawResponse: InitLoadMeFragment;
    mqttTopics: string[];
    mqttBrokerUrl: string | null;
    masterPincodeStepCompleted: number;
    communityStepCompleted: number;
    replayStepCompleted: number;
    ageRatingStepCompleted: number;
    privacyStepCompleted: number;
};

// eslint-disable-next-line max-statements,complexity
export const __parseLoadMe = (response?: InitLoadMeFragment): LoadMeResponse => {
    const userId = response?.me?.id ?? '';
    const profileId = response?.me?.device?.activeProfile?.id ?? response?.me?.household?.profiles?.items[0]?.id ?? '';
    const householdId = response?.me?.household?.id ?? '';
    const householdExternalId = response?.me?.household?.externalId ?? '';
    const maxNumberOfConfirmedReplayChannels = response?.me?.household.maxNumberOfConfirmedReplayChannels ?? 0;
    const maxNumberOfUnmanagedDevices = response?.me?.household?.maxNumberOfUnmanagedDevices ?? 0;
    const profile = response?.me?.household.profiles.items.find((pr) => pr?.id === profileId);
    const profilePreferences = profile?.preferences;
    const privacyPolicyConsents = (profile?.preferences?.consents as ProfileConsent[]) ?? [];
    const profiles = (response?.me?.household?.profiles.items! as ProfileInfo[]) ?? [];
    const householdOnboardingInfo = response?.me?.household.onboardingInfo;
    const masterPinCode = response?.me?.household?.masterPincode ?? '';
    const userName = response?.me?.userName ?? '';
    const parentalRating = profile?.permissions.parentalRating;
    const parentalRatingId = parentalRating?.id ?? '';
    const parentalRatingRank = parentalRating?.rank ?? 0;
    // check if uar is Allowed
    const isUserBehaviorAccepted = privacyPolicyConsents?.find((consent) => consent?.type === Consent.Type.USER_BEHAVIOR)?.accepted;
    const profileTrackViewingBehavior = profilePreferences?.trackViewingBehaviour;
    const householdTrackViewingBehavior = response?.me?.household?.trackViewingBehaviour;
    const dataUsageAllowed = isUserBehaviorAccepted || profileTrackViewingBehavior || householdTrackViewingBehavior || Env.IsBulgaria;
    const mqttTopics = (response?.me?.mqttTopics?.items as string[]) ?? [];
    const mqttBrokerUrl = response?.me?.mqttBrokerUrl ?? null;
    const masterPincodeStepCompleted = response?.me?.household?.onboardingInfo?.masterPincodeStepCompleted;
    const communityStepCompleted = response?.me?.household?.onboardingInfo?.communityStepCompleted;
    const replayStepCompleted = response?.me?.household?.onboardingInfo?.replayStepCompleted;
    const ageRatingStepCompleted =
        response?.me?.device?.activeProfile?.onboardingInfo?.ageRatingStepCompleted ??
        response?.me?.household?.profiles?.items[0]?.onboardingInfo?.ageRatingStepCompleted;
    const privacyStepCompleted =
        response?.me?.device?.activeProfile?.onboardingInfo?.privacyStepCompleted ??
        response?.me?.household?.profiles?.items[0]?.onboardingInfo?.privacyStepCompleted;
    return {
        userId,
        profileId,
        householdId,
        parentalRatingId,
        maxNumberOfUnmanagedDevices,
        maxNumberOfConfirmedReplayChannels,
        profiles,
        parentalRating,
        parentalRatingRank,
        householdOnboardingInfo,
        masterPinCode,
        profilePreferences,
        userName,
        dataUsageAllowed,
        rawResponse: response!,
        mqttTopics,
        mqttBrokerUrl,
        householdExternalId,
        masterPincodeStepCompleted,
        communityStepCompleted,
        replayStepCompleted,
        ageRatingStepCompleted,
        privacyStepCompleted,
    };
};

export const loadMe = async () => {
    const options = { fetchPolicy: 'network-only' };
    const response: InitLoadMeQueryResult = await GraphqlClient.makeGraphqlRequest(InitLoadMeDocument, {}, options);
    Error.collect(response);
    return __parseLoadMe(response.data);
};
