import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';

const change = (): Thunk<void> => (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.SELECT, { target: Overlay.SelectTarget.LANGUAGE }));
};

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(Language.actions.load());
    dispatch(Overlay.actions.unmount());
};

const unmount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    private: {
        change,
    },
    lifecycle: {
        mount,
        unmount,
    },
};
