import React, { useContext } from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { ReactComponent as ImageCoverSvg } from 'styles/assets/logos/Xplore_TV_Placeholder_Logo.svg';
import { ImageContext, Status } from 'App/Packages/UI/Image/ImageContext';
import { ImageClassNameProps } from 'App/Packages/UI/Image/Utils';

export type ImageCoverProps = ImageClassNameProps & {
    force?: boolean;
    hidden?: boolean;
};

export type ImageCoverModule = {
    Svg: typeof ImageCoverSvg;
} & React.FunctionComponent<ImageCoverProps>;

const getClassName = (theme: Theme.Type, props: ImageCoverProps) => {
    const classList = [];
    const base = 'image-v3__cover';
    classList.push(base);
    classList.push(Theme.getClassName(base, theme));
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

const ImageCoverComponent: React.FunctionComponent<ImageClassNameProps> = (props) => (
    <ImageCoverSvg className={getClassName(useTheme(), props)} />
);

export const ImageCover: ImageCoverModule = (props) => {
    const { status } = useContext(ImageContext);
    if (props.hidden) return null;
    if (props.force) return <ImageCoverComponent {...props} />;
    if (status === Status.Error) return <ImageCoverComponent {...props} />;
    return null;
};

ImageCover.Svg = ImageCoverSvg;
