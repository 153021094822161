import { SearchFilter } from '__SMART_APP_OLD__/api/graphql/types';
import { EventConcepts } from 'analytics/types/concepts';

import { AbstractEvent } from './AbstractEvent';
import { generateUuid } from '../loggingUtils';

export type SearchResultFolder = {
    // Title of the folder string
    title: string;
    // Total number of items in the folder, not necessarily all visible. integer (int32)
    totalCount: number;
};

export enum SearchEvents {
    // search by changing the text input field's value
    TEXT = 'text',

    // search by selecting one of the related titles after entering (part of) a search string.
    RELATED_TITLE = 'relatedTitle', // can't implement

    // Search by selecting one of the related cast (actors, directors).
    CAST = 'cast',

    // Execute a trending search
    TRENDING = 'trending', // can be implemented on a later stage, there are GraphQL query options to show and use instead of typed search

    //  Execute a recent search
    RECENT = 'recent', // can't implement

    // Narrow down the search by changing the filter
    FILTER = 'filter', // can't implement
}

export class SearchEvent extends AbstractEvent {
    private readonly concept: EventConcepts.SEARCH = EventConcepts.SEARCH;

    constructor(
        private readonly event: SearchEvents,
        private readonly query: string,
        private readonly result: SearchResultFolder[],
        private readonly filter?: SearchFilter
    ) {
        super(generateUuid());
    }
}
