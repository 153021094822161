import React from 'react';

import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { ChannelListsScreenManageChannelsCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsCtx';
import { ChannelListsScreenManageChannelsItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsItem';
import { CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/constants';

import { Translation } from 'App/Modules/Translation';
import { UseListReturnType as FocusUseListReturnType } from 'App/Packages/Focus/Hooks/Types';
import { Function } from 'App/Packages/Function';

type Props = {
    allItems: string[];
    addedChannelIds: string[];
    navigation: FocusUseListReturnType<string>;
    firstVisibleIndex: number;
    setFirstVisibleIndex: React.Dispatch<React.SetStateAction<number>>;
    updateChannel: (channelId: string) => void;
};

export const ChannelListsScreenManageChannelsViewAllSection: React.FunctionComponent<Props> = (props) => {
    const { allItems, addedChannelIds, navigation, firstVisibleIndex, setFirstVisibleIndex, updateChannel } = props;

    const onFocus = () => {
        setFirstVisibleIndex(
            Function.calculateNextFirstVisibleIndex(
                navigation.focusedIndex,
                firstVisibleIndex,
                CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS,
                allItems.length
            )
        );
    };

    const renderItem = (channelId: string, index: number) => (
        <ChannelListsScreenManageChannelsItem
            key={channelId}
            id={channelId}
            index={index}
            ctx={ChannelListsScreenManageChannelsCtx.All}
            navigation={navigation}
            isChecked={addedChannelIds.includes(channelId)}
            onEnter={updateChannel}
            onFocus={onFocus}
        />
    );

    return (
        <>
            <Settings.Section.Header>
                <Settings.Section.Header.Title>{Translation.Key.Screen.ChannelListsManage.AllChannels}</Settings.Section.Header.Title>
            </Settings.Section.Header>
            <Settings.Section.Content className="channel-lists-manage-screen__list">
                <List
                    firstVisibleIndex={firstVisibleIndex}
                    className={'settings-section-content__list'}
                    items={allItems}
                    itemType={ListItemType.CHANNEL_ITEM}
                    renderer={renderItem}
                />
            </Settings.Section.Content>
        </>
    );
};
