import { CommunityEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';

const toEntities = (entities: CommunityEntity[]): Record<string, CommunityEntity> =>
    entities.reduce<Record<string, CommunityEntity>>((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
    }, {});

const toIds = (entities: CommunityEntity[]): string[] => entities.map((entity) => entity.id);

export const communityMapper = { toEntities, toIds };
