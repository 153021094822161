import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum ConsentActionType {
    CHANGED = '@consent/changed',
    INITLOADALL = '@consent/initLoadAll',
}

// Action
export type ConsentChangedActionPayload = { type: ConsentType; accepted: boolean };
export type ConsentInitLoadActionPayload = ConsentInitLoad;
export type ConsentChangedAction = PayloadedAction<ConsentActionType.CHANGED, ConsentChangedActionPayload>;
export type ConsentInitLoadAction = PayloadedAction<ConsentActionType.INITLOADALL, ConsentInitLoadActionPayload>;

export type ConsentReduxAction = ConsentChangedAction | ConsentInitLoadAction;

// State
export enum ConsentType {
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    ANALYTICS = 'ANALYTICS',
    USER_BEHAVIOR = 'USER_BEHAVIOR',
    ADVERTISING = 'ADVERTISING',
}

export type ConsentEntityTable = Record<ConsentType, ConsentEntity>;

export type ConsentEntity = {
    accepted: boolean;
};

export type ConsentInitLoad = {
    [ConsentType.ADVERTISING]: ConsentEntity;
    [ConsentType.ANALYTICS]: ConsentEntity;
    [ConsentType.USER_BEHAVIOR]: ConsentEntity;
    [ConsentType.RECOMMENDATIONS]: ConsentEntity;
};
