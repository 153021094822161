import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodFolderFolderItemFragment = { __typename: 'VODFolder', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export const VodFolderFolderItemFragmentDoc = gql`
    fragment vodFolderFolderItem on VODFolder {
  id
  title
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  parentalRating {
    ...parentalRatingRelation
  }
  __typename
}
    ${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;