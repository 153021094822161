import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { KeyboardKeyElement } from 'App/Modules/Keyboard/Element/KeyboardKeyElement';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React from 'react';

export type KeyboardCharKeyProps = UI.KeyProps & {
    keyboard: KeyboardElement;
    element: KeyboardKeyElement;
};

export const KeyboardCharKey: React.FunctionComponent<KeyboardCharKeyProps> = (props) => {
    const { keyboard, element, ...other } = props;
    const capslock = Subscribable.use(keyboard.capslock);
    const variant: UI.KeyLabelVariant = capslock ? 'uppercase' : 'lowercase';
    if (element.schema.type !== 'key.char') return null;
    return (
        <UI.Keyboard.Key {...other}>
            <UI.Keyboard.Key.Label variant={variant} size={element.schema.label?.size}>
                {element.schema.value}
            </UI.Keyboard.Key.Label>
        </UI.Keyboard.Key>
    );
};
