import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

const Hash = 'DetailFolders';

export const assetTypeForContentItemType = {
    [ContentItemType.Banner]: AssetType.BANNER,
    [ContentItemType.Channel]: AssetType.CHANNEL,
    [ContentItemType.Channelproduct]: AssetType.CHANNEL_PRODUCT,
    [ContentItemType.Event]: AssetType.EVENT,
    [ContentItemType.Networkrecording]: AssetType.NETWORK_RECORDING,
    [ContentItemType.Productbundle]: AssetType.PRODUCT_BUNDLE,
    [ContentItemType.Series]: AssetType.SERIES,
    [ContentItemType.Vodasset]: AssetType.VOD_ASSET,
    [ContentItemType.Vodfolder]: AssetType.VOD_FOLDER,
    [ContentItemType.Vodproduct]: AssetType.VOD_PRODUCT,
    [ContentItemType.Vodseries]: AssetType.VOD_SERIES,
};

export const contentItemTypeForAssetType = Object.entries(assetTypeForContentItemType).reduce<Partial<Record<AssetType, ContentItemType>>>(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
);

export const Constants = {
    Hash,
    assetTypeForContentItemType,
    contentItemTypeForAssetType,
} as const;
