import { batch } from 'react-redux';

import { GQL } from '__SMART_APP_OLD__/app/gql';
import {
    selectCurrentRecordings,
    selectRecordingManagementIsTrickMode,
    selectRecordingManagementSelectedRecordings,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import {
    RecordingManageFilterOptionSelected,
    RecordingManageHandleDeleteResult,
    RecordingManageSelect,
    RecordingManageSortOptionSelected,
    RecordingManageTrickModeAction,
    RecordingManagementActionID,
    RecordingManagementFocusContext,
    SetInitialRecordManageOnBack,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { OutOfBounds as FocusOutOfBounds } from 'App/Packages/Focus/Types/OutOfBounds';
import { History } from '__SMART_APP_OLD__/app/modules/History';

export const recordingManageFilterOptionSelected = (filterByValue: string): RecordingManageFilterOptionSelected => ({
    type: ActionType.RECORDING_MANAGE_SET_FILTER_OPTION,
    payload: { filterByValue },
});

export const recordingManageSortingOptionSelected = (sortByValue: string): RecordingManageSortOptionSelected => ({
    type: ActionType.RECORDING_MANAGE_SET_SORT_OPTION,
    payload: { sortByValue },
});

export const recordingFilterSelectOverlayRaised: Thunk<void> = (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.SELECT, { target: Overlay.SelectTarget.RECORDING_FILTER }));
};

export const recordingSortSelectOverlayRaised: Thunk<void> = (dispatch) => {
    dispatch(Overlay.actions.mount(Overlay.Type.SELECT, { target: Overlay.SelectTarget.RECORDING_SORT }));
};

export const setInitialRecordManageStateOnBack = (): SetInitialRecordManageOnBack => ({
    type: ActionType.RECORDING_MANAGE_RESET_STATE,
    payload: null,
});

export const setTrickMode = (isTrickMode: boolean): RecordingManageTrickModeAction => ({
    type: ActionType.RECORDING_MANAGE_SET_TRICK_MODE,
    payload: { isTrickMode },
});

export const recordingManageSetTrickMode =
    (isTrickMode: boolean): Thunk<void> =>
    (dispatch) => {
        dispatch(setTrickMode(isTrickMode));
    };

export const recordingManageResetState = (nullValue: null): SetInitialRecordManageOnBack => ({
    type: ActionType.RECORDING_MANAGE_RESET_STATE,
    payload: nullValue,
});

export const recordingManageHandleSelect = (selected: string[]): RecordingManageSelect => ({
    type: ActionType.RECORDING_MANAGE_HANDLE_SELECT,
    payload: { selected },
});

export const recordingManagementBackTriggered: Thunk<void> = (dispatch, getState) => {
    const isTrickMode = selectRecordingManagementIsTrickMode(getState());
    if (isTrickMode) {
        dispatch(recordingManageSetTrickMode(false));
        dispatch(recordingManageHandleSelect([]));
        return;
    }
    dispatch(recordingManageResetState(null));
    dispatch(History.actions.pop());
};

export const recordingManagementItemSelected =
    (id: GQL.RecordingID): Thunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const isTrickMode = selectRecordingManagementIsTrickMode(state);
        if (!isTrickMode) return;
        const selectedRecordings = selectRecordingManagementSelectedRecordings(state);
        const copiedArray = [...selectedRecordings];
        const index = copiedArray.findIndex((selected) => selected === id);
        if (index !== -1) {
            copiedArray.splice(index, 1);
        } else {
            copiedArray.push(id);
        }
        dispatch(recordingManageHandleSelect(copiedArray));
    };

export const recordingManagementActionTaken =
    (actionId: RecordingManagementActionID): Thunk<void> =>
    (dispatch, getState) => {
        switch (actionId) {
            case RecordingManagementActionID.SORT:
                dispatch(recordingSortSelectOverlayRaised);
                break;
            case RecordingManagementActionID.FILTER:
                dispatch(recordingFilterSelectOverlayRaised);
                break;
            case RecordingManagementActionID.SELECT:
                dispatch(recordingManageSetTrickMode(true));
                break;
            case RecordingManagementActionID.CLEAR_ALL:
                dispatch(recordingManageHandleSelect([]));
                break;
            case RecordingManagementActionID.DELETE: {
                const recordingIds = selectRecordingManagementSelectedRecordings(getState());
                dispatch(Prompt.actions.mount(Prompt.Type.RecordingManagementConfirmDelete, { recordingIds }));
                break;
            }
            case RecordingManagementActionID.DELETE_ALL: {
                const state = getState();
                const recordingIds = selectCurrentRecordings(state);
                batch(() => {
                    dispatch(recordingManageHandleSelect(recordingIds));
                    dispatch(Prompt.actions.mount(Prompt.Type.RecordingManagementConfirmDelete, { recordingIds }));
                });
                break;
            }
            default:
                break;
        }
    };

export const recordingManageUpdateAfterDeleteCall = (selected: string[]): RecordingManageHandleDeleteResult => ({
    type: ActionType.RECORDING_MANAGE_HANDLE_DELETE_RESULT,
    payload: { selected },
});

export const recordingManagementOutOfBounds: FocusOutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === RecordingManagementFocusContext.HEADER && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.RECORDING_MANAGEMENT, RecordingManagementFocusContext.GRID));
    }
    if (ctx === RecordingManagementFocusContext.GRID && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.RECORDING_MANAGEMENT, RecordingManagementFocusContext.HEADER));
    }

    return undefined;
};

export const recordingDetailInitializeCTX = (): Thunk<Promise<void>> => async (dispatch) =>
    dispatch(Screen.actions.ctx.changed(Screen.Type.RECORDING_MANAGEMENT, RecordingManagementFocusContext.HEADER));

export const recordingManagementLifycycle = {
    mount: recordingDetailInitializeCTX,
    outOfBounds: recordingManagementOutOfBounds,
};
