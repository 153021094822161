import { TransportType } from '__SMART_APP_OLD__/app/platform/types';

export enum WifiStandards {
    LEGACY = 'legacy',
    WIFI_4 = 'wifi-4',
    WIFI_5 = 'wifi-5',
    WIFI_6 = 'wifi-6',
}

export interface NetworkConnectionParams {
    transport: TransportType;

    ipAddress?: string;

    ssid?: string;

    frequency?: number;

    wifiStandard?: WifiStandards;
}

export class NetworkConnection {
    private readonly transport: TransportType;

    private readonly ipAddress: string;

    private ssid?: string;

    private frequency?: number;

    private wifiStandard?: WifiStandards;

    constructor({ ipAddress, transport, frequency, ssid, wifiStandard }: NetworkConnectionParams) {
        this.transport = transport;
        this.ipAddress = ipAddress ?? '0.0.0.0';
        if (frequency) this.frequency = frequency;
        else delete this.frequency;
        if (ssid) this.ssid = ssid;
        else delete this.ssid;
        if (wifiStandard) this.wifiStandard = wifiStandard;
        else delete this.wifiStandard;
    }
}
