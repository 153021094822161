import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { MessageFragmentDoc } from '../fragments/message.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageLoadManyQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
  messagePagination: Types.FullPaginationParams;
  messageFilter: Types.MessageFilterParams;
  messageBackgroundWidth: Types.Scalars['Int']['input'];
  messageBackgroundHeight: Types.Scalars['Int']['input'];
  messageIconWidth: Types.Scalars['Int']['input'];
  messageIconHeight: Types.Scalars['Int']['input'];
  messageAttachmentImageWidth: Types.Scalars['Int']['input'];
  messageAttachmentImageHeight: Types.Scalars['Int']['input'];
}>;


export type MessageLoadManyQuery = { __typename?: 'Nexx4Queries', messages: { __typename?: 'MessagesConnection', edges: Array<{ __typename?: 'MessagesEdge', node: { __typename?: 'Message', id: string, title: string, message: string, label: string, displayKind: Types.MessageDisplayKind, status: Types.MessageStatus, timestamp: any, validFrom: any, validUntil: any, icon?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, actions?: { __typename?: 'ActionCatalog', items: Array<{ __typename: 'Action', id: string, uri: string, title: string } | null> } | null, notificationInfo?: { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null } | null, attachments?: { __typename?: 'MessageAttachmentCatalog', items: Array<{ __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null } | { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } } | null> } | null } } | null> } };

export type MessageLoadManyFragment = { __typename?: 'Nexx4Queries', messages: { __typename?: 'MessagesConnection', edges: Array<{ __typename?: 'MessagesEdge', node: { __typename?: 'Message', id: string, title: string, message: string, label: string, displayKind: Types.MessageDisplayKind, status: Types.MessageStatus, timestamp: any, validFrom: any, validUntil: any, icon?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, actions?: { __typename?: 'ActionCatalog', items: Array<{ __typename: 'Action', id: string, uri: string, title: string } | null> } | null, notificationInfo?: { __typename?: 'MessageNotificationDisplayInfo', timeout?: number | null, repeatTimes?: number | null, repeatDelay?: number | null, screens?: Array<Types.MessageNotificationDisplayInfoScreens | null> | null } | null, attachments?: { __typename?: 'MessageAttachmentCatalog', items: Array<{ __typename: 'ImageItem', id: string, image?: { __typename: 'Image', id: string, url?: string | null } | null } | { __typename: 'VODAsset', id: string, title: string, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, trailers: { __typename?: 'TrailerCatalog', items: Array<{ __typename?: 'Trailer', id: string, title: string } | null> } } | null> } | null } } | null> } };

export const MessageLoadManyFragmentDoc = gql`
    fragment messageLoadMany on Nexx4Queries {
  messages(
    profileId: $profileId
    pagination: $messagePagination
    filter: $messageFilter
  ) {
    edges {
      node {
        ...message
      }
    }
  }
}
    ${MessageFragmentDoc}`;
export const MessageLoadManyDocument = gql`
    query messageLoadMany($profileId: ID!, $messagePagination: FullPaginationParams!, $messageFilter: MessageFilterParams!, $messageBackgroundWidth: Int!, $messageBackgroundHeight: Int!, $messageIconWidth: Int!, $messageIconHeight: Int!, $messageAttachmentImageWidth: Int!, $messageAttachmentImageHeight: Int!) {
  ...messageLoadMany
}
    ${MessageLoadManyFragmentDoc}`;

/**
 * __useMessageLoadManyQuery__
 *
 * To run a query within a React component, call `useMessageLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageLoadManyQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      messagePagination: // value for 'messagePagination'
 *      messageFilter: // value for 'messageFilter'
 *      messageBackgroundWidth: // value for 'messageBackgroundWidth'
 *      messageBackgroundHeight: // value for 'messageBackgroundHeight'
 *      messageIconWidth: // value for 'messageIconWidth'
 *      messageIconHeight: // value for 'messageIconHeight'
 *      messageAttachmentImageWidth: // value for 'messageAttachmentImageWidth'
 *      messageAttachmentImageHeight: // value for 'messageAttachmentImageHeight'
 *   },
 * });
 */
export function useMessageLoadManyQuery(baseOptions: Apollo.QueryHookOptions<MessageLoadManyQuery, MessageLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageLoadManyQuery, MessageLoadManyQueryVariables>(MessageLoadManyDocument, options);
      }
export function useMessageLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageLoadManyQuery, MessageLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageLoadManyQuery, MessageLoadManyQueryVariables>(MessageLoadManyDocument, options);
        }
export function useMessageLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageLoadManyQuery, MessageLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageLoadManyQuery, MessageLoadManyQueryVariables>(MessageLoadManyDocument, options);
        }
export type MessageLoadManyQueryHookResult = ReturnType<typeof useMessageLoadManyQuery>;
export type MessageLoadManyLazyQueryHookResult = ReturnType<typeof useMessageLoadManyLazyQuery>;
export type MessageLoadManySuspenseQueryHookResult = ReturnType<typeof useMessageLoadManySuspenseQuery>;
export type MessageLoadManyQueryResult = Apollo.QueryResult<MessageLoadManyQuery, MessageLoadManyQueryVariables>;