import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type GenreFragment = { __typename?: 'Genre', id: string, title: string };

export const GenreFragmentDoc = gql`
    fragment genre on Genre {
  id
  title
}
    `;