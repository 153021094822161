import { TS } from '__SMART_APP_OLD__/app/utils/ts';

export const isEventRecordable = (
    networkRecording: boolean,
    start: number | string | null,
    end: number | string | null,
    plannableUntil: number | string | null
) => {
    const now = Date.now();
    const plannableUntilTS = TS.getTSFromDate(plannableUntil);
    const eventStartTS = TS.getTSFromDate(start);
    const eventEndTS = TS.getTSFromDate(end);
    if (!networkRecording) return false;
    if (!plannableUntilTS || !eventStartTS || !eventEndTS) return false;
    if (eventEndTS < now) return plannableUntilTS > now;
    if (now < eventStartTS) return plannableUntilTS >= eventStartTS;
    return plannableUntilTS >= eventEndTS;
};
