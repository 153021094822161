import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
import { EventEntitlementsFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/event/fragments/eventEntitlements.generated';
import { MetadataFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/metadata.generated';
import { StreamInfoFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/event/fragments/streamInfo.generated';
import { ImageFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
export type EventDetailItemFragment = { __typename: 'Event', id: string, title: string, start: any, end: any, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, streamInfo: { __typename?: 'StreamInfo', hardOfHearing: boolean, visuallyImpaired: boolean, dolby: boolean, multipleAudioLanguages: boolean, subtitles: boolean }, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null };

export const EventDetailItemFragmentDoc = gql`
    fragment eventDetailItem on Event {
  id
  title
  start
  end
  parentalRating {
    ...parentalRatingRelation
  }
  channel {
    id
  }
  entitlements {
    ...eventEntitlements
  }
  metadata {
    ...metadata
  }
  streamInfo {
    ...streamInfo
  }
  backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
    ...image
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  __typename
}
    ${ParentalRatingRelationFragmentDoc}
${EventEntitlementsFragmentDoc}
${MetadataFragmentDoc}
${StreamInfoFragmentDoc}
${ImageFragmentDoc}`;