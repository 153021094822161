import { KeyCapsLockComponent, KeyCapsLockProps, KeyCapsLock as Root } from 'App/Packages/UI/Keyboard/Components/Key/CapsLock/KeyCapsLock';
import {
    KeyCapsLockIcon,
    KeyCapsLockIconComponent,
    KeyCapsLockIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/CapsLock/KeyCapsLockIcon';

export type { KeyCapsLockComponent, KeyCapsLockIconComponent, KeyCapsLockIconProps, KeyCapsLockProps };

const KeyCapsLock = Object.assign(Root, {
    Icon: KeyCapsLockIcon,
});

export { KeyCapsLock };
