import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';

import { Mask } from 'App/Packages/UI/Mask';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

const getClassName = (props: CardFolderProps) => {
    const classlist = ['folder-card-v3'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

const getContentClassName = (isFocused: boolean) => {
    const classlist = ['folder-card-v3__content'];
    if (isFocused) classlist.push('folder-card-v3__content--focused');
    return classlist.join(' ');
};

export type CardFolderProps = React.PropsWithChildren<{
    isFocused?: boolean;
    className?: string;
    parentalRating?: string;
    sessionType?: PinSessionType;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHover?: React.MouseEventHandler<HTMLDivElement>;
}> &
    React.RefAttributes<HTMLDivElement>;

export type CardFolderComponent = React.FunctionComponent<CardFolderProps>;

export const CardFolder: CardFolderComponent = React.forwardRef((props, ref) => (
    <Mask.Provider parentalRatingId={props.parentalRating} pinSessionType={props.sessionType}>
        <div ref={ref} className={getClassName(props)} onClick={props.onClick} onMouseOver={props.onHover}>
            <Div
                className={getContentClassName(!!props.isFocused)}
                backgroundColor={props.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
                shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
            >
                {props.children}
            </Div>
        </div>
    </Mask.Provider>
));
