// Actions

import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export type AuthSessionReduxAction =
    | AuthSessionStatusChangeAction
    | AuthSessionLogActionUarAction
    | AuthSessionLegacyCallbacksTimeoutAction;
export type AuthSessionStatusChangeActionPayload = { status: AuthSessionStatus };
export type AuthSessionStatusChangeAction = PayloadedAction<ActionType.AUTH_SESSION_STATUS_CHANGE, AuthSessionStatusChangeActionPayload>;

export type AuthSessionLogActionUarPayload = { logAction: boolean; isLoginWithCode: boolean };
export type AuthSessionLogActionUarAction = PayloadedAction<ActionType.AUTH_SESSION_LOG_LOGIN_UAR, AuthSessionLogActionUarPayload>;

export type AuthSessionLegacyCallbacksTimeoutPayload = { legacyCallbacksInterval: number };

export type AuthSessionLegacyCallbacksTimeoutAction = PayloadedAction<
    ActionType.AUTH_SESSION_START_LEGACY_CALLBACKS,
    AuthSessionLegacyCallbacksTimeoutPayload
>;

// State
export type AuthSessionState = {
    status: AuthSessionStatus;
    logAction: boolean;
    isLoginWithCode: boolean;
    legacyCallbacksInterval: number;
};

export const enum AuthSessionStatus {
    NOT_LOGGED_IN = 'NOT_LOGGED_IN',
    LOGGED_IN = 'LOGGED_IN',
    LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
    IDP_LOGIN_IN_PROGRESS = 'IDP_LOGIN_IN_PROGRESS',
    INITIALISING_SESSION = 'INITIALISING_SESSION',
    HANDLING_ERROR_10 = 'HANDLING_ERROR_10',
    CREATE_DEVICE_IN_PROGRESS = 'CREATE_DEVICE_IN_PROGRESS',
    DEVICELESS_LOGIN = 'DEVICELESS_LOGIN',
    REFRESHING_TOKEN = 'REFRESHING_TOKEN',
}
