import { useEffect, useState } from 'react';

import { Value } from 'App/Packages/Subscribable/Core/Value';

export type Use = <const T extends Value<any> | undefined>(
    value: T
) => T extends undefined ? (T extends Value<infer G> ? G | undefined : undefined) : T extends Value<infer G> ? G : never;

const useS: Use = (value) => {
    const [state, setState] = useState(value?.get());
    useEffect(() => {
        if (!value) return;
        setState(value.get());
        value.subscribe(() => setState(value.get()));
    }, [value]);
    return state;
};

export const use = useS;
