import { ConfigService } from '__SMART_APP_OLD__/app/modules/ConfigService';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum TranslationActionType {
    LOADED = '@translation/loaded',
}

// Actions
export type TranslationReduxAction = TranslationLoadedAction;
export type TranslationLoadedAction = PayloadedAction<TranslationActionType.LOADED, TranslationLoadedActionPayload>;
export type TranslationLoadedActionPayload = { code: string; data: ConfigService.TranslationsData };

// State
export type TranslationEntityTable = Record<string, ConfigService.TranslationsData>;
