import { ftiActions } from '__SMART_APP_OLD__/app/modules/Fti/store/actions';
import { getInitialFtiState } from '__SMART_APP_OLD__/app/modules/Fti/store/getInitialState';
import { ftiReducer } from '__SMART_APP_OLD__/app/modules/Fti/store/reducer';
import { FtiActionType, FtiCompletedTable, FtiReduxAction } from '__SMART_APP_OLD__/app/modules/Fti/store/types';

export { FtiActionType as ActionType };

export type { FtiCompletedTable as CompletedTable, FtiReduxAction as ReduxAction };

export const getInitialState = getInitialFtiState;

export const reducer = ftiReducer;

export const actions = ftiActions.public;
