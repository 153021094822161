import { Calc } from 'App/Packages/Calc';

export type ConstantsType = typeof Constants;

export const Constants = {
    Thumbnail: {
        Width: Calc.pixel(416),
        Height: Calc.pixel(288),
    },
} as const;
