import React, { useMemo } from 'react';

import { DynamicListEngine } from '__SMART_APP_OLD__/app/components/ListV2';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { SettingsCtx } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsCtx';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectLanguageSettingsScreenTitle, selectSettingsLanguageItemsList } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguagePreferencesScreen/actions';
import {
    getAudioMappingLanguageDisplay,
    getSubtitleMappingLanguageDisplay,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguagePreferencesScreen/utilts';
import { SettingsLanguageElementType } from '__SMART_APP_OLD__/app/types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const LanguagePreferencesScreenView: React.FunctionComponent<Screen.Props<Screen.Type.LANGUAGE_PREFERENCES>> = () => {
    const dispatch = useDispatch();
    const elements = useSelector(selectSettingsLanguageItemsList);
    const items = useMemo(() => elements.map((element) => element.id), [elements]);
    const title = useSelector(selectLanguageSettingsScreenTitle);
    const profilePreferences = useSelector(Profile.selectors.selectPreferences);
    const ui = useSelector(Language.selectors.selectSelectedTitle);
    const audioPrimary = getAudioMappingLanguageDisplay(profilePreferences?.firstAudioLanguage ?? '');
    const audioSecondary = getAudioMappingLanguageDisplay(profilePreferences?.secondAudioLanguage ?? '');
    const subtitlePrimary = getSubtitleMappingLanguageDisplay(profilePreferences?.firstSubtitleLanguage ?? '');
    const subtitleSecondary = getSubtitleMappingLanguageDisplay(profilePreferences?.secondSubtitleLanguage ?? '');
    const valueForType = useMemo(
        () => ({
            [SettingsLanguageElementType.UI]: ui,
            [SettingsLanguageElementType.AUDIO_PRIMARY]: audioPrimary,
            [SettingsLanguageElementType.AUDIO_SECONDARY]: audioSecondary,
            [SettingsLanguageElementType.SUBTITLE_PRIMARY]: subtitlePrimary,
            [SettingsLanguageElementType.SUBTITLE_SECONDARY]: subtitleSecondary,
        }),
        [ui, audioPrimary, audioSecondary, subtitlePrimary, subtitleSecondary]
    );
    const { focusedIndex, isDisabled, focus } = Focus.useList({
        ctx: SettingsCtx.PrimaryList,
        axis: Axis.Y,
        items,
    });

    const engine = DynamicListEngine.useEngine(focusedIndex, items, { axis: Axis.Y, max: 8, offset: 1 });

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action />
                <Settings.Header.Title>{title}</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <Settings.Section.Content>
                    <Settings.Section.Content.List engine={engine}>
                        {(item, index) => (
                            <>
                                {engine.getFirstIndex() === index && <Settings.Section.Content.Separator />}
                                <Settings.Section.Content.Item
                                    id={item}
                                    ctx={SettingsCtx.PrimaryList}
                                    isFocused={engine.getIsFocused(index) && !isDisabled}
                                    isHidden={!engine.getIsRendered(index)}
                                    onEnter={(id) => dispatch(actions.private.enter(id))}
                                    onHover={focus}
                                >
                                    <Settings.Section.Content.Item.Title>{elements[index].title}</Settings.Section.Content.Item.Title>
                                    <Settings.Section.Content.Item.Spacer />
                                    <Settings.Section.Content.Item.Value>{valueForType[item]}</Settings.Section.Content.Item.Value>
                                    <Settings.Section.Content.Item.Icon.ArrowRight />
                                </Settings.Section.Content.Item>
                                <Settings.Section.Content.Separator />
                            </>
                        )}
                    </Settings.Section.Content.List>
                </Settings.Section.Content>
            </Settings.Section>
            <Settings.Section>
                <Settings.Section.Header>
                    <Settings.Section.Header.Title>{elements[focusedIndex].title}</Settings.Section.Header.Title>
                </Settings.Section.Header>
                <Settings.Section.Content>
                    <Settings.Section.Content.Text>{elements[focusedIndex].info}</Settings.Section.Content.Text>
                </Settings.Section.Content>
            </Settings.Section>
        </Settings>
    );
};
