import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import {
    BrandType,
    ConnectionType,
    DeviceName,
    GeneralDeviceType,
    GetStatusResponse,
    NetworkState,
    PlatformInterface,
    TransportType,
} from '__SMART_APP_OLD__/app/platform/types';
import { generateUniqueId } from '__SMART_APP_OLD__/utils/generateId';

type IDItem = {
    idType: string;
    idValue: string;
};

type IDResponse = {
    returnValue: boolean;
    idList: IDItem[];
};

const WEBOS_OS = 'WebOS';
const WEBOS_MANUFACTURER = 'LG-Smart-TV';

const loadDeviceID = () =>
    new Promise<string>((resolve) => {
        window.webOS.service.request('luna://com.webos.service.sm', {
            method: 'deviceid/getIDs',
            parameters: {
                idType: ['LGUDID'],
            },
            onComplete: (response: IDResponse) => {
                if (!response.returnValue) {
                    resolve(generateUniqueId());
                    return;
                }
                const LGUDID = response.idList.find((uid) => uid.idType === 'LGUDID');
                if (!LGUDID || !LGUDID.idValue) {
                    resolve(generateUniqueId());
                    return;
                }
                resolve(LGUDID.idValue);
            },
        });
    });

const getID = async () => {
    const id = Storage.get(Storage.Key.DEVICE_ID);
    if (id) return id;
    const lgId = await loadDeviceID();
    return lgId;
};

const getDeviceInfo = async (): Promise<[string, string]> =>
    new Promise((resolve) => {
        window.webOS.deviceInfo((info) => {
            try {
                console.log('WebOS device Info', JSON.stringify(info));
                const { sdkVersion = '', modelName = '' } = info;
                const osVersion = parseInt(sdkVersion.replaceAll('.', '').substring(0, 3), 10).toString();
                resolve([modelName, osVersion]);
            } catch (error) {
                console.error('Error parsing WebOs Sdk Version Number', error);
                resolve(['n/a', 'n/a']);
            }
        });
    });

const getNetworkStateGetStatusResponse = (response: GetStatusResponse) => {
    if (!response.isInternetConnectionAvailable) {
        return { transport: TransportType.OFFLINE, ipAddress: '0.0.0.0' };
    }
    if (response.wired && response.wired.state === 'connected') {
        return { transport: TransportType.ETHERNET, ipAddress: response.wired.ipAddress };
    }
    if (response.wifi && response.wifi.state === 'connected') {
        return { transport: TransportType.WIFI, ipAddress: response.wifi.ipAddress, ssid: response.wifi.ssid };
    }
    return { transport: TransportType.OTHER, ipAddress: '0.0.0.0' };
};

const getNetworkState = async (): Promise<NetworkState> =>
    new Promise<NetworkState>((resolve) =>
        window.webOSDev.connection.getStatus({
            onSuccess(response) {
                resolve(getNetworkStateGetStatusResponse(response));
            },
            onFailure(error) {
                console.log('Failed to get network state');
                console.log(`[${error.errorCode}]: ${error.errorText}`);
                resolve({ transport: TransportType.OTHER, ipAddress: '0.0.0.0' });
            },
            subscribe: false,
        })
    );

const getConnectionType = async (): Promise<ConnectionType> => {
    const networkState = await getNetworkState();
    switch (networkState.transport) {
        case TransportType.OFFLINE:
            return ConnectionType.DISCONNECTED;
        case TransportType.WIFI:
            return ConnectionType.WIFI;
        case TransportType.CELLULAR:
            return ConnectionType.CELLULAR;
        case TransportType.ETHERNET:
            return ConnectionType.ETHERNET;
        default:
            return ConnectionType.OTHER;
    }
};

const getTransportType = async (): Promise<TransportType> => {
    const networkState = await getNetworkState();
    return networkState.transport;
};

const networkStateChangeHandlerGenerator = async (platform: PlatformInterface) => {
    platform.IS_ON_LINE = navigator.onLine;
    const state = await platform.getNetworkState();
    const networkState = { current: state, prev: state };
    return (response: GetStatusResponse) => {
        networkState.prev = networkState.current;
        networkState.current = getNetworkStateGetStatusResponse(response);
        platform.IS_ON_LINE = response.isInternetConnectionAvailable;
        platform.onNetworkStateChange(networkState.current, networkState.prev);
    };
};

const attachNetworkStateListener = async (platform: PlatformInterface) => {
    const networkStateChangeHandler = await networkStateChangeHandlerGenerator(platform);
    window.webOSDev.connection.getStatus({
        onSuccess(response) {
            networkStateChangeHandler(response);
        },
        onFailure(error) {
            console.log('Failed to get network state');
            console.log(`[${error.errorCode}]: ${error.errorText}`);
        },
        subscribe: true,
    });
};

// if for the future we need to not totally close the app, but place the App to the recent list, and
// opens the list (bottom line) of all apps at the bottom of the screen.
// return window.webOS.platformBack ? window.webOS.platformBack() : window.close();
// close app on LG
// const close = () => window.close();

export const initializeWebOs = async (platform: PlatformInterface): Promise<void> => {
    // Super strange for me but webOSTV-dev.js script should be before webOSTV.js
    // Otherwise window.webOS is undefined
    const webOSDevScript = Common.loadDeviceSDK('/webOSTVjs-1.2.8/webOSTV-dev.js');
    const webOSScript = Common.loadDeviceSDK('/webOSTVjs-1.2.8/webOSTV.js');
    await webOSDevScript;
    await webOSScript;
    platform.DEVICE_TYPE = GQL.DeviceType.SmartTvWebos;
    platform.GENERAL_DEVICE_TYPE = GeneralDeviceType.TV;
    platform.DEVICE_NAME = DeviceName.LG_TV;
    platform.OS = WEBOS_OS;
    platform.MANUFACTURER = WEBOS_MANUFACTURER;
    platform.ID = await getID();
    const [model, osVersion] = await getDeviceInfo();
    platform.MODEL = model;
    platform.OS_VERSION = osVersion;
    platform.BRAND = BrandType.LG;
    platform.getConnectionType = getConnectionType;
    platform.getTransportType = getTransportType;
    platform.getNetworkState = getNetworkState;
    attachNetworkStateListener(platform);
    return Promise.resolve();
};
