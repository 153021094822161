import { MiniEpgMode } from '__SMART_APP_OLD__/components/EPG/MiniEpg/types';
import { NavKey } from '__SMART_APP_OLD__/utils/Constants';
import State from '__SMART_APP_OLD__/utils/State';

export const selectModeMiniEpg = (): MiniEpgMode => (State.get(NavKey.MINI_EPG)?.time ? MiniEpgMode.FULL : MiniEpgMode.ZAPPER);

export const selectTimeMiniEpg = (): number => State.get(NavKey.MINI_EPG)?.time || Date.now();

export const selectChannelIdMiniEpgGenerator = (channelIds: string[], liveChannelId: string) => (): string => {
    const channelIdFromState = State.get(NavKey.MINI_EPG)?.channelId;
    if (channelIdFromState && channelIds.includes(channelIdFromState)) return channelIdFromState;
    if (channelIds.includes(liveChannelId)) return liveChannelId;
    return channelIds[0];
};
