import { Subscribable } from 'App/Packages/Subscribable';
import { FolderItemValue } from 'App/Packages/UI/Folder/Modules/FolderItem/Context/FolderItemContext';
import { useEffect, useRef } from 'react';

export type UseFolderItemsFocusInitial = (items: Subscribable.Record<FolderItemValue>, initial: string | undefined) => void;

export const useFolderItemsFocusInitial: UseFolderItemsFocusInitial = (items, initial) => {
    const initialized = useRef(false);

    const item = Subscribable.useItem(items, initial);

    useEffect(() => {
        if (initialized.current) return;
        if (!item) return;
        item.scrollIntoView.instant.set(true);
        item.focus();
        initialized.current = true;
    }, [item]);
};
