import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import { KeyLanguageIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/Language/KeyLanguageIcon';
import React from 'react';

export type KeyLanguageProps = KeyProps;

export type KeyLanguageComponent = React.FunctionComponent<KeyLanguageProps>;

export const KeyLanguage: KeyLanguageComponent = (props) => {
    const { size = 'x1', variant = 'secondary', children = KeyLanguageIconInsance, ...other } = props;
    return (
        <Key size={size} variant={variant} {...other}>
            {children}
        </Key>
    );
};
