/* eslint-disable @typescript-eslint/no-unused-vars */
import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { openPinOverlay } from '__SMART_APP_OLD__/components/pin/PinUtils';
import { AssetType, Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

import { Folder } from 'App/Modules/Folder';
import { MyLibraryData } from 'App/Modules/MyLibraryData';
import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { MenuIconItemType, MenuItemType } from 'App/Types/Menu';

const mount: Screen.LifecycleMethod<Screen.Type.MyLibrary> = (props, ctx) => async (dispatch, getState) => {
    await dispatch(Overlay.actions.noFocus.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.PRIMARY }));
    await dispatch(MyLibraryData.actions.load());
    if (props.focusedFolderId === undefined && props.focusedItemId === undefined && props.focusedHeaderId !== undefined) {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MyLibrary, UI.Header.Constants.Ctx));
    } else {
        dispatch(Screen.actions.ctx.changed(Screen.Type.MyLibrary, UI.Folder.Constants.Ctx));
    }
    await Function.sleep(1500);
    await dispatch(Overlay.actions.unmount());
};

const unmount: Screen.LifecycleMethod<Screen.Type.MyLibrary> = (props, ctx) => async (dispatch, getState) => {
    dispatch(MyLibraryData.actions.reset());
    await dispatch(Overlay.actions.unmount());
};

const update: Screen.LifecycleMethod<Screen.Type.MyLibrary> = (props, ctx) => async (dispatch, getState) => {};

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch) => {
    if (ctx === UI.Header.Constants.Ctx && event.y === 1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.MyLibrary, UI.Folder.Constants.Ctx));
    }
    if (ctx === UI.Folder.Constants.Ctx && event.y === -1) {
        return dispatch(Screen.actions.ctx.changed(Screen.Type.MyLibrary, UI.Header.Constants.Ctx));
    }
    return undefined;
};

const lifecycle: Partial<Screen.Lifecycle<Screen.Type.MyLibrary>> = {
    mount,
    unmount,
    update,
    outOfBounds,
};

const selected =
    (folderId: string, itemId: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const payload = { focusedFolderId: folderId, focusedItemId: itemId };

        if (itemId === UI.Folder.Constants.Item.Id.More) {
            await dispatch(Screen.actions.update(Screen.Type.MyLibrary, payload));
            dispatch(History.actions.push());
            dispatch(Screen.actions.mount(Screen.Type.COLLECTION, { id: folderId }));
            return;
        }

        const state = getState();
        const folderSelector = MyLibraryData.selectors.selectFolder(folderId);
        const itemSelector = Folder.selectors.selectItem(folderSelector)(itemId);
        const item = itemSelector(state);
        const isRestricted = Folder.selectors.item.selectIsRestricted(itemSelector, PinSessionType.PIN)(state);
        if (!item) return;

        const toDetailPage = async () => {
            if (item.type === ContentItemType.Networkrecording && selectRecordingStatus(item.id)(state) === GQL.RecordingStatus.Failed) {
                dispatch(textNotificationShow('NOTIFICATION_FAILED_RECORDING_NO_DETAIL_PAGE'));
                return;
            }
            const assetType = assetTypeForContentItemType[item.type];
            if (!assetType) return;
            const isFolder = assetType === AssetType.VOD_FOLDER;
            const path = isFolder ? `/page/${Route.VOD_FOLDER}/${item.id}` : `/details/${assetType}/${item.id}`;
            if (!isFolder) {
                LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.TO_DETAILED_INFO, { assetType, id: item.id }));
            }
            await dispatch(Screen.actions.update(Screen.Type.MyLibrary, payload));
            dispatch(History.actions.push());
            await dispatch(Screen.actions.unmount());
            await dispatch(
                Screen.actions.mount(Screen.Type.Detail, {
                    id: item.id,
                    type: item.type,
                    oldOperation: () => CustomHistory.go(path),
                })
            );
        };

        if (!isRestricted) {
            toDetailPage();
            return;
        }

        dispatch(Overlay.actions.mount(Overlay.Type.PIN, {}));
        openPinOverlay(
            () => toDetailPage(),
            () => dispatch(Overlay.actions.unmount())
        );
    };

const headerSelected =
    (id: MenuItemType | MenuIconItemType): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(
            Screen.actions.update(Screen.Type.MyLibrary, {
                focusedHeaderId: id,
                focusedFolderId: undefined,
                focusedItemId: undefined,
            })
        );

export const actions = {
    lifecycle,
    public: {},
    private: {
        header: {
            selected: headerSelected,
        },
        selected,
    },
};
