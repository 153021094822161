import { GQL } from '__SMART_APP_OLD__/app/gql';
import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

// Action Type
export enum LanguageActionType {
    CHANGED = '@language/changed',
    LOADED = '@language/loaded',
}

// Actions
export type ReduxLanguageAction = LanguageLoadedAction | LanguageChangedAction;

export type LanguageChangedActionPayload = { code: string };
export type LanguageChangedAction = PayloadedAction<LanguageActionType.CHANGED, LanguageChangedActionPayload>;

export type LanguageLoadedActionPayload = { languages: GQL.Language[] };
export type LanguageLoadedAction = PayloadedAction<LanguageActionType.LOADED, LanguageLoadedActionPayload>;

// State
export type LanguageEntityTable = {
    selected: string;
    codes: string[];
    idForCode: Record<string, GQL.LanguageID>;
};
