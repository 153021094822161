import React, { useRef } from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelName, selectChannelSwitchId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';
import { selectSelectedChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { useChannelSwitchingBusinessLogic } from '__SMART_APP_OLD__/components/channelSwitchDialog/hooks';
import LiveProgressBar from '__SMART_APP_OLD__/components/common/LiveProgressBar';
import { getTimestampForChannelSwitch } from '__SMART_APP_OLD__/utils/timeUtils';

import { ContentMarkerType } from 'App/Types/ContentMarker';

export const ChannelSwitchDialog: React.FC = () => {
    const channelSwitchId = useSelector(selectChannelSwitchId);
    const channelNumber = useSelector(selectSelectedChannelListChannelNumber(channelSwitchId));
    const name = useSelector(selectChannelName(channelSwitchId));

    const { isDialogVisible, event, isRestricted, isSubscribed, shouldHideWithoutAnimation, isPlayerPage, shouldMask } =
        useChannelSwitchingBusinessLogic();

    // prevIsDialogVisible is used because
    // if isDialogVisible === false we don't want to show the ChannelSwitchDialog
    // but because we render the dialog without the visible className we get the
    // fadeOut animation on the first render of the Dialog
    // So with prevIsDialogVisible we get if there is a transition from visible to hidden
    // and if we have isDialogVisible === false and the prevIsDialogVisible === false
    // we don't want to render
    const prevIsDialogVisible = useRef(isDialogVisible);
    if (!channelSwitchId || !event || shouldHideWithoutAnimation || !isPlayerPage || (!prevIsDialogVisible.current && !isDialogVisible)) {
        return null;
    }
    prevIsDialogVisible.current = isDialogVisible;

    const metaInfo = getTimestampForChannelSwitch(new Date(event.start), new Date(event.end));
    const title = shouldMask ? 'PARENTAL_CONTROL_MASKED_TITLE' : event.title;
    return (
        <div key={channelSwitchId} className={`channel-switch-container ${isDialogVisible ? ' visible' : ''}`}>
            {isRestricted && isSubscribed && <div className="restricted-overlay" />}
            {!isSubscribed && <div className="not-subscribed-overlay" />}
            <div className="channel-switch-inner">
                <div className="channel-program-container">
                    <ChannelLogo className="channel-logo" id={channelSwitchId} theme={Theme.Type.Dark} />
                    <Text typeface={Typeface.SANS} size={FontSize.CAPTION_1} weight={FontWeight.REGULAR} color={Color.PRIMARY}>
                        {channelNumber || ''} {name || ''}
                    </Text>
                    <Text
                        className="channel-program-title"
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                    >
                        {title}
                    </Text>
                    <div className="channel-program">
                        <div>
                            <ContentMarker className="channel-program-content-marker" marker={ContentMarkerType.Live} />
                        </div>
                        <div className="channel-program-inner">
                            <Text
                                className="channel-program-time"
                                typeface={Typeface.SANS}
                                size={FontSize.BODY_1}
                                weight={FontWeight.BOLD}
                                color={Color.PRIMARY}
                            >
                                {metaInfo}
                            </Text>
                            <LiveProgressBar startTime={event.start} endTime={event.end} className="channel-program-live-progress-bar" />
                        </div>
                    </div>
                </div>
                {isRestricted && isSubscribed && (
                    <Text
                        className="action"
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.BOLD}
                        color={Color.SECONDARY}
                    >
                        PARENTAL_CONTROL_ZAPPING_PRESS_OK
                    </Text>
                )}
                {!isSubscribed && (
                    <Text
                        className="action"
                        typeface={Typeface.SERIF}
                        size={FontSize.TITLE_2}
                        weight={FontWeight.BOLD}
                        color={Color.SECONDARY}
                    >
                        CHANNEL_SWITCH_UNSUBSCRIBED
                    </Text>
                )}
            </div>
        </div>
    );
};
