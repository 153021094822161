import { GQL } from '__SMART_APP_OLD__/app/gql';

export interface PlatformInterface {
    READY: Promise<void>;
    IS_ON_LINE: boolean;
    DEVICE_TYPE: GQL.DeviceType; // Device
    GENERAL_DEVICE_TYPE: GeneralDeviceType; // Device
    DEVICE_NAME: DeviceName;
    SYSTEM_LOCALE: string; // Device
    MANUFACTURER: string; // Device
    ID: string; // Device
    OS: string; // Device
    OS_VERSION: string; // Device
    MODEL: string; // Device
    CONFIG_SERVICE_HEADERS: Record<string, string>; // ConfigService
    BRAND: BrandType; // Device
    onNetworkStateChange(state: NetworkState, prevState: NetworkState): void;
    getConnectionType(): Promise<ConnectionType>;
    getTransportType(): Promise<TransportType>;
    getNetworkState(): Promise<NetworkState>;
    isVersion(version: string): boolean; // Device
}

export enum ConnectionType {
    WIFI = 'WiFi',
    ETHERNET = 'Ethernet',
    CELLULAR = 'Cellular',
    DISCONNECTED = 'Disconnected',
    OTHER = 'Other',
}

export enum TransportType {
    WIFI = 'wifi',
    ETHERNET = 'ethernet',
    CELLULAR = 'cellular',
    OTHER = 'other',
    OFFLINE = 'offline',
}

export enum DeviceName {
    SAMSUNG_TV = 'Samsung TV',
    LG_TV = 'LG TV',
    HISENSE_TV = 'Hisense TV',
    PHILIPS_TV = 'Philips TV',
    PC = 'PC',
}

export enum GeneralDeviceType {
    TV = 'SMART_TV',
    PC = 'PC',
}

/**
 * @description An enum for brand types
 * @enum
 * @author SmartTVBG@a1.bg
 * @date 12/10/2023
 * @memberof Utils
 */
export enum BrandType {
    SAMSUNG = 'Samsung',
    LG = 'Lg',
    HISENSE = 'Hisense',
    PHILIPS = 'Philips',
    GOOGLE = 'Google',
}

export type NetworkState = { transport: TransportType; ipAddress: string; ssid?: string };
