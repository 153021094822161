import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Prompt } from 'App/Modules/Prompt';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';
import { App } from 'App/Modules/App';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

const actionsIds = [Translation.Key.Yes, Translation.Key.No];

export const View: React.FunctionComponent<Prompt.Props<Prompt.Type.AppExitPrompt>> = () => {
    const dispatch = useDispatch();
    return (
        <UI.Alert
            zIndex={Prompt.zIndex}
            onClose={() => {
                dispatch(Prompt.actions.unmount());
            }}
        >
            <UI.Alert.Title>{Translation.Key.Prompt.AppExit.Title}</UI.Alert.Title>
            <UI.Alert.Text>{Translation.Key.Prompt.AppExit.Text}</UI.Alert.Text>
            <UI.Alert.Actions ids={actionsIds}>
                <UI.Alert.Action
                    id={Translation.Key.Yes}
                    onEnter={() => {
                        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.EXIT));
                        Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
                        PlayerAPI.stopPlayback();
                        App.close();
                    }}
                >
                    {translate(Translation.Key.Yes)}
                </UI.Alert.Action>
                <UI.Alert.Action id={Translation.Key.No}>{translate(Translation.Key.No)}</UI.Alert.Action>
            </UI.Alert.Actions>
        </UI.Alert>
    );
};
