import { selectIsNotificationsActive } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Alert } from 'App/Modules/Alert';
import { Keyboard } from 'App/Modules/Keyboard';
import { PromptEntity, PromptType } from 'App/Modules/Prompt/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';

const select = (state: State): PromptEntity | null => state.prompt;

const selectTyped =
    <T extends PromptType>(type: T) =>
    (state: State): PromptEntity<T> | null => {
        const prompt = select(state);
        if (!prompt) return null;
        if (prompt.type !== type) return null;
        return prompt as PromptEntity<T>;
    };

const selectType = (state: State): PromptType | null => select(state)?.type ?? null;

const selectIsLayerActive = (state: State): boolean =>
    !Keyboard.selectors.selectIsActive(state) && !Alert.selectors.selectIsActive(state) && !selectIsNotificationsActive(state);

const selectIsActive = (state: State): boolean => !!state.prompt && selectIsLayerActive(state);

const selectCtx = (state: State): string => select(state)?.ctx ?? FocusDefault;

export const selectors = {
    public: {
        select,
        selectCtx,
        selectType,
        selectTyped,
        selectIsActive,
        selectIsLayerActive,
    },
    private: {},
} as const;
