import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { IsRecordableFragmentDoc } from '../fragments/isRecordable.generated';
import { IsRecordedFragmentDoc } from '../fragments/isRecorded.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CanRecordEventQueryVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input'];
  profileId: Types.Scalars['ID']['input'];
}>;


export type CanRecordEventQuery = { __typename?: 'Nexx4Queries', event?: { __typename: 'Event', start: any, end: any, entitlements?: { __typename: 'EventEntitlements', networkRecording: boolean, networkRecordingPlannableUntil: any } | null, personalInfo?: { __typename?: 'PersonalEventInfo', recordings?: Array<{ __typename: 'NetworkRecording', id: string } | null> | null } | null } | null };


export const CanRecordEventDocument = gql`
    query canRecordEvent($eventId: ID!, $profileId: ID!) {
  event(id: $eventId) {
    ...isRecordable
    ...isRecorded
    __typename
  }
}
    ${IsRecordableFragmentDoc}
${IsRecordedFragmentDoc}`;

/**
 * __useCanRecordEventQuery__
 *
 * To run a query within a React component, call `useCanRecordEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanRecordEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanRecordEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useCanRecordEventQuery(baseOptions: Apollo.QueryHookOptions<CanRecordEventQuery, CanRecordEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanRecordEventQuery, CanRecordEventQueryVariables>(CanRecordEventDocument, options);
      }
export function useCanRecordEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanRecordEventQuery, CanRecordEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanRecordEventQuery, CanRecordEventQueryVariables>(CanRecordEventDocument, options);
        }
export function useCanRecordEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanRecordEventQuery, CanRecordEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanRecordEventQuery, CanRecordEventQueryVariables>(CanRecordEventDocument, options);
        }
export type CanRecordEventQueryHookResult = ReturnType<typeof useCanRecordEventQuery>;
export type CanRecordEventLazyQueryHookResult = ReturnType<typeof useCanRecordEventLazyQuery>;
export type CanRecordEventSuspenseQueryHookResult = ReturnType<typeof useCanRecordEventSuspenseQuery>;
export type CanRecordEventQueryResult = Apollo.QueryResult<CanRecordEventQuery, CanRecordEventQueryVariables>;