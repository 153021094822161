import { favouritesActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/actions';
import { getInitialFavouritesState } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/getInitialState';
import { favouritesReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/reducer';
import { favouritesSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/selectors';
import {
    FavouritesActionType,
    FavouritesEntity,
    FavouritesEntityTable,
    FavouritesReduxAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/types';

export { FavouritesActionType as ActionType };

export type { FavouritesEntity as Entity, FavouritesEntityTable as EntityTable, FavouritesReduxAction as ReduxAction };

export const getInitialState = getInitialFavouritesState;

export const actions = favouritesActions.public;

export const selectors = favouritesSelectors.public;

export const reducer = favouritesReducer;
