import { Screen } from 'App/Modules/Screen';
import { Search } from 'App/Modules/Screen/Modules/Search/Components';
import { Focus } from 'App/Packages/Focus';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import React from 'react';

export const SearchView: React.FunctionComponent<Screen.Props<Screen.Type.Search>> = (props) => {
    const dispatch = useDispatch();

    Focus.useBack(() => dispatch(History.actions.pop()));

    return (
        <Search>
            <Search.Field />
            <Search.Results {...props} />
        </Search>
    );
};
