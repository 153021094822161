import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelEntitlementsFragment = { __typename: 'ChannelEntitlements', id: string, liveTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, networkRecording: boolean, householdConfirmedReplayPermissions?: boolean | null };

export const ChannelEntitlementsFragmentDoc = gql`
    fragment channelEntitlements on ChannelEntitlements {
  id
  liveTV
  pauseLiveTV
  restartTV
  catchupTV
  networkRecording
  householdConfirmedReplayPermissions
  __typename
}
    `;