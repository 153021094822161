import React, { useState } from 'react';

import { List, ListItemType } from '__SMART_APP_OLD__/app/components/List';
import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/constants';
import { settingsScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/actions';
import { selectSettingsItemsIds } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/selectors';
import { SettingsScreenCtx } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsScreenCtx';
import { SettingsScreenViewItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsScreenViewItem';
import { SettingsScreenViewItemDescription } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsScreenViewItemDescription';
import { SettingsScreenOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';

import { Screen } from 'App/Modules/Screen';
import { Translation } from 'App/Modules/Translation';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { Axis } from 'App/Types/Axis';

type SettingsScreenViewModule = React.FunctionComponent<Screen.Props<Screen.Type.SETTINGS>>;

export const SettingsScreenView: SettingsScreenViewModule = (props) => {
    const { lastHoveredIndex = 0, lastFirstVisibleIndex = 0 } = props;

    const dispatch = useDispatch();

    const settingsItemIds = useSelector(selectSettingsItemsIds);

    const [firstVisibleIndex, setFirstVisibleIndex] = useState(lastFirstVisibleIndex ?? 0);

    const navigation = Focus.useList({
        ctx: SettingsScreenCtx.Options,
        axis: Axis.Y,
        items: settingsItemIds,
        initialFocusedIndex: lastHoveredIndex,
    });

    const onFocus = () => {
        setFirstVisibleIndex(
            Function.calculateNextFirstVisibleIndex(
                navigation.focusedIndex,
                firstVisibleIndex,
                CHANNEL_LISTS_MANAGE_VISIBLE_ITEMS,
                settingsItemIds.length
            )
        );
    };

    const renderItem = (optionId: string, index: number) => (
        <SettingsScreenViewItem
            key={optionId}
            optionId={optionId as SettingsScreenOptionId}
            index={index}
            isFocused={navigation.focused === optionId && !navigation.isDisabled}
            onHover={navigation.focus}
            firstVisibleIndex={firstVisibleIndex}
            onFocus={onFocus}
        />
    );

    Focus.useBack(() => dispatch(settingsScreenActions.private.back()));

    return (
        <Settings>
            <Settings.Header>
                <Settings.Header.Action />
                <Settings.Header.Title>{Translation.Key.Screen.Settings.Title}</Settings.Header.Title>
            </Settings.Header>
            <Settings.Section>
                <Settings.Section.Content className="settings-screen__list">
                    <List
                        firstVisibleIndex={firstVisibleIndex}
                        className={'settings-section-content__list'}
                        items={settingsItemIds}
                        itemType={ListItemType.SETTINGS_SCREEN_ITEM}
                        renderer={renderItem}
                    />
                </Settings.Section.Content>
            </Settings.Section>
            <Settings.Section>
                <Settings.Section.Content>
                    <SettingsScreenViewItemDescription optionId={navigation.focused as SettingsScreenOptionId} />
                </Settings.Section.Content>
            </Settings.Section>
        </Settings>
    );
};
