import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

const EmptyPage = () => (
    <div className="empty-page">
        <Text
            className="empty-page-text"
            typeface={Typeface.SANS}
            size={FontSize.TITLE_2}
            weight={FontWeight.REGULAR}
            color={Color.PRIMARY}
        >
            EMPTY_CONTENT_PAGES_GLOBAL
        </Text>
    </div>
);

export default EmptyPage;
