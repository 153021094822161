import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { VodAssetCollectionItemMetadataFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodAsset/vodAssetCollectionItemMetadata.generated';
import { VodAssetCollectionItemEntitlementsFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/modules/Data/modules/Collection/api/fragments/vodAsset/vodAssetCollectionItemEntitlements.generated';
import { ImageFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { ParentalRatingRelationFragmentDoc } from '../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRatingRelation.generated';
export type VodAssetFolderItemFragment = { __typename: 'VODAsset', id: string, title: string, duration: number, metadata: { __typename?: 'Metadata', year?: number | null, shortDescription?: string | null, seriesInfo?: { __typename?: 'SeriesInfo', id: string } | null, genre?: { __typename?: 'Genre', title: string } | null }, vodAssetEntitlements: { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null } | null> }, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export const VodAssetFolderItemFragmentDoc = gql`
    fragment vodAssetFolderItem on VODAsset {
  id
  title
  duration
  metadata {
    ...vodAssetCollectionItemMetadata
  }
  vodAssetEntitlements: entitlements {
    ...vodAssetCollectionItemEntitlements
  }
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  parentalRating {
    ...parentalRatingRelation
  }
  __typename
}
    ${VodAssetCollectionItemMetadataFragmentDoc}
${VodAssetCollectionItemEntitlementsFragmentDoc}
${ImageFragmentDoc}
${ParentalRatingRelationFragmentDoc}`;