import { selectConfig, selectPrivacyPolicyLastChanged } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Screen } from 'App/Modules/Screen';

const selectWelcomeStepCompleted = (state: State): number => state.fti.welcomeStepCompleted;
const selectClosingStepCompleted = (state: State): number => state.fti.closingStepCompleted;
const selectLanguageStepCompleted = (state: State): number => state.fti.languageStepCompleted;
const selectMasterPincodeStepCompleted = (state: State): number => state.fti.masterPincodeStepCompleted;
const selectCommunityStepCompleted = (state: State): number => state.fti.communityStepCompleted;
const selectReplayStepCompleted = (state: State): number => state.fti.replayStepCompleted;
const selectAgeRatingStepCompleted = (state: State): number => state.fti.ageRatingStepCompleted;
const selectPrivacyStepCompleted = (state: State): number => state.fti.privacyStepCompleted;
const selectStartupStepCompleted = (state: State): number => state.fti.startupStepCompleted;
const selectThemeStepCompleted = (state: State): number => state.fti.themeStepCompleted;
const selectEducationStepCompleted = (state: State): number => state.fti.educationStepCompleted;

const selectEducationStepContent = (state: State) =>
    selectConfig(state).fti.educationScreenContent.filter((content) => content.timestamp > selectEducationStepCompleted(state));

const selectIsLanguageStepCompleted = (state: State): boolean => !!selectLanguageStepCompleted(state);
const selectIsMasterPincodeStepCompleted = (state: State): boolean => !!selectMasterPincodeStepCompleted(state);
const selectIsCommunityStepCompleted = (state: State): boolean => !!selectCommunityStepCompleted(state);
const selectIsReplayStepCompleted = (state: State): boolean => !!selectReplayStepCompleted(state);
const selectIsAgeRatingStepCompleted = (state: State): boolean => !!selectAgeRatingStepCompleted(state);
const selectIsPrivacyStepCompleted = (state: State): boolean =>
    !!selectPrivacyStepCompleted(state) && selectPrivacyStepCompleted(state) > selectPrivacyPolicyLastChanged(state);
const selectIsStartupStepCompleted = (state: State): boolean => !!selectStartupStepCompleted(state);
const selectIsThemeStepCompleted = (state: State): boolean => !!selectThemeStepCompleted(state);
const selectIsEducationStepCompleted = (state: State): boolean => !selectEducationStepContent(state).length;

const selectIsEveryStepCompleted = (state: State): boolean =>
    selectIsLanguageStepCompleted(state) &&
    selectIsMasterPincodeStepCompleted(state) &&
    selectIsCommunityStepCompleted(state) &&
    selectIsReplayStepCompleted(state) &&
    selectIsAgeRatingStepCompleted(state) &&
    selectIsPrivacyStepCompleted(state) &&
    selectIsStartupStepCompleted(state) &&
    selectIsThemeStepCompleted(state) &&
    selectIsEducationStepCompleted(state);

const selectIsWelcomeStepCompleted = (state: State): boolean => selectIsEveryStepCompleted(state) || !!selectWelcomeStepCompleted(state);
const selectIsClosingStepCompleted = (state: State): boolean => selectIsEveryStepCompleted(state) || !!selectClosingStepCompleted(state);

const selectShouldShow = (screen: Screen.Type) => (state: State): boolean => {
    switch (screen) {
        case Screen.Type.WELCOME:
            return !selectIsWelcomeStepCompleted(state);
        case Screen.Type.CLOSING:
            return !selectIsClosingStepCompleted(state);
        case Screen.Type.STARTUP_BEHAVIOR:
            return !selectIsStartupStepCompleted(state);
        case Screen.Type.APPEARANCE:
            return !selectIsThemeStepCompleted(state);
        case Screen.Type.LANGUAGE_SELECTION:
            return !selectIsLanguageStepCompleted(state);
        case Screen.Type.COMMUNITY_SELECTION:
            return !selectIsCommunityStepCompleted(state);
        case Screen.Type.MASTER_PIN_CODE:
            return !selectIsMasterPincodeStepCompleted(state);
        case Screen.Type.AGE_RATING_SELECTION:
            return !selectIsAgeRatingStepCompleted(state);
        case Screen.Type.REPLAY_SELECTION:
            return !selectIsReplayStepCompleted(state);
        case Screen.Type.PRIVACY_POLICY:
            return !selectIsPrivacyStepCompleted(state);
        case Screen.Type.EDUCATION:
            return !selectIsEducationStepCompleted(state);
        default:
            return false;
    }
};

const select = (state: State) => selectConfig(state).fti.screens.find((screen) => selectShouldShow(screen)(state));

export const ftiSelectors = {
    private: {
        select,
        selectEducationStepContent,
        selectWelcomeStepCompleted,
        selectClosingStepCompleted,
        selectLanguageStepCompleted,
        selectMasterPincodeStepCompleted,
        selectCommunityStepCompleted,
        selectReplayStepCompleted,
        selectAgeRatingStepCompleted,
        selectPrivacyStepCompleted,
        selectStartupStepCompleted,
        selectThemeStepCompleted,
        selectEducationStepCompleted,
    },
};
