import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

import { Overlay } from 'App/Modules/Overlay';
import { Translation } from 'App/Modules/Translation';
import { UI } from 'App/Packages/UI';

export const View: React.FunctionComponent<Overlay.Props<Overlay.Type.Splash>> = () => (
    <Layout className="splash" backgroundColor={BackgroundColor.PRIMARY} zIndex={Overlay.zIndex}>
        <UI.Logo.XploreTV className="splash__logo" />
        <div className="splash__spinner">
            <UI.Spinner />
        </div>
        <Text className="splash__text" typeface={Typeface.SANS} size={FontSize.BODY_1} weight={FontWeight.REGULAR} color={Color.PRIMARY}>
            {Translation.Key.Overlay.Splash.Text}
        </Text>
    </Layout>
);
