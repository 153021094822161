import { Direction } from 'App/Packages/Direction';
import { Engine } from 'App/Packages/Focus/Engine';
import { WheelEvent as NavigationWheelEvent } from 'App/Packages/Focus/Events/WheelEvent';

export class WheelEngine extends Engine<NavigationWheelEvent> {
    private static readonly instance: WheelEngine = new WheelEngine();

    private constructor() {
        super();
        this.attach();
    }

    public static readonly getInstance = () => WheelEngine.instance;

    private readonly attach = () => window.addEventListener('wheel', this.emitter);

    private readonly emitter = (event: WheelEvent) => this.emit(new NavigationWheelEvent(...this.getDirection(event)));

    private readonly getDirection = (event: WheelEvent): Direction.Tuple => [this.getDelta(event.deltaX), this.getDelta(event.deltaY)];

    private readonly getDelta = (delta: number): Direction.X | Direction.Y => (!delta ? 0 : delta > 0 ? 1 : delta < 0 ? -1 : 0);
}
