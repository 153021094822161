import throttle from 'lodash.throttle';
import { useCallback, useRef } from 'react';

import { Env } from 'App/Env';
import { Delay } from 'App/Packages/Focus/Constants';
import { Function } from 'App/Packages/Function';
import { OnHover } from './Types';

const throttled = throttle((cb: OnHover) => cb(), Delay.Hover);

const useNoHover = () => Function.noop;

const useHoverInternal = (onHover: OnHover): OnHover => {
    const hoverHandler = useRef(onHover);
    hoverHandler.current = onHover;
    return useCallback(() => throttled(hoverHandler.current), []);
};

export const useHover = Env.IsMouseSupported ? useHoverInternal : useNoHover;
