import React from 'react';

import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';

import { Folder } from 'App/Packages/UI/Folder';
import { hidable } from 'App/Packages/UI/Hidable';

type DetailButtonFavoriteProps = {
    isFavourited: boolean;
    onEnter: () => void;
};

const Button: React.FunctionComponent<DetailButtonFavoriteProps> = (props) => {
    const { isFavourited } = props;

    const favouriteButtonText = isFavourited ? 'SCREEN_DETAIL_REMOVE_FROM_FAVORITES_BUTTON' : 'SCREEN_DETAIL_ADD_TO_FAVORITES_BUTTON';
    const icon = isFavourited ? IconName.FAVORITE_OLD : IconName.FAVORITE_EMPTY_OLD;

    return (
        <Folder.Detail.Action id="favorites" icon={icon} onEnter={props.onEnter}>
            {favouriteButtonText}
        </Folder.Detail.Action>
    );
};

export const DetailButtonFavorite = hidable(Button);

export type DetailButtonFavoriteModule = typeof DetailButtonFavorite;
