import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckForeignDeviceDeletionStatusQueryVariables = Types.Exact<{
  clientGeneratedDeviceId: Types.Scalars['String']['input'];
}>;


export type CheckForeignDeviceDeletionStatusQuery = { __typename?: 'Nexx4Queries', checkForeignDeviceDeletionStatus: { __typename?: 'ForeignDeviceDeletionStatusPayload', status: Types.ForeignDeviceDeletionStatus } };


export const CheckForeignDeviceDeletionStatusDocument = gql`
    query checkForeignDeviceDeletionStatus($clientGeneratedDeviceId: String!) {
  checkForeignDeviceDeletionStatus(
    clientGeneratedDeviceId: $clientGeneratedDeviceId
  ) {
    status
  }
}
    `;

/**
 * __useCheckForeignDeviceDeletionStatusQuery__
 *
 * To run a query within a React component, call `useCheckForeignDeviceDeletionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForeignDeviceDeletionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForeignDeviceDeletionStatusQuery({
 *   variables: {
 *      clientGeneratedDeviceId: // value for 'clientGeneratedDeviceId'
 *   },
 * });
 */
export function useCheckForeignDeviceDeletionStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>(CheckForeignDeviceDeletionStatusDocument, options);
      }
export function useCheckForeignDeviceDeletionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>(CheckForeignDeviceDeletionStatusDocument, options);
        }
export function useCheckForeignDeviceDeletionStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>(CheckForeignDeviceDeletionStatusDocument, options);
        }
export type CheckForeignDeviceDeletionStatusQueryHookResult = ReturnType<typeof useCheckForeignDeviceDeletionStatusQuery>;
export type CheckForeignDeviceDeletionStatusLazyQueryHookResult = ReturnType<typeof useCheckForeignDeviceDeletionStatusLazyQuery>;
export type CheckForeignDeviceDeletionStatusSuspenseQueryHookResult = ReturnType<typeof useCheckForeignDeviceDeletionStatusSuspenseQuery>;
export type CheckForeignDeviceDeletionStatusQueryResult = Apollo.QueryResult<CheckForeignDeviceDeletionStatusQuery, CheckForeignDeviceDeletionStatusQueryVariables>;