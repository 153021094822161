import React from 'react';

const getProgressBarStyle = (isLive) => (isLive ? 'progress' : 'catchup-progress');

const StaticProgressBar = ({ percentage, className = '', isLive = false }) => {
    const styles = getProgressBarStyle(isLive);

    return percentage && !Number.isNaN(percentage) ? (
        <div className={`static-progress-bar ${className || ''}`}>
            <div className={styles} style={{ width: `${percentage}%` }} />
        </div>
    ) : null;
};
export default StaticProgressBar;
