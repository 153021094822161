import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { Folder } from 'App/Modules/Folder/index';
import { UI } from 'App/Packages/UI';

export type FolderCardProps = {
    selector: Selector<Folder.Item | null>;
    onFocus?: (id: string) => void;
};

export const FolderCard: React.FunctionComponent<FolderCardProps> = (props) => {
    const item = useSelector(props.selector);

    if (!item) return null;

    return (
        <UI.Folder.Card.Folder
            id={item.id}
            parentalRating={item.metadata.parentalRatingId}
            sessionType={item.metadata.sessionType}
            onFocus={props.onFocus}
        >
            <UI.Folder.Card.Folder.Thumbnail src={item.content.thumbnail} />
            <UI.Folder.Card.Folder.Gradient />
            <UI.Folder.Card.Folder.Title>{item.content.title}</UI.Folder.Card.Folder.Title>
        </UI.Folder.Card.Folder>
    );
};
