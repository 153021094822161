import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum ChannelListsManageScreenActionType {
    CHANNEL_LISTS_MANAGE_SCREEN_ADDED_CHANNEL_IDS_CHANGED = '@channelListsManageScreen/channelIdChanged',
}

export type ChannelListsManageScreenAddedChannelIdsChangedActionPayload = { channelIds: string[] };
export type ChannelListsManageScreenAddedChannelIdsChangedAction = PayloadedAction<
    ChannelListsManageScreenActionType.CHANNEL_LISTS_MANAGE_SCREEN_ADDED_CHANNEL_IDS_CHANGED,
    ChannelListsManageScreenAddedChannelIdsChangedActionPayload
>;

export type ChannelListsManageScreenReduxAction = ChannelListsManageScreenAddedChannelIdsChangedAction;

export type ChannelListsManageScreenState = {
    addedChannelIds: string[];
};
