import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum TimeActionType {
    CHANGED = '@time/changed',
}

export type TimeReduxAction = TimeChangedAction;

export type TimeChangedActionPayload = { time: number };
export type TimeChangedAction = PayloadedAction<TimeActionType.CHANGED, TimeChangedActionPayload>;
