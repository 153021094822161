import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    CommunityChangeDocument,
    CommunityChangeMutationResult,
    CommunityChangeMutationVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Community/api/mutation/communityChange.generated';
import {
    CommunityLoadDocument,
    CommunityLoadFragment,
    CommunityLoadQueryResult,
    CommunityLoadQueryVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Community/api/query/communityLoad.generated';
import { CommunityEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadResponse = {
    selected: string;
    communities: CommunityEntity[];
};

export const __parseLoad = (response?: CommunityLoadFragment): LoadResponse => {
    const communities = (response?.availableCommunities?.items ?? [])
        .map<CommunityEntity | null>((obj) => {
            if (!obj || !obj.id || !obj.title) return null;
            return { id: obj.id, title: obj.title };
        })
        .filter((obj): obj is CommunityEntity => !!obj);

    return { selected: response?.me?.household?.community?.id ?? '', communities };
};

export const load = async (): Promise<LoadResponse> => {
    const variables: CommunityLoadQueryVariables = {};
    const options = { fetchPolicy: 'no-cache' };
    const response: CommunityLoadQueryResult = await GraphqlClient.makeGraphqlRequest(CommunityLoadDocument, variables, options);
    Error.collect(response);
    return __parseLoad(response.data);
};

export const change = async (communityId: string): Promise<void> => {
    const variables: CommunityChangeMutationVariables = { communityChangeInput: { communityId } };
    const response: CommunityChangeMutationResult = await GraphqlClient.makeGraphqlMutationRequest(CommunityChangeDocument, variables);
    Error.collect(response);
};
