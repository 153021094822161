import { HeaderActionType, HeaderFocusedIndexChangedAction } from '__SMART_APP_OLD__/app/modules/Header/types';

const focusedIndexChanged = (index: number): HeaderFocusedIndexChangedAction => ({
    type: HeaderActionType.FOCUSED_INDEX_CHANGED,
    payload: index,
});

const selectedIndexChanged = (index: number = 0) => ({
    type: HeaderActionType.SELECTED_INDEX_CHANGED,
    payload: index,
});

const addIndexToHistory = (index: number) => ({
    type: HeaderActionType.ADD_INDEX_TO_HISTORY,
    payload: index,
});

const removeIndexFromHistory = () => ({
    type: HeaderActionType.REMOVE_INDEX_FROM_HISTORY,
});

const clearIndexesHistory = () => ({
    type: HeaderActionType.CLEAR_INDEXES_HISTORY,
});

export const headerActions = {
    private: {},
    public: {
        focusedIndexChanged,
        selectedIndexChanged,
        addIndexToHistory,
        removeIndexFromHistory,
        clearIndexesHistory,
    },
};
