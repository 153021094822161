import { FavouriteItemInput } from '__SMART_APP_OLD__/api/graphql/types';
import { favouritesApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/api';
import { favouritesSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/selectors';
import {
    FavouritesActionType,
    FavouritesAddedAction,
    FavouritesEntity,
    FavouritesLoadedAction,
    FavouritesRemovedAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = (favourites: FavouritesEntity[]): FavouritesLoadedAction => ({
    type: FavouritesActionType.LOADED,
    payload: { favourites },
});

const added = (id: string): FavouritesAddedAction => ({
    type: FavouritesActionType.ADDED,
    payload: { id },
});

const removed = (id: string): FavouritesRemovedAction => ({
    type: FavouritesActionType.REMOVED,
    payload: { id },
});

const load = (): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        const response = await favouritesApi.load();
        dispatch(loaded(response.favourites));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.FAVOURITES_LOAD, error));
        return false;
    }
};

const add = (input: FavouriteItemInput): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        await favouritesApi.add({ input });
        dispatch(added(input.itemId));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.FAVOURITES_ADD, error));
        return false;
    }
};

const remove = (input: FavouriteItemInput): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        await favouritesApi.remove({ input });
        dispatch(removed(input.itemId));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.FAVOURITES_REMOVE, error));
        return false;
    }
};

const toggle = (input: FavouriteItemInput): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        if (favouritesSelectors.public.selectIsFavourited(input.itemId)(getState())) {
            dispatch(remove(input));
        } else {
            dispatch(add(input));
        }
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.FAVOURITES_TOGGLE, error));
        return false;
    }
};

export const favouritesActions = {
    public: {
        load,
        add,
        remove,
        toggle,
    },
    private: {
        loaded,
        added,
        removed,
    },
};
