import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageLoadTotalCountQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
}>;


export type MessageLoadTotalCountQuery = { __typename?: 'Nexx4Queries', messages: { __typename?: 'MessagesConnection', totalCount?: number | null } };

export type MessageLoadTotalCountFragment = { __typename?: 'Nexx4Queries', messages: { __typename?: 'MessagesConnection', totalCount?: number | null } };

export const MessageLoadTotalCountFragmentDoc = gql`
    fragment messageLoadTotalCount on Nexx4Queries {
  messages(profileId: $profileId, pagination: {}, filter: {}) {
    totalCount
  }
}
    `;
export const MessageLoadTotalCountDocument = gql`
    query messageLoadTotalCount($profileId: ID!) {
  ...messageLoadTotalCount
}
    ${MessageLoadTotalCountFragmentDoc}`;

/**
 * __useMessageLoadTotalCountQuery__
 *
 * To run a query within a React component, call `useMessageLoadTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageLoadTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageLoadTotalCountQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useMessageLoadTotalCountQuery(baseOptions: Apollo.QueryHookOptions<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>(MessageLoadTotalCountDocument, options);
      }
export function useMessageLoadTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>(MessageLoadTotalCountDocument, options);
        }
export function useMessageLoadTotalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>(MessageLoadTotalCountDocument, options);
        }
export type MessageLoadTotalCountQueryHookResult = ReturnType<typeof useMessageLoadTotalCountQuery>;
export type MessageLoadTotalCountLazyQueryHookResult = ReturnType<typeof useMessageLoadTotalCountLazyQuery>;
export type MessageLoadTotalCountSuspenseQueryHookResult = ReturnType<typeof useMessageLoadTotalCountSuspenseQuery>;
export type MessageLoadTotalCountQueryResult = Apollo.QueryResult<MessageLoadTotalCountQuery, MessageLoadTotalCountQueryVariables>;