import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { Folder } from 'App/Modules/Folder';
import { Api } from 'App/Modules/Data/Detail/Event/Api';
import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import { loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { initialEventDetailReducerState } from 'App/Modules/Data/Detail/Event/Store/initialState';
import {
    DetailEventLoadedAction,
    DetailEventLoadedActionPayload,
    DetailEventResetAction,
    EventActionTypes,
} from 'App/Modules/Data/Detail/Event/Types';
import { EventDetailLoadQueryVariables } from 'App/Modules/Data/Detail/Event/Api/eventDetailLoad.generated';

export const loaded = (payload: DetailEventLoadedActionPayload): DetailEventLoadedAction => ({
    type: EventActionTypes.Load,
    payload,
});

export const reset = (): DetailEventResetAction => ({
    type: EventActionTypes.Reset,
});

const load =
    (variables: EventDetailLoadQueryVariables): Thunk<Promise<void>> =>
    async (dispatch) => {
        const data = await Api.loadEvent(variables);

        if (!data || !data.event) return;

        const item = Mapper.toDetailItem(data.event);

        if (!item) return;

        const folders = data.event?.relatedContent ? Folder.Mapper.toFolderList(data.event?.relatedContent) : null;

        const assetObject = createAssetObject(data.event, data.event.__typename);

        if (assetObject.channelId) {
            await dispatch(
                loadEventsForChannel(assetObject.channelId, assetObject.startTime.getTime(), assetObject.endTime.getTime() + Time.MINUTE_MS)
            );
        }

        dispatch(loaded({ item, assetObject, folders: folders ?? initialEventDetailReducerState.folders }));
    };

export const actions = {
    public: {
        load,
        reset,
    },
    private: {},
} as const;
