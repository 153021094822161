import React from 'react';

import { Clock, ClockComponent, ClockProps } from 'App/Packages/UI/Clock';

const getClassName = (className?: string): string => {
    const classlist = ['header-v2__clock'];
    if (className) classlist.push(className);
    return classlist.join(' ');
};

export type HeaderClockProps = ClockProps;

export type HeaderClockComponent = ClockComponent;

export const HeaderClock: HeaderClockComponent = ({ className, ...rest }) => <Clock {...rest} className={getClassName(className)} />;
