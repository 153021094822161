import React from 'react';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';

import { Mask } from 'App/Packages/UI/Mask';
import { ContentMarkerType } from 'App/Types/ContentMarker';

const getClassName = (props: CardPortraitMarkerProps) => {
    const classlist = ['portrait-card-v3__marker'];
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

export type CardPortraitMarkerProps = {
    marker?: ContentMarkerType | null;
    label?: string;
    className?: string;
};

export type CardPortraitMarkerComponent = React.FunctionComponent<CardPortraitMarkerProps>;

export const CardPortraitMarker: CardPortraitMarkerComponent = (props) => (
    <Mask masked={null}>
        <ContentMarker className={getClassName(props)} label={props.label} marker={props.marker} />
    </Mask>
);
