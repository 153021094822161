import * as Types from '../../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type VodAssetCollectionItemMetadataFragment = { __typename?: 'Metadata', year?: number | null, shortDescription?: string | null, seriesInfo?: { __typename?: 'SeriesInfo', id: string } | null, genre?: { __typename?: 'Genre', title: string } | null };

export const VodAssetCollectionItemMetadataFragmentDoc = gql`
    fragment vodAssetCollectionItemMetadata on Metadata {
  year
  shortDescription
  seriesInfo {
    id
  }
  genre {
    title
  }
}
    `;