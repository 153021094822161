import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    ChangeProfilePreferencesDocument,
    ChangeProfilePreferencesMutationVariables,
} from '__SMART_APP_OLD__/api/graphql/mutation/changeProfilePreferences.generated';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { channelListChannelsLoad, channelListLoad } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/actions';
import { selectSelectedChannelListId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { eventInitialize } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/actions';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';
import {
    recordingManageFilterOptionSelected,
    recordingManageSortingOptionSelected,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { tvGuideDatePickerOptionSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import { Translation } from '__SMART_APP_OLD__/app/modules/Translation';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';
import Events, { LANGUAGE_CHANGED } from '__SMART_APP_OLD__/config/Events';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';

const changeLanguagePreference =
    (
        key: 'firstAudioLanguage' | 'secondAudioLanguage' | 'firstSubtitleLanguage' | 'secondSubtitleLanguage',
        code: string
    ): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const profileId = Profile.selectors.selectId(state);
        const variables: ChangeProfilePreferencesMutationVariables = { input: { profileId, [key]: code } };
        await GraphqlClient.makeGraphqlMutationRequest(ChangeProfilePreferencesDocument, variables);
        await dispatch(Profile.actions.reloadLoadProfileData);
    };

/**
 *
 * !!!IMPORTANT!!!
 *
 * DO NOT ADD SELECT OVERLAY TARGETS
 *
 * CREATE DIFFERENT OVERLAY TYPES INSTEAD
 *
 * EXAMPLE:
 *  - Overlay.Type.CommunitySelect
 *  - Overlay.Type.ThemeSelect
 *  - Overlay.Type.AdultContentSelect
 *  - Overlay.Type.StartUpSelect
 *  - Overlay.Type.MessageFilterSelect
 *  - Overlay.Type.LiveClockSelect
 *  - Overlay.Type.ChannelListSelect
 *
 * Implementaions of these types can be found in src/App/Modules/Overlay/Modules/**
 *
 */

const selectedActionForTarget: Record<Overlay.SelectTarget, (option: ButtonEntity) => Thunk<void | Promise<void>>> = {
    [Overlay.SelectTarget.RECORDING_FILTER]: (option) => (dispatch) => {
        dispatch(recordingManageFilterOptionSelected(option.id));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.RECORDING_SORT]: (option) => (dispatch) => {
        dispatch(recordingManageSortingOptionSelected(option.id));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.DATE_PICKER]: (option) => (dispatch) => {
        dispatch(tvGuideDatePickerOptionSelected(option));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.LANGUAGE]: (option) => async (dispatch, getState) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        const state = getState();
        const channelListId = selectSelectedChannelListId(state);
        await dispatch(Translation.actions.load(option.id));
        await dispatch(Language.actions.changeByCode(option.id));
        await dispatch(channelListLoad());
        await dispatch(channelListChannelsLoad(channelListId));
        dispatch(eventInitialize());
        Events.triggerEvents(LANGUAGE_CHANGED);
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.AUDIO_PRIMARY]: (option) => async (dispatch) => {
        await dispatch(changeLanguagePreference('firstAudioLanguage', option.id));
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.AUDIO_CHANGED));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.AUDIO_SECONDARY]: (option) => async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        await dispatch(changeLanguagePreference('secondAudioLanguage', option.id));
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.AUDIO_CHANGED));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.SUBTITLE_PRIMARY]: (option) => async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        await dispatch(changeLanguagePreference('firstSubtitleLanguage', option.id));
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.SUBTITLES_CHANGED));
        dispatch(Overlay.actions.unmount());
    },
    [Overlay.SelectTarget.SUBTITLE_SECONDARY]: (option) => async (dispatch) => {
        dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        await dispatch(changeLanguagePreference('secondSubtitleLanguage', option.id));
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.SUBTITLES_CHANGED));
        dispatch(Overlay.actions.unmount());
    },
};

const selected =
    (target: Overlay.SelectTarget, option: ButtonEntity): Thunk<void> =>
    (dispatch) => {
        dispatch(selectedActionForTarget[target](option));
    };

const defaultDismissed: Thunk<void> = (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

const dismissedActionForTarget: Record<Overlay.SelectTarget, Thunk<void | Promise<void>>> = {
    [Overlay.SelectTarget.RECORDING_FILTER]: defaultDismissed,
    [Overlay.SelectTarget.RECORDING_SORT]: defaultDismissed,
    [Overlay.SelectTarget.DATE_PICKER]: defaultDismissed,
    [Overlay.SelectTarget.LANGUAGE]: defaultDismissed,
    [Overlay.SelectTarget.AUDIO_PRIMARY]: defaultDismissed,
    [Overlay.SelectTarget.AUDIO_SECONDARY]: defaultDismissed,
    [Overlay.SelectTarget.SUBTITLE_PRIMARY]: defaultDismissed,
    [Overlay.SelectTarget.SUBTITLE_SECONDARY]: defaultDismissed,
};

const dismissed =
    (target: Overlay.SelectTarget): Thunk<void> =>
    (dispatch) =>
        dispatch(dismissedActionForTarget[target]);

const mountActionsForTarget: Partial<Record<Overlay.SelectTarget, Thunk<Promise<void>>>> = {
    [Overlay.SelectTarget.LANGUAGE]: async (dispatch) => {
        await dispatch(Language.actions.load());
    },
};

const mount =
    (props: Overlay.Props<Overlay.Type.SELECT>): Thunk<Promise<void>> =>
    async (dispatch) => {
        const action = mountActionsForTarget[props.target];
        if (!action) return;
        await dispatch(action);
    };

export const selectOverlayActions = {
    private: {
        selected,
        dismissed,
    },
    lifecycle: {
        mount,
    },
};
