import { ContentFolderKind, ContentItemType, RecordingStatus } from '__SMART_APP_OLD__/api/graphql/types';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import {
    selectParentalRatingIsAdult,
    selectParentalRatingIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { selectRecording, selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { Detail } from 'App/Modules/Data/Detail';

import { Folder, FolderItem, FolderList } from 'App/Modules/Folder/Types';
import { ContentMarkerType } from 'App/Types/ContentMarker';

// Folder
const selectFolderId =
    (selector: Selector<Folder | null>): Selector<string> =>
    (state) =>
        selector(state)?.id ?? '';

const selectFolderKind =
    (selector: Selector<Folder | null>): Selector<ContentFolderKind> =>
    (state) =>
        selector(state)?.type ?? ContentFolderKind.Favourites;

const selectFolderTitle =
    (selector: Selector<Folder | null>): Selector<string> =>
    (state) =>
        selector(state)?.title ?? '';

const selectFolderCount =
    (selector: Selector<Folder | null>): Selector<number> =>
    (state) =>
        selector(state)?.count ?? 0;

const selectFolderItemsIds =
    (selector: Selector<Folder | null>): Selector<string[]> =>
    (state) =>
        selector(state)?.ids ?? [];

const selectFolderItemsEntities =
    (selector: Selector<Folder | null>): Selector<Record<string, FolderItem>> =>
    (state) =>
        selector(state)?.entities ?? {};

const selectFolderItem =
    (selector: Selector<Folder | null>) =>
    (folderItemId: string): Selector<FolderItem | Detail.Item | null> =>
    (state) =>
        selector(state)?.entities?.[folderItemId] ?? null;

const selectFolderNeedMoreCard =
    (selector: Selector<Folder | null>): Selector<boolean> =>
    (state) => {
        const folder = selector(state);
        if (!folder) return false;
        return folder.ids.length < folder.count;
    };

// Folder List
const selectFolderListId =
    (selector: Selector<FolderList | null>): Selector<string> =>
    (state) =>
        selector(state)?.id ?? '';

const selectFolderListCount =
    (selector: Selector<FolderList | null>): Selector<number> =>
    (state) =>
        selector(state)?.count ?? 0;

const selectFolderListFoldersIds =
    (selector: Selector<FolderList | null>): Selector<string[]> =>
    (state) =>
        selector(state)?.ids ?? [];

const selectFolderListFoldersEntities =
    (selector: Selector<FolderList | null>): Selector<Record<string, Folder>> =>
    (state) =>
        selector(state)?.entities ?? {};

const selectFolderListFolder =
    (selector: Selector<FolderList | null>) =>
    (folderId: string): Selector<Folder | null> =>
    (state) =>
        selector(state)?.entities?.[folderId] ?? null;

// Folder Item
const selectFolderItemId =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.id ?? '';

const selectFolderItemType =
    (selector: Selector<FolderItem | null>): Selector<ContentItemType> =>
    (state) =>
        selector(state)?.type ?? ContentItemType.Event;

const selectFolderItemTitle =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.title ?? '';

const selectFolderItemDescription =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.description ?? '';

const selectFolderItemMeta =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.info ?? '';

const selectFolderItemStart =
    (selector: Selector<FolderItem | null>): Selector<number> =>
    (state) =>
        selector(state)?.metadata.start ?? 0;

const selectFolderItemEnd =
    (selector: Selector<FolderItem | null>): Selector<number> =>
    (state) =>
        selector(state)?.metadata.end ?? 0;

const selectFolderItemSessionType =
    (selector: Selector<FolderItem | null>): Selector<PinSessionType> =>
    (state) =>
        selector(state)?.metadata.sessionType ?? PinSessionType.PIN;

const selectFolderItemIsLive =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) => {
        const item = selector(state);
        if (!item) return false;
        const now = Time.selectors.select(state);
        if (!item.metadata.start || !item.metadata.end) return false;
        return item.metadata.start <= now && item.metadata.end >= now;
    };

const selectFolderItemProgressColor =
    (selector: Selector<FolderItem | null>): Selector<BackgroundColor> =>
    (state) => {
        const item = selector(state);
        if (!item) return BackgroundColor.HOT_RED_PRIMARY;
        const isLive = selectFolderItemIsLive(selector)(state);
        if (isLive) return BackgroundColor.COOL_BLUE_PRIMARY;
        return BackgroundColor.HOT_RED_PRIMARY;
    };

const selectFolderItemProgress =
    (selector: Selector<FolderItem | null>): Selector<number> =>
    (state) => {
        const item = selector(state);
        if (!item) return 0;
        const isLive = selectFolderItemIsLive(selector)(state);
        if (!isLive) {
            const bookmark = Bookmarks.selectors.selectEntity(item.id)(state);
            if (!bookmark) return 0;
            return bookmark.progress;
        }
        const now = Time.selectors.select(state);
        if (!item.metadata.start || !item.metadata.end) return 0;
        if (now < item.metadata.start) return 0;
        if (now > item.metadata.end) return 0;
        return ((now - item.metadata.start) / (item.metadata.end - item.metadata.start)) * 100;
    };

const selectFolderItemContentMarker =
    (selector: Selector<FolderItem | null>): Selector<ContentMarkerType | undefined> =>
    (state) => {
        const item = selector(state);
        if (!item) return undefined;
        const isLive = selectFolderItemIsLive(selector)(state);
        const now = Time.selectors.select(state);
        if (isLive) return ContentMarkerType.Live;
        if (item.type === ContentItemType.Networkrecording) return ContentMarkerType.Recorded;
        if (item.entitlements.playback && item.entitlements.playbackAvailableUntil > now) return ContentMarkerType.Rented;
        return undefined;
    };

const selectFolderItemAvailableUntil =
    (selector: Selector<FolderItem | null>): Selector<number | undefined> =>
    (state) => {
        const item = selector(state);
        if (!item) return undefined;
        if (item.type === ContentItemType.Networkrecording) return selectRecording(item.id)(state)?.availableUntil;
        if (item.type === ContentItemType.Vodasset) return item.entitlements.playbackAvailableUntil;
        return undefined;
    };

const selectFolderItemIcons =
    (selector: Selector<FolderItem | null>): Selector<IconName[]> =>
    (state) => {
        const item = selector(state);
        if (!item) return [];
        const icons = [];
        if (item.type === ContentItemType.Series) icons.push(IconName.FOLDER);
        if (item.type === ContentItemType.Vodseries) icons.push(IconName.FOLDER);
        if (selectCatchupTVIcon(selector)(state)) icons.push(IconName.CATCHUP);
        return icons;
    };

const selectFolderItemRecordingStatus =
    (selector: Selector<FolderItem | null>): Selector<RecordingStatus | null> =>
    (state) => {
        const item = selector(state);
        if (!item) return null;
        return selectRecordingStatus(item.id)(state);
    };

const selectCatchupTVIcon =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) => {
        const item = selector(state);
        if (!item) return false;
        if (selectFolderItemIsLive(selector)(state)) return false;
        const time = Time.selectors.select(state);
        return item.entitlements.catchupTV && item.entitlements.catchupTVAvailableUntil > time;
    };

const selectFolderItemThumbnail =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.thumbnail ?? '';

const selectFolderItemParentalRatingId =
    (selector: Selector<FolderItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.metadata.parentalRatingId ?? '';

const selectFolderItemIsRestricted =
    (selector: Selector<FolderItem | null>, sessionType: PinSessionType): Selector<boolean> =>
    (state) => {
        const itemType = selectFolderItemType(selector)(state);
        return selectParentalRatingIsRestricted(selectFolderItemParentalRatingId(selector)(state), sessionType, itemType)(state);
    };

const selectFolderItemShouldMask =
    (selector: Selector<FolderItem | null>, sessionType: PinSessionType): Selector<boolean> =>
    (state) =>
        selectParentalRatingIsRestricted(selectFolderItemParentalRatingId(selector)(state), sessionType)(state);

const selectFolderItemIsAdult =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selectParentalRatingIsAdult(selectFolderItemParentalRatingId(selector)(state))(state);

const selectFolderItemIsEligibleForPortraitCardVisualization =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selector(state)?.visualization.isEligibleForPortraitCard ?? false;

const selectFolderItemIsEligibleForLandscapeCardVisualization =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selector(state)?.visualization.isEligibleForLandscapeCard ?? false;

const selectFolderItemIsEligibleForChannelCardVisualization =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selector(state)?.visualization.isEligibleForChannelCard ?? false;

const selectFolderItemIsEligibleForFolderCardVisualization =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selector(state)?.visualization.isEligibleForFolderCard ?? false;

const selectFolderItemIsEligibleForCardVisualization =
    (selector: Selector<FolderItem | null>): Selector<boolean> =>
    (state) =>
        selectFolderItemIsEligibleForPortraitCardVisualization(selector)(state) ||
        selectFolderItemIsEligibleForLandscapeCardVisualization(selector)(state) ||
        selectFolderItemIsEligibleForChannelCardVisualization(selector)(state) ||
        selectFolderItemIsEligibleForFolderCardVisualization(selector)(state);

export const selectors = {
    selectId: selectFolderId,
    selectKind: selectFolderKind,
    selectTitle: selectFolderTitle,
    selectCount: selectFolderCount,
    selectNeedMoreCard: selectFolderNeedMoreCard,
    selectItemsIds: selectFolderItemsIds,
    selectItemsEntities: selectFolderItemsEntities,
    selectItem: selectFolderItem,

    list: {
        selectId: selectFolderListId,
        selectCount: selectFolderListCount,
        selectFoldersIds: selectFolderListFoldersIds,
        selectFoldersEntities: selectFolderListFoldersEntities,
        selectFolder: selectFolderListFolder,
    },

    item: {
        selectId: selectFolderItemId,
        selectType: selectFolderItemType,
        selectTitle: selectFolderItemTitle,
        selectThumbnail: selectFolderItemThumbnail,
        selectDescription: selectFolderItemDescription,
        selectMeta: selectFolderItemMeta,
        selectStart: selectFolderItemStart,
        selectEnd: selectFolderItemEnd,
        selectIsLive: selectFolderItemIsLive,
        selectSessionType: selectFolderItemSessionType,
        selectContentMarker: selectFolderItemContentMarker,
        selectAvailableUntil: selectFolderItemAvailableUntil,
        selectProgressColor: selectFolderItemProgressColor,
        selectProgress: selectFolderItemProgress,
        selectIcons: selectFolderItemIcons,
        selectRecordingStatus: selectFolderItemRecordingStatus,
        selectParentalRatingId: selectFolderItemParentalRatingId,
        selectIsRestricted: selectFolderItemIsRestricted,
        selectShouldMask: selectFolderItemShouldMask,
        selectIsAdult: selectFolderItemIsAdult,
        selectIsEligibleForPortraitCardVisualization: selectFolderItemIsEligibleForPortraitCardVisualization,
        selectIsEligibleForLandscapeCardVisualization: selectFolderItemIsEligibleForLandscapeCardVisualization,
        selectIsEligibleForChannelCardVisualization: selectFolderItemIsEligibleForChannelCardVisualization,
        selectIsEligibleForFolderCardVisualization: selectFolderItemIsEligibleForFolderCardVisualization,
        selectIsEligibleForCardVisualization: selectFolderItemIsEligibleForCardVisualization,
    },
};
