import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Registry } from 'App/Modules/Overlay/Registry';
import { generator } from 'App/Modules/Overlay/Store/generator';
import { selectors } from 'App/Modules/Overlay/Store/selectors';
import { OverlayActionType, OverlayChangedAction, OverlayEntity, OverlayProps, OverlayType } from 'App/Modules/Overlay/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants';

const changed = (overlay: OverlayEntity | null): OverlayChangedAction => ({
    type: OverlayActionType.Changed,
    payload: { overlay },
});

const update =
    <T extends OverlayType>(type: T, props: Partial<OverlayProps<T>> = {}, ctx?: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const overlay = selectors.public.selectTyped(type)(getState());
        if (!overlay) return;
        const newProps: OverlayProps<T> = { ...overlay.props, ...props } as unknown as OverlayProps<T>;
        dispatch(changed(generator(type, newProps, ctx ?? overlay.ctx)));
        await dispatch(Registry.get(type)[1].update(newProps, ctx ?? overlay.ctx));
    };

const unmount = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const overlay = selectors.public.select(getState());
    if (!overlay) return;
    await dispatch(Registry.get(overlay.type)[1].unmount(overlay.props, overlay.ctx));
    dispatch(changed(null));
};

const mount =
    <T extends OverlayType>(type: T, props: OverlayProps<T>, ctx: string = FocusDefault, noFocus: boolean = false): Thunk<Promise<void>> =>
    async (dispatch) => {
        await dispatch(unmount());
        dispatch(changed(generator(type, props, ctx, noFocus)));
        await dispatch(Registry.get(type)[1].mount(props, ctx));
    };

const mountNoFocus =
    <T extends OverlayType>(type: T, props: OverlayProps<T>, ctx: string = FocusDefault): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(mount(type, props, ctx, true));

const ctxChanged =
    (type: OverlayType, ctx: string): Thunk<Promise<void>> =>
    async (dispatch) =>
        dispatch(update(type, {}, ctx));

const setCtx =
    (ctx: string): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const overlay = selectors.public.select(getState());
        if (!overlay) return;
        if (overlay.ctx === ctx) return;
        dispatch(ctxChanged(overlay.type, ctx));
    };

export const actions = {
    public: {
        mount,
        unmount,
        update,
        ctx: {
            set: setCtx,
            changed: ctxChanged,
        },
        noFocus: {
            mount: mountNoFocus,
        },
    },
    private: {
        changed,
    },
} as const;
