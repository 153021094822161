import { Environment } from 'App/Types/Environment';
import { Opco } from 'App/Types/Opco';

const UserAgent = navigator?.userAgent?.toLowerCase?.() || 'n/a';

const IsTizen = UserAgent.includes('tizen');

const IsWebOS = (UserAgent.includes('web0s') || UserAgent.includes('webos')) && UserAgent.includes('smarttv');

const IsVidaa = UserAgent.includes('hisense') || UserAgent.includes('vidaa');
const IsTitan = UserAgent.includes('whaletv') || UserAgent.includes('philips') || UserAgent.includes('nettv');

const IsBrowser = !IsTizen && !IsWebOS && !IsVidaa && !IsTitan;

export const Env = {
    Opco: process.env.REACT_APP_OPCO,
    IsBulgaria: process.env.REACT_APP_OPCO === Opco.Bulgaria,
    IsAustria: process.env.REACT_APP_OPCO === Opco.Austria,
    IsCroatia: process.env.REACT_APP_OPCO === Opco.Croatia,
    IsMacedonia: process.env.REACT_APP_OPCO === Opco.Macedonia,
    IsSerbia: process.env.REACT_APP_OPCO === Opco.Serbia,
    IsSlovenia: process.env.REACT_APP_OPCO === Opco.Slovenia,
    isAdBlockingEnv: process.env.REACT_APP_OPCO === Opco.Slovenia || process.env.REACT_APP_OPCO === Opco.Austria,
    Environment: process.env.REACT_APP_SERVICE_SCOPE,
    IsDevelopment: process.env.REACT_APP_SERVICE_SCOPE === Environment.Development,
    IsProduction: process.env.REACT_APP_SERVICE_SCOPE === Environment.Production,
    IsIntegration: process.env.REACT_APP_SERVICE_SCOPE === Environment.Integration,
    isBusiness: process.env.REACT_APP_SERVICE_SCOPE === Environment.Business,
    IsLocalDevelopment: process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true',
    IsSentryActivated: process.env.REACT_APP_PRODUCTION_BUILD === 'true',
    Version: process.env.REACT_APP_VERSION,
    UserAgent,
    IsTizen,
    IsWebOS,
    IsVidaa,
    IsTitan,
    IsBrowser,
    IsMouseSupported: IsBrowser || IsWebOS,
    Conviva: {
        Key: process.env.REACT_APP_CONVIVA_KEY,
        TouchStone: {
            Key: process.env.REACT_APP_CONVIVA_TOUCHSTONE_KEY,
            Url: process.env.REACT_APP_TOUCHSTONE_URL,
        },
    } as const,
    CastLabs: {
        Key: process.env.REACT_APP_CASTLABS_KEY,
    } as const,
    NowTilus: {
        Key: process.env.REACT_APP_NOWTILUS_KEY,
    } as const,
} as const;
