import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/image.generated';
import { TrailersFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/api/graphql/query/fragments.generated';
import { ParentalRatingFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRating.generated';
import { GenreFragmentDoc } from '../../../../../../../../__SMART_APP_OLD__/app/api/fragments/genre.generated';
export type VodProductDetailItemFragment = { __typename: 'VODProduct', id: string, title: string, kind: Types.ProductKind, shortDescription: string, fullDescription: string, originalTitle?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, trailers: { __typename: 'TrailerCatalog', id: string, itemCount: number, items: Array<{ __typename: 'Trailer', id: string, expiry: any, title: string } | null> }, entitlement?: { __typename: 'ProductEntitlement', availableUntil?: any | null } | null, purchaseInfo?: { __typename: 'ProductPurchaseInfo', id: string, expiry: any, status: Types.ProductPurchaseStatus } | null, productParentalRating?: { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null, personalProductInfo?: { __typename: 'PersonalProductInfo', favourited: boolean, id: string, expiry: any } | null };

export const VodProductDetailItemFragmentDoc = gql`
    fragment vodProductDetailItem on VODProduct {
  id
  title
  kind
  shortDescription
  fullDescription
  thumbnail(height: $thumbnailHeight) {
    ...image
  }
  backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
    ...image
  }
  trailers {
    ...trailersFragment
  }
  entitlement {
    availableUntil
    __typename
  }
  purchaseInfo {
    ...purchaseInfoFragment
  }
  originalTitle
  productParentalRating: parentalRating {
    ...parentalRating
  }
  genre {
    ...genre
  }
  actors
  directors
  personalProductInfo: personalInfo(profileId: $profileId) {
    ...personalProductInfoFragment
    favourited
    __typename
  }
  __typename
}
    ${ImageFragmentDoc}
${TrailersFragmentFragmentDoc}
${PurchaseInfoFragmentFragmentDoc}
${ParentalRatingFragmentDoc}
${GenreFragmentDoc}
${PersonalProductInfoFragmentFragmentDoc}`;