import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { NavigationEventHandler } from 'App/Packages/Focus/Generator/Types';
import { useCtx } from 'App/Packages/Focus/Hooks/useCtx';
import { useHover } from 'App/Packages/Focus/Hooks/useHover';
import { useHandler } from 'App/Packages/Focus/Hooks/useNavigationHandler';
import { useOutOfBounds } from 'App/Packages/Focus/Hooks/useOutOfBounds';
import { useSetCtx } from 'App/Packages/Focus/Hooks/useSetCtx';
import { UseElementOptions, UseElementReturnType } from './Types';

export const useElement = (options: UseElementOptions): UseElementReturnType => {
    const dispatch = useDispatch();

    const ctx = useCtx();
    const setCtx = useSetCtx();
    const outOfBounds = useOutOfBounds();

    const isFocused = !options.disabled && ctx === options.ctx;

    const navigationHandler: NavigationEventHandler = (event) => {
        if (typeof options.interceptor === 'function') options.interceptor(event, ctx);
        if (typeof options.guard === 'function' && !options.guard(event, ctx)) return null;
        return dispatch(outOfBounds(event, ctx));
    };

    const onHover = useHover(() => dispatch(setCtx(options.ctx)));

    const isDisabled = useHandler(navigationHandler, options.disabled || !isFocused);

    return {
        isFocused,
        isDisabled,
        onHover,
    };
};
