import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum AlertActionType {
    Changed = '@alert/changed',
}

export type AlertReduxAction = AlertChangedAction;
export type AlertChangedActionPayload = { alert: AlertState };
export type AlertChangedAction = PayloadedAction<AlertActionType.Changed, AlertChangedActionPayload>;

export enum AlertType {
    Basic = 'BASIC',
    DeviceCannotDelete = 'DEVICE_CANNOT_DELETE',
    LoginError = 'LOGIN_ERROR',
    BackEndDoesntWork = 'BACK_END_DOESNT_WORK',
    InternetConnectionLost = 'INTERNET_CONNECTION_LOST',
}

export type AlertState = AlertEntity | null;

export type AlertEntity<T extends AlertType = AlertType> = {
    type: T;
    ctx: string;
    props: AlertProps<T>;
};

export type AlertProps<T extends AlertType = AlertType> = T extends AlertType.Basic
    ? BasicAlertProps
    : T extends AlertType.DeviceCannotDelete
      ? DeviceCannotDeleteAlertProps
      : T extends AlertType.LoginError
        ? LoginErrorAlertProps
        : T extends AlertType.BackEndDoesntWork
          ? BackEndDoesntWorkAlertProps
          : T extends AlertType.InternetConnectionLost
            ? InternetConnectionLostAlertProps
            : BasicAlertProps | DeviceCannotDeleteAlertProps | LoginErrorAlertProps;

type BasicAlertProps = {
    title: string;
    text: string;
};

type BackEndDoesntWorkAlertProps = {};

type DeviceCannotDeleteAlertProps = {
    deviceId: string;
};

type LoginErrorAlertProps = {
    title: string;
    text: string;
    image?: string;
};

type InternetConnectionLostAlertProps = {};
