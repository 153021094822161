import { SeriesState } from 'App/Modules/Data/Detail/Series/Types';

export const initialDetailSeriesState: SeriesState = {
    item: null,
    assetObject: null,
    screenBackgroundImageUrl: '',
    groupTitles: [],
    items: null,
    itemIdsForGroupTitle: [],
    selectedGroupTitle: '',
};
