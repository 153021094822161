import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchTrendingSuggestionLoadQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SearchTrendingSuggestionLoadQuery = { __typename?: 'Nexx4Queries', suggestion?: { __typename?: 'StringCatalog', items: Array<string | null> } | null };

export type SearchTrendingSuggestionLoadFragment = { __typename?: 'Nexx4Queries', suggestion?: { __typename?: 'StringCatalog', items: Array<string | null> } | null };

export const SearchTrendingSuggestionLoadFragmentDoc = gql`
    fragment searchTrendingSuggestionLoad on Nexx4Queries {
  suggestion: trendingSearches {
    items
  }
}
    `;
export const SearchTrendingSuggestionLoadDocument = gql`
    query searchTrendingSuggestionLoad {
  ...searchTrendingSuggestionLoad
}
    ${SearchTrendingSuggestionLoadFragmentDoc}`;

/**
 * __useSearchTrendingSuggestionLoadQuery__
 *
 * To run a query within a React component, call `useSearchTrendingSuggestionLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTrendingSuggestionLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTrendingSuggestionLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchTrendingSuggestionLoadQuery(baseOptions?: Apollo.QueryHookOptions<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>(SearchTrendingSuggestionLoadDocument, options);
      }
export function useSearchTrendingSuggestionLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>(SearchTrendingSuggestionLoadDocument, options);
        }
export function useSearchTrendingSuggestionLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>(SearchTrendingSuggestionLoadDocument, options);
        }
export type SearchTrendingSuggestionLoadQueryHookResult = ReturnType<typeof useSearchTrendingSuggestionLoadQuery>;
export type SearchTrendingSuggestionLoadLazyQueryHookResult = ReturnType<typeof useSearchTrendingSuggestionLoadLazyQuery>;
export type SearchTrendingSuggestionLoadSuspenseQueryHookResult = ReturnType<typeof useSearchTrendingSuggestionLoadSuspenseQuery>;
export type SearchTrendingSuggestionLoadQueryResult = Apollo.QueryResult<SearchTrendingSuggestionLoadQuery, SearchTrendingSuggestionLoadQueryVariables>;