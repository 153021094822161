import { initialStartupState } from '__SMART_APP_OLD__/app/modules/Startup/constants';
import { StartupActionType, StartupPosition } from '__SMART_APP_OLD__/app/modules/Startup/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const startupReducer: Reducer<StartupPosition> = (state, action) => {
    if (!state) return initialStartupState;

    switch (action.type) {
        case StartupActionType.Changed:
            return action.payload.position;
        default:
            return state;
    }
};
