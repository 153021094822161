import {
    KeyArrowLeft as Root,
    KeyArrowLeftComponent,
    KeyArrowLeftProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowLeft/KeyArrowLeft';
import {
    KeyArrowLeftIcon,
    KeyArrowLeftIconInsance,
    KeyArrowLeftIconComponent,
    KeyArrowLeftIconProps,
} from 'App/Packages/UI/Keyboard/Components/Key/ArrowLeft/KeyArrowLeftIcon';

export type { KeyArrowLeftComponent, KeyArrowLeftProps, KeyArrowLeftIconComponent, KeyArrowLeftIconProps };

const KeyArrowLeft = Object.assign(Root, {
    Icon: Object.assign(KeyArrowLeftIcon, {
        Instance: KeyArrowLeftIconInsance,
    }),
});

export { KeyArrowLeft };
