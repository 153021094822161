import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { CacheInfoFragmentFragmentDoc, PageInfoFragmentFragmentDoc, ChannelLogosFragmentFragmentDoc, PersonalChannelInfoFragmentFragmentDoc, ImageFragmentFragmentDoc, ParentalRatingFragmentFragmentDoc, TrailersFragmentFragmentDoc, VodAssetEntitlementCatalogFragmentFragmentDoc, VodAssetEntitlementFragmentFragmentDoc, ChannelEntitlementsFragmentFragmentDoc, EventEntitlementsFragmentFragmentDoc, PersonalEventInfoFragmentFragmentDoc, MetadataFragmentFragmentDoc, PersonalRecordingInfoFragmentFragmentDoc, PurchaseInfoFragmentFragmentDoc, PersonalProductInfoFragmentFragmentDoc, GenreFragmentFragmentDoc, VodProductFrogmentFragmentDoc, PersonalVodInfoFragmentFragmentDoc, PriceFragmentFragmentDoc, SeriesInfoFragmentFragmentDoc, EpisodeInfoFragmentFragmentDoc } from '../../../../api/graphql/query/fragments.generated';
import { MetadataFragmentDoc } from '../../fragments/metadata.generated';
import { VodEntitlementFragmentDoc } from '../../fragments/vodEntitlement.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VodAssetQueryVariables = Types.Exact<{
  vodAssetId: Types.Scalars['ID']['input'];
  thumbnailHeight: Types.Scalars['Int']['input'];
  backgroundWidth: Types.Scalars['Int']['input'];
  backgroundHeight: Types.Scalars['Int']['input'];
}>;


export type VodAssetQuery = { __typename?: 'Nexx4Queries', vodAsset?: { __typename: 'VODAsset', title: string, duration: number, id: string, expiry: any, thumbnail?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, backgroundImage?: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number } | null, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean }, metadata: { __typename: 'Metadata', id: string, title: string, originalTitle?: string | null, shortDescription?: string | null, fullDescription?: string | null, actors?: Array<string | null> | null, directors?: Array<string | null> | null, country?: string | null, year?: number | null, audioLanguages?: Array<string | null> | null, subtitleLanguages?: Array<string | null> | null, seriesInfo?: { __typename: 'SeriesInfo', id: string, title?: string | null } | null, episodeInfo?: { __typename: 'EpisodeInfo', id: string, number?: number | null, title?: string | null, season?: number | null } | null, genre?: { __typename?: 'Genre', id: string, title: string } | null }, entitlements: { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', videoQuality?: Types.VideoQuality | null, id: string } | null } | null> }, personalInfo?: { __typename?: 'PersonalVODInfo', favourited: boolean } | null } | null };


export const VodAssetDocument = gql`
    query vodAsset($vodAssetId: ID!, $thumbnailHeight: Int!, $backgroundWidth: Int!, $backgroundHeight: Int!) {
  vodAsset(id: $vodAssetId) {
    ...cacheInfoFragment
    title
    duration
    thumbnail(height: $thumbnailHeight) {
      ...imageFragment
    }
    backgroundImage(width: $backgroundWidth, height: $backgroundHeight) {
      ...imageFragment
    }
    parentalRating {
      ...parentalRatingFragment
    }
    metadata {
      ...metadata
    }
    entitlements {
      items {
        ...VodEntitlement
      }
      itemCount
    }
    personalInfo {
      favourited
    }
  }
}
    ${CacheInfoFragmentFragmentDoc}
${ImageFragmentFragmentDoc}
${ParentalRatingFragmentFragmentDoc}
${MetadataFragmentDoc}
${VodEntitlementFragmentDoc}`;

/**
 * __useVodAssetQuery__
 *
 * To run a query within a React component, call `useVodAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useVodAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVodAssetQuery({
 *   variables: {
 *      vodAssetId: // value for 'vodAssetId'
 *      thumbnailHeight: // value for 'thumbnailHeight'
 *      backgroundWidth: // value for 'backgroundWidth'
 *      backgroundHeight: // value for 'backgroundHeight'
 *   },
 * });
 */
export function useVodAssetQuery(baseOptions: Apollo.QueryHookOptions<VodAssetQuery, VodAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VodAssetQuery, VodAssetQueryVariables>(VodAssetDocument, options);
      }
export function useVodAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VodAssetQuery, VodAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VodAssetQuery, VodAssetQueryVariables>(VodAssetDocument, options);
        }
export function useVodAssetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VodAssetQuery, VodAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VodAssetQuery, VodAssetQueryVariables>(VodAssetDocument, options);
        }
export type VodAssetQueryHookResult = ReturnType<typeof useVodAssetQuery>;
export type VodAssetLazyQueryHookResult = ReturnType<typeof useVodAssetLazyQuery>;
export type VodAssetSuspenseQueryHookResult = ReturnType<typeof useVodAssetSuspenseQuery>;
export type VodAssetQueryResult = Apollo.QueryResult<VodAssetQuery, VodAssetQueryVariables>;