import React, { useContext } from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { ReactComponent as ImageLoadingSvg } from 'App/Packages/UI/Image/Assets/Loading.svg';
import { ImageContext, Status } from 'App/Packages/UI/Image/ImageContext';
import { ImageClassNameProps } from 'App/Packages/UI/Image/Utils';

export type ImageLoadingProps = ImageClassNameProps & {
    force?: boolean;
    hidden?: boolean;
};

export type ImageLoadingModule = {
    Svg: typeof ImageLoadingSvg;
} & React.FunctionComponent<ImageLoadingProps>;

const getClassName = (theme: Theme.Type, props: ImageLoadingProps) => {
    const classList = [];
    const base = 'image-v3__loading';
    classList.push(base);
    classList.push(Theme.getClassName(base, theme));
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

const ImageLoadingComponent: React.FunctionComponent<ImageClassNameProps> = (props) => (
    <div className={getClassName(useTheme(), props)}>
        <ImageLoadingSvg />
    </div>
);

export const ImageLoading: ImageLoadingModule = (props) => {
    const { status } = useContext(ImageContext);
    if (props.hidden) return null;
    if (props.force) return <ImageLoadingComponent {...props} />;
    if (status === Status.Loading) return <ImageLoadingComponent {...props} />;
    return null;
};

ImageLoading.Svg = ImageLoadingSvg;
