/* eslint-disable react-func/max-lines-per-function */
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import {
    SeriesDetailItemFocusChangedAction,
    SeriesDetailLoadedAction,
    SeriesDetailLoadedActionPayload,
    SeriesDetailResetAction,
    SeriesDetailFolderHeaderSelectedAction,
    SeriesActionTypes,
    SeriesDetailStateItemsEntities,
} from 'App/Modules/Data/Detail/Series/Types';
import { SeriesDetailLoadQueryVariables } from 'App/Modules/Data/Detail/Series/Api/seriesDetailLoad.generated';
import { Api } from 'App/Modules/Data/Detail/Series/Api';
import { Screen } from 'App/Modules/Screen';

export const seriesDetailLoaded = (payload: SeriesDetailLoadedActionPayload): SeriesDetailLoadedAction => ({
    type: SeriesActionTypes.Load,
    payload,
});

export const reset = (): SeriesDetailResetAction => ({
    type: SeriesActionTypes.Reset,
});

export const folderHeaderSelectedAction = (payload: string): SeriesDetailFolderHeaderSelectedAction => ({
    type: SeriesActionTypes.FolderHeaderSelected,
    payload,
});

export const focusedItemChangedAction = (payload: string): SeriesDetailItemFocusChangedAction => ({
    type: SeriesActionTypes.ItemFocusChanged,
    payload,
});

const load =
    (variables: SeriesDetailLoadQueryVariables): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const data = await Api.load(variables);

        if (!data || !data.series) return;

        const allItems = data.series.content.items?.edges;

        if (!allItems) return;

        const itemIdsForGroupTitle = data.series.groupingInfos?.map((group) => {
            if (!group) return null;
            const { title, startCursor, endCursor } = group;
            const start = allItems.findIndex((currentItem) => currentItem?.cursor === startCursor);
            const end = allItems.findIndex((currentItem) => currentItem?.cursor === endCursor);
            if (!allItems || start === undefined || end === undefined) return null;
            const itemIds = allItems
                .slice(start, end + 1)
                .map((currentItem) => currentItem?.node.id)
                .filter((id): id is string => !!id);
            return { title, itemIds };
        });

        const entities = allItems.reduce<SeriesDetailStateItemsEntities>((acc, currentItem) => {
            if (!currentItem) return acc;
            const detailItem = Mapper.toDetailItem(currentItem.node);
            if (!detailItem) return acc;
            return { ...acc, [detailItem.id]: detailItem };
        }, {});

        const assetObjects = allItems.reduce<SeriesDetailStateItemsEntities>((acc, currentItem) => {
            if (!currentItem) return acc;
            const assetObject = createAssetObject(currentItem.node, currentItem.node.__typename);
            if (!assetObject) return acc;
            return { ...acc, [assetObject.id]: assetObject };
        }, {});

        const items = {
            ids: allItems.map((item) => item?.node.id ?? ''),
            entities,
            assetObjects,
        };

        const groupTitles = data.series.groupingInfos?.map((group) => group?.title ?? '') ?? [];
        const screen = Screen.selectors.selectTyped(Screen.Type.SeriesDetail)(state);
        const selectedGroupTitle =
            itemIdsForGroupTitle?.find((group) => group?.itemIds.includes(screen?.props.initialFocusedItemId ?? ''))?.title ??
            groupTitles[0];
        const activeEpisode = data.series.activeEpisode?.edge.node;
        const item = screen?.props.initialFocusedItemId
            ? items.entities[screen.props.initialFocusedItemId]
            : activeEpisode
              ? Mapper.toDetailItem(activeEpisode)
              : null;

        if (!item) return;

        const assetObject = assetObjects[item.id];

        dispatch(
            seriesDetailLoaded({
                item,
                items,
                assetObject,
                groupTitles,
                itemIdsForGroupTitle,
                selectedGroupTitle,
                screenBackgroundImageUrl: data.series?.backgroundImage?.url ?? '',
            })
        );
    };

export const actions = {
    public: {
        load,
        reset,
        folderHeaderSelectedAction,
        focusedItemChangedAction,
    },
    private: {},
} as const;
