import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { convertToMilliseconds, convertToMinutes } from '__SMART_APP_OLD__/utils/timeUtils';

/**
 * @namespace Utils
 */

/**
 * @description A function that returns the EPG past limit
 * @returns past limit
 * @memberof Utils
 * @function getEPGPastLimit
 */
export const getEPGPastLimit = () => {
    const now = new Date();
    const config = selectConfig(store.getState());
    const pastLimit = new Date(now.getTime() - config.epg.visibility.past);
    pastLimit.setHours(0, 0, 0, 0);

    return pastLimit;
};

/**
 * @description A function that returns the future EPG limit
 * @returns future limit
 * @memberof Utils
 * @function getEPGFutureLimit
 */
export const getEPGFutureLimit = () => {
    const now = new Date();
    const config = selectConfig(store.getState());
    const futureLimit = new Date(now.getTime() + config.epg.visibility.future);
    futureLimit.setHours(23, 59, 59, 999);

    return futureLimit;
};

/**
 * @description А function that returns the Prime time date
 * @param date date
 * @returns date
 * @memberof Utils
 * @function getPrimeTimeDate
 */
export const getPrimeTimeDate = (date: Date) => {
    // TODO handle prime_time_dst
    const config = selectConfig(store.getState());

    // check if prime time is configured
    if (!config.epg.primeTime.time) return new Date(date.getTime());

    date.setHours(0, 0, 0, 0); // set hour to midnight of selected date

    // setup the time to current day's prime time in the current time zone
    const primeTimeInCurrentTimeZone: any = new Date(date.getTime() + config.epg.primeTime.time);

    // get the date of the prime time in the DST time zone from config like 04 Dec 2020 19:00 GMT+5
    const primeTimeInTimeZoneMillis = new Date(Date.parse(`${primeTimeInCurrentTimeZone} ${config.epg.primeTime.timezone}`)).getTime();

    // get the aligned prime time
    const alignedPrimeTime =
        config.epg.primeTime.time -
        convertToMilliseconds(Math.round(convertToMinutes({ milliseconds: primeTimeInCurrentTimeZone - primeTimeInTimeZoneMillis })), 'm');
    // Time.HOUR_MS is hack so in tv guide to look like it is centered on the prime time event
    // doing it here as it is a hack.
    return new Date(date.getTime() + Time.HOUR_MS + (alignedPrimeTime || 0));
};
