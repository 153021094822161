import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectSelectedChannelListChannelNumberTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { snackbarConfig } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { NumberKeys } from 'App/Modules/Key';

const SnackBar = ({ callback, isLight = false }) => {
    const numberBuffer = useRef([]);
    const lastKeyTime = useRef(null);
    const channelListChannelNumber = useSelector(selectSelectedChannelListChannelNumberTable);
    const channelNumberEntries = useMemo(() => Object.entries(channelListChannelNumber), [channelListChannelNumber]);
    const keyPressTimer = useRef(null);
    const { keyPressTimeout, numberKeysLimit } = snackbarConfig;
    const [snackBar, setSnackBar] = useState(null);

    const findChannelIdByNumber = useCallback(
        (number) => {
            if (!channelNumberEntries.length) return null;
            const [channelId] = channelNumberEntries.find((entry) => entry[1] === number) ?? [];
            if (channelId) return channelId;
            const [closestChannelId] = channelNumberEntries.reduce((acc, cur, idx) => {
                if (idx === 0) return cur;
                if (Math.abs(acc[1] - number) === Math.abs(cur[1] - number)) return cur[0] > acc[0] ? cur : acc;
                return Math.abs(acc[1] - number) < Math.abs(cur[1] - number) ? acc : cur;
            }, []);
            if (!closestChannelId) return null;
            return closestChannelId;
        },
        [channelNumberEntries]
    );

    const findChannelOrClosestChannel = useCallback(
        (channelNumber) => {
            if (!channelNumberEntries.length) return;
            const channelId = findChannelIdByNumber(channelNumber);
            if (!channelId) return;
            LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.ZAP, { trigger: `CH_${channelNumber}`, id: channelId }));
            callback(channelId);
            setSnackBar(null);
        },
        [callback, channelNumberEntries.length, findChannelIdByNumber]
    );

    const handleNumberBuffer = useCallback(() => {
        const number = numberBuffer.current.join('') || null;
        numberBuffer.current = [];
        if (number) {
            switch (number) {
                case '0':
                case '00':
                case '000':
                    // if favorites available -> open favorites (to be implemented with another US)
                    // otherwise go to first available channel
                    findChannelOrClosestChannel(0);
                    break;
                default:
                    findChannelOrClosestChannel(parseInt(number, 10));
                    break;
            }
        }
    }, [findChannelOrClosestChannel]);

    const handleNumberKey = useCallback(
        (event) => {
            const key = event.keyCode || event.which;

            if (NumberKeys.includes(key)) {
                const currentTime = Date.now();
                if (lastKeyTime.current && currentTime - lastKeyTime.current > keyPressTimeout) {
                    numberBuffer.current = [];
                }

                if (numberBuffer.current.length < numberKeysLimit) {
                    numberBuffer.current.push(NumberKeys.findIndex((numberKey) => numberKey === key));
                    setSnackBar(numberBuffer.current.join(''));
                    lastKeyTime.current = currentTime;

                    clearTimeout(keyPressTimer.current);
                    keyPressTimer.current = setTimeout(handleNumberBuffer, keyPressTimeout);
                }
            }
        },
        [handleNumberBuffer, keyPressTimeout, numberKeysLimit]
    );

    useEffect(() => {
        if (!channelNumberEntries.length) return undefined;

        document.body.addEventListener('keydown', handleNumberKey);
        return () => {
            document.body.removeEventListener('keydown', handleNumberKey);
        };
    }, [channelNumberEntries, handleNumberKey]);

    useEffect(
        () => () => {
            lastKeyTime.current = null;
            clearTimeout(keyPressTimer.current);
        },
        []
    );

    if (!snackBar) return null;

    const className = `snack-bar ${isLight ? 'light' : 'dark'} `;

    return (
        <Text
            className={className}
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
            theme={isLight ? Theme.Type.Light : Theme.Type.Dark}
        >
            {snackBar}
        </Text>
    );
};

export default SnackBar;
