import React from 'react';

import { Settings } from '__SMART_APP_OLD__/app/components/SettingsV2';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { detailsForOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/constants';
import { SettingsAboutAppDetails } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/SettingsAboutAppDetails';
import { SettingsMyRecordings } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsMyRecordings/SettingsMyRecordings';
import { SettingsScreenOptionId } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/types';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import translate from 'language/translate';
import { Env } from 'App/Env';

type Props = {
    optionId: SettingsScreenOptionId;
};

type SettingsScreenViewItemDescriptionModule = React.FunctionComponent<Props>;

export const SettingsLink: React.FunctionComponent<Props> = (props) => {
    const { optionId } = props;

    const { title, hint } = detailsForOptionId[optionId].text;

    return (
        <>
            <Text typeface={Typeface.SANS} size={FontSize.TITLE_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
                {title}
            </Text>
            {optionId === SettingsScreenOptionId.IMPRINT && Env.IsAustria ? (
                <Settings.Section.Content.Text>{<InnerHTML html={translate(hint!)} />}</Settings.Section.Content.Text>
            ) : (
                <Settings.Section.Content.Text>{hint}</Settings.Section.Content.Text>
            )}
            {optionId === SettingsScreenOptionId.NVPR && <SettingsMyRecordings />}
        </>
    );
};

export const SettingsScreenViewItemDescription: SettingsScreenViewItemDescriptionModule = (props) => {
    const { optionId } = props;

    if (optionId === SettingsScreenOptionId.ABOUT) {
        return <SettingsAboutAppDetails />;
    }

    return <SettingsLink optionId={optionId} />;
};
