import React from 'react';

import { useFastScroll } from '__SMART_APP_OLD__/components/EPG/hooks/useFastScroll';

import { Function } from 'App/Packages/Function';

interface Props {
    onChangeEvent: (direction: 1 | -1) => void;
}

export const ProgramsMagicModeScroll: React.FunctionComponent<Props> = (props) => {
    const { onChangeEvent } = props;
    const [magicModeFastScrollStartHandler, magicModeFastScrollEndHandler] = useFastScroll(Function.noop, onChangeEvent);

    return (
        <>
            <div
                className="mini-epg-navigation-up"
                onMouseOver={() => magicModeFastScrollStartHandler(-1)}
                onMouseLeave={magicModeFastScrollEndHandler}
            />
            <div
                className="mini-epg-navigation-down"
                onMouseOver={() => magicModeFastScrollStartHandler(1)}
                onMouseLeave={magicModeFastScrollEndHandler}
            />
        </>
    );
};
