import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchSuggestionLoadQueryVariables = Types.Exact<{
  searchSuggestionInput: Types.Scalars['String']['input'];
  searchSuggestionfilter: Types.SearchFilter;
}>;


export type SearchSuggestionLoadQuery = { __typename?: 'Nexx4Queries', suggestion?: { __typename?: 'KeywordCatalog', items: Array<string | null> } | null };

export type SearchSuggestionLoadFragment = { __typename?: 'Nexx4Queries', suggestion?: { __typename?: 'KeywordCatalog', items: Array<string | null> } | null };

export const SearchSuggestionLoadFragmentDoc = gql`
    fragment searchSuggestionLoad on Nexx4Queries {
  suggestion: searchKeywords(
    input: $searchSuggestionInput
    filter: $searchSuggestionfilter
  ) {
    items
  }
}
    `;
export const SearchSuggestionLoadDocument = gql`
    query searchSuggestionLoad($searchSuggestionInput: String!, $searchSuggestionfilter: SearchFilter!) {
  ...searchSuggestionLoad
}
    ${SearchSuggestionLoadFragmentDoc}`;

/**
 * __useSearchSuggestionLoadQuery__
 *
 * To run a query within a React component, call `useSearchSuggestionLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestionLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestionLoadQuery({
 *   variables: {
 *      searchSuggestionInput: // value for 'searchSuggestionInput'
 *      searchSuggestionfilter: // value for 'searchSuggestionfilter'
 *   },
 * });
 */
export function useSearchSuggestionLoadQuery(baseOptions: Apollo.QueryHookOptions<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>(SearchSuggestionLoadDocument, options);
      }
export function useSearchSuggestionLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>(SearchSuggestionLoadDocument, options);
        }
export function useSearchSuggestionLoadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>(SearchSuggestionLoadDocument, options);
        }
export type SearchSuggestionLoadQueryHookResult = ReturnType<typeof useSearchSuggestionLoadQuery>;
export type SearchSuggestionLoadLazyQueryHookResult = ReturnType<typeof useSearchSuggestionLoadLazyQuery>;
export type SearchSuggestionLoadSuspenseQueryHookResult = ReturnType<typeof useSearchSuggestionLoadSuspenseQuery>;
export type SearchSuggestionLoadQueryResult = Apollo.QueryResult<SearchSuggestionLoadQuery, SearchSuggestionLoadQueryVariables>;