import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { PersonalEventInfo, PersonalRecordingInfo, PersonalVodInfo } from '__SMART_APP_OLD__/api/graphql/types';
import { BookmarkFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/fragments/bookmark.generated';
import { SetEventBookmarkDocument } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/mutation/setEventBookmark.generated';
import { SetRecordingBookmarkDocument } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/mutation/setRecordingBookmark.generated';
import { SetVodBookmarkDocument } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/mutation/setVODBookmark.generated';
import {
    BookmarksLoadDocument,
    BookmarksLoadFragment,
    BookmarksLoadQueryResult,
    BookmarksLoadQueryVariables,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/query/bookmarksLoad.generated';
import { BookmarksEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';
import { Error as ErrorModule } from '__SMART_APP_OLD__/app/modules/Error';

export type LoadResponse = {
    bookmarks: BookmarksEntity[];
};

type SaveBasePayload = { profileId: string; position: number };
type SaveEventPayload = SaveBasePayload & { eventId: string };
type SaveRecordingPayload = SaveBasePayload & { recordingId: string };
type SaveVodPayload = SaveBasePayload & { vodAssetId: string };
export type SavePayload = SaveEventPayload | SaveRecordingPayload | SaveVodPayload;

type SaveResponse = {
    bookmark: BookmarksEntity;
};

const __parseBookmark = (bookmark: BookmarkFragment): BookmarksEntity => {
    const { audio, subtitle } = bookmark;
    const id = bookmark.item?.id ?? '';
    const position = bookmark.position ?? 0;
    const duration = getDuration(bookmark.item);
    const progress = (position / duration) * 100;
    return {
        id,
        position,
        duration,
        progress,
        audio,
        subtitle,
    };
};

const getDuration = (item: BookmarkFragment['item']): number => {
    if (!item) return 0;
    if (item.__typename === 'VODAsset') return item.duration;
    if (item.__typename === 'Event') return (new Date(item.end).getTime() - new Date(item.start).getTime()) / 1000;
    if (item.__typename === 'NetworkRecording') return (new Date(item.end).getTime() - new Date(item.start).getTime()) / 1000;
    return 0;
};

export const __parseLoad = (response?: BookmarksLoadFragment): LoadResponse => {
    const bookmarks = (response?.bookmarks?.items ?? [])
        .map<BookmarksEntity | null>((obj) => {
            if (!obj || !obj.item || !obj.position) return null;
            return __parseBookmark(obj);
        })
        .filter((obj): obj is BookmarksEntity => !!obj);

    return { bookmarks };
};

const __parseSaveEvent = (event: PersonalEventInfo): SaveResponse => ({
    bookmark: __parseBookmark(event.bookmark!),
});

const __parseSaveRecording = (recording: PersonalRecordingInfo): SaveResponse => ({
    bookmark: __parseBookmark(recording.bookmark!),
});

const __parseSaveVod = (vodAsset: PersonalVodInfo): SaveResponse => ({
    bookmark: __parseBookmark(vodAsset.bookmark!),
});

export const load = async (): Promise<LoadResponse> => {
    const variables: BookmarksLoadQueryVariables = {};
    const options = { fetchPolicy: 'no-cache' };
    const response: BookmarksLoadQueryResult = await GraphqlClient.makeGraphqlRequest(BookmarksLoadDocument, variables, options);
    ErrorModule.collect(response);
    return __parseLoad(response.data);
};

const saveEvent = async (payload: SaveEventPayload): Promise<SaveResponse> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(SetEventBookmarkDocument, {
        input: {
            position: payload.position,
            eventId: payload.eventId,
        },
        profileId: payload.profileId,
    });
    const event = response.data?.setEventBookmark.event.personalInfo;
    ErrorModule.collect(response);
    return __parseSaveEvent(event);
};

const saveRecording = async (payload: SaveRecordingPayload): Promise<SaveResponse> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(SetRecordingBookmarkDocument, {
        input: {
            position: payload.position,
            recordingId: payload.recordingId,
        },
        profileId: payload.profileId,
    });
    const recording = response.data?.setRecordingBookmark.recording.personalInfo;
    ErrorModule.collect(response);
    return __parseSaveRecording(recording);
};

const saveVod = async (payload: SaveVodPayload): Promise<SaveResponse> => {
    const response = await GraphqlClient.makeGraphqlMutationRequest(SetVodBookmarkDocument, {
        input: {
            position: payload.position,
            vodAssetId: payload.vodAssetId,
        },
        profileId: payload.profileId,
    });
    const vodAsset = response.data?.setVODBookmark.vodAsset.personalInfo;
    ErrorModule.collect(response);
    return __parseSaveVod(vodAsset);
};

export const save = (payload: SavePayload): Promise<SaveResponse> => {
    if ('eventId' in payload) return saveEvent(payload);
    if ('recordingId' in payload) return saveRecording(payload);
    if ('vodAssetId' in payload) return saveVod(payload);
    throw new Error('Invalid Bookmark Action Type');
};
