import { useEffect } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectIsNotificationsActive } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import Focus from '__SMART_APP_OLD__/navigation/Focus';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { Screen } from 'App/Modules/Screen';
import { Keyboard } from 'App/Modules/Keyboard';
import { Alert } from 'App/Modules/Alert';

const useDisableFocusInternal = (isDisabled: boolean = false) => {
    useEffect(() => {
        if (isDisabled) return undefined;
        const handler = () => true;
        Focus.addEventListener('back', handler);
        Focus.addEventListener('onkey', handler);
        const { focused } = Focus;
        Focus.blur();
        return () => {
            Focus.removeEventListener('back', handler);
            Focus.removeEventListener('onkey', handler);
            if (Focus.focused) return;
            if (!focused) return;
            Focus.focus(focused);
        };
    }, [isDisabled]);
};

const useDisableFocusNotification = () => {
    useDisableFocusInternal(!useSelector(selectIsNotificationsActive));
};

const useDisableFocusOverlay = () => {
    const type = useSelector(Overlay.selectors.selectType);
    const isActive = useSelector(Overlay.selectors.selectIsActive);
    const isPin = type === Overlay.Type.PIN;
    const isDisabled = !isActive || (isActive && isPin);
    useDisableFocusInternal(isDisabled);
};

const useDisableFocusPrompt = () => {
    useDisableFocusInternal(!useSelector(Prompt.selectors.selectIsActive));
};

const useDisableFocusAlert = () => {
    useDisableFocusInternal(!useSelector(Alert.selectors.selectIsActive));
};

const useDisableFocusKeyboard = () => {
    const isActive = useSelector(Keyboard.selectors.selectIsActive);
    useDisableFocusInternal(!isActive);
};

const useDisableFocusScreen = () => {
    const type = useSelector(Screen.selectors.selectType);
    const isActive = useSelector(Screen.selectors.selectIsActive);
    const isPin = type === Screen.Type.MASTER_PIN_CODE;
    const isDisabled = !isActive || (isActive && isPin);
    useDisableFocusInternal(isDisabled);
};

export const useDisableFocus = () => {
    useDisableFocusKeyboard();
    useDisableFocusNotification();
    useDisableFocusPrompt();
    useDisableFocusAlert();
    useDisableFocusOverlay();
    useDisableFocusScreen();
};
