import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import {
    SettingsMyRecordingsNoData
} from "__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsMyRecordings/SettingsMyRecordingsNoData";
import { RecordingQuota } from '__SMART_APP_OLD__/components/common/RecordingQuota';
import { getRecordingQuotaData } from '__SMART_APP_OLD__/utils/dataUtils';

export const SettingsMyRecordings: React.FunctionComponent = () => {
    const recordingQuota = useSelector(Household.selectors.selectRecordingQuota);
    const { calculated } = getRecordingQuotaData(recordingQuota);

    return (
        <div>
            {calculated ? <RecordingQuota /> : <SettingsMyRecordingsNoData />}
        </div>
    );
};
