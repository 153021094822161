import * as Types from '../../../api/graphql/types';

import { gql } from '@apollo/client';
export type VodEntitlementFragment = { __typename?: 'VODAssetEntitlement', id: string, playback: boolean, temporary: boolean, playbackAvailableUntil?: any | null, product?: { __typename?: 'VODProduct', videoQuality?: Types.VideoQuality | null, id: string } | null };

export const VodEntitlementFragmentDoc = gql`
    fragment VodEntitlement on VODAssetEntitlement {
  id
  playback
  temporary
  playbackAvailableUntil
  product {
    id
    ... on VODProduct {
      videoQuality
    }
  }
}
    `;