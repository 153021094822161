import { Screen } from 'App/Modules/Screen';
import { Login } from 'App/Modules/Screen/Modules/Login';

export class Croatia {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Opco.Croatia.Initialize]');

        Screen.register(Screen.Type.LOGIN, Login.View, Login.lifecycle);
        return true;
    }
}
