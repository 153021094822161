import React from 'react';

import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectEventIsLoading, selectEventIsNoInfo } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { PlaceHolderProgramCard } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramCard/components/PlaceHolderProgramCard';
import { ProgramCardBody } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramCard/components/ProgramCardBody';

import { ContentMarkerType } from 'App/Types/ContentMarker';

interface Props {
    eventId: GQL.EventID;
    channelId: string;
    className?: string;
    contentMarker?: ContentMarkerType;
}

export const ProgramCard: React.FunctionComponent<Props> = React.memo((props) => {
    const isLoading = useSelector(selectEventIsLoading(props.eventId));
    const isNoInfo = useSelector(selectEventIsNoInfo(props.eventId));
    if (isLoading || isNoInfo) {
        return (
            <PlaceHolderProgramCard
                eventId={props.eventId}
                contentMarker={props.contentMarker}
                className={props.className}
                channelId={props.channelId}
            />
        );
    }

    return (
        <ProgramCardBody
            channelId={props.channelId}
            eventId={props.eventId}
            contentMarker={props.contentMarker}
            className={props.className}
        />
    );
});
