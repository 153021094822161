import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import { BrandType, DeviceName, GeneralDeviceType, PlatformInterface } from '__SMART_APP_OLD__/app/platform/types';
import { generateUniqueId } from '__SMART_APP_OLD__/utils/generateId';

type VidaaSystemPropertyMethod = 'getDeviceID' | 'getBrand' | 'getFirmWareVersion' | 'getModelName' | 'getOSVersion';

const getSystemProperty = (method: VidaaSystemPropertyMethod, fallback: string = 'n/a'): string => {
    try {
        const { vidaatv } = window;
        return vidaatv[method]();
    } catch {
        return fallback;
    }
};

const getID = () => {
    const id = Storage.get(Storage.Key.DEVICE_ID);
    if (id) return id;
    return getSystemProperty('getDeviceID', generateUniqueId());
};

export const initializeHisense = async (platform: PlatformInterface): Promise<void> => {
    const hisenseScript = Common.loadDeviceSDK('https://tvmodules-vidaa.vidaahub.com/deviceapi/vidaatv.js');
    await hisenseScript;
    platform.DEVICE_TYPE = GQL.DeviceType.SmartTvVidaaos;
    platform.GENERAL_DEVICE_TYPE = GeneralDeviceType.TV;
    platform.DEVICE_NAME = DeviceName.HISENSE_TV;
    platform.OS = getSystemProperty('getOSVersion');
    platform.MODEL = getSystemProperty('getModelName');
    platform.MANUFACTURER = getSystemProperty('getBrand');
    platform.OS_VERSION = getSystemProperty('getFirmWareVersion');
    platform.BRAND = BrandType.HISENSE;
    platform.ID = getID();
    Common.attachNetworkStateListener(platform);
    return Promise.resolve();
};
