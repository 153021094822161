import { AdsScippingEngine } from 'App/Modules/AdsScipping';
import Player from '__SMART_APP_OLD__/platforms/Player';

export class CoreAdsScippingEngine extends AdsScippingEngine.Engine {
    seekOverlappingAd = (playedTime: number, seekedTime: number, isLive: boolean) => {
        const overlappedAdEvent = Player.adCues
            .sort((a, b) => a.startTime - b.startTime)
            .find((adEvent) => {
                const { startTime, endTime, watched } = adEvent;
                const addStart = isLive ? startTime * 1000 : startTime;
                return (playedTime >= addStart || seekedTime >= addStart || (seekedTime <= endTime && playedTime >= addStart)) && !watched;
            });
        if (overlappedAdEvent) {
            return overlappedAdEvent.startTime;
        }
        return seekedTime;
    };

    getUnwatchedAdEvent = (playedTime: number, isLive: boolean) => {
        const ad = Player.adCues
            .sort((a, b) => a.startTime - b.startTime)
            .find((adEvent) => {
                const { startTime, watched } = adEvent;
                const startAdTime = isLive ? startTime * 1000 : startTime;

                return playedTime >= startAdTime && !watched;
            });
        return ad;
    };

    getUnwatchedAdEventInDynamicContext = (isRestarted: boolean, eventType: string, positionToCheck: number): any => {
        const adEvent = Player.adCues
            .sort((a, b) => a.startTime - b.startTime)
            .find((ad) => {
                const start = !isRestarted ? (ad?.startTime ?? 0) * 1000 : ad?.startTime;
                const end = !isRestarted ? (ad?.endTime ?? 0) * 1000 : ad?.endTime;
                return (positionToCheck <= start || (positionToCheck >= start && positionToCheck <= end)) && !ad.watched;
            });
        const adStarted = !isRestarted ? (adEvent?.startTime ?? 0) * 1000 : adEvent?.startTime;
        const andInTimeline = adStarted <= positionToCheck;
        const shouldBlock = andInTimeline && typeof adEvent === 'object';
        return { adEvent, shouldBlock };
    };
}
