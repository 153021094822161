import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';

export type CollectionV2HeaderTitleProps = {};

export type CollectionV2HeaderTitleModule = React.FunctionComponent<React.PropsWithChildren<CollectionV2HeaderTitleProps>>;

export const CollectionV2HeaderTitle: CollectionV2HeaderTitleModule = (props) => (
    <Text typeface={Typeface.SANS} size={FontSize.TITLE_2} weight={FontWeight.BOLD} color={Color.PRIMARY}>
        {props.children}
    </Text>
);
