import { Variant } from '__SMART_APP_OLD__/app/components/Image';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { selectChannelLiveId } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { defaultSelectEventOpts } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/constants';
import { EqualityCompare, EventEntity, SelectEventOpts } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { getEventFullDescription, isCatchupTV, isEventInRange } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import {
    selectParentalRatingId,
    selectParentalRatingIsRestricted,
} from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

export const selectEPGFutureLimit = (state: State): number => {
    const time = Time.selectors.select(state);
    const config = selectConfig(state);
    return new Date(time + config.epg.visibility.future + Time.DAY_MS).setHours(0, 0, 0, 0);
};

export const selectEPGPastLimit = (state: State): number => {
    const time = Time.selectors.select(state);
    const config = selectConfig(state);
    return new Date(time - config.epg.visibility.past).setHours(0, 0, 0, 0);
};

export const selectEPGLimited =
    (start: number, end: number) =>
    (state: State): [number, number] => [Math.max(selectEPGPastLimit(state), start), Math.min(selectEPGFutureLimit(state), end)];

export const selectChannelEventIds = (channelId: GQL.ChannelID) => (state: State) =>
    state.data.eventEntityTable.channelEventRelationOrderTable[channelId] ?? [];

export const selectEvent =
    (id: GQL.EventID) =>
    (state: State): EventEntity | null =>
        state.data.eventEntityTable.entities[id] ?? null;

export const selectEventIsFuture = (id: GQL.EventID) => (state: State) => selectEventStart(id)(state) > Time.selectors.select(state);

export const selectEventBy =
    (channelId: GQL.ChannelID, time: number, opts: SelectEventOpts = defaultSelectEventOpts) =>
    (state: State): EventEntity | null => {
        const eventIds = selectChannelEventIds(channelId)(state);
        if (!eventIds.length) return null;
        const equal = opts.equal ?? EqualityCompare.BOTH;
        const eventId = eventIds.find((id) => isEventInRange(selectEvent(id)(state), time, equal, !!opts.noLoading));
        if (!eventId) return null;
        return selectEvent(eventId)(state);
    };

export const selectHasLoadingEvents = (channelId: GQL.ChannelID, startTime: number, endTime: number) => (state: State) => {
    const [start, end] = selectEPGLimited(startTime, endTime)(state);
    const eventIds = selectChannelEventIds(channelId)(state);
    return eventIds.some((id) => {
        const event = selectEvent(id)(state);
        return event && event.isLoading && event.start < end && event.end > start;
    });
};

export const selectFilteredChannelIdsWithLoadingEvents =
    (channelIds: GQL.ChannelID[], start: number, end: number) =>
    (state: State): GQL.ChannelID[] =>
        channelIds.filter((id) => selectHasLoadingEvents(id, start, end)(state));

export const selectEventIsRestricted =
    (id: GQL.EventID, sessionType = PinSessionType.PIN) =>
    (state: State): boolean => {
        const event = selectEvent(id)(state);
        if (!event || event.isLoading) return false;
        return selectParentalRatingIsRestricted(event.parentalRatingId, sessionType)(state);
    };

export const selectEventShouldMask =
    (id: GQL.EventID, sessionType = PinSessionType.PIN) =>
    (state: State): boolean => {
        const event = selectEvent(id)(state);
        if (!event || event.isLoading) return false;
        const isRestricted = selectEventIsRestricted(id, sessionType)(state);
        const rank = selectParentalRatingId(state);

        if (rank === 'FSK18') {
            return false;
        }

        return isRestricted;
    };

export const selectEventExists =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        !!selectEvent(id)(state);

export const selectEventIsLoading =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        !!selectEvent(id)(state)?.isLoading;

export const selectEventIsNoInfo =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        !!selectEvent(id)(state)?.isNoInfo;

export const selectEventTitle =
    (id: GQL.EventID, sessionType = PinSessionType.PIN) =>
    (state: State): string => {
        const shouldMask = selectEventShouldMask(id, sessionType)(state);
        if (shouldMask) return 'PARENTAL_CONTROL_MASKED_TITLE';
        return selectEvent(id)(state)?.title ?? '';
    };
export const selectEventStart =
    (id: GQL.EventID) =>
    (state: State): number =>
        selectEvent(id)(state)?.start ?? 0;

export const selectEventEnd =
    (id: GQL.EventID) =>
    (state: State): number =>
        selectEvent(id)(state)?.end ?? 0;

export const selectEventThumbnail =
    (id: GQL.EventID) =>
    (state: State): string =>
        selectEvent(id)(state)?.thumbnail ?? '';

export const selectEventBackground =
    (id: GQL.EventID) =>
    (state: State): string =>
        selectEvent(id)(state)?.background ?? '';

export const selectEventIsLive =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        selectEventStart(id)(state) <= Time.selectors.select(state) && selectEventEnd(id)(state) > Time.selectors.select(state);

export const selectEventIsCatchUpTVEnabled =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        isCatchupTV(selectEvent(id)(state), Time.selectors.select(state));

export const selectEventIsDolby =
    (id: GQL.EventID) =>
    (state: State): boolean =>
        selectEvent(id)(state)?.streamInfo?.dolby ?? false;

export const selectEventFullDescription =
    (id: GQL.EventID) =>
    (state: State): string => {
        const shouldMask = selectEventShouldMask(id)(state);
        if (shouldMask) return 'PARENTAL_CONTROL_MASKED_DESCRIPTION';
        return getEventFullDescription(selectEvent(id)(state));
    };

export const selectEventShortDescription =
    (id: GQL.EventID, sessionType = PinSessionType.PIN) =>
    (state: State): string => {
        const shouldMask = selectEventShouldMask(id, sessionType)(state);
        if (shouldMask) return 'PARENTAL_CONTROL_MASKED_DESCRIPTION';
        return selectEvent(id)(state)?.metadata?.shortDescription ?? '';
    };

export const selectEventIndex =
    (channelId: GQL.ChannelID, eventId: GQL.EventID) =>
    (state: State): number =>
        selectChannelEventIds(channelId)(state).indexOf(eventId);

export const selectEventIdByIndex = (channelId: GQL.ChannelID, index: number) => (state: State) =>
    selectChannelEventIds(channelId)(state)[index];

export const selectEventByIndex =
    (channelId: GQL.ChannelID, index: number) =>
    (state: State): EventEntity | null =>
        selectEvent(selectEventIdByIndex(channelId, index)(state))(state);

export const selectEventChannelID = (id: GQL.EventID) => (state: State) => selectEvent(id)(state)?.channelId ?? '';

export const selectEventImageVariant = (id: GQL.EventID) => (state: State) => {
    if (selectEventIsLoading(id)(state)) return Variant.LOADING;
    if (selectEventIsNoInfo(id)(state)) return Variant.COVER;
    if (selectEventShouldMask(id)(state)) return Variant.ADULT;
    return Variant.BASE;
};

export const selectEventLiveProgress =
    (id: GQL.EventID) =>
    (state: State): number => {
        const isLive = selectEventIsLive(id)(state);
        if (!isLive) return -1;
        return (
            ((Time.selectors.select(state) - selectEventStart(id)(state)) / (selectEventEnd(id)(state) - selectEventStart(id)(state))) * 100
        );
    };

export const selectIsLiveChannelEventRestricted = (state: State) =>
    selectEventIsRestricted(
        selectEventBy(selectChannelLiveId(state), Time.selectors.select(state), { equal: EqualityCompare.START })(state)?.id ?? '',
        PinSessionType.PIN_LIVE_TV
    )(state);
