import { KeyboardLayoutSchema } from 'App/Modules/Keyboard/types';

export const Sl: KeyboardLayoutSchema = {
    name: 'default',
    language: 'sl',
    keys: [
        [
            { type: 'key.number', value: '1' },
            { type: 'key.number', value: '2' },
            { type: 'key.number', value: '3' },
            { type: 'key.number', value: '4' },
            { type: 'key.number', value: '5' },
            { type: 'key.number', value: '6' },
            { type: 'key.number', value: '7' },
            { type: 'key.number', value: '8' },
            { type: 'key.number', value: '9' },
            { type: 'key.number', value: '0' },
            { type: 'key.symbol', value: '@' },
        ],
        [
            { type: 'key.char', value: 'q' },
            { type: 'key.char', value: 'w' },
            { type: 'key.char', value: 'e' },
            { type: 'key.char', value: 'r' },
            { type: 'key.char', value: 't' },
            { type: 'key.char', value: 'z' },
            { type: 'key.char', value: 'u' },
            { type: 'key.char', value: 'i' },
            { type: 'key.char', value: 'o' },
            { type: 'key.char', value: 'p' },
            { type: 'key.char', value: 'š' },
        ],
        [
            { type: 'key.char', value: 'a' },
            { type: 'key.char', value: 's' },
            { type: 'key.char', value: 'd' },
            { type: 'key.char', value: 'f' },
            { type: 'key.char', value: 'g' },
            { type: 'key.char', value: 'h' },
            { type: 'key.char', value: 'j' },
            { type: 'key.char', value: 'k' },
            { type: 'key.char', value: 'l' },
            { type: 'key.char', value: 'č' },
            { type: 'key.char', value: 'ć' },
        ],
        [
            { type: 'key.capslock' },
            { type: 'key.char', value: 'y' },
            { type: 'key.char', value: 'x' },
            { type: 'key.char', value: 'c' },
            { type: 'key.char', value: 'v' },
            { type: 'key.char', value: 'b' },
            { type: 'key.char', value: 'n' },
            { type: 'key.char', value: 'm' },
            { type: 'key.char', value: 'đ' },
            { type: 'key.char', value: 'ž' },
            { type: 'key.backspace' },
        ],
        [
            { type: 'key.layout' },
            { type: 'key.language' },
            { type: 'key.arrow.left' },
            { type: 'key.arrow.right' },
            { type: 'key.space' },
            { type: 'key.symbol', value: '-' },
            { type: 'key.symbol', value: '.' },
            { type: 'key.enter' },
        ],
    ],
};
