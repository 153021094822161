import { useMemo, useRef } from 'react';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItemValue } from 'App/Packages/UI/Header/Modules/HeaderItem/Context/HeaderItemContext';
import { useHeaderItemActive } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/Private/useHeaderItemActive';
import { useHeaderItemEnter } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/Private/useHeaderItemEnter';
import { useHeaderItemFocus } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/Private/useHeaderItemFocus';
import { useHeaderItemFocused } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/Private/useHeaderItemFocused';
import { useHeaderItemScrollIntoView } from 'App/Packages/UI/Header/Modules/HeaderItem/Hooks/Private/useHeaderItemScrollIntoView';
import { HeaderItems } from 'App/Packages/UI/Header/Modules/HeaderItems';

export type UseHeaderItemValuePayload = {
    id: string;
    enter?: (id: string) => void;
};

export type UseHeaderItemValue = (payload: UseHeaderItemValuePayload) => HeaderItemValue;

export const useHeaderItemValue: UseHeaderItemValue = (payload) => {
    const items = HeaderItems.use();
    const { id } = payload;
    const { ctx } = items;
    const ref = useRef<HTMLDivElement | null>(null);
    const active = useHeaderItemActive(id);
    const focused = useHeaderItemFocused(id);
    const { disabled } = items;
    const focus = useHeaderItemFocus(id);
    const enter = useHeaderItemEnter(id, payload.enter);
    const scrollIntoView = useHeaderItemScrollIntoView(ref);

    Subscribable.useRegister(items.ids, id);

    const item = useMemo<HeaderItemValue>(
        () => ({ id, ctx, ref, active, focused, disabled, focus, enter, scrollIntoView }),
        [id, ctx, ref, active, focused, disabled, focus, enter, scrollIntoView]
    );

    Subscribable.useAdd(items.store, item.id, item);

    return item;
};
