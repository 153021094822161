import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type KeySize = 'x1' | 'x2' | 'x3';
export type KeyVariant = 'primary' | 'secondary' | 'accent' | 'focused';

export type KeyProps = DivPropsExtendable & {
    variant?: KeyVariant;
    size?: KeySize;
};

export type KeyComponent = React.FunctionComponent<KeyProps>;

export const Key: React.FunctionComponent<KeyProps> = (props) => {
    const { variant = 'primary', size = 'x1', children, ...other } = props;
    return (
        <Div defaultClassName={`keyboard__key key key--${variant} key--${size}`} {...other}>
            {children}
        </Div>
    );
};
