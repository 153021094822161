import { Axis } from 'App/Types/Axis';

export const Constants = {
    Ctx: 'Header',
    Items: {
        Axis: Axis.X,
    },
    Item: {
        Focus: {
            Delay: 25,
        },
        Scroll: {
            Delay: 100,
        },
    },
} as const;
