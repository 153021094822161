import * as Types from '../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type ProductBundleFolderItemFragment = { __typename: 'ProductBundle', id: string };

export const ProductBundleFolderItemFragmentDoc = gql`
    fragment productBundleFolderItem on ProductBundle {
  id
  __typename
}
    `;