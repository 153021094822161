import React from 'react';

import { GQL } from '__SMART_APP_OLD__/app/gql';
import { TVGuideChannelDetail } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideChannelDetail';
import { TVGuideEventList } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideEventList';

interface Props {
    channelId: GQL.ChannelID;
}

export const TVGuideRowComponent: React.FunctionComponent<Props> = (props) => (
    <div className="tv-guide__row">
        <TVGuideChannelDetail channelId={props.channelId} />
        <TVGuideEventList channelId={props.channelId} />
    </div>
);

export const TVGuideRow = React.memo(TVGuideRowComponent);
