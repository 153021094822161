import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';

export enum TrackViewingBehaviourActionType {
    CHANGED = '@trackViewingBehaviour/changed',
}

export type TrackViewingBehaviourReduxAction = TrackViewingBehaviourChangedAction;

export type TrackViewingBehaviourChangedActionPayload = { value: boolean };
export type TrackViewingBehaviourChangedAction = PayloadedAction<
    TrackViewingBehaviourActionType.CHANGED,
    TrackViewingBehaviourChangedActionPayload
>;
