import React from 'react';

import { SettingsSectionContent, SettingsSectionContentModule } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContent';
import { SettingsSectionHeader, SettingsSectionHeaderModule } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionHeader';

export type SettingsSectionProps = {};

export type SettingsSectionModule = {
    Header: SettingsSectionHeaderModule;
    Content: SettingsSectionContentModule;
} & React.FunctionComponent<React.PropsWithChildren<SettingsSectionProps>>;

export const SettingsSection: SettingsSectionModule = (props) => (
    <div className="settings__section">
        <div className="settings-section">{props.children}</div>
    </div>
);

SettingsSection.Header = SettingsSectionHeader;
SettingsSection.Content = SettingsSectionContent;
