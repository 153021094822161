import { DistributiveOmit } from 'react-redux';

import { bookmarksApi } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/';
import {
    BookmarksActionType,
    BookmarksEntity,
    BookmarksLoadedAction,
    BookmarksSavedAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';
import { profileSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/selectors';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loaded = (bookmarks: BookmarksEntity[]): BookmarksLoadedAction => ({
    type: BookmarksActionType.LOADED,
    payload: { bookmarks },
});

const saved = (bookmark: BookmarksEntity): BookmarksSavedAction => ({
    type: BookmarksActionType.SAVED,
    payload: { bookmark },
});

const load = (): Thunk<Promise<boolean>> => async (dispatch) => {
    try {
        const response = await bookmarksApi.load();
        dispatch(loaded(response.bookmarks));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.BOOKMARKS_LOAD, error));
        return false;
    }
};

type SavePayload = DistributiveOmit<bookmarksApi.SavePayload, 'profileId'>;

const save =
    (payload: SavePayload): Thunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const profileId = profileSelectors.public.selectId(getState());
            const response = await bookmarksApi.save({ ...payload, profileId });
            dispatch(saved(response.bookmark));
            return true;
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.BOOKMARKS_SAVE, error));
            return false;
        }
    };

export const bookmarksActions = {
    public: {
        load,
        save,
    },
    private: {
        loaded,
        saved,
    },
};
