import React from 'react';

import { AlertProps, AlertType } from 'App/Modules/Alert/Types';
import { Null } from 'App/Packages/UI/Null';
import { AlertLifecycle } from './types';

export class Registry {
    private static readonly default: AlertLifecycle = {
        outOfBounds: () => () => {},
        mount: () => async () => {},
        unmount: () => async () => {},
        update: () => async () => {},
    };

    private static readonly store: Partial<Record<AlertType, [React.FunctionComponent<AlertProps>, Partial<AlertLifecycle>]>> = {};

    public static readonly get = <T extends AlertType>(type: T): [React.FunctionComponent<AlertProps<T>>, AlertLifecycle<T>] => [
        (Registry.store[type]?.[0] as unknown as React.FunctionComponent<AlertProps<T>>) ?? Null,
        (Registry.store[type]?.[1] as unknown as AlertLifecycle<T>) ?? Registry.default,
    ];

    public static readonly register = <T extends AlertType>(
        type: T,
        Component: React.FunctionComponent<AlertProps<T>>,
        lifecycle: Partial<AlertLifecycle<T>> = {}
    ) => {
        Registry.store[type] = [Component as unknown as React.FunctionComponent<AlertProps>, { ...Registry.default, ...lifecycle }];
    };
}
