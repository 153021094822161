import { householdActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/actions';
import { getInitialHouseholdState } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/getInitialState';
import { householdReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/reducer';
import { householdSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/selectors';
import {
    HouseholdActionType,
    HouseholdEntityTable,
    HouseholdReduxAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Household/store/types';

export { HouseholdActionType as ActionType };
export type { HouseholdEntityTable as EntityTable, HouseholdReduxAction as ReduxAction };

export const getInitialState = getInitialHouseholdState;
export const reducer = householdReducer;
export const selectors = householdSelectors.public;
export const actions = householdActions.public;
