import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { FavouritableItemKind } from '__SMART_APP_OLD__/api/graphql/types';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import Button from '__SMART_APP_OLD__/components/common/Button';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Function } from 'App/Packages/Function';

interface FavouriteButtonProps {
    asset: any;
    backgroundClassName?: string;
    className?: string;
    onFocus?: () => void;
}

const getFavouritableItemKind = (typeOfAsset: any) => {
    switch (typeOfAsset) {
        case AssetType.EVENT:
        case AssetType.SERIES:
        case AssetType.SEASON:
        case AssetType.EPISODE:
            return FavouritableItemKind.Event;
        case AssetType.VOD_ASSET:
        case AssetType.VOD_FOLDER:
        case AssetType.VOD_SERIES:
            return FavouritableItemKind.VodAsset;
        case AssetType.NETWORK_RECORDING:
            return FavouritableItemKind.NetworkRecording;
        case AssetType.VOD_PRODUCT:
            return FavouritableItemKind.VodProduct;
        default:
            return typeOfAsset;
    }
};

const FavouriteButton = forwardRef<Button, FavouriteButtonProps>(({ asset, onFocus = Function.noop }, nodeRef) => {
    const dispatch = useDispatch();
    const isFavourited = useSelector(Favourites.selectors.selectIsFavourited(asset.id));
    const disabled = useRef<boolean>(false);
    const btnRef = useRef<any>(null);

    const enterPressHandler = async () => {
        if (disabled.current) return;
        disabled.current = true;
        const { backendAssetType, assetType, id } = asset;

        const event = isFavourited ? UIActionEvents.REMOVE_FAVORITE : UIActionEvents.ADD_FAVORITE;
        LoggingService.getInstance().logEvent(getUIActionEvent(event, { assetType, id }));

        dispatch(
            Favourites.actions.toggle({
                itemId: id,
                itemKind: getFavouritableItemKind(backendAssetType),
            })
        );
    };

    const setRef = useCallback(
        (ref: any) => {
            if (ref) {
                if (typeof nodeRef === 'function') nodeRef(ref);
                btnRef.current = ref?.nodeRef?.nodeRef || null;
            }
        },
        [nodeRef]
    );

    useEffect(() => {
        disabled.current = false;
        const focusedNodeClassList = Focus?.focused?.nodeRef?.classList;
        const isRemoveInFocus = focusedNodeClassList?.contains('remove-favorite');
        const isAddInFocus = focusedNodeClassList?.contains('add-favorite');
        if (isRemoveInFocus || isAddInFocus) {
            Focus.focus(btnRef.current);
        }
    }, [isFavourited]);

    if (asset?.isAdult) {
        return null;
    }

    const prefix = isFavourited ? 'remove' : 'add';

    return (
        <Button
            key={`${prefix}${asset.id}-favorite-button`}
            className={`${prefix}-favorite`}
            dataTestId={`button_${prefix}Favorite`}
            label={translate(`SCREEN_DETAIL_${isFavourited ? 'REMOVE_FROM' : 'ADD_TO'}_FAVORITES_BUTTON`)}
            onEnter={enterPressHandler}
            onFocus={onFocus}
            ref={setRef}
            scrollTop
        />
    );
});

export default FavouriteButton;
