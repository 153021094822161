import { Value } from 'App/Packages/Subscribable/Core/Value';
import { Add, ItemGetter, Remove } from 'App/Packages/Subscribable/Types';

export class Record<T> extends Value<{ [key: string]: T }> {
    public readonly item: ItemGetter<T> = (key) => this.get()[key];

    public readonly add: Add<T> = this.__add.bind(this);

    public readonly remove: Remove = this.__remove.bind(this);

    private __add(key: string, value: T): () => void {
        const state = this.get();
        if (state[key] === value) return () => this.__remove(key);
        this.set({ ...state, [key]: value });
        return () => this.__remove(key);
    }

    private __remove(key: string): void {
        const state = this.get();
        if (!state[key]) return;
        const next = { ...state };
        delete next[key];
        this.set(next);
    }
}
