import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectSearchConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import NoSearchResults from '__SMART_APP_OLD__/components/search/NoSearchResults';
import SearchResults from '__SMART_APP_OLD__/components/search/SearchResults';
import SearchSuggestions from '__SMART_APP_OLD__/components/search/SearchSuggestions';
import Events, { CLEAR_SEARCH_HISTORY, SEARCH_HIDE_TOP_AREA, SEARCH_SHOW_TOP_AREA } from '__SMART_APP_OLD__/config/Events';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { PageDataContext } from '__SMART_APP_OLD__/providers/usePageContext';
import { NavKey } from '__SMART_APP_OLD__/utils/Constants';
import History from '__SMART_APP_OLD__/utils/CustomHistory';
import State from '__SMART_APP_OLD__/utils/State';
import {
    isApplicationVisible,
    subscribeVisibilityChangeHandler,
    unsubscribeVisibilityChangeHandler,
} from '__SMART_APP_OLD__/utils/visibilityChange';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Input } from 'App/Modules/Input';
import { Key } from 'App/Modules/Key';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
// todo Remove if decided we don't need to implement suggestions

const PAGE_ID = 'search';

const Search = ({ searchValue, stripes, onSearch, searchSuggestions, searchDataLoaded }) => {
    const searchConfig = useSelector(selectSearchConfig);

    const isBack = State.hasState(NavKey.SEARCH_RESULTS);

    const searchInputRef = useRef(null);
    const element = useRef(null);
    const timeoutId = useRef(null);
    const searchTermUsedRef = useRef(false);
    const clearSearchId = useRef(null);

    const [showTopArea, setShowTopArea] = useState(false);
    const showSuggestions = searchSuggestions.length > 0 && showTopArea;
    const [focusOnRender, setFocusOnRender] = useState(false); // When the results stripe is rendered, in some case we need to force to focus the first asset.
    // todo Check this after Suggestion clarification
    const [isFirstEnter, setFirstEnter] = useState(State.hasState(NavKey.SEARCH) ? State.get(NavKey.SEARCH).isFirstEnter : true);
    const [focusPlaced, setFocusPlaced] = useState(true);

    const { onTermChange } = useContext(PageDataContext);

    const hideTopAreaContainers = () => {
        setShowTopArea(false);
        State.set(NavKey.SEARCH, {});
    };

    const showTopAreaContainers = () => {
        setShowTopArea(true);
        State.set(NavKey.SEARCH, {});
        setFocusPlaced(true);
    };

    const searchWithDebounce = useCallback(
        (searchTermText, options) => {
            clearTimeout(timeoutId.current);
            setFirstEnter(false);
            timeoutId.current = setTimeout(() => {
                State.set('Stripe', null);
                onSearch(searchTermText, options);
            }, searchConfig.debounce);
        },
        [onSearch, searchConfig.debounce]
    );

    const search = useCallback(
        (searchTerm, options) => {
            if (searchTerm.length < searchConfig.keyThreshold) return;
            // trigger search
            searchTermUsedRef.current = true;
            if (focusOnRender) {
                setFocusOnRender(false);
            }
            searchWithDebounce(searchTerm, options);
        },
        [focusOnRender, searchConfig.keyThreshold, searchWithDebounce]
    );

    const onSearchTermChange = useCallback(() => {
        clearTimeout(clearSearchId.current);
        onTermChange(element.current.value.get());
        searchTermUsedRef.current = false;
        clearSearchId.current = setTimeout(() => {
            if (element.current.value.get() === '') {
                Events.triggerEvents(CLEAR_SEARCH_HISTORY);
            }
        }, searchConfig.debounce);
        search(element.current.value.get());
    }, [onTermChange, search, searchConfig.debounce]);

    const onInputFocus = () => {
        // element.current.focus();
        setFocusPlaced(true);
        State.remove(NavKey.SEARCH);
    };

    const onSuggestionFocused = (previousDom) => {
        if (previousDom && !previousDom?.nodeRef?.classList.contains('button') && !Focus.isMagicMode) {
            // focus is coming from input or results - focus first tag
            setFocusPlaced(false);
            // when the suggestions are focused clear the State for Search
            // to avoid placing the focus on stripe
            State.remove(NavKey.SEARCH);
        }
    };

    const onSuggestionPressed = (keyword) => {
        if (element.current.value.get() !== keyword) {
            element.current.value.set(keyword);
            onTermChange(element.current.value.get());
            searchTermUsedRef.current = false;
            search(keyword, { fromSuggestions: true });
        }
    };

    const onDone = () => {
        setFocusPlaced(false);

        if (element.current.value.get().length) {
            searchTermUsedRef.current = true;
            setFocusOnRender(true);
            searchWithDebounce(element.current.value.get());
        }
    };

    const onBack = () => {
        Events.triggerEvents(CLEAR_SEARCH_HISTORY);
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.BACK));
        History.back();
    };

    const onKey = (keyCode) => {
        switch (keyCode) {
            case Key.VK_DOWN: {
                onDone();
                return true;
            }
            case Key.VK_ENTER: {
                element.current.focus();
                return true;
            }
            case Key.VK_BACK: {
                if (
                    stripes.length > 0 &&
                    element.current.value.get().length >= searchConfig.keyThreshold &&
                    element.current.focused.get()
                ) {
                    // if there are search results
                    onDone();
                } else {
                    // if page is empty
                    onBack();
                }
                return true;
            }

            default:
                return true;
        }
    };

    const onVisibilityChange = () => {
        if (!isApplicationVisible()) {
            Player.suspend();
            return;
        }

        Player.restore();
    };

    const handlerMouseMove = () => element?.focused?.get();

    const handleContainerFocusChanged = () => false;

    useEffect(() => {
        if (isBack) {
            setShowTopArea(true);
        }

        if (isFirstEnter) {
            setShowTopArea(true);
        }

        Events.addEventListener(SEARCH_HIDE_TOP_AREA, hideTopAreaContainers);
        Events.addEventListener(SEARCH_SHOW_TOP_AREA, showTopAreaContainers);
        subscribeVisibilityChangeHandler(onVisibilityChange);

        return () => {
            Events.removeEventListener(SEARCH_HIDE_TOP_AREA, hideTopAreaContainers);
            Events.removeEventListener(SEARCH_SHOW_TOP_AREA, showTopAreaContainers);
            State.remove(NavKey.SEARCH);
            unsubscribeVisibilityChangeHandler(onVisibilityChange);
        };
    }, [isBack, isFirstEnter]);

    useEffect(() => {
        if (searchInputRef.current && !isBack) {
            // place the focus on searchInput, otherwise the onKey won't work
            Focus.focus(searchInputRef?.current?.nodeRef?.nodeRef?.nodeRef);
            element.current.focus();
        }
    }, [isBack, searchInputRef, showTopArea]);

    useEffect(() => {
        element.current?.value.set(searchValue);
        if (element.current?.value.get()) searchTermUsedRef.current = true;
    }, [searchValue]);

    return (
        <Layout backgroundColor={BackgroundColor.PRIMARY}>
            <Container className="search" onMouseMove={handlerMouseMove} onFocusChanged={handleContainerFocusChanged}>
                {showTopArea && (
                    <div className="search-input-container">
                        <Focusable ref={searchInputRef} onFocus={onInputFocus} onEnter={() => element.current.focus()} onKey={onKey}>
                            <Input
                                ref={element}
                                value={searchValue}
                                block="input"
                                placeholder="SCREEN_SEARCH"
                                onChange={onSearchTermChange}
                                onBlur={onDone}
                            />
                        </Focusable>
                    </div>
                )}
                {showSuggestions && (
                    <SearchSuggestions
                        tags={searchSuggestions}
                        title={'SCREEN_SEARCH_STRIPE_TAGS'}
                        onSuggestionPressed={onSuggestionPressed}
                        onFocusChanged={onSuggestionFocused}
                        focusPlaced={focusPlaced}
                        showSuggestions={showSuggestions}
                    />
                )}
                {searchValue && searchDataLoaded ? (
                    <>
                        {stripes.length > 0 && searchValue ? (
                            <SearchResults
                                stripes={stripes}
                                pageId={PAGE_ID}
                                focusPlaced={focusPlaced}
                                focusOnRender={focusOnRender}
                                showSuggestions={showSuggestions}
                            />
                        ) : (
                            <NoSearchResults />
                        )}
                    </>
                ) : (
                    <SearchResults
                        stripes={stripes}
                        focusPlaced={focusPlaced}
                        focusOnRender={focusOnRender}
                        showSuggestions={showSuggestions}
                    />
                )}
            </Container>
        </Layout>
    );
};

export default Search;
