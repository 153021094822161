import { KeyArrowLeftIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/ArrowLeft/KeyArrowLeftIcon';
import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import React from 'react';

export type KeyArrowLeftProps = KeyProps & {};

export type KeyArrowLeftComponent = React.FunctionComponent<KeyArrowLeftProps>;

export const KeyArrowLeft: KeyArrowLeftComponent = (props) => {
    const { size = 'x1', children = KeyArrowLeftIconInsance, ...other } = props;

    return (
        <Key size={size} {...other}>
            {children}
        </Key>
    );
};
