import React, { useMemo } from 'react';

import { Calc } from 'App/Packages/Calc';
import { Axis } from 'App/Types/Axis';

type Props<T> = {
    firstVisibleIndex: number;
    className: string;
    items: T[];
    itemType: ListItemType;
    renderer: ListRenderer<T>;
};

export type ListRenderer<T> = (item: T, index: number, visibleIndex: number) => React.ReactNode;

export enum ListItemType {
    DEVICE_ITEM = 'DEVICE_ITEM',
    AGE_RATING_ITEM = 'AGE_RATING_ITEM',
    CHANNEL_ITEM = 'CHANNEL_ITEM',
    CHANNEL_LIST_ITEM = 'CHANNEL_LIST_ITEM',
    SETTINGS_SCREEN_ITEM = 'SETTINGS_SCREEN_ITEM',
    SELECT_ITEM = 'SELECT_ITEM',
    MESSAGE_INBOX_ITEM = 'MESSAGE_INBOX_ITEM',
    LANDSCAPE_CARD_GRID = 'LANDSCAPE_CARD_GRID',
    PORTRAIT_CARD_GRID = 'PORTRAIT_CARD_GRID',
    MESSAGE_DETAIL_ATTACHMENT = 'MESSAGE_DETAIL_ATTACHMENT',
    EDUCATION_ITEM = 'EDUCATION_ITEM',
    VOD_UPSELL_OPTION = 'VOD_UPSELL_OPTION',
    CHANNEL_LIST_OPTION = 'CHANNEL_LIST_OPTION',
}

export enum ListDirection {
    ROW = 'ROW',
    COLUMN = 'COLUMN',
}

export type ListConfig = {
    itemSize: number;
    visibleItems: number;
    renderedItems: number;
    hiddenItems: number; // hiddenItems + visibleItems + hiddenItems
    direction: ListDirection;
    transition?: number;
};

export interface ListItemProps {
    id: string;
}

const configForListItemType: Record<ListItemType, ListConfig> = {
    [ListItemType.DEVICE_ITEM]: {
        itemSize: 320 / 19.2,
        hiddenItems: 2,
        visibleItems: 5,
        renderedItems: 9,
        direction: ListDirection.ROW,
        transition: 250,
    },
    [ListItemType.AGE_RATING_ITEM]: {
        itemSize: 320 / 19.2,
        hiddenItems: 2,
        visibleItems: 5,
        renderedItems: 9,
        direction: ListDirection.ROW,
        transition: 250,
    },
    [ListItemType.CHANNEL_ITEM]: {
        itemSize: 81.6 / 19.2,
        hiddenItems: 2,
        visibleItems: 8,
        renderedItems: 12,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.CHANNEL_LIST_ITEM]: {
        itemSize: 81.6 / 19.2,
        hiddenItems: 2,
        visibleItems: 7,
        renderedItems: 11,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.SETTINGS_SCREEN_ITEM]: {
        itemSize: 81.6 / 19.2,
        hiddenItems: 2,
        visibleItems: 8,
        renderedItems: 12,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.SELECT_ITEM]: {
        itemSize: 104 / 19.2,
        hiddenItems: 5,
        visibleItems: 8,
        renderedItems: 18,
        direction: ListDirection.COLUMN,
    },
    [ListItemType.MESSAGE_INBOX_ITEM]: {
        itemSize: 272 / 19.2,
        hiddenItems: 1,
        visibleItems: 3,
        renderedItems: 5,
        direction: ListDirection.COLUMN,
        transition: 150,
    },
    [ListItemType.LANDSCAPE_CARD_GRID]: {
        itemSize: 304 / 19.2,
        hiddenItems: 2,
        visibleItems: 3,
        renderedItems: 7,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.PORTRAIT_CARD_GRID]: {
        itemSize: 408 / 19.2,
        hiddenItems: 2,
        visibleItems: 3,
        renderedItems: 7,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.MESSAGE_DETAIL_ATTACHMENT]: {
        itemSize: 438 / 19.2,
        hiddenItems: 5,
        visibleItems: 4,
        renderedItems: 14,
        direction: ListDirection.ROW,
        transition: 250,
    },
    [ListItemType.EDUCATION_ITEM]: {
        itemSize: 1024 / 19.2,
        hiddenItems: 3,
        visibleItems: 1,
        renderedItems: 7,
        direction: ListDirection.ROW,
        transition: 250,
    },
    [ListItemType.VOD_UPSELL_OPTION]: {
        itemSize: 104 / 19.2,
        hiddenItems: 0,
        visibleItems: 4,
        renderedItems: 14,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
    [ListItemType.CHANNEL_LIST_OPTION]: {
        itemSize: 104 / 19.2,
        hiddenItems: 2,
        visibleItems: 6,
        renderedItems: 10,
        direction: ListDirection.COLUMN,
        transition: 0,
    },
};

const classNameForDirection: Record<ListDirection, string> = {
    [ListDirection.ROW]: 'list-v2__items list-v2__items--row',
    [ListDirection.COLUMN]: 'list-v2__items list-v2__items--column',
};

const getListClassName = (className?: string) => `list-v2${className ? ` ${className}` : ''}`;

const genStyles = (index: number, itemSize: number, axis: Axis, transition?: number): React.CSSProperties => ({
    transform: `translateZ(0) translate${axis}(${index * itemSize}vw)`,
    ...(transition ? { transition: `transform ${transition}ms linear` } : {}),
});

const getVisibleItemIndex = (
    firstRenderedIndex: number,
    firstVisibleIndex: number,
    relativeIndex: number,
    visibleItems: number
): number => {
    const index = firstRenderedIndex - firstVisibleIndex + relativeIndex;
    if (index < 0 || index >= visibleItems) return -1;
    return index;
};

const useListStyles = (index: number, itemType: ListItemType, withTransition: boolean): React.CSSProperties => {
    const config = configForListItemType[itemType];
    const axis: Axis = config.direction === ListDirection.ROW ? Axis.X : Axis.Y;
    const transition = withTransition ? config.transition : undefined;
    const styleObject = useMemo(() => genStyles(index, config.itemSize, axis, transition), [index, config.itemSize, axis, transition]);
    return styleObject;
};

const ListComponent = <T,>(props: Props<T>) => {
    const config = configForListItemType[props.itemType];
    const firstVisibleIndex = Calc.clamp(0, props.firstVisibleIndex, props.items.length - config.visibleItems);
    const firstRenderedIndex = Math.max(0, firstVisibleIndex - config.hiddenItems);
    const [listValue, itemsValue] = !config.transition
        ? [0, firstRenderedIndex - firstVisibleIndex]
        : [-firstVisibleIndex, firstRenderedIndex];
    const listStyles = useListStyles(listValue, props.itemType, true);
    const itemsStyles = useListStyles(itemsValue, props.itemType, false);
    const listClassName = getListClassName(props.className);
    const itemsClassName = classNameForDirection[config.direction];
    const lastRenderedPosition = Math.min(firstRenderedIndex + config.renderedItems, props.items.length);
    const items = props.items.slice(firstRenderedIndex, lastRenderedPosition);

    return (
        <div className={listClassName}>
            <div className="list-v2__items-track" style={listStyles}>
                <div className={itemsClassName} style={itemsStyles}>
                    {items.map((item, index) => {
                        const originalItemIndex = firstRenderedIndex + index;
                        const visibleIndex = getVisibleItemIndex(firstRenderedIndex, firstVisibleIndex, index, config.visibleItems);
                        return props.renderer(item, originalItemIndex, visibleIndex);
                    })}
                </div>
            </div>
        </div>
    );
};

export const List = React.memo(ListComponent) as typeof ListComponent;
