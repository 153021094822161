import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

import { Subscribable } from 'App/Packages/Subscribable';
import { HeaderItem } from 'App/Packages/UI/Header/Modules/HeaderItem';

const getClassName = (theme: Theme.Type): string => {
    const classlist = ['header-item__indicator'];
    classlist.push(Theme.getClassName('header-item__indicator', theme));
    return classlist.join(' ');
};

export type HeaderItemIndicatorProps = {};

export type HeaderItemIndicatorComponent = React.FunctionComponent<HeaderItemIndicatorProps>;

export const HeaderItemIndicator: HeaderItemIndicatorComponent = () => {
    const theme = useTheme();
    const item = HeaderItem.use();
    const isActive = Subscribable.use(item.active);
    if (!isActive) return null;
    return <div className={getClassName(theme)} />;
};
