import { PayloadedAction } from '__SMART_APP_OLD__/app/store/types/PayloadedAction';
import { ButtonEntity } from '__SMART_APP_OLD__/app/types';

export enum ThemeType {
    Light = 'LIGHT',
    Dark = 'DARK',
}

export enum ThemeActionType {
    Changed = '@theme/changed',
}

export type ThemeOption<ID extends ThemeType = ThemeType, LABEL extends string = string> = ButtonEntity<ID, LABEL>;

export type ThemeReduxAction = ThemeChangedAction;
export type ThemeChangedActionPayload = { theme: ThemeType };
export type ThemeChangedAction = PayloadedAction<ThemeActionType.Changed, ThemeChangedActionPayload>;
