import React from 'react';

import { Button, ButtonAnimation, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen/actions';
import { CommunitySelectionAction } from '__SMART_APP_OLD__/app/modules/Screen/modules/CommunitySelectionScreen/types';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

const items = [CommunitySelectionAction.Change, CommunitySelectionAction.Continue];

export const CommunitySelectionScreenActions: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const { focused, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items,
        initialFocusedIndex: 1,
    });

    Focus.useBack(() => dispatch(Fti.actions.back()));

    return (
        <>
            <Button
                id={CommunitySelectionAction.Change}
                ctx={Focus.Default}
                className="community-selection__action"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                width={ButtonWidth.FULL}
                onHover={focus}
                onEnter={() => dispatch(actions.private.change())}
                isFocused={focused === CommunitySelectionAction.Change && !isDisabled}
            >
                FTI_REGION_SELECTION_CHANGE_REGION_BUTTON
            </Button>
            <Button
                id={CommunitySelectionAction.Continue}
                ctx={Focus.Default}
                className="community-selection__action"
                animation={ButtonAnimation.SCALE}
                corners={ButtonCornersStyle.ROUNDED}
                width={ButtonWidth.FULL}
                onHover={focus}
                onEnter={() => dispatch(Fti.actions.next(Screen.Type.COMMUNITY_SELECTION))}
                isFocused={focused === CommunitySelectionAction.Continue && !isDisabled}
            >
                CONTINUE_BUTTON
            </Button>
        </>
    );
};
