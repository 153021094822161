import throttle from 'lodash.throttle';
import React, { useEffect, useMemo, useRef } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectSelectedChannelListChannelIds } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import { NotFocusableChannelCard } from '__SMART_APP_OLD__/components/common/cards/ChannelCard';
import { ChannelsMagicModeScroll } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/Channels/components/ChannelsMagicModeScroll';
import { miniEpgConfig } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';

const getChannelsIdsToShow = (channelIds: string[], max: number, focused: string): string[] => {
    // focused is the channelID that is currently focused in mini epg
    // max is the number of cards that mini epg displays
    // we need to get 1 channelID before the focused one,
    // focused channel ID and 4 after focused channelID
    // to display channel cards correctly

    // cases:

    // base:
    // there is at least 1 item before focused and there are at least 4 items after focused
    // then we return array that contains channelIds from 1 before focused to 4 after focused
    // example:
    // channelIds = Array of 10 elements, focused index = 2
    // result: [ 1, 2, 3, 4, 5, 6]

    // no items before focused:
    // index === 0 and there are 4 items after focused
    // then we return array that has the last channel id for channelIds array and the first 5 items.
    // example:
    // channelIds = Array of 10 elements, focused index = 0
    // result: [ 9, 0, 1, 2, 3, 4]

    // not enough items to the end
    // there is 1 id before focused but there are less than 4 after focused
    // then we return array that has items from 1 before focused to the end of channelIds
    // + remaining channel ids from the start so we get the exact amount of channelIds
    // example:
    // channelIds = Array of 10 elements, focused index = 8
    // result: [ 7, 8, 9, 0, 1, 2]

    // edge cases:
    // when channelsIds are less that 6 (items that mini epg displays)
    // same logic as for the normal cases exept that we limit the items to the lenght of the array

    // base (edge):
    // example:
    // channelIds = Array of 4 elements, focused index = 1
    // result: [ 0, 1, 2, 3]

    // no items before focused (edge):
    // example:
    // channelIds = Array of 4 elements, focused index = 0
    // result: [ 3, 0, 1, 2]

    // not enough items to the end (edge):
    // example:
    // channelIds = Array of 4 elements, focused index = 2
    // result: [ 1, 2, 3, 0]

    const { length } = channelIds;
    const index = channelIds.indexOf(focused);
    // edge cases:
    if (length < max + 1) {
        // case -> no items before focused (edge) (line: 51)
        if (index === 0) return [channelIds[length - 1], ...channelIds.slice(0, length - 1)];
        // case -> base (edge) (line: )
        if (index === 1) return channelIds.slice(0, length);
        // case -> not enough items to the end (edge) (line: 56)
        return [...channelIds.slice(index - 1, length), ...channelIds.slice(0, index - 1)];
    }
    // case -> no items before focused (line: 27)
    if (index === 0) {
        const last = channelIds[length - 1];
        return [last, ...channelIds.slice(0, max - 1)];
    }
    // case -> base (line: 20)
    if (index > 0 && index + max <= length) {
        return channelIds.slice(index - 1, index + max - 1);
    }
    // case -> not enough items to the end (line: 34)
    if (index + max > length) {
        const itemsToEnd = length - index + 1;
        return [...channelIds.slice(index - 1), ...channelIds.slice(0, max - itemsToEnd)];
    }
    return [];
};

interface Props {
    channelId: string;
    isFullMode: boolean;
    isMagicMode: boolean;
    onChannelChange: (direction: 1 | -1) => void;
    onFastScrollChangeHandler: (isFastScroll: boolean) => void;
}

export const Channels: React.FunctionComponent<Props> = (props) => {
    const { isFullMode, isMagicMode, channelId, onChannelChange, onFastScrollChangeHandler } = props;

    const channelsIdsFromChannelList = useSelector(selectSelectedChannelListChannelIds);

    const channelsIds = useMemo(
        () => getChannelsIdsToShow(channelsIdsFromChannelList, miniEpgConfig.maxChannelCardsOnPage, channelId),
        [channelsIdsFromChannelList, channelId]
    );
    const leftNormal = channelsIds.length === 1 ? ' left-normal' : '';
    const shouldTriggerFastScroll = useRef(false);

    useEffect(() => {
        const keyDownHandler = throttle(
            (event: KeyboardEvent) => {
                const key = event.keyCode || event.which || event.detail;
                if (key !== Key.VK_LEFT && key !== Key.VK_RIGHT) return;
                const direction = key === Key.VK_LEFT ? -1 : 1;
                onChannelChange(direction);
                if (shouldTriggerFastScroll.current) {
                    onFastScrollChangeHandler(true);
                }
                shouldTriggerFastScroll.current = true;
            },
            miniEpgConfig.throttleChannelsNavigation,
            { trailing: false }
        );

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onChannelChange, onFastScrollChangeHandler]);

    useEffect(() => {
        const keyUpHandler = () => {
            shouldTriggerFastScroll.current = false;
            onFastScrollChangeHandler(false);
        };

        document.addEventListener('keyup', keyUpHandler);
        return () => {
            document.removeEventListener('keyup', keyUpHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFullMode) return undefined;
        const wheelHandler = throttle(
            (event: WheelEvent) => {
                const { deltaY, deltaX } = event;
                const increment = (deltaY || deltaX) > 0 ? 1 : -1;
                onChannelChange(increment);
            },
            miniEpgConfig.throttleChannelsNavigation,
            { trailing: false }
        );

        document.addEventListener('wheel', wheelHandler);
        return () => {
            document.removeEventListener('wheel', wheelHandler);
        };
    }, [onChannelChange, isFullMode]);

    return (
        <>
            <div className={`channel-list-container${leftNormal}`}>
                {channelsIds.map((id) => (
                    <NotFocusableChannelCard key={id} channelId={id} />
                ))}
            </div>
            {isMagicMode && <ChannelsMagicModeScroll onChannelChange={onChannelChange} />}
        </>
    );
};
