import { KeyboardKeyElement } from 'App/Modules/Keyboard/Element/KeyboardKeyElement';
import { KeyboardKeyType, KeyboardLayoutSchema } from 'App/Modules/Keyboard/types';
import { Calc } from 'App/Packages/Calc';
import { NavigationEvent as FocusNavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';

export class KeyboardLayoutElement {
    public readonly schema: KeyboardLayoutSchema;

    public col: number;

    public row: number;

    public key: KeyboardKeyElement;

    public keys: KeyboardKeyElement[][];

    constructor(schema: KeyboardLayoutSchema) {
        this.schema = schema;
        this.col = 0;
        this.row = 0;
        this.keys = schema.keys.map((s) => s.map((key) => new KeyboardKeyElement(key)));
        this.key = this.keys[this.row][this.col];
        this.key.focus();
    }

    public focus(key: KeyboardKeyElement) {
        if (key === this.key && key.focused.get()) return;
        const row = this.keys.findIndex((r) => r.includes(key));
        if (row === -1) return;
        this.row = row;
        this.col = this.keys[row].indexOf(key);
        this.key.blur();
        this.key = this.keys[this.row][this.col];
        this.key.focus();
    }

    public focusAt(row: number, col: number) {
        this.row = Calc.clamp(0, row, this.keys.length);
        this.col = Calc.clamp(0, col, this.keys[this.row].length);
        this.key.blur();
        this.key = this.keys[this.row][this.col];
        this.key.focus();
    }

    public focusByType(type: KeyboardKeyType) {
        const row = this.keys.findIndex((r) => r.some((key) => key.schema.type === type));
        if (row === -1) return;
        const col = this.keys[row].findIndex((key) => key.schema.type === type);
        this.focusAt(row, col);
    }

    public focusByValue(value: string) {
        const key = this.getByValue(value);
        if (!key) return;
        this.focus(key);
    }

    public getByValue(value: string) {
        const row = this.keys.findIndex((r) => r.some((key) => 'value' in key.schema && key.schema.value === value));
        if (row === -1) return null;
        const col = this.keys[row].findIndex((key) => 'value' in key.schema && key.schema.value === value);
        return this.keys[row][col];
    }

    public navigate(event: FocusNavigationEvent, hasSuggestions: boolean) {
        if (event.x === 0 && event.y === 0) return;

        if (event.x !== 0) {
            this.col = Calc.infinite(0, this.col + event.x, this.keys[this.row].length - 1);
        }

        if (event.y === -1 && this.row === 0 && hasSuggestions) throw new Error('Cannot navigate up from the first row');

        if (event.y !== 0) {
            const currentRow = this.getRowWithGaps(this.row);
            const currentCol = currentRow.indexOf(this.key);
            this.row = Calc.infinite(0, this.row + event.y, this.keys.length - 1);
            const nextRow = this.getRowWithDuplicates(this.row);
            const nextCol = Calc.clamp(0, currentCol, nextRow.length - 1);
            const key = nextRow[nextCol];
            this.col = this.keys[this.row].indexOf(key);
        }

        this.key.blur();
        this.key = this.keys[this.row][this.col];
        this.key.focus();
    }

    private getRowWithDuplicates(row: number) {
        return this.keys[row].reduce<KeyboardKeyElement[]>((acc, key) => {
            acc.push(...new Array(key.size).fill(key));
            return acc;
        }, []);
    }

    private getRowWithGaps(row: number) {
        return this.keys[row].reduce<(KeyboardKeyElement | null)[]>((acc, key, index) => {
            if (key.size === 3) {
                acc.push(null, key, null);
                return acc;
            }
            if (key.size === 2) {
                if (index > (this.keys[row].length - 1) / 2) acc.push(null, key);
                else acc.push(key, null);
                return acc;
            }
            acc.push(key);
            return acc;
        }, []);
    }
}
