import { Alert } from 'App/Modules/Alert';
import { BackEndDoesntWorkAlert } from 'App/Modules/Alert/Modules/BackEndDoesntWorkAlert';
import { BasicAlert } from 'App/Modules/Alert/Modules/BasicAlert';
import { DeviceCannotDeleteAlert } from 'App/Modules/Alert/Modules/DeviceCannotDeleteAlert';
import { LoginErrorAlert } from 'App/Modules/Alert/Modules/LoginErrorAlert';
import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { CoreBackEngine } from 'App/Modules/EnhancedBack/Implementation/Core';
import { Overlay } from 'App/Modules/Overlay';
import { AdultContentSelectOverlay } from 'App/Modules/Overlay/Modules/AdultContentSelectOverlay';
import { ChannelListSelectOverlay } from 'App/Modules/Overlay/Modules/ChannelListSelectOverlay';
import { SubtitlesSelectOverlay } from 'App/Modules/Overlay/Modules/SubtitlesSelectOverlay';
import { AudioSelectOverlay } from 'App/Modules/Overlay/Modules/AudioSelectOverlay';
import { ComunitySelectOverlay } from 'App/Modules/Overlay/Modules/CommunitySelectOverlay';
import { LiveClockSelectOverlay } from 'App/Modules/Overlay/Modules/LiveClockSelectOverlay';
import { LoadingOverlay } from 'App/Modules/Overlay/Modules/LoadingOverlay';
import { MessageFilterSelectOverlay } from 'App/Modules/Overlay/Modules/MessageFilterSelectSelect';
import { SplashOverlay } from 'App/Modules/Overlay/Modules/SplashOverlay';
import { StartUpSelectOverlay } from 'App/Modules/Overlay/Modules/StartUpSelectOverlay';
import { ThemeSelectOverlay } from 'App/Modules/Overlay/Modules/ThemeSelectOverlay';
import { Prompt } from 'App/Modules/Prompt';
import { ChannelListConfirmDelete } from 'App/Modules/Prompt/Modules/ChannelListConfirmDeletePrompt';
import { DeviceConfirmDeletePrompt } from 'App/Modules/Prompt/Modules/DeviceConfirmDeletePrompt';
import { LogoutPrompt } from 'App/Modules/Prompt/Modules/LogoutPrompt';
import { MessageConfirmDeletePrompt } from 'App/Modules/Prompt/Modules/MessageConfirmDeletePrompt';
import { RecordingConfirmCancelPrompt } from 'App/Modules/Prompt/Modules/RecordingConfirmCancelPrompt';
import { RecordingConfirmCancelSeriesPrompt } from 'App/Modules/Prompt/Modules/RecordingConfirmCancelSeriesPrompt/RecordingConfirmCancelSeriesPrompt';
import { RecordingConfirmCreateSeriesPrompt } from 'App/Modules/Prompt/Modules/RecordingConfirmCreateSeriesPrompt';
import { RecordingConfirmCreatePrompt } from 'App/Modules/Prompt/Modules/RecordingConfirmCreatePrompt';
import { RecordingConfirmDeletePrompt } from 'App/Modules/Prompt/Modules/RecordingConfirmDeletePrompt';
import { RecordingManagementConfirmDeletePrompt } from 'App/Modules/Prompt/Modules/RecordingManagementConfirmDeletePrompt';
import { Screen } from 'App/Modules/Screen';
import { LoginCodeExpired } from 'App/Modules/Screen/Modules/LoginCodeExpired';
import { LoginFlowSelector } from 'App/Modules/Screen/Modules/LoginFlowSelector';
import { LoginWithCode } from 'App/Modules/Screen/Modules/LoginWithCode';
import { LoginWithEmail } from 'App/Modules/Screen/Modules/LoginWithEmail';
import { MyLibrary } from 'App/Modules/Screen/Modules/MyLibrary';
import { VideoStore } from 'App/Modules/Screen/Modules/VideoStore';
import { ShowMoreOverlay } from 'App/Modules/Overlay/Modules/ShowMoreOverlay';
import { SubtitleMenuOverlay } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay';
import { AppExitPrompt } from 'App/Modules/Prompt/Modules/AppExitPrompt';
import { InternetConnectionLostAlert } from 'App/Modules/Alert/Modules/InternetConnectionLostAlert';
import { VodFolders } from 'App/Modules/Screen/Modules/VodFolders';
import { DetailEventScreen } from 'App/Modules/Screen/Modules/DetailEventScreen';
import { DetailSeriesScreen } from 'App/Modules/Screen/Modules/DetailSeriesScreen';
import { DetailVodAssetScreen } from 'App/Modules/Screen/Modules/DetailVodAssetScreen';
import { DetailRootScreen } from 'App/Modules/Screen/Modules/DetailRootScreen';
import { DetailVodSeriesScreen } from 'App/Modules/Screen/Modules/DetailVodSeriesScreen';
import { DetailNetworkRecordingScreen } from 'App/Modules/Screen/Modules/DetailNetworkRecordingScreen';
import { AdsScippingEngine } from 'App/Modules/AdsScipping';
import { CoreAdsScippingEngine } from 'App/Modules/AdsScipping/Implementation/Core';

export class Core {
    public static async initialize(): Promise<boolean> {
        console.log('[Platform.Core.Initialize]');
        EnhancedBack.initialize(new CoreBackEngine());
        AdsScippingEngine.initialize(new CoreAdsScippingEngine());
        Alert.register(Alert.Type.Basic, BasicAlert.View);
        Alert.register(Alert.Type.DeviceCannotDelete, DeviceCannotDeleteAlert.View);
        Alert.register(Alert.Type.LoginError, LoginErrorAlert.View);
        Alert.register(Alert.Type.BackEndDoesntWork, BackEndDoesntWorkAlert.View);
        Alert.register(Alert.Type.InternetConnectionLost, InternetConnectionLostAlert.View);

        Prompt.register(Prompt.Type.DeviceConfirmDelete, DeviceConfirmDeletePrompt.View);
        Prompt.register(Prompt.Type.MessageConfirmDelete, MessageConfirmDeletePrompt.View);
        Prompt.register(Prompt.Type.RecordingManagementConfirmDelete, RecordingManagementConfirmDeletePrompt.View);
        Prompt.register(Prompt.Type.RecordingConfirmCreate, RecordingConfirmCreatePrompt.View);
        Prompt.register(Prompt.Type.RecordingConfirmCreateSeries, RecordingConfirmCreateSeriesPrompt.View);
        Prompt.register(Prompt.Type.RecordingConfirmDelete, RecordingConfirmDeletePrompt.View);
        Prompt.register(Prompt.Type.RecordingConfirmCancel, RecordingConfirmCancelPrompt.View);
        Prompt.register(Prompt.Type.RecordingConfirmCancelSeries, RecordingConfirmCancelSeriesPrompt.View);
        Prompt.register(Prompt.Type.ChannelListConfirmDelete, ChannelListConfirmDelete.View);
        Prompt.register(Prompt.Type.AppExitPrompt, AppExitPrompt.View);
        Prompt.register(Prompt.Type.Logout, LogoutPrompt.View);

        Overlay.register(Overlay.Type.Loading, LoadingOverlay.View);
        Overlay.register(Overlay.Type.Splash, SplashOverlay.View);
        Overlay.register(Overlay.Type.CommunitySelect, ComunitySelectOverlay.View);
        Overlay.register(Overlay.Type.ThemeSelect, ThemeSelectOverlay.View);
        Overlay.register(Overlay.Type.AdultContentSelect, AdultContentSelectOverlay.View);
        Overlay.register(Overlay.Type.StartUpSelect, StartUpSelectOverlay.View);
        Overlay.register(Overlay.Type.MessageFilterSelect, MessageFilterSelectOverlay.View);
        Overlay.register(Overlay.Type.LiveClockSelect, LiveClockSelectOverlay.View);
        Overlay.register(Overlay.Type.ChannelListSelect, ChannelListSelectOverlay.View);
        Overlay.register(Overlay.Type.SubtitlesSelect, SubtitlesSelectOverlay.View);
        Overlay.register(Overlay.Type.AudioSelect, AudioSelectOverlay.View);
        Overlay.register(Overlay.Type.ShowMore, ShowMoreOverlay.View);
        Overlay.register(Overlay.Type.SubtitleMenu, SubtitleMenuOverlay.View, SubtitleMenuOverlay.lifecycle);

        Screen.register(Screen.Type.LOGIN_EXPIRED_CODE, LoginCodeExpired.View);
        Screen.register(Screen.Type.LOGIN_WITH_CODE, LoginWithCode.View);
        Screen.register(Screen.Type.LOGIN, LoginFlowSelector.View, LoginFlowSelector.lifecycle);
        Screen.register(Screen.Type.LOGIN_WITH_EMAIL, LoginWithEmail.View);

        Screen.register(Screen.Type.VideoStore, VideoStore.View, VideoStore.lifecycle);
        Screen.register(Screen.Type.MyLibrary, MyLibrary.View, MyLibrary.lifecycle);
        Screen.register(Screen.Type.VodFolders, VodFolders.View, VodFolders.lifecycle);
        Screen.register(Screen.Type.Detail, DetailRootScreen.View, DetailRootScreen.lifecycle);
        Screen.register(Screen.Type.VodAssetDetail, DetailVodAssetScreen.View, DetailVodAssetScreen.lifecycle);
        Screen.register(Screen.Type.EventDetail, DetailEventScreen.View, DetailEventScreen.lifecycle);
        Screen.register(Screen.Type.SeriesDetail, DetailSeriesScreen.View, DetailSeriesScreen.lifecycle);
        Screen.register(Screen.Type.VodSeriesDetail, DetailVodSeriesScreen.View, DetailVodSeriesScreen.lifecycle);
        Screen.register(Screen.Type.RecordingDetail, DetailNetworkRecordingScreen.View, DetailNetworkRecordingScreen.lifecycle);
        return true;
    }
}
