import React from 'react';

import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { CtxContext } from 'App/Packages/Focus/Context/CtxContext';
import { IsActiveContext } from 'App/Packages/Focus/Context/IsActiveContext';
import { OutOfBoundsContext } from 'App/Packages/Focus/Context/OutOfBoundsContext';
import { SetCtxContext } from 'App/Packages/Focus/Context/SetCtxContext';
import { OutOfBounds } from 'App/Packages/Focus/Types/OutOfBounds';
import { SetCtx } from 'App/Packages/Focus/Types/SetCtx';

export type ProviderProps = {
    ctx: Selector<string>;
    setCtx: SetCtx;
    isActive: Selector<boolean>;
    outOfBounds: OutOfBounds;
};

export const Provider: React.FunctionComponent<React.PropsWithChildren<ProviderProps>> = (props) => (
    <IsActiveContext.Provider value={props.isActive}>
        <OutOfBoundsContext.Provider value={props.outOfBounds}>
            <CtxContext.Provider value={props.ctx}>
                <SetCtxContext.Provider value={props.setCtx}>{props.children}</SetCtxContext.Provider>
            </CtxContext.Provider>
        </OutOfBoundsContext.Provider>
    </IsActiveContext.Provider>
);
