import { Quota } from '__SMART_APP_OLD__/api/graphql/types';
import { API } from '__SMART_APP_OLD__/app/api';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import {
    RecordingAddRecordingAction,
    RecordingAddSeriesRecordingsAction,
    RecordingDeleteSuccessAction,
    RecordingLoadManyAction,
    RecordingRemoveRecordingAction,
} from '__SMART_APP_OLD__/app/modules/Data/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { textNotificationShow } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import { recordingManageUpdateAfterDeleteCall } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/actions';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { isFulfilled, isRejected } from '__SMART_APP_OLD__/app/utils/promise/promise';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';

export const recordingLoadManySuccess = (recordings: GQL.Recording[]): RecordingLoadManyAction => ({
    type: ActionType.RECORDING_LOAD_MANY_SUCCESS,
    payload: { recordings },
});

export const recordingDeleteSuccess = (removedIds: string[]): RecordingDeleteSuccessAction => ({
    type: ActionType.RECORDING_DELETE_SUCCESS,
    payload: { removedIds },
});

export const recordingsDelete =
    (recordingsToDelete: string[]): Thunk<Promise<void>> =>
    async (dispatch) => {
        try {
            dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));

            const response = await Promise.allSettled(
                recordingsToDelete.map(async (id) => API.Recording.deleteRecording({ input: { recordingId: id } }))
            );
            const quotaResponse = await API.Recording.recordingQuotaRequest();
            dispatch(Household.actions.recordingQuotaLoad(quotaResponse.data?.recordingQuota! as Quota));

            const successResults = response.filter(isFulfilled)?.map((operation) => operation.value);
            const ids = successResults.map((result) => result.data?.deleteRecording.event?.id).filter(Boolean) as string[];
            const failResults = response.filter(isRejected);

            if (successResults.length !== 0 && failResults.length !== 0) {
                dispatch(textNotificationShow('RECORDING_MANAGE_DELETE_PARTIAL_SUCCESS'));
            }
            if (successResults.length && !failResults.length) dispatch(textNotificationShow('RECORDING_MANAGE_DELETE_SUCCESS'));

            dispatch(recordingDeleteSuccess(ids));
            dispatch(recordingManageUpdateAfterDeleteCall([]));

            dispatch(Overlay.actions.unmount());
        } catch (errors) {
            dispatch(textNotificationShow('RECORDING_MANAGE_DELETE_FAILED'));
            dispatch(Prompt.actions.unmount());
            dispatch(Overlay.actions.unmount());
        }
    };

export const addRecording = (recording: GQL.Recording): RecordingAddRecordingAction => ({
    type: ActionType.RECORDING_ADD_RECORDING,
    payload: { recording },
});

export const addSeriesRecording = (recordings: GQL.Recording[]): RecordingAddSeriesRecordingsAction => ({
    type: ActionType.RECORDING_ADD_SERIES_RECORDING,
    payload: { recordings },
});

export const removeRecording = (removedIds: GQL.EventID[]): RecordingRemoveRecordingAction => ({
    type: ActionType.RECORDING_REMOVE_RECORDING,
    payload: { removedIds },
});

export const recordingsLoadMany = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const state = getState();
        const backgroundWidth = state.config.image.background.width;
        const backgroundHeight = state.config.image.background.landscape;
        const { recordings } = await API.Recording.loadMany({
            pagination: { first: 1000 },
            filter: {},
            backgroundWidth,
            backgroundHeight,
        });
        dispatch(recordingLoadManySuccess(recordings));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.RECORDING_LOAD, error));
        return false;
    }
};
