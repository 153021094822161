import { collectionActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/actions';
import { getInitialCollectionState } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/getInitialState';
import { collectionReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/reducer';
import { collectionSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/selectors';

export type {
    CollectionActionType as ActionType,
    CollectionResetAction as ResetAction,
    CollectionReduxAction as ReduxAction,
    CollectionLoadedAction as LoadedAction,
    CollectionEntity as Entity,
    CollectionEntityTable as EntityTable,
    CollectionLoadedActionPayload as LoadedActionPayload,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Collection/store/types';

export const getInitialState = getInitialCollectionState;

export const actions = collectionActions.public;

export const selectors = collectionSelectors.public;

export const reducer = collectionReducer;
