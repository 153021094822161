import { Subscribable } from 'App/Packages/Subscribable';
import { InputChar } from 'App/Packages/UI/Input/Components/InputChar';
import React from 'react';

export type InputValueProps = {
    carret: Subscribable.Value<number>;
    value: Subscribable.Value<string>;
    focused: Subscribable.Value<boolean>;
    block?: string;
    hidden?: boolean;
};

export const InputValue: React.FunctionComponent<InputValueProps> = (props) => {
    const { block, hidden } = props;
    const carret = Subscribable.use(props.carret);
    const value = Subscribable.use(props.value);
    const focused = Subscribable.use(props.focused);
    return (
        <>
            {value.split('').map((char, index) => (
                <InputChar key={index} active={carret === index && focused} block={block} hidden={hidden}>
                    {char}
                </InputChar>
            ))}
            <InputChar active={carret === value.length && focused} block={block} hidden={hidden}>
                {''}
            </InputChar>
        </>
    );
};
