import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { Time } from '__SMART_APP_OLD__/app/modules/Time';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { ContentMarkerType } from 'App/Types/ContentMarker';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { selectRecording, selectRecordingByEventId } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';

import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { RecordingEntity } from '__SMART_APP_OLD__/app/store/types/state/data/recording';
import { selectIsSeriesRecordingAllowed, selectResumeThreshold } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Env } from 'App/Env';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { getTimeGapInDays, isFuture } from '__SMART_APP_OLD__/utils/timeUtils';
import { ReminderEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { selectReminderByEventId } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { canResume, getPlayButtonLabel } from 'App/Modules/Screen/Modules/DetailRootScreen/Store/helpers';
import { DetailItem } from 'App/Modules/Data/Detail/Root/Types/Item';
import { assetTypeForContentItemType } from 'App/Modules/Data/Detail/Root/Constants/Constants';

const selectAssetId =
    (selector: Selector<DetailItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.id ?? '';
const selectAssetTitle =
    (selector: Selector<DetailItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.title ?? '';
const selectAssetActors =
    (selector: Selector<DetailItem | null>): Selector<string[]> =>
    (state) =>
        selector(state)?.metadata.actors ?? [];
const selectAssetDirectors =
    (selector: Selector<DetailItem | null>): Selector<string[]> =>
    (state) =>
        selector(state)?.metadata.directors ?? [];
const selectAssetDescription =
    (selector: Selector<DetailItem | null>): Selector<string> =>
    (state) =>
        selector(state)?.content.description ?? '';
const selectAssetIsFavourited =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) =>
        Favourites.selectors.selectIsFavourited(selectAssetId(selector)(state))(state);
const selectAssetContentType =
    (selector: Selector<DetailItem | null>): Selector<ContentItemType> =>
    (state) =>
        selector(state)?.type ?? ContentItemType.Event;
const selectAssetType =
    (selector: Selector<DetailItem | null>): Selector<AssetType> =>
    (state) =>
        assetTypeForContentItemType[selectAssetContentType(selector)(state)];
const selectAssetHasTrailer =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) =>
        !!selector(state)?.metadata?.hasTrailer;
const selectAssetDuration =
    (selector: Selector<DetailItem | null>): Selector<number> =>
    (state) =>
        selector(state)?.metadata.duration ?? 0;
const selectAssetCanBeRented: Selector<boolean> = (state) => VodUpsell.selectors.selectAssetCanBeRented(state);
const selectAssetCanBeSubscribed: Selector<boolean> = (state) => VodUpsell.selectors.selectAssetCanBeSubscribed(state);
const selectAssetCanBePurchased: Selector<boolean> = (state) => VodUpsell.selectors.selectAssetCanBePurchased(state);
const selectAssetBookmark =
    (selector: Selector<DetailItem | null>): Selector<Bookmarks.Entity | null> =>
    (state) =>
        Bookmarks.selectors.selectEntity(selectAssetId(selector)(state))(state);
const selectAssetRecording =
    (selector: Selector<DetailItem | null>): Selector<RecordingEntity | null> =>
    (state) =>
        selectRecordingByEventId(selectAssetId(selector)(state))(state);
const selectAssetCanBeWatched =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) =>
        !!selector(state)?.entitlements?.playback;
const selectAssetReminder =
    (selector: Selector<DetailItem | null>): Selector<ReminderEntity | null> =>
    (state) =>
        selectReminderByEventId(selectAssetId(selector)(state))(state);

const selectAssetIsLive =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return false;
        const now = Time.selectors.select(state);
        if (!asset.metadata.start || !asset.metadata.end) return false;
        return asset.metadata.start <= now && asset.metadata.end >= now;
    };

const selectAssetAvailableUntil =
    (selector: Selector<DetailItem | null>): Selector<string | undefined> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return undefined;
        if (asset.type === ContentItemType.Networkrecording) return getTimeGapInDays(selectRecording(asset.id)(state)?.availableUntil);
        if (asset.type === ContentItemType.Vodasset) return getTimeGapInDays(asset.entitlements.playbackAvailableUntil);
        return undefined;
    };
const selectAssetProgress =
    (selector: Selector<DetailItem | null>): Selector<number> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return 0;
        const isLive = selectAssetIsLive(selector)(state);
        if (!isLive) {
            const bookmark = Bookmarks.selectors.selectEntity(asset.id)(state);
            if (!bookmark) return 0;
            return bookmark.progress;
        }
        const now = Time.selectors.select(state);
        if (!asset.metadata.start || !asset.metadata.end) return 0;
        if (now < asset.metadata.start) return 0;
        if (now > asset.metadata.end) return 0;
        return ((now - asset.metadata.start) / (asset.metadata.end - asset.metadata.start)) * 100;
    };

const selectAssetProgressColor =
    (selector: Selector<DetailItem | null>): Selector<BackgroundColor> =>
    (state) => {
        const item = selector(state);
        if (!item) return BackgroundColor.HOT_RED_PRIMARY;
        const isLive = selectAssetIsLive(selector)(state);
        if (isLive) return BackgroundColor.COOL_BLUE_PRIMARY;
        return BackgroundColor.HOT_RED_PRIMARY;
    };

const selectAssetContentMarker =
    (selector: Selector<DetailItem | null>): Selector<ContentMarkerType | undefined> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return undefined;
        const isLive = selectAssetIsLive(selector)(state);
        const now = Time.selectors.select(state);
        if (isLive) return ContentMarkerType.Live;
        if (asset.type === ContentItemType.Networkrecording) return ContentMarkerType.Recorded;
        if (asset.entitlements.playback && asset.entitlements.playbackAvailableUntil > now) return ContentMarkerType.Rented;
        return undefined;
    };

const selectAssetIsRecordable =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const asset = selector(state);

        if (!asset || Env.isBusiness) return false;

        const { networkRecording, networkRecordingPlannableUntil } = asset.entitlements;
        const { start, end } = asset.metadata;

        return Utils.isEventRecordable(networkRecording, start ?? 0, end ?? 0, networkRecordingPlannableUntil);
    };

const selectAssetIsProgressVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const assetProgress = selectAssetProgress(selector)(state);
        const resumeThreshold = selectResumeThreshold(state);
        const assetType = selectAssetType(selector)(state);

        return !!assetProgress && assetProgress < resumeThreshold && assetType !== AssetType.EPISODE;
    };

const selectAssetCanBeStartedOver =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return false;
        const progress = selectAssetProgress(selector)(state);
        const bookmark = selectAssetBookmark(selector)(state);
        const resumeThreshold = selectResumeThreshold(state);

        return canResume(asset, progress, bookmark, resumeThreshold);
    };

const selectAssetIsStartRecordingVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const isRecordable = selectAssetIsRecordable(selector)(state);
        const isRecorded = !!selectAssetRecording(selector)(state);

        return isRecordable && !isRecorded;
    };

const selectAssetIsSeriesRecordingVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const isRecordable = selectAssetIsRecordable(selector)(state);
        const isRecorded = !!selectAssetRecording(selector)(state);
        const isSeriesRecordingAllowed = selectIsSeriesRecordingAllowed(state);

        return isRecordable && isRecorded && isSeriesRecordingAllowed;
    };

const selectAssetIsStopRecordingVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const isRecordable = selectAssetIsRecordable(selector)(state);
        const recording = selectAssetRecording(selector)(state);
        const isRecorded = !!recording;

        return isRecordable && isRecorded && recording?.status !== GQL.RecordingStatus.Completed;
    };

const selectAssetIsDeleteRecordingVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const isRecordable = selectAssetIsRecordable(selector)(state);
        const recording = selectAssetRecording(selector)(state);
        const isRecorded = !!recording;

        return isRecordable && isRecorded && recording?.status === GQL.RecordingStatus.Completed;
    };

const selectAssetIsReminderVisible =
    (selector: Selector<DetailItem | null>): Selector<boolean> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return false;
        const assetType = selectAssetType(selector)(state);

        return isFuture(asset.metadata.start) && assetType === AssetType.EVENT;
    };

const selectAssetWatchButtonText =
    (selector: Selector<DetailItem | null>): Selector<string> =>
    (state) => {
        const asset = selector(state);
        if (!asset) return '';
        const progress = selectAssetProgress(selector)(state);
        const bookmark = selectAssetBookmark(selector)(state);
        const resumeThreshold = selectResumeThreshold(state);
        return getPlayButtonLabel(asset, progress, bookmark, resumeThreshold);
    };

export const assetDetailSelectors = {
    selectAssetId,
    selectAssetTitle,
    selectAssetActors,
    selectAssetDirectors,
    selectAssetDescription,
    selectAssetIsFavourited,
    selectAssetType,
    selectAssetContentType,
    selectAssetHasTrailer,
    selectAssetDuration,
    selectAssetRecording,
    selectAssetAvailableUntil,
    selectAssetProgress,
    selectAssetProgressColor,
    selectAssetContentMarker,
    selectAssetIsProgressVisible,
    selectAssetCanBeStartedOver,
    selectAssetCanBeWatched,
    selectAssetIsStartRecordingVisible,
    selectAssetIsSeriesRecordingVisible,
    selectAssetIsStopRecordingVisible,
    selectAssetIsDeleteRecordingVisible,
    selectAssetReminder,
    selectAssetIsReminderVisible,
    selectAssetBookmark,
    selectAssetCanBeRented,
    selectAssetCanBePurchased,
    selectAssetCanBeSubscribed,
    selectAssetWatchButtonText,
} as const;
