/* eslint-disable max-statements */
/* eslint-disable max-lines */
import React from 'react';

import { ContentFolderKind } from '__SMART_APP_OLD__/api/graphql/types';
import { connect } from '__SMART_APP_OLD__/app/hoc/connect';
import { selectConfig } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import Button from '__SMART_APP_OLD__/components/common/Button';
import EmptyPage from '__SMART_APP_OLD__/components/common/EmptyPage';
import { RecordingQuota } from '__SMART_APP_OLD__/components/common/RecordingQuota';
import Gallery from '__SMART_APP_OLD__/components/stripe/Gallery';
import Stripe from '__SMART_APP_OLD__/components/stripe/Stripe';
import Events, { CONTAINER_SCROLLED, DARK_MODE_CHANGED } from '__SMART_APP_OLD__/config/Events';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { NavKey, Route, StripeType } from '__SMART_APP_OLD__/utils/Constants';
import State from '__SMART_APP_OLD__/utils/State';
import { changePlayerVisibility } from '__SMART_APP_OLD__/utils/Utils';
import { getToManageRecordingsEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import translate from 'language/translate';

import { Key } from 'App/Modules/Key';
import { Screen } from 'App/Modules/Screen';
import { Direction } from 'App/Packages/Direction';
import { Calc } from 'App/Packages/Calc';

class Stripes extends React.Component {
    nodeRef = null;

    stripeNodeRef = null;

    hasGallery = false;

    wasInitialFocusSet = false;

    hasQuota = false;

    constructor(props) {
        super(props);
        Stripe.clearActiveIndexStore();
        if (window.location.pathname.includes(Route.HOME)) {
            changePlayerVisibility(true);
        } else {
            changePlayerVisibility(false);
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: false } }));
        }
        const playerView = true;
        // analyze last focused stripe
        if (State.hasState(this.stripeNavKeys) && State.get(this.stripeNavKeys)?.stripeId !== StripeType.ASSET_DETAILS) {
            const { stripes, galleryAssets } = props;
            const { stripeIndex, stripeId } = State.get(this.stripeNavKeys);

            const galleryLength = galleryAssets?.length || 0;
            const lastStripeActiveIndex = stripeIndex;
            const lastFocusedStripe = stripes?.[lastStripeActiveIndex];
            const lastFocusedStripeHasSamePosition = lastFocusedStripe?.stripeId === stripeId;

            if (lastFocusedStripeHasSamePosition && lastFocusedStripe?.assets?.length) {
                this.state = { stripeIndex: lastStripeActiveIndex, stripeId, playerView };
                this.checkAndChangeStripeState(this.state.stripeIndex);
                return;
            }

            const lastFocuseStripeNewPosition = stripes?.findIndex((stripe) => stripe.stripeId === stripeId);
            const isStripeExist = lastFocuseStripeNewPosition !== -1 && lastFocusedStripe?.assets?.length;

            if (isStripeExist) {
                this.state = { stripeIndex: lastFocuseStripeNewPosition, stripeId, playerView };
                this.checkAndChangeStripeState(this.state.stripeIndex);
                return;
            }
            // stripe which was in the focus does not exist any more, need to switch to another one
            const stripesLength = stripes.length;
            const nearestStripe = lastStripeActiveIndex > 0 ? lastStripeActiveIndex - 1 : 0;
            if (stripesLength > nearestStripe) {
                this.state = { stripeIndex: nearestStripe, stripeId, playerView };
                State.set('Stripe', {
                    scrollLeft: 0,
                    menuId: props.filterId,
                    stripeindex: this.state.stripeIndex + galleryLength,
                    stripeId: stripes?.[this.state.stripeIndex].stripeId,
                    activeAssetId: stripes?.[this.state.stripeIndex]?.assets?.[0]?.id,
                    activeIndex: 0,
                });
                return;
            }
        }

        this.state = { stripeIndex: 0, stripeId: null, playerView };
    }

    get isPlayerView() {
        return this.state.playerView && this.isWidePlayerViewEnabled;
    }

    get isWidePlayerViewEnabled() {
        return (
            this.props?.playerTargetDetails?.isPlayerAvailable &&
            document.location.pathname.includes(Route.HOME) &&
            this.props.isHomePlayerWideViewEnabled &&
            this.props.isHomeScreenPlayerEnabled
        );
    }

    checkAndChangeStripeState = (currentStripeIndex, checkIndex = true) => {
        const hasStripeState = State.hasState('Stripe');
        if (!hasStripeState) return;
        const stripeState = State.get('Stripe');
        const { stripes, filterId } = this.props;
        const currentStripe = stripes?.[currentStripeIndex];
        const { assets } = currentStripe;
        const lastFocusedAssetIndex = assets.findIndex((asset) => asset.id === stripeState.activeAssetId);
        let activeIndex = 0;
        if (checkIndex) {
            if (lastFocusedAssetIndex !== -1) {
                activeIndex = lastFocusedAssetIndex;
            } else if (assets?.length > stripeState.activeIndex && stripeState.activeIndex > 0) {
                activeIndex = stripeState.activeIndex - 1;
            } else {
                activeIndex = 0;
            }
        }
        State.set('Stripe', {
            scrollLeft: 0,
            menuId: filterId,
            stripeindex: currentStripeIndex,
            stripeId: stripes?.[currentStripeIndex].stripeId,
            activeAssetId: assets?.[activeIndex]?.id,
            activeIndex: lastFocusedAssetIndex === -1 ? false : lastFocusedAssetIndex,
        });
    };

    componentDidMount() {
        const { onFocusStripeChange, onActivity = () => {} } = this.props;
        const { stripeIndex } = this.state;
        if (this.isPlayerView) window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: true } }));
        if (this.nodeRef) {
            const container = this.nodeRef.nodeRef.nodeRef;
            if (container) {
                container.addEventListener('scroll', () => {
                    if (document.location.pathname.includes(Route.HOME)) {
                        changePlayerVisibility(container.scrollTop === 0);
                    } else {
                        changePlayerVisibility(false);
                    }
                    Events.triggerEvents(CONTAINER_SCROLLED, container.scrollTop);
                });
            }
            let activityLogged = false;
            if (State.hasState(this.stripeNavKeys) && State.get(this.stripeNavKeys)?.stripeId !== StripeType.ASSET_DETAILS) {
                const { scrollTop } = State.get(this.stripeNavKeys);
                container.scrollTop = scrollTop;
                this.setState({ playerView: false });
                if (stripeIndex === 0) {
                    activityLogged = true;
                    onActivity(() => this.setState({ playerView: true }));
                }
                onFocusStripeChange(stripeIndex);
            } else if (!this.wasInitialFocusSet && this.stripeNodeRef && this.hasGallery && window.location.pathname.includes(Route.HOME)) {
                this.wasInitialFocusSet = true;
                const button = document.querySelector('[data-test-id="button_watch"]');
                if (button) {
                    Focus.focus(button);
                }
            } else if (this.stripeNodeRef && !this.hasGallery) {
                this.wasInitialFocusSet = true;
                Focus.focus(this.stripeNodeRef);
            }
            if (!activityLogged) {
                onActivity();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const stripeState = State.get('Stripe');
        if (!this.wasInitialFocusSet && this.stripeNodeRef && !this.hasGallery && stripeState?.stripeindex === undefined) {
            this.wasInitialFocusSet = true;
            Focus.focus(this.stripeNodeRef);
        }
        if (!this.wasInitialFocusSet && this.stripeNodeRef && this.hasGallery && stripeState?.stripeindex === undefined) {
            this.wasInitialFocusSet = true;
            const button = document.querySelectorAll('[data-test-id="button_watch"]');
            if (button) {
                Focus.focus(button[0]);
            }
        }
        if (!window.location.pathname.includes(Route.HOME)) return;
        const player = document.getElementById('player');
        if (!this.isPlayerView && prevState.playerView) {
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: false } }));
            player.classList.add('gallery-player');
        } else if (this.isPlayerView && (!prevState.playerView || !prevProps.playerTargetDetails.isPlayerAvailable)) {
            window.dispatchEvent(new CustomEvent(DARK_MODE_CHANGED, { detail: { mode: true } }));
            player.classList.remove('gallery-player');
        }
    }

    componentWillUnmount() {
        if (this.nodeRef) {
            const container = this.nodeRef.nodeRef.nodeRef;
            if (container) {
                container.removeEventListener('scroll', () => this.updateHeaderGradient(container));
            }
        }
    }

    get stripeNavKeys() {
        const { filterId } = this.props;
        return `${NavKey.STRIPES}-${filterId}`;
    }

    onKey = (keyCode, stripeIndex, recordingQuota) => {
        const [, y] = Direction.get(keyCode);

        if (y === 0) return false;
        if (y === -1 && recordingQuota) return false;
        if (y === -1 && stripeIndex === 0) return false;
        if (y === 1 && stripeIndex === this.props.stripes.length - 1) return false;

        const stripe = this.getStripeByIndex(stripeIndex + y);
        if (!stripe) return false;

        const hasQuota = this.getStripeHasQuota(stripe);
        if (hasQuota) return false;

        const item = this.getStripeActiveItem(stripe);
        Focus.focus(item);
        return true;
    };

    getStripeByIndex = (index) => document.getElementById(this.props?.stripes[index]?.stripeId);

    getStripeItems = (stripe) => {
        if (!stripe) return null;
        return stripe?.querySelector('.tiles') ?? null;
    };

    getStripeHasQuota = (stripe) => stripe?.dataset?.hasQuota === 'true';

    getStripeActiveItem = (stripe) => {
        const items = this.getStripeItems(stripe);
        if (!items) return null;
        // eslint-disable-next-line no-unsafe-optional-chaining
        const item = items?.children?.item(+stripe?.dataset?.activeIndex || 0);
        if (!item) return null;
        return item;
    };

    getStripeActiveItemByIndex = (index) => {
        const stripe = this.getStripeByIndex(index);
        if (!stripe) return null;
        const item = this.getStripeActiveItem(stripe);
        if (!item) return null;
        return item;
    };

    // eslint-disable-next-line complexity,react-func/max-lines-per-function
    onFocusChanged = ({ focusedNode }) => {
        const { loadNextData, onActivity = () => {} } = this.props;
        if (!focusedNode) return true;
        let parent = focusedNode.nodeRef.parentNode;
        let parentDom = null;

        while (parent && parent !== document.body) {
            if (parent?.classList.contains('stripe') || parent?.classList.contains('gallery')) {
                parentDom = parent;
                break;
            }
            parent = parent.parentNode;
        }

        if (parent.classList.contains('gallery') && !this.state.playerView) {
            State.set(this.stripeNavKeys, {
                stripeId: StripeType.ASSET_DETAILS,
            });
            this.setState({ playerView: true });
        } else if (parent.classList.contains('stripe') && this.state.playerView) {
            this.setState({ playerView: false });
        }

        if (this.state.stripeIndex === 0) {
            onActivity(() => this.setState({ playerView: true }));
        } else {
            onActivity();
        }

        if (!parentDom || !this.nodeRef) {
            if (focusedNode) {
                this.focusedNodeToScroll = focusedNode;
            } else {
                console.warn("Don't know where to focus!");
            }
            return false;
        }

        const container = this.nodeRef.nodeRef.nodeRef;
        const childNodes = [];
        container.childNodes.forEach((node) => childNodes.push(node));

        if (this.hasGallery) {
            childNodes.shift();
        }

        const focusedIndex = childNodes.indexOf(parentDom);

        if (focusedIndex < 0) {
            container.scrollTop = 0;
            console.warn("HTML Structure changed, can't resolve focus!");
            return false;
        }
        if (focusedIndex === 0) {
            container.scrollTop = 0;
            focusedNode?.nodeRef?.closest?.('.stripe')?.children?.forEach?.((item) => {
                if (item?.classList?.contains?.('stripe-quota')) container.scrollTop = 128;
            });
        } else {
            let count = 0;

            if (this.hasGallery) {
                // when gallery has no slider,
                // the child node is removed, so initial scroll start is imitated by 500
                count += Calc.pixel(500);
                console.log('count.hasGallery', count);
            }

            if (!this.hasGallery) {
                count += Calc.pixel(256); // padding top value of .no-gallery
                console.log('count.noGallery', count);
            }

            for (let i = 0; i < focusedIndex; i += 1) {
                const node = childNodes[i];
                const size = node.offsetHeight;

                count += i !== focusedIndex - 1 ? size : size / 2;
            }
            container.scrollTop = count;
        }
        const { stripeIndex, stripeId } = this.state;
        loadNextData(stripeIndex);
        State.set(this.stripeNavKeys, {
            stripeIndex,
            stripeId,
            scrollTop: container.scrollTop,
        });

        return true;
    };

    getDataTestId(config) {
        if (config?.track_id) return config.track_id;
        return '';
    }

    genGallery() {
        const { filterId, configSource, galleryAssets, playerTargetDetails, parentalRatingRank, showScrollableGallery, sessionType } =
            this.props;
        const pageConfig = configSource(filterId);
        let gallery = null;
        if (galleryAssets?.length) {
            const key = `${filterId}_gallery`;
            const galleryConfigIndex = pageConfig.findIndex((config) => config.type === StripeType.GALLERY);
            const galleryConfig = pageConfig[galleryConfigIndex];
            const className = `stripe gallery-${galleryConfig?.variant}`;
            gallery = (
                <Gallery
                    key={key}
                    id="gallery"
                    assets={galleryAssets}
                    config={galleryConfig}
                    className={className}
                    type={StripeType.GALLERY}
                    stripeindex={galleryConfigIndex}
                    parentalRatingRank={parentalRatingRank}
                    playerTargetDetails={playerTargetDetails}
                    showScrollableGallery={showScrollableGallery}
                    playerView={this.isPlayerView}
                    sessionType={sessionType}
                />
            );

            this.hasGallery = true;
        }

        return gallery;
    }

    getRecordQuota = (type, stripeIndex) => {
        if (this.props?.filterId === Route.MY_LIBRARY && type === ContentFolderKind.Recordings && !this.hasQuota) {
            this.hasQuota = true;
            return (
                <Container className="stripe-quota">
                    <>
                        <RecordingQuota />
                        <Button
                            onKey={(keyCode) => {
                                if (keyCode !== Key.VK_DOWN && keyCode !== Key.VK_UP) {
                                    return false;
                                }
                                const index = keyCode === Key.VK_DOWN ? stripeIndex : stripeIndex - 1;
                                const stripeId = this.props?.stripes[index]?.stripeId;

                                if (!stripeId) return false;
                                const stripe = document.getElementById(stripeId);
                                const items = stripe?.querySelector('.tiles');

                                if (!stripe) {
                                    return false;
                                }

                                const item = items?.children?.item(+stripe.dataset.activeIndex || 0);
                                Focus.focus(item);
                                return true;
                            }}
                            key="manage-button"
                            className="manage-button"
                            label={translate('MANAGE_RECORDINGS_KEY')}
                            onEnter={() => {
                                const currentStripe = this.props.stripes[this.state.stripeIndex];
                                if (currentStripe.type === ContentFolderKind.Recordings) {
                                    // focused and distance calculated in onFocusChanged,
                                    // so just push and return
                                    this.props.toRecordingManagement();
                                    return;
                                }
                                const recordingsStripeIndex = this.state.stripeIndex + 1;
                                const stripe = this.props.stripes[recordingsStripeIndex];
                                const container = this.nodeRef.nodeRef.nodeRef;
                                const childNodes = [];
                                let count = 0;
                                container.childNodes.forEach((node) => childNodes.push(node));
                                if (this.hasGallery) {
                                    count += 500;
                                }
                                if (!this.hasGallery) {
                                    count += 256; // padding top value of .no-gallery
                                }
                                for (let i = 0; i < recordingsStripeIndex; i += 1) {
                                    const node = childNodes[i];
                                    const nodeStyle = window.getComputedStyle(node);
                                    const size = node.offsetHeight + parseInt(nodeStyle['margin-top'], 10);

                                    count += i !== recordingsStripeIndex - 1 ? size : size / 1.8;
                                }
                                State.set(this.stripeNavKeys, {
                                    stripeId: stripe.stripeId,
                                    stripeIndex: recordingsStripeIndex,
                                    scrollTop: count,
                                });
                                this.setState({ ...this.state, stripeIndex: recordingsStripeIndex, stripeId: stripe.stripeId });
                                this.props.onFocusStripeChange(recordingsStripeIndex);
                                this.checkAndChangeStripeState(recordingsStripeIndex, false);
                                this.props.toRecordingManagement();
                            }}
                        />
                    </>
                </Container>
            );
        }
        return null;
    };

    render() {
        const { stripeIndex } = this.state;
        const { filterId, stripes, parentalRatingRank, noHeaderNeeded } = this.props;
        this.hasQuota = false;
        const gallery = this.genGallery();
        if ((!stripes || !stripes.length) && !gallery) {
            return <EmptyPage />;
        }
        // we need to keep the first stripe all the time to
        // not lose the focus after keyDown from the header
        const recordingQuota = this.getRecordQuota(stripes[0]?.type, 0);

        const firstStripe = stripes[0]?.assets.length ? (
            <Stripe
                onKey={(keycode) => this.onKey(keycode, 0, !!recordingQuota)}
                recordQuota={recordingQuota}
                stripeId={stripes[0].stripeId}
                key={stripes[0].stripeId}
                className={this.isPlayerView ? 'stripe no-title' : 'stripe'}
                assets={stripes[0].assets}
                menuId={filterId}
                stripeindex={0}
                type={stripes[0].type}
                title={stripes[0].title || ''}
                parentalRatingRank={parentalRatingRank}
                onFocusChanged={this.onFocusChanged}
                onFocus={() => {
                    if (this.state.stripeId !== stripes[0].stripeId) {
                        this.setState({ stripeIndex: 0, stripeId: stripes[0].stripeId });
                    }
                }}
                ref={(ref) => {
                    if (ref && !this.stripeNodeRef) {
                        const { nodeRef } = ref.nodeRef;
                        if (nodeRef.nodeRef.children) {
                            const [firstChild] = nodeRef.nodeRef.children;
                            this.stripeNodeRef = firstChild;
                        }
                    }
                }}
            />
        ) : null;
        let galleryClassName = '';
        if (noHeaderNeeded) {
            galleryClassName = 'no-header';
        } else if (this.isPlayerView) {
            galleryClassName = 'has-player';
        } else if (gallery) {
            galleryClassName = 'has-gallery';
        } else {
            galleryClassName = 'no-gallery';
        }
        return (
            <Container
                className={`stripes-container ${galleryClassName}`}
                id="stripes-container"
                onFocusChanged={(node) => this.onFocusChanged({ focusedNode: node })}
                ref={(ref) => {
                    this.nodeRef = ref;
                }}
            >
                {gallery}
                {firstStripe}
                {stripes
                    .map((stripe, index) => {
                        if (!stripe.assets.length) {
                            return null;
                        }
                        const recQuota = this.getRecordQuota(stripe.type, index);

                        return (
                            <Stripe
                                onKey={(keycode) => this.onKey(keycode, index, !!recQuota)}
                                recordQuota={recQuota}
                                stripeId={stripe.stripeId}
                                key={stripe.stripeId}
                                className={this.isPlayerView && index === 0 ? 'stripe no-title' : 'stripe'}
                                assets={stripe.assets}
                                menuId={filterId}
                                // isRelated={isRelated}
                                stripeindex={index}
                                type={stripe.type}
                                title={stripe.title || ''}
                                parentalRatingRank={parentalRatingRank}
                                onFocusChanged={this.onFocusChanged}
                                onFocus={() => {
                                    if (this.state.stripeId !== stripe.stripeId) {
                                        this.setState({ stripeIndex: index, stripeId: stripe.stripeId });
                                    }
                                }}
                                ref={(ref) => {
                                    if (ref && !this.stripeNodeRef) {
                                        const { nodeRef } = ref.nodeRef;
                                        if (nodeRef.nodeRef.children) {
                                            const [firstChild] = nodeRef.nodeRef.children;
                                            this.stripeNodeRef = firstChild;
                                        }
                                    }
                                }}
                            />
                        );
                    })
                    .filter(Boolean)
                    .slice(Math.max(stripeIndex - 2, 1), stripeIndex + 3)}
            </Container>
        );
    }
}

export default connect(
    (state) => {
        const config = selectConfig(state);
        return {
            isHomePlayerWideViewEnabled: config.home.player.wideView,
            isHomeScreenPlayerEnabled: config.home.player.enabled,
            backgroundWidth: config.image.background.width,
            backgroundHeight: config.image.background.landscape,
        };
    },
    (dispatch) => ({
        toRecordingManagement: () => {
            dispatch(Screen.actions.mount(Screen.Type.RECORDING_MANAGEMENT, {}));
            LoggingService.getInstance().logEvent(getToManageRecordingsEvent());
        },
    })
)(Stripes);
