import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { ContentRef, ContentTypes } from 'analytics/logging/classes/ContentRef';

export const getObjectByRefObjectFromCache = (obj) => GraphqlClient.cache.data.data[obj?.__ref] ?? {};

export const getChannelByIdFromCache = (id) => GraphqlClient.cache.data.data[`Channel:${id}`] ?? {};

export const getVODAssetByIdFromCache = (id) => GraphqlClient.cache.data.data[`VODAsset:${id}`] ?? {};

export const getTrailerByIdFromCache = (id) => GraphqlClient.cache.data.data[`Trailer:${id}`] ?? {};

export const getEventByIdFromCache = (id) => GraphqlClient.cache.data.data[`Event:${id}`] ?? {};

export const getRecordingByIdFromCache = (id) => GraphqlClient.cache.data.data[`NetworkRecording:${id}`] ?? {};

export const getChannelListByIdFromCache = (id) => GraphqlClient.cache.data.data[`ChannelList:${id}`] ?? {};

export const getContentRefFiledsFromMetadataRefObject = (metadata) => {
    const { originalTitle, genre, episodeInfo, seriesInfo } = getObjectByRefObjectFromCache(metadata);
    const { title: genreName } = getObjectByRefObjectFromCache(genre);
    let seriesId;
    let episodeId;
    let episodeName;
    let episodeNumber;
    let seasonNumber;
    if (seriesInfo) {
        const { id } = getObjectByRefObjectFromCache(seriesInfo);
        seriesId = id;
    }
    if (episodeInfo) {
        const { id, title, number, season } = getObjectByRefObjectFromCache(episodeInfo);
        episodeId = id;
        episodeName = title;
        episodeNumber = number;
        seasonNumber = season;
    }
    const episodeSequenceName = episodeNumber && `${episodeNumber}`;
    const seasonSequenceName = seasonNumber && `${seasonNumber}`;
    return {
        originalTitle,
        episodeId,
        episodeName,
        seriesId,
        genreName,
        episodeSequenceName,
        seasonSequenceName,
    };
};

export const getLiveChannelContentRef = (channelId) => {
    const channel = getChannelByIdFromCache(channelId);
    return new ContentRef({
        contentId: channelId,
        contentName: channel?.title,
        contentType: ContentTypes.CHANNEL,
        channelId,
        channelName: channel?.title,
    });
};

export const getEventContentRef = (eventId) => {
    const { id: contentId, title: contentName, channel, metadata } = getEventByIdFromCache(eventId);
    const { id: channelId, title: channelName } = getObjectByRefObjectFromCache(channel);
    const contentRefFieldsFromMetadata = getContentRefFiledsFromMetadataRefObject(metadata);
    const contentType = ContentTypes.PROGRAM;

    return new ContentRef({
        contentId,
        contentName,
        contentType,
        channelId,
        channelName,
        ...contentRefFieldsFromMetadata,
    });
};

export const getChannelListContentRef = (channelListId) => {
    const channelList = getChannelListByIdFromCache(channelListId);
    return new ContentRef({
        contentId: channelListId,
        contentName: channelList?.name,
        contentType: ContentTypes.CHANNEL_LIST,
    });
};
export const getRecordingContentRef = (recordingId) => {
    const { title: contentName } = getRecordingByIdFromCache(recordingId);
    return new ContentRef({ contentId: recordingId, contentName, contentType: ContentTypes.RECORDING });
};

export const getTrailerContentRef = (trailerId) => {
    const { title: contentName } = getTrailerByIdFromCache(trailerId);
    return new ContentRef({ contentId: trailerId, contentName, contentType: ContentTypes.TRAILER });
};

export const getVODAssetContentRef = (vodAssetId) => {
    const { title: contentName, metadata } = getVODAssetByIdFromCache(vodAssetId);
    const contentRefFieldsFromMetadata = getContentRefFiledsFromMetadataRefObject(metadata);
    const contentType = ContentTypes.ASSET;

    return new ContentRef({
        contentId: vodAssetId,
        contentName,
        contentType,
        ...contentRefFieldsFromMetadata,
    });
};
