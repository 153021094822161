import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Image, Orientation } from '__SMART_APP_OLD__/app/components/Image';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMessageDetailImage } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';

export const MessageDetailImage: React.FunctionComponent = () => {
    const image = useSelector(selectMessageDetailImage);
    return (
        <div className="message-detail__image-container">
            <Image className="message-detail__image" orientation={Orientation.LANDSCAPE} src={image} />
            <Div className="message-detail__left-gradient" backgroundColor={BackgroundColor.LEFT_GRADIENT} shadow={Shadow.NONE} />
            <Div className="message-detail__bottom-gradient" backgroundColor={BackgroundColor.BOTTOM_GRADIENT} shadow={Shadow.NONE} />
        </div>
    );
};
