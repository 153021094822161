import React from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectMenuItemTitle, selectMenuItemsIds } from '__SMART_APP_OLD__/app/modules/Config/selectors';

import { actions } from 'App/Modules/Header/Store/actions';
import { HeaderIconItems } from 'App/Modules/Header/Widget/HeaderIconItems';
import { UI } from 'App/Packages/UI';
import { MenuIconItemType, MenuItemType } from 'App/Types/Menu';

export type WidgetProps = {
    ctx?: string;
    active: MenuItemType;
    initial: MenuItemType | MenuIconItemType;
    strong?: boolean;
    onEnter?: (id: MenuItemType | MenuIconItemType) => Promise<void> | void;
};

export const Widget: React.FunctionComponent<WidgetProps> = (props) => {
    const dispatch = useDispatch();
    const menuItemsIds = useSelector(selectMenuItemsIds);
    return (
        <UI.Header strong={props.strong}>
            <UI.Header.Logo />
            <UI.Header.Items
                ctx={props.ctx}
                active={props.active}
                initial={props.initial}
                onEnter={async (id) => {
                    if (props.onEnter) await props.onEnter(id as MenuItemType | MenuIconItemType);
                    dispatch(actions.private.menuSelected(id, props.active));
                }}
            >
                <UI.Header.Main>
                    {menuItemsIds.map((id) => (
                        <UI.Header.MenuItem id={id} key={id}>
                            <UI.Header.MenuItem.Label>
                                <UI.Selector selector={selectMenuItemTitle(id)}>
                                    {(title) => <UI.Translation>{title}</UI.Translation>}
                                </UI.Selector>
                            </UI.Header.MenuItem.Label>
                            <UI.Header.MenuItem.Indicator />
                        </UI.Header.MenuItem>
                    ))}
                </UI.Header.Main>
                <UI.Header.Static>
                    <HeaderIconItems />
                </UI.Header.Static>
            </UI.Header.Items>
            <UI.Header.Clock />
        </UI.Header>
    );
};
