import { ChannelListsScreenManageChannelsView } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/ChannelListsScreenManageChannelsView';
import { channelListsScreenManageActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/actions';
import {
    ChannelListsManageScreenActionType,
    ChannelListsManageScreenReduxAction,
    ChannelListsManageScreenState,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenManageChannels/store/types';

export { ChannelListsManageScreenActionType as ActionType };

export type { ChannelListsManageScreenReduxAction as ReduxAction, ChannelListsManageScreenState as State };

export const View = ChannelListsScreenManageChannelsView;

export const { lifecycle } = channelListsScreenManageActions;
