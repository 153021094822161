import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import Events, { MORE_OVERLAY_SHOW } from '__SMART_APP_OLD__/config/Events';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import Focusable from '__SMART_APP_OLD__/navigation/Focusable';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

export default class Description extends React.Component {
    nodeRef = null;

    showMoreRef = null;

    descriptionRef = null;

    constructor(props) {
        super(props);

        this.state = {
            descTag: 'div',
            descFocusable: false,
            descInitialized: false,
            moreOverlayOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { onFocusPlaced } = this.props;
        const { descInitialized } = this.state;
        const focusedNode = null;
        if (descInitialized !== prevState.descInitialized) {
            if (onFocusPlaced) {
                onFocusPlaced(focusedNode);
            }
        }
    }

    isDescriptionEllipsized = () => {
        if (this.descriptionRef) {
            return this.descriptionRef.scrollHeight > this.descriptionRef.clientHeight;
        }

        return false;
    };

    updateState = () => {
        const isTruncated = this.isDescriptionEllipsized();
        const { detailed } = this.props;
        this.setState({
            descFocusable: isTruncated && detailed,
            descInitialized: true,
        });
    };

    openMoreOverlay = () => {
        const { asset } = this.props;
        Focus.stash();
        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.OPEN_FULL_SYNOPSIS, asset));
        Events.triggerEvents(MORE_OVERLAY_SHOW, {
            asset,
        });
    };

    onCustomRect = (domNode) => {
        const { top, bottom, height } = domNode.getBoundingClientRect();
        return {
            top,
            bottom,
            left: 200,
            right: 0,
            height,
            width: 10,
        };
    };

    getContent = () => {
        const { asset, needMoreInfo, needShortDescription } = this.props;
        const { descFocusable } = this.state;

        return (
            <>
                <Text typeface={Typeface.SANS} size={FontSize.BODY_1} weight={FontWeight.REGULAR} color={Color.PRIMARY}>
                    <div
                        className={`description ${needShortDescription && 'description-short'}`}
                        ref={(ref) => {
                            if (ref) {
                                this.descriptionRef = ref;
                            }
                        }}
                    >
                        {asset.description ? asset.description : asset.descriptionShort}
                    </div>
                </Text>
                {(descFocusable || needMoreInfo) && (
                    <Focusable
                        className="menu-icon icon-show-more"
                        id="more-info"
                        key="more-info"
                        dataTestId="description-more-info"
                        scrollTop={true}
                        onCustomRect={this.onCustomRect}
                        onKey={this.onKey}
                        ref={(ref) => {
                            if (ref) {
                                this.showMoreRef = ref;
                            }
                        }}
                        onEnter={this.openMoreOverlay}
                        focusDelay={false}
                    />
                )}
            </>
        );
    };

    renderDescription = () => {
        const { detailed } = this.props;
        const { descTag } = this.state;
        let className = '';

        if (detailed) {
            className += ' detailed';
        }

        return React.createElement(
            descTag,
            {
                className,
                ref: (ref) => {
                    if (ref) {
                        if (!this.nodeRef) {
                            this.nodeRef = ref;
                            this.updateState();
                        }
                        this.nodeRef = ref;
                    }
                },
            },
            this.getContent()
        );
    };

    render() {
        return <>{this.renderDescription()}</>;
    }
}
