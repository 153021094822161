import { LoginWithEmailConstants } from 'App/Modules/Screen/Modules/LoginWithEmail/Constants';
import { LoginWithEmailForm } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailForm';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';
import React from 'react';

export const LoginWithEmailFormWithNavigation: React.FunctionComponent = () => {
    const { focused, focus } = Focus.useList({ ctx: Focus.Default, axis: Axis.Y, items: LoginWithEmailConstants.Elements });
    return <LoginWithEmailForm ctx={Focus.Default} focused={focused} onFocus={focus} />;
};
