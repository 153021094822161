import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { languageApi } from '__SMART_APP_OLD__/app/modules/Language/api';
import { languageSelectors } from '__SMART_APP_OLD__/app/modules/Language/store/selectors';
import {
    LanguageActionType,
    LanguageChangedAction,
    LanguageLoadedAction,
    LanguageLoadedActionPayload,
} from '__SMART_APP_OLD__/app/modules/Language/store/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

const loadMany = (payload: LanguageLoadedActionPayload): LanguageLoadedAction => ({
    type: LanguageActionType.LOADED,
    payload,
});

const change = (code: string): LanguageChangedAction => ({
    type: LanguageActionType.CHANGED,
    payload: { code },
});

const load = (force: boolean = false): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const languageIds = languageSelectors.public.selectCodes(getState());
        if (languageIds.length && !force) return true;
        const response = await languageApi.load();
        dispatch(loadMany(response));
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.LANGUAGE_LOAD, error));
        return false;
    }
};

const changeByCode = (code: string): Thunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const selected = languageSelectors.public.selectSelected(state);
    Storage.set(Storage.Key.LANGUAGE, code);
    if (selected === code) return true;
    const id = languageSelectors.public.selectIdByCode(code)(state);
    dispatch(change(code));
    try {
        await languageApi.change(id);
        return true;
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.LANGUAGE_CHANGE, error));
        return false;
    }
};

const initCode = (): Thunk<string> => (dispatch, getState) => {
    const state = getState();
    const code = languageSelectors.public.selectSelected(state);
    if (code) return code;
    const defaultCode = languageSelectors.public.selectDefaultCode(state);
    dispatch(change(defaultCode));
    return defaultCode;
};

const init = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    await dispatch(load());
    dispatch(changeByCode(languageSelectors.public.selectSelected(getState())));
};

export const languageActions = {
    public: {
        changeByCode,
        load,
        initCode,
        init,
    },
};
