import { CardFolderComponent, CardFolder as Root } from 'App/Packages/UI/Card/CardFolder/Components/CardFolder';
import { CardFolderGradient, CardFolderGradientComponent } from 'App/Packages/UI/Card/CardFolder/Components/CardFolderGradient';
import { CardFolderThumbnail, CardFolderThumbnailComponent } from 'App/Packages/UI/Card/CardFolder/Components/CardFolderThumbnail';
import { CardFolderTitle, CardFolderTitleComponent } from 'App/Packages/UI/Card/CardFolder/Components/CardFolderTitle';
import { Constants } from 'App/Packages/UI/Card/CardFolder/Constants';

export type CardFolderModules = {
    // Constants
    Constants: typeof Constants;

    // Components
    Gradient: CardFolderGradientComponent;
    Title: CardFolderTitleComponent;
    Thumbnail: CardFolderThumbnailComponent;
};

export type CardFolderModule = CardFolderComponent & CardFolderModules;

export const CardFolder: CardFolderModule = Object.assign(Root, {
    // Constants
    Constants,

    // Components
    Gradient: CardFolderGradient,
    Title: CardFolderTitle,
    Thumbnail: CardFolderThumbnail,
});
