import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { SearchContentType, SearchFilter } from '__SMART_APP_OLD__/api/graphql/types';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import {
    SearchBaseResultLoadDocument,
    SearchBaseResultLoadQueryVariables,
} from 'App/Modules/Data/Search/Result/Api/Query/searchBaseResultLoad.generated';
import {
    SearchResultLoadDocument,
    SearchResultLoadQueryResult,
    SearchResultLoadQueryVariables,
} from 'App/Modules/Data/Search/Result/Api/Query/searchResultLoad.generated';

import { Folder } from 'App/Modules/Folder';
import { FolderListFragment } from 'App/Modules/Folder/Api/fragments/folderList.generated';

const __load = async (
    document: typeof SearchResultLoadDocument | typeof SearchBaseResultLoadDocument,
    variables: SearchResultLoadQueryVariables | SearchBaseResultLoadQueryVariables,
    folderList: FolderListFragment | null = null
): Promise<FolderListFragment | null> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: SearchResultLoadQueryResult = await GraphqlClient.makeGraphqlRequest(document, variables, options);
    Error.collect(response);
    const page = response.data?.folderList?.folders.pageInfo;
    if (!page) return folderList;
    const data = response.data?.folderList;
    if (!data) return folderList;
    const next = Folder.Util.merge(folderList, data);
    if (!page.hasNextPage) return next;
    const nextCursor = page.endCursor;
    if (!nextCursor) return next;
    return __load(document, { ...variables, folderListAfter: nextCursor }, next);
};

export type LoadVariables = {
    query?: string;
    filters?: SearchFilter;
    context?: SearchContentType;
    profileId: string;
    items: number;
    thumbnailHeight: number;
};

export const load = async (vars: LoadVariables): Promise<FolderListFragment | null> => {
    const variables: SearchBaseResultLoadQueryVariables = {
        searchContext: {
            profileId: vars.profileId,
            orderContentType: vars.context,
        },
        thumbnailHeight: vars.thumbnailHeight,
        folderListFirst: Folder.Constants.List.Folders.Max,
        folderListAfter: undefined,
        folderFirst: vars.items,
        folderAfter: undefined,
    };
    if (!vars.query) return __load(SearchBaseResultLoadDocument, variables);
    return __load(SearchResultLoadDocument, { ...variables, searchKeyword: vars.query, searchFilter: vars.filters ?? {} });
};
