import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { LanguageSelectionScreenActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguageSelectionScreen/LanguageSelectionScreenActions';
import { LanguageSelectionScreenSelected } from '__SMART_APP_OLD__/app/modules/Screen/modules/LanguageSelectionScreen/LanguageSelectionScreenSelected';

import { Screen } from 'App/Modules/Screen';

export const LanguageSelectionScreenView: React.FunctionComponent<Screen.Props<Screen.Type.LANGUAGE_SELECTION>> = () => (
    <Layout className="language-selection" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
        <Text
            className="language-selection__title"
            typeface={Typeface.SANS}
            size={FontSize.TITLE_2}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            SETTINGS_LANGUAGE_UI_LANGUAGE
        </Text>
        <Text
            className="language-selection__subtitle"
            typeface={Typeface.SANS}
            size={FontSize.BODY_1}
            weight={FontWeight.REGULAR}
            color={Color.SECONDARY}
        >
            SETTINGS_LANGUAGE_UI_LANGUAGE_SUBHEADER
        </Text>
        <LanguageSelectionScreenSelected />
        <div className="language-selection__actions">
            <LanguageSelectionScreenActions />
        </div>
    </Layout>
);
