import React from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Function } from 'App/Packages/Function';
import { UI } from 'App/Packages/UI';
import { Env } from 'App/Env';

export const LoginCodeExpired: React.FunctionComponent<Screen.Props<Screen.Type.LOGIN_EXPIRED_CODE>> = () => {
    const dispatch = useDispatch();

    Focus.useBack(() => {
        Env.IsBulgaria || Env.isBusiness
            ? dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}))
            : dispatch(Screen.actions.mount(Screen.Type.LOGIN_WITH_CODE, {}));
    });

    return (
        <Layout className="login-code-expired" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <div className="login-code-expired__container">
                <UI.Logo.XploreTV className="login-code-expired__logo" />
                <Text
                    className="login-code-expired__title"
                    size={FontSize.TITLE_1}
                    typeface={Typeface.SANS}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    LOGIN_WITH_CODE_CODE_EXPIRED
                </Text>
                <Text
                    className="login-code-expired__hint"
                    size={FontSize.BODY_1}
                    typeface={Typeface.SERIF}
                    weight={FontWeight.REGULAR}
                    color={Color.PRIMARY}
                >
                    LOGIN_WITH_CODE_CODE_EXPIRED_HINT
                </Text>

                <Button
                    id="refresh-button"
                    className="login-code-expired__action"
                    ctx={Focus.Default}
                    isFocused
                    onHover={Function.noop}
                    onEnter={() => dispatch(Screen.actions.mount(Screen.Type.LOGIN, {}))}
                >
                    LOGIN_WITH_CODE_CODE_EXPIRED_REFRESH_BUTTON
                </Button>
            </div>
        </Layout>
    );
};
