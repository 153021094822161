import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import {
    DeviceRemoveDocument,
    DeviceRemoveMutationResult,
    DeviceRemoveMutationVariables,
} from '__SMART_APP_OLD__/app/api/device/mutation/deviceRemove.generated';
import {
    DeviceLoadManyDocument,
    DeviceLoadManyFragment,
    DeviceLoadManyQueryResult,
} from '__SMART_APP_OLD__/app/api/device/query/deviceLoadMany.generated';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';

/**
 * @namespace API.Device
 */

export type LoadManyResponse = { devices: GQL.Device[]; maxNumberOfUnmanagedDevices: number };

/**
 * @memberof API.Device
 * @description Function that parses response from graphql to LoadManyResponse
 * @author SmartTVBG@a1.bg
 * @date 24/01/2023
 * @param [response] response from gql
 * @returns parsed info for current user devices
 */
export const __parseLoadMany = (response?: DeviceLoadManyFragment): LoadManyResponse => {
    const devices = (response?.me?.household.devices.items ?? []) as GQL.Device[];
    const maxNumberOfUnmanagedDevices = response?.me?.household.maxNumberOfUnmanagedDevices ?? 0;
    const filteredDevices = devices.filter((device) => device?.deviceType !== GQL.DeviceType.Chromecast) ?? [];
    return { devices: filteredDevices, maxNumberOfUnmanagedDevices };
};

/**
 * @memberof API.Device
 * @description Loads devices for the assosiated household
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @returns raw info for current user devices
 */
export const loadMany = async (): Promise<LoadManyResponse> => {
    const options = { fetchPolicy: 'no-cache' };
    const response: DeviceLoadManyQueryResult = await GraphqlClient.makeGraphqlRequest(DeviceLoadManyDocument, {}, options);
    Error.collect(response);
    return __parseLoadMany(response.data);
};

/**
 * @memberof API.Device
 * @description Removes a device with an ID that matches the provided deviceId
 * @author SmartTVBG@a1.bg
 * @date 10/01/2023
 * @param deviceId ID of the device that should be deleted
 * @returns the result of delete device mutation
 */
export const remove = async (deviceId: GQL.DeviceID): Promise<void> => {
    const deviceRemoveInput: DeviceRemoveMutationVariables = { deviceRemoveInput: { id: deviceId } };
    const response: DeviceRemoveMutationResult = await GraphqlClient.makeGraphqlMutationRequest(DeviceRemoveDocument, deviceRemoveInput);
    Error.collect(response);
};
