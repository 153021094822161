import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import { ChangeChannelPreferencesDocument } from '__SMART_APP_OLD__/api/graphql/mutation/changeChannelPreferences.generated';
import { selectIsSubtitlesEditable, selectSubFontSizes, selectSubFontStyles } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { FontSizes, FontStyles } from '__SMART_APP_OLD__/app/modules/Config/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { UIActionEvents } from 'analytics/logging/events/UIActionEvent';
import { getUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { Overlay } from 'App/Modules/Overlay';
import {
    AudioItem,
    MenuTypes,
    SubtitleItem,
    TextFontSizeOptions,
    TextFontStyleVariants,
} from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/View/Types';
import { Focus } from 'App/Packages/Focus';
import { Constants } from 'App/Modules/Overlay/Modules/SubtitleMenuOverlay/Store/Constants';

const changePreferences = (langCode: any, languageType: any, channelId: string) => {
    if (channelId) {
        const changePreferencesInput = {
            input: {
                channelId,
                audioLanguage: '',
                subtitleLanguage: '',
            },
        };

        if (languageType === MenuTypes.AUDIO) {
            changePreferencesInput.input.audioLanguage = langCode;
        } else if (langCode === 'off') {
            changePreferencesInput.input.subtitleLanguage = '';
        } else {
            changePreferencesInput.input.subtitleLanguage = langCode;
        }

        GraphqlClient.makeGraphqlMutationRequest(ChangeChannelPreferencesDocument, changePreferencesInput);
    }
    return null;
};

const onSubtitleSelect =
    (channelId: string, playerSetSubtitleStream: (option: SubtitleItem) => void) =>
    (option: SubtitleItem): Thunk<void> =>
    (dispatch) => {
        dispatch(Overlay.actions.update(Overlay.Type.SubtitleMenu, { currentSubtitle: option }));

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.SUBTITLES_CHANGED, { id: channelId }));
        changePreferences(option.iso_code, MenuTypes.SUBTITLES, channelId);
        playerSetSubtitleStream(option);
    };

const onAudioSelect =
    (channelId: string, playerSetAudioStream: (option: AudioItem) => void) =>
    (option: AudioItem): Thunk<void> =>
    (dispatch) => {
        dispatch(Overlay.actions.update(Overlay.Type.SubtitleMenu, { currentAudioTrack: option }));

        LoggingService.getInstance().logEvent(getUIActionEvent(UIActionEvents.AUDIO_CHANGED, { id: channelId }));
        changePreferences(option.iso_code, MenuTypes.AUDIO, channelId);
        playerSetAudioStream(option);
    };

const subtitlesOverlaySelected = (): Thunk<void> => async (dispatch, getState) => {
    const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitleMenu)(getState());
    if (!overlay?.props) return;

    dispatch(
        Overlay.actions.mount(Overlay.Type.SubtitlesSelect, {
            subtitles: overlay.props.playerOptions.subtitles,
            currentSubtitle: overlay.props.currentSubtitle,
            onSubtitleSelect: onSubtitleSelect(overlay.props.channelId, overlay.props.playerSetSubtitleStream),
            subtitleMenuProps: overlay.props,
            onClose: () => dispatch(Overlay.actions.mount(Overlay.Type.SubtitleMenu, overlay.props, overlay.ctx)),
        })
    );
};

const audioOverlaySelected = (): Thunk<void> => (dispatch, getState) => {
    const overlay = Overlay.selectors.selectTyped(Overlay.Type.SubtitleMenu)(getState());
    if (!overlay?.props) return;

    dispatch(
        Overlay.actions.mount(Overlay.Type.AudioSelect, {
            audios: overlay.props.playerOptions.audio,
            currentAudioTrack: overlay.props.currentAudioTrack,
            onAudioSelect: onAudioSelect(overlay.props.channelId, overlay.props.playerSetAudioStream),
            subtitleMenuProps: overlay.props,
            onClose: () => dispatch(Overlay.actions.mount(Overlay.Type.SubtitleMenu, overlay.props, overlay.ctx)),
        })
    );
};

const updateFontSize =
    (value: string, size: string, playerSetFontSize: (value: string) => void): Thunk<void> =>
    (dispatch, getState) => {
        const isSubtitlesEditable = selectIsSubtitlesEditable(getState());
        if (!isSubtitlesEditable) return;

        dispatch(Overlay.actions.update(Overlay.Type.SubtitleMenu, { fontSize: value }));
        switch (size) {
            case TextFontSizeOptions.SMALL:
                playerSetFontSize(value);
                break;
            case TextFontSizeOptions.MEDIUM:
                playerSetFontSize(value);
                break;
            case TextFontSizeOptions.LARGE:
                playerSetFontSize(value);
                break;
            default:
                break;
        }
    };

const updateBackgroundAndColor =
    (
        color: string,
        bgColor: string,
        variant: string,
        edgeType: string,
        playerSetFontColor: (color: string) => void,
        playerSetBackgroundColor: (bgColor: string) => void,
        playerSetEdgeType: (value: string) => void
    ): Thunk<void> =>
    (dispatch, getState) => {
        const isSubtitlesEditable = selectIsSubtitlesEditable(getState());
        if (!isSubtitlesEditable) return;

        dispatch(Overlay.actions.update(Overlay.Type.SubtitleMenu, { fontColor: color, backgroundColor: bgColor, edgeType }));
        switch (variant) {
            case TextFontStyleVariants.VARIANT1:
                playerSetFontColor(color);
                playerSetBackgroundColor(bgColor);
                playerSetEdgeType(edgeType);
                break;
            case TextFontStyleVariants.VARIANT2:
                playerSetFontColor(color);
                playerSetBackgroundColor(bgColor);
                playerSetEdgeType(edgeType);
                break;
            case TextFontStyleVariants.VARIANT3:
                playerSetFontColor(color);
                playerSetBackgroundColor(bgColor);
                playerSetEdgeType(edgeType);
                break;
            case TextFontStyleVariants.VARIANT4:
                playerSetFontColor(color);
                playerSetBackgroundColor(bgColor);
                playerSetEdgeType(edgeType);
                break;
            default:
                break;
        }
    };

const mount =
    (props: Overlay.Props<Overlay.Type.SubtitleMenu>): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const subFontSizeOptions = selectSubFontSizes(state);
        const subFontStyleOptions = selectSubFontStyles(state);

        const doesFontSizeExist = subFontSizeOptions.find((sub: FontSizes) => sub.value === props.fontSize);
        const doesFontColorAndBgExist = subFontStyleOptions.find(
            (sub: FontStyles) => sub.color === props.fontColor && sub.bgColor === props.backgroundColor
        );

        // we use subFontSizeOptions[1] as the business wants always the medium value to be selected
        if (!doesFontSizeExist) {
            dispatch(updateFontSize(subFontSizeOptions[1].value, subFontSizeOptions[1].size, props.playerSetFontSize));
        }

        // we use subFontSizeOptions[1] as the business wants always
        // the second value to be selected, which is black bg whith white sub color
        if (!doesFontColorAndBgExist) {
            dispatch(
                updateBackgroundAndColor(
                    subFontStyleOptions[1].color,
                    subFontStyleOptions[1].bgColor,
                    subFontStyleOptions[1].variant,
                    subFontStyleOptions[1].edgeType,
                    props.playerSetFontColor,
                    props.playerSetBackgroundColor,
                    props.playerSetEdgeType
                )
            );
        }
    };

const outOfBounds: Focus.OutOfBounds = (event, ctx) => (dispatch, getState) => {
    const isSubtitlesEditable = selectIsSubtitlesEditable(getState());
    if (ctx === Constants.Ctx.audio && event.x === 1) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.subtitles));
    }
    if (ctx === Constants.Ctx.subtitles && event.x === -1) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.audio));
    }
    if (ctx === Constants.Ctx.subtitles && event.x === 1 && isSubtitlesEditable) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.fontSizes));
    }
    if (ctx === Constants.Ctx.fontSizes && event.x === -1) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.subtitles));
    }
    if (ctx === Constants.Ctx.fontSizes && event.x === 1) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.fontStyles));
    }
    if (ctx === Constants.Ctx.fontStyles && event.x === -1) {
        dispatch(Overlay.actions.ctx.changed(Overlay.Type.SubtitleMenu, Constants.Ctx.fontSizes));
    }
};

export const actions = {
    private: {
        updateFontSize,
        updateBackgroundAndColor,
        subtitlesOverlaySelected,
        audioOverlaySelected,
    },
    lifecycle: {
        mount,
        outOfBounds,
    },
};
