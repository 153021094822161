import { initialBookmarksState } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/constants';
import { bookmarksMapper } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/mapper';
import { BookmarksActionType, BookmarksEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/store/types';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const bookmarksReducer: Reducer<BookmarksEntityTable> = (state, action) => {
    if (!state) return initialBookmarksState;

    switch (action.type) {
        case BookmarksActionType.LOADED: {
            const ids = bookmarksMapper.toIds(action.payload.bookmarks);
            const entities = bookmarksMapper.toEntities(action.payload.bookmarks);
            return { ...state, ids, entities };
        }
        case BookmarksActionType.SAVED: {
            const entities = { ...state.entities, [action.payload.bookmark.id]: action.payload.bookmark };
            const ids = Object.keys(entities);
            return { ...state, ids, entities };
        }
        default:
            return state;
    }
};
