import React from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { TVGuideBackground } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideBackground';
import { TVGuideClock } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideClock';
import { TVGuideControls } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideControls';
import { TVGuideDetail } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideDetail';
import { TVGuideGrid } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideGrid';
import { TVGuideLiveLine } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideLiveLine';
import { TVGuideSnackbar } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideSnackbar';
import { TVGuideTimeline } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/TVGuideTimeline';

export const TVGuide: React.FunctionComponent = () => (
    <Layout className="tv-guide" backgroundColor={BackgroundColor.PRIMARY}>
        <TVGuideBackground />
        <TVGuideClock />
        <TVGuideDetail />
        <TVGuideTimeline />
        <TVGuideLiveLine />
        <TVGuideGrid />
        <TVGuideControls />
        <TVGuideSnackbar />
    </Layout>
);
