import React from 'react';

import { ListEngine } from '__SMART_APP_OLD__/app/components/ListV2/ListEngine';

import { UI } from 'App/Packages/UI';

export type ListProps<T> = {
    className?: string;
    engine: ListEngine<T>;
    children: UI.ChildRenderer<T>;
};

const getClassName = (className?: string): string => {
    const classlist = ['list'];
    if (className) classlist.push(className);
    return classlist.join(' ');
};

export const List = <T,>(props: ListProps<T>): ReturnType<React.FunctionComponent<ListProps<T>>> => (
    <div className={getClassName(props.className)}>
        <div className={props.engine.getListClassName()} ref={props.engine.setRef}>
            <UI.Children items={props.engine.getData()}>
                {(item, index, items) => (
                    <div key={props.engine.getKey(index)} className={props.engine.getItemClassName(index)}>
                        {props.children(item, index, items)}
                    </div>
                )}
            </UI.Children>
        </div>
    </div>
);
