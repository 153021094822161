/* eslint-disable react-func/max-lines-per-function */
import { ApolloClient, ApolloLink, HttpLink, from, Observable } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import possibleTypes from '__SMART_APP_OLD__/api/graphql/PossibleTypes.json';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { selectDataSource } from '__SMART_APP_OLD__/app/modules/Config/selectors';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { Platform } from '__SMART_APP_OLD__/app/platform';
import { store } from '__SMART_APP_OLD__/app/store/store';
import ErrorHandling from '__SMART_APP_OLD__/utils/errorHandling';
import * as HttpEventFactory from 'analytics/logging/factories/httpRequestEventFactory';
import * as issueEventFactory from 'analytics/logging/factories/issueEventFactory';
import { LoggingService } from 'analytics/loggingService';
import { logoutAction, refreshFlowAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { DataSourceMethod, DataSourceService } from 'App/Types/DataSource';
import { selectAuthSessionStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/selectors';
import { AuthSessionStatus } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/types';
import { stripIgnoredCharacters } from 'graphql';
import { asyncMap } from '@apollo/client/utilities';

class GraphqlClient {
    client;

    authLink = setContext((operation, { headers }) => {
        const operationHeaders = { ...headers };
        const isIdpAuthFlow = Storage.get(Storage.Key.IS_IDP_LOGIN);
        const addSdsevoDevicelessHeader =
            (operation.operationName === 'deleteForeignDevice' ||
                operation.operationName === 'deviceRemove' ||
                operation.operationName === 'checkForeignDeviceDeletionStatus') &&
            isIdpAuthFlow;
        if (addSdsevoDevicelessHeader) {
            operationHeaders.SDSEVO_DEVICELESS = true;
        }
        if (isIdpAuthFlow) {
            const idpAccessToken = Storage.get(Storage.Key.IDP_ACCESS_TOKEN);
            console.log(`[AuthLink] created for idpFlow with token=${idpAccessToken} `);
            return {
                headers: {
                    ...operationHeaders,
                    SDSEVO_DEVICE_ID: Platform.ID,
                    Authorization: `Bearer ${idpAccessToken}`,
                    'Zappware-User-Agent':
                        window?.navigator?.userAgent || 'windows_pc_chrome/v14.1.2 (Nexx 4.0 windows_pc_chrome; Windows; 10) null',
                },
            };
        }

        // get the authentication token from local storage if it exists
        const userId = Profile.selectors.selectUserId(store.getState());
        const userSessionToken = Storage.get(Storage.Key.USER_SESSION_TOKEN);

        console.log(`[AuthLink] created for userId=${userId} with token=${userSessionToken} and deviceId=${Platform.ID}`);
        return {
            headers: {
                ...operationHeaders,
                SDSEVO_DEVICE_ID: Platform.ID,
                SDSEVO_SESSION_ID: userSessionToken,
                SDSEVO_USER_ID: userId,
                'Zappware-User-Agent':
                    window?.navigator?.userAgent || 'windows_pc_chrome/v14.1.2 (Nexx 4.0 windows_pc_chrome; Windows; 10) null',
            },
        };
    });

    // eslint-disable-next-line complexity,max-statements
    errorLink = onError(({ graphQLErrors, operation, networkError, forward }) => {
        if (operation.operationName === 'logout') {
            return undefined;
        }

        const isIdpLogin = Storage.get(Storage.Key.IS_IDP_LOGIN) ?? false;
        if (graphQLErrors) {
            const [{ errorCode, message }] = graphQLErrors;
            graphQLErrors.forEach((error) => {
                const token = isIdpLogin ? Storage.get(Storage.Key.IDP_ACCESS_TOKEN) : Storage.get(Storage.Key.USER_SESSION_TOKEN);
                const variables = { ...operation.variables, isIdpLogin, token, ...operation.getContext()?.headers };
                LoggingService.getInstance().logEvent(issueEventFactory.getGraphQLIssueEvent(error, operation.operationName, variables));
            });
            if (operation.operationName === 'playVODAsset') {
                store.dispatch(VodUpsell.actions.cancelTransactionOnBackendError);
                return undefined;
            }

            if (operation.operationName === 'keepSessionAlive') {
                return undefined;
            }
            if (operation.operationName === 'deviceDelete' && errorCode === '0x02000200') {
                return undefined;
            }
            if (errorCode === '0x01000001') {
                return undefined;
            }
            if (errorCode === '0x0200020C' && selectAuthSessionStatus(store.getState()) !== AuthSessionStatus.LOGGED_IN) {
                return undefined;
            }
            if (isIdpLogin && errorCode === '0x02000200') {
                return undefined;
            }
            // 0x02000000 -> session expired or not existing!
            if (errorCode === '0x02000000') {
                return this.handlePromiseRefresh(store.dispatch(refreshFlowAction())).flatMap(() => {
                    const newContext = this.createContext(operation.getContext().headers);
                    operation.setContext(newContext);
                    return forward(operation);
                });
            }

            const sessionId = Storage.get(Storage.Key.PLAYBACK_SESSION_ID);
            if (errorCode === '0x02000704' && sessionId) {
                return this.handleSessionRefresh(PlayerAPI.stopPlayback()).flatMap(() => forward(operation));
            }

            ErrorHandling.checkGraphQlErrors(message, errorCode, isIdpLogin);
            return undefined;
        }
        if (networkError && (networkError?.statusCode === 401 || networkError?.statusCode === 403)) {
            // https://github.com/apollographql/apollo-link/issues/646 -> onError can't handle promises
            return this.handlePromiseRefresh(store.dispatch(refreshFlowAction())).flatMap(() => {
                const newContext = this.createContext(operation.getContext().headers);
                operation.setContext(newContext);
                return forward(operation);
            });
        }
        // https://www.apollographql.com/docs/react/data/error-handling/
        return undefined;
    });

    eventLoggerLink = new ApolloLink((operation, forward) => {
        operation.setContext({ start: Date.now() });
        return forward(operation).map((data) => {
            const { start, response } = operation.getContext();
            const latency = Date.now() - start;
            LoggingService.getInstance().logEvent(HttpEventFactory.getGraphQLHttpRequestEvent(operation.operationName, response, latency));
            return data;
        });
    });

    handleSessionRefresh = (promise) =>
        new Observable((subscriber) => {
            promise
                .then(
                    (value) => {
                        Storage.set(Storage.Key.PLAYBACK_SESSION_ID, '');
                        if (subscriber.closed) return null;
                        subscriber.next(value);
                        subscriber.complete();

                        return null;
                    },
                    () => {
                        Storage.set(Storage.Key.PLAYBACK_SESSION_ID, '');
                        subscriber.complete();
                    }
                )
                .catch(() => {
                    Storage.set(Storage.Key.PLAYBACK_SESSION_ID, '');
                });
        });

    handlePromiseRefresh = (promise) =>
        new Observable((subscriber) => {
            promise
                .then(
                    (value) => {
                        if (subscriber.closed) return null;
                        subscriber.next(value);
                        subscriber.complete();
                        return null;
                    },
                    () => {
                        subscriber.complete();
                        store.dispatch(logoutAction());
                    }
                )
                .catch(() => store.dispatch(logoutAction()));
        });

    createContext(headers) {
        const userId = Profile.selectors.selectUserId(store.getState());
        const token = Storage.get(Storage.Key.USER_SESSION_TOKEN);
        const isIdpAuthFlow = Storage.get(Storage.Key.IS_IDP_LOGIN);
        if (isIdpAuthFlow) {
            const idpAccessToken = Storage.get(Storage.Key.IDP_ACCESS_TOKEN);
            console.log(`[AuthLink] created for idpFlow with token=${idpAccessToken} `);
            return {
                headers: {
                    ...headers,
                    SDSEVO_DEVICE_ID: Platform.ID,
                    Authorization: `Bearer ${idpAccessToken}`,
                    'Zappware-User-Agent':
                        window?.navigator?.userAgent || 'windows_pc_chrome/v14.1.2 (Nexx 4.0 windows_pc_chrome; Windows; 10) null',
                },
            };
        }
        console.log(`[AuthLink] created for userId=${userId} with token=${token} and deviceId=${Platform.ID}`);
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                SDSEVO_DEVICE_ID: Platform.ID,
                SDSEVO_SESSION_ID: token,
                SDSEVO_USER_ID: userId,
                'Zappware-User-Agent':
                    window?.navigator?.userAgent || 'windows_pc_chrome/v14.1.2 (Nexx 4.0 windows_pc_chrome; Windows; 10) null',
            },
        };
    }

    getClient = () => {
        if (!this.client) this.initGraphQLClient();
        return this.client;
    };

    destroyClient = () => {
        if (!this.client) return Promise.resolve();
        return this.client.clearStore().then(() => {
            this.client = null;

            return null;
        });
    };

    stopClient = () => {
        if (!this.client) return Promise.resolve();
        return this.client.stop();
    };

    customFetch = (uri, options) => {
        const { operationName } = JSON.parse(options.body);
        const url = `${uri}?gqlquery=${operationName}&client=smarttv`;
        return fetch(url, options);
    };

    initGraphQLClient = () => {
        if (this.client) return;
        this.cache = new InMemoryCache({ addTypename: true, resultCaching: true, possibleTypes });
        const uri = selectDataSource(store.getState())[DataSourceService.Auth][DataSourceMethod.GraphQL];
        this.client = new ApolloClient({
            service: {
                name: 'SmartTV-client',
            },
            cache: this.cache,
            uri,
            link: from([
                this.errorLink,
                this.eventLoggerLink,
                apolloLogger,
                this.authLink,
                new HttpLink({
                    uri,
                    fetch: this.customFetch,
                    credentials: 'include',
                    print: (ast, originalPrint) => stripIgnoredCharacters(originalPrint(ast)),
                }),
            ]),
            credentials: 'include',
            connectToDevTools: true,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                query: {
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                mutate: {
                    errorPolicy: 'all',
                },
            },
        });
    };

    /**
     * Make an GraphQL request.
     * @param query - the GraphQL query as a string. This function will build the GQL.
     * @param variables - the input variables to the query.
     * @param opts
     * @returns Promise
     */
    makeGraphqlRequest = (query, variables, opts = {}) =>
        this.getClient()
            .query({ query, variables, ...opts })
            .catch((err) => {
                console.warn('[graphql] makeGraphqlQuery err = ', err);
                return err?.networkError?.result;
            });

    /**
     * Make an GraphQL mutation.
     * @param mutation - the GraphQL query as a string. This function will build the GQL.
     * @param variables - the input variables to the query.
     * @param opts
     * @returns Promise
     */
    makeGraphqlMutationRequest = (mutation, variables, opts = {}) =>
        this.getClient()
            .mutate({ mutation, variables, ...opts })
            .catch((err) => {
                console.warn('[graphql] makeGraphqlMutation err = ', err);
                return err?.networkError?.result;
            });

    readFragment = (id, fragment) => {
        try {
            return this.client.readFragment({ id, fragment });
        } catch (error) {
            console.error(error);
            return {};
        }
    };
}

export default new GraphqlClient();
