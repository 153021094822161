import React from 'react';

export interface IActionsContext {
    focused: string;
    ctx: string;
    items: string[];
    onHover: (item: string) => void;
    onEnter?: (item: string) => void;
}

export const ActionsContext = React.createContext<IActionsContext>({} as IActionsContext);
