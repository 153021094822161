import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { ThemeType } from '__SMART_APP_OLD__/app/modules/Theme/types';
import { ThemeProvider } from '__SMART_APP_OLD__/app/modules/Theme/ThemeContext';

type Props = {
    backgroundColor?: BackgroundColor;
    shadow?: Shadow;
    theme?: ThemeType | null;
    shadowTheme?: ThemeType | null;
    contentTheme?: ThemeType | null;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const enum BackgroundColor {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    TERTIARY = 'TERTIARY',
    INDICATOR = 'INDICATOR',
    FILL_PRIMARY = 'FILL_PRIMARY',
    FILL_SECONDARY = 'FILL_SECONDARY',
    FILL_TERTIARY = 'FILL_TERTIARY',
    FILL_QUATERNARY = 'FILL_QUATERNARY',
    HOT_RED_PRIMARY = 'HOT_RED_PRIMARY',
    HOT_RED_SECONDARY = 'HOT_RED_SECONDARY',
    HOT_RED_TERTIARY = 'HOT_RED_TERTIARY',
    HOT_RED_QUATERNARY = 'HOT_RED_QUATERNARY',
    COOL_BLUE_PRIMARY = 'COOL_BLUE_PRIMARY',
    COOL_BLUE_SECONDARY = 'COOL_BLUE_SECONDARY',
    COOL_BLUE_TERTIARY = 'COOL_BLUE_TERTIARY',
    COOL_BLUE_QUATERNARY = 'COOL_BLUE_QUATERNARY',
    BUTTON_PRIMARY = 'BUTTON_PRIMARY',
    BUTTON_SECONDARY = 'BUTTON_SECONDARY',
    BUTTON_HOT_RED = 'BUTTON_HOT_RED',
    BUTTON_COOL_BLUE = 'BUTTON_COOL_BLUE',
    OVERLAY = 'OVERLAY',
    STANDBY_OVERLAY = 'STAND_OVERLAY',
    BOTTOM_GRADIENT = 'BOTTOM_GRADIENT',
    LEFT_GRADIENT = 'LEFT_GRADIENT',
    NONE = 'NONE',
}

export enum Shadow {
    DEFAULT = 'DEFAULT',
    DEFAULT_SELECTED = 'DEFAULT_SELECTED',
    BUTTON = 'BUTTON',
    BUTTON_SELECTED = 'BUTTON_SELECTED',
    NONE = 'NONE',
}

const classNameForThemeType: Record<ThemeType, string> = {
    DARK: 'div-v2--dark-theme',
    LIGHT: 'div-v2--light-theme',
};

const classNameForShadowThemeType: Record<ThemeType, string> = {
    DARK: 'div-v2--shadow-dark-theme',
    LIGHT: 'div-v2--shadow-light-theme',
};

const classNameForBackgroundColor: Record<BackgroundColor, string> = {
    [BackgroundColor.PRIMARY]: 'div-v2--background-primary',
    [BackgroundColor.SECONDARY]: 'div-v2--background-secondary',
    [BackgroundColor.TERTIARY]: 'div-v2--background-tertiary',
    [BackgroundColor.INDICATOR]: 'div-v2--background-indicator',
    [BackgroundColor.FILL_PRIMARY]: 'div-v2--background-fill-primary',
    [BackgroundColor.FILL_SECONDARY]: 'div-v2--background-fill-secondary',
    [BackgroundColor.FILL_TERTIARY]: 'div-v2--background-fill-tertiary',
    [BackgroundColor.FILL_QUATERNARY]: 'div-v2--background-fill-quaternary',
    [BackgroundColor.HOT_RED_PRIMARY]: 'div-v2--background-hot-red-primary',
    [BackgroundColor.HOT_RED_SECONDARY]: 'div-v2--background-hot-red-secondary',
    [BackgroundColor.HOT_RED_TERTIARY]: 'div-v2--background-hot-red-tertiary',
    [BackgroundColor.HOT_RED_QUATERNARY]: 'div-v2--background-hot-red-quaternary',
    [BackgroundColor.COOL_BLUE_PRIMARY]: 'div-v2--background-cool-blue-primary',
    [BackgroundColor.COOL_BLUE_SECONDARY]: 'div-v2--background-cool-blue-secondary',
    [BackgroundColor.COOL_BLUE_TERTIARY]: 'div-v2--background-cool-blue-tertiary',
    [BackgroundColor.COOL_BLUE_QUATERNARY]: 'div-v2--background-cool-blue-quaternary',
    [BackgroundColor.BUTTON_PRIMARY]: 'div-v2--background-button-primary',
    [BackgroundColor.BUTTON_SECONDARY]: 'div-v2--background-button-secondary',
    [BackgroundColor.BUTTON_HOT_RED]: 'div-v2--background-button-hot-red',
    [BackgroundColor.BUTTON_COOL_BLUE]: 'div-v2--background-button-cool-blue',
    [BackgroundColor.OVERLAY]: 'div-v2--background-overlay',
    [BackgroundColor.STANDBY_OVERLAY]: 'div-v2--background-dark-primary-70',
    [BackgroundColor.LEFT_GRADIENT]: 'div-v2--background-left-gradient',
    [BackgroundColor.BOTTOM_GRADIENT]: 'div-v2--background-bottom-gradient',
    [BackgroundColor.NONE]: '',
};

const classNameForShadow: Record<Shadow, string> = {
    [Shadow.DEFAULT]: 'div-v2--shadow-default',
    [Shadow.DEFAULT_SELECTED]: 'div-v2--shadow-default-selected',
    [Shadow.BUTTON]: '',
    [Shadow.BUTTON_SELECTED]: 'div-v2--shadow-button-selected',
    [Shadow.NONE]: '',
};

const getClassName = (
    className: string | undefined,
    backgroundColor: BackgroundColor,
    shadow: Shadow,
    theme: ThemeType,
    shadowTheme: ThemeType
): string => {
    const classList = ['div-v2'];
    classList.push(classNameForThemeType[theme]);
    classList.push(classNameForShadowThemeType[shadowTheme]);
    classList.push(classNameForShadow[shadow]);
    classList.push(classNameForBackgroundColor[backgroundColor]);
    if (className) classList.push(className);
    return classList.join(' ');
};

export const Div = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { children, backgroundColor, shadow, className, theme, shadowTheme, contentTheme, ...rest } = props;
    const globalTheme = useTheme();
    const divClassName = getClassName(
        className,
        backgroundColor ?? BackgroundColor.NONE,
        shadow ?? Shadow.NONE,
        theme ?? globalTheme,
        shadowTheme ?? globalTheme
    );

    if (!contentTheme) {
        return (
            <div ref={ref} className={divClassName} {...rest}>
                {children}
            </div>
        );
    }

    return (
        <div ref={ref} className={divClassName} {...rest}>
            <ThemeProvider theme={contentTheme}>{children}</ThemeProvider>
        </div>
    );
}) as React.FunctionComponent<Props & React.RefAttributes<HTMLDivElement>>;
