import React, { useRef } from 'react';

import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { loginLegacyAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { loginErrors } from '__SMART_APP_OLD__/utils/errorHandling';

import { Alert } from 'App/Modules/Alert';
import { InputElement } from 'App/Modules/Input';
import { Overlay } from 'App/Modules/Overlay';
import { LoginElement } from 'App/Modules/Screen/Modules/Login/Types';
import { LoginWithEmailFormPasswordField } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormPasswordField';
import { LoginWithEmailFormSubmit } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormSubmit';
import { LoginWithEmailFormUserNameField } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailFormUserNameField';
import { PasswordSwitch } from 'App/Modules/Screen/Modules/LoginWithEmail/View/LoginWithEmailPasswordSwitch';

export type LoginWithEmailFormProps = {
    ctx: string;
    focused: LoginElement;
    onFocus: (id: LoginElement) => void;
};

export const LoginWithEmailForm: React.FunctionComponent<LoginWithEmailFormProps> = (props) => {
    const { ctx, focused, onFocus } = props;
    const dispatch = useDispatch();
    const userNameField = useRef<InputElement>(null);
    const passwordField = useRef<InputElement>(null);
    const type = useSelector(Overlay.selectors.selectType);
    const isActive = useSelector(Overlay.selectors.selectIsActive);

    const showAlert = (params: any) => {
        dispatch(
            Alert.actions.mount(Alert.Type.LoginError, {
                title: params.title,
                text: params.text,
                image: params.image,
            })
        );
    };

    const onComplete = (target: InputElement): void => {
        if (target.id === LoginElement.UserNameField) onFocus(LoginElement.PasswordField);
        if (target.id === LoginElement.PasswordField) onFocus(LoginElement.Submit);
    };

    const onSubmit = async () => {
        if (type === Overlay.Type.Loading && isActive) return;
        const email = userNameField.current?.value;
        const password = passwordField.current?.value;
        if (!email || !password) return;
        await dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
        if (!email.get() && !password.get()) {
            showAlert(loginErrors.invalid());
            password.set('');
        } else if (!email) {
            showAlert(loginErrors.emailInvalid());
            password.set('');
        } else if (!password) {
            showAlert(loginErrors.passwordInvalid());
        } else {
            const success = dispatch(loginLegacyAction(encodeURIComponent(email.get() || ''), encodeURIComponent(password.get() || '')));
            if (!success) password.set('');
        }
    };

    return (
        <>
            <LoginWithEmailFormUserNameField
                ctx={ctx}
                field={userNameField}
                isFocused={focused === LoginElement.UserNameField}
                onHover={onFocus}
                onComplete={onComplete}
            />
            <LoginWithEmailFormPasswordField
                ctx={ctx}
                field={passwordField}
                isFocused={focused === LoginElement.PasswordField}
                onHover={onFocus}
                onComplete={onComplete}
            />
            <PasswordSwitch
                ctx={ctx}
                isFocused={focused === LoginElement.PasswordSwitch}
                disabled={type === Overlay.Type.Loading && isActive}
                hidden={passwordField.current?.hidden}
                onEnter={() => passwordField.current?.toggleVisibility()}
                onHover={onFocus}
            />
            <LoginWithEmailFormSubmit
                ctx={ctx}
                disabled={type === Overlay.Type.Loading && isActive}
                isFocused={focused === LoginElement.Submit && !(type === Overlay.Type.Loading && isActive)}
                onEnter={onSubmit}
                onHover={onFocus}
            />
        </>
    );
};
