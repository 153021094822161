import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

export enum SettingsScreenOptionId {
    LANGUAGE = 'languages',
    CHANNEL_LINEUP = 'lineup',
    CLOCK = 'clock',
    PARENTAL_CONTROL = 'parental-control',
    PIN = 'pin',
    APPEARANCE = 'appearance',
    DEVICE_MANAGEMENT = 'device-management',
    STARTUP_BEHAVIOR = 'startupBehavior',
    PRIVACY_POLICY = 'policy',
    NVPR = 'npvr',
    ABOUT = 'about',
    LOGOUT = 'logout',
    IMPRINT = 'imprint',
    VIEW_CONTROL = 'view-control',
    REGION = 'region',
}

export type DetailsForSettingsScreenOptionId = {
    text: {
        title?: string;
        hint?: string;
    };
    action: (() => Thunk<Promise<void>>) | null;
};
