import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { MessageDetailObserver } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailObserver';
import { MessageDetailView } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/MessageDetailView';
import { selectMessageDetailLayoutType } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/selectors';
import { MessageDetailLayoutType } from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageDetail/types';

import { Screen } from 'App/Modules/Screen';

export const MessageDetail: React.FunctionComponent<Screen.Props<Screen.Type.MESSAGE_DETAIL>> = (props) => {
    const layout = useSelector(selectMessageDetailLayoutType);
    if (layout === MessageDetailLayoutType.OBSERVER) return <MessageDetailObserver />;
    return <MessageDetailView {...props} />;
};
