import React from 'react';

import { SettingsHeaderAction, SettingsHeaderActionModule } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsHeaderAction';
import { SettingsHeaderTitle } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsHeaderTitle';

export type SettingsHeaderModule = {
    Action: SettingsHeaderActionModule;
    Title: React.FunctionComponent<React.PropsWithChildren>;
} & React.FunctionComponent<React.PropsWithChildren>;

export const SettingsHeader: SettingsHeaderModule = (props) => (
    <div className="settings__header">
        <div className="settings-header">{props.children}</div>
    </div>
);

SettingsHeader.Action = SettingsHeaderAction;
SettingsHeader.Title = SettingsHeaderTitle;
