/* eslint-disable react-func/max-lines-per-function */
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import createAssetObject from '__SMART_APP_OLD__/data/AssetFactory';

import { Mapper } from 'App/Modules/Data/Detail/Root/Mapper';
import { Api } from 'App/Modules/Data/Detail/VodSeries/Api';
import { Screen } from 'App/Modules/Screen';
import { ProductOption } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell/types';
import {
    VodSeriesActionTypes,
    VodSeriesDetailFolderHeaderSelectedAction,
    VodSeriesDetailItemFocusChangedAction,
    VodSeriesDetailItemFocusChangedActionPayload,
    VodSeriesDetailLoadedAction,
    VodSeriesDetailLoadedActionPayload,
    VodSeriesDetailResetAction,
    VodSeriesDetailStateItemsEntities,
} from 'App/Modules/Data/Detail/VodSeries/Types';
import { VodSeriesDetailLoadQueryVariables } from 'App/Modules/Data/Detail/VodSeries/Api/vodSeriesDetailLoad.generated';
import { Profile } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile';
import { VodUpsell } from '__SMART_APP_OLD__/app/modules/Overlay/modules/VodUpsell';
import { selectors } from 'App/Modules/Data/Detail/VodSeries/Store/selectors';

export const vodSeriesDetailLoaded = (payload: VodSeriesDetailLoadedActionPayload): VodSeriesDetailLoadedAction => ({
    type: VodSeriesActionTypes.Load,
    payload,
});

export const reset = (): VodSeriesDetailResetAction => ({
    type: VodSeriesActionTypes.Reset,
});

export const folderHeaderSelectedAction = (payload: string): VodSeriesDetailFolderHeaderSelectedAction => ({
    type: VodSeriesActionTypes.FolderHeaderSelected,
    payload,
});

const focusedItemChangedAction = (payload: VodSeriesDetailItemFocusChangedActionPayload): VodSeriesDetailItemFocusChangedAction => ({
    type: VodSeriesActionTypes.ItemFocusChanged,
    payload,
});

export const focusedItemChanged =
    (itemId: string): Thunk<void> =>
    async (dispatch, getState) => {
        const state = getState();
        const profileId = Profile.selectors.selectId(state);
        const assetObject = selectors.selectAssetObjectById(itemId)(state);
        const products = await Api.loadVodSeriesProducts({ profileId, vodAssetId: itemId });
        const productItems = products?.items as ProductOption[];

        console.log('assetObject', assetObject);
        console.log('productItems', productItems);

        dispatch(focusedItemChangedAction({ itemId, products: productItems }));
        dispatch(VodUpsell.actions.setCurrentAsset(assetObject));
        dispatch(VodUpsell.actions.setProducts(productItems ?? []));
        dispatch(VodUpsell.actions.setVodAsset(itemId));
    };

const load =
    (variables: VodSeriesDetailLoadQueryVariables): Thunk<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const data = await Api.load(variables);

        if (!data || !data.vodSeries) return;

        const allItems = data.vodSeries.content.items?.edges;

        if (!allItems) return;

        const itemIdsForGroupTitle = data.vodSeries.seasonInfos?.map((group) => {
            if (!group) return null;
            const { episodeCount, cursor, number } = group;
            if (!episodeCount || !cursor || !number) return null;
            const start = allItems.findIndex((currentItem) => currentItem?.cursor === cursor);
            const end = start + episodeCount - 1;
            if (!allItems || start === undefined || end === undefined) return null;
            const itemIds = allItems
                .slice(start, end + 1)
                .map((currentItem) => currentItem?.node.id)
                .filter((id): id is string => !!id);
            const title = number.toString();
            return { title, itemIds };
        });

        const entities = allItems.reduce<VodSeriesDetailStateItemsEntities>((acc, currentItem) => {
            if (!currentItem) return acc;
            const detailItem = Mapper.toDetailItem(currentItem.node);
            if (!detailItem) return acc;
            return { ...acc, [detailItem.id]: detailItem };
        }, {});

        const assetObjects = allItems.reduce<VodSeriesDetailStateItemsEntities>((acc, currentItem) => {
            if (!currentItem) return acc;
            const assetObject = createAssetObject(currentItem.node, currentItem.node.__typename);
            if (!assetObject) return acc;
            return { ...acc, [assetObject.id]: assetObject };
        }, {});

        const items = {
            ids: allItems.map((item) => item?.node.id ?? ''),
            entities,
            assetObjects,
        };

        const screen = Screen.selectors.selectTyped(Screen.Type.VodSeriesDetail)(state);
        const groupTitles = data.vodSeries.seasonInfos?.map((group) => group?.number?.toString() ?? '') ?? [];
        const selectedGroupTitle =
            itemIdsForGroupTitle?.find((group) => group?.itemIds.includes(screen?.props.initialFocusedItemId ?? ''))?.title ??
            groupTitles[0];
        const initialItemId = itemIdsForGroupTitle?.find((group) => group?.title === selectedGroupTitle)?.itemIds[0];
        const initialItem = screen?.props.initialFocusedItemId
            ? entities[screen.props.initialFocusedItemId]
            : initialItemId
              ? entities[initialItemId]
              : null;

        if (!initialItem) return;

        const products = await Api.loadVodSeriesProducts({ profileId: variables.profileId, vodAssetId: initialItem.id });
        const assetObject = assetObjects[initialItem.id];

        dispatch(
            vodSeriesDetailLoaded({
                item: initialItem,
                items,
                assetObject,
                groupTitles,
                itemIdsForGroupTitle,
                selectedGroupTitle,
                screenBackgroundImageUrl: data.vodSeries.thumbnail?.url ?? '',
                products: (products?.items as ProductOption[]) ?? [],
            })
        );
    };

export const actions = {
    public: {
        load,
        reset,
        folderHeaderSelectedAction,
        focusedItemChanged,
    },
    private: {},
} as const;
