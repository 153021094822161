/**
 * @description An array containing the Parental Ratings
 * @constant
 * @memberof Utils
 */
export const parentalRatings = [
    {
        id: 'FSK0',
        title: 'SETTINGS_PARENTAL_CONTROL_ZERO',
        info: 'SETTINGS_PARENTAL_CONTROL_ZERO_HINT',
        type: 'Select',
    },
    {
        id: 'FSK6',
        title: 'SETTINGS_PARENTAL_CONTROL_SIX',
        info: 'SETTINGS_PARENTAL_CONTROL_SIX_HINT',
        type: 'Select',
    },
    {
        id: 'FSK12',
        title: 'SETTINGS_PARENTAL_CONTROL_TWELVE',
        info: 'SETTINGS_PARENTAL_CONTROL_TWELVE_HINT',
        type: 'Select',
    },
    {
        id: 'FSK16',
        title: 'SETTINGS_PARENTAL_CONTROL_SIXTEEN',
        info: 'SETTINGS_PARENTAL_CONTROL_SIXTEEN_HINT',
        type: 'Select',
    },
    {
        id: 'FSK18',
        title: 'SETTINGS_PARENTAL_CONTROL_EIGHTEEN',
        info: 'SETTINGS_PARENTAL_CONTROL_EIGHTEEN_HINT',
        type: 'Select',
    },
    {
        id: 'SETTINGS_ADULT_CONTENT',
        title: 'SETTINGS_ADULT_CONTENT',
        info: 'SETTINGS_ADULT_CONTENT_INFO',
        type: 'Link',
    },
];

/**
 * @type {string}
 * @memberof Utils
 */
export const SETTINGS_ADULT_CONTENT = 'SETTINGS_ADULT_CONTENT';
