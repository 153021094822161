import React from 'react';

import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';

import { CardPortraitContext } from 'App/Packages/UI/Card/CardPortrait/Context/CardPortraitContext';
import { Mask } from 'App/Packages/UI/Mask';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

const getClassName = (props: CardPortraitProps) => {
    const classlist = ['portrait-card-v3'];
    if (props.isFocused) classlist.push('portrait-card-v3--focused');
    if (!props.disableExpand) classlist.push('portrait-card-v3--expandable');
    if (props.isLarge) classlist.push('portrait-card-v3--large');
    if (props.className) classlist.push(props.className);
    return classlist.join(' ');
};

const getBodyClassName = (isFocused: boolean, isDisableScale: boolean) => {
    const classlist = ['portrait-card-v3__body'];
    if (isFocused) classlist.push('portrait-card-v3__body--focused');
    if (isDisableScale) classlist.push('portrait-card-v3__body--disable-scale');
    return classlist.join(' ');
};

export type CardPortraitProps = React.PropsWithChildren<{
    className?: string;
    parentalRating?: string;
    sessionType?: PinSessionType;
    disableExpand?: boolean;
    disableScale?: boolean;
    isLarge?: boolean;
    isFocused?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onHover?: React.MouseEventHandler<HTMLDivElement>;
    onExpand?: () => void;
}> &
    React.RefAttributes<HTMLDivElement>;

export type CardPortraitComponent = React.FunctionComponent<CardPortraitProps>;

export const CardPortrait: CardPortraitComponent = React.forwardRef((props, ref) => {
    const onTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (event) => {
        if (event.propertyName === 'width' && props.isFocused && props.onExpand) props.onExpand();
    };

    return (
        <Mask.Provider parentalRatingId={props.parentalRating} pinSessionType={props.sessionType}>
            <CardPortraitContext.Provider value={{ isFocused: !!props.isFocused, isLarge: !!props.isLarge }}>
                <div
                    ref={ref}
                    className={getClassName(props)}
                    onClick={props.onClick}
                    onMouseOver={props.onHover}
                    onTransitionEnd={onTransitionEnd}
                >
                    <Div
                        className={getBodyClassName(!!props.isFocused, !!props.disableScale)}
                        backgroundColor={props.isFocused ? BackgroundColor.TERTIARY : BackgroundColor.SECONDARY}
                        shadow={props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT}
                    >
                        {props.children}
                    </Div>
                </div>
            </CardPortraitContext.Provider>
        </Mask.Provider>
    );
});
