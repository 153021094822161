import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { QuotaFragmentDoc } from '../fragments/quota.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordingSeriesRemoveMutationVariables = Types.Exact<{
  input: Types.DeleteEpisodesOfNetworkSeriesRecordingInput;
}>;


export type RecordingSeriesRemoveMutation = { __typename?: 'Nexx4Mutations', deleteEpisodesOfNetworkSeriesRecording: { __typename: 'DeleteEpisodesOfNetworkSeriesRecordingPayload', events: Array<{ __typename?: 'Event', id: string } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export type RecordingSeriesRemoveFragment = { __typename?: 'Nexx4Mutations', deleteEpisodesOfNetworkSeriesRecording: { __typename: 'DeleteEpisodesOfNetworkSeriesRecordingPayload', events: Array<{ __typename?: 'Event', id: string } | null>, quota?: { __typename?: 'Quota', kind?: Types.QuotaKind | null, allowed: number, used: number } | null } };

export const RecordingSeriesRemoveFragmentDoc = gql`
    fragment recordingSeriesRemove on Nexx4Mutations {
  deleteEpisodesOfNetworkSeriesRecording(input: $input) {
    events {
      id
    }
    quota {
      ...quota
    }
    __typename
  }
}
    ${QuotaFragmentDoc}`;
export const RecordingSeriesRemoveDocument = gql`
    mutation recordingSeriesRemove($input: DeleteEpisodesOfNetworkSeriesRecordingInput!) {
  ...recordingSeriesRemove
}
    ${RecordingSeriesRemoveFragmentDoc}`;
export type RecordingSeriesRemoveMutationFn = Apollo.MutationFunction<RecordingSeriesRemoveMutation, RecordingSeriesRemoveMutationVariables>;

/**
 * __useRecordingSeriesRemoveMutation__
 *
 * To run a mutation, you first call `useRecordingSeriesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordingSeriesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordingSeriesRemoveMutation, { data, loading, error }] = useRecordingSeriesRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordingSeriesRemoveMutation(baseOptions?: Apollo.MutationHookOptions<RecordingSeriesRemoveMutation, RecordingSeriesRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordingSeriesRemoveMutation, RecordingSeriesRemoveMutationVariables>(RecordingSeriesRemoveDocument, options);
      }
export type RecordingSeriesRemoveMutationHookResult = ReturnType<typeof useRecordingSeriesRemoveMutation>;
export type RecordingSeriesRemoveMutationResult = Apollo.MutationResult<RecordingSeriesRemoveMutation>;
export type RecordingSeriesRemoveMutationOptions = Apollo.BaseMutationOptions<RecordingSeriesRemoveMutation, RecordingSeriesRemoveMutationVariables>;