import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { IsRecordedFragmentDoc } from '../fragments/isRecorded.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsEventRecordedQueryVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input'];
  profileId: Types.Scalars['ID']['input'];
}>;


export type IsEventRecordedQuery = { __typename?: 'Nexx4Queries', event?: { __typename: 'Event', personalInfo?: { __typename?: 'PersonalEventInfo', recordings?: Array<{ __typename: 'NetworkRecording', id: string } | null> | null } | null } | null };


export const IsEventRecordedDocument = gql`
    query isEventRecorded($eventId: ID!, $profileId: ID!) {
  event(id: $eventId) {
    ...isRecorded
    __typename
  }
}
    ${IsRecordedFragmentDoc}`;

/**
 * __useIsEventRecordedQuery__
 *
 * To run a query within a React component, call `useIsEventRecordedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEventRecordedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEventRecordedQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useIsEventRecordedQuery(baseOptions: Apollo.QueryHookOptions<IsEventRecordedQuery, IsEventRecordedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEventRecordedQuery, IsEventRecordedQueryVariables>(IsEventRecordedDocument, options);
      }
export function useIsEventRecordedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEventRecordedQuery, IsEventRecordedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEventRecordedQuery, IsEventRecordedQueryVariables>(IsEventRecordedDocument, options);
        }
export function useIsEventRecordedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsEventRecordedQuery, IsEventRecordedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsEventRecordedQuery, IsEventRecordedQueryVariables>(IsEventRecordedDocument, options);
        }
export type IsEventRecordedQueryHookResult = ReturnType<typeof useIsEventRecordedQuery>;
export type IsEventRecordedLazyQueryHookResult = ReturnType<typeof useIsEventRecordedLazyQuery>;
export type IsEventRecordedSuspenseQueryHookResult = ReturnType<typeof useIsEventRecordedSuspenseQuery>;
export type IsEventRecordedQueryResult = Apollo.QueryResult<IsEventRecordedQuery, IsEventRecordedQueryVariables>;