import throttle from 'lodash.throttle';
import React, { useEffect } from 'react';

import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { tvGuideKeyTriggered, tvGuideLoadData } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';

import { Key } from 'App/Modules/Key';
import { Focus } from 'App/Packages/Focus';

const TVGuideControlsComponent: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const wheelHandler = throttle(
            (event: WheelEvent) => {
                const { deltaY, deltaX } = event;
                const key = (deltaY || deltaX) > 0 ? Key.VK_DOWN : Key.VK_UP;
                dispatch(tvGuideKeyTriggered(key));
                dispatch(tvGuideLoadData());
            },
            250,
            { trailing: false }
        );

        document.addEventListener('wheel', wheelHandler);
        return () => {
            document.removeEventListener('wheel', wheelHandler);
        };
    }, [dispatch]);

    Focus.useKeyDown((event) => dispatch(tvGuideKeyTriggered(event.key)), {
        keys: [Key.VK_BACK, Key.VK_ENTER, Key.VK_UP, Key.VK_DOWN, Key.VK_LEFT, Key.VK_RIGHT],
    });

    Focus.useKeyUp(() => dispatch(tvGuideLoadData()), {
        keys: [Key.VK_UP, Key.VK_DOWN, Key.VK_LEFT, Key.VK_RIGHT],
    });

    return null;
};

export const TVGuideControls = React.memo(TVGuideControlsComponent) as React.FunctionComponent;
