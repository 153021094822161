import { useOnChange } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/Hooks/useOnChange';
import { communityActions } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/actions';
import { getInitialCommunityState } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/getInitialState';
import { communityReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/reducer';
import { communitySelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/selectors';
import {
    CommunityActionType,
    CommunityEntity,
    CommunityEntityTable,
    CommunityReduxAction,
} from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/types';

export { useOnChange };

export { CommunityActionType as ActionType };

export type { CommunityEntity as Entity, CommunityEntityTable as EntityTable, CommunityReduxAction as ReduxAction };

export const getInitialState = getInitialCommunityState;

export const actions = communityActions.public;

export const selectors = communitySelectors.public;

export const reducer = communityReducer;
