import React from 'react';

import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';

import { UI } from 'App/Packages/UI';
import { Detail } from 'App/Modules/Data/Detail';
import { Env } from 'App/Env';
import { DetailSeriesScreen } from 'App/Modules/Screen/Modules/DetailSeriesScreen';
import { DetailRootScreen } from 'App/Modules/Screen/Modules/DetailRootScreen';

export const Header = () => {
    const dispatch = useDispatch();

    const assetSelector = Detail.Series.selectors.selectAsset;
    const asset = useSelector(assetSelector);
    const watchButtonText = useSelector(Detail.Root.selectors.selectAssetWatchButtonText(assetSelector));
    const assetTitle = useSelector(Detail.Root.selectors.selectAssetTitle(assetSelector));
    const assetActors = useSelector(Detail.Root.selectors.selectAssetActors(assetSelector));
    const assetDirectors = useSelector(Detail.Root.selectors.selectAssetDirectors(assetSelector));
    const assetDescription = useSelector(Detail.Root.selectors.selectAssetDescription(assetSelector));
    const assetType = useSelector(Detail.Root.selectors.selectAssetType(assetSelector));
    const contentMarker = useSelector(Detail.Root.selectors.selectAssetContentMarker(assetSelector));
    const availableUntil = useSelector(Detail.Root.selectors.selectAssetAvailableUntil(assetSelector));
    const isFavourited = useSelector(Detail.Root.selectors.selectAssetIsFavourited(assetSelector));
    const reminder = useSelector(Detail.Root.selectors.selectAssetReminder(assetSelector));
    const assetProgress = useSelector(Detail.Root.selectors.selectAssetProgress(assetSelector));
    const assetProgressColor = useSelector(Detail.Root.selectors.selectAssetProgressColor(assetSelector));
    const isProgressVisible = useSelector(Detail.Root.selectors.selectAssetIsProgressVisible(assetSelector));
    const assetCanBeStartedOver = useSelector(Detail.Root.selectors.selectAssetCanBeStartedOver(assetSelector));
    const assetCanBeWatched = useSelector(Detail.Root.selectors.selectAssetCanBeWatched(assetSelector));
    const isStartRecordingVisible = useSelector(Detail.Root.selectors.selectAssetIsStartRecordingVisible(assetSelector));
    const isStopRecordingVisible = useSelector(Detail.Root.selectors.selectAssetIsStopRecordingVisible(assetSelector));
    const isDeleteRecordingVisible = useSelector(Detail.Root.selectors.selectAssetIsDeleteRecordingVisible(assetSelector));
    const isReminderVisible = useSelector(Detail.Root.selectors.selectAssetIsReminderVisible(assetSelector));
    const isSeriesManageRecordingsVisible = useSelector(Detail.Root.selectors.selectAssetIsSeriesRecordingVisible(assetSelector));
    const screenBackgroundImage = useSelector(Detail.Series.selectors.selectScreenBackgroundImage);

    if (!asset || !screenBackgroundImage) return null;

    return (
        <UI.Detail parentalRating={asset.metadata.parentalRatingId}>
            <UI.Detail.Gradient.Left />
            <UI.Detail.Gradient.Bottom />
            <UI.Detail.Background src={screenBackgroundImage} />
            <UI.Detail.Body className="detail-series__body">
                <UI.Detail.Logo id={asset.content.channelId ?? ''} />
                <UI.Detail.Title>{assetTitle}</UI.Detail.Title>
                <UI.Detail.SubTitle hidden={!asset.metadata.episodeInfo}>{asset.metadata.episodeInfo}</UI.Detail.SubTitle>
                <UI.Detail.Meta>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Marker marker={contentMarker} label={availableUntil} />
                        <Icons
                            className="detail__icons"
                            status={null}
                            parentalControl={asset.metadata.parentalRatingId}
                            audioLanguages={asset.metadata.audioLanguages as string[]}
                            subtitleLanguages={asset.metadata.subtitleLanguages as string[]}
                            videoQuality={asset.metadata?.videoQuality}
                            isReminderSet={!!reminder}
                            isVisuallyImpaired={asset.entitlements.isVisuallyImpaired}
                            isFavourited={isFavourited}
                            isCatchup={asset.entitlements.catchupTV}
                        />
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Progress hidden={!isProgressVisible} progress={assetProgress} color={assetProgressColor} />
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Meta.Info>{asset.content.seasonInfo}</UI.Detail.Meta.Info>
                    </UI.Detail.Meta.Row>
                    <UI.Detail.Meta.Row>
                        <UI.Detail.Description className="detail-series__short-description">
                            {asset.content.shortDescription}
                        </UI.Detail.Description>
                        <UI.Detail.ShowMore
                            onEnter={() =>
                                dispatch(
                                    DetailSeriesScreen.actions.showMoreSelected(assetTitle, assetActors, assetDirectors, assetDescription)
                                )
                            }
                        />
                    </UI.Detail.Meta.Row>
                </UI.Detail.Meta>
                <UI.Folder.Detail.Actions id="header-detail-actions">
                    <UI.Detail.Button.Watch
                        hidden={!assetCanBeWatched}
                        text={watchButtonText}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.watchSelected())}
                    />
                    <UI.Detail.Button.StartOver
                        hidden={!assetCanBeStartedOver}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.startOverSelected())}
                    />
                    <UI.Detail.Button.ManageRecordings
                        hidden={!isSeriesManageRecordingsVisible}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.manageRecordingsSelected())}
                    />
                    <UI.Detail.Button.Record
                        hidden={!isStartRecordingVisible}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.recordSelected())}
                    />
                    <UI.Detail.Button.CancelRecord
                        hidden={!isStopRecordingVisible}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.cancelRecordSelected())}
                    />
                    <UI.Detail.Button.DeleteRecord
                        hidden={!isDeleteRecordingVisible}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.deleteRecordSelected())}
                    />
                    <UI.Detail.Button.Reminder
                        hidden={!isReminderVisible}
                        reminder={reminder}
                        onEnter={() => dispatch(DetailSeriesScreen.actions.reminderSelected())}
                    />
                    <UI.Detail.Button.Favorite
                        hidden={Env.isBusiness}
                        isFavourited={isFavourited}
                        onEnter={() => dispatch(DetailRootScreen.actions.addToFavouritesSelected(asset.id, isFavourited, assetType))}
                    />
                </UI.Folder.Detail.Actions>
            </UI.Detail.Body>
        </UI.Detail>
    );
};
