import {
    AppSorting,
    ChannelKind,
    ChannelListKind,
    ConflictAutoResolution,
    ContentFolderKind,
    ContentItemType,
    DeviceType,
    EventLoggingOption,
    GroupInfoSelectBehaviour,
    ImageFlavour,
    Maybe,
    MessageDisplayKind,
    MessageNotificationDisplayInfoScreens,
    MessagePromotionDisplayInfoScreens,
    MessagePromotionDisplayInfoTriggers,
    MessageStatus,
    ProductKind,
    ProductPurchaseStatus,
    ProfileAvatarType,
    ProfileKind,
    ProfileProtection,
    QuotaKind,
    RecordingStatus,
    VideoQuality,
    VideoQualityLimitKind,
} from '__SMART_APP_OLD__/api/graphql/types';
import { ChannelFragment } from '__SMART_APP_OLD__/app/api/channel/fragments/channel.generated';
import { ChannelListFragment } from '__SMART_APP_OLD__/app/api/channelList/fragments/channelList.generated';
import { ChannelListChannelsEdgeFragment } from '__SMART_APP_OLD__/app/api/channelList/fragments/channelListChannelsEdge.generated';
import { DeviceFragment } from '__SMART_APP_OLD__/app/api/device/fragments/device.generated';
import { DeviceEnablementPolicyFragment } from '__SMART_APP_OLD__/app/api/device/fragments/deviceEnablementPolicy.generated';
import { EventFragment } from '__SMART_APP_OLD__/app/api/event/fragments/event.generated';
import { EventEntitlementsFragment } from '__SMART_APP_OLD__/app/api/event/fragments/eventEntitlements.generated';
import { StreamInfoFragment } from '__SMART_APP_OLD__/app/api/event/fragments/streamInfo.generated';
import { EpisodeInfoFragment } from '__SMART_APP_OLD__/app/api/fragments/episodeInfo.generated';
import { GenreFragment } from '__SMART_APP_OLD__/app/api/fragments/genre.generated';
import { MetadataFragment } from '__SMART_APP_OLD__/app/api/fragments/metadata.generated';
import { Product_VodProduct_Fragment } from '__SMART_APP_OLD__/app/api/fragments/product.generated';
import { SeriesInfoFragment } from '__SMART_APP_OLD__/app/api/fragments/seriesInfo.generated';
import { TrailerFragment } from '__SMART_APP_OLD__/app/api/fragments/trailer.generated';
import { VodEntitlementFragment } from '__SMART_APP_OLD__/app/api/fragments/vodEntitlement.generated';
import { HouseholdOnboardingInfoFragment } from '__SMART_APP_OLD__/app/api/init/fragments/householdOnBoardingInfo.generated';
import { ParentalRatingInfoFragment } from '__SMART_APP_OLD__/app/api/init/fragments/parentalRating.generated';
import { ProfileFragment } from '__SMART_APP_OLD__/app/api/init/fragments/profile.generated';
import { ProfileOnboardingInfoFragment } from '__SMART_APP_OLD__/app/api/init/fragments/profileOnboardingInfo.generated';
import { ProfilePreferencesFragment } from '__SMART_APP_OLD__/app/api/init/fragments/profilePreferences.generated';
import { MessageFragment } from '__SMART_APP_OLD__/app/api/message/fragments/message.generated';
import { MessageActionFragment } from '__SMART_APP_OLD__/app/api/message/fragments/messageAction.generated';
import {
    MessageAttachment_ImageItem_Fragment,
    MessageAttachment_VodAsset_Fragment,
} from '__SMART_APP_OLD__/app/api/message/fragments/messageAttachment.generated';
import { MessageNotificationInfoFragment } from '__SMART_APP_OLD__/app/api/message/fragments/messageNotificationInfo.generated';
import { ParentalRatingFragment } from '__SMART_APP_OLD__/app/api/parentalRating/fragments/parentalRating.generated';
import { QuotaFragment } from '__SMART_APP_OLD__/app/api/recording/fragments/quota.generated';
import { RecordingFragment } from '__SMART_APP_OLD__/app/api/recording/fragments/recording.generated';
import { RecordingDetailedFragment } from '__SMART_APP_OLD__/app/api/recording/fragments/recording_detailed.generated';
import { ReminderFragment } from '__SMART_APP_OLD__/app/api/reminder/fragments/reminder.generated';
import { BookmarkFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks/api/fragments/bookmark.generated';
import { CommunityFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/api/fragments/community.generated';
import { ConsentFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent/api/fragments/consent.generated';
import { FavouritesFragment } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/api/fragments/favourites.generated';
import { LanguageFragment } from '__SMART_APP_OLD__/app/modules/Language/api/fragments/language.generated';

export type ChannelListID = string;
export type ChannelID = string;
export type ChannelUserInfoID = string;
export type ChannelIndex = number;
export type ChannelNumber = number;
export type EventID = string;
export type RecordingID = string;
export type ReminderID = string;
export type LanguageID = string;
export type IsoCode = string;
export type ParentalRatingID = string;
export type BookmarkID = string;
export type MetadataID = string;
export type GenreID = string;
export type SeriesInfoID = string;
export type EpisodeInfoID = string;
export type DeviceID = string;
export type ProfileID = string;
export type UserID = string;
export type HouseholdID = string;
export type MessageID = string;
export type MessageActionID = string;
export type MessageNotificationDisplayInfoID = string;
export type MessagePromotionDisplayInfoID = string;
export type CommunityID = string;
export type QuotaID = string;
export type SeriesID = string;
export type ChannelProductID = string;
export type ContentFolderID = string;
export type ContentFolderListID = string;
export type VODFolderID = string;
export type VODAssetID = string;
export type VODProductID = string;
export type VODSeriesID = string;
export type ProductBundleID = string;
export type BannerID = string;
export type GroupingInfoID = string;
export type PriceID = string;
export type TrailerID = string;
export type TrailerLinkedItemID = VODAssetID | VODProductID;
export type ProductEntitlementID = string;
export type ProductPurchaseInfoID = string;
export type ContentProviderID = string;
export type VODAssetEntitlementID = string;
export type ProductID = ChannelProductID | VODProductID | ProductBundleID;
export type ImageID = string;
export type MessageAttachmentID = ImageID | VODAssetID;

export type ContentFolderItemID =
    | EventID
    | RecordingID
    | SeriesID
    | ChannelID
    | ChannelProductID
    | VODFolderID
    | VODAssetID
    | VODProductID
    | VODSeriesID
    | ProductBundleID
    | BannerID;

export enum TrailerItemType {
    Vodasset = ContentItemType.Vodasset,
    Vodproduct = ContentItemType.Vodproduct,
}

export enum ProductType {
    Channelproduct = ContentItemType.Channelproduct,
    Productbundle = ContentItemType.Productbundle,
    Vodproduct = ContentItemType.Vodproduct,
}

export enum MessageAttachmentType {
    Image = 'IMAGE',
    Vodasset = 'VODASSET',
}

export type Genre = GenreFragment;
export type Bookmark = BookmarkFragment;
export type RecordingQuery = RecordingFragment;
export type Recording = RecordingDetailedFragment;
export type DeviceEnablementPolicy = DeviceEnablementPolicyFragment;
export type Device = DeviceFragment;
export type Reminder = ReminderFragment;
export type Message = MessageFragment;
export type MessageAttachment = MessageVODAssetAttachment | MessageImageAttachment;
export type MessageVODAssetAttachment = MessageAttachment_VodAsset_Fragment;
export type MessageImageAttachment = MessageAttachment_ImageItem_Fragment;
export type MessageNotificationInfo = MessageNotificationInfoFragment;
export type MessageAction = MessageActionFragment;
export type Trailer = TrailerFragment;
export type ChannelList = ChannelListFragment;
export type Language = LanguageFragment;
export type ParentalRating = ParentalRatingFragment;
export type Quota = QuotaFragment;
export type Channel = ChannelFragment;
export type ChannelListChannelEdge = ChannelListChannelsEdgeFragment;
export type Event = EventFragment;
export type EventEntitlements = EventEntitlementsFragment;
export type Favourites = FavouritesFragment;
export type StreamInfo = StreamInfoFragment;
export type Metadata = MetadataFragment;
export type SeriesInfo = SeriesInfoFragment;
export type EpisodeInfo = EpisodeInfoFragment;
export type VODAssetEntitlement = VodEntitlementFragment;
export type VodProductEntitlement = Product_VodProduct_Fragment;
export type Community = CommunityFragment;
export type Consent = ConsentFragment;

export type ProfileInfo = ProfileFragment;
export type ProfilePreferences = ProfilePreferencesFragment;
export type ParentalRatingInfo = ParentalRatingInfoFragment;
export type HouseholdOnboardingInfo = HouseholdOnboardingInfoFragment;
export type ProfileOnboardingInfo = ProfileOnboardingInfoFragment;

export {
    DeviceType,
    ChannelListKind,
    ChannelKind,
    ImageFlavour,
    RecordingStatus,
    ConflictAutoResolution,
    MessageDisplayKind,
    MessageStatus,
    MessageNotificationDisplayInfoScreens,
    MessagePromotionDisplayInfoScreens,
    MessagePromotionDisplayInfoTriggers,
    VideoQualityLimitKind,
    EventLoggingOption,
    ProfileKind,
    ProfileAvatarType,
    ProfileProtection,
    AppSorting,
    QuotaKind,
    ContentFolderKind,
    ContentItemType,
    GroupInfoSelectBehaviour,
    ProductKind,
    ProductPurchaseStatus,
    VideoQuality,
};

export type { Maybe };
