import { Fti } from '__SMART_APP_OLD__/app/modules/Fti';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { getThemeChangeUIActionEvent } from 'analytics/logging/factories/uiActionEventFactory';
import { LoggingService } from 'analytics/loggingService';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const selected =
    (option: Theme.Type): Thunk<void> =>
    (dispatch) => {
        LoggingService.getInstance().logEvent(getThemeChangeUIActionEvent(option));
        dispatch(Theme.actions.set(option));
        dispatch(Fti.actions.next(Screen.Type.APPEARANCE));
    };

const focused =
    (option: Theme.Type): Thunk<void> =>
    (dispatch) => {
        dispatch(Theme.actions.applied(option));
    };

const mount = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Overlay.actions.unmount());
};

export const actions = {
    public: {},
    private: {
        focused,
        selected,
    },
    lifecycle: {
        mount,
    },
} as const;
