import React from 'react';

import { SettingsAboutAppItem } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/SettingsAboutAppItem';
import { SETTINGS_ABOUT_APP_TITLE } from '__SMART_APP_OLD__/app/modules/Screen/modules/SettingsScreen/SettingsAboutApp/types';

export const SettingsAboutAppDetails: React.FunctionComponent = () => (
    <div className="item-info">
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.APP_VERSION} />
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.DEVICE_ID} />
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.OS_VERSION} />
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.CONFIG_ID} />
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.APP_USER_ACCOUNT} />
        <SettingsAboutAppItem title={SETTINGS_ABOUT_APP_TITLE.TV_MODEL} withBottomSeparator />
    </div>
);
