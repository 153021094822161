import * as Types from '../../../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
export type ChannelProductDetailItemFragment = { __typename: 'ChannelProduct', id: string };

export const ChannelProductDetailItemFragmentDoc = gql`
    fragment channelProductDetailItem on ChannelProduct {
  id
  __typename
}
    `;