import React, { useCallback } from 'react';

import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';
import { shallowEqual } from 'react-redux';

import { LandscapeCard } from '__SMART_APP_OLD__/app/components/LandscapeCard';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/actions';

const CollectionScreenCardLandscapeComponent: React.FunctionComponent<CollectionItemProps> = (props) => {
    const dispatch = useDispatch();
    const title = useSelector(Collection.selectors.selectEntityTitle(props.id));
    const thumbnail = useSelector(Collection.selectors.selectEntityThumbnail(props.id));
    const channelId = useSelector(Collection.selectors.selectEntityProviderId(props.id));
    const entityType = useSelector(Collection.selectors.selectEntityType(props.id));
    const progress = useSelector(Collection.selectors.selectEntityProgressValue(props.id));
    const progressBar = useSelector(Collection.selectors.seclectEntityHasProgressBar(props.id));
    const progressColor = useSelector(Collection.selectors.selectEntityProgressColor(props.id));
    const contentMarker = useSelector(Collection.selectors.selectEntityContentMarker(props.id));
    const imageVariant = useSelector(Collection.selectors.selectImageVariant(props.id));
    const description = useSelector(Collection.selectors.selectEntityDescription(props.id));
    const availableUntil = useSelector(Collection.selectors.selectEntityContentMarkerLabel(props.id));
    const icons = useSelector(Collection.selectors.selectEntityIcons(props.id), shallowEqual);

    const enterHandler = useCallback(() => dispatch(actions.public.enterTriggered(props.id, entityType)), [entityType, dispatch, props.id]);

    return (
        <LandscapeCard
            id={props.id}
            ctx={props.ctx}
            className={props.className}
            channelId={channelId}
            title={title}
            description={description}
            assetType={entityType ?? ''}
            icons={icons}
            image={thumbnail}
            imageVariant={imageVariant}
            isFocused={props.isFocused}
            disableSelector
            disableAnimation
            progressBar={progressBar}
            progress={progress}
            progressColor={progressColor}
            contentMarker={contentMarker}
            availableUntil={availableUntil}
            onEnter={enterHandler}
            onHover={props.onHover}
        />
    );
};

export const CollectionScreenCardLandscape = React.memo(CollectionScreenCardLandscapeComponent);
