import React, { useContext } from 'react';

import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import { SettingsSectionContentItemText } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemText';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelName } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';

export const SettingsSectionContentItemChannelName: React.FunctionComponent = () => {
    const { id } = useContext(SettingsSectionContentItemContext);
    const name = useSelector(selectChannelName(id));
    return <SettingsSectionContentItemText className="settings-section-content-item__channel-name">{name}</SettingsSectionContentItemText>;
};
