import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type CommunityFragment = { __typename?: 'Community', id: string, title: string };

export const CommunityFragmentDoc = gql`
    fragment community on Community {
  id
  title
}
    `;