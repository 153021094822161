import React from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';

import { Folder } from 'App/Modules/Folder/index';
import { UI } from 'App/Packages/UI';

export type VideoStoreOptionalMoreCardProps = {
    id: string;
    selector: Selector<Folder.Entity | null>;
    onEnter?: (id: string) => void;
};

export const MoreCard: React.FunctionComponent<VideoStoreOptionalMoreCardProps> = (props) => {
    const shouldRender = useSelector(Folder.selectors.selectNeedMoreCard(props.selector));

    if (!shouldRender) return null;

    return <UI.Folder.Card.More id={props.id} onEnter={props.onEnter} />;
};
