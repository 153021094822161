import { Guard as FocusGuard } from 'App/Packages/Focus/Types/Guard';
import { useList as useListHook } from 'App/Packages/Focus/Hooks/useList';
import { Subscribable } from 'App/Packages/Subscribable';
import { DetailActionsValue } from 'App/Packages/UI/Detail/Modules/DetailActions/Context/DetailActionsContext';
import { useDetailActionsEnter } from 'App/Packages/UI/Detail/Modules/DetailActions/Hooks/Private/useDetailActionsEnter';
import { Axis } from 'App/Types/Axis';
import { useMemo } from 'react';

export type UseDetailActionsValuePayload = {
    ctx: string;
    isFocused: boolean;
    onEnter?: (id: string) => void;
};

export type UseDetailActionsValue = (payload: UseDetailActionsValuePayload) => DetailActionsValue;

const guard: FocusGuard<string> = (e) => {
    return !e.y;
};

export const useDetailActionsValue: UseDetailActionsValue = (payload) => {
    const { ctx } = payload;
    const isFocused = Subscribable.useValue(payload.isFocused);
    const ids = Subscribable.useArray<string>([]);
    const items = Subscribable.use(ids);
    const navigation = useListHook({ ctx, axis: Axis.X, items, guard });
    const focused = Subscribable.useValue<string>(navigation.focused);
    const { focus } = navigation;
    const enter = useDetailActionsEnter(payload.onEnter);

    Subscribable.useSetter(focused, navigation.focused);
    Subscribable.useSetter(isFocused, payload.isFocused);

    return useMemo<DetailActionsValue>(
        () => ({
            ctx,
            ids,
            isFocused,
            focused,
            focus,
            enter,
        }),
        [ctx, ids, isFocused, focused, focus, enter]
    );
};
