import * as Types from '../types';

import { gql } from '@apollo/client';
import { PersonalChannelInfoFragmentFragmentDoc } from '../query/fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlayChannelMutationVariables = Types.Exact<{
  input: Types.PlayChannelInput;
  profileId: Types.Scalars['ID']['input'];
}>;


export type PlayChannelMutation = { __typename?: 'Nexx4Mutations', playChannel: { __typename: 'PlayChannelPayload', playbackInfo: { __typename: 'ChannelPlaybackInfo', sessionId?: string | null, url: string, deliveryKind: Types.ContentDeliveryKind, adPlaybackRestrictions?: Array<Types.AdPlaybackRestriction | null> | null, adPlaybackPreRoll?: number | null, playbackRestrictions?: Array<{ __typename: 'EventConcurrencyPlaybackRestriction', message: string } | { __typename: 'JailbrokenPlaybackRestriction' } | { __typename: 'OutputPlaybackRestriction' } | { __typename: 'VideoQualityPlaybackRestriction' } | null> | null, channel: { __typename: 'Channel', id: string, kind: Types.ChannelKind, title: string, personalInfo?: { __typename: 'PersonalChannelInfo', id: string, expiry: any, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null } | null }, heartbeat?: { __typename: 'GraphQLHeartbeat' } | { __typename: 'HttpHeartbeat', url: string, interval: number, includeAuthHeaders: boolean } | null } } };


export const PlayChannelDocument = gql`
    mutation playChannel($input: PlayChannelInput!, $profileId: ID!) {
  playChannel(input: $input) {
    playbackInfo {
      sessionId
      url
      playbackRestrictions {
        ... on EventConcurrencyPlaybackRestriction {
          message
          __typename
        }
        __typename
      }
      deliveryKind
      adPlaybackRestrictions
      adPlaybackPreRoll
      channel {
        id
        kind
        title
        personalInfo(profileId: $profileId) {
          ...personalChannelInfoFragment
        }
        __typename
      }
      heartbeat {
        ... on HttpHeartbeat {
          url
          interval
          includeAuthHeaders
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
    ${PersonalChannelInfoFragmentFragmentDoc}`;
export type PlayChannelMutationFn = Apollo.MutationFunction<PlayChannelMutation, PlayChannelMutationVariables>;

/**
 * __usePlayChannelMutation__
 *
 * To run a mutation, you first call `usePlayChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playChannelMutation, { data, loading, error }] = usePlayChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function usePlayChannelMutation(baseOptions?: Apollo.MutationHookOptions<PlayChannelMutation, PlayChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayChannelMutation, PlayChannelMutationVariables>(PlayChannelDocument, options);
      }
export type PlayChannelMutationHookResult = ReturnType<typeof usePlayChannelMutation>;
export type PlayChannelMutationResult = Apollo.MutationResult<PlayChannelMutation>;
export type PlayChannelMutationOptions = Apollo.BaseMutationOptions<PlayChannelMutation, PlayChannelMutationVariables>;