import * as Types from '../../../../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { BookmarkFragmentDoc } from '../fragments/bookmark.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetVodBookmarkMutationVariables = Types.Exact<{
  input: Types.SetVodBookmarkInput;
  profileId: Types.Scalars['ID']['input'];
}>;


export type SetVodBookmarkMutation = { __typename?: 'Nexx4Mutations', setVODBookmark: { __typename?: 'SetVODBookmarkPayload', vodAsset: { __typename?: 'VODAsset', personalInfo?: { __typename?: 'PersonalVODInfo', bookmark?: { __typename?: 'Bookmark', position: number, audio?: string | null, subtitle?: string | null, item?: { __typename?: 'Event', id: string, start: any, end: any } | { __typename?: 'NetworkRecording', id: string, start: any, end: any } | { __typename?: 'VODAsset', id: string, duration: number } | null } | null } | null } } };


export const SetVodBookmarkDocument = gql`
    mutation setVODBookmark($input: SetVODBookmarkInput!, $profileId: ID!) {
  setVODBookmark(input: $input) {
    vodAsset {
      personalInfo(profileId: $profileId) {
        bookmark {
          ...bookmark
        }
      }
    }
  }
}
    ${BookmarkFragmentDoc}`;
export type SetVodBookmarkMutationFn = Apollo.MutationFunction<SetVodBookmarkMutation, SetVodBookmarkMutationVariables>;

/**
 * __useSetVodBookmarkMutation__
 *
 * To run a mutation, you first call `useSetVodBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVodBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVodBookmarkMutation, { data, loading, error }] = useSetVodBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useSetVodBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<SetVodBookmarkMutation, SetVodBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVodBookmarkMutation, SetVodBookmarkMutationVariables>(SetVodBookmarkDocument, options);
      }
export type SetVodBookmarkMutationHookResult = ReturnType<typeof useSetVodBookmarkMutation>;
export type SetVodBookmarkMutationResult = Apollo.MutationResult<SetVodBookmarkMutation>;
export type SetVodBookmarkMutationOptions = Apollo.BaseMutationOptions<SetVodBookmarkMutation, SetVodBookmarkMutationVariables>;