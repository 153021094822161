// @ts-ignore
import * as muxjs from 'mux.js';
import '@castlabs/prestoplay/clpp.styles.css';
import '@castlabs/prestoplay/cjs/polyfill.min';
import { clpp } from '@castlabs/prestoplay';
import '@castlabs/prestoplay/cl.conviva';
import '@castlabs/prestoplay/cl.htmlcue';
import '@castlabs/prestoplay/cl.dash';
import '@castlabs/prestoplay/cl.mse';
import '@castlabs/prestoplay/cl.hls';
import '@castlabs/prestoplay/cl.ttml';
import '@castlabs/prestoplay/cl.vtt';

import { Env } from 'App/Env';

window.muxjs = muxjs;
window.clpp = clpp;
declare const window: Window &
    // eslint-disable-next-line no-undef
    typeof globalThis & {
        Conviva: any;
        muxjs: any;
        clpp: any;
    };

export const setupDefaultPlayer = (
    videoDomName: string,
    drm: object | null,
    householdId: string,
    settings: object,
    webOSStartupPatch: boolean
) => {
    // @ts-ignore
    clpp.log.setLogLevel(clpp.log.Level.ERROR);
    // @ts-ignore
    clpp.log.setTagLevel('clpp.media.SegmentIndex', clpp.log.Level.ERROR);
    // @ts-ignore
    clpp.install(clpp.dash.DashComponent);
    // @ts-ignore
    clpp.install(clpp.hls.HlsComponent);
    // @ts-ignore
    clpp.install(clpp.drm.HeaderDrmComponent);
    // @ts-ignore
    clpp.install(clpp.htmlcue.HtmlCueComponent);
    // @ts-ignore
    clpp.install(clpp.ttml.TtmlComponent);
    // @ts-ignore
    clpp.install(clpp.vtt.VttComponent);

    const player = new clpp.Player(
        videoDomName,
        {
            ...settings,
            license: Env.CastLabs.Key,
            drm,
            viewerId: householdId,
        },
        // @ts-ignore
        { webOSStartupPatch }
    );
    return player;
};
