import { KeyArrowRightIconInsance } from 'App/Packages/UI/Keyboard/Components/Key/ArrowRight/KeyArrowRightIcon';
import { Key, KeyProps } from 'App/Packages/UI/Keyboard/Components/Key/Key';
import React from 'react';

export type KeyArrowRightProps = KeyProps & {};

export type KeyArrowRightComponent = React.FunctionComponent<KeyArrowRightProps>;

export const KeyArrowRight: KeyArrowRightComponent = (props) => {
    const { size = 'x1', children = KeyArrowRightIconInsance, ...other } = props;

    return (
        <Key size={size} {...other}>
            {children}
        </Key>
    );
};
