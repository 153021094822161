import { combineReducers } from 'redux';

import { authSessionReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/reducer';
import { Bookmarks } from '__SMART_APP_OLD__/app/modules/Data/modules/Bookmarks';
import { channelEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/reducer';
import { channelListEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/reducer';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { Community } from '__SMART_APP_OLD__/app/modules/Data/modules/Community';
import { Consent } from '__SMART_APP_OLD__/app/modules/Data/modules/Consent';
import { deviceEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/deviceEntityTable/reducer';
import { eventEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/reducer';
import { Favourites } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites';
import { Household } from '__SMART_APP_OLD__/app/modules/Data/modules/Household';
import { messageEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/reducer';
import { parentalRatingEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/parentalRatingEntityTable/reducer';
import { pinReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/reducer';
import { profileReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/Profile/store/reducer';
import { reminderEntityTableReducer } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/reducer';
import { TrackViewingBehaviour } from '__SMART_APP_OLD__/app/modules/Data/modules/TrackViewingBehaviour';
import { DataState } from '__SMART_APP_OLD__/app/modules/Data/types';
import { recordingEntityTableReducer } from '__SMART_APP_OLD__/app/store/reducers/data/recordingEntityTable/recordingEntityTable.reducer';
import { Action } from '__SMART_APP_OLD__/app/store/types/Action';

export const dataReducer = combineReducers<DataState, Action>({
    recordingEntityTable: recordingEntityTableReducer,
    reminderEntityTable: reminderEntityTableReducer,
    authSession: authSessionReducer,
    channelEntityTable: channelEntityTableReducer,
    channelListEntityTable: channelListEntityTableReducer,
    deviceEntityTable: deviceEntityTableReducer,
    eventEntityTable: eventEntityTableReducer,
    messageEntityTable: messageEntityTableReducer,
    parentalRatingEntityTable: parentalRatingEntityTableReducer,
    pin: pinReducer,

    // Refactored
    profile: profileReducer,
    trackViewingBehaviour: TrackViewingBehaviour.reducer,
    bookmarks: Bookmarks.reducer,
    consent: Consent.reducer,
    community: Community.reducer,
    collection: Collection.reducer,
    favourites: Favourites.reducer,
    household: Household.reducer,
});
