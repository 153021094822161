import React, { useContext } from 'react';

import { SettingsSectionContentItemChannelNumber } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemChannelNumber';
import { SettingsSectionContentItemContext } from '__SMART_APP_OLD__/app/components/SettingsV2/SettingsSectionContentItemContext';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectChannelDefaultNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/selectors';

export const SettingsSectionContentItemChannelNumberDefault: React.FunctionComponent = () => {
    const { id } = useContext(SettingsSectionContentItemContext);
    const number = useSelector(selectChannelDefaultNumber(id));
    return <SettingsSectionContentItemChannelNumber>{number}</SettingsSectionContentItemChannelNumber>;
};
