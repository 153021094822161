import { by639_1 } from 'iso-language-codes';

import { Storage } from '__SMART_APP_OLD__/app/common/storage';

export const getLanguageCode = (): string => {
    const code = Storage.get(Storage.Key.LANGUAGE);
    if (code?.length === 2) return by639_1[code as keyof typeof by639_1].iso639_2T;
    if (code?.length === 3) return code;
    return '';
};

export const isLanguageSet = () => !!getLanguageCode();
