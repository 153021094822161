import Asset from '__SMART_APP_OLD__/data/Asset';
import Banner from '__SMART_APP_OLD__/data/Banner';
import Channel from '__SMART_APP_OLD__/data/Channel';
import ChannelProduct from '__SMART_APP_OLD__/data/ChannelProduct';
import NetworkRecording from '__SMART_APP_OLD__/data/NetworkRecording';
import ProductBundle from '__SMART_APP_OLD__/data/ProductBundle';
import ProgramEvent from '__SMART_APP_OLD__/data/ProgramEvent';
import Series from '__SMART_APP_OLD__/data/Series';
import VODAsset from '__SMART_APP_OLD__/data/VODAsset';
import VODFolder from '__SMART_APP_OLD__/data/VODFolder';
import VODProduct from '__SMART_APP_OLD__/data/VODProduct';
import VODSeries from '__SMART_APP_OLD__/data/VODSeries';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';

import VODEpisode from './VODEpisode';

/**
 * @description Method used to create an asset object based on the asset type
 * @param rawData - raw data
 * @param type - asset type
 * @param cursor - cursor
 * @returns - asset object
 */
export default function createAssetObject(rawData: any, type?: string, cursor?: string): any {
    switch (type) {
        case AssetType.EVENT:
            return new ProgramEvent(rawData, createAssetObject);
        case AssetType.NETWORK_RECORDING:
            return new NetworkRecording(rawData, createAssetObject);
        case AssetType.CHANNEL:
            return new Channel(rawData, createAssetObject, cursor);
        case AssetType.CHANNEL_PRODUCT:
            return new ChannelProduct(rawData, createAssetObject);
        case AssetType.VOD_ASSET:
            if (rawData?.metadata?.episodeInfo) {
                return new VODEpisode(rawData, createAssetObject);
            }
            return new VODAsset(rawData, createAssetObject);
        case AssetType.VOD_FOLDER:
            return new VODFolder(rawData, createAssetObject);
        case AssetType.VOD_SERIES:
            return new VODSeries(rawData, createAssetObject);
        case AssetType.PRODUCT_BUNDLE:
            return new ProductBundle(rawData, createAssetObject);
        case AssetType.BANNER:
            return new Banner(rawData, createAssetObject);
        case AssetType.SERIES:
            return new Series(rawData, createAssetObject);
        case AssetType.VOD_PRODUCT:
            return new VODProduct(rawData, createAssetObject);
        default:
            return new Asset(rawData, createAssetObject);
    }
}
