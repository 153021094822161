import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { Error } from '__SMART_APP_OLD__/app/modules/Error';
import { ftiApi } from '__SMART_APP_OLD__/app/modules/Fti/api';
import { ftiSelectors } from '__SMART_APP_OLD__/app/modules/Fti/store/selectors';
import {
    FtiActionType,
    FtiAgeRatingStepAction,
    FtiCloseStepAction,
    FtiComunityStepAction,
    FtiInitialActionPayload,
    FtiLanguageStepAction,
    FtiMasterPinStepAction,
    FtiPrivacyStepAction,
    FtiReduxInitialAction,
    FtiReplayStepAction,
} from '__SMART_APP_OLD__/app/modules/Fti/store/types';
import { History } from '__SMART_APP_OLD__/app/modules/History';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';
import { logoutAction, startUserSessionAction } from '__SMART_APP_OLD__/app/modules/Data/modules/authSession/actions';
import { FtiWelcomeStepAction, FtiStartupStepAction, FtiThemeStepAction, FtiEducationStepAction } from './types';

export const setupInitialFTIValuesAfterAuthentication = (values: FtiInitialActionPayload): FtiReduxInitialAction => ({
    type: FtiActionType.SETUP_INITIAL_FTI_VALUES_AFTER_AUTHENTICATION,
    payload: {
        ageRatingStepCompleted: values.ageRatingStepCompleted,
        communityStepCompleted: values.communityStepCompleted,
        masterPincodeStepCompleted: values.masterPincodeStepCompleted,
        privacyStepCompleted: values.privacyStepCompleted,
        replayStepCompleted: values.replayStepCompleted,
    },
});

const welcomeStepCompletedChanged = (value: number): FtiWelcomeStepAction => ({
    type: FtiActionType.WELCOME_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});
const closingStepCompletedChanged = (value: number): FtiCloseStepAction => ({
    type: FtiActionType.CLOSING_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});
const languageStepCompletedChanged = (value: number): FtiLanguageStepAction => ({
    type: FtiActionType.LANGUAGE_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const masterPincodeStepCompletedChanged = (value: number): FtiMasterPinStepAction => ({
    type: FtiActionType.MASTER_PIN_CODE_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const communityStepCompletedChanged = (value: number): FtiComunityStepAction => ({
    type: FtiActionType.COMMUNITY_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const replayStepCompletedChanged = (value: number): FtiReplayStepAction => ({
    type: FtiActionType.REPLAY_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const ageRatingStepCompletedChanged = (value: number): FtiAgeRatingStepAction => ({
    type: FtiActionType.AGE_RATING_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const privacyStepCompletedChanged = (value: number): FtiPrivacyStepAction => ({
    type: FtiActionType.PRIVACY_POLICY_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const startupStepCompletedChanged = (value: number): FtiStartupStepAction => ({
    type: FtiActionType.STARTUP_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const themeStepCompletedChanged = (value: number): FtiThemeStepAction => ({
    type: FtiActionType.THEME_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const educationStepCompletedChanged = (value: number): FtiEducationStepAction => ({
    type: FtiActionType.EDUCATION_STEP_COMPLETED_CHANGED,
    payload: {
        value,
    },
});

const mountScreen =
    (screen: Screen.Type): Thunk<void> =>
    (dispatch, getState) => {
        switch (screen) {
            case Screen.Type.WELCOME:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.CLOSING:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.STARTUP_BEHAVIOR:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.APPEARANCE:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.LANGUAGE_SELECTION:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.COMMUNITY_SELECTION:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.MASTER_PIN_CODE:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.AGE_RATING_SELECTION:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.REPLAY_SELECTION:
                dispatch(Screen.actions.mount(screen, {}));
                break;
            case Screen.Type.PRIVACY_POLICY:
                dispatch(Screen.actions.mount(screen, { type: Screen.PrivacyPolicyType.FTI }));
                break;
            case Screen.Type.EDUCATION:
                dispatch(Screen.actions.mount(screen, { content: ftiSelectors.private.selectEducationStepContent(getState()) }));
                break;
            default:
                break;
        }
    };

const completeScreen =
    (screen: Screen.Type): Thunk<Promise<void>> =>
    async (dispatch) => {
        try {
            switch (screen) {
                case Screen.Type.WELCOME:
                    dispatch(welcomeStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.CLOSING:
                    dispatch(closingStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.STARTUP_BEHAVIOR:
                    dispatch(startupStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.APPEARANCE:
                    dispatch(themeStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.LANGUAGE_SELECTION:
                    dispatch(languageStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.COMMUNITY_SELECTION:
                    await ftiApi.completeCommunityStep();
                    dispatch(communityStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.MASTER_PIN_CODE:
                    await ftiApi.completeMasterPincodeStep();
                    dispatch(masterPincodeStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.AGE_RATING_SELECTION:
                    await ftiApi.completeAgeRatingStep();
                    dispatch(ageRatingStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.REPLAY_SELECTION:
                    await ftiApi.completeReplayStep();
                    dispatch(replayStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.PRIVACY_POLICY:
                    await ftiApi.completePrivacyStep();
                    dispatch(privacyStepCompletedChanged(Date.now()));
                    break;
                case Screen.Type.EDUCATION:
                    dispatch(educationStepCompletedChanged(Date.now()));
                    break;
                default:
                    break;
            }
        } catch (error) {
            dispatch(Error.actions.occured(Error.Dispatcher.FTI_COMPLETE_STEP, error));
        }
    };

const uncompleteScreen =
    (screen: Screen.Type): Thunk<Promise<void>> =>
    async (dispatch) => {
        switch (screen) {
            case Screen.Type.WELCOME:
                dispatch(welcomeStepCompletedChanged(0));
                break;
            case Screen.Type.CLOSING:
                dispatch(closingStepCompletedChanged(0));
                break;
            case Screen.Type.STARTUP_BEHAVIOR:
                dispatch(startupStepCompletedChanged(0));
                break;
            case Screen.Type.APPEARANCE:
                dispatch(themeStepCompletedChanged(0));
                break;
            case Screen.Type.LANGUAGE_SELECTION:
                dispatch(languageStepCompletedChanged(0));
                break;
            case Screen.Type.COMMUNITY_SELECTION:
                dispatch(communityStepCompletedChanged(0));
                break;
            case Screen.Type.MASTER_PIN_CODE:
                dispatch(masterPincodeStepCompletedChanged(0));
                break;
            case Screen.Type.AGE_RATING_SELECTION:
                dispatch(ageRatingStepCompletedChanged(0));
                break;
            case Screen.Type.REPLAY_SELECTION:
                dispatch(replayStepCompletedChanged(0));
                break;
            case Screen.Type.PRIVACY_POLICY:
                dispatch(privacyStepCompletedChanged(0));
                break;
            case Screen.Type.EDUCATION:
                dispatch(educationStepCompletedChanged(Storage.get(Storage.Key.EDUCATION_STEP_COMPLETED) ?? 0));
                break;
            default:
                break;
        }
    };

const complete = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(History.actions.clear());
    dispatch(Screen.actions.unmount());
    const state = getState();
    // Setting Storage value here so back behaviour works as intended
    // If value is set in completeScreen when navigating back
    // uncompleteScreen will set wrong timestamp
    Storage.set(Storage.Key.EDUCATION_STEP_COMPLETED, ftiSelectors.private.selectEducationStepCompleted(state));
    dispatch(Overlay.actions.mount(Overlay.Type.Splash, {}));
    dispatch(startUserSessionAction());
};

const mountNextScreen = (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const screen = ftiSelectors.private.select(state);
    if (!screen) {
        await dispatch(complete());
        return;
    }
    dispatch(mountScreen(screen));
};

const start = (): Thunk<Promise<void>> => async (dispatch) => {
    try {
        dispatch(History.actions.clear());
        await dispatch(mountNextScreen());
    } catch (error) {
        dispatch(Error.actions.occured(Error.Dispatcher.FTI_START, error));
        dispatch(logoutAction());
        dispatch(Overlay.actions.unmount());
    }
};

const next =
    (from: Screen.Type): Thunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(History.actions.push());
        await dispatch(completeScreen(from));
        await dispatch(mountNextScreen());
    };

const back = (): Thunk<Promise<void>> => async (dispatch) => {
    const entity = dispatch(History.actions.pop(true));
    if (!entity || !entity.screen) return;
    await dispatch(uncompleteScreen(entity.screen.type));
};

export const ftiActions = {
    public: {
        start,
        next,
        back,
        setupInitialFTIValuesAfterAuthentication,
    },
    private: {},
};
