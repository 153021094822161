import { KeyboardActionType, KeyboardClosedAction, KeyboardOpenedAction } from 'App/Modules/Keyboard/Store/types';

const opened: KeyboardOpenedAction = { type: KeyboardActionType.Opened };
const closed: KeyboardClosedAction = { type: KeyboardActionType.Closed };

export const actions = {
    private: {
        opened,
        closed,
        ctx: {
            set: () => () => {},
        },
    },
    lifecycle: {
        outOfBounds: () => () => {},
    },
};
