import React from 'react';

import { useTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';

const getClassName = (theme: Theme.Type): string => {
    const classList = ['xtv-logo'];
    classList.push(Theme.getClassName('xtv-logo', theme));
    return classList.join(' ');
};

const getTvTextClassName = (tvTextClassName?: string): string => {
    const classList = ['xtv-logo__tv'];
    if (tvTextClassName) classList.push(tvTextClassName);
    return classList.join(' ');
};

type LogoXTVRootProps = { theme: Theme.Type; tvTextClassName?: string; className?: string };

const LogoXTVRoot: React.FunctionComponent<LogoXTVRootProps> = (props) => (
    <div className={props.className}>
        <svg className={getClassName(props.theme)} width="53" height="50" viewBox="0 0 53 50" xmlns="http://www.w3.org/2000/svg">
            <path
                className={getTvTextClassName(props.tvTextClassName)}
                d="M5.169 20.92h-2.12v1.7H.939V18.9h10.9v3.72h-2.11v-1.7h-2.12v9.29h1.92v2.02h-6.26v-2.02h1.91l-.01-9.29Zm7.88-2.02h5.13v2h-1.33l1.8 5.18c.273.675.477 1.375.61 2.09.17-.82.28-1.22.53-2.09l1.67-5.18h-1.41v-2h5.11v2h-1.2l-3.78 11.33h-1.78l-4.15-11.35h-1.17l-.03-1.98Z"
            />
            <path
                className="xtv-logo__x"
                d="m46.849 33.73-11.03 11.01-8.69-8.68 8.68-8.74 2.34-2.33-11.02-11.01 8.7-8.72 11.03 11.05 5.83-5.83V5.81l-5.83 5.83L35.829.6l-13.38 13.38 11.07 11.05-11.07 11.03 13.37 13.34 11.03-11.01 5.84 5.83v-4.64l-5.84-5.85Z"
            />
        </svg>
    </div>
);

type LogoXTVWithInjectedThemeProps = { tvTextClassName?: string; className?: string };

const LogoXTVWithInjectedTheme: React.FunctionComponent<LogoXTVWithInjectedThemeProps> = (props) => (
    <LogoXTVRoot className={props.className} tvTextClassName={props.tvTextClassName} theme={useTheme()} />
);

export type LogoXTVProps = {
    className?: string;
    tvTextClassName?: string;
    theme?: Theme.Type;
};

export type LogoXTVModule = React.FunctionComponent<LogoXTVProps>;

export const LogoXTV: React.FunctionComponent<LogoXTVProps> = (props) => {
    if (!props.theme) return <LogoXTVWithInjectedTheme tvTextClassName={props.tvTextClassName} className={props.className} />;
    return <LogoXTVRoot tvTextClassName={props.tvTextClassName} className={props.className} theme={props.theme} />;
};
