export const initialTimeState: number = 0;

export const SECOND_MS = 1000;
export const SECOND_S = 1;
export const MINUTE_MS = SECOND_MS * 60;
export const MINUTE_S = SECOND_S * 60;
export const HOUR_MS = MINUTE_MS * 60;
export const HOUR_S = MINUTE_S * 60;
export const DAY_MS = HOUR_MS * 24;
export const DAY_S = HOUR_S * 24;
