export class AbstractEvent {
    private readonly eventId: string;

    private readonly eventSource: string = 'Client';

    protected customData: Record<string, any> = {};

    protected eventTime: number = new Date().getTime();

    constructor(eventId: string) {
        this.eventId = eventId;
    }
}
