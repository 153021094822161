export enum SettingsElementType {
    CHANNEL_LINEUP = 'lineup',
    LANGUAGE = 'languages',
    ABOUT = 'about',
    PRIVACY_POLICY = 'policy',
    LOGOUT = 'logout',
    PIN = 'pin',
    IMPRINT = 'imprint',
    PARENTAL_CONTROL = 'parental-control',
    VIEW_CONTROL = 'view-control',
    DEVICE_MANAGEMENT = 'device-management',
    REGION = 'region',
    NVPR = 'npvr',
    APPEARANCE = 'appearance',
    STARTUP_BEHAVIOR = 'startupBehavior',
    CLOCK = 'clock',
}

export enum SettingsItemType {
    LINK = 'Link',
    SELECT = 'Select',
    INTERNAL = 'Internal',
    ACTION = 'Action',
    CHANNEL_LIST = 'Channel-List',
    CHANNEL = 'Channel',
    NPVR = 'Npvr',
}

export enum SettingsLanguageElementType {
    UI = 'SETTINGS_LANGUAGE_UI_LANGUAGE',
    AUDIO_PRIMARY = 'SETTINGS_LANGUAGE_AUDIO_PRIMARY',
    AUDIO_SECONDARY = 'SETTINGS_LANGUAGE_AUDIO_SECONDARY',
    SUBTITLE_PRIMARY = 'SETTINGS_LANGUAGE_SUBTITLE_PRIMARY',
    SUBTITLE_SECONDARY = 'SETTINGS_LANGUAGE_SUBTITLE_SECONDARY',
}

export enum SettingsParentalControlElementType {
    FSK0 = '0+',
    FSK6 = '6+',
    FSK12 = '12+',
    FSK16 = '16+',
    FSK18 = '18',
}
