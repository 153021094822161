import { ContentItemType } from '__SMART_APP_OLD__/api/graphql/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { selectIsLiveTvPinActive, selectPinIsActive } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/selectors';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';
import { Selector } from '__SMART_APP_OLD__/app/store/types/Selector';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

export const selectParentalRatingId = (state: State): GQL.ParentalRatingID => state.data.parentalRatingEntityTable.selectedId;

export const selectCurrentParentalRatingRank = (state: State): number =>
    selectParentalRatingRank(state.data.parentalRatingEntityTable.selectedId)(state);

export const selectParentalRatingIds = (state: State): GQL.ParentalRatingID[] => state.data.parentalRatingEntityTable.ids;

export const selectParentalRatingTitle =
    (id: string) =>
    (state: State): string =>
        state.data.parentalRatingEntityTable.entities?.[id]?.title ?? '';

export const selectParentalRatingDescription =
    (id: string) =>
    (state: State): string =>
        state.data.parentalRatingEntityTable.entities?.[id]?.description ?? '';

export const selectParentalRatingRank =
    (id: GQL.ParentalRatingID): Selector<number> =>
    (): number =>
        +id.replace('FSK', '').replace('-ADULT', '');

export const selectParentalRatingIsAdult =
    (id: GQL.ParentalRatingID): Selector<boolean> =>
    (): boolean =>
        id.includes('ADULT');

export const selectParentalRatingIsRestricted =
    (id: GQL.ParentalRatingID, sessionType: string, itemType: string = '') =>
    (state: State): boolean => {
        const session = sessionType === PinSessionType.PIN ? selectPinIsActive(state) : selectIsLiveTvPinActive(state);

        if (session && itemType !== ContentItemType.Vodfolder) return false;
        const adult = selectParentalRatingIsAdult(id)(state);
        if (adult) return true;
        const rank = selectParentalRatingRank(id)(state);
        const selectedRank = selectParentalRatingRank(selectParentalRatingId(state))(state);
        return rank > selectedRank;
    };
