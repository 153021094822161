import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { messageRemove } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';

import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';

const remove = (messageIds: string[]): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Prompt.actions.unmount());
    dispatch(Overlay.actions.mount(Overlay.Type.Loading, { backgroundColor: BackgroundColor.OVERLAY }));
    await dispatch(messageRemove(messageIds));
    dispatch(Overlay.actions.unmount());
};

const cancel = (): Thunk<Promise<void>> => async (dispatch) => {
    dispatch(Prompt.actions.unmount());
};

export const actions = {
    private: {
        remove,
        cancel,
    },
};
