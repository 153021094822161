import {
    COMPATIBLE_STATUS_TRANSITIONS_FOR_NOTIFICATION_STATUS,
    initialNotificationState,
} from '__SMART_APP_OLD__/app/modules/Notification/constants';
import {
    NotificationVisibilityStatus,
    UINotificationEntity,
    UINotificationEntityTable,
} from '__SMART_APP_OLD__/app/modules/Notification/types';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const notificationReducer: Reducer<UINotificationEntityTable> = (state, action) => {
    if (!state) return initialNotificationState;
    switch (action.type) {
        case ActionType.NOTIFICATION_ACTIVE_STATUS_CHANGE:
            if (state.isActive === action.payload.isActive) return state;
            return { ...state, isActive: action.payload.isActive };
        case ActionType.NOTIFICATION_SHOW: {
            if (state.stack.includes(action.payload.notification.id)) return state;
            const stack: string[] = [action.payload.notification.id, ...state.stack];
            const entities: Record<string, UINotificationEntity> = {
                ...state.entities,
                [action.payload.notification.id]: action.payload.notification,
            };
            return { ...state, entities, stack };
        }
        case ActionType.NOTIFICATION_HIDE: {
            if (!state.stack.includes(action.payload.notificationId)) return state;
            const notification = state.entities[action.payload.notificationId];
            if (notification.status !== NotificationVisibilityStatus.HIDDEN) return state;
            const stack: string[] = state.stack.filter((id) => id !== action.payload.notificationId);
            const entities: Record<string, UINotificationEntity> = { ...state.entities };
            delete entities[action.payload.notificationId];
            return { ...state, entities, stack };
        }
        case ActionType.NOTIFICATION_STATUS_CHANGE: {
            const notification = state.entities[action.payload.notificationId];
            if (!notification) return state;
            const compatibleStatus = COMPATIBLE_STATUS_TRANSITIONS_FOR_NOTIFICATION_STATUS[notification.status];
            if (compatibleStatus !== action.payload.status) return state;
            const updated: UINotificationEntity = { ...notification, status: action.payload.status };
            const entities: Record<string, UINotificationEntity> = { ...state.entities, [updated.id]: updated };
            return { ...state, entities };
        }
        default:
            return state;
    }
};
