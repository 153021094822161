import { CollectionItemProps } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/types';
import React, { useCallback } from 'react';

import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { BackgroundColor, Div, Shadow } from '__SMART_APP_OLD__/app/components/Div';
import { Image, ObjectFit, Variant } from '__SMART_APP_OLD__/app/components/Image';
import { ProgressBar } from '__SMART_APP_OLD__/app/components/ProgressBar';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { Collection } from '__SMART_APP_OLD__/app/modules/Data/modules/Collection';
import { actions } from '__SMART_APP_OLD__/app/modules/Screen/modules/CollectionScreen/actions';
import { getTimeGapInDays } from '__SMART_APP_OLD__/utils/timeUtils';

import { Focus } from 'App/Packages/Focus';
import { ContentMarkerType } from 'App/Types/ContentMarker';

interface Props {
    isFocused?: boolean;
    className?: string;
}

const getClassName = (props: Props, isDarkTheme: boolean): string => {
    const classList = ['portrait-card-v2'];
    if (isDarkTheme) {
        classList.push('portrait-card-v2--dark-theme');
    } else {
        classList.push('portrait-card-v2--light-theme');
    }
    if (props.isFocused) classList.push('portrait-card-v2--focused');
    if (props.className) classList.push(props.className);
    return classList.join(' ');
};

export const CollectionScreenPortraitItem: React.FunctionComponent<CollectionItemProps> = (props) => {
    const dispatch = useDispatch();
    const thumbnail = useSelector(Collection.selectors.selectEntityThumbnail(props.id));
    const entityType = useSelector(Collection.selectors.selectEntityType(props.id));
    const imageVariant = useSelector(Collection.selectors.selectImageVariant(props.id));
    const availableUntil = useSelector(Collection.selectors.selectEntityContentMarkerLabel(props.id));
    const contentMarker = useSelector(Collection.selectors.selectEntityContentMarker(props.id));
    const progressBar = useSelector(Collection.selectors.seclectEntityHasProgressBar(props.id));
    const progress = useSelector(Collection.selectors.selectEntityProgressValue(props.id));
    const progressColor = useSelector(Collection.selectors.selectEntityProgressColor(props.id));

    const enterHandler = useCallback(() => dispatch(actions.public.enterTriggered(props.id, entityType)), [dispatch, props.id, entityType]);

    const isDarkTheme = useIsDarkTheme();
    const className = getClassName(props, isDarkTheme);
    const shadow = props.isFocused ? Shadow.DEFAULT_SELECTED : Shadow.DEFAULT;
    const { onClick, onHover } = Focus.useFocusable({
        id: props.id,
        ctx: props.ctx,
        isFocused: props.isFocused,
        onEnter: enterHandler,
        onHover: props.onHover,
    });

    return (
        <Div className={className} backgroundColor={BackgroundColor.PRIMARY} shadow={shadow} onClick={onClick} onMouseOver={onHover}>
            <Image
                className="portrait-card-v2__image"
                src={thumbnail}
                objectFit={ObjectFit.COVER}
                variant={imageVariant ?? Variant.BASE}
                noLoading
                width={272}
                height={408}
            />
            {(availableUntil && contentMarker === ContentMarkerType.Rented) ||
                (availableUntil && contentMarker === ContentMarkerType.Recorded && (
                    <ContentMarker
                        className="portrait-card-v2__content-marker"
                        marker={contentMarker}
                        label={getTimeGapInDays(availableUntil)}
                    />
                ))}

            {progressBar && <ProgressBar className="portrait-card-v2__progress-bar" color={progressColor} progress={progress ?? 0} />}
        </Div>
    );
};
