import { selectIsNotificationsActive } from '__SMART_APP_OLD__/app/modules/Notification/selectors';
import { State } from '__SMART_APP_OLD__/app/store/types/state';

import { Alert } from 'App/Modules/Alert';
import { Keyboard } from 'App/Modules/Keyboard';
import { Overlay } from 'App/Modules/Overlay';
import { Prompt } from 'App/Modules/Prompt';
import { ScreenEntity, ScreenType } from 'App/Modules/Screen/Types';
import { Default as FocusDefault } from 'App/Packages/Focus/Constants/';

const select = (state: State): ScreenEntity | null => state.screen;

const selectTyped =
    <T extends ScreenType>(type: T) =>
    (state: State): ScreenEntity<T> | null => {
        const screen = select(state);
        if (!screen) return null;
        if (screen.type !== type) return null;
        return screen as ScreenEntity<T>;
    };

const selectType = (state: State): ScreenType | null => select(state)?.type ?? null;

const selectIsLayerActive = (state: State): boolean =>
    !Keyboard.selectors.selectIsActive(state) &&
    !Alert.selectors.selectIsActive(state) &&
    !selectIsNotificationsActive(state) &&
    !Prompt.selectors.selectIsActive(state) &&
    !Overlay.selectors.selectIsActive(state);

const selectIsActive = (state: State): boolean => !!state.screen && selectIsLayerActive(state);

const selectCtx = (state: State): string => select(state)?.ctx ?? FocusDefault;

export const selectors = {
    public: {
        select,
        selectCtx,
        selectType,
        selectTyped,
        selectIsActive,
        selectIsLayerActive,
    },
    private: {},
} as const;
