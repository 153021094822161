import { Storage } from '__SMART_APP_OLD__/app/common/storage';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { Common } from '__SMART_APP_OLD__/app/platform/common';
import { BrandType, DeviceName, GeneralDeviceType, PlatformInterface } from '__SMART_APP_OLD__/app/platform/types';

const PHILIPS_SDK_URL = '/philips/zeasn_deviceInfo_sdk.js';

const getID = () => {
    const id = Storage.get(Storage.Key.DEVICE_ID);
    if (id) return id;
    return window.DeviceInfo.Product.deviceID;
};

// const backEventHandler = (platform: PlatformInterface) => (e: KeyboardEvent) => {
//     if (platform.getIsInputActive()) return;
//     e.preventDefault();
// };

export const initializePhilips = async (platform: PlatformInterface): Promise<void> => {
    await Common.loadDeviceSDK(PHILIPS_SDK_URL, (resolve) => window.onDeviceInfoReady(() => resolve()));
    platform.DEVICE_TYPE = GQL.DeviceType.SmartTvSaphios;
    platform.GENERAL_DEVICE_TYPE = GeneralDeviceType.TV;
    platform.DEVICE_NAME = DeviceName.PHILIPS_TV;
    platform.OS = window.DeviceInfo.Capability.os;
    platform.MODEL = window.DeviceInfo.Product.platform;
    platform.MANUFACTURER = window.DeviceInfo.Channel.vendor;
    platform.OS_VERSION = window.DeviceInfo.Product.firmwareVersion;
    platform.ID = getID();
    platform.BRAND = BrandType.PHILIPS;
    Common.attachNetworkStateListener(platform);
    return Promise.resolve();
};
