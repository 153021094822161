import * as Types from '../../../../../../__SMART_APP_OLD__/api/graphql/types';

import { gql } from '@apollo/client';
import { EventFolderItemFragmentDoc } from './event/eventFolderItem.generated';
import { RecordingFolderItemFragmentDoc } from './recording/recordingFolderItem.generated';
import { SeriesFolderItemFragmentDoc } from './series/seriesFolderItem.generated';
import { ChannelFolderItemFragmentDoc } from './channel/channelFolderItem.generated';
import { VodAssetFolderItemFragmentDoc } from './vodAsset/vodAssetFolderItem.generated';
import { VodFolderFolderItemFragmentDoc } from './vodFolder/vodFolderFolderItem.generated';
import { VodSeriesFolderItemFragmentDoc } from './vodSeries/vodSeriesFolderItem.generated';
import { VodProductFolderItemFragmentDoc } from './vodProduct/vodProductFolderItem.generated';
import { ProductBundleFolderItemFragmentDoc } from './productBundle/productBundleFolderItem.generated';
import { ChannelProductFolderItemFragmentDoc } from './channelProduct/channelProductFolderItem.generated';
import { BannerFolderItemFragmentDoc } from './banner/bannerFolderItem.generated';
export type FolderItem_Banner_Fragment = { __typename: 'Banner', id: string, link: string, thumbnailAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, backgroundImageAlias: { __typename: 'Image', id: string, expiry: any, url?: string | null, width: number, height: number }, parentalRating: { __typename: 'ParentalRating', id: string, expiry: any, title: string, description: string, rank: number, adult: boolean } };

export type FolderItem_Channel_Fragment = { __typename: 'Channel', id: string, title: string, description?: string | null, logoImageAlias?: { __typename: 'Image', id: string, url?: string | null } | null, userInfo?: { __typename?: 'UserChannelInfo', id: string, subscribed: boolean } | null };

export type FolderItem_ChannelProduct_Fragment = { __typename: 'ChannelProduct', id: string };

export type FolderItem_Event_Fragment = { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null };

export type FolderItem_NetworkRecording_Fragment = { __typename: 'NetworkRecording', id: string, status: Types.RecordingStatus, event: { __typename: 'Event', id: string, title: string, start: any, end: any, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, channel?: { __typename?: 'Channel', id: string } | null, entitlements?: { __typename: 'EventEntitlements', id: string, ppvTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, catchupTVAvailableUntil: any, networkRecording: boolean, networkRecordingPlannableUntil: any, needsConcurrencyToken: boolean } | null } };

export type FolderItem_ProductBundle_Fragment = { __typename: 'ProductBundle', id: string };

export type FolderItem_Series_Fragment = { __typename: 'Series', id: string, title: string, episodeCount: number, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, channel?: { __typename?: 'Channel', id: string } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export type FolderItem_VodAsset_Fragment = { __typename: 'VODAsset', id: string, title: string, duration: number, metadata: { __typename?: 'Metadata', year?: number | null, shortDescription?: string | null, seriesInfo?: { __typename?: 'SeriesInfo', id: string } | null, genre?: { __typename?: 'Genre', title: string } | null }, vodAssetEntitlements: { __typename?: 'VODAssetEntitlementCatalog', itemCount: number, items: Array<{ __typename?: 'VODAssetEntitlement', id: string, playback: boolean, playbackAvailableUntil?: any | null } | null> }, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export type FolderItem_VodFolder_Fragment = { __typename: 'VODFolder', id: string, title: string, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export type FolderItem_VodProduct_Fragment = { __typename: 'VODProduct', id: string };

export type FolderItem_VodSeries_Fragment = { __typename: 'VODSeries', id: string, title: string, seasonInfos?: Array<{ __typename?: 'SeasonInfo', number?: number | null } | null> | null, thumbnail?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string } };

export type FolderItemFragment = FolderItem_Banner_Fragment | FolderItem_Channel_Fragment | FolderItem_ChannelProduct_Fragment | FolderItem_Event_Fragment | FolderItem_NetworkRecording_Fragment | FolderItem_ProductBundle_Fragment | FolderItem_Series_Fragment | FolderItem_VodAsset_Fragment | FolderItem_VodFolder_Fragment | FolderItem_VodProduct_Fragment | FolderItem_VodSeries_Fragment;

export const FolderItemFragmentDoc = gql`
    fragment folderItem on ContentItem {
  ... on Event {
    ...eventFolderItem
  }
  ... on NetworkRecording {
    ...recordingFolderItem
  }
  ... on Series {
    ...seriesFolderItem
  }
  ... on Channel {
    ...channelFolderItem
  }
  ... on VODAsset {
    ...vodAssetFolderItem
  }
  ... on VODFolder {
    ...vodFolderFolderItem
  }
  ... on VODSeries {
    ...vodSeriesFolderItem
  }
  ... on VODProduct {
    ...vodProductFolderItem
  }
  ... on ProductBundle {
    ...productBundleFolderItem
  }
  ... on ChannelProduct {
    ...channelProductFolderItem
  }
  ... on Banner {
    ...bannerFolderItem
  }
  __typename
}
    ${EventFolderItemFragmentDoc}
${RecordingFolderItemFragmentDoc}
${SeriesFolderItemFragmentDoc}
${ChannelFolderItemFragmentDoc}
${VodAssetFolderItemFragmentDoc}
${VodFolderFolderItemFragmentDoc}
${VodSeriesFolderItemFragmentDoc}
${VodProductFolderItemFragmentDoc}
${ProductBundleFolderItemFragmentDoc}
${ChannelProductFolderItemFragmentDoc}
${BannerFolderItemFragmentDoc}`;