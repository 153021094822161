import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
export type ParentalRatingFragment = { __typename: 'ParentalRating', id: string, rank: number, title: string, description: string, adult: boolean };

export const ParentalRatingFragmentDoc = gql`
    fragment parentalRating on ParentalRating {
  id
  rank
  title
  description
  adult
  __typename
}
    `;