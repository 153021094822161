import React from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { Language } from '__SMART_APP_OLD__/app/modules/Language';

export const LanguageSelectionScreenSelected: React.FunctionComponent = () => {
    const title = useSelector(Language.selectors.selectSelectedTitle);

    return (
        <Text
            className="language-selection__selected"
            typeface={Typeface.SANS}
            size={FontSize.BIG_1}
            weight={FontWeight.BOLD}
            color={Color.PRIMARY}
        >
            {title}
        </Text>
    );
};
