import { useEffect, useRef } from 'react';

import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { communitySelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Community/store/selectors';

export const useOnChange = (onChange: (store: ReturnType<typeof useStore>) => void) => {
    const store = useStore();
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    useEffect(() => {
        let state = store.getState();
        return store.subscribe(() => {
            const prev = communitySelectors.public.selectSelected(state);
            const next = communitySelectors.public.selectSelected(store.getState());
            if (prev !== next) onChangeRef.current(store);
            state = store.getState();
        });
    }, [store]);
};
