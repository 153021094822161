import React from 'react';

import { Button } from '__SMART_APP_OLD__/app/components/Button';
import { ButtonAnimation, ButtonIconFlavour, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button/Button';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { notificationActionTriggered, notificationBackTriggered } from '__SMART_APP_OLD__/app/modules/Notification/actions';
import {
    BUTTON_ICON_FLAVOUR_FOR_NOTIFICATION_ACTION_ID,
    BUTTON_ICON_FOR_NOTIFICATION_ACTION_ID,
    NOTIFICATION_ACTION_FOR_ACTION_ID,
} from '__SMART_APP_OLD__/app/modules/Notification/constants';
import { NotificationActionID, NotificationButtonIconFlavour } from '__SMART_APP_OLD__/app/modules/Notification/types';

import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

interface Props {
    id: string;
    actions: NotificationActionID[];
    isDisabled: boolean;
}

export const NotificationActions: React.FunctionComponent<Props> = (props) => {
    const { focused, isDisabled, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.X,
        items: props.actions,
        disabled: props.isDisabled,
    });

    const dispatch = useDispatch();
    const enterHandler = (actionId: NotificationActionID) => dispatch(notificationActionTriggered(props.id, actionId));

    Focus.useBack(() => dispatch(notificationBackTriggered(props.id)), { disabled: props.isDisabled });

    return (
        <div className="notification-v2__actions">
            {props.actions.map((id) => (
                <Button
                    key={id}
                    id={id}
                    ctx={Focus.Default}
                    className="notification-v2__action"
                    icon={BUTTON_ICON_FOR_NOTIFICATION_ACTION_ID[id]}
                    iconFlavour={
                        BUTTON_ICON_FLAVOUR_FOR_NOTIFICATION_ACTION_ID[id] === NotificationButtonIconFlavour.DEFAULT
                            ? ButtonIconFlavour.DEFAULT
                            : ButtonIconFlavour.FANCY
                    }
                    isFocused={focused === id && !isDisabled}
                    disabled={isDisabled}
                    animation={ButtonAnimation.LABEL}
                    width={ButtonWidth.FIT}
                    onEnter={enterHandler}
                    onHover={focus}
                >
                    {NOTIFICATION_ACTION_FOR_ACTION_ID[id].label}
                </Button>
            ))}
        </div>
    );
};
