import { useCallback, useMemo, useState } from 'react';

import { Calc } from 'App/Packages/Calc';
import { NavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';
import { useCtx } from 'App/Packages/Focus/Hooks/useCtx';
import { useHandler, useNavigationHandler } from 'App/Packages/Focus/Hooks/useNavigationHandler';
import { useOnBlur } from 'App/Packages/Focus/Hooks/useOnBlur';
import { UseGridOptions, UseGridReturnType } from './Types';

const getRows = <T>(items: T[], columns: number) =>
    new Array(Math.floor((items.length - 1) / columns) + 1).fill(0).map((_, index) => items.slice(index * columns, (index + 1) * columns));

const isOutOfBounds = (event: NavigationEvent, focused: number, row: number, last: number, lastColumn: number): boolean =>
    (row === 0 && event.y === -1) ||
    (row === lastColumn && event.y === 1) ||
    (focused === 0 && event.x === -1) ||
    (focused === last && event.x === 1);

export const useGrid = <T>(options: UseGridOptions<T>): UseGridReturnType<T> => {
    const ctx = useCtx();
    const [focusedIndex, setFocusedIndex] = useState(options.initialFocusedIndex ?? 0);
    const focused = options.items[focusedIndex];
    const lastIndex = options.items.length - 1;
    const focusedRowIndex = Math.floor(focusedIndex / options.columns);
    const lastColumnIndex = useMemo(() => Math.floor(lastIndex / options.columns), [options.columns, lastIndex]);
    const rows = useMemo(() => getRows(options.items, options.columns), [options.items, options.columns]);
    const focusAt = useCallback((index: number) => setFocusedIndex(Calc.clamp(0, index, lastIndex)), [lastIndex]);
    const focus = useCallback((item: T) => focusAt(options.items.indexOf(item)), [options.items, focusAt]);

    const navigationHandler = useNavigationHandler({
        ctx,
        focused,
        columns: options.columns,
        lastIndex,
        disableOutOfBounds: options.disableOutOfBounds,
        isOutOfBounds: (event) => isOutOfBounds(event, focusedIndex, focusedRowIndex, lastIndex, lastColumnIndex),
        guard: options.guard,
        interceptor: options.interceptor,
        setFocusedIndex,
    });

    const isFocused = ctx === options.ctx;
    const isDisabled = useHandler(navigationHandler, options.disabled || !isFocused);
    useOnBlur(() => setFocusedIndex(options.resetFocusIndex ?? 0), isFocused, options.resetFocusOnBlur ?? false);

    return {
        focused,
        focusedIndex,
        focusedRowIndex,
        rows,
        isDisabled,
        focusAt,
        focus,
    };
};
