import { ChannelListsScreenViewMode } from '__SMART_APP_OLD__/app/modules/Screen/modules/ChannelListsScreenCreate/types';

import { Translation } from 'App/Modules/Translation';

export const CHANNEL_CREATE_SCREEN_NAME_TRANSLATIONS: Record<ChannelListsScreenViewMode, Record<string, string>> = {
    [ChannelListsScreenViewMode.CREATE]: {
        title: Translation.Key.Screen.ChannelListsCreate.Create.Title,
        description: Translation.Key.Screen.ChannelListsCreate.Create.Description,
        success: Translation.Key.Screen.ChannelListsCreate.Create.Success,
        failure: Translation.Key.Screen.ChannelListsCreate.Create.Failure,
    },
    [ChannelListsScreenViewMode.EDIT]: {
        title: Translation.Key.Screen.ChannelListsCreate.Edit.Title,
        description: Translation.Key.Screen.ChannelListsCreate.Edit.Description,
        success: Translation.Key.Screen.ChannelListsCreate.Edit.Success,
        failure: Translation.Key.Screen.ChannelListsCreate.Edit.Failure,
    },
};
