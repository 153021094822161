import { Mapper } from '__SMART_APP_OLD__/app/common/mapper';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { initialReminderEntityTableState } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/constants';
import {
    ReminderEntity,
    ReminderEntityStatus,
    ReminderEntityTable,
} from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/types';
import { getReminderEntityTriggerTS, getStatusFromTimeToStart } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/utils';
import { ActionType } from '__SMART_APP_OLD__/app/store/types/ActionType';
import { Reducer } from '__SMART_APP_OLD__/app/store/types/Reducer';

export const reminderEntityTableReducer: Reducer<ReminderEntityTable> = (state, action) => {
    if (!state) return initialReminderEntityTableState;
    switch (action.type) {
        case ActionType.REMINDER_CREATE: {
            if (state.entities[action.payload.reminder.id]) return state;
            const entity = Mapper.Reminder.toEntity(action.payload.reminder);
            const ids = [...state.ids, entity.id];
            const entities = { ...state.entities, [entity.id]: entity };
            const status = getStatusFromTimeToStart(entity.start - action.payload.loadTS);
            const statusTable = { ...state.statusTable, [entity.id]: status };
            const triggerTable = { ...state.triggerTable, [entity.id]: getReminderEntityTriggerTS(entity.start, status) };
            const eventReminderRelationTable = { ...state.eventReminderRelationTable, [entity.eventId]: entity.id };
            return { ...state, ids, entities, statusTable, triggerTable, eventReminderRelationTable };
        }
        case ActionType.REMINDER_LOAD_MANY: {
            const entitiesToBeAdded = action.payload.reminders.reduce<ReminderEntity[]>((result, reminder) => {
                const entity = Mapper.Reminder.toEntity(reminder);
                if (entity.start > action.payload.loadTS && entity.start - action.payload.loadTS > action.payload.timeBeforeTrigger) {
                    result.push(entity);
                }
                return result;
            }, []);
            const entities = Mapper.Common.toEntities(entitiesToBeAdded);
            const ids = Object.keys(entities);
            const statusTable = entitiesToBeAdded.reduce<Record<GQL.ReminderID, ReminderEntityStatus>>(
                (acc, rem) => ({ ...acc, [rem.id]: getStatusFromTimeToStart(rem.start - action.payload.loadTS) }),
                {}
            );
            const triggerTable = entitiesToBeAdded.reduce<Record<GQL.ReminderID, number>>(
                (acc, rem) => ({ ...acc, [rem.id]: getReminderEntityTriggerTS(rem.start, statusTable[rem.id]) }),
                {}
            );
            const eventReminderRelationTable = entitiesToBeAdded.reduce<Record<GQL.EventID, GQL.ReminderID>>(
                (acc, rem) => ({
                    ...acc,
                    [rem.eventId]: rem.id,
                }),
                {}
            );

            return { ...state, ids, entities, statusTable, triggerTable, eventReminderRelationTable };
        }
        case ActionType.REMINDER_DELETE: {
            const reminder = state.entities[action.payload.reminderId];
            if (!reminder) return state;
            const ids = Mapper.Common.toFilteredIds(state.ids, [reminder.id]);
            const entities = Mapper.Common.toRemovedEntities(state.entities, [reminder.id]);
            const statusTable = Mapper.Common.toRemovedEntities(state.statusTable, [reminder.id]);
            const triggerTable = Mapper.Common.toRemovedEntities(state.triggerTable, [reminder.id]);
            const eventReminderRelationTable = Mapper.Common.toRemovedEntities(state.eventReminderRelationTable, [reminder.eventId]);
            return { ...state, ids, entities, statusTable, triggerTable, eventReminderRelationTable };
        }
        case ActionType.REMINDER_STATUS_CHANGE: {
            // if (action.payload.status === ReminderEntityStatus.ACTIVE) return state;
            const reminder = state.entities[action.payload.reminderId];
            const status = state.statusTable[action.payload.reminderId];
            if (!reminder) return state;
            if (status !== ReminderEntityStatus.ACTIVE && action.payload.status === ReminderEntityStatus.SNOOZED) return state;
            const reminderTriggerTS = getReminderEntityTriggerTS(reminder.start, action.payload.status);
            const statusTable = { ...state.statusTable, [reminder.id]: action.payload.status };
            const triggerTable = { ...state.triggerTable, [reminder.id]: reminderTriggerTS };
            return { ...state, statusTable, triggerTable };
        }
        default:
            return state;
    }
};
