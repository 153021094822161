import React from 'react';

import { LogoXploreTV, LogoXploreTVModule, LogoXploreTVProps } from 'App/Packages/UI/Logo/LogoXploreTV';
import { LogoXTV, LogoXTVModule, LogoXTVProps } from 'App/Packages/UI/Logo/LogoXTV';

export type { LogoXTVModule, LogoXTVProps, LogoXploreTVModule, LogoXploreTVProps };

export type LogoProps = {};

export type LogoModule = React.FunctionComponent<LogoProps> & {
    XTV: LogoXTVModule;
    XploreTV: LogoXploreTVModule;
};

export const Logo: LogoModule = () => null;

Logo.XTV = LogoXTV;
Logo.XploreTV = LogoXploreTV;
