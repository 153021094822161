export enum EventConcepts {
    SESSION = 'Session',
    DEVICE = 'Device',
    PROFILE = 'Profile',
    PLAYBACK = 'Playback',
    HTTP_REQUEST = 'HTTPRequest',
    FAVORITE = 'Favorite',
    RECORDING = 'Recording',
    REMINDER = 'Reminder',
    ISSUE = 'Issue',
    PRODUCT = 'Product',
    UI_ACTION = 'UIAction',
    SEARCH = 'SEARCH',
    NETWORK = 'Network',
    DISPLAY = 'Display',
}
