export enum DataSourceService {
    Auth = 'Auth',
    Drm = 'DRM',
}

export enum DataSourceMethod {
    Login = 'Login',
    GraphQL = 'graphql',
    GetToken = 'GetToken',
    CheckToken = 'CheckToken',
    CloneToken = 'CloneToken',
    SessionHB = 'SessionHB',
    Logout = 'Logout',
    LicenseServerPlayReady = 'license_server_playready',
    LicenseServerWidevine = 'license_server_widevine',
}
