import { AdsScippingEngine } from 'App/Modules/AdsScipping/AdsScippingEngine';
import { SloveniaAdsScippingEngine } from 'App/Modules/AdsScipping/Implementation/Slovenia';

export class Slovenia {
    public static async initialize(): Promise<boolean> {
        AdsScippingEngine.initialize(new SloveniaAdsScippingEngine());

        console.log('[Platform.Opco.Slovenia.Initialize]');
        return true;
    }
}
