import React from 'react';

import { ChannelLogo } from '__SMART_APP_OLD__/app/components/ChannelLogo';
import { ContentMarker } from '__SMART_APP_OLD__/app/components/ContentMarker';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { selectSelectedChannelListChannelNumber } from '__SMART_APP_OLD__/app/modules/Data/modules/channelListEntityTable/selectors';
import {
    selectEvent,
    selectEventIsRestricted,
    selectEventShortDescription,
    selectEventShouldMask,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { getEventMetaInfo, getEventSeasonAndEpisode } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/utils';
import { favouritesSelectors } from '__SMART_APP_OLD__/app/modules/Data/modules/Favourites/store/selectors';
import { selectIsReminderSetForEvent } from '__SMART_APP_OLD__/app/modules/Data/modules/reminderEntityTable/selectors';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { CoverImageWithFallback } from '__SMART_APP_OLD__/components/common/CoverImageWithFallback';
import { Icons } from '__SMART_APP_OLD__/components/common/Icons';
import { InnerHTML } from '__SMART_APP_OLD__/components/common/InnerHTML';
import LiveProgressBar from '__SMART_APP_OLD__/components/common/LiveProgressBar';
import { Elements } from '__SMART_APP_OLD__/components/EPG/types';
import { AssetType } from '__SMART_APP_OLD__/utils/Constants';
import { PinSessionType } from '__SMART_APP_OLD__/app/modules/Data/modules/pin/types';

import { ContentMarkerType } from 'App/Types/ContentMarker';
import { selectRecordingStatus } from '__SMART_APP_OLD__/app/modules/Screen/modules/RecordingManagement/selectors';

interface Props {
    className?: string;
    eventId: GQL.EventID;
    channelId: string;
    contentMarker?: ContentMarkerType;
}

export const ProgramCardBody: React.FunctionComponent<Props> = (props) => {
    const channelNumber = useSelector(selectSelectedChannelListChannelNumber(props.channelId));
    const isReminderSet = useSelector(selectIsReminderSetForEvent(props.eventId ?? ''));
    const mask = useSelector(selectEventShouldMask(props.eventId, PinSessionType.PIN_LIVE_TV));
    const restrict = useSelector(selectEventIsRestricted(props.eventId, PinSessionType.PIN_LIVE_TV));
    const event = useSelector(selectEvent(props.eventId));
    const recordingStatus = useSelector(selectRecordingStatus(props.eventId));
    const isFavourited = useSelector(favouritesSelectors.public.selectIsFavourited(props.eventId));
    const descriptionShort = useSelector(selectEventShortDescription(props.eventId, PinSessionType.PIN_LIVE_TV));

    if (!event) return null;

    const isRecordable = Utils.isEventRecordable(
        event.entitlements.networkRecording,
        event.start,
        event.end,
        event.entitlements.networkRecordingPlannableUntil
    );
    const metaInfo = getEventMetaInfo(event);
    const seasonAndEpisodeInfo = getEventSeasonAndEpisode(event);
    const isNowPlaying = props.contentMarker === ContentMarkerType.NowPlaying;
    const isLive = props.contentMarker === ContentMarkerType.Live || isNowPlaying;

    return (
        <div
            className={`program-card ${props.className || ''}`}
            data-element={Elements.EVENT}
            data-channel-id={props.channelId}
            data-event-id={props.eventId}
            data-event-restricted={restrict}
            data-event-date={event?.start ?? 0}
            data-event-is-live={isLive}
            data-event-is-now-playing={isNowPlaying}
        >
            <div className="program-cover-area">
                <div className="program-cover mini-epg-cover">
                    <CoverImageWithFallback src={event.thumbnail ?? ''} alt={event.title} mask={mask} />
                    <div className="blur-logo-overlay" />
                    <ChannelLogo className="channel-logo" id={props.channelId} theme={Theme.Type.Dark} />
                    <div className="cover-overlay">
                        <ContentMarker marker={props.contentMarker} className="program" />

                        {isLive && <LiveProgressBar startTime={event.start} endTime={event.end} />}
                    </div>
                </div>
            </div>
            <div className="program-metadata">
                <Text
                    className="program-title"
                    typeface={Typeface.SERIF}
                    size={FontSize.BODY_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {mask ? 'PARENTAL_CONTROL_MASKED_TITLE' : event.title}
                </Text>
                <Text
                    className="channel-number"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_1}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                >
                    {/* TODO FIX */}
                    {channelNumber}
                </Text>
                <Text
                    className="program-meta-info"
                    typeface={Typeface.SANS}
                    size={FontSize.CAPTION_2}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                >
                    <InnerHTML className="program-meta-info--inner" html={metaInfo} />
                </Text>
                {descriptionShort && (
                    <Text
                        className="program-description"
                        typeface={Typeface.SANS}
                        size={FontSize.CAPTION_2}
                        weight={FontWeight.REGULAR}
                        color={Color.SECONDARY}
                    >
                        {descriptionShort}
                    </Text>
                )}
                <div className="program-card__season_and_episode">
                    <Text typeface={Typeface.SANS} size={FontSize.CAPTION_2} weight={FontWeight.REGULAR} color={Color.SECONDARY}>
                        {seasonAndEpisodeInfo}
                    </Text>
                </div>
                <div className="icons program-card__icons">
                    <Icons
                        assetType={AssetType.EVENT}
                        parentalControl={event.parentalRatingId}
                        isVisuallyImpaired={event.streamInfo.visuallyImpaired}
                        status={recordingStatus}
                        audioLanguages={event.metadata.audio}
                        subtitleLanguages={event.metadata.subtitle.length ? event.metadata.subtitle : undefined}
                        isReminderSet={isReminderSet}
                        isRecordable={isRecordable}
                        isCatchup={event.entitlements.catchupTV}
                        isDolby={event.streamInfo.dolby}
                        isFavourited={isFavourited}
                    />
                </div>
            </div>
        </div>
    );
};
