import * as Types from '../../../../api/graphql/types';

import { gql } from '@apollo/client';
import { ChannelFragmentDoc } from '../fragments/channel.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelLoadManyQueryVariables = Types.Exact<{
  profileId: Types.Scalars['ID']['input'];
  channelPagination: Types.FullPaginationParams;
  channelLogoWidth: Types.Scalars['Int']['input'];
  channelLogoHeight: Types.Scalars['Int']['input'];
  channelBackgroundWidth: Types.Scalars['Int']['input'];
  channelBackgroundHeight: Types.Scalars['Int']['input'];
}>;


export type ChannelLoadManyQuery = { __typename?: 'Nexx4Queries', channels?: { __typename?: 'ChannelsConnection', edges: Array<{ __typename?: 'ChannelsEdge', node: { __typename: 'Channel', id: string, kind: Types.ChannelKind, title: string, defaultNumber?: number | null, beforeTime?: number | null, afterTime?: number | null, NORMAL?: { __typename: 'Image', id: string, url?: string | null } | null, INVERTED?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, playBackgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, personalInfo?: { __typename: 'PersonalChannelInfo', id: string, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null } | null, entitlements?: { __typename: 'ChannelEntitlements', id: string, liveTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, networkRecording: boolean, householdConfirmedReplayPermissions?: boolean | null } | null } } | null> } | null };

export type ChannelLoadManyFragment = { __typename?: 'Nexx4Queries', channels?: { __typename?: 'ChannelsConnection', edges: Array<{ __typename?: 'ChannelsEdge', node: { __typename: 'Channel', id: string, kind: Types.ChannelKind, title: string, defaultNumber?: number | null, beforeTime?: number | null, afterTime?: number | null, NORMAL?: { __typename: 'Image', id: string, url?: string | null } | null, INVERTED?: { __typename: 'Image', id: string, url?: string | null } | null, backgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, playBackgroundImage?: { __typename: 'Image', id: string, url?: string | null } | null, parentalRating: { __typename?: 'ParentalRating', id: string }, personalInfo?: { __typename: 'PersonalChannelInfo', id: string, blocked: boolean, audioLanguage?: string | null, subtitleLanguage?: string | null } | null, entitlements?: { __typename: 'ChannelEntitlements', id: string, liveTV: boolean, pauseLiveTV: boolean, restartTV: boolean, catchupTV: boolean, networkRecording: boolean, householdConfirmedReplayPermissions?: boolean | null } | null } } | null> } | null };

export const ChannelLoadManyFragmentDoc = gql`
    fragment channelLoadMany on Nexx4Queries {
  channels(pagination: $channelPagination) {
    edges {
      node {
        ...channel
      }
    }
  }
}
    ${ChannelFragmentDoc}`;
export const ChannelLoadManyDocument = gql`
    query channelLoadMany($profileId: ID!, $channelPagination: FullPaginationParams!, $channelLogoWidth: Int!, $channelLogoHeight: Int!, $channelBackgroundWidth: Int!, $channelBackgroundHeight: Int!) {
  ...channelLoadMany
}
    ${ChannelLoadManyFragmentDoc}`;

/**
 * __useChannelLoadManyQuery__
 *
 * To run a query within a React component, call `useChannelLoadManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelLoadManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelLoadManyQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      channelPagination: // value for 'channelPagination'
 *      channelLogoWidth: // value for 'channelLogoWidth'
 *      channelLogoHeight: // value for 'channelLogoHeight'
 *      channelBackgroundWidth: // value for 'channelBackgroundWidth'
 *      channelBackgroundHeight: // value for 'channelBackgroundHeight'
 *   },
 * });
 */
export function useChannelLoadManyQuery(baseOptions: Apollo.QueryHookOptions<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>(ChannelLoadManyDocument, options);
      }
export function useChannelLoadManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>(ChannelLoadManyDocument, options);
        }
export function useChannelLoadManySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>(ChannelLoadManyDocument, options);
        }
export type ChannelLoadManyQueryHookResult = ReturnType<typeof useChannelLoadManyQuery>;
export type ChannelLoadManyLazyQueryHookResult = ReturnType<typeof useChannelLoadManyLazyQuery>;
export type ChannelLoadManySuspenseQueryHookResult = ReturnType<typeof useChannelLoadManySuspenseQuery>;
export type ChannelLoadManyQueryResult = Apollo.QueryResult<ChannelLoadManyQuery, ChannelLoadManyQueryVariables>;