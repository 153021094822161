// @ts-nocheck
import { ApolloProvider } from '@apollo/client';
import { Location } from 'history';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';

import GraphqlClient from '__SMART_APP_OLD__/api/graphql/GraphqlClient';
import PlayerAPI from '__SMART_APP_OLD__/api/PlayerAPI';
import { App as AppV2 } from '__SMART_APP_OLD__/app/App';
import { Theme } from '__SMART_APP_OLD__/app/modules/Theme';
import { store } from '__SMART_APP_OLD__/app/store/store';
import { ChannelSwitchDialog } from '__SMART_APP_OLD__/components/channelSwitchDialog';
import { DetailPage } from '__SMART_APP_OLD__/components/details/DetailPage';
import MoreInfoOverlay from '__SMART_APP_OLD__/components/details/MoreInfoOverlay';
import Page from '__SMART_APP_OLD__/components/Page';
import { SearchPage } from '__SMART_APP_OLD__/components/pages/SearchPage';
import PageWithPlayer from '__SMART_APP_OLD__/components/PageWithPlayer';
import PinOverlay from '__SMART_APP_OLD__/components/pin/PinOverlay';
import { PlayVodData } from '__SMART_APP_OLD__/components/PlayVodData';
import Events, { HIDE_CHANNEL_SWITCH_DIALOG } from '__SMART_APP_OLD__/config/Events';
import { PageProvider } from '__SMART_APP_OLD__/providers/usePageContext';
import { routesWithPlayback } from '__SMART_APP_OLD__/utils/Constants';
import { BrowserHistory } from '__SMART_APP_OLD__/utils/CustomHistory';

import { Keyboard } from 'App/Modules/Keyboard';
import { Null } from 'App/Packages/UI/Null';

interface RouteProps {
    component?: any;
    exact: boolean;
    location?: Location;
    path: string;
}

export const Route: React.FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
    const pathname = rest?.location?.pathname ?? '';
    useEffect(() => {
        if (!routesWithPlayback.some((path) => path.test(pathname)) || !Component) {
            PlayerAPI.stopPlayback();
            Events.triggerEvents(HIDE_CHANNEL_SWITCH_DIALOG);
        }
    }, [pathname, Component]);
    if (Component) {
        return <Component {...rest} />;
    }
    return null;
};

const routes = [
    { exact: true, path: '/page/:pageId', component: Page },
    { exact: true, path: '/page/:pageId/:subPageId', component: Page },
    { exact: true, path: '/playChannel/:channelId', component: PageWithPlayer },
    { exact: true, path: '/play/:type/:id/:bookmark', component: PlayVodData },
    { exact: true, path: '/details/:type/:id/', component: DetailPage },
    { exact: true, path: '/search/:context', component: SearchPage },
    { exact: true, path: '/redux', component: Null },
    { exact: false, path: '*' },
];

const Routes = () => (
    <Router history={BrowserHistory}>
        <Switch>
            {routes.map((route, index) => (
                <Route key={index} {...route} />
            ))}
        </Switch>
    </Router>
);

export const App = () => (
    <Provider store={store}>
        <ApolloProvider client={GraphqlClient.getClient()}>
            <Keyboard.Provider>
                <PageProvider>
                    <Theme.RootProvider>
                        <AppV2 />
                        <Routes />
                        <Theme.Provider theme={Theme.Type.Dark}>
                            <ChannelSwitchDialog />
                            <MoreInfoOverlay />
                        </Theme.Provider>
                        <PinOverlay />
                    </Theme.RootProvider>
                </PageProvider>
            </Keyboard.Provider>
        </ApolloProvider>
    </Provider>
);
