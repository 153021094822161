import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useIsDarkTheme } from '__SMART_APP_OLD__/app/hooks/useTheme';
import { selectMessageUnreadCount } from '__SMART_APP_OLD__/app/modules/Data/modules/messageEntityTable/selectors';

interface Props {
    isFocused: boolean;
    className?: string;
}

const getIconFlavour = (isFocused: boolean, isDarkTheme: boolean, hasUnreadMessages: boolean): IconFlavour => {
    if (isFocused && hasUnreadMessages) return IconFlavour.NEGATIVE;
    if (isDarkTheme || isFocused) return IconFlavour.SOLID_DARK;
    return IconFlavour.SOLID_LIGHT;
};

export const MessageInboxIcon: React.FunctionComponent<Props> = (props) => {
    const isDarkTheme = useIsDarkTheme();
    const unreadMessagesCount = useSelector(selectMessageUnreadCount);
    const iconName: IconName = unreadMessagesCount ? IconName.INBOX : IconName.MESSAGING;
    const iconFlavour = getIconFlavour(props.isFocused, isDarkTheme, !!unreadMessagesCount);
    return <Icon className={props.className} name={iconName} flavour={iconFlavour} />;
};
