import { KeyboardElement } from 'App/Modules/Keyboard/Element/KeyboardElement';
import { Subscribable } from 'App/Packages/Subscribable';
import { UI } from 'App/Packages/UI';
import React from 'react';

export type KeyboardCapsLockKeyProps = UI.KeyCapsLockProps & {
    keyboard: KeyboardElement;
};

export const KeyboardCapsLockKey: React.FunctionComponent<KeyboardCapsLockKeyProps> = (props) => {
    const { keyboard, ...other } = props;
    const active = Subscribable.use(keyboard.capslock);
    return <UI.Keyboard.Key.CapsLock active={active} {...other} />;
};
