import { ChannelEntityTable } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/types';

export const initialChannelEntityTableState: ChannelEntityTable = {
    liveId: '',
    channelSwitchId: '',
    ids: [],
    entities: {},
    audioRelationEntityTable: {},
    subtitleRelationEntityTable: {},
    blockedRelationEntityTable: {},
    replayPermissionRelationEntityTable: {},
};
