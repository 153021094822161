import { Div, DivPropsExtendable } from 'App/Packages/UI/Div';
import React from 'react';

export type DetailMetaRowProps = DivPropsExtendable;

export type DetailMetaRowComponent = React.FunctionComponent<DetailMetaRowProps>;

export const DetailMetaRow: DetailMetaRowComponent = (props) => {
    return <Div className="detail__meta-row" {...props} />;
};
