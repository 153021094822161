import { Key } from 'App/Modules/Key';
import { KeyboardKeyElement } from 'App/Modules/Keyboard/Element/KeyboardKeyElement';
import { KeyboardLayoutElement } from 'App/Modules/Keyboard/Element/KeyboardLayoutElement';
import KeyboardSuggestionElement from 'App/Modules/Keyboard/Element/KeyboardSuggestionElement';
import KeyboardSuggestionListElement from 'App/Modules/Keyboard/Element/KeyboardSuggestionListElement';
import { KeyboardTargetElement } from 'App/Modules/Keyboard/Element/KeyboardTargetElement';
import { KeyboardLanguage, KeyboardSchema } from 'App/Modules/Keyboard/types';
import { Calc } from 'App/Packages/Calc';
import { NavigationEvent as FocusNavigationEvent } from 'App/Packages/Focus/Events/NavigationEvent';
import { KeyDownEvent as FocusKeyDownEvent } from 'App/Packages/Focus/Events/KeyDownEvent';
import { Subscribable } from 'App/Packages/Subscribable';

export type KeyboardElementLanguage = {
    active: KeyboardLanguage;
    default: KeyboardLanguage;
    variants: KeyboardLanguage[];
};

export class KeyboardElement {
    public target: KeyboardTargetElement | null;

    public readonly isOpen: Subscribable.Value<boolean>;

    public readonly capslock: Subscribable.Value<boolean>;

    public readonly layout: Subscribable.Value<KeyboardLayoutElement>;

    public readonly layouts: KeyboardLayoutElement[];

    public readonly language: KeyboardElementLanguage;

    public readonly suggestions: KeyboardSuggestionListElement;

    public readonly focused: Subscribable.Value<'layout' | 'suggestions'>;

    constructor(schema: KeyboardSchema) {
        const layouts = schema.layouts.map((layout) => new KeyboardLayoutElement(layout));
        const variants = layouts.map((layout) => layout.schema.language).filter((language): language is KeyboardLanguage => !!language);
        this.target = null;
        this.isOpen = new Subscribable.Value<boolean>(false);
        this.capslock = new Subscribable.Value<boolean>(false);
        this.layout = new Subscribable.Value<KeyboardLayoutElement>(layouts[0]);
        this.layouts = layouts;

        this.language = {
            active: variants[0],
            default: variants[0],
            variants,
        };
        this.suggestions = new KeyboardSuggestionListElement();
        this.focused = new Subscribable.Value<'layout' | 'suggestions'>('layout');
        this.keydown = this.keydown.bind(this);
    }

    public open(target: KeyboardTargetElement) {
        this.isOpen.set(true);
        if (this.layout.get().schema.name !== 'default') {
            this.layout.set(this.layouts[0]);
        }
        this.layout.get().focusByType('key.char');

        if (this.target === target) return;
        if (this.target) this.target.blur();
        this.target = target;
        this.target.focus();
    }

    public close() {
        this.isOpen.set(false);
        const layout = this.layout.get();
        if (this.focused.get() === 'suggestions') {
            this.focused.set('layout');
            this.suggestions.focused?.blur();
            layout.key.focus();
        }
        this.layout.get().focusByType('key.char');

        if (!this.target) return;
        this.target.blur();
        this.target = null;
    }

    public suggest(suggestions: string[]) {
        this.suggestions.suggestionsChanged(suggestions);
        if (this.focused.get() === 'layout') {
            this.suggestions.focused?.blur();
        } else if (!this.suggestions.suggestions.get().length) {
            this.layout.get().key.focus();
            this.focused.set('layout');
        }
    }

    public hover(key: KeyboardKeyElement | KeyboardSuggestionElement) {
        if (key instanceof KeyboardKeyElement) {
            if (this.focused.get() === 'suggestions') {
                this.suggestions.focused?.blur();
                this.focused.set('layout');
            }
            return this.layout.get().focus(key);
        }
        if (key instanceof KeyboardSuggestionElement) {
            if (this.focused.get() === 'layout') {
                this.layout.get().key.blur();
                this.focused.set('suggestions');
            }
            return this.suggestions.focus(key);
        }
        return undefined;
    }

    public keydown(event: FocusKeyDownEvent) {
        this.navigate(event);
        if (event.key === Key.VK_0) this.simulateNumberKey('0');
        if (event.key === Key.VK_1) this.simulateNumberKey('1');
        if (event.key === Key.VK_2) this.simulateNumberKey('2');
        if (event.key === Key.VK_3) this.simulateNumberKey('3');
        if (event.key === Key.VK_4) this.simulateNumberKey('4');
        if (event.key === Key.VK_5) this.simulateNumberKey('5');
        if (event.key === Key.VK_6) this.simulateNumberKey('6');
        if (event.key === Key.VK_7) this.simulateNumberKey('7');
        if (event.key === Key.VK_8) this.simulateNumberKey('8');
        if (event.key === Key.VK_9) this.simulateNumberKey('9');
        if (event.key === Key.VK_ENTER) this.enter();
        if (event.key === Key.VK_BACK) this.back();
    }

    public navigate(event: FocusNavigationEvent) {
        if (event.x === 0 && event.y === 0) return;

        const focused = this.focused.get();
        const layout = this.layout.get();

        if (focused === 'layout') {
            try {
                layout.navigate(event, this.suggestions.suggestions.get().length > 0);
            } catch (error) {
                this.focused.set('suggestions');
                this.suggestions.focused?.focus();
                layout.key.blur();
            }
            return;
        }

        if (this.focused.get() === 'suggestions') {
            try {
                this.suggestions.navigate(event);
            } catch (error) {
                this.focused.set('layout');
                this.suggestions.focused?.blur();
                layout.key.focus();
            }
        }
    }

    public enter() {
        if (this.focused.get() === 'layout') return this.click(this.layout.get().key);
        if (this.focused.get() === 'suggestions') return this.click(this.suggestions.focused!);
        return undefined;
    }

    public back() {
        this.close();
    }

    public click(key: KeyboardKeyElement | KeyboardSuggestionElement) {
        if (key instanceof KeyboardSuggestionElement) {
            if (!this.target) return undefined;
            this.target.clear();
            this.target.keypress(key);
            return this.close();
        }

        if (key.schema.type === 'key.enter') return this.enterKeyPressed(key);
        if (key.schema.type === 'key.backspace') return this.backspaceKeyPressed(key);
        if (key.schema.type === 'key.space') return this.spaceKeyPressed(key);
        if (key.schema.type === 'key.char') return this.charKeyPressed(key);
        if (key.schema.type === 'key.number') return this.numberKeyPressed(key);
        if (key.schema.type === 'key.symbol') return this.symbolKeyPressed(key);
        if (key.schema.type === 'key.arrow.left') return this.arrowLeftKeyPressed(key);
        if (key.schema.type === 'key.arrow.right') return this.arrowRightKeyPressed(key);
        if (key.schema.type === 'key.capslock') return this.capsLockKeyPressed(key);
        if (key.schema.type === 'key.layout') return this.layoutKeyPressed(key);
        if (key.schema.type === 'key.language') return this.langaugeKeyPressed(key);

        if (!this.target) return undefined;
        return this.target.keypress(key);
    }

    private simulateNumberKey(value: string) {
        this.click(new KeyboardKeyElement({ type: 'key.number', value }));
        this.layout.get().focusByType('key.enter');
    }

    private enterKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.enter') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private backspaceKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.backspace') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private spaceKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.space') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private charKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.char') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private numberKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.number') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private symbolKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.symbol') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private arrowLeftKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.arrow.left') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private arrowRightKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.arrow.right') return;
        if (!this.target) return;
        this.target.keypress(key);
    }

    private capsLockKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.capslock') return;
        this.capslock.set(!this.capslock.get());
    }

    private layoutKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.layout') return;
        const layout = this.getToggledLayout();
        if (!layout) return;
        layout.focusByType('key.layout');
        this.layout.set(layout);
    }

    private langaugeKeyPressed(key: KeyboardKeyElement) {
        if (key.schema.type !== 'key.language') return;
        const layout = this.getNextLanguageLayout();
        if (!layout || !layout.schema.language) return;
        this.language.active = layout.schema.language;
        layout.focusByType('key.language');
        this.layout.set(layout);
    }

    private getToggledLayout(): KeyboardLayoutElement | null {
        const current = this.layout.get();
        if (current.schema.name === 'default') return this.getSymbolLayout();
        return this.getActiveLanguageLayout();
    }

    private getNextLanguageLayout(): KeyboardLayoutElement | null {
        const current = this.layout.get();
        if (current.schema.name === 'symbol') return this.getActiveLanguageLayout();
        const index = this.layouts.findIndex((l) => l.schema.language === this.language.active);
        if (index === -1) return null;
        const language = this.language.variants[Calc.infinite(0, index + 1, this.language.variants.length - 1)];
        if (!language) return null;
        const layout = this.layouts.find((l) => l.schema.language === language);
        if (!layout) return null;
        return layout;
    }

    private getActiveLanguageLayout(): KeyboardLayoutElement | null {
        const layout = this.layouts.find((l) => l.schema.language === this.language.active);
        if (!layout) return null;
        return layout;
    }

    private getSymbolLayout(): KeyboardLayoutElement | null {
        const layout = this.layouts.find((l) => l.schema.name === 'symbol');
        if (!layout) return null;
        return layout;
    }
}
