import React, { useEffect, useRef } from 'react';

import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import PinInput from '__SMART_APP_OLD__/components/pin/PinInput';
import Events, { PIN_OVERLAY_HIDE } from '__SMART_APP_OLD__/config/Events';
import Container from '__SMART_APP_OLD__/navigation/Container';
import Focus from '__SMART_APP_OLD__/navigation/Focus';
import { PinStyle } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';

const listenForBackHandler = (keyCode: any) => {
    switch (keyCode) {
        case Key.VK_BACK:
        case Key.VK_CANCEL:
            Events.triggerEvents(PIN_OVERLAY_HIDE);
            return true;
        default:
            return false;
    }
};

const PinWrapper: React.FC<{
    onKey: (keycode: number) => void;
    focusedIndex: number;
    resetInputStates: () => void;
    updateInputState: (value: number) => void;
    updateFocusedIndex: (value: number) => void;
    pin: any;
    counter: number;
    userBlocked: boolean;
    overlayInfo: any;
    isKeyboardOpen: boolean;
    setIsKeyboardOpen: any;
    style: {
        theme: string;
        layout: string;
    };
}> = ({
    overlayInfo,
    userBlocked,
    counter,
    pin,
    onKey,
    focusedIndex,
    isKeyboardOpen,
    setIsKeyboardOpen,
    style,
    resetInputStates,
    updateInputState,
    updateFocusedIndex,
}) => {
    const shouldMoveUp = isKeyboardOpen && !userBlocked ? 'move-up' : '';
    const { layout } = style;
    const userBlockedRef = useRef<any>(userBlocked);

    const { isOverlay } = overlayInfo;

    useEffect(() => {
        if (isOverlay) {
            Focus.addEventListener('back', listenForBackHandler);
        }
        return () => {
            if (isOverlay) {
                Focus.removeEventListener('back', listenForBackHandler);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pinRef = useRef(pin);
    pinRef.current = pin;

    useEffect(() => {
        if (userBlocked) {
            setIsKeyboardOpen(false);
        }
        userBlockedRef.current = userBlocked;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userBlocked]);

    const { pinOverlayTitle, pinOverlayText, pinOverlayHint } = overlayInfo;

    return (
        <Container className={`pin-entry-container ${layout === PinStyle.Fti ? 'page-theme' : 'overlay-theme'}`} stashId="pin-input">
            <div className={`pin-default-layout ${layout} ${shouldMoveUp}`}>
                <Text
                    className="pin-entry-overlay-title"
                    typeface={Typeface.SANS}
                    size={FontSize.TITLE_2}
                    weight={FontWeight.BOLD}
                    color={Color.PRIMARY}
                    isFocused={layout !== PinStyle.Fti}
                >
                    {pinOverlayTitle}
                </Text>
                <Text
                    className="pin-entry-overlay-info"
                    typeface={Typeface.SANS}
                    size={FontSize.BODY_1}
                    weight={FontWeight.REGULAR}
                    color={Color.SECONDARY}
                    isFocused={layout !== PinStyle.Fti}
                >
                    {pinOverlayText}
                </Text>

                {userBlocked ? (
                    <Text
                        className="pin-entry-timer"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_1}
                        weight={FontWeight.BOLD}
                        color={Color.PRIMARY}
                        isFocused={layout !== PinStyle.Fti}
                    >
                        {counter}
                    </Text>
                ) : (
                    <PinInput
                        pin={pin}
                        onKey={onKey}
                        focusedIndex={focusedIndex}
                        isKeyboardOpen={isKeyboardOpen}
                        resetInputStates={resetInputStates}
                        updateInputState={updateInputState}
                        updateFocusedIndex={updateFocusedIndex}
                    />
                )}
                {pinOverlayHint && (
                    <Text
                        className="pin-entry-overlay-hint"
                        typeface={Typeface.SANS}
                        size={FontSize.BODY_1}
                        weight={FontWeight.REGULAR}
                        color={Color.PRIMARY}
                        isFocused={layout !== PinStyle.Fti}
                    >
                        {pinOverlayHint}
                    </Text>
                )}
            </div>
        </Container>
    );
};

export default PinWrapper;
