import { History } from '__SMART_APP_OLD__/app/modules/History';
import { toTvGuide } from '__SMART_APP_OLD__/app/modules/Screen/modules/TVGuide/actions';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import { AssetType, Route } from '__SMART_APP_OLD__/utils/Constants';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

import { EnhancedBack } from 'App/Modules/EnhancedBack';
import { Screen } from 'App/Modules/Screen';

export class CoreBackEngine extends EnhancedBack.Engine {
    protected isEligibleForGoBack(assetType?: string): boolean {
        return (
            assetType === AssetType.VOD_ASSET ||
            assetType === AssetType.TRAILER ||
            assetType === AssetType.EPISODE ||
            assetType === AssetType.NETWORK_RECORDING
        );
    }

    protected goToHome(): Thunk<void> {
        return (dispatch) => {
            dispatch(History.actions.clear());
            CustomHistory.clear();
            CustomHistory.go(`/page/${Route.HOME}`);
        };
    }

    public onPlayerBack(assetType?: string): Thunk<void> {
        return (dispatch, getState) => {
            const hasTVGuide = History.selectors.select(getState()).some((entity) => entity.screen?.type === Screen.Type.TV_GUIDE);

            if (!hasTVGuide || this.isEligibleForGoBack(assetType)) {
                CustomHistory.back();
                return;
            }
            dispatch(this.goToHome());
            dispatch(toTvGuide());
        };
    }
}
