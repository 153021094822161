import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { useStore } from '__SMART_APP_OLD__/app/hooks/useStore';
import { loadEventsForChannel } from '__SMART_APP_OLD__/app/modules/Data/modules/channelEntityTable/actions';
import {
    selectChannelEventIds,
    selectEPGPastLimit,
    selectEvent,
    selectEventBy,
    selectEventByIndex,
    selectEventIndex,
} from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/selectors';
import { EqualityCompare, EventEntity } from '__SMART_APP_OLD__/app/modules/Data/modules/eventEntityTable/types';
import { Utils } from '__SMART_APP_OLD__/app/utils';
import { ProgramCard } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramCard';
import { DateCard } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramsData/components/DateCard';
import { ProgramsMagicModeScroll } from '__SMART_APP_OLD__/components/EPG/MiniEpg/components/ProgramsData/components/ProgramsMagicModeScroll';
import { Card, CardType } from '__SMART_APP_OLD__/components/EPG/MiniEpg/types';
import Player from '__SMART_APP_OLD__/platforms/Player';
import { miniEpgConfig } from '__SMART_APP_OLD__/utils/Constants';

import { Key } from 'App/Modules/Key';
import { ContentMarkerType } from 'App/Types/ContentMarker';

const focusedCardPosition = miniEpgConfig.maxProgramCardsOnPage - 2;
interface Props {
    channelId: string;
    contentMarker: ContentMarkerType;
    time: number;
    event?: EventEntity | null;
    isMagicMode: boolean;
    onBack: () => void;
    onEventChange: (direction: 1 | -1) => void;
    onFastScrollTimeChange: (direction: 1 | -1) => void;
}

export const Programs: React.FunctionComponent<Props> = (props) => {
    const store = useStore();
    const channelEventIds = useSelector(selectChannelEventIds(props.channelId));
    const pastLimit = useSelector(selectEPGPastLimit);
    const liveEventId = useSelector(selectEventBy(props.channelId, Date.now(), { equal: EqualityCompare.START }))?.id ?? '';
    const { isMagicMode, onBack, onEventChange } = props;
    const [isFastScroll, setIsFastScroll] = useState(false);
    const fastScrollStartTimerRef = useRef<ReturnType<typeof setTimeout | any>>();
    const fastScrollUpdateIntervalRef = useRef<ReturnType<typeof setTimeout | any>>(null);
    const index = channelEventIds.indexOf(props.event?.id ?? '');
    const requestInProgress = useRef(false);
    const isNavigatingVericaly = useRef(false);

    const startFastScroll = useCallback(
        (direction: 1 | -1) => {
            setIsFastScroll(true);
            if (fastScrollUpdateIntervalRef.current) return;
            fastScrollUpdateIntervalRef.current = setInterval(
                props.onFastScrollTimeChange,
                miniEpgConfig.fastScrollUpdateInterval,
                direction
            );
        },
        [props.onFastScrollTimeChange]
    );

    const stopFastScroll = useCallback(() => {
        setIsFastScroll(false);
        if (fastScrollStartTimerRef.current) clearTimeout(fastScrollStartTimerRef.current);
        if (fastScrollUpdateIntervalRef.current) clearInterval(fastScrollUpdateIntervalRef.current);
        fastScrollStartTimerRef.current = null;
        fastScrollUpdateIntervalRef.current = null;
    }, []);

    useEffect(() => {
        if (isFastScroll) return undefined;
        const keyDownHandler = throttle(
            (event: KeyboardEvent) => {
                const key = event.keyCode || event.which || event.detail;
                isNavigatingVericaly.current = false;
                if (key === Key.VK_UP || key === Key.VK_DOWN) {
                    const direction = key === Key.VK_UP ? -1 : 1;
                    isNavigatingVericaly.current = true;
                    onEventChange(direction);
                } else if (key === Key.VK_BACK) {
                    event.preventDefault();
                    onBack();
                }
            },
            miniEpgConfig.throttleEventsNavigation,
            { trailing: false }
        );

        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onEventChange, isFastScroll, onBack]);

    useEffect(() => {
        if (!isFastScroll) return undefined;
        const timer = setTimeout(stopFastScroll, miniEpgConfig.debouncefastScrollStop);
        return () => clearTimeout(timer);
    }, [props.time, isFastScroll, stopFastScroll]);

    useEffect(() => {
        let backTimer: ReturnType<typeof setTimeout>;

        const resetBackTimeout = () => {
            if (backTimer) clearTimeout(backTimer);
            if (isMagicMode) return;
            backTimer = setTimeout(onBack, miniEpgConfig.fullEpgTimeout);
        };

        const keyDownHandler = throttle(
            (event: KeyboardEvent) => {
                resetBackTimeout();
                const key = event.keyCode || event.which || event.detail;
                if (fastScrollStartTimerRef.current) return;
                if (key !== Key.VK_UP && key !== Key.VK_DOWN) return;
                const direction = key === Key.VK_UP ? -1 : 1;
                fastScrollStartTimerRef.current = setTimeout(startFastScroll, miniEpgConfig.fastScrollTimeout, direction);
            },
            miniEpgConfig.throttleEventsNavigation,
            { trailing: false }
        );

        resetBackTimeout();
        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener('keyup', stopFastScroll);

        return () => {
            stopFastScroll();
            if (backTimer) clearTimeout(backTimer);
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('keyup', stopFastScroll);
        };
    }, [isMagicMode, stopFastScroll, startFastScroll, onBack]);

    useEffect(() => {
        if (isFastScroll || requestInProgress.current) return;
        if (!props.event) return;

        const prev = new Date(props.event.start);
        const next = new Date(props.event.end);

        if (props.event.isLoading) {
            prev.setHours(prev.getHours() - 8, 0, 0);
            next.setHours(next.getHours() + 6, 0, 0);
            requestInProgress.current = true;
            store
                .dispatch(loadEventsForChannel(props.channelId, prev.getTime(), next.getTime()))
                .then(() => {
                    requestInProgress.current = false;

                    return null;
                })
                .catch((e) => {
                    throw e;
                });
        }

        const state = store.getState();
        const idx = selectEventIndex(props.channelId, props.event.id)(state);
        const isEventLoading = (i: number) => selectEventByIndex(props.channelId, i)(state)?.isLoading;
        const firstVisibleLoadingIndex = [idx - 3, idx - 2, idx - 1].find(isEventLoading);
        const isPrevEventLoading = firstVisibleLoadingIndex !== undefined;
        const isNextEventLoading = isEventLoading(idx + 1);

        if (!isNextEventLoading && !isPrevEventLoading) return;

        if (firstVisibleLoadingIndex !== undefined) {
            prev.setTime(selectEventByIndex(props.channelId, firstVisibleLoadingIndex)(state)?.start ?? prev.getTime());
            prev.setHours(prev.getHours() - 8, 0, 0);
        }

        if (isNextEventLoading) {
            next.setHours(next.getHours() + 6, 0, 0);
        }

        requestInProgress.current = true;
        store
            .dispatch(loadEventsForChannel(props.channelId, prev.getTime(), next.getTime()))
            .then(() => {
                requestInProgress.current = false;

                return null;
            })
            .catch((e) => {
                throw e;
            });
    }, [store, props.event, props.channelId, isFastScroll]);

    useEffect(() => {
        const wheelHandler = throttle(
            (event: WheelEvent) => {
                const { deltaY, deltaX } = event;
                const increment = (deltaY || deltaX) > 0 ? 1 : -1;
                onEventChange(increment);
            },
            miniEpgConfig.throttleEventsNavigation,
            { trailing: false }
        );
        document.addEventListener('wheel', wheelHandler);
        return () => {
            document.removeEventListener('wheel', wheelHandler);
        };
    }, [onEventChange]);

    if (!props.event || props.event.isLoading || isFastScroll) return null;
    const start = index - focusedCardPosition > 0 ? index - focusedCardPosition : 0;
    const end = index + 2 >= channelEventIds.length ? channelEventIds.length : index + 2;
    const liveEventIndex = channelEventIds.indexOf(liveEventId);
    const nextEventId = channelEventIds[liveEventIndex + 1];
    const cards = channelEventIds.slice(start, end).reduce<Card[]>((array, eventId) => {
        const event = selectEvent(eventId)(store.getState());
        if (!event) return array;
        if (event.start === pastLimit && !array.length) array.push({ type: CardType.DATE_CARD, data: new Date(event.start) });
        if (liveEventId === event.id) array.push({ type: CardType.PROGRAM_CARD, data: event.id, marker: props.contentMarker });
        else if (nextEventId === event.id) array.push({ type: CardType.PROGRAM_CARD, data: event.id, marker: ContentMarkerType.Next });
        else {
            array.push({
                type: CardType.PROGRAM_CARD,
                data: event.id,
                // eslint-disable-next-line dot-notation
                marker: Player?.program?.['id'] === event.id ? ContentMarkerType.NowPlaying : undefined,
            });
        }
        if (!Utils.TS.isSameDay(event.start, event.end)) array.push({ type: CardType.DATE_CARD, data: new Date(event.end) });
        return array;
    }, []);
    const selectedIndex = cards.findIndex((card) => card.type === CardType.PROGRAM_CARD && card.data === props.event?.id);
    const selectedCard = cards[selectedIndex];
    const bottomCard = cards[selectedIndex + 1];

    return (
        <>
            {isMagicMode && <div className="mini-epg-navigation-back" onClick={onBack} />}
            <ProgramCard
                className="channel-zapper"
                channelId={props.channelId}
                eventId={props.event.id ?? ''}
                contentMarker={selectedCard.marker}
            />
            <div className="programs-list-container">
                <div className="programs-list-container-top">
                    {cards.slice(0, selectedIndex).map((card) => {
                        if (card.type === CardType.DATE_CARD) return <DateCard key={`date-card-${card.data}`} date={card.data} />;
                        if (card.type === CardType.PROGRAM_CARD) {
                            return (
                                <ProgramCard key={card.data} eventId={card.data} channelId={props.channelId} contentMarker={card.marker} />
                            );
                        }
                        return null;
                    })}
                </div>
                <div className="programs-list-container-bottom">
                    {bottomCard &&
                        (bottomCard.type === CardType.PROGRAM_CARD ? (
                            <ProgramCard channelId={props.channelId} eventId={bottomCard.data} contentMarker={bottomCard.marker} />
                        ) : (
                            <DateCard date={bottomCard.data} />
                        ))}
                </div>
            </div>
            {isMagicMode && <ProgramsMagicModeScroll onChangeEvent={onEventChange} />}
        </>
    );
};
