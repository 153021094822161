import React from 'react';

import { Icon } from '__SMART_APP_OLD__/app/components/Icon';
import { IconFlavour, IconName } from '__SMART_APP_OLD__/app/components/Icon/types';
import { GQL } from '__SMART_APP_OLD__/app/gql';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import {
    selectMessageInboxIsMessageSelected,
    selectMessageInboxIsSelectMode,
} from '__SMART_APP_OLD__/app/modules/Screen/modules/MessageInbox/selectors';

interface Props {
    id: GQL.MessageID;
    isFocused: boolean;
}

const getClassName = (isFocused: boolean, isSelected: boolean, isSelectMode: boolean): string => {
    const classList = ['message-inbox-item__select'];
    if (!isSelectMode || (!isFocused && !isSelected)) classList.push('message-inbox-item__select--hidden');
    return classList.join(' ');
};

export const MessageInboxItemSelect: React.FunctionComponent<Props> = (props) => {
    const isSelectMode = useSelector(selectMessageInboxIsSelectMode);
    const isSelected = useSelector(selectMessageInboxIsMessageSelected(props.id));
    const className = getClassName(props.isFocused, isSelected, isSelectMode);
    return (
        <div className={className}>
            {isSelected ? (
                <Icon className="message-inbox-item__select-icon" name={IconName.OK} flavour={IconFlavour.POSITIVE} />
            ) : (
                <Icon className="message-inbox-item__select-icon" name={IconName.CHECKBOX} flavour={IconFlavour.SOLID_DARK} />
            )}
        </div>
    );
};
