import { AbstractEvent } from 'analytics/logging/events/AbstractEvent';
import { LoggingSessions, SessionTypes, getLoggingSessions } from 'analytics/logging/LoggingSession';
import { generateUuid } from 'analytics/logging/loggingUtils';
import { EventConcepts } from 'analytics/types/concepts';

export enum RequestMethods {
    POST = 'POST',
}

export enum RequestTypes {
    BACK_OFFICE = 'Back-Office',
}

export enum SessionSetupService {
    //  Live viewing of program content.
    Live_TV = 'Live TV',

    // Delayed viewing of live program content. Equivalent to the NPLTV mode in the UI.
    Timeshift_TV = 'Timeshift TV',

    // On demand viewing of a passed program.
    Catchup_TV = 'Catchup TV',

    // On demand viewing of an ongoing program.
    Restart_TV = 'Restart TV',

    // Viewing of a recorded program.
    PVR = 'PVR',
    // On demand viewing of a video asset.
    VOD = 'VOD',
}

export interface SessionSetup {
    service: SessionSetupService;
    position?: number;
    delay?: number;
}

/**
 * @class HttpRequestEvent
 */
export class HttpRequestEvent extends AbstractEvent {
    private readonly concept: EventConcepts = EventConcepts.HTTP_REQUEST;

    private readonly event: string = 'execute';

    private readonly requestType: RequestTypes = RequestTypes.BACK_OFFICE;

    private readonly linkedSessionId: string;

    private sessionSetup?: SessionSetup;

    /**
     * @param latency
     * @param request
     * @param requestMethod
     * @param requestURL
     * @param statusCode
     * @param statusDescription
     * @param sessionSetup
     */
    constructor(
        private readonly latency: number,
        private readonly request: string,
        private readonly requestMethod: RequestMethods,
        private readonly requestURL: string,
        private readonly statusCode: number,
        private readonly statusDescription: string,
        sessionSetup?: SessionSetup
    ) {
        super(generateUuid());
        const loggingSessions: LoggingSessions = getLoggingSessions();

        this.linkedSessionId = loggingSessions[SessionTypes.USER] || loggingSessions[SessionTypes.APPLICATION] || '';
        if (sessionSetup) this.sessionSetup = sessionSetup;
        else delete this.sessionSetup;
    }
}
