import React, { useMemo } from 'react';

import { Button, ButtonCornersStyle, ButtonWidth } from '__SMART_APP_OLD__/app/components/Button';
import { BackgroundColor } from '__SMART_APP_OLD__/app/components/Div';
import { Layout } from '__SMART_APP_OLD__/app/components/Layout';
import { Color, FontSize, FontWeight, Text, Typeface } from '__SMART_APP_OLD__/app/components/Text';
import { useDispatch } from '__SMART_APP_OLD__/app/hooks/useDispatch';
import { useSelector } from '__SMART_APP_OLD__/app/hooks/useSelector';
import { privacyPolicyActions } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/actions';
import { labelForPageTypeForPrivacyPolicyButtonType } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/constants';
import { PrivacyPolicyScreenOption } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/PrivacyPolicyScreenOption';
import { privacyPolicySelectors } from '__SMART_APP_OLD__/app/modules/Screen/modules/PrivacyPolicyScreen/selectors';

import { Screen } from 'App/Modules/Screen';
import { Focus } from 'App/Packages/Focus';
import { Axis } from 'App/Types/Axis';

export const PrivacyPolicyScreenView: React.FunctionComponent<Screen.Props<Screen.Type.PRIVACY_POLICY>> = (props) => {
    const dispatch = useDispatch();
    const options = useSelector(privacyPolicySelectors.private.selectItems);
    const actions = useSelector(privacyPolicySelectors.private.selectButtons(props.type));
    const items = useMemo(() => [...options, ...actions], [options, actions]);

    const { focused, focus } = Focus.useList({
        ctx: Focus.Default,
        axis: Axis.Y,
        items,
    });

    Focus.useBack(() => dispatch(privacyPolicyActions.private.back(props.type)));

    return (
        <Layout className="privacy-policy" backgroundColor={BackgroundColor.PRIMARY} zIndex={Screen.zIndex}>
            <Text
                className="privacy-policy__title"
                size={FontSize.TITLE_2}
                typeface={Typeface.SANS}
                weight={FontWeight.BOLD}
                color={Color.PRIMARY}
            >
                SETTINGS_PRIVACY_POLICY_TITLE
            </Text>
            <div className="privacy-policy__options">
                {options.map((option) => (
                    <PrivacyPolicyScreenOption
                        key={option}
                        id={option}
                        ctx={Focus.Default}
                        isFocused={focused === option}
                        onEnter={() => dispatch(privacyPolicyActions.private.selected(option))}
                        onHover={focus}
                    />
                ))}
            </div>
            <div className="privacy-policy__actions">
                {actions.map((action) => (
                    <Button
                        className="privacy-policy__action"
                        key={action}
                        id={action}
                        ctx={Focus.Default}
                        isFocused={focused === action}
                        width={ButtonWidth.FULL}
                        corners={ButtonCornersStyle.ROUNDED}
                        onEnter={() => dispatch(privacyPolicyActions.private.selected(action))}
                        onHover={focus}
                    >
                        {labelForPageTypeForPrivacyPolicyButtonType[props.type][action]}
                    </Button>
                ))}
            </div>
        </Layout>
    );
};
