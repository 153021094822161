import { FtiCompletedTable } from '__SMART_APP_OLD__/app/modules/Fti/store/types';

export const initialFtiState: FtiCompletedTable = {
    welcomeStepCompleted: 0,
    closingStepCompleted: 0,
    ageRatingStepCompleted: 0,
    communityStepCompleted: 0,
    educationStepCompleted: 0,
    languageStepCompleted: 0,
    masterPincodeStepCompleted: 0,
    privacyStepCompleted: 0,
    replayStepCompleted: 0,
    startupStepCompleted: 0,
    themeStepCompleted: 0,
};
