import { historySelectors } from '__SMART_APP_OLD__/app/modules/History/selectors';
import {
    HistoryActionType,
    HistoryClearAction,
    HistoryEntity,
    HistoryPopAction,
    HistoryPushAction,
} from '__SMART_APP_OLD__/app/modules/History/types';
import { Thunk } from '__SMART_APP_OLD__/app/store/types/Thunk';
import CustomHistory from '__SMART_APP_OLD__/utils/CustomHistory';

import { Overlay } from 'App/Modules/Overlay';
import { Screen } from 'App/Modules/Screen';

const popInternal = (): HistoryPopAction => ({
    type: HistoryActionType.POP,
});

const pushInternal = (entity: HistoryEntity): HistoryPushAction => ({
    type: HistoryActionType.PUSH,
    payload: { entity },
});

const clear = (): HistoryClearAction => ({
    type: HistoryActionType.CLEAR,
});

const pop =
    (keepRedux: boolean = false): Thunk<HistoryEntity | null> =>
    (dispatch, getState) => {
        const state = getState();
        const entity = historySelectors.private.selectPopEntity(state);
        if (!entity && keepRedux) return null;
        if ((!entity && !keepRedux) || (entity && !entity.screen && !entity.overlay)) CustomHistory.back();
        dispatch(popInternal());

        if (entity?.screen) dispatch(Screen.actions.mount(entity.screen.type, entity.screen.props, entity.screen.ctx));
        else dispatch(Screen.actions.unmount());

        if (entity?.overlay) dispatch(Overlay.actions.mount(entity?.overlay.type, entity.overlay.props, entity.overlay.ctx));
        else dispatch(Overlay.actions.unmount());

        return entity;
    };

const push =
    (entity?: HistoryEntity): Thunk<void> =>
    (dispatch, getState) => {
        dispatch(pushInternal(entity ?? { screen: Screen.selectors.select(getState()), overlay: Overlay.selectors.select(getState()) }));
    };

const clearReduxBeforeNavigation =
    (isReplace?: boolean): Thunk<void> =>
    (dispatch) => {
        if (window.location.pathname !== '/redux') return;
        if (!isReplace) dispatch(push());
        dispatch(Screen.actions.unmount());
        dispatch(Overlay.actions.unmount());
    };

export const historyActions = { public: { push, pop, clearReduxBeforeNavigation, clear } };
